import { AnyAction } from 'redux';
import * as ACTION_TYPE from '../actions/changePassword.actionTypes';
import { ChangePasswordState } from '../types/ChangePasswordState';
import { CHANGE_PASSWORD_STATUS } from '../types/ChangePassword.status';
/**
 *
 */
const initialState: ChangePasswordState = {
  status: CHANGE_PASSWORD_STATUS.INIT
};

function onChangePassword(state: ChangePasswordState): ChangePasswordState {
  return {
    ...state,
    status: CHANGE_PASSWORD_STATUS.INIT
  };
}
/**
 *
 * @param state
 */
function onChangePasswordFailure(state: ChangePasswordState): ChangePasswordState {
  return {
    ...state,
    status: CHANGE_PASSWORD_STATUS.ERROR
  };
}
/**
 *
 * @param state
 * @param action
 */
function onChangePasswordSuccess(
  state: ChangePasswordState,
  action: AnyAction
): ChangePasswordState {
  return {
    ...state,
    status: CHANGE_PASSWORD_STATUS.SUCCESS,
    Details: action.data
  };
}
/**
 *
 * @param state
 */

export default function changePasswordReducer(
  state: ChangePasswordState = initialState,
  action: AnyAction
): ChangePasswordState {
  switch (action.type) {
    case ACTION_TYPE.CHANGE_PASSWORD_PROGRESS:
      return onChangePassword(state);
    case ACTION_TYPE.CHANGE_PASSWORD_SUCCESS:
      return onChangePasswordSuccess(state, action);
    case ACTION_TYPE.CHANGE_PASSWORD_FAILURE:
      return onChangePasswordFailure(state);

    default:
      return state;
  }
}
