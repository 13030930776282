/**
 * Update Multiple Dashboard Items Saga
 */

import { put, takeEvery, call, all } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import * as UPDATE_MULTIPLE_DASHBOARD_ITEMS_ACTION_TYPES from './updateMultipleDashboardItems.actionsTypes';
import * as UPDATE_MULTIPLE_DASHBOARD_ITEMS_ACTIONS from './updateMultipleDashboardItems.action';
import {
  GenericObject,
  httpPatch,
  httpPut,
  removeProgressFor,
  showProgressFor,
  showToast,
  APISERVICES
} from '../../../../framework';
import { SagaIterator } from 'redux-saga';

function updateMultipleDashboardItemsIntoDatabase(data: any) {
  const rawInput: any = data.dashboard_items;
  const optimizedInput: any = rawInput?.map((item: any) => {
    return {
      dashboard_item_uuid: item?.dashboard_item_uuid,
      config: item?.config
    };
  });

  return httpPut(
    `orgs/${data.slug}/dashboard/${data.dashboard_uuid}/dashboard_items`,
    {
      dashboard_items: optimizedInput
    },
    APISERVICES.HOST_API
  );
}

function updateDashboardIntoDatabase(data: any) {
  //
  const { dashboard_data } = data;
  if (dashboard_data?.role_uuids) {
    return httpPatch(
      `orgs/${data.slug}/dashboard/${data.dashboard_uuid}`,
      {
        name: dashboard_data.name,
        role_uuids: dashboard_data.role_uuids,
        meta_data: dashboard_data?.meta_data
      },
      APISERVICES.HOST_API
    );
  }
  return httpPatch(
    `orgs/${data.slug}/dashboard/${data.dashboard_uuid}`,
    {
      name: dashboard_data.name,
      meta_data: dashboard_data?.meta_data
    },
    APISERVICES.HOST_API
  );
}

export function* updateMultipleDashboardItems(action: AnyAction): GenericObject {
  try {
    yield put(showProgressFor('UPDATE_MULTIPLE_DASHBOARD_ITEMS'));

    const [dashboardRepoonse, dashboardItemRepoonse] = yield all([
      call(updateDashboardIntoDatabase, action.payload),
      call(updateMultipleDashboardItemsIntoDatabase, action.payload)
    ]);
    yield put(showToast('Dashboard updated successfully', 'success'));
    yield put(
      // response
      UPDATE_MULTIPLE_DASHBOARD_ITEMS_ACTIONS.handleUpdateMultipleDashboardItemsSuccess(
        dashboardRepoonse
      )
    );
  } catch (error: any) {
    yield put(showToast('Update Dashboard failed', 'error'));
    yield put(
      UPDATE_MULTIPLE_DASHBOARD_ITEMS_ACTIONS.handleUpdateMultipleDashboardItemsFailure(error)
    );
  } finally {
    yield put(removeProgressFor('UPDATE_MULTIPLE_DASHBOARD_ITEMS'));
  }
}

export function* watchUpdateMultipleDashboardItemsSaga(): SagaIterator {
  yield takeEvery(
    UPDATE_MULTIPLE_DASHBOARD_ITEMS_ACTION_TYPES.UPDATE_MULTIPLE_DASHBOARD_ITEMS_PROGRESS,
    updateMultipleDashboardItems
  );
}
