/**
 * Create rule object parsers
 * @author priyanka.ambawane@shorelineiot.com
 */

import values from 'lodash/values';
import { isString } from 'util';

const timeConverter = (time: string) => {
  // converts time from 24hr to 12hr format
  const hourEnd = time.indexOf(':');
  const H = +time.substr(0, hourEnd);
  const h = H % 12 || 12;
  const ampm = H < 12 || H === 24 ? 'AM' : 'PM';
  const timeString = `${h + time.substr(hourEnd, 3)} ${ampm}`;
  return timeString;
};

export const formToApiDataParser = (dataObj: any) => {
  delete dataObj.days;

  dataObj = {
    ...dataObj,
    attributes: {
      alert_level: dataObj.alert_level,
      multi_device_rule: false,
      sub_type: 'normal_rule'
    }
  };

  delete dataObj.alert_level;

  const arr = [];
  const fromTime: string = dataObj.from;
  if (fromTime.includes('AM' || 'am')) {
    const removeAm: Array<string> = fromTime.split(' ');
    const str: Array<string> = removeAm[0].split(':');
    if (parseInt(str[0]) === 12) {
      arr.push(`00:${str[1]}`);
    } else {
      arr.push(`${str[0]}:${str[1]}`);
    }
  } else if (fromTime.includes('PM' || 'pm')) {
    const removePm: Array<string> = fromTime.split(' ');
    const str: Array<string> = removePm[0].split(':');
    if (parseInt(str[0]) !== 12) {
      arr.push(`${parseInt(str[0]) + 12}:${str[1]}`);
    } else {
      arr.push(`12:${str[1]}`);
    }
  } else {
    arr.push(fromTime);
  }

  const toTime: string = dataObj.to;
  if (toTime.includes('AM' || 'am')) {
    const removeAm: Array<string> = toTime.split(' ');
    const str: Array<string> = removeAm[0].split(':');
    if (parseInt(str[0]) === 12) {
      arr.push(`00:${str[1]}`);
    } else {
      arr.push(`${str[0]}:${str[1]}`);
    }
  } else if (toTime.includes('PM' || 'pm')) {
    const removePm: Array<string> = toTime.split(' ');
    const str: Array<string> = removePm[0].split(':');
    if (parseInt(str[0]) !== 12) {
      arr.push(`${parseInt(str[0]) + 12}:${str[1]}`);
    } else {
      arr.push(`12:${str[1]}`);
    }
  } else {
    arr.push(toTime);
  }

  dataObj = {
    ...dataObj,
    holdoff_time_ms: dataObj.holdoff_time_ms * 1000,
    timetable: {
      days_of_week: [
        {
          enabled_days: dataObj.timetable.days_of_week[0].enabled_days,
          enabled_timeslots: [arr]
        }
      ]
    }
  };

  delete dataObj.from;
  delete dataObj.to;

  const emailActions: any = [];
  const smsActions: any = [];
  const webhookTriggerActions: any = [];

  if (dataObj.conditions) {
    const conditionsArray: Array<any> = [];
    dataObj.conditions.forEach((condition: any) => {
      if (condition.device) {
        delete condition.device;

        conditionsArray.push({
          ...condition,
          operation_data: {
            ...condition.operation_data,
            comp_val: Number(condition.operation_data.comp_val)
          },
          validation_time_ms: condition.validation_time_ms * 1000
        });
      } else {
        // For narrowband rules conditions
        delete condition.threshold_type;
        delete condition.rule_id;

        dataObj = {
          ...dataObj,
          name: condition.alert_name
        };
        if (dataObj.rule_type === 'device') {
          dataObj = {
            ...dataObj,
            rule_type: 'cloud'
          };
        }
        delete condition.alert_name;

        if (dataObj.dpid) {
          conditionsArray.push({
            ...condition,
            operation_data: {
              ...condition.operation_data,
              dpid: Number(dataObj.dpid),
              lower_val: Number(condition.operation_data.lower_val),
              upper_val: Number(condition.operation_data.upper_val)
            }
          });
          delete dataObj.dpid;
        } else {
          conditionsArray.push({
            ...condition,
            operation_data: {
              ...condition.operation_data,
              dpid: Number(condition.operation_data.dpid),
              lower_val: Number(condition.operation_data.lower_val),
              upper_val: Number(condition.operation_data.upper_val)
            }
          });
        }
      }
    });
    dataObj = { ...dataObj, conditions: conditionsArray };
  }

  if (dataObj.actions) {
    const actionsArray = dataObj.actions;
    delete dataObj.actions;
    actionsArray.forEach((action: any, actionIndex: number) => {
      if (action.isNew) {
        delete action.isNew;
      }
      if (action.type === 'email') {
        delete action.type;
        let emailObj = action.email;
        if (isString(action.email.to)) {
          const emails: Array<string> = [];
          emails.push(emailObj.to);
          emailObj = {
            ...emailObj,
            to: emails
          };
          emailActions.push(emailObj);
        } else {
          if (action.email.to.length === 0) {
            throw 'NoEmailPresent';
          }
          emailActions.push(action.email);
        }
        dataObj = {
          ...dataObj,
          actions: {
            ...dataObj.actions,
            email: emailActions
          }
        };
      } else if (action.type === 'sms') {
        delete action.type;
        let smsObj = action.sms;
        smsObj = {
          ...smsObj,
          to: dataObj[`smsActions${actionIndex}`]
        };
        const phoneNos: Array<string> = [];
        smsObj.to?.forEach((element: any) => {
          phoneNos.push(`${element.countryCode}-${element.number}`);
        });
        smsObj = {
          ...smsObj,
          to: phoneNos
        };
        delete dataObj[`smsActions${actionIndex}`];
        smsActions.push(smsObj);
        dataObj = {
          ...dataObj,
          actions: {
            ...dataObj.actions,
            sms: smsActions
          }
        };
      } else if (action.type === 'set-variable') {
        delete action.type;
        dataObj = {
          ...dataObj,
          actions: {
            ...dataObj.actions,
            set_device_variable: action.setVariable.variables
          }
        };
      } else if (action.type === 'webhook-trigger') {
        delete action.type;
        webhookTriggerActions.push(values(action));
        dataObj = {
          ...dataObj,
          actions: {
            ...dataObj.actions,
            webhook: webhookTriggerActions
          }
        };
      }
    });
  }
  return dataObj;
};

export const apiToFormDataParser = (dataObj: any) => {
  if (dataObj.attributes) {
    dataObj = {
      ...dataObj,
      alert_level: dataObj.attributes.alert_level
    };

    delete dataObj.attributes;
  }

  if (dataObj.rule_type !== 'cloud') {
    const conditionsArray: any = [];
    if (dataObj.conditions) {
      dataObj.conditions.forEach((condition: any) => {
        conditionsArray.push({
          ...condition,
          validation_time_ms: condition.validation_time_ms / 1000
        });
      });
      dataObj = { ...dataObj, conditions: conditionsArray };
      // delete dataObj.apply_to_devices;
    }
  } else {
    const conditionsArray: any = [];
    if (dataObj.conditions) {
      dataObj.conditions.forEach((condition: any) => {
        conditionsArray.push({
          ...condition,
          alert_name: dataObj.name,
          threshold_type: dataObj.alert_level,
          rule_id: dataObj.rule_template_uuid
        });
      });
      dataObj = { ...dataObj, conditions: conditionsArray };
    }
  }

  if (dataObj.timetable) {
    dataObj = {
      ...dataObj,
      holdoff_time_ms: dataObj.holdoff_time_ms / 1000,
      days: dataObj.timetable.days_of_week[0].enabled_days,
      from: dataObj.timetable.days_of_week[0].enabled_timeslots[0][0],
      to: dataObj.timetable.days_of_week[0].enabled_timeslots[0][1]
    };
  }

  dataObj = {
    ...dataObj,
    from: timeConverter(dataObj.from),
    to: timeConverter(dataObj.to)
  };

  const actionsArray: any = [];

  if (dataObj.actions) {
    if (dataObj.actions.email) {
      dataObj.actions.email.forEach((email: any) => {
        actionsArray.push({
          type: 'email',
          email
        });
      });
    }
    if (dataObj.actions.sms) {
      dataObj.actions.sms.forEach((sms: any, actionIndex: number) => {
        const numbers: any = [];
        sms.to.forEach((element: string) => {
          numbers.push({
            countryCode: element.split(/-(.+)/)[0],
            number: element.split(/-(.+)/)[1]
          });
        });
        dataObj = {
          ...dataObj,
          [`smsActions${actionIndex}`]: numbers
        };
        actionsArray.push({
          type: 'sms',
          sms: { to: numbers, body: sms.body }
        });
      });
    }
    if (dataObj.actions.set_device_variable) {
      actionsArray.push({
        type: 'set-variable',
        setVariable: {
          variables: dataObj.actions.set_device_variable
        }
      });
    }
  }
  dataObj = { ...dataObj, actions: actionsArray };
  return dataObj;
};
