/**
 * Organisation List Saga
 * @author ashwini.gawade@shorelineiot.com
 */
import { takeEvery, call, put } from 'redux-saga/effects';
import * as ACTIONS from './actions/orgList.actionTypes';
import * as orgActions from './actions/orgList.actions';
import * as switchOrgActions from '../org-selector/orgSelector.actions';
import { GenericObject, httpGet, SafeSaga, APISERVICES } from '../../../../framework';
import { SagaIterator } from 'redux-saga';

/**
 *
 */
function getOrganisations() {
  return httpGet('orgs', null, APISERVICES.HOST_API);
}

/**
 * get Organisation List
 */
function* getOrganisationListSaga() {
  const response: GenericObject = yield call(getOrganisations);
  yield put(orgActions.fetchOrgListSuccess(response.results));
  // yield put(showToast("Failed to fetch Org List", "error", true));
}
function* errorHandler(err: any) {
  yield put(orgActions.fetchOrgListFailure(err));
  yield put(switchOrgActions.switchOrgFailure(err));
}
/**
 * Aggregating Organisation List sagas
 */
export function* watchOrganisationListSaga(): SagaIterator {
  yield takeEvery(
    ACTIONS.FETCH_ORG_LIST_PROGRESS,
    SafeSaga(getOrganisationListSaga, ACTIONS.FETCH_ORG_LIST_PROGRESS, errorHandler)
  );
}
