import en from './en-US';
import de from './de-De';
import fr from './fr-CA';
import es from './es-ES';

export default {
  ...en,
  ...de,
  ...fr,
  ...es
};
