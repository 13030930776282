import { all } from 'redux-saga/effects';
import {
  watchAlertsListSaga,
  watchTagsListSaga,
  watchAlertsUpdateSaga,
  watchSensorAlertDeleteSaga,
  watchGetSensorAlertSaga,
  watchSensorAlertsCreateSaga
} from './store/sagas';

export function* alertsSagas() {
  yield all([
    watchAlertsListSaga(),
    watchTagsListSaga(),
    watchAlertsUpdateSaga(),
    watchSensorAlertDeleteSaga(),
    watchGetSensorAlertSaga(),
    watchSensorAlertsCreateSaga()
  ]);
}
