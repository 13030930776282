/**
 * Utility functions for working with devices and groups.
 * @author aditya.bhadange@shorelineiot.com
 */

import { WEBSOCKET_MODULE_OPTIONS } from '../../common/websocket/websocket.constants';
import { DevicePhotoList, DeviceProfilesDetails, Photo } from './device.types';
import { Device, Group } from './types';

/**
 * Adds new properties to an array of devices to indicate their hierarchical
 * level in a device group.
 *
 * `Note` - Make sure to use this function while you are fetching the list of devices
 * via the API or WebSocket.
 * @param {Device[]} devices - An array of devices.
 * @returns {Device[]} - The modified array of devices with new properties.
 */
export function setNewDeviceProperties(devices: Device[]): Device[] {
  devices?.forEach((device) => {
    const devicePathLength =
      device?.fq_dg_path === '' ? 1 : device?.fq_dg_path?.split('.')?.length + 1;
    device.level = devicePathLength;
    device.device_id = device?.id;
    device.is_group = false;
  });
  return devices;
}

/**
 * Adds new properties to an array of groups to indicate their hierarchical
 * level and group ID.
 *
 * `Note` - Make sure to use this function while you are fetching the list of groups
 * via the API or WebSocket.
 * @param {Group[]} groups - An array of groups.
 * @returns {Group[]} - The modified array of groups with new properties.
 */
export function setNewGroupProperties(groups: Group[]): Group[] {
  groups?.forEach((group) => {
    const groupPathLength = group?.fq_dg_path.split('.')?.length;
    group.level = groupPathLength;
    group.group_id = String(group?.fq_dg_path.split('.')?.pop());
    group.is_group = true;
  });
  return groups;
}

export function isDeviceRelatedWebSocketMessage(data: any) {
  return (
    data.module === WEBSOCKET_MODULE_OPTIONS.DEVICE.DEVICE_LIST ||
    data.module === WEBSOCKET_MODULE_OPTIONS.DEVICE.DEVICE_GROUP_LIST ||
    data.module === WEBSOCKET_MODULE_OPTIONS.DEVICE.POWERTRAIN ||
    data.module === WEBSOCKET_MODULE_OPTIONS.DEVICE.DATAPOINT_LIST ||
    data.module === WEBSOCKET_MODULE_OPTIONS.DEVICE.NB_MODULES ||
    data.module === WEBSOCKET_MODULE_OPTIONS.DEVICE.EXPORT_DATA
  );
}

export function getFormatedList(results: DeviceProfilesDetails[]) {
  let defaultProfile = '';
  const formattedProfileList: {
    value: string;
    label: string;
  }[] = [];
  results?.forEach((deviceProfile) => {
    if (deviceProfile.is_default === false) {
      formattedProfileList.push({
        value: deviceProfile.id ? deviceProfile.id : 'NA',
        label: deviceProfile.name ? deviceProfile.name.trim() : 'NA'
      });
    } else {
      defaultProfile = deviceProfile.id;
    }
  });
  return { formattedProfileList, defaultProfile };
}

export function setPhotosData(response: DevicePhotoList): Photo[] {
  if (!response || !response.device_photos_meta) {
    return [];
  }

  return response.device_photos_meta.map((item) => {
    return {
      ...item,
      key: `${item.device_name} : ${item.view}`,
      caption: `${item.device_name} : ${item.view}`
    };
  });
}
