/**
 * user self actions
 * @author swapnil.kshirsagar@shorelineiot.com
 */

export const FETCH_USER_SELF_SUCCESS = 'FEATURES/USERSETTINGS/FETCH_USER_SELF_SUCCESS';
export const FETCH_USER_SELF_FAILURE = 'FEATURES/USERSETTINGS/FETCH_USER_SELF_FAILURE';
export const FETCH_USER_SELF_PROGRESS = 'FEATURES/USERSETTINGS/FETCH_USER_SELF_PROGRESS';
export const FETCH_USER_SELF_RESET = 'FEATURES/USERSETTINGS/FETCH_USER_SELF_RESET';
export const UPDATE_USER = 'FEATURES/USERSETTINGS/UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'FEATURES/USERSETTINGS/UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'FEATURES/USERSETTINGS/UPDATE_USER_FAILURE';

export const RESET_USER_DETAILS = 'FEATURES/USERSETTINGS/RESET_USER_DETAILS';
