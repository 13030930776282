/**
 * Get Dashboard Items Actions
 * @author harsha.gokhale@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTION_TYPES from './dashboardItems.actionsTypes';
/**
 *
 */
export function getDashboardItems(data: any): AnyAction {
  return {
    type: ACTION_TYPES.DASHBOARD_ITEMS_PROGRESS,
    payload: data
  };
}
/**
 *
 * @param data data to be returned on successful fetch API call
 */
export function handleGetDashboardItemsSuccess(data: any): AnyAction {
  return {
    type: ACTION_TYPES.DASHBOARD_ITEMS_SUCCESS,
    payload: data
  };
}
/**
 *
 */
export function handleGetDashboardItemsFailure(error: any): AnyAction {
  return {
    type: ACTION_TYPES.DASHBOARD_ITEMS_FAILURE,
    payload: error
  };
}

export const resetState = (): AnyAction => {
  return {
    type: ACTION_TYPES.DASHBOARD_ITEMS_RESET_STATE,
    payload: false
  };
};
