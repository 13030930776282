/**
 * Alarms Export State
 * @author prerna.more@shorelineiot.com
 */
export enum ALARMS_EXPORT_STATUS {
  INIT,
  FETCHING,
  READY,
  ERROR
}

export type ShouldDownloadExportedFile = boolean;

export interface AlarmsExportState {
  status: ALARMS_EXPORT_STATUS;
  emailList: Array<any>;
  exportList: any;
  exportType: any;
  /**
   * Determines whether the exported alarms file should be downloaded
   * for the current user or not.
   * This is used in case the user opens two same tabs in the browser,
   * and one's download action shouldn't interfere with the other tab instance.
   */
  shouldDownloadFile: ShouldDownloadExportedFile;
}

export type ExportAlarmsFromWebsocketResponsePayload = {
  download_link: string;
  start_ts: number;
  ts: number;
};

export interface ExportAlarmsFromWebsocketResponse {
  module: string;
  action: string;
  payload: ExportAlarmsFromWebsocketResponsePayload;
}
