/**
 * Device Mailbox Action Types
 * @author prerna.more@shorelineiot.com
 */
export const FETCH_DEVICE_MAILBOX = 'FEATURES/REPORTS/DEVICE_MAILBOX/FETCH_DEVICE_MAILBOX';
export const FETCH_DEVICE_MAILBOX_SUCCESS =
  'FEATURES/REPORTS/DEVICE_MAILBOX/FETCH_DEVICE_MAILBOX_SUCCESS';
export const FETCH_DEVICE_MAILBOX_FAILURE =
  'FEATURES/REPORTS/DEVICE_MAILBOX/FETCH_DEVICE_MAILBOX_FAILURE';
export const RESET_DEVICE_MAILBOX = 'FEATURES/REPORTS/DEVICE_MAILBOX/RESET';
export const FETCH_DEVICE_MAILBOX_PATH_LIST_SUCCESS =
  'FEATURES/REPORTS/DEVICE_MAILBOX/FETCH_DEVICE_MAILBOX_PATH_LIST_SUCCESS';
