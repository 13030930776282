/**
 * SL Checkbox Component
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { ReactNode, useEffect } from 'react';
import { FormControl, Checkbox, FormHelperText, FormControlLabel } from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';
import TranslateText from '../../../i18n/TranslateText';

interface SLCheckboxProps {
  name: string;
  label: ReactNode;
  rules?: Object | Function;
  value?: boolean;
  defaultValue?: any;
  onChange?: (value: boolean) => void | undefined;
  [key: string]: any;
  testID?: string;
  showLabelSeparately?: boolean;
  labelTranslationId?: string;
}
const SLCheckbox: React.FC<SLCheckboxProps> = ({
  name,
  label,
  rules,
  value,
  defaultValue,
  onChange,
  testID,
  showLabelSeparately,
  labelTranslationId,
  ...otherProps
}: SLCheckboxProps) => {
  const {
    formState: { errors },
    watch,
    setValue,
    control
  } = useFormContext();

  const handleOnChange = (checked: boolean) => {
    setValue(name, checked);
    if (onChange) {
      onChange(checked);
    }
  };
  useEffect(() => {
    if (value !== undefined) {
      setValue(name, value);
    }
  }, [name, value]);

  return (
    <FormControl error={Boolean(errors[name])}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={typeof rules === 'function' ? rules(watch) : rules}
        render={({ field, fieldState: { error } }) => (
          <>
            {showLabelSeparately ? (
              <>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox
                    inputProps={{ id: testID }}
                    color="primary"
                    name={name}
                    inputRef={field.ref}
                    checked={value !== undefined ? Boolean(value) : Boolean(field.value)}
                    value={value !== undefined ? Boolean(value) : Boolean(field.value)}
                    defaultValue={defaultValue}
                    defaultChecked={defaultValue}
                    onChange={(event, checked) => {
                      field.onChange(event);
                      handleOnChange(checked);
                    }}
                    {...otherProps}
                  />
                  {labelTranslationId ? TranslateText(labelTranslationId) : label}
                </div>
                {error && error?.message && <FormHelperText error>{error?.message}</FormHelperText>}
              </>
            ) : (
              <>
                <FormControlLabel
                  label={labelTranslationId ? TranslateText(labelTranslationId) : label}
                  control={
                    <Checkbox
                      inputProps={{ id: testID }}
                      color="primary"
                      name={name}
                      inputRef={field.ref}
                      checked={value !== undefined ? Boolean(value) : Boolean(field.value)}
                      value={value !== undefined ? Boolean(value) : Boolean(field.value)}
                      defaultValue={defaultValue}
                      defaultChecked={defaultValue}
                      onChange={(event, checked) => {
                        field.onChange(event);
                        handleOnChange(checked);
                      }}
                      {...otherProps}
                    />
                  }
                />
                {error && error?.message && <FormHelperText error>{error?.message}</FormHelperText>}
              </>
            )}
          </>
        )}
      />
    </FormControl>
  );
};
export default SLCheckbox;
