/**
 * VA Summary State Type
 * @author mahesh.kedari@shorelineiot.com
 */
export enum VA_SUMMARY_STATUS {
  INIT,
  FETCHING,
  READY,
  ERROR
}
export interface VASummaryState {
  status: VA_SUMMARY_STATUS;
  summary: any;
}
