import { AnyAction } from 'redux';
import * as ACTION_TYPES from './alerts.actionTypes';
/**
 *
 */
export function createAlert(data: any): AnyAction {
  return {
    type: ACTION_TYPES.CREATE_ALERT_PROGRESS,
    payload: data
  };
}
/**
 *
 * @param data data to be returned on successful fetch API call
 */
export function createAlertSuccess(data: any): AnyAction {
  return {
    type: ACTION_TYPES.CREATE_ALERT_SUCCESS,
    payload: data
  };
}
/**
 *
 */
export function createAlertFailure(error: any): AnyAction {
  return {
    type: ACTION_TYPES.CREATE_ALERT_FAILURE,
    payload: error
  };
}

export function conditionsFormData(data: any): AnyAction {
  return {
    type: ACTION_TYPES.CONDITIONS_FORM_DATA,
    payload: data
  };
}
