/**
 * New Item Card Component
 * @author mahesh.kedari@shorelineiot.com
 */
import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      backgroundColor: theme.palette.background.default,
      height: 'auto'
    },
    header: {
      margin: '0 auto',
      height: '35px',
      padding: '0 10px',
      color: theme.palette.background.default,
      backgroundColor: theme.palette.primary.main,
      cursor: 'pointer'
    },
    image: {
      width: '40%',
      height: '40%',
      display: 'inline-block'
    },
    button: {
      padding: '12px 3px',
      color: theme.palette.background.default
    },
    actionCard: {
      width: '16vw',
      height: '53vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '2vw',
      backgroundColor: theme.palette.background.default
    },
    actionIcon: {
      marginTop: '5px',
      width: '30%',
      height: '30%'
    },
    actionTitle: {
      textAlign: 'center',
      paddingTop: '25px'
    },
    actionContent: {
      textAlign: 'center',
      padding: '25px'
    }
  })
);
