/**
 * Alerts Actions
 * @author shubham.holkar@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTION_TYPES from './alerts.actionTypes';
import { DeleteSensorAlertActionPayload } from '../types/AlertsListState';
/**
 *
 */
export function deleteSensorAlert(data: DeleteSensorAlertActionPayload): AnyAction {
  return {
    type: ACTION_TYPES.DELETE_SENSOR_ALERT,
    payload: data
  };
}
export function deleteSensorAlertsSuccess(data: any): AnyAction {
  return {
    type: ACTION_TYPES.DELETE_SENSOR_ALERT_SUCCESS,
    payload: data
  };
}

export function deleteSensorAlertsReset(): AnyAction {
  return {
    type: ACTION_TYPES.DELETE_SENSOR_ALERT_RESET
  };
}
