/**
 * Create Org Menu Component
 * @author mahesh.kedari@shorelineiot.com
 */
import React from 'react';
import { MenuItem, Typography } from '@mui/material';
import AddOutline from '@mui/icons-material/AddCircleOutline';
import { useStyles } from '../orgList.styles';
import { CanAccess } from '../../../../framework';
import { ORGANISATION_PERMISSIONS } from '../../organisation.permissions';
import { sidebarId } from '../../../../framework/components/sidebar/sidebar.utils';
import TranslateText from '../../../../i18n/TranslateText';
/**
 *
 */
interface CreateOrgMenuProps {
  create: (entity: string) => void;
}
/**
 *
 */
export default function CreateOrgMenu(props: CreateOrgMenuProps) {
  const { create } = props;
  const classes = useStyles();
  return (
    <CanAccess action={ORGANISATION_PERMISSIONS.CREATE}>
      <MenuItem id={sidebarId.create_org} onClick={() => create('org')} className={classes.orgmenu}>
        <AddOutline />
        &nbsp;&nbsp;
        <Typography variant="body1">{TranslateText('create_org', 'Create Org')}</Typography>
      </MenuItem>
    </CanAccess>
  );
}
