/**
 * Update CPM Saga
 * @author mahesh.kedari@shorelineiot.com
 */
import { takeEvery, call, put } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import * as VA_ACTIONS from '../vibrationAnalysis.actionTypes';
import * as vaActions from '../vibrationAnalysis.actions';
import { httpPost, SafeSaga, showToast, APISERVICES } from '../../../../../framework';
import { SagaIterator } from 'redux-saga';
/**
 *
 * @param slug
 * @param deviceId
 * @param eventId
 * @param cpm
 * @returns
 */
function postUpdateCPMRequest(
  slug: string,
  deviceId: string | number,
  eventId: number,
  cpm: number
) {
  return httpPost(
    `orgs/${slug}/devices/${deviceId}/narrowband/change_cpm`,
    {
      event_id: eventId,
      cpm
    },
    APISERVICES.DEVICE_API
  );
}
/**
 *
 * @param action
 */
function* updateCPM(action: AnyAction) {
  const { slug, deviceId, eventId, cpm } = action.payload;
  yield call(postUpdateCPMRequest, slug, deviceId, eventId, cpm);
  yield put(vaActions.updateCpmSuccess());
}

function* handleError() {
  yield put(showToast('Failed to update speed', 'error'));
}
/**
 *
 */
export function* watchUpdateCPMSaga(): SagaIterator {
  yield takeEvery(VA_ACTIONS.UPDATE_CPM, SafeSaga(updateCPM, VA_ACTIONS.UPDATE_CPM, handleError));
}
