import { TooltipFormatterContextObject } from 'highcharts';

function translatePowerAndUnit(du: any) {
  if (du === 'mm/second^2' || du === 'inches/second^2') {
    return `${du.split('^')[0].replace('second', 'sec')}
                    <sup>${du.split('^')[1]}</sup>
         `;
  }
  return du !== undefined ? `${du.replace('second', 'sec')}` : '';
}

export function NarrowBandTooltipFormatter(
  this: TooltipFormatterContextObject | { series: any; point: any & { chart: any } }
): string {
  const displayUnit = this?.series?.chart?.displayUnit;
  const hzOrCPMValue = this?.series?.chart?.hzOrCPMValue;
  const seriesName = this?.series?.name;
  const seriesRawDPName = seriesName.split('-');
  const updatedDisplayUnit =
    seriesRawDPName[1]?.trim() !== 'Structure borne' ? translatePowerAndUnit(displayUnit) : 'dB';

  let formattedString = `<table style="background-color: #00000000">`;
  const { spectrumSeriesName, order, x, y } = this.point;
  if (seriesName && seriesName === 'Harmonics') {
    formattedString += `<td><b>${spectrumSeriesName || ''}</b></td></tr>`;

    formattedString += `<tr><td style="color: {series.color}">Harmonics: </td>`;
    formattedString += `<td><b>${order || ''}x</b></td></tr>`;
  } else {
    // Replace - Peaks with blank text in series name.
    // This is in order to avoid separate name for peak to be shown to user when hover
    formattedString += `<td><b>${seriesName.replace(' - Peaks', '') || ''}</b></td></tr>`;
  }

  formattedString += `<td><b>${x?.toFixed(2) || ''} ${hzOrCPMValue} , ${
    y?.toFixed(4) || ''
  } ${updatedDisplayUnit}</b></td></tr></table>`;
  return formattedString;
}
