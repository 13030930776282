import { conditions, sensorNameConditions } from './BatteryStatusConstants';
import {
  BatteryStatusCondition,
  BatteryStatusFormatterObject,
  DataTypes,
  TableCellFormattingType
} from './batteryStatusTypes';

export const getConditionList = (columnName: string) => {
  if (columnName === 'sensor_name') {
    return sensorNameConditions;
  } else {
    return conditions;
  }
};

export const matchesCondition = (
  condition: BatteryStatusCondition,
  value: string,
  cellValue: string,
  type: DataTypes
) => {
  switch (condition) {
    case 'EQUALS':
      return type === 'number' ? Number(cellValue) === Number(value) : cellValue === value;
    case 'NOT_EQUAL':
      return type === 'number' ? Number(cellValue) !== Number(value) : cellValue !== value;
    case 'GREATER_THAN':
      return Number(cellValue) > Number(value);
    case 'GREATER_THAN_OR_EQUAL':
      return Number(cellValue) >= Number(value);
    case 'LESS_THAN':
      return Number(cellValue) < Number(value);
    case 'LESS_THAN_OR_EQUAL':
      return Number(cellValue) <= Number(value);
    case 'CONTAINS':
      return cellValue.toLowerCase().includes(value.toLowerCase());
    case 'DOES_NOT_CONTAIN':
      return !cellValue.toLowerCase().includes(value.toLowerCase());
    default:
      return false;
  }
};

export const buildStylesObj = (formatters: BatteryStatusFormatterObject[]) => {
  const stylesObj: TableCellFormattingType = {};
  formatters.forEach((formatter) => {
    stylesObj[formatter.type] = formatter.color.hex;
  });
  return stylesObj;
};
