/**
 * Alarms Details Saga
 * @author mahesh.kedari@shorelineiot.com
 */
import { put, takeEvery, call } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import * as actions from './alarmsDetails.actions';
import * as ACTION_TYPES from './alarmsDetails.actionTypes';
import { AlarmDetails } from '../types/AlarmDetails';
import {
  httpGet,
  removeProgressFor,
  showProgressFor,
  showToast,
  APISERVICES
} from '../../../../framework';
import { VAactions, DiagnosisType } from '../../../workflow/vibration-analysis/store';
import { SagaIterator } from 'redux-saga';
import { Alarm } from '../alarms.types';

const ErrorMessage = 'DEVICE-SERVICE__INVALID-ALERT-TRIGGER-ID';
const ALARMS_DETAILS_PROGRESS_ID = 'ALARMS_DETAILS_PROGRESS_ID';
/**
 *
 */
function getDetails(data: any) {
  return httpGet(
    `orgs/${data.slug}/device_alarms/${data.ruleTriggerId}`,
    null,
    APISERVICES.DEVICE_API
  );
}
/**
 *
 * @param action
 */
function* alarmsDetailsSaga(action: AnyAction) {
  yield put(showProgressFor(ALARMS_DETAILS_PROGRESS_ID));
  try {
    const { alarm }: { alarm: Alarm } = action.payload;
    const response: AlarmDetails = yield call(getDetails, action.payload);
    const diagnosis: DiagnosisType = {
      event_id: Number(response.event_id),
      diagnosis: alarm.diagnosis,
      recommendation: alarm.recommendation,
      anomaly: alarm.anomaly,
      analyst_notes: alarm.analyst_notes || ''
    };
    yield put(VAactions.getEventDiagnosisSuccess(diagnosis));

    yield put(
      actions.fetchAlarmsDetailsSuccess({
        ...response,
        age: alarm?.latest
      })
    );
  } catch (error: any) {
    const errorBody = error?.response?.body;
    const parsedError = typeof errorBody === 'string' ? JSON.parse(errorBody) : errorBody;
    const errCode = parsedError?.err_code;
    if (error?.response?.statusCode === 400 && errCode === ErrorMessage) {
      yield put(showToast('No vibration data associated with this alert', 'warning', true));
    } else {
      yield put(showToast(error?.message || 'Internal Server Error', 'error', true));
    }
    yield put(actions.fetchAlarmsDetailsFailure());
  } finally {
    yield put(removeProgressFor(ALARMS_DETAILS_PROGRESS_ID));
  }
}
/**
 *
 */
export function* watchAlarmsDetailsSagas(): SagaIterator {
  yield takeEvery(ACTION_TYPES.FETCH_ALARMS_DETAILS, alarmsDetailsSaga);
}
