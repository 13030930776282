/**
 * Custom hook - useGAEventTracker
 * @author aditya.bhadange@shorelineiot.com
 */

import ReactGA from 'react-ga4';

/**
 * This is the custom-hook for Google Analytics event recording.
 * @param {string} category A top level category for these events. E.g. 'User', 'Alarms', 'Device', etc.
 * @returns
 */
const useGAEventTracker = (category = 'Event category') => {
  /**
   * This function is used to record user interactions (events) that
   * don't trigger a change in URL.
   * @param {string} action A description of the behaviour. E.g. 'Changed filters', 'Deleted a device', 'Deleted user', etc.
   * @param {string} label More precise labelling of the related action.
   */
  const trackEvent = (action = 'action', label = 'label') => {
    ReactGA.event({ category, action, label });
  };
  return trackEvent;
};

export default useGAEventTracker;
