/**
 * narrowBandunit Reducer
 * @author ashwini.gawade@shorelineiot.com
 */
import { AnyAction } from 'redux';
import {
  FREQUENCY_UNIT,
  AMPLITUDE_SELECTION,
  VAUnitsState,
  VAMotionUnitStatus,
  UnitInfoType,
  INDICATOR_OPTION,
  TIME_CHART_OPTION
} from './vaUnit.stateType';
import * as ACTIONS from '../vibrationAnalysis.actionTypes';

const initialState: VAUnitsState = {
  frequencyUnit: FREQUENCY_UNIT.CPM,
  motionUnit: {
    status: VAMotionUnitStatus.INIT,
    motionUnitsList: [],
    selectedMotionUnit: 'inches/second',
    selectedMotionUnitType: 'inches/second'
  },
  amplitudeSelection: AMPLITUDE_SELECTION.PEAK,
  displayUnit: '',
  enableHarmonics: false,
  lockHarmonics: false,
  harmonicsCount: 5,
  selectedIndicator: INDICATOR_OPTION.INDICATORS,
  enableSideband: false,
  lockSideband: false,
  timeChartSelectedIndicator: TIME_CHART_OPTION.NONE,
  lockDeltaMarker: false,
  enableDeltaMarker: false,
  enableSlidingWindow: false,
  slidingWindowCount: 5
};

function handleFetchMotionUnits(state: VAUnitsState): VAUnitsState {
  return {
    ...state,
    motionUnit: { ...state.motionUnit, status: VAMotionUnitStatus.FETCHING }
  };
}

function handleFetchMotionUnitsSuccess(state: VAUnitsState, data: UnitInfoType): VAUnitsState {
  const motionUnitsList = data.units.map((item) => ({
    value: item,
    label: item.replace('second', 'sec'),
    name: item
  }));

  return {
    ...state,
    motionUnit: {
      ...state.motionUnit,
      status: VAMotionUnitStatus.READY,
      motionUnitsList,
      selectedMotionUnit: data.user_unit,
      selectedMotionUnitType: data.user_unit
    },
    displayUnit: data.user_unit.replace('second', 'sec')
  };
}

function handleFetchMotionUnitsError(state: VAUnitsState): VAUnitsState {
  return {
    ...state,
    motionUnit: { ...state.motionUnit, status: VAMotionUnitStatus.ERROR }
  };
}

function handleResetMotionUnits(state: VAUnitsState): VAUnitsState {
  return {
    ...initialState,
    motionUnit: { ...state.motionUnit, selectedMotionUnit: '' }
  };
}
function handleUpdateSelectedMotionUnit(state: VAUnitsState, payload: any): VAUnitsState {
  const { motionUnit } = state;
  const selectedMotionUnit = motionUnit.motionUnitsList.find(
    (unit) => unit.value === payload.value
  );
  return {
    ...state,
    motionUnit: {
      ...state.motionUnit,
      selectedMotionUnit: selectedMotionUnit.value,
      selectedMotionUnitType: selectedMotionUnit.value
    },
    displayUnit: selectedMotionUnit.label
  };
}
function handleUpdateFrequencyUnit(state: VAUnitsState, payload: any) {
  return {
    ...state,
    frequencyUnit: payload.value
  };
}
function handleUpdateEnableHarmonics(state: VAUnitsState, payload: any) {
  return {
    ...state,
    enableHarmonics: payload
  };
}
function handleUpdateLockHarmonics(state: VAUnitsState, payload: any) {
  return {
    ...state,
    lockHarmonics: payload
  };
}
function handleUpdateCurrentIndicator(state: VAUnitsState, payload: any) {
  return {
    ...state,
    selectedIndicator: payload
  };
}

function handleUpdateHarmonicsCount(state: VAUnitsState, payload: any) {
  return {
    ...state,
    harmonicsCount: payload
  };
}
function handleUpdateAmplitudeSelection(state: VAUnitsState, payload: any) {
  return {
    ...state,
    amplitudeSelection: payload.value
  };
}
function handleUpdateSidebandState(state: VAUnitsState, payload: any) {
  return {
    ...state,
    enableSideband: payload
  };
}
function handleUpdateLockSideband(state: VAUnitsState, payload: any) {
  return {
    ...state,
    lockSideband: payload
  };
}

function handleUpdateTimeChartCurrentIndicator(state: VAUnitsState, payload: any) {
  return {
    ...state,
    timeChartSelectedIndicator: payload
  };
}

function handleUpdateLockDeltaMarker(state: VAUnitsState, payload: any) {
  return {
    ...state,
    lockDeltaMarker: payload
  };
}

function handleUpdateDeltaMarkerState(state: VAUnitsState, payload: any) {
  return {
    ...state,
    enableDeltaMarker: payload
  };
}

function handleUpdateSlidingWindowState(state: VAUnitsState, payload: any) {
  return {
    ...state,
    enableSlidingWindow: payload
  };
}

function handleUpdateSlidingWindowCount(state: VAUnitsState, payload: any) {
  return {
    ...state,
    slidingWindowCount: payload
  };
}
/**
 *
 */
function handleReset() {
  return {
    ...initialState
  };
}
/*
    Reducer is a pure function that takes actions and state as input 
    and returns/passes the modified state to the store.
    It is implemented using switch case to update the state according 
    to the ation type required.
*/

export default function VAUnitReducer(
  state: VAUnitsState = initialState,
  action: AnyAction
): VAUnitsState {
  switch (action.type) {
    case ACTIONS.FETCH_MOTION_UNITS:
      return handleFetchMotionUnits(state);
    case ACTIONS.FETCH_MOTION_UNITS_SUCCESS:
      return handleFetchMotionUnitsSuccess(state, action.payload);
    case ACTIONS.FETCH_MOTION_UNITS_FAILURE:
      return handleFetchMotionUnitsError(state);
    case ACTIONS.RESET_MOTION_UNITS:
      return handleResetMotionUnits(state);
    case ACTIONS.UPDATE_SELECTED_MOTION_UNIT_SUCCESS:
      return handleUpdateSelectedMotionUnit(state, action.payload);
    case ACTIONS.UPDATE_FREQUENCY_UNIT:
      return handleUpdateFrequencyUnit(state, action.payload);
    case ACTIONS.UPDATE_AMPLITUDE_SELECTION:
      return handleUpdateAmplitudeSelection(state, action.payload);
    case ACTIONS.UPDATE_HARMONICS:
      return handleUpdateEnableHarmonics(state, action.payload);
    case ACTIONS.RESET_VIBRATION_ANALYSIS:
    case ACTIONS.RESET_FFT_CHART:
      return handleReset();
    case ACTIONS.UPDATE_HARMONICS_COUNT:
      return handleUpdateHarmonicsCount(state, action.payload);
    case ACTIONS.UPDATE_LOCK_HARMONICS_STATUS:
      return handleUpdateLockHarmonics(state, action.payload);
    case ACTIONS.SET_CURRENT_INDICATORS:
      return handleUpdateCurrentIndicator(state, action.payload);
    case ACTIONS.SET_LOCK_SIDEBAND_STATE:
      return handleUpdateLockSideband(state, action.payload);
    case ACTIONS.SET_SIDEBAND_STATE:
      return handleUpdateSidebandState(state, action.payload);
    case ACTIONS.SET_TIME_CHART_CURRENT_INDICATORS:
      return handleUpdateTimeChartCurrentIndicator(state, action.payload);
    case ACTIONS.SET_LOCK_DELTA_MARKER_STATE:
      return handleUpdateLockDeltaMarker(state, action.payload);
    case ACTIONS.SET_DELTA_MARKER_STATE:
      return handleUpdateDeltaMarkerState(state, action.payload);
    case ACTIONS.SET_SLIDING_WINDOW_STATE:
      return handleUpdateSlidingWindowState(state, action.payload);
    case ACTIONS.UPDATE_SLIDING_WINDOW_COUNT:
      return handleUpdateSlidingWindowCount(state, action.payload);
    default:
      return state;
  }
}
