import { TypographyOptions } from '@mui/material/styles/createTypography';

export const Typography: TypographyOptions = {
  fontFamily: 'Poppins',
  fontSize: 16,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,
  h1: {
    fontSize: '1.5rem',
    '@media (min-width:600px)': {
      fontSize: '2rem'
    },
    '@media (min-width:960px)': {
      fontSize: '3rem'
    },
    '@media (min-width:1280px)': {
      fontSize: '4rem'
    },
    '@media (min-width:1920px)': {
      fontSize: '5rem'
    }
  },
  h2: {
    fontSize: '0.875rem',
    '@media (min-width:600px)': {
      fontSize: '1.1rem'
    },
    '@media (min-width:960px)': {
      fontSize: '1.325rem'
    },
    '@media (min-width:1280px)': {
      fontSize: '1.55rem'
    },
    '@media (min-width:1920px)': {
      fontSize: '1.875rem'
    }
  },
  h3: {
    fontSize: '0.75rem',
    '@media (min-width:600px)': {
      fontSize: '0.845rem'
    },
    '@media (min-width:960px)': {
      fontSize: '0.94rem'
    },
    '@media (min-width:1280px)': {
      fontSize: '1.035rem'
    },
    '@media (min-width:1920px)': {
      fontSize: '1.125rem'
    },
    fontWeight: 500
  },
  h4: {
    fontWeight: 500,
    fontSize: '0.625rem',
    '@media (min-width:600px)': {
      fontSize: '0.719rem'
    },
    '@media (min-width:960px)': {
      fontSize: '0.813rem'
    },
    '@media (min-width:1280px)': {
      fontSize: '0.907rem'
    },
    '@media (min-width:1920px)': {
      fontSize: '1rem'
    }
  },
  h5: {
    fontSize: '0.625rem',
    '@media (min-width:600px)': {
      fontSize: '0.687rem'
    },
    '@media (min-width:960px)': {
      fontSize: '0.749rem'
    },
    '@media (min-width:1280px)': {
      fontSize: '0.811rem'
    },
    '@media (min-width:1920px)': {
      fontSize: '0.875rem'
    }
  },
  body1: {
    fontSize: '0.625rem',
    '@media (min-width:600px)': {
      fontSize: '0.687rem'
    },
    '@media (min-width:960px)': {
      fontSize: '0.89rem'
    },
    '@media (min-width:1280px)': {
      fontSize: '1rem'
    },
    '@media (min-width:1920px)': {
      fontSize: '1.25rem'
    }
  },
  body2: {
    fontSize: '0.625rem',
    '@media(min-width: 600px)': {
      fontSize: '0.625rem'
    },
    '@media (min-width:960px)': {
      fontSize: '0.75rem'
    },
    '@media (min-width:1280px)': {
      fontSize: '0.89rem'
    },
    '@media (min-width:1920px)': {
      fontSize: '1rem'
    }
  },
  subtitle1: {
    fontSize: '0.625rem',
    '@media (min-width:600px)': {
      fontSize: '0.687rem'
    },
    '@media (min-width:960px)': {
      fontSize: '0.94rem'
    },
    '@media (min-width:1280px)': {
      fontSize: '1rem'
    }
  },
  subtitle2: {
    fontSize: '0.625rem',
    '@media(min-width: 600px)': {
      fontSize: '0.625rem'
    },
    '@media (min-width:960px)': {
      fontSize: '0.85rem'
    },
    '@media (min-width:1920px)': {
      fontSize: '1rem'
    }
  },
  caption: {
    fontSize: '0.7rem',
    '@media(min-width: 600px)': {
      fontSize: '0.7rem'
    },
    '@media (min-width:960px)': {
      fontSize: '0.85rem'
    },
    '@media (min-width:1920px)': {
      fontSize: '0.9rem'
    }
  },
  button: {
    textTransform: 'none',
    fontWeight: 'normal'
  },

  allVariants: {
    fontWeight: 300
  }
};
