import React, { useRef, useEffect, useState } from 'react';
import { ResultSet } from './MapChartViewer';
import { Series } from '../common/map-chart/mapChartUtils';
import GoogleMapReact, { Maps, MapOptions } from 'google-map-react';
import MapMarker from './MapMarker';
import { useStyles } from './legendAndButton.styles';
import { MapChartMarkerTypes } from './MapChartDefaultViewConfig';
import { Info } from '../common/map-chart/mapChartUtils';
import { MapTooltip } from './MapTooltip';

interface GoogleMapProps {
  resultSet: ResultSet;
  editMode: boolean;
  showCritical: boolean;
  showHigh: boolean;
  showOther: boolean;
  showModerate: boolean;
  markerType: MapChartMarkerTypes;
}

export interface TooltipInfo extends Info {
  lat?: number;
  lng?: number;
}
export const GoogleMapComponent: React.FC<GoogleMapProps> = ({
  resultSet,
  editMode,
  showCritical,
  showHigh,
  showOther,
  showModerate,
  markerType
}) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [tooltipInfo, setTooltipInfo] = useState<TooltipInfo | null>(null);
  const [onTooltip, setOnTooltip] = useState<boolean>(false);
  const mapRef = useRef<google.maps.Map | null>(null);
  const mapsRef = useRef<typeof google.maps | null>(null);
  const classes = useStyles();

  /** This function assigns positions to control options like fullscreen, zoom in/out,
   * and map type(map/satellite/terrain)
   */
  const createMapOptions = (maps: Maps): MapOptions => {
    return {
      zoomControlOptions: {
        position: maps.ControlPosition.TOP_LEFT
      },
      mapTypeControlOptions: {
        position: maps.ControlPosition.TOP_RIGHT
      },
      fullscreenControlOptions: {
        position: maps.ControlPosition.TOP_RIGHT
      },
      mapTypeControl: true,
      fullscreenControl: true,
      minZoom: 1
    };
  };

  // computes the mean of the latitudes and longitudes to get the correct center
  const getCenter = () => {
    let centerLat = 0;
    let centerLon = 0;
    const allPoints = [
      ...resultSet.critical_series,
      ...resultSet.high_series,
      ...resultSet.moderate_series,
      ...resultSet.other_series
    ];
    allPoints.forEach((point: Series) => {
      if (point.lat) {
        centerLat += point.lat;
      }
      if (point.lon) {
        centerLon += point.lon;
      }
    });

    return { lat: centerLat / allPoints.length, lng: centerLon / allPoints.length };
  };

  const createResetZoomButton = () => {
    //create the reset button
    const resetZoomButton = document.createElement('button');
    resetZoomButton.type = 'button';
    resetZoomButton.className = classes.customMapButton;
    resetZoomButton.textContent = 'Reset';
    resetZoomButton.addEventListener('click', () => {
      if (mapRef && mapRef.current && mapsRef && mapsRef.current) {
        computeMapBoundaries({ map: mapRef.current, maps: mapsRef.current });
      }
    });
    //create the tooltip for it
    const tooltipText = document.createElement('span');
    tooltipText.className = classes.customMapButtonTooltip;
    tooltipText.textContent = 'Reset Zoom';
    resetZoomButton.appendChild(tooltipText);
    return resetZoomButton;
  };

  /** This function is responsible for setting the boundaries of the map based on the
  min max lat long values of the points present in the result set. 
  */
  const computeMapBoundaries = ({
    map,
    maps
  }: {
    map: google.maps.Map;
    maps: typeof google.maps;
  }) => {
    const bounds = new maps.LatLngBounds();
    const allPoints = [
      ...resultSet.critical_series,
      ...resultSet.high_series,
      ...resultSet.moderate_series,
      ...resultSet.other_series
    ];
    allPoints.forEach((point: Series) => {
      if (point.lat && point.lon) {
        bounds.extend(new maps.LatLng(point.lat, point.lon));
        const margin = 0.05;
        const ne = bounds.getNorthEast();
        const sw = bounds.getSouthWest();
        bounds.extend(new maps.LatLng(ne.lat() + margin, ne.lng() + margin));
        bounds.extend(new maps.LatLng(sw.lat() - margin, sw.lng() - margin));
        map.fitBounds(bounds);
      }
    });
    mapRef.current = map;
    mapsRef.current = maps;
  };

  /**
   * This This function is responsible for creating the custom button when the map gets loaded
   *  Reset button - for setting the zoom to default on load zoom
   */
  const handleMapLoad = ({ map, maps }: { map: google.maps.Map; maps: typeof google.maps }) => {
    // compute the map boundaries and assign values to mapRef and mapsRef
    computeMapBoundaries({ map, maps });

    //place a reset zoom button on the map on load
    const resetZoomButton = createResetZoomButton();
    const zoomDiv = document.createElement('div');
    zoomDiv.appendChild(resetZoomButton);
    map.controls[maps.ControlPosition.TOP_LEFT].push(zoomDiv);
  };

  // The boundaries have to be changed as and when the result set changes
  useEffect(() => {
    if (mapRef && mapRef.current && mapsRef && mapsRef.current) {
      computeMapBoundaries({ map: mapRef.current, maps: mapsRef.current });
    }
  }, [resultSet]);

  return (
    <GoogleMapReact
      bootstrapURLKeys={{
        key: import.meta.env.VITE_GOOGLE_MAP_KEY || ''
      }}
      options={createMapOptions}
      center={getCenter()}
      zoom={1}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={handleMapLoad}>
      {(showTooltip || onTooltip) && tooltipInfo && tooltipInfo.lat && tooltipInfo.lng && (
        <MapTooltip
          lat={tooltipInfo.lat}
          lng={tooltipInfo.lng}
          tooltipInfo={tooltipInfo}
          setOnTooltip={setOnTooltip}
          showTooltip={showTooltip}
          setShowTooltip={setShowTooltip}
          setTooltipInfo={setTooltipInfo}
        />
      )}
      {showCritical &&
        resultSet.critical_series.map((point: Series, index: number) => {
          if (point.lat && point.lon) {
            return (
              <MapMarker
                lat={point.lat}
                lng={point.lon}
                info={resultSet.critical_info[index]}
                severity="ALERT_FATAL"
                color="rgb(206, 0, 0, 0.7)"
                key={index}
                editMode={editMode}
                markerType={markerType}
                setShowTooltip={setShowTooltip}
                setTooltipInfo={setTooltipInfo}
                onTooltip={onTooltip}
              />
            );
          }
        })}
      {showHigh &&
        resultSet.high_series.map((point: Series, index: number) => {
          if (point.lat && point.lon) {
            return (
              <MapMarker
                lat={point.lat}
                lng={point.lon}
                info={resultSet.high_info[index]}
                severity="ALERT_ERROR"
                color="rgb(253, 140, 0, 0.7)"
                key={index}
                editMode={editMode}
                markerType={markerType}
                setShowTooltip={setShowTooltip}
                setTooltipInfo={setTooltipInfo}
                onTooltip={onTooltip}
              />
            );
          }
        })}
      {showModerate &&
        resultSet.moderate_series.map((point: Series, index: number) => {
          if (point.lat && point.lon) {
            return (
              <MapMarker
                lat={point.lat}
                lng={point.lon}
                info={resultSet.moderate_info[index]}
                severity="ALERT_WARNING"
                color="rgb(253, 197, 0, 0.7)"
                key={index}
                editMode={editMode}
                markerType={markerType}
                setShowTooltip={setShowTooltip}
                setTooltipInfo={setTooltipInfo}
                onTooltip={onTooltip}
              />
            );
          }
        })}
      {showOther &&
        resultSet.other_series.map((point: Series, index: number) => {
          if (point.lat && point.lon) {
            return (
              <MapMarker
                lat={point.lat}
                lng={point.lon}
                info={resultSet.other_info[index]}
                severity="ALERT_NONE"
                color="green"
                key={index}
                editMode={editMode}
                markerType={markerType}
                setShowTooltip={setShowTooltip}
                setTooltipInfo={setTooltipInfo}
                onTooltip={onTooltip}
              />
            );
          }
        })}
    </GoogleMapReact>
  );
};
