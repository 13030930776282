import { AnyAction } from 'redux';
import * as ACTION_TYPE from './actions/getAutoNarrowbandList.actionTypes';
import { getAutoNarrowbandListState } from './types/getAutoNarrowbandListState';
import { GET_AUTO_NARROWBAND_LIST_STATUS } from './types/getAutoNarrowbandListStatus';

const initialState: getAutoNarrowbandListState = {
  status: GET_AUTO_NARROWBAND_LIST_STATUS.INIT,
  tableStatus: GET_AUTO_NARROWBAND_LIST_STATUS.TABLE_INIT,
  narrowbandStatus: false,
  narrowbandList: [],
  rawNarrowbands: null
};

function getAutoNarrowbandListStateProgress(
  state: getAutoNarrowbandListState
): getAutoNarrowbandListState {
  return {
    ...state,
    status: GET_AUTO_NARROWBAND_LIST_STATUS.PROGRESS
  };
}

function getAutoNarrowbandListStateSuccess(
  state: getAutoNarrowbandListState,
  action: AnyAction
): getAutoNarrowbandListState {
  const { data, rawNarrowbands } = action;
  return {
    ...state,
    status: GET_AUTO_NARROWBAND_LIST_STATUS.COMPLETED,
    narrowbandList: data,
    rawNarrowbands
  };
}

function getAutoNarrowbandListStateFailure(
  state: getAutoNarrowbandListState
): getAutoNarrowbandListState {
  return {
    ...state,
    status: GET_AUTO_NARROWBAND_LIST_STATUS.ERROR
  };
}

function resetGetAutoNarrowbandListState() {
  return {
    ...initialState
  };
}
function setNarrowbandCreationStatus(
  state: getAutoNarrowbandListState,
  action: AnyAction
): getAutoNarrowbandListState {
  return {
    ...state,
    narrowbandStatus: action.data
  };
}

function getAutoNarrowbandTableListStateProgress(
  state: getAutoNarrowbandListState
): getAutoNarrowbandListState {
  return {
    ...state,
    tableStatus: GET_AUTO_NARROWBAND_LIST_STATUS.TABLE_PROGRESS
  };
}

function getAutoNarrowbandTableListStateSuccess(
  state: getAutoNarrowbandListState,
  action: AnyAction
): getAutoNarrowbandListState {
  const { data, rawNarrowbands } = action;
  return {
    ...state,
    tableStatus: GET_AUTO_NARROWBAND_LIST_STATUS.TABLE_COMPLETED,
    narrowbandList: data,
    rawNarrowbands
  };
}

function getAutoNarrowbandTableListStateFailure(
  state: getAutoNarrowbandListState
): getAutoNarrowbandListState {
  return {
    ...state,
    tableStatus: GET_AUTO_NARROWBAND_LIST_STATUS.TABLE_ERROR
  };
}

function resetGetAutoNarrowbandTableListState(
  state: getAutoNarrowbandListState
): getAutoNarrowbandListState {
  return {
    ...state,
    tableStatus: GET_AUTO_NARROWBAND_LIST_STATUS.TABLE_INIT
  };
}

/**
 *
 * @param state
 * @param action
 */
export default function getAutoNarrowbandListReducer(
  state: getAutoNarrowbandListState = initialState,
  action: AnyAction
): getAutoNarrowbandListState {
  switch (action.type) {
    case ACTION_TYPE.GET_AUTO_NARROWBAND_LIST:
      return getAutoNarrowbandListStateProgress(state);
    case ACTION_TYPE.GET_AUTO_NARROWBAND_LIST_SUCCESS:
      return getAutoNarrowbandListStateSuccess(state, action);
    case ACTION_TYPE.GET_AUTO_NARROWBAND_LIST_FAILURE:
      return getAutoNarrowbandListStateFailure(state);
    case ACTION_TYPE.GET_AUTO_NARROWBAND_LIST_RESET:
      return resetGetAutoNarrowbandListState();
    case ACTION_TYPE.SET_NARROWBAND_STATUS:
      return setNarrowbandCreationStatus(state, action);
    case ACTION_TYPE.GET_AUTO_NARROWBAND_TABLE_LIST:
      return getAutoNarrowbandTableListStateProgress(state);
    case ACTION_TYPE.GET_AUTO_NARROWBAND_TABLE_LIST_SUCCESS:
      return getAutoNarrowbandTableListStateSuccess(state, action);
    case ACTION_TYPE.GET_AUTO_NARROWBAND_TABLE_LIST_FAILURE:
      return getAutoNarrowbandTableListStateFailure(state);
    case ACTION_TYPE.GET_AUTO_NARROWBAND_TABLE_LIST_RESET:
      return resetGetAutoNarrowbandTableListState(state);

    default:
      return state;
  }
}
