/**
 * Reports Reducer
 * @author prerna.more@shorelineiot.com
 */
import { combineReducers } from 'redux';
import externalReport from './external-reports/externalReports.reducer';
import deviceReport from './device-reports/deviceReports.reducer';
import remark from './device-reports/remark/remark.reducer';
import deviceMailbox from './device-mailbox/deviceMailbox.reducer';
import deleteDeviceMailbox from './device-mailbox/delete-device-mailbox/deleteDeviceMailbox.reducer';
/*
 *
 */
const reportReducer = combineReducers({
  externalReport,
  deviceReport,
  remark,
  deviceMailbox,
  deleteDeviceMailbox
});

export default reportReducer;
