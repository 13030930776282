import { IconButton } from '@mui/material';
import React from 'react';
import { EditIcon } from '../../icons';
import { LightTooltip } from '../tooltip/Tooltip';
import { ACTION_ICON_CLASS_NAME } from './actionsIcon.utils';
import { toSnakeCase } from '../../../i18n/translation.helper';

interface ActionsEditIconProp {
  onClick?: React.MouseEventHandler<any> | undefined;
  /**
   * Make this `true` if you want IconButton & want to disable
   * the button conditionally.
   * @default false
   */
  isButton?: boolean | undefined;
  /**
   * Make this `true` if you want to disable the button.
   * @default false
   */
  disabled?: boolean | undefined;
  /**
   * Custom tooltip title.
   * @default 'Delete'
   */
  tooltipTitle?: string | undefined;
  /**
   * Make this `true` if you don't want to show the tooltip
   * & only want to show the icon.
   * @default false
   */
  noTooltip?: boolean | undefined;
  iconButtonClassName?: any;
  iconFillOpacity?: string | number | undefined;
}

const ActionsEditIcon = ({
  onClick,
  isButton = true,
  disabled,
  tooltipTitle,
  noTooltip,
  iconButtonClassName,
  iconFillOpacity
}: ActionsEditIconProp) => {
  if (noTooltip) {
    <EditIcon color="black" className={ACTION_ICON_CLASS_NAME} onClick={onClick} />;
  }

  if (isButton) {
    return (
      <LightTooltip
        title={tooltipTitle || 'Edit'}
        placement="bottom-end"
        translationId={toSnakeCase(tooltipTitle) || 'edit'}>
        <IconButton
          aria-label="edit"
          className={iconButtonClassName}
          size="small"
          disabled={disabled}
          onClick={onClick}>
          <EditIcon
            color="black"
            className={ACTION_ICON_CLASS_NAME}
            fillOpacity={disabled ? '0.3' : ''}
          />
        </IconButton>
      </LightTooltip>
    );
  }

  return (
    <LightTooltip
      title={tooltipTitle || 'Edit'}
      placement="bottom-end"
      translationId={toSnakeCase(tooltipTitle) || 'edit'}>
      <EditIcon
        color="black"
        className={ACTION_ICON_CLASS_NAME}
        onClick={onClick}
        fillOpacity={iconFillOpacity}
      />
    </LightTooltip>
  );
};

export default ActionsEditIcon;
