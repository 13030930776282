import { put, takeEvery, all } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import { VA_ACTIONS, VAactions } from '../index';

import { httpGet, SafeSaga, APISERVICES } from '../../../../../framework';
import { SagaIterator } from 'redux-saga';
import { DiagnosisType } from './vaEvents.stateType';
function getEventDiagnosis(data: { slug: string | undefined; deviceId: number; eventId: number }) {
  return httpGet(
    `orgs/${data.slug}/devices/${data.deviceId}/diagnosis?event_id=${data.eventId}`,
    null,
    APISERVICES.DEVICE_API
  );
}

function* getOnlyDataPointsSaga(action: AnyAction): any {
  const diagnosisResponse: DiagnosisType = yield getEventDiagnosis(action.payload);

  yield put(VAactions.getEventDiagnosisSuccess(diagnosisResponse));
}

export function* watchGetEventDiagnosisSagas(): SagaIterator {
  yield all([
    takeEvery(
      VA_ACTIONS.FETCH_EVENT_DIAGNOSIS,
      SafeSaga(getOnlyDataPointsSaga, VA_ACTIONS.FETCH_EVENT_DIAGNOSIS)
    )
  ]);
}
