/**
 * Verification Style
 * @author mahesh.kedari@shorelineiot.com
 * updated by Swapnil
 */
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flex: 1,
    maxWidth: '54%',
    margin: ' 0 auto',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  labelNormal: {
    height: '40px'
  },
  labelSmall: {
    height: '20px'
  },
  centerButton: {
    display: 'flex',
    width: '150px',
    margin: '28px auto'
  },
  verificationIcon: {
    height: '40%',
    width: '40%'
  },
  formStyle: {
    marginTop: '-18px',
    marginBottom: '30px'
  },
  boxStyle: {
    marginTop: '5%'
  }
}));
