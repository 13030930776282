/**
 * External Reports Actions
 * @author prerna.more@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTIONS_TYPES from './externalReports.actionTypes';

export const fetchExternalReports = (data: any): AnyAction => {
  return {
    type: ACTIONS_TYPES.FETCH_EXTERNAL_REPORTS,
    payload: data
  };
};

export const fetchExternalReportsSuccess = (data: any): AnyAction => {
  return {
    type: ACTIONS_TYPES.FETCH_EXTERNAL_REPORTS_SUCCESS,
    payload: data
  };
};

export const fetchExternalReportsFailure = (): AnyAction => {
  return {
    type: ACTIONS_TYPES.FETCH_EXTERNAL_REPORTS_FAILURE
  };
};

export const fetchExternalReportsReset = (): AnyAction => {
  return {
    type: ACTIONS_TYPES.FETCH_EXTERNAL_REPORTS
  };
};
