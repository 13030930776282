/**
 * Banner Component
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { memo } from 'react';
import SLBanner from '../../framework/components/banner/SLBanner';
import { useBanner } from '../../framework/components/banner';

interface Props {
  alertClassName?: string;
  messageClassName?: string;
}
export default memo(function Banner({ alertClassName, messageClassName }: Props) {
  const banner = useBanner();
  if (!banner) {
    return null;
  }
  const { message, ...otherProps } = banner;
  return (
    <SLBanner
      alertClassName={alertClassName}
      messageClassName={messageClassName}
      message={message}
      {...otherProps}
    />
  );
});
