/**
 * success and failure Helper function
 * @author mahesh.kedari@shorelineiot.com
 */

import { LoginStateType } from '../types/LoginStateType';
/**
 *
 */
export function onSuccessHandler(state: LoginStateType): LoginStateType {
  return {
    ...state,
    status: 'LOGGED_IN'
  };
}
/**
 *
 */
export function onFailureHandler(state: LoginStateType): LoginStateType {
  return {
    ...state,
    status: 'ERROR'
  };
}
