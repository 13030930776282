/**
 * Live notifications
 * @author priyanka.ambawane@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTION_TYPE from '../actions/notifications.actionTypes';

export enum SET_LAST_NOTIFICATIONS_STATUS {
  INIT,
  FETCHING,
  READY,
  ERROR
}
interface SetLastNotification {
  status: SET_LAST_NOTIFICATIONS_STATUS;
  response: any;
}

const initialState: SetLastNotification = {
  status: SET_LAST_NOTIFICATIONS_STATUS.INIT,
  response: []
};

function setLastNotificationSuccess(
  state: SetLastNotification,
  response: any
): SetLastNotification {
  if (state.response.length === 0) {
    return {
      ...state,
      status: SET_LAST_NOTIFICATIONS_STATUS.READY,
      response
    };
  }
  return {
    ...state,
    status: SET_LAST_NOTIFICATIONS_STATUS.READY,
    response: state.response.concat(response)
  };
}

/*
    Reducer is a pure function that takes actions and state as input 
    and returns/passes the modified state to the store.
    It is implemented using switch case to update the state according 
    to the ation type required.
*/

export default function setLastNotification(
  state: SetLastNotification = initialState,
  action: AnyAction
): SetLastNotification {
  switch (action.type) {
    case ACTION_TYPE.SET_LAST_NOTIFICATION:
      return setLastNotificationSuccess(state, action.payload);
    default:
      return state;
  }
}
