import React from 'react';
import { SLCard, SLTable } from '../../../../framework';
import { LeakHistoricalAlarmColumns } from '../../../../features/reports/leak-detection/historical-alarms/LeakHistoricalAlarmColumns';
import { ChartAndHistoricalTableProps } from './LeakDetectionViewer';
import { MethaneEmissionAlarm } from '../../../../features/reports/leak-detection/utils/LeakDetectionAlarmType';

export default function LeakDetectionHistoricalAlarms({
  alarms,
  isFetchingAlarms,
  airborneData,
  isAirborneDataLoading
}: ChartAndHistoricalTableProps) {
  const columns = React.useMemo(() => LeakHistoricalAlarmColumns, []);

  let historicalAlarms = [];

  if (alarms?.length > 0 && !isAirborneDataLoading) {
    // Aggregate alarms by name and store count of occurrances, total_duration, average_duration
    const groupedAlarms = new Map<string, any>();
    alarms.forEach((alarm: MethaneEmissionAlarm) => {
      const deviceIds = alarm.device_ids;
      let duration = 0;
      deviceIds.forEach((deviceId) => {
        if (deviceId in airborneData) {
          duration += airborneData[deviceId].length;
        }
      });
      const duration_in_hrs = duration * 0.25;
      if (groupedAlarms) {
        if (groupedAlarms.has(alarm.name)) {
          groupedAlarms.get(alarm.name).occurrence_count += 1;
        } else {
          groupedAlarms.set(alarm.name, {
            name: alarm.name,
            occurrence_count: 1,
            total_duration: duration_in_hrs
          });
        }
      }
    });
    historicalAlarms = Array.from(groupedAlarms.values());
  } else {
    historicalAlarms = [];
  }

  return (
    <SLCard title="Historical Alarms" action={<></>}>
      <SLTable
        feature="leak-detection-historical-alarms"
        data={historicalAlarms}
        columns={columns}
        enableBottomToolbar
        enablePagination
        showSkeletons={isFetchingAlarms || isAirborneDataLoading}
        noRecordsToDisplay={'No Alarms for selected Range'}
        maxBodyHeight={'calc(45vh - 100px)'}
        getRowId={(originalRow) => originalRow?.alarm_id}
      />
    </SLCard>
  );
}
