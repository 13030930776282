/**
 * Table Column Formatter
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { ReactElement } from 'react';
import Add from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import Remove from '@mui/icons-material/Remove';
import Grid from '@mui/material/Grid';
import { useFieldArray, useFormContext } from 'react-hook-form';
import makeStyles from '@mui/styles/makeStyles';
import SLDivider from '../../../../../divider/SLDivider';
import SLSelect from '../../../../../form/select/SLSelect';
import { formatterOptions } from './formatterOptions';
import { formatterValidation } from './formatter.validation';
import FormatterValueObject from './FormatterValueObject';

interface Props {
  prefix: string;
  handleUpdate: () => void;
}
const useStlyes = makeStyles(() => ({
  removeButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end'
  }
}));

export default function TableColumnFormatter({ prefix, handleUpdate }: Props): ReactElement {
  const classes = useStlyes();
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `${prefix}.formatter`
  });
  const handleAddFormatter = () => {
    append({
      key: Math.random(),
      value: ' ',
      type: 'color'
    });
  };
  const removeFormatter = (index: number) => {
    remove(index);
  };
  return (
    <>
      <SLDivider textAlign="left">Formatter</SLDivider>
      {fields.map((field: any, index: number) => (
        <Grid key={index} item xs={12} container>
          <Grid item xs={11} container>
            <Grid item xs={4}>
              <SLSelect
                name={`${prefix}.formatter.${index}.type`}
                label="Type"
                options={formatterOptions}
                rules={formatterValidation.type}
                onSelectChange={handleUpdate}
              />
            </Grid>
            <Grid item xs={8}>
              <FormatterValueObject
                prefix={`${prefix}.formatter.${index}`}
                handleUpdate={handleUpdate}
              />
            </Grid>
          </Grid>
          <Grid item xs={1} className={classes.removeButtonContainer}>
            <IconButton onClick={() => removeFormatter(index)} size="small">
              <Remove />
            </IconButton>
          </Grid>
        </Grid>
      ))}
      <IconButton onClick={handleAddFormatter} size="large">
        <Add />
      </IconButton>
    </>
  );
}
