import moment from 'moment';

/**
 * Date Formatter
 * @author mahesh.kedari@shorelineiot.com
 *
 */

export function sortYear(dt: any) {
  return `${dt.getFullYear()}`.substr(2);
}
export function formattedDate(dateObj: Date) {
  const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  return `${months[dateObj.getMonth()]}/${dateObj.getDate()}`;
}

export const xAxisFormatter: any = (axisObject: any) => {
  const updatedValue = axisObject.value / 1000;
  const dateObj = new Date(updatedValue);
  const range = axisObject.axis.max - axisObject.axis.min;
  if (range > 86400000000) {
    return formattedDate(dateObj);
  }
  if (range > 10000) {
    return dateObj.toLocaleTimeString();
  }
  const mil = axisObject.value % 1000;
  return `${dateObj.toLocaleTimeString()}-${mil}`;
};

export const chartXAxisFormatter: any = (axisObject: any) => {
  const updatedValue = axisObject.value;
  const date = Math.ceil(Number(updatedValue) / 1000000);
  const dateTime = moment.unix(date).format('MM/DD/YY  HH:mm:ss ');
  return dateTime;
};

export const chartXAxisLabelFormatter = {
  style: {
    whiteSpace: 'normal',
    wordWrap: 'break-word'
  },
  useHTML: true
};
