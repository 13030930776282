/**
 * Dialog Styles
 * @author mahesh.kedari@shorelineiot.com
 */
import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  dialogRoot: {
    minWidth: '25%'
  },
  title: {
    padding: 0
  },
  paper: {
    padding: 16,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    color: theme.palette.background.default,
    backgroundColor: theme.palette.primary.main
  }
}));
