/**
 * Roles List Status
 * @author mahesh.kedari@shorelineiot.com
 */
export enum ROLES_LIST_STATUS {
  INIT,
  FETCHING_ROLES,
  READY,
  ERROR,
  RESET
}
