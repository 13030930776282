/**
 * Create NB Rules Actions
 * @author priyanka.ambawane@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTIONS from './nbRuleCreate.actionTypes';
/**
 *
 * @param data
 */
export const createNBRule = (slug: any, data: any): AnyAction => {
  // returns the action type
  return {
    type: ACTIONS.CREATE_NB_RULES,
    payload: {
      slug,
      data
    }
  };
};
/**
 *
 * @param data
 */
export const createNBRuleSuccess = (data: any): AnyAction => {
  return {
    type: ACTIONS.CREATE_NB_RULES_SUCCESS,
    payload: data
  };
};
/**
 *
 */
export const createNBRuleFailure = (error: any): AnyAction => {
  // returns the action type
  return {
    type: ACTIONS.CREATE_NB_RULES_FAILURE,
    error
  };
};

export const resetCreateNBRuleState = (): AnyAction => {
  // returns the action type
  return {
    type: ACTIONS.RESET_CREATE_NB_RULES_STATE
  };
};
