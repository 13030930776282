/**
 * Sync Extremes
 * @author mahesh.kedari@shorelineiot.com
 * @param event
 * @param references
 * @param index
 */
export const syncExtremes = (event: any, references: Array<any>, index: number) => {
  references.forEach((currentRef: any, refIndex: number) => {
    if (refIndex !== index) {
      const chart = currentRef?.current?.chart;
      if (chart?.xAxis[0]?.setExtremes) {
        // It is null while updating
        chart.xAxis[0].setExtremes(event.min, event.max, true, true, {
          trigger: 'syncExtremes'
        });
      }
    }
  });
};
