/**
 * Draggable Item from Pivot configuration
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { ReactElement } from 'react';
import { Theme, Typography } from '@mui/material';
import DragIndicator from '@mui/icons-material/DragIndicatorOutlined';
import { makeStyles } from '@mui/styles';

import { Draggable } from 'react-beautiful-dnd';
import { LightTooltip } from '../../tooltip/Tooltip';

const useStyles = makeStyles((theme: Theme) => ({
  draggableObject: {
    border: `1px dashed`,
    borderRadius: 4,
    padding: '5px',
    lineHeight: '22px',
    overflow: 'hidden',
    borderColor: theme.palette.grey[300],
    display: 'inline-flex'
  },
  draggableText: {
    maxWidth: '100%',
    marginRight: '8px'
  }
}));
interface Props {
  id: any;
  index: number;
}
export default function DraggableAxisItem({ id, index }: Props): ReactElement<Props> {
  const classes = useStyles();
  return (
    <Draggable draggableId={id} index={index}>
      {({ draggableProps, dragHandleProps, innerRef }) => (
        <div
          ref={innerRef}
          {...draggableProps}
          {...dragHandleProps}
          className={classes.draggableObject}>
          <DragIndicator fontSize="small" />
          <LightTooltip title={id} placement="bottom-end">
            <Typography className={classes.draggableText}>{id}</Typography>
          </LightTooltip>
        </div>
      )}
    </Draggable>
  );
}
