import { IndicatorsOptionTypes, TimeChartOptionsType } from './store';

const PEAK_COUNT = 'peakCount';
const FFT_FMIN_ZOOM_HZ = 'fftFMinZoomHz';
const FFT_FMAX_ZOOM_HZ = 'fftFMaxZoomHz';
const TIME_MIN_ZOOM = 'timeMinZoom';
const TIME_MAX_ZOOM = 'timeMaxZoom';

export const PEAK_CONSTANT: { [key: string]: string } = {
  PEAK_COUNT
};
export const FFT_CONSTANT: { [key: string]: string } = {
  FFT_FMIN_ZOOM_HZ,
  FFT_FMAX_ZOOM_HZ,
  TIME_MIN_ZOOM,
  TIME_MAX_ZOOM
};

export const INDICATORSOPTIONS: Array<IndicatorsOptionTypes> = [
  //DO not change sequence
  'Indicators',
  'Peak Search',
  'Harmonics',
  'SideBand'
];
export const TVE_TYPE = 'tve';

export const TIME_CHART_OPTIONS_TYPE: Array<TimeChartOptionsType> = [
  'None',
  'Delta Markers',
  'Sliding Window'
];
export const TOGGLE_ID = 'toggle';

export const TOGGLE_LEGEND = {
  id: TOGGLE_ID,
  name: 'Show All / Hide All',
  showInLegend: true
};
