import { PaletteOptions } from '@mui/material/styles';
import { Colors } from '../../../constants';

export const Palette: PaletteOptions = {
  primary: {
    main: Colors.primaryVariant,
    light: Colors.sidebarMenuItemSelectedBackground
  },
  secondary: {
    main: Colors.secondary
  },
  success: {
    main: Colors.success
  },
  warning: {
    main: Colors.warning
  },
  error: {
    main: Colors.error
  },
  info: {
    main: Colors.primary
  },
  background: {
    paper: Colors.backgroundSecondary,
    default: Colors.background
  },
  text: {
    primary: Colors.primaryText,
    secondary: Colors.secondaryText
  },
  grey: {
    '50': Colors.iconGrey
  }
};
