/**
 * export Device Data Saga
 * @author vinayak.rathod@shorelineiot.com
 */
import { AnyAction } from 'redux';
import { takeEvery, call, put } from 'redux-saga/effects';
import * as actionTypes from './exportDeviceData.actionTypes';
import * as actions from './exportDeviceData.actions';
import {
  GenericObject,
  httpGet,
  removeProgressFor,
  showProgressFor,
  showToast,
  APISERVICES
} from '../../../../../framework';

/**
 * get export device data
 */
const EXPORT_DATA_PROCESS_ID = 'EXPORT-DEVICE-DATA';
function getExportDeviceData(data: any) {
  let apiEndPoint: string;

  if (data.dpids && data.dpids.length > 0) {
    apiEndPoint = `orgs/${data.slug}/devices/${data.device_id}/export?start=${data.start}&end=${data.start}&timezone=${data.timezone}&dpids=${data.dpids}`;
  } else {
    apiEndPoint = `orgs/${data.slug}/devices/${data.device_id}/export`;
  }

  if (data.dateOrEvent === 'event') {
    apiEndPoint = `orgs/${data.slug}/devices/${data.device_id}/events/export`;
    if (data.email === '') {
      return httpGet(
        apiEndPoint,
        {
          start: data.start,
          end: data.end,
          event_ids: data.selectedEventList,
          timezone: data.timezone
        },
        APISERVICES.DEVICE_API
      );
    }
    return httpGet(
      apiEndPoint,
      {
        start: data.start,
        end: data.end,
        event_ids: data.selectedEventList,
        email: data.email,
        timezone: data.timezone
      },
      APISERVICES.DEVICE_API
    );
  }

  if (data.email === '') {
    return httpGet(
      apiEndPoint,
      {
        start: data.start,
        end: data.end,
        timezone: data.timezone
      },
      APISERVICES.DEVICE_API
    );
  }
  return httpGet(
    apiEndPoint,
    {
      start: data.start,
      end: data.end,
      email: data.email,
      timezone: data.timezone
    },
    APISERVICES.DEVICE_API
  );
}

export function* getExoportDeviceDataSaga(action: AnyAction) {
  try {
    const {
      // handleDateTimeChange,
      setSelectedState
      // setDateOrEvent,
    } = action.payload;

    yield put(showProgressFor(EXPORT_DATA_PROCESS_ID));
    const resp: GenericObject = yield call(getExportDeviceData, action.payload);
    if (action.payload.setting !== 'email') {
      // yield put(actions.fetchExportDeviceDataSuccess(resp.download_link));
      // MQTT related change
      yield put(
        actions.fetchExportDeviceDataSuccess({
          response: resp,
          deviceId: action.payload.device_id,
          selectedOperation: action.payload.setting
        })
      );
      yield put(showToast('Your export will be downloaded shortly', 'success'));
    } else {
      yield put(
        actions.fetchExportDeviceDataSuccess({
          response: resp,
          deviceId: action.payload.device_id,
          selectedOperation: action.payload.setting
        })
      );
      yield put(showToast('Your export will be emailed to you shortly', 'success'));
    }
    // handleDateTimeChange(moment().subtract(1, "days"), moment());
    setSelectedState(false);
    // setDateOrEvent("date");
  } catch (error: any) {
    // if (error.message === 'Request failed with status code 400') {
    //   yield put(showToast('End date must be greater than start date', 'error'));
    // } else {
    //   yield put(showToast(error.message, 'error'));
    // }
    yield put(actions.fetchExportDeviceDataFailure(error));
    yield put(showToast(error.message, 'error'));
  } finally {
    yield put(removeProgressFor(EXPORT_DATA_PROCESS_ID));
  }
}

const FETCH_EVENTS_DATA_PROCESS_ID = 'FETCH-EVENTS-DATA';
function getEventsData(data: any) {
  if (data.vibrationAnalysis) {
    return httpGet(
      `orgs/${data.slug}/devices/${data.device_id}/events`,
      {
        timezone: data.timezone,
        dpids: data.dpids || []
      },
      APISERVICES.DEVICE_API
    );
  }
  return httpGet(
    `orgs/${data.slug}/devices/${data.device_id}/events`,
    {
      start: data.start,
      end: data.end,
      timezone: data.timezone
    },
    APISERVICES.DEVICE_API
  );
}

export function* getEventsDataSaga(action: AnyAction) {
  try {
    yield put(showProgressFor(FETCH_EVENTS_DATA_PROCESS_ID));
    const result: GenericObject = yield call(getEventsData, action.payload);

    yield put(actions.handleFetchEventsSuccess(result));
  } catch (error: any) {
    yield put(actions.handleFetchEventsFailure(error));
  } finally {
    yield put(removeProgressFor(FETCH_EVENTS_DATA_PROCESS_ID));
  }
}

/**
 *
 * @param data
 */
function getUserList(data: any) {
  return httpGet(`orgs/${data}/users`, null, APISERVICES.HOST_API);
}

/**
 *
 * @param action
 */
const FETCH_USERS_FOR_EXPORT_DATA = 'FETCH-USERS-FOR-EXPORT-DATA';
function* usersList(action: AnyAction) {
  yield put(showProgressFor(FETCH_USERS_FOR_EXPORT_DATA));
  try {
    const response: GenericObject = yield call(getUserList, action.payload);
    yield put(actions.usersListSucess(response.results));
  } catch (error: any) {
    yield put(showToast('Failed to fetch users list', 'error', true));
    yield put(actions.usersListFailure());
  } finally {
    yield put(removeProgressFor(FETCH_USERS_FOR_EXPORT_DATA));
  }
}

export function* getExportDeviceDataWatchingSagas() {
  yield takeEvery(actionTypes.FETCH_EXPORT_DEVICE_DATA_PROGRESS, getExoportDeviceDataSaga);

  yield takeEvery(actionTypes.FETCH_EVENTS_PROGRESS, getEventsDataSaga);

  yield takeEvery(actionTypes.FETCH_USER_LIST_PROGRESS, usersList);
}
