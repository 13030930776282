/**
 * Device Actions
 * @author mahesh.kedari@shorelineiot.com
 * Updated by Swapnil
 */
import { AnyAction } from 'redux';
import * as DEVICE_LIST_ACTION_TYPES from './deviceList.actionTypes';

export function addDeviceListWithDatapoints(data: any): AnyAction {
  return {
    type: DEVICE_LIST_ACTION_TYPES.UPDATE_DEVICE_LIST_WITH_DATAPOINTS,
    payload: data
  };
}

export function updateMultipleDeviceWithDatapoints(data: any): AnyAction {
  return {
    type: DEVICE_LIST_ACTION_TYPES.UPDATE_MULTIPLE_DEVICES_WITH_DATAPOINTS,
    payload: data
  };
}

export function handleAddDeviceSuccess(data: any): AnyAction {
  return {
    type: DEVICE_LIST_ACTION_TYPES.ADD_DEVICE_SUCCESS,
    payload: data
  };
}
