/**
 * Create Stackchart Series
 * @author mahesh.kedari@shorelineiot.com
 */
import { merge } from 'lodash';
import moment from 'moment';
import { TooltipFormatterContextObject } from 'highcharts';
import { multilineChartConfig as defaultSettings } from './stacklineChartSettings';
import { timeAxisFormatter } from '../utils/timeAxisFormatter';
import { microsecondsTimeFormatter } from '../utils/microsecondsTimeFormatter';
import { chartXAxisLabelFormatter } from '../../../helpers';

export const createAnalyticsStackchartSeries = (
  key: string,
  dataArray: Array<any>,
  seriesCount: number
) => {
  return merge({}, defaultSettings(seriesCount, key), {
    xAxis: {
      labels: {
        formatter: timeAxisFormatter
      }
    },
    series: [
      {
        name: key,
        type: 'line',
        data: dataArray
      }
    ]
  });
};

export const createRawDataStackchartSeries = (
  key: string,
  dataArray: Array<any>,
  seriesCount: number,
  datapoint?: any
): Highcharts.Options => {
  return merge({}, defaultSettings(seriesCount, key), {
    xAxis: {
      labels: {
        formatter: microsecondsTimeFormatter,
        ...chartXAxisLabelFormatter
      }
    },
    yAxis: [
      {
        title: datapoint?.unit || 'value'
      }
    ],
    tooltip: {
      formatter(this: TooltipFormatterContextObject) {
        if (this) {
          const { x, y } = this.point;
          const date = Math.ceil(Number(x) / 1000000);
          const dateTime = moment.unix(date).format('MM/DD/YY | HH:mm:ss ');
          return `${y?.toFixed(4)} ${datapoint?.unit} <br />
          ${dateTime}`;
        }
        return '';
      },
      style: {
        color: 'white'
      },
      backgroundColor: '#4d4d4d'
    },
    series: [
      {
        name: key,
        type: 'line',
        data: dataArray
      }
    ]
  });
};
