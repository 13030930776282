/**
 * Update Dashboard  Saga
 */

import { put, takeEvery, call } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import * as UPDATE_DASHBOARD_ACTION_TYPES from './updateDashboard.actionsTypes';
import * as UPDATE_DASHBOARD_ACTIONS from './updateDashboard.action';
import { GenericObject, httpPatch, removeProgressFor, APISERVICES } from '../../../../framework';
import { SagaIterator } from 'redux-saga';

function updateDashboardIntoDatabase(data: any) {
  return httpPatch(
    `orgs/${data.slug}/dashboard/${data.dashboard_uuid}`,
    {
      name: data.name,
      is_default: data.is_default,
      dashboard_type: data.dashboard_type,
      meta_data: data.meta_data
    },
    APISERVICES.HOST_API
  );
}

export function* updateDashboard(action: AnyAction) {
  try {
    const response: GenericObject = yield call(updateDashboardIntoDatabase, action.payload);
    yield put(UPDATE_DASHBOARD_ACTIONS.handleUpdateDashboardSuccess(response));
  } catch (error: any) {
    yield put(UPDATE_DASHBOARD_ACTIONS.handleUpdateDashboardFailure(error));
  } finally {
    yield put(removeProgressFor('UPDATE_DASHBOARD'));
  }
}

export function* watchUpdateDashboardSaga(): SagaIterator {
  yield takeEvery(UPDATE_DASHBOARD_ACTION_TYPES.UPDATE_DASHBOARD_PROGRESS, updateDashboard);
}
