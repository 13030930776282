/**
 * Alarms Export Saga
 * @author prerna.more@shorelineiot.com
 */
import { put, takeEvery, call } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import * as actions from './alarmsExport.actions';
import * as ACTION_TYPES from './alarmsExport.actionTypes';
import { GenericObject, httpGet, SafeSaga, showToast, APISERVICES } from '../../../../framework';
import { SagaIterator } from 'redux-saga';

function getExportList(data: any) {
  if (data.setting === 'download') {
    return httpGet(
      `orgs/${data.slug}/device_alarms/export?start=${data.start}&end=${data.end}&timezone=${data.timezone}&alarm_type=${data.alarmType}`,
      null,
      APISERVICES.DEVICE_API
    );
  }
  if (data.setting === 'email') {
    return httpGet(
      `orgs/${data.slug}/device_alarms/export?start=${data.start}&end=${data.end}&email=${data.email}&timezone=${data.timezone}&alarm_type=${data.alarmType}`,
      null,
      APISERVICES.DEVICE_API
    );
  }

  return null;
}

function* alarmsExportSaga(action: AnyAction) {
  const response: GenericObject = yield call(getExportList, action.payload);
  yield put(actions.fetchAlarmsExportSuccess(response.message));
  if (action.payload.setting === 'download') {
    yield put(showToast('Your export will be downloaded shortly', 'success'));
  } else {
    yield put(showToast('Your export will be emailed to you shortly', 'success'));
  }
}

function* errorHandler() {
  yield put(actions.fetchAlarmsExportFailure());
}

export function* watchAlarmsExportSagas(): SagaIterator {
  yield takeEvery(
    ACTION_TYPES.FETCH_ALARMS_EXPORT_LIST,
    SafeSaga(alarmsExportSaga, ACTION_TYPES.FETCH_ALARMS_EXPORT_LIST, errorHandler)
  );
}
