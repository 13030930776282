import { AnyAction } from 'redux';
import * as ACTION_TYPE from '../actions/rolesUpdate.actionTypes';
import { RolesUpdateState } from '../types/RolesUpdate.State';
import { ROLES_UPDATE_STATUS } from '../types/RolesUpdate.Status';

const initialState: RolesUpdateState = {
  status: ROLES_UPDATE_STATUS.INIT,
  RolesUpdateData: []
};

function rolesUpdateProgress(state: RolesUpdateState): RolesUpdateState {
  return {
    ...state,
    status: ROLES_UPDATE_STATUS.INIT
  };
}

function rolesUpdateSuccess(state: RolesUpdateState, RolesUpdateData: any): RolesUpdateState {
  return {
    ...state,
    status: ROLES_UPDATE_STATUS.READY,
    RolesUpdateData
  };
}

function rolesUpdateFailure(state: RolesUpdateState): RolesUpdateState {
  return {
    ...state,
    status: ROLES_UPDATE_STATUS.ERROR
  };
}

export default function rolesUpdate(
  state: RolesUpdateState = initialState,
  action: AnyAction
): RolesUpdateState {
  switch (action.type) {
    case ACTION_TYPE.UPDATE_ROLE_INIT:
      return rolesUpdateProgress(state);
    case ACTION_TYPE.UPDATE_ROLE_SUCCESS:
      return rolesUpdateSuccess(state, action.payload);
    case ACTION_TYPE.UPDATE_ROLE_FAILURE:
      return rolesUpdateFailure(state);
    default:
      return state;
  }
}
