/**
 * Anomaly Expression Eval Saga
 * @author meghneel.adke@shorelineiot.com
 */
import { takeEvery, call, put } from 'redux-saga/effects';
import { AnyAction } from 'redux';

import * as actions from './expr-eval.action';
import * as ACTIONS from './expr-eval.actionTypes';
import { GenericObject, httpPost, SafeSaga, APISERVICES } from '../../../../../framework';
import { SagaIterator } from 'redux-saga';

function fetchAnomalyExprEvalResult(payload: any) {
  return httpPost(`eval_expr`, payload, APISERVICES.EVAL_EXPR_API);
}
/**
 *
 */
function* getAnomalyExpressionEvalResult(action: AnyAction) {
  try {
    const response: GenericObject = yield call(fetchAnomalyExprEvalResult, action.payload);

    yield put(actions.getExpressionEvalResultSuccess(response));
  } catch (error: any) {
    yield put(actions.getExpressionEvalResultFailure(error));
  }
}
/**
 *
 */
export function* watchAnomalyExpressionEvalResultSaga(): SagaIterator {
  yield takeEvery(
    ACTIONS.ANOMALY_EXPR_EVAL_IN_PROGRESS,
    SafeSaga(getAnomalyExpressionEvalResult, ACTIONS.ANOMALY_EXPR_EVAL_IN_PROGRESS)
  );
}
