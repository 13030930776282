export enum deleteDeviceMailboxStatus {
  INIT,
  PROGRESS,
  SUCCESS,
  ERROR
}

export interface deleteDeviceMailboxState {
  status: deleteDeviceMailboxStatus;
}
