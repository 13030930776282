/**
 * Get topic Saga
 * @author mahesh.kedari@shorelineiot.com
 */
import { AnyAction } from 'redux';
import { takeEvery, call, put } from 'redux-saga/effects';
import {
  fetchHistoricalDataSuccess,
  fetchHistoricalDataFailure
} from '../helper/historical_data/historicalData.actions';
import { FETCH_HISTORICAL_DATA } from '../helper/historical_data/historicalData.actionTypes';
import {
  GenericObject,
  httpGet,
  removeProgressFor,
  showProgressFor,
  APISERVICES
} from '../../../../../../../framework';
import { SagaIterator } from 'redux-saga';
/**
 * get topics for each sensor
 */
function getData(data: any) {
  return httpGet(
    `orgs/${data.slug}/devices/${data.deviceId}/datapoints/historical_data_without_sampling`,
    {
      maxPoints: data.maxPoints,
      dpids: data.dpids
    },
    APISERVICES.DEVICE_API
  );
}
/**
 *
 * @param action
 */
function* getDataSaga(action: AnyAction) {
  const criteria = action.payload;
  yield put(showProgressFor(`HISTORICAL`));
  try {
    const resp: GenericObject = yield call(getData, criteria);
    yield put(fetchHistoricalDataSuccess(resp));
  } catch (error: any) {
    yield put(fetchHistoricalDataFailure(error));
  } finally {
    yield put(removeProgressFor(`HISTORICAL`));
  }
}
/**
 * Watcher saga for Fetch Topic Request
 */
export function* watchHistoricalDataSaga(): SagaIterator {
  yield takeEvery(FETCH_HISTORICAL_DATA, getDataSaga);
}
