import { useLazyFetchIdProviderExistsQuery } from './idProvider.api';

export const useLazyFetchIdProviderExists = () => {
  const [fetchIdProviderExists, { data, isSuccess, isError }] = useLazyFetchIdProviderExistsQuery();
  return {
    fetchIdProviderExists,
    idProviderExists: data,
    idProviderExistsSuccess: isSuccess,
    idProviderExistsError: isError
  };
};
