/**
 * theme list
 * @author ashwini.gawade@shorelineiot.com
 */
export const themesMenu: any = [
  { value: 'custom', label: 'Custom' },
  { value: 'default', label: 'Default' }
];

export const appSettingLocalStorage = 'appSettingLocalStorage';
export const themeChanged = 'themeChanged';
