/**
 * Data Specific Pie Chart Editor
 * @author aditya.bhadange@shorelineiot.com
 */
import { isEqual } from 'lodash';
import React, { ReactElement } from 'react';
import { DASHBOARD_ITEM_TYPE } from '../../../dashboard-widgets/types/DashboardItemType';
import AnalyticsPieChartEditor from './AnalyticsPieChartEditor';

interface Props {
  dataConfig: any;
  chartSettings: any;
  widgetType: DASHBOARD_ITEM_TYPE;
  handleUpdate: (data: any) => void;
}
const RawDataWidgetEditor = () => {
  return null;
};

export default React.memo(
  function DataSpecificPieChartEditor({
    dataConfig,
    chartSettings,
    widgetType,
    handleUpdate
  }: Props): ReactElement {
    return (
      <>
        {widgetType === DASHBOARD_ITEM_TYPE.ANALYTICS && (
          <AnalyticsPieChartEditor
            query={dataConfig?.query}
            chartSettings={chartSettings}
            handleUpdate={handleUpdate}
          />
        )}
        {widgetType === DASHBOARD_ITEM_TYPE.RAW_DATA && <RawDataWidgetEditor />}
      </>
    );
  },
  (prevProps, nextProps) => isEqual(prevProps?.chartSettings, nextProps?.chartSettings)
);
