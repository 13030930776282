import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  bubble: {
    cursor: 'pointer',
    borderRadius: '50%',
    transform: 'translate(-50%, -50%)',
    border: 'solid thin white',
    boxShadow: '-1px 1px 4px rgba(0, 0, 0, 0.5)'
  },
  marker: {
    width: '20px',
    height: '20px',
    padding: '5px',
    border: 'solid thin white',
    boxShadow: '-1px 1px 4px rgba(0, 0, 0, 0.5)',
    borderRadius: '50% 50% 50% 0',
    transform: 'translate(-50%, -50%) rotateZ(-45deg)'
  },
  legendItem: {
    display: 'flex',
    cursor: 'pointer',
    borderRadius: '50%',
    height: '18px',
    width: '18px',
    marginRight: '0.2em'
  },
  legendText: {
    color: 'black'
  },
  legendTextHidden: {
    color: 'gray',
    '&:hover': {
      color: 'black'
    }
  }
}));
