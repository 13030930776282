/**
 * Device Mailbox Reducer
 * @author prerna.more@shorelineiot.com
 */

import { AnyAction } from 'redux';
import * as ACTION_TYPE from './deviceMailbox.actionType';
import { DeviceMailboxState, DEVICE_MAILBOX_STATUS } from './deviceMailbox.state';

const initialState: DeviceMailboxState = {
  status: DEVICE_MAILBOX_STATUS.INIT,
  DeviceMailboxData: [],
  pathList: []
};

function handleFetchDeviceMailboxDetails(state: DeviceMailboxState): DeviceMailboxState {
  return {
    ...state,
    status: DEVICE_MAILBOX_STATUS.FETCHING
  };
}

function handleFetchDeviceMailboxSuccess(
  state: DeviceMailboxState,
  DeviceMailboxData: any
): DeviceMailboxState {
  return {
    ...state,
    status: DEVICE_MAILBOX_STATUS.READY,
    DeviceMailboxData
  };
}

function handleFetchDeviceMailboxFailure(state: DeviceMailboxState): DeviceMailboxState {
  return {
    ...state,
    status: DEVICE_MAILBOX_STATUS.ERROR
  };
}

function handleFetchDeviceMailboxPathList(
  state: DeviceMailboxState,
  data: any
): DeviceMailboxState {
  return {
    ...state,
    pathList: data
  };
}

function resetDeviceMailbox(): DeviceMailboxState {
  return {
    ...initialState
  };
}

export default function deviceMailboxReducer(
  state: DeviceMailboxState = initialState,
  action: AnyAction
): DeviceMailboxState {
  switch (action.type) {
    case ACTION_TYPE.FETCH_DEVICE_MAILBOX:
      return handleFetchDeviceMailboxDetails(state);
    case ACTION_TYPE.FETCH_DEVICE_MAILBOX_SUCCESS:
      return handleFetchDeviceMailboxSuccess(state, action.payload);
    case ACTION_TYPE.FETCH_DEVICE_MAILBOX_FAILURE:
      return handleFetchDeviceMailboxFailure(state);
    case ACTION_TYPE.FETCH_DEVICE_MAILBOX_PATH_LIST_SUCCESS:
      return handleFetchDeviceMailboxPathList(state, action.payload);
    case ACTION_TYPE.RESET_DEVICE_MAILBOX:
      return resetDeviceMailbox();
    default:
      return state;
  }
}
