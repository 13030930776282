/**
 * Templates Sidebar Menu
 * @author aditya.bhadange@shorelineiot.com
 */
import React from 'react';
import {
  ACCESS_PERMIT,
  SidebarMenu,
  SLSubmenuLink,
  TemplateIcon,
  usePermissions,
  useSlugContext
} from '../../framework';
import { ANOMALY_TEMPLATES_PERMISSIONS } from '../anomaly-templates/anomaly.permissions';
import { COMPONENT_COMPILER_PERMISSIONS } from './component-compiler/componentCompiler.permissions';
import { sidebarId } from '../../framework/components/sidebar/sidebar.utils';
import { SSO_PERMISSIONS } from './sso-configurations/sso.permissions';
import { SSO_CONFIGURATIONS_BREADCRUMB_KEY } from './sso-configurations/ssoConfigurations.constants';

export default function TemplatesMenu() {
  const { slug } = useSlugContext();
  const hasViewPermissionAnomaly: ACCESS_PERMIT = usePermissions(
    ANOMALY_TEMPLATES_PERMISSIONS.VIEW
  );
  const hasViewPermissionCompiler: ACCESS_PERMIT = usePermissions(
    COMPONENT_COMPILER_PERMISSIONS.VIEW
  );
  const hasViewPermissionSSO: ACCESS_PERMIT = usePermissions(SSO_PERMISSIONS.VIEW);
  const templateMenuId: string = sidebarId.menuItems('templates');

  if (
    hasViewPermissionAnomaly === ACCESS_PERMIT.ALLOW ||
    hasViewPermissionCompiler === ACCESS_PERMIT.ALLOW ||
    hasViewPermissionSSO === ACCESS_PERMIT.ALLOW
  ) {
    return (
      <SidebarMenu
        testID={templateMenuId}
        route={`/${slug}/app/templates`}
        textKey="templates"
        defaultText="Templates"
        icon={<TemplateIcon className="MuiSvgIcon-root" />}>
        {hasViewPermissionCompiler === ACCESS_PERMIT.ALLOW && (
          <SLSubmenuLink
            testID={`${templateMenuId}-component`}
            route={`/${slug}/app/templates/component-compiler`}
            textKey="component"
            defaultText="Component"
          />
        )}
        {hasViewPermissionAnomaly === ACCESS_PERMIT.ALLOW && (
          <SLSubmenuLink
            testID={`${templateMenuId}-anomaly`}
            route={`/${slug}/app/anomaly-templates`}
            textKey="anomaly"
            defaultText="Anomaly"
          />
        )}
        {hasViewPermissionSSO === ACCESS_PERMIT.ALLOW && (
          <SLSubmenuLink
            testID={`${templateMenuId}-sso-configurations`}
            route={`/${slug}/app/templates/${SSO_CONFIGURATIONS_BREADCRUMB_KEY}`}
            textKey="sso_configurations"
            defaultText="SSO Config"
          />
        )}
      </SidebarMenu>
    );
  }

  return null;
}
