/**
 * SLPanel Content
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { PropsWithChildren } from 'react';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
/**
 *
 */
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    margin: theme.spacing(2)
  }
}));

/**
 *
 * @returns
 */
export default function SLPanelContent({ children }: PropsWithChildren<any>) {
  const classes = useStyles();
  return <div className={classes.root}>{children}</div>;
}
