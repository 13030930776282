/**
 * Switch component
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { useEffect } from 'react';
import { Switch, SwitchProps, FormControl, Theme, FormControlLabel } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Controller, useFormContext } from 'react-hook-form';
import { Colors } from '../../../constants';
import { Variant } from '@mui/material/styles/createTypography';
import TranslateText from '../../../../i18n/TranslateText';
/**
 *
 */
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: '0 10px'
  },
  yes: {
    '& > .MuiFormControlLabel-label': {
      marginLeft: '-55px',
      fontSize: ' 0.89rem',
      color: Colors.secondaryText,
      opacity: 0.9
    }
  },

  no: {
    '& > .MuiFormControlLabel-label': {
      marginLeft: '-32px',
      fontSize: ' 0.89rem',
      color: Colors.secondaryText,
      opacity: 0.9
    }
  },
  disabled: {
    opacity: 0.5
  },
  switchContainer: {
    width: '65px'
  },
  switch: {
    '&.MuiSwitch-root': {
      width: '52px',
      height: '27px',
      padding: '0px',
      margin: theme.spacing(1),
      borderRadius: '27px',
      borderColor: theme.palette.text.primary,
      color: theme.palette.text.primary,
      boxShadow: '0 0px 3px 0px white',
      justifyContent: 'center'
    },
    '& >.MuiSwitch-switchBase': {
      width: 27,
      height: 25,
      padding: 1,
      '&.Mui-checked': {
        color: Colors.switchbtnColor,
        transform: 'translateX(23px)'
      }
    },
    '& .MuiSwitch-colorPrimary + .MuiSwitch-track': {
      backgroundColor: theme.palette.background.paper
    },
    '& .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track': {
      backgroundColor: theme.palette.primary.main,
      opacity: '1'
    }
  }
}));
/**
 *
 */
interface Props extends SwitchProps {
  leftLabel?: string;
  rightLabel?: string;
  labelColor?:
    | 'initial'
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'textPrimary'
    | 'textSecondary'
    | 'error';
  labelVarient?: Variant;
  name: string;
  rules?: Object | Function;
  value?: boolean;
  disabled?: boolean;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

/**
 * SLSwitch Component
 * @param param0 ```{Props}```
 * @returns
 */
export default function SLSwitch({
  leftLabel = 'Off',
  rightLabel = 'On',
  // labelVarient = 'caption',
  // labelColor = 'textSecondary',
  name,
  rules,
  value,
  disabled = false,
  handleChange,
  ...otherProps
}: Props) {
  const classes = useStyles();
  const {
    formState: { errors },
    watch,
    setValue
  } = useFormContext();

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (handleChange) {
      handleChange(e);
    }
  };

  useEffect(() => {
    if (value) {
      setValue(name, value);
    }
  }, [value]);

  return (
    <FormControl error={Boolean(errors[name])}>
      <Controller
        name={name}
        rules={typeof rules === 'function' ? rules(watch) : rules}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <div className={classes.switchContainer}>
            <FormControlLabel
              value={value ? rightLabel : leftLabel}
              className={`${value ? classes.yes : classes.no} ${disabled && classes.disabled}`}
              disabled={disabled}
              label={value ? TranslateText('on', 'On') : TranslateText('off', 'Off')}
              control={
                <Switch
                  className={classes.switch}
                  color="primary"
                  checked={value}
                  onBlur={onBlur}
                  size="medium"
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  inputRef={ref}
                  onChange={(e) => {
                    onChange(e);
                    handleOnChange(e);
                  }}
                  {...otherProps}
                />
              }
            />
          </div>
        )}
      />
    </FormControl>
  );
}
