/**
 * Reset Default Configuration
 * @author mahesh.kedari@shorelineiot.com
 */
import { Box, Button, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { ReactElement, ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';
import { resetChartEditorForm } from './resetDefault.helper';
import { widgetFormCommonDefaults } from './widgetFormCommonDefaults';
import TranslateText from '../../../../i18n/TranslateText';
import { toSnakeCase } from '../../../../i18n/translation.helper';

interface Props {
  onReset?: () => void;
  chartType?: string;
  label?: ReactNode;
}
/**
 *
 */
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginRight: '10px',
    '&.MuiButton-contained': {
      color: theme.palette.primary.main,
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.background.default
    }
  }
}));
/**
 *
 * @param param0
 * @returns
 */
export default function ResetDefaultConfig({
  onReset,
  chartType,
  label = 'Reset Default'
}: Props): ReactElement {
  const selectedWidgetType = widgetFormCommonDefaults.find(
    (widgetSettingsObject: any) => widgetSettingsObject?.chartType === chartType
  );
  const classes = useStyles();
  const { reset, getValues, setValue } = useFormContext();
  const newFormValues = getValues();

  const handleReset = () => {
    if (selectedWidgetType) {
      resetChartEditorForm(chartType, newFormValues, setValue);
    } else {
      reset();
    }
    if (onReset) {
      onReset();
    }
  };

  return (
    <Box my={5}>
      <Button
        variant="contained"
        size="small"
        classes={{
          root: classes.root
        }}
        onClick={handleReset}>
        {label && typeof label === 'string' ? TranslateText(toSnakeCase(label), label) : label}
      </Button>
    </Box>
  );
}
