import axios from 'axios';
/**
 * Http Services Interaction
 */

/**
 *
 */
interface HttpConfiguration {
  basePath: string;
}

interface HttpConfigType {
  get: Function;
  post: Function;
  put: Function;
  delete: Function;
}
/**
 *
 */
class HttpConfig implements HttpConfigType {
  private configuration: HttpConfiguration;

  constructor(basePath: string) {
    this.configuration = { basePath };
  }

  get = (path: string) => {
    return axios.get(`${this.configuration.basePath}${path}`);
  };

  post = (contextPath: string, data: any) => {
    return axios.post(`${this.configuration.basePath}${contextPath}`, data);
  };

  put = (contextPath: string, data: any) => {
    return axios.put(`${this.configuration.basePath}${contextPath}`, data);
  };

  delete = (contextPath: string, id: number) => {
    return axios.delete(`${this.configuration.basePath}${contextPath}\\${id}`);
  };
}
/**
 *
 */
const Http = (function httpGenerator() {
  let instance: HttpConfigType;

  function createInstance() {
    const URL = `${import.meta.env.VITE_HOST_URL}`;
    const object = new HttpConfig(URL);
    return object;
  }

  return function instanceHandler() {
    if (!instance) {
      instance = createInstance();
    }
    return instance;
  };
})();

export default Http();
