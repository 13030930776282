import { AlertLevel } from '../../alarms/store';
import { Orientation } from '../device-details/store';
import { Weekday } from '../device-list/create-device/create-powertrain/additional-configs/types/additionalConfigs.types';
import { PowertrainApiPayload } from '../device-list/create-device/create-powertrain/powertrain.types';
import { Device } from './types';

export enum DEVICE_TAG {
  DEVICE = 'DEVICE',
  DEVICES = 'DEVICES',
  DEVICE_GROUPS = 'DEVICE_GROUPS',
  DEVICE_FIRMWARE_BY_ID = 'DEVICE_FIRMWARE_BY_ID',
  STANDALONE_DEVICES = 'STANDALONE_DEVICES',
  DEVICE_DATAPOINTS = 'DEVICE_DATAPOINTS',
  MULTI_DEVICE_DATAPOINTS = 'MULTI_DEVICE_DATAPOINTS',
  DEVICE_EVENTS = 'DEVICE_EVENTS',
  DEVICE_PROFILES_DETAILS_BY_COMPATIBLE_DEVICE_TYPE = 'DEVICE_PROFILES_DETAILS_BY_COMPATIBLE_DEVICE_TYPE',
  DEVICE_PROFILES_DETAILS_BY_DEVICE_PROFILE_ID = 'DEVICE_PROFILES_DETAILS_BY_DEVICE_PROFILE_ID',
  DEVICE_SENSOR_TEMPLATES = 'DEVICE_SENSOR_TEMPLATES',
  CREATE_DEVICE = 'CREATE_DEVICE',
  DELETE_DEVICE = 'DELETE_DEVICE',
  CREATE_GROUP = 'CREATE_GROUP',
  EDIT_GROUP = 'EDIT_GROUP',
  DELETE_GROUP = 'DELETE_GROUP',
  MOVE_GROUP_TO_GROUP = 'MOVE_GROUP_TO_GROUP',
  MOVE_DEVICE_TO_GROUP = 'MOVE_DEVICE_TO_GROUP',
  ORBIT_CHART = 'ORBIT_CHART',
  DEVICE_SCHEDULE_CONFIG = 'DEVICE_SCHEDULE_CONFIG',
  DEVICE_HISTORICAL_DATA_WITHOUT_SAMPLING = 'DEVICE_HISTORICAL_DATA_WITHOUT_SAMPLING',
  DEVICE_WIDGET_SETTING = 'DEVICE_WIDGET_SETTING',
  DEVICE_TYPES = 'DEVICE_TYPES',
  BATTERY_STATUS = 'BATTERY_STATUS',
  POWERTRAIN_COMPONENTS = 'POWERTRAIN_COMPONENTS',
  POWERTRAIN_DETAIL = 'POWERTRAIN_DETAIL',
  UPDATE_POWERTRAIN = 'UPDATE_POWERTRAIN',
  CREATE_POWERTRAIN = 'CREATE_POWERTRAIN',
  DELETE_POWERTRAIN = 'DELETE_POWERTRAIN',
  LATEST_POWERTRAIN_CONFIG = 'LATEST_POWERTRAIN_CONFIG',
  GENERIC_DASHBOARD = 'GENERIC_DASHBOARD',
  ASSET_WIDGET_DATA = 'ASSET_WIDGET_DATA',
  SENSOR_WIDGET_DATA = 'SENSOR_WIDGET_DATA',
  IMAGE_WIDGET_DATA = 'IMAGE_WIDGET_DATA',
  UPDATE_SENSOR_WIDGET_DATA = 'UPDATE_SENSOR_WIDGET_DATA',
  DEVICE_PHOTOS = 'DEVICE_PHOTOS',
  DELETE_DEVICE_PHOTO = 'DELETE_DEVICE_PHOTO',
  UPLOAD_DEVICE_PHOTO = 'UPLOAD_DEVICE_PHOTO',
  REPLACE_DEVICE_PHOTO = 'REPLACE_DEVICE_PHOTO',
  DEVICE_PROFILES = 'DEVICE_PROFILES',
  UPDATE_DEVICE_PROFILE = 'UPDATE_DEVICE_PROFILE',
  UPDATE_DEVICE_SIM_SETTINGS = 'UPDATE_DEVICE_SIM_SETTINGS',
  UPDATE_DEVICE_SETTINGS = 'UPDATE_DEVICE_SETTINGS',
  UPDATE_DEVICE_FIRMWARE = 'UPDATE_DEVICE_FIRMWARE',
  REBOOT_DEVICE = 'REBOOT_DEVICE',
  CATEGORIES = 'CATEGORIES',
  ACTIVITY_LOGS = 'ACTIVITY_LOGS',
  ADDITIONAL_SENSORS = 'ADDITIONAL_SENSORS',
  UPDATE_DEVICE_WIDGET_SETTING = 'UPDATE_DEVICE_WIDGET_SETTING',
  CREATE_DEVICE_WIDGETS_SETTING = 'CREATE_DEVICE_WIDGETS_SETTING',

  RAW_DATAPOINTS_BY_DEVICE_IDS = 'RAW_DATAPOINTS_BY_DEVICE_IDS',
  HISTORICAL_TREND_DATA = 'HISTORICAL_TREND_DATA',
  ANOMALY_LIST = 'ANOMALY_LIST',
  AIRBORNE_DATA = 'AIRBORNE_DATA',
  DIAL_WIDGET_DATA = 'DIAL_WIDGET_DATA'
}

//Device API types below
export type ProvisioningStatus =
  | 'NEVER-PROVISIONED'
  | 'DE-PROVISIONED'
  | 'PROVISIONED'
  | 'PROV-FAILED'
  | 'PROV-SERIAL-NUMBER-ATTACHED'
  | 'PROV-IN-PROGRESS'
  | 'PROV-INITIATED'
  | 'PROV-CERTS-GENERATED';
export interface Location {
  lat: number;
  long: number;
}

export interface IpSection {
  dns1: string;
  dns2: string;
  gateway: string;
  netmask: string;
  ip_address: string;
  ip_allocation: string;
}

export interface Sim {
  APN: string;
  IMSI: string;
  ICCID: string;
  CARRIER: string;
  enabled: boolean;
  scanseq: string;
  slot_id: number;
}

export interface NetworkDetails {
  ip_section: IpSection;
  connected_via: string;
  cellular_section: {
    IMEI: string;
    SIMS: Sim[];
  };
  connectivity_capabilities: {
    cap_net_eth: string;
    cap_net_wifi: string;
    cap_net_cellular: string;
  };
  wifi_section?: any;
  network_config?: any;
}

export interface OperatingHours {
  end: number;
  days: string[];
  start: number;
}

export interface CloudInterval {
  days: string[];
  hour?: number;
  minute?: number;
  hour_interval?: string;
}

export type DeviceType =
  | 'external_device'
  | 'dcp_compressor_v2_new'
  | 'iCastSense'
  | 'iCast1_NN_MPS'
  | 'iCast1'
  | 'iCast1c'
  | 'dcp_compressor'
  | 'estis_compressor';

export enum DEVICE_TYPE_CONSTANTS {
  ICAST_SENSE = 'iCastSense',
  ICAST1_NN_MPS = 'iCast1_NN_MPS',
  EXTERNAL_DEVICE = 'external_device',
  DCP_COMPRESSOR_V2_NEW = 'dcp_compressor_v2_new',
  ICAST1 = 'iCast1',
  ICAST1C = 'iCast1c'
}

export interface CommonDeviceFields {
  id: number;
  name: string;
  description: string | null;
  device_type: DeviceType;
  device_type_uuid: string;
  device_profile_uuid: string;
  alert_level: AlertLevel;
  sensor_alert_level?: AlertLevel;
  fq_dg_path: string;
  last_connected_ts: number | null;
  org_uuid: string;
  suborg_uuid: string;
  provisioning_status: ProvisioningStatus;
  serial_number: string | null;
  firmware_uuid: string | null;
  ext_datasource: string;
  firmware_version: string | null;
  is_in_powertrain: boolean;
  location: Location | null;
  asset_type: string;
  /**
   * Below keys are added for the purpose of usage in the Assets table.
   */
  is_group: false;
  device_id: number;
  site?: string;
}

export interface DeviceDetail extends CommonDeviceFields {
  ble_mac: string;
  asset_alert_level: AlertLevel;
  sensor_alert_level: AlertLevel;
  network_details: NetworkDetails;
  operating_hours: OperatingHours;
  cloud_interval_bat_fe: CloudInterval;
  cloud_interval_pwr_fe: CloudInterval;
  next_connection_ts: number;
  enqueued_fw_version: string | null;
}

export interface FetchDeviceArgs {
  slug: string;
  deviceId: number;
}
export type GetDevicesArgs = {
  slug: string;
  showProgressBar?: boolean;
};

export interface FetchDeviceGroupsArgsPayload {
  /**
   * To include the `alert_level` key in the response from the API call use `true`.
   *
   * Set this to `false` if you don't want to include the `alert_level` key in the
   * response in some cases to make the API call faster.
   * @description Now it's not being included in the response by default to improve the API response time.
   * @default false
   */
  alarm_status?: boolean;
  /**
   * To include the `last_connected_ts` key in the response from the API call use `true`.
   *
   * Set this to `false` if you don't want to include the `last_connected_ts` key in the
   * response in some cases to make the API call faster.
   * @description Now it's not being included in the response by default to improve the API response time.
   * @default false
   */
  last_connected?: boolean;
}

export interface FetchDeviceGroupsArgs {
  slug: string;
  payload?: FetchDeviceGroupsArgsPayload;
  showProgressBar?: boolean;
}

export type GetDeviceTypesArgs = {
  slug: string;
};

export interface FetchDeviceProfilesDetailsByCompatibleDeviceTypeArgs {
  slug: string;
  compatible_device_type: string;
  searchText?: string;
}

export interface FetchDeviceProfilesDetailsByDeviceProfileIdArgs {
  slug: string;
  /**
   * This is the UUID of the device profile.
   */
  uuid: string;
}

interface UpdateDeviceProfilePayload {
  name: string;
  description?: string;
  compatible_device_type: 'iCastSense' | 'iCast1';
  profile_objects: {
    cge: CGE;
    cloud_configuration: CloudConfig;
    sensor_configuration: SensorConfiguration[];
    sim_config: SimConfiguration;
  };
}

export interface UpdateDeviceProfileArgs {
  slug: string;
  /**
   * This is the UUID of the device profile.
   */
  uuid: string;
  /**
   * This is the payload that will be sent to the API.
   */
  payload: UpdateDeviceProfilePayload;
}

export interface UpdateDeviceProfileResponse extends UpdateDeviceProfilePayload {
  revision: number;
}

export interface GetDeviceProfilesArgs {
  slug: string;
}

export interface CreateDeviceArgs {
  slug: string;
  deviceProfileId: string;
  deviceTypeId: string;
  path: string;
  name: string;
}

export interface DeleteDeviceArgs {
  slug: string;
  deviceId: number;
}

export interface DeviceNameAndDescriptionPayload {
  name: string;
  description: string;
}

export interface LocationUpdatePayload {
  location: {
    lat: number;
    long: number;
  };
}

export interface UpdateDeviceSettingsArgs {
  slug: string;
  deviceId: number;
  payload: LocationUpdatePayload | DeviceNameAndDescriptionPayload;
}

export interface UpdateDeviceFirmwareArgs {
  slug: string;
  deviceId: number;
  firmwareId: string;
}

export type UpdateDeviceFirmwareResponse = BaseMessageSuccessResponse;

interface BaseMessageSuccessResponse {
  message: string;
}

export interface RebootDeviceArgs {
  slug: string;
  deviceId: number;
}

export type RebootDeviceResponse = BaseMessageSuccessResponse;

export interface UpdateDeviceSimSettingsArgsPayload {
  sim_config: {
    slot_1: {
      enabled: boolean;
      scanseq: number[];
    };
    slot_2: {
      enabled: boolean;
      scanseq: number[];
    };
  };
}

export interface UpdateDeviceSimSettingsArgs {
  slug: string;
  deviceProfileId: string;
  payload: UpdateDeviceSimSettingsArgsPayload;
}

export type UpdateDeviceSimSettingsResponse = BaseMessageSuccessResponse;

export type DeleteDeviceResponse = BaseMessageSuccessResponse;

export interface EditGroupArgs {
  slug: string;
  rel_dg_parent_path: string;
  name: string;
}

export interface MoveGroupToGroupArgs {
  slug: string;
  current_rel_dg_path: string;
  targetPath: string;
}

export interface MoveGroupToGroupQueryParams {
  current_rel_dg_path: string;
  action: 'move_dg';
}

export interface MoveDeviceToGroupArgs {
  slug: string;
  device_ids: number[];
  fqDgPath: string;
  current_rel_dg_path: string;
}

export interface MoveDeviceToGroupQueryParams {
  device_ids: number[];
  action: 'add_device';
  current_rel_dg_path: string;
}

export interface DeleteGroupArgs {
  slug: string;
  fqDgPath: string;
}

export interface DeleteGroupResponse {
  code: string;
  message: string;
}

interface CommonMoveToApiResponse {
  results: {
    success_count: number;
    name: string;
    fq_dg_path: string;
    failures: any[];
  };
}

export type MoveGroupToGroupResponse = CommonMoveToApiResponse;

export type MoveDeviceToGroupResponse = CommonMoveToApiResponse;

export interface EditGroupQueryParams extends Omit<EditGroupArgs, 'slug' | 'rel_dg_parent_path'> {
  name: string;
}

export interface CreateGroupArgs {
  slug: string;
  device_ids?: number[];
  name: string;
  rel_dg_parent_path: string;
}

export interface FetchPowertrainComponentsArgs {
  slug: string;
}

export interface UpdatePowertrainArgs extends PowertrainApiPayload {
  slug: string;
  powertrainId: number;
}

export interface CreatePowertrainArgs extends PowertrainApiPayload {
  slug: string;
}

export interface DeletePowertrainArgs {
  slug: string;
  powertrainId: number;
}

export interface FetchPowertrainDetailArgs {
  slug: string;
  powertrainId: number;
}

export interface CreateDeviceApiQueryParams {
  device_profile_id: string;
  device_type_id: string;
  fq_dg_path: string;
  name: string;
}

export type TemplateId =
  | 'sns_mic_struct_borne'
  | 'sns_mic_airborne'
  | 'sns_humidity'
  | 'sns_temperature';

export type SensorName =
  | 'Structure borne'
  | 'Airborne'
  | 'Humidity'
  | 'Surface Temperature'
  | 'Environment Temperature';

export enum SENSOR_NAME {
  STRUCTURE_BORNE = 'Structure borne',
  AIRBORNE = 'Airborne',
  HUMIDITY = 'Humidity',
  SURFACE_TEMPERATURE = 'Surface Temperature',
  ENVIRONMENT_TEMPERATURE = 'Environment Temperature'
}

export enum TEMPLATE_ID {
  SNS_MIC_STRUCT_BORNE = 'sns_mic_struct_borne',
  SNS_MIC_AIRBORNE = 'sns_mic_airborne',
  SNS_HUMIDITY = 'sns_humidity',
  SNS_TEMPERATURE = 'sns_temperature'
}

export interface FetchDeviceSensorTemplatesArgs {
  slug: string;
  deviceId: number;
}

export interface FetchDeviceSensorTemplatesInternalApiArgs extends FetchDeviceSensorTemplatesArgs {
  templateId: TemplateId;
  sensorName: SensorName;
}

// fireware API types below
export type GetDevicesByFirmwareIdArgs = {
  slug: string;
  firmwareId: string;
};
export type DeviceByFirmwareIdResponse = {
  page: number;
  page_size: number;
  total: number;
  previous: string;
  next: string;
  results: Device[];
};

//standalone device API types
export interface FetchStandaloneDevicesArgs {
  slug: string;
  parentGroupId?: string | null;
}

// datapoint API types below

export enum SensorType {
  SNS_TOTAL_VIBRATION_ENERGY = 'sns_total_vibration_energy',
  SNS_ACCELEROMETER = 'sns_accelerometer',
  SNS_TEMPERATURE = 'sns_temperature',
  SNS_HUMIDITY = 'sns_humidity',
  SNS_BATTERY = 'sns_battery',
  SNS_NARROWBAND = 'sns_narrowband',
  SNS_BAND = 'sns_band',
  SNS_RSRP = 'sns_rsrp',
  SNS_RSRQ = 'sns_rsrq',
  SNS_CSQ = 'sns_csq',
  SNS_SINR = 'sns_sinr',
  SNS_AI = 'sns_ai',
  SNS_MIC_AIRBORNE = 'sns_mic_airborne',
  SNS_MIC_STRUCT_BORNE = 'sns_mic_struct_borne',
  SNS_BAROMETER = 'sns_barometer',
  ANALOG_INPUT = 'analog_input',
  MODBUS = 'modbus',
  NN_MPS_GAS_SENSOR = 'nn_mps_gas_sensor',
  SNS_ACC_NARROWBAND = 'sns_acc_narrowband',
  SNS_BATT_USAGE_ACCUM = 'sns_batt_usage_accum',
  SNS_DIO = 'sns_dio',
  SNS_SIM_SLOT = 'sns_sim_slot',
  SNS_BAT_TIME = 'sns_bat_time',
  SNS_POW_TIME = 'sns_pow_time'
}

export interface DataPoint {
  dp_name: string;
  odr: number | null;
  sensor_module_uuid: string;
  datapoint_type: string;
  is_live: boolean;
  sensor_type: SensorType;
  datatype: string;
  dpid: number;
  peripheral_name: string;
  unit: string;
  path: string | null;
}
export interface TransformedDataPointResponse {
  [key: string]: DataPoint; // Assuming DataPoint is the type of your datapoint objects
}

export interface FetchMultipleDeviceDatapointsArgs {
  slug: string;
  deviceIds: number[];
  vibrationAnalysis?: boolean;
}

export interface DatapointResponse {
  device_id: number;
  device_profile_uuid: string;
  datapoints: Array<DataPoint>;
}
export interface FetchMultipleDeviceDatapointsResponse {
  datapoints: Array<DatapointResponse>;
}
export interface FetchDeviceDatapointsArgs {
  slug: string;
  deviceId: number;
  vibrationAnalysis?: 'true' | 'false';
}

// event API types below
export interface FetchDeviceEventsTrendArgs {
  slug: string;
  deviceId: number;
  dpids: number[];
  start?: number;
  end?: number;
  unit?: string;
  skip?: boolean;
}

interface Event {
  event_id: number;
  speed_hz: number;
  value: number;
  peak_frequency: number;
  ts: number;
}

export interface FetchDeviceEventsTrendResponse {
  data: Record<string, Event[]>;
}

// Orbit plot API ts below.
export interface OrbitPlotData {
  phase_radians: number;
  phase_degrees: number;
  orbit_plot_data: number[][];
}

export interface FetchOrbitPlotDataResponse {
  [key: string]: OrbitPlotData;
}

export type FetchOrbitPlotDataCustomCombination =
  | 'axial_horizontal'
  | 'axial_vertical'
  | 'horizontal_axial'
  | 'horizontal_vertical'
  | 'vertical_axial'
  | 'vertical_horizontal';

export type Odr = 100 | 800 | 3200 | 25600;
export interface FetchOrbitPlotDataArgs {
  slug: string;
  event_id: number;
  odr: Odr;
  dpid?: number;
  center_freq?: number;
  custom_combinations?: FetchOrbitPlotDataCustomCombination;
  deviceId: number;
}
// Default Schedule Config API ts below.
export interface FetchDefaultScheduleConfigArgs {
  slug: string;
}

export interface CloudUpdateIntervalFe {
  days: string[];
  hour: number | string;
  minute: number;
}

export interface FetchDefaultScheduleConfigResponse {
  logging_interval_bat_fe: string[];
  sampling_interval_bat_fe: string;
  acc_g_range: number;
  cloud_update_interval_bat_fe: CloudUpdateIntervalFe;
  operating_hours: {
    end: number;
    days: string[];
    start: number;
  };
  firmware_uuid: string;
}

// Historical Data API ts below.
export interface FetchDeviceHistoricalDataWithoutSamplingArgs {
  slug: string;
  deviceId: number;
  dpids: number[];
  maxPoints?: number;
  startDate?: number;
  endDate?: number;
}
export interface FetchDeviceHistoricalDataWithoutSamplingQueryParams {
  dpids: string;
  maxPoints?: number;
  start?: number;
  end?: number;
}
export interface DataObject {
  [key: string]: [];
}

export interface DataEmpty {}

export interface HistoricalDataWithoutSampling extends DataObject, DataEmpty {}

export interface FetchHistoricalDataWithoutSamplingResponse {
  data: HistoricalDataWithoutSampling;
}
// Widget Setting API ts below.
export interface WidgetSettings {
  show: boolean;
  widget_type: string;
  datapoint_ids: number[];
}
export interface Widget {
  widget_id: number;
  settings: WidgetSettings;
}

export interface FetchDeviceWidgetSettingArgs {
  slug: string;
  deviceId: number;
}

export interface FetchDeviceWidgetSettingResponse {
  widget: Widget[];
}
export interface UpdateDeviceWidgetSettingResponse {
  message: string;
}
export interface UpdateDeviceWidgetSettingPayload {
  settings: WidgetSettings;
}

export interface CreateWidget {
  settings: WidgetSettings;
}
export interface CreateDeviceWidgetSettingPayload {
  widgets: CreateWidget[];
}

export interface UpdateDeviceWidgetSettingArgs extends UpdateDeviceWidgetSettingPayload {
  slug: string;
  deviceId: number;
  datapointName: string;
  widgetId: number;
}

export interface CreateDeviceWidgetsSettingArgs extends CreateDeviceWidgetSettingPayload {
  slug: string;
  deviceId: number;
}
export interface CreateDeviceWidgetsSettingResponse {
  widgets: Widget[];
}

export interface DeviceProfilesDetails {
  id: string;
  name: string;
  description: string;
  attached_devices: number;
  compatible_device_type: string;
  is_default: boolean;
}

export interface CompatibleDeviceProfilesDetailsApiResponse {
  total: number;
  page: number;
  page_size: number;
  results: DeviceProfilesDetails[];
}

interface DeviceProfilesDetailsListProfile {
  value: string;
  label: string;
}

export interface DeviceProfilesDetailsByCompatibleDeviceTypeApiTransformedResponse
  extends CompatibleDeviceProfilesDetailsApiResponse {
  listProfile?: DeviceProfilesDetailsListProfile[];
  defaultProfile?: string;
}

interface SlotConfiguration {
  enabled: boolean;
  scanseq: number[];
}

interface SimConfiguration {
  slot_1: SlotConfiguration;
  slot_2: SlotConfiguration;
}

export interface SensorConfiguration {
  config: SensorModule;
  port: string;
}

export interface CloudConfig {
  cloud_update_interval_bat: string;
  cloud_update_interval_bat_fe: CloudUpdateIntervalFe;
  cloud_update_interval_pwr: string;
  cloud_update_interval_pwr_fe: {
    days: string[];
    hour_interval: number | string;
  };
  operating_hours: OperatingHours;
}

export enum COMPATIBLE_DEVICE_TYPE {
  iCastSense = 'iCastSense',
  iCast1 = 'iCast1'
}

export interface DeviceProfilesDetailsByProfileUUIDResponse {
  name?: string;
  description?: string;
  compatible_device_type?: 'iCastSense' | 'iCast1';
  profile_objects: {
    cge?: CGE;
    cloud_configuration?: CloudConfig;
    sensor_configuration?: SensorConfiguration[];
    sim_config?: SimConfiguration;
  };
  revision?: number;
  sha?: string;
}

export interface DeviceProfile {
  id: string;
  name: string;
  description: string;
  attached_devices: number;
  compatible_device_type: string;
  is_default: boolean;
}

export interface DeviceProfilesApiResponse {
  page: number;
  page_size: number;
  results: DeviceProfile[];
  total: number;
}

export interface DeviceSensorTemplatesResponse {
  structborneResponse?: DeviceSensorTemplate;
  airborneResponse?: DeviceSensorTemplate;
  humidityResponse?: DeviceSensorTemplate;
  surfaceTempResponse?: DeviceSensorTemplate;
  enviromentTempResponse: DeviceSensorTemplate;
}

interface ModuleMeta {
  name: string;
  uuid?: string;
  description: string;
}

export interface SensorModule {
  datapoints: Datapoint[];
  module_meta: ModuleMeta;
  sensor_type: string;
  common_properties: CommonProperties;
}

export interface DeviceSensorTemplate {
  port: string;
  default_sensor_module: SensorModule;
  configured_sensor_module: SensorModule | null;
}

interface Block {
  fb: string;
  idx: number;
  odr?: number;
  source?: string;
  filter?: (number | number[] | number[][])[];
  dpid?: number;
  vals?: number;
  mask?: number;
}

interface Connection {
  0: number;
  1: number;
  2: number;
  3: number;
}

interface CGE {
  blocks: Block[];
  connections: Connection[];
}

export interface Datapoint {
  dpid?: number;
  name: string;
  unit: string;
  datatype: string;
  readonly: boolean;
  writeonly: boolean;
  datapoint_type: string;
  odr?: number;
  enable?: boolean;
  no_of_readings?: number;
}

interface DeviceProfileDataPoint extends Datapoint {
  odr?: number;
  enable?: boolean;
  no_of_readings?: number;
}

interface CommonProperties {
  samples_count: number;
  operating_hours: OperatingHours;
  operating_hours_fe: string;
  sampling_frequency: number;
  logging_interval_bat: string;
  logging_interval_pwr: string;
  sampling_interval_bat: string;
  sampling_interval_pwr: string;
  logging_interval_bat_fe: string;
  logging_interval_pwr_fe: string;
  sampling_interval_bat_fe: string;
  sampling_interval_pwr_fe: string;
}

// interface DeviceProfileCommonProperties extends CommonProperties {
//   sync_tach?: boolean;
//   acc_g_range?: number;
//   samples_count?: number;
// }

interface SensorConfigurationFromDeviceProfileByProfileUUID {
  datapoints: Datapoint[];
  module_meta: ModuleMeta;
  sensor_type: string;
  common_properties: CommonProperties;
}

export interface ProfileConfigObject {
  acc_g_range: number;
  operating_hours: OperatingHours;
  logging_interval_bat_fe: string[] | string;
  sampling_interval_bat_fe: string;
  cloud_update_interval_bat_fe: CloudUpdateIntervalFe;
}

export interface PowertrainDetailResponse {
  powertrain_id: number;
  name: string;
  rpm_min: number;
  rpm_max: number;
  default_cpm: null | boolean;
  cpm_device_id: null | number;
  cpm_global_dpid: null | number;
  firmware_uuid: string;
  profile_config_json: ProfileConfigObject;
  override_cpm: boolean;
  assets: PowertrainAsset[];
}

type SAME_AS_POWER_TEXT = 'Same as power';
type SAME_AS_BATTERY_TEXT = 'Same as battery';

export interface FetchLatestPowetrainConfigResponse {
  logging_interval_bat_fe: string[] | SAME_AS_POWER_TEXT;
  logging_interval_pwr_fe: string;
  sampling_interval_bat_fe: string;
  sampling_interval_pwr_fe: string;
  cloud_update_interval_bat_fe: {
    days: string[];
    hour: number | SAME_AS_POWER_TEXT;
    minute: number;
  };
  cloud_update_interval_pwr_fe: {
    days: string[];
    hour_interval: number | SAME_AS_BATTERY_TEXT;
  };
}

export interface FetchLatestPowetrainConfigArgs {
  slug: string;
  powertrainId: number;
}

interface ModelLabel {
  value: string | number;
  label: string;
}

interface CloudUpdateInterval {
  days: string[];
  hour: number;
  minute: number;
}

interface ProfileConfig {
  acc_g_range: number;
  operating_hours: OperatingHours;
  logging_interval_bat_fe: string[];
  sampling_interval_bat_fe: string;
  cloud_update_interval_bat_fe: CloudUpdateInterval;
}

interface MountPoints {
  [key: string]: boolean;
}

interface ComponentClass {
  value: string;
  label: string;
}

interface ComponentSubclass {
  value: string;
  label: string;
}

export interface PowertrainAssetForm {
  serial_number: string;
  name: string;
  id?: number;
  asset_id?: number;
  newAsset?: boolean;
  model: ModelLabel | '';
  fq_dg_path?: string;
  asset_type: PowertrainAssetType;
  asset_seq_id: number;
  manufacturer: ModelLabel | '';
  mount_points: MountPoints;
  component_id?: number;
  component_revision?: number;
  component_class: ComponentClass | '';
  component_subclass: ComponentSubclass | '';
  previous_component_id?: number;
  mount_pt_mapping?: null | string;
  mount_pt_ids?: number[];
}

interface Additional {
  loggingTimeBattery: string[];
  gRange: string;
  samplingIntervalBattery: string;
  firmware: string;
  cloudUpdateWeekday: {
    Mon: boolean;
    Tue: boolean;
    Wed: boolean;
    Thu: boolean;
    Fri: boolean;
    Sat: boolean;
    Sun: boolean;
  };
  cloudUpdateHour: string;
  cloudUpdateMinute: string;
  operatingHoursWeekday: {
    Mon: boolean;
    Tue: boolean;
    Wed: boolean;
    Thu: boolean;
    Fri: boolean;
    Sat: boolean;
    Sun: string | boolean;
  };
  operatingHoursFrom: string;
  operatingHoursTo: string;
}

export interface PowertrainFormValues {
  powertrain_id: number;
  name: string;
  rpm_min: number;
  rpm_max: number;
  default_cpm?: number;
  cpm_device_id?: ModelLabel;
  cpm_global_dpid?: ModelLabel;
  firmware_uuid: string;
  profile_config_json: ProfileConfig;
  override_cpm: boolean;
  assets: PowertrainAssetForm[];
  additional: Additional;
  selectDatapoint?: string;
}

export interface UpdatePowertrainResponse {
  message: 'Powertrain update request sent successfully';
}

export interface CreatePowertrainResponse {
  message: 'Powertrain creation request sent successfully';
}

export interface CreateGroupResponse {
  results: {
    success_count: number;
    fq_dg_path: string;
    failures: any[];
  };
}

export interface EditGroupResponse {
  results: {
    success_count: number;
    fq_dg_path: string;
    failures: any[];
  };
}

export enum POWERTRAIN_ASSET_TYPE {
  PRIME_MOVER = 'prime_mover',
  FINAL_DRIVE = 'final_drive',
  DRIVE_TRAIN = 'drive_train'
}

type PowertrainAssetType = `${POWERTRAIN_ASSET_TYPE}`;

export interface PowertrainAsset {
  id: number;
  /**
   * `asset_id` is same as `id`, but duplicating it due to powertrain form-related issue.
   * This is important because `id` key in the final form submission is getting
   * replaced by some other value by the usage of field-array.
   */
  asset_id?: number;
  name: string;
  model: string;
  asset_type: PowertrainAssetType;
  fq_dg_path: string;
  asset_seq_id: number;
  component_id: number;
  manufacturer: string;
  mount_points: string[];
  serial_number: string;
  component_class: string;
  mount_pt_mapping: null | any;
  component_revision: number;
  component_subclass: string;
  previous_component_id: number;
  mount_pt_ids: number[];
}

export interface FetchMultipleDevicePhotosArgs {
  slug: string;
  deviceIds: number[];
  /**
   * Disable the "No Image Found" toaster when no image is present.
   * @default `true`
   */
  showNoPhotosFoundToast?: boolean;
}
export interface DevicePhotoList {
  device_photos_meta: Photo[];
}

export interface Photo {
  content_type: string;
  get_object_url: string;
  get_object_url_viewable: string;
  name: string;
  resolution: string;
  size: number;
  view: string;
  caption?: string;
  device_name: string;
  device_id: number;
  src?: string;
  key?: string;
  alt?: string;
  source?: string;
}

export interface DeleteDevicePhotoPayload {
  device_photos_meta: [string];
}

export interface DeleteDevicePhotoArgs {
  slug: string;
  deviceId: number;
  /**
   * `device_photos_meta` is an array of names of photos to be deleted.
   */
  payload: DeleteDevicePhotoPayload;
}

export interface DeleteDevicePhotoResponse {
  message: string;
}

export interface NewImageMetadata {
  name: `${string}.jpeg`;
  view: string | undefined;
  content_type: 'image/jpg';
  size: number;
  resolution: 'HxV';
}

export interface CustomFile extends File {
  lastModifiedDate: Date;
}

export interface ReplaceDevicePhotoPayload {
  oldImage: {
    device_photos_meta: [string];
  };
  newImage: {
    device_photos_meta: [NewImageMetadata];
    newImageDetails: CustomFile;
  };
}

export interface ReplaceDevicePhotoArgs {
  slug: string;
  deviceId: number;
  payload: ReplaceDevicePhotoPayload;
}

export interface UploadDevicePhotoResponseData {
  content_type: string;
  name: string;
  put_object_url: string;
  resolution: string;
  size: number;
  view: string;
}

export interface ReplaceDevicePhotoResponse {
  device_photos_meta: [UploadDevicePhotoResponseData];
}

interface UploadDevicePhotoPayload {
  device_photos_meta: [NewImageMetadata];
  newImageDetails: CustomFile;
}

export interface UploadDevicePhotoArgs {
  slug: string;
  deviceId: number;
  payload: UploadDevicePhotoPayload;
}

export interface UploadDevicePhotoResponse {
  device_photos_meta: [UploadDevicePhotoResponseData];
}

export interface FetchCategoriesArgs {
  slug: string;
  payload?: {
    category_type: 'device';
  };
}

export interface ActivityLogsCategory {
  app_feature: string;
  display_name: string;
}

export interface FetchCategoriesResponse {
  results: ActivityLogsCategory[];
}

export interface FetchAdditionalSensorsArgs {
  slug: string;
  payload: {
    device_id: number;
  };
}

export interface SensorData {
  sensor_type: string;
  sensor_module_name: string;
}

export interface AdditionalSensorsResponse {
  page: number;
  page_size: number;
  total: number;
  previous: string;
  next: string;
  results: SensorData[];
}

export interface FetchRawDatapointsByDeviceIdsArgs {
  slug: string;
  payload: {
    device_ids: string;
  };
}

export interface RawDatapoint {
  dp_name: string;
  datapoint_type: string;
  sensor_type: string;
  datatype: string;
  dpid: number;
  sensor_module_uuid: string;
  unit: string;
  iswritable: boolean;
  odr: number | null;
  orientation: Orientation;
  peripheral_name: string;
}

export interface RawDatapointsObject {
  device_id: number;
  device_profile_uuid: string;
  datapoints: RawDatapoint[];
}

export interface RawDataPointsResponse {
  datapoints: RawDatapointsObject[];
}

export interface FetchHistoricalTrendDataArgs {
  slug: string;
  deviceId: number;
  payload: {
    start: number;
    end: number;
    maxPoints?: number;
    dpids: string;
  };
}

export interface HistoricalTrendDataResponse {
  data: {
    [key: number]: [number, number][];
  };
  recent: {
    [key: number]: [number, number];
  };
}

export interface FetchBatteryStatusArgs {
  slug: string;
  payload: {
    /**
     * Comma separated string of device ids.
     */
    device_ids: string;
  };
}

export interface BatteryStatusResponse {
  device_id: number;
  device_name: string;
  bat_accu_dpid: number;
  bat_accu: number;
  batt_remaining: number;
  days_left: number;
  last_30_avg: number;
}

export interface FetchAnomalyListQueryArgs {
  slug: string;
  payload?: {
    hide_anomaly?: 'true';
    /**
     * Comma separated string of device ids in an array.
     * @example device_ids: JSON.stringify(assetDevices?.map((device) => device.id))
     */
    device_ids?: string;
  };
}

export interface FetchAnomalyListArgs {
  slug: string;
  payload?: {
    /**
     * Comma separated string of device ids in an array.
     * @example device_ids: JSON.stringify(assetDevices?.map((device) => device.id))
     */
    device_ids?: string;
  };
}

interface AnomalyLevels {
  alert_warn?: number;
  alert_fatal: number;
  alert_error?: number;
}

interface DerivedExpression {
  name: string;
  unit: string;
  powertrain: string;
  anomaly_type: string;
  anomaly_levels: AnomalyLevels;
  derived_dp_id: number;
  eval_anomaly: boolean;
}

export interface AnomalyListResponse {
  derived_expressions: DerivedExpression[];
}
