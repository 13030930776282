/**
 * Analytics Table Editor
 * @author mahesh.kedari@shorelineiot.com
 */
import React from 'react';
import { useCubeQuery } from '@cubejs-client/react';
import { useFormContext } from 'react-hook-form';
import { Grid } from '@mui/material';
import TableColumnConditions from './condition/TableColumnConditions';
import WidgetSettingAccordion from '../../common/WidgetAccordion';
import ColumnTitle from './header/ColumnTitle';
import RedirectionURLEditor from '../../common/RedirectionURLEditor';
import { SLCheckbox } from '../../..';
import { combineExistingAndNewIncomingWidgetsData } from '../../cartesian-chart/settings/analytics/AnalyticsCartesianChartEditor';
/**
 *
 */
interface ColumnConfigurationProps {
  column: any;
  handleUpdate: (data: any) => void;
}
/**
 * This is a configuration for individual columns to be shown in Table view
 * @param param0
 * @returns
 */
const ColumnConfiguration = ({ column, handleUpdate }: ColumnConfigurationProps) => {
  const { getValues } = useFormContext();
  const columnKey = column.key.replaceAll('.', '_');

  const newValues = getValues();

  const handleFieldUpdate = () => {
    const values = getValues();
    handleUpdate(values);
  };

  return (
    <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
      <Grid item xs={6}>
        <ColumnTitle name={`dataSettings.${columnKey}.title`} handleUpdate={handleFieldUpdate} />
      </Grid>
      <Grid item xs={6}>
        <SLCheckbox
          name={`dataSettings.${columnKey}.hideColumn`}
          label="Hide Column"
          labelTranslationId="hide_column"
          checked={!!newValues?.dataSettings?.[columnKey]?.hideColumn}
          onChange={handleFieldUpdate}
        />
      </Grid>

      <TableColumnConditions
        name={`dataSettings.${columnKey}.conditions`}
        handleUpdate={handleFieldUpdate}
      />
      <RedirectionURLEditor handleUpdate={handleUpdate} path={`dataSettings.${columnKey}`} />
    </Grid>
  );
};
/**
 *
 */
interface Props {
  dataSettings: any;
  query: any;
  handleUpdate: (data: any) => void;
}
/**
 * Iterate over all the columns obtained from resultSet object and
 * show configurations for all the columns
 * TODO: [SLC-4315] Handle update method is being passed to child components using Props.
 * We need to create a context and access that method in any of the child classes using useContext
 * @param param0
 * @returns
 */
const AnalyticsTableEditor = ({ dataSettings, query, handleUpdate }: Props) => {
  const { resultSet } = useCubeQuery<any>(query);
  const columns = resultSet?.tableColumns();

  const newColumns = combineExistingAndNewIncomingWidgetsData(dataSettings, columns);
  return (
    <>
      {newColumns?.map((column: any) => (
        <WidgetSettingAccordion key={column?.key} title={column?.title}>
          <ColumnConfiguration column={column} handleUpdate={handleUpdate} />
        </WidgetSettingAccordion>
      ))}
    </>
  );
};

export default AnalyticsTableEditor;
