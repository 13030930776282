export type TimeData = {
  time: number;
  val: number;
};

export type FetchAirborneDataArgs = {
  id: number;
  startDate: number;
  endDate: number;
  slug: string;
};

export const airborneDatapoint = 3000016;

export type DeviceAirborneDataType = {
  data: { [airborneDatapoint]: number[][] };
};

export type DeviceDataType = {
  [key: number]: TimeData[];
};
