import { AnyAction } from 'redux';
import * as ACTION_TYPES from './alerts.actionTypes';

export function fetchTagsList(data: any): AnyAction {
  return {
    type: ACTION_TYPES.FETCH_TAGS_LIST_PROGRESS,
    payload: data
  };
}
export function fetchTagsListSuccess(data: any): AnyAction {
  return {
    type: ACTION_TYPES.FETCH_TAGS_LIST_SUCCESSS,
    payload: data
  };
}
export function fetchTagsListFailure(): AnyAction {
  return {
    type: ACTION_TYPES.FETCH_TAGS_LIST_FAILURE
  };
}
