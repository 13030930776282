/**
 * Release notes component
 * @author prerna.more@shorelineiot.com
 */
import React from 'react';
import {
  Container,
  Typography,
  Box,
  List,
  ListItem,
  Link,
  Divider,
  ListItemIcon,
  Theme
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import { ShorelineNewLogo } from '../../../../assets';
import makeStyles from '@mui/styles/makeStyles';

interface ReleaseNotesListComponentProps {
  header: string;
  description: string;
}
const ReleaseNotesListComponent = ({ header, description }: ReleaseNotesListComponentProps) => {
  return (
    <ListItem>
      <ListItemIcon>
        <CircleIcon fontSize="small" style={{ height: '10px', width: '10px', color: 'black' }} />
      </ListItemIcon>
      <Typography variant="body1" gutterBottom>
        <b style={{ fontWeight: '500' }}>{header}</b> - {description}
      </Typography>
    </ListItem>
  );
};

const useStyle = makeStyles((theme: Theme) => ({
  link: {
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium
  }
}));
function ReleaseNotes() {
  const classes = useStyle();
  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Box sx={{ textAlign: 'center', mb: 4 }}>
        <img src={ShorelineNewLogo} alt="Shoreline AI" style={{ width: '250px' }} />
      </Box>
      <Typography variant="h6" fontWeight={510} gutterBottom>
        Shoreline APM / PdM Cloud Portal - New Features, now available!!
      </Typography>
      <Typography variant="body1" gutterBottom>
        Based on customer demand, we are pleased to launch these new features with intent to improve
        user experience. Please feel free to provide feedback and suggestions to{' '}
        <Link href="mailto:info@shorelineai.us" className={classes.link}>
          info@shorelineai.us
        </Link>
        .
      </Typography>
      <List>
        <ReleaseNotesListComponent
          header={'New Powertrain-level view'}
          description={
            'This enables you to view all information related to a powertrain in a single view. The previous drill-down approach to see Powertrain components, down to sensor-level will still be available.'
          }
        />
        <Divider component="li" />
        <ReleaseNotesListComponent
          header={' Alarm-handling notes-making for all actions'}
          description={
            "Users can now add notes to all alarms when in states like ‘NEW', 'ACK’, or ‘WIP’. Your notes made while working with alarms will be visible to all users of your org."
          }
        />

        <Divider component="li" />
        <ReleaseNotesListComponent
          header={'User-Group capability'}
          description={
            'New "Groups" menu has been added under the “org settings” menu. This enables assignment of “groups” of powertrains to a user. Similarly, in the user settings page an additional column is added to assign powertrain-groups to each user. From the next portal update - Based on the groups assigned to each user, they will receive notifications related to assigned groups only.'
          }
        />

        <Divider component="li" />
        <ReleaseNotesListComponent
          header={'Map widget in Dashboards menu'}
          description={
            'You can now add a map widget in as a dashboard to show a map-view of assets in one or more alarm states. You may also use this view to create a map-view of all your assets. Markers can be changed to a bubble-map wherein the bubble size increases based on the number of days the alarm(s) have been active. This is intended to draw attention to those alarms that may have been left open/unattended-to for a long time.'
          }
        />
      </List>
      <Typography variant="body1" gutterBottom>
        We hope you will find these new features helpful. Best wishes.
      </Typography>
      <br />
      <Typography variant="body1" gutterBottom>
        Sincerely,
      </Typography>
      <Typography variant="body1" gutterBottom>
        Team Shoreline
      </Typography>
    </Container>
  );
}

export default ReleaseNotes;
