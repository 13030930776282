/**
 * Narrowband Rules List Actions
 * @author priyanka.ambawane@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTIONS from './narrowbandRules.actionTypes';
/**
 *
 * @param data
 */
export const narrowbandRuleList = (slug: any, data: any): AnyAction => {
  // returns the action type
  return {
    type: ACTIONS.NARROWBAND_RULES_LIST,
    payload: {
      slug,
      data
    }
  };
};
/**
 *
 * @param data
 */
export const narrowbandRuleListSuccess = (data: any): AnyAction => {
  return {
    type: ACTIONS.NARROWBAND_RULES_LIST_SUCCESS,
    payload: data
  };
};
/**
 *
 */
export const narrowbandRuleListFailure = (error: any): AnyAction => {
  // returns the action type
  return {
    type: ACTIONS.NARROWBAND_RULES_LIST_FAILURE,
    payload: error
  };
};

export const resetNarrowbandRuleListState = (): AnyAction => {
  // returns the action type
  return {
    type: ACTIONS.RESET_NARROWBAND_RULES_LIST
  };
};
