import { AnyAction } from 'redux';
import * as ACTION_TYPE from './deleteNarrowband.actionTypes';
/**
 *
 */

export const deleteNarrowbandProgress = (data: any): AnyAction => {
  return {
    type: ACTION_TYPE.DELETE_NARROWBAND_PROGRESS,
    payload: data
  };
};

export const deleteNarrowbandSuccess = (data: any): AnyAction => {
  return {
    type: ACTION_TYPE.DELETE_NARROWBAND_SUCCESS,
    payload: data
  };
};

export const deleteNarrowbandFailure = (error: any): AnyAction => {
  return {
    type: ACTION_TYPE.DELETE_NARROWBAND_FAILURE,
    payload: error
  };
};

export const deleteNarrowbandReset = (): AnyAction => {
  return {
    type: ACTION_TYPE.DELETE_NARROWBAND_RESET_STATE,
    payload: false
  };
};
