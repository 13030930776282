/**
 * LogoPath Reducer
 * @author meghneel.adke@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTIONS from './logoPath.actionTypes';
/**
 *
 */
type StateType = [string];
/**
 *
 */
const initialState: StateType = [''];
/**
 *
 * @param state
 * @param processID
 */
function onSetLogoPath(state: StateType, processID: string): StateType {
  return [processID];
}
/**
 *
 */
function onResetLogoPath() {
  return initialState;
}
/**
 *
 * @param state old state
 * @param action Actions to perform
 */
export default function LogoPathReducer(
  state: StateType = initialState,
  action: AnyAction
): StateType {
  switch (action.type) {
    case ACTIONS.SET_LOGO_PATH: {
      return onSetLogoPath(state, action.payload);
    }
    case ACTIONS.RESET_LOGO_PATH: {
      return onResetLogoPath();
    }
    default:
      return state;
  }
}
