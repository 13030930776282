/**
 * Multiline Chart Editor
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { merge } from 'lodash';
import { ChartEditorProps } from '../common/ChartEditorProps';
import WidgetSettingAccordion from '../common/WidgetAccordion';
import { getDetapointSeries } from '../common/getDatapointSeries';
import StackLineCommonSettings from './StackLineCommonSettings';
import ResetDefaultConfig from '../common/ResetDefaultConfig';
import { useRawDatapoints } from '../../../../features/dashboard/utils/useRawDatapoints';
import { DASHBOARD_ITEM_TYPE } from '../../dashboard-widgets/types/DashboardItemType';
import TranslateText from '../../../../i18n/TranslateText';

const SLMultilineChartEditor: React.FC<ChartEditorProps> = ({
  handleSubmitForm,
  defaultValues,
  widgetType,
  dataConfig
}: ChartEditorProps) => {
  const [settings, setSettings] = useState<any>({
    chartType: 'stack-line',
    chartSettings: Object.keys(defaultValues).length ? defaultValues : {}
  });
  const { getValues } = useFormContext();
  const devices: any[] = dataConfig?.query?.data?.devices;
  const handleUpdate = () => {
    const values = getValues();
    const updatedSettings = merge({}, settings);
    // updating local variable here
    const mergedSettings = {
      ...updatedSettings,
      chartSettings: {
        ...values
      }
    };
    setSettings(mergedSettings);
    handleSubmitForm(mergedSettings);
  };

  const { datapointsList } = useRawDatapoints();
  const DataSpecificConfigs = () => {
    if (widgetType === DASHBOARD_ITEM_TYPE.RAW_DATA) {
      const datapointSeries: Array<any> = getDetapointSeries(devices, datapointsList);
      return (
        <>
          {datapointSeries.map((datapoint) => (
            <WidgetSettingAccordion key={datapoint.title} title={datapoint.title}>
              {TranslateText('stack_line_chart', 'Stack-line Chart')} {datapoint.title}
            </WidgetSettingAccordion>
          ))}
        </>
      );
    }
    return null;
  };
  return (
    <>
      <StackLineCommonSettings
        currentSettings={settings.chartSettings}
        handleUpdate={handleUpdate}
      />
      {/* <WidgetSettingAccordion title="common">
        {
          // TODO: [SLC-4232] Add Sequence component for stack-line chart
        }
      </WidgetSettingAccordion> */}
      <DataSpecificConfigs />
      <ResetDefaultConfig chartType="stack-line" onReset={handleUpdate} />
    </>
  );
};

export default SLMultilineChartEditor;
