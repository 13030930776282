/**
 * Full Row Redirection Editor
 * @author aditya.bhadange@shorelineiot.com
 *
 */
import { Grid } from '@mui/material';
import React, { ReactElement } from 'react';
import { SLCheckbox, SLSelect } from '../../../..';

interface Props {
  value: boolean;
  handleUpdate: (data: any) => void;
}

export default function FullRowRedirection({ value, handleUpdate }: Props): ReactElement {
  const fullRowRedirectionTypes = [
    {
      label: 'Alarms',
      value: 'alarms'
    }
  ];
  const checkBoxChange = (value: boolean): void => {
    handleUpdate({
      fullRowRedirection: value
    });
  };

  return (
    <>
      <Grid container justifyContent="flex-start" spacing={2} alignItems="center">
        <Grid item xs={6}>
          <SLCheckbox
            name="fullRowRedirection"
            label="Full Row Redirection"
            labelTranslationId="full_row_redirection"
            value={value}
            checked={value}
            onChange={(value: boolean) => {
              checkBoxChange(value);
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <SLSelect
            name="fullRowRedirectionType"
            label="Redirection Type"
            labelTranslationId="redirection_type"
            options={fullRowRedirectionTypes}
            defaultValue="alarms"
            disabled
          />
        </Grid>
      </Grid>
    </>
  );
}
