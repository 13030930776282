import { AccordionSummaryClasses, Theme } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
/**
 *
 */
export const AccordionStyle:
  | Partial<
      OverridesStyleRules<
        keyof AccordionSummaryClasses,
        'MuiAccordionSummary',
        Omit<Theme, 'components'>
      >
    >
  | undefined = {
  expandIconWrapper: {
    order: -1
  }
};
