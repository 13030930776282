/**
 * widget settings config Saga
 * @author ashwini.gawade@shorelineiot.com
 */
import { AnyAction } from 'redux';
import { takeEvery, call, put, all } from 'redux-saga/effects';
import * as updateWidgetSettingsActions from './updateWidgetSetting.actions';
import * as UPDATE_WIDGET_SETTING_ACTION_TYPES from './updateWidgetSetting.actionTypes';
import { datapointActions } from '../../device-settings';
import {
  GenericObject,
  removeProgressFor,
  showProgressFor,
  httpPut,
  showToast,
  APISERVICES
} from '../../../../../../framework';
import { SagaIterator } from 'redux-saga';
/**
 * Set widget settings for specified datapoint and user
 */
function setWidgetSettingConfig(data: any) {
  return httpPut(
    `orgs/${data.slug}/devices/${data.deviceId}/widgets/${data.widgetSettings.widget_id}`,
    {
      settings: data.widgetSettings.settings
    },
    APISERVICES.DEVICE_API
  );
}
/**
 *
 * @param action
 */
function* setWidgetSettingSaga(action: AnyAction) {
  const data = action.payload;
  yield put(showProgressFor(`SET_WIDGET_SETTING_${data.dpid}`));
  try {
    const resp: GenericObject = yield call(setWidgetSettingConfig, data);
    yield put(updateWidgetSettingsActions.updateWidgetSettingSuccess(resp));
    if (data.operation === 'HIDE') {
      yield put(showToast(`Hide Datapoint ${data.datapointName} Successful!`, 'success'));
      yield put(datapointActions.hideDatapointSuccess(data.dpid));
    } else if (data.operation === 'UNHIDE') {
      yield put(showToast(`Unhide Datapoint ${data.datapointName} Successful!`, 'success'));
      yield put(datapointActions.unhideDatapointSuccess(data.dpid));
    }
  } catch (error: any) {
    yield put(showToast('Widget Settings could not be updated!', 'error', true));
    yield put(
      updateWidgetSettingsActions.updateWidgetSettingFailure({
        dpid: data.dpid
      })
    );
  } finally {
    yield put(removeProgressFor(`SET_WIDGET_SETTING_${data.dpid}`));
  }
}
/**
 * Watcher saga for Fetch Topic Request
 */
export function* watchUpdateWidgetSettingSaga(): SagaIterator {
  yield all([
    takeEvery(UPDATE_WIDGET_SETTING_ACTION_TYPES.UPDATE_WIDGET_SETTINGS, setWidgetSettingSaga)
  ]);
}
