export interface FetchTourArgs {
  slug: string;
  payload?: { tour_config: any };
}
export interface FetchTourResponse {
  [key: string]: any;
}

export interface UpdateTourResponse {
  message: string;
}

export const TOUR_TAG = {
  TOUR: 'Tour'
};
