/**
 * Chart Configuration helper for datapoints reducer
 * @author mahesh.kedari@shorelineiot.com
 */
import { DatapointsListState } from '../types/datapointsList.state';
/**
 *
 * @param state
 * @param param1
 */
export function onSetLastDataValue(
  state: DatapointsListState,
  { dpid, value }: any
): DatapointsListState {
  const lastDatapoint = state.lastDataValue[dpid];
  // If user has set a value for any datapoint, do not update it with live data
  if (lastDatapoint && lastDatapoint.userSet) {
    return state;
  }
  return {
    ...state,
    lastDataValue: { ...state.lastDataValue, [dpid]: value }
  };
}
/**
 *
 */
export function onSetDataValue(
  state: DatapointsListState,
  { dpid, value }: any
): DatapointsListState {
  return {
    ...state,
    lastDataValue: {
      ...state.lastDataValue,
      [dpid]: { ...value, userSet: true }
    }
  };
}
