/**
 * Live Data Configuration Object Definition
 * @author mahesh.kedari@shorelineiot.com
 */
export enum LIVE_DATA_CONFIG_STATUS {
  INIT,
  FETCHING,
  READY,
  ERROR
}
export interface LiveDataConfigState {
  status: LIVE_DATA_CONFIG_STATUS;
  topic: string;
}
