/**
 * Pivot Settings
 * @author mahesh.kedari@shorelineiot.com
 */
import { PivotConfig } from '@cubejs-client/core';
import { PivotConfigUpdater } from '@cubejs-client/react';
import { Divider } from '@mui/material';
import React, { ReactElement } from 'react';
import { SLPopover } from '../../popover/SLPopover';
import SLAxesSettings from './SLAxesSettings';
import SLMissingDates from './SLMissingDates';

interface Props {
  limit: number;
  pivotConfig?: PivotConfig;
  updatePivotConfig: PivotConfigUpdater;
}
/**
 *
 * @param param0
 * @returns
 */
export default function SLPivotSetting({
  limit,
  pivotConfig,
  updatePivotConfig
}: Props): ReactElement {
  const handleFillMissingDatesUpdate = (fillMissingDates: boolean) =>
    updatePivotConfig.update({
      fillMissingDates,
      limit
    });
  return (
    <SLPopover label="Pivot">
      <SLAxesSettings pivotConfig={pivotConfig} onMove={updatePivotConfig.moveItem} />
      <Divider orientation="horizontal" light />
      <SLMissingDates
        fillMissingDates={!!pivotConfig?.fillMissingDates}
        handleUpdate={handleFillMissingDatesUpdate}
      />
    </SLPopover>
  );
}
