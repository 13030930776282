/**
 * Org Menu Button
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { ReactElement, useEffect, useRef, useLayoutEffect, useState } from 'react';
import { Button, PopoverPosition, Typography } from '@mui/material';
import DownArrow from '@mui/icons-material/ArrowDropDown';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { alertsListActions } from '../../alerts/store';
import { AnomalyActions } from '../../workflow/anomaly-configurator/store';
import { DASHBOARD_LIST_ACTIONS, DEFAULT_DASHBOARD_ACTIONS } from '../../dashboard/store';
import defaultTheme from '../../../framework/theme/default/theme';
import { useStyles } from '../org-list/orgList.styles';
import {
  useSlugContext,
  showToast,
  useThemeContext,
  resetLogoPath,
  setLogoPath,
  hideBackdrop,
  showBackdrop
} from '../../../framework';
import { LightTooltip } from '../../../framework/components/tooltip/Tooltip';
import {
  saveIntoLacalStorage,
  updateThemeData
} from '../../org-settings/customization/constants/personalizationParser';
import { getUserSelf } from '../../user-settings/getUserSelf';
import {
  PERSONALIZE_SETTINGS_STATUS,
  personalizeSettingAction,
  ORG_UPDATE_STATUS,
  orgUpdateAction,
  usePersonalizeSettings,
  useOrgUpdate,
  useLazyFetchUsers
} from '../../org-settings/store';
import {
  Organisation,
  orgSelectActions,
  subOrgListActions,
  getLastSelectedOrg,
  getSelectedOrgName,
  useOrgList,
  useOrgSelector,
  ORG_LIST_STATUS,
  SubOrgFromOrganisation
} from '../store';
import { USER_SELF_STATUS, selfActions, useUserSelf } from '../../user-settings/store';
import { useLazyFetchDevices, useLazyFetchDeviceGroups } from '../../device/store/device.hooks';
import { sidebarId } from '../../../framework/components/sidebar/sidebar.utils';
import { togglePowertrainUserGroupsFilter } from '../../device';

/**
 *
 */
interface Props {
  menuPosition: PopoverPosition | undefined;
  setMenuPosition: Function;
}
/**
 *
 * @param param0
 */
export default function OrgSelectorMenuButon({
  menuPosition,
  setMenuPosition
}: Props): ReactElement {
  const { slug, setSlug } = useSlugContext();
  const { fetchLazyUsers } = useLazyFetchUsers();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [displayName, setDisplayName] = useState<any>('');
  const { theme, setTheme } = useThemeContext();
  const orgListState = useOrgList();
  const userSelf = useUserSelf();

  const orgUpdateState = useOrgUpdate();
  const orgSelector = useOrgSelector();
  const appSettings = usePersonalizeSettings();

  const { fetchLazyDevices } = useLazyFetchDevices();
  const { fetchLazyDeviceGroups } = useLazyFetchDeviceGroups();

  useEffect(() => {
    if (userSelf.status === USER_SELF_STATUS.INIT && slug && slug !== 'org') {
      dispatch(selfActions.fetchSelf(slug));
    }
  }, [dispatch, slug, userSelf.status]);

  useEffect(() => {
    switch (appSettings.status) {
      case PERSONALIZE_SETTINGS_STATUS.READY: {
        if (slug) {
          saveIntoLacalStorage(slug, appSettings?.personalization_info);
        }
        let customTheme = theme;
        if (customTheme) {
          const themeData = appSettings?.personalization_info?.personalization;
          customTheme = updateThemeData(customTheme, themeData, defaultTheme);
          if (setTheme) setTheme(customTheme);
        } else if (setTheme) setTheme(defaultTheme);

        //TODO: favicon variable is not declared anywhere so commenting below
        //once all is stable we need to remove this

        // if (
        //   appSettings?.personalization_info != null &&
        //   Object.prototype.hasOwnProperty.call(
        //     appSettings?.personalization_info?.images,
        //     'favicon'
        //   ) &&
        //   appSettings?.personalization_info?.images?.favicon?.length > 0
        // ) {
        //   favicon.href = appSettings?.personalization_info?.images?.favicon;
        // } else {
        //   favicon.href = ShorelineFavicon;
        // }
        if (
          appSettings?.personalization_info != null &&
          Object.prototype.hasOwnProperty.call(appSettings?.personalization_info?.images, 'logo') &&
          appSettings?.personalization_info?.images?.logo?.length > 0
        ) {
          dispatch(resetLogoPath());
          dispatch(setLogoPath(appSettings?.personalization_info?.images?.logo));
        } else {
          dispatch(resetLogoPath());
        }

        break;
      }
      default:
        break;
    }
  }, [appSettings.status]);
  /**
   *
   * @param lastSelectedOrg
   */
  const handleSwitch = (
    lastSelectedOrg: Organisation | SubOrgFromOrganisation | undefined,
    currentOrg: Organisation | SubOrgFromOrganisation | undefined
  ) => {
    if (orgListState.orgList.length === 0) {
      // No Organisation Found
      dispatch(orgSelectActions.noOrgFound());
      navigate(`/${slug}/app/org/no-org`);
    } else if (currentOrg && currentOrg.slug === lastSelectedOrg?.slug) {
      // current slug is present in org list
      // And last selected org is same as current org. but name is changed .
      dispatch(orgSelectActions.switchOrgSuccess(currentOrg));
      if (slug && slug !== 'org') {
        getUserSelf(slug, dispatch);
        dispatch(personalizeSettingAction.getPersonalizationInfoProgress(slug));
        fetchLazyUsers({ slug });
        dispatch(subOrgListActions.fetchSubOrgList(slug));
      }
    } else if (currentOrg && currentOrg.slug !== lastSelectedOrg?.slug) {
      // Current slug is present in org list and last selected org is not same as current org
      // Send request to backend to update last selected org
      dispatch(orgSelectActions.switchOrg(currentOrg));
      if (slug && slug !== 'org') {
        getUserSelf(slug, dispatch);
        dispatch(personalizeSettingAction.getPersonalizationInfoProgress(slug));
        fetchLazyDevices({ slug });
        fetchLazyDeviceGroups({ slug });
        fetchLazyUsers({ slug });
        dispatch(subOrgListActions.fetchSubOrgList(slug));
        dispatch(togglePowertrainUserGroupsFilter(true));
      }
    } else if (orgListState.orgList.length === 1 && setSlug) {
      // Current slug is not present in org list And org list contains only one organisation
      // Assume that organisation as expected organisation to switch, and proceed
      const orgToSelect = orgListState.orgList[0];
      if (lastSelectedOrg?.slug) {
        const currentSlug = lastSelectedOrg.slug.toLowerCase();
        setSlug(currentSlug);
      } else {
        const currentSlug = orgToSelect.slug.toLowerCase();
        setSlug(currentSlug);
      }
      dispatch(orgSelectActions.switchOrg(lastSelectedOrg));
      if (orgToSelect.slug.toLowerCase() !== 'org') {
        if (!(slug === 'org')) {
          dispatch(
            showToast('Organization does not exist, switching to default org.', 'warning', false)
          );
        }
        getUserSelf(orgToSelect.slug.toLowerCase(), dispatch);
        fetchLazyDevices({ slug: orgToSelect.slug.toLowerCase() });
        fetchLazyDeviceGroups({ slug: orgToSelect.slug.toLowerCase() });
        // SLC-7050 - error message is shown because we are trying to fetch users list with wrong slug which doesn't
        // exists in user's associated org/suborg list.
        fetchLazyUsers({ slug: orgToSelect.slug.toLowerCase() });

        dispatch(
          personalizeSettingAction.getPersonalizationInfoProgress(orgToSelect.slug.toLowerCase())
        );
        dispatch(subOrgListActions.fetchSubOrgList(orgToSelect.slug.toLowerCase()));
      }
    } else if (lastSelectedOrg && lastSelectedOrg.slug && setSlug) {
      // Current slug is not present in org list and list contains one organisation with last selected org flag = true
      // Update slug with last selected org
      const currentSlug = lastSelectedOrg.slug.toLowerCase();
      setSlug(currentSlug);
      dispatch(orgSelectActions.switchOrgSuccess(lastSelectedOrg));
      if (currentSlug !== 'org') {
        if (!(slug === 'org')) {
          dispatch(
            showToast('Organization does not exist, switching to default org.', 'warning', false)
          );
        }
        getUserSelf(currentSlug, dispatch);
        fetchLazyUsers({ slug: currentSlug });
        dispatch(personalizeSettingAction.getPersonalizationInfoProgress(currentSlug));
        dispatch(subOrgListActions.fetchSubOrgList(currentSlug));
      }
    } else {
      // Current slug is not present in org list and also last selected organisation is not present in list.
      // Give choice to user to select organisation
      dispatch(showToast('Please select organisation to switch', 'warning', true));
      dispatch(orgSelectActions.selectOrg());
      navigate(`/${slug}/app/org/org-selector`);
    }
    dispatch(hideBackdrop());
  };
  /**
   *
   */

  useEffect(() => {
    switch (orgListState.status) {
      case ORG_LIST_STATUS.READY:
        if (
          orgSelector.selectedOrg === null ||
          orgSelector?.selectedOrg?.slug !== slug ||
          userSelf.status === USER_SELF_STATUS.INIT
        ) {
          const { lastSelectedOrg, currentOrg } = getLastSelectedOrg(orgListState, slug);
          handleSwitch(lastSelectedOrg, currentOrg);
          break;
        }
        dispatch(hideBackdrop());
        break;
      case ORG_LIST_STATUS.FETCHING_LIST:
        dispatch(showBackdrop());
        break;
      case ORG_LIST_STATUS.ERROR:
        // TODO: [SLC-700] Send user to Error page when organisation fetch operation fails
        dispatch(hideBackdrop());
        break;
      default:
        break;
    }
  }, [orgListState.status, slug, orgListState?.orgList, userSelf.status]);

  const classes = useStyles();
  const openOrgMenu = (event: React.MouseEvent) => {
    if (menuPosition) {
      return;
    }
    event.preventDefault();
    const currentTargetRect = event.currentTarget.getBoundingClientRect();
    setMenuPosition({
      top: currentTargetRect.top,
      left: currentTargetRect.left
    });
  };

  const menuContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let _displayName;
    if (orgUpdateState.orgDetails?.name && orgUpdateState?.status === ORG_UPDATE_STATUS.READY) {
      _displayName = orgUpdateState.orgDetails?.name;
      //Update orgSelector state when org updated and last switch to true becuase we are on same org, and name updated
      const updatedData = {
        ...orgSelector.selectedOrg,
        name: orgUpdateState.orgDetails?.name,
        last_switched_to: true
      };
      dispatch(orgSelectActions.switchOrgSuccess(updatedData));
    } else {
      _displayName = getSelectedOrgName(orgSelector) || '';
    }
    document.title = _displayName === 'Loading' ? 'Shoreline' : _displayName;
    setDisplayName(_displayName);
  }, [orgSelector?.status, orgUpdateState?.status]);

  useEffect(() => {
    /**
     * This useEffect will trigger after switching the org/suborg.
     */
    dispatch(DASHBOARD_LIST_ACTIONS.resetState());
    dispatch(DEFAULT_DASHBOARD_ACTIONS.resetState());
    dispatch(AnomalyActions.anomalyListReset());
    dispatch(alertsListActions.resetAlertsList());
    dispatch(orgUpdateAction.orgDetailsReset());
  }, []);

  useLayoutEffect(() => {
    if (menuContainerRef.current !== null) {
      const node = menuContainerRef.current;
      if (node?.scrollWidth > node?.clientWidth) {
        setOpen(true);
      }
    }
  });
  return (
    <Button
      id={sidebarId.select_org}
      variant="contained"
      size="small"
      color="primary"
      onClick={openOrgMenu}
      className={classes.orgButton}>
      <LightTooltip disableHoverListener={!open} title={displayName || ''} placement="bottom-end">
        <div ref={menuContainerRef}>
          <Typography variant="subtitle1" className={classes.selectorText}>
            {displayName}
          </Typography>
        </div>
      </LightTooltip>

      <DownArrow />
    </Button>
  );
}
