/**
 * SLPanel
 * General purpose panel with Action Header
 */
import React, { PropsWithChildren } from 'react';
import { Paper, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

type Props = PropsWithChildren<any>;
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    background: theme.palette.background.default
  }
}));

export default function SLPanel({ children }: Props) {
  const classes = useStyles();
  return <Paper className={classes.root}>{children}</Paper>;
}
