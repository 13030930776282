/**
 * Get Dashboard List Reducer
 */
import { AnyAction } from 'redux';
import * as ACTION_TYPE from './dashboardList.actionsTypes';
import {
  DashboardListData,
  DashboardListState,
  DashboardListStatus
} from './dashboardList.stateType';

const initialState: DashboardListState = {
  status: DashboardListStatus.INIT,
  dashboardListData: []
};

function getDashboardListProgress(state: DashboardListState): DashboardListState {
  return {
    ...state,
    status: DashboardListStatus.FETCHING
  };
}

function getDashboardListSuccess(
  state: DashboardListState,
  dashboardListData: DashboardListData[]
): DashboardListState {
  return {
    ...state,
    status: DashboardListStatus.READY,
    dashboardListData
  };
}

function getDashboardListFailure(state: DashboardListState): DashboardListState {
  return {
    ...state,
    status: DashboardListStatus.ERROR
  };
}

function updateDashboardList(
  state: DashboardListState,
  updatedDashboard: DashboardListData
): DashboardListState {
  const dashboardListCopy = [...state.dashboardListData];
  dashboardListCopy.forEach((dashboard: any) => {
    if (dashboard.dashboard_uuid === updatedDashboard.dashboard_uuid) {
      dashboard.name = updatedDashboard.name;
    }
  });
  return {
    ...state,
    dashboardListData: dashboardListCopy
  };
}

function resetState(state: DashboardListState): DashboardListState {
  return {
    ...state,
    status: DashboardListStatus.INIT,
    dashboardListData: []
  };
}
/*
    Reducer is a pure function that takes actions and state as input 
    and returns/passes the modified state to the store.
    It is implemented using switch case to update the state according 
    to the ation type required.
*/

export default function dashboardListReducer(
  state: DashboardListState = initialState,
  action: AnyAction
): DashboardListState {
  switch (action.type) {
    case ACTION_TYPE.DASHBOARD_LIST_PROGRESS:
      return getDashboardListProgress(state);
    case ACTION_TYPE.DASHBOARD_LIST_SUCCESS:
      return getDashboardListSuccess(state, action.payload);
    case ACTION_TYPE.DASHBOARD_LIST_FAILURE:
      return getDashboardListFailure(state);
    case ACTION_TYPE.DASHBOARD_LIST_UPDATE:
      return updateDashboardList(state, action.payload);
    case ACTION_TYPE.DASHBOARD_LIST_RESET_STATE:
      return resetState(state);
    default:
      return state;
  }
}
