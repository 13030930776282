import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  buttonStyle: {
    margin: '0 auto',
    alignSelf: 'center'
  },
  imageStyle: {
    display: 'flex',
    alignSelf: 'center',
    margin: '0 auto',
    paddingTop: 45
  },
  textStyle: {
    margin: '0 auto',
    alignSelf: 'center',
    textAlign: 'center'
  },

  boxStyle: {
    marginTop: '25px',
    margin: '0 auto'
  },

  sectionDesktop: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }
}));
