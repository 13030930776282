/**
 * SL Chart List
 * @author mahesh.kedari@shorelineiot.com
 */
import React from 'react';
import SLAreaChartEditor from './area-chart/SLAreaChartEditor';
import SLAreaChartView from './area-chart/SLAreaChartView';
import SLBarChartEditor from './bar-chart/SLBarChartEditor';
import SLBarChartView from './bar-chart/SLBarChartView';
import SLColumnChartEditor from './column-chart/SLColumnChartEditor';
import SLColumnChartView from './column-chart/SLColumnChartView';
import { ChartEditorProps } from './common/ChartEditorProps';
import { ChartViewProps } from './common/ChartViewProps';
import SLLineChartEditor from './line-chart/SLLineChartEditor';
import SLLineChartView from './line-chart/SLLineChartView';
import SLNumberEditor from './number/SLNumberEditor';
import SLNumberView from './number/SLNumberView';
import SLPieChartEditor from './pie-chart/SLPieChartEditor';
import SLPieChartView from './pie-chart/SLPieChartView';
import SLScatterPlotEditor from './scatter-plot-chart/SLScatterPlotEditor';
import SLScatterPlotView from './scatter-plot-chart/SLScatterPlotView';
import SLTableEditor from './table/SLTableEditor';
import SLTableView from './table/SLTableView';
import SLGaugeChartEditor from './gauge/SLGaugeChartEditor';
import SLGaugeChartView from './gauge/SLGaugeChartView';
import SLSolidGaugeChartEditor from './solid-gauge/SLSolidGaugeChartEditor';
import SLSolidGaugeChartView from './solid-gauge/SLSolidGaugeChartView';
import StackLineEditor from './stack-line/SLStacklineChartEditor';
import StackLineView from './stack-line/SLStacklineChartView';
import SLBatteryEditor from './battery/SLBatteryEditor';
import SLBatteryView from './battery/SLBatteryView';
import SLDonutChartEditor from './donut-chart/SLDonutChartEditor';
import SLDonutChartView from './donut-chart/SLDonutChartView';
import SLNoneSelectionEditor from './none-selection/SLNoneSelectionEditor';
import SLNoneSelectionView from './none-selection/SLNoneSelectionView';
import { DASHBOARD_ITEM_TYPE } from '../dashboard-widgets/types/DashboardItemType';
import SLWaterfallChartEditor from './waterfall/SLWaterfallChartEditor';
import SLWaterfallChartViewer from './waterfall/SLWaterfallChartView';
import SLBatteryStatusViewer from './battery-status/SLBatteryStatusViewer';
import SLBatteryStatusEditor from './battery-status/SLBatteryStatusEditor';
import MapChartEditor from './map-chart/MapChartEditor';
import MapChartViewer from './map-chart/MapChartViewer';
import { LeakDetectionEditor } from './leak-detection/LeakDetectionEditor';
import { LeakDetectionViewer } from './leak-detection/LeakDetectionViewer';
import DialWidgetEditor from './dial-widget/DialWidgetEditor';
import DialWidgetViewer from './dial-widget/DialWidgetViewer';
// Predictive Maintenance Components
import PlantHealthEditor from './predictive-maintenance/plant-health/PlantHealthEditor';
import PlantHealthViewer from './predictive-maintenance/plant-health/PlantHealthViewer';
import PlantHealthTrendsEditor from './predictive-maintenance/plant-health-trends/PlantHealthTrendsEditor';
import PlantHealthTrendsViewer from './predictive-maintenance/plant-health-trends/PlantHealthTrendsViewer';
import AssetSeverityAgeEditor from './predictive-maintenance/asset-severity-age/AssetSeverityAgeEditor';
import AssetSeverityAgeViewer from './predictive-maintenance/asset-severity-age/AssetSeverityAgeViewer';

export enum CHART_TYPE {
  NONE = 'none',
  LINE = 'line',
  AREA = 'area',
  BAR = 'bar',
  COLUMN = 'column',
  PIE = 'pie',
  NUMBER = 'number',
  TABLE = 'table',
  SCATTER_PLOT = 'scatter_plot',
  GAUGE = 'gauge',
  SOLID_GAUGE = 'solidgauge',
  STACK_LINE = 'stack-line',
  CARTESIAN = 'Cartesian',
  BATTERY = 'battery-indicator',
  DONUT = 'donut',
  WATERFALL = 'waterfall',
  BATTERY_STATUS = 'battery_status',
  MAP = 'map',
  LEAK_DETECTION = 'leak_detection',
  DIAL = 'dial',
  // Predictive Maintenance Components
  PLANT_HEALTH = 'pm_plant_health',
  PLANT_HEALTH_TRENDS = 'pm_plant_health_trends',
  ASSET_SEVERITY_AGE = 'pm_asset_severity_age'
}
export interface SLChart {
  label: string;
  value: CHART_TYPE;
  viewer: React.FC<ChartViewProps>;
  editor: React.FC<ChartEditorProps>;
  supports: Array<DASHBOARD_ITEM_TYPE>;
}

export const SLChartList: Array<SLChart> = [
  {
    label: '',
    value: CHART_TYPE.NONE,
    editor: SLNoneSelectionEditor,
    viewer: SLNoneSelectionView,
    supports: [DASHBOARD_ITEM_TYPE.ANALYTICS, DASHBOARD_ITEM_TYPE.RAW_DATA]
  },
  {
    label: 'Table',
    value: CHART_TYPE.TABLE,
    editor: SLTableEditor,
    viewer: SLTableView,
    supports: [DASHBOARD_ITEM_TYPE.ANALYTICS]
  },
  {
    label: 'Line',
    value: CHART_TYPE.LINE,
    viewer: SLLineChartView,
    editor: SLLineChartEditor,
    supports: [DASHBOARD_ITEM_TYPE.ANALYTICS, DASHBOARD_ITEM_TYPE.RAW_DATA]
  },
  {
    label: 'Area',
    value: CHART_TYPE.AREA,
    editor: SLAreaChartEditor,
    viewer: SLAreaChartView,
    supports: [DASHBOARD_ITEM_TYPE.ANALYTICS, DASHBOARD_ITEM_TYPE.RAW_DATA]
  },
  {
    label: 'Bar',
    value: CHART_TYPE.BAR,
    editor: SLBarChartEditor,
    viewer: SLBarChartView,
    supports: [DASHBOARD_ITEM_TYPE.ANALYTICS]
  },
  {
    label: 'Column',
    value: CHART_TYPE.COLUMN,
    editor: SLColumnChartEditor,
    viewer: SLColumnChartView,
    supports: [DASHBOARD_ITEM_TYPE.ANALYTICS, DASHBOARD_ITEM_TYPE.RAW_DATA]
  },
  {
    label: 'Pie',
    value: CHART_TYPE.PIE,
    editor: SLPieChartEditor,
    viewer: SLPieChartView,
    supports: [DASHBOARD_ITEM_TYPE.ANALYTICS]
  },
  {
    label: 'Donut',
    value: CHART_TYPE.DONUT,
    editor: SLDonutChartEditor,
    viewer: SLDonutChartView,
    supports: [DASHBOARD_ITEM_TYPE.ANALYTICS]
  },
  {
    label: 'Scatter',
    value: CHART_TYPE.SCATTER_PLOT,
    editor: SLScatterPlotEditor,
    viewer: SLScatterPlotView,
    supports: [DASHBOARD_ITEM_TYPE.ANALYTICS, DASHBOARD_ITEM_TYPE.RAW_DATA]
  },
  {
    label: 'Number',
    value: CHART_TYPE.NUMBER,
    editor: SLNumberEditor,
    viewer: SLNumberView,
    supports: [DASHBOARD_ITEM_TYPE.ANALYTICS]
  },

  {
    label: 'Gauge',
    value: CHART_TYPE.GAUGE,
    editor: SLGaugeChartEditor,
    viewer: SLGaugeChartView,
    supports: [DASHBOARD_ITEM_TYPE.ANALYTICS]
  },
  {
    label: 'Solid Gauge',
    value: CHART_TYPE.SOLID_GAUGE,
    editor: SLSolidGaugeChartEditor,
    viewer: SLSolidGaugeChartView,
    supports: [DASHBOARD_ITEM_TYPE.ANALYTICS]
  },
  {
    label: 'Stack line',
    value: CHART_TYPE.STACK_LINE,
    editor: StackLineEditor,
    viewer: StackLineView,
    supports: [DASHBOARD_ITEM_TYPE.ANALYTICS, DASHBOARD_ITEM_TYPE.RAW_DATA]
  },
  {
    label: 'Battery',
    value: CHART_TYPE.BATTERY,
    editor: SLBatteryEditor,
    viewer: SLBatteryView,
    supports: [DASHBOARD_ITEM_TYPE.ANALYTICS]
  },
  {
    label: 'Waterfall',
    value: CHART_TYPE.WATERFALL,
    editor: SLWaterfallChartEditor,
    viewer: SLWaterfallChartViewer,
    supports: [DASHBOARD_ITEM_TYPE.WATERFALL_CHART]
  },
  {
    label: 'Battery Status',
    value: CHART_TYPE.BATTERY_STATUS,
    editor: SLBatteryStatusEditor,
    viewer: SLBatteryStatusViewer,
    supports: [DASHBOARD_ITEM_TYPE.BATTERY_STATUS]
  },
  {
    label: 'Map',
    value: CHART_TYPE.MAP,
    editor: MapChartEditor,
    viewer: MapChartViewer,
    supports: [DASHBOARD_ITEM_TYPE.MAP_CHART]
  },
  {
    label: 'Leak Detection',
    value: CHART_TYPE.LEAK_DETECTION,
    editor: LeakDetectionEditor,
    viewer: LeakDetectionViewer,
    supports: [DASHBOARD_ITEM_TYPE.LEAK_DETECTION]
  },
  {
    label: 'Dial Widget',
    value: CHART_TYPE.DIAL,
    editor: DialWidgetEditor,
    viewer: DialWidgetViewer,
    supports: [DASHBOARD_ITEM_TYPE.DIAL]
    // Predictive Maintenance Components
  },
  {
    label: 'Pie Chart',
    value: CHART_TYPE.PLANT_HEALTH,
    editor: PlantHealthEditor,
    viewer: PlantHealthViewer,
    supports: [DASHBOARD_ITEM_TYPE.PLANT_HEALTH]
  },
  {
    label: 'Line Chart',
    value: CHART_TYPE.PLANT_HEALTH_TRENDS,
    editor: PlantHealthTrendsEditor,
    viewer: PlantHealthTrendsViewer,
    supports: [DASHBOARD_ITEM_TYPE.PLANT_HEALTH_TRENDS]
  },
  {
    label: 'Table',
    value: CHART_TYPE.ASSET_SEVERITY_AGE,
    editor: AssetSeverityAgeEditor,
    viewer: AssetSeverityAgeViewer,
    supports: [DASHBOARD_ITEM_TYPE.ASSET_SEVERITY_AGE]
  }
];
/**
 *
 * @param chartType
 * @returns
 */
export function getChartViewer(chartType: CHART_TYPE): React.FC<ChartViewProps> | null {
  const SelectedChart: any = SLChartList.find((chart: SLChart) => {
    return chart.value === chartType;
  });
  return SelectedChart ? SelectedChart.viewer : null;
}
/**
 *
 * @param chartType
 * @returns
 */
export function getChartEditor(chartType: CHART_TYPE): React.FC<ChartEditorProps> | null {
  const SelectedChart: any = SLChartList.find((chart: SLChart) => {
    return chart.value === chartType;
  });
  return SelectedChart ? SelectedChart.editor : null;
}
