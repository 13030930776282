/**
 * Sub-Organisation List Reducer
 * @author meghneel.adke@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTIONS from './actions/subOrgList.actionTypes';
import { SubOrgListState, ORG_LIST_STATUS } from '../types/OrgListState';
import { Suborg } from '../orgs';

const initialState: SubOrgListState = {
  status: ORG_LIST_STATUS.UNKNOWN,
  orgList: []
};
/**
 *
 * @param state old state
 */
function onFetchSubOrgList(state: SubOrgListState): SubOrgListState {
  return {
    ...state,
    status: ORG_LIST_STATUS.FETCHING_LIST
  };
}
/**
 *
 * @param state Old State
 * @param orgList Organisation List
 */
function onFetchSubOrgListSuccess(state: SubOrgListState, orgList: Suborg[]): SubOrgListState {
  return {
    ...state,
    status: ORG_LIST_STATUS.READY,
    orgList
  };
}
/**
 *
 * @param state Old State
 * @param error Error Object
 */
function onFetchSubOrgListFailure(state: SubOrgListState): SubOrgListState {
  return {
    ...state,
    status: ORG_LIST_STATUS.ERROR
  };
}
/**
 *
 * @param state Old State
 * @param action Action to be performed
 */
export default function subOrgListReducer(
  state: SubOrgListState = initialState,
  action: AnyAction
): SubOrgListState {
  switch (action.type) {
    case ACTIONS.FETCH_SUB_ORG_LIST_PROGRESS:
      return onFetchSubOrgList(state);
    case ACTIONS.FETCH_SUB_ORG_LIST_SUCCESS:
      return onFetchSubOrgListSuccess(state, action.payload);
    case ACTIONS.FETCH_SUB_ORG_LIST_FAILURE:
      return onFetchSubOrgListFailure(state);
    default:
      return state;
  }
}
