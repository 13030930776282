import { AnyAction } from 'redux';
import * as ACTION_TYPE from './actions/modbus.actionTypes';
import { AddModbusState } from './types/AddModbusState';
import { ADD_MODBUS_STATUS } from './types/AddModbusStatus';

let arrayCoils: Array<{}> = [];
let arrayDiscreteInputs: Array<{}> = [];
let arrayInputRegister: Array<{}> = [];
let arrayHoldingRegister: Array<{}> = [];
const initialState: AddModbusState = {
  status: ADD_MODBUS_STATUS.INIT,
  form1Object: Object,
  coilsObject: [],
  discreteInputsObject: [],
  inputRegisterObject: [],
  holdingRegisterObject: []
};
function addForm1Object(state: AddModbusState, object: any): AddModbusState {
  return {
    ...state,
    form1Object: object
  };
}

function addCoilsObject(state: AddModbusState, object: any): AddModbusState {
  arrayCoils.push({
    name: object.name,
    address: object.address,
    valueOne: object.valueOne,
    valueTwo: object.valueTwo,
    periodicWriteValue: object.periodicWriteValue,
    isChecked: object.isChecked,
    subDataPointType: object.subDataPointType
  });
  return {
    ...state,
    coilsObject: arrayCoils
  };
}

function deleteCoilsObject(state: AddModbusState, data: any): AddModbusState {
  arrayCoils.splice(data, 1);
  return {
    ...state,
    coilsObject: arrayCoils
  };
}

function editCoilsObject(state: AddModbusState, dataPayload: any): AddModbusState {
  Object.entries(arrayCoils).forEach(([key, value]) => {
    if (key) {
      if (key === dataPayload.keyEdit) {
        const valueObject = Object(value);
        valueObject.name = dataPayload.data.name;
        valueObject.address = dataPayload.data.address;
        valueObject.valueOne = dataPayload.data.valueOne;
        valueObject.valueTwo = dataPayload.data.valueTwo;
        valueObject.isChecked = dataPayload.data.isChecked;
        valueObject.periodicWriteValue = dataPayload.data.periodicWriteValue;
      }
    }
  });
  return {
    ...state,
    coilsObject: arrayCoils
  };
}

function addDiscreteInputsObject(state: AddModbusState, object: any): AddModbusState {
  arrayDiscreteInputs.push({
    name: object.name,
    address: object.address,
    valueOne: object.valueOne,
    valueTwo: object.valueTwo,
    subDataPointType: object.subDataPointType
  });
  return {
    ...state,
    discreteInputsObject: arrayDiscreteInputs
  };
}

function deleteDiscreteInputsObject(state: AddModbusState, data: any): AddModbusState {
  arrayDiscreteInputs.splice(data, 1);
  return {
    ...state,
    discreteInputsObject: arrayDiscreteInputs
  };
}

function editDiscreteInputsObject(state: AddModbusState, dataPayload: any): AddModbusState {
  Object.entries(arrayDiscreteInputs).forEach(([key, value]) => {
    if (key) {
      if (key === dataPayload.keyEdit) {
        const valueObject = Object(value);
        valueObject.name = dataPayload.data.name;
        valueObject.address = dataPayload.data.address;
        valueObject.valueOne = dataPayload.data.valueOne;
        valueObject.valueTwo = dataPayload.data.valueTwo;
      }
    }
  });
  return {
    ...state,
    discreteInputsObject: arrayDiscreteInputs
  };
}

function addHoldingRegisterObject(state: AddModbusState, object: any): AddModbusState {
  arrayHoldingRegister.push({
    name: object.name,
    address: object.address,
    unit: object.unit,
    otherUnit: object.otherUnit,
    dataType: object.dataType,
    bitOffset: object.bitOffset,
    bitLength: object.bitLength,
    subType: object.subType,
    states: object.states,
    isChecked: object.isChecked,
    periodicWriteValue: object.periodicWriteValue,
    raw_min: object.raw_min,
    raw_max: object.raw_max,
    scaled_min: object.scaled_min,
    scaled_max: object.scaled_max,
    math_op1: object.math_op1,
    math_op_val1: object.math_op_val1,
    math_op2: object.math_op2,
    math_op_val2: object.math_op_val2,
    subDataPointType: object.subDataPointType
  });
  return {
    ...state,
    holdingRegisterObject: arrayHoldingRegister
  };
}

function deleteHoldingRegisterObject(state: AddModbusState, data: any): AddModbusState {
  arrayHoldingRegister.splice(data, 1);
  return {
    ...state,
    holdingRegisterObject: arrayHoldingRegister
  };
}

function editHoldingRegisterObject(state: AddModbusState, dataPayload: any): AddModbusState {
  Object.entries(arrayHoldingRegister).forEach(([key, value]) => {
    if (key) {
      if (key === dataPayload.keyEdit) {
        const valueObject = Object(value);
        valueObject.name = dataPayload.data.name;
        valueObject.address = dataPayload.data.address;
        valueObject.unit = dataPayload.data.unit;
        valueObject.otherUnit = dataPayload.data.otherUnit;
        valueObject.dataType = dataPayload.data.dataType;
        valueObject.bitOffset = dataPayload.data.bitOffset;
        valueObject.bitLength = dataPayload.data.bitLength;
        valueObject.subType = dataPayload.data.subType;
        valueObject.states = dataPayload.data.states;
        valueObject.isChecked = dataPayload.data.isChecked;
        valueObject.periodicWriteValue = dataPayload.data.periodicWriteValue;
        valueObject.raw_min = dataPayload.data.raw_min;
        valueObject.raw_max = dataPayload.data.raw_max;
        valueObject.scaled_min = dataPayload.data.scaled_min;
        valueObject.scaled_max = dataPayload.data.scaled_max;
        valueObject.math_op1 = dataPayload.data.math_op1;
        valueObject.math_op_val1 = dataPayload.data.math_op_val1;
        valueObject.math_op2 = dataPayload.data.math_op2;
        valueObject.math_op_val2 = dataPayload.data.math_op_val2;
      }
    }
  });
  return {
    ...state,
    holdingRegisterObject: arrayHoldingRegister
  };
}

function addInputRegisterObject(state: AddModbusState, object: any): AddModbusState {
  arrayInputRegister.push({
    name: object.name,
    address: object.address,
    unit: object.unit,
    otherUnit: object.otherUnit,
    dataType: object.dataType,
    bitOffset: object.bitOffset,
    bitLength: object.bitLength,
    subType: object.subType,
    states: object.states,
    raw_min: object.raw_min,
    raw_max: object.raw_max,
    scaled_min: object.scaled_min,
    scaled_max: object.scaled_max,
    math_op1: object.math_op1,
    math_op_val1: object.math_op_val1,
    math_op2: object.math_op2,
    math_op_val2: object.math_op_val2,
    subDataPointType: object.subDataPointType
  });
  return {
    ...state,
    inputRegisterObject: arrayInputRegister
  };
}

function deleteInputRegisterObject(state: AddModbusState, data: any): AddModbusState {
  arrayInputRegister.splice(data, 1);
  return {
    ...state,
    inputRegisterObject: arrayInputRegister
  };
}

function editInputRegisterObject(state: AddModbusState, dataPayload: any): AddModbusState {
  Object.entries(arrayInputRegister).forEach(([key, value]) => {
    if (key) {
      if (key === dataPayload.keyEdit) {
        const valueObject = Object(value);
        valueObject.name = dataPayload.data.name;
        valueObject.address = dataPayload.data.address;
        valueObject.unit = dataPayload.data.unit;
        valueObject.otherUnit = dataPayload.data.otherUnit;
        valueObject.dataType = dataPayload.data.dataType;
        valueObject.bitOffset = dataPayload.data.bitOffset;
        valueObject.bitLength = dataPayload.data.bitLength;
        valueObject.subType = dataPayload.data.subType;
        valueObject.states = dataPayload.data.states;
        valueObject.raw_min = dataPayload.data.raw_min;
        valueObject.raw_max = dataPayload.data.raw_max;
        valueObject.scaled_min = dataPayload.data.scaled_min;
        valueObject.scaled_max = dataPayload.data.scaled_max;
        valueObject.math_op1 = dataPayload.data.math_op1;
        valueObject.math_op_val1 = dataPayload.data.math_op_val1;
        valueObject.math_op2 = dataPayload.data.math_op2;
        valueObject.math_op_val2 = dataPayload.data.math_op_val2;
      }
    }
  });
  return {
    ...state,
    inputRegisterObject: arrayInputRegister
  };
}
function addModbusProgress(state: AddModbusState): AddModbusState {
  return {
    ...state,
    status: ADD_MODBUS_STATUS.PROGRESS
  };
}

function addModbusSuccess(state: AddModbusState, action: AnyAction): AddModbusState {
  return {
    ...state,
    status: ADD_MODBUS_STATUS.SUCCESS,
    modbusDetails: action.data
  };
}

function addModbusFailure(state: AddModbusState): AddModbusState {
  return {
    ...state,
    status: ADD_MODBUS_STATUS.ERROR
  };
}

function resetModbusDetails() {
  arrayCoils = [];
  arrayDiscreteInputs = [];
  arrayInputRegister = [];
  arrayHoldingRegister = [];
  return {
    ...initialState
  };
}
/**
 *
 * @param state
 * @param action
 */
export default function addModbusReducer(
  state: AddModbusState = initialState,
  action: AnyAction
): AddModbusState {
  switch (action.type) {
    case ACTION_TYPE.ADD_MODBUS_PROGRESS:
      return addModbusProgress(state);
    case ACTION_TYPE.ADD_MODBUS_SUCCESS:
      return addModbusSuccess(state, action);
    case ACTION_TYPE.ADD_MODBUS_FAILURE:
      return addModbusFailure(state);
    case ACTION_TYPE.ADD_MODBUS_RESET:
      return resetModbusDetails();
    case ACTION_TYPE.FORM1_OBJECT:
      return addForm1Object(state, action.payload);
    case ACTION_TYPE.ADD_COILS_OBJECT:
      return addCoilsObject(state, action.payload);
    case ACTION_TYPE.DELETE_COILS_OBJECT:
      return deleteCoilsObject(state, action.payload);
    case ACTION_TYPE.EDIT_COILS_OBJECT:
      return editCoilsObject(state, action.payload);
    case ACTION_TYPE.ADD_DISCRETE_INPUTS_OBJECT:
      return addDiscreteInputsObject(state, action.payload);
    case ACTION_TYPE.DELETE_DISCRETE_INPUTS_OBJECT:
      return deleteDiscreteInputsObject(state, action.payload);
    case ACTION_TYPE.EDIT_DISCRETE_INPUTS_OBJECT:
      return editDiscreteInputsObject(state, action.payload);
    case ACTION_TYPE.ADD_INPUT_REGISTER_OBJECT:
      return addInputRegisterObject(state, action.payload);
    case ACTION_TYPE.DELETE_INPUT_REGISTER_OBJECT:
      return deleteInputRegisterObject(state, action.payload);
    case ACTION_TYPE.EDIT_INPUT_REGISTER_OBJECT:
      return editInputRegisterObject(state, action.payload);
    case ACTION_TYPE.ADD_HOLDING_REGISTER_OBJECT:
      return addHoldingRegisterObject(state, action.payload);
    case ACTION_TYPE.DELETE_HOLDING_REGISTER_OBJECT:
      return deleteHoldingRegisterObject(state, action.payload);
    case ACTION_TYPE.EDIT_HOLDING_REGISTER_OBJECT:
      return editHoldingRegisterObject(state, action.payload);
    default:
      return state;
  }
}
