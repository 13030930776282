/**
 * Unauthenticated Header Styles
 * @author mahesh.kedari@shorelineiot.com
 */
import makeStyles from '@mui/styles/makeStyles';
import { Colors } from '../../../../framework';

export const useStyles = makeStyles(() => ({
  root: {
    marginTop: '-10px',
    marginLeft: '-30px',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  toolBar: {
    background: '#f6f6f6'
  },
  link: {
    padding: '0px 10px',
    marginTop: '-50px',
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'none'
    },
    color: Colors.primaryText
  }
}));
