import { AnyAction } from 'redux';
import * as ACTION_TYPES from './alerts.actionTypes';
/**
 *
 */
export function getSensorAlert(data: any): AnyAction {
  return {
    type: ACTION_TYPES.FETCH_SENSOR_ALERT_PROGRESS,
    payload: data
  };
}
/**
 *
 */
export function getSensorAlertSuccess(data: any): AnyAction {
  return {
    type: ACTION_TYPES.FETCH_SENSOR_ALERT_SUCCESSS,
    payload: data
  };
}
/**
 *
 */
export function getSensorAlertFailure(error: any): AnyAction {
  return {
    type: ACTION_TYPES.FETCH_SENSOR_ALERT_FAILURE,
    payload: error
  };
}
