/**
 *
 */
import makeStyles from '@mui/styles/makeStyles';

import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles(() =>
  createStyles({
    backdrop: {
      color: '#20436c',
      zIndex: 2,
      backgroundColor: 'rgba(0, 0, 0, 0)'
    },
    backdropText: {
      width: 'max-content'
    },
    blurredBackground: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'white',
      filter: 'blur(12px)',
      zIndex: -1
    },
    parentDiv: {
      position: 'relative',
      height: '120px',
      width: '150px',
      textAlign: 'center'
    }
  })
);
