import { AnyAction } from 'redux';
import * as ACTIONS from './rawDeviceDatapoints.actionTypes';
import {
  RawDeviceDatapointsState,
  RAW_DEVICE_DATAPOINTS_STATUS
} from './rawDeviceDatapoints.stateType';

const initialState: RawDeviceDatapointsState = {
  status: RAW_DEVICE_DATAPOINTS_STATUS.INIT,
  datapointsList: [],
  devicesList: [],
  selectedDatapoints: [],
  selectedDevices: []
};
/**
 *
 * @param state
 * @param payload
 * @returns
 */
function handleFetchRawDatapoints(
  state: RawDeviceDatapointsState,
  payload: any
): RawDeviceDatapointsState {
  return {
    ...state,
    status: RAW_DEVICE_DATAPOINTS_STATUS.FETCHING,
    devicesList: payload.deviceIds
  };
}
/**
 *
 * @param state
 * @param payload
 * @returns
 */
function handleRawDatapointsSuccess(
  state: RawDeviceDatapointsState,
  payload: any
): RawDeviceDatapointsState {
  return {
    ...state,
    status: RAW_DEVICE_DATAPOINTS_STATUS.READY,
    datapointsList: payload.datapointsList
  };
}
/**
 *
 * @param state
 * @returns
 */
function handleRawDatapointsFailure(state: RawDeviceDatapointsState): RawDeviceDatapointsState {
  return {
    ...state,
    status: RAW_DEVICE_DATAPOINTS_STATUS.ERROR,
    datapointsList: []
  };
}

function handleResetRawDatapointsState(): RawDeviceDatapointsState {
  return {
    ...initialState
  };
}
/**
 *
 * @param state
 * @param action
 * @returns
 */
export default function rawDatapointsReducer(
  state: RawDeviceDatapointsState = initialState,
  action: AnyAction
): RawDeviceDatapointsState {
  switch (action.type) {
    case ACTIONS.FETCH_RAW_DATAPOINTS:
      return handleFetchRawDatapoints(state, action.payload);
    case ACTIONS.FETCH_RAW_DATAPOINTS_SUCCESS:
      return handleRawDatapointsSuccess(state, action.payload);
    case ACTIONS.FETCH_RAW_DATAPOINTS_FAILURE:
      return handleRawDatapointsFailure(state);
    case ACTIONS.RESET_RAW_DATAPOINTS_STATE:
      return handleResetRawDatapointsState();
    default:
      return state;
  }
}
