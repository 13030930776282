/**
 * Subscriptions Saga
 * @author mahesh.kedari@shorelineiot.com
 */
import { put, takeEvery } from 'redux-saga/effects';
import * as ACTIONS from './subscriptions.actionTypes';
import { showToast } from '../../../framework';
import { SagaIterator } from 'redux-saga';
/**
 *
 */
function* subscriptionSaga() {
  yield put(showToast('Error while resetting subscription state', 'error', true));
}
/**
 *
 */
export default function* watchSubscriptionSaga(): SagaIterator {
  yield takeEvery(ACTIONS.SUBSCRIPTION_RESET, subscriptionSaga);
}
