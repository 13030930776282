/**
 * Verification Reducer
 * @author swapnil.kshirsagar@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTIONS from '../actions/verification.actionTypes';

export const initialState = {
  isLoading: false,
  isLinkSent: false,
  error: false,
  messages: []
};

function onVerificationProgress(state: any) {
  return {
    ...state,
    isLoading: true,
    isLinkSent: false,
    messages: []
  };
}
function onVerificationSuccess(state: any, action: AnyAction) {
  const messages = [...state.messages, { type: 'SUCCESS', message: action.payload }];
  return {
    ...state,
    isLinkSent: true,
    isLoading: false,
    messages
  };
}

function onVerificationFailure(state: any, action: AnyAction) {
  const messages = [...state.messages, { type: 'ERROR', message: action.payload }];
  return {
    ...state,
    isLinkSent: false,
    isLoading: false,
    messages
  };
}
/**
 *
 * @param state old state
 * @param action Action to perform
 */
export default function verificationReducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case ACTIONS.VERIFICATION_PROGERSS:
      return onVerificationProgress(state);
    case ACTIONS.VERIFICATION_SUCCESS:
      return onVerificationSuccess(state, action);
    case ACTIONS.VERIFICATION_FAILURE:
      return onVerificationFailure(state, action);
    default:
      return state;
  }
}
