/**
 * Social Login Styles
 * @author mahesh.kedari@shorelineiot.com
 */
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 20,
    marginBottom: 10
  },
  avatar: {
    backgroundColor: theme.palette.text.secondary,
    '@media (min-width:600px)': {
      marginLeft: '-12px'
    },
    '@media (min-width:960px)': {
      marginLeft: '-8.50px'
    },
    '@media (min-width:1280px)': {
      marginLeft: '-8.3px'
    },
    '@media (min-width:1920px)': {
      marginLeft: '-5px'
    }
  },
  socialButton: {
    width: '11.438',
    justifyContent: 'flex-start',
    paddingLeft: '7px',
    '@media(max-width: 600px)': {
      width: '15.688',
      paddingLeft: '16px'
    },
    '@media (min-width:600px)': {
      width: '12.688',
      paddingLeft: '16px',
      '@media (max-width:1024px)': {
        width: '16.938rem'
      }
    },
    '@media (min-width:1280px)': {
      width: '21.875rem'
    },
    '@media (min-width:1920px)': {
      width: '25.9rem'
    },
    color: theme.palette.text.secondary
  },
  google: {
    backgroundColor: '#db5247',
    '&:hover': {
      backgroundColor: '#d63d31'
    }
  },
  linkedIn: {
    backgroundColor: '#3179b5',
    '&:hover': {
      backgroundColor: '#1E639B'
    }
  },
  icon: {
    width: 12,
    height: 12,

    '@media (min-width:600px)': {
      width: 18,
      height: 18
    },
    '@media (min-width:960px)': {
      width: 19,
      height: 19
    },
    '@media (min-width:1280px)': {
      width: 28,
      height: 28
    }
  }
}));
export default useStyles;
