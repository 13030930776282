/**
 * SL Table Component
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { forwardRef, ReactNode, useCallback, useEffect, useState } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
  MRT_Cell,
  MRT_Row,
  MRT_GlobalFilterTextField,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFullScreenButton,
  MRT_TableInstance,
  MRT_Column
} from 'material-react-table';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import SwapVertIcon from '@mui/icons-material/SwapVert';

import { Box, Grid, Typography } from '@mui/material';
import { ArrowUpward } from '@mui/icons-material';

import {
  allMultiSelectOptionsHaveLength,
  DisableCellClickFunc,
  getColumnFiltersDefaultValuesInTitles,
  getColumnFiltersDefaultValues,
  getMultiSelectColumnName,
  getSelectedLanguageLocalization
} from './sltable.utils';
import { Colors } from '../../constants';
import { SLEditActionButtons } from './SLEditActionButtons';
import { EditIcon } from '../../icons';
import SLExportButton from './SLExportButton';
import { ActionsEditIcon, ACTION_ICON_CLASS_NAME } from '..';
import {
  ColumnFilter,
  PaginationState,
  SelectedRows,
  SLTableProps,
  SortingState
} from './slTable.types';
import MaterialColumns from './SLTable.column';
import { INITIAL_VALUE } from './SlTable.constant';
import { useSidebarOpenState } from '../sidebar/sidebar.slector';
import { HEADER_HEIGHT, SIDEBAR_WIDTH } from '../full-screen-wrapper/FullScreenWrapper';
import { SLReloadPageButton } from './SLReloadPageButton';
import { toSnakeCase } from '../../../i18n/translation.helper';
import TranslateText from '../../../i18n/TranslateText';
import { useUserSelf } from '../../../features/user-settings';

function SLTable<T extends Record<string, any>>({
  columns,
  data,
  title = undefined,
  enableColumnFilters,
  enableSearch,
  enableHiding = true,
  enableColumnActions = true,
  enableDensityToggle,
  enableTopToolbar,
  enableBottomToolbar,
  enablePagination,
  enableSorting = true,
  enableMultiSort,
  enableRowSelection,
  enableFullScreenToggle,
  enableReloadPageButton,
  enableRowActions,
  enableColumnResizing = true,
  columnResizeMode = 'onChange',
  muiEditTextFieldProps,
  enableColumnDragging,
  enableColumnFilterModes = false,
  enableClickToCopy,
  enableColumnOrdering,
  enablePinning = true,
  initialColumnPinningState = { left: [], right: [] },
  passTableRef,
  initialColumnVisibilityState = {},
  enableGrouping,
  onColumnFiltersChange,
  showColumnFilters,
  positionCreatingRow,
  enableEditing,
  createDisplayMode = 'row',
  editDisplayMode = 'row',
  disableEditRowCustomFn,
  customRowEditOnClickHandler,
  positionActionsColumn,
  RowActionsButtons,
  density,
  TopToolbarCustomActionButtons,
  onReloadPage,
  pageIndex,
  pageSize = 25,
  rowsPerPageOptions = [25, 50, 75, 100],
  ArrowDownwardIcon,
  onPageChange,
  onSearchChange,
  onSortChange,
  searchPlaceholder,
  textAlign,
  maxBodyHeight,
  paperColor,
  rowCount,
  onRowClick,
  getRowId,
  csvOptions,
  onRowSelectionChange,
  muiSelectCheckboxProps,
  feature,
  enableStickyHeader = true,
  enableStickyFooter = true,
  enableManualPagination,
  enableManualFiltering = false,
  globalFilterFn = 'contains',
  enableManualSorting = false,
  enableExportData,
  enableRowVirtualization = false,
  selectedRowFromParent,
  customActionsHeader,
  onEditingRowSave,
  fixedLayout = true,
  onEditingRowCancel,
  enableRowOrdering = false,
  muiRowDragHandleProps,
  actionsColumnSize = 100,
  muiSearchTextFieldProps = undefined,
  resetSearchText = undefined,
  isLoading = false,
  showLinearProgress,
  showSkeletons = false,
  defaultSorting = [],
  noRecordsToDisplay,
  actionButtonsSpacing = 2,
  disableExportData,
  renderDetailPanel,
  onCreatingRowSave,
  onCreatingRowCancel,
  customSearchText = undefined,
  enableExpanding = false,
  tableNote
}: SLTableProps<T>) {
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: pageIndex || 0,
    pageSize: enablePagination ? (pageSize ? pageSize : data?.length) : data?.length //customize the default page size
  });
  const [globalFilter, setGlobalFilter] = useState<string | undefined>(customSearchText || '');
  const [selectedRows, setSelectedRows] = useState<SelectedRows>(selectedRowFromParent || {});
  const [sorting, setSorting] = useState<SortingState>(defaultSorting);
  const [fullScreen, setFullScreen] = useState(false);
  /**
   * This state is used to manage filter settings for columns in a table.
   * The initial state is an array containing a dummy single filter object to handle column filters bookmark feature,
   * as using undefined or null breaking some table internal functionalities
   * which should be replaced with actual default values once available.
   * This setup ensures the table has a defined and predictable initial filter state.
   * Contains titles of multi-select-filter column filters.
   */
  const [columnFilters, setColumnFilters] = useState<{ id: string; value: unknown }[]>([
    { id: INITIAL_VALUE, value: INITIAL_VALUE }
  ]);
  /**
   * This state is used to manage filter settings for columns in a table.
   * Contains values of multi-select-filter column filters.
   */
  const [columnFiltersValues, setColumnFiltersValues] = useState<ColumnFilter[]>([
    { id: INITIAL_VALUE, value: INITIAL_VALUE }
  ]);
  const [areAllMultiSelectOptionsValid, setAreAllMultiSelectOptionsValid] = useState(false);

  const openSidebar = useSidebarOpenState();
  const userSelf = useUserSelf();

  const isDataPresent: boolean = data?.length > 0;
  const emptyTableContainerHeight = 'calc(100vh - 350px)';

  useEffect(() => {
    if (resetSearchText !== undefined) {
      setGlobalFilter(undefined);
    }
  }, [resetSearchText]);
  const handleRowSelectionChange = (updater: any) => {
    setSelectedRows((prevSelectedRows) => {
      if (typeof updater === 'function') {
        const newValue = updater(prevSelectedRows);
        /**
         * when column group by internal feature is used
         * and user selects parent row then keys with : appears
         * so we are hanlding those here
         */
        const foundParentRowSelection = Object.keys(updater(prevSelectedRows)).filter((key) =>
          key.includes(':')
        );

        if (Object.keys(newValue).length === foundParentRowSelection.length) {
          return Object.keys(updater(prevSelectedRows)).filter((key) => !key.includes(':'));
        }
        return newValue;
      } else {
        const updatedSelection = updater(prevSelectedRows);
        return updatedSelection;
      }
    });
  };
  useEffect(() => {
    if (onRowSelectionChange) {
      const selectedRowsArray = Object.keys(selectedRows).filter((key) => !key.includes(':'));
      onRowSelectionChange(selectedRowsArray);
    }
  }, [selectedRows]);

  useEffect(() => {
    if (enableManualFiltering) {
      setPagination({ ...pagination, pageIndex: 0 });
    }
    if (onColumnFiltersChange) {
      onColumnFiltersChange(columnFiltersValues);
    }
  }, [columnFiltersValues]);

  useEffect(() => {
    /**
     * Filter out column filters which are present in columnFilters
     * As columnFilters contains titles of multi-select-filter column filters but require values of multi-select-filter column filters
     * But can not directly store values of multi-select-filter columnFilters as on hovering over filter icon it shows values,
     * So to avoid it, maintaining two separate states for columnFilters, columnFilters have titles and columnFiltersValues have values
     */
    const filteredColumnFilterValue = columnFiltersValues.filter((item) => {
      return columnFilters.some((filterItem) => filterItem.id === item.id);
    });
    /**
     * Filter out default filter values which are not 'multi-select-with-search' filter variant which is custome filter
     * as columnFiltersValues contains only custome filter values that is multi-select-filter column filters
     * Filter out column filters which are not present in columnFiltersValues
     */
    const multiSelectColumn = getMultiSelectColumnName(columns);
    const filterUncommomnData = columnFilters.filter(
      (item) => !multiSelectColumn.includes(item.id)
    );
    //Merge default filter values with custom filter values
    setColumnFiltersValues([...filterUncommomnData, ...filteredColumnFilterValue]);
  }, [columnFilters]);

  useEffect(() => {
    setPagination((prev: PaginationState) => ({
      ...prev,
      pageIndex: prev.pageIndex || 0,
      pageSize: enablePagination ? (pageSize ? pageSize : data?.length) : data?.length
    }));
  }, [data, enablePagination, pageSize]);

  useEffect(() => {
    // used for setting selectedRow from the parent component
    if (selectedRowFromParent) {
      setSelectedRows(selectedRowFromParent);
    }
  }, [selectedRowFromParent]);

  const renderTitle = (tableTitle: string) => {
    return (
      <Typography variant="h6">{TranslateText(toSnakeCase(tableTitle), tableTitle)}</Typography>
    );
  };
  const renderTableNote = (tableNote: string) => {
    return <Typography variant="caption">Note - {tableNote}</Typography>;
  };

  const TopToolbarComponent = ({ table }: { table: MRT_TableInstance<any> }): React.ReactNode => {
    return (
      <Box
        width="100%"
        my={1}
        ref={(ref: HTMLDivElement) => {
          const toolbarProps =
            table.options.muiTopToolbarProps instanceof Function
              ? table.options.muiTopToolbarProps({ table })
              : table.options.muiTopToolbarProps;
          table.refs.topToolbarRef.current = ref;
          if (toolbarProps?.ref) {
            // @ts-ignore
            toolbarProps.ref.current = ref;
          }
        }}>
        <Grid container spacing={1} direction="row" alignItems="flex-start" wrap="nowrap">
          {title && (
            <Grid
              container
              item
              xs={4}
              justifyContent="flex-start"
              alignItems="center"
              wrap="nowrap">
              {title && (
                <Grid ml={1} item>
                  {renderTitle(title)}
                </Grid>
              )}
            </Grid>
          )}
          {!title && tableNote && (
            <Grid
              container
              item
              justifyContent="flex-start"
              wrap="nowrap"
              style={{ whiteSpace: 'nowrap' }}>
              {tableNote && (
                <Grid ml={1} item>
                  {renderTableNote(tableNote)}
                </Grid>
              )}
            </Grid>
          )}
          <Grid
            container
            item
            spacing={1}
            justifyContent="flex-end"
            alignItems="center"
            wrap="nowrap">
            {enableSearch && (
              <Grid item>
                <MRT_GlobalFilterTextField table={table} />
              </Grid>
            )}
            {enableExportData && (
              <Grid item>
                <SLExportButton
                  table={table}
                  csvOptions={csvOptions}
                  disabled={disableExportData}
                />
              </Grid>
            )}
            {enableDensityToggle && (
              <Grid item>
                <MRT_ToggleDensePaddingButton table={table} />
              </Grid>
            )}
            {TopToolbarCustomActionButtons && (
              <Grid item>
                <TopToolbarCustomActionButtons table={table} />
              </Grid>
            )}
            {enableReloadPageButton && onReloadPage && (
              <Grid item>
                <SLReloadPageButton onReload={onReloadPage} disabled={!!showSkeletons} />
              </Grid>
            )}
            {enableFullScreenToggle && (
              <Grid item>
                <MRT_ToggleFullScreenButton table={table} />
              </Grid>
            )}
          </Grid>
        </Grid>
        {/* enableTopToolbar to see this loder on table container, when isLoader true
         {isLoading && <LinearProgress color="primary" />} */}
      </Box>
    );
  };

  const materialRowActionsButtons:
    | (({
        cell,
        row,
        table
      }: {
        cell: MRT_Cell<any>;
        row: MRT_Row<any>;
        table: MRT_TableInstance<any>;
      }) => ReactNode)
    | undefined = ({ cell, row, table }) => {
    const { getState } = table;
    const { editingRow } = getState();
    return (
      <>
        {row.id === editingRow?.id && editDisplayMode === 'row' ? (
          <SLEditActionButtons row={row} table={table} />
        ) : (
          <Grid container spacing={actionButtonsSpacing} wrap={'nowrap'} alignItems={'center'}>
            {RowActionsButtons && RowActionsButtons({ cell, row, table })}
            {((enableEditing && disableEditRowCustomFn && disableEditRowCustomFn(row)) ||
              (enableEditing && !disableEditRowCustomFn)) && (
              <Grid item>
                <ActionsEditIcon
                  onClick={() => {
                    if (customRowEditOnClickHandler) {
                      // Perform custom edit onClick operation below.
                      const isCustomEditOnClick = customRowEditOnClickHandler(row);
                      if (!isCustomEditOnClick) {
                        table.setEditingRow(row);
                      }
                    } else if (!customRowEditOnClickHandler) {
                      // Execute default edit onClick operation.
                      table.setEditingRow(row);
                    }
                  }}
                />
              </Grid>
            )}
          </Grid>
        )}
      </>
    );
  };

  const materialColumns = MaterialColumns(
    columns,
    columnFiltersValues,
    setColumnFiltersValues,
    areAllMultiSelectOptionsValid,
    feature
  );

  //This function is responsible for creating a suitable column name from its properties to be used in the ID
  const createColumnName = (column: MRT_Column<any>) => {
    let columnName = '';
    if (column.columnDef.header.length > 0) {
      //string processing to eliminate spaces, special characters if present and convert the column header to lower case.
      //Words are joined with '-'.
      const words = column?.columnDef?.header?.split(' ') ?? [];
      for (const word of words) {
        const lowerCaseWord = word.toLowerCase();
        let wordAdded = false;
        for (let i = 0; i < lowerCaseWord.length; i++) {
          const charCode = lowerCaseWord[i].charCodeAt(0);
          if (charCode >= 97 && charCode <= 122) {
            columnName = columnName + lowerCaseWord[i];
            wordAdded = true;
          }
        }
        if (wordAdded) {
          columnName += '-';
        }
      }
    } else {
      //if column header is empty then use column accessorKey (e.g. severity column)
      columnName = columnName + column.columnDef.accessorKey + '-';
    }
    return columnName;
  };

  useEffect(() => {
    onPageChange && onPageChange(pagination.pageIndex, pagination.pageSize);
  }, [pagination.pageIndex, pagination.pageSize]);

  useEffect(() => {
    if (onSearchChange) {
      setPagination({ ...pagination, pageIndex: 0 });
      onSearchChange(globalFilter || '');
    }
  }, [globalFilter]);

  useEffect(() => {
    onSortChange && onSortChange(sorting);
  }, [sorting]);

  const handleFullScreenChange = useCallback(
    (isFullScreen: boolean) => {
      if (isFullScreen !== fullScreen) {
        setFullScreen(isFullScreen);
      }
    },
    [fullScreen]
  );

  const table = useMaterialReactTable({
    columns: materialColumns,
    data: data,
    columnFilterDisplayMode: 'popover',
    enableKeyboardShortcuts: false,
    renderTopToolbar: TopToolbarComponent,
    renderRowActions: RowActionsButtons && materialRowActionsButtons,
    renderDetailPanel: renderDetailPanel,
    muiTableBodyCellProps: ({ column, row }) => {
      return DisableCellClickFunc(onRowClick, column, row, paperColor, textAlign);
    },
    muiTableProps: {
      sx: {
        tableLayout: fixedLayout ? 'fixed' : 'auto'
      }
    },
    muiEditTextFieldProps: muiEditTextFieldProps
      ? muiEditTextFieldProps
      : {
          sx: {
            '& .Mui-error': {
              whiteSpace: 'normal'
            }
          }
        },
    columnResizeMode: columnResizeMode,
    rowCount: rowCount,
    getRowId: getRowId,
    onRowSelectionChange: handleRowSelectionChange,
    muiSelectCheckboxProps: feature
      ? ({ row }) => ({
          id: `${feature}-[${row.index}]-checkbox`,
          ...muiSelectCheckboxProps
        })
      : muiSelectCheckboxProps,
    muiSelectAllCheckboxProps: feature ? { id: `${feature}-select-all-checkbox` } : undefined,
    muiExpandButtonProps: feature
      ? ({ row }) => ({
          id: `${feature}-[${row.index}]-expand-collapse-button`
        })
      : undefined,
    muiExpandAllButtonProps: feature ? { id: `${feature}-expand-collapse-all-button` } : undefined,
    muiColumnActionsButtonProps: feature
      ? ({ column }) => {
          const columnName = createColumnName(column);
          return {
            id: `${feature}-${columnName}column-action-button`
          };
        }
      : undefined,
    renderColumnActionsMenuItems: feature
      ? ({ internalColumnMenuItems, column }) => {
          const columnName = createColumnName(column);
          //removing spaces, special characters and converting the menu items of the column actions into lowercase
          //words are joined with '-'.
          const customMenuItems = internalColumnMenuItems.map((item: any) => {
            const menuItemWords = item?.props?.label?.split(' ') ?? [];
            let menuItemName = '';
            for (const word of menuItemWords) {
              let menuItemAdded = false;
              for (let i = 0; i < word.length; i++) {
                const lower = word[i].toLowerCase();
                const menuItemCode = lower.charCodeAt(0);
                if (menuItemCode >= 97 && menuItemCode <= 122) {
                  menuItemName = menuItemName + lower;
                  menuItemAdded = true;
                }
              }
              if (menuItemAdded) {
                menuItemName += '-';
              }
            }
            const updatedProps = {
              ...item.props,
              'data-test-id': `${feature}-${columnName}column-actions-${menuItemName}menu-item-input`
            };
            const newItem = { ...item, props: updatedProps };
            return newItem;
          });
          return customMenuItems;
        }
      : undefined,
    displayColumnDefOptions: {
      'mrt-row-select': {
        size: 60,
        minSize: 60,
        maxSize: 60
      },
      'mrt-row-actions': {
        header: customActionsHeader || 'Actions',
        ...(actionsColumnSize && { size: actionsColumnSize })
      },
      'mrt-row-expand': {
        size: 60,
        minSize: 60,
        maxSize: 60
      },
      'mrt-row-drag': {
        header: '',
        maxSize: 20,
        minSize: 20,
        size: 20
      }
    },
    muiRowDragHandleProps: muiRowDragHandleProps,
    onEditingRowSave: onEditingRowSave,
    muiFilterTextFieldProps: {
      sx: {
        '& .MuiSelect-select.MuiSelect-standard.MuiSelect-multiple .MuiBox-root': {
          flexWrap: 'nowrap',
          overflowX: 'scroll'
        },
        '& .MuiFormHelperText-root': {
          color: 'black'
        }
      },
      helperText: false,
      id: feature ? `${feature}-filter-text-field` : 'filter-text-field'
    },
    onEditingRowCancel: onEditingRowCancel,
    muiTablePaperProps: {
      elevation: 0,
      sx: (theme) => ({
        backgroundColor: paperColor || theme.palette.background.paper
      }),
      style: {
        // Custom styles for full-screen mode.
        // When full-screen, the top header and the left sidebar should be visible.
        ...(fullScreen && {
          height: `calc(100vh - ${HEADER_HEIGHT}px)`,
          width: fullScreen
            ? openSidebar
              ? `calc(100vw - ${SIDEBAR_WIDTH}px)`
              : `calc(100vw - 40px)`
            : 'inherit',
          right: 0,
          bottom: 0,
          left: 'unset',
          top: 'unset'
        })
      }
    },
    muiTableContainerProps: {
      sx: {
        ...(isDataPresent && {
          maxHeight: fullScreen ? '100%' : maxBodyHeight || '80%',
          height: fullScreen ? '100%' : maxBodyHeight || '80%'
        }),
        /**
         * If the data is not present, then add an empty height
         * on the container, so pagination will stick close to the
         * bottom of the screen.
         */
        ...(!isDataPresent && {
          height: fullScreen ? '100%' : maxBodyHeight || emptyTableContainerHeight
        })
      }
    },
    muiTableBodyProps: {
      sx: (theme) => ({
        backgroundColor: paperColor || theme.palette.background.paper,
        '&.MuiTableBody-root .MuiTypography-root': {
          color: Colors.shadowColor
        },
        // Making No records to disaplay message centralize.
        '& .MuiTypography-root & .MuiTypography-body1': {
          margin: 'auto'
        }
      })
    },
    muiTableHeadCellProps: {
      sx: (theme) => ({
        backgroundColor: paperColor || theme.palette.background.paper,
        fontWeight: theme.typography.fontWeightMedium
      })
    },
    muiBottomToolbarProps: {
      sx: (theme) => ({
        backgroundColor: paperColor || theme.palette.background.paper,
        // Chanaging the pagination's 'Row per page' label font-weight to normal.
        '& .MuiTablePagination-root .MuiFormLabel-root.MuiInputLabel-root': {
          fontWeight: 'inherit'
        },
        ...(fullScreen && {
          // When full-screen, the top header and the left sidebar should be visible.
          width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
          left: 'unset'
        })
      })
    },
    muiTableBodyRowProps: ({ row, table }) => {
      return {
        hover: !table.getState()?.editingRow,
        sx: {
          opacity:
            !table?.getState()?.editingRow || table?.getState()?.editingRow?.id === row?.id
              ? 1
              : 0.3,
          pointerEvents:
            !table?.getState()?.editingRow || table?.getState()?.editingRow?.id === row?.id
              ? 'auto'
              : 'none'

          // Below code helps in hiding/customizing the cell
          // '& td:nth-of-type(2)': {
          //   visibility: row.depth === 0 && hideParentCheckBox ? 'hidden' : 'table-cell'
          // }
        }
      };
    },
    muiSearchTextFieldProps: {
      ...(muiSearchTextFieldProps && { ...muiSearchTextFieldProps }),
      placeholder: searchPlaceholder
        ? TranslateText(toSnakeCase(searchPlaceholder), searchPlaceholder)
        : TranslateText('search', 'Search'),
      sx: {
        maxWidth: '360px',
        // Styling for search bar
        '& .MuiInputBase-root.MuiOutlinedInput-root': {
          borderRadius: '50px',
          height: '2.3rem'
        },
        '& .MuiInputBase-input.MuiOutlinedInput-input': {
          paddingLeft: '5px'
        }
      },
      variant: 'outlined',
      size: 'small'
    },
    muiTableFooterProps: {
      sx: {
        display: !data?.length ? 'none' : 'inherit'
      }
    },
    enableRowVirtualization: !!enableRowVirtualization,
    enableColumnFilters: !!enableColumnFilters,
    enableGlobalFilter: !!enableSearch,
    enableHiding: !!enableHiding,
    enableColumnActions: !!enableColumnActions,
    enableDensityToggle: !!enableDensityToggle,
    enableTopToolbar: !!enableTopToolbar,
    manualPagination: !!enableManualPagination,
    manualFiltering: !!enableManualFiltering,
    manualSorting: !!enableManualSorting,
    enableExpanding: !!enableExpanding,
    enableBottomToolbar: !!enableBottomToolbar,
    enablePagination: !!enablePagination,
    enableSorting: !!enableSorting,
    enableRowSelection: enableRowSelection,
    enableColumnFilterModes: !!enableColumnFilterModes,
    enableMultiSort: !!enableMultiSort,
    enableClickToCopy: !!enableClickToCopy,
    enableColumnDragging: !!enableColumnDragging,
    enablePinning: !!enablePinning,
    enableGrouping: !!enableGrouping,
    enableFullScreenToggle: !!enableFullScreenToggle,
    enableRowActions: !!enableRowActions,
    enableColumnResizing: !!enableColumnResizing,
    enableRowOrdering: !!enableRowOrdering,
    enableColumnOrdering: !!enableColumnOrdering,
    enableEditing: !!enableEditing,
    positionCreatingRow: positionCreatingRow,
    createDisplayMode: createDisplayMode,
    editDisplayMode: editDisplayMode,
    enableStickyHeader: !!enableStickyHeader,
    enableStickyFooter: !!enableStickyFooter,
    layoutMode: 'semantic',
    positionActionsColumn: positionActionsColumn || 'last',
    initialState: {
      density: density || 'compact',
      showGlobalFilter: true,
      showColumnFilters: !!showColumnFilters,
      columnPinning: initialColumnPinningState,
      columnVisibility: initialColumnVisibilityState
    },
    muiPaginationProps: {
      rowsPerPageOptions: rowsPerPageOptions || [25, 50, 75, 100]
    },
    onPaginationChange: setPagination,
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    localization: {
      ...getSelectedLanguageLocalization(userSelf.self.language),
      noRecordsToDisplay: isLoading
        ? TranslateText('fetching_data', 'Fetching Data')
        : noRecordsToDisplay || TranslateText('no_records_to_display', 'No records to display')
    },
    muiSkeletonProps: {
      height: '35px'
    },
    globalFilterFn: globalFilterFn,
    state: {
      pagination,
      globalFilter,
      rowSelection: selectedRows,
      sorting,
      columnFilters,
      isLoading: isLoading,
      showSkeletons: showSkeletons
    },
    // enableSelectAll:enableSelectAll,
    onCreatingRowSave: onCreatingRowSave,
    onCreatingRowCancel: onCreatingRowCancel,
    selectAllMode: 'all',
    icons: {
      ArrowDownwardIcon:
        ArrowDownwardIcon ||
        forwardRef((props, ref: React.Ref<SVGSVGElement>) => (
          // temporary solution for fontSize, can be fixed once we fix the svg issue in theme
          <ArrowUpward {...props} ref={ref} style={{ fill: '#000000', fontSize: '18px' }} />
        )),
      SyncAltIcon: () => (
        <SwapVertIcon
          htmlColor="black"
          className={ACTION_ICON_CLASS_NAME}
          style={{ height: '0.7em' }}
        />
      ),
      SearchIcon: () => <div />,
      EditIcon: () => <EditIcon color="black" className={ACTION_ICON_CLASS_NAME} />,
      CancelIcon: () => (
        <CancelOutlinedIcon
          htmlColor="black"
          style={{ opacity: 0.8 }}
          className={ACTION_ICON_CLASS_NAME}
        />
      ),
      SaveIcon: () => (
        <CheckCircleOutlinedIcon
          htmlColor="black"
          style={{ opacity: 0.8 }}
          className={ACTION_ICON_CLASS_NAME}
        />
      )
    } //table options as options to this hook
  });

  const tableCurrentState = table?.getState();

  useEffect(() => {
    if (enableFullScreenToggle) {
      handleFullScreenChange(tableCurrentState?.isFullScreen);
    }
  }, [enableFullScreenToggle, handleFullScreenChange, tableCurrentState]);

  useEffect(() => {
    setAreAllMultiSelectOptionsValid(allMultiSelectOptionsHaveLength(columns));
  }, [columns]);

  /**
   * Set default values for column filters
   */
  useEffect(() => {
    if (areAllMultiSelectOptionsValid) {
      table.setColumnFilters(getColumnFiltersDefaultValuesInTitles(columns));
      setColumnFiltersValues(getColumnFiltersDefaultValues(columns));
    }
  }, [areAllMultiSelectOptionsValid]);

  useEffect(() => {
    if (passTableRef) {
      passTableRef(table);
    }
  }, [passTableRef, table]);

  return <MaterialReactTable table={table} />;
}

export default SLTable;
