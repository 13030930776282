/**
 * Alarms Reducer
 * @author mahesh.kedari@shorelineiot.com
 */
import { combineReducers } from 'redux';
import alarmsDetails from './alarms-details/alarmsDetails.reducer';
import alarmsExport from './alarms-export/alarmsExport.reducer';
/*
 *
 */
const alarmsReducer = combineReducers({
  alarmsDetails,
  alarmsExport
});

export default alarmsReducer;
