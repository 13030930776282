import { useTheme, useMediaQuery } from '@mui/material';

/**
 * usePlatformDetails custom Hook
 * @author mahesh.kedari@shorelineiot.com
 */
export default function usePlatformDetails() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'), {
    defaultMatches: true
  });
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'xl'), {
    defaultMatches: false
  });
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: false
  });
  //                                (orientation: portrait)
  const isPortrait = useMediaQuery('(orientation: portrait)', {
    defaultMatches: true
  });
  return { isMobile, isTablet, isDesktop, isPortrait };
}
