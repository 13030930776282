import { AnyAction } from 'redux';
import * as ACTIONS from './historicalData.actionTypes';

export const fetchHistoricalData = (data: any): AnyAction => {
  return {
    type: ACTIONS.FETCH_HISTORICAL_DATA,
    payload: data
  };
};
/**
 * @param data response data
 * @returns data to be passed to reducer()
 */
export const fetchHistoricalDataSuccess = (data: any): AnyAction => {
  return {
    type: ACTIONS.FETCH_HISTORICAL_DATA_SUCCESS,
    payload: data
  };
};

/**
 *
 * @param err retrun from api call
 */
export const fetchHistoricalDataFailure = (error: any): AnyAction => {
  return {
    type: ACTIONS.FETCH_HISTORICAL_DATA_FAILURE,
    payload: error
  };
};

export const resetHistoricalData = (): AnyAction => {
  return {
    type: ACTIONS.FETCH_HISTORICAL_DATA_RESET
  };
};
