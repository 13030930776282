export const SUBMIT_NARROWBANDS = 'FEATURES/DEVICE/DEVICE-SETTINGS/NARROWBAND/AUTO-CONFIG/SUBMIT';
export const CREATE_RULE = 'FEATURES/DEVICE/DEVICE-SETTINGS/NARROWBAND/AUTO-CONFIG/CREATE-RULE';
export const PARSE_NARROWBANDS = 'FEATURES/DEVICE/DEVICE-SETTINGS/NARROWBAND/AUTO-CONFIG/PARSE';
export const SAVE_ACTIONS = 'FEATURES/DEVICE/DEVICE-SETTINGS/NARROWBAND/AUTO-CONFIG/SAVE-ACTIONS';
export const SUBMIT_NARROWBAND_UPDATING =
  'FEATURES/DEVICE/DEVICE-SETTINGS/NARROWBAND/AUTO-CONFIG/SUBMIT/UPDATING';
export const SUBMIT_NARROWBAND_SUCCESS =
  'FEATURES/DEVICE/DEVICE-SETTINGS/NARROWBAND/AUTO-CONFIG/SUBMIT/SUCCESS';
export const SUBMIT_NARROWBAND_ERROR =
  'FEATURES/DEVICE/DEVICE-SETTINGS/NARROWBAND/AUTO-CONFIG/SUBMIT/ERROR';
export const RESET_AUTOCONFIG = 'FEATURES/DEVICE/DEVICE-SETTINGS/NARROWBAND/AUTO-CONFIG/RESET';
export const ATTACH_NB_DIAGNOSTICS =
  'FEATURES/DEVICE/DEVICE-SETTINGS/NARROWBAND/AUTO-CONFIG/ATTACH-NB-DIAGNOSTICS';
export const SET_COMPONENT_ID =
  'FEATURES/DEVICE/DEVICE-SETTINGS/NARROWBAND/AUTO-CONFIG/SET_COMPONENT_ID';
