import React from 'react';
import { Box, Grid } from '@mui/material';
import { renderCorrectCellularIcon, renderCorrectSignalText } from './cellular.utils';
import WidgetDataUnavailable from '../widget-data-unavailable/WidgetDataUnavailable';
import { useStyles } from './cellularWidget.styles';
import TranslateText from '../../../../i18n/TranslateText';

interface signalData {
  value: number;
  ts: number;
  message: string;
}

interface CellularWidgetProps {
  signalStrength: signalData;
}

/**
 * SLCellularWidget component for the dashboard.
 */
const SLCellularWidget = React.forwardRef(({ signalStrength }: CellularWidgetProps, ref: any) => {
  const classes = useStyles();

  if (
    !signalStrength ||
    (signalStrength && signalStrength?.message && signalStrength?.message === 'No data')
  ) {
    return <WidgetDataUnavailable />;
  }
  return (
    <Box textAlign="center" className={classes.containerize} ref={ref}>
      <Grid
        container
        spacing={1}
        justifyContent="center"
        alignItems="center"
        wrap="nowrap"
        className={classes.autoMargin}>
        <Grid item xs={3} className={classes.alignRight}>
          {renderCorrectCellularIcon(signalStrength?.value, classes)}
        </Grid>
        <Grid item className={classes.alignLeft}>
          <span>
            <b>{TranslateText('cellular_signal', 'Cellular Signal')}: </b>
            {renderCorrectSignalText(signalStrength?.value)}
          </span>
        </Grid>
      </Grid>
    </Box>
  );
});

export default SLCellularWidget;
