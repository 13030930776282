/**
 * Get Datapoints Series from Query object
 * @author mahesh.kedari@shorelineiot.com
 */

/**
 * Prepare name string for datapoint to be shown on Widget Editor
 * @param datapointsList - Master List of datapoints
 * @param deviceId - Device ID for Selected datapoint
 * @param dpid - Selected datapoint ID
 * @returns - Name of the datapoint to be shown on widget Editor
 */
const getDatapointName = (datapointsList: Array<any>, deviceId: number, dpid: number) => {
  const device = datapointsList.find((deviceObj: any) => deviceObj.device_id === deviceId);
  const selectedDatapoint = device?.datapoints?.find(
    (datapointObj: any) => datapointObj.dpid === dpid
  );
  return selectedDatapoint?.dp_name;
};
/**
 * Get List of datapoints from query object.
 * @param devices - Selected List of devices in query
 * @param datapointsList - Master List for checking datapoint details
 * @returns Flatten Array of all the datapoints selected in query
 */
export function getDetapointSeries(devices: Array<any>, datapointsList: Array<any>): Array<any> {
  const datapointSeries: Array<any> = [];
  devices?.forEach((device: any) => {
    device.datapoints.forEach((datapoint: any) => {
      datapointSeries.push({
        id: `${device.deviceId}_${datapoint.dpid}`,
        title: getDatapointName(datapointsList, device.deviceId, datapoint.dpid)
      });
    });
  });
  return datapointSeries;
}
