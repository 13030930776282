/**
 * Organization Actions Constants
 * @author ashwini.gawade@shorelineiot.com
 */

// create organization constants
// for updating organization details

export const FETCH_ORG_LIST_SUCCESS = 'FEATURES/ORGANISATION/FETCH_LIST_SUCCESS';
export const FETCH_ORG_LIST_FAILURE = 'FEATURES/ORGANISATION/FETCH_LIST_FAILURE';
export const FETCH_ORG_LIST_PROGRESS = 'FEATURES/ORGANISATION/FETCH_LIST_PROGRESS';
