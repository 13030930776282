import { put, takeEvery, call } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import * as addSurveyActions from './actions/survey.actions';
import * as actionTypes from './actions/survey.actionTypes';
import * as autoConfigActions from './actions/autoConfig.actions';
import {
  GenericObject,
  httpPost,
  removeProgressFor,
  showProgressFor,
  APISERVICES,
  showToast
} from '../../../../../../../framework';
import { SagaIterator } from 'redux-saga';

const ADD_SURVEY = 'ADD_SURVEY';

function addSurveyAnswers(
  slug: string,
  deviceId: string,
  componentId: number,
  surveyQuesID: number,
  answers: any
) {
  return httpPost(
    `orgs/${slug}/devices/${deviceId}/powertrain_component/${componentId}/survey_answers`,
    {
      survey_questions_id: surveyQuesID,
      survey_answers: answers
    },
    APISERVICES.DEVICE_API
  );
}

function getNarrowbandAxis(datapointType: string) {
  if (datapointType.includes('SNS_ACC_X_AXIS')) {
    return 'x_axis';
  }
  if (datapointType.includes('SNS_ACC_Y_AXIS')) {
    return 'y_axis';
  }
  if (datapointType.includes('SNS_ACC_Z_AXIS')) {
    return 'z_axis';
  }
  if (datapointType.includes('SNS_MIC_STRUCT_BORNE')) {
    return 'mic';
  }
  return '';
}

function formatNarrowbands(response: any) {
  const narrowbands: any = [];

  response.narrowbands.forEach((narrowbandAxis: any) => {
    const datapointType = narrowbandAxis.raw_datapoint_type;
    const axisNarrowbands = narrowbandAxis.narrowband_config;
    const rawDpid = narrowbandAxis.raw_dpid;
    axisNarrowbands.forEach((narrowband: any) => {
      const updatedNarrowband = {
        ...narrowband,
        description: narrowband.description.replace(/,/g, '')
      };
      if (getNarrowbandAxis(datapointType) === 'x_axis') {
        narrowbands.push({
          ...updatedNarrowband,
          raw_dpid: rawDpid,
          axis: 'x'
        });
      } else if (getNarrowbandAxis(datapointType) === 'y_axis') {
        narrowbands.push({
          ...updatedNarrowband,
          raw_dpid: rawDpid,
          axis: 'y'
        });
      } else if (getNarrowbandAxis(datapointType) === 'z_axis') {
        narrowbands.push({
          ...updatedNarrowband,
          raw_dpid: rawDpid,
          axis: 'z'
        });
      } else if (getNarrowbandAxis(datapointType) === 'mic') {
        narrowbands.push({
          ...updatedNarrowband,
          raw_dpid: rawDpid,
          axis: 'mic'
        });
      } else {
        narrowbands.push({
          ...updatedNarrowband,
          raw_dpid: narrowband.raw_dpid,
          axis: ''
        });
      }
    });
  });

  return narrowbands;
}

export function* addSurveySaga(action: AnyAction) {
  const data = action.payload;
  yield put(showProgressFor(ADD_SURVEY));
  try {
    yield put(
      autoConfigActions.setComponentId({
        componentId: action.payload.componentId
      })
    );
    const response: GenericObject = yield call(
      addSurveyAnswers,
      action.payload.slug,
      action.payload.deviceId,
      action.payload.componentId,
      data.surveyQuestionsId,
      action.payload.answers
    );

    // adding mode auto
    const updatedRawNBs: any = response.narrowbands.map((axisNB: any) => {
      const currentAxisNB = axisNB;
      let currentNarrowbandConfig = currentAxisNB.narrowband_config;
      currentNarrowbandConfig = currentNarrowbandConfig.map((nb: { mode: string }) => {
        return { ...nb, mode: 'auto' };
      });
      currentAxisNB.narrowband_config = currentNarrowbandConfig;

      return currentAxisNB;
    });

    yield put(
      addSurveyActions.addSurveySuccess(
        formatNarrowbands(response),
        updatedRawNBs,
        response.powertrain
      )
    );
  } catch (error: any) {
    yield put(showToast(error.message, 'error', true));
    yield put(addSurveyActions.addSurveyFailure(error));
  } finally {
    yield put(removeProgressFor(ADD_SURVEY));
  }
}

export function* watchAddSurveySaga(): SagaIterator {
  yield takeEvery(actionTypes.ADD_SURVEY_ANSWERS, addSurveySaga);
}
