import React, { ReactElement } from 'react';
import { useGAEventTracker } from '../../../custom-hooks';
import { SLSelect } from '../../form';
import { Grid } from '@mui/material';
import {
  DisplayUnitsList,
  FREQUENCY_UNIT,
  FrequencyUnitsList,
  Y_AXIS_UNIT
} from '../../../../features/workflow/trend-analysis/store';

export default function WaterfallFrequencyUnit({
  setWaterfallXUnit,
  xUnit,
  yUnit,
  setWaterfallYUnit
}: any): ReactElement {
  const GAEventsTracker = useGAEventTracker('Waterfall Unit');

  const handleFrequencyUnitChange = (event: string) => {
    GAEventsTracker('WaterFall unit change', `unit:${event}`);
    if (event) {
      const unit = event as FREQUENCY_UNIT;
      setWaterfallXUnit(unit);
    }
  };

  const handleYAxisUnitChange = (event: string) => {
    if (event) {
      const unit = event as Y_AXIS_UNIT;
      setWaterfallYUnit(unit);
    }
  };
  return (
    <Grid container columnSpacing={2} xs={12}>
      <Grid item xs={3} columnSpacing={2}>
        <SLSelect
          options={FrequencyUnitsList}
          name={'unit'}
          label={'Unit'}
          translationId="unit"
          value={xUnit || FREQUENCY_UNIT.Hz}
          defaultValue={xUnit || FREQUENCY_UNIT.Hz}
          onSelectChange={handleFrequencyUnitChange}
        />
      </Grid>
      <Grid item xs={3} columnSpacing={2}>
        <SLSelect
          options={DisplayUnitsList}
          name={'yUnit'}
          label={'Y Unit'}
          translationId="y_unit"
          value={yUnit || Y_AXIS_UNIT.INCHES_PER_SEC}
          defaultValue={yUnit || Y_AXIS_UNIT.INCHES_PER_SEC}
          onSelectChange={handleYAxisUnitChange}
        />
      </Grid>
    </Grid>
  );
}
