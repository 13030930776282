/**
 * Icons List
 * @author mahesh.kedari@shorelineiot.com
 */
import React from 'react';
import IndicatorIcon from '@mui/icons-material/Brightness1';
import AddIcon from '@mui/icons-material/Add';
import AdjustIcon from '@mui/icons-material/Adjust';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import AdbIcon from '@mui/icons-material/Adb';
import FlagIcon from '@mui/icons-material/AssistantPhoto';
import BatteryAlertIcon from '@mui/icons-material/BatteryAlert';
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import BatteryUnknownIcon from '@mui/icons-material/BatteryUnknown';
import BlockIcon from '@mui/icons-material/Block';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import CloudOffIcon from '@mui/icons-material/CloudOff';
// import EcoIcon from '@mui/icons-material/Eco';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import FlashOffIcon from '@mui/icons-material/FlashOff';
import GradeIcon from '@mui/icons-material/Grade';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import GoodMoodIcon from '@mui/icons-material/Mood';
import BadMoodIcon from '@mui/icons-material/MoodBad';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import PauseIcon from '@mui/icons-material/PauseCircleOutline';
import WifiIcon from '@mui/icons-material/Wifi';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import CellularFull from '@mui/icons-material/SignalCellular4Bar';
import CellularPartial from '@mui/icons-material/NetworkCell';
import CellularDown from '@mui/icons-material/SignalCellularOff';
// You can add any number of icons in this list. This list will be shown on editor and based on value it will be displayed on table
export const IconsList = [
  {
    label: <IndicatorIcon fontSize="small" />,
    value: 'indicator'
  },
  { label: <AddIcon fontSize="small" />, value: 'add' },
  { label: <AdjustIcon fontSize="small" />, value: 'adjust' },
  { label: <WarningIcon fontSize="small" />, value: 'warning' },
  { label: <ErrorIcon fontSize="small" />, value: 'error' },
  { label: <ErrorOutlineIcon fontSize="small" />, value: 'error-outline' },
  { label: <AdbIcon fontSize="small" />, value: 'adb' },
  { label: <FlagIcon fontSize="small" />, value: 'flag' },
  { label: <BatteryAlertIcon fontSize="small" />, value: 'battery-down' },
  { label: <BatteryFullIcon fontSize="small" />, value: 'battery-full' },
  { label: <BatteryUnknownIcon fontSize="small" />, value: 'battery-unknown' },
  { label: <BlockIcon fontSize="small" />, value: 'block' },
  { label: <CancelIcon fontSize="small" />, value: 'cancel' },
  { label: <CheckIcon fontSize="small" />, value: 'check' },
  { label: <CloudDoneIcon fontSize="small" />, value: 'cloud-done' },
  { label: <CloudOffIcon fontSize="small" />, value: 'cloud-off' },
  // { label: <EcoIcon fontSize="small" />, value: 'eco' },
  { label: <FlashOnIcon fontSize="small" />, value: 'flash-on' },
  { label: <FlashOffIcon fontSize="small" />, value: 'flash-off' },
  { label: <GradeIcon fontSize="small" />, value: 'grade' },
  { label: <LiveHelpIcon fontSize="small" />, value: 'live-help' },
  { label: <GoodMoodIcon fontSize="small" />, value: 'good-mood' },
  { label: <BadMoodIcon fontSize="small" />, value: 'bad-mood' },
  {
    label: <NotificationImportantIcon fontSize="small" />,
    value: 'notification'
  },
  { label: <PauseIcon fontSize="small" />, value: 'pause' },
  { label: <WifiIcon fontSize="small" />, value: 'wifi-on' },
  { label: <WifiOffIcon fontSize="small" />, value: 'wifi-off' },
  { label: <CellularFull fontSize="small" />, value: 'cellular-full' },
  { label: <CellularPartial fontSize="small" />, value: 'cellular-partial' },
  { label: <CellularDown fontSize="small" />, value: 'cellular-off' }
];
