/**
 * Home Layout
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { useState } from 'react';
import FeaturesContainer from './common/container/FeaturesContainer';
import { SlugContext, SLFrameworkContainer } from '../framework';
import { I18Provider } from '../i18n';
import { useUserSelf } from './user-settings';

/**
 * This is an entry point for features.
 */
export default function FeaturesLayout(): JSX.Element {
  const [slug, setSlug] = useState<string>('');
  const language = useUserSelf()?.self?.language || 'en';

  return (
    <I18Provider locale={language}>
      <SlugContext.Provider value={{ slug, setSlug }}>
        <SLFrameworkContainer>
          <FeaturesContainer />
        </SLFrameworkContainer>
      </SlugContext.Provider>
    </I18Provider>
  );
}
