/**
 * Component List Saga
 * @author mahesh.kedari@shorelineiot.com
 */
import { takeEvery, put, call } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import * as ACTIONS from './componentApplyToDevices.actionTypes';
import * as actions from './componentApplyToDevices.actions';
import {
  SafeSaga,
  GenericObject,
  httpGet,
  httpPost,
  showToast,
  APISERVICES
} from '../../../../../framework';
import { SagaIterator } from 'redux-saga';

/**
 *
 */
function getComponentAttachList(data: any) {
  return httpGet(`component_compiler/${data?.component_id}/attach`, null, APISERVICES.DEVICE_API);
}

/**
 *
 */
function updateComponentAttachList(data: any) {
  return httpPost(
    `component_compiler/${data?.component_id}/attach`,
    data.payload,
    APISERVICES.DEVICE_API
  );
}

function* fetchComponentAttachListSaga(action: AnyAction) {
  const response: GenericObject = yield call(getComponentAttachList, action.payload);

  yield put(actions.fetchComponentAttachListSuccess(response));
}
/**
 *
 */
function* updateComponentSaga(action: AnyAction) {
  const response: GenericObject = yield call(updateComponentAttachList, action.payload);

  yield put(actions.updateComponentAttachListSuccess(response));
  yield put(showToast('Component Details Updated Successfully!', 'success'));
}
/**
 *
 * @param error
 */
function* errorHandler(error: any) {
  yield put(actions.fetchComponentAttachListFailure(error));
}

/**
 *
 * @param error
 */
function* updateErrorHandler(error: any) {
  yield put(actions.updateComponentAttachListFailure(error));
}
/**
 *
 */
export function* watchFetchComponentAttachListSaga(): SagaIterator {
  yield takeEvery(
    ACTIONS.FETCH_COMPONENT_ATTACH_LIST,
    SafeSaga(fetchComponentAttachListSaga, ACTIONS.FETCH_COMPONENT_ATTACH_LIST, errorHandler)
  );
}
/**
 *
 */
export function* watchUpdateComponentAttachListSaga(): SagaIterator {
  yield takeEvery(
    ACTIONS.UPDATE_COMPONENT_ATTACH_LIST,
    SafeSaga(updateComponentSaga, ACTIONS.UPDATE_COMPONENT_ATTACH_LIST, updateErrorHandler)
  );
}
