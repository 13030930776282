/**
 * Filter and Diff Array
 * @param array1 : Array of events and data
 * @param array2 : Array of events
 * @returns filteredArray of Array1 and Filtered events of Array2
 */
type EventAndData = {
  eventId: string;
  data: Array<any>;
};
export function filterAndDiffArray(array1: Array<EventAndData>, array2: Array<string>): Array<any> {
  // Extracting event_ids from array2 for efficient lookup
  const eventIdsToKeep = new Set(array2);
  // Filtering array1 to retain only those objects whose event_id is present in array2
  const existingEventsWithData = array1.filter((obj) => eventIdsToKeep.has(obj.eventId));
  const existingEventIdsWithData = new Set(existingEventsWithData.map((obj) => obj.eventId));
  // Finding the event_ids that are present in array2 but not in array1
  const newEventIds = array2.filter((eventId) => !existingEventIdsWithData.has(eventId));
  return [existingEventsWithData, newEventIds];
}
