/**
 * Leak Detection Alarm Type
 * @author mahesh@mulyam.in
 */
export const LEAK_DETECTION_ALARM_TYPE = 'ASSET';
export type LeakDetectionAlertLevel = keyof typeof LEAK_ALERT_LEVEL;
export type LeakDetectionAlarmStatusType =
  | 'REVIEW'
  | 'NEW'
  | 'ACK'
  | 'IN_PROGRESS'
  | 'FIXED'
  | 'FALSE';

/**
 * This is the raw response on an Alarm object from an API.
 * @example Use this interface whenever you don't need the customized response.
 */

export const LEAK_ALERT_LEVEL = {
  ALERT_LOW: 'ALERT_LOW',
  ALERT_WARNING: 'ALERT_WARNING',
  ALERT_ERROR: 'ALERT_ERROR',
  ALERT_FATAL: 'ALERT_FATAL',
  ALERT_NONE: 'ALERT_NONE'
};

export interface AlarmRawObj {
  name: string;
  alarm_id: number;
  alarm_category: string;
  created: number;
  severity: LeakDetectionAlertLevel;
  occurrence_count: number;
  closed_reason?: string;
  trigger_value: number;
  latest: number;
  status: LeakDetectionAlarmStatusType;
  unit: string;
  org_uuid: string;
  suborg_uuid: string;
  threshold_value: number | null;
  state_modified_by: string | null;
  state_modified_on: number;
  device_ids: number[];
  event_ids: (number | null)[];
  duration: number;
}

/**
 * This is the customized response on an Alarm object from an API
 * to add more usefull properties for Alams list table.
 */
export interface MethaneEmissionAlarm extends AlarmRawObj {
  createdColumnData: {
    cellText: string;
    tooltipValue: string;
  };
  latestColumnData: {
    cellText: string;
    tooltipValue: string;
  };
  stateColumnData: {
    cellText: string;
    tooltipValue: string;
    lastModifiedData: string;
    modifiedByUserName: string;
  };
  siteColumnData: {
    cellText: string;
  };
}

export type FetchMethaneAlarmsArgs = {
  slug: string;
  alarmType: typeof LEAK_DETECTION_ALARM_TYPE;
  isHistoricalAlarms: boolean;
  severity: string[] | undefined;
  /**
   * The start date/time for filtering alarms.
   * This key is not part of the local table filters.
   * When the `start` key appears in the URL, it should
   * be included as a query parameter in the alarm API.
   */
  start: number | undefined;
  /**
   * The end date/time for filtering alarms.
   * This key is not part of the local table filters.
   * When the `end` key appears in the URL, it should be included as a query parameter in the alarm API.
   */
  end: number | undefined;
  allAlarms?: boolean;
};

export type FetchAlarmsCountArgs = {
  slug: string;
  count: boolean;
  alarmType: any;
};

export interface FetchExportedAlarmsArgs {
  slug: string;
  start: number;
  end: number;
  setting: AlarmExportType;
  email?: string;
  timezone: string;
  alarmType: typeof LEAK_DETECTION_ALARM_TYPE;
}

export type AlarmExportType = 'download' | 'email' | '';
export type Occurred = string;
export type ModifiedBy = string;
export type Site = string;
export type Path = number;

export type AlarmsFiltersState = {
  /**
   * Filters that are selected in the reducer but not finalized yet.
   */
  selections: {
    severity: LeakDetectionAlertLevel[];
    state: LeakDetectionAlarmStatusType[];
    modifiedBy: ModifiedBy[];
    site: Site[];
    path: Path[];
    occurred: Occurred;
  };
  /**
   * Finalized filters that are set w.r.t. URL params by user in the reducer.
   */
  setFilters: {
    severity: LeakDetectionAlertLevel[];
    state: LeakDetectionAlarmStatusType[];
    modifiedBy: ModifiedBy[];
    site: Site[];
    path: Path[];
    occurred: Occurred;
  };
  export: {
    /**
     * Determines whether the exported alarms file should be downloaded
     * for the current user or not.
     * This is used in case the user opens two same tabs in the browser,
     * and one's download action shouldn't interfere with the other tab instance.
     */
    shouldDownloadFile: boolean;
  };
};

export interface AlarmDeleteWebsocketPayload {
  module: string;
  action: string;
  payload: {
    alarms: AlarmRawObj[];
  };
}

export interface UpdateAlarmsStatusArgs {
  slug: string;
  alarmStatus: LeakDetectionAlarmStatusType;
  ruleTriggerIds: string[];
  isFixedFalse?: boolean;
  summary?: string;
  analystNotes?: string;
  analystDiagnosis?: string;
  analystRecommendation?: string;
}

export enum LEAK_DETECTION_ALARMS_VALIDATION_TAGS {
  ALARM_COUNT = 'LEAK_DETECTION_ALARM_COUNT'
}
