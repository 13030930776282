/**
 * Narrowband Rule List Sagas
 * @author priyanka.ambawane@shorelineiot.com
 */

import { put, takeEvery, call } from 'redux-saga/effects';
import * as createNarrowbandRuleActions from './nbRuleCreate.actions';
import * as CREATE_NARROWBAND_RULE_ACTIONS from './nbRuleCreate.actionTypes';
import { formToApiDataParser } from '../createRule.parsers';
import {
  GenericObject,
  removeProgressFor,
  httpPost,
  showToast,
  APISERVICES
} from '../../../../../framework';
import { SagaIterator } from 'redux-saga';
/**
 *
 * @param data
 */
function createRule(slug: string, data: any) {
  const apiData = formToApiDataParser(data);
  return httpPost(`orgs/${slug}/device_rule_template`, apiData, APISERVICES.DEVICE_API);
}

/**
 *
 * @param action
 */
export function* createNarrowbandRuleSaga(action: any) {
  try {
    const rules: any = action.payload.data;
    if (rules.warningRule && rules.errorRule) {
      const warningRuleResponse: GenericObject = yield call(
        createRule,
        action.payload.slug,
        rules.warningRule
      );
      const errorRuleResponse: GenericObject = yield call(
        createRule,
        action.payload.slug,
        rules.errorRule
      );
      yield put(
        createNarrowbandRuleActions.createNBRuleSuccess({
          warningRuleResponse,
          errorRuleResponse
        })
      );
    } else if (rules.warningRule && !rules.errorRule) {
      const warningRuleResponse: GenericObject = yield call(
        createRule,
        action.payload.slug,
        rules.warningRule
      );
      yield put(
        createNarrowbandRuleActions.createNBRuleSuccess({
          warningRuleResponse
        })
      );
    } else if (!rules.warningRule && rules.errorRule) {
      const errorRuleResponse: GenericObject = yield call(
        createRule,
        action.payload.slug,
        rules.errorRule
      );
      yield put(
        createNarrowbandRuleActions.createNBRuleSuccess({
          errorRuleResponse
        })
      );
    }
  } catch (error: any) {
    if (error === 'NoEmailPresent') {
      yield put(
        showToast('Please enter at-least one email id to create the email action!', 'error', true)
      );
    } else {
      yield put(showToast('Rule could not be created!', 'error', true));
    }
    yield put(createNarrowbandRuleActions.createNBRuleFailure(error));
  } finally {
    yield put(removeProgressFor('NB_RULE_CREATE'));
  }
}
/**
 *
 */
export function* watchCreateNarrowbandRuleSaga(): SagaIterator {
  yield takeEvery(CREATE_NARROWBAND_RULE_ACTIONS.CREATE_NB_RULES, createNarrowbandRuleSaga);
}
