/**
 * Global intl object Component
 * @author prerna.more@shorelineiot.com
 */
import { IntlShape } from 'react-intl';

let intl: IntlShape | null = null;

// Function to set the global intl object
export const setGlobalIntl = (intlObject: IntlShape) => {
  intl = intlObject;
};

// Function to get the global intl object
export const getGlobalIntl = (): IntlShape => {
  if (!intl) {
    throw new Error('Intl object is not set. Ensure the app is wrapped in IntlProvider.');
  }
  return intl;
};
