/**
 *
 */
import merge from 'lodash/merge';
import { useEffect, useState } from 'react';
import Highcharts, { Options } from 'highcharts';
import Data from 'highcharts/modules/data';
import { useNavigate } from 'react-router';
import { analyticsCartesianParser } from './analyticsCartesianParser';
import { rawDataCartesianParser } from './rawDataCartesianParser';
import { useSlugContext } from '../../..';
import { DASHBOARD_ITEM_TYPE } from '../../dashboard-widgets/types/DashboardItemType';
import { useDispatch } from 'react-redux';
import { handleLegendItemClick } from './cartesianChartLegend';

Data(Highcharts);

export default function useCartesionChart(
  configChartSettings: any,
  defaultChartSettings: any,
  resultSet: any,
  widgetType: DASHBOARD_ITEM_TYPE,
  chartType: string,
  hideSeries?: Set<string>,
  setHideSeries?: React.Dispatch<React.SetStateAction<Set<string>>>
) {
  const [chartSettings, setChartSettings] = useState<any>(defaultChartSettings);
  const { slug } = useSlugContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  /**
   * Chart configurations based on widget Settings
   */
  const { options3d, marker, labelDirection, dataSettings, xAxisTitle, grid, crosshair } =
    configChartSettings;

  const WidgetSettings: Options = {
    chart: {
      options3d: {
        enabled: options3d?.enabled,
        alpha: options3d?.alpha || 10, // These defaults are preveing error in highcharts when these values are initially undefined. Need to remove this code in future
        depth: options3d?.depth || 20,
        beta: options3d?.beta || 40,
        axisLabelPosition: 'auto'
      }
    },
    plotOptions: {
      series: {
        marker: {
          enabled: marker
        },
        ...(chartType === 'column' && {
          dataLabels: {
            enabled: false,
            rotation: labelDirection
          }
        })
      }
    },
    legend: {
      events: {
        itemClick: function (event) {
          return handleLegendItemClick(event, dispatch);
        }
      }
    },
    xAxis: {
      title: {
        text: xAxisTitle
      },
      gridLineWidth: grid?.disable ? 0 : grid?.width || 1,
      ...(!crosshair?.enable && { crosshair: false }),
      ...(crosshair?.enable && {
        crosshair: {
          width: 3,
          color: crosshair?.color?.hex
        }
      })
    }
  };
  useEffect(() => {
    const settingsWithData =
      widgetType === DASHBOARD_ITEM_TYPE.RAW_DATA
        ? rawDataCartesianParser(
            resultSet,
            chartType,
            dispatch,
            configChartSettings,
            hideSeries,
            setHideSeries
          )
        : analyticsCartesianParser(
            resultSet,
            chartType,
            configChartSettings,
            dataSettings,
            grid,
            navigate,
            slug || ''
          );
    const newSettings = merge({}, defaultChartSettings, settingsWithData, WidgetSettings);
    setChartSettings(newSettings);
  }, [resultSet, configChartSettings, hideSeries]);

  return chartSettings;
}
