/**
 * Async Websocket Handler
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
// import { websocketActions, WEBSOCKET_TYPE } from '@slcloud/shared/features';
import WebsocketComponent from './WebsocketComponent';
import { useOnline, useSlugContext } from '../../../framework';
import { websocketActions, WEBSOCKET_TYPE } from '../../websocket';
import { VITE_ENABLE_DEV_WEBSOCKET } from '../../../framework/services/envConstants';

const AsyncHandler = () => {
  const isOnline = useOnline();
  const { slug } = useSlugContext();
  const dispatch = useDispatch();
  const [enableWebsocket] = useState<boolean>(
    // Check if its *NOT* a development environment
    !window?.location?.origin?.includes('localhost') ||
      // Check if override flag is set for enabling websocket to local dev environment
      Boolean(VITE_ENABLE_DEV_WEBSOCKET)
  );
  /**
   *
   */
  useEffect(() => {
    if (enableWebsocket && !isOnline) {
      dispatch(websocketActions.clearWebSocket(WEBSOCKET_TYPE.DEVICE));
      dispatch(websocketActions.clearWebSocket(WEBSOCKET_TYPE.ORG));
    }
  }, [isOnline, slug]);
  /**
   *
   */
  if (enableWebsocket && isOnline && slug && slug !== 'org') {
    return <WebsocketComponent slug={slug} />;
  }
  return null;
};
export default AsyncHandler;
