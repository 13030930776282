/**
 * Raw Datapoints Multi Device List Saga
 */
import { put, takeEvery, call, all } from 'redux-saga/effects';
import { chunk } from 'lodash';
import { AnyAction } from 'redux';
import * as actionTypes from './rawDeviceDatapoints.actionTypes';
import * as actions from './rawDeviceDatapoints.action';
import { httpGet, SafeSaga, APISERVICES } from '../../../../framework';
import { SagaIterator } from 'redux-saga';

const CHUNK_SIZE = 60;
/**
 * @param slug
 * @param deviceIds
 * @returns
 */
function getRawDeviceDatapoints(slug: string, deviceIds: Array<string | number>) {
  return httpGet(
    `orgs/${slug}/devices/datapoints?device_ids=${deviceIds.join()}`,
    null,
    APISERVICES.DEVICE_API
  );
}

//Function to check if device ids array send or device object array.
const isArrayContainsOnlyStrings = (devicesList: Array<any>) => {
  return devicesList.every((element) => typeof element === 'string');
};
/**
 * @param slug
 * @param devices
 * @returns
 */
function* getRawDatapointsListForAllDevices(slug: string, devices: Array<any>) {
  /**
   * #SLC-6656 When there are too many devices in org, lambda service dies to fetch the datapoints of all of them together.
   * Hence we need to chunk these devices in batches and make multiple APIs to get the result and then combine it to form
   * appropriate resultset.
   * TODO:  Eventually even this approach may create a lot of junk data on front-end. Hence we would need
   * to find out a better approach to handle this scenario.
   */
  const deviceIdList = isArrayContainsOnlyStrings(devices)
    ? devices
    : devices.map((device: any) => device.id);
  const deviceChunks = chunk(deviceIdList, CHUNK_SIZE);
  const rawDatapointsListResponse: Array<{
    datapoints: Array<any>;
  }> = yield all(
    deviceChunks.map((deviceList: Array<any>) => {
      return call(getRawDeviceDatapoints, slug, deviceList);
    })
  );
  // Combine result of all the API calls and return a consolidated list
  return rawDatapointsListResponse
    .map((rawResponse: { datapoints: Array<any> }) => rawResponse.datapoints)
    .flat();
}
/**
 *
 */
export function* getRawDatapointsSaga(action: AnyAction) {
  const { slug } = action.payload;
  const { deviceIds } = action.payload;
  if (deviceIds.length > 0) {
    const rawDatapointListMultiDevice: Array<any> = yield getRawDatapointsListForAllDevices(
      slug,
      deviceIds
    );
    if (rawDatapointListMultiDevice) {
      yield put(
        actions.fetchRawDatapointsSuccess({
          datapointsList: rawDatapointListMultiDevice
        })
      );
    }
  }
}
function* errorHandler(error: any) {
  yield put(actions.fetchRawDatapointsError(error));
}
/**
 *
 */
export function* watchRawDatapointListSaga(): SagaIterator {
  yield takeEvery(
    actionTypes.FETCH_RAW_DATAPOINTS,
    SafeSaga(getRawDatapointsSaga, actionTypes.FETCH_RAW_DATAPOINTS, errorHandler)
  );
}
