/**
 * Widget setting state
 * @author ashwini.gawade@shorelineiot.com
 */
export enum GetWidgetSettingStatus {
  INIT,
  FETCHING,
  READY,
  ERROR,
  RESET
}
/**
 *
 */
export interface GetWidgetSettingState {
  status: GetWidgetSettingStatus;
  widgetSettingsData: any;
}
