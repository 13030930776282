import { put, takeEvery, call } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import * as editSurveyActions from './actions/survey.actions';
import * as actionTypes from './actions/survey.actionTypes';
import * as autoConfigActions from './actions/autoConfig.actions';
import {
  GenericObject,
  removeProgressFor,
  showProgressFor,
  httpPut,
  APISERVICES,
  showToast
} from '../../../../../../../framework';
import { SagaIterator } from 'redux-saga';

const EDIT_SURVEY = 'EDIT_SURVEY';

function editSurveyAnswers(
  slug: string,
  deviceId: string,
  componentId: number,
  answersId: string,
  answers: any
) {
  return httpPut(
    `orgs/${slug}/devices/${deviceId}/powertrain_component/${componentId}/survey_answers/${answersId}`,
    {
      survey_answers: answers
    },
    APISERVICES.DEVICE_API
  );
}

function getNarrowbandAxis(datapointType: string) {
  if (datapointType.includes('SNS_ACC_X_AXIS')) {
    return 'x_axis';
  }
  if (datapointType.includes('SNS_ACC_Y_AXIS')) {
    return 'y_axis';
  }
  if (datapointType.includes('SNS_ACC_Z_AXIS')) {
    return 'z_axis';
  }
  if (datapointType.includes('SNS_MIC_STRUCT_BORNE')) {
    return 'mic';
  }
  return '';
}

function formatNarrowbands(response: any) {
  const narrowbands: any = [];

  response.nb_create_update.forEach((narrowbandAxis: any) => {
    const datapointType = narrowbandAxis.raw_datapoint_type;
    const axisNarrowbands = narrowbandAxis.narrowband_config;
    const rawDpid = narrowbandAxis.raw_dpid;
    axisNarrowbands.forEach((narrowband: any) => {
      const updatedNarrowband = {
        ...narrowband,
        description: narrowband.description.replace(/,/g, '')
      };
      if (getNarrowbandAxis(datapointType) === 'x_axis') {
        narrowbands.push({
          ...updatedNarrowband,
          dpid: narrowband.dpid,
          raw_dpid: rawDpid,
          axis: 'x'
        });
      } else if (getNarrowbandAxis(datapointType) === 'y_axis') {
        narrowbands.push({
          ...updatedNarrowband,
          dpid: narrowband.dpid,
          raw_dpid: rawDpid,
          axis: 'y'
        });
      } else if (getNarrowbandAxis(datapointType) === 'z_axis') {
        narrowbands.push({
          ...updatedNarrowband,
          dpid: narrowband.dpid,
          raw_dpid: rawDpid,
          axis: 'z'
        });
      } else if (getNarrowbandAxis(datapointType) === 'mic') {
        narrowbands.push({
          ...updatedNarrowband,
          dpid: narrowband.dpid,
          raw_dpid: rawDpid,
          axis: 'mic'
        });
      } else {
        narrowbands.push({
          ...updatedNarrowband,
          dpid: narrowband.dpid,
          raw_dpid: rawDpid,
          axis: ''
        });
      }
    });
  });

  return narrowbands;
}

export function* editSurveySaga(action: AnyAction) {
  const data = action.payload;
  yield put(showProgressFor(EDIT_SURVEY));
  try {
    yield put(autoConfigActions.setComponentId({ componentId: data.componentId }));

    const response: GenericObject = yield call(
      editSurveyAnswers,
      action.payload.slug,
      action.payload.deviceId,
      action.payload.componentId,
      action.payload.answersId,
      action.payload.answers
    );
    // adding mode auto
    const nbCreateUpdate: any = response.nb_create_update.map((axisNB: any) => {
      const currentAxisNB = axisNB;
      const currentNarrowbandConfig = currentAxisNB.narrowband_config.map(
        (nb: { mode: string }) => {
          return { ...nb, mode: 'auto' };
        }
      );
      currentAxisNB.narrowband_config = currentNarrowbandConfig;

      return currentAxisNB;
    });
    yield put(
      editSurveyActions.editSurveySuccess(formatNarrowbands(response), {
        nb_create_update: nbCreateUpdate,
        ...response
      })
    );
  } catch (error: any) {
    yield put(showToast(error.message, 'error', true));
    yield put(editSurveyActions.editSurveyFailure(error));
  } finally {
    yield put(removeProgressFor(EDIT_SURVEY));
  }
}

export function* watchEditSurveySaga(): SagaIterator {
  yield takeEvery(actionTypes.EDIT_SURVEY_ANSWERS, editSurveySaga);
}
