/**
 * Toast Reducer
 * @author mahesh.kedari@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTIONS from './toast.actionTypes';
/**
 *
 */
type StateType = Array<any>;
/**
 *
 */
const defaultState: Array<any> = [];
/**
 *
 * @param state
 * @param action
 */
export default function SLToastReducer(
  state: StateType = defaultState,
  action: AnyAction
): StateType {
  switch (action.type) {
    case ACTIONS.SHOW_TOAST:
      // If matching error message is found, do not include one more entry of new message
      if (
        action.notification?.options?.variant === 'error' &&
        !!state.find((error: any): boolean => {
          return error.message === action.notification?.message;
        })
      ) {
        // This mechanism is used to prevent duplicate error messages which gives annoying UX.
        return state;
      }
      return [
        ...state,
        {
          key: action.key,
          ...action.notification
        }
      ];

    case ACTIONS.CLOSE_TOAST:
      return state.map((toast) =>
        action.dismissAll || toast.key === action.key ? { ...toast, dismissed: true } : { ...toast }
      );

    case ACTIONS.REMOVE_TOAST:
      return state.filter((toast) => toast.key !== action.key);

    default:
      return state;
  }
}
