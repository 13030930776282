/**
 * External Reports Action Types
 * @author prerna.more@shorelineiot.com
 */
export const FETCH_EXTERNAL_REPORTS = 'FEATURES/REPORTS/EXTERNAL_REPORTS/FETCH_EXTERNAL_REPORTS';
export const FETCH_EXTERNAL_REPORTS_SUCCESS =
  'FEATURES/REPORTS/EXTERNAL_REPORTS/FETCH_EXTERNAL_REPORTS_SUCCESS';
export const FETCH_EXTERNAL_REPORTS_FAILURE =
  'FEATURES/REPORTS/EXTERNAL_REPORTS/FETCH_EXTERNAL_REPORTS_FAILURE';
export const RESET_EXTERNAL_REPORTS = 'FEATURES/REPORTS/EXTERNAL_REPORTS/RESET';
