/**
 *
 */
export enum DATAPOINTS_LIST_STATUS {
  INIT,
  FETCHING,
  READY,
  ERROR
}
/**
 *
 */
export interface DatapointsListState {
  status: DATAPOINTS_LIST_STATUS;
  historicalConfigs: any;
}
