/**
 * get widget settings reducer
 * @author ashwini.gawade@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTIONS_TYPES from './getWidgetSetting.actionTypes';
import { GetWidgetSettingState, GetWidgetSettingStatus } from './getWidgetSetting.state';

const initialState: GetWidgetSettingState = {
  status: GetWidgetSettingStatus.INIT,
  widgetSettingsData: []
};
function getWidgetSettingsProgress(state: GetWidgetSettingState): GetWidgetSettingState {
  return {
    ...state,
    status: GetWidgetSettingStatus.FETCHING
  };
}

function getWidgetSettingsSuccess(
  state: GetWidgetSettingState,
  widgetSettingsData: any
): GetWidgetSettingState {
  return {
    ...state,
    status: GetWidgetSettingStatus.READY,
    widgetSettingsData
  };
}

function getWidgetSettingsFailure(state: GetWidgetSettingState): GetWidgetSettingState {
  return {
    ...state,
    status: GetWidgetSettingStatus.ERROR
  };
}

function resetWidgetSettings(state: GetWidgetSettingState): GetWidgetSettingState {
  return {
    ...state,
    status: GetWidgetSettingStatus.INIT,
    widgetSettingsData: []
  };
}
/**
 *
 * @param state
 * @param action
 */
export default function getWidgetSettingsReducer(
  state: GetWidgetSettingState = initialState,
  action: AnyAction
): GetWidgetSettingState {
  switch (action.type) {
    case ACTIONS_TYPES.GET_WIDGET_SETTINGS:
      return getWidgetSettingsProgress(state);
    case ACTIONS_TYPES.GET_WIDGET_SETTINGS_SUCCESS:
      return getWidgetSettingsSuccess(state, action.payload);
    case ACTIONS_TYPES.GET_WIDGET_SETTINGS_FAILURE:
      return getWidgetSettingsFailure(state);
    case ACTIONS_TYPES.RESET_WIDGET_SETTINGS_STATE:
      return resetWidgetSettings(state);
    default:
      return state;
  }
}
