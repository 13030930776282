export const datapointsMap = [
  { name: 'SNS_TOTAL_VIBRATION_ENERGY', sequence_id: 1 },
  { name: 'SNS_ACC_X_AXIS_1', sequence_id: 2 },
  { name: 'SNS_ACC_Y_AXIS_1', sequence_id: 3 },
  { name: 'SNS_ACC_Z_AXIS_1', sequence_id: 4 },
  { name: 'SNS_ACC_X_AXIS_2', sequence_id: 5 },
  { name: 'SNS_ACC_Y_AXIS_2', sequence_id: 6 },
  { name: 'SNS_ACC_Z_AXIS_2', sequence_id: 7 },
  { name: 'SNS_ACC_X_AXIS_3', sequence_id: 8 },
  { name: 'SNS_ACC_Y_AXIS_3', sequence_id: 9 },
  { name: 'SNS_ACC_Z_AXIS_3', sequence_id: 10 },
  { name: 'SNS_ACC_X_AXIS_4', sequence_id: 11 },
  { name: 'SNS_ACC_Y_AXIS_4', sequence_id: 12 },
  { name: 'SNS_ACC_Z_AXIS_4', sequence_id: 13 },
  { name: 'SNS_TEMP_SURFACE', sequence_id: 14 },
  { name: 'SNS_TEMP_ENV', sequence_id: 15 },
  { name: 'SNS_HMD', sequence_id: 16 },
  { name: 'SNS_MIC_STRUCT_BORNE', sequence_id: 17 },
  { name: 'SNS_MIC_AIRBORNE', sequence_id: 18 },
  { name: 'SNS_BAROMETER', sequence_id: 19 },
  { name: 'SNS_AI_RAW', sequence_id: 20 },
  { name: 'SNS_DI_TACH', sequence_id: 21 },
  { name: 'SNS_DI_FLOW', sequence_id: 22 },
  { name: 'SNS_DI_PULSE_WIDTH', sequence_id: 23 },
  { name: 'SNS_CSQ', sequence_id: 22 },
  { name: 'SNS_POW_TIME', sequence_id: 23 },
  { name: 'SNS_BAT_ACCUM', sequence_id: 24 },
  { name: 'SNS_BAT_TIME', sequence_id: 25 },
  { name: 'SNS_RSRQ', sequence_id: 26 },
  { name: 'SNS_RSRP', sequence_id: 27 },
  { name: 'SNS_SIM_SLOT', sequence_id: 28 }
];

export const datapointsMapNevadaNano = [
  { name: 'Gas Concentration', sequence_id: 1 },
  { name: 'Gas ID', sequence_id: 2 },
  { name: 'Temperature', sequence_id: 3 },
  { name: 'Pressure', sequence_id: 4 },
  { name: 'Absolute Humidity', sequence_id: 5 },
  { name: 'Relative Humidity', sequence_id: 6 },
  { name: 'Status', sequence_id: 7 },
  { name: 'Serial Number', sequence_id: 8 },
  { name: 'Software Version', sequence_id: 9 },
  { name: 'Protocol Version', sequence_id: 10 },
  { name: 'Hardware Version', sequence_id: 11 },
  { name: 'Manufacturing Date', sequence_id: 12 },
  { name: 'Calibration Date', sequence_id: 13 }
];
export const DATAPOINTS_SEQUENCES: Record<string, number> = {
  SNS_TOTAL_VIBRATION_ENERGY: 1,
  SNS_ACC_X_AXIS_1: 2,
  SNS_ACC_Y_AXIS_1: 3,
  SNS_ACC_Z_AXIS_1: 4,
  SNS_ACC_X_AXIS_2: 5,
  SNS_ACC_Y_AXIS_2: 6,
  SNS_ACC_Z_AXIS_2: 7,
  SNS_ACC_X_AXIS_3: 8,
  SNS_ACC_Y_AXIS_3: 9,
  SNS_ACC_Z_AXIS_3: 10,
  SNS_ACC_X_AXIS_4: 11,
  SNS_ACC_Y_AXIS_4: 12,
  SNS_ACC_Z_AXIS_4: 13,
  SNS_TEMP_SURFACE: 14,
  SNS_TEMP_ENV: 15,
  SNS_HMD: 16,
  SNS_MIC_STRUCT_BORNE: 17,
  SNS_MIC_AIRBORNE: 18,
  SNS_BAROMETER: 19,
  SNS_AI_RAW: 20,
  SNS_DI_TACH: 21,
  SNS_DI_FLOW: 22,
  SNS_DI_PULSE_WIDTH: 23,
  SNS_CSQ: 22,
  SNS_POW_TIME: 23,
  SNS_BAT_ACCUM: 24,
  SNS_BAT_TIME: 25,
  SNS_RSRQ: 26,
  SNS_RSRP: 27,
  SNS_SIM_SLOT: 28
};

export const isSenorToBeHidden = (datapointType: string): boolean => {
  /* Datapoint Criteria */
  const hideDatapointTypes: Set<string> = new Set([
    'sns_csq',
    'sns_rsrq',
    'sns_rsrp',
    'sns_post_req_succ',
    'sns_post_req_done',
    'sns_diagnostics_register',
    'sns_sinr',
    'sns_band',
    'sns_sim_slot',
    'sns_bat_accum',
    'sns_bat_time',
    'sns_pow_time',
    'sns_battery'
  ]);
  return hideDatapointTypes.has(datapointType);
};
