/**
 * Shortname Generator
 * @author mahesh.kedari@shorelineiot.com
 */
import { gen } from '@sibevin/random-token';

export const updateShortname = (
  value: any,
  setValue: (slug: string, shortName: string) => void
) => {
  if (value && value.org_name) {
    const organisationName = value.org_name.replace(/[^A-Za-z0-9_]/g, '');
    const shortName: string = organisationName.slice(0, 3) + gen({ length: 6, seed: ['a', 'n'] });
    if (!value.slug || value.slug === '') {
      setValue('slug', shortName.toLowerCase());
    }
  }
};

export const updateSubOrgShortname = (
  value: any,
  setValue: (slug: string, shortName: string) => void
) => {
  if (value && value.org_name) {
    const organisationName = value.org_name.replace(/[^A-Za-z0-9_]/g, '');
    const shortName: string = organisationName.slice(0, 3) + gen({ length: 6, seed: ['a', 'n'] });
    if (!value.slug || value.slug === '') {
      setValue('subOrgSlug', shortName.toLowerCase());
    }
  }
};
