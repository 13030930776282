/**
 * Device Reducer
 * @author priyanka.ambawane@shorelineiot.com
 */

import { combineReducers } from 'redux';
import { rawDatapoints } from './rawDeviceDatapoints';
import { createPowertrain } from './powertrain';
import {
  deviceDetailsReducer as deviceDetails,
  updateNevadaNanoReducer,
  addModbusReducer,
  updateModbusReducer,
  widgetSettings,
  deviceActivity,
  liveData,
  componentClassListReducer,
  loadDevicePhotos,
  exportDeviceData,
  fetchAnalogSchemaReducer,
  addAnalogSensorReducer,
  fetchDigitalSchemaReducer,
  addDigitalSensorReducer,
  getRuleTemplateReducer,
  updateRuleTemplateReducer,
  resetDatapointReducer
} from '../device-details';
import { assetDashboardSlice } from './device.slice';

const devices = combineReducers({
  rawDatapoints,
  deviceDetails,
  liveData,
  exportDeviceData,
  deviceActivity,
  addModbusReducer,
  updateNevadaNanoReducer,
  updateModbusReducer,
  componentClassListReducer,
  widgetSettings,
  fetchAnalogSchemaReducer,
  addAnalogSensorReducer,
  fetchDigitalSchemaReducer,
  addDigitalSensorReducer,
  getRuleTemplateReducer,
  updateRuleTemplateReducer,
  resetDatapointReducer,
  loadDevicePhotos,
  createPowertrain,
  assetDashboard: assetDashboardSlice.reducer
});

export default devices;
