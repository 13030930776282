export enum NarrowbandRuleListStatus {
  INIT,
  IN_PROGRESS,
  READY,
  ERROR
}
/**
 *
 */
export interface NarrowbandRuleListState {
  status: NarrowbandRuleListStatus;
  response: any;
}
