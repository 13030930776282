import { useSelector } from 'react-redux';
import { RootState } from '../../../../configureStore';

const useDefaultDashboard = () =>
  useSelector((state: RootState) => state.features.dashboard.getDefaultDashboard);

const useDefaultDashboardData = () =>
  useSelector(
    (state: RootState) => state.features.dashboard.getDefaultDashboard?.defaultDashboardData
  );
export { useDefaultDashboard, useDefaultDashboardData };
