/**
 * Org Selector State
 * @author mahesh.kedari@shorelineiot.com
 */
import { Organisation } from './Organisation';
/**
 *
 */

export enum ORG_SELECTOR_STATUS {
  'UNKNOWN',
  'SELECT',
  'UPDATING_SELECTION',
  'NO_ORG_FOUND',
  'READY',
  'ERROR'
}
export interface OrgSelectorState {
  status: ORG_SELECTOR_STATUS;
  selectedOrg: Organisation | null;
}
