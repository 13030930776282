/**
 * Action bar
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { ReactElement } from 'react';
import { useActionContext } from './actionContext';

/**
 *
 * @param param0
 */
export default function SLActionBar(): ReactElement {
  const { actions } = useActionContext();
  return <div>{actions}</div>;
}
