/**
 * Organisation Create State
 * @author ashwini.gawade@shorelineiot.com
 */
export enum CREATE_ORG_STATUS {
  INIT,
  CREATING,
  SUCCESS,
  ERROR
}
/**
 *
 */
export interface CreateOrgState {
  status: CREATE_ORG_STATUS;
}
