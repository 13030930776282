/**
 * Notification Selector hook
 * @author prerna.more@shorelineiot.com
 */

import { useSelector } from 'react-redux';
import { RootState } from '../../../configureStore';

const useLastNotification = () =>
  useSelector((state: RootState) => state.features.notifications.setLastNotification);

export { useLastNotification };
