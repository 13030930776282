export type MapChartDeviceSettingsTypes = 'alarms' | 'all';
export type MapChartMarkerTypes = 'marker' | 'bubble';

export const MAP_CHART_DEVICES_SETTINGS_TYPES = {
  ALARMS: 'alarms',
  ALL: 'all'
};
export const MAP_CHART_MARKER_TYPES = {
  BUBBLE: 'bubble',
  MARKER: 'marker'
};

export const defaultMapChartSettings: MapChartSettingsType = {
  deviceSettings: 'all',
  markerType: 'marker'
};

export interface MapChartSettingsType {
  deviceSettings: MapChartDeviceSettingsTypes;
  markerType: MapChartMarkerTypes;
}
