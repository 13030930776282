/**
 * SL Nested Menu Item
 * @author Adam Zmenak 'https://github.com/azmenak'
 * Repository Path:  https://github.com/azmenak/material-ui-nested-menu-item
 */
import React, {
  useState,
  useRef,
  useImperativeHandle,
  // ReactNode,
  // ComponentClass,
  PropsWithChildren
} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem, { MenuItemProps } from '@mui/material/MenuItem';
import ArrowRight from '@mui/icons-material/ArrowRight';
import clsx from 'clsx';
import { sidebarId } from '../sidebar/sidebar.utils';

export interface SLNestedMenuItemProps extends Omit<MenuItemProps, 'button'> {
  /**
   * Open state of parent `<Menu />`, used to close decendent menus when the
   * root menu is closed.
   */
  parentMenuOpen: boolean;
  /**
   * Component for the container element.
   * @default 'div'
   */
  component?: React.ElementType;
  /**
   * Effectively becomes the `children` prop passed to the `<MenuItem/>`
   * element.
   */
  label?: React.ReactNode;
  /**
   * @default <ArrowRight />
   */
  rightIcon?: React.ReactNode;
  /**
   * Props passed to container element.
   */
  ContainerProps?: React.HTMLAttributes<HTMLElement> & React.RefAttributes<HTMLElement | null>;
  /**
   * Props passed to sub `<Menu/>` element
   */
  MenuProps?: Omit<MenuProps, 'children'>;
  /**
   * @see https://material-ui.com/api/list-item/
   */
  button?: true | undefined;
}

const TRANSPARENT = 'rgba(0,0,0,0)';
const useMenuItemStyles = makeStyles((theme) => ({
  root: (props: any) => ({
    backgroundColor: props.open ? theme.palette.action.hover : TRANSPARENT
  })
}));

// interface NestedMenuItemProps {
//   parentMenuOpen: boolean;
//   component: ComponentClass<any, any> | string | undefined;
//   label: string;
//   rightIcon: ReactNode;
//   children: ReactNode;
//   className: string;
//   tabIndex: any;
//   MenuProps: any;
//   ContainerProps: any;
//   [key: string]: any;
// }
/**
 * Use as a drop-in replacement for `<MenuItem>` when you need to add cascading
 * menu elements as children to this component.
 */
const SLNestedMenuItem = React.forwardRef<HTMLLIElement | null, SLNestedMenuItemProps>(
  function NestedMenuItem(props: PropsWithChildren<SLNestedMenuItemProps>, ref) {
    const {
      parentMenuOpen,
      label,
      rightIcon = <ArrowRight onClick={(e) => e.stopPropagation()} />,
      children,
      className,
      tabIndex: tabIndexProp,
      ContainerProps: ContainerPropsProp = {},
      ...menuItemProps
    }: PropsWithChildren<SLNestedMenuItemProps> = props;
    const { ref: containerRefProp, ...ContainerProps } = ContainerPropsProp;

    const menuItemRef = useRef<HTMLLIElement>(null);
    // @ts-ignore
    useImperativeHandle(ref, () => menuItemRef.current);

    const containerRef = useRef<HTMLDivElement>(null);
    // @ts-ignore
    useImperativeHandle(containerRefProp, () => containerRef.current);

    const menuContainerRef = useRef<HTMLDivElement>(null);

    const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

    const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
      setIsSubMenuOpen(true);

      if (ContainerProps?.onMouseEnter) {
        ContainerProps.onMouseEnter(event);
      }
    };
    const handleMouseLeave = (event: React.MouseEvent<HTMLElement>) => {
      setIsSubMenuOpen(false);

      if (ContainerProps?.onMouseLeave) {
        ContainerProps.onMouseLeave(event);
      }
    };

    const handleOnClick = (event: React.MouseEvent<HTMLElement>) => {
      setIsSubMenuOpen(false);

      if (ContainerProps?.onMouseLeave) {
        ContainerProps.onMouseLeave(event);
      }
    };
    // Check if any immediate children are active
    const isSubmenuFocused = () => {
      const active = containerRef.current?.ownerDocument?.activeElement;
      const allChildren = menuContainerRef.current?.children ?? [];

      for (const child of Array.from(allChildren)) {
        if (child === active) {
          return true;
        }
      }
      return false;
    };

    // const isContains = (json: any, value: any) => {
    //   let contains = false;
    //   Object.keys(json).some((key) => {
    //     contains =
    //       typeof json[key] === 'object' ? isContains(json[key], value) : json[key] === value;
    //     return contains;
    //   });
    //   return contains;
    // };
    const handleFocus = (event: React.FocusEvent<HTMLElement>) => {
      if (event.target === containerRef.current) {
        setIsSubMenuOpen(true);
      }

      if (ContainerProps?.onFocus) {
        ContainerProps.onFocus(event);
      }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Escape') {
        return;
      }

      if (isSubmenuFocused()) {
        event.stopPropagation();
      }

      const active = containerRef.current?.ownerDocument?.activeElement;

      if (event.key === 'ArrowLeft' && isSubmenuFocused()) {
        containerRef.current?.focus();
      }

      if (
        event.key === 'ArrowRight' &&
        event.target === containerRef.current &&
        event.target === active
      ) {
        const firstChild = menuContainerRef.current?.children[0] as HTMLElement | undefined;
        firstChild?.focus();
      }
    };

    const open = isSubMenuOpen && parentMenuOpen;
    const menuItemClasses = useMenuItemStyles({ open });

    // Root element must have a `tabIndex` attribute for keyboard navigation
    let tabIndex;
    if (!props.disabled) {
      tabIndex = tabIndexProp !== undefined ? tabIndexProp : -1;
    }
    return (
      <div
        {...ContainerProps}
        ref={containerRef}
        onFocus={handleFocus}
        tabIndex={tabIndex}
        role="button"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onKeyDown={handleKeyDown}
        onClick={handleOnClick}
        style={{ zIndex: 9999 }}>
        <MenuItem
          id={`${sidebarId.select_org}-${label}`}
          {...menuItemProps}
          className={clsx(menuItemClasses.root, className)}
          ref={menuItemRef}
          style={{ zIndex: 9999 }}>
          {label}
          {rightIcon}
        </MenuItem>
        <Menu
          // Set pointer events to 'none' to prevent the invisible Popover div
          // from capturing events for clicks and hovers
          style={{ pointerEvents: 'none', zIndex: 9999 }}
          PaperProps={{
            style: { pointerEvents: 'auto' }
          }}
          anchorEl={menuItemRef.current}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          open={open}
          autoFocus={false}
          disableAutoFocus
          disableEnforceFocus
          onClose={() => {
            setIsSubMenuOpen(false);
          }}
          className={className}>
          <div ref={menuContainerRef} style={{ zIndex: 9999 }}>
            {children}
          </div>
        </Menu>
      </div>
    );
  }
);

export default SLNestedMenuItem;
