// /**
//  * Breadcrumb Reducers
//  * @author mahesh.kedari@shorelineiot.com
//  */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BreadcrumbState } from './types/breadcrumbState';
import { BreadcrumbType } from './types/breadcrumbTypes';

const initialState: BreadcrumbState = {
  breadcrumbs: [],
  shouldMoveToRight: false
};

const pushToBreadcrumbHandler = (state: BreadcrumbState, action: PayloadAction<BreadcrumbType>) => {
  const { breadcrumbs } = state;
  const index = breadcrumbs.findIndex((br: BreadcrumbType) => {
    return action.payload.key === br.key;
  });
  if (index !== -1) {
    return {
      breadcrumbs: [...breadcrumbs.slice(0, index), action.payload, ...breadcrumbs.slice(index + 1)]
    };
  }
  state.breadcrumbs.push(action.payload);
};

const popFromBreadcrumbHandler = (state: BreadcrumbState, action: PayloadAction<string>) => {
  const newBreadcrumbs = state.breadcrumbs.filter((breadcrumb: BreadcrumbType) => {
    return breadcrumb.key !== action.payload;
  });
  state.breadcrumbs = newBreadcrumbs;
};

const resetBreadcrumbHandler = () => {
  return initialState;
};

const breadcrumbSlice = createSlice({
  name: 'breadcrumb',
  initialState,
  reducers: {
    pushToBreadcrumb: (state, action: PayloadAction<BreadcrumbType>) =>
      pushToBreadcrumbHandler(state, action),
    popFromBreadcrumb: (state, action: PayloadAction<string>) =>
      popFromBreadcrumbHandler(state, action),
    resetBreadcrumb: () => resetBreadcrumbHandler(),
    moveBreadcrumbToRight: (state, action: PayloadAction<boolean>) => {
      state.shouldMoveToRight = action.payload;
    }
  }
});

export const { pushToBreadcrumb, popFromBreadcrumb, resetBreadcrumb, moveBreadcrumbToRight } =
  breadcrumbSlice.actions;
export default breadcrumbSlice.reducer;
