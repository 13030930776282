import { FetchSuborgsArgs, Org, Suborg } from './orgs.types';
import { APISERVICES } from '../../../../framework';
import { Organisation } from '../types';
import { switchOrgSuccess } from './orgs.slice';
import { ORGS_TAG_TYPES } from './orgs.utils';
import { featureApi } from '../../../feature.api';
import { invalidateTagsListId } from '../../../../framework/lib/rtk/RTK.constants';

export const orgsApi = featureApi.injectEndpoints({
  endpoints: (build) => ({
    fetchOrgs: build.query<Org[], void>({
      query: () => ({
        apiEndpoint: `orgs`,
        params: null,
        service: APISERVICES.HOST_API,
        method: 'GET',
        actionKey: 'ORGS',
        onSuccess: (dispatch, result) => {
          const lastSwitchedOrg: Org | undefined = result.results?.find(
            (orgs: Org) => orgs.last_switched_to
          );
          if (lastSwitchedOrg) {
            dispatch(switchOrgSuccess(lastSwitchedOrg.org_uuid));
          }
        }
      }),
      transformResponse: (response) => {
        return response?.results;
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ org_uuid }: { org_uuid: string }) => ({
                type: ORGS_TAG_TYPES.ORGS,
                id: org_uuid
              })),
              { type: ORGS_TAG_TYPES.ORGS, id: invalidateTagsListId }
            ]
          : [{ type: ORGS_TAG_TYPES.ORGS, id: invalidateTagsListId }]
    }),
    fetchSuborgs: build.query<Suborg[], FetchSuborgsArgs>({
      query: (fetchSuborgsArg) => ({
        apiEndpoint: `orgs/${fetchSuborgsArg.slug}/suborgs`,
        params: null,
        service: APISERVICES.HOST_API,
        method: 'GET',
        actionKey: 'SUBORGS',
        showProgressBar: fetchSuborgsArg.showProgressBar
      }),
      transformResponse: (response) => {
        return response?.results;
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ uuid }: { uuid: string }) => ({
                type: ORGS_TAG_TYPES.SUBORGS,
                id: uuid
              })),
              { type: ORGS_TAG_TYPES.SUBORGS, id: invalidateTagsListId }
            ]
          : [{ type: ORGS_TAG_TYPES.SUBORGS, id: invalidateTagsListId }]
    }),
    switchOrg: build.mutation<any, Organisation>({
      query: (switchOrgArgs) => ({
        apiEndpoint: `orgs/${switchOrgArgs.slug}/mark_last_switch_to`,
        params: null,
        service: APISERVICES.HOST_API,
        method: 'POST',
        actionKey: 'SWITCH_ORG',
        onSuccess: (dispatch) => {
          dispatch(switchOrgSuccess(switchOrgArgs.org_uuid));
        }
      }),
      invalidatesTags: () => {
        return [
          { type: ORGS_TAG_TYPES.ORGS, id: invalidateTagsListId },
          { type: ORGS_TAG_TYPES.SUBORGS, id: invalidateTagsListId }
        ];
      }
    })
  })
});

export const { useSwitchOrgMutation, useFetchOrgsQuery, useFetchSuborgsQuery } = orgsApi;
