import { MenuClasses, Theme } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

export const MuiMenuStyle:
  | Partial<OverridesStyleRules<keyof MenuClasses, 'MuiMenu', Omit<Theme, 'components'>>>
  | undefined = {
  paper: {
    '& .MuiBox-root .MuiButtonBase-root': {
      fontSize: '0.89rem',
      padding: '0px'
    }
  }
};
