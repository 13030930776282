/**
 * Leak Detection Date Range
 * @author mahesh.kedari@shorelineiot.com
 */

import moment from 'moment';

export type LeakDetectionDateRangeType = {
  value: '1w' | '1m' | '3m' | '6m' | '1y';
  startDate: number;
  endDate: number;
};

export const LeakDetectionDateRange: Array<LeakDetectionDateRangeType> = [
  {
    value: '1w',
    startDate: moment().subtract(1, 'weeks').valueOf(),
    endDate: moment().valueOf()
  },
  {
    value: '1m',
    startDate: moment().subtract(1, 'months').valueOf(),
    endDate: moment().valueOf()
  },
  {
    value: '3m',
    startDate: moment().subtract(3, 'months').valueOf(),
    endDate: moment().valueOf()
  },
  {
    value: '6m',
    startDate: moment().subtract(6, 'months').valueOf(),
    endDate: moment().valueOf()
  },
  {
    value: '1y',
    startDate: moment().subtract(1, 'years').valueOf(),
    endDate: moment().valueOf()
  }
];
