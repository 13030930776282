/**
 * Remove Datapoint Saga
 * @author mahesh.kedari@shorelineiot.com
 */
import { AnyAction } from 'redux';
import { takeEvery, call, put, all } from 'redux-saga/effects';
import {
  datapointActions,
  DATAPOINT_ACTIONS,
  sensorSettingsActions,
  SENSOR_SETTINGS_ACTIONS
} from '../actions';
import {
  GenericObject,
  hideBackdrop,
  httpDelete,
  httpGet,
  httpPut,
  removeProgressFor,
  showBackdrop,
  showProgressFor,
  showToast,
  APISERVICES
} from '../../../../../../../framework';
import { SagaIterator } from 'redux-saga';
import { deviceApi } from '../../../../../store/device.api';
import { DEVICE_TAG } from '../../../../../store/device.types';
/**
 *
 * @param data
 */
function updateDatapoint(data: any) {
  // {DEVICE_BASE_URL}/api/v1/orgs/{SLUG}/device_sensor_modules/{SENSOR_MODULE_UUID}

  return httpPut(
    `orgs/${data.slug}/device_sensor_modules/${data.sensorModuleUUID}`,
    {
      device_id: data.deviceId,
      sensor_module: data.sensorModule
    },
    APISERVICES.DEVICE_API
  );
}

function fetchDatapointSchema(data: any) {
  return httpGet(
    `orgs/${data.slug}/device_sensor_modules?device_id=${data.deviceId}&dpid=${data.dpid}`,
    null,
    APISERVICES.DEVICE_API
  );
}
/**
 *
 * @param data
 */
function removeDatapoint(data: any) {
  // {DEVICE_BASE_URL}/api/v1/orgs/{SLUG}/device_sensor_modules/{SENSOR_MODULE_UUID}

  return httpDelete(
    `orgs/${data.slug}/device_sensor_modules/${data.sensorModuleUUID}?device_id=${data.deviceId}`,
    null,
    APISERVICES.DEVICE_URL
  );
}
/**
 *
 * @param action
 */
function* updateSensorConfigSaga(action: AnyAction) {
  yield put(showProgressFor('GET_DATAPOINTS'));
  try {
    const resp: GenericObject = yield call(updateDatapoint, action.payload);
    if (action.payload?.sensorModule?.sensor_type !== 'sns_narrowband') {
      yield put(showToast('Settings applied successfully”', 'success'));
      yield put(
        datapointActions.fetchDatapoints({
          slug: action.payload.slug,
          deviceId: action.payload.deviceId
        })
      );
    }
    yield put(sensorSettingsActions.updateSensorConfigSuccess(resp));
  } catch (error: any) {
    yield put(showToast(error?.message, 'error', true));
    yield put(sensorSettingsActions.updateSensorConfigFailure(error));
  } finally {
    yield put(removeProgressFor('GET_DATAPOINTS'));
  }
}

function* fetchSensorConfigSaga(action: AnyAction) {
  yield put(showProgressFor('FETCH_DATAPOINT_SCHEMA'));
  try {
    const resp: GenericObject = yield call(fetchDatapointSchema, action.payload);
    yield put(sensorSettingsActions.sensorSettingsSuccess(resp));
  } catch (error: any) {
    yield put(sensorSettingsActions.sensorSettingsFailure(error));
  } finally {
    yield put(removeProgressFor('FETCH_DATAPOINT_SCHEMA'));
  }
}

/**
 *
 * @param action
 */
function* removeDataPointsSaga(action: AnyAction) {
  yield put(showBackdrop());
  try {
    yield call(removeDatapoint, action.payload);
    yield put(datapointActions.removeDatapointSuccess({ dpid: action.payload.dpid }));

    put(deviceApi.util.invalidateTags([DEVICE_TAG.ADDITIONAL_SENSORS]));

    yield put(
      datapointActions.fetchDatapoints({
        slug: action.payload.slug,
        deviceId: action.payload.deviceId
      })
    );
    yield put(showToast('Datapoint removed Successfully', 'success'));
  } catch (error: any) {
    yield put(showToast('Failed to remove datapoints', 'error', true));
    yield put(datapointActions.removeDatapointFailure(error));
  } finally {
    yield put(hideBackdrop());
  }
}

/**
 *
 */
export function* watchUpdateDatapointsSaga(): SagaIterator {
  yield all([
    takeEvery(SENSOR_SETTINGS_ACTIONS.SENSOR_SETTINGS_FETCH, fetchSensorConfigSaga),
    takeEvery(SENSOR_SETTINGS_ACTIONS.UPDATE_SENSOR_CONFIG, updateSensorConfigSaga),
    takeEvery(DATAPOINT_ACTIONS.REMOVE_DATAPOINT, removeDataPointsSaga)
  ]);
}
