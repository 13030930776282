export const FETCH_ANALOG_SENSOR_SCHEMA_PROGRESS_AI1 =
  'FEATURES/DEVICE/ADDITIONAL-SENSORS/ANALOG-SENSOR/FETCH_ANALOG_SENSOR_SCHEMA_PROGRESS_AI1';
export const FETCH_ANALOG_SENSOR_SCHEMA_SUCCESS_AI1 =
  'FEATURES/DEVICE/ADDITIONAL-SENSORS/ANALOG-SENSOR/FETCH_ANALOG_SENSOR_SCHEMA_SUCCESS_AI1';
export const FETCH_ANALOG_SENSOR_SCHEMA_FAILURE_AI1 =
  'FEATURES/DEVICE/ADDITIONAL-SENSORS/ANALOG-SENSOR/FETCH_ANALOG_SENSOR_SCHEMA_FAILURE_AI1';
export const FETCH_ANALOG_SENSOR_RESET_STATE_AI1 =
  'FEATURES/DEVICE/ADDITIONAL-SENSORS/ANALOG-SENSOR/FETCH_ANALOG_SENSOR_RESET_STATE_AI1';

export const FETCH_ANALOG_SENSOR_SCHEMA_PROGRESS_AI2 =
  'FEATURES/DEVICE/ADDITIONAL-SENSORS/ANALOG-SENSOR/FETCH_ANALOG_SENSOR_SCHEMA_PROGRESS_AI2';
export const FETCH_ANALOG_SENSOR_SCHEMA_SUCCESS_AI2 =
  'FEATURES/DEVICE/ADDITIONAL-SENSORS/ANALOG-SENSOR/FETCH_ANALOG_SENSOR_SCHEMA_SUCCESS_AI2';
export const FETCH_ANALOG_SENSOR_SCHEMA_FAILURE_AI2 =
  'FEATURES/DEVICE/ADDITIONAL-SENSORS/ANALOG-SENSOR/FETCH_ANALOG_SENSOR_SCHEMA_FAILURE_AI2';
export const FETCH_ANALOG_SENSOR_RESET_STATE_AI2 =
  'FEATURES/DEVICE/ADDITIONAL-SENSORS/ANALOG-SENSOR/FETCH_ANALOG_SENSOR_RESET_STATE_AI2';

export const ADD_ANALOG_SENSOR_PROGRESS =
  'FEATURES/DEVICE/ADDITIONAL-SENSORS/ANALOG-SENSOR/ADD_ANALOG_SENSOR_PROGRESS';
export const ADD_ANALOG_SENSOR_SUCCESS =
  'FEATURES/DEVICE/ADDITIONAL-SENSORS/ANALOG-SENSOR/ADD_ANALOG_SENSOR_SUCCESS';
export const ADD_ANALOG_SENSOR_FAILURE =
  'FEATURES/DEVICE/ADDITIONAL-SENSORS/ANALOG-SENSOR/ADD_ANALOG_SENSOR_FAILURE';
export const ADD_ANALOG_SENSOR_RESET_STATE =
  'FEATURES/DEVICE/ADDITIONAL-SENSORS/ANALOG-SENSOR/ADD_ANALOG_SENSOR_RESET_STATE';

export const SET_DEFAULT_AI1 = 'FEATURES/DEVICE/ADDITIONAL-SENSORS/ANALOG-SENSOR/SET_DEFAULT_AI1';
export const SET_DEFAULT_AI2 = 'FEATURES/DEVICE/ADDITIONAL-SENSORS/ANALOG-SENSOR/SET_DEFAULT_AI2';
