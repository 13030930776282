/**
 * Features Reducer
 * @author harsha.gokhale@shorelineiot.com
 */
import { combineReducers } from 'redux';
import widgetDetails from './widget-details/widgetDetails.reducer';
import dashboardItems from './dashboard-items/dashboardItems.reducer';
import updateDashboardReducer from './update-dashboard/updateDashboard.reducer';
import deleteDashboard from './delete-dashboard/deleteDashboard.reducer';
import createDashboardItem from './create-dashboard-item/createDashboardItem.reducer';
import dashboardList from './dashboard-list/dashboardList.reducer';
import getDefaultDashboard from './default-dashboard/defaultDashboard.reducer';
import updateDashboardItem from './update-dashboard-item/updateDashboardItem.reducer';
import deleteDashboardItem from './delete-dashboard-item/deleteDashboardItem.reducer';
import localDashboardItems from './local-dashboard-items/localDashboardItems.reducer';
import addMultipleDashboardItems from './add-multiple-dashboard-items/addMultipleDashboardItems.reducer';
import updateMultipleDashboardItems from './update-multiple-dashboard-items/updateMultipleDashboardItems.reducer';
import setDefaultDashboardReducer from './set-default-dashboard/setDefaultDashboard.reducer';
/**
 *
 */
const dashboardReducer = combineReducers({
  widgetDetails,
  dashboardItems,
  updateDashboardReducer,
  deleteDashboard,
  createDashboardItem,
  dashboardList,
  getDefaultDashboard,
  updateDashboardItem,
  deleteDashboardItem,
  localDashboardItems,
  addMultipleDashboardItems,
  updateMultipleDashboardItems,
  setDefaultDashboardReducer
});
/**
 * Features reducer combined
 */
export default dashboardReducer;
