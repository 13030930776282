import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: 'rgba(0,0,0,0.8)',
    color: 'white',
    width: 'fit-content',
    minWidth: '120px',
    padding: '10px',
    borderRadius: '5px',
    fontSize: 'small',
    transform: 'translate(-50%, -100%)',
    zIndex: 1000003,
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.5)',
    position: 'absolute'
  }
}));
