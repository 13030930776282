/**
 * widget setting feature  saga
 * @author ashwini.gawade@shorelineiot.com
 */
import { all } from 'redux-saga/effects';
import { watchUpdateWidgetSettingSaga } from './update-line-chart-config/updateWidgetSetting.saga';
import { watchWidgetSettingSaga } from './get-line-chart-config/getWidgetSetting.saga';

export function* widgetSettingsSagas() {
  yield all([watchUpdateWidgetSettingSaga(), watchWidgetSettingSaga()]);
}
