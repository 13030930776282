/**
 * Configuration Reducer
 * @author mahesh.kedari@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTIONS from './configure.actionTypes';
/**
 * Configuration state object definition
 */
export type AuthConfigStateType = {
  status: 'INIT' | 'CONFIG_RECEIVED' | 'CONFIGURED' | 'READY' | 'ERROR';
  config: {
    region?: string | undefined;
    userPoolId: string | undefined;
    userPoolClientId: string | undefined;
    identityPoolId: string | undefined;
  } | null;
};
/**
 * Initial state of configuration object
 */
const initialState: AuthConfigStateType = {
  status: 'INIT',
  config: null
};
/**
 * Request to backend for Amplify configuration is successful
 * @param state
 * @param action
 */
function onAmplifyRequestSuccess(
  state: AuthConfigStateType,
  action: AnyAction
): AuthConfigStateType {
  return {
    ...state,
    status: 'CONFIG_RECEIVED',
    config: action.payload
  };
}
/**
 * Request to backend for amplify configuration has failed.
 * @param state
 */
const VITE_AUTH_REGION = 'us-east-1';
const VITE_AUTH_USER_POOL_ID = 'us-east-1_MNKlePQjo';
const VITE_AUTH_USER_POOL_WEB_CLIENT = '64ktb9h1p40tqf7pmuvmhqmqh6';
const VITE_IDENTITY_POOL_ID = 'us-east-1:2cc9e911-1b52-4797-9770-2ef2c44f181a';
function onAmplifyRequestFailure(state: AuthConfigStateType): AuthConfigStateType {
  // Fallback - When config is not received from backend, user environment configurations
  const amplifyConfig = {
    region: VITE_AUTH_REGION,
    identityPoolId: VITE_IDENTITY_POOL_ID,
    userPoolId: VITE_AUTH_USER_POOL_ID,
    userPoolClientId: VITE_AUTH_USER_POOL_WEB_CLIENT
  };
  return {
    ...state,
    // TODO: handle error condition
    status: 'CONFIG_RECEIVED',
    config: amplifyConfig
  };
}
/**
 * Amplify is configured successfully and ready to take authentication requests
 * @param state
 */
function onAmplifyConfigSuccess(state: AuthConfigStateType): AuthConfigStateType {
  return {
    ...state,
    status: 'CONFIGURED'
  };
}
/**
 * Amplify configuration has failed
 * @param state
 */
function onAmplifyConfigFailure(state: AuthConfigStateType): AuthConfigStateType {
  return {
    ...state,
    status: 'ERROR'
  };
}

/**
 * Configuration feature reducer
 */
export default function ConfigReducer(
  state: AuthConfigStateType = initialState,
  action: AnyAction
): AuthConfigStateType {
  switch (action.type) {
    case ACTIONS.AMPLIFY_REQUEST_SUCCESS:
      return onAmplifyRequestSuccess(state, action);
    case ACTIONS.AMPLIFY_REQUEST_FAILURE:
      return onAmplifyRequestFailure(state);
    case ACTIONS.AMPLIFY_CONFIG_SUCCESS:
      return onAmplifyConfigSuccess(state);
    case ACTIONS.AMPLIFY_CONFIG_FAILURE:
      return onAmplifyConfigFailure(state);
    default:
      return state;
  }
}
