export interface ComponentType {
  id?: string;
  component_class?: string;
  component_revision?: number;
  component_subclass?: string;
  manufacturer?: string;
  model?: string;
  narrowbands: any;
  nominal_rpm: number;
  description?: string;
}
/**
 *
 */
export interface MountPointType {
  mount_pt_name: string;
  mount_pt_id?: number;
  mount_pt_copy_id?: number;
  label?: string;
  value?: string | number;
}
/**
 *
 */
export enum COMPONENT_DETAILS_STATUS {
  INIT,
  FETCHING,
  READY,
  ERROR,
  IN_PROGRESS,
  UPDATED
}

/**
 *
 */
export interface ComponentDetailsStateType {
  status: COMPONENT_DETAILS_STATUS;
  data?: ComponentType;
  narrowbands?: Array<any>;
  multipleComponents?: Array<ComponentType>;
  mountPoints?: Array<MountPointType>;
  narrowbandData: NarrowbandType[];
  originalNarrowbandData: Array<any>;
}

/**
 *
 */
export interface NarrowbandType {
  fmax: number;
  name: string;
  moderate?: string | null;
  odr?: number;
  cfmax: number;
  high?: string | null;
  source?: string;
  description: string | null;
  critical?: string | null;
  cfmin: number;
  mount_pts: Array<MountPointType>;
  global_dpid?: number;
  fmin: number;
  low?: string | null;
  path: string;
  freq_order?: string;
  raw_name?: string;
  new?: boolean;
  original_name?: string;
  tempId?: number;
}
export type updateNBCellColumns = 'name' | 'path' | 'freq_order';
