/**
 * Auth Routes
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { ReactElement } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import LoginPage from './login/LoginPage';
import RegisterPage from './register/RegisterPage';
import VerificationComponent from './register/verification/VerificationComponent';
import ResetPassword from './login/accept-invite/AcceptInvite';
import ForgotPassword from './forgot-password/ForgotPassword';
import ConfirmUser from './register/confirm-user/ConfirmUser';
import FederatedAuth from './federated/FederatedAuth';

function authRoutes(): ReactElement {
  return (
    <Routes>
      <Route path="login" element={<LoginPage />} />
      <Route path="register" element={<RegisterPage />} />
      <Route path="invite-accepted" element={<ResetPassword />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="federated_auth" element={<FederatedAuth />} />
      <Route path="federated_invite_accept" element={<FederatedAuth acceptInvite />} />
      <Route path="verification-link" element={<VerificationComponent />} />
      <Route path="verify-email" element={<ConfirmUser />} />
      <Route path="*" element={<Navigate to="login" replace />} />
    </Routes>
  );
}
export default authRoutes;
