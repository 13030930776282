import { AnyAction } from 'redux';
import * as ACTION_TYPE from './actions/updateNevadaNano.actionTypes';
import { UpdateNevadaNanoState } from './types/UpdateNevadaNanoState';
import { UPDATE_NEVADA_NANO_STATUS } from './types/UpdateNevadaNano';

const initialState: UpdateNevadaNanoState = {
  status: UPDATE_NEVADA_NANO_STATUS.INIT
};

function updateNevadaNanoProgress(state: UpdateNevadaNanoState): UpdateNevadaNanoState {
  return {
    ...state,
    status: UPDATE_NEVADA_NANO_STATUS.PROGRESS
  };
}

function updateNevadaNanoSuccess(
  state: UpdateNevadaNanoState,
  action: AnyAction
): UpdateNevadaNanoState {
  return {
    ...state,
    status: UPDATE_NEVADA_NANO_STATUS.READY,
    nevadaNanoDetails: action.data
  };
}

function updateNevadaNanoFailure(state: UpdateNevadaNanoState): UpdateNevadaNanoState {
  return {
    ...state,
    status: UPDATE_NEVADA_NANO_STATUS.ERROR
  };
}

function resetNevadaNanoUpdate() {
  return {
    ...initialState
  };
}
/**
 *
 * @param state
 * @param action
 */
export function updateNevadaNanoReducer(
  state: UpdateNevadaNanoState = initialState,
  action: AnyAction
): UpdateNevadaNanoState {
  switch (action.type) {
    case ACTION_TYPE.UPDATE_NEVADA_NANO_PROGRESS:
      return updateNevadaNanoProgress(state);
    case ACTION_TYPE.UPDATE_NEVADA_NANO_SUCCESS:
      return updateNevadaNanoSuccess(state, action);
    case ACTION_TYPE.UPDATE_NEVADA_NANO_FAILURE:
      return updateNevadaNanoFailure(state);
    case ACTION_TYPE.UPDATE_NEVADA_NANO_RESET:
      return resetNevadaNanoUpdate();
    default:
      return state;
  }
}
