/**
 * Customization Action
 * @author ashwini.gawade@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTIONS from './getPersonalizeSettings.actionTypes';
/**
 *
 */
export const getPersonalizationInfoProgress = (slug: string): AnyAction => {
  return {
    type: ACTIONS.FETCH_THEMES,
    payload: {
      slug
    }
  };
};
/**
 *
 * @param data
 */
export const getPersonalizationInfoSuccess = (data: any): AnyAction => {
  // returns the action type
  return {
    type: ACTIONS.FETCH_THEMES_SUCCESS,
    data
  };
};
/**
 *
 * @param error
 */
export const getPersonalizationInfoFailure = (error: any): AnyAction => {
  // returns the action type
  return {
    type: ACTIONS.FETCH_THEMES_FAILURE,
    error
  };
};
/**
 *
 * @param data
 */

export const getPersonalizationInfoReset = (): AnyAction => {
  return {
    type: ACTIONS.RESET_THEME
  };
};
