/**
 * Progress Bar Selector hook
 * @author prerna.more@shorelineiot.com
 */
import { useSelector } from 'react-redux';
import { RootState } from '../../../configureStore';

const useProgressBar = () => useSelector((state: RootState) => state.framework.progressBar);

export { useProgressBar };
