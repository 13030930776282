/**
 * Input Adornment
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { ReactElement } from 'react';
import { InputAdornment, InputAdornmentProps } from '@mui/material';

type Props = InputAdornmentProps;

function SLInputAdornment({ position = 'end', ...otherProps }: Props): ReactElement {
  return (
    <InputAdornment position={position} {...otherProps}>
      {/* <IconButton
                aria-label="toggle password visibility"
                 onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword} 
              >
                <InfoOutlinedIcon color="primary" />
              </IconButton> */}
    </InputAdornment>
  );
}

export default SLInputAdornment;
