/*  */
/**
 * Narrowbands Data Parser
 * @author mahesh.kedari@shorelineiot.com
 */

import {
  AMPLITUDE_SELECTION,
  FREQUENCY_UNIT,
  MotionUnitTypes,
  UnitsPayload
} from '../va-units/vaUnit.stateType';
import { NormalizedNarrowbandsType, LatestRawNBType } from './vaNarrowbands.stateType';

/**
 * Get ODR For selected Narrowband
 * @param narrowBand
 * @returns
 */
export const getODR = (narrowBand: any) => {
  switch (true) {
    case narrowBand?.fmax >= 10000:
      return 80000;
    case narrowBand?.fmax > 1400 || narrowBand?.nb_type === 'tve':
      return 25600;
    case narrowBand?.fmax > 88:
      return 3200;
    default:
      return 800;
  }
};

export const duplicateKeysForNB = (
  normalizedNarrowbands: NormalizedNarrowbandsType[],
  amplitude: AMPLITUDE_SELECTION
) => {
  return normalizedNarrowbands?.map((narrowband: NormalizedNarrowbandsType) => {
    const { peak_amplitude } = getAmplitudeSelectionBasedValues(narrowband, amplitude);

    // Make a copy of the original narrowband object
    const narrowbandCopy = { ...narrowband };

    // Add the new properties to the copied object
    narrowbandCopy.fmin_in_HZ =
      typeof narrowband.cfmin === 'number' && narrowband.cfmin !== 0
        ? narrowband.cfmin
        : narrowband.fmin;
    narrowbandCopy.fmax_in_HZ =
      typeof narrowband.cfmax === 'number' && narrowband.cfmax !== 0
        ? narrowband.cfmax
        : narrowband.fmax;
    narrowbandCopy.frequency_at_peak_amp_in_HZ = narrowband.frequency_at_peak_amp;
    narrowbandCopy.peak_amplitude_in_hz = peak_amplitude;

    return narrowbandCopy; // Return the copied object
  });
};

/**

Calculates the equivalent value for a given frequency unit.
@param {number} value - The value to convert.
@param {string} frequencyUnit - The frequency unit to convert to.
@param {number | undefined} speedValue - Optional parameter representing the speed value, used when frequencyUnit is ORDER.
@returns {number} - The equivalent value for the selected frequency unit.
*/
export function getEquivalentValueForSelectedFrequencyUnit(
  value: number,
  frequencyUnit: FREQUENCY_UNIT,
  speedValue?: number
): number {
  switch (frequencyUnit) {
    case FREQUENCY_UNIT.CPM: {
      return value * 60;
    }
    case FREQUENCY_UNIT.ORDERS: {
      if (speedValue && speedValue > 0) {
        return value / speedValue;
      } else {
        return value;
      }
    }
    default: {
      return value;
    }
  }
}

export function getEquivalentValueinHz(
  value: number,
  frequencyUnit: string,
  speedValue: number | undefined
): number {
  switch (frequencyUnit) {
    case FREQUENCY_UNIT.CPM: {
      return value / 60;
    }
    case FREQUENCY_UNIT.ORDERS: {
      if (speedValue && speedValue > 0) {
        return value * speedValue;
      } else {
        return value;
      }
    }
    default: {
      return value;
    }
  }
}

/**
 * Update values based on Frequency Unit selected
 * Currently we have fmin, fmax and frequency_at_peak_amp value to be updated
 * @param narrowband
 * @param frequencyUnit
 * @returns {fmin, fmax, frequency_at_peak_amp}
 */
export function getFrequencyUnitBasedValues(
  copy: any,
  frequencyUnit: FREQUENCY_UNIT,
  speedValue: any
) {
  const narrowband = { ...copy };

  let frequency_at_peak_amp = narrowband?.frequency_at_peak_amp;

  let fmin = getEquivalentValueForSelectedFrequencyUnit(
    narrowband.fmin_in_HZ,
    frequencyUnit,
    speedValue
  );
  let fmax = getEquivalentValueForSelectedFrequencyUnit(
    narrowband.fmax_in_HZ,
    frequencyUnit,
    speedValue
  );
  frequency_at_peak_amp = getEquivalentValueForSelectedFrequencyUnit(
    narrowband.frequency_at_peak_amp_in_HZ,
    frequencyUnit,
    speedValue
  );
  if (typeof narrowband.cfmin === 'number' && narrowband.cfmin !== 0) {
    fmin *= typeof speedValue === 'number' ? speedValue : 0;
  }

  if (typeof narrowband.cfmax === 'number' && narrowband.cfmax !== 0) {
    fmax *= typeof speedValue === 'number' ? speedValue : 0;
  }

  return { fmin, fmax, frequency_at_peak_amp };
}
/**
 * Update values based on Amplitude Selection
 * Currently we have only peak_amplitude value to be updated
 * @param narrowband
 * @param amplitudeSelection
 * @returns {peak_amplitude}
 */
export function getAmplitudeSelectionBasedValues(
  narrowband: any,
  amplitudeSelection: AMPLITUDE_SELECTION
) {
  let peak_amplitude = narrowband?.peak_amplitude;
  switch (amplitudeSelection) {
    case AMPLITUDE_SELECTION.RMS:
      peak_amplitude = narrowband?.peak_amplitude * 0.7071;
      break;
    case AMPLITUDE_SELECTION.PEAK_TO_PEAK:
      peak_amplitude = narrowband?.peak_amplitude * 2;
      break;
    default:
      break;
  }
  return { peak_amplitude };
}

function getParsedNarrowband(narrowband: any, units: UnitsPayload, speedInHZ: any) {
  const { peak_amplitude } = getAmplitudeSelectionBasedValues(narrowband, units.amplitudeSelection);

  const { fmin, fmax, frequency_at_peak_amp } = getFrequencyUnitBasedValues(
    narrowband,
    units.frequencyUnit,
    speedInHZ
  );
  return {
    ...narrowband,
    fmin,
    fmax,
    frequency_at_peak_amp,
    peak_amplitude,
    odr: getODR(narrowband),
    group_name:
      narrowband.deviceName === undefined || null || ''
        ? ''
        : `${narrowband?.deviceName}: ${narrowband?.raw_dpname}`
  };
}

function getSortedFrequencyNB(groupedNarrowbands: any) {
  const sortedGroupedNB: any = {};
  let finalNB: Array<any> = [];

  Object.keys(groupedNarrowbands).forEach((nbKey) => {
    sortedGroupedNB[nbKey] = groupedNarrowbands[nbKey].sort(
      (a: { frequency_at_peak_amp: string }, b: { frequency_at_peak_amp: string }) =>
        parseFloat(a.frequency_at_peak_amp) - parseFloat(b.frequency_at_peak_amp)
    );
    finalNB = [...finalNB, ...sortedGroupedNB[nbKey]];
  });
  return finalNB;
}

/**
 * Convert Narrowband data received from API into tabular data
 * @param narrowbandList
 */
export function parseNarrowbandData(
  narrowbandsList: Array<NormalizedNarrowbandsType>,
  units: UnitsPayload,
  speedInHZ: any,
  selectedNarrowband: any,
  checkedNarrowbands?: Array<any>
): Array<any> {
  // const type: MotionUnitTypes = units.motionUnitType;
  // if (type) {
  const groupedNB: any = {};
  narrowbandsList?.forEach((narrowband: NormalizedNarrowbandsType) => {
    const nb = {
      ...getParsedNarrowband(narrowband, units, speedInHZ),
      tableData: {
        checked: checkedNarrowbands?.some(
          (current: NormalizedNarrowbandsType) => current.key === narrowband.key
        ),
        selected: selectedNarrowband?.key === narrowband.key
      }
    };
    if (groupedNB[nb.group_name]) {
      groupedNB[nb.group_name] = [...groupedNB[nb.group_name], nb];
    } else {
      groupedNB[nb.group_name] = [nb];
    }
    return nb;
  });
  return getSortedFrequencyNB(groupedNB);
  // }
  // return [];
}
/**
 * This should get called when user is freshly checking vibration analysis
 * by selecting device in device dropdown. In case of alerts navigation, this should not get called.
 * By default we are selecting TVE datapoint as a selected narrowband.
 * @param rawNarrowbands
 * @param units
 * @returns
 */
export function getDefaultSelectedNarrowband(
  narrowbandsList: Array<NormalizedNarrowbandsType>,
  units: UnitsPayload,
  nbdpid?: any // This narrowband DPID is sent when user comes from Alarms page. In this case we need to make that narrowband as selected by default
): any {
  // const type: MotionUnitTypes = units.motionUnitType;
  // if (type) {
  // const narrowbandsList = rawNarrowbands[type];
  if (nbdpid) {
    // Find narrowband which was clicked on alarms page to navigate to VA page.
    return narrowbandsList.find(
      (narrowband: NormalizedNarrowbandsType) =>
        narrowband.dpid === nbdpid.dpid && narrowband.deviceId === nbdpid.deviceId
    );
  }
  // If user is not coming from alarms page, in that case select a narrowband which is of the type Total Vibration Enery
  const foundTVENB = narrowbandsList.find((narrowband: any) => narrowband.nb_type === 'tve');
  if (foundTVENB) {
    return foundTVENB;
  }
  if (narrowbandsList.length > 0) {
    // selecting first NB as default when TVE is absent
    return narrowbandsList[0];
  }
  // }
  return undefined;
}

export function getSelectedNarrowband(
  narrowbandsList: Array<NormalizedNarrowbandsType>,
  units: UnitsPayload,
  alreadySelectedNarrowband: any
) {
  // const type: MotionUnitTypes = units.motionUnitType;
  // if (type) {
  // const narrowbandsList = rawNarrowbands[type];
  const selectedNarrowband = narrowbandsList.find(
    (narrowband: NormalizedNarrowbandsType) => narrowband.key === alreadySelectedNarrowband.key
  );
  if (selectedNarrowband) {
    return selectedNarrowband;
  }
  const similarNameNB = narrowbandsList.find(
    (narrowband: NormalizedNarrowbandsType) =>
      narrowband.rawName === alreadySelectedNarrowband.rawName
  );
  if (similarNameNB) {
    return similarNameNB;
  }
  // }
  return getDefaultSelectedNarrowband(narrowbandsList, units, null);
}
/**
 * Get Default Checked narrowbands. This should get executed when user is freshly checking Vibration analysis
 * by selecting device in device dropdown. In case of alers navigation, this method should not get called.
 * By default we are selecting all the narrowbands available in the list
 * @param rawNarrowbands
 * @param units
 * @returns
 */
export function getDefaultCheckedNarrowbands(
  rawNarrowbands: Array<NormalizedNarrowbandsType>,
  units: UnitsPayload,
  nbdpid?: any // This narrowband DPID is sent when user comes from Alarms page. In this case we need to make that narrowband as selected by default
): NormalizedNarrowbandsType[] {
  const type: MotionUnitTypes = units.motionUnitType;
  if (type && nbdpid) {
    const foundNB = rawNarrowbands.find(
      (narrowband: NormalizedNarrowbandsType) =>
        narrowband.dpid === nbdpid.dpid &&
        narrowband.deviceId === nbdpid.deviceId &&
        narrowband.nb_type !== 'tve'
    );
    if (foundNB) {
      if (foundNB?.name.replace(foundNB?.rawName, '').toLowerCase().includes('x') === true) {
        /**
         * If we find the x axis nb then we are returing it to state
         */
        return [foundNB];
      } else {
        /**
         * Here means we got nb but of a different axis so we are using
         * found nb to find x axis nb and returing it
         * as on NB dropdow we are showing all x axis nbs
         */
        const foundXAxisNB = rawNarrowbands.find(
          (narrowband: NormalizedNarrowbandsType) =>
            narrowband.rawName === foundNB.rawName &&
            narrowband?.name.replace(narrowband?.rawName, '').toLowerCase().includes('x') === true
        );
        return foundXAxisNB ? [foundXAxisNB] : [];
      }
    }
  }
  return [];
}
/**
 * Find the narrowband based on key and check if previously it was selected or not.
 * If it was selected, mark it as checked again.
 * @param rawNarrowbands
 * @param units
 * @param alreadyCheckedNarrowands
 * @returns
 */
export function getCheckedNarrowbands(
  rawNarrowbands: Array<NormalizedNarrowbandsType>,
  units: UnitsPayload,
  alreadyCheckedNarrowands?: Array<any>
): NormalizedNarrowbandsType[] {
  // const type: MotionUnitTypes = units.motionUnitType;
  if (alreadyCheckedNarrowands) {
    return rawNarrowbands.filter(
      (narrowband: NormalizedNarrowbandsType) =>
        alreadyCheckedNarrowands?.findIndex(
          (oldNarrowband: any) => oldNarrowband.key === narrowband.key
        ) >= 0
    );
  }
  return [];
}
export function getAmplitudeSelectionValues(value: any, amplitudeSelection: AMPLITUDE_SELECTION) {
  let peak_amplitude = value;
  if (value) {
    switch (amplitudeSelection) {
      case AMPLITUDE_SELECTION.RMS:
        peak_amplitude = value * 0.7071;
        break;
      case AMPLITUDE_SELECTION.PEAK_TO_PEAK:
        peak_amplitude = value * 2;
        break;
      default:
        peak_amplitude = value;
        break;
    }
  }
  return peak_amplitude;
}

export function getAmplitudeValueinPeak(
  value: number,
  amplitudeSelection: AMPLITUDE_SELECTION
): number {
  if (value) {
    switch (amplitudeSelection) {
      case AMPLITUDE_SELECTION.RMS: {
        return value / 0.7071;
      }
      case AMPLITUDE_SELECTION.PEAK_TO_PEAK: {
        return value / 2;
      }
      default: {
        return value;
      }
    }
  }
  return value;
}
