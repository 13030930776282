/**
 * useOnline: custom hook to identify if browser is currently online
 * @author mahesh.kedari@shorelineiot.com
 * */
import React from 'react';
/**
 *
 * @returns
 */
const getOnLineStatus = (): boolean =>
  typeof navigator !== 'undefined' && typeof navigator.onLine === 'boolean'
    ? navigator.onLine
    : true;
/**
 *
 * @returns
 */
const useOnline = () => {
  const [status, setStatus] = React.useState(getOnLineStatus());

  const setOnline = () => setStatus(true);
  const setOffline = () => setStatus(false);

  React.useEffect(() => {
    window.addEventListener('online', setOnline);
    window.addEventListener('offline', setOffline);

    return () => {
      window.removeEventListener('online', setOnline);
      window.removeEventListener('offline', setOffline);
    };
  }, []);

  return status;
};
/**
 *
 */
export default useOnline;
