import { put, takeEvery, all } from 'redux-saga/effects';
import * as deleteDashboardActions from './deleteDashboard.actions';
import * as DELETE_DASHBOARD_ACTION_TYPES from './deleteDashboard.actionTypes';
import {
  GenericObject,
  httpDelete,
  removeProgressFor,
  showProgressFor,
  showToast,
  APISERVICES
} from '../../../../framework';
import { SagaIterator } from 'redux-saga';

const DELETE_DASHBOARD = 'DELETE_DASHBOARD';

function* deleteDashboardSaga(action: any) {
  const data = action.payload;
  yield put(showProgressFor(DELETE_DASHBOARD));
  try {
    const response: GenericObject = yield httpDelete(
      `orgs/${data?.slug}/dashboard/${data?.dashboardUUID}`,
      null,
      APISERVICES.HOST_URL
    );
    yield put(deleteDashboardActions.deleteDashboardSuccess(response));
    yield put(showToast('Dashboard deleted successfully!', 'success'));
  } catch (error: any) {
    yield put(showToast('Dashboard could not be deleted!', 'error', true));
    yield put(deleteDashboardActions.deleteDashboardFailure(error));
  } finally {
    yield put(removeProgressFor(DELETE_DASHBOARD));
  }
}

export function* watchDeleteDashboardSagas(): SagaIterator {
  yield all([
    takeEvery(DELETE_DASHBOARD_ACTION_TYPES.DELETE_DASHBOARD_PROGRESS, deleteDashboardSaga)
  ]);
}
