import { skipToken } from '@reduxjs/toolkit/query';
import { useFetchOrgsQuery, useFetchSuborgsQuery, useSwitchOrgMutation } from './orgs.api';
import { FetchSuborgsArgs } from './orgs.types';

export const useFetchOrgs = () => {
  const {
    data: orgs,
    isFetching: isFetchingOrgs,
    isError: isFetchOrgsError,
    error: fetchOrgsError,
    isSuccess: isFetchOrgsSuccess
  } = useFetchOrgsQuery();

  return {
    orgs,
    isFetchingOrgs,
    isFetchOrgsError,
    fetchOrgsError,
    isFetchOrgsSuccess
  };
};

export const useFetchSuborgs = (args: FetchSuborgsArgs | typeof skipToken) => {
  const {
    data,
    isFetching: isFetchingSuborgs,
    isError: isFetchSuborgsError,
    error: fetchSuborgsError,
    isSuccess: isFetchSuborgsSuccess,
    refetch: refetchSuborgs
  } = useFetchSuborgsQuery(args);

  const suborgs = data || [];

  return {
    suborgs,
    isFetchingSuborgs,
    isFetchSuborgsError,
    fetchSuborgsError,
    isFetchSuborgsSuccess,
    refetchSuborgs
  };
};

export const useSwitchOrg = () => {
  const [
    switchOrg,
    { isLoading: isSwitchOrgLoading, isError: isSwitchOrgError, isSuccess: isSwitchOrgSuccess }
  ] = useSwitchOrgMutation();

  return {
    switchOrg,
    isSwitchOrgLoading,
    isSwitchOrgError,
    isSwitchOrgSuccess
  };
};
