import moment from 'moment';
import { AirborneDataType } from './LeakDetectionViewer';
import { MethaneEmissionAlarm } from '../../../../features/reports/leak-detection/utils/LeakDetectionAlarmType';

// Convert date to a numeric timestamp
const parseDate = (str: string, key: 'month' | 'week') => {
  if (key === 'month') {
    return moment(str, 'YYYY-MMM').valueOf();
  } else {
    return moment(str, 'DD-MMM-YY').valueOf();
  }
};

export const sortAccordingToKeys = (obj: { [key: string]: number }, key: 'month' | 'week') => {
  const keys = Object.keys(obj);
  const sortedEntries = keys.sort((a, b) => {
    return parseDate(a, key) - parseDate(b, key);
  });
  return sortedEntries;
};

//For those months/weeks in range which are not present in the object, add val=0
export const addZeroEntries = (
  min_val: moment.Moment | null,
  max_val: moment.Moment | null,
  obj: { [key: string]: number },
  key: 'month' | 'week'
) => {
  let current_val = min_val;
  while (current_val && current_val.isSameOrBefore(max_val, key === 'month' ? 'month' : 'week')) {
    const formattedDate =
      key === 'month' ? current_val.format('YYYY-MMM') : current_val.format('DD-MMM-YY');
    if (!(formattedDate in obj)) {
      obj[formattedDate] = 0;
    }
    current_val = moment(current_val).add(1, key === 'month' ? 'month' : 'week');
  }
  return obj;
};

//Keeps a track of the min and max month/week for checking the range
export const getMinMax = (
  alarms: MethaneEmissionAlarm[],
  airborneData: AirborneDataType,
  key: 'month' | 'week'
) => {
  let minVal = moment(alarms[0].created);
  let maxVal = moment(alarms[0].created);
  alarms.forEach((alarm) => {
    const currentVal = moment(alarm.created);
    if (currentVal.isBefore(minVal, key === 'month' ? 'month' : 'week')) {
      minVal = currentVal;
    }
    if (currentVal.isAfter(maxVal, key === 'month' ? 'month' : 'week')) {
      maxVal = currentVal;
    }
  });

  const deviceIds = Object.keys(airborneData);
  deviceIds.forEach((deviceId) => {
    const dataPoints = airborneData[Number(deviceId)];
    dataPoints.forEach((item) => {
      const month_created = moment(item.time / 1000); //nanosec to milisec
      if (month_created.isBefore(minVal, key === 'month' ? 'month' : 'week')) {
        minVal = month_created;
      }
      if (month_created.isAfter(maxVal, key === 'month' ? 'month' : 'week')) {
        maxVal = month_created;
      }
    });
  });

  return {
    minVal: minVal.startOf('week'),
    maxVal
  };
};
