/**
 * LogoPath Actions
 * @author meghneel.adke@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTIONS from './logoPath.actionTypes';
/**
 * This feature is used to set App logo source dynamically
 */

/**
 *
 * @param logoPath
 */
export const setLogoPath = (logoPath: string): AnyAction => {
  return {
    type: ACTIONS.SET_LOGO_PATH,
    payload: [logoPath]
  };
};

export const resetLogoPath = (): AnyAction => {
  return {
    type: ACTIONS.RESET_LOGO_PATH,
    payload: []
  };
};
