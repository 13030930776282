/**
 * personalization Reducer
 * @author ashwini.gawade@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTION_TYPE from './actions/getPersonalizeSettings.actionTypes';
import {
  PersonalizeSettingsState,
  PERSONALIZE_SETTINGS_STATUS
} from './types/PersonalizeSettingsState';

/**
 *
 */
const initialState: PersonalizeSettingsState = {
  status: PERSONALIZE_SETTINGS_STATUS.INIT,
  personalization_info: {}
};

/**
 *
 * @param state
 */
function getPersonalizationInfo(state: PersonalizeSettingsState): PersonalizeSettingsState {
  return {
    ...state,
    status: PERSONALIZE_SETTINGS_STATUS.FETCHING,
    personalization_info: {}
  };
}
/**
 *
 * @param state
 * @param action
 */
function getPersonalizationInfoSuccess(
  state: PersonalizeSettingsState,
  action: AnyAction
): PersonalizeSettingsState {
  return {
    ...state,
    status: PERSONALIZE_SETTINGS_STATUS.READY,
    personalization_info: action.data
  };
}

/**
 *
 * @param state
 */
function getPersonalizationInfoFailure(state: PersonalizeSettingsState): PersonalizeSettingsState {
  return {
    ...state,
    status: PERSONALIZE_SETTINGS_STATUS.ERROR
  };
}

/**
 *
 */
function resetPersonalisationInfo() {
  return {
    ...initialState
  };
}

/**
 *
 * @param state
 * @param action
 */
export default function personalizeSettingsReducer(
  state: PersonalizeSettingsState = initialState,
  action: AnyAction
): PersonalizeSettingsState {
  switch (action.type) {
    case ACTION_TYPE.FETCH_THEMES:
      return getPersonalizationInfo(state);
    case ACTION_TYPE.FETCH_THEMES_SUCCESS:
      return getPersonalizationInfoSuccess(state, action);
    case ACTION_TYPE.FETCH_THEMES_FAILURE:
      return getPersonalizationInfoFailure(state);
    case ACTION_TYPE.RESET_THEME:
      return resetPersonalisationInfo();
    default:
      return state;
  }
}
