/**
 * Auto configurator Narrowbands Reducer
 * @author mahesh.kedari@shorelineiot.com
 */
import { AnyAction } from 'redux';
import { AUTOCONFIG_NB_ACTIONS } from './actions';
import {
  AutoConfigNarrowband,
  AutoconfigNarrowbandState,
  AutoconfigNarrowbandStatus,
  NarrowbandStatus
} from './types';

const initialState: AutoconfigNarrowbandState = {
  status: AutoconfigNarrowbandStatus.INIT,
  summary: {
    success: 0,
    failure: 0,
    total: 0
  },
  narrowbands: {},
  componentId: ''
};

const handleParsedNarrowbands = (state: AutoconfigNarrowbandState, narrowbands: Array<any>) => {
  return {
    ...state,
    status: AutoconfigNarrowbandStatus.READY,
    narrowbands
  };
};
const getActionTemplate = (ruleAction: any) => {
  return {
    app_notification_enabled: true,
    email: [
      {
        to: [ruleAction.emailList],
        subject: ruleAction.subject,
        body: ruleAction.message
      }
    ]
  };
};

const handleActions = (
  state: AutoconfigNarrowbandState,
  ruleAction: any
): AutoconfigNarrowbandState => {
  const updatedNarrowbands: Array<[string, AutoConfigNarrowband]> = Object.entries(
    state.narrowbands
  );
  const narrowbands: Array<AutoConfigNarrowband> = updatedNarrowbands.map(
    ([, narrowband]: [string, AutoConfigNarrowband]) => {
      if (narrowband.ruleModule) {
        return {
          ...narrowband,
          ruleModule: {
            ...narrowband.ruleModule,
            actions: {
              1: getActionTemplate(ruleAction),
              2: getActionTemplate(ruleAction),
              3: getActionTemplate(ruleAction),
              4: getActionTemplate(ruleAction)
            }
          },
          status: NarrowbandStatus.IN_PROGRESS
        };
      }
      return { ...narrowband, status: NarrowbandStatus.IN_PROGRESS };
    }
  );
  return {
    ...state,
    status: AutoconfigNarrowbandStatus.IN_PROGRESS,
    narrowbands: { ...narrowbands }
  };
};
const handleNarrowbandsUpdating = (state: AutoconfigNarrowbandState) => {
  return {
    ...state,
    status: AutoconfigNarrowbandStatus.UPDATING
  };
};

const handlesubmitNarrowbands = (state: AutoconfigNarrowbandState) => {
  return {
    ...state,
    status: AutoconfigNarrowbandStatus.READY
  };
};

const handleSubmitNBSuccess = (state: AutoconfigNarrowbandState): AutoconfigNarrowbandState => {
  return { ...state, status: AutoconfigNarrowbandStatus.SUCCESS };
};

const handleSubmitNBError = (state: AutoconfigNarrowbandState): AutoconfigNarrowbandState => {
  return { ...state, status: AutoconfigNarrowbandStatus.ERROR };
};
/**
 *
 */
const resetAutoconfigNB = (): AutoconfigNarrowbandState => {
  return {
    ...initialState
  };
};
function setComponentId(
  state: AutoconfigNarrowbandState,
  payload: AnyAction
): AutoconfigNarrowbandState {
  const { componentId } = payload;
  return {
    ...state,
    componentId
  };
}
/**
 *
 * @param state
 * @param actions
 */
export default function autoConfigNarrowbandsReducer(
  state: AutoconfigNarrowbandState = initialState,
  action: AnyAction
): AutoconfigNarrowbandState {
  switch (action.type) {
    case AUTOCONFIG_NB_ACTIONS.PARSE_NARROWBANDS:
      return handleParsedNarrowbands(state, action.payload);
    case AUTOCONFIG_NB_ACTIONS.SAVE_ACTIONS:
      return handleActions(state, action.payload);
    case AUTOCONFIG_NB_ACTIONS.SUBMIT_NARROWBANDS:
      // No Action Needed
      return handlesubmitNarrowbands(state);
    case AUTOCONFIG_NB_ACTIONS.SUBMIT_NARROWBAND_UPDATING:
      return handleNarrowbandsUpdating(state);
    case AUTOCONFIG_NB_ACTIONS.SUBMIT_NARROWBAND_SUCCESS:
      return handleSubmitNBSuccess(state);
    case AUTOCONFIG_NB_ACTIONS.SUBMIT_NARROWBAND_ERROR:
      return handleSubmitNBError(state);
    case AUTOCONFIG_NB_ACTIONS.RESET_AUTOCONFIG:
      return resetAutoconfigNB();
    case AUTOCONFIG_NB_ACTIONS.SET_COMPONENT_ID:
      return setComponentId(state, action.payload);
    default:
      return state;
  }
}
