/**
 * Feature Container
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { useEffect } from 'react';

import { useMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as actions from '../../auth/configure/configure.actions';
import { AuthConfigStateType } from '../../auth/configure/configure.reducer';
import {
  useSlugContext,
  SLLoadingPage,
  useThemeContext,
  resetLogoPath,
  setLogoPath,
  showBanner
} from '../../../framework';

import FeaturesAuthRouter from './FeaturesAuthRouter';
import {
  getFromLocalStorage,
  updateThemeData
} from '../../org-settings/customization/constants/personalizationParser';
import defaultTheme from '../../../framework/theme/default/theme';
import { ShorelineFavicon } from '../../../assets';
import { useConfigureState } from '../../auth/configure/configure.selectorHooks';
import { ORG_SELECTOR_STATUS, useOrgSelector } from '../../organization/store';
import Bugsnag from '@bugsnag/js';
import { VITE_BASE_PATH, VITE_BUGSNAG_KEY } from '../../../framework/services/envConstants';
import GoogleTranslate from '../../FeaturesGoogleTranslater';
import { useIntl } from 'react-intl';
import { setGlobalIntl } from '../../../i18n/intlGlobal';
/**
 * Feature Container
 * @author mahesh.kedari@shorelineiot.com
 */
export default function FeaturesContainer() {
  const dispatch = useDispatch();
  const { slug, setSlug } = useSlugContext();
  const intl = useIntl();
  setGlobalIntl(intl);
  const favicon = document.getElementById('favicon') as HTMLAnchorElement | null;

  const configState: AuthConfigStateType = useConfigureState();

  const { theme, setTheme } = useThemeContext();
  const match: null | { params: { slug: string | undefined } } = useMatch('/:slug/*');

  const orgSelector: any = useOrgSelector();
  if (VITE_BUGSNAG_KEY) {
    Bugsnag.setUser(`${localStorage.getItem('savedEmail')}`);
  }
  useEffect(() => {
    // TODO: Uncomment below code to enable maintenance banner.
    // Please note that this is just a workaround till notification framework is ready on backend.
    // dispatch(
    //   showBanner(
    //     `Application will be down for maintenance from ${new Date(
    //       '2023-12-20 04:30:00 UTC'
    //     ).toLocaleString()} to ${new Date('2023-12-20 11:30:00 UTC').toLocaleString()}`,
    //     {
    //       color: 'error',
    //       severity: 'error'
    //     }
    //   )
    // );
  }, [dispatch]);

  useEffect(() => {
    if (VITE_BUGSNAG_KEY && orgSelector.status === ORG_SELECTOR_STATUS.READY) {
      Bugsnag.addMetadata('Org_info', {
        org_name: orgSelector?.selectedOrg?.name,
        org_uuid: orgSelector?.selectedOrg?.org_uuid,
        isMainOrg: !!orgSelector?.selectedOrg?.org_type,
        slug: orgSelector?.selectedOrg?.slug,
        host: VITE_BASE_PATH
      });
    }
  }, [orgSelector.status]);
  /**
   * Check if user has already added a slug in URL.
   * If not, append default slug.
   */

  useEffect(() => {
    // if URL does not contain slug
    if (match === null && setSlug) {
      setSlug('org');
    } else if (match !== null && setSlug && match.params?.slug) {
      const newSlug = match.params?.slug?.toLowerCase();
      if (newSlug === 'auth' || newSlug === 'app') {
        setSlug('org');
      } else {
        setSlug(newSlug);
      }
    }
  }, []);
  /*
   * Configure Amplify
   */

  const updateTheme = (data: any) => {
    let customTheme = theme;
    if (customTheme) {
      const themeData = data?.personalization;
      customTheme = updateThemeData(customTheme, themeData, defaultTheme);
      if (setTheme) setTheme(customTheme);
    } else if (setTheme) setTheme(defaultTheme);

    if (
      data != null &&
      Object.prototype.hasOwnProperty.call(data?.images, 'favicon') &&
      data?.images?.favicon?.length > 0 &&
      favicon
    ) {
      favicon.href = data?.images?.favicon;
    } else if (favicon) {
      favicon.href = ShorelineFavicon;
    }
    if (
      data != null &&
      Object.prototype.hasOwnProperty.call(data?.images, 'logo') &&
      data?.images?.logo?.length > 0
    ) {
      dispatch(resetLogoPath());
      dispatch(setLogoPath(data?.images?.logo));
    } else {
      dispatch(resetLogoPath());
    }
  };
  useEffect(() => {
    if (slug) {
      const data = getFromLocalStorage(slug);
      if (
        data != null &&
        data?.slug?.length > 0 &&
        Object.prototype.hasOwnProperty.call(data?.data?.personalization, 'primary')
      ) {
        updateTheme(data?.data);
      }
    }
  }, [slug]);

  useEffect(() => {
    if (configState.status === 'INIT') {
      dispatch(actions.requestConfigs(slug || ''));
    } else if (configState.status === 'CONFIG_RECEIVED') {
      dispatch(actions.configureAmplify(configState.config));
    }
  }, [configState.config, dispatch]);

  return (
    <>
      <div>
        {configState.status === 'CONFIGURED' && slug ? (
          <>
            <FeaturesAuthRouter />
            {/* <GoogleTranslate /> */}
          </>
        ) : (
          <SLLoadingPage />
        )}
      </div>
    </>
  );
}
