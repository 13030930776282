/**
 * Redirection Type Parameters Options
 * @author meghneel.adke@shorelineiot.com
 */

export const RedirectionTypeParametersOptions: Array<{
  type: string;
  parameters: Array<{ label: string; value: string }>;
}> = [
  {
    type: '',
    parameters: [
      {
        label: 'None',
        value: ''
      }
    ]
  },
  {
    type: 'asset',
    parameters: [
      {
        label: 'None',
        value: ''
      }
    ]
  },
  {
    type: 'device-data',
    parameters: [
      {
        label: 'None',
        value: ''
      }
    ]
  },
  {
    type: 'datapoint',
    parameters: [
      {
        label: 'None',
        value: ''
      }
    ]
  },
  {
    type: 'device-list',
    parameters: [
      {
        label: 'None',
        value: ''
      }
    ]
  },
  {
    type: 'alarms',
    parameters: [
      {
        label: 'None',
        value: ''
      },
      {
        label: 'Path',
        value: 'path'
      },
      {
        label: 'Severity',
        value: 'severity'
      }
    ]
  },
  {
    type: 'dashboard',
    parameters: [
      {
        label: 'Dashboard Name',
        value: 'dashboard_id'
      }
    ]
  },
  {
    type: 'vibration-analysis',
    parameters: [
      {
        label: 'None',
        value: ''
      }
    ]
  },
  {
    type: 'activity-logs',
    parameters: [
      {
        label: 'None',
        value: ''
      }
    ]
  }
];
