import { AnyAction } from 'redux';
import { CreatePowertrainState } from './powertrain.States';
import * as ACTION_TYPE from './powertrain.actionTypes';
import { createPowertrainDefaults } from './createPowertrain.constants';

const initialState: CreatePowertrainState = {
  formDefaults: createPowertrainDefaults,
  mode: 'ADD'
};

function setDefaultValues(state: CreatePowertrainState, data: any): CreatePowertrainState {
  return {
    ...state,
    formDefaults: data
  };
}

function setPowertrainMode(state: CreatePowertrainState, data: any): CreatePowertrainState {
  return {
    ...state,
    mode: data
  };
}
export default function createPowertrain(
  state: CreatePowertrainState = initialState,
  action: AnyAction
): CreatePowertrainState {
  switch (action.type) {
    case ACTION_TYPE.POWERTRAIN_SET_DEFAULT_VALUES:
      return setDefaultValues(state, action.payload);
    case ACTION_TYPE.POWERTRAIN_SET_MODE:
      return setPowertrainMode(state, action.payload);
    default:
      return state;
  }
}
