/**
 * Leak Detection hooks
 * @author mahesh.kedari@shorelineiot.com
 */

import { useSlugContext } from '../../../../framework';
import {
  LEAK_DETECTION_ALARM_TYPE,
  LEAK_ALERT_LEVEL
} from '../../leak-detection/utils/LeakDetectionAlarmType';
import { useFetchLeakDetectionAlarmsQuery } from './leakDetection.api';

export const useFetchLeakDetectionAlarms = (
  startDate?: number,
  endDate?: number,
  isHistoricalAlarms?: boolean,
  allAlarms?: boolean
) => {
  const { slug } = useSlugContext();
  const {
    data: alarms = [],
    isFetching: isFetchingAlarms,
    isSuccess: isFetchAlarmsSuccess,
    isError: isFetchAlarmsError,
    error
  } = useFetchLeakDetectionAlarmsQuery({
    slug,
    alarmType: LEAK_DETECTION_ALARM_TYPE,
    isHistoricalAlarms: !!isHistoricalAlarms,
    allAlarms: !!allAlarms,
    severity: [
      LEAK_ALERT_LEVEL.ALERT_FATAL,
      LEAK_ALERT_LEVEL.ALERT_ERROR,
      LEAK_ALERT_LEVEL.ALERT_WARNING
    ],
    start: startDate,
    end: endDate
  });
  const alarmsData = alarms.filter((alarm) => alarm.name.includes('Slop Tank'));
  return {
    alarms: alarmsData,
    isFetchingAlarms,
    isFetchAlarmsSuccess,
    isFetchAlarmsError,
    error
  };
};
