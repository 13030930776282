/**
 * Alerts List Saga
 * @author shubham.holkar@shorelineiot.com
 */

import { put, takeEvery, call } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import * as actions from '../actions/alertsList.actions';
import * as actionTypes from '../actions/alerts.actionTypes';
import { GenericObject } from '../../../../framework/utils';
import { httpGet, SafeSaga, APISERVICES } from '../../../../framework';
import { SagaIterator } from 'redux-saga';

function getUrlWithParams(obj: any) {
  // const url = new URL("http://localhost:3000/");
  // url.search = new URLSearchParams(obj);
  // console.log("url", url.search);
  const params = new URLSearchParams(obj);
  Array.from(params.entries()).forEach(([key, value]) => {
    if (!value) {
      params.delete(key);
    }
  });
  const cleaned = String(params);
  return cleaned;
}
function getList(data: any) {
  return httpGet(
    `orgs/${data.slug}/alerts`,
    {
      ...data.nbAlertsFilter
    },
    APISERVICES.DEVICE_API
  );
}
function getFilteredList(data: any) {
  return httpGet(
    `orgs/${data.slug}/alerts?${getUrlWithParams(data.nbAlertsFilter)}`,
    null,
    APISERVICES.DEVICE_API
  );
}

export function* getAlerts(action: AnyAction) {
  const { nbAlertsFilter } = action.payload;
  const alertType = nbAlertsFilter.filter.includes('sensor_rule') ? 'sensor_rule' : 'nb_rule';
  const levelFilter = nbAlertsFilter?.level?.join(',');
  const data = {
    nbAlertsFilter: {
      page_size: nbAlertsFilter.rowsPerPage,
      page: nbAlertsFilter.page + 1,
      category: alertType,
      ...(levelFilter?.length && { alert_level: levelFilter }),
      ...(nbAlertsFilter?.searchText && {
        alert_name: nbAlertsFilter?.searchText,
        device_name: nbAlertsFilter?.searchText
      })
    },
    slug: action.payload.slug
  };
  const response: GenericObject = yield call(getList, data);

  const alerts = response.results;

  const sensorAlerts = alerts.filter(
    (alert: any) => alert.category !== 'nb_rule' && alert.category !== 'slope_rule'
  );
  const nbAlerts = alerts.filter(
    (alert: any) => alert.category === 'nb_rule' || alert.category === 'slope_rule'
  );
  yield put(
    actions.handleAlertsListSuccess({
      alerts,
      sensorAlerts,
      nbAlerts,
      total: response.total
    })
  );
}

export function* getFilteredAlerts(action: AnyAction) {
  const { nbAlertsFilter } = action.payload;
  const levelFilter = nbAlertsFilter.level.join(',');
  const alertType = nbAlertsFilter.filter.includes('sensor_rule') ? 'sensor_rule' : 'nb_rule';

  const data = {
    nbAlertsFilter: {
      page_size: nbAlertsFilter?.rowsPerPage,
      page: nbAlertsFilter?.page + 1,
      sort: nbAlertsFilter?.sort,
      ...(levelFilter?.length && { alert_level: levelFilter }),
      ...(nbAlertsFilter?.filter && { category: alertType }),
      ...(nbAlertsFilter?.searchText && {
        alert_name: nbAlertsFilter?.searchText,
        device_name: nbAlertsFilter?.searchText
      })
    },
    slug: action.payload.slug
  };
  const response: GenericObject = yield call(getFilteredList, data);
  const alerts = response.results;

  const sensorAlerts = alerts.filter(
    (alert: any) => alert.category !== 'nb_rule' && alert.category !== 'slope_rule'
  );
  const nbAlerts = alerts.filter(
    (alert: any) => alert.category === 'nb_rule' || alert.category === 'slope_rule'
  );
  yield put(
    actions.handleAlertsListSuccess({
      alerts,
      sensorAlerts,
      nbAlerts,
      total: response.total
    })
  );
}
function* errorHandler(error: any) {
  yield put(actions.handleAlertsListFailure(error));
}

export function* watchAlertsListSaga(): SagaIterator {
  yield takeEvery(
    actionTypes.FETCH_LIST_PROGRESS,
    SafeSaga(getAlerts, actionTypes.FETCH_LIST_PROGRESS, errorHandler)
  );
  yield takeEvery(
    actionTypes.UPDATE_NBALERTS_FILTER,
    SafeSaga(getFilteredAlerts, actionTypes.UPDATE_NBALERTS_FILTER, errorHandler)
  );
}
