/**
 * Delete Anomaly Template Saga
 * @author aditya.bhadange@shorelineiot.com
 */
import { takeEvery, put, call } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import * as ACTION_TYPES from './deleteAnomalyTemplate.actionTypes';
import * as actions from './deleteAnomalyTemplate.actions';
import { anomalyTemplateListActions } from '../anomaly-templates-list';
import { httpDelete, SafeSaga, showToast, APISERVICES } from '../../../../framework';
import { SagaIterator } from 'redux-saga';

function deleteAnomalyTemplateApi(anomalyId: number) {
  return httpDelete(`anomalies/${anomalyId}`, null, APISERVICES.DEVICE_URL);
}

function* deleteAnomalyTemplateSaga(action: AnyAction) {
  const anomalyId: number = action.payload;
  yield call(deleteAnomalyTemplateApi, anomalyId);
  yield put(actions.deleteAnomalyTemplateSuccess());
  yield put(anomalyTemplateListActions.resetAnomalyTemplatesList());
  yield put(showToast('Anomaly Template Deleted Successfully!', 'success'));
}

function* errorHandler() {
  yield put(actions.deleteAnomalyTemplateFailure());
}

/**
 * Saga for the Delete Anomaly Template.
 */
export function* watchDeleteAnomalyTemplateSaga(): SagaIterator {
  yield takeEvery(
    ACTION_TYPES.DELETE_ANOMALY_TEMPLATE,
    SafeSaga(deleteAnomalyTemplateSaga, ACTION_TYPES.DELETE_ANOMALY_TEMPLATE, errorHandler)
  );
}
