/**
 * Device Reports Actions
 * @author prerna.more@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTIONS_TYPES from './deviceReports.actionTypes';

export const fetchDeviceReports = (data: any): AnyAction => {
  return {
    type: ACTIONS_TYPES.FETCH_DEVICE_REPORTS,
    payload: data
  };
};

export const fetchDeviceReportsSuccess = (data: any): AnyAction => {
  return {
    type: ACTIONS_TYPES.FETCH_DEVICE_REPORTS_SUCCESS,
    payload: data
  };
};

export const fetchDeviceReportsFailure = (): AnyAction => {
  return {
    type: ACTIONS_TYPES.FETCH_DEVICE_REPORTS_FAILURE
  };
};

export const fetchDeviceReportsReset = (): AnyAction => {
  return {
    type: ACTIONS_TYPES.RESET_DEVICE_REPORTS
  };
};
