/**
 * Reset Password Reducer
 * @author priyanka.ambawame@shorelineiot.com
 */
import { ACCEPT_INVITE_STATUS, LoginStateType } from '../types/LoginStateType';
/**
 *
 * @param state
 */
export function onResetPasswordSuccess(state: LoginStateType, session: any): LoginStateType {
  return {
    ...state,
    status: 'LOGGED_IN',
    session
  };
}
/**
 *
 * @param state
 * @returns
 */
export function onResetPasswordProgress(state: LoginStateType): LoginStateType {
  return {
    ...state,
    acceptInviteStatus: ACCEPT_INVITE_STATUS.ACCEPTING
  };
}
/**
 *
 * @param state
 */
export function onResetPasswordFailure(state: LoginStateType): LoginStateType {
  return {
    ...state,
    status: 'ERROR'
  };
}
/**
 *
 * @param state
 */
export function onAcceptInviteSuccess(state: LoginStateType, session: any): LoginStateType {
  return {
    ...state,
    acceptInviteStatus: ACCEPT_INVITE_STATUS.SUCCESS,
    session
  };
}
/**
 *
 * @param state
 */
export function onAcceptInviteFailure(
  state: LoginStateType,
  status: ACCEPT_INVITE_STATUS
): LoginStateType {
  return {
    ...state,
    acceptInviteStatus: status
  };
}
/**
 *
 * @param state
 */
export function resetAcceptInvite(state: LoginStateType): LoginStateType {
  return {
    ...state,
    acceptInviteStatus: ACCEPT_INVITE_STATUS.INIT
  };
}
