/**
 * Authentication Context
 * @author mahesh.kedari@shorelineiot.com
 */
import { useContext, createContext } from 'react';

type ContextProps = {
  handleCloseMenu: Function;
};

export const MenuContext = createContext<Partial<ContextProps>>({});

export function useMenuContext() {
  return useContext(MenuContext);
}
