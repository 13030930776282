/**
 * Additional Sensors Reducer
 * @author mahesh.kedari@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTIONS from './additionalSensors.actionTypes';
import { AdditionalSensorsState, AdditionalSensorsStatus } from './additionalSensors.state';

const initialState: AdditionalSensorsState = {
  selectedSensorStatus: AdditionalSensorsStatus.INIT,
  selectedSensorSchema: {},
  drawerOpen: false
};
/**
 *
 * @param state
 */
function resetAdditionalSensors(): AdditionalSensorsState {
  return {
    ...initialState
  };
}
/**
 *
 * @param state
 */
function fetchSensorSchema(state: AdditionalSensorsState): AdditionalSensorsState {
  return { ...state, selectedSensorStatus: AdditionalSensorsStatus.FETCHING };
}

function sensorSchemaSuccess(state: AdditionalSensorsState, payload: any): AdditionalSensorsState {
  return {
    ...state,
    selectedSensorStatus: AdditionalSensorsStatus.READY,
    selectedSensorSchema: payload
  };
}

function sensorSchemaError(state: AdditionalSensorsState): AdditionalSensorsState {
  return {
    ...state,
    selectedSensorStatus: AdditionalSensorsStatus.ERROR
  };
}
function setDrawerOpenStatus(state: AdditionalSensorsState, flag: boolean): AdditionalSensorsState {
  return {
    ...state,
    drawerOpen: flag
  };
}

/**
 *
 * @param state
 * @param action
 */
export default function AdditionalSensorReducer(
  state: AdditionalSensorsState = initialState,
  action: AnyAction
): AdditionalSensorsState {
  switch (action.type) {
    case ACTIONS.FETCH_SENSORS_SCHEMA:
      return fetchSensorSchema(state);
    case ACTIONS.SENSORS_SCHEMA_SUCCESS:
      return sensorSchemaSuccess(state, action.payload);
    case ACTIONS.SENSORS_SCHEMA_FAILURE:
      return sensorSchemaError(state);
    case ACTIONS.SENSORS_SCHEMA_RESET:
      return resetAdditionalSensors();
    case ACTIONS.SET_DRAWER_OPEN_STATUS:
      return setDrawerOpenStatus(state, action.payload);
    default:
      return state;
  }
}
