/**
 * Trend analysis Reducer
 * @author mahesh.kedari@shorelineiot.com
 */
import { combineReducers } from 'redux';
import waterfall from './waterfall-rtk/waterfall.slice';
import orbit from './orbit-rtk/orbit.slice';
import trendChart from './trend-chart/trendChart.slice';

const TrendAnalysisReducer = combineReducers({
  waterfall,
  orbit,
  trendChart
});

export default TrendAnalysisReducer;
