/**
 * User Self Selector hook
 * @author prerna.more@shorelineiot.com
 */
import { useSelector } from 'react-redux';
import { RootState } from '../../../../configureStore';

const useUserSelf = () => useSelector((state: RootState) => state.features.userSelf);

const useUserSelfRole = () =>
  useSelector((state: RootState) => state.features.userSelf?.self?.role_actions);

export { useUserSelf, useUserSelfRole };
