/**
 * Remarks Actions
 * @author prerna.more@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTIONS_TYPES from './remarks.actionTypes';

export const fetchRemarks = (data: any): AnyAction => {
  return {
    type: ACTIONS_TYPES.FETCH_REMARK,
    payload: data
  };
};

export const fetchRemarksSuccess = (data: any): AnyAction => {
  return {
    type: ACTIONS_TYPES.FETCH_REMARK_SUCCESS,
    payload: data
  };
};

export const fetchRemarksFailure = (): AnyAction => {
  return {
    type: ACTIONS_TYPES.FETCH_REMARK_FAILURE
  };
};

export const fetchRemarksReset = (): AnyAction => {
  return {
    type: ACTIONS_TYPES.FETCH_REMARK
  };
};
