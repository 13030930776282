/**
 * Device Data Action Types
 * @author priyanka.ambawane@shorelineiot.com
 */

export const GET_DEVICE_DATA = 'FEATURES/DEVICE/DEVICE_DATA/GET_DEVICE_DATA';
export const GET_DEVICE_DATA_SUCCESS = 'FEATURES/DEVICE/DEVICE_DATA/GET_DEVICE_DATA_SUCCESS';
export const GET_DEVICE_DATA_FAILURE = 'FEATURES/DEVICE/DEVICE_DATA/GET_DEVICE_DATA_FAILURE';

export const INITIATE_SUBSCRIPTION_TO_TOPIC =
  'FEATURES/DEVICE/DEVICE_DATA/INITIATE_SUBSCRIPTION_TO_TOPIC';
export const INITIATE_SUBSCRIPTION_SUCCESS =
  'FEATURES/DEVICE/DEVICE_DATA/INITIATE_SUBSCRIPTION_SUCCESS';
export const INITIATE_SUBSCRIPTION_FAILURE =
  'FEATURES/DEVICE/DEVICE_DATA/INITIATE_SUBSCRIPTION_FAILURE';

export const CANCEL_SUBSCRIPTION_TO_TOPIC =
  'FEATURES/DEVICE/DEVICE_DATA/CANCEL_SUBSCRIPTION_TO_TOPIC';
export const CANCEL_SUBSCRIPTION_SUCCESS =
  'FEATURES/DEVICE/DEVICE_DATA/CANCEL_SUBSCRIPTION_SUCCESS';
export const CANCEL_SUBSCRIPTION_FAILURE =
  'FEATURES/DEVICE/DEVICE_DATA/CANCEL_SUBSCRIPTION_FAILURE';
