/**
 * Fetch Datapoints Operation
 */
export const FETCH_DATAPOINTS = 'FEATURES/DEVICE/DATAPOINT/FETCH';
export const FETCH_ONLY_DATAPOINTS = 'FEATURES/DEVICE/DATAPOINT/FETCH_ONLY_DATAPOINTS';
export const DATAPOINTS_SUCCESS = 'FEATURES/DEVICE/DATAPOINT/SUCCESS';
export const DATAPOINTS_FAILURE = 'FEATURES/DEVICE/DATAPOINT/FAILURE';
export const RESET_DATAPOINTS = 'FEATURES/DEVICE/DATAPOINTS/RESET';
export const FETCH_DATAPOINTS_AGAIN = 'FEATURES/DEVICE/DATAPOINTS/FETCH_DATAPOINTS_AGAIN';

/**
 * Chart Configuration for Datapoints
 */
export const FETCH_VIEW_CONFIG = 'FEATURES/DEVICE/DATAPOINT/VIEW_CONFIG';
export const VIEW_CONFIG_SUCCESS = 'FEATURES/DEVICE/DATAPOINT/VIEW_CONFIG/SUCCESS';
export const VIEW_CONFIG_FAILURE = 'FEATURES/DEVICE/DATAPOINT/VIEW_CONFIG/FAILURE';
export const RESET_VIEW_CONFIG = 'FEATURES/DEVICE/DATAPOINT/VIEW_CONFIG/RESET';

/**
 * Set Chart Configurations for Datapoint
 */
export const SET_VIEW_CONFIG = 'FEATURES/DEVICE/DATAPOINT/VIEW_CONFIG/SET';
export const SET_VIEW_CONFIG_SUCCESS = 'FEATURES/DEVICE/DATAPOINT/VIEW_CONFIG/SET/SUCCESS';
export const SET_VIEW_CONFIG_FAILURE = 'FEATURES/DEVICE/DATAPOINT/VIEW_CONFIG/SET/FAILURE';

/**
 * Fetch Topic for Datapoint
 */
export const FETCH_TOPIC = 'FEATURES/DEVICE/DATAPOINT/TOPIC';
export const FETCH_ALL_TOPICS = 'FEATURES/DEVICE/DATAPOINT/TOPICS/ALL';
export const TOPIC_SUCCESS = 'FEATURES/DEVICE/DATAPOINT/TOPIC/SUCCESS';
export const TOPIC_FAILURE = 'FEATURES/DEVICE/DATAPOINT/TOPIC/FAILURE';
export const TOPIC_CLOSE = 'FEATURES/DEVICE/DATAPOINT/TOPIC/CLOSE';

/**
 * Fetch Data for Datapoint
 */
export const FETCH_DATA_PROGRESS = 'FEATURES/DEVICE/DATAPOINT/DATA/FETCH_DATA_PROGRESS';
export const FETCH_DATA_SUCCESS = 'FEATURES/DEVICE/DATAPOINT/DATA/FETCH_DATA_SUCCESS';
export const FETCH_DATA_FAILURE = 'FEATURES/DEVICE/DATAPOINT/DATA/FETCH_DATA_FAILURE';
export const FETCH_DATA_RESET = 'FEATURES/DEVICE/DATAPOINT/DATA/FETCH_DATA_RESET';

export const SET_DATA = 'FEATURES/DEVICE/DATAPOINT/DATA/SET';
export const SET_DATA_SUCCESS = 'FEATURES/DEVICE/DATAPOINT/DATA/SET/SUCCESS';
export const SET_DATA_FAILURE = 'FEATURES/DEVICE/DATAPOINT/DATA/SET/FAILURE';

export const SET_LAST_DATA_VALUE = 'FEATURES/DEVICE/DATAPOINT/DATA/SET/LAST_VALUE';

export const REMOVE_DATAPOINT = 'FEATURES/DEVICE/DATAPOINT/REMOVE';
export const REMOVE_DATAPOINT_SUCCESS = 'FEATURES/DEVICE/DATAPOINT/REMOVE/SUCCESS';
export const REMOVE_DATAPOINT_FAILURE = 'FEATURES/DEVICE/DATAPOINT/REMOVE/FAILURE';

export const HIDE_DATAPOINT = 'FEATURES/DEVICE/DATAPOINT/HIDE';
export const HIDE_DATAPOINT_SUCCESS = 'FEATURES/DEVICE/DATAPOINT/HIDE/SUCCESS';
export const HIDE_DATAPOINT_FAILURE = 'FEATURES/DEVICE/DATAPOINT/HIDE/FAILURE';

export const UNHIDE_DATAPOINT = 'FEATURES/DEVICE/DATAPOINT/UNHIDE';
export const UNHIDE_DATAPOINT_SUCCESS = 'FEATURES/DEVICE/DATAPOINT/UNHIDE/SUCCESS';
export const UNHIDE_DATAPOINT_FAILURE = 'FEATURES/DEVICE/DATAPOINT/UNHIDE/FAILURE';
