/**
 * This utility function sets the decimal point resolution of a Sensor's Datapoints.
 * Return formatted datapoint value. Otherwise returns same value.
 * @param sensorType
 * @param datapointValue
 */
function checkDatatypeAndSetResolution(value: any, resolution: number) {
  if (typeof value === 'number') {
    return value.toFixed(resolution);
  }
  return value;
}
export const setDatapointValueResolution = (sensorType: string, datapointValue: any): any => {
  /* SensorType Criteria */
  switch (sensorType) {
    case 'sns_total_vibration_energy':
      return datapointValue?.toPrecision(4);
    // return checkDatatypeAndSetResolution(datapointValue, 4);
    case 'sns_accelerometer':
      return checkDatatypeAndSetResolution(datapointValue, 4);
    case 'sns_temperature':
      return checkDatatypeAndSetResolution(datapointValue, 1);
    case 'sns_humidity':
      return checkDatatypeAndSetResolution(datapointValue, 1);
    case 'sns_battery':
      return checkDatatypeAndSetResolution(datapointValue, 4);
    case 'sns_narrowband':
      return checkDatatypeAndSetResolution(datapointValue, 3);
    case 'sns_band':
      return checkDatatypeAndSetResolution(datapointValue, 4);
    case 'sns_rsrp':
      return checkDatatypeAndSetResolution(datapointValue, 4);
    case 'sns_rsrq':
      return checkDatatypeAndSetResolution(datapointValue, 4);
    case 'sns_csq':
      return checkDatatypeAndSetResolution(datapointValue, 4);
    case 'sns_sinr':
      return checkDatatypeAndSetResolution(datapointValue, 4);
    case 'sns_ai':
      return checkDatatypeAndSetResolution(datapointValue, 4);
    case 'sns_mic_airborne':
      return checkDatatypeAndSetResolution(datapointValue, 4);
    case 'sns_mic_struct_borne':
      return checkDatatypeAndSetResolution(datapointValue, 4);
    case 'sns_barometer':
      return checkDatatypeAndSetResolution(datapointValue, 1);
    case 'analog_input':
      return checkDatatypeAndSetResolution(datapointValue, 1);
    case 'modbus':
      return checkDatatypeAndSetResolution(datapointValue, 1);
    default:
      return datapointValue;
  }
};
