import { FetchTourArgs, UpdateTourResponse, FetchTourResponse } from './tour.types';
import { fetchTourConfigProperties, updateTourConfigQuery } from './tour.utils';
import { TOUR_TAG } from './tour.types';
import { featureApi } from '../../feature.api';
import { invalidateTagsListId } from '../../../framework/lib/rtk/RTK.constants';
import { generateTags } from '../../../framework/lib/rtk/RTK.utils';

export const tourApi = featureApi.injectEndpoints({
  endpoints: (build) => ({
    fetchTourConfig: build.query<FetchTourResponse | Record<string, any>, FetchTourArgs>({
      query: fetchTourConfigProperties,
      transformResponse: (response) => {
        return response.tour_config;
      },
      providesTags: (result) => generateTags(result, TOUR_TAG.TOUR)
    }),
    updateTourInfo: build.mutation<UpdateTourResponse, FetchTourArgs>({
      query: updateTourConfigQuery,
      invalidatesTags: () => {
        return [{ type: TOUR_TAG.TOUR, id: invalidateTagsListId }];
      }
    })
  })
});

export const { useFetchTourConfigQuery, useUpdateTourInfoMutation } = tourApi;
