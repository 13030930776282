/**
 * Gauge widget styles
 */
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  minMax: {
    marginTop: '-40px',
    zIndex: 10
  }
}));
