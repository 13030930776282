/**
 * Anomaly Template Details Action Types
 * @author aditya.bhadange@shorelineiot.com
 */

export const FETCH_ANOMALY_TEMPLATE_DETAILS =
  'FEATURES/ANOMALY_TEMPLATES/ANOMALY_TEMPLATE_DETAILS/FETCH';
export const FETCH_ANOMALY_TEMPLATE_DETAILS_SUCCESS =
  'FEATURES/ANOMALY_TEMPLATES/ANOMALY_TEMPLATE_DETAILS/FETCH/SUCCESS';
export const FETCH_ANOMALY_TEMPLATE_DETAILS_FAILURE =
  'FEATURES/ANOMALY_TEMPLATES/ANOMALY_TEMPLATE_DETAILS/FETCH/FAILURE';
export const RESET_ANOMALY_TEMPLATE_DETAILS =
  'FEATURES/ANOMALY_TEMPLATES/ANOMALY_TEMPLATE_DETAILS/RESET';

export const UPDATE_ANOMALY_TEMPLATE = 'FEATURES/ANOMALY_TEMPLATES/ANOMALY_TEMPLATE_DETAILS/UPDATE';
export const UPDATE_ANOMALY_TEMPLATE_SUCCESS =
  'FEATURES/ANOMALY_TEMPLATES/ANOMALY_TEMPLATE_DETAILS/UPDATE/SUCCESS';
export const UPDATE_ANOMALY_TEMPLATE_FAILURE =
  'FEATURES/ANOMALY_TEMPLATES/ANOMALY_TEMPLATE_DETAILS/UPDATE/FAILURE';
