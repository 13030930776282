/**
 * Update Profile Saga
 * @author mahesh.kedari@shorelineiot.com
 */
import { takeEvery, put, call, all } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import * as ACTION_TYPES from '../actions/updateProfile.actionTypes';
import * as actions from '../actions/updateProfile.actions';
import {
  GenericObject,
  httpGet,
  httpPut,
  removeProgressFor,
  showProgressFor,
  APISERVICES
} from '../../../framework';
import { SagaIterator } from 'redux-saga';
/**
 *
 */
const FETCH_PROFILE = 'FETCH-PROFILE-DETAILS';
const FETCH_SCHEMA = 'FETCH-PROFILE-SCHEMA';
const UPDATE_PROFILE = 'UPDATE-PROFILE-DETAILS';
const FETCH_MODULE_TEMPLATE = 'FETCH-MODULE-TEMPALTE';
const FETCH_EXISTING_MODULE = 'FETCH-EXISTING-MODULE';
/**
 *
 * @param data
 */
function fetchDetails({ slug, uuid }: any) {
  return httpGet(`orgs/${slug}/device_profiles/${uuid}`, null, APISERVICES.DEVICE_API);
}

function* getProfileDetails(action: AnyAction) {
  yield put(showProgressFor(FETCH_PROFILE));
  try {
    const response: GenericObject = yield call(fetchDetails, action.payload);
    yield put(actions.handleDetailsSuccess(response.results));
  } catch (error: any) {
    yield put(actions.handleDetailsFailure(error));
  } finally {
    yield put(removeProgressFor(FETCH_PROFILE));
  }
}

function fetchSchema({ slug, deviceType }: any) {
  return httpGet(
    `orgs/${slug}/device_profiles/schema?compatible_device_type=${deviceType}`,
    null,
    APISERVICES.DEVICE_API
  );
}
function* getProfileSchema(action: AnyAction) {
  yield put(showProgressFor(FETCH_SCHEMA));
  try {
    const response: GenericObject = yield call(fetchSchema, action.payload);
    yield put(actions.handleSchemaSuccess(response.schema));
  } catch (error: any) {
    yield put(actions.handleSchemaFailure(error));
    // yield put(actions.handleSchemaSuccess(schema));
  } finally {
    yield put(removeProgressFor(FETCH_SCHEMA));
  }
}

function updateProfile(data: any) {
  return httpPut(`orgs/${data}/devices`, data, APISERVICES.DEVICE_API);
}
function* updateProfileDetails(action: AnyAction) {
  yield put(showProgressFor(UPDATE_PROFILE));
  try {
    const response: GenericObject = yield call(updateProfile, action.payload);
    yield put(actions.handleUpdateSuccess(response.result));
  } catch (error: any) {
    yield put(actions.handleUpdateFailure(error));
  } finally {
    yield put(removeProgressFor(UPDATE_PROFILE));
  }
}

function getSensorTemplate(data: any) {
  return httpGet(
    `orgs/${data.slug}/device_sensor_templates/type/${data.sensorType}`,
    null,
    APISERVICES.DEVICE_API
  );
}

function* fetchSensorTemplate(action: AnyAction) {
  yield put(showProgressFor(`${FETCH_MODULE_TEMPLATE}_${action.payload.index}`));
  try {
    const response: GenericObject = yield call(getSensorTemplate, action.payload);
    yield put(actions.fetchSensorTemplateSuccess(response.sensor_template, action.payload.index));
  } catch (error: any) {
    yield put(actions.fetchSensorTemplateFailure(error));
  } finally {
    yield put(removeProgressFor(`${FETCH_MODULE_TEMPLATE}_${action.payload.index}`));
  }
}

function* addExistingSensorModule(action: AnyAction) {
  yield put(
    showProgressFor(`${FETCH_EXISTING_MODULE}_${action.payload.index}`)
    // const response:any =
  );
  yield put(removeProgressFor(`${FETCH_EXISTING_MODULE}_${action.payload.index}`));
}
/**
 *
 */
export default function* watchProfileDetails(): SagaIterator {
  yield all([
    takeEvery(ACTION_TYPES.FETCH, getProfileDetails),
    takeEvery(ACTION_TYPES.FETCH_SCHEMA, getProfileSchema),
    takeEvery(ACTION_TYPES.UPDATE, updateProfileDetails),
    takeEvery(ACTION_TYPES.FETCH_SENSOR_TEMPLATE, fetchSensorTemplate),
    takeEvery(ACTION_TYPES.ADD_EXISTING_SENSOR_MODULE, addExistingSensorModule)
  ]);
}
