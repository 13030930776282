export enum UpdateNarrowbandRuleStatus {
  INIT,
  IN_PROGRESS,
  READY,
  ERROR
}
/**
 *
 */
export interface UpdateNarrowbandRuleState {
  status: UpdateNarrowbandRuleStatus;
  response: any;
}
