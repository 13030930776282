/**
 * Delete Device Mailbox Actions
 * @author prerna.more@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTION_TYPE from './deleteDeviceMailbox.actionTypes';

export const deleteDeviceMailboxProgress = (data: any): AnyAction => {
  return {
    type: ACTION_TYPE.DELETE_DEVICE_MAILBOX_PROGRESS,
    payload: data
  };
};
export const deleteDeviceMailboxSuccess = (data: any): AnyAction => {
  return {
    type: ACTION_TYPE.DELETE_DEVICE_MAILBOX_SUCCESS,
    payload: data
  };
};

export const deleteDeviceMailboxFailure = (error: any): AnyAction => {
  return {
    type: ACTION_TYPE.DELETE_DEVICE_MAILBOX_FAILURE,
    payload: error
  };
};

export const deleteDeviceMailboxReset = (): AnyAction => {
  return {
    type: ACTION_TYPE.DELETE_DEVICE_MAILBOX_RESET_STATE,
    payload: false
  };
};
