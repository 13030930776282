import { generateTags } from '../../../../framework/lib/rtk/RTK.utils';
import { featureApi } from '../../../feature.api';
import {
  FEATURE_CONFIGURATIONS_TAG_TYPES,
  FeatureResponse,
  FetchFeaturesListArgs,
  OrgFeatures,
  UpdateOrgFeaturesArgs,
  UpdateOrgFeaturesResponse
} from './featureConfigurations.types';
import {
  fetchFeatureListQuery,
  fetchOrgFeatureListQuery,
  updateOrgFeaturesQuery
} from './featureConfigurations.utils';

export const featureConfigurationsApi = featureApi.injectEndpoints({
  endpoints: (build) => ({
    fetchFeatures: build.query<FeatureResponse[], FetchFeaturesListArgs>({
      query: fetchFeatureListQuery,
      providesTags: (result) =>
        generateTags(result, FEATURE_CONFIGURATIONS_TAG_TYPES.FEATURE_DETAILS)
    }),
    fetchOrgFeatures: build.query<OrgFeatures[], FetchFeaturesListArgs>({
      query: fetchOrgFeatureListQuery,
      providesTags: (result) =>
        generateTags(result, FEATURE_CONFIGURATIONS_TAG_TYPES.ORG_FEATURE_LIST)
    }),
    updateOrgFeatures: build.mutation<UpdateOrgFeaturesResponse[], UpdateOrgFeaturesArgs>({
      query: updateOrgFeaturesQuery,
      invalidatesTags: () => [FEATURE_CONFIGURATIONS_TAG_TYPES.ORG_FEATURE_LIST]
    })
  })
});

export const { useFetchFeaturesQuery, useFetchOrgFeaturesQuery, useUpdateOrgFeaturesMutation } =
  featureConfigurationsApi;
