import moment, { Moment } from 'moment';

/**
 * Converts a given date to an epoch timestamp (in milliseconds) as a string.
 *
 * This function accepts either a `moment` object, an epoch timestamp string, or `undefined`:
 * - If the input is a `moment` object, it converts it to an epoch timestamp (in milliseconds) and returns it as a string.
 * - If the input is already a string (representing an epoch timestamp), it returns it as is.
 * - If the input is `undefined` or any other type, it returns an empty string.
 *
 * @param {string | Moment | undefined} date - The date to convert. Can be a moment object, a string representing an epoch timestamp, or `undefined`.
 * @returns {string} - The epoch timestamp in milliseconds as a string, or an empty string if the input is undefined or invalid.
 *
 * @example
 * // Converting a moment object to epoch
 * const momentDate = moment();
 * console.log(convertToEpoch(momentDate)); // Output: '1634567890123' (epoch string)
 *
 * // Passing an epoch timestamp string directly
 * console.log(convertToEpoch('1634567890123')); // Output: '1634567890123'
 *
 * // Passing undefined
 * console.log(convertToEpoch(undefined)); // Output: ''
 */
export const convertToEpoch = (date: string | Moment | undefined): string => {
  if (moment.isMoment(date)) {
    return date.valueOf().toString(); // If it's a moment object, convert to epoch
  } else if (typeof date === 'string') {
    return date; // If it's already a string (epoch), return as is
  }
  return ''; // Return an empty string if it's undefined or another type (optional handling)
};
