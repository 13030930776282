/**
 * Update Org Action Types
 * @author ashwini.gawade@shorelineiot.com
 */

export const FETCH_ORG_DETAILS = 'FEATURES/ORG_SETTINGS/ORG_UPDATE/FETCH';
export const ORG_DETAILS_SUCCESS = 'FEATURES/ORG_SETTINGS/ORG_UPDATE/FETCH/SUCCESS';
export const ORG_DETAILS_FAILURE = 'FEATURES/ORG_SETTINGS/ORG_UPDATE/FETCH/FAILURE';

export const UPDATE_ORG = 'FEATURES/ORG_SETTINGS/ORG_UPDATE/UPDATE_ORG';
export const UPDATE_ORG_SUCCESS = 'FEATURES/ORG_SETTINGS/ORG_UPDATE/UPDATE_ORG/SUCCESS';
export const UPDATE_ORG_FAILURE = 'FEATURES/ORG_SETTINGS/ORG_UPDATE/UPDATE_ORG/FAILURE';

export const RESET_ORG_DETAILS = 'FEATURES/ORG_SETTINGS/ORG_UPDATE/RESET';
