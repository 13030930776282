/**
 * Device Activity Action Type
 * @author mahesh.kedari@shorelineiot.com
 */
export const FETCH_ACTIVITIES = 'FEATURES/DEVICE/DEVICE_ACTIVITY_LOGS/GET';
export const FETCH_ACTIVITIES_SUCCESS = 'FEATURES/DEVICE/DEVICE_ACTIVITY_LOGS/SUCCESS';
export const FETCH_ACTIVITIES_FAILURE = 'FEATURES/DEVICE/DEVICE_ACTIVITY_LOGS/FAILURE';
export const FETCH_ACTIVITIES_RESET = 'FEATURES/DEVICE/DEVICE_ACTIVITY_LOGS/RESET';

export const EXPORT_LOGS = 'FEATURES/DEVICE/DEVICE_ACTIVITY_LOGS/EXPORT';
export const EXPORT_LOGS_SUCCESS = 'FEATURES/DEVICE/DEVICE_ACTIVITY_LOGS/EXPORT_SUCCESS';
export const EXPORT_LOGS_FAILURE = 'FEATURES/DEVICE/DEVICE_ACTIVITY_LOGS/EXPORT_FAILURE';
