/**
 * Application Main Theme
 * @author mahesh.kedari@shorelineiot.com
 */
import { createTheme } from '@mui/material/styles';
import {
  ButtonStyle,
  Palette,
  Typography,
  CssBaselineStyle,
  PaperStyle,
  LinkStyle,
  RadioButtonStyle,
  MenuListItemStyle,
  BreadcrumbStyle,
  TooltipStyle,
  FormLabelStyle,
  FormCheckboxStyle,
  FormInputStyle,
  FormInputAdornmentStyle,
  AccordionStyle,
  AvatarStyle,
  MuiMenuItemStyle
} from './overrides';
import { MuiMaterialReactMuiContainerStyle } from './overrides/MuiMaterialReactMuiContainerStyle';
import { MuiMaterialReactTablePaginationStyle } from './overrides/MuiMaterialReactTablePaginationStyle';
import { MuiMaterialReactTableStyle } from './overrides/MuiMaterialReactTableStyle';
import { MuiMenuStyle } from './overrides/MuiMenuStyle';

// Shoreline Theme
const defaultTheme = createTheme({
  palette: Palette,
  typography: Typography,
  components: {
    MuiCssBaseline: {
      styleOverrides: CssBaselineStyle
    },
    MuiButton: {
      styleOverrides: ButtonStyle
    },
    MuiTable: {
      styleOverrides: MuiMaterialReactTableStyle
    },
    MuiTablePagination: {
      styleOverrides: MuiMaterialReactTablePaginationStyle
    },
    MuiTableContainer: {
      styleOverrides: MuiMaterialReactMuiContainerStyle
    },
    MuiMenuItem: {
      styleOverrides: MuiMenuItemStyle
    },
    MuiMenu: {
      styleOverrides: MuiMenuStyle
    },
    MuiAvatar: {
      styleOverrides: AvatarStyle
    },
    MuiAccordionSummary: {
      styleOverrides: AccordionStyle
    },
    MuiTooltip: {
      styleOverrides: TooltipStyle
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard'
      }
    },
    MuiInput: {
      styleOverrides: FormInputStyle
    },
    MuiFormLabel: {
      styleOverrides: FormLabelStyle
    },
    MuiCheckbox: {
      styleOverrides: FormCheckboxStyle
    },
    MuiBreadcrumbs: {
      styleOverrides: BreadcrumbStyle
    },
    MuiListItem: {
      styleOverrides: MenuListItemStyle
    },
    MuiInputAdornment: {
      styleOverrides: FormInputAdornmentStyle
    },
    MuiRadio: {
      styleOverrides: RadioButtonStyle
    },
    MuiLink: {
      styleOverrides: LinkStyle
    },
    MuiPaper: {
      styleOverrides: PaperStyle
    }
  }
});

export default defaultTheme;
