/**
 * @author swapnil.kshirsagar@shorelineiot.com
 */

import { AnyAction } from 'redux';
import * as ACTIONS from './verification.actionTypes';

export const onVerificationLoad = (): AnyAction => {
  return {
    type: ACTIONS.ON_VERIFICATION_PAGE_LOAD
  };
};
export const sendLink = (data: any): AnyAction => {
  return {
    type: ACTIONS.VERIFICATION_PROGERSS,
    payload: data
  };
};

export const sentLinkSuccess = (data: any): AnyAction => {
  return {
    type: ACTIONS.VERIFICATION_SUCCESS,
    payload: data
  };
};

export const sentLinkFailure = (error: any): AnyAction => {
  return {
    type: ACTIONS.VERIFICATION_FAILURE,
    payload: error
  };
};
