/**
 * 3D chart configuration sliders
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { useEffect } from 'react';
import { SLForm, SLSlider } from '../form';
import { makeStyles } from '@mui/styles';
import { SLButton } from '../button';
import { Grid } from '@mui/material';
import {
  DEFAULT_CONFIG_3D,
  WATERFALL_LOCALSTORAGE_3D_CONFIG
} from '../../../features/workflow/trend-analysis/waterfall/header/waterfall.helper';
import { useGAEventTracker } from '../../custom-hooks';
import { toSnakeCase } from '../../../i18n/translation.helper';
import TranslateText from '../../../i18n/TranslateText';

type ChartSliderProps = {
  chartRef: any;
  label: string;
  configProperty: string;
  value: number;
  min: number;
  max: number;
  setLocalState: Function;
};
const ChartSlider = (props: ChartSliderProps) => {
  const handleChange = React.useCallback(
    (event: any) => {
      const chart = props?.chartRef?.current?.chart;
      props.setLocalState(props.configProperty, event.target?.value);

      if (chart) {
        if (props.configProperty === 'lineGap') {
          chart.options.plotOptions.area.depth = event.target?.value;
        } else {
          chart.options.chart.options3d[props.configProperty] = event.target?.value;
        }
        chart.redraw(false);
      }
    },
    [props]
  );
  return (
    <SLSlider
      label={TranslateText(toSnakeCase(props.label), props.label)}
      value={props.value}
      min={props.min}
      max={props.max}
      onChange={handleChange}
    />
  );
};

type Props = {
  chartRef: any;
  setConfig3d: React.Dispatch<any>;
  config3d: any;
};

const useStyles = makeStyles(() => ({
  form: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '100%'
  },
  sliderContainer: {
    display: 'flex',
    gap: '15px',
    flexGrow: '0.95'
  }
}));
/**
 *
 * @param param0
 * @returns
 */
export default function SLChartViewSlider({ chartRef, setConfig3d, config3d }: Props) {
  const classes = useStyles();
  const GAEventsTracker = useGAEventTracker('Waterfall config');
  const handleChange3d = React.useCallback((property: string, value: number) => {
    setConfig3d((prevValue: any) => ({ ...prevValue, [property]: value }));
    if (property === 'lineGap') {
      chartRef.current.chart.update(
        {
          plotOptions: {
            area: {
              depth: value
            }
          }
        },
        true,
        true
      );
    }
  }, []);

  useEffect(() => {
    GAEventsTracker('WaterFall chart config', `config: ${config3d}`);
    localStorage.setItem(WATERFALL_LOCALSTORAGE_3D_CONFIG, JSON.stringify(config3d));
  }, [config3d]);

  const resetDefault = React.useCallback(() => {
    const chart = chartRef?.current?.chart;
    setConfig3d(DEFAULT_CONFIG_3D);
    if (chart) {
      chart.options.chart.options3d.alpha = DEFAULT_CONFIG_3D.alpha;
      chart.options.chart.options3d.beta = DEFAULT_CONFIG_3D.beta;
      chart.options.chart.options3d.depth = DEFAULT_CONFIG_3D.depth;
      chart.options.chart.options3d.viewDistance = DEFAULT_CONFIG_3D.viewDistance;
      chart.options.plotOptions.area.depth = DEFAULT_CONFIG_3D.lineGap;
      chart.redraw(false);
    }
  }, []);

  return (
    <SLForm className={classes.form}>
      <Grid container columnSpacing={2}>
        <Grid item xs={11} container columnSpacing={2} justifyContent="center" alignItems="center">
          <Grid item xs={2.1}>
            <ChartSlider
              configProperty="alpha"
              label={'Vertical'}
              value={config3d.alpha}
              min={-45}
              max={45}
              chartRef={chartRef}
              setLocalState={handleChange3d}
            />
          </Grid>
          <Grid item xs={2.1}>
            <ChartSlider
              configProperty="beta"
              value={config3d.beta}
              label={'Horizontal'}
              min={-90}
              max={90}
              chartRef={chartRef}
              setLocalState={handleChange3d}
            />
          </Grid>
          <Grid item xs={2.1}>
            <ChartSlider
              configProperty="depth"
              value={config3d.depth}
              label={'Depth'}
              min={0}
              max={1000}
              chartRef={chartRef}
              setLocalState={handleChange3d}
            />
          </Grid>
          <Grid item xs={2.1}>
            <ChartSlider
              configProperty="viewDistance"
              value={config3d.viewDistance}
              label={'View Distance'}
              min={1}
              max={50}
              chartRef={chartRef}
              setLocalState={handleChange3d}
            />
          </Grid>
          <Grid item xs={2.1}>
            <ChartSlider
              configProperty="lineGap"
              value={config3d.lineGap}
              label={'Line Gap'}
              min={20}
              max={100}
              chartRef={chartRef}
              setLocalState={handleChange3d}
            />
          </Grid>
          <Grid item xs={0.5} justifyContent="flex-start" alignItems="flex-start" marginTop={2}>
            <SLButton onClick={resetDefault} translationId="default">
              Default
            </SLButton>
          </Grid>
        </Grid>
      </Grid>
    </SLForm>
  );
}
