/**
 * Feature Auth Router
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { ReactElement, useEffect } from 'react';
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// import * as actions from '@slcloud/shared/features/auth/login/actions/login.actions';
import {
  AuthenticatedRoute,
  UnauthenticatedRoute,
  useSlugContext,
  SLLoadingPage,
  OfflineIndicator
} from '../../../framework';
import HomeLayout from '../../home/HomeLayout';
import AuthLayout from '../../auth/AuthLayout';
import PublicLayout from '../../public/PublicLayout';
import { Terms, Pricing, FAQs } from '../../static-pages';
import { ORG_LIST_STATUS, useOrgList } from '../../organization/store';
import { ACCEPT_INVITE_STATUS, useLoginState } from '../../auth/login';
import { acceptInviteActions, loginActions } from '../../auth/login/store/actions';
import TermsAndConditions from '../../static-pages/termsAndConditions/TermsAndConditions';
// import { LoginStateType } from '../../auth/login/types/LoginStateType';
/**
 *
 */
export default function FeaturesAuthRouter(): ReactElement {
  const { slug } = useSlugContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const login = useLoginState();
  const orgListState = useOrgList();
  /**
   * Check if existing logged in session is present
   */
  useEffect(() => {
    if (login.status === 'UNKNOWN') {
      dispatch(loginActions.checkCurrentSession());
    }
  }, [login.status]);

  useEffect(() => {
    if (
      login.status === 'LOGGED_IN' &&
      login.acceptInviteStatus === ACCEPT_INVITE_STATUS.ERROR &&
      orgListState.status === ORG_LIST_STATUS.ERROR &&
      orgListState.orgList?.length === 0
    ) {
      navigate(`/${slug}/app/org/create`);
      dispatch(acceptInviteActions.resetAcceptInvite());
    }
  }, [orgListState, login]);

  return (
    <>
      {login.status === 'CHECKING_CURRENT_SESSION' || login.status === 'UNKNOWN' ? (
        <SLLoadingPage />
      ) : (
        <Routes>
          <Route path="/legal/*">
            <Route path="terms-and-conditions" element={<TermsAndConditions />} />
            <Route path="*" element={<Navigate to="terms-and-conditions" replace />} />
          </Route>
          <Route path={`/${slug}/*`}>
            <Route index element={<Navigate to="app" replace />} />
            <Route path="app/*" element={<AuthenticatedComponent />} />
            <Route path="auth/*" element={<UnauthenticatedComponent />} />
            <Route path="public/*" element={<PublicLayout />} />
            <Route path="*" element={<Navigate to="app" replace />} />
          </Route>
          <Route path="terms" element={<Terms />} />
          <Route path="pricing" element={<Pricing />} />
          <Route path="faqs" element={<FAQs />} />
          <Route path="*" element={<Navigate to={`/${slug}/auth`} replace />} />
        </Routes>
      )}
      <OfflineIndicator />
    </>
  );
}

const AuthenticatedComponent = () => {
  return (
    <AuthenticatedRoute path="app">
      <HomeLayout />
    </AuthenticatedRoute>
  );
};

const UnauthenticatedComponent = () => {
  return (
    <UnauthenticatedRoute path="auth">
      <AuthLayout />
    </UnauthenticatedRoute>
  );
};
