import { AnyAction } from 'redux';
import * as ACTION_TYPE from './analogSensor.actionTypes';
import { addAnalogSensorStatus, addAnalogSensorState } from './analogSensor.state';
/**
 *
 */

/**
 *
 */

const initialState: addAnalogSensorState = {
  status: addAnalogSensorStatus.INIT
};

function addAnalogSensorProgress(state: addAnalogSensorState): addAnalogSensorState {
  return {
    ...state,
    status: addAnalogSensorStatus.PROGRESS
  };
}
function addAnalogSensorSuccess(state: addAnalogSensorState): addAnalogSensorState {
  return {
    ...state,
    status: addAnalogSensorStatus.SUCCESS
  };
}
/**
 *
 * @param state
 */

/**
 *
 */

/**
 *
 */
function addAnalogSensorFailure(state: addAnalogSensorState): addAnalogSensorState {
  return {
    ...state,
    status: addAnalogSensorStatus.ERROR
  };
}

function addAnalogSensorReset(state: addAnalogSensorState): addAnalogSensorState {
  return {
    ...state,
    status: addAnalogSensorStatus.INIT
  };
}
/**
 *
 * @param state
 * @param action
 */
export default function addAnalogSensorReducer(
  state: addAnalogSensorState = initialState,
  action: AnyAction
): addAnalogSensorState {
  switch (action.type) {
    case ACTION_TYPE.ADD_ANALOG_SENSOR_PROGRESS:
      return addAnalogSensorProgress(state);
    case ACTION_TYPE.ADD_ANALOG_SENSOR_SUCCESS:
      return addAnalogSensorSuccess(state);
    case ACTION_TYPE.ADD_ANALOG_SENSOR_FAILURE:
      return addAnalogSensorFailure(state);
    case ACTION_TYPE.ADD_ANALOG_SENSOR_RESET_STATE:
      return addAnalogSensorReset(state);

    default:
      return state;
  }
}
