import { LOCALES } from '../locales';

export default {
  [LOCALES.GERMAN]: {
    welcomeStatement: 'Sprache',
    email: 'E-Mail',
    password: 'Passwort',
    submit: 'Absenden',
    dashboard: 'Dashboard',
    device: 'Geräte',
    powertrains: 'Antriebsstränge',
    device_profile: 'Geräteprofil',
    profile: 'Profil',
    sensor_module: 'Sensormodul',
    rules: 'Regeln',
    organization: 'Organisations-Einstellungen',
    export_data: 'Daten exportieren',
    firmware: 'Firmware',
    workflow: 'Arbeitsablauf',
    reports: 'Berichte',
    settings: 'Anpassung',
    user_settings: 'Benutzereinstellungen',
    activity_logs: 'Aktivitätsprotokolle',
    genai_bot: 'GenAI Bot',
    custom_dashboard: 'Dashboard',
    alarm: 'Alarme',
    alerts: 'Benachrichtigungen',
    snapshot: 'Momentaufnahme',
    assets: 'Vermögenswerte',
    templates: 'Vorlagen',
    addDashboard: 'Dashboard hinzufügen',
    viewAll: 'Alle anzeigen',
    sensorAlarms: 'Sensoralarme',
    assetAlarms: 'Vermögensalarme',
    survey: 'Umfragen',
    leak_detection: 'Lecksuche',
    user_script: 'Benutzerskripte'
  }
};
