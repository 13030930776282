import { TableContainerClasses, Theme } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

/**
 *
 */
export const MuiMaterialReactMuiContainerStyle:
  | Partial<
      OverridesStyleRules<
        keyof TableContainerClasses,
        'MuiTableContainer',
        Omit<Theme, 'components'>
      >
    >
  | undefined = {
  root: {
    '::-webkit-scrollbar': {
      height: '10px'
    },
    scrollbarColor: '#c8c8c8 #f1f1f188'
  }
};
