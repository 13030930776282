import React, {
  JSXElementConstructor,
  ReactElement,
  useLayoutEffect,
  useRef,
  useState
} from 'react';
import { LightTooltip } from '../components';

type OtherOptionsTypes = {
  /**
   * Use this to show custom text in the cell.
   * @default undefined
   */
  text?: string | JSX.Element | undefined;
  /**
   * Make this `true` to always show the tooltip.
   */
  tooltipAlwaysEnabled?: boolean | undefined;
  /**
   * Use this to change color of text in the cell.
   */
  textColor?: string | undefined;
  /**
   * Use this to change background Color of the cell.
   */
  bgColor?: string | undefined;
};

/**
 * This custom-hook will handle the logic of showing tooltip
 * in the table row.
 * @param {string} title Data that need to be shown in the row.
 * @example
 * export default function DescriptionColumn({ description }: Props): ReactElement {
    return useShowTableRowTooltip(description);
  }
 * @returns {string} Title that needs to be rendered.
 */
const useShowTableRowTooltip = (
  title: string | number | JSX.Element,
  otherOptions?: OtherOptionsTypes
): ReactElement<any, string | JSXElementConstructor<any>> => {
  const [enableTooltip, setEnableTooltip] = useState<boolean>(!!otherOptions?.tooltipAlwaysEnabled);
  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (ref?.current !== null && !otherOptions?.tooltipAlwaysEnabled) {
      const node = ref?.current;
      if (node?.parentElement) {
        if (node?.parentElement?.scrollWidth > node?.parentElement?.clientWidth) {
          setEnableTooltip(true);
        } else if (enableTooltip) {
          setEnableTooltip(false);
        }
      }
    }
  });

  const renderTitle = (title: string | number | JSX.Element) => {
    if (typeof title === 'number') {
      return String(title);
    } else {
      return title || '--';
    }
  };

  return (
    <LightTooltip disableHoverListener={!enableTooltip} title={title} placement="bottom-start">
      <span
        ref={ref}
        style={{
          color: otherOptions?.textColor || 'inherit',
          cursor: 'inherit',
          backgroundColor: otherOptions?.bgColor
        }}>
        {renderTitle(otherOptions?.text || title)}
      </span>
    </LightTooltip>
  );
};

export default useShowTableRowTooltip;
