import { TableClasses, Theme } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

/**
 *
 */
export const MuiMaterialReactTableStyle:
  | Partial<OverridesStyleRules<keyof TableClasses, 'MuiTable', Omit<Theme, 'components'>>>
  | undefined = {
  root: {
    /**
     * Below styles are added to remove the `italic` `font-style` from the
     * `No records to display` text on the table.
     */
    '& .MuiTypography-root.MuiTypography-body1': {
      fontStyle: 'inherit',
      fontFamily: 'inherit'
    }
  }
};
