/**
 * Number Editor
 * @author mahesh.kedari@shorelineiot.com
 */
import merge from 'lodash/merge';
import { Grid, Box, Button } from '@mui/material';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ChartEditorProps } from '../common/ChartEditorProps';
import { useStyles } from '../chart.styles';
import SLColorPicker from '../../color-picker/SLColorPicker';
import { SLCheckbox, SLTextField } from '../../form';
import WidgetSettingAccordion from '../common/WidgetAccordion';
import TranslateText from '../../../../i18n/TranslateText';
/**
 *
 * @param param0
 * @returns
 */
const SLNumberEditor: React.FC<ChartEditorProps> = ({
  handleSubmitForm,
  defaultValues
}: ChartEditorProps) => {
  const classes = useStyles();
  const formDefaults = {
    label: 'Label',
    isHideLabel: false,
    color: '#000000'
  };
  const [textColor, setTextColor] = useState<any>({
    hex: defaultValues?.color ? defaultValues?.color : formDefaults.color
  });
  const [numberChartSettings, setNumberChartSettings] = useState<any>({
    chartType: 'number',
    chartSettings: {
      label: defaultValues?.label ? defaultValues?.label : formDefaults.label,
      isHideLabel: defaultValues?.isHideLabel
        ? defaultValues?.isHideLabel
        : formDefaults.isHideLabel,
      color: defaultValues?.color ? defaultValues?.color : formDefaults.color
    }
  });

  const { setValue } = useFormContext();

  const createNewSettingsObject = (presentSettings: any) => {
    // Chart Settings is merged with blank object to create a new Chart settings object
    return merge({}, presentSettings);
  };

  const handleColorChange = (color: any) => {
    setTextColor({
      hex: color.hex
    });

    const updatedSettings = createNewSettingsObject(numberChartSettings);
    // updating local variable here
    setNumberChartSettings({
      ...updatedSettings,
      chartSettings: {
        ...updatedSettings.chartSettings,
        color: color.hex
      }
    });
    // updating chart view here
    handleSubmitForm({
      ...updatedSettings,
      chartSettings: {
        ...updatedSettings.chartSettings,
        color: color.hex
      }
    });
  };

  const handleNumberFormChange = (data: any) => {
    const updatedSettings = createNewSettingsObject(numberChartSettings);
    // updating local variable here
    setNumberChartSettings({
      ...updatedSettings,
      chartSettings: {
        ...updatedSettings.chartSettings,
        label: data?.label
      }
    });
    // updating chart view here
    handleSubmitForm({
      ...updatedSettings,
      chartSettings: {
        ...updatedSettings.chartSettings,
        label: data?.label
      }
    });
  };

  const checkBoxChange = (value: boolean, element: string): void => {
    const updatedSettings = createNewSettingsObject(numberChartSettings);
    // updating chart view here
    handleSubmitForm({
      ...updatedSettings,
      chartSettings: {
        ...updatedSettings.chartSettings,
        [element]: value
      }
    });
    // updating local variable here
    setNumberChartSettings({
      ...updatedSettings,
      chartSettings: {
        ...updatedSettings.chartSettings,
        [element]: event
      }
    });
  };

  const resetToDefault = () => {
    setValue('label', 'Label');
    setValue('color', formDefaults.color);
    setTextColor({
      hex: '#000'
    });
    handleSubmitForm({
      ...numberChartSettings,
      chartSettings: {
        ...numberChartSettings.chartSettings,
        label: 'Label',
        isHideLabel: false,
        color: '#000'
      }
    });
    setNumberChartSettings({
      ...numberChartSettings,
      chartSettings: {
        ...numberChartSettings.chartSettings,
        label: 'Label',
        isHideLabel: false,
        color: '#000'
      }
    });
  };

  return (
    <>
      <WidgetSettingAccordion title="common">
        <Grid container justifyContent="flex-start" spacing={2} alignItems="flex-end">
          <Grid
            item
            xs={6}
            justifyContent="center"
            className={numberChartSettings?.chartSettings?.isHideLabel ? classes.isHide : ''}>
            <SLCheckbox
              name="isHideLabel"
              label="Hide Label"
              labelTranslationId="hide_label"
              value={numberChartSettings.chartSettings.isHideLabel}
              onChange={(value: boolean) => {
                checkBoxChange(value, 'isHideLabel');
              }}
            />
          </Grid>
          {!numberChartSettings?.chartSettings?.isHideLabel && (
            <Grid item xs={6}>
              <SLTextField
                name="label"
                label="Label"
                labelTranslationId="label"
                onBlur={handleNumberFormChange}
              />
            </Grid>
          )}
        </Grid>
        <Grid container justifyContent="flex-start" spacing={2} alignItems="flex-end">
          <Grid item xs={6}>
            <SLColorPicker name="color" value={textColor} handleColorChange={handleColorChange} />
          </Grid>
        </Grid>
      </WidgetSettingAccordion>
      <Box my={5}>
        <Button
          variant="contained"
          size="small"
          classes={{
            root: classes.root
          }}
          onClick={resetToDefault}>
          {TranslateText('reset_default', ' Reset Default')}
        </Button>
      </Box>
    </>
  );
};

export default SLNumberEditor;
