import { AnyAction } from 'redux';
import * as ACTION_TYPES from './setDefaultDashboard.actionTypes';
/**
 *
 */
export function setDefaultDashboard(data: any): AnyAction {
  return {
    type: ACTION_TYPES.SET_DEFAULT_DASHBOARD_PROGRESS,
    payload: data
  };
}
/**
 *
 * @param data data to be returned on successful fetch API call
 */
export function setDefaultDashboardSuccess(data: any): AnyAction {
  return {
    type: ACTION_TYPES.SET_DEFAULT_DASHBOARD_SUCCESS,
    payload: data
  };
}
/**
 *
 */
export function setDefaultDashboardFailure(error: any): AnyAction {
  return {
    type: ACTION_TYPES.SET_DEFAULT_DASHBOARD_FAILURE,
    payload: error
  };
}

export const resetState = (): AnyAction => {
  return {
    type: ACTION_TYPES.SET_DEFAULT_DASHBOARD_RESET_STATE,
    payload: false
  };
};
