/**
 * Alerts list State Reducer
 * @author shubham.holkar@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTION_TYPE from '../actions/alerts.actionTypes';
import { SensorAlertDeleteState } from '../types/SensorAlertDeleteState';
import { ALERT_DELETE_STATUS } from '../types/SensorAlertDeleteStatus';

const initialState: SensorAlertDeleteState = {
  status: ALERT_DELETE_STATUS.INIT,
  response: {}
};

function deleteSensorAlert(state: SensorAlertDeleteState): SensorAlertDeleteState {
  return {
    ...state,
    status: ALERT_DELETE_STATUS.IN_PROGRESS
  };
}

function deleteSensorAlertSuccess(
  state: SensorAlertDeleteState,
  payload: any
): SensorAlertDeleteState {
  return {
    ...state,
    status: ALERT_DELETE_STATUS.READY,
    response: payload
  };
}

function deleteSensorAlertReset(): SensorAlertDeleteState {
  return {
    ...initialState
  };
}

export default function sensorAlertDeleteReducer(
  state: SensorAlertDeleteState = initialState,
  action: AnyAction
): SensorAlertDeleteState {
  switch (action.type) {
    case ACTION_TYPE.DELETE_SENSOR_ALERT:
      return deleteSensorAlert(state);
    case ACTION_TYPE.DELETE_SENSOR_ALERT_SUCCESS:
      return deleteSensorAlertSuccess(state, action.payload);
    case ACTION_TYPE.DELETE_SENSOR_ALERT_RESET:
      return deleteSensorAlertReset();
    default:
      return state;
  }
}
