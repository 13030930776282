/**
 * Gauge Chart Custom hook
 * @author mahesh.kedari@shorelineiot.com
 */
import Highcharts from 'highcharts';
import merge from 'lodash/merge';
import { useEffect, useState } from 'react';

interface SeriesType {
  series: Array<any>;
  key: string;
  title: string;
}
export function parseSeries(resultSet: any) {
  const resultSetSeries = resultSet?.series();
  const dataArray = resultSetSeries[0]?.series;
  const yAxisArray: Array<any> = [];
  const series: Array<any> = resultSetSeries.map((resultSeries: SeriesType, index: number) => {
    const yAxis = {
      title: {
        text: resultSeries.title
      },
      opposite: index > 0
    };
    const data = resultSeries.series.map((point: any) => point.value);
    const currentSeries = {
      id: resultSeries.key,
      name: resultSeries.title,
      data: [data[0]]
    };
    yAxisArray.push(yAxis);
    return currentSeries;
  });
  const xAxisArray = dataArray.map((item: any) => {
    return item.x;
  });
  return { series, xAxisArray, yAxisArray };
}

export default function useGaugeValueChart(
  configChartSettings: Highcharts.Options,
  resultSet: any,
  type: string
): Highcharts.Options {
  const [chartSettings, setChartSettings] = useState<Highcharts.Options>({});
  // configChartSettings

  useEffect(() => {
    const { series } = parseSeries(resultSet);
    const newChartSettings = merge({}, configChartSettings, {
      chart: {
        type
      },
      series
    });
    setChartSettings(newChartSettings);
  }, [resultSet, configChartSettings]);
  return chartSettings;
}
