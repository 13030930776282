import React from 'react';
import {
  SLBattery10Icon,
  SLBattery20Icon,
  SLBattery30Icon,
  SLBattery40Icon,
  SLBattery50Icon,
  SLBattery60Icon,
  SLBattery70Icon,
  SLBattery80Icon,
  SLBattery90Icon,
  SLBattery100Icon,
  SLBattery0Icon
} from '../../../icons';

/**
 * This function will return the appropriate battery icon
 * as per the percentage.
 * @param batteryPercentage
 * @param classes
 * @returns Icon Component
 */
export const renderCorrectBatteryIcon = (
  batteryPercentage?: number,
  size?: { width?: number | string; height?: number | string }
) => {
  const dimensions = {
    width: size?.width || 'auto',
    height: size?.height || 'auto'
  };

  // Ensure batteryPercentage is a valid number, defaulting to 0 if undefined
  const roundedBatteryPercentage = Math.round(batteryPercentage ?? 0);

  if (!roundedBatteryPercentage) {
    return <SLBattery0Icon style={dimensions} />;
  }

  if (roundedBatteryPercentage > 0 && roundedBatteryPercentage <= 15) {
    return <SLBattery10Icon style={dimensions} />;
  }
  if (roundedBatteryPercentage >= 16 && roundedBatteryPercentage <= 27) {
    return <SLBattery20Icon style={dimensions} />;
  }
  if (roundedBatteryPercentage >= 28 && roundedBatteryPercentage <= 39) {
    return <SLBattery30Icon style={dimensions} />;
  }
  if (roundedBatteryPercentage >= 40 && roundedBatteryPercentage <= 49) {
    return <SLBattery40Icon style={dimensions} />;
  }
  if (roundedBatteryPercentage >= 50 && roundedBatteryPercentage <= 59) {
    return <SLBattery50Icon style={dimensions} />;
  }
  if (roundedBatteryPercentage >= 60 && roundedBatteryPercentage <= 69) {
    return <SLBattery60Icon style={dimensions} />;
  }
  if (roundedBatteryPercentage >= 70 && roundedBatteryPercentage <= 79) {
    return <SLBattery70Icon style={dimensions} />;
  }
  if (roundedBatteryPercentage >= 80 && roundedBatteryPercentage <= 89) {
    return <SLBattery80Icon style={dimensions} />;
  }
  if (roundedBatteryPercentage >= 90 && roundedBatteryPercentage <= 99) {
    return <SLBattery90Icon style={dimensions} />;
  }
  if (roundedBatteryPercentage === 100) {
    return <SLBattery100Icon style={dimensions} />;
  }
};
