import { Colors } from '../../../../framework';
import { appSettingLocalStorage } from './themes.constants';

interface AppLocal {
  data: any;
  slug: string;
}
export const parseFormToPayload = (
  slug: string,
  appSettings: any,
  faviconFile?: any,
  logoFile?: any,
  primaryColor?: any,
  secondaryColor?: any,
  textPrimaryColor?: any,
  textSecondaryColor?: any
): any => {
  let primary = '';
  let secondary = '';
  let textPrimary = '';
  let textSecondary = '';

  const logo = {
    content_type: 'image/jpg'
  };
  const favicon = {
    content_type: 'image/jpg'
  };
  if (primaryColor?.length > 0) {
    primary = primaryColor;
  } else if (
    appSettings?.personalization_info &&
    Object.prototype.hasOwnProperty.call(
      appSettings?.personalization_info?.personalization,
      'primary'
    ) &&
    appSettings?.personalization_info?.personalization?.primary?.length > 0
  ) {
    primary = appSettings?.personalization_info?.personalization?.primary;
  }

  if (secondaryColor?.length > 0) {
    secondary = secondaryColor;
  } else if (
    appSettings?.personalization_info &&
    Object.prototype.hasOwnProperty.call(
      appSettings?.personalization_info?.personalization,
      'secondary'
    ) &&
    appSettings?.personalization_info?.personalization?.secondary?.length > 0
  ) {
    secondary = appSettings?.personalization_info?.personalization?.secondary;
  }

  if (textPrimaryColor?.length > 0) {
    textPrimary = textPrimaryColor;
  } else if (
    appSettings?.personalization_info &&
    Object.prototype.hasOwnProperty.call(
      appSettings?.personalization_info?.personalization,
      'textPrimary'
    ) &&
    appSettings?.personalization_info?.personalization?.textPrimary?.length > 0
  ) {
    textPrimary = appSettings?.personalization_info?.personalization?.textPrimary;
  }

  if (textSecondaryColor?.length > 0) {
    textSecondary = textSecondaryColor;
  } else if (
    appSettings?.personalization_info &&
    Object.prototype.hasOwnProperty.call(
      appSettings?.personalization_info?.personalization,
      'textSecondary'
    ) &&
    appSettings?.personalization_info?.personalization?.textSecondary?.length > 0
  ) {
    textSecondary = appSettings?.personalization_info?.personalization?.textSecondary;
  }
  const personalization = { primary, secondary, textPrimary, textSecondary };
  let images = {};
  let data = {};

  if (faviconFile != null && logoFile != null) {
    images = { favicon, logo };
  } else if (faviconFile != null && logoFile === null) {
    images = { favicon };
  } else if (faviconFile === null && logoFile != null) {
    images = { logo };
  }

  if (faviconFile === null && logoFile === null) {
    images = {};
    data = { personalization, images };
  } else {
    data = {
      personalization,
      images
    };
  }
  const payload = {
    data,
    slug,
    faviconFile,
    logoFile
  };

  return payload;
};

export const saveIntoLacalStorage = (slug: string, data: any) => {
  let dataToSave = [] as AppLocal[];

  const newDataToInsert = {} as AppLocal;
  newDataToInsert.slug = slug;
  newDataToInsert.data = data;

  const localData = localStorage.getItem(appSettingLocalStorage);
  if (localData === null || localData?.length < 0) {
    dataToSave.push(newDataToInsert);
  } else {
    dataToSave = JSON.parse(localData);
    const previousData: AppLocal | undefined = dataToSave.find((element) => {
      return element.slug === newDataToInsert.slug;
    });
    if (previousData) {
      const index = dataToSave.indexOf(previousData);
      const dataToPush: AppLocal = previousData;
      dataToPush.data = data;
      dataToSave.splice(index, 1);
      dataToSave.splice(index, 0, dataToPush);
    } else {
      dataToSave.push(newDataToInsert);
    }
  }
  const array = JSON.stringify(dataToSave);
  localStorage.setItem(appSettingLocalStorage, array);
};

export const getFromLocalStorage = (slug: string): any => {
  const localData = localStorage.getItem(appSettingLocalStorage);
  let data = {} as AppLocal | undefined;
  let dataToSave = [] as AppLocal[];
  if (localData != null) {
    dataToSave = JSON.parse(localData);
    const previousData: AppLocal | undefined = dataToSave.find((element) => {
      return element.slug === slug;
    });
    data = previousData;
  }
  return data;
};

export const getLogoURL = (slug: string): any => {
  const localData = localStorage.getItem(appSettingLocalStorage);
  let data = {} as AppLocal;
  let url = '';
  let dataToSave = [] as AppLocal[];
  if (localData != null) {
    dataToSave = JSON.parse(localData);
    const previousData: AppLocal | undefined = dataToSave.find((element) => {
      return element.slug === slug;
    });
    if (previousData) {
      data = previousData;
    } else {
      data = { data: '', slug: '' };
    }
  }
  if (
    data?.slug?.length > 0 &&
    Object.prototype.hasOwnProperty.call(data?.data?.images, 'logo') &&
    data?.data?.images?.logo?.length > 0
  ) {
    url = data?.data?.images?.logo;
  } else {
    url = '';
  }
  return url;
};

export const updateThemeData = (customTheme: any, personalization: any, defaultTheme: any): any => {
  // const theme = customTheme;
  if (customTheme) {
    customTheme.palette.primary.main =
      personalization?.primary?.length > 0 ? personalization?.primary : Colors.primaryVariant;

    customTheme.palette.secondary.main =
      personalization?.secondary?.length > 0 ? personalization?.secondary : Colors.secondary;

    customTheme.palette.text.primary =
      personalization?.textPrimary?.length > 0
        ? personalization?.textPrimary
        : defaultTheme?.palette?.text?.primary;

    customTheme.palette.text.secondary =
      personalization?.textSecondary?.length > 0
        ? personalization?.textSecondary
        : defaultTheme?.palette?.text?.secondary;
  }
  return customTheme;
};
