import Highcharts from 'highcharts';
import TranslateText from '../../../../i18n/TranslateText';
/**
 *
 */
export const areaChartConfig = (): Highcharts.Options => {
  return {
    lang: {
      noData: TranslateText('no_data_found', 'No data found')
    },
    noData: {
      style: {
        fontWeight: 'bold',
        fontSize: '15px'
      }
    },
    chart: {
      animation: false,
      zooming: { type: 'x' },
      resetZoomButton: {
        position: {
          x: 0,
          y: 0
        }
      }
    },
    title: {
      text: ''
    },
    xAxis: {
      type: 'linear',
      crosshair: true,
      labels: {
        autoRotation: [0]
      },
      minRange: 1
    },
    legend: {
      enabled: true,
      itemStyle: {
        fontWeight: 'bold'
      }
    },
    time: {
      useUTC: false
    },
    rangeSelector: {
      enabled: true,
      buttonPosition: {
        align: 'right',
        x: -25
      },
      buttonTheme: {
        fill: 'none',
        stroke: 'none',
        'stroke-width': 0,
        style: {
          fontWeight: 'bold'
        },
        states: {
          hover: {
            fill: 'none'
          },
          select: {
            fill: 'none',
            style: {
              color: 'white'
            }
          }
        }
      },
      inputBoxBorderColor: 'gray',
      inputBoxWidth: 120,
      inputBoxHeight: 18,
      inputStyle: {
        color: '#00b1c1',
        fontWeight: 'bold'
      },
      buttons: [
        {
          type: 'all',
          text: 'Reset'
        }
      ],
      inputEnabled: false,
      labelStyle: {
        display: 'none'
      }
    },
    scrollbar: {
      liveRedraw: false
    },
    navigator: {
      enabled: true,
      adaptToUpdatedData: false,
      xAxis: {
        type: 'linear',
        labels: {}
      }
    },
    credits: {
      enabled: false
    }
  };
};
