import { AnyAction } from 'redux';
import * as ACTION_TYPES from './survey.actionTypes';

export const addSurveyProgress = (
  slug: string,
  deviceId: string | number,
  componentId: string,
  answers: any,
  surveyQuestionsId: number
): AnyAction => {
  return {
    type: ACTION_TYPES.ADD_SURVEY_ANSWERS,
    payload: {
      slug,
      deviceId,
      componentId,
      answers,
      surveyQuestionsId
    }
  };
};
/**
 *
 * @param data
 */
export const addSurveySuccess = (data: any, rawNarrowbands: any, powertrain: any): AnyAction => {
  // returns the action type
  return {
    type: ACTION_TYPES.ADD_SURVEY_ANSWERS_SUCCESS,
    data,
    rawNarrowbands,
    powertrain
  };
};
/**
 *
 * @param error
 */
export const addSurveyFailure = (error: any): AnyAction => {
  // returns the action type
  return {
    type: ACTION_TYPES.ADD_SURVEY_ANSWERS_FAILURE,
    error
  };
};
/**
 *
 * @param data
 */

export const addSurveyReset = (): AnyAction => {
  return {
    type: ACTION_TYPES.ADD_SURVEY_ANSWERS_RESET
  };
};

export const editSurveyProgress = (
  slug: string,
  deviceId: string | number,
  componentId: string,
  answersId: string,
  answers: any
): AnyAction => {
  return {
    type: ACTION_TYPES.EDIT_SURVEY_ANSWERS,
    payload: {
      slug,
      deviceId,
      componentId,
      answersId,
      answers
    }
  };
};
/**
 *
 * @param data
 */
export const editSurveySuccess = (data: any, rawNarrowbands: any): AnyAction => {
  // returns the action type
  return {
    type: ACTION_TYPES.EDIT_SURVEY_ANSWERS_SUCCESS,
    data,
    rawNarrowbands
  };
};
/**
 *
 * @param error
 */
export const editSurveyFailure = (error: any): AnyAction => {
  // returns the action type
  return {
    type: ACTION_TYPES.EDIT_SURVEY_ANSWERS_FAILURE,
    error
  };
};
/**
 *
 * @param data
 */

export const editSurveyReset = (): AnyAction => {
  return {
    type: ACTION_TYPES.EDIT_SURVEY_ANSWERS_RESET
  };
};
