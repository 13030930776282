import { AnyAction } from 'redux';
import * as ACTION_TYPE from './deleteDashboardItem.actionTypes';
/**
 *
 */

export const deleteDashboardItem = (data: any): AnyAction => {
  return {
    type: ACTION_TYPE.DELETE_DASHBOARD_ITEM_PROGRESS,
    payload: data
  };
};
export const deleteDashboardItemSuccess = (dashboard_item_uuid: any): AnyAction => {
  return {
    type: ACTION_TYPE.DELETE_DASHBOARD_ITEM_SUCCESS,
    payload: dashboard_item_uuid
  };
};

export const deleteDashboardItemFailure = (error: any): AnyAction => {
  return {
    type: ACTION_TYPE.DELETE_DASHBOARD_ITEM_FAILURE,
    payload: error
  };
};

export const deleteDashboardItemReset = (): AnyAction => {
  return {
    type: ACTION_TYPE.DELETE_DASHBOARD_ITEM_RESET_STATE,
    payload: false
  };
};
