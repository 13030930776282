import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  formControl: {
    display: 'flex',
    margin: '0 auto'
  },
  root: {
    margin: '0 auto'
  },
  buttonStyle: {
    margin: '0 auto',
    textAlign: 'center',
    paddingTop: 35
  }
}));
