/**
 * Update Organisation Saga
 * @author mahesh.kedari@shorelineiot.com
 */

import { put, takeEvery, all, ForkEffect } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import * as actions from './actions/orgUpdate.actions';
import * as actionTypes from './actions/orgUpdate.actionTypes';
import { fetchOrgList } from '../../../organization/store/org-list/actions/orgList.actions';
import {
  GenericObject,
  httpGet,
  httpPut,
  SafeSaga,
  showToast,
  APISERVICES
} from '../../../../framework';
/**
 *
 * @param action
 */
function* updateOrg(action: AnyAction) {
  const { name, url, slug } = action.payload;
  const body = {
    name,
    url
  };
  const response: GenericObject = yield httpPut(`orgs/${slug}`, body, APISERVICES.HOST_API);
  yield put(showToast('Organization Updated Successfully!', 'success'));
  yield put(actions.updateOrgSuccess(response));
  yield put(fetchOrgList());
}
function* errorHandlerUpdateOrgDetails(error: any) {
  yield put(actions.updateOrgFailure(error));
}
/**
 *
 * @param action
 */
function* getOrgDetails(action: AnyAction) {
  const { slug } = action.payload;

  const response: GenericObject = yield httpGet(`orgs/${slug}`, null, APISERVICES.HOST_API);
  yield put(actions.orgDetailsSuccess(response));
}
function* errorHandlerGetOrgDetails() {
  yield put(actions.orgDetailsFailure());
}
/**
 *
 */
export default function* watchOrgUpdateSaga() {
  const updateOrgSaga: ForkEffect<GenericObject> = yield takeEvery(
    actionTypes.UPDATE_ORG,
    SafeSaga(updateOrg, actionTypes.UPDATE_ORG, errorHandlerUpdateOrgDetails)
  );
  const fetchOrgDetailsSaga: ForkEffect<GenericObject> = yield takeEvery(
    actionTypes.FETCH_ORG_DETAILS,
    SafeSaga(getOrgDetails, actionTypes.FETCH_ORG_DETAILS, errorHandlerGetOrgDetails)
  );
  yield all([updateOrgSaga, fetchOrgDetailsSaga]);
}
