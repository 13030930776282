/**
 * Nested Conditions
 * @author mahesh.kedari@shorelineiot.com
 */
import React from 'react';
import { Grid, Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { useFieldArray, useFormContext } from 'react-hook-form';
import { ConditionRow } from './TableColumnConditionRow';
import SLDeleteButtonWithTooltip from '../../../../button/DeleteButtonWithTooltip';
import SLAddButtonWithTooltip from '../../../../button/AddButtonWithTooltip';
import SLDivider from '../../../../divider/SLDivider';
import TranslateText from '../../../../../../i18n/TranslateText';

interface Props {
  name: string;
  handleUpdate: () => void;
}
const useStyles = makeStyles((theme: Theme) => ({
  condition: {
    border: 'solid thin',
    borderColor: theme.palette.grey[300],
    borderRadius: '5px',
    padding: '3px',
    marginBottom: '5px'
  },
  deleteButton: {
    position: 'absolute',
    right: '15px'
  }
}));

export default function TableColumnConditions({ name, handleUpdate }: Props) {
  const classes = useStyles();
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name
  });
  const handleAppend = () => {
    append({
      key: Math.random(),
      value: ' ',
      operator: ' ',
      formatted_text: ''
    });
  };
  return (
    <>
      <SLDivider textAlign="center">
        {TranslateText('conditional_formatting', 'Conditional Fomatting')}
      </SLDivider>
      <Grid container>
        {fields &&
          fields.map((item: any, index: number) => (
            <Grid key={index} item xs={12} className={classes.condition} container>
              <ConditionRow prefix={name} index={index} handleUpdate={handleUpdate} />
              <div className={classes.deleteButton}>
                <SLDeleteButtonWithTooltip
                  tooltip="Delete Condition"
                  onRemove={() => {
                    remove(index);
                  }}
                />
              </div>
            </Grid>
          ))}
        <Grid item xs={10}>
          {/* Offset */}
        </Grid>
        <Grid item xs={2}>
          <SLAddButtonWithTooltip tooltip="Add Condition" onAdd={handleAppend} />
        </Grid>
      </Grid>
    </>
  );
}
