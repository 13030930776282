// Dashboard Utilities Functions

import { LEAK_DETECTION_DASHBOARD, MACHINE_HEALTH_DASHBOARD } from './dashboard.constants';

const timeStampIndex = 0;
const dataIndex = 1;

function isInt(n: number) {
  return n % 1 === 0;
}

export const getMinValue = (dataArray: any[]) => {
  if (dataArray.length !== 0) {
    let tempMinValue = dataArray[0][dataIndex];
    dataArray.forEach((data) => {
      if (data[dataIndex] < tempMinValue) tempMinValue = data[dataIndex];
    });
    return isInt(tempMinValue) ? tempMinValue : parseFloat(tempMinValue.toFixed(4));
  }
  return null;
};

export const getMaxValue = (dataArray: any[]) => {
  if (dataArray.length !== 0) {
    let tempMaxValue = dataArray[0][dataIndex];
    dataArray.forEach((data) => {
      if (data[dataIndex] > tempMaxValue) tempMaxValue = data[dataIndex];
    });
    return isInt(tempMaxValue) ? tempMaxValue : parseFloat(tempMaxValue.toFixed(4));
  }
  return null;
};

export const getLatestValue = (dataArray: any[]) => {
  if (dataArray.length !== 0) {
    let latestTS = dataArray[0][timeStampIndex];
    let latestTSValue = dataArray[0][dataIndex];
    dataArray.forEach((data) => {
      if (data[timeStampIndex] > latestTS) {
        latestTS = data[timeStampIndex];
        latestTSValue = data[dataIndex];
      }
    });
    return isInt(latestTSValue) ? latestTSValue : parseFloat(latestTSValue.toFixed(4));
  }
  return null;
};

export const formatString = (str: string) => {
  return str.toLowerCase().trim().replace(/\s+/g, '_');
};

/**
 * Determines if a dashboard should be enabled based on its label and the status
 * of feature flags for machine health and leak detection dashboards.
 *
 * @param isMachineHealthDashboardEnabled - Flag indicating if the machine health dashboard is enabled.
 * @param isLeakDetectionDashboardEnabled - Flag indicating if the leak detection dashboard is enabled.
 * @param label - Label of the dashboard used to check against feature flags.
 * @returns A boolean indicating whether the dashboard should be enabled.
 */

export const canEnableMachineHealthAndLeakDetectionDashboard = (
  isMachineHealthDashboardEnabled: boolean,
  isLeakDetectionDashboardEnabled: boolean,
  label: string
) => {
  const shouldDashboardBeEnabled =
    (isMachineHealthDashboardEnabled || !(label.toLowerCase() === MACHINE_HEALTH_DASHBOARD)) &&
    (isLeakDetectionDashboardEnabled || !(label.toLowerCase() === LEAK_DETECTION_DASHBOARD));
  return shouldDashboardBeEnabled;
};
