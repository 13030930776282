/**
 * Organisation Selector Saga
 * @author mahesh.kedari@shorelineiot.com
 */
import { call, put, takeLatest } from 'redux-saga/effects';
import { AnyAction } from 'redux';

import * as ACTIONS from './orgSelector.actionTypes';
import * as actions from './orgSelector.actions';
import { httpPost, SafeSaga, APISERVICES } from '../../../../framework';
import { SagaIterator } from 'redux-saga';

/**
 *
 * @param slug Organisation Slug
 */
function switchOrganisation(slug: string) {
  return httpPost(`orgs/${slug}/mark_last_switch_to`, {}, APISERVICES.HOST_API);
}
/**
 *
 * @param organisation Organisation object to be stored
 */
function updateLocalStorage(organisation: any) {
  localStorage.setItem('organisation', JSON.stringify(organisation));
}
/**
 * Switch Organisation
 */
function* switchOrganisationSaga(action: AnyAction) {
  const { slug } = action.payload;
  yield call(switchOrganisation, slug);
  // On Success, update local storage
  yield put(actions.switchOrgSuccess(action.payload));
  yield call(updateLocalStorage, action.payload);
}

function* errorHandler(err: any) {
  yield put(actions.switchOrgFailure(err));
}

export function* watchOrgSelectorSaga(): SagaIterator {
  yield takeLatest(
    ACTIONS.SWITCH_ORG_INIT,
    SafeSaga(switchOrganisationSaga, ACTIONS.SWITCH_ORG_INIT, errorHandler)
  );
}
