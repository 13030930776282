/**
 * Pie Chart View
 * @author mahesh.kedari@shorelineiot.com
 */
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highcharts3d from 'highcharts/highcharts-3d';
import merge from 'lodash/merge';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { ChartViewProps } from '../../common/ChartViewProps';
import { plantHealthTrendChartConfig as defaultPlantHealthSettings } from './defaultPlantHealthTrendSettings';

highcharts3d(Highcharts);

const mergeDefaultSettings = (customSettings: any) => {
  return merge({}, defaultPlantHealthSettings(), {
    yAxis: {
      title: {
        text: customSettings.yAxis?.label
      }
    },
    plotOptions: {
      series: {
        marker: {
          enabled: customSettings.marker.enabled
        }
      }
    }
  });
};
/**
 *
 * @param param0
 * @returns
 */
const PlantHealthViewer: React.FC<ChartViewProps> = React.forwardRef(
  ({ chartSettings, resultSet }: ChartViewProps, ref: any) => {
    const navigate = useNavigate();
    const [lineChartSettings, setLineChartSettings] = useState(mergeDefaultSettings(chartSettings));
    useEffect(() => {
      const seriesData =
        resultSet?.reduce(
          (acc: any, current: any) => {
            switch (current.alert_level) {
              case 'ALERT_NONE':
                acc.alert_none.push({
                  x: new Date(current.day).getTime(),
                  y: current.count,
                  current: 'alert_none'
                });
                break;
              case 'ALERT_ERROR':
                acc.alert_error.push({
                  x: new Date(current.day).getTime(),
                  y: current.count,
                  current: 'alert_error'
                });
                break;
              case 'ALERT_FATAL':
                acc.alert_fatal.push({
                  x: new Date(current.day).getTime(),
                  y: current.count,
                  current: 'alert_fatal'
                });
                break;
              default:
                break;
            }
            return acc;
          },
          {
            alert_none: [],
            alert_error: [],
            alert_fatal: []
          }
        ) ?? [];
      const series: Array<Highcharts.SeriesLineOptions> = [
        {
          type: 'line',
          name: chartSettings?.healthy.label,
          data: seriesData.alert_none,
          color: chartSettings?.healthy.color.hex
        },
        {
          type: 'line',
          name: chartSettings?.high.label,
          data: seriesData.alert_error,
          color: chartSettings?.high.color.hex
        },
        {
          type: 'line',
          name: chartSettings?.critical.label,
          data: seriesData.alert_fatal,
          color: chartSettings?.critical.color.hex
        }
      ];

      const revisedSettings = merge(lineChartSettings, {
        chart: {
          options3d: {
            enabled: chartSettings.enable3d,
            alpha: 45
          }
        },
        title: {
          text: chartSettings?.title,
          verticalAlign: 'middle',
          align: 'center',
          y: 0,
          style: {
            fontSize: '15px',
            fontWeight: 'bold'
          }
        },
        xAxis: {
          type: 'linear',
          // tickInterval: 24 * 3600,
          crosshair: chartSettings?.crosshair?.enable ? 1 : 0,
          title: {
            text: chartSettings?.xAxisTitle
          },
          gridLineWidth: chartSettings?.grid?.disable ? 0 : (chartSettings?.grid?.width ?? 1),
          labels: {
            autoRotation: [0],
            formatter: function () {
              // @ts-ignore - Type issue hence need to add this rule bypassing the error
              const date = new Date(this.value);
              const month: string = (date.getMonth() + 1).toString().padStart(2, '0');
              const day: string = date.getDate().toString().padStart(2, '0');
              return `${month}/${day}`;
            }
          }
        },
        yAxis: {
          title: {
            text: chartSettings?.yAxisTitle
          }
        },
        plotOptions: {
          series: {
            cursor: 'pointer',
            point: {
              events: {
                click: (e: any) => {
                  if (e.point.url !== '' && e.point.url !== undefined) {
                    navigate(e.point.url);
                  }
                }
              }
            },
            marker: {
              enabled: chartSettings?.marker
            }
          }
        },
        series
      });
      setLineChartSettings({ ...revisedSettings });
    }, [resultSet, chartSettings]);
    return <HighchartsReact highcharts={Highcharts} options={lineChartSettings} ref={ref} />;
  }
);

export default PlantHealthViewer;
