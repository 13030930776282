/**
 * Donut Chart Settings
 * @author Prerna.More@shorelineiot.com
 */

import Highcharts from 'highcharts';
import TranslateText from '../../../../i18n/TranslateText';

export const donutChartConfig = (): Highcharts.Options => {
  return {
    lang: {
      noData: TranslateText('no_data_found', 'No data found')
    },
    noData: {
      style: {
        fontWeight: 'bold',
        fontSize: '15px'
      }
    },
    chart: {
      type: 'pie',
      animation: false,
      plotBorderWidth: 0,
      marginTop: -90,
      marginLeft: 0,
      marginRight: 0,
      marginBottom: 0,
      options3d: {
        alpha: 45
      },
      zooming: { type: 'x' },
      resetZoomButton: {
        position: {
          x: 0,
          y: 0
        }
      }
    },
    title: {
      text: ''
    },
    tooltip: {
      formatter() {
        return `<b>${this.point.name} : ${this.y} </b>`;
      },
      style: {
        color: 'white'
      },
      backgroundColor: '#4d4d4d'
    },
    rangeSelector: {
      enabled: true,
      buttonPosition: {
        align: 'right',
        x: -25
      },
      buttonTheme: {
        // styles for the buttons
        fill: 'none',
        stroke: 'none',
        'stroke-width': 0,
        style: {
          fontWeight: 'bold'
        },
        states: {
          hover: {
            fill: 'none'
          },
          select: {
            fill: 'none',
            style: {
              color: 'white'
            }
          }
        }
      },
      inputBoxBorderColor: 'gray',
      inputBoxWidth: 120,
      inputBoxHeight: 18,
      inputStyle: {
        color: '#00b1c1',
        fontWeight: 'bold'
      },
      buttons: [
        {
          type: 'all',
          text: 'Reset'
        }
      ],
      inputEnabled: false,
      labelStyle: {
        display: 'none'
      }
    },
    scrollbar: {
      liveRedraw: false
    },
    navigator: {
      enabled: true,
      adaptToUpdatedData: false,
      xAxis: {
        type: 'linear',
        labels: {}
      }
    },
    credits: {
      enabled: false
    },
    accessibility: {
      point: {
        valueSuffix: '%'
      }
    },
    plotOptions: {
      // pie: {
      //   shadow: false,
      //   showInLegend: true,
      // },
      pie: {
        shadow: false,
        size: '100%',
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          // distance: '0%',
          distance: -50,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %',
          style: {
            fontSize: '13px'
          }
        }
      },
      series: {
        marker: {
          symbol: 'square',
          radius: 12
        }
      }
    },
    series: [
      {
        type: 'pie',
        size: '60%',
        innerSize: '50%',
        dataLabels: {
          enabled: false
        }
      }
    ]
  };
};
