/**
 * Line Chart View
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import merge from 'lodash/merge';
import { ChartViewProps } from '../common/ChartViewProps';
import { parseGaugeSettings } from './gaugeChartSettings';
import { parseSeries } from './useGaugeValueChart';
/**
 *
 */
const SLGaugeChartView: React.FC<ChartViewProps> = React.forwardRef(
  ({ chartSettings, resultSet }: ChartViewProps, ref: any) => {
    const updatedChartSettings = parseGaugeSettings(chartSettings);
    const [gaugeSettings, setGaugeSettings] = useState<Highcharts.Options>(updatedChartSettings);

    useEffect(() => {
      const { series } = parseSeries(resultSet);
      const newChartSettings = merge({}, updatedChartSettings, {
        chart: {
          type: chartSettings.chartType
        },
        series
      });
      setGaugeSettings(newChartSettings);
    }, [resultSet, chartSettings]);

    return <HighchartsReact highcharts={Highcharts} options={gaugeSettings} ref={ref} />;
  }
);

export default SLGaugeChartView;
