import { Box } from '@mui/material';
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react';
import WidgetDataUnavailable from '../widget-data-unavailable/WidgetDataUnavailable';
import SLTable from '../../table/SLTable';
import { useStyles } from './styles/assetAlarms.styles';
import { Colors } from '../../../constants';
import { SLTableColumn } from '../../table/slTable.types';
import { Alarm, updatePathName } from '../../../../features/alarms/store';
import moment from 'moment';
import { useFetchDevices, useFetchDeviceGroups } from '../../../../features/device';
import { useSlugContext } from '../../../lib';

interface TableProps {
  tableData: any;
  tableColumns: Array<SLTableColumn<any>>;
  customOptions: { search: boolean };
  onRowClickFunc?: (rowData?: any) => void;
}

const SLAssetAlarmsWidget = React.forwardRef(
  ({ tableData, tableColumns, customOptions, onRowClickFunc }: TableProps, ref: any) => {
    const { slug } = useSlugContext();
    const classes = useStyles();
    const stageCanvasRef: any = useRef(null);
    const [tableHeight, setTableHeight] = useState(
      stageCanvasRef?.current?.parentElement?.parentElement?.parentElement?.clientHeight
    );
    const DIFFERENCE_IN_PX = 70;

    const { devices } = useFetchDevices({
      slug
    });
    const { deviceGroups } = useFetchDeviceGroups({ slug });

    const epochToHumanReadableDate = (dateTimestamp: number) => {
      if (!dateTimestamp) {
        return '';
      }
      const localTimestamp = moment(dateTimestamp).format('MMM D, YYYY | h:mm A');
      return localTimestamp;
    };

    const generateAdditionalColumnsData = useCallback(
      (alarms: Alarm[]): Alarm[] => {
        return alarms?.map((alarm) => {
          return {
            ...alarm,
            latestColumnData: {
              cellText: epochToHumanReadableDate(alarm?.latest)
            },
            pathColumnData: {
              cellText: updatePathName(alarm, devices, deviceGroups) || ''
            }
          };
        });
      },
      [deviceGroups, devices]
    );

    const newData = useMemo(
      () => generateAdditionalColumnsData(tableData),
      [generateAdditionalColumnsData, tableData]
    );

    useEffect(() => {
      setTableHeight(
        stageCanvasRef?.current?.parentElement?.parentElement?.parentElement?.clientHeight
      );
    }, [stageCanvasRef?.current?.parentElement?.parentElement?.parentElement?.clientHeight]);

    useImperativeHandle(
      ref,
      () => ({
        resize(width: number, height: number) {
          setTableHeight(height);
        }
      }),
      []
    );

    if (!tableData || (tableData && tableData[0]?.message && tableData[0]?.message === 'No data')) {
      return <WidgetDataUnavailable />;
    }

    return (
      <Box ref={stageCanvasRef} textAlign="center" className={classes.topMargin}>
        <SLTable
          columns={tableColumns}
          data={newData || []}
          enableTopToolbar
          enableSearch={customOptions?.search}
          enableSorting
          maxBodyHeight={tableHeight && tableHeight - DIFFERENCE_IN_PX}
          paperColor={Colors.background}
          onRowClick={onRowClickFunc}
          feature="asset-alarms"
        />
      </Box>
    );
  }
);

export default SLAssetAlarmsWidget;
