/**
 * Component Compilar Permissions,
 * @author prerna.more@shorelineiot.com
 */
export const COMPONENT_COMPILER_PERMISSIONS = {
  VIEW: 'view component compiler',
  CREATE: 'create component compiler',
  DELETE: 'delete component compiler',
  UPDATE: 'update component compiler',
  COPY: 'copy component compiler'
};
