/**
 * Link Styling
 * @author mahesh.kedari@shorelineiot.com
 */
import { LinkClasses, Theme } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { Colors } from '../../../constants';

export const LinkStyle:
  | Partial<OverridesStyleRules<keyof LinkClasses, 'MuiLink', Omit<Theme, 'components'>>>
  | undefined = {
  root: {
    textDecoration: 'none',
    color: Colors.primaryText
  },
  underlineAlways: 'none'
};
