/**
 * User Profile Menu
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { PropsWithChildren } from 'react';
import Menu from '@mui/material/Menu';
import { MenuContext } from './UserMenuContext';
/**
 *
 */
interface Props {
  idFor: string;
  anchorEl?: Element;
  status: boolean;
  parent: HTMLElement | null;
  onMenuClose: Function;
}
/**
 *
 */
export default function UserProfileMenu(props: PropsWithChildren<Props>) {
  const { onMenuClose, idFor, parent, status, children } = props;

  const handleCloseMenu = () => {
    if (onMenuClose) {
      onMenuClose(null);
    }
  };

  return (
    <MenuContext.Provider value={{ handleCloseMenu }}>
      <Menu
        id={idFor}
        anchorEl={parent}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        keepMounted
        open={status}
        style={{ zIndex: 9999 }}
        onClose={handleCloseMenu}>
        {children}
      </Menu>
    </MenuContext.Provider>
  );
}
