/**
 * Dashboard List Saga
 */

import { put, takeEvery, call } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import * as DEFAULT_DASHBOARD_ACTION_TYPES from './defaultDashboard.actionsTypes';
import * as DEFAULT_DASHBOARD_ACTIONS from './defaultDashboard.action';
import { GenericObject, httpGet, removeProgressFor, APISERVICES } from '../../../../framework';
import { SagaIterator } from 'redux-saga';

function fetchDefaultDashboardFromDatabase(data: any) {
  return httpGet(`orgs/${data.slug}/user_dashboard`, null, APISERVICES.HOST_API);
}

export function* getDefaultDashboardData(action: AnyAction) {
  try {
    const response: GenericObject = yield call(fetchDefaultDashboardFromDatabase, action.payload);
    yield put(DEFAULT_DASHBOARD_ACTIONS.handleGetDefaultDashboardSuccess(response));
  } catch (error: any) {
    yield put(DEFAULT_DASHBOARD_ACTIONS.handleGetDefaultDashboardFailure(error));
  } finally {
    yield put(removeProgressFor('GET_DEFAULT_DASHBOARD'));
  }
}

export function* watchGetDefaultDashboardSaga(): SagaIterator {
  yield takeEvery(
    DEFAULT_DASHBOARD_ACTION_TYPES.DEFAULT_DASHBOARD_PROGRESS,
    getDefaultDashboardData
  );
}
