import { put, takeEvery, call } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import * as getSurveyQuestionsActions from './actions/getSurveyQuestions.actions';
import * as actionTypes from './actions/getSurveyQuestions.actionTypes';
import {
  GenericObject,
  httpGet,
  removeProgressFor,
  showProgressFor,
  showToast,
  APISERVICES
} from '../../../../../../../framework';
import { SagaIterator } from 'redux-saga';

const SURVEY_QUESTIONS = 'SURVEY_QUESTIONS';
const SINGLE_SURVEY_QUESTIONS = 'SINGLE_SURVEY_QUESTIONS';

function getAllPowerTrainSurveyQuestions(slug: string, deviceId: string) {
  return httpGet(
    `orgs/${slug}/devices/${deviceId}/powertrain_component/survey_questions`,
    null,
    APISERVICES.DEVICE_API
  );
}
function getSinglePowerTrainSurveyQuestions(slug: string, deviceId: string, componentId: string) {
  return httpGet(
    `orgs/${slug}/devices/${deviceId}/powertrain_component/${componentId}/survey_questions`,
    null,
    APISERVICES.DEVICE_API
  );
}

export function* getSurveyQuestionsSaga(action: AnyAction) {
  yield put(showProgressFor(SURVEY_QUESTIONS));

  try {
    const allTrainResponse: GenericObject = yield call(
      getAllPowerTrainSurveyQuestions,
      action.payload.slug,
      action.payload.deviceId
    );

    yield put(getSurveyQuestionsActions.getSurveyQuestionsSuccess(allTrainResponse));
  } catch (error: any) {
    yield put(showToast('Error in fetching Questions', 'error', true));
    yield put(getSurveyQuestionsActions.getSurveyQuestionsFailure(error));
  } finally {
    yield put(removeProgressFor(SURVEY_QUESTIONS));
  }
}

export function* getSingleSurveyQuestionsSaga(action: AnyAction) {
  yield put(showProgressFor(SINGLE_SURVEY_QUESTIONS));

  try {
    const { slug, deviceId, componentId, setShowSurveyJS } = action.payload;

    const response: GenericObject = yield call(
      getSinglePowerTrainSurveyQuestions,
      slug,
      deviceId,
      componentId
    );

    yield put(getSurveyQuestionsActions.getSingleSurveyQuestionsSuccess(response));
    if (setShowSurveyJS) {
      setShowSurveyJS(true);
    }
  } catch (error: any) {
    yield put(showToast('Error in fetching Questions', 'error', true));
    yield put(getSurveyQuestionsActions.getSingleSurveyQuestionsFailure(error));
  } finally {
    yield put(removeProgressFor(SINGLE_SURVEY_QUESTIONS));
  }
}

export function* watchGetSurveyQuestionsSaga(): SagaIterator {
  yield takeEvery(actionTypes.GET_SURVEY_QUESTIONS, getSurveyQuestionsSaga);
  yield takeEvery(actionTypes.GET_SINGLE_SURVEY_QUESTIONS, getSingleSurveyQuestionsSaga);
}
