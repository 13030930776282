/**
 * Anomaly Template Details Saga
 * @author aditya.bhadange@shorelineiot.com
 */
import { takeEvery, put, call } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import * as ANOMALY_TEMPLATE_DETAILS_ACTIONS from './anomalyTemplatesDetails.actionTypes';
import * as anomalyTemplateDetailsActions from './anomalyTemplatesDetails.actions';
import {
  GenericObject,
  httpGet,
  httpPut,
  SafeSaga,
  showToast,
  APISERVICES
} from '../../../../framework';
import { SagaIterator } from 'redux-saga';
/**
 *
 * @param data anomaly ID
 * @returns
 */
function getAnomalyTemplateDetails(data: any) {
  return httpGet(`anomalies/${data.anomalyId}`, null, APISERVICES.DEVICE_API);
}
/**
 *
 * @param data
 * @returns
 */
function updateAnomalyTemplateDetails(data: any) {
  return httpPut(`anomalies/${data.anomalyId}`, data.payload, APISERVICES.DEVICE_API);
}
/**
 *
 */
function* fetchAnomalyTemplateSaga(action: AnyAction) {
  const response: GenericObject = yield call(getAnomalyTemplateDetails, action.payload);

  yield put(anomalyTemplateDetailsActions.fetchComponentDetailsSuccess(response));
}
/**
 *
 */
function* updateAnomalyTemplateSaga(action: AnyAction) {
  const response: GenericObject = yield call(updateAnomalyTemplateDetails, action.payload);

  yield put(anomalyTemplateDetailsActions.updateAnomalyTemplateSuccess(response));
  yield put(showToast('Anomaly Template Updated Successfully!', 'success'));
}
/**
 *
 * @param error
 */
function* fetchErrorHandler(error: any) {
  yield put(anomalyTemplateDetailsActions.fetchComponentDetailsFailure(error));
}
/**
 *
 * @param error
 */
function* updateErrorHandler(error: any) {
  yield put(anomalyTemplateDetailsActions.updateAnomalyTemplateFailure(error));
}
/**
 *
 */
export function* watchFetchAnomalyTemplateDetailsSaga(): SagaIterator {
  yield takeEvery(
    ANOMALY_TEMPLATE_DETAILS_ACTIONS.FETCH_ANOMALY_TEMPLATE_DETAILS,
    SafeSaga(
      fetchAnomalyTemplateSaga,
      ANOMALY_TEMPLATE_DETAILS_ACTIONS.FETCH_ANOMALY_TEMPLATE_DETAILS,
      fetchErrorHandler
    )
  );
}
/**
 *
 */
export function* watchUpdateAnomalyTemplateDetailsSaga(): SagaIterator {
  yield takeEvery(
    ANOMALY_TEMPLATE_DETAILS_ACTIONS.UPDATE_ANOMALY_TEMPLATE,
    SafeSaga(
      updateAnomalyTemplateSaga,
      ANOMALY_TEMPLATE_DETAILS_ACTIONS.UPDATE_ANOMALY_TEMPLATE,
      updateErrorHandler
    )
  );
}
