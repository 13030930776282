import { combineReducers } from 'redux';
import va from './vibration-analysis/store/vibrationAnalysis.reducer';
import anomaly from './anomaly-configurator/store/anomaly.reducer';
import trends from './trend-analysis/store/trendAnalysis.reducer';

/**
 *
 */
const workflowReducer = combineReducers({
  anomaly,
  va,
  trends
});
/**
 * Features reducer combined
 */
export default workflowReducer;
