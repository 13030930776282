/**
 * Bar Chart View
 * @author mahesh.kedari@shorelineiot.com
 */
import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { ChartViewProps } from '../common/ChartViewProps';
import { barChartConfig as defaultBarChartSettings } from './barChartSettings';
import useCartesionChart from '../utils/useCartesianChart';
/**
 *
 * @param param0
 * @returns
 */
const SLBarChartView: React.FC<ChartViewProps> = React.forwardRef(
  ({ chartSettings, resultSet, widgetType }: ChartViewProps, ref: any) => {
    const barChartSettings = useCartesionChart(
      chartSettings,
      defaultBarChartSettings(),
      resultSet,
      widgetType,
      'bar'
    );

    return <HighchartsReact highcharts={Highcharts} options={barChartSettings} ref={ref} />;
  }
);

export default SLBarChartView;
