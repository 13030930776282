export const defaultRawDataQuery = {
  type: 'device',
  data: {
    devices: [], // Either duration or record_count can be specified
    duration: {
      start: new Date().getTime(),
      end: new Date().getTime()
    },
    recordCount: 10000 // Last 10,000 records will be fetched
  }
};
