import { Dispatch } from 'redux';
import { roleListActions } from '../../../org-settings/store';
import { WEBSOCKET_MODULE_OPTIONS } from '../websocket.constants';
/**
 * Handle Response
 * @param event
 */
export function orgMessageHandler(dispatch: Dispatch<any>, data: any) {
  if (data.module === WEBSOCKET_MODULE_OPTIONS.ROLES.ROLES_LIST) {
    dispatch(roleListActions.updateRoles(data));
  }
}
