/**
 * FFT Chart State Type
 * @author mahesh.kedari@shorelineiot.com
 */
export enum FFT_DOMAIN {
  TIME = 'time',
  FREQUENCY = 'frequency'
}
/**
 *
 */
export interface ManualScaleType {
  enabled: boolean;
  min?: number;
  max?: number;
}
/**
 *
 */
export interface PeakSearchType {
  enabled: boolean;
  count: number;
}

export interface FFTZoomType {
  minHz: number | null;
  maxHz: number | null;
}

export enum FFT_CHART_STATUS {
  INIT,
  FETCHING,
  FREQUENCY_READY,
  TIME_READY,
  ERROR
}
/**
 *
 */
export interface FFTChartState {
  status: FFT_CHART_STATUS;
  domain: FFT_DOMAIN;
  fftDataTime?: any;
  fftchartDataTime: Array<any>;
  fftDataFrequency?: any;
  fftchartDataFrequency: Array<any>;
  manualScale: ManualScaleType;
  peakSearch: PeakSearchType;
  zoomInHZ: FFTZoomType;
  harmonicsList: Array<any>;
  harmonicsOriginalList: Array<any>;
  allHarmonicsSeries: Array<any>;
  spectrumSeriesId: string;
}

// export const seriesColorArray = [
//   // '#0000FF', // Blue
//   // '#000000', // Black
//   // '#C0C0C0', // Aqua
//   "#21436C",
//   "#53CCD1",
//   "#A1B64F",
//   "#FF00FF", // Fuschia
// ];
const getTveColorBySeriesId = (id: number) => {
  switch (id) {
    case 1:
      return '#3134D7';
    case 2:
      return '#8533d7';
    case 3:
      return '#33d7ae';
    case 4:
      return '#5c33d7';

    default:
      return '#3134D7';
  }
};

const getHorizontalColorBySeriesId = (id: number) => {
  switch (id) {
    case 1:
      return '#21436C';
    case 2:
      return '#6394cf';
    case 3:
      return '#0c1827';
    case 4:
      return '#b1c9e7';

    default:
      return '#21436C';
  }
};

const getVerticalColorBySeriesId = (id: number) => {
  switch (id) {
    case 1:
      return '#53CCD1';
    case 2:
      return '#008000';
    case 3:
      return '#0b2728';
    case 4:
      return '#c3edef';

    default:
      return '#53CCD1';
  }
};

const getAxialColorBySeriesId = (id: number) => {
  switch (id) {
    case 1:
      return '#A1B64F';
    case 2:
      return '#b54f4f';
    case 3:
      return '#b59c4f';
    case 4:
      return '#4fb54f';

    default:
      return '#A1B64F';
  }
};
// export const seriesColorMap = (rawDpName: string) => {
//   if (rawDpName.toLowerCase().includes("horizontal")) {
//     return "#21436C";
//   } else if (rawDpName.toLowerCase().includes("vertical")) {
//     return "#53CCD1";
//   } else if (rawDpName.toLowerCase().includes("axial")) {
//     return "#A1B64F";
//   }
// };

export const seriesColorMap = (rawDpName: string, seriesId: number) => {
  if (rawDpName && rawDpName.toLowerCase().includes('horizontal')) {
    return getHorizontalColorBySeriesId(seriesId);
  }
  if (rawDpName && rawDpName.toLowerCase().includes('vertical')) {
    return getVerticalColorBySeriesId(seriesId);
  }
  if (rawDpName && rawDpName.toLowerCase().includes('axial')) {
    return getAxialColorBySeriesId(seriesId);
  }
  // in case of TVE
  return getTveColorBySeriesId(seriesId);
};
// Harmonics Multiplier Constant
//
export const HARMONICS_MUlTIPLIER = 0.04;
export const HARMONICS_MUlTIPLIER_FOR_NEG_VALUE = 0.007;
