/**
 * Accordion Styles
 * @author mahesh.kedari@shorelineiot.com
 */

import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%'
    },
    accordion: {
      margin: '0.8rem 0',
      '&.Mui-expanded': {
        margin: '0.8rem 0'
      },
      background: theme.palette.common.white,
      '& .MuiPaper-root': {
        background: theme.palette.common.white
      }
    },
    expansionIcon: {
      transform: 'rotate(90deg)'
    },
    title: {
      padding: 0,
      '&.Mui-expanded': {
        height: 46,
        minHeight: 40,
        paddingTop: 10
      }
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),

      fontWeight: theme.typography.fontWeightRegular,
      flexDirection: 'row-reverse'
    },
    details: {
      padding: 8
    }
  })
);
