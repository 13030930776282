/**
 * Login Page Component
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import { Box, Typography } from '@mui/material';
import EmailForm from './login-form/EmailForm';
import { useStyles } from './loginPage.style';
import AuthHeader from '../shared/AuthHeader';
import PasswordForm from './login-form/PasswordForm';
import { ProviderState, PROVIDER_STATUS, useProviderState } from '../store';
import { AuthService } from '../../../framework/services/auth.service';
import { useLocation } from 'react-router-dom';
import { useLazyFetchIdProviderExists } from './store/idProviderHooks';

enum AUTH_STATE {
  EMAIL,
  PASSWORD
}
/**
 * Login Container
 */
const LoginPage: React.FC = () => {
  const classes = useStyles();
  const [authState, setAuthState] = useState<AUTH_STATE>(AUTH_STATE.EMAIL);
  const provider: ProviderState = useProviderState();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const paramValue = searchParams.get('identity_provider');
  const {
    fetchIdProviderExists,
    idProviderExists,
    idProviderExistsSuccess,
    idProviderExistsError
  } = useLazyFetchIdProviderExists();

  useEffect(() => {
    if (paramValue) {
      fetchIdProviderExists({ idp: paramValue.toLowerCase() }, true);
    }
  }, [paramValue, fetchIdProviderExists]);

  useEffect(() => {
    if (paramValue && idProviderExistsSuccess && idProviderExists) {
      const { success } = idProviderExists;
      const authService = new AuthService();
      authService.navigateToFederatedIdentityProvider(success);
    }
  }, [idProviderExists, idProviderExistsSuccess, paramValue]);

  useEffect(() => {
    if (provider.status === PROVIDER_STATUS.READY && provider.providerType === 'federated') {
      const authService = new AuthService();
      authService.navigateToFederatedIdentityProvider(provider.providerName || '');
    } else if (provider.status === PROVIDER_STATUS.READY && provider.providerType === 'normal') {
      setAuthState(AUTH_STATE.PASSWORD);
    }
  }, [provider.status]);

  const handleBackToEmail = () => {
    setAuthState(AUTH_STATE.EMAIL);
  };
  if (paramValue && idProviderExistsSuccess) {
    return <Typography variant="body1">Redirecting to federated identity provider...</Typography>;
  }

  //only show the login form if there is no identity provider or the given id provider is invalid
  if (!paramValue || idProviderExistsError) {
    return (
      <>
        <Container>
          <Box flexDirection="row" className={classes.root} flexGrow="1">
            <Box flexGrow="1" flexBasis="0">
              <AuthHeader
                title="Log In"
                message=""
                path=""
                buttonText=""
                translationId={'log_in'}
              />
              {authState === AUTH_STATE.EMAIL && <EmailForm />}
              {authState === AUTH_STATE.PASSWORD && (
                <PasswordForm handleBackClick={handleBackToEmail} />
              )}
            </Box>
          </Box>
        </Container>
      </>
    );
  }

  return <></>;
};

export default LoginPage;
