import { RadioClasses, Theme } from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
/**
 *
 */
export const RadioButtonStyle:
  | Partial<OverridesStyleRules<keyof RadioClasses, 'MuiRadio', Omit<Theme, 'components'>>>
  | undefined = {
  root: {
    color: blueGrey[800]
  }
};
