/**
 * Menu List Item Customization
 * @author mahesh.kedari@shorelineiot.com
 */

import { ListItemClasses, Theme } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
/**
 *
 */
export const MenuListItemStyle:
  | Partial<OverridesStyleRules<keyof ListItemClasses, 'MuiListItem', Omit<Theme, 'components'>>>
  | undefined = {
  root: {
    '&.selected': {
      backgroundColor: 'rgba(255, 255, 255, 0.3)'
    },
    '&.selected:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.4)'
    }
  }
};
