import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  info: {
    marginTop: '3%',
    marginBottom: '3%',
    marginLeft: '5%'
  },
  radioGroup: {
    marginLeft: '5%',
    marginBottom: '3%'
  }
}));
