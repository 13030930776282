/**
 *
 */
import React from 'react';
import { Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { FailureLarge, useSlugContext } from '../../../../framework';
/**
 *
 * @param props
 * @returns
 */
export const InvitationExpired = () => {
  const { slug } = useSlugContext();
  return (
    <>
      <FailureLarge />
      <Typography variant="h2">Invitation link has already been used</Typography>
      <Typography variant="h4">Please ask administrator to resend invitation.</Typography>
      <Typography>
        Navigate to &nbsp;
        <RouterLink to={`/${slug}/auth/login`}>Login</RouterLink>
      </Typography>
    </>
  );
};
