import { AnyAction } from 'redux';
import * as ACTION_TYPES from './updateNevadaNano.actionTypes';

export const updateNevadaNanoProgress = (data: any): AnyAction => {
  return {
    type: ACTION_TYPES.UPDATE_NEVADA_NANO_PROGRESS,
    payload: data
  };
};
/**
 *
 * @param data
 */
export const updateNevadaNanoSuccess = (data: any): AnyAction => {
  // returns the action type
  return {
    type: ACTION_TYPES.UPDATE_NEVADA_NANO_SUCCESS,
    data
  };
};
/**
 *
 * @param error
 */
export const updateNevadaNanoFailure = (error: any): AnyAction => {
  // returns the action type
  return {
    type: ACTION_TYPES.UPDATE_NEVADA_NANO_FAILURE,
    error
  };
};
/**
 *
 * @param data
 */

export const updateNevadaNanoReset = (): AnyAction => {
  return {
    type: ACTION_TYPES.UPDATE_NEVADA_NANO_RESET
  };
};
