/**
 * RIght Drawer Styles
 * @author mahesh.kedari@shorelineiot.com
 */
import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  drawerPaper: {
    // minWidth: '35%',
    width: '50%',
    overflowX: 'hidden',
    '@media (min-width:1920px)': {
      width: '40%'
    }
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9)
    }
  },
  drawerTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '57px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.secondary,
    padding: 15
  },
  list: {
    paddingTop: 0
  },
  textStyle: {
    color: theme.palette.background.default
  }
}));
