/**
 * Get Redirection Path
 * @author mahesh.kedari@shorelineiot.com
 */
import { generatePath } from 'react-router';
import { Group } from '../../../../features/device';

export const getRedirectionPath = (
  slug: string,
  redirection: string,
  resolver: (
    redirectionInfo: string | undefined,
    paramsInfo: string | undefined,
    rowData: any,
    chartSettings: any,
    deviceGroups?: Group[]
  ) => void,
  params?: string | undefined,
  rowData?: any,
  chartSettings?: any,
  deviceGroups?: Group[]
) => {
  switch (redirection) {
    case 'asset':
      return generatePath(
        `/${slug}/app/devices/${resolver(
          redirection,
          params,
          rowData,
          chartSettings,
          deviceGroups
        )}`
      );
    case 'device-data':
      return generatePath(
        `/${slug}/app/devices/${resolver(
          redirection,
          params,
          rowData,
          chartSettings,
          deviceGroups
        )}`
      );
    case 'datapoint':
      return generatePath(
        `/${slug}/app/devices/${resolver(
          redirection,
          params,
          rowData,
          chartSettings,
          deviceGroups
        )}`
      );
    case 'alarms':
      return generatePath(
        `/${slug}/app/alarms${resolver(redirection, params, rowData, chartSettings, deviceGroups)}`
      );
    case 'dashboard':
      return generatePath(
        `/${slug}/app/custom-dashboard/${resolver(
          redirection,
          params,
          rowData,
          chartSettings,
          deviceGroups
        )}`
      );
    case 'vibration-analysis':
      return generatePath(
        `/${slug}/app/workflow/vibration-analysis/${resolver(
          redirection,
          params,
          rowData,
          chartSettings,
          deviceGroups
        )}`
      );
    case 'activity-logs':
      return generatePath(
        `/${slug}/app/activity-logs/${resolver(
          redirection,
          params,
          rowData,
          chartSettings,
          deviceGroups
        )}`
      );
    case '':
    default:
      return '';
  }
};
