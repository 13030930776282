/**
 * Alerts Actions
 * @author shubham.holkar@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTION_TYPES from './alerts.actionTypes';
/**
 *
 */
export function getAlertsList(data: any): AnyAction {
  return {
    type: ACTION_TYPES.FETCH_LIST_PROGRESS,
    payload: data
  };
}
/**
 *
 * @param data data to be returned on successful fetch API call
 */
export function handleAlertsListSuccess(data: any): AnyAction {
  return {
    type: ACTION_TYPES.FETCH_LIST_SUCCESSS,
    payload: data
  };
}
/**
 *
 */
export function handleAlertsListFailure(error: any): AnyAction {
  return {
    type: ACTION_TYPES.FETCH_LIST_FAILURE,
    payload: error
  };
}
export function resetAlertsList(): AnyAction {
  return {
    type: ACTION_TYPES.RESET_ALERTS_STATE
  };
}

export function handleUpdateNBAlertsFilter(data: any): AnyAction {
  return {
    type: ACTION_TYPES.UPDATE_NBALERTS_FILTER,
    payload: data
  };
}

export const fetchAlertsLevelSuccess = (data: any): AnyAction => {
  return {
    type: ACTION_TYPES.FETCH_ALERTS_LEVEL_SUCCESS,
    payload: data
  };
};
