/**
 * Narrowband Action Types
 * @author mahesh.kedari@shorelineiot.com
 */
export const FETCH_NB_RULE = 'FEATURES/DEVICE/DEVICE_DETAILS/ADDITIONAL_SENSORS/NB/FETCH_RULE';
export const FETCH_NB_RULE_SUCCESS =
  'FEATURES/DEVICE/DEVICE_DETAILS/ADDITIONAL_SENSORS/NB/FETCH_RULE/SUCCESS';
export const PERSIST_NB_CONFIG = 'FEATURES/DEVICE/DEVICE_DETAILS/ADDITIONAL_SENSORS/NB/PERSIST';
export const PERSIST_NB_RULE_CONDITIONS =
  'FEATURES/DEVICE/DEVICE_DETAILS/ADDITIONAL_SENSORS/NB/RULE/CONDITIONS/PERSIST';
export const PERSIST_NB_RULE_ACTIONS_AND_SUBMIT =
  'FEATURES/DEVICE/DEVICE_DETAILS/ADDITIONAL_SENSORS/NB/RULE/ACTIONS/SUBMIT';
export const PERSIST_NB_RULE_ACTIONS =
  'FEATURES/DEVICE/DEVICE_DETAILS/ADDITIONAL_SENSORS/NB/RULE/ACTIONS/PERSIST';
export const SUBMIT_NB = 'FEATURES/DEVICE/DEVICE_DETAILS/ADDITIONAL_SENSORS/NB/CREATE';
export const NB_NO_CHANGE = 'FEATURES/DEVICE/DEVICE_DETAILS/ADDITIONAL_SENSORS/NB/SUCCESS';
export const NB_IN_PROGRESS = 'FEATURES/DEVICE/DEVICE_DETAILS/ADDITIONAL_SENSORS/NB/SUCCESS';
export const NB_UPDATING = 'FEATURES/DEVICE/DEVICE_DETAILS/ADDITIONAL_SENSORS/NB/SUCCESS';
export const NB_SUCCESS = 'FEATURES/DEVICE/DEVICE_DETAILS/ADDITIONAL_SENSORS/NB/SUCCESS';
export const NB_FAILURE = 'FEATURES/DEVICE/DEVICE_DETAILS/ADDITIONAL_SENSORS/NB/FAILURE';
export const UPDATE_FORM_STEP = 'FEATURES/DEVICE/DEVICE_DETAILS/ADDITIONAL_SENSORS/NB/UPDATE_STEP';
export const RESET_NB_CONFIG = 'FEATURES/DEVICE/DEVICE_DETAILS/ADDITIONAL_SENSORS/NB/RESET';
export const CREATE_MANUAL_NB =
  'FEATURES/DEVICE/DEVICE_DETAILS/ADDITIONAL_SENSORS/NB/CREATE_MANUAL_NB';
export const UPDATE_MANUAL_NB =
  'FEATURES/DEVICE/DEVICE_DETAILS/ADDITIONAL_SENSORS/NB/UPDATE_MANUAL_NB';
