import { LoginStateType } from '../types/LoginStateType';
/**
 *
 */
export function onResendVerificationMail(state: LoginStateType): LoginStateType {
  return {
    ...state,
    status: 'VERIFICATION_MAIL_SENT'
  };
}
