import { AnyAction } from 'redux';
import * as ACTIONS from './datapoints.actionTypes';
import { ThunkDispatch } from '@reduxjs/toolkit';

export interface GetOnlyDataPointsSagaActionPayload {
  deviceId: number;
  slug: string;
  /**
   * This is being used to add a `datapoints` key in a particular device
   * in case of Anomaly config workflow.
   */
  dispatch?: ThunkDispatch<any, any, AnyAction>;
}

/**
 * Datapoints Actions
 */
export const fetchDatapoints = (data: any): AnyAction => {
  return {
    type: ACTIONS.FETCH_DATAPOINTS,
    payload: data
  };
};

export const fetchOnlyDatapoints = (data: GetOnlyDataPointsSagaActionPayload): AnyAction => {
  return {
    type: ACTIONS.FETCH_ONLY_DATAPOINTS,
    payload: data
  };
};

export const datapointsSuccess = (data: any): AnyAction => {
  return {
    type: ACTIONS.DATAPOINTS_SUCCESS,
    payload: data
  };
};

export const datapointsFailure = (): AnyAction => {
  return {
    type: ACTIONS.DATAPOINTS_FAILURE
  };
};

export const resetDatapoints = (): AnyAction => {
  return {
    type: ACTIONS.RESET_DATAPOINTS
  };
};

export const setFetchDatapointsAgain = (data: any): AnyAction => {
  return {
    type: ACTIONS.FETCH_DATAPOINTS_AGAIN,

    payload: data
  };
};
/**
 *
 */
export const fetchChartConfig = (data: any): AnyAction => {
  return {
    type: ACTIONS.FETCH_VIEW_CONFIG,
    payload: data
  };
};

export const chartConfigSuccess = (data: any): AnyAction => {
  return {
    type: ACTIONS.VIEW_CONFIG_SUCCESS,
    payload: data
  };
};

export const chartConfigFailure = (data: any): AnyAction => {
  return {
    type: ACTIONS.VIEW_CONFIG_FAILURE,
    payload: data
  };
};

export const resetChartConfig = (data: any): AnyAction => {
  return {
    type: ACTIONS.RESET_VIEW_CONFIG,
    payload: data
  };
};

/**
 * Set Chart Config
 */
export const setChartConfig = (data: any): AnyAction => {
  return {
    type: ACTIONS.SET_VIEW_CONFIG,
    payload: data
  };
};

export const setChartConfigSuccess = (data: any): AnyAction => {
  return {
    type: ACTIONS.SET_VIEW_CONFIG_SUCCESS,
    payload: data
  };
};

export const setChartConfigFailure = (data: any): AnyAction => {
  return {
    type: ACTIONS.SET_VIEW_CONFIG_FAILURE,
    payload: data
  };
};

/**
 * Fetch Topic for specific Datapoint
 */
export const fetchTopic = (data: any): AnyAction => {
  return {
    type: ACTIONS.FETCH_TOPIC,
    payload: data
  };
};

export const fetchAllTopics = (
  dpids: Array<string | number>,
  deviceId: string,
  slug = ''
): AnyAction => {
  return {
    type: ACTIONS.FETCH_ALL_TOPICS,
    payload: { dpids, deviceId, slug }
  };
};

export const fetchTopicSuccess = (data: any): AnyAction => {
  return {
    type: ACTIONS.TOPIC_SUCCESS,
    payload: data
  };
};

export const fetchTopicFailure = (): AnyAction => {
  return {
    type: ACTIONS.TOPIC_FAILURE
  };
};

export const closeTopic = (data: any): AnyAction => {
  return {
    type: ACTIONS.TOPIC_CLOSE,
    payload: data
  };
};
/**
 * Fetch Data for datapoint
 */
export const fetchDataProgress = (data: any): AnyAction => {
  return {
    type: ACTIONS.FETCH_DATA_PROGRESS,
    payload: data
  };
};

export const fetchDataSuccess = (data: any): AnyAction => {
  return {
    type: ACTIONS.FETCH_DATA_SUCCESS,
    payload: data
  };
};

export const fetchDataFailure = (data: any): AnyAction => {
  return {
    type: ACTIONS.FETCH_DATA_FAILURE,
    payload: data
  };
};

export const resetData = (): AnyAction => {
  return {
    type: ACTIONS.FETCH_DATA_RESET
  };
};

export const setData = (data: any): AnyAction => {
  return {
    type: ACTIONS.SET_DATA,
    payload: data
  };
};

export const setDataSuccess = (data: any): AnyAction => {
  return {
    type: ACTIONS.SET_DATA_SUCCESS,
    payload: data
  };
};
/**
 *
 *
 */
export const setDataFailure = (error: any): AnyAction => {
  return {
    type: ACTIONS.SET_DATA_FAILURE,
    payload: error
  };
};

/**
 *
 */
export const setLastDataValue = (payload: any): AnyAction => {
  return {
    type: ACTIONS.SET_LAST_DATA_VALUE,
    payload
  };
};
/**
 *
 */
export const removeDatapoint = (payload: any): AnyAction => {
  return {
    type: ACTIONS.REMOVE_DATAPOINT,
    payload
  };
};
/**
 *
 */
export const removeDatapointSuccess = (payload: any): AnyAction => {
  return {
    type: ACTIONS.REMOVE_DATAPOINT_SUCCESS,
    payload
  };
};
/**
 *
 */
export const removeDatapointFailure = (error: any): AnyAction => {
  return {
    type: ACTIONS.REMOVE_DATAPOINT_FAILURE,
    error
  };
};

export const hideDatapointSuccess = (payload: any): AnyAction => {
  return {
    type: ACTIONS.HIDE_DATAPOINT_SUCCESS,
    payload
  };
};

export const unhideDatapointSuccess = (payload: any): AnyAction => {
  return {
    type: ACTIONS.UNHIDE_DATAPOINT_SUCCESS,
    payload
  };
};
