import React from 'react';
import { LightTooltip } from '../tooltip/Tooltip';
import { useStyles } from './MultiselectDropdown.styles';
import { Box } from '@mui/material';

interface TagProps {
  key?: React.Key;
  value?: string | number;
  label?: React.ReactNode;
  title?: React.ReactNode;
  disabled?: boolean;
}

interface PlaceholderProps {
  placeholder: TagProps[];
  isTitle?: boolean;
}

function TooltipTitle({ placeholder, isTitle }: PlaceholderProps) {
  const classes = useStyles();
  return (
    <div className={classes.tooltip}>
      {placeholder.map((item: TagProps, index: number) => (
        <div key={index}>{isTitle ? item.label : item.value}</div>
      ))}
    </div>
  );
}

export default function MaxTagPlaceholder({ placeholder, isTitle }: PlaceholderProps) {
  return (
    <>
      <LightTooltip title={<TooltipTitle placeholder={placeholder} isTitle={isTitle} />}>
        <Box>+{placeholder.length}...</Box>
      </LightTooltip>
    </>
  );
}
