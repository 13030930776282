/**
 * Chart 3d configurations Options
 * @author mahesh.kedari@shorleineiot.com
 */
import React, { ReactElement } from 'react';
import Grid from '@mui/material/Grid';
import { SLCheckbox, SLSlider, SLDivider } from '../../../..';
import TranslateText from '../../../../../../i18n/TranslateText';

interface Props {
  prefix?: string;
  currentValue: any;
  handleUpdate: () => void;
}

export default function Options3dConfigs({
  prefix = 'options3d',
  currentValue,
  handleUpdate
}: Props): ReactElement {
  return (
    <>
      <SLDivider textAlign="center">{TranslateText('configs_3d', '3D configs')}</SLDivider>
      <Grid container justifyContent="flex-start" spacing={2} alignItems="flex-end">
        <Grid item xs={6}>
          <SLCheckbox
            name={`${prefix}.enabled`}
            label="Enable 3d"
            labelTranslationId="enable_3d"
            onChange={handleUpdate}
            value={currentValue?.[prefix]?.enabled}
            checked={currentValue?.[prefix]?.enabled || false}
          />
        </Grid>
        <Grid item xs={6}>
          {/* Filler */}
        </Grid>
        <Grid item xs={4}>
          <SLSlider
            defaultValue={15}
            name={`${prefix}.alpha`}
            disabled={!currentValue?.[prefix]?.enabled}
            min={0}
            max={45}
            label="Alpha"
            onSliderChange={handleUpdate}
          />
        </Grid>
        <Grid item xs={4}>
          <SLSlider
            defaultValue={15}
            name={`${prefix}.beta`}
            disabled={!currentValue?.[prefix]?.enabled}
            min={-45}
            max={45}
            label="Beta"
            onSliderChange={handleUpdate}
          />
        </Grid>
        <Grid item xs={4}>
          <SLSlider
            name={`${prefix}.depth`}
            disabled={!currentValue?.[prefix]?.enabled}
            defaultValue={50}
            min={0}
            max={100}
            label="Depth"
            onSliderChange={handleUpdate}
          />
        </Grid>
      </Grid>
    </>
  );
}
