/**
 * Device Photos Action Types
 * @author ashwini.gawade@shorelineiot.com
 */
// LOAD PHOTOS
export const LOAD_PHOTOS_FAILURE = 'FEATURES/DEVICE/DEVICE_SETTINGS/LOAD_PHOTOS_FAILURE';
export const RESET_LOAD_PHOTOS = 'FEATURES/DEVICE/DEVICE_SETTINGS/RESET_LOAD_PHOTOS';

// DELETE PHOTOS
export const DELETE_PHOTO = 'FEATURES/DEVICE/DEVICE_SETTINGS/DELETE_PHOTO';
export const DELETE_PHOTO_SUCCESS = 'FEATURES/DEVICE/DEVICE_SETTINGS/DELETE_PHOTO_SUCCESS';
export const DELETE_PHOTO_FAILURE = 'FEATURES/DEVICE/DEVICE_SETTINGS/DELETE_PHOTO_FAILURE';

// LOAD MULTIPLE DEVICE PHOTOS
export const LOAD_MULTIPLE_DEVICE_PHOTOS =
  'FEATURES/DEVICE/DEVICE_SETTINGS/LOAD_MULTIPLE_DEVICE_PHOTOS';
export const LOAD__MULTIPLE_DEVICE_PHOTOS_SUCCESS =
  'FEATURES/DEVICE/DEVICE_SETTINGS/LOAD__MULTIPLE_DEVICE_PHOTOS_SUCCESS';
export const LOAD__MULTIPLE_DEVICE_PHOTOS_FAILURE =
  'FEATURES/DEVICE/DEVICE_SETTINGS/LOAD__MULTIPLE_DEVICE_PHOTOS_FAILURE';
export const RESET__MULTIPLE_DEVICE__PHOTOS = 'FEATURES/DEVICE/DEVICE_SETTINGS/RESET_LOAD_PHOTOS';
