/**
 * Create org saga
 * @author ashwini.gawade@shorelineiot.com
 */
import { takeEvery, call, put } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import * as ACTIONS from './actions/subOrg.actionType';
import * as actions from './actions/subOrg.actions';
import * as orgActions from '../../org-list/actions/orgList.actions';
import { CreateSubOrg } from '../../types/subOrg';
import { httpGet, httpPost, SafeSaga, showToast, APISERVICES } from '../../../../../framework';
import { SagaIterator } from 'redux-saga';

const SUB_ORG_CREATE_ACTION_PROCESS_ID = 'SUB_ORG_CREATE_ACTION_PROCESS_ID';

/**
 *
 * @param suborganisation SubOrganisation to be created
 */
function createSubOrganisation(suborganisation: CreateSubOrg) {
  const subOrg = {
    name: suborganisation.subOrgName,
    slug: suborganisation.subOrgSlug
  };
  const { orgSlug } = suborganisation;
  return httpPost(`orgs/${orgSlug}/suborgs`, subOrg, APISERVICES.HOST_API);
}

function validateSubOrgShortName(suborganisation: CreateSubOrg) {
  return httpGet(`orgs/${suborganisation?.subOrgSlug}/exists`, null, APISERVICES.HOST_API);
}
/**
 *
 * @param action Action to be performed
 */
export function* createSubOrg(action: AnyAction) {
  const subOrg = action.payload;
  const shortNameResponse: { status: boolean } = yield call(validateSubOrgShortName, subOrg);
  if (shortNameResponse?.status === false) {
    // Request for sub org creation if shortname does not exists
    yield call(createSubOrganisation, subOrg);
    yield put(actions.createSubOrgSuccess());
    yield put(showToast('Sub-Organization created successfully!', 'success'));
    yield put(orgActions.fetchOrgList());
  } else {
    yield put(actions.createSubOrgFailure('Short name is already used'));
    yield put(showToast('Short name is already used', 'error'));
  }
}

function* errorHandler(error: any) {
  yield put(actions.createSubOrgFailure(error));
  yield put(showToast('Failed to create sub org', 'error'));
}
/**
 *
 */
export function* watchCreateSubOrgRequest(): SagaIterator {
  yield takeEvery(
    ACTIONS.SUB_ORG_CREATE,
    SafeSaga(createSubOrg, SUB_ORG_CREATE_ACTION_PROCESS_ID, errorHandler)
  );
}

/* const subOrgSaga = [fork(watchCreateSubOrgRequest)];
export default subOrgSaga;
 */
