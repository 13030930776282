/**
 * Fetch Topic for notifications
 */
export const FETCH_NOTIFICATION_TOPIC = 'FEATURES/NOTIFICATIONS/FETCH_NOTIFICATION_TOPIC';
export const FETCH_NOTIFICATION_TOPIC_SUCCESS =
  'FEATURES/NOTIFICATIONS/FETCH_NOTIFICATION_TOPIC_SUCCESS';
export const FETCH_NOTIFICATION_TOPIC_FAILURE =
  'FEATURES/NOTIFICATIONS/FETCH_NOTIFICATION_TOPIC_FAILURE';
export const CLOSE_NOTIFICATION_TOPIC = 'FEATURES/NOTIFICATIONS/CLOSE_NOTIFICATION_TOPIC';

/**
 * Fetch historical/existing notifications
 */
export const FETCH_NOTIFICATIONS = 'FEATURES/NOTIFICATIONS/FETCH_NOTIFICATIONS';
export const FETCH_NOTIFICATIONS_SUCCESS = 'FEATURES/NOTIFICATIONS/FETCH_NOTIFICATIONS_SUCCESS';
export const FETCH_NOTIFICATIONS_FAILURE = 'FEATURES/NOTIFICATIONS/FETCH_NOTIFICATIONS_FAILURE';
export const RESET_NOTIFICATIONS = 'FEATURES/NOTIFICATIONS/RESET_NOTIFICATIONS';

/**
 * Fetch live/new notifications
 */
export const SET_LAST_NOTIFICATION = 'FEATURES/NOTIFICATIONS/SET_LAST_NOTIFICATION';
/* export const SET_LAST_NOTIFICATION_SUCCESS =
  'FEATURES/NOTIFICATIONS/SET_LAST_NOTIFICATION_SUCCESS';
export const SET_LAST_NOTIFICATION_FAILURE =
  'FEATURES/NOTIFICATIONS/SET_LAST_NOTIFICATION_FAILURE'; */
