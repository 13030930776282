/**
 * Menu Link
 * @author mahesh.kedari@shorelineiot.com
 */
import React, {
  ReactElement,
  ReactNodeArray,
  ReactNode,
  useEffect,
  useRef,
  useLayoutEffect
} from 'react';
import { Link, useMatch } from 'react-router-dom';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { ArrowDropUp, ArrowDropDown } from '@mui/icons-material';
import { Collapse, List } from '@mui/material';
import { useStyles } from '../sidebarItems.styles';
import TranslateText from '../../../../../i18n/TranslateText';
import { LightTooltip } from '../../../tooltip/Tooltip';
import ListItemButton from '@mui/material/ListItemButton';
import { useDispatch } from 'react-redux';
import { resetBreadcrumb } from '../../../breadcrumb';
/**
 *
 */
export interface MenuLinkProps {
  route: string;
  textKey: string;
  defaultText: string;
  icon?: ReactElement;
  children?: ReactNode | ReactNodeArray;
  testID?: string;
  /**
   * Use this prop to expand the sidebar by default.
   * @default false
   */
  expandSidebar?: boolean;
}
/**
 *
 * @param props Menu Link Props
 */
const MenuLink = ({
  route,
  defaultText,
  icon,
  children,
  textKey,
  testID,
  expandSidebar = false
}: MenuLinkProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(expandSidebar);
  const [enableTooltip, setEnableTooltip] = React.useState(false);
  const defaultTextRef = useRef<any>(null);

  const handleClick = () => {
    setOpen(!open);
  };
  const match: any = useMatch(route.split('?')[0]);
  const isSelected = !children ? Boolean(match) : match?.isExact;
  const ExpansionIcon = () => {
    if (!children) {
      return null;
    }
    if (open) return <ArrowDropUp className={classes.listIcon} onClick={handleClick} />;
    return <ArrowDropDown className={classes.listIcon} onClick={handleClick} />;
  };

  useEffect(() => {
    setOpen(expandSidebar);
  }, [expandSidebar]);

  useLayoutEffect(() => {
    if (defaultTextRef.current !== null) {
      const node = defaultTextRef.current;
      if (node?.scrollWidth > node?.clientWidth) {
        setEnableTooltip(true);
      }
    }
  });

  const ListItemView = () => {
    return (
      <ListItemButton
        id={`${testID}`}
        className={classes.Menu}
        selected={isSelected}
        disableRipple
        onClick={handleClick}>
        {icon && (
          <ListItemIcon className={classes.listIcon}>
            <icon.type {...icon?.props} />
          </ListItemIcon>
        )}
        <LightTooltip
          disableHoverListener={!enableTooltip}
          title={TranslateText(textKey, defaultText)}
          placement="right">
          <ListItemText className={classes.listText}>
            <div ref={defaultTextRef} className={classes.defaultText}>
              {TranslateText(textKey, defaultText)}
            </div>
          </ListItemText>
        </LightTooltip>
        <ExpansionIcon />
      </ListItemButton>
    );
  };

  return (
    <>
      {children ? (
        <ListItemView />
      ) : (
        <Link to={route} onClick={() => dispatch(resetBreadcrumb())} className={classes.routeLinks}>
          <ListItemView />
        </Link>
      )}
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List id={`${testID}`} component="div" className={classes.subMenu}>
          {children}
        </List>
      </Collapse>
    </>
  );
};

export default MenuLink;
