export interface Assets {
  name: string;
  serial_number: string;
  component_class: string;
  component_subclass: string;
  asset_seq_id: number;
  asset_type: string;
  manufacturer: string;
  model: string;
  mount_points: any;
  newAsset?: boolean;
  index: number;
}

export interface CreatePowertrainForm {
  name: string;
  rpm_min: number;
  rpm_max: number;
  assets: Array<Assets>;
  cpm: any;
  cpm_device_id: any;
  cpm_global_dpid: any;
}
export const createPowertrainDefaults: CreatePowertrainForm = {
  name: '',
  rpm_min: 0,
  rpm_max: 0,
  cpm: undefined,
  cpm_device_id: undefined,
  cpm_global_dpid: undefined,
  assets: [
    {
      name: '',
      newAsset: true,
      serial_number: '',
      index: 0,
      component_class: '',
      component_subclass: '',
      asset_seq_id: 1,
      asset_type: 'prime_mover',
      manufacturer: '',
      model: '',
      mount_points: {}
    }
  ]
};

export type SetPowertrainModeType = 'ADD' | 'EDIT' | 'COPY' | 'DELETE';
