export enum DEVICE_REPORTS_STATUS {
  INIT,
  FETCHING,
  READY,
  ERROR
}

export interface DeviceSummaryObj {
  observation_id: number;
  device_id: number;
  device_name: string;
  cloud_update_interval_ts: string;
  percent_batt_used: number;
  daily_batt_used: number;
  firmware_version: string;
  serial_number: string;
  last_connected_ts: number;
  suborg_uuid: string;
  total_actual_count: number;
  total_expected_count: number;
  datapoint_count_list: {
    sensor_name: string;
    actual_count: number;
    expected_count: number;
    min_value: number;
    max_value: number;
  }[];
  observation_type: string[];
  siteName: string;
}

export interface DeviceReportsObj {
  device_summary: DeviceSummaryObj[];
}

export interface DeviceReportsState {
  status: DEVICE_REPORTS_STATUS;
  DeviceReportsData: DeviceReportsObj;
}
