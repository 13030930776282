/**
 * Help Component
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { useEffect } from 'react';
// import { ZendeskAPI } from 'react-zendesk';
import Link from '@mui/material/Link';
import { Typography } from '@mui/material';
import { useStyles } from './help.styles';
import {
  VITE_HELPDESK_URL_GOOGLE_SSO,
  VITE_HELPDESK_URL_DCP_SSO,
  VITE_HELPDESK_URL_GE_SSO,
  VITE_HELPDESK_URL_NON_SSO
} from '../../../services/envConstants';
import {
  AuthProviderActions,
  ProviderState,
  PROVIDER_STATUS,
  useProviderState
} from '../../../../features/auth/store';
import { useUserSelf } from '../../../../features/user-settings/store';
import { useDispatch } from 'react-redux';
import TranslateText from '../../../../i18n/TranslateText';

export default function Help() {
  const userSelf = useUserSelf();
  const providerState: ProviderState = useProviderState();
  const domain = userSelf.self.email?.split('@')?.[1];
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    if (providerState.status === PROVIDER_STATUS.INIT && domain) {
      dispatch(AuthProviderActions.getProviderForDomain(domain));
    }
  }, [providerState.status, userSelf.status]);

  function handleHelpClick() {
    /* ZendeskAPI('webWidget', 'show');
    ZendeskAPI('webWidget', 'open'); */
    if (providerState.providerName == 'Google') {
      window.open(VITE_HELPDESK_URL_GOOGLE_SSO, '_blank');
    } else if (providerState.providerName == 'dcpmidstream') {
      window.open(VITE_HELPDESK_URL_DCP_SSO, '_blank');
    } else if (providerState.providerName == 'ge-appliances') {
      window.open(VITE_HELPDESK_URL_GE_SSO, '_blank');
    } else {
      window.open(VITE_HELPDESK_URL_NON_SSO, '_blank');
    }
  }

  /* ZendeskAPI('webWidget:on', 'close', () => {
    ZendeskAPI('webWidget', 'hide');
  }); */

  return (
    <Link className={classes.helpLink} component="button" onClick={handleHelpClick} variant="body2">
      <Typography variant="body2">{TranslateText('help', 'Help')}</Typography>
    </Link>
  );
}
