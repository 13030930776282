/**
 * @author swapnil.kshirsagar@shorelineiot.com
 */

import { AnyAction } from 'redux';
import * as ACTIONS from './register.actionTypes';
// TODO: Update sign-up payload datatype
export const register = (data: any): AnyAction => {
  return {
    type: ACTIONS.SIGNUP_PROGERSS,
    payload: data
  };
};

// TODO: Update Success payload datatype
export const registerSuccess = (data: any): AnyAction => {
  return {
    type: ACTIONS.SIGNUP_SUCCESS,
    payload: data
  };
};

// TODO: Update error payload datatype
export const registerFailure = (error: any): AnyAction => {
  return {
    type: ACTIONS.SIGNUP_FAILURE,
    payload: error
  };
};

export const registerWithGoogle = (): AnyAction => {
  return {
    type: ACTIONS.REGISTER_WITH_GOOGLE
  };
};

export const registerWithLinkedIn = (): AnyAction => {
  return {
    type: ACTIONS.REGISTER_WITH_LINKED_IN
  };
};

export const onVerificationPageLoad = (): AnyAction => {
  return {
    type: ACTIONS.ON_VERIFICATION_PAGE
  };
};
/**
 *
 * @param data Email and code
 */
export const onConfirmUser = (data: { email: string; code: string }): AnyAction => {
  return {
    type: ACTIONS.CONFIRM_USER,
    payload: data
  };
};

export const onConfirmUserSuccess = () => {
  return {
    type: ACTIONS.CONFIRM_USER_SUCCESS
  };
};

export const onConfirmUserFailure = (error: any) => {
  return {
    type: ACTIONS.CONFIRM_USER_FAILURE,
    error
  };
};

export const resetToRegister = () => {
  return {
    type: ACTIONS.RESET_REGISTER_STATE
  };
};
