/**
 * Component List Actions
 * @author mahesh.kedari@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTIONS from './componentList.actionTypes';

export const fetchComponentList = (): AnyAction => ({
  type: ACTIONS.FETCH_COMPONENT_LIST
});

export const fetchComponentListSuccess = (payload: any): AnyAction => ({
  type: ACTIONS.FETCH_COMPONENT_LIST_SUCCESS,
  payload
});

export const fetchComponentListFailure = (error: any) => ({
  type: ACTIONS.FETCH_COMPONENT_LIST_FAILURE,
  error
});

export const resetComponentList = (): AnyAction => ({
  type: ACTIONS.RESET_COMPONENT_LIST
});

export const deleteComponent = (id: string | number): AnyAction => ({
  type: ACTIONS.DELETE_COMPONENT,
  payload: { id }
});

export const deleteComponentSuccess = (payload: any): AnyAction => ({
  type: ACTIONS.DELETE_COMPONENT_SUCCESS,
  payload
});

export const deleteComponentFailure = (error: any): AnyAction => ({
  type: ACTIONS.DELETE_COMPONENT_FAILURE,
  error
});
