/**
 * update multiple dashbaord items
 */
export enum UpdateMultipleDashboardItemsStatus {
  INIT,
  FETCHING,
  READY,
  ERROR,
  RESET
}
export interface UpdateMultipleDashboardItemsState {
  status: UpdateMultipleDashboardItemsStatus;
  updateMultipleDashboardItemsData: any;
}
