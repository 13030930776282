/**
 * Device Mailbox Delete Action Types
 * @author prerna.more@shorelineiot.com
 */
export const DELETE_DEVICE_MAILBOX_PROGRESS =
  'FEATURES/REPORTS/DEVICE_MAILBOX/DELETE_DEVICE_MAILBOX/DELETE_DEVICE_MAILBOX_PROGRESS';
export const DELETE_DEVICE_MAILBOX_SUCCESS =
  'FEATURES/REPORTS/DEVICE_MAILBOX/DELETE_DEVICE_MAILBOX/DELETE_DEVICE_MAILBOX_SUCCESS';
export const DELETE_DEVICE_MAILBOX_FAILURE =
  'FEATURES/REPORTS/DEVICE_MAILBOX/DELETE_DEVICE_MAILBOX/DELETE_DEVICE_MAILBOX_FAILURE';
export const DELETE_DEVICE_MAILBOX_RESET_STATE =
  'FEATURES/REPORTS/DEVICE_MAILBOX/DELETE_DEVICE_MAILBOX/DELETE_DEVICE_MAILBOX_RESET_STATE';
