/**
 * Name Splitter Utility Function
 * @author mahesh.kedari@shorelineiot.com
 * @param name to be split
 */
export function nameSplitter(name = '') {
  const fullName = name.split(' ');
  let givenName;
  let familyName;
  if (fullName.length > 1) {
    familyName = fullName.pop();
    givenName = fullName.join(' ');
  } else {
    givenName = fullName.pop();
    familyName = '';
  }
  return [givenName, familyName];
}
