/**
 * Toast Template
 */
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
/**
 *
 */
export const useStyles = makeStyles((theme: Theme) => ({
  card: {
    width: '25vw',
    borderWidth: 1,
    borderStyle: 'solid',
    borderLeftWidth: 20,
    whiteSpace: 'initial'
  },
  actionRoot: {
    padding: '8px 8px 8px 16px'
  },
  title: {
    textTransform: 'capitalize'
  },
  success: {
    color: theme.palette.success.main,
    borderColor: theme.palette.success.main
  },
  error: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main
  },
  warning: {
    color: theme.palette.warning.main,
    borderColor: theme.palette.warning.main
  },
  info: {
    color: theme.palette.info.main,
    borderColor: theme.palette.info.main
  },
  icons: {
    marginLeft: 'auto'
  },
  close: {
    transform: 'rotate(0deg)',
    width: '8.5px',
    height: '8.5px',
    padding: 0
  },
  closeIcon: {
    fontSize: '10px'
  },
  message: {
    color: theme.palette.text.primary
  }
}));
