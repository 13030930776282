/**
 * Tooltip Formatter
 * @author mahesh.kedari@shorelineiot.com
 */
import moment from 'moment';
import { setDatapointValueResolution } from './setDatapointValueResolution';

export function TooltipFormatter(this: any): string {
  let formattedString = '<table>';
  const elem = this.point;
  const microSec = elem.x % 1000;
  let microSecStr = '0';
  let yAxisDatapoint = 0;
  const sensorType = this?.series?.chart?.sensor_type;
  if (microSec !== 0) {
    microSecStr = microSec.toString()[0];
  }
  if (elem.y) {
    yAxisDatapoint = setDatapointValueResolution(sensorType, elem.y);
  }

  const currentTime = new Date(elem.x / 1000);
  formattedString += `<tr><td style="color: {series.color}">Value: </td>`;
  formattedString += `<td style="text-align: right"><b>${yAxisDatapoint}</b></td></tr>`;
  formattedString += `<tr><td>ts:</td><td style="text-align: right"><b>${moment(currentTime).format(
    `MMM DD, YYYY hh:mm:ss.SSS${microSecStr} A`
  )}</b></td></tr></table>`;
  return formattedString;
}
