import {
  APISERVICES,
  fileUploadAxiosPut,
  handleError,
  httpDelete,
  httpGet,
  httpPost,
  removeProgressFor,
  showProgressFor,
  showToast
} from '../../../../framework';
import { DEVICE_CONSTANTS } from '../../../common/constants';
import {
  DEVICE_TAG,
  FetchDeviceDatapointsArgs,
  FetchDeviceEventsTrendArgs,
  FetchOrbitPlotDataArgs,
  FetchStandaloneDevicesArgs,
  GetDevicesArgs,
  GetDevicesByFirmwareIdArgs,
  FetchDeviceHistoricalDataWithoutSamplingArgs,
  FetchDeviceWidgetSettingArgs,
  FetchDeviceArgs,
  FetchDeviceHistoricalDataWithoutSamplingQueryParams,
  GetDeviceTypesArgs,
  DeviceSensorTemplate,
  FetchDeviceProfilesDetailsByDeviceProfileIdArgs,
  FetchDeviceProfilesDetailsByCompatibleDeviceTypeArgs,
  FetchDeviceSensorTemplatesArgs,
  CreateDeviceArgs,
  CreateDeviceApiQueryParams,
  FetchPowertrainComponentsArgs,
  FetchPowertrainDetailArgs,
  UpdatePowertrainArgs,
  CreatePowertrainArgs,
  DeletePowertrainArgs,
  CreateGroupArgs,
  EditGroupArgs,
  EditGroupQueryParams,
  DeleteGroupArgs,
  MoveGroupToGroupArgs,
  MoveGroupToGroupQueryParams,
  MoveDeviceToGroupArgs,
  MoveDeviceToGroupQueryParams,
  FetchMultipleDevicePhotosArgs,
  GetDeviceProfilesArgs,
  TEMPLATE_ID,
  SENSOR_NAME,
  FetchDeviceSensorTemplatesInternalApiArgs,
  UpdateDeviceProfileArgs,
  DeleteDevicePhotoArgs,
  DeleteDevicePhotoPayload,
  ReplaceDevicePhotoArgs,
  UploadDevicePhotoResponse,
  UploadDevicePhotoArgs,
  CustomFile,
  NewImageMetadata,
  DeleteDeviceArgs,
  UpdateDeviceSimSettingsArgs,
  UpdateDeviceSettingsArgs,
  DeviceDetail,
  UpdateDeviceFirmwareArgs,
  RebootDeviceArgs,
  FetchCategoriesArgs,
  FetchAdditionalSensorsArgs,
  CreateDeviceWidgetSettingPayload,
  CreateDeviceWidgetsSettingArgs,
  UpdateDeviceWidgetSettingArgs,
  UpdateDeviceWidgetSettingPayload,
  FetchDeviceGroupsArgs,
  FetchRawDatapointsByDeviceIdsArgs,
  FetchHistoricalTrendDataArgs,
  FetchMultipleDeviceDatapointsArgs,
  FetchBatteryStatusArgs,
  FetchAnomalyListQueryArgs,
  FetchLatestPowetrainConfigArgs
} from '../device.types';
import { buildQuery } from '../../../../framework/lib/rtk/RTK.utils';
import { HTTP_METHOD } from '../../../../framework/services/auth.service';
import moment from 'moment-timezone';
import { ThunkDispatch } from '@reduxjs/toolkit';
import Bugsnag from '@bugsnag/js';
import { PowertrainApiPayload } from '../../device-list/create-device/create-powertrain/powertrain.types';
import {
  MergedFetchGenericDashboardArgs,
  GenericDashboardType,
  FetchGenericDashboardQueryParams,
  DASHBOARD_TYPE,
  FetchAssetWidgetDataArgs,
  FetchImageWidgetDataArgs,
  FetchAssetWidgetDataQueryParams,
  FetchSensorWidgetDataArgs,
  FetchSensorWidgetDataQueryParams,
  UpdateSensorWidgetDataArgs,
  UpdateSensorWidgetDataQueryParams
} from '../types/genericDashboard.types';
import axios from 'axios';
import { VITE_ASSET_META_BUCKET_URL } from '../../../../framework/services/envConstants';
import CryptoJS from 'crypto-js';
import { deviceApi } from '../device.api';

export function buildDeviceApiEndpoint(slug: string, endpoint: string) {
  return `orgs/${slug}/devices${endpoint}` as const;
}

export function buildDatapointsApiEndpoint(slug: string, deviceId: number, endpoint: string) {
  return `orgs/${slug}/devices/${deviceId}/datapoints${endpoint}` as const;
}
export function buildMultiDeviceDatapointsApiEndpoint(
  slug: string,
  deviceIds: Array<number>,
  vibrationAnalysis: boolean
) {
  return `orgs/${slug}/devices/datapoints?device_ids=${deviceIds.join(
    ','
  )}&vibration_analysis=${vibrationAnalysis}` as const;
}
export function buildDeviceGroupApiEndpoint(slug: string) {
  return `orgs/${slug}/device_groups` as const;
}

export function buildDeviceTypesApiEndpoint(slug: string) {
  return `orgs/${slug}/device_type` as const;
}

export function buildDeviceProfilesApiEndpoint(slug: string) {
  return `orgs/${slug}/device_profiles` as const;
}

export function buildDevicePhotoApiEndpoint({ slug, deviceId }: DeleteDevicePhotoArgs) {
  return `orgs/${slug}/devices/${deviceId}/device_photos_meta` as const;
}

export function buildCategoriesApiEndpoint({ slug }: FetchCategoriesArgs) {
  return `orgs/${slug}/categories` as const;
}

export function buildAdditionalSensorsApiEndpoint({ slug }: FetchAdditionalSensorsArgs) {
  return `orgs/${slug}/device_sensor_templates` as const;
}

export function buildRawDatapointsByDeviceIdsApiEndpoint({
  slug
}: FetchRawDatapointsByDeviceIdsArgs) {
  return `orgs/${slug}/devices/datapoints` as const;
}

export function buildHistoricalTrendDataApiEndpoint({
  slug,
  deviceId
}: FetchHistoricalTrendDataArgs) {
  return `orgs/${slug}/devices/${deviceId}/datapoints/historical_data` as const;
}

export function buildBatteryStatusApiEndpoint({ slug }: FetchBatteryStatusArgs) {
  return `orgs/${slug}/battery_status` as const;
}

export function buildAnomalyListApiEndpoint({ slug }: FetchAnomalyListQueryArgs) {
  return `orgs/${slug}/derived_expressions` as const;
}

export function buildDeviceProfilesDetailsByCompatibleDeviceTypeApiEndpoint({
  slug,
  compatible_device_type,
  searchText
}: FetchDeviceProfilesDetailsByCompatibleDeviceTypeArgs) {
  return `orgs/${slug}/device_profiles?compatible_device_type=${compatible_device_type}&page=1&page_size=${
    DEVICE_CONSTANTS.PAGE_SIZE
  }${searchText ? `&filter=device_profile_name:${searchText}` : ''}` as const;
}

export function buildDeviceProfilesDetailsByDeviceProfileIdApiEndpoint({
  slug,
  uuid
}: FetchDeviceProfilesDetailsByDeviceProfileIdArgs) {
  return `orgs/${slug}/device_profiles/${uuid}?timezone=${moment.tz.guess()}` as const;
}

export function buildUpdateDeviceProfileApiEndpoint({ slug, uuid }: UpdateDeviceProfileArgs) {
  return `orgs/${slug}/device_profiles/${uuid}` as const;
}

export function buildPowertrainComponentsApiEndpoint({ slug }: FetchPowertrainComponentsArgs) {
  return `orgs/${slug}/powertrain/components` as const;
}

export function buildPowertrainDetailApiEndpoint({
  slug,
  powertrainId
}: FetchPowertrainDetailArgs) {
  return `orgs/${slug}/powertrain/${powertrainId}` as const;
}

export function buildLatestPowetrainConfigApiEndpoint({
  slug,
  powertrainId
}: FetchLatestPowetrainConfigArgs) {
  return `orgs/${slug}/powertrain/${powertrainId}/latest_config`;
}

export function buildUpdatePowertrainApiEndpoint({
  slug,
  powertrainId
}: FetchPowertrainDetailArgs) {
  return `orgs/${slug}/powertrain/${powertrainId}` as const;
}

export function buildCreatePowertrainApiEndpoint({ slug }: CreatePowertrainArgs) {
  return `orgs/${slug}/powertrain` as const;
}

export function buildDeletePowertrainApiEndpoint({ slug, powertrainId }: DeletePowertrainArgs) {
  return `orgs/${slug}/powertrain/${powertrainId}` as const;
}

export function buildGenericDashboardApiEndpoint({
  slug
}: MergedFetchGenericDashboardArgs<GenericDashboardType>) {
  return `orgs/${slug}/generic_dashboard` as const;
}

export function buildDashoardWidgetDataApiEndpoint({ slug }: { slug: string }) {
  return `orgs/${slug}/generic_dashboard_widget/data` as const;
}

export function buildUpdateSensorWidgetDataApiEndpoint({ slug }: UpdateSensorWidgetDataArgs) {
  return `orgs/${slug}/generic_dashboard_widget` as const;
}

export function buildCreateDeviceApiEndpoint({ slug }: CreateDeviceArgs) {
  return `orgs/${slug}/devices` as const;
}

export function buildDeleteDeviceApiEndpoint({ slug, deviceId }: DeleteDeviceArgs) {
  return `orgs/${slug}/devices/${deviceId}?force_delete=true` as const;
}

export function buildCreateGroupApiEndpoint({ slug }: CreateGroupArgs) {
  return `orgs/${slug}/device_groups` as const;
}

export function buildEditGroupApiEndpoint({ slug, rel_dg_parent_path }: EditGroupArgs) {
  return `orgs/${slug}/device_groups/${rel_dg_parent_path}` as const;
}

export function buildDeleteGroupApiEndpoint({ slug, fqDgPath }: DeleteGroupArgs) {
  return `orgs/${slug}/device_groups/${fqDgPath}` as const;
}

export function buildMoveGroupToGroupApiEndpoint({ slug, targetPath }: MoveGroupToGroupArgs) {
  if (targetPath === '') {
    return `orgs/${slug}/device_groups` as const;
  }
  return `orgs/${slug}/device_groups/${targetPath}` as const;
}

export function buildMoveDeviceToGroupApiEndpoint({ slug, fqDgPath }: MoveDeviceToGroupArgs) {
  if (!fqDgPath) {
    return `orgs/${slug}/device_groups` as const;
  }
  return `orgs/${slug}/device_groups/${fqDgPath}` as const;
}

export function buildUpdateDeviceSimSettingsApiEndpoint({
  slug,
  deviceProfileId
}: UpdateDeviceSimSettingsArgs) {
  return `orgs/${slug}/device_profiles/${deviceProfileId}` as const;
}

export function buildUpdateDeviceSettingsApiEndpoint({ slug, deviceId }: UpdateDeviceSettingsArgs) {
  return `orgs/${slug}/devices/${deviceId}` as const;
}

export function buildUpdateDeviceFirmwareApiEndpoint({
  slug,
  deviceId,
  firmwareId
}: UpdateDeviceFirmwareArgs) {
  return `orgs/${slug}/devices/${deviceId}/firmwares/${firmwareId}/apply` as const;
}

export function buildRebootDeviceApiEndpoint({ slug, deviceId }: RebootDeviceArgs) {
  return `orgs/${slug}/devices/${deviceId}/actions` as const;
}

export function fetchDeviceQuery({ slug, deviceId }: FetchDeviceArgs) {
  return buildQuery({
    apiEndpoint: buildDeviceApiEndpoint(slug, `/${deviceId}`),
    method: HTTP_METHOD.GET,
    service: APISERVICES.DEVICE_API,
    actionKey: DEVICE_TAG.DEVICE
  });
}
export function fetchDevicesQuery({ slug, showProgressBar }: GetDevicesArgs) {
  return buildQuery({
    apiEndpoint: buildDeviceApiEndpoint(slug, `?page=1&page_size=${DEVICE_CONSTANTS.PAGE_SIZE}`),
    method: HTTP_METHOD.GET,
    service: APISERVICES.DEVICE_API,
    actionKey: DEVICE_TAG.DEVICES,
    showProgressBar
  });
}

export function fetchDeviceGroupsQuery({ slug, showProgressBar, payload }: FetchDeviceGroupsArgs) {
  return buildQuery({
    apiEndpoint: buildDeviceGroupApiEndpoint(slug),
    method: HTTP_METHOD.GET,
    service: APISERVICES.DEVICE_API,
    actionKey: DEVICE_TAG.DEVICE_GROUPS,
    showProgressBar,
    params: {
      max_depth: 10,
      page: 1,
      page_size: DEVICE_CONSTANTS.GROUP_PAGE_SIZE,
      // Making query below params `false` by default to make an
      // optimized query for fetching all device groups.
      ...(!payload?.alarm_status
        ? { alarm_status: false }
        : { alarm_status: payload?.alarm_status }),
      ...(!payload?.last_connected
        ? { last_connected: false }
        : { last_connected: payload?.last_connected })
    }
  });
}

export function fetchStandaloneDevicesQuery({
  slug,
  parentGroupId = 'NULL'
}: FetchStandaloneDevicesArgs) {
  return buildQuery({
    apiEndpoint: buildDeviceApiEndpoint(slug, `?fq_dg_path=${parentGroupId}`),
    method: HTTP_METHOD.GET,
    service: APISERVICES.DEVICE_API,
    actionKey: DEVICE_TAG.STANDALONE_DEVICES
  });
}

export function fetchDevicesByFirmwareIdQuery({ slug, firmwareId }: GetDevicesByFirmwareIdArgs) {
  return buildQuery({
    apiEndpoint: buildDeviceApiEndpoint(slug, `?firmware_uuid=${firmwareId}`),
    method: HTTP_METHOD.GET,
    service: APISERVICES.DEVICE_API,
    actionKey: DEVICE_TAG.DEVICE_FIRMWARE_BY_ID
  });
}
export function fetchMultipleDeviceDatapointsQuery({
  slug,
  deviceIds,
  vibrationAnalysis = true
}: FetchMultipleDeviceDatapointsArgs) {
  return buildQuery({
    apiEndpoint: buildMultiDeviceDatapointsApiEndpoint(slug, deviceIds, vibrationAnalysis),
    method: HTTP_METHOD.GET,
    service: APISERVICES.DEVICE_API,
    actionKey: DEVICE_TAG.MULTI_DEVICE_DATAPOINTS
  });
}

export function fetchDeviceDatapointsQuery({
  slug,
  deviceId,
  vibrationAnalysis = 'true'
}: FetchDeviceDatapointsArgs) {
  return buildQuery({
    apiEndpoint: buildDatapointsApiEndpoint(
      slug,
      deviceId,
      `?vibration_analysis=${vibrationAnalysis}`
    ),
    method: HTTP_METHOD.GET,
    service: APISERVICES.DEVICE_API,
    actionKey: DEVICE_TAG.DEVICE_DATAPOINTS
  });
}

export function fetchDevicesTypesQuery({ slug }: GetDeviceTypesArgs) {
  return buildQuery({
    apiEndpoint: buildDeviceTypesApiEndpoint(slug),
    method: HTTP_METHOD.GET,
    service: APISERVICES.DEVICE_API,
    actionKey: DEVICE_TAG.DEVICE_TYPES
  });
}

export function fetchDeviceProfilesQuery({ slug }: GetDeviceProfilesArgs) {
  return buildQuery({
    apiEndpoint: buildDeviceProfilesApiEndpoint(slug),
    method: HTTP_METHOD.GET,
    service: APISERVICES.DEVICE_API,
    actionKey: DEVICE_TAG.DEVICE_PROFILES
  });
}

export function fetchDeviceProfilesDetailsByCompatibleDeviceTypeQuery(
  args: FetchDeviceProfilesDetailsByCompatibleDeviceTypeArgs
) {
  return buildQuery({
    apiEndpoint: buildDeviceProfilesDetailsByCompatibleDeviceTypeApiEndpoint(args),
    method: HTTP_METHOD.GET,
    service: APISERVICES.DEVICE_API,
    actionKey: DEVICE_TAG.DEVICE_PROFILES_DETAILS_BY_COMPATIBLE_DEVICE_TYPE
  });
}

export function fetchDeviceProfilesDetailsByDeviceProfileIdQuery(
  args: FetchDeviceProfilesDetailsByDeviceProfileIdArgs
) {
  return buildQuery({
    apiEndpoint: buildDeviceProfilesDetailsByDeviceProfileIdApiEndpoint(args),
    method: HTTP_METHOD.GET,
    service: APISERVICES.DEVICE_API,
    actionKey: DEVICE_TAG.DEVICE_PROFILES_DETAILS_BY_DEVICE_PROFILE_ID
  });
}

export function updateDeviceProfileQuery(args: UpdateDeviceProfileArgs) {
  const payload = args.payload;

  return buildQuery({
    apiEndpoint: buildUpdateDeviceProfileApiEndpoint(args),
    method: HTTP_METHOD.PUT,
    service: APISERVICES.DEVICE_API,
    actionKey: DEVICE_TAG.UPDATE_DEVICE_PROFILE,
    body: payload,
    onSuccess(dispatch) {
      dispatch(showToast('Device Settings Updated successfully', 'success'));
    }
  });
}

export function fetchPowertrainComponentsQuery(args: FetchPowertrainComponentsArgs) {
  return buildQuery({
    apiEndpoint: buildPowertrainComponentsApiEndpoint(args),
    method: HTTP_METHOD.GET,
    service: APISERVICES.DEVICE_API,
    actionKey: DEVICE_TAG.POWERTRAIN_COMPONENTS
  });
}

export function fetchPowertrainDetailQuery(args: FetchPowertrainDetailArgs) {
  return buildQuery({
    apiEndpoint: buildPowertrainDetailApiEndpoint(args),
    method: HTTP_METHOD.GET,
    service: APISERVICES.DEVICE_API,
    actionKey: DEVICE_TAG.POWERTRAIN_DETAIL
  });
}

export function fetchLatestPowetrainConfigQuery(args: FetchLatestPowetrainConfigArgs) {
  return buildQuery({
    apiEndpoint: buildLatestPowetrainConfigApiEndpoint(args),
    method: HTTP_METHOD.GET,
    service: APISERVICES.DEVICE_API,
    actionKey: DEVICE_TAG.LATEST_POWERTRAIN_CONFIG
  });
}

export function updatePowertrainQuery(args: UpdatePowertrainArgs) {
  const queryParams: PowertrainApiPayload = {
    ...(args.powertrain && { powertrain: args.powertrain }),
    ...(args.additional_configs && { additional_configs: args.additional_configs }),
    ...(args.manual_nbs && { manual_nbs: args.manual_nbs })
  };

  return buildQuery({
    apiEndpoint: buildUpdatePowertrainApiEndpoint(args),
    method: HTTP_METHOD.PATCH,
    service: APISERVICES.DEVICE_API,
    actionKey: DEVICE_TAG.UPDATE_POWERTRAIN,
    body: queryParams,
    onSuccess(dispatch) {
      dispatch(showToast('Powertrain will be updated shortly!', 'success'));
    }
  });
}

export function createPowertrainQuery(args: CreatePowertrainArgs) {
  const queryParams: PowertrainApiPayload = {
    ...(args.powertrain && { powertrain: args.powertrain }),
    ...(args.additional_configs && { additional_configs: args.additional_configs }),
    ...(args.manual_nbs && { manual_nbs: args.manual_nbs })
  };

  return buildQuery({
    apiEndpoint: buildCreatePowertrainApiEndpoint(args),
    method: HTTP_METHOD.POST,
    service: APISERVICES.DEVICE_API,
    actionKey: DEVICE_TAG.CREATE_POWERTRAIN,
    onSuccess(dispatch) {
      dispatch(showToast('Powertrain will be created shortly!', 'success'));
    },
    body: queryParams
  });
}

export function deletePowertrainQuery(args: DeletePowertrainArgs) {
  return buildQuery({
    apiEndpoint: buildDeletePowertrainApiEndpoint(args),
    method: HTTP_METHOD.DELETE,
    service: APISERVICES.DEVICE_URL,
    actionKey: DEVICE_TAG.DELETE_POWERTRAIN,
    onSuccess(dispatch) {
      dispatch(showToast('Powertrain will be deleted shortly!', 'success'));
    }
  });
}

export function fetchGenericDashboardQuery(
  args: MergedFetchGenericDashboardArgs<GenericDashboardType>
) {
  const queryParams: FetchGenericDashboardQueryParams = {
    dashboard_type: args.dashboard_type,
    ...(args?.dashboard_type === DASHBOARD_TYPE.SENSOR_HEALTH && {
      compatible_device_type: args.compatible_device_type
    })
  };

  return buildQuery({
    apiEndpoint: buildGenericDashboardApiEndpoint(args),
    method: HTTP_METHOD.GET,
    service: APISERVICES.DEVICE_API,
    actionKey: DEVICE_TAG.GENERIC_DASHBOARD,
    params: queryParams
  });
}

export function fetchAssetWidgetDataQuery(args: FetchAssetWidgetDataArgs) {
  const queryParams: FetchAssetWidgetDataQueryParams = {
    widget_uuid: args.widget_uuid,
    asset_path: args.asset_path
  };

  return buildQuery({
    apiEndpoint: buildDashoardWidgetDataApiEndpoint(args),
    method: HTTP_METHOD.GET,
    service: APISERVICES.DEVICE_API,
    actionKey: DEVICE_TAG.ASSET_WIDGET_DATA,
    params: queryParams
  });
}

export function fetchSensorWidgetDataQuery(args: FetchSensorWidgetDataArgs) {
  const queryParams: FetchSensorWidgetDataQueryParams = {
    widget_uuid: args.widget_uuid,
    device_id: args.device_id,
    ...(args.dpid && { dpid: args.dpid }),
    ...(args.start_ts && { start_ts: args.start_ts }),
    ...(args.end_ts && { end_ts: args.end_ts })
  };

  return buildQuery({
    apiEndpoint: buildDashoardWidgetDataApiEndpoint(args),
    method: HTTP_METHOD.GET,
    service: APISERVICES.DEVICE_API,
    actionKey: DEVICE_TAG.SENSOR_WIDGET_DATA,
    params: queryParams
  });
}

export function updateSensorWidgetDataQuery(args: UpdateSensorWidgetDataArgs) {
  const queryParams: UpdateSensorWidgetDataQueryParams = {
    widgets: args.widgets
  };

  return buildQuery({
    apiEndpoint: buildUpdateSensorWidgetDataApiEndpoint(args),
    method: HTTP_METHOD.PATCH,
    service: APISERVICES.DEVICE_API,
    actionKey: DEVICE_TAG.UPDATE_SENSOR_WIDGET_DATA,
    body: queryParams,
    onSuccess(dispatch) {
      dispatch(showToast('Widget updated successfully!', 'success'));
    }
  });
}

export function createDeviceQuery(args: CreateDeviceArgs) {
  const queryParams: CreateDeviceApiQueryParams = {
    device_profile_id: args.deviceProfileId,
    device_type_id: args.deviceTypeId,
    fq_dg_path: args.path,
    name: args.name
  };

  return buildQuery({
    apiEndpoint: buildCreateDeviceApiEndpoint(args),
    method: HTTP_METHOD.POST,
    service: APISERVICES.DEVICE_API,
    actionKey: DEVICE_TAG.CREATE_DEVICE,
    body: queryParams,
    onSuccess(dispatch) {
      dispatch(showToast('Device added successfully!', 'success'));
    }
  });
}

export function deleteDeviceQuery(args: DeleteDeviceArgs) {
  return buildQuery({
    apiEndpoint: buildDeleteDeviceApiEndpoint(args),
    method: HTTP_METHOD.DELETE,
    service: APISERVICES.DEVICE_URL,
    actionKey: DEVICE_TAG.DELETE_DEVICE,
    onSuccess(dispatch) {
      dispatch(showToast('Device deleted successfully!', 'success'));
    },
    onError(dispatch) {
      dispatch(showToast('Device could not be deleted', 'error'));
    }
  });
}

export function createGroupQuery(args: CreateGroupArgs) {
  const queryParams: Omit<CreateGroupArgs, 'slug'> = {
    name: args.name,
    device_ids: [],
    rel_dg_parent_path: args.rel_dg_parent_path
  };

  return buildQuery({
    apiEndpoint: buildCreateGroupApiEndpoint(args),
    method: HTTP_METHOD.POST,
    service: APISERVICES.DEVICE_API,
    actionKey: DEVICE_TAG.CREATE_GROUP,
    body: queryParams,
    onSuccess(dispatch) {
      dispatch(showToast('Group created successfully!', 'success'));
    },
    onError(dispatch) {
      dispatch(showToast('Group could not be created', 'error', true));
    }
  });
}

export function editGroupQuery(args: EditGroupArgs) {
  const queryParams: EditGroupQueryParams = {
    name: args.name
  };

  return buildQuery({
    apiEndpoint: buildEditGroupApiEndpoint(args),
    method: HTTP_METHOD.PATCH,
    service: APISERVICES.DEVICE_API,
    actionKey: DEVICE_TAG.EDIT_GROUP,
    body: queryParams,
    onSuccess(dispatch) {
      dispatch(showToast('Group updated successfully!', 'success'));
    },
    onError(dispatch) {
      dispatch(showToast('Group could not be updated', 'error', true));
    }
  });
}

export function deleteGroupQuery(args: DeleteGroupArgs) {
  return buildQuery({
    apiEndpoint: buildDeleteGroupApiEndpoint(args),
    method: HTTP_METHOD.DELETE,
    service: APISERVICES.DEVICE_URL,
    actionKey: DEVICE_TAG.DELETE_GROUP,
    onSuccess(dispatch) {
      dispatch(showToast('Group deleted successfully!', 'success', true));
    },
    onError(dispatch) {
      dispatch(showToast('Group could not be deleted', 'error', true));
    }
  });
}

export function moveGroupToGroupQuery(args: MoveGroupToGroupArgs) {
  const queryParams: MoveGroupToGroupQueryParams = {
    action: 'move_dg',
    current_rel_dg_path: args.current_rel_dg_path
  };

  return buildQuery({
    apiEndpoint: buildMoveGroupToGroupApiEndpoint(args),
    method: HTTP_METHOD.PATCH,
    service: APISERVICES.DEVICE_API,
    actionKey: DEVICE_TAG.MOVE_GROUP_TO_GROUP,
    body: queryParams
  });
}

export function moveDeviceToGroupQuery(args: MoveDeviceToGroupArgs) {
  const queryParams: MoveDeviceToGroupQueryParams = {
    action: 'add_device',
    device_ids: args.device_ids,
    current_rel_dg_path: args.current_rel_dg_path
  };

  return buildQuery({
    apiEndpoint: buildMoveDeviceToGroupApiEndpoint(args),
    method: HTTP_METHOD.PATCH,
    service: APISERVICES.DEVICE_API,
    actionKey: DEVICE_TAG.MOVE_DEVICE_TO_GROUP,
    body: queryParams,
    onError(dispatch) {
      dispatch(showToast('Devices could not be moved to Group', 'error', true));
    }
  });
}

export function updateDeviceSimSettingsQuery(args: UpdateDeviceSimSettingsArgs) {
  return buildQuery({
    apiEndpoint: buildUpdateDeviceSimSettingsApiEndpoint(args),
    method: HTTP_METHOD.PATCH,
    service: APISERVICES.DEVICE_API,
    actionKey: DEVICE_TAG.UPDATE_DEVICE_SIM_SETTINGS,
    body: args.payload,
    onSuccess(dispatch) {
      dispatch(showToast('Device Sim Settings Updated Successfully!', 'success'));
    },
    onError(dispatch) {
      dispatch(showToast('Device Sim Settings could not be updated', 'error', true));
    }
  });
}

export function updateDeviceSettingsQuery(args: UpdateDeviceSettingsArgs) {
  const fetchDeviceQueryArgs = {
    slug: args.slug,
    deviceId: args.deviceId
  };
  return buildQuery({
    apiEndpoint: buildUpdateDeviceSettingsApiEndpoint(args),
    method: HTTP_METHOD.PUT,
    service: APISERVICES.DEVICE_API,
    actionKey: DEVICE_TAG.UPDATE_DEVICE_SETTINGS,
    body: args.payload,
    onSuccess(dispatch, result: DeviceDetail) {
      dispatch(
        deviceApi.util.updateQueryData('fetchDevice', fetchDeviceQueryArgs, (draftDeviceData) => {
          Object.assign(draftDeviceData, result);
        })
      );
      dispatch(showToast('Device Details Updated Successfully!', 'success'));
    },
    onError(dispatch) {
      dispatch(showToast('Device Details could not be updated', 'error', true));
    }
  });
}

export function updateDeviceFirmwareQuery(args: UpdateDeviceFirmwareArgs) {
  return buildQuery({
    apiEndpoint: buildUpdateDeviceFirmwareApiEndpoint(args),
    method: HTTP_METHOD.POST,
    service: APISERVICES.DEVICE_API,
    actionKey: DEVICE_TAG.UPDATE_DEVICE_FIRMWARE,
    onSuccess(dispatch) {
      dispatch(showToast('Firmware attached successfully', 'success'));
    }
  });
}

export function rebootDeviceQuery(args: RebootDeviceArgs) {
  const requestPayload = { payload: { set_device_state: 'reboot' } } as const;

  return buildQuery({
    apiEndpoint: buildRebootDeviceApiEndpoint(args),
    method: HTTP_METHOD.POST,
    service: APISERVICES.DEVICE_API,
    actionKey: DEVICE_TAG.REBOOT_DEVICE,
    body: requestPayload,
    onSuccess(dispatch) {
      dispatch(showToast('Device rebooted Successfully!', 'success'));
    },
    onError(dispatch) {
      dispatch(showToast('Failed to reboot device', 'error', true));
    }
  });
}

function fetchDeviceDeviceSensorTemplates({
  slug,
  deviceId,
  templateId,
  sensorName
}: FetchDeviceSensorTemplatesInternalApiArgs) {
  return httpGet(
    `orgs/${slug}/device_sensor_templates/type/${templateId}?device_id=${deviceId}&sensor_module_name=${sensorName}`,
    null,
    APISERVICES.DEVICE_API
  );
}

export async function fetchDeviceDeviceSensorTemplatesQuery(
  args: FetchDeviceSensorTemplatesArgs,
  dispatch: ThunkDispatch<any, any, any>,
  actionKey: string
) {
  dispatch(showProgressFor(actionKey));
  try {
    const structborneResponse: DeviceSensorTemplate = await fetchDeviceDeviceSensorTemplates({
      ...args,
      templateId: TEMPLATE_ID.SNS_MIC_STRUCT_BORNE,
      sensorName: SENSOR_NAME.STRUCTURE_BORNE
    });
    const airborneResponse: DeviceSensorTemplate = await fetchDeviceDeviceSensorTemplates({
      ...args,
      templateId: TEMPLATE_ID.SNS_MIC_AIRBORNE,
      sensorName: SENSOR_NAME.AIRBORNE
    });
    const humidityResponse: DeviceSensorTemplate = await fetchDeviceDeviceSensorTemplates({
      ...args,
      templateId: TEMPLATE_ID.SNS_HUMIDITY,
      sensorName: SENSOR_NAME.HUMIDITY
    });
    const surfaceTempResponse: DeviceSensorTemplate = await fetchDeviceDeviceSensorTemplates({
      ...args,
      templateId: TEMPLATE_ID.SNS_TEMPERATURE,
      sensorName: SENSOR_NAME.SURFACE_TEMPERATURE
    });
    const enviromentTempResponse: DeviceSensorTemplate = await fetchDeviceDeviceSensorTemplates({
      ...args,
      templateId: TEMPLATE_ID.SNS_TEMPERATURE,
      sensorName: SENSOR_NAME.ENVIRONMENT_TEMPERATURE
    });

    return {
      data: {
        structborneResponse,
        airborneResponse,
        humidityResponse,
        surfaceTempResponse,
        enviromentTempResponse
      }
    };
  } catch (error: any) {
    Bugsnag.notify(error);
    handleError(error, dispatch);
    return {
      error: error?.response || 'error'
    };
  } finally {
    dispatch(removeProgressFor(actionKey));
  }
}

export async function fetchImageWidgetDataQuery(
  args: FetchImageWidgetDataArgs,
  dispatch: ThunkDispatch<any, any, any>,
  actionKey: string
) {
  dispatch(showProgressFor(actionKey));
  try {
    const axiosNewInstance = axios.create();
    const imageResult = await axiosNewInstance.get(
      `${VITE_ASSET_META_BUCKET_URL}/${args.assetSlug}/${args.assetPath}/image.jpg`,
      { responseType: 'arraybuffer' }
    );
    // Convert Image response from binary to base64-encoded string.
    const arrayBuffer = new Uint8Array(imageResult?.data).buffer;
    const binaryString = String.fromCharCode(...new Uint8Array(arrayBuffer));
    const base64ImageString = btoa(binaryString);
    const srcValue = `data:image/jpeg;base64,${base64ImageString}`;

    const imageMarkersResult = await axiosNewInstance.get(
      `${VITE_ASSET_META_BUCKET_URL}/${args.assetSlug}/${args.assetPath}/data.json`
    );

    return {
      data: {
        imageUrlData: srcValue,
        imageMarkerData: {
          markers: imageMarkersResult?.data?.markers
        }
      }
    };
  } catch (error: any) {
    return {
      error: error?.response || 'error'
    };
  } finally {
    dispatch(removeProgressFor(actionKey));
  }
}

export function fetchDeviceEventsTrendQuery({
  slug,
  deviceId,
  dpids,
  start,
  end,
  unit
}: FetchDeviceEventsTrendArgs) {
  const queryParams: Record<string, any> = {
    ...(unit && { unit }),
    dpids: dpids.join(','),
    ...(start && { start }),
    ...(end && { end })
  };

  return buildQuery({
    apiEndpoint: buildDatapointsApiEndpoint(slug, deviceId, '/narrowband_data'),
    method: HTTP_METHOD.GET,
    service: APISERVICES.VA_API,
    actionKey: DEVICE_TAG.DEVICE_EVENTS,
    params: queryParams
  });
}

export function fetchOrbitPlotDataQuery({
  slug,
  deviceId,
  event_id,
  odr,
  dpid,
  center_freq,
  custom_combinations
}: FetchOrbitPlotDataArgs) {
  const queryParams: Record<string, any> = {
    event_id,
    odr,
    ...(dpid && { dpid }),
    ...(center_freq && { center_freq }),
    ...(custom_combinations && { custom_combinations })
  };

  return buildQuery({
    apiEndpoint: buildDatapointsApiEndpoint(slug, deviceId, '/orbit_plot'),
    method: HTTP_METHOD.GET,
    service: APISERVICES.VA_API,
    actionKey: DEVICE_TAG.ORBIT_CHART,
    params: queryParams
  });
}

export function fetchDefaultScheduleConfigQuery({ slug }: { slug: string }) {
  return buildQuery({
    apiEndpoint: `orgs/${slug}/device_profiles/default_schedule_config`,
    method: HTTP_METHOD.GET,
    service: APISERVICES.DEVICE_API,
    actionKey: DEVICE_TAG.DEVICE_SCHEDULE_CONFIG
  });
}

export function fetchHistoricalDataWithoutSamplingQuery({
  slug,
  deviceId,
  dpids,
  maxPoints,
  startDate,
  endDate
}: FetchDeviceHistoricalDataWithoutSamplingArgs) {
  const queryParams: FetchDeviceHistoricalDataWithoutSamplingQueryParams = {
    dpids: dpids.join(','),
    maxPoints,
    start: startDate !== undefined ? Math.round(startDate) : undefined,
    end: endDate !== undefined ? Math.round(endDate) : undefined
  };

  return buildQuery({
    apiEndpoint: buildDatapointsApiEndpoint(slug, deviceId, `/historical_data_without_sampling`),
    method: HTTP_METHOD.GET,
    service: APISERVICES.DEVICE_API,
    actionKey: DEVICE_TAG.DEVICE_HISTORICAL_DATA_WITHOUT_SAMPLING,
    params: queryParams
  });
}

export function fetchDeviceWidgetSetting({ slug, deviceId }: FetchDeviceWidgetSettingArgs) {
  return buildQuery({
    apiEndpoint: buildDeviceApiEndpoint(slug, `/${deviceId}/widgets`),
    method: HTTP_METHOD.GET,
    service: APISERVICES.DEVICE_API,
    actionKey: DEVICE_TAG.DEVICE_WIDGET_SETTING
  });
}

export function fetchMultipleDevicePhotosQuery({
  slug,
  deviceIds,
  showNoPhotosFoundToast = true
}: FetchMultipleDevicePhotosArgs) {
  return buildQuery({
    apiEndpoint: buildDeviceApiEndpoint(slug, `/photos?device_ids=${deviceIds.join(',')}`),
    method: HTTP_METHOD.GET,
    service: APISERVICES.DEVICE_API,
    actionKey: DEVICE_TAG.DEVICE_PHOTOS,
    onSuccess(dispatch, result) {
      if (showNoPhotosFoundToast && result?.device_photos_meta.length === 0) {
        dispatch(showToast('No Images Found', 'warning', false));
      }
    }
  });
}

export function deleteDevicePhotoQuery(args: DeleteDevicePhotoArgs) {
  const body: DeleteDevicePhotoPayload = args.payload;

  return buildQuery({
    apiEndpoint: buildDevicePhotoApiEndpoint(args),
    method: HTTP_METHOD.DELETE,
    service: APISERVICES.DEVICE_URL,
    actionKey: DEVICE_TAG.DELETE_DEVICE_PHOTO,
    body
  });
}

async function uploadDevicePhotoData({
  slug,
  deviceId,
  newImageDetails,
  device_photos_meta,
  dispatch
}: UploadPhotoArgs) {
  const uploadPhotoResponse: UploadDevicePhotoResponse = await httpPost(
    `orgs/${slug}/devices/${deviceId}/device_photos_meta`,
    {
      device_photos_meta: device_photos_meta
    },
    APISERVICES.DEVICE_API
  );

  // Upload the image to S3 bucket.
  const promises = uploadPhotoResponse.device_photos_meta.map(async (photo) => {
    const md5Hash = CryptoJS.MD5(JSON.stringify(newImageDetails)).toString();
    return await fileUploadAxiosPut(photo.put_object_url, newImageDetails, {
      Content_MD5: md5Hash
    });
  });

  await Promise.all(promises);

  dispatch(showToast('Device photo uploaded successfully!', 'success'));

  return uploadPhotoResponse;
}

export async function replaceDevicePhotoQuery(
  args: ReplaceDevicePhotoArgs,
  dispatch: ThunkDispatch<any, any, any>,
  actionKey: string
) {
  dispatch(showProgressFor(actionKey));
  try {
    const { newImage, oldImage } = args.payload;

    await httpDelete(
      `orgs/${args.slug}/devices/${args.deviceId}/device_photos_meta`,
      {
        device_photos_meta: oldImage.device_photos_meta
      },
      APISERVICES.DEVICE_URL
    );

    const response = await uploadDevicePhotoData({
      slug: args.slug,
      deviceId: args.deviceId,
      device_photos_meta: newImage.device_photos_meta,
      dispatch,
      newImageDetails: newImage.newImageDetails
    });

    return {
      data: response
    };
  } catch (error: any) {
    Bugsnag.notify(error);
    dispatch(showToast('Failed to upload image', 'error', true));
    handleError(error, dispatch);
    return {
      error: error?.response || 'error'
    };
  } finally {
    dispatch(removeProgressFor(actionKey));
  }
}

interface UploadPhotoArgs {
  slug: string;
  deviceId: number;
  newImageDetails: CustomFile;
  device_photos_meta: [NewImageMetadata]; // Replace 'any' with the actual type of device_photos_meta
  dispatch: ThunkDispatch<any, any, any>;
}

export async function uploadDevicePhotoQuery(
  args: UploadDevicePhotoArgs,
  dispatch: ThunkDispatch<any, any, any>,
  actionKey: string
) {
  dispatch(showProgressFor(actionKey));
  try {
    const { device_photos_meta, newImageDetails } = args.payload;

    const response = await uploadDevicePhotoData({
      slug: args.slug,
      deviceId: args.deviceId,
      newImageDetails,
      device_photos_meta,
      dispatch
    });

    return {
      data: response
    };
  } catch (error: any) {
    Bugsnag.notify(error);
    dispatch(showToast('Failed to upload image', 'error', true));
    handleError(error, dispatch);
    return {
      error: error?.response || 'error'
    };
  } finally {
    dispatch(removeProgressFor(actionKey));
  }
}

export function fetchCategoriesQuery(args: FetchCategoriesArgs) {
  return buildQuery({
    apiEndpoint: buildCategoriesApiEndpoint(args),
    method: HTTP_METHOD.GET,
    service: APISERVICES.ACTIVITY_LOGS_API,
    actionKey: DEVICE_TAG.CATEGORIES,
    params: args.payload
  });
}

export function fetchAdditionalSensorsQuery(args: FetchAdditionalSensorsArgs) {
  return buildQuery({
    apiEndpoint: buildAdditionalSensorsApiEndpoint(args),
    method: HTTP_METHOD.GET,
    service: APISERVICES.DEVICE_API,
    actionKey: DEVICE_TAG.ADDITIONAL_SENSORS,
    params: args.payload
  });
}
export function updateDeviceWidgetSetting({
  slug,
  deviceId,
  datapointName,
  widgetId,
  settings
}: UpdateDeviceWidgetSettingArgs) {
  const payload: UpdateDeviceWidgetSettingPayload = { settings };
  return buildQuery({
    apiEndpoint: buildDeviceApiEndpoint(slug, `/${deviceId}/widgets/${widgetId}`),
    method: HTTP_METHOD.PUT,
    service: APISERVICES.DEVICE_API,
    actionKey: DEVICE_TAG.UPDATE_DEVICE_WIDGET_SETTING,
    onSuccess(dispatch) {
      if (settings.show === false) {
        dispatch(showToast(`Hide Datapoint ${datapointName} Successful!`, 'success'));
      } else if (settings.show === true) {
        dispatch(showToast(`Unhide Datapoint ${datapointName} Successful!`, 'success'));
      }
    },
    onError(dispatch) {
      dispatch(showToast('Widget Settings could not be updated!', 'error', true));
    }
  });
}
export function createDeviceWidgetsSetting({
  slug,
  deviceId,
  widgets
}: CreateDeviceWidgetsSettingArgs) {
  const payload: CreateDeviceWidgetSettingPayload = { widgets };
  return buildQuery({
    apiEndpoint: buildDeviceApiEndpoint(slug, `/${deviceId}/widgets`),
    method: HTTP_METHOD.PUT,
    service: APISERVICES.DEVICE_API,
    actionKey: DEVICE_TAG.CREATE_DEVICE_WIDGETS_SETTING,
    body: payload
  });
}

export function fetchRawDatapointsByDeviceIdsQuery(args: FetchRawDatapointsByDeviceIdsArgs) {
  return buildQuery({
    apiEndpoint: buildRawDatapointsByDeviceIdsApiEndpoint(args),
    method: HTTP_METHOD.GET,
    service: APISERVICES.DEVICE_API,
    actionKey: DEVICE_TAG.RAW_DATAPOINTS_BY_DEVICE_IDS,
    params: args.payload
  });
}

export function fetchHistoricalTrendDataQuery(args: FetchHistoricalTrendDataArgs) {
  return buildQuery({
    apiEndpoint: buildHistoricalTrendDataApiEndpoint(args),
    method: HTTP_METHOD.GET,
    service: APISERVICES.DEVICE_API,
    actionKey: DEVICE_TAG.HISTORICAL_TREND_DATA,
    params: args.payload
  });
}

export function fetchBatteryStatusQuery(args: FetchBatteryStatusArgs) {
  return buildQuery({
    apiEndpoint: buildBatteryStatusApiEndpoint(args),
    method: HTTP_METHOD.GET,
    service: APISERVICES.DEVICE_API,
    actionKey: DEVICE_TAG.BATTERY_STATUS,
    params: args.payload
  });
}

export function fetchAnomalyListQuery(args: FetchAnomalyListQueryArgs) {
  return buildQuery({
    apiEndpoint: buildAnomalyListApiEndpoint(args),
    method: HTTP_METHOD.GET,
    service: APISERVICES.DEVICE_API,
    actionKey: DEVICE_TAG.ANOMALY_LIST,
    params: args.payload
  });
}
