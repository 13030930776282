/**
 * Login Action Types
 */
export const CHECK_CURRENT_SESSION = 'FEATURES/AUTH/CHECK_CURRENT_SESSION';
export const CURRENT_SESSION_AVAILABLE = 'FEATURES/AUTH/CURRENT_SESSION_AVAILABLE ';
export const CURRENT_SESSION_NOT_AVAILABLE = 'FEATURES/AUTH/CURRENT_SESSION_NOT_AVAILABLE ';
/**
 * Federated Login
 */
export const LOGIN_WITH_GOOGLE = 'FEATURES/AUTH/LOGIN_WITH_GOOGLE';
export const LOGIN_WITH_LINKED_IN = 'FEATURES/AUTH/LOGIN_WITH_LINKED_IN';
/**
 * Login Process
 */
export const SET_USER_EMAIL = 'FEATURES/AUTH/SET_USER_EMAIL';
export const LOGIN_PROGERSS = 'FEATURES/AUTH/LOGIN_PROGERSS';
export const LOGIN_FORCE_NEW_PWD = 'FEATURES/AUTH/LOGIN_FORCE_NEW_PASSWORD';
export const LOGIN_SUCCESS = 'FEATURES/AUTH/LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'FEATURES/AUTH/LOGIN_FAILURE';
export const LOGIN_TIMEOUT = 'FEATURES/AUTH/LOGIN_TIMEOUT';
/**
 * Logout
 */
export const LOGOUT_SUCCEED = 'FEATURES/AUTH/LOGOUT_SUCCEED';
export const LOGOUT_PROGRESS = 'FEATURES/AUTH/LOGOUT_PROGRESS';
export const LOGOUT_FAILED = 'FEATURES/AUTH/LOGOUT_FAILED';
/**
 * Resend Verification
 */
export const RESEND_VERIFICATION = 'FEATURES/AUTH/RESEND_VERIFICATION_LINK';
export const RESEND_VERIFICATION_SUCCESS = 'FEATURES/AUTH/RESEND_VERIFICATION_LINK_SUCCESS';
export const RESEND_VERIFICATION_FAILED = 'FEATURES/AUTH/RESEND_VERIFICATION_LINK_FAILED';
/**
 * Forgot password
 */
export const FORGOT_PASSWORD_CODE = 'FEATURES/AUTH/FORGOT_PASSWORD_CODE';
export const FORGOT_PASSWORD_CODE_SUCCESS = 'FEATURES/AUTH/FORGOT_PASSWORD_CODE_SUCCESS';
export const FORGOT_PASSWORD_CODE_FAILED = 'FEATURES/AUTH/FORGOT_PASSWORD_CODE_FAILED';
export const RESET_TO_LOGIN_PAGE = 'FEATURES/AUTH/RESET_TO_LOGIN';
