import { FREQUENCY_UNIT, Y_AXIS_UNIT } from './waterfall.types';

export const MAX_EVENTS_COUNT = 20;

export const FrequencyUnitsList = [
  { value: FREQUENCY_UNIT.Hz, label: FREQUENCY_UNIT.Hz },
  { value: FREQUENCY_UNIT.CPM, label: FREQUENCY_UNIT.CPM },
  { value: FREQUENCY_UNIT.Orders, label: FREQUENCY_UNIT.Orders }
];

export const DisplayUnitsList = [
  { value: Y_AXIS_UNIT.INCHES_PER_SEC, label: Y_AXIS_UNIT.INCHES_PER_SEC },
  { value: Y_AXIS_UNIT.G, label: Y_AXIS_UNIT.G }
];
