/**
 * useIsFeatureEnable custom hook
 * @author prerna.more@shorelineiot.com
 */
import { skipToken } from '@reduxjs/toolkit/query';
import { useFetchOrgFeaturesList } from '../../features/org-settings/store/feature-configurations/featureConfigurations.hooks';
import { useSlugContext } from '../lib';
import useGetOrgUuidBySlug from './useGetOrgUuidBySlug';

/**
 * This hook checks if a specific feature is enabled for the organization identified by the slug.
 * It returns `true` if the feature is enabled , otherwise `false`.
 *
 * @param featureName - The name of the feature to check.
 * @returns A boolean indicating whether the feature is enabled.
 */
export const useIsFeatureEnable = (featureName: string | typeof skipToken) => {
  const { slug } = useSlugContext();
  const org_uuid = useGetOrgUuidBySlug(slug);
  const { orgFeaturesList, isOrgFeaturesListSuccess } = useFetchOrgFeaturesList(
    slug !== 'org'
      ? {
          slug
        }
      : skipToken
  );

  if (isOrgFeaturesListSuccess && orgFeaturesList?.length) {
    // Find the organization that matches the org_uuid
    const matchingOrg = orgFeaturesList.find((org) => org.suborg_uuid === org_uuid);

    if (matchingOrg) {
      // Check if the feature with the given featureName exists in the matching organization
      const isFeatureEnable = matchingOrg.features.find(
        (feature) => feature.unique_name === featureName
      );

      return isFeatureEnable?.feature_config !== 'false' ? true : false;
    }
  }

  // Return false if no match is found or data is still loading
  return false;
};
