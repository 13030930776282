import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, FormHelperText, IconButton } from '@mui/material';
import { RebootIcon } from '../../icons';
import { LightTooltip } from '../tooltip/Tooltip';
import { trimDecimals } from '../../helpers';
import {
  ZoomValue,
  eventsDataType,
  useWaterfallYAxisExtremesValue
} from '../../../features/workflow/trend-analysis/store';
import { SLTextField } from '../form';
import { useFormContext } from 'react-hook-form';
import TranslateText from '../../../i18n/TranslateText';

type Props = {
  chartRef: any;
  eventsData: eventsDataType[];
};

const useStyles = makeStyles(() => ({
  inputBox: {
    width: '100px'
  },
  resetButton: {
    left: '-15px',
    top: '-8px'
  }
}));
/**
 *
 * @param param0
 * @returns
 */

export default function SLChartYAxisZoomHandler({ chartRef, eventsData }: Props) {
  const classes = useStyles();
  const yAxisExtremesValueofChart = useWaterfallYAxisExtremesValue();
  const [yAxisExtremesValue, setYAxisExtremesValue] = useState(yAxisExtremesValueofChart);
  const [zoomValues, setZoomValues] = useState<ZoomValue>({
    min: null,
    max: null
  });
  const [showError, setShowError] = useState(false);
  const { setValue } = useFormContext();
  function setChartZoomHandler(newZoomValues: ZoomValue) {
    const chart: any = chartRef?.current?.chart;
    const yAxis: any = chart?.yAxis[0];
    const extremes: any = yAxis?.getExtremes();
    const getMaxValue = () => {
      if (newZoomValues.max === null || newZoomValues.max > extremes?.dataMax) {
        //Update textfield value if newly entered value is greater than current max value or null
        setValue('maxY', trimDecimals(extremes?.dataMax));
        return extremes?.dataMax;
      }
      return newZoomValues.max;
    };
    const newValues = {
      min: newZoomValues.min === null ? 0 : newZoomValues.min,
      max: trimDecimals(getMaxValue())
    };

    /**
     * checking min<max before setting range to chart
     */
    if (newValues.max && newValues.min < newValues.max) {
      chart?.update({
        yAxis: {
          min: newValues.min,
          max: newValues.max
        }
      });
      setShowError(false);
    } else {
      setShowError(true);
    }
    /**
     * whatever the min,max values are
     * we are setting on inputs
     */
    setZoomValues(newValues);
  }

  const handleMinMaxZoomChange = (value: any) => {
    const yMinValue = Number(value.minY);
    const yMaxValue = Number(value.maxY) || null;
    // Regex to allow up to 4 decimal places
    const regex = /^\d+(\.\d{0,4})?$/;
    if (regex.test(String(yMinValue)) && regex.test(String(yMaxValue))) {
      //setting new max,min value to old zoomvalues
      setChartZoomHandler({ min: yMinValue, max: yMaxValue });
    }
  };
  /**
   * Reset chart range
   */
  const resetDefault = () => {
    const chart: any = chartRef?.current?.chart;
    const newZoomValues = { min: 0, max: null };
    setShowError(false);
    setZoomValues(newZoomValues);
    chart?.update({
      yAxis: {
        min: newZoomValues.min,
        max: newZoomValues.max
      }
    });
  };

  useEffect(() => {
    setYAxisExtremesValue(yAxisExtremesValueofChart);
  }, [yAxisExtremesValueofChart]);

  //Reset zoom values if zoommax is greater than largest value of event data
  useEffect(() => {
    if (zoomValues?.max && yAxisExtremesValue.max && yAxisExtremesValue.max < zoomValues.max) {
      resetDefault();
    }
  }, [eventsData, yAxisExtremesValue]);

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="left"
        spacing={1}
        xs={12}>
        <Grid item xs={4.5} marginTop={'-20px'}>
          <SLTextField
            name="minY"
            type="number"
            label="Min Y"
            labelTranslationId="min_y"
            allowNegative={false}
            value={zoomValues.min || '0'}
            onChange={(value: any) => handleMinMaxZoomChange(value)}
          />
        </Grid>

        <Grid item xs={4.5} marginTop={'-20px'} marginRight={'10px'}>
          <SLTextField
            name="maxY"
            type="number"
            label="Max Y"
            labelTranslationId="max_y"
            allowNegative={false}
            value={zoomValues.max || yAxisExtremesValue.max}
            onChange={(value: any) => handleMinMaxZoomChange(value)}
          />
        </Grid>
        <Grid item xs={1}>
          <LightTooltip title="Reset Y Zoom" translationId="reset_y_zoom">
            <IconButton size="small" onClick={resetDefault} className={classes.resetButton}>
              <RebootIcon className="MuiSvgIcon-root" />
            </IconButton>
          </LightTooltip>
        </Grid>
      </Grid>
      <Grid item>
        {showError && (
          <FormHelperText error>
            {TranslateText('invalid_chart_range', 'Invalid chart range')}
          </FormHelperText>
        )}
      </Grid>
    </>
  );
}
