import React, { useState } from 'react';
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Grid,
  Divider
} from '@mui/material';
import { DASHBOARD_ITEM_TYPE } from '../../dashboard-widgets/types/DashboardItemType';
import {
  MAP_CHART_DEVICES_SETTINGS_TYPES,
  MAP_CHART_MARKER_TYPES,
  MapChartSettingsType,
  defaultMapChartSettings
} from './MapChartDefaultViewConfig';
import { BubblePointInfo } from './BubblePointInfo';
import { useStyles } from './mapChartEditor.styles';
import TranslateText from '../../../../i18n/TranslateText';

const MapChartEditor = ({
  defaultValues,
  handleSubmitForm
}: {
  defaultValues: MapChartSettingsType;
  handleSubmitForm: (value: any) => void;
}) => {
  const classes = useStyles();

  // Use the default chart settings if there is no default value
  const [settings, setSettings] = useState<any>({
    chart_type: DASHBOARD_ITEM_TYPE.MAP_CHART,
    chartSettings:
      Object.keys(defaultValues).length > 0 ? defaultValues : { ...defaultMapChartSettings },
    positionMeta: [0, 0, 16, 10, 16, 10]
  });

  const handleDeviceSelectionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedChartSettings = { ...settings.chartSettings, deviceSettings: event.target.value };
    const updatedSettings = { ...settings, chartSettings: updatedChartSettings };
    setSettings(updatedSettings);
    handleSubmitForm(updatedSettings);
  };

  const handleMarkerSelectionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedChartSettings = { ...settings.chartSettings, markerType: event.target.value };
    const updatedSettings = { ...settings, chartSettings: updatedChartSettings };
    setSettings(updatedSettings);
    handleSubmitForm(updatedSettings);
  };

  return (
    <FormControl>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <RadioGroup
            row
            onChange={handleDeviceSelectionChange}
            defaultValue={settings.chartSettings.deviceSettings}
            name="device-settings-selection-group"
            className={classes.radioGroup}>
            <FormControlLabel
              value={MAP_CHART_DEVICES_SETTINGS_TYPES.ALARMS}
              control={<Radio />}
              label={TranslateText('only_alarms', 'Only Alarms')}
            />
            <FormControlLabel
              value={MAP_CHART_DEVICES_SETTINGS_TYPES.ALL}
              control={<Radio />}
              label={TranslateText('all_devices', 'All Devices')}
            />
          </RadioGroup>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <RadioGroup
            row
            onChange={handleMarkerSelectionChange}
            defaultValue={settings.chartSettings.markerType}
            name="marker-type-selection-group"
            className={classes.radioGroup}>
            <FormControlLabel
              value={MAP_CHART_MARKER_TYPES.MARKER}
              control={<Radio />}
              label={TranslateText('location_marker', 'Location Marker')}
            />
            <FormControlLabel
              value={MAP_CHART_MARKER_TYPES.BUBBLE}
              control={<Radio />}
              label={TranslateText('bubble_marker', 'Bubble Marker')}
            />
          </RadioGroup>
          <Divider />
        </Grid>
        {settings.chartSettings.markerType === MAP_CHART_MARKER_TYPES.BUBBLE && (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Box className={classes.info}>
              <BubblePointInfo />
            </Box>
          </Grid>
        )}
      </Grid>
    </FormControl>
  );
};

export default MapChartEditor;
