/**
 * Alarms Menu
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { SidebarMenu, useSlugContext, AlarmsIcon, SLSubmenuLink, useQuery } from '../../framework';
import useGetAlarmFiltersFromURL from '../../framework/custom-hooks/useGetAlarmFiltersFromURL';
import useIsHistoricalAlarms from '../../framework/custom-hooks/useIsHistoricalAlarms';
import { ALARM_TYPE } from './store';
import { HISTORICAL_ALARMS } from './store/alarms-list/utils/alarmsList.utils';
import { ALARM_TYPE_OPTIONS } from './store/alarms.constants';
import { sidebarId } from '../../framework/components/sidebar/sidebar.utils';
import { useGetUserGroupDevicePaths } from './utils/alarms.utils';
/**
 *
 * @returns
 */
export default function AlarmsMenu(): JSX.Element {
  const { slug } = useSlugContext();
  const params = useQuery();
  const location = useLocation();
  const alarmFiltersFromURL = useGetAlarmFiltersFromURL();
  const isHistoricalAlarmsSelected = useIsHistoricalAlarms();

  const [expandSidebar, setExpandSidebar] = useState(false);
  const [forceExpand, setForceExpand] = useState(false);

  const historicalAlarmsPrefix = isHistoricalAlarmsSelected ? `/${HISTORICAL_ALARMS}` : '';

  const { userGroupDevicePaths } = useGetUserGroupDevicePaths();

  const [assestRoute, setAssestRoute] = useState(
    `/${slug}/app/alarms${historicalAlarmsPrefix}?alarm_type=${ALARM_TYPE_OPTIONS.ASSET.value}&severity=ALERT_FATAL,ALERT_ERROR`
  );
  const [sensoreRoute, setSensorRoute] = useState(
    `/${slug}/app/alarms${historicalAlarmsPrefix}?alarm_type=${ALARM_TYPE_OPTIONS.SENSOR.value}&severity=ALERT_FATAL,ALERT_ERROR`
  );

  const URLGenerator = (type: ALARM_TYPE, userGroupDevicePaths: string) => {
    const severity = alarmFiltersFromURL.severity || 'ALERT_FATAL,ALERT_ERROR';
    const path = alarmFiltersFromURL.path || userGroupDevicePaths;
    const modifiedBy = alarmFiltersFromURL.modifiedBy;
    const state = alarmFiltersFromURL.state;
    const site = alarmFiltersFromURL.site;
    const occurred = alarmFiltersFromURL.occurred;
    const alarmType = type;

    const paramsString: any = {
      ...(alarmType && { alarm_type: alarmType }),
      ...(severity && { severity }),
      ...(path && { path }),
      ...(modifiedBy && { modifiedBy }),
      ...(state && { state }),
      ...(site && { site }),
      ...(occurred && { occurred })
    };

    const qs = new URLSearchParams(paramsString).toString();
    return `?${qs}`;
  };

  useEffect(() => {
    if (slug || location.pathname === `/${slug}/app/alarms`) {
      setAssestRoute(
        `/${slug}/app/alarms${historicalAlarmsPrefix}${URLGenerator(
          ALARM_TYPE_OPTIONS.ASSET.value,
          userGroupDevicePaths
        )}`
      );
      setSensorRoute(
        `/${slug}/app/alarms${historicalAlarmsPrefix}${URLGenerator(
          ALARM_TYPE_OPTIONS.SENSOR.value,
          userGroupDevicePaths
        )}`
      );
    }
  }, [location.search, slug, userGroupDevicePaths]);

  const isSelected = useCallback(
    (alarmType: string) => {
      const alarmTypeFromURL = params.get('alarm_type');
      return alarmType === alarmTypeFromURL;
    },
    [params]
  );

  const alarmsMenuId: string = sidebarId.menuItems('alarms');

  useEffect(() => {
    if (location.pathname.includes(`alarms`)) {
      setExpandSidebar(true);
      /**
       * Trigger a re-render of the sidebar whenever the user navigates to the alarms page.
       */
      setForceExpand((prev) => !prev); // Trigger re-render by toggling another state
    }
  }, [location.pathname]);

  return (
    <SidebarMenu
      key={`${forceExpand}`} // This key will force the component to re-render
      testID={alarmsMenuId}
      route="#"
      textKey="alarm"
      defaultText="Alarms"
      expandSidebar={expandSidebar}
      icon={<AlarmsIcon className="MuiSvgIcon-root" />}>
      <SLSubmenuLink
        testID={`${alarmsMenuId}-asset_alarms`}
        route={assestRoute}
        textKey={ALARM_TYPE_OPTIONS.ASSET.key}
        defaultText={ALARM_TYPE_OPTIONS.ASSET.label}
        isActive={() => isSelected(ALARM_TYPE_OPTIONS.ASSET.value)}
      />
      <SLSubmenuLink
        testID={`${alarmsMenuId}-sensor_alarms`}
        route={sensoreRoute}
        textKey={ALARM_TYPE_OPTIONS.SENSOR.key}
        defaultText={ALARM_TYPE_OPTIONS.SENSOR.label}
        isActive={() => isSelected(ALARM_TYPE_OPTIONS.SENSOR.value)}
      />
    </SidebarMenu>
  );
}
