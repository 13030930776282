import { AnyAction } from 'redux';
import * as ACTION_TYPE from './rolesDetails.actionTypes';
import { RolesDetailsState } from './RolesDetails.State';
import { ROLES_DETAILS_STATUS } from './RolesDetails.Status';

const initialState: RolesDetailsState = {
  status: ROLES_DETAILS_STATUS.INIT,
  RolesDetailsData: []
};

function fetchRolesDetails(state: RolesDetailsState): RolesDetailsState {
  return {
    ...state,
    status: ROLES_DETAILS_STATUS.INIT
  };
}

function fetchRolesDetailsSuccess(
  state: RolesDetailsState,
  RolesDetailsData: any
): RolesDetailsState {
  return {
    ...state,
    status: ROLES_DETAILS_STATUS.READY,
    RolesDetailsData
  };
}

function fetchRolesDetailsFailure(state: RolesDetailsState): RolesDetailsState {
  return {
    ...state,
    status: ROLES_DETAILS_STATUS.ERROR
  };
}

function resetRolesDetails(state: RolesDetailsState): RolesDetailsState {
  return {
    ...state,
    status: ROLES_DETAILS_STATUS.INIT
  };
}

export default function rolesDetails(
  state: RolesDetailsState = initialState,
  action: AnyAction
): RolesDetailsState {
  switch (action.type) {
    case ACTION_TYPE.FETCH_ROLES_DETAILS:
      return fetchRolesDetails(state);
    case ACTION_TYPE.FETCH_ROLES_DETAILS_SUCCESS:
      return fetchRolesDetailsSuccess(state, action.payload);
    case ACTION_TYPE.FETCH_ROLES_DETAILS_FAILURE:
      return fetchRolesDetailsFailure(state);
    case ACTION_TYPE.FETCH_ROLES_DETAILS_RESET:
      return resetRolesDetails(state);
    default:
      return state;
  }
}
