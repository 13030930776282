/**
 * Conditional Formatted Column
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { ReactElement } from 'react';
import RedirectionView from '../../common/RedirectionView';
import { formulaEvaluator } from './formulaEvaluator';
import IconCell from './IconCell';

interface ConditionalColumnViewProps {
  conditions: any;
  value: any;
}

interface ValueObjectType {
  displayText: string;
  icon?: string;
  color?: string & {
    hex?: string;
  };

  bgColor?: string;
  format?: string;
  prefix?: string;
  suffix?: string;
}
function ConditionalColumnView({ conditions, value }: ConditionalColumnViewProps): ReactElement {
  // Find function will check first condition which is true for current value.
  // Although there are multiple conditions in an array, it will check only first one.
  // Hence user needs to set best match condition at the top
  const applicableCondition = conditions.find((condition: any) =>
    formulaEvaluator(condition, value)
  );
  const allFormatters = applicableCondition?.formatter || [];
  if (allFormatters?.length > 0) {
    const valueObject: ValueObjectType = {
      displayText: value
    };

    // TODO: This forEach is used just because of time constraint. I should use map.reduce function
    // here instead of this switch case and merge values into valueObject
    allFormatters.forEach((formatter: any) => {
      switch (formatter.type) {
        case 'text':
          valueObject.displayText = formatter.text;
          break;
        case 'color':
          valueObject.color = formatter.color?.hex || formatter.color;
          break;
        case 'bgcolor':
          valueObject.bgColor = formatter.bgcolor?.hex || formatter.bgcolor;
          break;
        case 'icon':
          valueObject.icon = formatter.icon;
          valueObject.color = formatter.color;
          break;
        case 'format':
          valueObject.format = formatter.format;
          break;
        case 'prefix':
          valueObject.prefix = formatter.prefix ? `${formatter.prefix} ` : '';
          break;
        case 'suffix':
          valueObject.suffix = formatter.suffix ? ` ${formatter.suffix}` : '';
          break;
        default:
          valueObject.color = formatter.color;
          break;
      }
    });
    if (valueObject.icon) {
      return (
        <>
          <IconCell
            iconName={valueObject.icon}
            // TODO: temporary workaround for widgets with having color as an object. Replace this later
            color={valueObject.color?.hex || valueObject.color}
          />
        </>
      );
    }
    if (valueObject.format) {
      // TODO: try converting value into date and then format it will specified formatter
    }
    return (
      <div
        style={{
          color: valueObject.color,
          backgroundColor: valueObject.bgColor
        }}>
        {valueObject.prefix}
        {valueObject.displayText}
        {valueObject.suffix}
      </div>
    );
  }
  return <>{value}</>;
}

interface ConditionalFormattedColumnProps {
  conditions?: Array<any>;
  data: any;
  redirection?: any;
  params?: any;
  rowData?: any;
  chartSettings: any;
}

const ConditionalFormattedColumn = ({
  conditions,
  data,
  redirection,
  params,
  rowData,
  chartSettings
}: ConditionalFormattedColumnProps) => {
  if (redirection && redirection.length > 0)
    return (
      <RedirectionView
        redirection={redirection}
        params={params}
        rowData={rowData}
        chartSettings={chartSettings}>
        {conditions && Object.keys(conditions).length > 0 ? (
          <ConditionalColumnView value={data} conditions={conditions} />
        ) : (
          <>{data}</>
        )}
      </RedirectionView>
    );
  return (
    <>
      {conditions && Object.keys(conditions).length > 0 ? (
        <ConditionalColumnView value={data} conditions={conditions} />
      ) : (
        <>{data}</>
      )}
    </>
  );
};

export default ConditionalFormattedColumn;
