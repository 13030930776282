export const LOCAL_DASHBOARD_ITEMS_SUCCESS = 'FEATURES/DASHBOARD/LOCAL_DASHBOARD_ITEMS_SUCCESS';
export const LOCAL_DASHBOARD_ITEMS_FAILURE = 'FEATURES/DASHBOARD/LOCAL_DASHBOARD_ITEMS_FAILURE';
export const LOCAL_DASHBOARD_ITEMS_PROGRESS = 'FEATURES/DASHBOARD/LOCAL_DASHBOARD_ITEMS_PROGRESS';
export const LOCAL_DASHBOARD_ITEMS_RESET_STATE =
  'FEATURES/DASHBOARD/LOCAL_DASHBOARD_ITEMS_RESET_STATE';
export const SET_LOCAL_DASHBOARD_ITEMS = 'FEATURES/DASHBOARD/SET_LOCAL_DASHBOARD_ITEMS';
export const ADD_ITEM_TO_LOCAL_DASHBOARD_ITEMS =
  'FEATURES/DASHBOARD/ADD_ITEM_TO_LOCAL_DASHBOARD_ITEMS';
export const UPDATE_ITEM_TO_LOCAL_DASHBOARD_ITEMS =
  'FEATURES/DASHBOARD/UPDATE_ITEM_TO_LOCAL_DASHBOARD_ITEMS';
export const DELETE_ITEM_FROM_LOCAL_DASHBOARD_ITEMS =
  'FEATURES/DASHBOARD/DELETE_ITEM_FROM_LOCAL_DASHBOARD_ITEMS';
export const UPDATE_LOCAL_DASHBOARD_ITEMS = 'FEATURES/DASHBOARD/UPDATE_LOCAL_DASHBOARD_ITEMS';
