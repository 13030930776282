/**
 * @author swapnil.kshirsagar@shorelineiot.com
 */
export const ON_VERIFICATION_PAGE = 'FEATURES/AUTH/ON_VERIFICATION_PAGE';
export const SIGNUP_PROGERSS = 'FEATURES/AUTH/SIGNUP_PROGERSS';
export const SIGNUP_SUCCESS = 'FEATURES/AUTH/SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'FEATURES/AUTH/SIGNUP_FAILURE';
export const REGISTER_WITH_GOOGLE = 'FEATURES/AUTH/REGISTER_WITH_GOOGLE';
export const REGISTER_WITH_LINKED_IN = 'FEATURES/AUTH/REGISTER_WITH_LINKED_IN';
export const RESET_REGISTER_STATE = 'FEATURES/AUTH/RESET_REGISTER';

export const CONFIRM_USER = 'FEATURES/AUTH/CONFIRM_USER';
export const CONFIRM_USER_SUCCESS = 'FEATURES/AUTH/CONFIRM_USER_SUCCESS';
export const CONFIRM_USER_FAILURE = 'FEATURES/AUTH/CONFIRM_USER_FAILURE';
