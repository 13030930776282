/**
 * Banner Reducer
 * @author mahesh.kedari@shorelineiot.com
 */
import { AnyAction } from 'redux';
import { BannerType } from '../../types';
import * as ACTIONS from './banner.actionTypes';
/**
 *
 * @param state Old State
 * @param payload Action Payload
 */
function showBanner(payload: any): BannerType | null {
  const banner: BannerType = {
    message: payload.message,
    closable: payload.config.closable,
    color: payload.config?.color ? payload.config.color : 'default',
    duration: payload.config?.duration ? payload.config?.duration : -1,
    icon: payload?.config?.icon ? payload.config?.icon : '',
    severity: payload?.config?.severity ? payload.config?.severity : 'info'
  };
  return banner;
}
/**
 *
 * @param state Old state
 * @param action Action to perform
 */
export default function bannerReducer(
  state: BannerType | null = null,
  action: AnyAction
): BannerType | null {
  switch (action.type) {
    case ACTIONS.HIDE_BANNER:
      return null;
    case ACTIONS.SHOW_BANNER:
      return showBanner(action.payload);
    default:
      return state;
  }
}
