/**
 * Device Rules Permissions,
 * @author mahesh.kedari@shorelineiot.com
 */
export const ALERTS_PERMISSIONS = {
  VIEW_LIST: 'view device_rule',
  CREATE: 'create device_rule',
  VIEW_DETAILS: 'view_details device_rule',
  DELETE: 'delete device_rule',
  UPDATE: 'update device_rule',
  DEFAULT_UPDATE: 'update default_rule'
};
