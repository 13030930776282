/**
 * Offline Indicator
 * @author mahesh.kedari@shorelineiot.com
 */
import React from 'react';
import SignalWifiOffIcon from '@mui/icons-material/SignalWifiOff';
import { Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useOnline } from '../../custom-hooks';
/**
 *
 * @param param0
 * @returns
 */

const useStyles = makeStyles((theme: Theme) => ({
  toast: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    bottom: '10px',
    left: 'calc(50% - 80px)',
    height: '40px',
    padding: '0 15px',
    backgroundColor: theme.palette.error.light,
    color: theme.palette.text.secondary,
    borderRadius: '10px',
    zIndex: 100000,
    justifyContent: 'space-evenly',
    boxShadow: '9px 7px 10px grey'
  }
}));
export function OfflineIndicator() {
  const classes = useStyles();
  const online = useOnline();
  if (online) {
    return null;
  }
  return (
    <div className={classes.toast}>
      <SignalWifiOffIcon />
      &nbsp;
      <Typography variant="h3"> You are offline.</Typography>
    </div>
  );
}
