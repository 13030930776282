export enum ID_PROVIDER_TAG {
  ID_PROVIDER_EXISTS = 'ID_PROVIDER_EXISTS'
}

export type IdProviderExistsArgs = {
  idp: string;
};

export type IdProviderExistsResult = {
  success: string;
};
