/**
 * Alarms Export Action Types
 * @author prerna.more@shorelineiot.com
 */
export const FETCH_ALARMS_EXPORT_LIST = 'FEATURES/ALARMS/ALARMS_EXPORT/FETCH_ALARMS_EXPORT_LIST';
export const FETCH_ALARMS_EXPORT_LIST_SUCCESS =
  'FEATURES/ALARMS/ALARMS_EXPORT/FETCH_ALARMS_EXPORT_LIST_SUCCESS';
export const FETCH_ALARMS_EXPORT_LIST_FAILURE =
  'FEATURES/ALARMS/ALARMS_EXPORT/FETCH_ALARMS_EXPORT_LIST_FAILURE';
export const FETCH_ALARMS_EXPORT_EMAIL_CHANGE =
  'FEATURES/ALARMS/ALARMS_EXPORT/FETCH_ALARMS_EXPORT_EMAIL_CHANGE';
export const FETCH_ALARMS_EXPORT_LIST_RESET =
  'FEATURES/ALARMS/ALARMS_EXPORT/FETCH_ALARMS_EXPORT_LIST_RESET';
export const SET_ALARMS_EXPORT_TYPE = 'FEATURES/ALARMS/ALARMS_EXPORT/SET_ALARMS_EXPORT_TYPE';
export const PERFORM_ALARMS_FILE_DOWNLOAD =
  'FEATURES/ALARMS/ALARMS_EXPORT/PERFORM_ALARMS_FILE_DOWNLOAD';
