/**
 * Create Anomaly Template State
 * @author aditya.bhadange@shorelineiot.com
 */
export enum createAnomalyTemplateStatus {
  INIT,
  PROGRESS,
  SUCCESS,
  ERROR
}

export interface createAnomalyTemplateState {
  status: createAnomalyTemplateStatus;
  payload?: any;
}
