/**
 * Data Specific Table Chart Editor
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { ReactElement } from 'react';
import { DASHBOARD_ITEM_TYPE } from '../../../dashboard-widgets/types/DashboardItemType';
import AnalyticsTableEditor from './AnalyticsTableEditor';
import RawDataTableEditor from './RawDataTableEditor';

interface Props {
  dataSettings: any;
  query: any;
  widgetType: DASHBOARD_ITEM_TYPE;
  handleUpdate: (data: any) => void;
}

const DataSpecificTableWidgetEditor = React.memo(
  ({ dataSettings, query, widgetType, handleUpdate }: Props): ReactElement => {
    return (
      <>
        {widgetType === DASHBOARD_ITEM_TYPE.ANALYTICS && (
          <AnalyticsTableEditor
            dataSettings={dataSettings}
            query={query}
            handleUpdate={handleUpdate}
          />
        )}
        {widgetType === DASHBOARD_ITEM_TYPE.RAW_DATA && (
          <RawDataTableEditor query={query} handleUpdate={handleUpdate} />
        )}
      </>
    );
  },
  (prevProps, nextProps) => prevProps.query === nextProps.query
);
export default DataSpecificTableWidgetEditor;
