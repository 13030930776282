/**
 * Component List Saga
 * @author mahesh.kedari@shorelineiot.com
 */
import { takeEvery, put, call } from 'redux-saga/effects';
import * as ACTIONS from './componentList.actionTypes';
import * as actions from './componentList.actions';
import { GenericObject, httpGet, SafeSaga, APISERVICES } from '../../../../../framework';
import { SagaIterator } from 'redux-saga';
/**
 *
 */
function getcomponentList() {
  return httpGet(`component_compiler`, null, APISERVICES.DEVICE_API);
}

function* fetchComponentListSaga() {
  const response: GenericObject = yield call(getcomponentList);

  yield put(actions.fetchComponentListSuccess(response?.components));
}
/**
 *
 * @param error
 */
function* errorHandler(error: any) {
  yield put(actions.fetchComponentListFailure(error));
}
/**
 *
 */
export function* watchFetchComponentListSaga(): SagaIterator {
  yield takeEvery(
    ACTIONS.FETCH_COMPONENT_LIST,
    SafeSaga(fetchComponentListSaga, ACTIONS.FETCH_COMPONENT_LIST, errorHandler)
  );
}
