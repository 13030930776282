/**
 * Delete Anomaly Template Actions
 * @author aditya.bhadange@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTIONS from './deleteAnomalyTemplate.actionTypes';

/**
 * Delete the Anomaly Template
 * @param payload Anomaly ID which need to be deleted.
 */
export const deleteAnomalyTemplate = (payload: any): AnyAction => ({
  type: ACTIONS.DELETE_ANOMALY_TEMPLATE,
  payload
});

export const deleteAnomalyTemplateSuccess = (): AnyAction => ({
  type: ACTIONS.DELETE_ANOMALY_TEMPLATE_SUCCESS
});

export const deleteAnomalyTemplateFailure = () => ({
  type: ACTIONS.DELETE_ANOMALY_TEMPLATE_FAILURE
});

export const deleteAnomalyTemplateReset = (): AnyAction => ({
  type: ACTIONS.DELETE_ANOMALY_TEMPLATE_RESET
});
