/**
 * add multiple dashbaord items
 */
export enum AddMultipleDashboardItemsStatus {
  INIT,
  FETCHING,
  READY,
  ERROR,
  RESET
}
export interface AddMultipleDashboardItemsState {
  status: AddMultipleDashboardItemsStatus;
  addMultipleDashboardItemsData: any;
}
