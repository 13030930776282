/**
 * Device Activity Actions
 * @author mahesh.kedari@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTIONS_TYPES from './deviceActivity.actionTypes';
/**
 *
 */
// export function getDeviceActivities(filterType: string): AnyAction {
//   return {
//     type: ACTIONS_TYPES.FETCH_ACTIVITIES,
//     payload: { filterType },
//   };
// }
// /**
//  *
//  */
// export function handleDeviceActivitiesSuccess(
//   activities: Array<any>
// ): AnyAction {
//   return {
//     type: ACTIONS_TYPES.FETCH_ACTIVITIES_SUCCESS,
//     payload: activities,
//   };
// }
// /**
//  *
//  */
// export function handleDeviceActivitiesFailure(error: Error): AnyAction {
//   return {
//     type: ACTIONS_TYPES.FETCH_ACTIVITIES_FAILURE,
//     payload: error,
//   };
// }
/**
 *
 */
export function exportLogs(): AnyAction {
  return {
    type: ACTIONS_TYPES.EXPORT_LOGS
  };
}
/**
 *
 */
export function exportLogsSuccess(): AnyAction {
  return {
    type: ACTIONS_TYPES.EXPORT_LOGS_SUCCESS
  };
}
/**
 *
 */
export function exportLogsFailure(): AnyAction {
  return {
    type: ACTIONS_TYPES.FETCH_ACTIVITIES_FAILURE
  };
}

export const fetchActivities = (data: any): AnyAction => {
  return {
    type: ACTIONS_TYPES.FETCH_ACTIVITIES,
    payload: data
  };
};
/**
 *
 * @param data
 */
export const fetchActivitiesSuccess = (data: any): AnyAction => {
  return {
    type: ACTIONS_TYPES.FETCH_ACTIVITIES_SUCCESS,
    payload: data
  };
};
/**
 *
 */
export const fetchActivitiesFailure = (): AnyAction => {
  return {
    type: ACTIONS_TYPES.FETCH_ACTIVITIES_FAILURE
  };
};

export const fetchActivitiesReset = (): AnyAction => {
  return {
    type: ACTIONS_TYPES.FETCH_ACTIVITIES_RESET,
    payload: false
  };
};
