/**
 * Raw Datapoints Actions
 * @author aditya.bhadange@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTIONS from './rawDeviceDatapoints.actionTypes';
import { Device } from '..';

export function fetchRawDatapoints(slug: string, deviceIds: Device[] | string[]): AnyAction {
  return {
    type: ACTIONS.FETCH_RAW_DATAPOINTS,
    payload: {
      slug,
      deviceIds
    }
  };
}

export function fetchRawDatapointsSuccess(data: any): AnyAction {
  return {
    type: ACTIONS.FETCH_RAW_DATAPOINTS_SUCCESS,
    payload: data
  };
}

export function fetchRawDatapointsError(error: any): AnyAction {
  return {
    type: ACTIONS.FETCH_RAW_DATAPOINTS_FAILURE,
    payload: { error }
  };
}

export function resetRawDatapointsState(): AnyAction {
  return {
    type: ACTIONS.RESET_RAW_DATAPOINTS_STATE
  };
}
