/**
 * Column Chart Editor
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { useState } from 'react';
import merge from 'lodash/merge';
import { useFormContext } from 'react-hook-form';
import { ChartEditorProps } from '../common/ChartEditorProps';
import ResetDefaultConfig from '../common/ResetDefaultConfig';
import CartesianChartCommonSettings from '../cartesian-chart/settings/CartesianChartCommonSettings';
import DataSpecificCartesianChartSettings from '../cartesian-chart/settings/DataSpecificCartesianChartSettings';
import { cartesianFormDefaults } from '../chartEditor.utils';
/**
 *
 * @param param0
 * @returns
 */
const SLColumnChartEditor: React.FC<ChartEditorProps> = ({
  handleSubmitForm,
  defaultValues,
  dataConfig,
  widgetType
}: ChartEditorProps) => {
  const [settings, setSettings] = useState<any>({
    chart_type: 'column',
    chartSettings: Object.keys(defaultValues).length !== 0 ? defaultValues : cartesianFormDefaults
  });
  const { getValues } = useFormContext();

  const handleUpdate = () => {
    const values = getValues();
    const updatedSettings = merge({}, settings);
    // updating local variable here
    const mergedSettings = {
      ...updatedSettings,
      chartSettings: {
        ...values
      }
    };
    setSettings(mergedSettings);
    handleSubmitForm(mergedSettings);
  };

  return (
    <>
      <CartesianChartCommonSettings
        currentSettings={settings.chartSettings}
        handleUpdate={handleUpdate}
      />
      <DataSpecificCartesianChartSettings
        type={widgetType}
        query={dataConfig?.query}
        chartSettings={settings.chartSettings}
        handleUpdate={handleUpdate}
      />
      <ResetDefaultConfig chartType="column" onReset={handleUpdate} />
    </>
  );
};
export default SLColumnChartEditor;
