/**
 * User self Status
 * @author swapnil.kshirsagar@shorelineiot.com
 */
export enum USER_SELF_STATUS {
  INIT,
  FETCHING_SELF,
  READY,
  ERROR,
  RESET
}
