import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginLeft: '-4px'
    },
    saveButtonContainer: {
      marginLeft: '-2px'
    }
  })
);
