export enum DEVICE_MAILBOX_STATUS {
  INIT,
  FETCHING,
  READY,
  ERROR
}

export interface DeviceMailboxState {
  status: DEVICE_MAILBOX_STATUS;
  DeviceMailboxData: any;
  pathList: any;
}
