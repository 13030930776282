/**
 * Vibration Analysis Actions
 * @author mahesh.kedari@shorelineiot.com
 */
import { AnyAction } from 'redux';
import { FFT_DOMAIN } from './fft-chart/fftChart.stateType';
import {
  UnitsPayload,
  UnitInfoType,
  IndicatorsOptionTypes,
  TimeChartOptionsType
} from './va-units/vaUnit.stateType';
import { DiagnosisType } from './events/vaEvents.stateType';
import * as ACTIONS from './vibrationAnalysis.actionTypes';
/** Device */
export function fetchDatapoints(data: any): AnyAction {
  return {
    type: ACTIONS.FETCH_DATAPOINTS,
    payload: data
  };
}

export function fetchDatapointsSuccess(data: any): AnyAction {
  return {
    type: ACTIONS.FETCH_DATAPOINTS_SUCCESS,
    payload: data
  };
}

export function fetchDatapointsError(error: any): AnyAction {
  return {
    type: ACTIONS.FETCH_DATAPOINTS_FAILURE,
    payload: { error }
  };
}

export function resetDeviceState(): AnyAction {
  return {
    type: ACTIONS.RESET_DEVICE_STATE
  };
}
export function fullResetDeviceState(): AnyAction {
  return {
    type: ACTIONS.FULL_RESET_DEVICE_STATE
  };
}
export function fullResetEventsState(): AnyAction {
  return {
    type: ACTIONS.FULL_RESET_EVENTS
  };
}
export function updateVATrueDatapoints(data: any): AnyAction {
  return {
    type: ACTIONS.UPDATE_VA_TRUE_DATAPOINTS,
    payload: data
  };
}
export function updateVATrueDatapointsFromWS(data: any): AnyAction {
  return {
    type: ACTIONS.UPDATE_VA_TRUE_DATAPOINTS_FROM_WS,
    payload: data
  };
}

export function updateDatapointSelection(
  devices: Array<any>,
  slug: string,
  start: any,
  end: any,
  event?: {
    eventId: number;
  }
): AnyAction {
  return {
    type: ACTIONS.UPDATE_DATAPOINT_SELECTION,
    payload: {
      slug,
      devices,
      event,
      start,
      end
    }
  };
}
export function setOnlyDateTimePickerValues(data: any): AnyAction {
  return {
    type: ACTIONS.SET_ONLY_DATE_TIME_PICKER_VALUES,
    payload: data
  };
}
/** Events */

export function updateDateTimePickerValues(data: any): AnyAction {
  return {
    type: ACTIONS.UPDATE_DATE_TIME_PICKER_VALUES,
    payload: data
  };
}

export function fetchEvents(
  slug: string,
  selectedNarrowband: any,
  units: UnitsPayload,
  start: any,
  end: any,
  paramNBDpid?: any
): AnyAction {
  return {
    type: ACTIONS.FETCH_EVENTS,
    payload: {
      slug,
      selectedNarrowband,
      units,
      start,
      end,
      paramNBDpid
    }
  };
}

export function fetchEventsSuccess(
  events: Array<any>,
  selectedUnit: string,
  selectedNBDpid?: any
): AnyAction {
  return {
    type: ACTIONS.FETCH_EVENTS_SUCCESS,
    payload: {
      events,
      selectedUnit,
      selectedNBDpid
    }
  };
}

export function recalculateNBs(selectedEvents: any, units: UnitsPayload): AnyAction {
  return {
    type: ACTIONS.RECALCULATE_NARROWBANDS,
    payload: {
      selectedEvents,
      units
    }
  };
}

export function fetchEventsFailure(error: any): AnyAction {
  return {
    type: ACTIONS.FETCH_EVENTS_FAILURE,
    payload: { error }
  };
}

export function resetEvents(): AnyAction {
  return {
    type: ACTIONS.RESET_EVENTS
  };
}

export function fetchDataPointsTrendChart(slug: string, devicesList: any): AnyAction {
  return {
    type: ACTIONS.FETCH_DATAPOINTS_TREND_CHART,
    payload: {
      slug,
      devicesList
    }
  };
}
export function fetchDataPointsTrendChartSuccess(datapoints: Array<any>): AnyAction {
  return {
    type: ACTIONS.FETCH_DATAPOINTS_TREND_CHART_SUCCESS,
    payload: datapoints
  };
}

export function fetchDataPointsTrendChartFailure(error: any): AnyAction {
  return {
    type: ACTIONS.FETCH_DATAPOINTS_TREND_CHART_FAILURE,
    payload: { error }
  };
}
export function fetchHistoricalData(data: any): AnyAction {
  return {
    type: ACTIONS.FETCH_HISTORICAL_DATA,
    payload: data
  };
}
export function fetchHistoricalDataSuccess(additionalSensorsData: Array<any>): AnyAction {
  return {
    type: ACTIONS.FETCH_HISTORICAL_DATA_SUCCESS,
    payload: {
      additionalSensorsData
    }
  };
}

export function fetchHistoricalDataFailure(error: any): AnyAction {
  return {
    type: ACTIONS.FETCH_HISTORICAL_DATA_FAILURE,
    payload: { error }
  };
}
/** Device Events */
export function fetchDeviceEvents(
  slug: string,
  devices: Array<any>,
  event?: any,
  narrowband?: any
): AnyAction {
  return {
    type: ACTIONS.FETCH_DEVICE_EVENTS,
    payload: {
      slug,
      devices,
      event,
      narrowband
    }
  };
}

export function fetchDeviceEventsSuccess(events: any): AnyAction {
  return {
    type: ACTIONS.FETCH_DEVICE_EVENTS_SUCCESS,
    payload: events
  };
}

export function fetchDeviceEventsFailure(error: any): AnyAction {
  return {
    type: ACTIONS.FETCH_DEVICE_EVENTS_FAILURE,
    payload: { error }
  };
}

export interface EventSelectionPayload {
  units: UnitsPayload;
  event: any;
  events: Array<any>;
  slug: string;
  fftDomain: FFT_DOMAIN;
  selectedUnits: UnitsPayload;
  title: string;
  devices: Array<any>;
  fftchartStatePayload: any;
  selectedEvent: any;
  selectedNarrowband: any;
  start: any;
  end: any;
}
export function updateEventSelection(payload: EventSelectionPayload): AnyAction {
  return {
    type: ACTIONS.UPDATE_EVENT_SELECTION,
    payload
  };
}
export function updateMergeAxisSelection(payload: { isMergeAxisSelected: boolean }): AnyAction {
  return {
    type: ACTIONS.UPDATE_MERGE_AXIS_SELECTION,
    payload
  };
}
export function updateEventTitle(title: any): AnyAction {
  return {
    type: ACTIONS.UPDATE_EVENT_TITLE,
    payload: title
  };
}
export function updateTrendChartData(data: any): AnyAction {
  return {
    type: ACTIONS.UPDATE_TREND_CHART_DATA,
    payload: data
  };
}
/** Narrowbands */
export function fetchNarrowbands(
  slug: string,
  units: UnitsPayload,
  devices: Array<any>,
  events?: Array<any>,
  nbdpid?: number,
  checkednb?: boolean
): AnyAction {
  return {
    type: ACTIONS.FETCH_NARROWBANDS,
    payload: {
      slug,
      units,
      devices,
      events,
      nbdpid,
      checkednb
    }
  };
}

export function fetchNarrowbandsSuccess(
  rawNarrowbands: Array<any>,
  units: UnitsPayload,
  selectedEvents: any,
  nbdpid?: number,
  checkednb?: boolean
): AnyAction {
  return {
    type: ACTIONS.FETCH_NARROWBANDS_SUCCESS,
    payload: {
      rawNarrowbands,
      units,
      selectedEvents,
      nbdpid,
      checkednb
    }
  };
}

export function handleNarrowbandsAfterSelectedEventUpdate(
  rawNarrowbands: Array<any>,
  units: UnitsPayload,
  selectedEvents: any
) {
  return {
    type: ACTIONS.NARROWBANDS_AFTER_EVENT_UPDATE,
    payload: { rawNarrowbands, units, selectedEvents }
  };
}

export function fetchNarrowbandsFailure(error: any): AnyAction {
  return {
    type: ACTIONS.FETCH_NARROWBANDS_FAILURE,
    payload: { error }
  };
}

export function resetNarrowbands(): AnyAction {
  return {
    type: ACTIONS.RESET_NARROWBANDS
  };
}

export function updateNarrowbandsSelection(
  slug: string,
  selectedNarrowband: any,
  units: UnitsPayload,
  devices: any,
  start: any,
  end: any,
  speedValue: any
): AnyAction {
  return {
    type: ACTIONS.UPDATE_NARROWBANDS_SELECTION,
    payload: {
      slug,
      selectedNarrowband,
      units,
      devices,
      start,
      end,
      speedValue
    }
  };
}

export function updateCheckedNarrowbands(narrowbands: Array<any>): AnyAction {
  return {
    type: ACTIONS.UPDATE_CHECKED_NARROWBANDS,
    payload: narrowbands
  };
}

export function updateBulkCheckedNarrowbands(narrowbands: Array<any>): AnyAction {
  return {
    type: ACTIONS.UPDATE_BULK_CHECKED_NARROWBANDS,
    payload: narrowbands
  };
}

export function changeNarrowbandsODR(odr: number): AnyAction {
  return {
    type: ACTIONS.CHANGE_ODR_NARROWBANDS,
    payload: odr
  };
}
export function fetchLatestNarrowbandForDevice(
  slug: string,
  units: UnitsPayload,
  devices: Array<any>,
  nbdpid?: number,
  checkednb?: boolean
): AnyAction {
  return {
    type: ACTIONS.FETCH_NARROWBAND_DATA,
    payload: {
      slug,
      units,
      devices,
      nbdpid,
      checkednb
    }
  };
}

export function fetchNarrowbandDataSuccess(
  latestNarrowbandsForDevices: Array<any>,
  units: UnitsPayload,
  selectedEvents: any,
  nbdpid?: number,
  checkednb?: boolean
): AnyAction {
  return {
    type: ACTIONS.FETCH_NARROWBAND_DATA_SUCCESS,
    payload: {
      latestNarrowbandsForDevices,
      units,
      selectedEvents,
      nbdpid,
      checkednb
    }
  };
}
/** Harmonics */
export function updateHarmonics(value: boolean): AnyAction {
  return {
    type: ACTIONS.UPDATE_HARMONICS,
    payload: value
  };
}
export function updateHarmonicsCount(value: number): AnyAction {
  return {
    type: ACTIONS.UPDATE_HARMONICS_COUNT,
    payload: value
  };
}
export function updateLockHarmonics(value: boolean): AnyAction {
  return {
    type: ACTIONS.UPDATE_LOCK_HARMONICS_STATUS,
    payload: value
  };
}
/** FFT Chart Indicator */
export function setCurrentIndicator(value: IndicatorsOptionTypes): AnyAction {
  return {
    type: ACTIONS.SET_CURRENT_INDICATORS,
    payload: value
  };
}

/** Motion Unit */
export function fetchMotionUnits(slug: string | undefined): AnyAction {
  return {
    type: ACTIONS.FETCH_MOTION_UNITS,
    payload: {
      slug
    }
  };
}

export function fetchMotionUnitsSuccess(data: UnitInfoType): AnyAction {
  return {
    type: ACTIONS.FETCH_MOTION_UNITS_SUCCESS,
    payload: data
  };
}

export function fetchMotionUnitsFailure(error: any): AnyAction {
  return {
    type: ACTIONS.FETCH_MOTION_UNITS_FAILURE,
    payload: { error }
  };
}

export function resetMotionUnits(): AnyAction {
  return {
    type: ACTIONS.RESET_MOTION_UNITS
  };
}

export function updateSelectedMotionUnit(data: {
  slug: string | undefined;
  value: any;
  units: UnitsPayload;
}): AnyAction {
  return {
    type: ACTIONS.UPDATE_SELECTED_MOTION_UNIT,
    payload: data
  };
}
export function updateSelectedMotionUnitSuccess(data: {
  slug: string | undefined;
  value: any;
  units: UnitsPayload;
}): AnyAction {
  return {
    type: ACTIONS.UPDATE_SELECTED_MOTION_UNIT_SUCCESS,
    payload: data
  };
}
/** Other Units Change */
export function updateFrequencyUnit(value: any, units: UnitsPayload): AnyAction {
  return {
    type: ACTIONS.UPDATE_FREQUENCY_UNIT,
    payload: { value, units }
  };
}
export function updateAmplitudeSelection(value: any, units: UnitsPayload): AnyAction {
  return {
    type: ACTIONS.UPDATE_AMPLITUDE_SELECTION,
    payload: {
      value,
      units
    }
  };
}

export function resetVibrationAnalysis() {
  return {
    type: ACTIONS.RESET_VIBRATION_ANALYSIS
  };
}

/** FFT Domain */
export function updateFFTDomain(value: any): AnyAction {
  return {
    type: ACTIONS.UPDATE_FFT_DOMAIN,
    payload: { value }
  };
}

/** FFT Time and Frequency */
export function fetchFFTSpectrum(data: any): AnyAction {
  return {
    type: ACTIONS.FETCH_FFT_SPECTRUM,
    payload: data
  };
}

export function updateFFTChartForZeroDeviceEvent(): AnyAction {
  return {
    type: ACTIONS.UPDATE_FFT_CHART_FOR_ZERO_DEVICE_EVENT
  };
}
export function updateTimeDomainChartForZeroDeviceEvent(): AnyAction {
  return {
    type: ACTIONS.UPDATE_TIME_DOMAIN_CHART_FOR_ZERO_DEVICE_EVENT
  };
}
export function updateFFTFrequency(data: any): AnyAction {
  return {
    type: ACTIONS.UPDATE_FFT_FREQUENCY_DATA,
    payload: data
  };
}
export function updateFFTTime(data: any): AnyAction {
  return {
    type: ACTIONS.UPDATE_FFT_TIME_DATA,
    payload: data
  };
}
/** FFT Peak Search */
export function updateFFTPeakSearch(data: any): AnyAction {
  return {
    type: ACTIONS.UPDATE_FFT_PEAK_SEARCH,
    payload: data
  };
}
export function updateFFTPeakSearchZoom(data: any): AnyAction {
  return {
    type: ACTIONS.UPDATE_FFT_ZOOM,
    payload: data
  };
}

/** FFT peakSearch */
export function updateFFTHarmonicsList(data: any): AnyAction {
  return {
    type: ACTIONS.UPDATE_HARMONICS_LIST,
    payload: data
  };
}
export function updateFFTHarmonicsOriginalList(data: any): AnyAction {
  return {
    type: ACTIONS.UPDATE_HARMONICS_ORIGINAL_LIST,
    payload: data
  };
}
/** FFT SpectrumSeries ID */

export function updateSpectrumSeriesId(data: any): AnyAction {
  return {
    type: ACTIONS.UPDATE_SPECTRUM_SERIES_ID,
    payload: data
  };
}
export function resetFFTChart(): AnyAction {
  return {
    type: ACTIONS.RESET_FFT_CHART
  };
}

export function updateCpm(slug: string, deviceId: number, eventId: number, cpm: number): AnyAction {
  return {
    type: ACTIONS.UPDATE_CPM,
    payload: {
      slug,
      deviceId,
      eventId,
      cpm
    }
  };
}

export function updateCpmSuccess() {
  return {
    type: ACTIONS.UPDATE_CPM_SUCCESS
  };
}

export function getEventDiagnosis(data: {
  slug: string | undefined;
  deviceId: number;
  eventId: number;
}): AnyAction {
  return {
    type: ACTIONS.FETCH_EVENT_DIAGNOSIS,
    payload: data
  };
}

export function getEventDiagnosisSuccess(data: DiagnosisType): AnyAction {
  return {
    type: ACTIONS.FETCH_EVENT_DIAGNOSIS_SUCCESS,
    payload: data
  };
}
/** FFT Chart Sideband */
export function setSidebandState(value: boolean): AnyAction {
  return {
    type: ACTIONS.SET_SIDEBAND_STATE,
    payload: value
  };
}

export function setLockSidebandState(value: boolean): AnyAction {
  return {
    type: ACTIONS.SET_LOCK_SIDEBAND_STATE,
    payload: value
  };
}

/** FFT Time Chart */
export function setTimeChartCurrentIndicator(value: TimeChartOptionsType): AnyAction {
  return {
    type: ACTIONS.SET_TIME_CHART_CURRENT_INDICATORS,
    payload: value
  };
}

export function setLockDeltaMarkerState(value: boolean): AnyAction {
  return {
    type: ACTIONS.SET_LOCK_DELTA_MARKER_STATE,
    payload: value
  };
}

export function setDeltaMarkerState(value: boolean): AnyAction {
  return {
    type: ACTIONS.SET_DELTA_MARKER_STATE,
    payload: value
  };
}

/** FFT Time Chart Sliding Window */

export function setSlidingWindowState(value: boolean): AnyAction {
  return {
    type: ACTIONS.SET_SLIDING_WINDOW_STATE,
    payload: value
  };
}

export function updateSlidingWindowCount(value: number): AnyAction {
  return {
    type: ACTIONS.UPDATE_SLIDING_WINDOW_COUNT,
    payload: value
  };
}
