/**
 * Update Dashboard Item Saga
 */

import { put, takeEvery, call } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import * as UPDATE_DASHBOARD_ITEM_ACTION_TYPES from './updateDashboardItem.actionsTypes';
import * as UPDATE_DASHBOARD_ITEM_ACTIONS from './updateDashboardItem.action';
import { GenericObject, removeProgressFor, httpPut, APISERVICES } from '../../../../framework';
import { SagaIterator } from 'redux-saga';

function updateDashboardItemIntoDatabase(data: any) {
  return httpPut(
    `orgs/${data.slug}/dashboard_item/${data.dashboard_item_uuid}`,
    {
      config: data.config
    },
    APISERVICES.HOST_API
  );
}

export function* updateDashboardItem(action: AnyAction) {
  try {
    const response: GenericObject = yield call(updateDashboardItemIntoDatabase, action.payload);
    yield put(UPDATE_DASHBOARD_ITEM_ACTIONS.handleUpdateDashboardItemSuccess(response));
  } catch (error: any) {
    yield put(UPDATE_DASHBOARD_ITEM_ACTIONS.handleUpdateDashboardItemFailure(error));
  } finally {
    yield put(removeProgressFor('UPDATE_DASHBOARD_ITEM'));
  }
}

export function* watchUpdateDashboardItemSaga(): SagaIterator {
  yield takeEvery(
    UPDATE_DASHBOARD_ITEM_ACTION_TYPES.UPDATE_DASHBOARD_ITEM_PROGRESS,
    updateDashboardItem
  );
}
