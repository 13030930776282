export const GET_AUTO_NARROWBAND_LIST =
  'FEATURES/DEVICE/DEVICE-SETTINGS/AUTOMATIC-CONFIG/ACTIONS/GET_AUTO_NARROWBAND_LIST';
export const GET_AUTO_NARROWBAND_LIST_SUCCESS =
  'FEATURES/DEVICE/DEVICE-SETTINGS/AUTOMATIC-CONFIG/ACTIONS/GET_AUTO_NARROWBAND_LIST_SUCCESS';
export const GET_AUTO_NARROWBAND_LIST_FAILURE =
  'FEATURES/DEVICE/DEVICE-SETTINGS/AUTOMATIC-CONFIG/ACTIONS/GET_AUTO_NARROWBAND_LIST_FAILURE';
export const GET_AUTO_NARROWBAND_LIST_RESET =
  'FEATURES/DEVICE/DEVICE-SETTINGS/AUTOMATIC-CONFIG/ACTIONS/GET_AUTO_NARROWBAND_LIST_RESET';
export const SET_NARROWBAND_STATUS =
  'FEATURES/DEVICE/DEVICE-SETTINGS/AUTOMATIC-CONFIG/ACTIONS/SET_NARROWBAND_STATUS';

export const GET_AUTO_NARROWBAND_TABLE_LIST =
  'FEATURES/DEVICE/DEVICE-SETTINGS/AUTOMATIC-CONFIG/ACTIONS/GET_AUTO_NARROWBAND_TABLE_LIST';
export const GET_AUTO_NARROWBAND_TABLE_LIST_SUCCESS =
  'FEATURES/DEVICE/DEVICE-SETTINGS/AUTOMATIC-CONFIG/ACTIONS/GET_AUTO_NARROWBAND_TABLE_LIST_SUCCESS';
export const GET_AUTO_NARROWBAND_TABLE_LIST_FAILURE =
  'FEATURES/DEVICE/DEVICE-SETTINGS/AUTOMATIC-CONFIG/ACTIONS/GET_AUTO_NARROWBAND_TABLE_LIST_FAILURE';
export const GET_AUTO_NARROWBAND_TABLE_LIST_RESET =
  'FEATURES/DEVICE/DEVICE-SETTINGS/AUTOMATIC-CONFIG/ACTIONS/GET_AUTO_NARROWBAND_TABLE_LIST_RESET';
