/**
 * Custom hook - useAPI Reducer
 * @author priyanka.ambawane@shorelineiot.com
 */
import * as Actions from '../actions/useAPI.actionTypes';

const initialState = {
  isLoading: false
};

/*
    Reducer is a pure function that takes actions and state as input 
    and returns/passes the modified state to the store.
    It is implemented using switch case to update the state according 
    to the ation type required.
*/

export default function useAPIReducer(state = initialState, action: any) {
  switch (action.type) {
    case Actions.LOADER_START:
      return {
        ...state, // creates a copy of the state object and only changes the values that needs to be changed
        isLoading: true
      };
    case Actions.LOADER_STOP:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
}
