import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { DownloadIcon } from '../../icons';
import { MRT_Column, MRT_Row, MRT_TableInstance } from 'material-react-table';
import { CSVLink } from 'react-csv';
import { csvExportOptions } from './slTable.types';
import TranslateText from '../../../i18n/TranslateText';

interface Props {
  table: MRT_TableInstance<any>;
  disabled?: boolean;
  csvOptions?: csvExportOptions;
}

const SLExportButton = ({ table, disabled, csvOptions }: Props) => {
  const headers = table.getAllColumns().map((column: MRT_Column<any>) => ({
    label: column?.columnDef?.header,
    key: column?.id
  }));

  // To avoid defaulting to a .txt file or an incorrect format when
  // the filename contains special characters or multiple periods
  const filename = (csvOptions?.filename ?? 'table-data-export') + '.csv';

  const handleExportRows = (rows: MRT_Row<any>[]) => {
    return rows.map((row) => {
      const rowData: { [key: string]: any } = {}; // Object for key-value pairs
      table.getAllColumns().forEach((column: MRT_Column<any>) => {
        const rowValue = row.getValue(column.id);
        rowData[column.id] = Array.isArray(rowValue) ? rowValue.toString() : rowValue;
      });
      return rowData;
    });
  };

  const csvData = handleExportRows(table.getPrePaginationRowModel().rows);

  return (
    <Tooltip arrow title={TranslateText('export', 'Export')}>
      <IconButton disabled={disabled}>
        <CSVLink
          data={csvData ?? [{}]}
          headers={csvOptions?.customHeaders ?? headers}
          separator={csvOptions?.separator ?? ','}
          filename={filename}
          style={{ textDecoration: 'none', color: 'inherit' }}>
          <DownloadIcon className={`MuiSvgIcon-root`} />
        </CSVLink>
      </IconButton>
    </Tooltip>
  );
};

export default SLExportButton;
