import React from 'react';
import EventsSelectDropdown from '../../../../../features/workflow/trend-analysis/waterfall/event-trends/EventsSelectDropdown';
import { DownArrowTableIcon } from '../../../../icons';
import { SLButton } from '../../../button';
import { Popover } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  popoverStyle: {
    borderRadius: '15px',
    width: '300px'
  }
}));

export default function OptionsPopover({ deviceEventsList, selectedEvent, setSelectedEvent }: any) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const id = open ? 'OptionsPopover' : undefined;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <SLButton
        size="small"
        variant="outlined"
        color="inherit"
        onClick={handleClick}
        endIcon={<DownArrowTableIcon />}
        translationId="events">
        Events
      </SLButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        classes={{
          paper: classes.popoverStyle
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}>
        <EventsSelectDropdown
          deviceEvents={deviceEventsList}
          selectedEvent={selectedEvent}
          setSelectedEvent={setSelectedEvent}
        />
      </Popover>
    </>
  );
}
