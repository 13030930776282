import React, { useEffect, useState } from 'react';
import {
  ColorRanges,
  CompleteDialWidgetSettingsType,
  DISPLAY_RANGE_SELECTION_OPTIONS,
  DisplayRangeSelectionOptions
} from './dialWidgetConstants';
import { Divider, Grid, IconButton, TextField, Typography } from '@mui/material';
import { useStyles } from './dialWidget.styles';
import { ColorResult } from 'react-color';
import { SLButton } from '../../button';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DialWidgetColorPicker from './DialWidgetColorPicker';
import TranslateText from '../../../../i18n/TranslateText';

const DialWidgetColorConfigurator = ({
  handleSubmitForm,
  settings,
  setSettings,
  min,
  max,
  displayRangeSelection
}: {
  handleSubmitForm: (value: any) => void;
  settings: CompleteDialWidgetSettingsType;
  setSettings: React.Dispatch<React.SetStateAction<CompleteDialWidgetSettingsType>>;
  min?: string;
  max?: string;
  displayRangeSelection: DisplayRangeSelectionOptions;
}) => {
  const classes = useStyles();
  const [colorRanges, setColorRanges] = useState<ColorRanges>(
    settings?.chartSettings?.colorRanges ? settings.chartSettings.colorRanges : {}
  );

  const addColourRange = () => {
    const newColorRange = {
      from: '-1',
      to: '-1',
      color: '#808080'
    };
    const ids = Object.keys(colorRanges);
    let maxId = 0;
    ids.map((id) => {
      const currId = parseInt(id);
      if (currId > maxId) {
        maxId = currId;
      }
    });
    setColorRanges({ ...colorRanges, [maxId + 1]: newColorRange });
  };

  const handleColorChange = (color: ColorResult, colorRangeId: number) => {
    const newColorRange = { ...colorRanges[colorRangeId] };
    newColorRange.color = color.hex;
    setColorRanges({ ...colorRanges, [colorRangeId]: newColorRange });
  };

  const handleFromChange = (
    from: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    colorRangeId: number
  ) => {
    if (from) {
      const newColorRange = { ...colorRanges[colorRangeId] };
      newColorRange.from = from.target.value;
      setColorRanges({ ...colorRanges, [colorRangeId]: newColorRange });
    }
  };

  const handleToChange = (
    to: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    colorRangeId: number
  ) => {
    if (to) {
      const newColorRange = { ...colorRanges[colorRangeId] };
      newColorRange.to = to.target.value;
      setColorRanges({ ...colorRanges, [colorRangeId]: newColorRange });
    }
  };

  const deleteColorRange = (colorRangeId: number) => {
    if (colorRangeId in colorRanges) {
      const newColourRanges = { ...colorRanges };
      delete newColourRanges[colorRangeId];
      setColorRanges(newColourRanges);
    }
  };

  const getFromFieldError = (colorRangeId: number) => {
    const from = Number(colorRanges[colorRangeId].from);
    if (from === -1) {
      return false;
    }
    if (min && max && displayRangeSelection === DISPLAY_RANGE_SELECTION_OPTIONS.MANUAL) {
      if (from < Number(min) || from > Number(max)) {
        return true;
      }
    }
    return false;
  };

  const getFromHelperText = (colorRangeId: number) => {
    if (getFromFieldError(colorRangeId)) {
      return 'Min<=From<=Max';
    } else {
      return '';
    }
  };

  const getToFieldError = (colorRangeId: number) => {
    const to = Number(colorRanges[colorRangeId].to);
    if (to === -1) {
      return false;
    }
    if (min && max && displayRangeSelection === DISPLAY_RANGE_SELECTION_OPTIONS.MANUAL) {
      if (to < Number(min) || to > Number(max) || to <= Number(colorRanges[colorRangeId].from)) {
        return true;
      }
    }
    return false;
  };
  const getToHelperText = (colorRangeId: number) => {
    if (min && max) {
      const to = Number(colorRanges[colorRangeId].to);
      if (to < Number(min) || to > Number(max)) {
        return 'Min<=From<=Max';
      }
      if (to <= Number(colorRanges[colorRangeId].from)) {
        return 'To>From';
      }
    } else {
      return '';
    }
  };

  useEffect(() => {
    const newChartSettings = { ...settings.chartSettings, colorRanges };
    setSettings({ ...settings, chartSettings: newChartSettings });
    const updatedSettings = { ...settings, chartSettings: newChartSettings };
    handleSubmitForm(updatedSettings);
  }, [colorRanges]);

  return (
    <>
      <Typography> {TranslateText('color_configurator', 'Color Configurator')}:</Typography>
      {Object.keys(colorRanges).map((colorRangeId, index) => {
        const id = parseInt(colorRangeId);
        return (
          <Grid container direction="row" justifyContent="space-between" key={index} spacing={2}>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <TextField
                label={TranslateText('from', 'From')}
                value={Number(colorRanges[id].from) !== -1 ? colorRanges[id].from : null}
                onChange={(from) => handleFromChange(from, id)}
                error={getFromFieldError(id)}
                helperText={getFromHelperText(id)}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <TextField
                label={TranslateText('to', 'To')}
                value={Number(colorRanges[id].to) !== -1 ? colorRanges[id].to : null}
                onChange={(to) => handleToChange(to, id)}
                error={getToFieldError(id)}
                helperText={getToHelperText(id)}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1} sx={{ marginTop: '10px' }}>
              <DialWidgetColorPicker
                id={id}
                handleColorChange={handleColorChange}
                color={colorRanges[id].color}
              />
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2} sx={{ marginTop: '5px' }}>
              <IconButton onClick={() => deleteColorRange(id)}>
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            </Grid>
          </Grid>
        );
      })}
      <Divider className={classes.divider} />
      <SLButton
        onClick={addColourRange}
        dataTestId="dial-widget-add-color-range-button"
        style={{ marginBottom: '10px' }}
        translationId="add_a_color_range">
        Add a Color Range
      </SLButton>
    </>
  );
};

export default DialWidgetColorConfigurator;
