/**
 * Narrowband Rule List Sagas
 * @author priyanka.ambawane@shorelineiot.com
 */

import { put, takeEvery, call } from 'redux-saga/effects';

import * as updateNarrowbandRuleActions from './nbRuleUpdate.actions';
import { datapointActions } from '../device-settings/device-data/actions';
import * as UPDATE_NARROWBAND_RULE_ACTIONS from './nbRuleUpdate.actionTypes';
import { formToApiDataParser } from '../createRule.parsers';
import {
  GenericObject,
  removeProgressFor,
  httpPut,
  showToast,
  APISERVICES
} from '../../../../../framework';
import { SagaIterator } from 'redux-saga';
/**
 *
 * @param data
 */
function updateRule(slug: any, id: any, data: any) {
  const apiData = formToApiDataParser(data);
  return httpPut(`orgs/${slug}/device_rule_template/${id}`, apiData, APISERVICES.DEVICE_API);
}

/**
 *
 * @param action
 */
export function* updateNarrowbandRuleSaga(action: any) {
  try {
    const rules: any = action.payload.data;
    if (rules.rulesData.warningRule && rules.rulesData.errorRule) {
      const warningRuleResponse: GenericObject = yield call(
        updateRule,
        action.payload.slug,
        rules.warningId,
        rules.rulesData.warningRule
      );
      const errorRuleResponse: GenericObject = yield call(
        updateRule,
        action.payload.slug,
        rules.errorId,
        rules.rulesData.errorRule
      );
      yield put(
        updateNarrowbandRuleActions.updateNBRuleSuccess({
          warningRuleResponse,
          errorRuleResponse
        })
      );
    } else if (rules.rulesData.warningRule && !rules.rulesData.errorRule) {
      const warningRuleResponse: GenericObject = yield call(
        updateRule,
        action.payload.slug,
        rules.warningId,
        rules.rulesData.warningRule
      );
      yield put(
        updateNarrowbandRuleActions.updateNBRuleSuccess({
          warningRuleResponse
        })
      );
    } else if (!rules.rulesData.warningRule && rules.rulesData.errorRule) {
      const errorRuleResponse: GenericObject = yield call(
        updateRule,
        action.payload.slug,
        rules.errorId,
        rules.rulesData.errorRule
      );
      yield put(
        updateNarrowbandRuleActions.updateNBRuleSuccess({
          errorRuleResponse
        })
      );
    }
    yield put(
      datapointActions.fetchDatapoints({
        slug: action.payload.slug,
        deviceId: rules.deviceId
      })
    );
  } catch (error: any) {
    if (error === 'NoEmailPresent') {
      yield put(
        showToast('Please enter at-least one email id to update the email action!', 'error', true)
      );
    } else {
      yield put(showToast('Rule could not be updated!', 'error', true));
    }
    yield put(updateNarrowbandRuleActions.updateNBRuleFailure(error));
  } finally {
    yield put(removeProgressFor('NB_RULE_UPDATE'));
  }
}
/**
 *
 */
export function* watchUpdateNarrowbandRuleSaga(): SagaIterator {
  yield takeEvery(UPDATE_NARROWBAND_RULE_ACTIONS.UPDATE_NB_RULES, updateNarrowbandRuleSaga);
}
