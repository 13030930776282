/**
 * narrowBandunit Reducer
 * @author ashwini.gawade@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTIONS from '../vibrationAnalysis.actionTypes';
import { VADeviceState, VA_DEVICE_STATUS } from './vaDevice.stateType';
import { getDataPointsParsedList } from '../common/vaUtils';

const initialState: VADeviceState = {
  status: VA_DEVICE_STATUS.INIT,
  datapointsList: [],
  devicesList: [],
  selectedDatapoints: [],
  selectedDevices: [],
  devicesWithDatapoints: []
};
/**
 * This method will just update the status of va-device reducer object
 * @param state
 * @param payload
 * @returns
 */
function handleFetchDatapoints(state: VADeviceState): VADeviceState {
  return { ...state, status: VA_DEVICE_STATUS.FETCHING };
}
/**
 * This method updates VA-device reducer status and also appends a payload by parsing datapoints.
 * @param state
 * @param payload
 * @returns
 */
function handleDatapointsSuccess(state: VADeviceState, payload: any): VADeviceState {
  const parsedData = getDataPointsParsedList(payload.datapointsList, payload.devicesList, true);
  return {
    ...state,
    status: VA_DEVICE_STATUS.READY,
    datapointsList: parsedData,
    devicesList: payload.devicesList,
    devicesWithDatapoints: payload.datapointsList
  };
}
/**
 *
 * @param state
 * @returns
 */
function handleDatapointsFailure(state: VADeviceState): VADeviceState {
  return {
    ...state,
    status: VA_DEVICE_STATUS.ERROR,
    datapointsList: [],
    devicesList: []
  };
}

function handleResetDeviceState(state: VADeviceState): VADeviceState {
  const { datapointsList, devicesWithDatapoints, devicesList } = state;
  return {
    ...initialState,
    datapointsList,
    devicesWithDatapoints,
    devicesList
  };
}
function handleFullResetDeviceState(): VADeviceState {
  return {
    ...initialState
  };
}
function handleUpdateSelection(state: VADeviceState, payload: any): VADeviceState {
  return {
    ...state,
    selectedDevices: payload.devices
  };
}
function handleUpdateVATrueDatapoints(state: VADeviceState, data: any): VADeviceState {
  const { payload, devices, action } = data;
  const { datapoints } = payload;

  const parsedData = datapoints;

  let updatedDatapoints = state.datapointsList;
  let { devicesWithDatapoints } = state;
  if (action === 'add') {
    devicesWithDatapoints = devicesWithDatapoints.map((currentDp) => {
      const dp = currentDp;
      if (dp.device_id === payload.device_id) {
        dp.datapoints = [...dp.datapoints, ...parsedData];
      }
      return dp;
    });
  } else if (action === 'update') {
    devicesWithDatapoints = devicesWithDatapoints.map((currentDp: any) => {
      const dp = currentDp;
      if (dp.device_id === payload.device_id) {
        dp.datapoints = dp.datapoints.map((dpoint: any) => {
          const found = parsedData.find((d: any) => d.dpid === dpoint.dpid);
          if (found) {
            return found;
          }
          return dpoint;
        });
      }
      return dp;
    });
  } else if (action === 'delete') {
    devicesWithDatapoints = devicesWithDatapoints.map((currentDp: any) => {
      const dp = currentDp;
      if (dp.device_id === payload.device_id) {
        dp.datapoints = dp.datapoints.filter((dpoint: any) => {
          const found = parsedData.find((d: any) => d.dpid === dpoint.dpid);
          if (found === undefined) {
            return dpoint;
          }
        });
      }
      return dp;
    });
  }
  updatedDatapoints = getDataPointsParsedList(devicesWithDatapoints, devices, true);

  return {
    ...state,
    datapointsList: updatedDatapoints,
    devicesWithDatapoints
  };
}

/**
 *
 * @param state
 * @param action
 * @returns
 */
export default function VADeviceReducer(
  state: VADeviceState = initialState,
  action: AnyAction
): VADeviceState {
  switch (action.type) {
    case ACTIONS.FETCH_DATAPOINTS:
      return handleFetchDatapoints(state);
    case ACTIONS.FETCH_DATAPOINTS_SUCCESS:
      return handleDatapointsSuccess(state, action.payload);
    case ACTIONS.FETCH_DATAPOINTS_FAILURE:
      return handleDatapointsFailure(state);
    case ACTIONS.RESET_DEVICE_STATE:
    case ACTIONS.RESET_VIBRATION_ANALYSIS:
      return handleResetDeviceState(state);
    case ACTIONS.UPDATE_DATAPOINT_SELECTION:
      return handleUpdateSelection(state, action.payload);
    // smart-cache Listening to update va true datapoints
    case ACTIONS.UPDATE_VA_TRUE_DATAPOINTS:
      return handleUpdateVATrueDatapoints(state, action.payload);
    case ACTIONS.FULL_RESET_DEVICE_STATE:
      return handleFullResetDeviceState();
    default:
      return state;
  }
}
