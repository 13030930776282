import { AnyAction } from 'redux';
import * as ACTIONS_TYPES from './rolesUpdate.actionTypes';

export const fetchRolesOrgList = (data: any): AnyAction => {
  return {
    type: ACTIONS_TYPES.FETCH_ACTIONS_ORG,
    payload: data
  };
};
/**
 *
 * @param data
 */
export const fetchRolesOrgSuccess = (data: any): AnyAction => {
  return {
    type: ACTIONS_TYPES.FETCH_ACTIONS_SUCCESS,
    payload: data
  };
};

/**
 *
 */
export const fetchRolesOrgFailure = (): AnyAction => {
  return {
    type: ACTIONS_TYPES.FETCH_ACTIONS_FAILURE
  };
};

export const resetRolesOrg = (): AnyAction => {
  return {
    type: ACTIONS_TYPES.FETCH_ACTIONS_RESET,
    payload: false
  };
};
