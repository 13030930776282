import { AnyAction } from 'redux';
import * as ACTIONS_TYPES from './rolesDetails.actionTypes';

export const fetchRolesDetails = (data: any): AnyAction => {
  return {
    type: ACTIONS_TYPES.FETCH_ROLES_DETAILS,
    payload: data
  };
};
/**
 *
 * @param data
 */
export const fetchRolesDetailsSuccess = (data: any): AnyAction => {
  return {
    type: ACTIONS_TYPES.FETCH_ROLES_DETAILS_SUCCESS,
    payload: data
  };
};
/**
 *
 */
export const fetchRolesDetailsFailure = (): AnyAction => {
  return {
    type: ACTIONS_TYPES.FETCH_ROLES_DETAILS_FAILURE
  };
};

export const resetRolesDetails = (): AnyAction => {
  return {
    type: ACTIONS_TYPES.FETCH_ROLES_DETAILS_RESET,
    payload: false
  };
};
