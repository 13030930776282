/**
 * Org Selector Helper
 * @author mahesh.kedari@shorelineiot.com
 */
import { OrgListState } from '../types/OrgListState';
import { Organisation, SubOrgFromOrganisation } from '../types/Organisation';
import { OrgSelectorState, ORG_SELECTOR_STATUS } from '../types/orgSelector.state';

/**
 *
 */
export const getLastSelectedOrg = (
  orgListState: OrgListState,
  slug: string | undefined
): {
  lastSelectedOrg: Organisation | SubOrgFromOrganisation | undefined;
  currentOrg: Organisation | SubOrgFromOrganisation | undefined;
} => {
  let lastSelectedOrg: Organisation | SubOrgFromOrganisation | undefined;
  let currentOrg: Organisation | SubOrgFromOrganisation | undefined;
  orgListState.orgList.find((org: Organisation) => {
    if (!currentOrg && org.slug === slug) {
      currentOrg = org;
    }
    if (org.last_switched_to) {
      // If current Organisation has last_switched_to flag true
      lastSelectedOrg = org;
    }
    if (org.suborgs) {
      // If not, check if current organisation has sub-organisations
      org.suborgs.find((suborg) => {
        if (!currentOrg && suborg.slug === slug) {
          currentOrg = suborg;
        }
        if (suborg.last_switched_to) {
          lastSelectedOrg = suborg;
        }
        return Boolean(lastSelectedOrg) && Boolean(currentOrg);
      });
    }
    return Boolean(lastSelectedOrg) && Boolean(currentOrg);
  });
  return { lastSelectedOrg, currentOrg };
};
/**
 *
 * @param orgSelector
 */
export const getSelectedOrgName = (orgSelector: OrgSelectorState) => {
  switch (orgSelector.status) {
    case ORG_SELECTOR_STATUS.READY:
      return orgSelector.selectedOrg?.name;
    case ORG_SELECTOR_STATUS.SELECT:
      return 'Select Org';
    case ORG_SELECTOR_STATUS.ERROR:
      return 'Error';
    default:
      return 'Loading';
  }
};
