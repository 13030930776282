/**
 * datapoint Multi Device List Saga
 */
import { chunk } from 'lodash';
import { put, takeEvery, call, all } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import * as VA_ACTIONS from '../vibrationAnalysis.actionTypes';
import * as VAactions from '../vibrationAnalysis.actions';
import { httpGet, SafeSaga, APISERVICES, getUniqueElements } from '../../../../../framework';
import { SagaIterator } from 'redux-saga';
import { DEVICE_CONSTANTS } from '../../../../common/constants';
import { deviceApi } from '../../../../device/store/device.api';

const CHUNK_SIZE = 60;
/**
 *
 */
function getAllDeviceList(slug: string) {
  return httpGet(
    `orgs/${slug}/devices?page=1&page_size=${DEVICE_CONSTANTS.PAGE_SIZE}`,
    null,
    APISERVICES.DEVICE_API
  );
}
/**
 *
 * @param slug
 * @param deviceIds
 * @returns
 */
function getDeviceDatapoints(slug: string, deviceIds: Array<string | number>) {
  return httpGet(
    `orgs/${slug}/devices/datapoints?device_ids=${deviceIds.join()}&vibration_analysis=true`,
    null,
    APISERVICES.DEVICE_API
  );
}

/**
 *
 * @param slug
 * @param devices
 * @returns
 */

function* getDatapointsListForAllDevices(slug: string, devices: Array<any>) {
  /**
   * #SLC-6656 When there are too many devices in org, lambda service dies to fetch the datapoints of all of them together.
   * Hence we need to chunk these devices in batches and make multiple APIs to get the result and then combine it to form
   * appropriate resultset.
   * TODO:  Eventually even this approach may create a lot of junk data on front-end. Hence we would need
   * to find out a better approach to handle this scenario.
   */
  const deviceChunks = chunk(devices, CHUNK_SIZE);
  const datapointsListResponse: Array<{
    datapoints: Array<any>;
  }> = yield all(
    deviceChunks.map((deviceChunk: Array<any>) => {
      return call(
        getDeviceDatapoints,
        slug,
        deviceChunk.map((device: any) => device.id)
      );
    })
  );
  // Combine result of all the API calls and create a consolidated list
  const devicesWithDatapoints = getUniqueElements(
    datapointsListResponse
      .map((rawResponse: { datapoints: Array<any> }) => rawResponse.datapoints)
      .flat(),
    'device_id'
  );
  const filteredDataPoints = devicesWithDatapoints.map((singleDevice) => {
    const datapoints = singleDevice.datapoints.filter(
      (datapoint: any) =>
        datapoint.sensor_type === 'sns_accelerometer' ||
        datapoint.sensor_type === 'sns_mic_struct_borne'
    );
    singleDevice.datapoints = datapoints;
    return singleDevice;
  });
  return filteredDataPoints;
}

/**
 *
 */
export function* getDatapointsSaga(action: AnyAction) {
  const { slug, getDeviceList, deviceList } = action.payload;
  let devices: Array<any> = deviceList;
  if (getDeviceList !== false) {
    devices = yield call(getAllDeviceList, slug);
  }
  if (devices.length > 0) {
    const datapointListMultiDevice: Array<any> = yield getDatapointsListForAllDevices(
      slug,
      devices
    );
    if (datapointListMultiDevice) {
      yield put(
        VAactions.fetchDatapointsSuccess({
          devicesList: devices,
          datapointsList: datapointListMultiDevice
        })
      );
    }
  }
}

/**
 *
 */
export function* updateVATrueFromWSSaga(action: AnyAction) {
  const { data: devices } = deviceApi.endpoints.fetchDevices.useQuery({
    slug: action?.payload?.slug
  });
  // Deleting slug from payload, because it's needed only for quering devices.
  delete action?.payload?.slug;

  const data = action?.payload;

  const finalPayload = { ...data, devices };

  yield put(VAactions.updateVATrueDatapoints(finalPayload));
}
function* errorHandler(error: any) {
  yield put(VAactions.fetchDatapointsError(error));
}
/**
 *
 */
export function* watchVADatapointListSaga(): SagaIterator {
  yield takeEvery(
    VA_ACTIONS.FETCH_DATAPOINTS,
    SafeSaga(getDatapointsSaga, VA_ACTIONS.FETCH_DATAPOINTS, errorHandler)
  );

  yield takeEvery(
    VA_ACTIONS.UPDATE_VA_TRUE_DATAPOINTS_FROM_WS,
    SafeSaga(updateVATrueFromWSSaga, VA_ACTIONS.UPDATE_VA_TRUE_DATAPOINTS_FROM_WS)
  );
}
