import { useSelector } from 'react-redux';
import { RootState } from '../../../../../configureStore';

const useWaterfallXUnit = () =>
  useSelector((state: RootState) => state.features.workflow.trends.waterfall.xUnit);

const useWaterfallYUnit = () =>
  useSelector((state: RootState) => state.features.workflow.trends.waterfall.yUnit);

const useWaterfallSelectedDevice = () =>
  useSelector((state: RootState) => state.features.workflow.trends.waterfall.selectedDevice);

const useWaterfallIsStructureborneDatapointSelected = () =>
  useSelector(
    (state: RootState) => state.features.workflow.trends.waterfall.isStructureborneDatapointSelected
  );

const useWaterfallXAxisZoomeValue = () =>
  useSelector((state: RootState) => state.features.workflow.trends.waterfall.xAxisZoomValue);

const useWaterfallskipNullCpmValue = () =>
  useSelector((state: RootState) => state.features.workflow.trends.waterfall.isSkipNullCpm);
const useWaterfallEventSpeed = () =>
  useSelector((state: RootState) => state.features.workflow.trends.waterfall.speed);

const useWaterfallSelectedEventCount = () =>
  useSelector((state: RootState) => state.features.workflow.trends.waterfall.selectedEventCount);

const useWaterfallSkipEventCount = () =>
  useSelector((state: RootState) => state.features.workflow.trends.waterfall.skipEventCount);

const useWaterfallYAxisExtremesValue = () =>
  useSelector((state: RootState) => state.features.workflow.trends.waterfall.yAxisExtremesValue);

export {
  useWaterfallXUnit,
  useWaterfallYUnit,
  useWaterfallSelectedDevice,
  useWaterfallIsStructureborneDatapointSelected,
  useWaterfallXAxisZoomeValue,
  useWaterfallskipNullCpmValue,
  useWaterfallEventSpeed,
  useWaterfallSelectedEventCount,
  useWaterfallSkipEventCount,
  useWaterfallYAxisExtremesValue
};
