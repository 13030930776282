/**
 * Unauthenticated Header
 * @author mahesh.kedari@shorelineiot.com
 */
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Toolbar from '@mui/material/Toolbar';
import Hidden from '@mui/material/Hidden';
import { Box, useTheme, useMediaQuery, Link, Button, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import Logo from '../../../../framework/components/header/logo/Logo';
import { useStyles } from './unauthenticatedHeader.styles';
import { useSlugContext } from '../../../../framework';
import { registerActions } from '../../../auth/register/store';
import Banner from '../../../home/Banner';

export default function UnauthenticatedHeader() {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'), {
    defaultMatches: false
  });
  const { slug } = useSlugContext();

  return (
    <>
      <Banner />
      <Box mt={2}>
        <Toolbar className={classes.toolBar}>
          <Box
            display="flex"
            flexGrow={1}
            alignContent="flex-start"
            justifyContent={isMobile ? 'center' : 'flex-start'}
            m={2}>
            <Button
              onClick={() => dispatch(registerActions.resetToRegister())}
              className={classes.root}>
              <RouterLink to={`/${slug}/auth/login`}>
                <Logo />
              </RouterLink>
            </Button>
          </Box>
        </Toolbar>
      </Box>
    </>
  );
}
