/**
 * Error Handler Service
 * @author mahesh.kedari@shorelineiot.com
 */
import { put } from 'redux-saga/effects';
import { showToast } from '../components/toast/toast.actions';
import { ThunkDispatch } from '@reduxjs/toolkit';

export function* handleErrorSaga(error: any, defaultMessage?: string) {
  const message = defaultMessage || 'Failed to perform this operation';
  if (error?.response?.statusCode === 400) {
    yield put(showToast(error?.message || message, 'error', true));
  } else {
    yield put(showToast(message, 'error', true));
  }
}

/**
 * Handles error messages based on the HTTP status code.
 *
 * @param {Error} error - The error object.
 * @param {Function} dispatch - The Redux dispatch function.
 */
export function handleError(error: any, dispatch: ThunkDispatch<any, any, any>) {
  const message = 'Failed to perform this operation';

  if (error?.response?.statusCode === 400) {
    dispatch(showToast(error?.message || message, 'error', true));
  } else if (error?.response?.statusCode === 500) {
    dispatch(showToast('Internal Server Error', 'error', true));
  } else if (error?.response?.statusCode === 401) {
    dispatch(showToast('You are not authorized to perform this operation', 'error', true));
  } else {
    dispatch(showToast('Unknown error has occurred. Please try after some time', 'error', true));
  }
}
