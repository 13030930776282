/**
 * Sidebar header Styles
 * @author mahesh.kedari@shorelineiot.com
 */
import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.palette.primary.contrastText,
    paddingLeft: 5,
    paddingTop: 10,
    width: '100%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    lineHeight: 1.5
  }
}));
