/**
 * Backdrop Actions
 * @author mahesh.kedari@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTIONS from './backdrop.actionTypes';
/**
 *
 */
export const showBackdrop = (): AnyAction => {
  return {
    type: ACTIONS.SHOW_BACKDROP
  };
};
/**
 *
 */
export const hideBackdrop = (): AnyAction => {
  return {
    type: ACTIONS.HIDE_BACKDROP
  };
};
