/**
 * Reports Sidebar Menu
 * @author mahesh.kedari@shorelineiot.com
 */
import React from 'react';
import {
  ACCESS_PERMIT,
  SidebarMenu,
  SLSubmenuLink,
  usePermissions,
  useSlugContext
} from '../../framework';
import { ReportsIcon } from '../../framework/icons';
import { REPORTS_PERMISSIONS } from './reports.permissions';
import { sidebarId } from '../../framework/components/sidebar/sidebar.utils';

export default function ReportsMenu() {
  const { slug } = useSlugContext();
  const reportsMenuId: string = sidebarId.menuItems('reports');
  const hasViewPermission: ACCESS_PERMIT = usePermissions(REPORTS_PERMISSIONS.VIEW);

  const eventReportsPermittedOrgs = new Set([
    'oilandgas',
    'shorelinedemo',
    'release_1_20',
    'release_1_21'
  ]);

  if (hasViewPermission === ACCESS_PERMIT.ALLOW) {
    return (
      <SidebarMenu
        testID={reportsMenuId}
        route={`/${slug}/app/reports`}
        textKey="reports"
        defaultText="Reports"
        icon={<ReportsIcon className="MuiSvgIcon-root" />}>
        {eventReportsPermittedOrgs.has(slug) && (
          <SLSubmenuLink
            route={`/${slug}/app/reports/event-reports`}
            textKey="event_reports"
            defaultText="Event Reports"
          />
        )}
        <SLSubmenuLink
          testID={`${reportsMenuId}-device_data`}
          route={`/${slug}/app/reports/device-data`}
          textKey="device_data"
          defaultText="Device Data"
        />
        <SLSubmenuLink
          testID={`${reportsMenuId}-external_data`}
          route={`/${slug}/app/reports/external-data`}
          textKey="external_data"
          defaultText="External Data"
        />
        <SLSubmenuLink
          testID={`${reportsMenuId}-device_mailbox`}
          route={`/${slug}/app/reports/device-mailbox`}
          textKey="device_mailbox"
          defaultText="Device Mailbox"
        />
        {(slug === 'dcpmidstream' || slug === 'shorelinedemo') && (
          <SLSubmenuLink
            route={`/${slug}/app/reports/leak-detection`}
            textKey="leak_detection"
            defaultText="Leak Detection"
          />
        )}
      </SidebarMenu>
    );
  }
  return <div />;
}
