/**
 * Accordion Container Style
 * @author mahesh.kedari@shorelineiot.com
 */
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
/**
 *
 */
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: '24px',
      padding: '4px',
      '&:hover': {
        backgroundColor: theme.palette.primary.main
      }
    }
  })
);
