import { useSelector } from 'react-redux';
import { RootState } from '../../../../../configureStore';

const useAcceptInviteStatus = () =>
  useSelector((state: RootState) => state.features.auth.login.acceptInviteStatus);

const useUser = () => useSelector((state: RootState) => state.features.auth.login.user);
const useIsAuthenticated = () => useSelector((state: any) => state.features.auth.login.isLoggedIn);
const useUserSession = () => useSelector((state: RootState) => state.features.auth?.login?.session);
const useLoginState = () => useSelector((state: RootState) => state.features.auth.login);
export { useLoginState, useAcceptInviteStatus, useUser, useIsAuthenticated, useUserSession };
