/**
 * Aggregator of Device Saga
 * @author mahesh.kedari@shorelineiot.com
 */
import { all } from 'redux-saga/effects';
import { watchRawDatapointListSaga } from './rawDeviceDatapoints';
import {
  watchUpdateNevadaNanoSaga,
  watchaddModbusSaga,
  watchUpdateModbusSaga,
  widgetSettingsSagas,
  watchDeviceActivityLogsSagas,
  watchComponentClassesListSagas,
  watchLoadDevicePhotosSagas,
  watchDeviceDataSaga,
  watchNarrowbandSaga,
  watchAnalogSensorSagas,
  watchDigitalSensorSagas,
  watchGetRuleTemplateSagas,
  watchUpdateRuleTemplateSaga,
  watchResetDatapointSaga
} from '../device-details';

/**
 *
 */
export function* watchDeviceSaga() {
  yield all([
    watchRawDatapointListSaga(),
    watchDeviceDataSaga(),
    watchNarrowbandSaga(),
    // watchAttachProfileSaga(),
    watchDeviceActivityLogsSagas(),
    watchaddModbusSaga(),
    watchUpdateNevadaNanoSaga(),
    watchUpdateModbusSaga(),
    watchComponentClassesListSagas(),
    widgetSettingsSagas(),
    watchAnalogSensorSagas(),
    watchDigitalSensorSagas(),
    watchGetRuleTemplateSagas(),
    watchUpdateRuleTemplateSaga(),
    watchResetDatapointSaga(),
    watchLoadDevicePhotosSagas()
  ]);
}
