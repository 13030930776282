import React from 'react';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import { DialogContentText } from '@mui/material';
import TermsAndConditions from '../../../static-pages/termsAndConditions/TermsAndConditions';

const TermsAndConditionsDialog = ({ open, onClose }: DialogProps) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContentText sx={{ color: 'black' }}>
        <TermsAndConditions />
      </DialogContentText>
    </Dialog>
  );
};

export default TermsAndConditionsDialog;
