/**
 * Update Profile Action Types
 * @author mahesh.kedari@shorelineiot.com
 */
export const RESET = 'FEATURES/DEVICE-PROFILE/PROFILE-UPDATE/RESET';

export const SET_TYPE = 'FEATURES/DEVICE-PROFILE/PROFILE-UPDATE/SET_TYPE';
export const SET_PROFILE_ID = 'FEATURES/DEVICE-PROFILE/PROFILE-UPDATE/SET_PROFILE_ID';

export const FETCH = 'FEATURES/DEVICE-PROFILE/PROFILE-UPDATE/FETCH';
export const FETCH_SUCCESS = 'FEATURES/DEVICE-PROFILE/SENSOR-MODULE/PROFILE-UPDATE/FETCH/SUCCESS';
export const FETCH_FAILURE = 'FEATURES/DEVICE-PROFILE/SENSOR-MODULE/PROFILE-UPDATE/FETCH/FAILURE';

export const FETCH_SCHEMA = 'FEATURES/DEVICE-PROFILE/PROFILE-UPDATE/FETCH_SCHEMA';
export const FETCH_SCHEMA_SUCCESS =
  'FEATURES/DEVICE-PROFILE/SENSOR-MODULE/PROFILE-UPDATE/FETCH_SCHEMA/SUCCESS';
export const FETCH_SCHEMA_FAILURE =
  'FEATURES/DEVICE-PROFILE/SENSOR-MODULE/PROFILE-UPDATE/FETCH_SCHEMA/FAILURE';

export const ADD_NEW_SENSOR_MODULE = 'FEATURES/DEVICE-PROFILE/PROFILE-UPDATE/SENSOR/NEW/ADD';
export const FETCH_SENSOR_TEMPLATE = 'FEATURES/DEVICE-PROFILE/PROFILE-UPDATE/SENSOR/FETCH/TEMPLATE';
export const FETCH_SENSOR_TEMPLATE_SUCCESS =
  'FEATURES/DEVICE-PROFILE/PROFILE-UPDATE/SENSOR/FETCH/TEMPLATE/SUCCESS';
export const FETCH_SENSOR_TEMPLATE_ERROR =
  'FEATURES/DEVICE-PROFILE/PROFILE-UPDATE/SENSOR/FETCH/TEMPLATE/FAILURE';
export const REMOVE_SENSOR_MODULE = 'FEATURES/DEVICE-PROFILE/PROFILE-UPDATE/SENSOR/REMOVE';

export const ADD_EXISTING_SENSOR_MODULE = 'FEATURES/DEVICE-PROFILE/PROFILE-UPDATE/SENSOR/ADD';
export const ADD_SENSOR_MODULE_SUCCESS =
  'FEATURES/DEVICE-PROFILE/PROFILE-UPDATE/SENSOR/ADD/SUCCESS';
export const ADD_SENSOR_MODULE_FAILURE =
  'FEATURES/DEVICE-PROFILE/PROFILE-UPDATE/SENSOR/ADD/FAILURE';
export const SENSOR_MODULE_RESET = 'FEATURES/DEVICE-PROFILE/PROFILE-UPDATE/SENSOR/RESET';

export const UPDATE = 'FEATURES/DEVICE-PROFILE/PROFILE-UPDATE/UPDATE';
export const UPDATE_SUCCESS = 'FEATURES/DEVICE-PROFILE/SENSOR-MODULE/PROFILE-UPDATE/UPDATE/SUCCESS';
export const UPDATE_FAILURE = 'FEATURES/DEVICE-PROFILE/SENSOR-MODULE/PROFILE-UPDATE/UPDATE/FAILURE';

export const FETCH_SENSOR_MODULES = 'FEATURES/DEVICE-PROFILE/PROFILE-UPDATE/FETCH_SENSOR_MODULES';
export const FETCH_SENSOR_MODULES_SUCCESS =
  'FEATURES/DEVICE-PROFILE/PROFILE-UPDATE/FETCH_SENSOR_MODULES/SUCCESS';
export const FETCH_SENSOR_MODULES_FAILURE =
  'FEATURES/DEVICE-PROFILE/PROFILE-UPDATE/FETCH_SENSOR_MODULES/FAILURE';

export const SUBMIT_SENSOR_CONFIG = 'FEATURES/DEVICE-PROFILE/PROFILE-UPDATE/SENSOR_CONFIG/SUBMIT';
export const SUBMIT_SENSOR_METADATA =
  'FEATURES/DEVICE-PROFILE/PROFILE-UPDATE/SENSOR_METADATA/SUBMIT';

export const RESET_SENSOR_CONFIG = 'FEATURES/DEVICE-PROFILE/PROFILE-UPDATE/SENSOR_CONFIG/RESET';
export const RESET_SENSOR_METADATA = 'FEATURES/DEVICE-PROFILE/PROFILE-UPDATE/SENSOR_METADATA/RESET';
