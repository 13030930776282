/**
 * Help Styles
 * @author mahesh.kedari@shorelineiot.com
 */
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((/* theme: Theme */) => ({
  helpLink: {
    color: '#000000',
    marginTop: '-20px'
  }
}));
