/**
 * Auth Check State Saga
 * @author mahesh.kedari@shorelineiot.com
 */
import {
  AuthSession,
  fetchAuthSession,
  fetchUserAttributes,
  FetchUserAttributesOutput
} from 'aws-amplify/auth';
import { put } from 'redux-saga/effects';
import * as actions from '../actions/login.actions';
/**
 *
 */
interface CognitoUserInfo {
  id: string;
  attributes: {
    'custom:country_code': string;
    'custom:org_name': string;
    'custom:slug': string;
    email: string;
    email_verified: boolean;
    family_name: string;
    given_name: string;
    sub: string;
  };
  username: string;
}
/**
 *
 */
export function* authCheckStatus() {
  try {
    const currentSession: AuthSession = yield fetchAuthSession();
    const userInfoAttributes: FetchUserAttributesOutput = yield fetchUserAttributes();
    yield put(
      actions.currentSessionAvailable({
        ...currentSession.tokens,
        ...userInfoAttributes
      })
    );
  } catch (error: any) {
    yield put(actions.currentSessionNotAvailable());
  }
}
