// import { AmplifyService } from '../../../../framework/server-requests/amplify.service';

import { httpGet, APISERVICES } from '../../../../framework';

/**
 * Signup Validation
 * @author mahesh.kedari@shorelineiot.com
 * Updated By Swapnil
 */
export const registrationValidationRule = {
  name: {
    required: 'Name is required',
    minLength: {
      value: 3,
      message: 'Minimum 3 characters are required'
    },
    maxLength: {
      value: 30,
      message: 'Maximum 30 characters are allowed'
    }
    // pattern: {
    //   /* eslint-disable no-useless-escape */
    //   value: /^\s*[a-zA-Z0-9\s*!@#$%^&*()_\-=\[\]{};':"\\|,.<>\/?~`]*$\s*/,
    //   message: 'Characters,integers and special characters allowed',
    // },
  },
  email: {
    required: 'Email is required',
    minLength: {
      value: 6,
      message: 'Minimum 6 characters are required'
    },
    maxLength: {
      value: 64,
      message: 'Maximum 64 characters are allowed'
    },
    pattern: {
      value:
        /^([a-zA-Z0-9_]+)([.+_-]?[a-zA-Z0-9]+)*@([a-zA-Z0-9]+)([._-]?[a-zA-Z0-9]+)*([.]{1}[a-zA-Z0-9]{2,})+$/,
      message: 'Enter a valid email address'
    }
    /* validate: async (value: string) => {
      // const amplifyService = new AmplifyService();
      // const doesEmailAlreadyExist = await amplifyService.emailAlreadyExists(
      // value
      // );
      // return doesEmailAlreadyExist ? 'E-mail ID is already registered' : null;
    }, */
  },
  password: {
    required: 'Password is required',
    minLength: {
      value: 8,
      message: 'Password should be between 8 to 64 characters long'
    },
    maxLength: {
      value: 64,
      message: 'Password should be between 8 to 64 characters long'
    }
    /* pattern: {
      value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-“!@#%&/,><\’:;|_~`])\S{6,99}$/,
      message:
        'Your password must be at least 6 characters long, contain at least 1 number, 1 special character and have a mixture of uppercase and lowercase letters.',
    }, */
  },
  retypePassword: (watch: Function) => {
    return {
      validate: (value: string) => {
        return value === watch('password') ? null : 'Passwords must match';
      }
    };
  },
  country_code: {
    required: 'Select the Country'
  },
  organisation: {
    required: 'Organization is required',
    minLength: {
      value: 3,
      message: 'Minimum 3 characters are required'
    },
    maxLength: {
      value: 64,
      message: 'Maximum 64 characters are allowed'
    }
  },
  slug: {
    required: 'Short name is required',
    minLength: {
      value: 6,
      message: 'Minimum 6 characters are required'
    },
    maxLength: {
      value: 20,
      message: 'Maximum 20 characters are allowed'
    },
    pattern: {
      value: /^[a-zA-Z0-9_]+$/,
      message: 'Only alphanumeric characters and underscore are allowed'
    },
    validate: async (value: string) => {
      try {
        const resp = await httpGet(`orgs/${value}/exists`, null, APISERVICES.HOST_API);
        return resp.status ? 'Short name is already used' : null;
      } catch (error: any) {
        return null;
      }
    }
  },
  acceptTnC: {
    required: 'Accept the Terms and Conditions'
  },
  code: {
    required: 'Verification code is required. Please check your email.',
    minLength: {
      value: '6',
      message: 'Invalid entry! Code should be 6-digit numeric.'
    },
    maxLength: {
      value: '6',
      message: 'Invalid entry! Code should be 6-digit numeric.'
    }
  }
};
/**
 *
 */
export const registerDefaultValues = {
  name: '',
  email: '',
  password: '',
  retypePassword: '',
  country_code: '',
  org_name: '',
  slug: '',
  terms: false,
  code: ''
};
