/**
 * Device Related Action Types
 * @author mahesh.kedari@shorelineiot.com
 */
export const ADD_DEVICE_SUCCESS = 'FEATURES/DEVICE/ADD_DEVICE_SUCCESS';
export const UPDATE_MULTIPLE_DEVICES_WITH_DATAPOINTS =
  'FEATURES/DEVICE/UPDATE_MULTIPLE_DEVICES_WITH_DATAPOINTS';
export const UPDATE_DEVICE_LIST_WITH_DATAPOINTS =
  'FEATURES/DEVICE/UPDATE_DEVICE_LIST_WITH_DATAPOINTS';
export const FETCH_DETAILS = 'FEATURES/DEVICE/FETCH_DEVICE_DETAILS';
export const FETCH_DETAILS_SUCCESS = 'FEATURES/DEVICE/FETCH_DETAILS_SUCCESS';
export const FETCH_DETAILS_FAILURE = 'FEATURES/DEVICE/FETCH_DETAILS_FAILURE';

export const FETCH_GROUP_DETAILS = 'FEATURES/DEVICE/FETCH_GROUP_DETAILS';
export const FETCH_GROUP_DETAILS_SUCCESS = 'FEATURES/DEVICE/FETCH_GROUP_DETAILS_SUCCESS';
export const FETCH_GROUP_DETAILS_FAILURE = 'FEATURES/DEVICE/FETCH_GROUP_DETAILS_FAILURE';

export const UPDATE_DEVICE_LIST = 'FEATURES/DEVICE/UPDATE_DEVICE_LIST';
