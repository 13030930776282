import { AnyAction } from 'redux';
import * as ACTIONS_TYPES from './ComponentClassList.actionTypes';

export const fetchComponentClassList = (
  slug: string,
  deviceId: string | number,
  manufacturerId: string | number,
  modelId: string | number,
  classId: string | number,
  subclassId: string | number
): AnyAction => {
  return {
    type: ACTIONS_TYPES.FETCH_COMPONENT_CLASS_LIST_PROGRESS,
    payload: {
      slug,
      deviceId,
      manufacturerID: manufacturerId,
      modelID: modelId,
      classId,
      subclassId
    }
  };
};
/**
 *
 * @param data
 */
export const fetchComponentClassListSuccess = (data: any): AnyAction => {
  return {
    type: ACTIONS_TYPES.FETCH_COMPONENT_CLASS_LIST_SUCCESS,
    payload: data
  };
};
/**
 *
 */
export const fetchComponentClassListFailure = (): AnyAction => {
  return {
    type: ACTIONS_TYPES.FETCH_COMPONENT_CLASS_LIST_FAILURE
  };
};

export const resetComponentClassList = (): AnyAction => {
  return {
    type: ACTIONS_TYPES.RESET_COMPONENT_CLASS_LIST,
    payload: false
  };
};
