import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { OrbitState } from './orbit.types';

const initialState: OrbitState = {
  odrs: [],
  eventMetaData: undefined
};

export const orbit = createSlice({
  name: 'orbit',
  initialState,
  reducers: {
    handleOdrListChange(state: OrbitState, action: PayloadAction<number[]>) {
      state.odrs = action.payload;
    },
    handleEventMetaData(
      state: OrbitState,
      action: PayloadAction<{ value: number; ts: string } | undefined>
    ) {
      state.eventMetaData = action.payload;
    }
  }
});

export const { handleOdrListChange, handleEventMetaData } = orbit.actions;

export default orbit.reducer;
