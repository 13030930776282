/**
 * update dashbaord item
 */
export enum UpdateDashboardItemStatus {
  INIT,
  FETCHING,
  READY,
  ERROR,
  RESET
}
export interface UpdateDashboardItemState {
  status: UpdateDashboardItemStatus;
  updateDashboardItemData: any;
}
