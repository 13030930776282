/**
 * Plant Health Trends Editor
 * @author mahesh.kedari@shorelineiot.com
 */
import { Grid } from '@mui/material';
import merge from 'lodash/merge';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import SLColorPicker from '../../../color-picker/SLColorPicker';
import { SLCheckbox, SLTextField } from '../../../form';
import CrosshairConfigs from '../../cartesian-chart/settings/CrosshairConfigs';
import { cartesianFormDefaults } from '../../chartEditor.utils';
import { ChartEditorProps } from '../../common/ChartEditorProps';
import ResetDefaultConfig from '../../common/ResetDefaultConfig';
import WidgetSettingAccordion from '../../common/WidgetAccordion';
/**
 *
 * @param param0
 * @returns
 */
const PlantHealthTrendsEditor: React.FC<ChartEditorProps> = ({
  handleSubmitForm,
  defaultValues
}: // dataConfig,
// widgetType
//TODO: Uncomment above when backend supports Query parameters in API
ChartEditorProps) => {
  const [settings, setSettings] = useState<any>({
    chart_type: 'line',
    chartSettings: Object.keys(defaultValues).length !== 0 ? defaultValues : cartesianFormDefaults
  });
  const { getValues } = useFormContext();

  const handleUpdate = () => {
    const values = getValues();
    const updatedSettings = merge({}, settings);
    // updating local variable here
    const mergedSettings = {
      ...updatedSettings,
      chartSettings: {
        ...values
      }
    };
    setSettings(mergedSettings);
    handleSubmitForm(mergedSettings);
  };

  return (
    <>
      <WidgetSettingAccordion title="Common">
        <Grid container justifyContent="flex-start" spacing={2} alignItems="flex-end">
          <Grid item xs={6}>
            <SLTextField
              name="xAxisTitle"
              label="X-Axis Title"
              labelTranslationId="x_axis_title"
              onBlur={handleUpdate}
            />
          </Grid>
          <Grid item xs={6}>
            <SLTextField
              name="yAxisTitle"
              label="Y-Axis Title"
              labelTranslationId="y_axis_title"
              onBlur={handleUpdate}
            />
          </Grid>
          <Grid item xs={6} justifyContent="center">
            <SLCheckbox
              name="marker"
              label="Marker On/Off"
              labelTranslationId="marker_on_off"
              onChange={handleUpdate}
              value={settings.chartSettings?.marker}
              checked={settings.chartSettings?.marker || false}
            />
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={6}>
            <SLCheckbox
              name="grid.disable"
              label="Grid Off"
              labelTranslationId="grid_off"
              onChange={handleUpdate}
              value={settings.chartSettings?.grid?.disable}
              checked={settings.chartSettings?.grid?.disable || false}
            />
          </Grid>
          <Grid item xs={6}>
            <SLTextField
              name="grid.width"
              label="Grid width"
              labelTranslationId="grid_width"
              type="number"
              disabled={settings.chartSettings?.grid?.disable}
              onBlur={handleUpdate}
            />
          </Grid>
          <CrosshairConfigs currentValue={settings.chartSettings} handleUpdate={handleUpdate} />
        </Grid>
      </WidgetSettingAccordion>
      <WidgetSettingAccordion title="No Alarms">
        <Grid container justifyContent="flex-start" spacing={2} alignItems="flex-end">
          <Grid item xs={6}>
            <SLTextField
              name="healthy.label"
              label="Label"
              labelTranslationId="label"
              onBlur={handleUpdate}
            />
          </Grid>
          <Grid item xs={6}>
            <SLColorPicker
              name={`healthy.color`}
              label=""
              value={settings.chartSettings?.healthy?.color}
              handleColorChange={handleUpdate}
            />
          </Grid>
        </Grid>
      </WidgetSettingAccordion>
      <WidgetSettingAccordion title="Critical">
        <Grid container justifyContent="flex-start" spacing={2} alignItems="flex-end">
          <Grid item xs={6}>
            <SLTextField
              name="critical.label"
              label="Label"
              labelTranslationId="label"
              onBlur={handleUpdate}
            />
          </Grid>
          <Grid item xs={6}>
            <SLColorPicker
              name={`critical.color`}
              label=""
              value={settings.chartSettings?.critical?.color}
              handleColorChange={handleUpdate}
            />
          </Grid>
        </Grid>
      </WidgetSettingAccordion>
      <WidgetSettingAccordion title="High">
        <Grid container justifyContent="flex-start" spacing={2} alignItems="flex-end">
          <Grid item xs={6}>
            <SLTextField
              name="high.label"
              label="Label"
              labelTranslationId="label"
              onBlur={handleUpdate}
            />
          </Grid>
          <Grid item xs={6}>
            <SLColorPicker
              name={`high.color`}
              label=""
              value={settings.chartSettings?.high?.color}
              handleColorChange={handleUpdate}
            />
          </Grid>
        </Grid>
      </WidgetSettingAccordion>
      <ResetDefaultConfig chartType="line" onReset={handleUpdate} />
    </>
  );
};
export default PlantHealthTrendsEditor;
