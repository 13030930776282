/**
 * User Profile Menu List
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { forwardRef } from 'react';
import UserOptionMenu from '../features/user-settings/UserOptionMenu';

const OptionMenuList = forwardRef((/* props: any, ref: any */) => {
  return <UserOptionMenu />;
});

export default OptionMenuList;
