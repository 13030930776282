/**
 * Create Sub-Organisation State
 * @author ashwini.gawade@shorelineiot.com
 */
import { Organisation } from './Organisation';
/**
 *
 */
export enum CREATE_SUB_ORG_STATUS {
  INIT,
  READY,
  CREATING,
  SUCCESS,
  ERROR
}
/**
 *
 */
export interface CreateSubOrgState {
  status: CREATE_SUB_ORG_STATUS;
  org?: Organisation;
}
