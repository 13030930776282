import { ALARM_TYPE_OPTIONS } from '../../../../features/alarms/store';
import { Group } from '../../../../features/device';

/**
 * Common helper functions to generate the redirection URL.
 * @author aditya.bhadange@shorelineiot.com
 */
export const addURLQueryOperators = (paramsPaths: any) => {
  let urlString = '';
  paramsPaths.forEach((URLParamas: any, index: number) => {
    if (index === 0) {
      urlString = `${urlString}?${URLParamas}`;
    } else {
      urlString = `${urlString}&${URLParamas}`;
    }
  });
  return urlString;
};

export const createAlarmsPathParamsURL = (deviceGroup: any) => {
  const deviceGroupIds = deviceGroup?.device_ids;
  return `path=${deviceGroupIds?.join()}`;
};

export const getDeviceGroupWithDeviceIDs = (
  deviceGroupListStateInfo: any,
  deviceGroupName: string
) => {
  return deviceGroupListStateInfo?.find(
    (deviceGroup: any) => deviceGroup?.name === deviceGroupName
  );
};

export const getAdjasentColumnRedirections = (chartSettingsInfo: any, redirectionInfo: any) => {
  const columnSettings = chartSettingsInfo?.dataSettings;
  const adjasentColumns: any[] = [];
  if (columnSettings !== undefined) {
    Object.keys(columnSettings).forEach((columnKey) => {
      Object.keys(columnSettings[columnKey]).forEach((subkey) => {
        if (columnSettings[columnKey][subkey] === redirectionInfo) {
          adjasentColumns.push({
            columnKey: columnKey?.replaceAll('_', '.'),
            params: columnSettings[columnKey]?.params
          });
        }
      });
    });
  }

  return adjasentColumns;
};

export const createDeviceListRedirectionURL = (
  chartSettingsInfo?: any,
  deviceGroupListStateInfo?: any,
  rowDataInfo?: any,
  redirectionInfo?: any
) => {
  const columnAndParamsMap = getAdjasentColumnRedirections(chartSettingsInfo, redirectionInfo);
  const paramsPaths: any[] = [];
  columnAndParamsMap.forEach((element: any) => {
    const deviceGroupInfo = getDeviceGroupWithDeviceIDs(
      deviceGroupListStateInfo,
      rowDataInfo[element.columnKey]
    );
    paramsPaths.push(`fq_dg_path=${deviceGroupInfo?.fq_dg_path}&level=${deviceGroupInfo?.level}`);
  });
  return addURLQueryOperators(paramsPaths);
};

export const createAlarmsRedirectionURL = (
  rowDataInfo?: any,
  chartSettingsInfo?: any,
  redirectionInfo?: any,
  deviceGroups?: Group[]
) => {
  const columnAndParamsMap = getAdjasentColumnRedirections(chartSettingsInfo, redirectionInfo);
  const paramsPaths = [`alarm_type=${ALARM_TYPE_OPTIONS.ASSET.value}`];
  columnAndParamsMap.forEach((element: any) => {
    if (element.params === 'severity') {
      paramsPaths.push(`severity=${rowDataInfo[element.columnKey]}`);
    } else if (element.params === 'path') {
      const key = element.columnKey.replaceAll('.', '_');
      const deviceGroupInfo = getDeviceGroupWithDeviceIDs(deviceGroups, rowDataInfo[key]);
      paramsPaths.push(createAlarmsPathParamsURL(deviceGroupInfo));
    }
  });
  return addURLQueryOperators(paramsPaths);
};

export const createAnotherDashboardRedirectURL = (chartSettingsInfo: any, redirectionInfo: any) => {
  const columnAndParamsMap = getAdjasentColumnRedirections(chartSettingsInfo, redirectionInfo);
  const paramsPaths: any[] = [];
  columnAndParamsMap.forEach((element: any) => {
    if (element.params === 'dashboard_id') {
      const columnRedirectionInfo =
        chartSettingsInfo?.dataSettings?.[element.columnKey.replaceAll('.', '_')];
      paramsPaths.push(columnRedirectionInfo?.dashboard_id);
    } else {
      paramsPaths.push('default');
    }
  });
  return paramsPaths[0];
};

export const resolver = (
  redirectionInfo: string | undefined,
  paramsInfo: string | undefined,
  rowData: any,
  chartSettings: any,
  deviceGroups?: Group[]
) => {
  // TODO: Based on Data and params we need to find out URL parameters
  switch (redirectionInfo) {
    case 'alarms':
      return createAlarmsRedirectionURL(rowData, chartSettings, redirectionInfo, deviceGroups);
    case 'asset':
      return createDeviceListRedirectionURL(chartSettings, deviceGroups, rowData, redirectionInfo);
    case 'dashboard':
      return createAnotherDashboardRedirectURL(chartSettings, redirectionInfo);
    default:
      return paramsInfo || '';
  }
};
