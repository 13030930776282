/**
 * Get Widget Details Reducer
 */
import { AnyAction } from 'redux';
import * as ACTION_TYPE from './widgetDetails.actionsTypes';
import { widgetDetailsState, widgetDetailsStatus } from './widgetDetails.stateType';

const initialState: widgetDetailsState = {
  status: widgetDetailsStatus.INIT,
  widgetDetailsData: []
};

function getWidgetDetailsProgress(state: widgetDetailsState): widgetDetailsState {
  return {
    ...state,
    status: widgetDetailsStatus.FETCHING
  };
}

function getWidgetDetailsSuccess(
  state: widgetDetailsState,
  widgetDetailsData: any
): widgetDetailsState {
  return {
    ...state,
    status: widgetDetailsStatus.READY,
    widgetDetailsData
  };
}

function getWidgetDetailsFailure(state: widgetDetailsState): widgetDetailsState {
  return {
    ...state,
    status: widgetDetailsStatus.ERROR
  };
}

function resetState(state: widgetDetailsState): widgetDetailsState {
  return {
    ...state,
    status: widgetDetailsStatus.INIT,
    widgetDetailsData: []
  };
}
/*
    Reducer is a pure function that takes actions and state as input 
    and returns/passes the modified state to the store.
    It is implemented using switch case to update the state according 
    to the ation type required.
*/

export default function widgetDetailsReducer(
  state: widgetDetailsState = initialState,
  action: AnyAction
): widgetDetailsState {
  switch (action.type) {
    case ACTION_TYPE.FETCH_WIDGET_DETAILS_PROGRESS:
      return getWidgetDetailsProgress(state);
    case ACTION_TYPE.FETCH_WIDGET_DETAILS_SUCCESS:
      return getWidgetDetailsSuccess(state, action.payload);
    case ACTION_TYPE.FETCH_WIDGET_DETAILS_FAILURE:
      return getWidgetDetailsFailure(state);
    case ACTION_TYPE.FETCH_WIDGET_DETAILS_RESET_STATE:
      return resetState(state);
    default:
      return state;
  }
}
