/**
 * SL Form component
 * @author mahesh.kedari@shorelineiot.com
 */

import React, { ReactElement, BaseSyntheticEvent, ReactNode } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

interface Props {
  /**
   * TODO: Marking onSubmit as optional
   * need to reconstruct SLTextfield to make onSubmit compulsory
   */
  onSubmit?: (
    data: Record<string, any>,
    event?: BaseSyntheticEvent<Object, any, any> | undefined
  ) => void | Promise<void>;
  children: ReactNode | ReactNode[];
  /**
   * Not all form require defaultValues
   */
  defaultValues?: any;
  reValidateMode?: 'onBlur' | 'onChange' | 'onSubmit';
  methods?: any;
  /**
   * Use this prop if you want to avoid submitting form accidentally
   * while pressing Enter on any input element.
   */
  stopPropogationOnEnter?: boolean;
  [key: string]: any;
}

export default function SLForm({
  onSubmit,
  children,
  defaultValues,
  methods,
  reValidateMode = 'onSubmit',
  stopPropogationOnEnter = false,
  ...otherProps
}: Props): ReactElement {
  const defaultMethods = useForm({
    defaultValues,
    reValidateMode
  });
  const allMethods = methods || defaultMethods;

  return (
    <FormProvider {...allMethods}>
      <form
        onSubmit={allMethods.handleSubmit(onSubmit)}
        role="presentation"
        onKeyPress={(e: any) => {
          if (stopPropogationOnEnter && e?.key === 'Enter') {
            e?.stopPropagation();
            e?.preventDefault();
          }
        }}
        autoComplete="off"
        {...otherProps}>
        {children}
      </form>
    </FormProvider>
  );
}
