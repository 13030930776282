import { AnyAction } from 'redux';
import * as ACTION_TYPE from './alarmsDetails.actionTypes';
import { AlarmsDetailsState, ALARMS_DETAILS_STATUS } from '../types/alarmsDetails.state';

const initialState: AlarmsDetailsState = {
  status: ALARMS_DETAILS_STATUS.INIT,
  AlarmsDetailsData: []
};
/**
 *
 * @param state
 * @returns
 */
function handleFetchAlarmsDetails(state: AlarmsDetailsState): AlarmsDetailsState {
  return {
    ...state,
    status: ALARMS_DETAILS_STATUS.FETCHING
  };
}
/**
 *
 * @param state
 * @param AlarmsDetailsData
 * @returns
 */
function handleFetchAlarmsDetailsSuccess(
  state: AlarmsDetailsState,
  AlarmsDetailsData: any
): AlarmsDetailsState {
  return {
    ...state,
    status: ALARMS_DETAILS_STATUS.READY,
    AlarmsDetailsData
  };
}
/**
 *
 * @param state
 * @returns
 */
function handleFetchAlarmsDetailsFailure(state: AlarmsDetailsState): AlarmsDetailsState {
  return {
    ...state,
    status: ALARMS_DETAILS_STATUS.ERROR
  };
}
/**
 *
 * @param state
 * @returns
 */
function resetAlarms(state: AlarmsDetailsState): AlarmsDetailsState {
  return {
    ...state,
    status: ALARMS_DETAILS_STATUS.INIT,
    AlarmsDetailsData: []
  };
}
/**
 *
 * @param state
 * @param action
 * @returns
 */
export default function alarmsDetailsReducer(
  state: AlarmsDetailsState = initialState,
  action: AnyAction
): AlarmsDetailsState {
  switch (action.type) {
    case ACTION_TYPE.FETCH_ALARMS_DETAILS:
      return handleFetchAlarmsDetails(state);
    case ACTION_TYPE.FETCH_ALARMS_DETAILS_SUCCESS:
      return handleFetchAlarmsDetailsSuccess(state, action.payload);
    case ACTION_TYPE.FETCH_ALARMS_DETAILS_FAILURE:
      return handleFetchAlarmsDetailsFailure(state);
    case ACTION_TYPE.RESET_ALARMS_DETAILS:
      return resetAlarms(state);
    default:
      return state;
  }
}
