import { useFetchTourConfigQuery, useUpdateTourInfoMutation } from './tour.api';
import { FetchTourArgs } from './tour.types';

export const useFetchTourConfig = (args: FetchTourArgs) => {
  const { data: tourConfig, isFetching: isFetchTourConfig } = useFetchTourConfigQuery(args, {
    skip: args.slug === 'org'
  });

  return {
    tourConfig,
    isFetchingTourConfig: isFetchTourConfig
  };
};

export const useUpdateTourInfo = () => {
  const [updateTourInfo, { isLoading: isUpdateTourLoading }] = useUpdateTourInfoMutation();

  return { updateTourInfo, isUpdateTourLoading };
};
