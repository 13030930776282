/**
 *
 */
import React, { ReactElement } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { useStyles } from './loadingPage.styles';
/**
 *
 */
export default function LoadingPage(): ReactElement {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CircularProgress />
    </div>
  );
}
