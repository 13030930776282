export enum GET_SURVEY_QUESTIONS_STATUS {
  INIT,
  PROGRESS,
  ERROR,
  COMPLETED
}

export interface nbFirstPage {
  choice: string;
  class: string;
  manufacturer: string;
  model: string;
  subclass: string;
}
export const nbFirstPagedefaults: nbFirstPage = {
  choice: 'database',
  class: '',
  manufacturer: '',
  model: '',
  subclass: ''
};
