/**
 * Query Limit Setting
 * @author mahesh.kedari@shorelineiot.com
 */
import { Input, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { ReactElement } from 'react';
import { SLPopover } from '../../popover/SLPopover';
import TranslateText from '../../../../i18n/TranslateText';

interface Props {
  defaultLimit: number;
  updateQueryLimit: (limit: number) => void;
}

const useStyles = makeStyles(() => ({
  label: {},
  container: {
    margin: '5px',
    display: 'inline-flex',
    columnGap: '14px'
  }
}));

export default function SLQueryLimitSetting({
  defaultLimit,
  updateQueryLimit
}: Props): ReactElement {
  const handleLimitChange: any = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue: any = event?.target?.value;
    if (defaultLimit !== newValue) {
      updateQueryLimit(newValue);
    }
  };
  const classes = useStyles();
  return (
    <SLPopover label="Limit">
      <div className={classes.container}>
        <Typography className={classes.label}>{TranslateText('limit', ' Limit')}</Typography>
        <Input type="number" defaultValue={defaultLimit} onBlur={handleLimitChange} />
      </div>
    </SLPopover>
  );
}
