import { DashboardListData } from '../store';
import { NEW_DASHBOARD_ID } from './dashboard';

export const newDashbaordDefaultDetails = (slug: any): DashboardListData => {
  return {
    role_uuids: [],
    dashboard_uuid: NEW_DASHBOARD_ID,
    is_default: false,
    meta_data: {},
    name: 'New Dashboard',
    slug,
    edit_mode: true
  };
};
