/**
 * VA Summary Reducer
 * @author mahesh.kedari@shorelineiot.com
 */
import { AnyAction } from 'redux';
import { VASummaryState, VA_SUMMARY_STATUS } from './vaSummary.stateType';
import * as ACTIONS from '../vibrationAnalysis.actionTypes';

const initialState: VASummaryState = {
  status: VA_SUMMARY_STATUS.INIT,
  summary: null
};

function handleAnomalyReset(): VASummaryState {
  return {
    ...initialState
  };
}

export default function VASummaryReducer(
  state: VASummaryState = initialState,
  action: AnyAction
): VASummaryState {
  switch (action.type) {
    case ACTIONS.RESET_NARROWBANDS:
    case ACTIONS.UPDATE_DATAPOINT_SELECTION:
    case ACTIONS.RESET_VIBRATION_ANALYSIS:
      return handleAnomalyReset();
    default:
      return state;
  }
}
