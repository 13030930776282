/**
 * SL Uncontrolled Select Component
 * @author priyanka.ambawane@shorelineiot.com
 */
import React, { ReactElement } from 'react';
import { Select, MenuItem, Typography, SelectChangeEvent, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiPaper-root': {
      maxHeight: '25vh'
    }
  },
  dropdwonIconStyle: {
    width: '18vw'
  },
  labelStyle: {
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: '15px'
  }
}));

interface Props {
  name: string;
  label: string;
  menuList: Array<{ value: any; label: string | number }>;
  onChange?: (event: SelectChangeEvent) => void;
}

function SLUncontrolledSelect({ name, label, menuList, onChange }: Props): ReactElement {
  const classes = useStyles();

  return (
    <>
      <Typography className={classes.labelStyle} variant="caption">
        {label}
      </Typography>
      <Select
        name={name}
        className={classes.dropdwonIconStyle}
        onChange={onChange}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          className: classes.root
        }}>
        {menuList.map((item: any) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </>
  );
}

export default SLUncontrolledSelect;
