/**
 * Roles List Reducer
 * @author mahesh.kedari@shorelineiot.com
 */
import { AnyAction } from 'redux';
import { RolesListState, ROLES_LIST_STATUS } from '../../types';
import * as ACTION_TYPE from './actions/rolesList.actionTypes';
/**
 *
 */
const initialState: RolesListState = {
  status: ROLES_LIST_STATUS.INIT,
  roles: []
};
/**
 *
 * @param state
 */
function fetchRolesProgress(state: RolesListState): RolesListState {
  return {
    ...state,
    status: ROLES_LIST_STATUS.FETCHING_ROLES,
    roles: []
  };
}

/**
 *
 * @param state
 * @param action
 */
function fetchRolesSuccess(state: RolesListState, action: AnyAction): RolesListState {
  return {
    ...state,
    roles: action.payload,
    status: ROLES_LIST_STATUS.READY
  };
}
/**
 *
 * @param state
 */
function fetchRolesFailure(state: RolesListState): RolesListState {
  return {
    ...state,
    status: ROLES_LIST_STATUS.ERROR
  };
}
function rolesReset(state: RolesListState): RolesListState {
  return {
    ...state,
    status: ROLES_LIST_STATUS.INIT,
    roles: []
  };
}
function rolesUpdate(state: RolesListState, data: AnyAction): RolesListState {
  const { payload, action } = data;
  const { roles } = state;
  const rolesToUpdate = payload.roles;

  let updatedRolesList = roles;
  if (action === 'add') {
    updatedRolesList = [...roles, ...rolesToUpdate];
  } else if (action === 'update') {
    updatedRolesList = updatedRolesList.map((singleRole: any) => {
      const found = rolesToUpdate.find((r: any) => r.role_id === singleRole.role_id);
      if (found) {
        return Object.assign(singleRole, found);
      }
      return singleRole;
    });
  } else if (action === 'delete') {
    updatedRolesList = updatedRolesList.filter((singleRole: any) => {
      const found = rolesToUpdate.find((r: any) => r.role_id !== singleRole.role_id);
      if (found) {
        return found;
      }
      return false;
    });
  }
  return {
    ...state,
    roles: [...updatedRolesList]
  };
}
/**
 *
 * @param state
 * @param action
 */
export default function RolesDataReducer(state: RolesListState = initialState, action: AnyAction) {
  switch (action.type) {
    case ACTION_TYPE.FETCH_ROLES:
      return fetchRolesProgress(state);
    case ACTION_TYPE.ROLES_SUCCESS:
      return fetchRolesSuccess(state, action);
    case ACTION_TYPE.ROLES_FAILURE:
      return fetchRolesFailure(state);
    case ACTION_TYPE.ROLES_RESET:
      return rolesReset(state);
    case ACTION_TYPE.ROLES_UPDATE:
      return rolesUpdate(state, action.payload);
    default:
      return state;
  }
}
