export enum deleteDashboardStatus {
  INIT,
  PROGRESS,
  SUCCESS,
  ERROR
}

export interface deleteDashboardState {
  status: deleteDashboardStatus;
}
