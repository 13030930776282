/**
 * Current session helper
 */
import { LoginStateType } from '../types/LoginStateType';
/**
 *
 * @param state
 */
export function onCheckCurrentSession(state: LoginStateType): LoginStateType {
  return {
    ...state,
    status: 'CHECKING_CURRENT_SESSION'
  };
}
/**
 *
 * @param state
 * @param session
 */
export function onCurrentSessionAvailable(state: LoginStateType, session: any): LoginStateType {
  return {
    ...state,
    status: 'LOGGED_IN',
    session
  };
}
/**
 *
 * @param state
 */
export function onCurrentSessionNotAvailable(state: LoginStateType): LoginStateType {
  return {
    ...state,
    status: 'NOT_LOGGED_IN',
    session: null
  };
}
