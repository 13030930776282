/**
 * digital sensor state
 * @author ashwini.gawade@shorelineiot.com
 */
export enum fetchDigitalSensorSchemaStatusDIO1 {
  INIT,
  PROGRESS,
  READY,
  ERROR
}

export enum fetchDigitalSensorSchemaStatusDIO2 {
  INIT,
  PROGRESS,
  READY,
  ERROR
}

export enum fetchDigitalSensorSchemaStatusDIO3 {
  INIT,
  PROGRESS,
  READY,
  ERROR
}

export enum fetchDigitalSensorSchemaStatusDIO4 {
  INIT,
  PROGRESS,
  READY,
  ERROR
}
/**
 *
 */

export interface fetchDigitalSensorSchemaState {
  statusDIO1: fetchDigitalSensorSchemaStatusDIO1;
  statusDIO2: fetchDigitalSensorSchemaStatusDIO2;
  statusDIO3: fetchDigitalSensorSchemaStatusDIO3;
  statusDIO4: fetchDigitalSensorSchemaStatusDIO4;
  DIO1: any;
  DIO2: any;
  DIO3: any;
  DIO4: any;
  defaultDIO1: any;
  defaultDIO2: any;
  defaultDIO3: any;
  defaultDIO4: any;
}

export enum addDigitalSensorStatus {
  INIT,
  PROGRESS,
  SUCCESS,
  ERROR
}

export interface addDigitalSensorState {
  status: addDigitalSensorStatus;
}
