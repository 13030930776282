/**
 * Logout Saga
 * @author mahesh.kedari@shorelineiot.com
 */
import { put, call } from 'redux-saga/effects';
import { AuthService } from '../../../../../framework/services/auth.service';
import * as actions from '../actions/login.actions';
/**
 *
 */
export function* logoutSaga() {
  const authService = new AuthService();
  yield call([authService, 'signOutCurrentUser']);
  yield put(actions.logoutSucceed());
}
