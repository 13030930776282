/**
 * Device Mailbox Saga
 * @author prerna.more@shorelineiot.com
 */
import { put, takeEvery, call } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import * as actions from './deviceMailbox.actions';
import * as ACTION_TYPES from './deviceMailbox.actionType';
import { GenericObject, httpGet, SafeSaga, APISERVICES } from '../../../../framework';
import { SagaIterator } from 'redux-saga';

function getDetails(data: any) {
  if (data.start && data.end) {
    return httpGet(
      `orgs/${data.slug}/device_mailbox?start=${data.start}&end=${data.end}`,
      null,
      APISERVICES.DEVICE_API
    );
  }

  return httpGet(`orgs/${data.slug}/device_mailbox`, null, APISERVICES.DEVICE_API);
}

function* deviceMailboxSaga(action: AnyAction) {
  const response: GenericObject = yield call(getDetails, action.payload);
  yield put(actions.fetchDeviceMailboxSuccess(response));
}

function* errorHandler() {
  yield put(actions.fetchDeviceMailboxFailure());
}

export function* watchdeviceMailboxSagas(): SagaIterator {
  yield takeEvery(
    ACTION_TYPES.FETCH_DEVICE_MAILBOX,
    SafeSaga(deviceMailboxSaga, ACTION_TYPES.FETCH_DEVICE_MAILBOX, errorHandler)
  );
}
