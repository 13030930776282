/**
 * Get Theme Reducer
 * @author priyanka.ambawane@shorelineiot.com
 */
import * as ACTION_TYPES from './theme.actionTypes';

const initialState = {
  fetchThemeProgress: false,
  fetchThemeSuccess: false,
  fetchThemeFailure: false,
  fetchThemeError: {},
  orgTheme: {}
};

export default function orgTheme(state = initialState, action: any) {
  switch (action.type) {
    case ACTION_TYPES.GET_ORG_THEME_PROGERSS:
      return {
        ...state,
        fetchThemeProgress: true
      };
    case ACTION_TYPES.GET_ORG_THEME_SUCCESS:
      return {
        ...state,
        fetchThemeProgress: false,
        fetchThemeSuccess: true,
        orgTheme: action.payload
      };
    case ACTION_TYPES.GET_ORG_THEME_FAILURE:
      return {
        ...state,
        fetchThemeProgress: false,
        fetchThemeFailure: true,
        fetchThemeError: action.payload
      };
    default:
      return state;
  }
}
