/**
 * Organisation Selector Actions
 * @author mahesh.kedari@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTIONS from './orgSelector.actionTypes';

/**
 *
 * @param data
 */

export const switchOrg = (data: any): AnyAction => {
  return {
    type: ACTIONS.SWITCH_ORG_INIT,
    payload: data
  };
};
/**
 * @param data response data
 * @returns data to be passed to reducer()
 */
export const switchOrgSuccess = (data: any) => {
  return {
    type: ACTIONS.SWITCH_ORG_SUCCESS,
    payload: data
  };
};
/**
 *
 * @param error retrun from api call
 */
export const switchOrgFailure = (error: any) => {
  return {
    type: ACTIONS.SWITCH_ORG_FAILED,
    error
  };
};
/**
 *
 */
export const selectOrg = () => {
  return {
    type: ACTIONS.SWITCH_ORG_SELECT
  };
};
/**
 *
 */
export const noOrgFound = () => {
  return {
    type: ACTIONS.NO_ORG_FOUND
  };
};
