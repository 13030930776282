/**
 * Create Anomaly Template Saga
 * @author aditya.bhadange@shorelineiot.com
 */
import { takeEvery, put, call } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import * as ANOMALY_TEMPLATE_ACTIONS from './createAnomalyTemplate.actionTypes';
import * as anomalyTemplateActions from './createAnomalyTemplate.actions';
import { GenericObject, httpPost, SafeSaga, showToast, APISERVICES } from '../../../../framework';
import { SagaIterator } from 'redux-saga';
/**
 *
 */
function createAnomalyTemplate(data: any) {
  return httpPost('anomalies', data, APISERVICES.DEVICE_API);
}
/**
 *
 */
function* createAnomalyTemplateSaga(action: AnyAction) {
  const response: GenericObject = yield call(createAnomalyTemplate, action.payload);

  yield put(anomalyTemplateActions.createAnomalyTemplateSuccess(response));
  yield put(showToast('Anomaly Template Created Successfully!', 'success'));
}
/**
 *
 * @param error
 */
function* createErrorHandler(error: any) {
  yield put(anomalyTemplateActions.createAnomalyTemplateFailure(error));
}
/**
 *
 */
export function* watchCreateAnomalyTemplateSaga(): SagaIterator {
  yield takeEvery(
    ANOMALY_TEMPLATE_ACTIONS.CREATE_ANOMALY_TEMPLATE,
    SafeSaga(
      createAnomalyTemplateSaga,
      ANOMALY_TEMPLATE_ACTIONS.CREATE_ANOMALY_TEMPLATE,
      createErrorHandler
    )
  );
}
