/**
 * Sensor Alerts Update Saga
 * @author shubham.holkar@shorelineiot.com
 */

import { put, takeEvery, call, select } from 'redux-saga/effects';
import * as actions from '../actions/alertsUpdate.actions';
import * as actionTypes from '../actions/alerts.actionTypes';

import { alertsListActions } from '../actions';
import { SafeSaga, httpPut, showToast, APISERVICES } from '../../../../framework';
import { SagaIterator } from 'redux-saga';
import {
  CreateRuleResponse,
  UpdateNBRule,
  UpdateNBRulePayload,
  UpdateSensorRule,
  UpdateSensorRulePayload
} from '../types/CreateAlertState';
import { RootState } from '../../../../configureStore';
import { Filter } from '../types/AlertsListState';

function updateAlert(slug: string, payload: UpdateSensorRulePayload | UpdateNBRulePayload) {
  return httpPut(`orgs/${slug}/alerts`, payload, APISERVICES.DEVICE_API);
}

export function* updateAlerts(action: { payload: UpdateSensorRule | UpdateNBRule }) {
  const { slug, payload, navigate, redirect } = action.payload;
  const response: CreateRuleResponse = yield call(updateAlert, slug, payload);
  yield put(actions.updateAlertsSuccess(response));
  const nbAlertsFilter: Filter = yield select(
    (state: RootState) => state.features.alerts.alertsList?.nbAlertsFilter
  );
  const filterPayload = {
    nbAlertsFilter,
    slug
  };
  yield put(alertsListActions.getAlertsList(filterPayload));
  yield put(showToast('Rule updated Successfully!', 'success'));

  if (redirect === true && navigate) {
    navigate(`/${slug}/app/alerts`);
  }
}

export function* watchAlertsUpdateSaga(): SagaIterator {
  yield takeEvery(actionTypes.UPDATE_ALERT, SafeSaga(updateAlerts, actionTypes.UPDATE_ALERT));
}
