/**
 * Provider Sagas
 * @author mahesh.kedari@shorelineiot.com
 */
import { AnyAction } from 'redux';
import { takeEvery, put, call } from 'redux-saga/effects';
import * as ACTIONS from './provider.actionTypes';
import * as actions from './provider.actions';
import {
  GenericObject,
  httpGet,
  removeProgressFor,
  showProgressFor,
  showToast,
  APISERVICES
} from '../../../../framework';
import { SagaIterator } from 'redux-saga';
/**
 *
 */
const FETCH_PROVIDER_ACTION = 'FETCH_PROVIDER_ACTION_ID';

function getProvider(domain: string) {
  return httpGet(`orgs/id_provider?domain=${domain}`, null, APISERVICES.HOST_API);
}

function* getProviderSaga(action: AnyAction) {
  try {
    yield put(showProgressFor(FETCH_PROVIDER_ACTION));
    const response: GenericObject = yield call(getProvider, action.payload);
    yield put(actions.handleProviderSuccess(response?.provider));
  } catch (error: any) {
    const errorBody = error?.response?.body;
    const parsedError = typeof errorBody === 'string' ? JSON.parse(errorBody) : errorBody;
    const errCode = parsedError?.err_code;
    if (errCode && errCode === 'ORG-SERVICE__DOMAIN-DOES-NOT-EXIST') {
      yield put(actions.handleProviderSuccess());
    } else {
      yield put(showToast('Failed to get authentication provider', 'error', true));
      yield put(actions.handleProviderError(error));
    }
  } finally {
    yield put(removeProgressFor(FETCH_PROVIDER_ACTION));
  }
}
/**
 *
 
function* errorHandler() {
  yield put(
    actions.handleProviderError({
      message: 'Could not get Authentication provider',
    })
  );
} */
export default function* watchProviderSaga(): SagaIterator {
  yield takeEvery(ACTIONS.GET_AUTH_PROVIDER, getProviderSaga);
}
