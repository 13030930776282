export const WEBSOCKET_MODULE_OPTIONS = {
  ALARMS: {
    ALARMS_LIST: 'alarms-list',
    HISTORICAL_ALARMS: 'historical-alarms'
  },
  DEVICE: {
    DEVICE_LIST: 'device-list',
    DEVICE_GROUP_LIST: 'device-group-list',
    DATAPOINT_LIST: 'datapoint-list',
    NB_MODULES: 'nb-modules',
    POWERTRAIN: 'powertrain',
    EXPORT_DATA: 'export-data'
  },
  USERS: {
    USERS_LIST: 'users-list'
  },
  ROLES: {
    ROLES_LIST: 'roles-list'
  }
};
