import { all } from 'redux-saga/effects';
import { watchWidgetDetailsSaga } from './widget-details';
import { watchdashboardItemsSaga } from './dashboard-items';
import { watchUpdateDashboardSaga } from './update-dashboard';
import { watchDeleteDashboardSagas } from './delete-dashboard';
import { watchCreateDashboardItemSaga } from './create-dashboard-item';
import { watchDashboardListSaga } from './dashboard-list';
import { watchGetDefaultDashboardSaga } from './default-dashboard';
import { watchUpdateDashboardItemSaga } from './update-dashboard-item';
import { watchDeleteDashboardItemSagas } from './delete-dashboard-item';
import { watchAddMultipleDashboardItemsSaga } from './add-multiple-dashboard-items';
import { watchUpdateMultipleDashboardItemsSaga } from './update-multiple-dashboard-items';
import { watchsetDefaultDashboardSaga } from './set-default-dashboard';

export function* dashboardSagas() {
  yield all([
    watchWidgetDetailsSaga(),
    watchdashboardItemsSaga(),
    watchUpdateDashboardSaga(),
    watchDeleteDashboardSagas(),
    watchCreateDashboardItemSaga(),
    watchDashboardListSaga(),
    watchGetDefaultDashboardSaga(),
    watchUpdateDashboardItemSaga(),
    watchDeleteDashboardItemSagas(),
    watchAddMultipleDashboardItemsSaga(),
    watchUpdateMultipleDashboardItemsSaga(),
    watchsetDefaultDashboardSaga()
  ]);
}
