/**
 * Anomaly Templates List Reducer
 * @author aditya.bhadange@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTIONS from './anomalyTemplatesList.actionTypes';
/**
 *
 */
export enum ANOMALY_TEMPLATES_LIST_STATUS {
  INIT,
  FETCHING,
  READY,
  ERROR
}
/**
 *
 */
export interface AnomalyTemplatesListState {
  status: ANOMALY_TEMPLATES_LIST_STATUS;
  list: Array<any>;
}
/**
 *
 */
const initialState: AnomalyTemplatesListState = {
  status: ANOMALY_TEMPLATES_LIST_STATUS.INIT,
  list: []
};
/**
 *
 * @param state
 * @returns
 */
const handleFetchAnomalyTemplatesList = (
  state: AnomalyTemplatesListState
): AnomalyTemplatesListState => ({
  ...state,
  status: ANOMALY_TEMPLATES_LIST_STATUS.FETCHING
});
/**
 *
 * @param state
 * @param list
 * @returns
 */
const handleFetchAnomalyTemplatesSuccess = (
  state: AnomalyTemplatesListState,
  list: Array<any>
): AnomalyTemplatesListState => ({
  ...state,
  status: ANOMALY_TEMPLATES_LIST_STATUS.READY,
  list
});
/**
 *
 * @param state
 * @returns
 */
const handleFetchAnomalyTemplatesFailure = (
  state: AnomalyTemplatesListState
): AnomalyTemplatesListState => ({
  ...state,
  status: ANOMALY_TEMPLATES_LIST_STATUS.ERROR,
  list: []
});
/**
 *
 * @param state
 * @param action
 * @returns
 */
export default function anomalyTemplatesListReducer(
  state: AnomalyTemplatesListState = initialState,
  action: AnyAction
): AnomalyTemplatesListState {
  switch (action.type) {
    case ACTIONS.FETCH_ANOMALY_TEMPLATES_LIST:
      return handleFetchAnomalyTemplatesList(state);
    case ACTIONS.FETCH_ANOMALY_TEMPLATES_LIST_SUCCESS:
      return handleFetchAnomalyTemplatesSuccess(state, action.payload);
    case ACTIONS.FETCH_ANOMALY_TEMPLATES_LIST_FAILURE:
      return handleFetchAnomalyTemplatesFailure(state);
    case ACTIONS.RESET_ANOMALY_TEMPLATES_LIST:
      return { ...initialState };
    default:
      return state;
  }
}
