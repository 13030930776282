export const FETCH_LIST_SUCCESSS = 'FEATURES/ALERTS/FETCH_ALERTS_LIST_SUCCESS';
export const FETCH_LIST_FAILURE = 'FEATURES/ALERTS/FETCH_ALERTS_LIST_FAILURE';
export const FETCH_LIST_PROGRESS = 'FEATURES/ALERTS/FETCH_LIST_PROGRESS';
export const CREATE_ALERT_SUCCESS = 'FEATURES/ALERTS/CREATE_ALERT_SUCCESS';
export const CREATE_ALERT_FAILURE = 'FEATURES/ALERTS/CREATE_ALERT_FAILURE';
export const CREATE_ALERT_PROGRESS = 'FEATURES/ALERTS/CREATE_ALERT_PROGRESS';
export const CONDITIONS_FORM_DATA = 'FEATURES/ALERTS/CONDITIONS_FORM_DATA';
export const UPDATE_SENSOR_ALERT = 'FEATURES/ALERTS/UPDATE_SENSOR_ALERT';
export const UPDATE_SENSOR_ALERT_SUCCESS = 'FEATURES/ALERTS/UPDATE_SENSOR_ALERT_SUCCESS';
export const FETCH_TAGS_LIST_SUCCESSS = 'FEATURES/ALERTS/FETCH_ALERTS_TAGS_LIST_SUCCESS';
export const FETCH_TAGS_LIST_FAILURE = 'FEATURES/ALERTS/FETCH_ALERTS_TAGS_LIST_FAILURE';
export const FETCH_TAGS_LIST_PROGRESS = 'FEATURES/ALERTS/FETCH_TAGS_LIST_PROGRESS';
export const UPDATE_ALERT = 'FEATURES/ALERTS/UPDATE_ALERT';
export const UPDATE_ALERT_SUCCESS = 'FEATURES/ALERTS/UPDATE_ALERT_SUCCESS';
export const DELETE_SENSOR_ALERT = 'FEATURES/ALERTS/DELETE_SENSOR_ALERT';
export const DELETE_SENSOR_ALERT_SUCCESS = 'FEATURES/ALERTS/DELETE_SENSOR_ALERT_SUCCESS';
export const DELETE_SENSOR_ALERT_RESET = 'FEATURES/ALERTS/DELETE_SENSOR_ALERT_RESET';

export const FETCH_SENSOR_ALERT_SUCCESSS = 'FEATURES/ALERTS/FETCH_ALERTS_SENSOR_ALERT_SUCCESS';
export const FETCH_SENSOR_ALERT_FAILURE = 'FEATURES/ALERTS/FETCH_ALERTS_SENSOR_ALERT_FAILURE';
export const FETCH_SENSOR_ALERT_PROGRESS = 'FEATURES/ALERTS/FETCH_SENSOR_ALERT_PROGRESS';

export const RESET_ALERTS_STATE = 'FEATURES/ALERTS/RESET_ALERTS_STATE';

/*
FILTER
*/

export const UPDATE_NBALERTS_FILTER = 'FEATURES/ALERTS/UPDATE_NBALERTS_FILTER';
export const FETCH_ALERTS_LEVEL_SUCCESS = 'FEATURES/ALERTS/FETCH_ALERTS_LEVEL_SUCCESSS';
