//these conditions are applicable for battery remaining and replace battery columns
export const conditions = [
  {
    value: 'EQUALS',
    label: 'equal to'
  },
  {
    value: 'NOT_EQUAL',
    label: 'not equal to'
  },
  {
    value: 'GREATER_THAN',
    label: 'greater than'
  },
  {
    value: 'GREATER_THAN_OR_EQUAL',
    label: 'greater than equal to'
  },
  {
    value: 'LESS_THAN',
    label: 'less than'
  },
  {
    value: 'LESS_THAN_OR_EQUAL',
    label: 'less than equal to'
  }
];

//these conditions are applicable for sensor name column
export const sensorNameConditions = [
  {
    value: 'EQUALS',
    label: 'equal to'
  },
  {
    value: 'NOT_EQUAL',
    label: 'not equal to'
  },
  {
    value: 'CONTAINS',
    label: 'contains'
  },
  {
    value: 'DOES_NOT_CONTAIN',
    label: 'does not contain'
  }
];

//common for all the three columns
export const formatterOptions = [
  {
    value: 'color',
    label: 'Text Color'
  },
  {
    value: 'bgcolor',
    label: 'Background'
  },
  {
    value: 'indicator',
    label: 'Indicator'
  }
];

export const defaultSensorNameCondition = 'EQUALS';

export const defaultFormatterType = 'color';

export const defaultSensorNameConditionObject = {
  condition: 'EQUALS',
  value: '',
  formatters: []
};

export const columns = [
  {
    title: 'Sensor Name',
    name: 'sensor_name'
  },
  {
    title: 'Total Battery Remaining',
    name: 'battery_remaining'
  },
  {
    title: 'Replace Battery',
    name: 'replace_battery'
  }
];

export const BatteryRemainingDisplayTypes = {
  PERCENTAGE: 'PERCENTAGE',
  ICON: 'ICON'
};

//keys represent columns of the table and object represents the formatting applied for that column in the form of id and object
export const defaultBatteryStatusSettings = {
  sensor_name: {
    conditions: []
  },
  battery_remaining: {
    display: BatteryRemainingDisplayTypes.PERCENTAGE,
    conditions: []
  },
  replace_battery: {
    conditions: []
  }
};
