import React, { useEffect, useRef, useState } from 'react';
import { SketchPicker, ColorResult, Color } from 'react-color';
import { useStyles } from './dialWidget.styles';

interface ColorPickerProps {
  id: number;
  handleColorChange: (color: ColorResult, colorRangeId: number) => void;
  color: Color;
}
const DialWidgetColorPicker = ({ id, handleColorChange, color }: ColorPickerProps) => {
  const [isColorPickerVisible, setColorPickerVisibility] = useState<boolean>(false);
  const pickerRef = useRef<HTMLDivElement | null>(null);
  const classes = useStyles();

  const handleColorClick = () => {
    setColorPickerVisibility((prevVisibility) => !prevVisibility);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target as Node)) {
        setColorPickerVisibility(false);
      }
    };

    if (isColorPickerVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isColorPickerVisible]);

  return (
    <div ref={pickerRef}>
      {isColorPickerVisible && (
        <div className={classes.colorPicker}>
          <SketchPicker color={color} onChangeComplete={(color) => handleColorChange(color, id)} />
        </div>
      )}
      <div
        role="button"
        onClick={handleColorClick}
        className={classes.selectedColor}
        style={{
          backgroundColor: `${color}`
        }}
      />
    </div>
  );
};

export default DialWidgetColorPicker;
