/**
 * Anomaly List Reducer
 * @author mahesh.kedari@shorelineiot.com
 */
import { AnyAction } from 'redux';
import { AnomalyListState, ANOMALY_LIST_STATUS, DerivedExpressions } from './anomaly-list.state';
import * as ACTIONS from '../anomaly.actionTypes';
import { PayloadAction } from '@reduxjs/toolkit';

const initialState: AnomalyListState = {
  status: ANOMALY_LIST_STATUS.INIT,
  list: { derived_expressions: [] }
};

function onAnomolyListProgress(state: AnomalyListState): AnomalyListState {
  return {
    ...state,
    status: ANOMALY_LIST_STATUS.FETCHING
  };
}
/**
 *
 * @param state
 */
function onAnomolyListSuccess(
  state: AnomalyListState,
  action: PayloadAction<DerivedExpressions>
): AnomalyListState {
  return {
    ...state,
    status: ANOMALY_LIST_STATUS.READY,
    list: action.payload
  };
}

function onAnomolyListFailure(state: AnomalyListState): AnomalyListState {
  return {
    ...state,
    status: ANOMALY_LIST_STATUS.ERROR
  };
}

function onAnomolyListReset(): AnomalyListState {
  return {
    ...initialState
  };
}

export default function anomalyListReducer(
  state: AnomalyListState = initialState,
  action: AnyAction
): AnomalyListState {
  switch (action.type) {
    case ACTIONS.FETCH_ANOMALY_CONFIG_LIST:
      return onAnomolyListProgress(state);
    case ACTIONS.ANOMALY_CONFIG_LIST_SUCCESS:
      return onAnomolyListSuccess(state, action as PayloadAction<DerivedExpressions>);
    case ACTIONS.ANOMALY_CONFIG_LIST_FAILURE:
      return onAnomolyListFailure(state);
    case ACTIONS.ANOMALY_CONFIG_LIST_RESET:
      return onAnomolyListReset();
    default:
      return state;
  }
}
