/**
 * Area Chart View
 * @author mahesh.kedari@shorelineiot.com
 */
import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { ChartViewProps } from '../common/ChartViewProps';
import { areaChartConfig as defaultAreaChartSettings } from './areaChartSettings';
import useCartesionChart from '../utils/useCartesianChart';
/**
 *
 * @param param0
 * @returns
 */
const SLAreaChartView: React.FC<ChartViewProps> = React.forwardRef(
  ({ chartSettings, resultSet, widgetType }: ChartViewProps, ref: any) => {
    const areaChartSettings = useCartesionChart(
      chartSettings,
      defaultAreaChartSettings(),
      resultSet,
      widgetType,
      'area'
    );

    return <HighchartsReact highcharts={Highcharts} options={areaChartSettings} ref={ref} />;
  }
);
export default SLAreaChartView;
