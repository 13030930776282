/**
 * Stack X Axis Formatter
 * @author mahesh.kedari@shorelineiot.com
 */
import { formattedDate } from '../../../helpers';

export const timeAxisFormatter = (axisObject: any) => {
  const updatedValue = axisObject.value;
  const dateObj = new Date(updatedValue);
  const range = axisObject.axis.max - axisObject.axis.min;
  if (range > 86400000) {
    return formattedDate(dateObj);
  }
  if (range > 10000) {
    return dateObj.toLocaleTimeString();
  }
  const mil = axisObject.value % 1000;
  return `${dateObj.toLocaleTimeString()}-${mil}`;
};

/**
 *
 * @param resultSet It is a resultSet object which contains
 *  time selected in **By** category in widget editor screen.
 * @returns true if Time **By** selected as 'Hour', 'Minute' or 'Second', else false
 */
export const isByHourMinuteSecondSelected = (resultSet: any) => {
  if (
    resultSet?.loadResponse?.results[0]?.query?.timeDimensions[0]?.granularity === 'hour' ||
    resultSet?.loadResponse?.results[0]?.query?.timeDimensions[0]?.granularity === 'minute' ||
    resultSet?.loadResponse?.results[0]?.query?.timeDimensions[0]?.granularity === 'second'
  ) {
    return true;
  }
  return false;
};
