import { generateTags } from '../../../../../../framework/lib/rtk/RTK.utils';
import { featureApi } from '../../../../../feature.api';
import {
  FetchOrphanGroupArgs,
  AssociateUserToGroupArgs,
  OrphanGroup,
  GROUP_TAG
} from './group.types';
import { USER_TAG_TYPES } from '../user-list';
import { fetchOrphanGroupQuery, associateUserToGroupQuery } from './groupQuery.utils';

export const groupApi = featureApi.injectEndpoints({
  endpoints: (build) => ({
    fetchOrphanGroups: build.query<OrphanGroup[], FetchOrphanGroupArgs>({
      query: fetchOrphanGroupQuery,
      providesTags: (result) => generateTags(result, USER_TAG_TYPES.ORPHAN_GROUP)
    }),
    updateUserGroup: build.mutation<void, AssociateUserToGroupArgs>({
      query: associateUserToGroupQuery,
      invalidatesTags: () => [USER_TAG_TYPES.ORPHAN_GROUP, USER_TAG_TYPES.USER_GROUP]
    })
  })
});

export const {
  useLazyFetchOrphanGroupsQuery,
  useFetchOrphanGroupsQuery,
  useUpdateUserGroupMutation
} = groupApi;
