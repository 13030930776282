/**
 * Alerts List Saga
 * @author shubham.holkar@shorelineiot.com
 */

import { put, takeEvery, call } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import * as actions from '../actions/tagsList.actions';
import * as actionTypes from '../actions/alerts.actionTypes';
import { GenericObject, httpGet, SafeSaga, APISERVICES } from '../../../../framework';
import { SagaIterator } from 'redux-saga';

function getList(slug: any) {
  return httpGet(`orgs/${slug}/alerts/supported_tags`, null, APISERVICES.DEVICE_API);
}

export function* getTags(action: AnyAction) {
  const response: GenericObject = yield call(getList, action.payload);
  const { tags } = response;

  yield put(actions.fetchTagsListSuccess(tags));
}

function* errorHandler() {
  yield put(actions.fetchTagsListFailure());
}

export function* watchTagsListSaga(): SagaIterator {
  yield takeEvery(
    actionTypes.FETCH_TAGS_LIST_PROGRESS,
    SafeSaga(getTags, actionTypes.FETCH_TAGS_LIST_PROGRESS, errorHandler)
  );
}
