/**
 * update widget settings reducer
 * @author ashwini.gawade@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTIONS_TYPES from './updateWidgetSetting.actionTypes';
import { UpdateWidgetSettingState, UpdateWidgetSettingStatus } from './updateWidgetSetting.state';

const initialState: UpdateWidgetSettingState = {
  status: UpdateWidgetSettingStatus.INIT
  // response: [],
};
/**
 *
 * @param state
 */
function updateWidgetSettings(state: UpdateWidgetSettingState): UpdateWidgetSettingState {
  return { ...state, status: UpdateWidgetSettingStatus.IN_PROGRESS };
}
/**
 *
 * @param state
 * @param payload
 */
function updateWidgetSettingsSuccess(state: UpdateWidgetSettingState): UpdateWidgetSettingState {
  return {
    ...state,
    status: UpdateWidgetSettingStatus.READY
    // response: payload,
  };
}
/**
 *
 * @param state
 */
function updateWidgetSettingsFailure(state: UpdateWidgetSettingState): UpdateWidgetSettingState {
  return {
    ...state,
    status: UpdateWidgetSettingStatus.ERROR
    // response: payload,
  };
}
/**
 *
 * @param state
 */
function resetWidgetSettings(): UpdateWidgetSettingState {
  return {
    ...initialState
  };
}
/**
 *
 * @param state
 * @param action
 */
export default function updateWidgetSettingReducer(
  state: UpdateWidgetSettingState = initialState,
  action: AnyAction
): UpdateWidgetSettingState {
  switch (action.type) {
    case ACTIONS_TYPES.UPDATE_WIDGET_SETTINGS:
      return updateWidgetSettings(state);
    case ACTIONS_TYPES.UPDATE_WIDGET_SETTINGS_SUCCESS:
      return updateWidgetSettingsSuccess(state);
    case ACTIONS_TYPES.UPDATE_WIDGET_SETTINGS_FAILURE:
      return updateWidgetSettingsFailure(state);
    case ACTIONS_TYPES.RESET_WIDGET_SETTINGS_STATE:
      return resetWidgetSettings();
    default:
      return state;
  }
}
