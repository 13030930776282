import { AnyAction } from 'redux';
import * as ACTION_TYPE from './deleteDashboardItem.actionTypes';
import { deleteDashboardItemStatus, deleteDashboardItemState } from './deleteDashboardItem.state';
/**
 *
 */

/**
 *
 */

const initialState: deleteDashboardItemState = {
  status: deleteDashboardItemStatus.INIT
};

function deleteDashboardItemProgress(state: deleteDashboardItemState): deleteDashboardItemState {
  return {
    ...state,
    status: deleteDashboardItemStatus.PROGRESS
  };
}
function deleteDashboardItemSuccess(state: deleteDashboardItemState): deleteDashboardItemState {
  return {
    ...state,
    status: deleteDashboardItemStatus.SUCCESS
  };
}
/**
 *
 * @param state
 */

/**
 *
 */

/**
 *
 */
function deleteDashboardItemFailure(state: deleteDashboardItemState): deleteDashboardItemState {
  return {
    ...state,
    status: deleteDashboardItemStatus.ERROR
  };
}

function deleteDashboardItemReset(state: deleteDashboardItemState): deleteDashboardItemState {
  return {
    ...state,
    status: deleteDashboardItemStatus.INIT
  };
}
/**
 *
 * @param state
 * @param action
 */
export default function deleteDashboardItemReducer(
  state: deleteDashboardItemState = initialState,
  action: AnyAction
): deleteDashboardItemState {
  switch (action.type) {
    case ACTION_TYPE.DELETE_DASHBOARD_ITEM_PROGRESS:
      return deleteDashboardItemProgress(state);
    case ACTION_TYPE.DELETE_DASHBOARD_ITEM_SUCCESS:
      return deleteDashboardItemSuccess(state);
    case ACTION_TYPE.DELETE_DASHBOARD_ITEM_FAILURE:
      return deleteDashboardItemFailure(state);
    case ACTION_TYPE.DELETE_DASHBOARD_ITEM_RESET_STATE:
      return deleteDashboardItemReset(state);

    default:
      return state;
  }
}
