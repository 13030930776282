export type FetchRolesArgs = {
  slug: string;
};

export type Role = {
  role_name: string;
  role_id: string;
  users: number;
  display_name: string;
  description: string;
};

export const ROLES_TAG_TYPES = {
  ROLES: 'Roles'
};

export const USER = 'User';
