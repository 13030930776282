import { Dispatch } from 'redux';
import { alarmsApi } from '../../../alarms/store/alarms.api';
import {
  ALARMS_VALIDATION_TAGS,
  AlarmDeleteWebsocketPayload,
  FetchAlarmsArgs
} from '../../../alarms/store/alarms.types';
import { getAlarmsIds } from './utils/device.websocket.utils';

export function alarmsMessageHandler(dispatch: Dispatch<any>, data: any, arg: FetchAlarmsArgs) {
  const deletePayload: AlarmDeleteWebsocketPayload = { ...data };

  switch (data.action) {
    case 'add':
      dispatch(
        alarmsApi.util.updateQueryData('fetchAlarms', arg, (draftAlarms) => {
          draftAlarms.push(...data.payload.alarms);
        })
      );
      dispatch(alarmsApi.util.invalidateTags([ALARMS_VALIDATION_TAGS.ALARM_COUNT]));
      break;
    case 'update':
      dispatch(
        alarmsApi.util.updateQueryData('fetchAlarms', arg, (draftAlarms) => {
          for (const updatedAlarm of data.payload.alarms) {
            const updatedAlarmId = updatedAlarm.alarm_id;
            const alarmIndex = draftAlarms.findIndex((alarm) => alarm.alarm_id === updatedAlarmId);
            if (alarmIndex !== -1) {
              draftAlarms[alarmIndex] = updatedAlarm;
            }
          }
        })
      );
      break;
    case 'delete':
      dispatch(
        alarmsApi.util.updateQueryData('fetchAlarms', arg, (draftAlarms) => {
          const alarmsIds = getAlarmsIds(deletePayload.payload.alarms);
          const updatedAlarms = draftAlarms.filter((alarm) => !alarmsIds.includes(alarm.alarm_id));
          return updatedAlarms;
        })
      );
      dispatch(alarmsApi.util.invalidateTags([ALARMS_VALIDATION_TAGS.ALARM_COUNT]));
      break;
  }
}
