import { ThunkDispatch } from '@reduxjs/toolkit';
import { showToast } from '../../../../framework';
import { ALARM_STATE_OPTIONS } from '../alarms.constants';
import { AlarmStatusType } from '..';

export const showAlarmsStateChangeToast = (
  dispatch: ThunkDispatch<any, any, any>,
  alarmStatus: AlarmStatusType
) => {
  if (alarmStatus === ALARM_STATE_OPTIONS.FIXED) {
    dispatch(showToast('Successfully marked as fixed alarm', 'success'));
  } else if (alarmStatus === ALARM_STATE_OPTIONS.FALSE) {
    dispatch(showToast('Successfully marked as false alarm', 'success'));
  } else if (alarmStatus === ALARM_STATE_OPTIONS.ACKNOWLEDGED) {
    dispatch(showToast('Successfully marked as acknowledged alarm', 'success'));
  } else if (alarmStatus === ALARM_STATE_OPTIONS.IN_PROGRESS) {
    dispatch(showToast('Successfully marked as in progress alarm', 'success'));
  } else if (alarmStatus === ALARM_STATE_OPTIONS.NEW) {
    dispatch(showToast('Successfully marked as new alarm', 'success'));
  }
};
