/**
 * Component List Reducer
 * @author mahesh.kedari@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTIONS from './componentApplyToDevices.actionTypes';
import { ComponentType } from '../component-details/componentDetails.type';
/**
 *
 */
export enum COMPONENT_ATTACH_LIST_STATUS {
  INIT,
  FETCHING,
  IN_PROGRESS,
  READY,
  UPDATED,
  ERROR
}
/**
 *
 */
export interface ComponentAttachListState {
  status: COMPONENT_ATTACH_LIST_STATUS;
  list: Array<ComponentType>;
}
/**
 *
 */
const initialState: ComponentAttachListState = {
  status: COMPONENT_ATTACH_LIST_STATUS.INIT,
  list: []
};
/**
 *
 * @param state
 * @returns
 */
const handleFetchComponentList = (state: ComponentAttachListState): ComponentAttachListState => ({
  ...state,
  status: COMPONENT_ATTACH_LIST_STATUS.FETCHING
});
/**
 *
 * @param state
 * @param list
 * @returns
 */
const handleFetchComponentsSuccess = (
  state: ComponentAttachListState,
  list: Array<ComponentType>
): ComponentAttachListState => ({
  ...state,
  status: COMPONENT_ATTACH_LIST_STATUS.READY,
  list
});

const handleFetchComponentsFailure = (
  state: ComponentAttachListState
): ComponentAttachListState => ({
  ...state,
  status: COMPONENT_ATTACH_LIST_STATUS.ERROR,
  list: []
});

const handleUpdateDetails = (state: ComponentAttachListState): ComponentAttachListState => {
  return {
    ...state,
    status: COMPONENT_ATTACH_LIST_STATUS.IN_PROGRESS
  };
};

const handleUpdateSuccess = (state: ComponentAttachListState, component: any) => {
  return {
    ...state,
    status: COMPONENT_ATTACH_LIST_STATUS.UPDATED,
    data: component
  };
};

const handleUpdateFailure = (state: ComponentAttachListState) => ({
  ...state,
  status: COMPONENT_ATTACH_LIST_STATUS.ERROR
});
/**
 *
 * @param state
 * @param action
 * @returns
 */
export default function componentListReducer(
  state: ComponentAttachListState = initialState,
  action: AnyAction
): ComponentAttachListState {
  switch (action.type) {
    case ACTIONS.FETCH_COMPONENT_ATTACH_LIST:
      return handleFetchComponentList(state);
    case ACTIONS.FETCH_COMPONENT_ATTACH_LIST_SUCCESS:
      return handleFetchComponentsSuccess(state, action.payload);
    case ACTIONS.FETCH_COMPONENT_ATTACH_LIST_FAILURE:
      return handleFetchComponentsFailure(state);
    case ACTIONS.RESET_COMPONENT_ATTACH_LIST:
      return { ...initialState };
    case ACTIONS.UPDATE_COMPONENT_ATTACH_LIST:
      return handleUpdateDetails(state);
    case ACTIONS.UPDATE_COMPONENT_ATTACH_LIST_SUCCESS:
      return handleUpdateSuccess(state, action.payload);
    case ACTIONS.UPDATE_COMPONENT_ATTACH_LIST_FAILURE:
      return handleUpdateFailure(state);
    default:
      return state;
  }
}
