/**
 * Reset Password Form
 * @author mahesh.kedari@shorelineiot.com
 */
import { Box, Link, TextField, Typography, Tooltip } from '@mui/material';
import React, { useRef, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SLCheckbox, SLForm, SLSubmit, useQuery, useSlugContext } from '../../../../framework';
import Passwords from '../../register/register-form/components/Passwords';
import AuthHeader from '../../shared/AuthHeader';
import {
  registerDefaultValues as defaultValues,
  registrationValidationRule as rules
} from '../../register/register-form/register.validation';
import { acceptInviteActions } from '../store/actions';
import { useUserSession } from '../store';
import TermsAndConditionsDialog from './TermsAndConditionsDialog';
import { useTheme } from '@mui/material/styles';

/**
 *
 * @returns
 */
export const ResetPasswordForm: React.FC = () => {
  const textFieldRef = useRef<HTMLInputElement>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const dispatch = useDispatch();
  const { slug } = useSlugContext();
  const user: any = useUserSession();
  const query = useQuery();
  const theme = useTheme();
  const userEmail = query.get('email');
  const onSubmit = (data: any) => {
    const role = query.get('role');
    const email = query.get('email');
    const subOrgSlugs = query.get('slugs')?.split(',');
    dispatch(
      acceptInviteActions.resetPasswordAndAccept({
        user,
        email,
        newPassword: data.password,
        slug,
        subOrgSlugs,
        role
      })
    );
  };

  const handleTermsAndConditionsClick = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    const input = textFieldRef?.current;
    if (input) {
      setShowTooltip(input.clientWidth < input.scrollWidth);
    }
  }, [textFieldRef]);

  return (
    <>
      <AuthHeader title="Accept Shoreline Cloud Portal Invitation" message="" />
      <SLForm onSubmit={onSubmit} defaultValues={defaultValues}>
        <Box display="flex" flexDirection="column">
          {showTooltip && (
            <Tooltip
              title={userEmail}
              placement="top-end"
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, -30]
                      }
                    }
                  ]
                }
              }}>
              <TextField
                inputRef={textFieldRef}
                id="userEmail"
                name="userEmail"
                label="User Email"
                value={userEmail}
                variant="standard"
                fullWidth
                disabled
                sx={{
                  '& .MuiInputBase-input': {
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                  }
                }}
              />
            </Tooltip>
          )}
          {!showTooltip && (
            <TextField
              inputRef={textFieldRef}
              id="userEmail"
              name="userEmail"
              label="User Email"
              value={userEmail}
              variant="standard"
              fullWidth
              disabled
              sx={{
                '& .MuiInputBase-input': {
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                }
              }}
            />
          )}

          <Passwords />
          <SLCheckbox
            name="terms"
            label={
              <>
                <Typography variant="body1">
                  Accept the&nbsp;
                  <Link
                    color={theme.palette.secondary.main}
                    onClick={handleTermsAndConditionsClick}
                    sx={{ cursor: 'pointer' }}>
                    Terms and Conditions
                  </Link>
                </Typography>
              </>
            }
            rules={rules.acceptTnC}
            showLabelSeparately
          />
          <TermsAndConditionsDialog open={openDialog} onClose={handleDialogClose} />
          <br />
        </Box>
        <Box display="flex" justifyContent="center" m={3}>
          <SLSubmit dataTestId="set-password-button">Create New Password</SLSubmit>
        </Box>
      </SLForm>
    </>
  );
};
