/**
 * Table Editor
 * @author mahesh.kedari@shorelineiot.com
 */

import React, { useState } from 'react';
import merge from 'lodash/merge';
import { useFormContext } from 'react-hook-form';
import { ChartEditorProps } from '../common/ChartEditorProps';
import ResetDefaultConfig from '../common/ResetDefaultConfig';
import TableWidgetCommonCommonEditor from './editor/TableWidgetCommonEditor';
import DataSpecificTableWidgetEditor from './editor/DataSpecificTableChartEditor';
/**
 *
 * @returns
 */
const SLTableEditor: React.FC<ChartEditorProps> = ({
  handleSubmitForm,
  defaultValues,
  dataConfig,
  widgetType
}: ChartEditorProps) => {
  const formDefaults = {
    sorting: false,
    columnAlignment: 'left'
  };

  const [tableWidgetSettings, setTableWidgetSettings] = useState<any>({
    chartType: 'table',
    chartSettings: {
      sorting: defaultValues?.sorting ? defaultValues?.sorting : formDefaults.sorting,
      columnAlignment: defaultValues?.columnAlignment || formDefaults.columnAlignment,
      dataSettings: defaultValues?.dataSettings
    }
  });

  const { getValues } = useFormContext();

  const handleUpdate = () => {
    const currentSettings = merge({}, tableWidgetSettings);
    const values = getValues();
    const updatedSettings = {
      ...currentSettings,
      chartSettings: {
        ...values
      }
    };
    handleSubmitForm(updatedSettings);
    setTableWidgetSettings(updatedSettings);
  };

  return (
    <>
      <TableWidgetCommonCommonEditor
        currentSettings={tableWidgetSettings.chartSettings}
        handleUpdate={handleUpdate}
      />
      <DataSpecificTableWidgetEditor
        dataSettings={tableWidgetSettings?.chartSettings?.dataSettings}
        query={dataConfig?.query}
        widgetType={widgetType}
        handleUpdate={handleUpdate}
      />
      <ResetDefaultConfig chartType="table" onReset={handleUpdate} />
    </>
  );
};

export default SLTableEditor;
