import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  content: {
    marginLeft: '5%',
    marginRight: '5%'
  },
  divider: {
    marginBottom: '3%',
    marginTop: '3%'
  },
  alarm: {
    backgroundColor: 'red',
    borderRadius: '50%',
    height: '10px',
    width: '10px',
    display: 'inline-block',
    marginLeft: '5px'
  },
  colorPicker: {
    position: 'fixed',
    zIndex: 2,
    bottom: 0
  },
  selectedColor: {
    width: '40px',
    height: '15px',
    marginTop: '12px',
    cursor: 'pointer'
  }
}));
