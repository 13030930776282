/**
 * Narrowband Reducer Aggregator
 * @author mahesh.kedari@shorelineiot.com
 */

import { combineReducers } from 'redux';
import { deleteNarrowbandReducer as deleteNarrowband } from './delete-narrowband';
import {
  addSurveyReducer as addSurvey,
  editSurveyReducer as editSurvey,
  getSurveyAnswersReducer as getSurveyAnswers,
  getSurveyQuestionsReducer as getSurveyQuestions,
  getAutoNarrowbandListReducer as getAutoNarrowbandList,
  autoconfigNBReducer as autoConfig
} from './automatic-config';
import { manualConfig } from './manual-config';
/**
 *
 */
export const narrowbandReducer = combineReducers({
  addSurvey,
  editSurvey,
  getSurveyAnswers,
  getSurveyQuestions,
  getAutoNarrowbandList,
  manualConfig,
  autoConfig,
  deleteNarrowband
});
