import { AnyAction } from 'redux';
import * as ACTIONS_TYPES from './rolesUpdate.actionTypes';

export const updateRole = (data: any): AnyAction => {
  return {
    type: ACTIONS_TYPES.UPDATE_ROLE_INIT,
    payload: data
  };
};

export const updateRoleSucccess = (data: any): AnyAction => {
  return {
    type: ACTIONS_TYPES.UPDATE_ROLE_SUCCESS,
    payload: data
  };
};

export const updateRoleFailure = (error: any): AnyAction => {
  return {
    type: ACTIONS_TYPES.UPDATE_ROLE_FAILURE,
    error
  };
};

export const updateRoleReset = (): AnyAction => {
  return {
    type: ACTIONS_TYPES.UPDATE_ROLE_RESET,
    payload: false
  };
};
