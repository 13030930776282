/**
 * Authentication Pages Header
 * @author mahesh.kedari@shorelineiot.com
 */
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import { usePlatformDetails } from '../../../framework';
import TranslateText from '../../../i18n/TranslateText';
/**
 *
 */
interface Props {
  title: string;
  message: string;
  path?: string;
  buttonText?: string;
  translationId?: string;
}
/**
 *
 */
export default function AuthHeader(props: Props) {
  const { title, message, path, buttonText, translationId } = props;
  const { isMobile, isPortrait } = usePlatformDetails();

  return (
    <Box
      display="flex"
      flexDirection="column"
      flexGrow={isMobile || isPortrait ? 0.2 : 1}
      justifyContent={isPortrait ? 'flex-end' : 'center'}
      py={isMobile ? '5px' : '25px'}>
      <Typography variant="h2">
        {translationId ? TranslateText(translationId, title) : title}
      </Typography>
      {path && (
        <Box mt={isMobile ? '6px' : '13px'}>
          <Typography variant="h2">
            {message}&nbsp;&nbsp;
            <Link component={RouterLink} to={path} color="secondary">
              {buttonText}
            </Link>
          </Typography>
        </Box>
      )}
    </Box>
  );
}
