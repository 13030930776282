/**
 * Provider Actions
 * @author mahesh.kedari@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTIONS from './provider.actionTypes';
/**
 *
 * @returns
 */
export function getProviderForDomain(payload: string): AnyAction {
  return {
    type: ACTIONS.GET_AUTH_PROVIDER,
    payload
  };
}
/**
 *
 * @param payload
 * @returns
 */
export function handleProviderSuccess(payload?: any): AnyAction {
  return {
    type: ACTIONS.AUTH_PROVIDER_SUCCESS,
    payload
  };
}
/**
 *
 * @param error
 * @returns
 */
export function handleProviderError(error: any): AnyAction {
  return {
    type: ACTIONS.AUTH_PROVIDER_FAILURE,
    error
  };
}

export function resetProviderState(): AnyAction {
  return {
    type: ACTIONS.RESET_AUTH_PROVIDER
  };
}
