/**
 * Alerts Actions
 * @author shubham.holkar@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTION_TYPES from './alerts.actionTypes';
import { UpdateNBRule, UpdateSensorRule } from '../types/CreateAlertState';
/**
 *
 */
export function updateAlert(data: UpdateSensorRule | UpdateNBRule): AnyAction {
  return {
    type: ACTION_TYPES.UPDATE_ALERT,
    payload: data
  };
}
export function updateAlertsSuccess(data: any): AnyAction {
  return {
    type: ACTION_TYPES.UPDATE_ALERT_SUCCESS,
    payload: data
  };
}
