import { ALARM_TYPE } from '.';

interface ALARM_TYPE_OPTIONS_INTERFACE {
  ASSET: {
    key: string;
    label: string;
    value: ALARM_TYPE;
  };
  SENSOR: {
    key: string;
    label: string;
    value: ALARM_TYPE;
  };
}

export enum ALARM_STATE_OPTIONS {
  ACKNOWLEDGED = 'ACK',
  NEW = 'NEW',
  IN_PROGRESS = 'IN_PROGRESS',
  FIXED = 'FIXED',
  FALSE = 'FALSE',
  REVIEW = 'REVIEW'
}

export const ALARM_TYPE_OPTIONS: ALARM_TYPE_OPTIONS_INTERFACE = {
  ASSET: { key: 'assetAlarms', label: 'Asset Alarms', value: 'ASSET' },
  SENSOR: {
    key: 'sensorAlarms',
    label: 'Sensor Alarms',
    value: 'SENSOR'
  }
};

export const SYSTEM = 'System';

export const ALARM_URL_PARAMS = {
  ALARM_TYPE: 'alarm_type',
  SEVERITY: 'severity',
  STATE: 'state',
  MODIFIED_BY: 'modifiedBy',
  SITE: 'site',
  PATH: 'path',
  OCCURRED: 'occurred',
  START: 'start',
  END: 'end',
  NAME: 'name',
  ID: 'id',
  ALARM_ID: 'alarm_id'
};
