/**
 * Login State Reducer
 * @author mahesh.kedari@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTIONS from '../actions/login.actionTypes';
import * as ACCEPT_INVITE_ACTION from '../actions/acceptInvite.actionTypes';
import { onLoginProgress, onLoginSuccess, onLoginFailure, setUserEmail } from './login.helper';
import { onSetNewPassword, codeSentSuccess } from './forgotPassword.helper';
import { ACCEPT_INVITE_STATUS, LoginStateType } from '../types/LoginStateType';
import { onLogout } from './logout.helper';
import { onFailureHandler } from './loginResponse.helper';
import {
  onCurrentSessionAvailable,
  onCurrentSessionNotAvailable,
  onCheckCurrentSession
} from './currentSession.helper';
import {
  onResetPasswordSuccess,
  onResetPasswordFailure,
  onAcceptInviteSuccess,
  onAcceptInviteFailure,
  resetAcceptInvite,
  onResetPasswordProgress
} from './acceptInvite.helper';
import { onResendVerificationMail } from './resendVerification.helper';
import {
  onValidatePassword,
  onValidatePasswordFailure,
  onValidatePasswordSuccess
} from './validatePassword.helper';

/**
 * Initial state
 */
export const initialState: LoginStateType = {
  status: 'UNKNOWN',
  session: null,
  user: {},
  acceptInviteStatus: ACCEPT_INVITE_STATUS.INIT
};

/**
 *
 * @param state
 * @param action
 */
export default function loginReducer(state = initialState, action: AnyAction): LoginStateType {
  switch (action.type) {
    case ACTIONS.RESET_TO_LOGIN_PAGE:
      return initialState;
    /* Current Session Validation */
    case ACTIONS.CHECK_CURRENT_SESSION:
      return onCheckCurrentSession(state);
    case ACTIONS.CURRENT_SESSION_AVAILABLE:
      return onCurrentSessionAvailable(state, action.payload);
    case ACTIONS.CURRENT_SESSION_NOT_AVAILABLE:
      return onCurrentSessionNotAvailable(state);

    /* Form based Login */
    case ACTIONS.SET_USER_EMAIL:
      return setUserEmail(state, action.payload);
    case ACTIONS.LOGIN_PROGERSS:
      return onLoginProgress(state);
    case ACTIONS.LOGIN_SUCCESS:
      return onLoginSuccess(state, action);
    case ACTIONS.LOGIN_FAILURE:
      return onLoginFailure(state, action);

    /* Logout process */
    case ACTIONS.LOGOUT_PROGRESS:
      return onLogout(state, action.payload);

    /* Resend Verification Link */
    case ACTIONS.RESEND_VERIFICATION_SUCCESS:
      return onResendVerificationMail(state);
    case ACTIONS.RESEND_VERIFICATION_FAILED:
      return onFailureHandler(state);

    /* Forgot Password */
    case ACTIONS.FORGOT_PASSWORD_CODE_SUCCESS:
      return codeSentSuccess(state, action.payload);
    case ACTIONS.FORGOT_PASSWORD_CODE_FAILED:
      return onFailureHandler(state);

    /* Force New password */
    case ACCEPT_INVITE_ACTION.RESET_PASSWORD_PROGRESS:
      return onResetPasswordProgress(state);
    case ACTIONS.LOGIN_FORCE_NEW_PWD:
      return onSetNewPassword(state, action.data);
    case ACCEPT_INVITE_ACTION.RESET_PASSWORD_SUCCESS:
      return onResetPasswordSuccess(state, action.payload);
    case ACCEPT_INVITE_ACTION.RESET_PASSWORD_FAILURE:
      return onResetPasswordFailure(state);

    /* Validate Password */
    case ACCEPT_INVITE_ACTION.VALIDATE_PASSWORD:
      return onValidatePassword(state);
    case ACCEPT_INVITE_ACTION.VALIDATE_PASSWORD_SUCCESS:
      return onValidatePasswordSuccess(state, action.payload);
    case ACCEPT_INVITE_ACTION.VALIDATE_PASSWORD_FAILURE:
      return onValidatePasswordFailure(state, action.payload);
    /* Accept Invite */
    case ACCEPT_INVITE_ACTION.ACCEPT_INVITE_SUCCESS:
      return onAcceptInviteSuccess(state, action.data);
    case ACCEPT_INVITE_ACTION.ACCEPT_INVITE_FAILURE:
      return onAcceptInviteFailure(state, action.payload);
    case ACCEPT_INVITE_ACTION.RESET_ACCEPT_INVITE:
      return resetAcceptInvite(state);

    default:
      return state;
  }
}
