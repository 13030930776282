/**
 *
 */
import { AnyAction } from 'redux';
import { Anomaly, DeleteBulkAnomalyPayload } from './anomaly-details/anomaly-details.state';
import * as ACTIONS from './anomaly.actionTypes';
import { BulkUpdateAnomaly } from './anomaly.types';
import { FetchAnomalyListPayload } from './anomaly-list/anomaly-list.state';
/**
 *
 * @returns
 */
export function fetchAnomalyList(payload: FetchAnomalyListPayload): AnyAction {
  return {
    type: ACTIONS.FETCH_ANOMALY_CONFIG_LIST,
    payload
  };
}

export function anomalyListSuccess(list: any): AnyAction {
  return {
    type: ACTIONS.ANOMALY_CONFIG_LIST_SUCCESS,
    payload: list
  };
}

export function anomalyListFailure(error: any): AnyAction {
  return { type: ACTIONS.ANOMALY_CONFIG_LIST_FAILURE, payload: error };
}

export function anomalyListReset(): AnyAction {
  return { type: ACTIONS.ANOMALY_CONFIG_LIST_RESET };
}

export function anomalyDetailsReset(): AnyAction {
  return { type: ACTIONS.RESET_ANOMALY_CONFIG };
}

export function fetchAnomalyDetails(slug: string, derivedDpid: any): AnyAction {
  return {
    type: ACTIONS.FETCH_ANOMALY_CONFIG,
    payload: {
      slug,
      derived_dpid: derivedDpid
    }
  };
}

export function fetchAnomalyDetailsSuccess(anomaly: Anomaly): AnyAction {
  return {
    type: ACTIONS.ANOMALY_CONFIG_SUCCESS,
    payload: anomaly
  };
}

export function fetchAnomalyDetailsFailure(error: any): AnyAction {
  return {
    type: ACTIONS.ANOMALY_CONFIG_FAILURE,
    payload: error
  };
}

export function createAnomalyConfig(slug: string, data: Anomaly): AnyAction {
  return {
    type: ACTIONS.CREATE_ANOMALY_CONFIG,
    payload: {
      slug,
      data
    }
  };
}

export function createAnomalyConfigSuccess(response: any): AnyAction {
  return {
    type: ACTIONS.CREATE_ANOMALY_CONFIG_SUCCESS,
    payload: response
  };
}

export function createAnomalyConfigFailure(): AnyAction {
  return {
    type: ACTIONS.CREATE_ANOMALY_CONFIG_FAILURE
  };
}

export function updateAnomalyDetails(slug: string, data: Anomaly): AnyAction {
  return {
    type: ACTIONS.UPDATE_ANOMALY_CONFIG,
    payload: {
      slug,
      data
    }
  };
}

export function updateAnomalySuccess(response: any): AnyAction {
  return {
    type: ACTIONS.UPDATE_ANOMALY_CONFIG_SUCCESS,
    payload: response
  };
}

export function updateAnomalyFailure(): AnyAction {
  return {
    type: ACTIONS.UPDATE_ANOMALY_CONFIG_FAILURE
  };
}

/**
 * Performs a bulk update of an anomaly configuration.
 * @param slug - The slug of the anomaly.
 * @param data - The bulk update anomaly data.
 * @returns The Redux action object.
 */
export function bulkUpdateAnomaly(slug: string, data: BulkUpdateAnomaly): AnyAction {
  return {
    type: ACTIONS.BULK_UPDATE_ANOMALY_CONFIG,
    payload: {
      slug,
      data
    }
  };
}

/**
 * Action dispatched when the bulk update anomaly configuration is successful.
 * @param response - The response from the successful request.
 * @returns The Redux action object.
 */
export function bulkUpdateAnomalySuccess(): AnyAction {
  return {
    type: ACTIONS.BULK_UPDATE_ANOMALY_CONFIG_SUCCESS
  };
}

/**
 * Action dispatched when there is a failure in the bulk update anomaly configuration.
 * @param error - The error object representing the failure.
 * @returns The Redux action object.
 */
export function bulkUpdateAnomalyFailure(): AnyAction {
  return {
    type: ACTIONS.BULK_UPDATE_ANOMALY_CONFIG_FAILURE
  };
}

export function deleteAnomalyConfig(slug: any, derivedDpid: any, isSuperAdmin: boolean): AnyAction {
  return {
    type: ACTIONS.DELETE_ANOMALY_CONFIG,
    payload: {
      slug,
      derived_dpid: derivedDpid,
      isSuperAdmin
    }
  };
}

export function deleteAnomalyConfigSuccess(anomaly: Anomaly): AnyAction {
  return {
    type: ACTIONS.DELETE_ANOMALY_CONFIG_SUCCESS,
    payload: anomaly
  };
}

export function deleteBulkAnomalyConfig(data: DeleteBulkAnomalyPayload): AnyAction {
  return {
    type: ACTIONS.DELETE_BULK_ANOMALY_CONFIG,
    payload: data
  };
}

export function deleteBulkAnomalyConfigSuccess(): AnyAction {
  return {
    type: ACTIONS.DELETE_BULK_ANOMALY_CONFIG_SUCCESS
  };
}
