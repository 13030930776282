/**
 * add digital sensor reducer
 * @author ashwini.gawade@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTION_TYPES from './digitalSensor.actionTypes';
import { addDigitalSensorStatus, addDigitalSensorState } from './digitalSensor.state';
/**
 *
 */
const initialState: addDigitalSensorState = {
  status: addDigitalSensorStatus.INIT
};

function addDigitalSensorProgress(state: addDigitalSensorState): addDigitalSensorState {
  return {
    ...state,
    status: addDigitalSensorStatus.PROGRESS
  };
}
function addDigitalSensorSuccess(state: addDigitalSensorState): addDigitalSensorState {
  return {
    ...state,
    status: addDigitalSensorStatus.SUCCESS
  };
}
/**
 *
 * @param state
 */
function addDigitalSensorFailure(state: addDigitalSensorState): addDigitalSensorState {
  return {
    ...state,
    status: addDigitalSensorStatus.ERROR
  };
}

function addDigitalSensorReset(state: addDigitalSensorState): addDigitalSensorState {
  return {
    ...state,
    status: addDigitalSensorStatus.INIT
  };
}
/**
 *
 * @param state
 * @param action
 */
export default function addDigitalSensorReducer(
  state: addDigitalSensorState = initialState,
  action: AnyAction
): addDigitalSensorState {
  switch (action.type) {
    case ACTION_TYPES.ADD_DIGITAL_SENSOR_PROGRESS:
      return addDigitalSensorProgress(state);
    case ACTION_TYPES.ADD_DIGITAL_SENSOR_SUCCESS:
      return addDigitalSensorSuccess(state);
    case ACTION_TYPES.ADD_DIGITAL_SENSOR_FAILURE:
      return addDigitalSensorFailure(state);
    case ACTION_TYPES.ADD_DIGITAL_SENSOR_RESET_STATE:
      return addDigitalSensorReset(state);

    default:
      return state;
  }
}
