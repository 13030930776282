import { AnyAction } from 'redux';
import * as ACTION_TYPES from './modbus.actionTypes';

export const updateModbusProgress = (data: any): AnyAction => {
  return {
    type: ACTION_TYPES.UPDATE_MODBUS_PROGRESS,
    payload: data
  };
};
/**
 *
 * @param data
 */
export const updateModbusSuccess = (data: any): AnyAction => {
  // returns the action type
  return {
    type: ACTION_TYPES.UPDATE_MODBUS_SUCCESS,
    data
  };
};
/**
 *
 * @param error
 */
export const updateModbusFailure = (error: any): AnyAction => {
  // returns the action type
  return {
    type: ACTION_TYPES.UPDATE_MODBUS_FAILURE,
    error
  };
};
/**
 *
 * @param data
 */

export const updateModbusReset = (): AnyAction => {
  return {
    type: ACTION_TYPES.UPDATE_MODBUS_RESET
  };
};
