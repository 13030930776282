/**
 * Dropzone Styles
 * @author mahesh.kedari@shorelineiot.com
 */
import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    droparea: {
      minHeight: '16rem',
      margin: '1em',
      padding: '1em',
      border: 'dashed 0.31rem',
      borderColor: theme.palette.grey[50],
      outline: 'none',
      display: 'flex',
      alignItems: 'stretch',
      justifyContent: 'center'
    },
    uploadIcon: {
      height: '3em',
      width: '3em'
    },
    browseButton: {
      borderRadius: '3.25rem',
      backgroundColor: theme.palette.primary.main,
      width: '8em',
      height: '2.5rem',
      fontSize: '1rem',
      textAlign: 'center',
      color: theme.palette.text.secondary,
      cursor: 'pointer'
    }
  })
);
