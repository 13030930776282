/**
 * Get topic Saga
 * @author mahesh.kedari@shorelineiot.com
 */
import { AnyAction } from 'redux';
import { takeEvery, call, put } from 'redux-saga/effects';
import { datapointActions, DATAPOINT_ACTIONS } from '../actions';
import {
  GenericObject,
  httpGet,
  removeProgressFor,
  showProgressFor,
  APISERVICES
} from '../../../../../../../framework';
import { SagaIterator } from 'redux-saga';

/**
 * get topics for each sensor
 */
function getData(data: any) {
  return httpGet(
    `orgs/${data.slug}/devices/${data.deviceId}/datapoints/historical_data`,
    {
      start: data.from,
      end: data.to,
      maxPoints: data.maxPoints,
      dpids: data.dpid
    },
    APISERVICES.DEVICE_API
  );
}
/**
 *
 * @param action
 */
function* getDataSaga(action: AnyAction) {
  const criteria = action.payload;
  yield put(showProgressFor(`GET_DATA_SUCCESS`));
  try {
    const resp: GenericObject = yield call(getData, criteria);

    yield put(datapointActions.fetchDataSuccess(resp));
  } catch (error: any) {
    yield put(datapointActions.fetchDataFailure(error));
  } finally {
    yield put(removeProgressFor(`GET_DATA_SUCCESS`));
  }
}
/**
 * Watcher saga for Fetch Topic Request
 */
export function* watchDataSaga(): SagaIterator {
  yield takeEvery(DATAPOINT_ACTIONS.FETCH_DATA_PROGRESS, getDataSaga);
}
