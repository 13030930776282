export enum SAML_METADATA_OPTION_TYPE {
  FILE_UPLOAD = 'fileUpload',
  URL = 'url'
}

export enum CONFUGURATIONS_MODE {
  CREATE = 'create',
  EDIT = 'edit'
}

export interface OptionType {
  label: string;
  value: string;
}

export enum ProviderTypeOptions {
  GOOGLE = 'Google',
  MICROSOFT = 'Microsoft',
  OKTA = 'Okta',
  AUTH0 = 'Auth0',
  SECUREAUTH = 'SecureAuth',
  CUSTOM = 'Custom'
}

export enum SsoActions {
  ENABLE = 'enable',
  DISABLE = 'disable'
}
