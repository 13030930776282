import { AnyAction } from 'redux';
import * as ACTION_TYPE from './analogSensor.actionTypes';
/**
 *
 */
export function getAnalogSensorSchemaProgressAI1(data: any): AnyAction {
  return {
    type: ACTION_TYPE.FETCH_ANALOG_SENSOR_SCHEMA_PROGRESS_AI1,
    payload: data
  };
}

export function getAnalogSensorSchemaSuccessAI1(data: any): AnyAction {
  return {
    type: ACTION_TYPE.FETCH_ANALOG_SENSOR_SCHEMA_SUCCESS_AI1,
    payload: data
  };
}
/**
 *
 */
export function getAnalogSensorSchemaFailureAI1(error: any): AnyAction {
  return {
    type: ACTION_TYPE.FETCH_ANALOG_SENSOR_SCHEMA_FAILURE_AI1,
    payload: error
  };
}

export const resetSchemaStateAI1 = (): AnyAction => {
  return {
    type: ACTION_TYPE.FETCH_ANALOG_SENSOR_RESET_STATE_AI1,
    payload: false
  };
};

export function getAnalogSensorSchemaProgressAI2(data: any): AnyAction {
  return {
    type: ACTION_TYPE.FETCH_ANALOG_SENSOR_SCHEMA_PROGRESS_AI2,
    payload: data
  };
}

export function getAnalogSensorSchemaSuccessAI2(data: any): AnyAction {
  return {
    type: ACTION_TYPE.FETCH_ANALOG_SENSOR_SCHEMA_SUCCESS_AI2,
    payload: data
  };
}
/**
 *
 */
export function getAnalogSensorSchemaFailureAI2(error: any): AnyAction {
  return {
    type: ACTION_TYPE.FETCH_ANALOG_SENSOR_SCHEMA_FAILURE_AI2,
    payload: error
  };
}

export const resetSchemaStateAI2 = (): AnyAction => {
  return {
    type: ACTION_TYPE.FETCH_ANALOG_SENSOR_RESET_STATE_AI2,
    payload: false
  };
};

export const addAnalogSensorProgress = (data: any): AnyAction => {
  return {
    type: ACTION_TYPE.ADD_ANALOG_SENSOR_PROGRESS,
    payload: data
  };
};
export const addAnalogSensorSuccess = (data: any): AnyAction => {
  return {
    type: ACTION_TYPE.ADD_ANALOG_SENSOR_SUCCESS,
    payload: data
  };
};

export const addAnalogSensorFailure = (error: any): AnyAction => {
  return {
    type: ACTION_TYPE.ADD_ANALOG_SENSOR_FAILURE,
    payload: error
  };
};

export const addAnalogSensorReset = (): AnyAction => {
  return {
    type: ACTION_TYPE.ADD_ANALOG_SENSOR_FAILURE,
    payload: false
  };
};

export const setDefaultAI1 = (data: any) => {
  return {
    type: ACTION_TYPE.SET_DEFAULT_AI1,
    payload: data
  };
};

export const setDefaultAI2 = (data: any) => {
  return {
    type: ACTION_TYPE.SET_DEFAULT_AI2,
    payload: data
  };
};

export function actions(): AnyAction {
  throw new Error('Function not implemented.');
}
