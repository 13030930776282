export const FETCH_DIGITAL_SENSOR_SCHEMA_PROGRESS_DIO1 =
  'FEATURES/DEVICE/ADDITIONAL-SENSORS/DIGITAL-SENSOR/FETCH_DIGITAL_SENSOR_SCHEMA_PROGRESS_DIO1';
export const FETCH_DIGITAL_SENSOR_SCHEMA_SUCCESS_DIO1 =
  'FEATURES/DEVICE/ADDITIONAL-SENSORS/DIGITAL-SENSOR/FETCH_DIGITAL_SENSOR_SCHEMA_SUCCESS_DIO1';
export const FETCH_DIGITAL_SENSOR_SCHEMA_FAILURE_DIO1 =
  'FEATURES/DEVICE/ADDITIONAL-SENSORS/DIGITAL-SENSOR/FETCH_DIGITAL_SENSOR_SCHEMA_FAILURE_DIO1';
export const FETCH_DIGITAL_SENSOR_RESET_STATE_DIO1 =
  'FEATURES/DEVICE/ADDITIONAL-SENSORS/DIGITAL-SENSOR/FETCH_DIGITAL_SENSOR_RESET_STATE_DIO1';

export const FETCH_DIGITAL_SENSOR_SCHEMA_PROGRESS_DIO2 =
  'FEATURES/DEVICE/ADDITIONAL-SENSORS/DIGITAL-SENSOR/FETCH_DIGITAL_SENSOR_SCHEMA_PROGRESS_DIO2';
export const FETCH_DIGITAL_SENSOR_SCHEMA_SUCCESS_DIO2 =
  'FEATURES/DEVICE/ADDITIONAL-SENSORS/DIGITAL-SENSOR/FETCH_DIGITAL_SENSOR_SCHEMA_SUCCESS_DIO2';
export const FETCH_DIGITAL_SENSOR_SCHEMA_FAILURE_DIO2 =
  'FEATURES/DEVICE/ADDITIONAL-SENSORS/DIGITAL-SENSOR/FETCH_DIGITAL_SENSOR_SCHEMA_FAILURE_DIO2';
export const FETCH_DIGITAL_SENSOR_RESET_STATE_DIO2 =
  'FEATURES/DEVICE/ADDITIONAL-SENSORS/DIGITAL-SENSOR/FETCH_DIGITAL_SENSOR_RESET_STATE_DIO2';

export const FETCH_DIGITAL_SENSOR_SCHEMA_PROGRESS_DIO3 =
  'FEATURES/DEVICE/ADDITIONAL-SENSORS/DIGITAL-SENSOR/FETCH_DIGITAL_SENSOR_SCHEMA_PROGRESS_DIO3';
export const FETCH_DIGITAL_SENSOR_SCHEMA_SUCCESS_DIO3 =
  'FEATURES/DEVICE/ADDITIONAL-SENSORS/DIGITAL-SENSOR/FETCH_DIGITAL_SENSOR_SCHEMA_SUCCESS_DIO3';
export const FETCH_DIGITAL_SENSOR_SCHEMA_FAILURE_DIO3 =
  'FEATURES/DEVICE/ADDITIONAL-SENSORS/DIGITAL-SENSOR/FETCH_DIGITAL_SENSOR_SCHEMA_FAILURE_DIO3';
export const FETCH_DIGITAL_SENSOR_RESET_STATE_DIO3 =
  'FEATURES/DEVICE/ADDITIONAL-SENSORS/DIGITAL-SENSOR/FETCH_DIGITAL_SENSOR_RESET_STATE_DIO3';

export const FETCH_DIGITAL_SENSOR_SCHEMA_PROGRESS_DIO4 =
  'FEATURES/DEVICE/ADDITIONAL-SENSORS/DIGITAL-SENSOR/FETCH_DIGITAL_SENSOR_SCHEMA_PROGRESS_DIO4';
export const FETCH_DIGITAL_SENSOR_SCHEMA_SUCCESS_DIO4 =
  'FEATURES/DEVICE/ADDITIONAL-SENSORS/DIGITAL-SENSOR/FETCH_DIGITAL_SENSOR_SCHEMA_SUCCESS_DIO4';
export const FETCH_DIGITAL_SENSOR_SCHEMA_FAILURE_DIO4 =
  'FEATURES/DEVICE/ADDITIONAL-SENSORS/DIGITAL-SENSOR/FETCH_DIGITAL_SENSOR_SCHEMA_FAILURE_DIO4';
export const FETCH_DIGITAL_SENSOR_RESET_STATE_DIO4 =
  'FEATURES/DEVICE/ADDITIONAL-SENSORS/DIGITAL-SENSOR/FETCH_DIGITAL_SENSOR_RESET_STATE_DIO4';

export const ADD_DIGITAL_SENSOR_PROGRESS =
  'FEATURES/DEVICE/ADDITIONAL-SENSORS/DIGITAL-SENSOR/ADD_DIGITAL_SENSOR_PROGRESS';
export const ADD_DIGITAL_SENSOR_SUCCESS =
  'FEATURES/DEVICE/ADDITIONAL-SENSORS/DIGITAL-SENSOR/ADD_DIGITAL_SENSOR_SUCCESS';
export const ADD_DIGITAL_SENSOR_FAILURE =
  'FEATURES/DEVICE/ADDITIONAL-SENSORS/DIGITAL-SENSOR/ADD_DIGITAL_SENSOR_FAILURE';
export const ADD_DIGITAL_SENSOR_RESET_STATE =
  'FEATURES/DEVICE/ADDITIONAL-SENSORS/DIGITAL-SENSOR/ADD_DIGITAL_SENSOR_RESET_STATE';

export const SET_DEFAULT_DIO1 =
  'FEATURES/DEVICE/ADDITIONAL-SENSORS/DIGITAL-SENSOR/SET_DEFAULT_DIO1';
export const SET_DEFAULT_DIO2 =
  'FEATURES/DEVICE/ADDITIONAL-SENSORS/DIGITAL-SENSOR/SET_DEFAULT_DIO2';
export const SET_DEFAULT_DIO3 =
  'FEATURES/DEVICE/ADDITIONAL-SENSORS/DIGITAL-SENSOR/SET_DEFAULT_DIO3';
export const SET_DEFAULT_DIO4 =
  'FEATURES/DEVICE/ADDITIONAL-SENSORS/DIGITAL-SENSOR/SET_DEFAULT_DIO4';
