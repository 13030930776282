import { useSelector } from 'react-redux';
import { RootState } from '../../../../configureStore';

const useOrgState = () => useSelector((state: RootState) => state.features.organisations);

const useOrgSelector = () =>
  useSelector((state: RootState) => state.features.organisations.orgSelector);

const useSelectedOrg = () =>
  useSelector((state: RootState) => state.features.organisations.orgSelector.selectedOrg);
const useSelectedOrgSlug = () =>
  useSelector((state: RootState) => state.features.organisations.orgSelector?.selectedOrg?.slug);
const useSelectedOrgName = () =>
  useSelector((state: RootState) => state.features.organisations.orgSelector?.selectedOrg?.name);

const useSelectedOrgStatus = () =>
  useSelector((state: RootState) => state.features.organisations.orgSelector.status);

export {
  useOrgState,
  useOrgSelector,
  useSelectedOrg,
  useSelectedOrgSlug,
  useSelectedOrgName,
  useSelectedOrgStatus
};
