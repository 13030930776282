import { put, takeEvery, all } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import * as actions from './actions/updateNevadaNano.actions';
import * as actionTypes from './actions/updateNevadaNano.actionTypes';
import {
  GenericObject,
  removeProgressFor,
  showProgressFor,
  httpPut,
  showToast,
  APISERVICES
} from '../../../../../framework';
import { SagaIterator } from 'redux-saga';

const UPDATE_NEVADA_NANO_ID = 'UPDATE_NEVADA_NANO';

function* updateNevadaNano(action: AnyAction) {
  const data = action.payload;
  const body = {
    device_id: data.deviceId,
    sensor_module: data.dataObject.sensor_module
  };

  try {
    yield put(showProgressFor(UPDATE_NEVADA_NANO_ID));
    const response: GenericObject = yield httpPut(
      `orgs/${data.slug}/device_sensor_modules/${data.dataObject.uuid}`,
      body,
      APISERVICES.DEVICE_API
    );

    yield put(actions.updateNevadaNanoSuccess(response));
  } catch (error: any) {
    yield put(showToast('Sensor could not be updated!', 'error', true));
    yield put(actions.updateNevadaNanoFailure(error));
  } finally {
    yield put(removeProgressFor(UPDATE_NEVADA_NANO_ID));
  }
}
/**
 *
 * @param action

/**
 *
 */
export function* watchUpdateNevadaNanoSaga(): SagaIterator {
  yield all([yield takeEvery(actionTypes.UPDATE_NEVADA_NANO_PROGRESS, updateNevadaNano)]);
}
