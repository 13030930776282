/**
 * Table Column Condition Row
 * @author mahesh.kedari@shorelineiot.com
 */
import React from 'react';
import { Grid, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import SLTextField from '../../../../form/SLTextField';
import SLSelect from '../../../../form/select/SLSelect';

import conditionList from './criteria.json';
import { rules } from './tableConditionRow.validation';
import TableColumnFormatter from './formatter/TableColumnFormatter';

interface Props {
  prefix: string;
  index: number;
  handleUpdate: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  conditionTextField: {
    width: '10vw',
    [theme.breakpoints.down('lg')]: {
      width: '100%'
    }
  }
}));
/**
 *
 * @param param0
 * @returns
 */
export const ConditionRow = ({ prefix, index, handleUpdate }: Props) => {
  const classes = useStyles();
  return (
    <>
      <Grid item xs={5}>
        <SLSelect
          name={`${prefix}.${index}.operator`}
          label="Condition"
          options={conditionList}
          // defaultValue={item?.operator ? item?.operator : ' '}
          className={classes.conditionTextField}
          rules={rules.requiredError}
          onSelectChange={handleUpdate}
        />
      </Grid>
      <Grid item xs={7}>
        <SLTextField
          name={`${prefix}.${index}.value`}
          label="Value"
          // value={item?.value ? item?.value : ' '}
          style={{ width: '8vw' }}
          rules={rules.value}
          onBlur={handleUpdate}
        />
      </Grid>
      <Grid item xs={12}>
        <TableColumnFormatter handleUpdate={handleUpdate} prefix={`${prefix}.${index}`} />
      </Grid>
    </>
  );
};
