/**
 * Create Dashboard Item Saga
 */

import { put, takeEvery, call } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import * as CREATE_DASHBOARD_ITEM_ACTION_TYPES from './createDashboardItem.actionsTypes';
import * as CREATE_DASHBOARD_ITEM_ACTIONS from './createDashboardItem.action';
import { GenericObject, httpPost, removeProgressFor, APISERVICES } from '../../../../framework';
import { SagaIterator } from 'redux-saga';

function createDashboardItemIntoDatabase(data: any) {
  return httpPost(
    `orgs/${data.slug}/dashboard_item`,
    {
      config: data.config,
      dashboard_uuid: data.dashboard_uuid
    },
    APISERVICES.HOST_API
  );
}

export function* createDashboardItem(action: AnyAction) {
  try {
    const response: GenericObject = yield call(createDashboardItemIntoDatabase, action.payload);
    yield put(CREATE_DASHBOARD_ITEM_ACTIONS.handleCreateDashboardItemSuccess(response));
  } catch (error: any) {
    yield put(CREATE_DASHBOARD_ITEM_ACTIONS.handleCreateDashboardItemFailure(error));
  } finally {
    yield put(removeProgressFor('CREATE_DASHBOARD_ITEM'));
  }
}

export function* watchCreateDashboardItemSaga(): SagaIterator {
  yield takeEvery(
    CREATE_DASHBOARD_ITEM_ACTION_TYPES.CREATE_DASHBOARD_ITEM_PROGRESS,
    createDashboardItem
  );
}
