export interface Info {
  anomaly?: string;
  age?: string;
  device_id: number;
  name: string;
  radius: number;
}

export interface Series {
  lat?: number;
  lon?: number;
}

export interface AlarmDetails {
  severity: string;
  lat?: number;
  lon?: number;
}

export const getMarkerColor = (severity: string) => {
  switch (severity) {
    case 'moderate':
      return 'rgb(253, 197, 0)';
    case 'high':
      return 'rgb(253, 140, 0)';
    case 'critical':
      return 'rgb(206, 0, 0)';
    case 'No alarms':
      return 'green';
    default:
      return 'rgb(204, 204, 204)';
  }
};

export const getSeverityIndex = (severity: string) => {
  switch (severity) {
    case 'ALERT_WARNING':
      return 0;
    case 'ALERT_ERROR':
      return 1;
    case 'ALERT_FATAL':
      return 2;
    default:
      return -1;
  }
};

export const getSeverity = (severity: string) => {
  switch (severity) {
    case 'ALERT_WARNING':
      return 'moderate';
    case 'ALERT_ERROR':
      return 'high';
    case 'ALERT_FATAL':
      return 'critical';
    default:
      return 'No alarms';
  }
};

export const getSeriesName = (severity: string) => {
  switch (severity) {
    case 'ALERT_WARNING':
      return 'moderate_series';
    case 'ALERT_ERROR':
      return 'high_series';
    case 'ALERT_FATAL':
      return 'critical_series';
    default:
      return 'other_series';
  }
};

export const getInfoName = (severity: string) => {
  switch (severity) {
    case 'ALERT_WARNING':
      return 'moderate_info';
    case 'ALERT_ERROR':
      return 'high_info';
    case 'ALERT_FATAL':
      return 'critical_info';
    default:
      return 'other_info';
  }
};

export const separateSeries = (result_set: AlarmDetails[], info_set: Info[]) => {
  const result: {
    critical_series: Series[];
    high_series: Series[];
    other_series: Series[];
    moderate_series: Series[];
    critical_info: Info[];
    high_info: Info[];
    other_info: Info[];
    moderate_info: Info[];
    devices: number;
    alarms: number;
  } = {
    critical_series: [],
    high_series: [],
    other_series: [],
    moderate_series: [],
    critical_info: [],
    high_info: [],
    other_info: [],
    moderate_info: [],
    devices: 0,
    alarms: 0
  };
  for (let i = 0; i < result_set.length; i++) {
    const severity: string = result_set[i].severity;
    const seriesObj = {
      lat: result_set[i].lat,
      lon: result_set[i].lon
    };
    const infoObj = {
      anomaly: info_set[i].anomaly,
      age: info_set[i].age,
      device_id: info_set[i].device_id,
      name: info_set[i].name,
      radius: info_set[i].radius
    };
    result[getSeriesName(severity)].push(seriesObj);
    result[getInfoName(severity)].push(infoObj);
  }
  return result;
};

export const getRadius = (age: number) => {
  const ageThreshold = 108;
  const minimumVisibleSize = 16;
  const maximumVisibleSize = 116;
  if (age <= ageThreshold) {
    return age + minimumVisibleSize;
  } else {
    return maximumVisibleSize;
  }
};
