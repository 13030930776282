/**
 * Leak Detection Date Selector
 * @author mahesh.kedari@shorelineiot.com
 */
import React from 'react';
import { SLButton } from '../../../framework';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { LeakDetectionDateRange, LeakDetectionDateRangeType } from './utils/LeakDetectionDateRange';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    columnGap: '10px',
    marginTop: '-30px',
    marginBottom: '10px'
  }
}));

type Props = {
  selectedDateRange: LeakDetectionDateRangeType;
  onSelectionChange: (dateRange: LeakDetectionDateRangeType) => void;
};
function LeakDetectionDateSelector({ selectedDateRange, onSelectionChange }: Props) {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <SLButton
        variant={selectedDateRange.value === '1m' ? 'contained' : 'outlined'}
        onClick={() => onSelectionChange(LeakDetectionDateRange[1])}>
        1 Month
      </SLButton>
      <SLButton
        variant={selectedDateRange.value === '3m' ? 'contained' : 'outlined'}
        onClick={() => onSelectionChange(LeakDetectionDateRange[2])}>
        3 Months
      </SLButton>
      <SLButton
        variant={selectedDateRange.value === '6m' ? 'contained' : 'outlined'}
        onClick={() => onSelectionChange(LeakDetectionDateRange[3])}>
        6 Months
      </SLButton>
      <SLButton
        variant={selectedDateRange.value === '1y' ? 'contained' : 'outlined'}
        onClick={() => onSelectionChange(LeakDetectionDateRange[4])}>
        1 Year
      </SLButton>
    </Box>
  );
}

export default LeakDetectionDateSelector;
