import { AnyAction } from 'redux';
import * as ACTION_TYPES from './getRuleTemplate.actionTypes';
/**
 *
 */
export function getRuleTemplateProgress(data: any): AnyAction {
  return {
    type: ACTION_TYPES.FETCH_RULE_TEMPLATE_PROGRESS,
    payload: data
  };
}
/**
 *
 * @param data data to be returned on successful fetch API call
 */
export function getRuleTemplateSuccess(data: any): AnyAction {
  return {
    type: ACTION_TYPES.FETCH_RULE_TEMPLATE_SUCCESS,
    payload: data
  };
}
/**
 *
 */
export function getRuleTemplateFailure(error: any): AnyAction {
  return {
    type: ACTION_TYPES.FETCH_RULE_TEMPLATE_FAILURE,
    payload: error
  };
}

export const resetState = (): AnyAction => {
  return {
    type: ACTION_TYPES.FETCH_RULE_TEMPLATE_RESET_STATE,
    payload: false
  };
};
