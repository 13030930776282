/**
 * Notification Indicator
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { ReactNode } from 'react';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import { Menu, Box, ListItem, Divider, Paper } from '@mui/material';
import { NotificationIcon } from '../../../icons';
import { useStyles } from './notificationIndicator.styles';
/* import { SLLinkButton } from '../..'; */

// interface Notification {
//   id: string | number;
//   notification_type: string;
//   feature?: string; // DEVICE, DEVICE_PROFILE, FIRMWARE etc.
//   action: string; // CREATE_SUCCESS , UPLOAD_SUCCESS etc.
//   resource_id?: string;
//   details: string;
//   timestamp: any;
// }

interface Notification {
  label: string | ReactNode;
  value: any;
}

interface Props {
  notificationCount?: number;
  notificationList?: Array<any>;
  onNotificationAction?: () => void;
}

export default function NotificationIndicator({
  notificationCount = 0,
  notificationList
}: /* onNotificationAction, */
Props) {
  const label = `Show ${notificationCount} new notifications`;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const classes = useStyles();
  const handleCloseMenu = () => {
    if (setAnchorEl) {
      setAnchorEl(null);
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const menuId = 'notification-menu';
  return (
    <>
      <IconButton aria-label={label} color="inherit" onClick={handleClick} size="large">
        <Badge badgeContent={notificationCount} color="secondary">
          {/* <NotificationsIcon /> */}
          <NotificationIcon className={classes.notification} />
        </Badge>
      </IconButton>
      <Menu
        id={menuId}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        keepMounted
        open={open}
        onClose={handleCloseMenu}
        className={classes.root}>
        <Paper className={classes.paper}>
          {notificationList?.length !== 0 ? (
            notificationList?.map((notification: Notification) => (
              <ListItem key={notification.value} value={notification.value} divider>
                <Box display="flex" flexDirection="column" justifyContent="flex-start">
                  {notification.label}
                  {/* <Box display="flex">{notification.notification_type}</Box>
              <Box display="flex">{notification.details}</Box>
              <Box display="flex">
                <SLLinkButton onClick={onNotificationAction}>
                  {notification.action}
                </SLLinkButton>
              </Box> */}
                </Box>
                <Divider />
              </ListItem>
            ))
          ) : (
            <Box mx={2}>No notifications available!</Box>
          )}
        </Paper>
      </Menu>
    </>
  );
}
