/**
 * personalizeSettings Saga
 * @author ashwini.gawade@shorelineiot.com
 */
import { put, takeEvery, call } from 'redux-saga/effects';
import CryptoJS from 'crypto-js';
import { AnyAction } from 'redux';
import * as ACTIONS from './actions/updatePersonalizeSettings.actionTypes';
import * as updatePersonalizationInfoActions from './actions/updatePersonalizeSettings.actions';
import { personalizeSettingAction } from './actions';
import {
  GenericObject,
  removeProgressFor,
  showProgressFor,
  showToast,
  APISERVICES
} from '../../../../framework';
import { fileUploadAxiosPut, httpPost } from '../../../../framework/services/fetchCalls';
import { SagaIterator } from 'redux-saga';

const UPDATE_PERSONALIZATION_INFO = 'UPDATE_PERSONALIZATION_INFO';

function updatePersonalizationInfo(slug: string, themeInfo: any) {
  return httpPost(`orgs/${slug}/org_personalization`, themeInfo, APISERVICES.HOST_API);
}

function uploadImage(url: any, data: any) {
  const md5Hash = CryptoJS.MD5(data).toString();
  return fileUploadAxiosPut(url, data, { Content_MD5: md5Hash });
}
export function* uploadTask(action: any) {
  const data: any = action;
  try {
    yield call(uploadImage, data?.url, data?.object);
  } catch (error: any) {
    // yield call(deleteFirmware, data);
    yield put(showToast('Failed to upload image', 'error', true));
    throw error;
  }
}

export function* updatePersonalizationInfoSaga(action: AnyAction) {
  yield put(showProgressFor(UPDATE_PERSONALIZATION_INFO));
  try {
    const response: GenericObject = yield call(
      updatePersonalizationInfo,
      action.payload.slug,
      action.payload.data
    );
    // console.log(`res &&& ${JSON.stringify(response)}`);
    if (response?.images?.logo && response?.images?.logo.length > 0) {
      yield call(uploadTask, {
        url: response?.images?.logo,
        object: action?.payload?.logoFile,
        slug: action?.payload?.slug
      });
    }
    if (response?.images?.favicon && response?.images?.favicon.length > 0) {
      yield call(uploadTask, {
        url: response?.images?.favicon,
        object: action?.payload?.faviconFile,
        slug: action?.payload?.slug
      });
    }
    yield put(updatePersonalizationInfoActions.updatePersonalizationInfoSuccess(response));
    yield put(personalizeSettingAction.getPersonalizationInfoProgress(action.payload.slug));
  } catch (error: any) {
    yield put(showToast('Error in updateting Personalisation Info.', 'error', true));
    yield put(updatePersonalizationInfoActions.updatePersonalizationInfoFailure(error));
  } finally {
    yield put(removeProgressFor(UPDATE_PERSONALIZATION_INFO));
  }
}
/**
 *
 */
export default function* watchUpdatePersonalizeSettingsSaga(): SagaIterator {
  yield takeEvery(ACTIONS.UPDATE_THEMES, updatePersonalizationInfoSaga);
}
