import { DEVICE_PROVISION_STATUS } from '../../../../framework/constants/provision';
import TranslateText from '../../../../i18n/TranslateText';
import { SubOrgListState } from '../../../organization/store';
import { Device } from '../../store';

export const getSubOrgInfo = (subOrgUUID: string, subOrgListStateInfo: SubOrgListState) => {
  const subOrgInfo = subOrgListStateInfo?.orgList.find((subOrg) => subOrg.uuid === subOrgUUID);
  return subOrgInfo?.name || '';
};

export const checkAndGetSubOrgName = (
  subOrgUUID: string,
  subOrgListStateInfo: SubOrgListState,
  orgNameInfo: string | undefined
) => {
  const subOrgName = getSubOrgInfo(subOrgUUID, subOrgListStateInfo);
  if (subOrgName && subOrgName === '_phantom') {
    return orgNameInfo;
  }
  return subOrgName || orgNameInfo;
};

export const isProvisionedIcastSenseDevice = (device?: Device) => {
  return (
    device?.provisioning_status === DEVICE_PROVISION_STATUS.PROVISIONED &&
    device?.device_type === 'iCastSense'
  );
};

/**
 * Filters and retrieves provisioned iCastSense devices based on the given device IDs.
 * @param devicesMap A Map containing devices, where the keys are device IDs and values are Device objects.
 * @param deviceIds An array of device IDs to filter.
 * @returns An array of device IDs for provisioned iCastSense devices.
 */
export function getProvisionedIcastSenseDeviceIds(
  devicesMap?: Map<number, Device>,
  deviceIds?: number[]
): number[] {
  if (!deviceIds?.length) {
    return [];
  }

  const selectedProvisionedDevices = deviceIds
    ?.filter((id) => isProvisionedIcastSenseDevice(devicesMap?.get(id)))
    ?.map((id) => id);

  return selectedProvisionedDevices;
}

export function getRelativeTime(lastConnectedTS: number) {
  const currentTS = Math.floor(new Date().getTime());
  const msPerMinute = 60 * 1000;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;
  const msPerMonth = msPerDay * 30;
  const msPerYear = msPerDay * 365;

  const elapsed = currentTS - lastConnectedTS * 1000;

  if (!lastConnectedTS) {
    return '--';
  }

  if (elapsed < msPerMinute) {
    return `${Math.round(elapsed / 1000)} ${TranslateText('seconds_ago', 'seconds ago')}` as const;
  }

  if (elapsed < msPerHour) {
    return `${Math.round(elapsed / msPerMinute)} ${TranslateText(
      'minutes_ago',
      'minutes ago'
    )}` as const;
  }

  if (elapsed < msPerDay) {
    return `${Math.round(elapsed / msPerHour)} ${TranslateText('hours_ago', 'hours ago')}` as const;
  }

  if (elapsed < msPerMonth) {
    return `${Math.round(elapsed / msPerDay)} ${TranslateText('days_ago', 'days ago')}` as const;
  }

  if (elapsed < msPerYear) {
    return `${Math.round(elapsed / msPerMonth)} ${TranslateText(
      'mounths_ago',
      'months ago'
    )}` as const;
  }

  return `${Math.round(elapsed / msPerYear)} ${TranslateText('years_ago', 'years ago')}` as const;
}
