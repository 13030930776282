import { AnyAction } from 'redux';

import * as ACTION_TYPE from './actions/datapoints.actionTypes';
import { fetchHistoricalDataState } from './types/fetchHistoricalData.state';
import { FETCH_HISTORICAL_DATA_STATUS } from './types/fetchHistoricalData.status';

const initialState: fetchHistoricalDataState = {
  status: FETCH_HISTORICAL_DATA_STATUS.INIT,
  HistoricalData: []
};

function fetchHistoricalDataListProgress(
  state: fetchHistoricalDataState
): fetchHistoricalDataState {
  return {
    ...state,
    status: FETCH_HISTORICAL_DATA_STATUS.INIT
  };
}

function fetchHistoricalDataListSuccess(
  state: fetchHistoricalDataState,
  HistoricalData: any
): fetchHistoricalDataState {
  return {
    ...state,
    status: FETCH_HISTORICAL_DATA_STATUS.READY,
    HistoricalData
  };
}

function fetchHistoricalDataListFailure(state: fetchHistoricalDataState): fetchHistoricalDataState {
  return {
    ...state,
    status: FETCH_HISTORICAL_DATA_STATUS.ERROR
  };
}

function resetHistoricalDataList(): fetchHistoricalDataState {
  return {
    ...initialState
  };
}
export default function fetchHistoricalDataListReducer(
  state: fetchHistoricalDataState = initialState,
  action: AnyAction
): fetchHistoricalDataState {
  switch (action.type) {
    case ACTION_TYPE.FETCH_DATA_PROGRESS:
      return fetchHistoricalDataListProgress(state);
    case ACTION_TYPE.FETCH_DATA_SUCCESS:
      return fetchHistoricalDataListSuccess(state, action.payload);
    case ACTION_TYPE.FETCH_DATA_FAILURE:
      return fetchHistoricalDataListFailure(state);
    case ACTION_TYPE.FETCH_DATA_RESET:
      return resetHistoricalDataList();
    default:
      return state;
  }
}
