import { CHECKED_STRATEGY_TYPE, TreeDataType } from '../../types/MultiSelectDropdownTypes';

/**
 * Concatenates two arrays while removing duplicates.
 * @param array1 The first array.
 * @param array2 The second array.
 * @returns A new array containing elements from both arrays without duplicates.
 */
export const concatArray = (array1: string[], array2: string[]): string[] => {
  // Create a Set to automatically remove duplicates
  const combinedArrays: Set<string> = new Set([...array1, ...array2]);

  // Convert Set back to Array
  return Array.from(combinedArrays);
};

/**
 * Gets titles for given values from a tree data structure.
 * @param data The tree data to search.
 * @param values The values to search for.
 * @returns An array of titles corresponding to the values found.
 */
export const getPropsForValues = (
  data: TreeDataType[],
  values: string[],
  props = 'title'
): string[] => {
  const foundTitles: string[] = [];

  // Function to search for titles recursively
  const searchTitles = (options: TreeDataType[]) => {
    options.forEach((option) => {
      if (values.includes(option.value) && !option.disabled) {
        if (props === 'value') {
          foundTitles.push(option.value);
        } else {
          foundTitles.push(option.title);
        }
      }
      if (option.children) {
        searchTitles(option.children);
      }
    });
  };

  // Start searching from the root of the tree
  searchTitles(data);

  return foundTitles;
};

/**
 * Function return leaf nodes values
 * @param node
 * @returns Leaf node values
 */
function getLeafNodeValues(node: any) {
  let result: string[] = [];

  // Base case: if the node has no children, add its value
  if ((!node?.children || node?.children?.length === 0) && !node.disabled) {
    result.push(node.value);
  } else {
    // Recursive case: traverse through each child
    for (const child of node.children) {
      result = result.concat(getLeafNodeValues(child));
    }
  }
  return result;
}

/**
 * Function return values of all nodes
 * @param node
 * @returns Nodes values
 */
function getAllNodesValues(node: any) {
  let result: string[] = [];

  if (!node.disabled) {
    // Push the current node's value to the result
    result.push(node.value);
  }

  // If the node has children, recursively collect their values
  if (node.children && node.children.length > 0) {
    for (const child of node.children) {
      result = result.concat(getAllNodesValues(child));
    }
  }

  return result;
}

/**
 * Searches for options by title in a tree data structure.
 * @param data The tree data to search.
 * @param searchValue The value to search for in the titles.
 * @returns An array of values corresponding to options with titles matching the search value.
 */
export const getValuesForTitle = (
  data: TreeDataType[],
  searchValue: string,
  showCheckedStrategy?:
    | CHECKED_STRATEGY_TYPE.SHOW_ALL
    | CHECKED_STRATEGY_TYPE.SHOW_CHILD
    | CHECKED_STRATEGY_TYPE.SHOW_PARENT
): string[] => {
  const foundValues: string[] = [];

  // Recursive function to search for values by title
  const search = (options: TreeDataType[]) => {
    options.forEach((option) => {
      if (option.title.toLowerCase().includes(searchValue.toLowerCase())) {
        // If the option's title matches the search value and it has children,
        if (option.children) {
          if (
            showCheckedStrategy === CHECKED_STRATEGY_TYPE.SHOW_ALL ||
            showCheckedStrategy === CHECKED_STRATEGY_TYPE.SHOW_PARENT
          ) {
            foundValues.push(option.value);
          }
          option.children.forEach((child) => {
            if (showCheckedStrategy === CHECKED_STRATEGY_TYPE.SHOW_ALL) {
              const nodeValue = getAllNodesValues(child);
              foundValues.push(...nodeValue);
            }
            if (showCheckedStrategy === CHECKED_STRATEGY_TYPE.SHOW_CHILD) {
              const childNodes = getLeafNodeValues(child);
              foundValues.push(...childNodes);
            }
          });
        } else {
          // If option doesn't have children, push its value
          foundValues.push(option.value);
        }
      }
      if (option.children) {
        search(option.children);
      }
    });
  };

  // Start searching from the root of the tree
  search(data);

  return foundValues;
};

/**
 * Returns child of parent
 * @param data Tree data
 * @param parentValue Search value
 * @returns Child of parent
 */
export const getChildOfParent = (data: TreeDataType[], parentValue: string[]): string[] => {
  const childValues: string[] = [];
  const getValues = (options: TreeDataType[]) => {
    options.forEach((option) => {
      //Check if option's value is included in parentValue
      if (parentValue.includes(option.value)) {
        if (option.children?.length) {
          return getValues(option.children);
        } else {
          if (!option.disabled) {
            childValues.push(option.value);
          }
        } // Else if option have children, then do recursive call
      } else if (option.children) {
        getValues(option.children);
      }
    });
  };

  getValues(data);
  return childValues;
};
