/**
 * Create organization actions
 * @author ashwini.gawade@shorelineiot.com
 */

import { AnyAction } from 'redux';
import * as ACTIONS from './orgList.actionTypes';
import { Organisation } from '../../types/Organisation';
/**
 *
 * @param flag true/false value upda
 * @param type
 */
export const fetchOrgList = (): AnyAction => {
  // returns the action type
  return {
    type: ACTIONS.FETCH_ORG_LIST_PROGRESS
  };
};
/**
 *
 * @param orgList
 */
export const fetchOrgListSuccess = (orgList: Array<Organisation>): AnyAction => {
  return {
    type: ACTIONS.FETCH_ORG_LIST_SUCCESS,
    payload: orgList
  };
};
/**
 *
 * @param error
 */
export const fetchOrgListFailure = (error: any): AnyAction => {
  return {
    type: ACTIONS.FETCH_ORG_LIST_FAILURE,
    payload: error
  };
};
