/**
 * Device Reports Action Types
 * @author prerna.more@shorelineiot.com
 */
export const FETCH_DEVICE_REPORTS = 'FEATURES/REPORTS/DEVICE_REPORTS/FETCH_DEVICE_REPORTS';
export const FETCH_DEVICE_REPORTS_SUCCESS =
  'FEATURES/REPORTS/DEVICE_REPORTS/FETCH_DEVICE_REPORTS_SUCCESS';
export const FETCH_DEVICE_REPORTS_FAILURE =
  'FEATURES/REPORTS/DEVICE_REPORTS/FETCH_DEVICE_REPORTS_FAILURE';
export const RESET_DEVICE_REPORTS = 'FEATURES/REPORTS/DEVICE_REPORTS/RESET';
