/**
 * Dashboard Items Saga
 */

import { put, takeEvery, call } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import * as DASHBOARD_ITEMS_ACTIONS from './dashboardItems.actionsTypes';
import * as actions from './dashboardItems.action';
import { GenericObject, httpGet, APISERVICES, removeProgressFor } from '../../../../framework';
import { SagaIterator } from 'redux-saga';

function fetchDashboardItemsFromDatabase(data: any) {
  return httpGet(
    `orgs/${data.slug}/dashboard/${data.dashboard_uuid}/dashboard_items`,
    null,
    APISERVICES.HOST_API
  );
}

export function* getDashboardItemsData(action: AnyAction) {
  try {
    const response: GenericObject = yield call(fetchDashboardItemsFromDatabase, action.payload);
    yield put(actions.handleGetDashboardItemsSuccess(response));
  } catch (error: any) {
    yield put(actions.handleGetDashboardItemsFailure(error));
  } finally {
    yield put(removeProgressFor('GET_DASHBOARD_ITEMS'));
  }
}

export function* watchdashboardItemsSaga(): SagaIterator {
  yield takeEvery(DASHBOARD_ITEMS_ACTIONS.DASHBOARD_ITEMS_PROGRESS, getDashboardItemsData);
}
