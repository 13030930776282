/**
 * Data Specific Cartesian Charts Settings
 * @author mahesh.kedari@shorelineiot.com
 *
 */
import React from 'react';
import { DASHBOARD_ITEM_TYPE } from '../../../dashboard-widgets/types/DashboardItemType';
import AnalyticsCartesianChartEditor from './analytics/AnalyticsCartesianChartEditor';
import RawDataCartesianChartEditor from './raw-data/RawDataCartesianChartEditor';

interface Props {
  type: DASHBOARD_ITEM_TYPE;
  query: any;
  chartSettings: any;
  handleUpdate: () => void;
}

/**
 *
 * @param param0
 * @returns
 */
const DataSpecificCartesianChartSettings = ({
  type,
  query,
  chartSettings,
  handleUpdate
}: Props) => {
  return (
    <>
      {type === DASHBOARD_ITEM_TYPE.RAW_DATA && (
        <RawDataCartesianChartEditor query={query} handleUpdate={handleUpdate} />
      )}
      {type === DASHBOARD_ITEM_TYPE.ANALYTICS && (
        <AnalyticsCartesianChartEditor
          query={query}
          chartSettings={chartSettings}
          handleUpdate={handleUpdate}
        />
      )}
    </>
  );
};
export default DataSpecificCartesianChartSettings;
