/**
 * Anomaly Actions Type
 * @author mahesh.kedari@shorelineiot.com
 */
export const FETCH_ANOMALY_CONFIG_LIST = 'FEATURES/WORKFLOW/ANOMALY_CONFIG/LIST/FETCH';
export const ANOMALY_CONFIG_LIST_SUCCESS = 'FEATURES/WORKFLOW/ANOMALY_CONFIG/LIST/SUCCESS';
export const ANOMALY_CONFIG_LIST_FAILURE = 'FEATURES/WORKFLOW/ANOMALY_CONFIG/LIST/FAILURE';
export const ANOMALY_CONFIG_LIST_RESET = 'FEATURES/WORKFLOW/ANOMALY_CONFIG/LIST/RESET';

export const FETCH_ANOMALY_CONFIG = 'FEATURES/WORKFLOW/ANOMALY_CONFIG/FETCH';
export const ANOMALY_CONFIG_SUCCESS = 'FEATURES/WORKFLOW/ANOMALY_CONFIG/SUCCESS';
export const ANOMALY_CONFIG_FAILURE = 'FEATURES/WORKFLOW/ANOMALY_CONFIG/FAILURE';

export const UPDATE_ANOMALY_CONFIG = 'FEATURES/WORKFLOW/ANOMALY_CONFIG/UPDATE';
export const UPDATE_ANOMALY_CONFIG_SUCCESS = 'FEATURES/WORKFLOW/ANOMALY_CONFIG/UPDATE/SUCCESS';
export const UPDATE_ANOMALY_CONFIG_FAILURE = 'FEATURES/WORKFLOW/ANOMALY_CONFIG/UPDATE/FAILURE';

export const BULK_UPDATE_ANOMALY_CONFIG = 'FEATURES/WORKFLOW/ANOMALY_CONFIG/BULK_UPDATE';
export const BULK_UPDATE_ANOMALY_CONFIG_SUCCESS =
  'FEATURES/WORKFLOW/ANOMALY_CONFIG/BULK_UPDATE/SUCCESS';
export const BULK_UPDATE_ANOMALY_CONFIG_FAILURE =
  'FEATURES/WORKFLOW/ANOMALY_CONFIG/BULK_UPDATE/FAILURE';

export const CREATE_ANOMALY_CONFIG = 'FEATURES/WORKFLOW/ANOMALY_CONFIG/CREATE';
export const CREATE_ANOMALY_CONFIG_SUCCESS = 'FEATURES/WORKFLOW/ANOMALY_CONFIG/CREATE/SUCCESS';
export const CREATE_ANOMALY_CONFIG_FAILURE = 'FEATURES/WORKFLOW/ANOMALY_CONFIG/CREATE/FAILURE';

export const DELETE_ANOMALY_CONFIG = 'FEATURES/WORKFLOW/ANOMALY_CONFIG/DELETE';
export const DELETE_ANOMALY_CONFIG_SUCCESS = 'FEATURES/WORKFLOW/ANOMALY_CONFIG/DELETE/SUCCESS';
export const DELETE_BULK_ANOMALY_CONFIG = 'FEATURES/WORKFLOW/ANOMALY_CONFIG/BULK_DELETE';
export const DELETE_BULK_ANOMALY_CONFIG_SUCCESS =
  'FEATURES/WORKFLOW/ANOMALY_CONFIG/BULK_DELETE/SUCCESS';

export const RESET_ANOMALY_CONFIG = 'FEATURES/WORKFLOW/ANOMALY_CONFIG/RESET';
