import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface OrgsInitialState {
  selectedOrg: string;
}

const initialState: OrgsInitialState = {
  selectedOrg: ''
};

export const orgsSlice = createSlice({
  name: 'orgs',
  initialState,
  reducers: {
    switchOrgSuccess: (state, action: PayloadAction<string>) => {
      state.selectedOrg = action.payload;
    }
  }
});

export const { switchOrgSuccess } = orgsSlice.actions;

export default orgsSlice.reducer;
