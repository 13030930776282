/**
 * personalization Reducer
 * @author ashwini.gawade@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTION_TYPE from './actions/updatePersonalizeSettings.actionTypes';
import {
  UpdatePersonalizeSettingsState,
  UPDATE_PERSONALIZE_SETTINGS_STATUS
} from './types/UpdatePersonalizeSettingsState';

/**
 *
 */
const initialState: UpdatePersonalizeSettingsState = {
  status: UPDATE_PERSONALIZE_SETTINGS_STATUS.INIT,
  personalization_info: {}
};

/**
 *
 * @param state
 */
function updatePersonalizationInfo(
  state: UpdatePersonalizeSettingsState
): UpdatePersonalizeSettingsState {
  return {
    ...state,
    status: UPDATE_PERSONALIZE_SETTINGS_STATUS.FETCHING
  };
}
/**
 *
 * @param state
 * @param action
 */
function updatePersonalizationInfoSuccess(
  state: UpdatePersonalizeSettingsState,
  action: AnyAction
): UpdatePersonalizeSettingsState {
  return {
    ...state,
    status: UPDATE_PERSONALIZE_SETTINGS_STATUS.READY,
    personalization_info: action.data
  };
}

/**
 *
 * @param state
 */
function updatePersonalizationInfoFailure(
  state: UpdatePersonalizeSettingsState
): UpdatePersonalizeSettingsState {
  return {
    ...state,
    status: UPDATE_PERSONALIZE_SETTINGS_STATUS.ERROR
  };
}

/**
 *
 */
function resetPersonalisationInfo() {
  return {
    ...initialState
  };
}

/**
 *
 * @param state
 * @param action
 */
export default function updatePersonalizeSettingsReducer(
  state: UpdatePersonalizeSettingsState = initialState,
  action: AnyAction
): UpdatePersonalizeSettingsState {
  switch (action.type) {
    case ACTION_TYPE.UPDATE_THEMES:
      return updatePersonalizationInfo(state);
    case ACTION_TYPE.UPDATE_THEMES_SUCCESS:
      return updatePersonalizationInfoSuccess(state, action);
    case ACTION_TYPE.UPDATE_THEMES_FAILURE:
      return updatePersonalizationInfoFailure(state);
    case ACTION_TYPE.RESET_THEME:
      return resetPersonalisationInfo();
    default:
      return state;
  }
}
