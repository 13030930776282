export enum ALARMS_DETAILS_STATUS {
  INIT,
  FETCHING,
  READY,
  ERROR
}

export interface AlarmsDetailsState {
  status: ALARMS_DETAILS_STATUS;
  AlarmsDetailsData: any;
}
