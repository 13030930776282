/**
 * Anomaly Details Reducer
 * @author mahesh.kedari@shorelineiot.com
 */
import { AnyAction } from 'redux';
import {
  CREATE_ANOMALY_CONFIG,
  CREATE_ANOMALY_CONFIG_SUCCESS,
  CREATE_ANOMALY_CONFIG_FAILURE,
  FETCH_ANOMALY_CONFIG,
  ANOMALY_CONFIG_SUCCESS,
  ANOMALY_CONFIG_FAILURE,
  UPDATE_ANOMALY_CONFIG,
  UPDATE_ANOMALY_CONFIG_SUCCESS,
  UPDATE_ANOMALY_CONFIG_FAILURE,
  BULK_UPDATE_ANOMALY_CONFIG,
  BULK_UPDATE_ANOMALY_CONFIG_FAILURE,
  BULK_UPDATE_ANOMALY_CONFIG_SUCCESS,
  RESET_ANOMALY_CONFIG,
  DELETE_ANOMALY_CONFIG,
  DELETE_ANOMALY_CONFIG_SUCCESS,
  DELETE_BULK_ANOMALY_CONFIG,
  DELETE_BULK_ANOMALY_CONFIG_SUCCESS
} from '../anomaly.actionTypes';
import { AnomalyDetailsState, ANOMALY_DETAILS_STATE } from './anomaly-details.state';

const initialState: AnomalyDetailsState = {
  status: ANOMALY_DETAILS_STATE.INIT,
  anomaly: undefined
};

// FETCH SPECIFIC ANOMALY DETAILS

function fetchAnomalyConfig(state: AnomalyDetailsState): AnomalyDetailsState {
  return {
    ...state,
    status: ANOMALY_DETAILS_STATE.FETCHING
  };
}

// CREATE NEW ANOMALY CONFIG
function submitAnomalyConfig(state: AnomalyDetailsState): AnomalyDetailsState {
  return {
    ...state,
    status: ANOMALY_DETAILS_STATE.IN_PROGRESS
  };
}

// DELETE ANOMALY CONFIG
function deleteAnomalyConfig(state: AnomalyDetailsState): AnomalyDetailsState {
  return {
    ...state,
    status: ANOMALY_DETAILS_STATE.DELETE_IN_PROGRESS
  };
}

function anomalyConfigSuccess(state: AnomalyDetailsState, payload: any): AnomalyDetailsState {
  return {
    ...state,
    status: ANOMALY_DETAILS_STATE.READY,
    anomaly: payload
  };
}

function createAnomalyConfigSuccess(state: AnomalyDetailsState, payload: any): AnomalyDetailsState {
  return {
    ...state,
    status: ANOMALY_DETAILS_STATE.CREATED,
    anomaly: payload
  };
}

function updateAnomalyConfigSuccess(state: AnomalyDetailsState, payload: any): AnomalyDetailsState {
  return {
    ...state,
    status: ANOMALY_DETAILS_STATE.UPDATED,
    anomaly: payload
  };
}

function handleBulkUpdateAnomaly(state: AnomalyDetailsState): AnomalyDetailsState {
  return {
    ...state,
    status: ANOMALY_DETAILS_STATE.IN_PROGRESS
  };
}

function handleBulkUpdateAnomalySuccess(state: AnomalyDetailsState): AnomalyDetailsState {
  return {
    ...state,
    status: ANOMALY_DETAILS_STATE.UPDATED
  };
}

function handleBulkUpdateAnomalyError(state: AnomalyDetailsState): AnomalyDetailsState {
  return {
    ...state,
    status: ANOMALY_DETAILS_STATE.ERROR
  };
}

function deleteAnomalyConfigSuccess(state: AnomalyDetailsState, payload: any): AnomalyDetailsState {
  return {
    ...state,
    status: ANOMALY_DETAILS_STATE.DELETED,
    anomaly: payload
  };
}

function anomalyConfigFailure(state: AnomalyDetailsState): AnomalyDetailsState {
  return {
    ...state,
    status: ANOMALY_DETAILS_STATE.ERROR
  };
}

function resetAnomalyConfig(): AnomalyDetailsState {
  return initialState;
}

function deleteBulkAnomalyConfigSuccess(state: AnomalyDetailsState): AnomalyDetailsState {
  return {
    ...state,
    status: ANOMALY_DETAILS_STATE.DELETED
  };
}

export default function anomalyDetailsReducer(
  state: AnomalyDetailsState = initialState,
  action: AnyAction
): AnomalyDetailsState {
  switch (action.type) {
    case FETCH_ANOMALY_CONFIG:
      return fetchAnomalyConfig(state);
    case CREATE_ANOMALY_CONFIG:
    case UPDATE_ANOMALY_CONFIG:
      return submitAnomalyConfig(state);
    case DELETE_ANOMALY_CONFIG:
      return deleteAnomalyConfig(state);
    case ANOMALY_CONFIG_SUCCESS:
      return anomalyConfigSuccess(state, action.payload);
    case CREATE_ANOMALY_CONFIG_SUCCESS:
      return createAnomalyConfigSuccess(state, action.payload);
    case UPDATE_ANOMALY_CONFIG_SUCCESS:
      return updateAnomalyConfigSuccess(state, action.payload);
    case BULK_UPDATE_ANOMALY_CONFIG:
      return handleBulkUpdateAnomaly(state);
    case BULK_UPDATE_ANOMALY_CONFIG_SUCCESS:
      return handleBulkUpdateAnomalySuccess(state);
    case BULK_UPDATE_ANOMALY_CONFIG_FAILURE:
      return handleBulkUpdateAnomalyError(state);
    case DELETE_ANOMALY_CONFIG_SUCCESS:
      return deleteAnomalyConfigSuccess(state, action.payload);
    case DELETE_BULK_ANOMALY_CONFIG:
      return deleteAnomalyConfig(state);
    case DELETE_BULK_ANOMALY_CONFIG_SUCCESS:
      return deleteBulkAnomalyConfigSuccess(state);
    case ANOMALY_CONFIG_FAILURE:
    case CREATE_ANOMALY_CONFIG_FAILURE:
    case UPDATE_ANOMALY_CONFIG_FAILURE:
      return anomalyConfigFailure(state);
    case RESET_ANOMALY_CONFIG:
      return resetAnomalyConfig();

    default:
      return state;
  }
}
