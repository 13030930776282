/**
 * Donut Chart View
 * @author Prerna.More@shorelineiot.com
 */
import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highcharts3d from 'highcharts/highcharts-3d';
import merge from 'lodash/merge';
import { useNavigate } from 'react-router-dom';
import { generatePath } from 'react-router';
import { Box, Typography } from '@mui/material';
import { ChartViewProps } from '../common/ChartViewProps';
import { donutChartConfig as defaultDonutChartSettings } from './donutChartSettings';
import { useSlugContext } from '../../..';

highcharts3d(Highcharts);
/**
 *
 * @param param0
 * @returns
 */
const SLDonutChartView: React.FC<ChartViewProps> = React.forwardRef(
  ({ chartSettings, resultSet }: ChartViewProps, ref: any) => {
    const navigate = useNavigate();

    const newDataSpecificSettings: any = {};
    const unwantedKeysObject = {
      title: '',
      innerSize: '50',
      legands: true,
      enable3d: false,
      dataLabelType: 'PERCENTAGE',
      chartType: 'pie',
      data_label_value_unit: 'ABSOLUTE',
      donutTitle: 'Assets',
      size: '50',
      dataSettings: {}
    };
    if (!chartSettings?.dataSetting) {
      // Get only data-specific settings from the existingData object.
      Object.entries(chartSettings)?.forEach(([key, value]: [any, any]) => {
        const unwantedItem = Object.entries(unwantedKeysObject)?.find(
          ([formDefaultKey]) => formDefaultKey === key
        );
        if (!unwantedItem) {
          newDataSpecificSettings[key] = value;
        }
      });
    }

    const [donutChartSettings, setDonutChartSettings] = useState(
      merge(defaultDonutChartSettings(), chartSettings)
    );

    const { slug } = useSlugContext();
    // TODO: Replace below URL redirection with Generic URL direction
    const redirectToAlarmsPageSeverity = (alarmType: string) => {
      switch (alarmType) {
        case 'Critical':
          return generatePath(`/${slug}/app/alarms?severity=ALERT_FATAL`);
        case 'High':
          return generatePath(`/${slug}/app/alarms?severity=ALERT_ERROR`);
        case 'Moderate':
          return generatePath(`/${slug}/app/alarms?severity=ALERT_WARNING`);
        case 'Low':
          return generatePath(`/${slug}/app/alarms?severity=ALERT_LOW`);
        default:
          return generatePath(`/${slug}/app/alarms?severity=${alarmType}`);
      }
    };

    const redirectToSelectedRedirection = (redirection: string, selectedValue: any) => {
      switch (redirection) {
        case 'alarms':
          return redirectToAlarmsPageSeverity(selectedValue);
        case 'asset':
          return generatePath(`/${slug}/app/devices/`);
        case 'dashboard':
          return generatePath(`/${slug}/app/custom-dashboard/${selectedValue}`);
        default:
          return '';
      }
    };

    useEffect(() => {
      const dataArray: Array<any> = resultSet?.series()[0]?.series;
      const total = dataArray?.reduce(
        (accumulator, currentValue) => accumulator + currentValue.value,
        0
      );
      const yAxisArray = dataArray?.map((item: any) => {
        const percentageValue = (item.value / total) * 100;
        const categorySettings = Object.entries(newDataSpecificSettings)?.length
          ? newDataSpecificSettings?.[item?.category]
          : chartSettings?.dataSettings?.[item?.category];
        return {
          name: categorySettings?.label || item.category,
          y: item.value,
          dataLabels: {
            enabled: true,
            distance: percentageValue >= 10 ? 20 : 5,
            style: {
              width: '80px',
              textOverflow: 'ellipsis'
            }
          },
          color: categorySettings?.color?.hex || categorySettings?.color,
          url: redirectToSelectedRedirection(
            categorySettings?.redirection,
            categorySettings?.dashboard_id || item.category
          )
        };
      });
      const xAxisArray = dataArray?.map((item: any) => {
        return item.x;
      });
      setDonutChartSettings({
        ...donutChartSettings,
        chart: {
          options3d: {
            enabled: chartSettings.enable3d,
            alpha: 45
          }
        },
        legend: {
          y: -10,
          itemStyle: {
            fontWeight: 'bold'
          }
        },
        xAxis: {
          categories: xAxisArray
        },
        title: {
          text: chartSettings?.title,
          verticalAlign: 'middle',
          align: 'center',
          y: -25,
          style: {
            fontSize: '15px',
            fontWeight: 'bold'
          }
        },
        plotOptions: {
          pie: {
            depth: 35,
            showInLegend: chartSettings.legands,
            dataLabels: {
              ...donutChartSettings.plotOptions.pie.dataLabels,
              format: `{point.name} : {point.${
                chartSettings.dataLabelType === 'ABSOLUTE' ? 'y}' : 'percentage:.1f} %'
              }`
            }
          },
          series: {
            cursor: 'pointer',
            point: {
              events: {
                click: (e: any) => {
                  if (e.point.url !== '' && e.point.url !== undefined) {
                    navigate(e.point.url);
                  }
                }
              }
            }
          }
        },
        series: [
          {
            data: yAxisArray,
            type: 'pie',
            innerSize: chartSettings.innerSize ? `${chartSettings.innerSize}%` : '50%'
          }
        ]
      });
    }, [resultSet, chartSettings]);

    if (donutChartSettings?.series[0]?.data) {
      /**
       * Donut chart requires 'series.data' as a type of Number to work correctly,
       * but sometimes due to inappropriate data selection app is getting crashed
       * because it's getting data as a string. To avoid the crash, added this check.
       * For more info: https://assets.highcharts.com/errors/14/
       */
      if (typeof donutChartSettings?.series[0]?.data[0]?.y === 'string') {
        return (
          <Box mt={2} ml={2}>
            <Typography>Inappropriate data, please change measures/dimensions</Typography>
          </Box>
        );
      }
    }

    return <HighchartsReact highcharts={Highcharts} options={donutChartSettings} ref={ref} />;
  }
);

export default SLDonutChartView;
