/**
 * Anomaly Permissions
 * @author aditya.bhadange@shorelineiot.com
 */

export const ANOMALY_PERMISSIONS = {
  VIEW: 'view anomaly',
  CREATE: 'create anomaly',
  UPDATE: 'update anomaly',
  DELETE: 'delete anomaly'
};

export const ANOMALY_TEMPLATES_PERMISSIONS = {
  VIEW: 'view anomaly template',
  CREATE: 'create anomaly template',
  UPDATE: 'update anomaly template',
  DELETE: 'delete anomaly template'
};
