/**
 * fetch device photos reducer
 * @author ashwini.gawade@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTION_TYPE from './devicePhotos.actionTypes';
import { DEVICE_PHOTOS_STATUS, devicePhotosState } from './devicePhotos.state';

const initialState: devicePhotosState = {
  status: DEVICE_PHOTOS_STATUS.INIT,
  multipleDevicePhotos: []
};

function fetchDevicePhotosFailure(state: devicePhotosState): devicePhotosState {
  return {
    ...state,
    status: DEVICE_PHOTOS_STATUS.ERROR
  };
}

function resetDevicePhotos(state: devicePhotosState): devicePhotosState {
  return {
    ...state,
    status: DEVICE_PHOTOS_STATUS.INIT,
    multipleDevicePhotos: []
  };
}

function fetchMultipleDevicePhotos(state: devicePhotosState): devicePhotosState {
  return {
    ...state,
    status: DEVICE_PHOTOS_STATUS.FETCHING_DEVICE_PHOTOS
  };
}

function fetchMultipleDevicePhotosSuccess(
  state: devicePhotosState,
  data: any[]
): devicePhotosState {
  return {
    ...state,
    status: DEVICE_PHOTOS_STATUS.READY,
    multipleDevicePhotos: data
  };
}

function fetchMultipleDevicePhotosFailure(state: devicePhotosState): devicePhotosState {
  return {
    ...state,
    multipleDevicePhotos: [],
    status: DEVICE_PHOTOS_STATUS.ERROR
  };
}

export default function loadDevicePhotosReducer(
  state: devicePhotosState = initialState,
  action: AnyAction
): any {
  switch (action.type) {
    case ACTION_TYPE.LOAD_PHOTOS_FAILURE:
      return fetchDevicePhotosFailure(state);
    case ACTION_TYPE.RESET_LOAD_PHOTOS:
      return resetDevicePhotos(state);
    case ACTION_TYPE.LOAD_MULTIPLE_DEVICE_PHOTOS:
      return fetchMultipleDevicePhotos(state);
    case ACTION_TYPE.LOAD__MULTIPLE_DEVICE_PHOTOS_SUCCESS:
      return fetchMultipleDevicePhotosSuccess(state, action.payload);
    case ACTION_TYPE.LOAD__MULTIPLE_DEVICE_PHOTOS_FAILURE:
      return fetchMultipleDevicePhotosFailure(state);
    default:
      return state;
  }
}
