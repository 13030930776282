import { AnyAction } from 'redux';
import * as ACTION_TYPES from './updateRuleTemplate.actionTypes';

export const updateRuleTemplateProgress = (data: any): AnyAction => {
  return {
    type: ACTION_TYPES.UPDATE_RULE_TEMPLATE_PROGRESS,
    payload: data
  };
};
/**
 *
 * @param data
 */
export const updateRuleTemplateSuccess = (data: any): AnyAction => {
  // returns the action type
  return {
    type: ACTION_TYPES.UPDATE_RULE_TEMPLATE_SUCCESS,
    data
  };
};
/**
 *
 * @param error
 */
export const updateRuleTemplateFailure = (error: any): AnyAction => {
  // returns the action type
  return {
    type: ACTION_TYPES.UPDATE_RULE_TEMPLATE_FAILURE,
    error
  };
};
/**
 *
 * @param data
 */

export const updateRuleTemplateReset = (): AnyAction => {
  return {
    type: ACTION_TYPES.UPDATE_RULE_TEMPLATE_RESET_STATE
  };
};
