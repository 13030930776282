export enum REMARK_STATUS {
  INIT,
  FETCHING,
  READY,
  ERROR
}

export interface RemarkState {
  status: REMARK_STATUS;
  RemarksData: any;
}
