import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  customMapButton: {
    background: 'white padding-box',
    cursor: 'pointer',
    border: '0px',
    marginTop: '0.55em',
    color: 'gray',
    padding: '9px 11px',
    fontFamily: 'Roboto, Arial, sans-serif',
    fontSize: '18px',
    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px',
    minWidth: '36px',
    display: 'table-cell',
    position: 'relative',
    '&:hover': {
      color: 'black',
      background: 'rgb(235,235,235) padding-box',
      '& $customMapButtonTooltip': {
        visibility: 'visible',
        opacity: 1
      }
    }
  },
  customMapButtonTooltip: {
    visibility: 'hidden',
    whiteSpace: 'nowrap',
    backgroundColor: 'rgba(0,0,0,0.8)',
    color: 'white',
    fontFamily: 'Roboto, Arial, sans-serif',
    fontSize: '14px',
    textAlign: 'center',
    borderRadius: '6px',
    padding: '10px',
    position: 'absolute',
    top: '100%',
    left: '25%',
    transition: 'opacity 0.3s'
  },
  title: {
    marginLeft: 16
  },
  legends: {
    marginLeft: 10
  }
}));
