/**
 * Alarms Details Actions
 * @author mahesh.kedari@shorelineiot.com
 */
import { AnyAction } from 'redux';
import { GenericObject } from '../../../../framework';
import * as ACTIONS_TYPES from './alarmsDetails.actionTypes';
/**
 *
 * @param data
 * @returns
 */
export const fetchAlarmsDetails = (data: GenericObject): AnyAction => {
  return {
    type: ACTIONS_TYPES.FETCH_ALARMS_DETAILS,
    payload: data
  };
};
/**
 *
 */
export const fetchAlarmsDetailsSuccess = (data: GenericObject): AnyAction => {
  return {
    type: ACTIONS_TYPES.FETCH_ALARMS_DETAILS_SUCCESS,
    payload: data
  };
};
/**
 *
 */
export const fetchAlarmsDetailsFailure = (): AnyAction => {
  return {
    type: ACTIONS_TYPES.FETCH_ALARMS_DETAILS_FAILURE
  };
};

export const resetAlarmsDetails = (): AnyAction => {
  return {
    type: ACTIONS_TYPES.RESET_ALARMS_DETAILS
  };
};
