import { Dispatch } from 'redux';
import { userApi } from '../../../org-settings/store/user-management/components/user-list/user.api';
import { UsersMessageHandlerData } from '../websocket.types';
import { FetchUsersArgs } from '../../../org-settings/store/user-management/components/user-list/user.types';

export function usersMessageHandler(
  dispatch: Dispatch<any>,
  data: UsersMessageHandlerData,
  arg: FetchUsersArgs
) {
  switch (data.action) {
    case 'add':
      dispatch(
        userApi.util.updateQueryData('fetchUsers', arg, (draftUsers) => {
          const updatedUserIndex = draftUsers.findIndex(
            (user) => user.id === data.payload.users[0].id
          );
          /**
           * From org column when user is invited for sub org
           * then add type websocket is recived so thats handled here
           *
           * Checking if user exist or not then adding new data
           */
          if (updatedUserIndex !== -1) {
            draftUsers[updatedUserIndex] = data.payload.users[0];
          } else {
            draftUsers.push(...data.payload.users);
          }
        })
      );
      break;
    case 'update':
      dispatch(
        userApi.util.updateQueryData('fetchUsers', arg, (draftUsers) => {
          const updatedUserIndex = draftUsers.findIndex(
            (user) => user.id === data.payload.users[0].id
          );
          if (updatedUserIndex !== -1) {
            draftUsers[updatedUserIndex] = data.payload.users[0];
          }
        })
      );
      break;
    case 'delete':
      dispatch(
        userApi.util.updateQueryData('fetchUsers', arg, (draftUsers) => {
          const deletedUserIndex = draftUsers.findIndex(
            (user) => user.id === data.payload.users[0].id
          );
          if (deletedUserIndex !== -1) {
            draftUsers.splice(deletedUserIndex, 1);
          }
        })
      );
      break;
  }
}
