/**
 * Configure reducer
 * @author mahesh.kedari@shorelineiot.com
 */
import { all, takeEvery, put } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import * as ACTIONS from './configure.actionTypes';
import * as actions from './configure.actions';
import { GenericObject, httpGet, showToast, APISERVICES } from '../../../framework';
import { AuthService } from '../../../framework/services/auth.service';

function requestConfig(slug: string) {
  return httpGet(`auth/config/${slug}`, null, APISERVICES.HOST_API);
}
/**
 *
 */
export function* configureAmplifySaga(action: AnyAction) {
  // Configure Amplify with parameters
  try {
    const authService = new AuthService();
    yield authService.configure(action.payload);
    yield put(actions.configureAmplifySuccess());
  } catch (error: any) {
    yield put(showToast('Authentication Config Failed', 'error', true));
    yield put(actions.configureAmplifyFailure(error));
  }
}
/**
 *
 */
export function* getAmplifyConfigSaga(action: AnyAction) {
  try {
    const response: GenericObject = yield requestConfig(action.payload);
    yield put(actions.requestConfigSuccess(response));
  } catch (error: any) {
    // yield put(showToast('Authentication Config Failed', 'error'));
    yield put(actions.requestConfigFailure(error));
  }
}
/**
 *
 */
export function* configureSagas() {
  yield all([
    takeEvery(ACTIONS.AMPLIFY_CONFIG_REQUEST, getAmplifyConfigSaga),
    takeEvery(ACTIONS.AMPLIFY_CONFIG_INIT, configureAmplifySaga)
  ]);
}
