/**
 * get widget settings config Saga
 * @author ashwini.gawade@shorelineiot.com
 */
import { AnyAction } from 'redux';
import { takeEvery, call, put, all } from 'redux-saga/effects';
import widgetData from './widget.json';
import * as getWidgetSettingsActions from './getWidgetSetting.actions';
import * as GET_WIDGET_SETTING_ACTIONS_TYPES from './getWidgetSetting.actionTypes';
import { GenericObject, removeProgressFor, showProgressFor } from '../../../../../../framework';
import { SagaIterator } from 'redux-saga';

/**
 * get widget settings for specified datapoint
 */
function getWidgetSettingConfig() {
  return widgetData;
  // return httpGet(`orgs/${data.slug}/widgets/devices/${data.deviceId}`);
}

/**
 *
 * @param action
 */
function* getWidgetSettingSaga(action: AnyAction) {
  const data = action.payload;
  yield put(showProgressFor(`GET_WIDGET_SETTING_${data.deviceId}`));
  try {
    const resp: GenericObject = yield call(getWidgetSettingConfig /* , data */);
    yield put(getWidgetSettingsActions.getWidgetSettingSuccess(resp.widgets));
  } catch (error: any) {
    yield put(
      getWidgetSettingsActions.getWidgetSettingFailure({
        dpid: data.deviceId,
        config: {
          chartType: 'LINE_CHART'
        }
      })
    );
  } finally {
    yield put(removeProgressFor(`GET_WIDGET_SETTING_${data.deviceId}`));
  }
}
/**
 * Watcher saga for Fetch Topic Request
 */
export function* watchWidgetSettingSaga(): SagaIterator {
  yield all([
    takeEvery(GET_WIDGET_SETTING_ACTIONS_TYPES.GET_WIDGET_SETTINGS, getWidgetSettingSaga)
  ]);
}
