/**
 * Table Styles
 * @author mahesh.kedari@shorelineiot.com
 */
import { TableCellClasses, TableSortLabelClasses } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { Palette } from './palette';
/**
 *
 */
export const TableStyle: Partial<OverridesStyleRules<keyof TableCellClasses, 'MuiTableCell'>> = {
  root: {
    fontSize: '0.875rem'
  },
  head: {
    fontWeight: 500,
    padding: '3px 6px'
  }
};

export const TableSortLabel: Partial<
  OverridesStyleRules<keyof TableSortLabelClasses, 'MuiTableSortLabel'>
> = {
  root: {
    color: Palette.text?.primary,
    '&:hover': {
      color: Palette.text?.primary
    },
    '&:focus': {
      color: Palette.text?.primary
    }
  }
};
