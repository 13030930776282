/**
 * Anomaly Template Details Actions
 * @author aditya.bhadange@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTIONS from './anomalyTemplatesDetails.actionTypes';
/**
 *
 * @param anomalyId
 * @returns
 */
export const fetchAnomalyTemplateDetails = (anomalyId: string | number): AnyAction => ({
  type: ACTIONS.FETCH_ANOMALY_TEMPLATE_DETAILS,
  payload: {
    anomalyId
  }
});
/**
 *
 * @param payload
 * @returns
 */
export const fetchComponentDetailsSuccess = (payload: any): AnyAction => ({
  type: ACTIONS.FETCH_ANOMALY_TEMPLATE_DETAILS_SUCCESS,
  payload
});
/**
 *
 * @param error
 * @returns
 */
export const fetchComponentDetailsFailure = (error: any) => ({
  type: ACTIONS.FETCH_ANOMALY_TEMPLATE_DETAILS_FAILURE,
  error
});
/**
 *
 * @returns
 */
export const resetAnomalyTemplateDetails = () => ({
  type: ACTIONS.RESET_ANOMALY_TEMPLATE_DETAILS
});
/**
 *
 * @param anomalyId
 * @param payload
 * @returns
 */
export const updateAnomalyTemplateDetails = (anomalyId: any, payload: any) => ({
  type: ACTIONS.UPDATE_ANOMALY_TEMPLATE,
  payload: {
    anomalyId,
    payload
  }
});
/**
 *
 * @param payload
 * @returns
 */
export const updateAnomalyTemplateSuccess = (payload: any) => ({
  type: ACTIONS.UPDATE_ANOMALY_TEMPLATE_SUCCESS,
  payload
});
/**
 *
 * @param payload
 * @returns
 */
export const updateAnomalyTemplateFailure = (error: any) => ({
  type: ACTIONS.UPDATE_ANOMALY_TEMPLATE_FAILURE,
  error
});
