import { RootState } from '../../../../configureStore';
import { WEBSOCKET_TYPE } from '../../../websocket';
import { isDeviceRelatedWebSocketMessage } from '../device.utils';
import { AnyAction, ThunkDispatch, UnknownAction } from '@reduxjs/toolkit';

type MessageHandlerFunction<U> = (
  dispatch: ThunkDispatch<any, any, AnyAction>,
  data: any,
  customArgs: U
) => void;

/**
 * Sets up a WebSocket listener for a specific feature with a provided message handler and custom arguments.
 *
 * @typeparam T - The type of API response.
 * @typeparam U - The type of custom arguments passed to the message handler.
 *
 * @param messageHandler - A function that handles incoming WebSocket messages.
 * @param customArgs - Custom arguments to be passed to the message handler.
 * @param api - API object providing access to the cache, state, and dispatch.
 *
 * @returns A promise that resolves when the WebSocket listener is set up.
 *
 * @throws Will catch and handle any errors that occur during the setup process.
 */
export const setupWebSocketListener = async <T, U>(
  messageHandler: MessageHandlerFunction<U>,
  customArgs: U,
  {
    cacheDataLoaded,
    getState,
    dispatch
  }: {
    cacheDataLoaded: Promise<void> | Promise<{ data: T; meta: {} | undefined }>;
    getState: () => unknown;
    dispatch: ThunkDispatch<any, any, UnknownAction>;
  }
): Promise<void> => {
  try {
    // Ensure cache data is loaded before setting up the WebSocket listener.
    await cacheDataLoaded;

    // Access the current state from the store.
    const state = getState() as RootState;

    // Find the WebSocket connection related to the specified feature type.
    const ws = state.features.websocket.connections?.find(
      (connection) => WEBSOCKET_TYPE.DEVICE === connection.type
    );

    const listener = (event: MessageEvent): void => {
      const data = JSON.parse(event.data);

      // Check if the incoming message is related to devices.
      if (isDeviceRelatedWebSocketMessage(data)) {
        // Call the provided message handler with the dispatch function, data, and custom arguments.
        messageHandler(dispatch, data, customArgs);
      }
    };

    // Attach the listener to the WebSocket connection if available.
    if (ws?.connection && ws.connection.onmessage && (ws?.connection as WebSocket)) {
      ws?.connection.addEventListener('message', listener);
    }
  } catch (error) {
    // handle error
  }
};
