/**
 * Roles List Actions
 * @author mahesh.kedari@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTIONS from './rolesList.actionTypes';
/**
 *
 */
export const fetchRoles = (slug: string): AnyAction => {
  return {
    type: ACTIONS.FETCH_ROLES,
    payload: slug
  };
};
/**
 *
 * @param data
 */
export const fetchRolesSuccess = (data: any): AnyAction => {
  return {
    type: ACTIONS.ROLES_SUCCESS,
    payload: data
  };
};
/**
 *
 */
export const fetchRolesFailure = (): AnyAction => {
  return {
    type: ACTIONS.ROLES_FAILURE
  };
};

export const resetState = (): AnyAction => {
  return {
    type: ACTIONS.ROLES_RESET
  };
};
export const updateRoles = (data: any): AnyAction => {
  return {
    type: ACTIONS.ROLES_UPDATE,
    payload: data
  };
};
