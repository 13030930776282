import { AnyAction } from 'redux';
import * as ACTION_TYPE from './deviceReports.actionTypes';
import { DeviceReportsObj, DeviceReportsState, DEVICE_REPORTS_STATUS } from './deviceReports.state';
import { enhanceDeviceReportWithDatapoints } from './deviceReports.utils';

const initialState: DeviceReportsState = {
  status: DEVICE_REPORTS_STATUS.INIT,
  DeviceReportsData: { device_summary: [] }
};

function handleFetchDeviceReportsDetails(state: DeviceReportsState): DeviceReportsState {
  return {
    ...state,
    status: DEVICE_REPORTS_STATUS.FETCHING
  };
}

function handleFetchDeviceReportsSuccess(
  state: DeviceReportsState,
  DeviceReportsData: DeviceReportsObj
): DeviceReportsState {
  const formattedDeviceReports = enhanceDeviceReportWithDatapoints(DeviceReportsData);
  return {
    ...state,
    status: DEVICE_REPORTS_STATUS.READY,
    DeviceReportsData: formattedDeviceReports
  };
}

function handleFetchDeviceReportsFailure(state: DeviceReportsState): DeviceReportsState {
  return {
    ...state,
    status: DEVICE_REPORTS_STATUS.ERROR
  };
}

function resetDeviceReports(): DeviceReportsState {
  return initialState;
}

export default function devicereportsReducer(
  state: DeviceReportsState = initialState,
  action: AnyAction
): DeviceReportsState {
  switch (action.type) {
    case ACTION_TYPE.FETCH_DEVICE_REPORTS:
      return handleFetchDeviceReportsDetails(state);
    case ACTION_TYPE.FETCH_DEVICE_REPORTS_SUCCESS:
      return handleFetchDeviceReportsSuccess(state, action.payload);
    case ACTION_TYPE.FETCH_DEVICE_REPORTS_FAILURE:
      return handleFetchDeviceReportsFailure(state);
    case ACTION_TYPE.RESET_DEVICE_REPORTS:
      return resetDeviceReports();
    default:
      return state;
  }
}
