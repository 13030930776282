/**
 * Notifications combining reducer
 * @author priyanka.ambawane@shorelineiot.com
 */

import { combineReducers } from 'redux';
import setLastNotification from './live-notifications.reducer';

const notifications = combineReducers({
  setLastNotification
});

export default notifications;
