import { AlertLevel } from '../../../../features/alarms/store';
import TranslateText from '../../../../i18n/TranslateText';

export const getRelativeTime = (lastConnectedTS: number) => {
  const currentTS = Math.floor(new Date().getTime());
  const msPerMinute = 60 * 1000;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;
  const msPerMonth = msPerDay * 30;
  const msPerYear = msPerDay * 365;

  const elapsed = currentTS - lastConnectedTS * 1000;

  if (!lastConnectedTS) {
    return '-';
  }

  if (elapsed < msPerMinute) {
    return `${Math.round(elapsed / 1000)}  ${TranslateText('seconds_ago', 'seconds ago')}`;
  }

  if (elapsed < msPerHour) {
    return `${Math.round(elapsed / msPerMinute)}  ${TranslateText('minutes_ago', 'minutes ago')}`;
  }

  if (elapsed < msPerDay) {
    return `${Math.round(elapsed / msPerHour)}  ${TranslateText('hours_ago', 'hours ago')}`;
  }

  if (elapsed < msPerMonth) {
    return `${Math.round(elapsed / msPerDay)}  ${TranslateText('days_ago', 'days ago')}`;
  }

  if (elapsed < msPerYear) {
    return `${Math.round(elapsed / msPerMonth)}  ${TranslateText('months_ago', 'months ago')}`;
  }

  return `${Math.round(elapsed / msPerYear)}  ${TranslateText('years_ago', 'years ago')}`;
};

export const getAlertName = (alertLevel: AlertLevel | undefined) => {
  switch (alertLevel) {
    case 'ALERT_FATAL':
      return 'Critical';
    case 'ALERT_ERROR':
      return 'High';
    case 'ALERT_WARNING':
      return 'Warning';
    case 'ALERT_LOW':
      return 'Moderate';
    default:
      return 'None';
  }
};

export const getCorrectAlert = (alertLevel: AlertLevel | undefined) => {
  switch (alertLevel) {
    case 'ALERT_FATAL':
      return 'fatal';
    case 'ALERT_ERROR':
      return 'error';
    case 'ALERT_WARNING':
      return 'warning';
    case 'ALERT_LOW':
      return 'low';
    default:
      return 'none';
  }
};
