import { AnyAction } from 'redux';
import * as ACTION_TYPE from './getRuleTemplate.actionTypes';
import { GET_RULE_TEMPLATE_STATUS, getRuleTemplateState } from './getRuleTemplate.state';

const initialState: getRuleTemplateState = {
  status: GET_RULE_TEMPLATE_STATUS.INIT,
  RuleTemplateData: []
};

function fetchRuleTemplateProgress(state: getRuleTemplateState): getRuleTemplateState {
  return {
    ...state,
    status: GET_RULE_TEMPLATE_STATUS.FETCHING
  };
}

function fetchRuleTemplateSuccess(
  state: getRuleTemplateState,
  RuleTemplateData: any
): getRuleTemplateState {
  return {
    ...state,
    status: GET_RULE_TEMPLATE_STATUS.READY,
    RuleTemplateData
  };
}

function fetchRuleTemplateFailure(state: getRuleTemplateState): getRuleTemplateState {
  return {
    ...state,
    status: GET_RULE_TEMPLATE_STATUS.ERROR
  };
}

function resetRuleTemplate(state: getRuleTemplateState): getRuleTemplateState {
  return {
    ...state,
    status: GET_RULE_TEMPLATE_STATUS.INIT
  };
}
export default function getRuleTemplateReducer(
  state: getRuleTemplateState = initialState,
  action: AnyAction
): getRuleTemplateState {
  switch (action.type) {
    case ACTION_TYPE.FETCH_RULE_TEMPLATE_PROGRESS:
      return fetchRuleTemplateProgress(state);
    case ACTION_TYPE.FETCH_RULE_TEMPLATE_SUCCESS:
      return fetchRuleTemplateSuccess(state, action.payload);
    case ACTION_TYPE.FETCH_RULE_TEMPLATE_FAILURE:
      return fetchRuleTemplateFailure(state);
    case ACTION_TYPE.FETCH_RULE_TEMPLATE_RESET_STATE:
      return resetRuleTemplate(state);
    default:
      return state;
  }
}
