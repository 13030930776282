import { AnyAction } from 'redux';
import * as ACTION_TYPE from './remarks.actionTypes';
import { RemarkState, REMARK_STATUS } from './remark.state';

const initialState: RemarkState = {
  status: REMARK_STATUS.INIT,
  RemarksData: []
};

function handleFetchRemarkDetails(state: RemarkState): RemarkState {
  return {
    ...state,
    status: REMARK_STATUS.FETCHING
  };
}

function handleFetchRemarkSuccess(state: RemarkState, RemarksData: any): RemarkState {
  return {
    ...state,
    status: REMARK_STATUS.READY,
    RemarksData
  };
}

function handleFetchRemarkFailure(state: RemarkState): RemarkState {
  return {
    ...state,
    status: REMARK_STATUS.ERROR
  };
}

function resetRemark(state: RemarkState): RemarkState {
  return {
    ...state,
    status: REMARK_STATUS.INIT,
    RemarksData: []
  };
}

export default function remarkReducer(
  state: RemarkState = initialState,
  action: AnyAction
): RemarkState {
  switch (action.type) {
    case ACTION_TYPE.FETCH_REMARK:
      return handleFetchRemarkDetails(state);
    case ACTION_TYPE.FETCH_REMARK_SUCCESS:
      return handleFetchRemarkSuccess(state, action.payload);
    case ACTION_TYPE.FETCH_REMARK_FAILURE:
      return handleFetchRemarkFailure(state);
    case ACTION_TYPE.RESET_REMARK:
      return resetRemark(state);
    default:
      return state;
  }
}
