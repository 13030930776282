/**
 * User Script Menu
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { useEffect, useState } from 'react';

import { SidebarMenu, useSlugContext } from '../../framework';
import UserScriptIcon from '@mui/icons-material/Terminal';
import { USER_SELF_STATUS, useUserSelf } from '../user-settings';
import { sidebarId } from '../../framework/components/sidebar/sidebar.utils';
import { useIsFeatureEnable } from '../../framework/custom-hooks/useIsFeatureEnable';
import { FEATURE_NAME } from '../featureUniqueNames';

export default function UserScriptsMenu() {
  const { slug } = useSlugContext();
  const [role, setRole] = useState('');
  const [email, setEmail] = useState('');
  const userSelf = useUserSelf();
  const isFeatureEnabled = useIsFeatureEnable(FEATURE_NAME.USER_SCRIPTS);
  const allowedDomain = 'shorelineiot.com';
  function checkEmailDomain(email: string, domain: string) {
    // Regular expression pattern to match email addresses
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if the email matches the pattern
    if (emailRegex.test(email)) {
      // Extract the domain part of the email
      const emailDomain = email.split('@')[1];

      // Check if the extracted domain matches the specified domain
      if (emailDomain === domain) {
        return true;
      }
    }

    return false;
  }

  useEffect(() => {
    if (userSelf.status === USER_SELF_STATUS.READY) {
      setRole(userSelf?.self?.role?.name);
      setEmail(userSelf?.self?.email);
    }
  }, [userSelf]);
  if (role === 'Super Admin' && checkEmailDomain(email, allowedDomain) && isFeatureEnabled) {
    const route = `/${slug}/app/user-scripts`;
    return (
      <SidebarMenu
        testID={sidebarId.menuItems('user_script')}
        route={route}
        textKey="user_script"
        defaultText="User Script"
        icon={<UserScriptIcon className="MuiSvgIcon-root" />}
      />
    );
  }
  return null;
}
