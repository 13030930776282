/**
 * Automatic Configurator Actions
 * @author mahesh.kedari@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as AUTOCONFIG_ACTIONS from './autoConfig.actionTypes';
/**
 *
 * @param payload
 */
export const parseNarrowbands = (payload: any) => {
  return {
    type: AUTOCONFIG_ACTIONS.PARSE_NARROWBANDS,
    payload
  };
};

export const saveActions = (payload: any) => {
  return {
    type: AUTOCONFIG_ACTIONS.SAVE_ACTIONS,
    payload
  };
};

interface SubmitNarrowbandPayload {
  deviceId: string | number;
  narrowbandConfig: any;
  slug?: string;
  // index: number;
  narrowbands: any;
  deletedNbDpid: any;
  isEdit: boolean;
  surveyAnswers: any;
  componentId: string | number;
  powertrain: any;
}

/**
 *
 * @param payload
 */
export const setComponentId = (payload: any): AnyAction => {
  return {
    type: AUTOCONFIG_ACTIONS.SET_COMPONENT_ID,
    payload
  };
};
/**
 *
 */
export const submitNarrowbands = (payload: SubmitNarrowbandPayload): AnyAction => {
  return {
    type: AUTOCONFIG_ACTIONS.SUBMIT_NARROWBANDS,
    payload
  };
};
/**
 *
 */
export const submitNarrowbandUpdating = (): AnyAction => {
  return {
    type: AUTOCONFIG_ACTIONS.SUBMIT_NARROWBAND_UPDATING
  };
};
/**
 *
 */
export const submitNarrowbandSuccess = (): AnyAction => {
  return {
    type: AUTOCONFIG_ACTIONS.SUBMIT_NARROWBAND_SUCCESS
  };
};
/**
 *
 */
export const submitNarrowbandFailure = (error: any): AnyAction => {
  return {
    type: AUTOCONFIG_ACTIONS.SUBMIT_NARROWBAND_ERROR,
    error
  };
};

export const attachDiagnostics = (payload: any): AnyAction => {
  return {
    type: AUTOCONFIG_ACTIONS.ATTACH_NB_DIAGNOSTICS,
    payload
  };
};

/**
 *
 */
export const resetAutoconfigNB = (): AnyAction => {
  return {
    type: AUTOCONFIG_ACTIONS.RESET_AUTOCONFIG
  };
};
