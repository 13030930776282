/**
 * Spinner Reducer
 * @author mahesh.kedari@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTIONS from '../actions/loader/loader.actionTypes';

export default function spinnerReducer(state = false, action: AnyAction) {
  switch (action.type) {
    case ACTIONS.SHOW_LOADING_SPINNER: {
      return true;
    }
    case ACTIONS.HIDE_LOADING_SPINNER: {
      return false;
    }
    default:
      return state;
  }
}
