import { OptionType, ProviderTypeOptions } from './SsoConfigurations.types';

export const SSO_CONFIGURATIONS_BREADCRUMB_KEY = 'sso-configurations';
export const mappingAttributes = ['email', 'given_name', 'family_name'];

export const ProviderOptions: OptionType[] = [
  {
    value: ProviderTypeOptions.GOOGLE,
    label: ProviderTypeOptions.GOOGLE
  },
  { value: ProviderTypeOptions.MICROSOFT, label: ProviderTypeOptions.MICROSOFT },
  { value: ProviderTypeOptions.AUTH0, label: ProviderTypeOptions.AUTH0 },
  {
    value: ProviderTypeOptions.OKTA,
    label: ProviderTypeOptions.OKTA
  },
  {
    value: ProviderTypeOptions.SECUREAUTH,
    label: ProviderTypeOptions.SECUREAUTH
  },
  {
    value: ProviderTypeOptions.CUSTOM,
    label: ProviderTypeOptions.CUSTOM
  }
];
