import { put, takeEvery, call } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import * as actions from '../actions/rolesActionsOrg';
import * as RolesActions from '../actions/rolesActions';
import * as ACTION_TYPES from '../actions/rolesUpdate.actionTypes';
import {
  GenericObject,
  httpGet,
  removeProgressFor,
  showProgressFor,
  APISERVICES
} from '../../../../../../../framework';
import { SagaIterator } from 'redux-saga';

const ORG_ACTIONS_LIST = 'ORG_ACTIONS_LIST';

function getList(data: any) {
  return httpGet(`orgs/${data.slug}/actions`, null, APISERVICES.HOST_API);
}
function* OrgActionsSaga(action: AnyAction) {
  yield put(showProgressFor(ORG_ACTIONS_LIST));
  try {
    const response: GenericObject = yield call(getList, action.payload);

    yield put(actions.fetchRolesOrgSuccess(response));
    const dataRoles = {
      slug: action.payload.slug,
      roleId: action.payload.roleId
    };
    yield put(RolesActions.fetchRolesActions(dataRoles));
  } catch (error: any) {
    yield put(actions.fetchRolesOrgFailure());
  } finally {
    yield put(removeProgressFor(ORG_ACTIONS_LIST));
  }
}
/**
 *
 */
export default function* watchOrgActions(): SagaIterator {
  yield takeEvery(ACTION_TYPES.FETCH_ACTIONS_ORG, OrgActionsSaga);
}
