import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DataObject, TrendChartState } from './trendChart.types';

const initialState: TrendChartState = {
  selectedDevices: [],
  selectedDatapoints: [],
  selectedRecordCount: undefined,
  selectedTimeRange: '1m',
  devicesDatapointsCollection: [],
  isGenerateChartData: true
};

export const trendChart = createSlice({
  name: 'trendChart',
  initialState,
  reducers: {
    handleSelectedDevices(state: TrendChartState, action: PayloadAction<string[]>) {
      state.selectedDevices = action.payload;
    },
    handleSelectedDatapoints(state: TrendChartState, action: PayloadAction<string[]>) {
      state.selectedDatapoints = action.payload;
    },
    handleSelectedRecordCount(state: TrendChartState, action: PayloadAction<number | undefined>) {
      state.selectedRecordCount = action.payload;
    },
    handleSelectedTimeRange(state: TrendChartState, action: PayloadAction<string>) {
      state.selectedTimeRange = action.payload;
    },
    handleDevicesDatapointsCollection(state: TrendChartState, action: PayloadAction<DataObject[]>) {
      state.devicesDatapointsCollection = action.payload;
    },
    handleIsGenerateChartData(state: TrendChartState, action: PayloadAction<boolean>) {
      state.isGenerateChartData = action.payload;
    },
    resetTrendChart() {
      return initialState;
    }
  }
});

export const {
  handleSelectedDevices,
  handleSelectedDatapoints,
  handleSelectedRecordCount,
  handleSelectedTimeRange,
  handleDevicesDatapointsCollection,
  handleIsGenerateChartData,
  resetTrendChart
} = trendChart.actions;

export default trendChart.reducer;
