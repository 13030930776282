/**
 * User Role
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { ReactElement, useState, useEffect, useRef, useLayoutEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { USER_SELF_STATUS, useUserSelf } from '../store';
import { useStyles } from './userRole.styles';
import { LightTooltip } from '../../../framework/components/tooltip/Tooltip';
/**
 *
 */
export default function UserRole(): ReactElement {
  // TODO: Pull the role from Store
  const classes = useStyles();
  const userSelf = useUserSelf();
  const [role, setRole] = useState('');
  const [open, setOpen] = useState(false);
  const menuContainerRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (menuContainerRef.current !== null) {
      const node = menuContainerRef.current;
      if (node?.scrollWidth > node?.clientWidth) {
        setOpen(true);
      }
    }
  });

  useEffect(() => {
    if (userSelf.status === USER_SELF_STATUS.READY) {
      setRole(userSelf?.self?.role?.name);
    }
  }, [userSelf]);
  return (
    <>
      {userSelf.status === USER_SELF_STATUS.READY ? (
        <LightTooltip disableHoverListener={!open} title={role} placement="bottom-start">
          <div ref={menuContainerRef}>
            <Typography variant="subtitle1" className={classes.root}>
              Org. role: {role}
            </Typography>
          </div>
        </LightTooltip>
      ) : (
        <div style={{ width: '100%', whiteSpace: 'nowrap' }}>
          <Box>
            <Typography variant="subtitle1" className={classes.root}>
              Org. role:
            </Typography>
          </Box>
        </div>
      )}
    </>
  );
}
