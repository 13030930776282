/**
 * Forgot Password Helper function
 * @author mahesh.kedari@shorelineiot.com
 */
import { LoginStateType } from '../types/LoginStateType';
/**
 *
 */
export function codeSentSuccess(state: LoginStateType, email: string): LoginStateType {
  return {
    ...state,
    status: 'FORGOT_PASSWORD_CODE_SENT',
    user: { email }
  };
}
/**
 *
 * @param state
 * @param session
 */
export function onSetNewPassword(state: LoginStateType, session: any): LoginStateType {
  return {
    ...state,
    status: 'RESET_PASSWORD_REQUIRED',
    session
  };
}
