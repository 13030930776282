/**
 * User self Reducer
 * @author swapnil.kshirsagar@shorelineiot.com
 */
import moment from 'moment-timezone';
import { AnyAction } from 'redux';
import * as ACTION_TYPE from '../actions/userSelf.actionTypes';
import { UserSelfState } from '../actions/userSelfState';
import { USER_SELF_STATUS } from '../actions/userSelfStatus';

const initialState: UserSelfState = {
  status: USER_SELF_STATUS.INIT,
  self: {
    system_units: '',
    email: '',
    user_uuid: '',
    role: { name: '', id: '' },
    first_name: '',
    mobile: '',
    cognito_uuid: '',
    last_name: '',
    country_code: '',
    role_actions: [],
    userName: '',
    timeZone: '',
    systemUnit: '',
    slug: '',
    firstName: '',
    lastName: '',
    language: ''
  }
};
/**
 *
 * @param state
 */
function fetchSelfProgress(state: UserSelfState): UserSelfState {
  return {
    ...state,
    status: USER_SELF_STATUS.FETCHING_SELF
  };
}

/**
 *
 * @param state
 * @param action
 */
function fetchSelfSuccess(state: UserSelfState, action: AnyAction): UserSelfState {
  const userData = action.payload;
  return {
    ...state,
    self: {
      ...action.payload,
      userName: userData?.first_name
        ? `${userData?.first_name}  ${userData?.last_name}`
        : 'Not Available',
      email: userData?.email,
      timeZone: moment?.tz?.guess()
    },
    status: USER_SELF_STATUS.READY
  };
}
/**
 *
 * @param state
 */
function fetchSelfFailure(state: UserSelfState): UserSelfState {
  return {
    ...state,
    status: USER_SELF_STATUS.ERROR
  };
}
function selfReset(state: UserSelfState): UserSelfState {
  return {
    ...state
  };
}
/**
 *
 * @param state
 * @param action
 */
export default function UserSelfReducer(state: UserSelfState = initialState, action: AnyAction) {
  switch (action.type) {
    case ACTION_TYPE.FETCH_USER_SELF_PROGRESS:
      return fetchSelfProgress(state);
    case ACTION_TYPE.FETCH_USER_SELF_SUCCESS:
      return fetchSelfSuccess(state, action);
    case ACTION_TYPE.FETCH_USER_SELF_FAILURE:
      return fetchSelfFailure(state);
    case ACTION_TYPE.FETCH_USER_SELF_RESET:
      return selfReset(state);
    default:
      return state;
  }
}
