/**
 * Additional Sensor Saga
 * @author mahesh.kedari@shorelineiot.com
 */
import { all } from 'redux-saga/effects';
import {
  watchAddSurveySaga,
  watchEditSurveySaga,
  watchGetSurveyAnswersSaga,
  watchAutoconfigSaga,
  watchGetSurveyQuestionsSaga,
  watchGetAutoNarrowbandListSaga
} from './automatic-config';
import { watchDeleteNarrowbandSagas } from './delete-narrowband';
import { watchManualConfigSaga } from './manual-config';
/**
 *
 */
export function* watchNarrowbandSaga() {
  yield all([
    watchAddSurveySaga(),
    watchEditSurveySaga(),
    watchGetSurveyAnswersSaga(),
    watchGetSurveyQuestionsSaga(),
    watchManualConfigSaga(),
    watchAutoconfigSaga(),
    watchDeleteNarrowbandSagas(),
    watchGetAutoNarrowbandListSaga()
  ]);
}
