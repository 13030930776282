/**
 * Widget Settings Accordion
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { ReactElement, PropsWithChildren } from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { toSnakeCase } from '../../../../i18n/translation.helper';
import TranslateText from '../../../../i18n/TranslateText';

interface Props {
  title?: string;
  defaultExpanded?: boolean;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      '& .Mui-expanded.MuiAccordionSummary-content': {
        borderBottom: 'solid thin',
        borderBottomColor: theme.palette.primary.main
      }
    },
    details: {
      flexDirection: 'column'
    },
    heading: {
      fontWeight: theme.typography.fontWeightRegular
    }
  })
);

export default function WidgetSettingAccordion({
  title,
  children,
  defaultExpanded = false
}: PropsWithChildren<Props>): ReactElement {
  const classes = useStyles();
  return (
    <Accordion defaultExpanded={defaultExpanded}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${title}-content`}
        id={`${title}-header`}
        className={classes.root}>
        <Typography variant="subtitle2" className={classes.heading}>
          {title ? TranslateText(toSnakeCase(title), title) : title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>{children}</AccordionDetails>
    </Accordion>
  );
}
