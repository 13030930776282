import { ThunkDispatch, UnknownAction } from '@reduxjs/toolkit';
import { showToast } from '../../toast';
/**
 * Handles a legend item click event.
 * If the shift key is pressed, copies the legend name to the clipboard
 * and shows a success toast.
 * Otherwise, lets Highcharts handle the default action.
 *
 * @param {Highcharts.LegendItemClickEventObject} event The event object.
 * @param {ThunkDispatch<any, any, UnknownAction>} dispatch The dispatch function.
 * @returns {boolean} true if Highcharts should handle the default action, false otherwise.
 */
export const handleLegendItemClick = (
  event: Highcharts.LegendItemClickEventObject,
  dispatch: ThunkDispatch<any, any, UnknownAction>
) => {
  if (event.browserEvent.shiftKey) {
    //@ts-ignore
    const clickedLegend = event?.legendItem?.name;
    if (clickedLegend) {
      navigator.clipboard.writeText(clickedLegend);
      dispatch(showToast('Copied to clipboard', 'success', false));
    }
    return false;
  }
  // Let Highcharts handle the default action
  return true;
};
