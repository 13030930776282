/**
 * Get Local Dashboard Items Actions
 * @author harsha.gokhale@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTION_TYPES from './localDashboardItems.actionsTypes';
/**
 *
 */
export function getLocalDashboardItems(data: any): AnyAction {
  return {
    type: ACTION_TYPES.LOCAL_DASHBOARD_ITEMS_PROGRESS,
    payload: data
  };
}
export function setLocalDashboardItems(data: any): AnyAction {
  return {
    type: ACTION_TYPES.SET_LOCAL_DASHBOARD_ITEMS,
    payload: data
  };
}

export function updateLocalDashboardItems(data: any): AnyAction {
  return {
    type: ACTION_TYPES.UPDATE_LOCAL_DASHBOARD_ITEMS,
    payload: data
  };
}

export function addItemToLocalDashboardItems(data: any): AnyAction {
  return {
    type: ACTION_TYPES.ADD_ITEM_TO_LOCAL_DASHBOARD_ITEMS,
    payload: data
  };
}

export function updateItemToLocalDashboardItems(data: any): AnyAction {
  return {
    type: ACTION_TYPES.UPDATE_ITEM_TO_LOCAL_DASHBOARD_ITEMS,
    payload: data
  };
}

export function deleteItemFromLocalDashboardItems(data: any): AnyAction {
  return {
    type: ACTION_TYPES.DELETE_ITEM_FROM_LOCAL_DASHBOARD_ITEMS,
    payload: data
  };
}

/**
 *
 * @param data data to be returned on successful fetch API call
 */
export function handleGetLocalDashboardItemsSuccess(data: any): AnyAction {
  return {
    type: ACTION_TYPES.LOCAL_DASHBOARD_ITEMS_SUCCESS,
    payload: data
  };
}
/**
 *
 */
export function handleGetLocalDashboardItemsFailure(error: any): AnyAction {
  return {
    type: ACTION_TYPES.LOCAL_DASHBOARD_ITEMS_FAILURE,
    payload: error
  };
}

export const resetState = (): AnyAction => {
  return {
    type: ACTION_TYPES.LOCAL_DASHBOARD_ITEMS_RESET_STATE,
    payload: false
  };
};
