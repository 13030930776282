export enum GET_RULE_TEMPLATE_STATUS {
  INIT,
  FETCHING,
  READY,
  ERROR
}

export interface getRuleTemplateState {
  status: GET_RULE_TEMPLATE_STATUS;
  RuleTemplateData: any;
}
