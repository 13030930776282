/**
 * RBAC Permissions Reducer
 * @author priyanka.ambawane@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTION_TYPE from '../actions/rbac.actionTypes';

export const initialState = {
  isFetchingPermissions: false,
  permissionsReceiveSuccess: false,
  rbacPermissions: [],
  permissionsReceiveFailure: false,
  permissionsReceiveFailureMessage: ''
};

export interface State {
  isFetchingPermissions: boolean;
  permissionsReceiveSuccess: boolean;
  rbacPermissions: Array<any>;
  permissionsReceiveFailure: boolean;
  permissionsReceiveFailureMessage: string;
}

function onFetchProgress(state: State) {
  return {
    ...state,
    isFetchingPermissions: true,
    permissionsReceiveSuccess: false,
    permissionsReceiveFailure: false
  };
}
function onFetchFailure(state: State, action: AnyAction) {
  return {
    ...state,
    isFetchingPermissions: false,
    permissionsReceiveSuccess: false,
    permissionsReceiveFailure: true,
    permissionsReceiveFailureMessage: action.error
  };
}

function onFetchSuccess(state: State, action: AnyAction) {
  return {
    ...state,
    isFetchingPermissions: false,
    permissionsReceiveSuccess: true,
    permissionsReceiveFailure: false,
    permissionsReceiveFailureMessage: '',
    rbacPermissions: action.data
  };
}
/*
    Reducer is a pure function that takes actions and state as input 
    and returns/passes the modified state to the store.
    It is implemented using switch case to update the state according 
    to the ation type required.
*/

export default function rbacPermission(state = initialState, action: AnyAction) {
  switch (action.type) {
    case ACTION_TYPE.RBAC_PERMISSION_PROGRESS:
      return onFetchProgress(state);
    case ACTION_TYPE.RBAC_PERMISSION_SUCCESS:
      return onFetchSuccess(state, action);
    case ACTION_TYPE.RBAC_PERMISSION_FAILURE:
      return onFetchFailure(state, action);
    default:
      return state;
  }
}
