/**
 * Update Dashboard Item Reducer
 */
import { AnyAction } from 'redux';
import * as ACTION_TYPE from './updateDashboardItem.actionsTypes';
import {
  UpdateDashboardItemState,
  UpdateDashboardItemStatus
} from './updateDashboardItem.stateType';

const initialState: UpdateDashboardItemState = {
  status: UpdateDashboardItemStatus.INIT,
  updateDashboardItemData: {}
};

function updateDashboardItemProgress(state: UpdateDashboardItemState): UpdateDashboardItemState {
  return {
    ...state,
    status: UpdateDashboardItemStatus.FETCHING
  };
}

function updateDashboardItemSuccess(
  state: UpdateDashboardItemState,
  updateDashboardItemData: any
): UpdateDashboardItemState {
  return {
    ...state,
    status: UpdateDashboardItemStatus.READY,
    updateDashboardItemData
  };
}

function updateDashboardItemFailure(state: UpdateDashboardItemState): UpdateDashboardItemState {
  return {
    ...state,
    status: UpdateDashboardItemStatus.ERROR
  };
}

function resetState(state: UpdateDashboardItemState): UpdateDashboardItemState {
  return {
    ...state,
    status: UpdateDashboardItemStatus.INIT,
    updateDashboardItemData: {}
  };
}
/*
    Reducer is a pure function that takes actions and state as input 
    and returns/passes the modified state to the store.
    It is implemented using switch case to update the state according 
    to the ation type required.
*/

export default function updateDashboardItemReducer(
  state: UpdateDashboardItemState = initialState,
  action: AnyAction
): UpdateDashboardItemState {
  switch (action.type) {
    case ACTION_TYPE.UPDATE_DASHBOARD_ITEM_PROGRESS:
      return updateDashboardItemProgress(state);
    case ACTION_TYPE.UPDATE_DASHBOARD_ITEM_SUCCESS:
      return updateDashboardItemSuccess(state, action.payload);
    case ACTION_TYPE.UPDATE_DASHBOARD_ITEM_FAILURE:
      return updateDashboardItemFailure(state);
    case ACTION_TYPE.UPDATE_DASHBOARD_ITEM_RESET_STATE:
      return resetState(state);
    default:
      return state;
  }
}
