/**
 * Alerts list State Reducer
 * @author shubham.holkar@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTION_TYPE from '../actions/alerts.actionTypes';
import { AlertsListState, NarrowbandRule } from '../types/AlertsListState';
import { ALERTS_LIST_STATUS } from '../types/AlertsListStatus';

const initialState: AlertsListState = {
  status: ALERTS_LIST_STATUS.INIT,
  alerts: [],
  sensorAlerts: [],
  nbAlerts: [],
  nbAlertsFilter: {
    page: 0,
    rowsPerPage: 10,
    searchText: '',
    sort: '',
    filter: 'category:sensor_rule',
    total: 0,
    level: []
  }
};

function getAlertsListProgress(state: AlertsListState): AlertsListState {
  return {
    ...state,
    status: ALERTS_LIST_STATUS.FETCHING
  };
}

function updateNBAlertsFilter(state: AlertsListState, payload: any): AlertsListState {
  const { page, rowsPerPage, searchText, sort, filter, total, level } = payload.nbAlertsFilter;
  const nbAlertsFilter = {
    page,
    rowsPerPage,
    searchText,
    sort,
    filter,
    total,
    level
  };
  return {
    ...state,
    nbAlertsFilter
  };
}

function getAlertsListSuccess(state: AlertsListState, payload: any): AlertsListState {
  const nbAlerts = payload.nbAlerts.map((alert: NarrowbandRule) => {
    const conditions = new Array(4);

    // Below for loop will create new 'conditions' object,
    // that will store severity type as it is, if available from the API call.
    // But if severity type is not available, then it will store blank object,
    // so that indexing for severity types in 'conditions' object will not change.
    for (let i = 0; i <= 3; i++) {
      if (alert?.conditions[i]?.alert_level === 'ALERT_FATAL') {
        conditions[0] = alert?.conditions[i];
      } else if (alert?.conditions[i]?.alert_level === 'ALERT_ERROR') {
        conditions[1] = alert?.conditions[i];
      } else if (alert?.conditions[i]?.alert_level === 'ALERT_WARNING') {
        conditions[2] = alert?.conditions[i];
      } else if (alert?.conditions[i]?.alert_level === 'ALERT_LOW') {
        conditions[3] = alert?.conditions[i];
      }
    }
    return { ...alert, conditions };
  });
  const { nbAlertsFilter } = state;
  nbAlertsFilter.total = payload.total;

  if (!isNaN(payload?.page)) nbAlertsFilter.page = payload.page;

  return {
    ...state,
    status: ALERTS_LIST_STATUS.READY,
    alerts: payload.alerts,
    sensorAlerts: payload.sensorAlerts,
    nbAlerts: [...nbAlerts],
    nbAlertsFilter
  };
}

function getAlertsListFailure(state: AlertsListState): AlertsListState {
  return {
    ...state,
    status: ALERTS_LIST_STATUS.ERROR
  };
}
function resetState(): AlertsListState {
  // after reseting we keeping user last visited tab type
  // need this when user org switches from alert page
  const nbAlertsFilter = {
    page: 0,
    rowsPerPage: 10,
    searchText: '',
    sort: '',
    filter: 'category:sensor_rule',
    total: 0,
    level: []
  };
  return {
    status: ALERTS_LIST_STATUS.INIT,
    alerts: [],
    sensorAlerts: [],
    nbAlerts: [],
    nbAlertsFilter
  };
}
/**
 * Update Alerts List when one of the alert is updated successfully on backend
 * @param state
 * @param payload
 * @returns
 */
function updateAlertsSuccess(state: AlertsListState, payload: any): AlertsListState {
  const updatedAlerts = payload?.alerts;
  // If updated Alerts Payload is not empty, then update the alerts list
  if (updatedAlerts?.length > 0) {
    const sensorAlerts = state.sensorAlerts.map((alert: any) => {
      // Find matching alert in existing list of alerts
      const matchedAlert = updatedAlerts.find(
        (updatedAlert: any) => alert.alert_id === updatedAlert.alert_id
      );
      // If matched alert is found, then update the alert
      return matchedAlert ? { ...alert, ...matchedAlert } : alert;
    });
    return {
      ...state,
      sensorAlerts
    };
  }
  return state;
}

function fetchAlertsLevelSuccess(state: AlertsListState, data: any): AlertsListState {
  return {
    ...state,
    nbAlertsFilter: { ...state.nbAlertsFilter, level: data }
  };
}

export default function alertsListReducer(
  state: AlertsListState = initialState,
  action: AnyAction
): AlertsListState {
  switch (action.type) {
    case ACTION_TYPE.FETCH_LIST_PROGRESS:
      return getAlertsListProgress(state);
    case ACTION_TYPE.FETCH_LIST_SUCCESSS:
      return getAlertsListSuccess(state, action.payload);
    case ACTION_TYPE.FETCH_LIST_FAILURE:
      return getAlertsListFailure(state);
    case ACTION_TYPE.UPDATE_ALERT_SUCCESS:
      return updateAlertsSuccess(state, action.payload);
    case ACTION_TYPE.RESET_ALERTS_STATE:
      return resetState();
    case ACTION_TYPE.UPDATE_NBALERTS_FILTER:
      return updateNBAlertsFilter(state, action.payload);
    case ACTION_TYPE.FETCH_ALERTS_LEVEL_SUCCESS:
      return fetchAlertsLevelSuccess(state, action.payload);
    default:
      return state;
  }
}
