import React from 'react';
import { TooltipInfo } from './GoogleMapComponent';
import { useStyles } from './mapTooltip.styles';

interface MapTooltipProps {
  tooltipInfo: TooltipInfo;
  lat: number;
  lng: number;
  setOnTooltip: React.Dispatch<React.SetStateAction<boolean>>;
  setShowTooltip: React.Dispatch<React.SetStateAction<boolean>>;
  setTooltipInfo: React.Dispatch<React.SetStateAction<TooltipInfo | null>>;
  showTooltip: boolean;
}
export const MapTooltip = ({
  tooltipInfo,
  setOnTooltip,
  setShowTooltip,
  setTooltipInfo
}: MapTooltipProps) => {
  const classes = useStyles();
  return (
    <div
      className={classes.tooltip}
      onMouseEnter={() => {
        setOnTooltip(true);
      }}
      onMouseLeave={() => {
        setOnTooltip(false);
        setShowTooltip(false);
        setTooltipInfo(null);
      }}>
      {/* If age is present, then it is an alarm. Otherwise it is a healthy device */}
      {tooltipInfo.age && (
        <>
          <b>Name - </b>
          {tooltipInfo.name}
          <br />
          <b>Age - </b>
          {tooltipInfo.age}
          <br />
          <b>Anomaly - </b>
          {tooltipInfo.anomaly}
          <br />
        </>
      )}
      {!tooltipInfo.age && (
        <>
          <b>No Alarms</b>
          <br />
          <b>Name - </b>
          {tooltipInfo.name}
        </>
      )}
    </div>
  );
};
