/**
 * Page Not Found component
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { ReactElement } from 'react';
import { SLContentPane } from '../../framework';

/**
 *
 */
export default function PageNotFound(/* props: Props */): ReactElement {
  return (
    <SLContentPane>
      <div>Page Not Found</div>
    </SLContentPane>
  );
}
