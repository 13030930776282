import React, { ReactElement } from 'react';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';

import { useStyles } from './verificationPages.styles';
import { SLSubmit } from '../../../../framework';
import { FailureLarge } from '../../../../framework/icons';
import { loginActions } from '../../login/store/actions';

interface Props {
  emailId: any;
}

export default function VerificationFailedPage(props: Props): ReactElement {
  const { emailId } = props;
  const dispatch = useDispatch();

  const classes = useStyles();
  return (
    <>
      <Box className={classes.imageStyle}>
        <FailureLarge />
      </Box>

      <Box className={classes.boxStyle}>
        <div className={classes.textStyle}>The verification link has expired.</div>
        <div className={classes.textStyle}>
          Resend the verification link by clicking the button below.
        </div>
      </Box>
      <Box className={classes.boxStyle}>
        <Box className={classes.buttonStyle}>
          <SLSubmit
            dataTestId="resend-button"
            onClick={() => dispatch(loginActions.resendVerificationLink(emailId))}>
            Resend
          </SLSubmit>
        </Box>
      </Box>
    </>
  );
}
