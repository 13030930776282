/**
 * Line Chart View
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import merge from 'lodash/merge';
import { ChartViewProps } from '../common/ChartViewProps';
import { parseSolidGaugeSettings } from './solidGaugeSettings';
import { parseSeries } from '../utils/useSingleValueChart';
/**
 *
 */
const SLSolidGaugeChartView: React.FC<ChartViewProps> = React.forwardRef(
  ({ chartSettings, resultSet }: ChartViewProps, ref: any) => {
    const updatedChartSettings = parseSolidGaugeSettings(chartSettings);
    const [solidGaugeSettings, setSolidGaugeSettings] =
      useState<Highcharts.Options>(updatedChartSettings);

    useEffect(() => {
      const { series } = parseSeries(resultSet);
      const newChartSettings = merge({}, updatedChartSettings, {
        chart: {
          type: chartSettings.chartType
        },
        series
      });
      setSolidGaugeSettings(newChartSettings);
    }, [resultSet, chartSettings]);
    return (
      <>
        <HighchartsReact
          highcharts={Highcharts}
          options={solidGaugeSettings}
          ref={ref}
          containerProps={{
            style: {
              height: '350px'
            }
          }}
        />
      </>
    );
  }
);

export default SLSolidGaugeChartView;
