/*  */
/**
 * Normalise Narrowbands received from API
 * @param multiDeviceNarrowbandsList
 */

import {
  NormalizedNarrowbandsType,
  MultiDeviceNarrowbandsListType
} from './vaNarrowbands.stateType';
import { getODR } from './narrowbandsHelper';
/**
 * Backend sends narrowband for individual event and dpid or a list for device ID.
 * Saga merges the response to form a device -> datapoints -> narrowband hierarchy.
 * We need to normalize this data to list of narrowbands so that this data can be
 * supplied to grid. This function performs normalization operation.
 *
 * NOTE: Duplicate entries of TVE needs to be avoided.
 * @param multiDeviceNarrowbandsList - Narrowbands list grouped by Device and DPID.
 * @returns - Normalized list of narrowbands
 */
export function normalizeAllNarrowbands(
  multiDeviceNarrowbandsList: Array<MultiDeviceNarrowbandsListType>
) {
  const configNarrowbands: Array<NormalizedNarrowbandsType> = [];
  multiDeviceNarrowbandsList?.forEach((nbAxisRow: any) => {
    const tveDeviceList: any = [];
    const narrowbandList: Array<NormalizedNarrowbandsType> = nbAxisRow.narrowbands;
    narrowbandList?.forEach((currentBand: NormalizedNarrowbandsType) => {
      if (currentBand.nb_type === 'tve') {
        // Check if total vibration energy band is already added for current device
        const tveExistInDevice = tveDeviceList?.find(
          (item: any) => item.dpid === currentBand.dpid //&& item.type === type
        );
        if (tveExistInDevice) {
          // If device is already present in tveDeviceList array, do not add duplicate TVE entries
          return;
        }
        // Add device ID to tveDeviceList array to avoid duplicates
        tveDeviceList.push({ dpid: currentBand.dpid });
      }
      const currentKey: any = `${nbAxisRow.deviceId}_${currentBand.raw_dpid}_${currentBand.dpid}`;
      const rawDPName = currentBand.nb_type !== 'tve' ? currentBand.raw_dp_name : 'TVE';
      const bandName = currentBand.nb_type !== 'tve' ? currentBand.name : 'TVE';
      const { deviceId, eventId } = nbAxisRow;
      let rawName: string | string[] = currentBand.name;
      // Accelerometer will have a frequency range of 0.1hz to 10000 hz.
      // And microphone will have a frequency range of above 10000 hz.
      // Microphone doesn't have multiple axis, hence no need to perform any operation on rawName.
      if (currentBand.fmin < 10000 && currentBand.nb_type !== 'tve') {
        rawName = currentBand.name?.split(',');
        rawName.pop();
        rawName = rawName.join(',');
      } else if (currentBand.nb_type === 'tve') {
        rawName = 'TVE';
      }
      // Reconstructing narrowband object for presentation purpose
      const normalizedNarrowband = {
        ...currentBand,
        key: currentKey,
        deviceId,
        eventId,
        raw_dpid: currentBand.raw_dpid,
        raw_dpname: rawDPName,
        odr: getODR(currentBand),
        deviceName: nbAxisRow?.device_name || nbAxisRow?.deviceName,
        type: currentBand.fmin > 10000 ? 'mic' : 'accel',
        name: bandName,
        rawName
      };
      configNarrowbands.push(normalizedNarrowband);
    });
  });

  return configNarrowbands;
}
