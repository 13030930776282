/**
 * Reset Password Action Types
 * @author swapnil.kshirsagar@shorelineiot.com
 */
export const RESET_PASSWORD_PROGRESS = 'FEATURES/AUTH/LOGIN/RESET_PASSWORD_PROGRESS';
export const RESET_PASSWORD_SUCCESS = 'FEATURES/AUTH/LOGIN/RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'FEATURES/AUTH/LOGIN/RESET_PASSWORD_FAILURE';
export const VALIDATE_PASSWORD = 'FEATURES/AUTH/LOGIN/VALIDATE_PASSWORD';
export const VALIDATE_PASSWORD_SUCCESS = 'FEATURES/AUTH/LOGIN/VALIDATE_PASSWORD/SUCCESS';
export const VALIDATE_PASSWORD_FAILURE = 'FEATURES/AUTH/LOGIN/VALIDATE_PASSWORD/FAILURE';
export const ACCEPT_INVITE = 'FEATURES/AUTH/LOGIN/ACCEPT_INVITE';
export const ACCEPT_INVITE_SUCCESS = 'FEATURES/AUTH/LOGIN/ACCEPT_INVITE_SUCCESS';
export const ACCEPT_INVITE_FAILURE = 'FEATURES/AUTH/LOGIN/ACCEPT_INVITE_FAILURE';
export const RESET_ACCEPT_INVITE = 'FEATURES/AUTH/LOGIN/RESET_ACCEPT_INVITE';
export const MARK_ACCEPT_INVITE_IN_PROGRESS = 'FEATURES/AUTH/LOGIN/ACCEPT_INVITE/IN_PROGRESS';
export const ACCEPT_IN_PROGRESS_INVITES = 'FEATURES/AUTH/LOGIN/ACCEPT_IN_PROGRESS_INVITES';
