/**
 * Frequenty Asked Questions Page
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { ReactElement } from 'react';
import { Box } from '@mui/material';
import { UnauthenticatedHeader } from '../../common/header';
/**
 *
 * @param param0
 */
function FAQs(): ReactElement {
  return (
    <Box>
      <UnauthenticatedHeader />
      FAQs page Here
    </Box>
  );
}

export default FAQs;
