/**
 * Alerts combining reducer
 * @author shubham.holkar@shorelineiot.com
 */

import { combineReducers } from 'redux';
import {
  alertsList,
  alertsUpdate,
  createAlert,
  tagsList,
  sensorAlertDelete,
  getSensorAlert
} from './store/reducers';

const alertsReducer = combineReducers({
  alertsList,
  alertsUpdate,
  createAlert,
  tagsList,
  sensorAlertDelete,
  getSensorAlert
});

export default alertsReducer;
