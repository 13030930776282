/**
 * Content Pane Styles
 * @author mahesh.kedari@shorelineiot.com
 */
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((/* theme: Theme */) => ({
  content: {
    width: '100%',
    height: 'calc(100vh - 48px)',
    flexGrow: 1,
    overflow: 'auto',
    backgroundColor: '#f6f6f6',
    padding: 15
  }
}));
