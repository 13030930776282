/**
 * Chart Configuration helper for datapoints reducer
 * @author mahesh.kedari@shorelineiot.com
 */
import { DatapointsListState } from '../types/datapointsList.state';
import { ViewConfigState, VIEW_CONFIG_STATUS } from '../types/viewConfig';

function getViewConfig(state: DatapointsListState, dpid: string | number): ViewConfigState | null {
  const viewConfig = { ...state.viewConfigs[dpid] };
  if (viewConfig) {
    return viewConfig;
  }
  return null;
}

export function onFetchViewConfig(state: DatapointsListState, { dpid }: any): DatapointsListState {
  const selectedViewConfig: ViewConfigState | null = getViewConfig(state, dpid);
  if (selectedViewConfig !== null) {
    selectedViewConfig.status = VIEW_CONFIG_STATUS.FETCHING;
    return {
      ...state,
      viewConfigs: { ...state.viewConfigs, [dpid]: selectedViewConfig }
    };
  }
  return state;
}

export function onFetchViewConfigSuccess(
  state: DatapointsListState,
  { dpid, config }: any
): DatapointsListState {
  const selectedViewConfig: ViewConfigState | null = getViewConfig(state, dpid);
  if (selectedViewConfig !== null) {
    selectedViewConfig.status = VIEW_CONFIG_STATUS.FETCHING;
    selectedViewConfig.config = config;
    return {
      ...state,
      viewConfigs: { ...state.viewConfigs, [dpid]: selectedViewConfig }
    };
  }
  return state;
}

export function onFetchViewConfigFailure(
  state: DatapointsListState,
  { dpid, config }: any
): DatapointsListState {
  const selectedViewConfig: ViewConfigState | null = getViewConfig(state, dpid);
  if (selectedViewConfig !== null) {
    selectedViewConfig.status = VIEW_CONFIG_STATUS.FETCHING;
    selectedViewConfig.config = config;
    return {
      ...state,
      viewConfigs: { ...state.viewConfigs, [dpid]: selectedViewConfig }
    };
  }
  return state;
}
export function onResetViewConfig(
  state: DatapointsListState,
  { dpid, config }: any
): DatapointsListState {
  const selectedViewConfig: ViewConfigState | null = getViewConfig(state, dpid);
  if (selectedViewConfig !== null) {
    selectedViewConfig.status = VIEW_CONFIG_STATUS.READY;
    selectedViewConfig.config = config;
    return {
      ...state,
      viewConfigs: { ...state.viewConfigs, [dpid]: selectedViewConfig }
    };
  }
  return state;
}

export function onSetViewConfig(state: DatapointsListState, { dpid }: any): DatapointsListState {
  const selectedViewConfig: ViewConfigState | null = getViewConfig(state, dpid);
  if (selectedViewConfig !== null) {
    selectedViewConfig.status = VIEW_CONFIG_STATUS.FETCHING;
    return {
      ...state,
      viewConfigs: { ...state.viewConfigs, [dpid]: selectedViewConfig }
    };
  }
  return state;
}
export function onSetViewConfigSuccess(
  state: DatapointsListState,
  { dpid, config }: any
): DatapointsListState {
  const selectedViewConfig: ViewConfigState | null = getViewConfig(state, dpid);
  if (selectedViewConfig !== null) {
    selectedViewConfig.status = VIEW_CONFIG_STATUS.READY;
    selectedViewConfig.config = config;
    return {
      ...state,
      viewConfigs: { ...state.viewConfigs, [dpid]: selectedViewConfig }
    };
  }
  return state;
}

export function onSetViewConfigFailure(
  state: DatapointsListState,
  { dpid, config }: any
): DatapointsListState {
  const selectedViewConfig: ViewConfigState | null = getViewConfig(state, dpid);
  if (selectedViewConfig !== null) {
    selectedViewConfig.status = VIEW_CONFIG_STATUS.READY;
    selectedViewConfig.config = config;
    return {
      ...state,
      viewConfigs: { ...state.viewConfigs, [dpid]: selectedViewConfig }
    };
  }
  return state;
}
