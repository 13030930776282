/**
 * Column Sorting Editor
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { ReactElement } from 'react';
import SLCheckbox from '../../../../form/SLCheckbox';

interface Props {
  value: boolean;
  handleUpdate: (data: any) => void;
}

export default function ColumnSorting({ value, handleUpdate }: Props): ReactElement {
  const checkBoxChange = (value: boolean): void => {
    handleUpdate({
      sorting: value
    });
  };
  return (
    <SLCheckbox
      name="sorting"
      label="Allow Sorting"
      labelTranslationId="allow_sorting"
      value={value}
      checked={value}
      onChange={(value: boolean) => {
        checkBoxChange(value);
      }}
    />
  );
}
