/**
 * Battery View
 * @author mahesh.kedari@shorelineiot.com
 */
import { Box } from '@mui/material';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import { ChartViewProps } from '../common/ChartViewProps';
import BatteryIndicator from './BatteryIndicator';
/**
 *
 */
const SLBatteryView: React.FC<ChartViewProps> = React.forwardRef(
  ({ chartSettings, resultSet }: ChartViewProps, ref: any) => {
    const [level, setLevel] = useState<number>(0);
    const [size, setSize] = useState<{ width: number; height: number }>({
      width: 400,
      height: 400
    });

    useImperativeHandle(
      ref,
      () => ({
        resize(width: number, height: number) {
          setSize({ width: width - 70, height: height - 70 });
        }
      }),
      []
    );
    useEffect(() => {
      const data: number = resultSet?.series()[0]?.series[0]?.value;
      if (data) {
        if (data > 1) {
          // TODO: Normalize data based on min and max set in chart settings
          setLevel(1);
        } else if (data < 0) {
          // TODO: Normalize data based on min and max set in chart settings
          setLevel(0);
        } else {
          setLevel(data);
        }
      }
    }, [resultSet]);
    return (
      <Box m="5px">
        <BatteryIndicator level={level} {...chartSettings} {...size} />
      </Box>
    );
  }
);

export default SLBatteryView;
