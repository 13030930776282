/**
 * dashbaord items
 */
export enum CreateDashboardItemStatus {
  INIT,
  FETCHING,
  READY,
  ERROR,
  RESET
}
export interface CreateDashboardItemState {
  status: CreateDashboardItemStatus;
  createDashboardItemData: any;
}
