/**
 * Accordion Container Style
 * @author mahesh.kedari@shorelineiot.com
 */
import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontWeight: theme.typography.fontWeightLight,
      fontSize: theme.typography.body1.fontSize,
      '&:hover': {
        backgroundColor: 'transparent'
      }
    }
  })
);
