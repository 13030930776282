/**
 * Draggable Order Item
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { PropsWithChildren } from 'react';
import { FormControl, FormControlLabel, Radio, RadioGroup, Typography, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import DragIndicator from '@mui/icons-material/DragIndicatorOutlined';
import { Draggable } from 'react-beautiful-dnd';

interface Props {
  id: string;
  index: number;
  order: string;
  onOrderChange: (id: string, value: any) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    margin: '0 auto 0 8px',
    padding: '5px 0'
  },
  orderItem: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    columnGap: '5px',
    margin: '5px',
    border: `1px dashed`,
    borderRadius: 4,
    borderColor: theme.palette.grey[300]
  },
  fieldSet: {
    flexDirection: 'row'
  }
}));
export default function DraggableOrderItem({
  id,
  index,
  order = 'none',
  children,
  onOrderChange
}: PropsWithChildren<Props>) {
  const classes = useStyles();
  return (
    <Draggable draggableId={id} index={index}>
      {({ draggableProps, dragHandleProps, innerRef }) => (
        <div
          data-testid="order-item"
          ref={innerRef}
          {...draggableProps}
          {...dragHandleProps}
          className={classes.orderItem}>
          <DragIndicator fontSize="small" />
          <Typography className={classes.label}>{children}</Typography>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="query order"
              name="queryorder"
              value={order}
              onChange={(e) => onOrderChange(id, e.target.value)}
              className={classes.fieldSet}>
              <FormControlLabel value="asc" control={<Radio />} label="ASC" />
              <FormControlLabel value="desc" control={<Radio />} label="DESC" />
              <FormControlLabel value="none" control={<Radio />} label="NONE" />
            </RadioGroup>
          </FormControl>
        </div>
      )}
    </Draggable>
  );
}
