/**
 * Device Details Reducer
 * @author mahesh.kedari@shorelineiot.com
 */
import { combineReducers } from 'redux';

import { additionalSensor, createAdditionalSensor } from './additional-sensors';
import { narrowbandReducer as narrowband } from './additional-sensors/narrowband/narrowband.reducer';
import {
  datapointReducer as datapoints,
  sensorSettingsReducer as sensorSettings,
  historicalReducer as historicalData,
  historicalDataReducer
} from './device-settings';
import { componentClassListReducer as componentClassList } from './component-classes';
import { zoomData } from './zoom-ops';

/**
 *
 */
export const deviceDetailsReducer = combineReducers({
  datapoints,
  sensorSettings,
  additionalSensor,
  narrowband,
  historicalData,
  createAdditionalSensor,
  zoomData,
  historicalDataReducer,
  componentClassList
});
