/**
 * Export Device Data Action Types
 * @author vinayak.rathod@shorelineiot.com
 */
// Get export device data constants
export const FETCH_EXPORT_DEVICE_DATA_PROGRESS =
  'FEATURES/DEVICE/DEVICE_DATA/EXPORT_DEVICE_DATA/FETCH_EXPORT_DEVICE_DATA_PROGRESS';
export const FETCH_EXPORT_DEVICE_DATA_SUCCESS =
  'FEATURES/DEVICE/DEVICE_DATA/EXPORT_DEVICE_DATA/FETCH_EXPORT_DEVICE_DATA_SUCCESS';
export const FETCH_EXPORT_DEVICE_DATA_FAILURE =
  'FEATURES/DEVICE/DEVICE_DATA/EXPORT_DEVICE_DATA/FETCH_EXPORT_DEVICE_DATA_FAILURE';
export const FETCH_EXPORT_DEVICE_DATA_RESET =
  'FEATURES/DEVICE/DEVICE_DATA/EXPORT_DEVICE_DATA/FETCH_EXPORT_DEVICE_DATA_RESET';
export const PERFORM_DEVICE_DATA_FILE_DOWNLOAD =
  'FEATURES/DEVICE/DEVICE_DATA/EXPORT_DEVICE_DATA/PERFORM_DEVICE_DATA_FILE_DOWNLOAD';
export const EMAIL_CHANGE = 'FEATURES/DEVICE/DEVICE_DATA/EXPORT_DEVICE_DATA/EMAIL_CHANGE';
export const FETCH_USER_LIST_PROGRESS =
  'FEATURES/DEVICE/DEVICE_DATA/EXPORT_DEVICE_DATA/USERS_LIST/PROGRESS';
export const USERS_LIST_FOR_EXPORT_SUCCESS =
  'FEATURES/DEVICE/DEVICE_DATA/EXPORT_DEVICE_DATA/USERS_LIST/SUCCESS';
export const USERS_LIST_FOR_EXPORT_FAILURE =
  'FEATURES/DEVICE/DEVICE_DATA/EXPORT_DEVICE_DATA/USERS_LIST/FAILURE';

// Get events export constants
export const FETCH_EVENTS_PROGRESS =
  'FEATURES/DEVICE/DEVICE_DATA/EXPORT_DEVICE_DATA/EVENTS/FETCH_EVENTS_PROGRESS';
export const FETCH_EVENTS_SUCCESS =
  'FEATURES/DEVICE/DEVICE_DATA/EXPORT_DEVICE_DATA/EVENTS/FETCH_EVENTS_SUCCESS';
export const FETCH_EVENTS_FAILURE =
  'FEATURES/DEVICE/DEVICE_DATA/EXPORT_DEVICE_DATA/EVENTS/FETCH_EVENTS_FAILURE';
export const FETCH_EVENTS_RESET =
  'FEATURES/DEVICE/DEVICE_DATA/EXPORT_DEVICE_DATA/EVENTS/FETCH_EVENTS_RESET';
export const UPDATE_SELECTED_EVENTS =
  'FEATURES/DEVICE/DEVICE_DATA/EXPORT_DEVICE_DATA/EVENTS/UPDATE_SELECTED_EVENTS';
export const UPDATE_DATE_EVENTS_TOGGLE =
  'FEATURES/DEVICE/DEVICE_DATA/EXPORT_DEVICE_DATA/EVENTS/UPDATE_DATE_EVENTS_TOGGLE';
