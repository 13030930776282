/**
 * Narrowband Sensor Configuration
 * @author mahesh.kedari@shorelineiot.com
 */
export enum ManualNBConfigStatus {
  INIT,
  FETCHING,
  IN_PROGRESS,
  UPDATING,
  NO_CHANGE,
  READY,
  SUCCESS,
  ERROR
}
/**
 *
 */
export enum MANUAL_NB_FORM_STEPS {
  NARROWBAND_CONFIG,
  RULES_CONDITIONS,
  RULES_ACTIONS
}
/**
 *
 */
export interface ManualNBConfigState {
  dpid?: number | string;
  status: ManualNBConfigStatus;
  narrowbandConfig: any;
  ruleConditions: any;
  ruleActions: any;
  formStep: MANUAL_NB_FORM_STEPS;
  ruleObject: any;
}
