import React, { ReactElement } from 'react';
import { Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { Droppable } from 'react-beautiful-dnd';
import { PivotConfig } from '@cubejs-client/core';
import DraggableAxisItem from './DraggableAxisItem';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pivote: {
      display: 'flex',
      justifyContent: 'center',
      padding: '8px 16px',
      background: theme.palette.grey[200],
      borderBottom: '1px solid',
      borderBottomColor: theme.palette.grey[400],
      textTransform: 'uppercase'
    },
    droppableItem: {
      display: 'grid',
      gridTemplateColumns: 'minmax(0, 1fr)',
      gap: '8px',
      height: '100%',
      minHeight: '32px'
    }
  })
);
/**
 *
 */
interface Props {
  pivotConfig?: PivotConfig;
  axis: 'x' | 'y';
}
export default function DroppableAxisArea({ pivotConfig, axis }: Props): ReactElement<Props> {
  const classes = useStyles();
  if (pivotConfig) {
    return (
      <>
        <Typography className={classes.pivote}>{axis}</Typography>
        <div
          data-testid={`pivot-popover-${axis}`}
          style={{
            padding: '8px'
          }}>
          <Droppable droppableId={axis}>
            {(provided: any) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className={classes.droppableItem}>
                {pivotConfig?.[axis]?.map((id, index) => (
                  <DraggableAxisItem key={id} id={id} index={index} />
                ))}

                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </>
    );
  }
  return <></>;
}
