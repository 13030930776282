/**
 * YAxis Configurations
 * @author mahesh.kedari@shorelineiot.com
 */
import React from 'react';
import { Grid } from '@mui/material';
import { SLCheckbox, SLTextField } from '../../form';
/**
 *
 */
interface YAxisConfigsProps {
  path: string;
  currentValue: any;
  handleUpdate: () => void;
}
/**
 *
 */
const YAxisConfigEditor = React.memo(
  ({ path, currentValue, handleUpdate }: YAxisConfigsProps) => {
    return (
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <SLCheckbox
            name={`${path}.yAxis.hide`}
            label="Hide Y Axis"
            labelTranslationId="hide_y_axis"
            onChange={handleUpdate}
          />
        </Grid>
        <Grid item xs={4}>
          <SLCheckbox
            name={`${path}.yAxis.opposit`}
            label="Opposit Side"
            labelTranslationId="opposite_side"
            disabled={currentValue?.hide}
            onChange={handleUpdate}
          />
        </Grid>
        <Grid item xs={4}>
          <SLTextField
            name={`${path}.yAxis.tickInterval`}
            label="Tick Interval"
            labelTranslationId="tick_interval"
            type="number"
            disabled={currentValue?.hide}
            onBlur={handleUpdate}
          />
        </Grid>
        <Grid item xs={6}>
          <SLTextField
            name={`${path}.yAxis.title`}
            label="Y Axis Title"
            labelTranslationId="y_axis_title"
            disabled={currentValue?.hide}
            onBlur={handleUpdate}
          />
        </Grid>
        <Grid item xs={3}>
          <SLTextField
            name={`${path}.yAxis.min`}
            label="Min"
            labelTranslationId="min"
            type="number"
            disabled={currentValue?.hide}
            onBlur={handleUpdate}
          />
        </Grid>
        <Grid item xs={3}>
          <SLTextField
            name={`${path}.yAxis.max`}
            label="Max"
            labelTranslationId="max"
            type="number"
            disabled={currentValue?.hide}
            onBlur={handleUpdate}
          />
        </Grid>
      </Grid>
    );
  },
  (prevProps, nextProps) =>
    prevProps.currentValue === nextProps.currentValue && prevProps.path === nextProps.path
);
export default YAxisConfigEditor;
