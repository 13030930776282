/**
 * Colors
 * @author mahesh.kedari@shorelineiot.com
 */
export const Colors = {
  primary: '#2e639e',
  primaryVariant: '#21436c',
  secondary: '#00b1c1',
  secondaryVariant: '#006565',
  primaryText: '#202124',
  secondaryText: '#FFFFFF',
  disabledText: '#7D7F86',
  error: '#CE0000',
  warning: '#FFAB00',
  success: '#109505',
  background: '#FFFFFF',
  backgroundSecondary: '#F6F6F6',
  switchbtnColor: '#7691b2',
  iconGrey: '#cecece',
  primaryBackground: '#102f55',
  orgSubOrgHighlight: '#284c7a',
  sidebarButtonBorder: '#5a7fb4',
  shadowColor: 'black',
  dropdownBaseColor: '#49494c',
  borderGrey: '#c6c6c6',
  placeholder: '#626262',
  sidebarMenuItemSelectedBackground: '#ffffff4d',
  sidebarMenuItemSelectedHover: '#ffffff66',
  transparent: 'transparent',
  alarmAcknowledged: '#f28400',
  alarmInProgress: '#2367ec',
  disabledButton: '#b9b9b9'
};
