/**
 * Organisation Settings Saga
 * @author mahesh.kedari@shorelineiot.com
 */
import { all } from 'redux-saga/effects';
import watchUsers from './user-management/users.saga';
import watchPayments from './payments/payments.saga';
import watchSubscription from './subscriptions/subscriptions.saga';
import {
  watchOrgUpdate,
  watchPersonalizeSettingsSaga,
  watchUpdatePersonalizeSettingsSaga
} from './store';
import { watchDeletePersonalizeSettingsSaga } from './store/personalize-settings/deletePersonalizeSettings';
/**
 *
 */
export default function* watchOrgSettingsSaga() {
  yield all([
    watchOrgUpdate(),
    watchUsers(),
    watchPayments(),
    watchPersonalizeSettingsSaga(),
    watchUpdatePersonalizeSettingsSaga(),
    watchSubscription(),
    watchDeletePersonalizeSettingsSaga()
  ]);
}
