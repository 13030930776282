/**
 * Authentication Reducer
 * @author mahesh.kedari@shorelineiot.com
 */
import { combineReducers } from 'redux';
import { login, rbacPermission } from './login';
import { signup, verification } from './register/store';
import { configure } from './configure';
import { changePasswordReducer } from '../user-settings';
import forgotPasswordSubmit from './forgot-password/forgotPassword.reducer';
import { provider } from './store';

const authReducer = combineReducers({
  login,
  signup,
  provider,
  verification,
  rbacPermission,
  configure,
  forgotPasswordSubmit,
  changePasswordReducer
});

export default authReducer;
