/**
 * Option Menu template
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { MouseEvent, forwardRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMenuContext } from '../UserMenuContext';
/**
 *
 */
interface OptionMenuProps {
  action: (event: any) => void;
  children: any;
}
/**
 *
 * @param props
 */
const SLOptionMenu = forwardRef((props: OptionMenuProps) => {
  const { action, children } = props;
  const { handleCloseMenu } = useMenuContext();

  const onMenuClick = (event: MouseEvent) => {
    if (handleCloseMenu) handleCloseMenu();
    if (action) action(event);
  };
  return <MenuItem onClick={onMenuClick}>{children}</MenuItem>;
});

export default SLOptionMenu;
