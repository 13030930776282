/**
 * Organisation Menu List Component
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { PopoverPosition } from '@mui/material';
// import { CanAccess } from '@slcloud/shared/rbac';
// import { ORGANISATION } from '@slcloud/constants/permissions';
import { useLoginState, ACCEPT_INVITE_STATUS } from '../../auth/login';
import { useUser } from '../../auth/login/store';
import { acceptInviteActions } from '../../auth/login/store/actions';
import { useSlugContext } from '../../../framework';
import OrganisationMenu from './components/OrgMenu';
import OrgSelectorMenuButton from '../org-selector/OrgSelectorMenuButton';
import { orgListActions, useOrgList, ORG_LIST_STATUS } from '../store';

/**
 *
 */
function OrgMenuList() {
  const dispatch = useDispatch();
  const [menuPosition, setMenuPosition] = useState<PopoverPosition | undefined>();
  const orgListState = useOrgList();

  const loginState: any = useLoginState();
  const user = useUser();
  const { email, subOrgSlugs, role, acceptInvite } = user;
  const { slug } = useSlugContext();

  useEffect(() => {
    if (orgListState.status === ORG_LIST_STATUS.UNKNOWN) {
      /**
       * User has yet to accept the new invite as email is set  in
       * state.
       */
      if (!(loginState.status === 'LOGGED_IN' && email && acceptInvite)) {
        dispatch(orgListActions.fetchOrgList());
      }
    }
    if (orgListState?.status === ORG_LIST_STATUS.READY && orgListState?.orgList?.length) {
      // Sorting org list alphabetically.
      orgListState?.orgList?.sort((a, b) => a?.name?.localeCompare(b?.name));
      // Sorting sub-org list alphabetically.
      orgListState?.orgList?.forEach((element) => {
        element?.suborgs?.sort((a, b) => a?.name?.localeCompare(b?.name));
      });
    }
  }, [orgListState.status]);

  useEffect(() => {
    if (
      loginState.status === 'LOGGED_IN' &&
      loginState.acceptInviteStatus === ACCEPT_INVITE_STATUS.INIT &&
      email?.toLowerCase() === loginState?.session?.email?.toLowerCase() &&
      acceptInvite === true
    ) {
      // After new user has been logged in, then
      // dispatch 'acceptInvite' action. after entering the password if
      // invite is pending
      const data = {
        slug,
        subOrgSlugs: subOrgSlugs,
        role: role
      };
      dispatch(acceptInviteActions.acceptInvite(data));
    }
  }, [loginState.status]);
  return (
    <>
      <OrgSelectorMenuButton menuPosition={menuPosition} setMenuPosition={setMenuPosition} />

      {/* <CanAccess action={ORGANISATION.VIEW_LIST}> */}
      <OrganisationMenu
        orgList={orgListState.orgList}
        menuPosition={menuPosition}
        setMenuPosition={setMenuPosition}
      />
      {/* </CanAccess> */}
    </>
  );
}
export default OrgMenuList;
