import { call } from 'redux-saga/effects';
import { GenericObject, httpPost, httpPut, APISERVICES } from '../../../../../../../framework';
/**
 * Make a request to add all the narrowbands into current device's profile object in backend
 * @param sensorModule
 * @param slug
 */
export function submitNarrowband(sensorModule: any, slug: string, isEdit: boolean) {
  if (isEdit) {
    return httpPut(
      `orgs/${slug}/device_sensor_modules/multiple_modules`,
      sensorModule,
      APISERVICES.DEVICE_API
    );
  }
  return httpPost(
    `orgs/${slug}/device_sensor_modules/multiple_modules`,
    sensorModule,
    APISERVICES.DEVICE_API
  );
}
export function createUpdateNarrowband(
  payload: any,
  slug: string,
  isEdit: boolean,
  deviceId: string
) {
  if (isEdit) {
    return httpPut(
      `orgs/${slug}/devices/${deviceId}/nb_modules/auto`,
      payload,
      APISERVICES.DEVICE_API
    );
  }
  return httpPost(
    `orgs/${slug}/devices/${deviceId}/nb_modules/auto`,
    payload,
    APISERVICES.DEVICE_API
  );
}
export function updateNarrowbandManualConfig(sensorModule: any, slug: string, uuid: string) {
  return httpPut(
    `orgs/${slug}/device_sensor_modules/${uuid}`,
    sensorModule,
    APISERVICES.DEVICE_API
  );
}

/**
 * Make a request for Attaching Rule to device
 * @param slug
 * @param deviceId
 * @param ruleId
 */
function attachNarrowbandRule(slug: string, deviceId: string | number, ruleId: string) {
  return httpPost(
    `orgs/${slug}/device_rule_template/${ruleId}/rule_instances`,
    {
      is_enabled: true,
      device_ids: [deviceId]
    },
    APISERVICES.HOST_API
  );
}
/**
 * Make an API for creating Narrowband Rule
 */
function createNarrowbandRule(rule: any, slug: string) {
  return httpPost(`orgs/${slug}/device_rule_template`, rule, APISERVICES.HOST_API);
}
/**
 *
 * @param rule
 * @param slug
 * @param ruleUUID
 */
function updateNarrowbandRule(rule: any, slug: string, ruleUUID: string) {
  return httpPut(`orgs/${slug}/device_rule_template/${ruleUUID}`, rule, APISERVICES.DEVICE_API);
}
/**
 * Create New Rule. This function gets recursively called for every rule object in narrowbands list.
 * This is being called from a fork hence separate error handling should be added here.
 * @param rule Rule Object
 * @param slug Organisation Slug
 * @param deviceId Device ID
 */
export function* createRule(rule: any, slug: string, deviceId: string | number) {
  const createdRule: GenericObject = yield call(createNarrowbandRule, rule, slug);
  yield call(attachNarrowbandRule, slug, deviceId, createdRule.rule_template_uuid);
}

export function* updateRule(rule: any, slug: string, ruleUUID: string) {
  yield call(updateNarrowbandRule, rule, slug, ruleUUID);
}
