/**
 * Flexi-container Styles
 * @author mahesh.kedari@shorelineiot.com
 */
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import dragBackground from './drag-background.svg';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column'
  },

  dragBackground: {
    background: `url(${dragBackground})`,
    backgroundRepeat: 'repeat-y',
    backgroundPosition: '0px -4px',
    backgroundSize: '100% 52px'
  }
}));
