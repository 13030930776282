import { put, takeEvery, call } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import * as actions from './rolesDetailsActions';
import * as ACTION_TYPES from './rolesDetails.actionTypes';
import {
  GenericObject,
  httpGet,
  removeProgressFor,
  showProgressFor,
  APISERVICES
} from '../../../../../../framework';
import { SagaIterator } from 'redux-saga';

const ROLE_DETAILS_LIST = 'ROLE_DETAILS_LIST';

function getList(data: any) {
  return httpGet(`orgs/${data.slug}/roles/${data.roleId}`, null, APISERVICES.HOST_API);
}
function* RolesDetailsSaga(action: AnyAction) {
  yield put(showProgressFor(ROLE_DETAILS_LIST));
  try {
    const response: GenericObject = yield call(getList, action.payload);
    yield put(actions.fetchRolesDetailsSuccess(response));
  } catch (error: any) {
    yield put(actions.fetchRolesDetailsFailure());
  } finally {
    yield put(removeProgressFor(ROLE_DETAILS_LIST));
  }
}
/**
 *
 */
export default function* watchRolesDetails(): SagaIterator {
  yield takeEvery(ACTION_TYPES.FETCH_ROLES_DETAILS, RolesDetailsSaga);
}
