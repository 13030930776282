/**
 * Login State Type
 * @author mahesh.kedari@shorelineiot.com
 */
export enum ACCEPT_INVITE_STATUS {
  'INIT',
  'PASSWORD_VALIDATING',
  'PASSWORD_VALIDATED',
  'EXPIRED',
  'ACCEPTING',
  'ALREADY_ACCEPTED',
  'SUCCESS',
  'ERROR'
}
/**
 *
 */
export interface inviteUserDataType {
  email: string;
  subOrgSlugs?: string[];
  role?: string;
  acceptInvite?: boolean;
}
/**
 *
 */
export interface LoginStateType {
  status:
    | 'UNKNOWN'
    | 'CHECKING_CURRENT_SESSION'
    | 'NOT_LOGGED_IN'
    | 'LOGIN_IN_PROGRESS'
    | 'RESET_PASSWORD_REQUIRED'
    | 'VERIFICATION_MAIL_SENT'
    | 'FORGOT_PASSWORD_CODE_SENT'
    | 'LOGGED_IN'
    | 'LOGGED_OUT'
    | 'ERROR';
  // login: [];
  session: any;
  user: Partial<inviteUserDataType>;
  acceptInviteStatus: ACCEPT_INVITE_STATUS;
}
