import React, { ReactElement } from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import { useStyles } from './backdrop.styles';
import { useShowBackdrop } from './backdrop.selectorHooks';
/**
 *
 * @param param0
 */
export default function SLBackdrop(): ReactElement {
  const classes = useStyles();
  const showBackdrop = useShowBackdrop();
  return (
    <Backdrop className={classes.backdrop} open={showBackdrop}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
