/**
 * personalization State
 * @author ashwini.gawade@shorelineiot.com
 */

export enum PERSONALIZE_SETTINGS_STATUS {
  INIT,
  FETCHING,
  READY,
  ERROR,
  RESET
}
export interface PersonalizeSettingsState {
  status: PERSONALIZE_SETTINGS_STATUS;
  personalization_info?: any;
}
