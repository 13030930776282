/**
 * Divider with Label
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { ReactNode } from 'react';
import { Grid, Divider as MuiDivider, Grid2Props } from '@mui/material';

interface Props extends Grid2Props {
  textAlign?: 'center' | 'left' | 'right';
  action?: ReactNode;
}
const Divider = ({
  textAlign = 'center',
  children,
  alignItems = 'center',
  action,
  ...props
}: Props) => {
  return (
    <Grid container alignItems={alignItems} spacing={3} {...props}>
      {textAlign !== 'left' && (
        <Grid item xs>
          <MuiDivider />
        </Grid>
      )}
      <Grid item>{children}</Grid>
      {textAlign !== 'right' && (
        <Grid item xs>
          <MuiDivider />
        </Grid>
      )}
      {action}
    </Grid>
  );
};

export default Divider;
