/**
 * Current Battery Component.
 * @author aditya.bhadange@shorelineiot.com
 */

import React, { useImperativeHandle, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { renderCorrectBatteryIcon } from './battery.utils';
import WidgetDataUnavailable from '../widget-data-unavailable/WidgetDataUnavailable';
import { useStyles } from './batteryWidget.styles';
import TranslateText from '../../../../i18n/TranslateText';

interface BatteryData {
  /**
   * Number of battery percentage.
   */
  batt_remaining?: number;
  /**
   * Number of days remaining to replace the battery.
   */
  days_remaining?: number;
  message?: string;
}

export interface BatteryProps {
  data: BatteryData;
}

interface Size {
  width: number | undefined;
  height: number | undefined;
}

/**
 * Battery Widget Component for the dashboard.
 */
const SLBatteryWidget = React.forwardRef(({ data }: BatteryProps, ref: any) => {
  const classes = useStyles();
  const [size, setSize] = useState<Size>({
    width: undefined,
    height: undefined
  });

  useImperativeHandle(
    ref,
    () => ({
      resize(width: number, height: number) {
        setSize({
          width: width / 4,
          height: height / 2 + 50
        });
      }
    }),
    []
  );

  if (!data || (data && data?.message && data?.message === 'No data')) {
    return <WidgetDataUnavailable />;
  }

  const isNormalBatteryLevel = (batteryPercentage: any) => {
    if (batteryPercentage >= 0 && batteryPercentage <= 100) {
      return true;
    }
    return false;
  };

  return (
    <Box className={classes.containerize}>
      <Grid
        container
        spacing={2}
        justifyContent="flex-end"
        alignItems="flex-end"
        className={classes.autoMargin}>
        <Grid item xs={6} className={classes.autoMargin}>
          <Box textAlign="right">{renderCorrectBatteryIcon(data?.batt_remaining, size)}</Box>
        </Grid>
        <Grid
          container
          item
          xs={6}
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          className={classes.autoMargin}>
          <Grid item>
            {isNormalBatteryLevel(data?.batt_remaining) ? (
              <Box textAlign="left" fontWeight="bold">
                {data?.batt_remaining}%
              </Box>
            ) : (
              <span>{TranslateText('unknown_status', 'Unknown Status')}</span>
            )}
          </Grid>
          {isNormalBatteryLevel(data?.batt_remaining) && (
            <Grid item>
              <Box>
                <div>{TranslateText('replace_in', 'Replace in')}</div>
                <span>
                  {data?.days_remaining} {TranslateText('days', 'Days')}
                </span>
              </Box>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
});

export default SLBatteryWidget;
