import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: '0px',
    paddingBottom: '0px'
  },
  normalHeader: {
    flexGrow: 0
  },
  content: {
    marginTop: '0px',
    paddingTop: '0px'
  },
  pushToDown: {
    marginTop: '25px'
  },
  titleField: {
    width: '85%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'left',
    color: theme.palette.text.primary
  },
  normalContent: {
    flexGrow: 1,
    padding: 0,
    margin: 0
  },
  link: {
    textDecoration: 'none',
    '&:link, &:visited, &:hover, &:active': {
      color: theme.palette.text.primary
    }
  },
  viewDetails: {
    color: '#4eb8c7 !important',
    fontWeight: 'bold'
  },
  card: {
    '& .MuiCardHeader-content': {
      zIndex: 1
    }
  }
}));
