/**
 *
 */
export const CartesianChartsList = [
  {
    value: 'line',
    label: 'Line'
  },
  {
    value: 'area',
    label: 'Area'
  },
  {
    value: 'column',
    label: 'Column'
  },
  /* {
    value: 'scatter',
    label: 'Scatter',
  },
  {
    value: 'cylinder',
    label: 'Cylinder',
  }, */
  {
    value: 'spline',
    label: 'Smooth Line'
  }
];
