/**
 * @author vinayak.rathod@shorelineiot.com
 */

import { AnyAction } from 'redux';
import * as ACTIONS from './exportDeviceData.actionTypes';
import {
  ExportDeviceDataFromWebsocketResponse,
  ExportDeviceDataState
} from './exportDeviceDataType';

const initialState: ExportDeviceDataState = {
  isLoading: false,
  isSuccess: false,
  isExportSuccess: false,
  isFailure: false,
  error: [],
  exportDataResponse: {},
  eventList: [],
  selectedEventList: [],
  email: '',
  dateOrEvent: 'date',
  userList: [],
  emailList: [],
  shouldDownloadFile: false
};

/**
 *
 * @param state old state
 */
function onFetchExportDeviceDataProgress(state: ExportDeviceDataState): ExportDeviceDataState {
  return {
    ...state,
    isLoading: true,
    shouldDownloadFile: true
  };
}
/**
 *
 * @param state Old State
 * @param deviceData
 */
function onFetchExportDeviceDataSuccess(
  state: ExportDeviceDataState,
  action: any
): ExportDeviceDataState {
  return {
    ...state,
    isLoading: false,
    isExportSuccess: true,
    exportDataResponse: action.payload
  };
}

/**
 *
 * @param state Old State
 * @param error Error Object
 */
function onFetchExportDeviceDataFailure(
  state: ExportDeviceDataState,
  action: AnyAction
): ExportDeviceDataState {
  return {
    ...state,
    isLoading: false,
    isExportSuccess: false,
    isFailure: true,
    error: action.payload
  };
}

/**
 *
 * @param state Old State
 * @param error Error Object
 */
function exportDeviceDataReset(
  state: ExportDeviceDataState,
  emailList: Array<string>
): ExportDeviceDataState {
  return {
    ...state,
    isLoading: false,
    isFailure: false,
    isSuccess: false,
    isExportSuccess: false,
    error: [],
    emailList
  };
}

/**
 *
 * @param state old state
 */
function onFetchEventsProgress(state: ExportDeviceDataState): ExportDeviceDataState {
  return {
    ...state,
    isLoading: true,
    dateOrEvent: 'event',
    eventList: []
  };
}
/**
 *
 * @param state Old State
 * @param deviceData
 */
function onFetchEventsSuccess(state: ExportDeviceDataState, action: any): ExportDeviceDataState {
  return {
    ...state,
    isLoading: false,
    isSuccess: true,
    eventList: action.payload
  };
}

function updateSelectedEvents(state: ExportDeviceDataState, action: any): ExportDeviceDataState {
  return {
    ...state,
    isLoading: false,
    selectedEventList: action.payload
  };
}

function onEmailChange(state: ExportDeviceDataState, action: any): ExportDeviceDataState {
  return {
    ...state,
    isLoading: false,
    emailList: action.payload
  };
}

function onDateEventToggle(state: ExportDeviceDataState, action: any): ExportDeviceDataState {
  return {
    ...state,
    isLoading: false,
    dateOrEvent: action.payload
  };
}

/**
 *
 * @param state Old State
 * @param error Error Object
 */
function onFetchEventsFailure(
  state: ExportDeviceDataState,
  action: AnyAction
): ExportDeviceDataState {
  return {
    ...state,
    isLoading: false,
    isFailure: true,
    isSuccess: false,
    eventList: [],
    error: action.payload
  };
}

/**
 *
 * @param state Old State
 * @param error Error Object
 */
function exportEventsReset(state: ExportDeviceDataState): ExportDeviceDataState {
  return {
    ...state,
    isLoading: false,
    isFailure: false,
    isSuccess: false,
    error: [],
    eventList: [],
    dateOrEvent: 'date'
  };
}

/**
 *
 * @param state old state
 */
function onFetchUsersListProgress(state: ExportDeviceDataState): ExportDeviceDataState {
  return {
    ...state,
    isLoading: true
  };
}
/**
 *
 * @param state Old State
 * @param deviceData
 */
function onFetchUsersListSuccess(state: ExportDeviceDataState, action: any): ExportDeviceDataState {
  return {
    ...state,
    isLoading: false,
    isSuccess: true,
    userList: action.data
  };
}

/**
 *
 * @param state Old State
 * @param error Error Object
 */
function onFetchUsersListFailure(
  state: ExportDeviceDataState,
  action: AnyAction
): ExportDeviceDataState {
  return {
    ...state,
    isLoading: false,
    isFailure: true,
    error: action.payload
  };
}

function onPerformDeviceDataFileDownload(
  state: ExportDeviceDataState,
  data: ExportDeviceDataFromWebsocketResponse
): ExportDeviceDataState {
  if (state.shouldDownloadFile && data.action === 'create') {
    const link = document.createElement('a');
    link.href = data.payload.download_link;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    return {
      ...state,
      shouldDownloadFile: false
    };
  }

  return state;
}

/**
 *
 * @param state Old State
 * @param action Action to be performed
 */

export default function exportDeviceDataReducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case ACTIONS.FETCH_EXPORT_DEVICE_DATA_PROGRESS:
      return onFetchExportDeviceDataProgress(state);
    case ACTIONS.FETCH_EXPORT_DEVICE_DATA_SUCCESS:
      return onFetchExportDeviceDataSuccess(state, action);
    case ACTIONS.FETCH_EXPORT_DEVICE_DATA_FAILURE:
      return onFetchExportDeviceDataFailure(state, action);
    case ACTIONS.FETCH_EXPORT_DEVICE_DATA_RESET:
      return exportDeviceDataReset(state, action.payload);
    case ACTIONS.EMAIL_CHANGE:
      return onEmailChange(state, action);
    case ACTIONS.FETCH_USER_LIST_PROGRESS:
      return onFetchUsersListProgress(state);
    case ACTIONS.USERS_LIST_FOR_EXPORT_SUCCESS:
      return onFetchUsersListSuccess(state, action);
    case ACTIONS.USERS_LIST_FOR_EXPORT_FAILURE:
      return onFetchUsersListFailure(state, action);
    case ACTIONS.PERFORM_DEVICE_DATA_FILE_DOWNLOAD:
      return onPerformDeviceDataFileDownload(state, action.payload);

    case ACTIONS.FETCH_EVENTS_PROGRESS:
      return onFetchEventsProgress(state);
    case ACTIONS.FETCH_EVENTS_SUCCESS:
      return onFetchEventsSuccess(state, action);
    case ACTIONS.FETCH_EVENTS_FAILURE:
      return onFetchEventsFailure(state, action);
    case ACTIONS.FETCH_EVENTS_RESET:
      return exportEventsReset(state);
    case ACTIONS.UPDATE_SELECTED_EVENTS:
      return updateSelectedEvents(state, action);
    case ACTIONS.UPDATE_DATE_EVENTS_TOGGLE:
      return onDateEventToggle(state, action);
    default:
      return state;
  }
}
