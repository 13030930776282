import { AnyAction } from 'redux';
import * as ACTION_TYPE from './actions/modbus.actionTypes';
import { UpdateModbusState } from './types/editModbusState';
import { UPDATE_MODBUS_STATUS } from './types/editModbusStatus';

const initialState: UpdateModbusState = {
  status: UPDATE_MODBUS_STATUS.INIT
};

function updateModbusProgress(state: UpdateModbusState): UpdateModbusState {
  return {
    ...state,
    status: UPDATE_MODBUS_STATUS.PROGRESS
  };
}

function updateModbusSuccess(state: UpdateModbusState, action: AnyAction): UpdateModbusState {
  return {
    ...state,
    status: UPDATE_MODBUS_STATUS.READY,
    modbusDetails: action.data
  };
}

function updateModbusFailure(state: UpdateModbusState): UpdateModbusState {
  return {
    ...state,
    status: UPDATE_MODBUS_STATUS.ERROR
  };
}

function resetModbusUpdate() {
  return {
    ...initialState
  };
}
/**
 *
 * @param state
 * @param action
 */
export default function updateModbusReducer(
  state: UpdateModbusState = initialState,
  action: AnyAction
): UpdateModbusState {
  switch (action.type) {
    case ACTION_TYPE.UPDATE_MODBUS_PROGRESS:
      return updateModbusProgress(state);
    case ACTION_TYPE.UPDATE_MODBUS_SUCCESS:
      return updateModbusSuccess(state, action);
    case ACTION_TYPE.UPDATE_MODBUS_FAILURE:
      return updateModbusFailure(state);
    case ACTION_TYPE.UPDATE_MODBUS_RESET:
      return resetModbusUpdate();
    default:
      return state;
  }
}
