/**
 * Toast Messages Actions
 * @author mahesh.kedari@shorelineiot.com
 */
import { AnyAction } from 'redux';
import { SnackbarKey } from 'notistack';
import { ReactNode } from 'react';
import * as ACTIONS from './toast.actionTypes';

export type ToastMessageType = 'success' | 'warning' | 'error' | 'info';

/**
 *
 * @param message message to be dislpayed in Toast
 * @param type
 * @param customOptions
 */
export const showToast = (
  message: ReactNode,
  type: ToastMessageType,
  persist?: boolean,
  customOptions?: any
) => {
  const key = new Date().getTime() + Math.random();
  const options = customOptions || {
    key,
    variant: type,
    persist
  };
  return {
    type: ACTIONS.SHOW_TOAST,
    notification: {
      key,
      message,
      options
    }
  };
};
/**
 *
 * @param key
 */
export const closeToast = (key?: SnackbarKey): AnyAction => ({
  type: ACTIONS.CLOSE_TOAST,
  dismissAll: !key, // dismiss all if no key has been defined
  key
});
/**
 *
 */
export const closeAllToasts = (): AnyAction => ({
  type: ACTIONS.CLOSE_TOAST,
  dismissAll: true
});
/**
 *
 * @param key
 */
export const removeToast = (key: SnackbarKey) => ({
  type: ACTIONS.REMOVE_TOAST,
  key
});
