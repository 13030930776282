/**
 * Forgot password
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { ReactElement } from 'react';
import { ButtonProps, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { SLLinkButton, showProgressFor } from '../../../../framework';
import { useStyles } from '../loginPage.style';
import { loginActions } from '../store/actions';
import TranslateText from '../../../../i18n/TranslateText';

interface Props extends ButtonProps {
  email: string;
}

export default function ForgotPasswordLink(props: Props): ReactElement {
  const dispatch = useDispatch();
  const classes = useStyles();
  function handleForgotPassword() {
    dispatch(showProgressFor('SEND_FORGOT_PWD_LINK'));
    dispatch(loginActions.forgotPasswordCode(props.email));
  }
  return (
    <SLLinkButton onClick={handleForgotPassword} {...props} className={classes.forgetButtonFont}>
      <Typography variant="body1">{TranslateText('forgot_password', 'Forgot Password')}</Typography>
    </SLLinkButton>
  );
}
