export enum deleteNarrowbandStatus {
  INIT,
  PROGRESS,
  SUCCESS,
  ERROR
}

export interface deleteNarrowbandState {
  status: deleteNarrowbandStatus;
}
