/**
 * Component List Actions
 * @author mahesh.kedari@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTIONS from './componentApplyToDevices.actionTypes';

export const fetchComponentAttachList = (payload: any): AnyAction => ({
  type: ACTIONS.FETCH_COMPONENT_ATTACH_LIST,
  payload
});

export const fetchComponentAttachListSuccess = (payload: any): AnyAction => ({
  type: ACTIONS.FETCH_COMPONENT_ATTACH_LIST_SUCCESS,
  payload
});

export const fetchComponentAttachListFailure = (error: any) => ({
  type: ACTIONS.FETCH_COMPONENT_ATTACH_LIST_FAILURE,
  error
});

export const resetComponentAttachList = (): AnyAction => ({
  type: ACTIONS.RESET_COMPONENT_ATTACH_LIST
});

export const updateComponentAttachList = (componentId: any, payload: any) => ({
  type: ACTIONS.UPDATE_COMPONENT_ATTACH_LIST,
  payload: {
    component_id: componentId,
    payload
  }
});
/**
 *
 * @param payload
 * @returns
 */
export const updateComponentAttachListSuccess = (payload: any) => ({
  type: ACTIONS.UPDATE_COMPONENT_ATTACH_LIST_SUCCESS,
  payload
});
/**
 *
 * @param payload
 * @returns
 */
export const updateComponentAttachListFailure = (error: any) => ({
  type: ACTIONS.UPDATE_COMPONENT_ATTACH_LIST_FAILURE,
  error
});
