/**
 * Pie Chart Common Editor
 * @author mahesh.kedari@shorelineiot.com
 */
import { Grid } from '@mui/material';
import React from 'react';
import { SLCheckbox, SLTextField } from '../../..';
import { SLSelect } from '../../../..';
import WidgetSettingAccordion from '../../common/WidgetAccordion';

interface Props {
  currentSettings: any;
  handleUpdate: (data: any) => void;
}

export const PieChartCommonEditor = ({ handleUpdate, currentSettings }: Props) => {
  const ValueUnits = [
    {
      value: 'PERCENTAGE',
      label: 'Percentage'
    },
    {
      value: 'ABSOLUTE',
      label: 'Absolute'
    }
  ];

  return (
    <WidgetSettingAccordion title="common">
      <Grid container justifyContent="flex-start" spacing={2} alignItems="flex-end">
        <Grid item xs={6}>
          <SLTextField
            name="title"
            label="Title"
            labelTranslationId="title"
            onBlur={handleUpdate}
          />
        </Grid>
        <Grid item xs={6}>
          <SLTextField
            name="innerSize"
            type="number"
            label="Inner Size"
            labelTranslationId="inner_size"
            onBlur={handleUpdate}
          />
        </Grid>
        <Grid item xs={6}>
          <SLCheckbox
            name="legands"
            label="Show legends"
            labelTranslationId="show_legends"
            onChange={handleUpdate}
            checked={currentSettings?.legands || false}
          />
        </Grid>
        <Grid item xs={6}>
          <SLCheckbox
            name="enable3d"
            label="Enable 3D"
            labelTranslationId="enable_3d"
            onChange={handleUpdate}
            checked={currentSettings?.enable3d || false}
          />
        </Grid>
        <Grid item xs={6}>
          <SLSelect
            name="dataLabelType"
            options={ValueUnits}
            label="Data Label Value Unit"
            labelTranslationId="data_label_value_unit"
            onSelectChange={handleUpdate}
          />
        </Grid>
      </Grid>
    </WidgetSettingAccordion>
  );
};
