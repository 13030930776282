/**
 * Component LIST Action Types
 * @author mahesh.kedari@shorelineiot.com
 */

export const FETCH_COMPONENT_LIST = 'FEATURES/COMPONENT_COMPILER/COMPONENT_LIST/FETCH';
export const FETCH_COMPONENT_LIST_SUCCESS =
  'FEATURES/COMPONENT_COMPILER/COMPONENT_LIST/FETCH/SUCCESS';
export const FETCH_COMPONENT_LIST_FAILURE =
  'FEATURES/COMPONENT_COMPILER/COMPONENT_LIST/FETCH/FAILURE';
export const RESET_COMPONENT_LIST = 'FEATURES/COMPONENT_COMPILER/COMPONENT_LIST/RESET';

export const DELETE_COMPONENT = 'FEATURES/COMPONENT_COMPILER/COMPONENT_LIST/DELETE';
export const DELETE_COMPONENT_SUCCESS = 'FEATURES/COMPONENT_COMPILER/COMPONENT_LIST/DELETE/SUCCESS';
export const DELETE_COMPONENT_FAILURE = 'FEATURES/COMPONENT_COMPILER/COMPONENT_LIST/DELETE/FAILURE';
