/**
 * Framwork's top level container.
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { ReactElement } from 'react';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter } from 'react-router-dom';
import { SLProgressBar } from '../progress-bar';
import { SLToast, SLToastTemplate } from '../toast';
import SLBackdrop from '../backdrop/Backdrop';

/**
 *
 */
interface Props {
  maxSnack?: number;
  children: ReactElement;
}
/**
 *
 */
export default function FrameworkContainer({ maxSnack = 4, children }: Props): ReactElement {
  return (
    <>
      <SLBackdrop />
      <BrowserRouter>
        <SLProgressBar />
        <SnackbarProvider
          maxSnack={maxSnack}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          hideIconVariant
          content={(key: any, message: any, ...otherProps: any[]) => (
            <SLToastTemplate id={key} message={message} {...otherProps} />
          )}>
          <>
            <SLToast />
            {children}
          </>
        </SnackbarProvider>
      </BrowserRouter>
    </>
  );
}
