/**
 * Create sub organization actions
 * @author ashwini.gawade@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTIONS from './subOrg.actionType';
import { Organisation } from '../../../types/Organisation';
import { CreateSubOrg } from '../../../types/subOrg';
/**
 *
 * @param org object from components
 * @param dispatch
 * @summary method used to call httPost() request
 */
export const createSubOrg = (subOrg: CreateSubOrg): AnyAction => {
  return {
    type: ACTIONS.SUB_ORG_CREATE,
    payload: subOrg
  };
};
/**
 *
 */
export const createSubOrgSuccess = (): AnyAction => {
  return {
    type: ACTIONS.SUB_ORG_SUCCESS
  };
};
/**
 *
 */
export const createSubOrgFailure = (error: any): AnyAction => {
  return {
    type: ACTIONS.SUB_ORG_FAILURE,
    error
  };
};
/**
 *
 */
export const initSubOrg = (org: Organisation): AnyAction => {
  return {
    type: ACTIONS.SUB_ORG_INIT,
    payload: org
  };
};
/**
 *
 */
export const resetSubOrg = (): AnyAction => {
  return {
    type: ACTIONS.SUB_ORG_RESET
  };
};
