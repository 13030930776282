/**
 * Org Update Status
 * @author mahesh.kedari@shorelineiot.com
 */
export enum ORG_UPDATE_STATUS {
  INIT,
  FETCHING_ORG,
  UPDATING_ORG,
  READY,
  ERROR
}
