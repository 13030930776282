/**
 * Decimal Precision
 * @param value
 * @param precision
 * @returns
 */
export const decimalPrecision = (value: number, precision: number, defaultValue = '-') => {
  if (value && !Number.isNaN(value)) {
    const valueToBeFixed = Number(value);
    return valueToBeFixed.toFixed(precision);
  }
  return defaultValue;
};

/**
 * Trim the number to the specified number of decimal places
 * @param number Number to trim
 * @param trimUpto number to trim upto given number
 * @returns trimed number
 */

export function trimDecimals(number: number, trimUpto = 4) {
  // Get the number of decimal places in the input number
  const decimalPlaces = (number?.toString().split('.')[1] || '').length;

  // Check if the number of decimal places exceeds the trimUpto value
  if (decimalPlaces > trimUpto) {
    return parseFloat(number?.toFixed(trimUpto));
  } else {
    return number;
  }
}
