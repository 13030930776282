/**
 * Backdrop Reducer
 * @author mahesh.kedari@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTIONS from './backdrop.actionTypes';

const initialState = false;
/**
 *
 * @param state
 * @param action
 */
export function BackdropReducer(state: boolean = initialState, action: AnyAction): boolean {
  switch (action.type) {
    case ACTIONS.SHOW_BACKDROP:
      return true;
    case ACTIONS.HIDE_BACKDROP:
      return false;
    default:
      return state;
  }
}
