/**
 *
 */
import { AnyAction } from 'redux';
import * as ACTIONS from './expr-eval.actionTypes';
/**
 *
 * @returns
 */

export function getExpressionEvalResult(scope: any, expression: any): AnyAction {
  return {
    type: ACTIONS.ANOMALY_EXPR_EVAL_IN_PROGRESS,
    payload: {
      scope,
      expression
    }
  };
}

export function getExpressionEvalResultSuccess(response: any): AnyAction {
  return {
    type: ACTIONS.ANOMALY_EXPR_EVAL_SUCCESS,
    payload: response
  };
}

export function getExpressionEvalResultFailure(error: any): AnyAction {
  return {
    type: ACTIONS.ANOMALY_EXPR_EVAL_FAILURE,
    payload: error
  };
}

export function getExpressionEvalResultReset(): AnyAction {
  return {
    type: ACTIONS.ANOMALY_EXPR_EVAL_RESET
  };
}
