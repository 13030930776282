/**
 * SL Password Field component
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { ReactElement, useEffect, useState } from 'react';
import { FormControl, IconButton, TextField } from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import SLInputAdornment from './SLInputAdornment';
import { useStyles } from './common/SLForms.styles';
import TranslateText from '../../../i18n/TranslateText';
/**
 *
 */
type Props = {
  name: string;
  label: string;
  rules?: Object | Function;
  value?: any;
  canView?: boolean;
  onBlur?: ((value: any, fn: Function) => void) | undefined;
  autoFocus?: boolean;
  [key: string]: any;
  translationId?: string;
};
/**
 *
 */
export function ShowPasswordIcon(props: any) {
  const { passwordVisiblity, setPasswordVisibility } = props;
  const onToggleVisiblity = () => {
    setPasswordVisibility(!passwordVisiblity);
  };
  return (
    <SLInputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={onToggleVisiblity}
        color="default"
        tabIndex={-1}
        /* onMouseDown={handleMouseDownPassword} */
        size="large">
        {passwordVisiblity ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </SLInputAdornment>
  );
}

export default function SLPasswordField({
  name,
  label,
  rules,
  onBlur,
  value,
  canView = true,
  autoFocus = false,
  translationId,
  ...otherProps
}: Props): ReactElement {
  const {
    control,
    formState: { errors },
    watch,
    setValue,
    getValues
  } = useFormContext();
  const [passwordVisibility, setPasswordVisiblity] = useState(false);
  const classes = useStyles();

  const handleOnBlur = () => {
    if (onBlur) {
      onBlur(getValues(), setValue);
    }
  };

  useEffect(() => {
    if (value) {
      setValue(name, value);
    }
  }, [value]);

  // SLC-605 Prevent paste operation on retype password field
  const preventPaste = (event: React.DragEvent | React.ClipboardEvent) => {
    if (!canView) {
      event.preventDefault();
      return false;
    }
    return true;
  };

  return (
    <FormControl error={Boolean(errors[name])} className={classes.root}>
      <Controller
        name={name}
        control={control}
        rules={typeof rules === 'function' ? rules(watch) : rules}
        render={({ field: { onChange, onBlur, ref }, fieldState: { error } }) => (
          <>
            <TextField
              id={name}
              name={name}
              label={translationId ? TranslateText(translationId) : label}
              inputRef={ref}
              variant="standard"
              fullWidth
              error={!!error}
              helperText={error ? error?.message : null}
              type={passwordVisibility ? 'text' : 'password'}
              autoComplete="on"
              InputProps={{
                endAdornment: canView ? (
                  <ShowPasswordIcon
                    passwordVisiblity={passwordVisibility}
                    setPasswordVisibility={setPasswordVisiblity}
                  />
                ) : null
              }}
              onDrop={preventPaste}
              onPaste={preventPaste}
              onChange={onChange}
              onBlur={() => {
                onBlur();
                handleOnBlur();
              }}
              autoFocus={autoFocus}
              {...otherProps}
            />
          </>
        )}
      />
    </FormControl>
  );
}
