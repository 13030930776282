/**
 * Pie Chart View
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highcharts3d from 'highcharts/highcharts-3d';
import merge from 'lodash/merge';
import { Box, Typography } from '@mui/material';
import { ChartViewProps } from '../common/ChartViewProps';
import { pieChartConfig as defaultPieChartSettings } from './pieChartSettings';

highcharts3d(Highcharts);

/**
 *
 * @param param0
 * @returns
 */
const SLPieChartView: React.FC<ChartViewProps> = React.forwardRef(
  ({ chartSettings, resultSet }: ChartViewProps, ref: any) => {
    const newDataSpecificSettings: any = {};
    const unwantedKeysObject = {
      title: '',
      innerSize: '',
      legands: true,
      enable3d: false,
      dataLabelType: 'PERCENTAGE',
      chartType: 'donut',
      data_label_value_unit: '',
      donutTitle: '',
      size: '',
      dataSettings: {}
    };
    if (!chartSettings?.dataSetting) {
      // Get only data-specific settings from the existingData object.
      Object.entries(chartSettings)?.forEach(([key, value]: [any, any]) => {
        const unwantedItem = Object.entries(unwantedKeysObject)?.find(
          ([formDefaultKey]) => formDefaultKey === key
        );
        if (!unwantedItem) {
          newDataSpecificSettings[key] = value;
        }
      });
    }

    const [pieChartSettings, setPieChartSettings] = useState(
      merge(defaultPieChartSettings(), chartSettings)
    );
    useEffect(() => {
      const dataArray: Array<any> = resultSet?.series()[0]?.series;
      const total = dataArray?.reduce(
        (accumulator, currentValue) => accumulator + currentValue.value,
        0
      );
      const yAxisArray = dataArray?.map((item: any) => {
        const percentageValue = (item.value / total) * 100;
        const categorySettings = Object.entries(newDataSpecificSettings)?.length
          ? newDataSpecificSettings?.[item?.category]
          : chartSettings?.dataSettings?.[item?.category];
        return {
          name: categorySettings?.label || item.category,
          y: item.value,
          dataLabels: {
            enabled: true,
            distance: percentageValue >= 10 ? -30 : 5,
            style: {
              width: '80px',
              textOverflow: 'ellipsis'
            }
          },
          color: categorySettings?.color?.hex || categorySettings?.color
        };
      });
      const xAxisArray = dataArray?.map((item: any) => {
        return item.x;
      });
      setPieChartSettings({
        ...pieChartSettings,
        chart: {
          options3d: {
            enabled: chartSettings.enable3d,
            alpha: 45
          }
        },
        xAxis: {
          categories: xAxisArray
        },
        title: {
          text: chartSettings?.title,
          verticalAlign: 'middle',
          align: 'center',
          y: 0,
          style: {
            fontSize: '15px',
            fontWeight: 'bold'
          }
        },
        plotOptions: {
          pie: {
            innerSize: chartSettings.innerSize ? `${chartSettings.innerSize}%` : '0',
            depth: 35,
            showInLegend: chartSettings.legands,
            dataLabels: {
              ...pieChartSettings.plotOptions.pie.dataLabels,
              format: `<br>{point.name}</br>:<br>{point.${
                chartSettings.dataLabelType === 'ABSOLUTE' ? 'y}</br>' : 'percentage:.1f} %</br>'
              }`
            }
          }
        },
        series: [{ data: yAxisArray, type: 'pie' }]
      });
    }, [resultSet, chartSettings]);

    if (pieChartSettings?.series[0]?.data) {
      /**
       * Pie chart requires 'series.data' as a type of Number to work correctly,
       * but sometimes due to inappropriate data selection, app is getting crashed
       * because it's getting data as a string. To avoid the crash, added this check.
       * For more info: https://assets.highcharts.com/errors/14/
       */
      if (typeof pieChartSettings?.series[0]?.data[0]?.y === 'string') {
        return (
          <Box mt={2} ml={2}>
            <Typography>Inappropriate data, please change measures/dimensions</Typography>
          </Box>
        );
      }
    }

    return <HighchartsReact highcharts={Highcharts} options={pieChartSettings} ref={ref} />;
  }
);

export default SLPieChartView;
