import { AnyAction } from 'redux';
import * as ACTION_TYPE from './deleteDeviceMailbox.actionTypes';
import { deleteDeviceMailboxState, deleteDeviceMailboxStatus } from './deleteDeviceMailbox.state';

const initialState: deleteDeviceMailboxState = {
  status: deleteDeviceMailboxStatus.INIT
};

function deleteDeviceMailboxProgress(state: deleteDeviceMailboxState): deleteDeviceMailboxState {
  return {
    ...state,
    status: deleteDeviceMailboxStatus.PROGRESS
  };
}
function deleteDeviceMailboxSuccess(state: deleteDeviceMailboxState): deleteDeviceMailboxState {
  return {
    ...state,
    status: deleteDeviceMailboxStatus.SUCCESS
  };
}

function deleteDeviceMailboxFailure(state: deleteDeviceMailboxState): deleteDeviceMailboxState {
  return {
    ...state,
    status: deleteDeviceMailboxStatus.ERROR
  };
}

function deleteDeviceMailboxReset(state: deleteDeviceMailboxState): deleteDeviceMailboxState {
  return {
    ...state,
    status: deleteDeviceMailboxStatus.INIT
  };
}

export default function deleteDeviceMailboxReducer(
  state: deleteDeviceMailboxState = initialState,
  action: AnyAction
): deleteDeviceMailboxState {
  switch (action.type) {
    case ACTION_TYPE.DELETE_DEVICE_MAILBOX_PROGRESS:
      return deleteDeviceMailboxProgress(state);
    case ACTION_TYPE.DELETE_DEVICE_MAILBOX_SUCCESS:
      return deleteDeviceMailboxSuccess(state);
    case ACTION_TYPE.DELETE_DEVICE_MAILBOX_FAILURE:
      return deleteDeviceMailboxFailure(state);
    case ACTION_TYPE.DELETE_DEVICE_MAILBOX_RESET_STATE:
      return deleteDeviceMailboxReset(state);

    default:
      return state;
  }
}
