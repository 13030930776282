/**
 * @author ashwini.gawade@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTIONS from './actions/orgList.actionTypes';
import { Organisation } from '../types/Organisation';
import { OrgListState, ORG_LIST_STATUS } from '../types/OrgListState';

const initialState: OrgListState = {
  status: ORG_LIST_STATUS.UNKNOWN,
  orgList: []
};
/**
 *
 * @param state old state
 */
function onFetchOrgList(state: OrgListState): OrgListState {
  return {
    ...state,
    status: ORG_LIST_STATUS.FETCHING_LIST
  };
}
/**
 *
 * @param state Old State
 * @param orgList Organisation List
 */
function onFetchOrgListSuccess(state: OrgListState, orgList: Array<Organisation>): OrgListState {
  return {
    ...state,
    status: ORG_LIST_STATUS.READY,
    orgList
  };
}
/**
 *
 * @param state Old State
 * @param error Error Object
 */
function onFetchOrgListFailure(state: OrgListState): OrgListState {
  return {
    ...state,
    status: ORG_LIST_STATUS.ERROR
  };
}
/**
 *
 * @param state Old State
 * @param action Action to be performed
 */
export default function orgListReducer(
  state: OrgListState = initialState,
  action: AnyAction
): OrgListState {
  switch (action.type) {
    case ACTIONS.FETCH_ORG_LIST_PROGRESS:
      return onFetchOrgList(state);
    case ACTIONS.FETCH_ORG_LIST_SUCCESS:
      return onFetchOrgListSuccess(state, action.payload);
    case ACTIONS.FETCH_ORG_LIST_FAILURE:
      return onFetchOrgListFailure(state);
    default:
      return state;
  }
}
