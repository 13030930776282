/**
 * Anomaly Details Reducer
 * @author aditya.bhadange@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTIONS from './anomalyTemplatesDetails.actionTypes';
import {
  anomalyTemplateDetailsState,
  ANOMALY_TEMPLATE_DETAILS_STATUS
} from './anomalyTemplatesDetails.state';
/**
 *
 */
const initialState: anomalyTemplateDetailsState = {
  status: ANOMALY_TEMPLATE_DETAILS_STATUS.INIT,
  data: []
};
/**
 *
 * @param state
 * @returns
 */
const handleAnomalyTemplateFetchDetails = (
  state: anomalyTemplateDetailsState
): anomalyTemplateDetailsState => {
  return {
    ...state,
    status: ANOMALY_TEMPLATE_DETAILS_STATUS.FETCHING
  };
};
const handleAnomalyTemplateUpdateDetails = (
  state: anomalyTemplateDetailsState
): anomalyTemplateDetailsState => {
  return {
    ...state,
    status: ANOMALY_TEMPLATE_DETAILS_STATUS.IN_PROGRESS
  };
};
/**
 *
 * @param state
 * @param component
 * @returns
 */
const handleAnomalyTemplateFetchSuccess = (state: anomalyTemplateDetailsState, payload: any) => {
  return {
    ...state,
    status: ANOMALY_TEMPLATE_DETAILS_STATUS.READY,
    data: payload
  };
};

const handleAnomalyTemplateUpdateSuccess = (state: anomalyTemplateDetailsState, payload: any) => {
  return {
    ...state,
    status: ANOMALY_TEMPLATE_DETAILS_STATUS.UPDATED,
    data: payload
  };
};

const handleFetchFailure = (state: anomalyTemplateDetailsState) => ({
  ...state,
  status: ANOMALY_TEMPLATE_DETAILS_STATUS.ERROR
});

const handleAnomalyTemplateUpdateFailure = (state: anomalyTemplateDetailsState) => ({
  ...state,
  status: ANOMALY_TEMPLATE_DETAILS_STATUS.ERROR
});
/**
 *
 * @param state
 * @param action
 * @returns
 */
export default function anomalyTemplateDetailsReducer(
  state: anomalyTemplateDetailsState = initialState,
  action: AnyAction
): anomalyTemplateDetailsState {
  switch (action.type) {
    case ACTIONS.FETCH_ANOMALY_TEMPLATE_DETAILS:
      return handleAnomalyTemplateFetchDetails(state);
    case ACTIONS.FETCH_ANOMALY_TEMPLATE_DETAILS_SUCCESS:
      return handleAnomalyTemplateFetchSuccess(state, action.payload);
    case ACTIONS.FETCH_ANOMALY_TEMPLATE_DETAILS_FAILURE:
      return handleFetchFailure(state);
    case ACTIONS.UPDATE_ANOMALY_TEMPLATE:
      return handleAnomalyTemplateUpdateDetails(state);
    case ACTIONS.UPDATE_ANOMALY_TEMPLATE_SUCCESS:
      return handleAnomalyTemplateUpdateSuccess(state, action.payload);
    case ACTIONS.UPDATE_ANOMALY_TEMPLATE_FAILURE:
      return handleAnomalyTemplateUpdateFailure(state);
    case ACTIONS.RESET_ANOMALY_TEMPLATE_DETAILS:
      return {
        ...initialState
      };
    default:
      return state;
  }
}
