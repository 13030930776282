/**
 * User Option Menu
 * @author mahesh.kedari@shorleineiot.com
 */
import React, { forwardRef } from 'react';
import { useNavigate } from 'react-router';
import { SLOptionMenu, useSlugContext } from '../../framework';
import TranslateText from '../../i18n/TranslateText';

interface Props {}

const UserOptionMenu = forwardRef((props: Props, ref: any) => {
  const navigate = useNavigate();
  const { slug } = useSlugContext();
  /**
   * Handle click on User Profile
   * @param event
   */
  const handleProfileClick = (/* event: any */) => {
    navigate(`/${slug}/app/user-settings`);
  };
  /**
   *
   */
  return (
    <SLOptionMenu action={handleProfileClick}>
      {TranslateText('user_settings', 'User Settings')}
    </SLOptionMenu>
  );
});
export default UserOptionMenu;
