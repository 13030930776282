/**
 * Peak Highlighter Series Configurations
 * @author mahesh.kedari@shorelineiot.com
 */
// import Highcharts from 'highcharts';
import { NarrowBandTooltipFormatter } from './NarrowBandTooltipFormatter';

export const PeakHighlighterConfig = (peakList: Array<any>) => {
  return {
    data: peakList,
    type: 'scatter',
    selected: true,
    id: `peaks`,
    name: `peaks`,
    showInLegend: false,
    enableMouseTracking: false,
    marker: {
      fillColor: 'blue',
      symbol: 'square'
    },
    dataLabels: {
      enabled: true,
      shape: 'callout',
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: 'rgba(0,0,0,0.9)',
      y: -10,
      formatter: NarrowBandTooltipFormatter
    }
  };
};
