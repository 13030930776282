/**
 * Organisation Component
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { ReactElement } from 'react';
import {
  SLTextField,
  updateShortname,
  SLInputAdornment,
  LightTooltip
} from '../../../../../framework';
import { InformationIcon } from '../../../../../framework/icons';
/**
 *
 */
function SlugInfoIcon() {
  return (
    <SLInputAdornment position="end">
      <LightTooltip
        title="URL-friendly version of org name. Lowercase letters, numbers and underscore allowed"
        placement="bottom-end"
        buttonColor="secondary"
        tabIndex={-1}>
        <InformationIcon />
      </LightTooltip>
    </SLInputAdornment>
  );
}
/**
 *
 */
interface Props {
  rules: any;
}
/**
 *
 */
function Organisation({ rules }: Props): ReactElement {
  return (
    <>
      <SLTextField
        name="org_name"
        label="Organization Name"
        labelTranslationId="orgnization_name"
        rules={rules.organisation}
        onBlur={updateShortname}
      />
      <SLTextField
        name="slug"
        label="Short Name"
        labelTranslationId="short_name"
        rules={rules.slug}
        endAdornment={<SlugInfoIcon />}
      />
    </>
  );
}
export default Organisation;
