/**
 * UnHide Datapoint Helper functions
 * @author ninad.kulkarni@shorelineiot.com
 */
import { DatapointsListState } from '../types';
/**
 *
 * @param state
 */
export function onUnhideDatapoint(state: DatapointsListState): DatapointsListState {
  return {
    ...state
  };
}
/**
 *
 * @param state
 * @param datapointId
 */
export function onUnhideDatapointSuccess(
  state: DatapointsListState,
  dpid: number
): DatapointsListState {
  // console.log(datapointId);
  /* const { [dpid]: value1, ...datapointConfigs } = state.datapointConfigs;
  const { [dpid]: value2, ...lastDataValue } = state.lastDataValue;
  const { [dpid]: value3, ...liveDataConfigs } = state.liveDataConfigs;
  const { [dpid]: value4, ...viewConfigs } = state.viewConfigs;
  return {
    ...state,
    datapointConfigs,
    lastDataValue,
    liveDataConfigs,
    viewConfigs,
  }; */
  const unhiddenDatapoint = state?.datapointConfigs[dpid];
  unhiddenDatapoint.widget_settings.settings = {
    ...unhiddenDatapoint?.widget_settings?.settings,
    show: true
  };
  return {
    ...state,
    datapointConfigs: { ...state.datapointConfigs, [dpid]: unhiddenDatapoint }
  };
}
/**
 *
 * @param state
 * @param datapointId
 */
export function onUnhideDatapointFailure(state: DatapointsListState): DatapointsListState {
  return {
    ...state
  };
}
