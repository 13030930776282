/**
 * Copyrights Component
 * @author mahesh.kedari@shorelineiot.com
 */
import React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { LightTooltip } from '../../tooltip/Tooltip';
/**
 *
 */
interface Props {
  companyName?: string;
  url?: string;
  prefixText?: string;
  postfixText?: string;
}
/**
 *
 */
const Copyright: React.FC<Props> = ({
  companyName = 'Shoreline',
  url = 'https://shorelineai.us',
  prefixText,
  postfixText
}: Props) => {
  return (
    <>
      <LightTooltip title={`Version: ${import.meta.env.VITE_WEBSITE_VERSION}`} placement="top">
        <Typography variant="body2">
          <Link color="inherit" href={url}>
            <span>{prefixText}</span>
            &nbsp;Powered by&nbsp;
            {companyName}
          </Link>
          &nbsp;
          {/* {new Date().getFullYear()}. */}
          <span>{postfixText}</span>
        </Typography>
      </LightTooltip>
    </>
  );
};

export default Copyright;
