import { createSlice } from '@reduxjs/toolkit';
import { FeatureConfigurationState } from './featureConfigurations.types';
const initialState: FeatureConfigurationState = {
  featureConfigData: [],
  isSaveDisable: true,
  updatedFeatureConfigData: []
};

export const featureConfigurationsSlice = createSlice({
  name: 'featureConfigurations',
  initialState,
  reducers: {
    handleFeatureConfigData: (state, action) => {
      state.featureConfigData = action.payload;
    },
    handleIsSaveDisable: (state, action) => {
      state.isSaveDisable = action.payload;
    },
    handleUpdatedFeatureConfigData: (state, action) => {
      state.updatedFeatureConfigData = action.payload;
    }
  }
});
export const { handleFeatureConfigData, handleIsSaveDisable, handleUpdatedFeatureConfigData } =
  featureConfigurationsSlice.actions;

export default featureConfigurationsSlice.reducer;
