/**
 * Aggregator of Organisations Saga
 * @author mahesh.kedari@shorelineiot.com
 */
import { all } from 'redux-saga/effects';
import {
  watchCreateOrgRequest,
  watchOrganisationListSaga,
  watchSubOrganisationListSaga,
  watchCreateSubOrgRequest,
  watchOrgSelectorSaga
} from './store';

/**
 *
 */
export function* watchOrganisationSaga() {
  yield all([
    watchOrganisationListSaga(),
    watchSubOrganisationListSaga(),
    watchOrgSelectorSaga(),
    watchCreateOrgRequest(),
    watchCreateSubOrgRequest()
  ]);
}
