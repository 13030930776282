import { AnyAction } from 'redux';
import * as ACTIONS from './changePassword.actionTypes';

/**
 *
 */
export const changePassword = (data: any): AnyAction => {
  return {
    type: ACTIONS.CHANGE_PASSWORD_PROGRESS,
    payload: data
  };
};
/**
 *
 */
export const changePasswordSuccess = (data: any): AnyAction => {
  return {
    type: ACTIONS.CHANGE_PASSWORD_SUCCESS,
    payload: data
  };
};
/**
 *
 */
export const changePasswordFailure = (error: any): AnyAction => {
  return {
    type: ACTIONS.CHANGE_PASSWORD_FAILURE,
    payload: error
  };
};
