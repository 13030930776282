import { AnyAction } from 'redux';
import * as ACTION_TYPE from './actions/survey.actionTypes';
import { SurveyState } from './types';
import { SURVEY_STATUS } from './types/survey.status';

const initialState: SurveyState = {
  status: SURVEY_STATUS.INIT,
  surveyDetails: [],
  rawNarrowbands: null,
  powertrain: {}
};

function addSurveyStateProgress(state: SurveyState): SurveyState {
  return {
    ...state,
    status: SURVEY_STATUS.PROGRESS
  };
}

function addSurveyStateSuccess(state: SurveyState, action: AnyAction): SurveyState {
  return {
    ...state,
    status: SURVEY_STATUS.COMPLETED,
    surveyDetails: action.data,
    rawNarrowbands: action.rawNarrowbands,
    powertrain: action.powertrain
  };
}

function addSurveyStateFailure(state: SurveyState): SurveyState {
  return {
    ...state,
    status: SURVEY_STATUS.ERROR
  };
}

function resetSurveyState() {
  return {
    ...initialState
  };
}
/**
 *
 * @param state
 * @param action
 */
export default function addSurveyReducer(
  state: SurveyState = initialState,
  action: AnyAction
): SurveyState {
  switch (action.type) {
    case ACTION_TYPE.ADD_SURVEY_ANSWERS:
      return addSurveyStateProgress(state);
    case ACTION_TYPE.ADD_SURVEY_ANSWERS_SUCCESS:
      return addSurveyStateSuccess(state, action);
    case ACTION_TYPE.ADD_SURVEY_ANSWERS_FAILURE:
      return addSurveyStateFailure(state);
    case ACTION_TYPE.ADD_SURVEY_ANSWERS_RESET:
      return resetSurveyState();
    default:
      return state;
  }
}
