/**
 * deleteCustomization Action
 * @author prerna.more@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTIONS from './deletePersonalizationSettings.actionTypes';

export const deletePersonalizationInfoProgress = (data: any): AnyAction => {
  return {
    type: ACTIONS.DELETE_THEMES,
    payload: data
  };
};
/**
 *
 * @param data
 */
export const deletePersonalizationInfoSuccess = (data: any): AnyAction => {
  return {
    type: ACTIONS.DELETE_THEMES_SUCCESS,
    payload: data
  };
};
/**
 *
 * @param error
 */
export const deletePersonalizationInfoFailure = (error: any): AnyAction => {
  return {
    type: ACTIONS.DELETE_THEMES_FAILURE,
    payload: error
  };
};
/**
 *
 * @param data
 */

export const deletePersonalizationInfoReset = (): AnyAction => {
  return {
    type: ACTIONS.RESET_THEME
  };
};
