/**
 * Table Widget Styles
 * @author mahesh.kedari@shorelineiot.com
 */
import { Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginRight: '10px',
      '&.MuiButton-contained': {
        color: theme.palette.primary.main,
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.background.default
      },
      '& .MuiTableSortLabel-icon': {
        opacity: '1 !important',
        color: 'black !important'
      },
      '& .MuiTableSortLabel-root:hover .MuiTableSortLabel-icon': {
        opacity: '1 !important',
        color: 'black !important'
      }
    },
    applyIcon: {
      height: '1.9em',
      width: '1.9em'
    },
    applyButton: {
      marginTop: '-4px'
    },
    settingsButton: {
      marginLeft: '10px'
    },
    menuLabel: {
      color: theme.palette.secondary.main
    },
    selectRoot: {
      display: 'flex'
    },
    dropdownStyle: {
      width: '10vw',
      marginRight: '30px',
      marginTop: '3px'
    },
    menuRoot: {
      '& .MuiPaper-root': {
        maxHeight: '25vh',
        width: '10vw'
      },
      '& .MuiCheckbox - indeterminate': {
        color: 'primary'
      }
    },
    tableRoot: {
      '& .MuiTableCell-head': {
        zIndex: 0
      },
      '& .MuiTypography-root.MuiTypography-caption': {
        color: 'black'
      },
      backgroundColor: theme.palette.background.default,
      marginLeft: '0.7vw',
      paddingTop: '0.5vw'
    },
    iconButton: {
      marginLeft: '30px'
    },
    colHeader: {
      color: 'black',
      '&:hover': {
        color: 'black'
      }
    },
    selectStyle: {
      borderWidth: 1,
      height: '33px',

      borderColor: theme.palette.grey[400],
      borderRadius: '28px',
      borderStyle: 'solid',

      '& .MuiSelect-select.MuiSelect-select': {
        paddingLeft: '10px'
      },

      '@media(min-width: 1280px)': {
        borderWidth: 1,
        borderColor: theme.palette.grey[400],
        borderRadius: '28px',
        borderStyle: 'solid',
        paddingTop: '1px'
      },

      '@media(min-width: 1920px)': {
        borderWidth: 1,
        borderColor: theme.palette.grey[400],
        borderRadius: '28px',
        borderStyle: 'solid',
        paddingTop: '2px'
      },
      '&:hover': {
        color: 'black'
      },
      '&:focus': {
        color: 'black'
      }
    }
  })
);
