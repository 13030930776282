/**
 * Export Device Data Actions
 * @author vinayak.rathod@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTIONS from './exportDeviceData.actionTypes';
import { ExportDeviceDataFromWebsocketResponse } from './exportDeviceDataType';

export const fetchExportDeviceData = (data: any): AnyAction => {
  return {
    type: ACTIONS.FETCH_EXPORT_DEVICE_DATA_PROGRESS,
    payload: data
  };
};
/**
 * @param data response data
 * @returns data to be passed to reducer()
 */
export const fetchExportDeviceDataSuccess = (data: any): AnyAction => {
  return {
    type: ACTIONS.FETCH_EXPORT_DEVICE_DATA_SUCCESS,
    payload: data
  };
};

/**
 *
 * @param err retrun from api call
 */
export const fetchExportDeviceDataFailure = (error: any): AnyAction => {
  return {
    type: ACTIONS.FETCH_EXPORT_DEVICE_DATA_FAILURE,
    payload: error
  };
};

/**
 *
 * @param err retrun from api call
 */
export const exportDeviceDataReset = (payload: any): AnyAction => {
  return {
    type: ACTIONS.FETCH_EXPORT_DEVICE_DATA_RESET,
    payload
  };
};

export const fetchEvents = (data: any): AnyAction => {
  return {
    type: ACTIONS.FETCH_EVENTS_PROGRESS,
    payload: data
  };
};
/**
 * @param data response data
 * @returns data to be passed to reducer()
 */
export const handleFetchEventsSuccess = (data: any): AnyAction => {
  return {
    type: ACTIONS.FETCH_EVENTS_SUCCESS,
    payload: data
  };
};

export const handleUpdateSelectedEvents = (data: any): AnyAction => {
  return {
    type: ACTIONS.UPDATE_SELECTED_EVENTS,
    payload: data
  };
};

export const handleEmailChange = (data: any): AnyAction => {
  return {
    type: ACTIONS.EMAIL_CHANGE,
    payload: data
  };
};

export const handleDateEventTogggle = (data: any): AnyAction => {
  return {
    type: ACTIONS.UPDATE_DATE_EVENTS_TOGGLE,
    payload: data
  };
};

/**
 *
 * @param err retrun from api call
 */
export const handleFetchEventsFailure = (error: any): AnyAction => {
  return {
    type: ACTIONS.FETCH_EVENTS_FAILURE,
    payload: error
  };
};

export const performDeviceDataFileDownload = (
  data: ExportDeviceDataFromWebsocketResponse
): AnyAction => {
  return {
    type: ACTIONS.PERFORM_DEVICE_DATA_FILE_DOWNLOAD,
    payload: data
  };
};

/**
 *
 * @param err retrun from api call
 */
export const fetchEventsReset = (): AnyAction => {
  return {
    type: ACTIONS.FETCH_EVENTS_RESET
  };
};

export const fetchUserList = (data: any): AnyAction => {
  return {
    type: ACTIONS.FETCH_USER_LIST_PROGRESS,
    payload: data
  };
};

export const usersListSucess = (data: any): AnyAction => {
  // returns the action type
  return {
    type: ACTIONS.USERS_LIST_FOR_EXPORT_SUCCESS,
    data
  };
};

export const usersListFailure = (): AnyAction => {
  // returns the action type
  return {
    type: ACTIONS.USERS_LIST_FOR_EXPORT_FAILURE
  };
};
