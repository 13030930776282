import React from 'react';
import { SLCard, SLTable } from '../../../../framework';
import { LeakActiveAlarmsColumns } from '../../../../features/reports/leak-detection/active-alarms/LeakActiveAlarmsColumns';
import { useFetchLeakDetectionAlarms } from '../../../../features/reports/store/leak-detection/leakDetection.hooks';

function LeakDetectionActiveAlarms() {
  const columns = React.useMemo(() => LeakActiveAlarmsColumns, []);
  const { alarms, isFetchingAlarms } = useFetchLeakDetectionAlarms();

  return (
    <SLCard title="Active Alarms" action={<></>}>
      <SLTable
        feature="leak-detection-active-alarms"
        data={alarms || []}
        columns={columns}
        enableBottomToolbar
        enablePagination
        showSkeletons={isFetchingAlarms}
        noRecordsToDisplay={'No Alarms for selected Range'}
        maxBodyHeight={'calc(45vh - 100px)'}
        getRowId={(originalRow) => originalRow?.alarm_id}
      />
    </SLCard>
  );
}

export default LeakDetectionActiveAlarms;
