/**
 * Sub-Organisation List Actions
 * @author meghneel.adke@shorelineiot.com
 */

import { AnyAction } from 'redux';
import * as ACTIONS from './subOrgList.actionTypes';
import { Suborg } from '../../orgs';
/**
 *
 * @param flag true/false value upda
 * @param type
 */
export const fetchSubOrgList = (slug: string): AnyAction => {
  // returns the action type
  return {
    type: ACTIONS.FETCH_SUB_ORG_LIST_PROGRESS,
    payload: {
      slug
    }
  };
};
/**
 *
 * @param orgList
 */
export const fetchSubOrgListSuccess = (orgList: Suborg[]): AnyAction => {
  return {
    type: ACTIONS.FETCH_SUB_ORG_LIST_SUCCESS,
    payload: orgList
  };
};
/**
 *
 * @param error
 */
export const fetchSubOrgListFailure = (error: any): AnyAction => {
  return {
    type: ACTIONS.FETCH_SUB_ORG_LIST_FAILURE,
    payload: error
  };
};
