/**
 * Formula Evaluator function. This function is resposible for checking
 * if current formula meets the condition or not. This can be extend
 * further to check complex business logic.
 * @param condition
 * @param value
 * @returns
 */
export function formulaEvaluator(condition: any, value: any) {
  try {
    switch (condition.operator) {
      case 'EQUALS':
        return value.toString() === condition.value;
      case 'LESS_THAN':
        return value < condition.value;
      case 'GREATER_THAN':
        return value > condition.value;
      case 'NOT_EQUAL':
        return value !== condition.value;
      case 'GREATER_THAN_OR_EQUAL':
        return value >= condition.value;
      case 'LESS_THAN_OR_EQUAL':
        return value <= condition.value;
      default:
        return false;
    }
  } catch (error: any) {
    return false;
  }
}
