/**
 * Get Theme Saga
 * @author priyanka.ambawane@shorelineiot.com
 */

import { all, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import { AnyAction } from 'redux';
import * as ACTION from './theme.action';
import * as ACTION_TYPE from './theme.actionTypes';
import { GenericObject } from '../../framework';

export function* getOrgThemeSaga(action: AnyAction) {
  try {
    const response: GenericObject = yield axios.post(
      'http://localhost:8080/api/v2/unAuth/getOrgTheme',
      action.payload
    );
    yield put(ACTION.getOrgThemeSuccess(response.data));
  } catch (error: any) {
    yield put(ACTION.getOrgThemeFailure(error));
  }
}

export function* getOrgThemeWatcherSagas() {
  yield all([takeEvery(ACTION_TYPE.GET_ORG_THEME_PROGERSS, getOrgThemeSaga)]);
}
