/**
 * personalization State
 * @author ashwini.gawade@shorelineiot.com
 */

export enum UPDATE_PERSONALIZE_SETTINGS_STATUS {
  INIT,
  FETCHING,
  READY,
  ERROR,
  RESET
}
export interface UpdatePersonalizeSettingsState {
  status: UPDATE_PERSONALIZE_SETTINGS_STATUS;
  personalization_info?: any;
}
