/**
 * Sensor Settings
 * @author mahesh.kedari@shorelineiot.com
 */
export const SENSOR_SETTINGS_FETCH = 'FEATURE/DEVICE/DEVICE_DATA/SENSOR_SETTINGS/FETCH';
export const SENSOR_SETTINGS_SUCCESS = 'FEATURE/DEVICE/DEVICE_DATA/SENSOR_SETTINGS/SUCCESS';
export const SENSOR_SETTINGS_FAILURE = 'FEATURE/DEVICE/DEVICE_DATA/SENSOR_SETTINGS/FAILURE';
export const SENSOR_SETTINGS_RESET = 'FEATURE/DEVICE/DEVICE_DATA/SENSOR_SETTINGS/RESET';

export const UPDATE_SENSOR_CONFIG = 'FEATURES/DEVICE/DEVICE_DATA/SENSOR_SETTINGS/UPDATE';
export const UPDATE_SENSOR_CONFIG_SUCCESS =
  'FEATURES/DEVICE/DEVICE_DATA/SENSOR_SETTINGS/UPDATE/SUCCESS';
export const UPDATE_SENSOR_CONFIG_FAILURE =
  'FEATURES/DEVICE/DEVICE_DATA/SENSOR_SETTINGS/UPDATE/FAILURE';
