/**
 *
 */
import { AnyAction } from 'redux';
import * as ACTIONS from './payments.actionTypes';
import { PAYMENTS_STATUS } from './types/PaymentsStatus';
import { PaymentsState } from './types/PaymentsState';
/**
 *
 */
const initialState: PaymentsState = {
  status: PAYMENTS_STATUS.INIT
};
/**
 *
 * @param state
 * @param action
 */
export default function paymentsReducer(state: PaymentsState = initialState, action: AnyAction) {
  switch (action.type) {
    case ACTIONS.PAYMENTS_RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
}
