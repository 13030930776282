/**
 * Reports Saga
 * @author prerna.more@shorelineiot.com
 */
import { all } from 'redux-saga/effects';
import { watchdeviceMailboxSagas } from './device-mailbox/deviceMailbox.saga';
import { watchDeleteDeviceMailboxSagas } from './device-mailbox/delete-device-mailbox/deleteDeviceMailbox.saga';
import { watchexternalReportsSagas } from './external-reports';
import { watchdeviceReportsSagas, watchRemarkSagas } from './device-reports';

export function* watchReportsSaga() {
  yield all([
    watchexternalReportsSagas(),
    watchdeviceReportsSagas(),
    watchRemarkSagas(),
    watchdeviceMailboxSagas(),
    watchDeleteDeviceMailboxSagas()
  ]);
}
