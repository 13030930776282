/**
 * widget setting feature  Reducer
 * @author ashwini.gawade@shorelineiot.com
 */
import { combineReducers } from 'redux';
import getWidgetSetting from './get-line-chart-config/getWidgetSetting.reducer';
import updateWidgetSetting from './update-line-chart-config/updateWidgetSetting.reducer';
/**
 *
 */
const WidgetSettingReducer = combineReducers({
  getWidgetSetting,
  updateWidgetSetting
});
/**
 * Features reducer combined
 */
export default WidgetSettingReducer;
