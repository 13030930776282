import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiDialogContent-root': {
      overflowY: 'hidden'
    }
  },
  dialogContent: {
    height: '10vh',
    overflow: 'hidden',
    '@media (min-width:1920px)': {
      height: '6vh'
    }
  },
  titleContent: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.default
  },
  applyButton: {
    height: '34px'
  },
  cancelButton: {
    marginRight: '10px'
  }
}));
