/**
 *
 */
export enum VA_DEVICE_STATUS {
  INIT,
  FETCHING,
  READY,
  ERROR
}
/**
 *
 */
export interface VADeviceState {
  status: VA_DEVICE_STATUS;
  datapointsList: Array<any>;
  devicesList: Array<any>;
  selectedDatapoints: Array<any>;
  selectedDevices: Array<any>;
  devicesWithDatapoints: Array<any>;
}
