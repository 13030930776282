/**
 * Sensor List widget styles
 */
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  grabStyle: {
    cursor: 'pointer'
  },
  autoMargin: {
    marginTop: '-20px'
  }
}));
