import { datapointsMap, datapointsMapNevadaNano } from './datapointsMap';

export function orderDatapoints(datapointList: Array<any>) {
  const orderedDatapoints: Array<any> = [];
  let maximumSequenceId = 0;

  datapointList.forEach((datapoint: any) => {
    const mapObject: any = datapointsMap.find(
      (object: any) => object.name === datapoint.datapoint_type
    );
    if (mapObject) {
      orderedDatapoints.push({
        ...datapoint,
        sequence_id: mapObject.sequence_id,
        is_ordered: true
      });

      if (mapObject.sequence_id > maximumSequenceId) {
        maximumSequenceId = mapObject.sequence_id;
      }
    }
  });

  const unorderedDatapoints = datapointList
    .filter(
      (datapoint: any) =>
        !orderedDatapoints.some(
          (orderedData: any) => String(orderedData?.dpid) === String(datapoint?.dpid)
        )
    )
    .map((datapoint: any, index: number) => {
      return {
        ...datapoint,
        sequence_id: maximumSequenceId + index + 1,
        is_ordered: true
      };
    });

  orderedDatapoints.sort((a, b) => {
    return a.sequence_id - b.sequence_id;
  });
  return [...orderedDatapoints, ...unorderedDatapoints];
}

export function orderNevadaNanoDatapoints(datapointList: Array<any>) {
  const orderedDatapoints: Array<any> = [];
  let maximumSequenceId = 0;

  datapointList.forEach((datapoint: any) => {
    const mapObject: any = datapointsMapNevadaNano.find(
      (object: any) => object.name === datapoint.dp_name
    );
    if (mapObject) {
      orderedDatapoints.push({
        ...datapoint,
        sequence_id: mapObject.sequence_id,
        is_ordered: true
      });

      if (mapObject.sequence_id > maximumSequenceId) {
        maximumSequenceId = mapObject.sequence_id;
      }
    }
  });

  const unorderedDatapoints = datapointList.filter(
    (datapoint: any) => datapoint.is_ordered === undefined
  );

  unorderedDatapoints.forEach((datapoint: any, index: number) => {
    orderedDatapoints.push({
      ...datapoint,
      sequence_id: maximumSequenceId + index + 1,
      is_ordered: true
    });
  });

  orderedDatapoints.sort((a, b) => {
    return a.sequence_id - b.sequence_id;
  });

  return orderedDatapoints;
}
