/**
 * Component Details Action Types
 * @author mahesh.kedari@shorelineiot.com
 */

export const FETCH_COMPONENT_DETAILS = 'FEATURES/COMPONENT_COMPILER/COMPONENT_DETAILS/FETCH';
export const EXPORT_COMPONENT_DETAILS = 'FEATURES/COMPONENT_COMPILER/COMPONENT_DETAILS/EXPORT';

export const FETCH_COMPONENT_DETAILS_SUCCESS =
  'FEATURES/COMPONENT_COMPILER/COMPONENT_DETAILS/FETCH/SUCCESS';
export const FETCH_COMPONENT_DETAILS_FAILURE =
  'FEATURES/COMPONENT_COMPILER/COMPONENT_DETAILS/FETCH/FAILURE';
export const ADD_COMPONENT_MOUNT_POINTS =
  'FEATURES/COMPONENT_COMPILER/COMPONENT_DETAILS/ADD_MOUNT_POINTS';

export const UPDATE_COMPONENT_MOUNT_POINT =
  'FEATURES/COMPONENT_COMPILER/COMPONENT_DETAILS/UPDATE_MOUNT_POINT';

export const UPDATE_NB_MOUNT_POINT =
  'FEATURES/COMPONENT_COMPILER/COMPONENT_DETAILS/UPDATE_NB_MOUNT_POINT';

export const UPDATE_SINGLE_NB = 'FEATURES/COMPONENT_COMPILER/COMPONENT_DETAILS/UPDATE_SINGLE_NB';
export const DELETE_SINGLE_NB = 'FEATURES/COMPONENT_COMPILER/COMPONENT_DETAILS/DELETE_SINGLE_NB';
export const ADD_NEW_NB = 'FEATURES/COMPONENT_COMPILER/COMPONENT_DETAILS/ADD_NEW_NB';

export const RESET_COMPONENT_DETAILS = 'FEATURES/COMPONENT_COMPILER/COMPONENT_DETAILS/RESET';
export const RESET_MULTIPLE_COMPONENT_DETAILS =
  'FEATURES/COMPONENT_COMPILER/MULTIPLE_COMPONENT_DETAILS/RESET';

export const UPDATE_COMPONENT_DETAILS = 'FEATURES/COMPONENT_COMPILER/COMPONENT_DETAILS/UPDATE';
export const UPDATE_COMPONENT_DETAILS_SUCCESS =
  'FEATURES/COMPONENT_COMPILER/COMPONENT_DETAILS/UPDATE/SUCCESS';
export const UPDATE_COMPONENT_DETAILS_FAILURE =
  'FEATURES/COMPONENT_COMPILER/COMPONENT_DETAILS/UPDATE/FAILURE';

export const CREATE_COMPONENT_DETAILS = 'FEATURES/COMPONENT_COMPILER/COMPONENT_DETAILS/CREATE';
export const CREATE_COMPONENT_DETAILS_SUCCESS =
  'FEATURES/COMPONENT_COMPILER/COMPONENT_DETAILS/CREATE/SUCCESS';
export const CREATE_COMPONENT_DETAILS_FAILURE =
  'FEATURES/COMPONENT_COMPILER/COMPONENT_DETAILS/CREATE/FAILURE';
export const CREATE_COMPONENT_DETAILS_RESET =
  'FEATURES/COMPONENT_COMPILER/COMPONENT_DETAILS/CREATE/RESET';

export const GET_NARROWBANDS_FOR_COMPONENTS =
  'FEATURES/COMPONENT_COMPILER/COMPONENT_DETAILS/GET_NARROWBANDS';
export const GET_NARROWBANDS_SUCCESS =
  'FEATURES/COMPONENT_COMPILER/COMPONENT_DETAILS/GET_NARROWBANDS/SUCCESS';
export const GET_NARROWBANDS_FAILURE =
  'FEATURES/COMPONENT_COMPILER/COMPONENT_DETAILS/GET_NARROWBANDS/FAILURE';

export const APPLY_TO_DEVICES = 'FEATURES/COMPONENT_COMPILER/COMPONENT_DETAILS/APPLY_TO_DEVICES';
export const APPLY_TO_DEVICES_SUCCESS =
  'FEATURES/COMPONENT_COMPILER/COMPONENT_DETAILS/APPLY_TO_DEVICES/SUCCESS';
export const APPLY_TO_DEVICES_FAILURE =
  'FEATURES/COMPONENT_COMPILER/COMPONENT_DETAILS/APPLY_TO_DEVICES/FAILURE';

export const GET_ATTACHED_DEVICES =
  'FEATURES/COMPONENT_COMPILER/COMPONENT_DETAILS/GET_ATTACHED_DEVICES';
export const GET_ATTACHED_DEVICES_SUCCESS =
  'FEATURES/COMPONENT_COMPILER/COMPONENT_DETAILS/GET_ATTACHED_DEVICES/SUCCESS';
export const GET_ATTACHED_DEVICES_FAILURE =
  'FEATURES/COMPONENT_COMPILER/COMPONENT_DETAILS/GET_ATTACHED_DEVICES/FAILURE';
