/**
 * Features Routes List
 * @author mahesh.kedari@shorelineiot.com
 */
// import React from 'react';
import { lazy } from 'react';

export const routes = [
  {
    id: 'home-route-1',
    exact: false,
    path: 'alarms/*',
    component: lazy(() => import('./alarms/AlarmsLayout'))
  },
  {
    id: 'home-route-2',
    exact: false,
    path: 'alarms-new/*',
    component: lazy(() => import('./alarms/AlarmsLayout'))
  },
  {
    id: 'home-route-3',
    exact: false,
    path: 'devices/*',
    component: lazy(() => import('./device/DeviceLayout'))
  },
  {
    id: 'home-route-4',
    exact: false,
    path: 'new-asset-dashboard/*',
    component: lazy(() => import('./device/new-asset-dashboard/NewAssetDashboardLayout'))
  },
  {
    id: 'home-route-5',
    exact: false,
    path: 'export-logs/*',
    component: lazy(() => import('./export-data/ExportDataLayout'))
  },
  {
    id: 'home-route-6',
    exact: false,
    path: 'org-settings/*',
    component: lazy(() => import('./org-settings/OrgSettingsLayout'))
  },
  {
    id: 'home-route-7',
    exact: false,
    path: 'org/*',
    component: lazy(() => import('./organization/OrgLayout'))
  },
  {
    id: 'home-route-8',
    exact: false,
    path: 'user-settings/*',
    component: lazy(() => import('./user-settings/UserSettingsLayout'))
  },
  {
    id: 'home-route-9',
    exact: false,
    path: 'workflow/*',
    component: lazy(() => import('./workflow/WorkflowLayout'))
  },
  {
    id: 'home-route-10',
    exact: false,
    path: 'reports/*',
    component: lazy(() => import('./reports/ReportsLayout'))
  },
  {
    id: 'home-route-11',
    exact: false,
    path: 'firmware/*',
    component: lazy(() => import('./firmware/FirmwareLayout'))
  },
  {
    id: 'home-route-12',
    exact: false,
    path: 'activity-logs/*',
    component: lazy(() => import('./activity-logs/ActivityLogsLayout'))
  },
  {
    id: 'home-route-13',
    exact: false,
    path: 'custom-dashboard/*',
    component: lazy(() => import('./dashboard/DashboardLayout'))
  },
  {
    id: 'home-route-14',
    exact: false,
    path: 'alerts/*',
    component: lazy(() => import('./alerts/AlertsLayout'))
  },
  {
    id: 'home-route-15',
    exact: false,
    path: 'templates/*',
    component: lazy(() => import('./templates/TemplatesLayout'))
  },
  {
    id: 'home-route-16',
    exact: false,
    path: 'anomaly-templates/*',
    component: lazy(() => import('./anomaly-templates/AnomalyTemplatesLayout'))
  },
  {
    id: 'home-route-17',
    exact: false,
    path: 'genai-bot/*',
    component: lazy(() => import('./chat/ChatLayout'))
  },
  {
    id: 'home-route-18',
    exact: false,
    path: 'user-scripts/*',
    component: lazy(() => import('./user-scripts/UserScriptsLayout'))
  }
];
