/**
 * Sub-Organisation Action Constants
 * @author meghneel.adke@shorelineiot.com
 */

// create organization constants
// for updating organization details

export const FETCH_SUB_ORG_LIST_SUCCESS = 'FEATURES/SUB_ORGANISATION/FETCH_LIST_SUCCESS';
export const FETCH_SUB_ORG_LIST_FAILURE = 'FEATURES/SUB_ORGANISATION/FETCH_LIST_FAILURE';
export const FETCH_SUB_ORG_LIST_PROGRESS = 'FEATURES/SUB_ORGANISATION/FETCH_LIST_PROGRESS';
