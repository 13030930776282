/**
 * fetch device photos states
 * @author ashwini.gawade@shorelineiot.com
 */
export enum DEVICE_PHOTOS_STATUS {
  INIT,
  FETCHING_DEVICE_PHOTOS,
  READY,
  ERROR
}
export interface photo {
  content_type: string;
  get_object_url: string;
  name: string;
  resolution: string;
  size: number;
  view: string;
  caption?: string;
}
export interface devicePhotosState {
  status: DEVICE_PHOTOS_STATUS;
  multipleDevicePhotos: photo[];
}
