/**
 * Anomaly List Saga
 * @author mahesh.kedari@shorelineiot.com
 */
import { takeEvery, call, put } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import * as actions from '../anomaly.action';
import * as ACTIONS from '../anomaly.actionTypes';
import { GenericObject, httpGet, SafeSaga, APISERVICES } from '../../../../../framework';
import { SagaIterator } from 'redux-saga';
import { FetchAnomalyListPayload } from './anomaly-list.state';

function fetchAnomalyList(data: FetchAnomalyListPayload) {
  if (data?.isSuperAdmin) {
    return httpGet(
      `orgs/${data?.slug}/derived_expressions?hide_anomaly=true`,
      null,
      APISERVICES.DEVICE_API
    );
  }
  return httpGet(`orgs/${data?.slug}/derived_expressions`, null, APISERVICES.DEVICE_API);
}
/**
 *
 */
function* getAnomalyList(action: AnyAction) {
  try {
    const response: GenericObject = yield call(fetchAnomalyList, action.payload);
    yield put(actions.anomalyListSuccess(response));
  } catch (error: any) {
    yield put(actions.anomalyListFailure(error));
  }
}
/**
 *
 */
export function* watchAnomalyListSaga(): SagaIterator {
  yield takeEvery(
    ACTIONS.FETCH_ANOMALY_CONFIG_LIST,
    SafeSaga(getAnomalyList, ACTIONS.FETCH_ANOMALY_CONFIG_LIST)
  );
}
