import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, FormHelperText, IconButton } from '@mui/material';
import { RebootIcon } from '../../icons';
import { LightTooltip } from '../tooltip/Tooltip';
import { useDebounce, useGAEventTracker } from '../../custom-hooks';
import { useDispatch } from 'react-redux';
import { handleWaterfallXAxisZoomValueChange } from '../../../features/workflow/trend-analysis/store/waterfall-rtk/waterfall.slice';
import {
  useWaterfallEventSpeed,
  useWaterfallXUnit,
  useWaterfallXAxisZoomeValue
} from '../../../features/workflow/trend-analysis/store/waterfall-rtk/waterfall.slice.hooks';
import { trimDecimals } from '../../helpers';
import { ZoomValue, eventsDataType } from '../../../features/workflow/trend-analysis/store';
import {
  getZoomValuesOnUnitChange,
  getEventMaxValue,
  validateMaxZoomValues,
  getZoomValuesInHz
} from '../../../features/workflow/trend-analysis/waterfall/header/waterfall.helper';
import { SLTextField } from '../form';
import TranslateText from '../../../i18n/TranslateText';

type Props = {
  chartRef: React.MutableRefObject<any>;
  eventsData: eventsDataType[];
};

const useStyles = makeStyles(() => ({
  inputBox: {
    width: '100px'
  },
  resetButton: {
    left: '-15px',
    top: '-8px'
  }
}));
/**
 *
 * @param param0
 * @returns
 */

export default function SLChartXAxisZoomHandler({ chartRef, eventsData }: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const unit = useWaterfallXUnit();
  const speed = useWaterfallEventSpeed();
  const xAxisZoomValues = useWaterfallXAxisZoomeValue();
  const GAEventsTracker = useGAEventTracker('Waterfall min max');
  const [zoomValues, setZoomValues] = useState<ZoomValue>({
    min: null,
    max: null
  });
  const [showError, setShowError] = useState(false);
  const [eventMaxValue, setEventMaxValue] = useState(getEventMaxValue(eventsData, unit, speed));

  /**
   * useDebounce updates the chart's zoom values.
   * It's designed to prevent potential page hang-ups that may occur when manually zooming a 3D chart.
   * In case of X-Axis zoom, which is not offically supported by the chart, it's debounced to 1 second.
   */
  const debouncedZoomValue = useDebounce(zoomValues, 1000);

  function setChartZoomHandler(newZoomValues: ZoomValue) {
    const xAxis: any = chartRef?.current?.chart?.xAxis[0];
    const extremes: any = xAxis?.getExtremes();
    const newValues = {
      min: newZoomValues.min === null ? 0 : newZoomValues.min,
      max:
        newZoomValues.max === null || newZoomValues.max > extremes?.dataMax
          ? trimDecimals(extremes?.dataMax)
          : newZoomValues.max || null
    };

    /**
     * checking min<max before setting range to chart
     */
    if (newValues.max !== null && newValues.min < newValues.max) {
      setShowError(false);
    } else {
      setShowError(true);
    }

    /**
     * whatever the min,max values are
     * we are setting on inputs
     */
    setZoomValues(newValues);
  }

  useEffect(() => {
    if (zoomValues.min !== null && validateMaxZoomValues(zoomValues)) {
      // If zoom values are min = 0 and max = null and valid, dispatch zoom values
      if (zoomValues.min === 0 && zoomValues.max === null) {
        dispatch(handleWaterfallXAxisZoomValueChange(zoomValues));
      } else {
        // If zoom values are valid, then store zoom values in hz unit
        if (zoomValues?.min !== null && zoomValues?.max) {
          const zoomValueInHz = getZoomValuesInHz(
            { min: zoomValues.min, max: zoomValues.max },
            unit,
            speed
          );
          dispatch(handleWaterfallXAxisZoomValueChange(zoomValueInHz));
        }
      }
    }
  }, [debouncedZoomValue]);

  const handleMinMaxZoomChange = (value: any) => {
    const xMinValue = Number(value.minX);
    const xMaxValue = Number(value.maxX) || null;

    // Regex to allow up to 4 decimal places
    const regex = /^\d*\.?\d{0,4}$/;
    if (regex.test(String(xMinValue)) && regex.test(String(xMaxValue))) {
      //setting new max,min value to old zoomvalues
      setChartZoomHandler({ min: xMinValue, max: xMaxValue });
    }

    GAEventsTracker(
      'WaterFall min max change',
      ` min: ${xMinValue},
      max: ${xMaxValue}`
    );
  };
  /**
   * Reset chart range
   */
  const resetDefault = () => {
    const newZoomValues = { min: 0, max: null };
    setShowError(false);
    setZoomValues(newZoomValues);
  };
  //On unit change get equivalent zoom values.
  //Zoom values with respect to first event.
  useEffect(() => {
    if (eventsData?.length > 0 && eventsData[0]?.data?.length > 0) {
      if (xAxisZoomValues.min !== null && xAxisZoomValues.max !== null) {
        const { zoomMin, zoomMax } = getZoomValuesOnUnitChange(
          {
            min: xAxisZoomValues?.min,
            max: xAxisZoomValues?.max
          },
          unit,
          speed
        );
        setZoomValues({ min: trimDecimals(zoomMin), max: trimDecimals(zoomMax) });
      }
    }
    const eventDataMaxValue = getEventMaxValue(eventsData, unit, speed);
    setEventMaxValue(eventDataMaxValue);
  }, [unit]);

  useEffect(() => {
    const eventDataMaxValue = getEventMaxValue(eventsData, unit, speed);
    setEventMaxValue(eventDataMaxValue);
    //Reset zoom values if zoommax is greater than largest value of event data
    if (zoomValues?.max && eventDataMaxValue < zoomValues.max) {
      setZoomValues({ min: 0, max: null });
    }
  }, [eventsData]);

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="left"
        spacing={1}
        xs={12}>
        <Grid item xs={4.5} marginTop={'-20px'}>
          <SLTextField
            name="minX"
            type="number"
            label="Min X"
            labelTranslationId="min_x"
            allowNegative={false}
            value={zoomValues.min || '0'}
            onChange={(value: any) => handleMinMaxZoomChange(value)}
          />
        </Grid>

        <Grid item xs={4.5} marginTop={'-20px'} marginRight={'10px'}>
          <SLTextField
            name="maxX"
            type="number"
            label="Max X"
            labelTranslationId="max_x"
            allowNegative={false}
            value={zoomValues.max || eventMaxValue}
            onChange={(value: any) => handleMinMaxZoomChange(value)}
          />
        </Grid>
        <Grid item xs={1}>
          <LightTooltip title="Reset X Zoom" translationId="reset_x_zoom">
            <IconButton size="small" onClick={resetDefault} className={classes.resetButton}>
              <RebootIcon className="MuiSvgIcon-root" />
            </IconButton>
          </LightTooltip>
        </Grid>
      </Grid>
      <Grid item>
        {showError && (
          <FormHelperText error>
            {TranslateText('invalid_chart_range', 'Invalid chart range')}
          </FormHelperText>
        )}
      </Grid>
    </>
  );
}
