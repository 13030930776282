/**
 * Spinner Reducer
 * @author mahesh.kedari@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTIONS from '../actions/framework.actionType';
import { ThemeType } from '../types';
/**
 *
 * @param state Old state
 * @param action Action to perform
 */
export default function themeReducers(state: ThemeType | null = null, action: AnyAction) {
  switch (action.type) {
    case ACTIONS.UPDATE_THEME: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
}
