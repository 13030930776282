/**
 * Device Data Reducer
 * @author priyanka.ambawane@shorelineiot.com
 */

import { AnyAction } from 'redux';
import * as ACTION_TYPES from './zoomOps.actionTypes';

const initialState = {
  dpid: 0,
  startDateNum: 0,
  endDateNum: 0,
  zoomLiveOrHist: ''
};

export default function zoomData(state = initialState, action: AnyAction) {
  switch (action.type) {
    case ACTION_TYPES.ZOOM_DATA:
      return {
        ...state,
        dpid: action.payload.dpid,
        startDateNum: action.payload.startDateNum,
        endDateNum: action.payload.endDateNum,
        zoomLiveOrHist: action.payload.zoomLiveOrHist
      };
    default:
      return state;
  }
}
