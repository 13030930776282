import { Box } from '@mui/material';
import React, { useRef, useState } from 'react';
import ImageMarker from 'react-image-marker';
import { useStyles } from './imageMarkingWidget.styles';
import CustomMarker from './CustomMarker';

interface ImageWidgetProps {
  imageWidgetData: {
    image: any;
    imageData: any;
  };
}

const SLImageMarkingWidget = React.forwardRef(({ imageWidgetData }: ImageWidgetProps) => {
  const classes = useStyles();
  const imageRef = useRef<any>(null);
  const [dimensions, setDimensions] = useState({
    height: 0,
    width: 0
  });
  const markers = imageWidgetData?.imageData;
  const DIFFERENCE_IN_PX = 50;

  /**
   * This function will calculate & set the aspect-ratio of
   * the image inside the card container.
   * @param event
   */
  const handleImageLoad = (event: any) => {
    const containerHeight =
      imageRef?.current?.parentNode?.offsetParent?.clientHeight - DIFFERENCE_IN_PX;

    const imageHeight = event.target.naturalHeight;
    const imageWidth = event.target.naturalWidth;

    const aspectRatio = imageWidth / imageHeight;
    setDimensions({
      height: containerHeight,
      width: containerHeight * aspectRatio
    });
  };

  /**
   * Don't change the HTML structure below, because aspect-ratio
   * calculation logic is depending upon the current structure.
   */
  return (
    <Box className={classes.container}>
      <Box
        ref={imageRef}
        className={classes.frame}
        style={{
          height: dimensions.height,
          width: dimensions.width
        }}>
        {/* Below <img /> element is used to get the actual height & width of an image only.
            It's not supposed to display */}
        <img src={imageWidgetData?.image} className={classes.hidden} onLoad={handleImageLoad} />
        <ImageMarker
          src={imageWidgetData?.image}
          markers={markers}
          markerComponent={CustomMarker}
        />
      </Box>
    </Box>
  );
});

export default SLImageMarkingWidget;
