/**
 * Anomaly Templates LIST Action Types
 * @author aditya.bhadange@shorelineiot.com
 */

export const FETCH_ANOMALY_TEMPLATES_LIST =
  'FEATURES/ANOMALY_TEMPLATES/ANOMALY_TEMPLATES_LIST/FETCH';
export const FETCH_ANOMALY_TEMPLATES_LIST_SUCCESS =
  'FEATURES/ANOMALY_TEMPLATES/ANOMALY_TEMPLATES_LIST/FETCH/SUCCESS';
export const FETCH_ANOMALY_TEMPLATES_LIST_FAILURE =
  'FEATURES/ANOMALY_TEMPLATES/ANOMALY_TEMPLATES_LIST/FETCH/FAILURE';
export const RESET_ANOMALY_TEMPLATES_LIST =
  'FEATURES/ANOMALY_TEMPLATES/ANOMALY_TEMPLATES_LIST/RESET';
