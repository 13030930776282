/**
 * Delete Device Mailbox Saga
 * @author prerna.more@shorelineiot.com
 */
import { put, takeEvery, all } from 'redux-saga/effects';
import * as actions from './deleteDeviceMailbox.action';
import * as ACTION_TYPE from './deleteDeviceMailbox.actionTypes';

import { deviceMailboxAction } from '../..';
import { GenericObject, httpDelete, SafeSaga, APISERVICES } from '../../../../../framework';
import { SagaIterator } from 'redux-saga';

function* deleteDeviceMailboxSaga(action: any) {
  const data = action.payload;
  const response: GenericObject = yield httpDelete(
    `orgs/${data?.slug}/device_mailbox`,
    {
      request_ids: data?.request_ids
    },
    APISERVICES.DEVICE_URL
  );
  yield put(actions.deleteDeviceMailboxSuccess(response));
  yield put(deviceMailboxAction.fetchDeviceMailbox(data));
}

function* errorHandler(error: any) {
  yield put(actions.deleteDeviceMailboxFailure(error));
}

export function* watchDeleteDeviceMailboxSagas(): SagaIterator {
  yield all([
    takeEvery(
      ACTION_TYPE.DELETE_DEVICE_MAILBOX_PROGRESS,
      SafeSaga(deleteDeviceMailboxSaga, ACTION_TYPE.DELETE_DEVICE_MAILBOX_PROGRESS, errorHandler)
    )
  ]);
}
