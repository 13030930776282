/**
 * Auth User Saga
 * @author mahesh.kedari@shorelineiot.com
 */

import { put } from 'redux-saga/effects';
import * as actions from '../actions/login.actions';
import { removeProgressFor, showToast } from '../../../../../framework';
import { AuthService } from '../../../../../framework/services/auth.service';
import { AuthSession, SignInOutput } from 'aws-amplify/auth';

const LOGIN_ACTION_PROGRESS_ID = 'LOGIN_ACTION_PROGRESS_ID';

interface AuthenticationObject {
  challengeName: string;
  username: string;
  signInUserSession: any;
}
/**
 *
 */
export function* authUserSaga(action: any) {
  const authService = new AuthService();
  try {
    const response: SignInOutput = yield authService.signIn({
      email: action.body.email,
      password: action.body.password
    });
    const session: AuthSession = yield authService.getCurrentSession();
    const { nextStep } = response;
    switch (nextStep.signInStep) {
      case 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED':
        // const { requiredAttributes } = response.challengeParam;
        // Redirect to Change password screen
        yield put(actions.forceNewPassword(response));
        break;

      default:
        yield put(
          actions.loginSuccess({
            email: action.body.email,
            // username:response.username,
            accessToken: session.tokens?.accessToken.toString(),
            idToken: session.tokens?.idToken?.toString()
            //  refreshToken: response.signInUserSession.refreshToken
          })
        );
        break;
    }
  } catch (error: any) {
    if (error.name === 'UserNotConfirmedException') {
      // The error happens if the user didn't finish the confirmation step when signing up
      // In this case you need to resend the code and confirm the user
      // About how to resend the code and confirm the user, please check the signUp part
      yield put(showToast('Account is not verified, click Resend Verification', 'error'));
      yield put(actions.loginFailure(error));
    } else if (error.name === 'PasswordResetRequiredException') {
      // The error happens when the password is reset in the Cognito console
      // In this case you need to call forgotPassword to reset the password
      // Please check the Forgot Password part.
      yield put(showToast('Password Reset is yet ', 'error', true));
      yield put(actions.loginFailure(error));
    } else if (error.name === 'NotAuthorizedException') {
      // The error happens when the incorrect password is provided
      yield put(showToast('Email address or password is incorrect', 'error', true));
      yield put(actions.loginFailure(error));
    } else if (error.name === 'UserNotFoundException') {
      // The error happens when the supplied username/email does not exist in the Cognito user pool
      yield put(showToast('User does not exist', 'error', true));
      yield put(actions.loginFailure(error));
    } else {
      yield put(showToast('Email address or password is incorrect', 'error', true));
      yield put(actions.loginFailure(error));
    }
  } finally {
    yield put(removeProgressFor(LOGIN_ACTION_PROGRESS_ID));
  }
  // attach iot policies
  // authService.attachIoTPolicies();
}
