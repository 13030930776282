/**
 * Get Unique Array elements based on input key. This method will return a new reference of an array.
 * It will not modify the original one. This method needs just one iteration hence time complexity is O(n).
 * For more efficient approach you may choose to go for map function. But map function will be slower for small arrays.
 * @param array
 * @param key
 * @returns
 */
export function getUniqueElements(array: any[], key: string): any[] {
  const uniqueValues = new Set();
  const uniqueElements: any[] = [];
  array.forEach((item) => {
    if (!uniqueValues.has(item[key])) {
      uniqueValues.add(item[key]);
      uniqueElements.push(item);
    }
  });

  return uniqueElements;
}
