import { AnyAction } from 'redux';
import * as ACTION_TYPE from './deleteDashboard.actionTypes';
import * as LIST_ACTION_TYPE from '../dashboard-list/dashboardList.actionsTypes';
import { deleteDashboardStatus, deleteDashboardState } from './deleteDashboard.state';
/**
 *
 */

/**
 *
 */

const initialState: deleteDashboardState = {
  status: deleteDashboardStatus.INIT
};

function deleteDashboardProgress(state: deleteDashboardState): deleteDashboardState {
  return {
    ...state,
    status: deleteDashboardStatus.PROGRESS
  };
}
function deleteDashboardSuccess(state: deleteDashboardState): deleteDashboardState {
  return {
    ...state,
    status: deleteDashboardStatus.SUCCESS
  };
}
/**
 *
 * @param state
 */

/**
 *
 */

/**
 *
 */
function deleteDashboardFailure(state: deleteDashboardState): deleteDashboardState {
  return {
    ...state,
    status: deleteDashboardStatus.ERROR
  };
}

function deleteDashboardReset(state: deleteDashboardState): deleteDashboardState {
  return {
    ...state,
    status: deleteDashboardStatus.INIT
  };
}
/**
 *
 * @param state
 * @param action
 */
export default function deleteDashboardReducer(
  state: deleteDashboardState = initialState,
  action: AnyAction
): deleteDashboardState {
  switch (action.type) {
    case ACTION_TYPE.DELETE_DASHBOARD_PROGRESS:
      return deleteDashboardProgress(state);
    case ACTION_TYPE.DELETE_DASHBOARD_SUCCESS:
      return deleteDashboardSuccess(state);
    case ACTION_TYPE.DELETE_DASHBOARD_FAILURE:
      return deleteDashboardFailure(state);
    case ACTION_TYPE.DELETE_DASHBOARD_RESET_STATE:
    case LIST_ACTION_TYPE.DASHBOARD_LIST_RESET_STATE:
      return deleteDashboardReset(state);
    default:
      return state;
  }
}
