/**
 * Component Compiler Reducer
 * @author mahesh.kedari@shorelineiot.com
 */
import { combineReducers } from 'redux';
import { componentList } from './component-list';
import { componentDetails } from './component-details';
import { componentAttachList } from './component-apply-to-devices';
/**
 *
 */
const componentCompiler = combineReducers({
  componentList,
  componentDetails,
  componentAttachList
});
/**
 *
 */
export default componentCompiler;
