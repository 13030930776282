/**
 * Alerts List Status
 * @author shubham.holkar@shorelineiot.com
 */
export enum ALERTS_LIST_STATUS {
  INIT,
  FETCHING,
  READY,
  ERROR,
  RESET
}
