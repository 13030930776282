/**
 *
 * @returns
 */
import { CSSInterpolation, CSSObject, Theme } from '@mui/material';

export const CssBaselineStyle:
  | string
  | CSSObject
  | ((theme: Omit<Theme, 'components'>) => CSSInterpolation)
  | undefined = () => ({
  body: {
    fontSize: '1rem',
    '& .Mui-error': {
      fontWeight: 'normal'
    }
  }
});
