/**
 * Anomaly List State
 * @author mahesh.kedari@shorelineiot.com
 */
import { Anomaly } from '../anomaly-details/anomaly-details.state';

/**
 *
 */
export enum ANOMALY_LIST_STATUS {
  INIT,
  FETCHING,
  READY,
  ERROR,
  RESET
}

export interface AnomalyDefinition {
  derived_dp_id: number;
  powertrain: string;
  anomaly_type: string;
  /**
   * If it's `true`, then it's a non sript based anomaly.
   */
  eval_anomaly: boolean;
  anomaly_levels: {
    alert_error?: number;
    alert_warn?: number;
    alert_fatal?: number;
  };
  name: string;
  unit: string;
  suborg_uuid: string;
}

export interface DerivedExpressions {
  derived_expressions: AnomalyDefinition[];
}
/**
 *
 */
export interface AnomalyListState {
  status: ANOMALY_LIST_STATUS;
  list: DerivedExpressions;
}

export interface FetchAnomalyListPayload {
  slug: string;
  isSuperAdmin: boolean;
}

export type SuborgMap = {
  [key: number | string]: string;
};
