/**
 * Anomaly Eval Expr State
 * @author meghneel.adke@shorelineiot.com
 */
/**
 *
 */
export enum EXPR_EVAL_STATUS {
  INIT,
  IN_PROGRESS,
  READY,
  ERROR,
  RESET
}

export interface ExpressionResult {
  eval_result?: Record<string, any>;
}
/**
 *
 */
export interface AnomalyExprEvalState {
  status: EXPR_EVAL_STATUS;
  result: ExpressionResult;
}
