import { put, takeEvery, call, all } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import * as actions from './analogSensor.actions';
import * as ACTION_TYPE from './analogSensor.actionTypes';
import {
  GenericObject,
  httpGet,
  httpPut,
  SafeSaga,
  showToast,
  APISERVICES
} from '../../../../../../framework';
import { SagaIterator } from 'redux-saga';

function getDataAI1(data: any) {
  return httpGet(
    `orgs/${data.slug}/device_sensor_templates/type/${data.templateId}?device_id=${data.deviceId}&sensor_module_name=${data.sensorName}&port=${data.port}`,
    null,
    APISERVICES.DEVICE_API
  );
}
function* analogSensorSagaAI1(action: AnyAction) {
  const response: GenericObject = yield call(getDataAI1, action.payload);
  yield put(actions.getAnalogSensorSchemaSuccessAI1(response));
}

function* errorHandlerAI1(error: any) {
  yield put(actions.getAnalogSensorSchemaFailureAI1(error));
}

function getDataAI2(data: any) {
  return httpGet(
    `orgs/${data.slug}/device_sensor_templates/type/${data.templateId}?device_id=${data.deviceId}&sensor_module_name=${data.sensorName}&port=${data.port}`,
    null,
    APISERVICES.DEVICE_API
  );
}
function* analogSensorSagaAI2(action: AnyAction) {
  const response: GenericObject = yield call(getDataAI2, action.payload);
  yield put(actions.getAnalogSensorSchemaSuccessAI2(response));
}

function* errorHandlerAI2(error: any) {
  yield put(actions.getAnalogSensorSchemaFailureAI2(error));
}

function* addAnalogSensorSaga(action: any) {
  const data = action.payload;
  const body = {
    device_id: data.deviceId,
    sensor_modules: data.modules
  };

  const response: GenericObject = yield httpPut(
    `orgs/${data.slug}/device_sensor_modules/multiple_modules`,
    body,
    APISERVICES.DEVICE_API
  );
  yield put(actions.addAnalogSensorSuccess(response));
  yield put(showToast('Sensor/Sensors added/updated successfully!', 'success'));
  /* yield put(
    datapointActions.fetchDatapoints({
      slug: data.slug,
      deviceId: data.deviceId,
    })
  ); */
}

function* errorHandler(error: any) {
  yield put(actions.addAnalogSensorFailure(error));
}

export function* watchAnalogSensorSagas(): SagaIterator {
  yield all([
    takeEvery(
      ACTION_TYPE.FETCH_ANALOG_SENSOR_SCHEMA_PROGRESS_AI1,
      SafeSaga(
        analogSensorSagaAI1,
        ACTION_TYPE.FETCH_ANALOG_SENSOR_SCHEMA_PROGRESS_AI1,
        errorHandlerAI1
      )
    ),
    takeEvery(
      ACTION_TYPE.FETCH_ANALOG_SENSOR_SCHEMA_PROGRESS_AI2,
      SafeSaga(
        analogSensorSagaAI2,
        ACTION_TYPE.FETCH_ANALOG_SENSOR_SCHEMA_PROGRESS_AI2,
        errorHandlerAI2
      )
    ),
    takeEvery(
      ACTION_TYPE.ADD_ANALOG_SENSOR_PROGRESS,
      SafeSaga(addAnalogSensorSaga, ACTION_TYPE.ADD_ANALOG_SENSOR_PROGRESS, errorHandler)
    )
  ]);
}
