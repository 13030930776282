/**
 * Editor Styles
 * @author mahesh.kedari@shorelineiot.com
 */
import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { grey } from '@mui/material/colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      listStyle: 'none',
      padding: theme.spacing(0.5),
      margin: 0
    },
    chip: {
      margin: theme.spacing(0.5),
      backgroundColor: theme.palette.background.paper,
      borderColor: grey[400],
      borderStyle: 'solid',
      borderWidth: '1px'
    },
    addIcon: {
      fill: theme.palette.primary.main
    },
    spacer: {
      marginLeft: '5px',
      marginRight: '5px'
    },
    divider: {
      marginTop: '25px'
    },
    dropdownListSubHeader: {
      color: theme.palette.primary.main
    },
    indent: {
      textIndent: '18px'
    }
  })
);
