/**
 * User self Saga
 * @author swapnil.kshirsagar@shorelineiot.com
 */
import { takeEvery, call, put } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import * as ACTIONS from '../actions/userSelf.actionTypes';
import * as selfActions from '../actions/userSelf.actions';
import { UserSelf } from '../actions';
import { httpGet, showToast, APISERVICES } from '../../../../framework';
import { SagaIterator } from 'redux-saga';
/**
 *
 */
function getUserSelf(slug: any): Promise<UserSelf> {
  return httpGet(`orgs/${slug}/users/self`, null, APISERVICES.HOST_API);
}

function* getUserSelfSaga(action: AnyAction) {
  try {
    const response: UserSelf = yield call(getUserSelf, action.slug);
    localStorage.setItem('self', JSON.stringify({ [action.slug]: response }));
    yield put(selfActions.fetchSelfSuccess(response));
  } catch (err) {
    yield put(showToast('Failed to fetch User self', 'error', true));
    yield put(selfActions.fetchSelfFailure(err));
  }
}

export function* watchUserSelfSaga(): SagaIterator {
  yield takeEvery(ACTIONS.FETCH_USER_SELF_PROGRESS, getUserSelfSaga);
}
