import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  notification: {
    fill: theme.palette.text.secondary,
    marginTop: '-20px',
    '@media(min-width:960px)': {
      fill: theme.palette.text.primary
    }
  },
  paper: {
    padding: '0px',
    backgroundColor: theme.palette.background.default,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)'
  },
  root: {
    '& .MuiList-padding': {
      padding: '0px !important'
    }
  }
}));
