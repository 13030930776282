/**
 * Stampbox Styles
 * @author mahesh.kedari@shorelineiot.com
 */
import { Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      borderWidth: '0',
      borderRadius: '40px',
      display: 'flex',
      width: 'min-content',
      height: '40px',
      backgroundColor: theme.palette.background.default
    },
    value: {
      overflow: 'hidden',
      display: 'flex',
      color: theme.palette.text.secondary,
      width: '40px',
      height: '100%',
      margin: 'inherit',
      justifyContent: 'center',
      alignItems: 'center',
      borderBottomRightRadius: '40px',
      borderTopLeftRadius: 'inherit',
      borderBottomLeftRadius: 'inherit'
    },
    label: {
      display: 'flex',
      height: '100%',
      margin: 'inherit',
      padding: '20px',
      justifyContent: 'left',
      alignItems: 'center'
    }
  })
);
