/**
 *
 */
import makeStyles from '@mui/styles/makeStyles';

import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles(() =>
  createStyles({
    backdrop: {
      position: 'absolute',
      zIndex: 5,
      color: '#fff'
    }
  })
);
