// /* eslint-disable */
// import axios, { AxiosRequestConfig } from 'axios';
import { AuthService, APISERVICES } from './auth.service';
// import {
//   VITE_ACTIVITY_LOGS_URL,
//   VITE_DEVICE_URL,
//   VITE_HOST_URL
// } from './envConstants';
// import { getAuthenticationHeader } from './http.interceptor';
/**
 *
 */
// const contextPath = `${VITE_HOST_URL}`;
const authService = new AuthService();

/*
    callAPI is the method that makes the actual fetch call to the server that accepts the type of 
    HTTP_METHOD to be performed from the above enum, apiEndPoint and body from the caller function
    and successHandler and errorHandler as optional parameters. callAPI returns a promise based upon
    response from the server and handles these responses based upon its type(success or failure) in 
    their respective handlers.
*/
// const callAPI = (method: HTTP_METHOD, apiEnd: string, body: any, params?: any): Promise<any> => {
//   // FIXME: SLC-627 Temporary fix Update this later.
//   // let updatedPath = apiEnd.toLowerCase().includes('device')
//   //   ? `${VITE_DEVICE_URL}`
//   //   : contextPath;

//   let updatedPath = '';
//   if (apiEnd.toLowerCase().includes('device') || apiEnd.toLowerCase().includes('anomalies')) {
//     updatedPath = `${VITE_DEVICE_URL}`;
//   } else if (apiEnd.toLowerCase().includes('activity_logs')) {
//     updatedPath = `${VITE_ACTIVITY_LOGS_URL}`;
//   } else if (
//     apiEnd.toLowerCase().includes('alerts') ||
//     apiEnd.toLowerCase().includes('derived_expressions')
//   ) {
//     updatedPath = `${VITE_DEVICE_URL}`;
//   } else {
//     updatedPath = contextPath;
//   }

//   if (authService.isConfigured()) {
//     return getAuthenticationHeader().then(() => {
//       return axios({
//         method: HTTP_METHOD[method] as AxiosRequestConfig['method'],
//         url: updatedPath + apiEnd,
//         data: body || null,
//         responseType: 'json',
//         params: params || null
//       });
//     });
//   }
//   return axios({
//     method: HTTP_METHOD[method] as AxiosRequestConfig['method'],
//     url: contextPath + apiEnd,
//     data: body || null,
//     responseType: 'json',
//     params: params || null
//   });
// };

// Import httpPost where you need to trigger the FETCH POST call, pass the parameters accordingly
/**
 *
 * @param apiEndpoint
 * @deprecated  We are going to remove this method [httpPost(apiEndpoint: string, body?: any)] in next version,
 * kindly use [httpPost(apiEndpoint: string, body?: any, service?: APISERVICES)] which has service parameter instead
 * @returns
 */
function httpPost(apiEndpoint: string, body?: any): any;
function httpPost(apiEndpoint: string, body?: any, service?: APISERVICES): any;
function httpPost(apiEndpoint: string, body?: any, service?: APISERVICES) {
  return authService.callAmplifyPostAPI(apiEndpoint, body || null, null, service);
}

export const fileUploadAxiosPut = (apiEndpoint: string, body: any, headers: any = null) => {
  return authService.axiosFileUploadPut(apiEndpoint, body, null, headers);
};

/**
 *
 * @param apiEndpoint
 * @deprecated  We are going to remove this method [fileUploadPost(apiEndpoint: string, body: any, headers: any)] in next version,
 * kindly use [fileUploadPost(apiEndpoint: string, body: any, headers: any, service?: APISERVICES)] which has service parameter instead
 * @returns
 */
function fileUploadPost(apiEndpoint: string, body: any, headers: any): any;
function fileUploadPost(apiEndpoint: string, body: any, headers: any, service?: APISERVICES): any;
function fileUploadPost(apiEndpoint: string, body: any, headers: any, service?: APISERVICES) {
  return authService.axiosFileUploadPost(apiEndpoint, body, null, headers, service); //service
}

/**
 *
 * @param apiEndpoint
 * @deprecated  We are going to remove this method [httpGet(apiEndpoint: string, params?: any)] in next version,
 * kindly use [httpGet(apiEndpoint: string, params?: any, service?: APISERVICES)] which has service parameter instead
 * @returns
 */
function httpGet(apiEndpoint: string, params?: any): any;
/**
 *
 * @param apiEndpoint
 * Do pass service param to this method httpGet(apiEndpoint: string, params?: any, service?: APISERVICES) which has service parameter
 * @returns
 */
function httpGet(apiEndpoint: string, params?: any, service?: APISERVICES): any;
function httpGet(apiEndpoint: string, params?: any, service?: APISERVICES): any {
  return authService.callAmplifyGetAPI(apiEndpoint, params || null, service);
}

// Import httpPut where you need to trigger the FETCH PUT call, pass the parameters accordingly
/**
 *
 * @param apiEndpoint
 * @deprecated  We are going to remove this method [httpPut(apiEndpoint: string, body: any)] in next version,
 * kindly use [httpPut(apiEndpoint: string, body: any, service?: APISERVICES)] which has service parameter instead
 * @returns
 */
function httpPut(apiEndpoint: string, body: any): any;
/**
 *
 * @param apiEndpoint
 * Do pass service param to this method httpGet(apiEndpoint: string, body: any, service?: APISERVICES) which has service parameter
 * @returns
 */
function httpPut(apiEndpoint: string, body: any, service?: APISERVICES): any;
function httpPut(apiEndpoint: string, body: any, service?: APISERVICES) {
  return authService.callAmplifyPutAPI(apiEndpoint, body, service);
}

// Import httpPatch where you need to trigger the FETCH PATCH call, pass the parameters accordingly
/**
 *
 * @param apiEndpoint
 * @deprecated  We are going to remove this method [httpPatch(apiEndpoint: string, body?: any)] in next version,
 * kindly use [httpPatch(apiEndpoint: string, body?: any, service?: APISERVICES)] which has service parameter instead
 * @returns
 */
function httpPatch(apiEndpoint: string, body: any): any;
function httpPatch(apiEndpoint: string, body: any, service?: APISERVICES): any;
function httpPatch(apiEndpoint: string, body: any, service?: APISERVICES) {
  return authService.callAmplifyPatchAPI(apiEndpoint, body, service);
}

// Import httpDelete where you need to trigger the FETCH DELETE call, pass the parameters accordingly
/**
 *
 * @param apiEndpoint
 * @deprecated  We are going to remove this method [httpDelete(apiEndpoint: string, body?: any)] in next version,
 * kindly use [httpDelete(apiEndpoint: string, body?: any, service?: APISERVICES)] which has service parameter instead
 * @returns
 */
function httpDelete(apiEndpoint: string, params?: any): any;
function httpDelete(apiEndpoint: string, params?: any, service?: APISERVICES): any;
function httpDelete(apiEndpoint: string, params?: any, service?: APISERVICES) {
  return authService.callAmplifyDeleteAPI(apiEndpoint, params || null, service);
}
export { httpGet, httpPut, httpPatch, httpDelete, httpPost, fileUploadPost };
