import { put, takeEvery } from 'redux-saga/effects';
import * as actionTypes from '../actions/rolesUpdate.actionTypes';
import * as actionsUpdate from '../actions/rolesUpdateActions';
import {
  GenericObject,
  removeProgressFor,
  showProgressFor,
  httpPut,
  showToast,
  APISERVICES
} from '../../../../../../../framework';
import { SagaIterator } from 'redux-saga';
/**
 *
 * @param data
 */
// function updateRole(data: any) {
//   console.log("data in update saga is", data);
//   const body = {
//     actions: data.dataActions,
//   };
//   return httpPut(`orgs/${data.slug}/role-actions/${data.role}`, body);
// }

function* rolesUpdate(action: any) {
  const data = action.payload;
  const body = {
    actions: data.dataActions
  };
  yield put(showProgressFor('UPDATE ROLES'));
  try {
    // const response: any = yield call(updateRole, action.payload);
    const response: GenericObject = yield httpPut(
      `orgs/${data.slug}/role-actions/${data.role}`,
      body,
      APISERVICES.HOST_API
    );
    yield put(actionsUpdate.updateRoleSucccess(response));
    yield put(showToast('Role Actions updated successfully!', 'success'));
  } catch (error: any) {
    yield put(showToast('Role Actions could not be updated!', 'error', true));
    yield put(actionsUpdate.updateRoleFailure(error));
  } finally {
    yield put(removeProgressFor('UPDATE ROLES'));
  }
}
/**
 *
 */
export default function* watchUpdateRoles(): SagaIterator {
  yield takeEvery(actionTypes.UPDATE_ROLE_INIT, rolesUpdate);
}
