/**
 * Asset Alarms List Styles
 * @author aditys.bhadange@shorelineiot.com
 */
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  redFontColor: {
    color: 'red'
  },
  topMargin: {
    marginTop: '-65px',
    '& .MuiCollapse-root': {
      zIndex: 10,
      position: 'relative'
    }
  }
}));
