/**
 * Column Title Editor
 * @author mahesh.kedari@shorelineiot.com
 *
 */
import React, { ReactElement } from 'react';
import { SLTextField } from '../../../..';

interface Props {
  name: string;
  handleUpdate: () => void;
}

export default function ColumnTitle({ name, handleUpdate }: Props): ReactElement {
  return <SLTextField name={name} label="Column Title" onBlur={handleUpdate} />;
}
