/**
 * User Profile Icon
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { ReactNode, useLayoutEffect, useState, useRef } from 'react';
import { Button, Hidden, Avatar, Typography } from '@mui/material';
import { useStyles } from './userProfile.styles';
import UserProfileMenu from './UserProfileMenu';
import { LightTooltip } from '../../tooltip/Tooltip';
/**
 *
 */
interface Props {
  userName?: string;
  children?: ReactNode;
}
/**
 *
 * @param props Props from parent
 */
export default function UserProfile({ userName = '', children }: Props) {
  const menuId = 'user-profile-menu';
  const [openUserName, setOpen] = useState(false);
  const menuContainerRef = useRef<HTMLDivElement>(null);
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  useLayoutEffect(() => {
    if (menuContainerRef.current !== null) {
      const node = menuContainerRef.current;
      if (node?.scrollWidth > node?.clientWidth) {
        setOpen(true);
      }
    }
  });

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <>
      <Button
        variant="outlined"
        className={classes.profile}
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleClick}>
        <Typography variant="body1" className={classes.username}>
          <LightTooltip
            disableHoverListener={!openUserName}
            title={userName}
            placement="bottom-start">
            <span ref={menuContainerRef} className={classes.textRuleNameOverFlow}>
              <Hidden>{userName}</Hidden>
            </span>
          </LightTooltip>
        </Typography>
        {/* <AccountCircle className={classes.icon} /> */}
        <Avatar className={classes.icon}>
          {userName && userName.length > 0 ? userName[0] : 'S'}{' '}
        </Avatar>
      </Button>
      <UserProfileMenu idFor={menuId} parent={anchorEl} status={open} onMenuClose={setAnchorEl}>
        {children}
      </UserProfileMenu>
    </>
  );
}
