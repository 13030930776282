import React from 'react';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import MultiSelectDropdown from '../../../../../framework/components/dropDown/MultiSelectDropdown';
import { EventType } from '../../store';
import { useTheme } from '@mui/material';
import TranslateText from '../../../../../i18n/TranslateText';

interface EventsSelectDropdownProps {
  deviceEvents: EventType[];
  setSelectedEvent: React.Dispatch<React.SetStateAction<string[] | undefined>>;
  selectedEvent: string[] | undefined;
}

const EventsSelectDropdown = ({
  deviceEvents,
  selectedEvent,
  setSelectedEvent
}: EventsSelectDropdownProps) => {
  const handleOnOptionChange = (events: string[]) => {
    setSelectedEvent(events);
  };

  const handleOnOptionClear = () => {
    setSelectedEvent([]);
  };

  const theme = useTheme();

  return (
    <FormControl sx={{ m: 1, width: '100%' }}>
      <Typography
        style={{ transform: 'translate(0px, -1.5px) scale(0.75)', placeSelf: 'flex-start' }}
        fontWeight={theme.typography.fontWeightMedium}>
        {TranslateText('events', 'Events')}
      </Typography>
      <MultiSelectDropdown
        label=""
        value={selectedEvent}
        treeData={deviceEvents?.map((currentEvent) => ({
          title: `${currentEvent.event_date} - ${currentEvent.event_time}`,
          value: currentEvent.event_id
        }))}
        placeholder="Select Events to plot"
        showArrow
        allowClear
        showSearch
        onTagCloseButton={handleOnOptionChange}
        onDropdownClose={handleOnOptionChange}
        onCloseClick={handleOnOptionClear}
        titleTag
        isCustomTag
        checkboxLimit={30}
      />
    </FormControl>
  );
};

export default EventsSelectDropdown;
