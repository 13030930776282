/**
 * Component Details Reducer
 * @author mahesh.kedari@shorelineiot.com
 */
import { AnyAction } from 'redux';
import {
  COMPONENT_DETAILS_STATUS,
  ComponentDetailsStateType,
  ComponentType,
  MountPointType,
  NarrowbandType,
  updateNBCellColumns
} from './componentDetails.type';
import * as ACTIONS from './componentDetails.actionTypes';
import {
  preProcessTableData,
  addMountPointToNBs,
  updateMountPointToNBs,
  updateMountPointToSingleNB,
  updateSingleNB,
  deleteSingleNB
} from './componentDetails.utils';

/**
 *
 */
const initialState: ComponentDetailsStateType = {
  status: COMPONENT_DETAILS_STATUS.INIT,
  multipleComponents: [],
  mountPoints: [],
  narrowbandData: [],
  originalNarrowbandData: []
};

/**
 *
 * @param state
 * @returns
 */
const handleFetchDetails = (state: ComponentDetailsStateType): ComponentDetailsStateType => {
  return {
    ...state,
    status: COMPONENT_DETAILS_STATUS.FETCHING
  };
};
const handleUpdateDetails = (state: ComponentDetailsStateType): ComponentDetailsStateType => {
  return {
    ...state,
    status: COMPONENT_DETAILS_STATUS.IN_PROGRESS
  };
};
/**
 *
 * @param state
 * @param component
 * @returns
 */
const handleFetchSuccess = (state: ComponentDetailsStateType, component: ComponentType) => {
  const { narrowbandData, mountPoints, originalNarrowbandData } = preProcessTableData(
    component?.narrowbands
  );
  return {
    ...state,
    status: COMPONENT_DETAILS_STATUS.READY,
    data: component,
    narrowbandData,
    mountPoints,
    originalNarrowbandData,
    multipleComponents: state.multipleComponents
      ? [...state.multipleComponents, component]
      : [component]
  };
};

const handleUpdateSuccess = (state: ComponentDetailsStateType, component: ComponentType) => {
  return {
    ...state,
    status: COMPONENT_DETAILS_STATUS.UPDATED,
    data: component
  };
};

const handleFetchFailure = (state: ComponentDetailsStateType) => ({
  ...state,
  status: COMPONENT_DETAILS_STATUS.ERROR
});

const handleAddMountPoint = (state: ComponentDetailsStateType, newMountPoint: MountPointType) => {
  /**
   * This function is when mount point name is updated or adding first mount point
   */
  const { narrowbandData, mountPoints } = addMountPointToNBs(
    state?.narrowbandData,
    state.mountPoints || [],
    newMountPoint
  );

  return {
    ...state,
    narrowbandData,
    mountPoints
  };
};

const handleUpdateMountPoint = (
  state: ComponentDetailsStateType,
  updatedMountPoint: MountPointType
) => {
  /**
   * This function is to add copied mount point to respective narrowbands
   */
  const { narrowbandData, mountPoints } = updateMountPointToNBs(
    state?.narrowbandData,
    state.mountPoints || [],
    updatedMountPoint
  );

  return {
    ...state,
    narrowbandData,
    mountPoints
  };
};
const handleUpdateMountPointOnSingleNB = (
  state: ComponentDetailsStateType,
  data: { checked: boolean; mountPoints: Array<MountPointType>; row: any }
) => {
  /**
   * This function is to enable/disable mount points on narrowband
   */
  const { narrowbandData } = updateMountPointToSingleNB(
    state?.narrowbandData,
    data.mountPoints,
    data.row
  );

  return {
    ...state,
    narrowbandData
  };
};

const handleUpdateSingleNB = (
  state: ComponentDetailsStateType,
  data: {
    narrowbandData: NarrowbandType;
    changedCell: { name: updateNBCellColumns; value: any };
    nbToUpdate: NarrowbandType;
  }
) => {
  /**
   * This function is to update single narrowband info i.e name, path, freq
   */
  const { narrowbandData } = updateSingleNB(
    state.narrowbandData,
    data.changedCell,
    data.nbToUpdate
  );

  return {
    ...state,
    narrowbandData
  };
};

const handleDeleteSingleNB = (
  state: ComponentDetailsStateType,
  data: { nbToDelete: NarrowbandType }
) => {
  /**
   * This function is to update single narrowband info i.e name, path, freq
   */
  const { narrowbandData } = deleteSingleNB(state?.narrowbandData, data.nbToDelete);

  return {
    ...state,
    narrowbandData
  };
};

const handleAddNewNB = (state: ComponentDetailsStateType) => {
  /**
   * This function is to add new NB to the list
   * new and tempId are temporary keys to identify newly added nbs
   */
  const newNB = {
    new: true,
    mount_pts: [],
    cfmax: 0,
    cfmin: 0,
    description: '',
    fmax: 0,
    fmin: 0,
    source: 'cloud',
    freq_order: '',
    name: '',
    path: '',
    tempId: Math.random()
  };

  const narrowbandData = [newNB, ...state.narrowbandData];

  return {
    ...state,
    narrowbandData: narrowbandData
  };
};

const handleUpdateFailure = (state: ComponentDetailsStateType) => ({
  ...state,
  status: COMPONENT_DETAILS_STATUS.ERROR
});

const handleResetMultipleComponents = (state: ComponentDetailsStateType) => ({
  ...state,
  multipleComponents: []
});

const handleCreateDetails = (state: ComponentDetailsStateType) => {
  return {
    ...state,
    status: COMPONENT_DETAILS_STATUS.IN_PROGRESS
  };
};

const handleCreateSuccess = (state: ComponentDetailsStateType, component: ComponentType) => {
  return {
    ...state,
    status: COMPONENT_DETAILS_STATUS.UPDATED,
    data: component
  };
};

const handleCreateFailure = (state: ComponentDetailsStateType) => {
  return {
    ...state,
    /**
     * setting ready so after failure user can make changes on current view
     */
    status: COMPONENT_DETAILS_STATUS.READY
  };
};
const handleResetState = () => {
  return {
    ...initialState
  };
};
/**
 *
 * @param state
 * @param action
 * @returns
 */
export default function componentDetailsReducer(
  state: ComponentDetailsStateType = initialState,
  action: AnyAction
): ComponentDetailsStateType {
  switch (action.type) {
    case ACTIONS.FETCH_COMPONENT_DETAILS:
      return handleFetchDetails(state);
    case ACTIONS.FETCH_COMPONENT_DETAILS_SUCCESS:
      return handleFetchSuccess(state, action.payload);
    case ACTIONS.FETCH_COMPONENT_DETAILS_FAILURE:
      return handleFetchFailure(state);
    case ACTIONS.ADD_COMPONENT_MOUNT_POINTS:
      return handleAddMountPoint(state, action.payload);
    case ACTIONS.UPDATE_COMPONENT_DETAILS:
      return handleUpdateDetails(state);
    case ACTIONS.UPDATE_COMPONENT_DETAILS_SUCCESS:
      return handleUpdateSuccess(state, action.payload);
    case ACTIONS.UPDATE_COMPONENT_DETAILS_FAILURE:
      return handleUpdateFailure(state);
    case ACTIONS.RESET_MULTIPLE_COMPONENT_DETAILS:
      return handleResetMultipleComponents(state);
    case ACTIONS.RESET_COMPONENT_DETAILS:
      return {
        ...initialState
      };
    case ACTIONS.CREATE_COMPONENT_DETAILS:
      return handleCreateDetails(state);
    case ACTIONS.CREATE_COMPONENT_DETAILS_SUCCESS:
      return handleCreateSuccess(state, action.payload);
    case ACTIONS.CREATE_COMPONENT_DETAILS_FAILURE:
      return handleCreateFailure(state);
    case ACTIONS.CREATE_COMPONENT_DETAILS_RESET:
      return handleResetState();
    case ACTIONS.UPDATE_COMPONENT_MOUNT_POINT:
      return handleUpdateMountPoint(state, action.payload);
    case ACTIONS.UPDATE_NB_MOUNT_POINT:
      return handleUpdateMountPointOnSingleNB(state, action.payload);
    case ACTIONS.UPDATE_SINGLE_NB:
      return handleUpdateSingleNB(state, action.payload);
    case ACTIONS.DELETE_SINGLE_NB:
      return handleDeleteSingleNB(state, action.payload);
    case ACTIONS.ADD_NEW_NB:
      return handleAddNewNB(state);

    default:
      return state;
  }
}
