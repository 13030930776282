/**
 * Narrowband rules list reducer
 * @author priyanka.ambawane@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTIONS from './narrowbandRules.actionTypes';
import { NarrowbandRuleListState, NarrowbandRuleListStatus } from './narrowbandRules.state';

const initialState: NarrowbandRuleListState = {
  status: NarrowbandRuleListStatus.INIT,
  response: []
};
/**
 *
 * @param state
 */
function narrowbandRuleListSensors(state: NarrowbandRuleListState): NarrowbandRuleListState {
  return { ...state, status: NarrowbandRuleListStatus.IN_PROGRESS };
}
/**
 *
 * @param state
 * @param payload
 */
function onNarrowbandRuleListSuccess(
  state: NarrowbandRuleListState,
  payload: Array<any>
): NarrowbandRuleListState {
  return {
    ...state,
    status: NarrowbandRuleListStatus.READY,
    response: payload
  };
}
/**
 *
 * @param state
 */
function onNarrowbandRuleListFailure(
  state: NarrowbandRuleListState,
  payload: any
): NarrowbandRuleListState {
  return {
    ...state,
    status: NarrowbandRuleListStatus.ERROR,
    response: payload
  };
}
/**
 *
 * @param state
 */
function resetNarrowbandRuleList(state: NarrowbandRuleListState): NarrowbandRuleListState {
  return {
    ...initialState
  };
}
/**
 *
 * @param state
 * @param action
 */
export function NarrowbandRuleListReducer(
  state: NarrowbandRuleListState = initialState,
  action: AnyAction
): NarrowbandRuleListState {
  switch (action.type) {
    case ACTIONS.NARROWBAND_RULES_LIST:
      return narrowbandRuleListSensors(state);
    case ACTIONS.NARROWBAND_RULES_LIST_SUCCESS:
      return onNarrowbandRuleListSuccess(state, action.payload);
    case ACTIONS.NARROWBAND_RULES_LIST_FAILURE:
      return onNarrowbandRuleListFailure(state, action.payload);
    case ACTIONS.RESET_NARROWBAND_RULES_LIST:
      return resetNarrowbandRuleList(state);
    default:
      return state;
  }
}
