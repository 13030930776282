/**
 * RBAC Actions
 * @author priyanka.ambawane@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTION_TYPE from './rbac.actionTypes';

export const getRBACPermission = (): AnyAction => {
  // returns the action type to login_reducer
  return {
    type: ACTION_TYPE.RBAC_PERMISSION_PROGRESS
  };
};

export const getRBACPermissionSuccess = (data: any): AnyAction => {
  // returns the action type to login_reducer
  return {
    type: ACTION_TYPE.RBAC_PERMISSION_SUCCESS,
    data
  };
};

export const getRBACPermissionFailure = (error: any): AnyAction => {
  // returns the action type to login_reducer
  return {
    type: ACTION_TYPE.RBAC_PERMISSION_FAILURE,
    error
  };
};
