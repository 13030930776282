/**
 * fetch device photos action
 * @author ashwini.gawade@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTIONS from './devicePhotos.actionTypes';
/**
 *
 */
export function loadMultipleDevicePhoto(slug: string, devices: any[]): AnyAction {
  return {
    type: ACTIONS.LOAD_MULTIPLE_DEVICE_PHOTOS,
    payload: { slug, devices }
  };
}
export function loadMultipleDevicePhotoSuccess(data: any): AnyAction {
  return {
    type: ACTIONS.LOAD__MULTIPLE_DEVICE_PHOTOS_SUCCESS,
    payload: data
  };
}

export function loadMultipleDevicePhotoFailure(): AnyAction {
  return {
    type: ACTIONS.LOAD__MULTIPLE_DEVICE_PHOTOS_FAILURE
  };
}

export function loadDevicePhotoFailure(): AnyAction {
  return {
    type: ACTIONS.LOAD_PHOTOS_FAILURE
  };
}

export function resetLoadDevicePhoto(): AnyAction {
  return {
    type: ACTIONS.RESET_LOAD_PHOTOS
  };
}
