/**
 * Tooltip Formatter
 * @author meghneel.adke@shorelineiot.com
 */
import { TooltipFormatterContextObject } from 'highcharts';
import { formattedDate } from '../../../helpers';

export function CartesianChartTooltipFormatter(this: TooltipFormatterContextObject): string {
  let formattedString = '<table>';
  const elem = this.point;
  const currentTime = formattedDate(new Date(this.x || ''));
  formattedString += `<tr><td style="color: {series.color}">Value: </td>`;
  formattedString += `<td style="text-align: right"><b>${elem.y?.toFixed(2)}</b></td></tr>`;
  formattedString += `<tr><td>ts:</td><td style="text-align: right"><b>${currentTime}</b></td></tr></table>`;
  return formattedString;
}
