/**
 * Get Widget Details Actions
 * @author harsha.gokhale@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTION_TYPES from './widgetDetails.actionsTypes';
/**
 *
 */
export function getWidgetDetails(data: any): AnyAction {
  return {
    type: ACTION_TYPES.FETCH_WIDGET_DETAILS_PROGRESS,
    payload: data
  };
}
/**
 *
 * @param data data to be returned on successful fetch API call
 */
export function handleGetWidgetDetailsSuccess(data: any): AnyAction {
  return {
    type: ACTION_TYPES.FETCH_WIDGET_DETAILS_SUCCESS,
    payload: data
  };
}
/**
 *
 */
export function handleGetWidgetDetailsFailure(error: any): AnyAction {
  return {
    type: ACTION_TYPES.FETCH_WIDGET_DETAILS_FAILURE,
    payload: error
  };
}

export const resetState = (): AnyAction => {
  return {
    type: ACTION_TYPES.FETCH_WIDGET_DETAILS_RESET_STATE,
    payload: false
  };
};
