/**
 * Get days of operation Style
 * @author mahesh.kedari@shorelineiot.com
 */
import makeStyles from '@mui/styles/makeStyles';
/**
 *
 */
export const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '3em',
    marginTop: '3vh',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.36)',
    '& .MuiToggleButton-label ': {
      color: theme.palette.text.secondary
    },
    '& .Mui-selected': {
      color: theme.palette.text.secondary,
      backgroundColor: theme.palette.secondary.main,
      '&:hover': {
        backgroundColor: theme.palette.secondary.main
      }
    },
    '& > button': {
      borderRadius: '1em',
      minWidth: '4em',
      border: 'none',
      [theme.breakpoints.down('lg')]: {
        minWidth: '3.4em'
      }
    },
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  }
}));
