/**
 * This is a flexible Container which will have list of widgets which can be resized/ dragged or removed dynamically by the user.
 * TODO: Use react-grid-layout for rendering Flexible Dashboard Container
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Card, Typography } from '@mui/material';
import { Responsive, WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { useStyles } from './slFlexiContainer.styles';
import DashboardSeparator from '../separator/DashboardSeparator';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

interface Props {
  // Array of components to be rendered in Flexi Container
  list: Array<any>;
  // View of the element to be rendered in Flexi Container Card
  Component: React.ComponentType;
  handleLayoutChange: (changedItems: Array<any>) => void;
  handleResize: (itemId: string, width: number, height: number) => void;
  canChange?: boolean;
  gridVisible?: boolean;
}
const SLFlexiContainer = ({
  list,
  Component,
  handleLayoutChange,
  handleResize,
  canChange = false, //turns true only when the dashboard is in edit mode, otherwise remains false
  gridVisible
}: PropsWithChildren<Props>) => {
  const onBreakpointChange = () => {
    /*empty function*/
  };
  const [isDragging, setIsDragging] = useState(false);
  const classes = useStyles();
  const onLayoutChange = (layout: Array<any>) => {
    if (handleLayoutChange) {
      handleLayoutChange(layout);
    }
  };

  const onResize: ReactGridLayout.ItemCallback = (
    layout: ReactGridLayout.Layout[],
    oldItem: ReactGridLayout.Layout,
    newItem: ReactGridLayout.Layout,
    placeholder: ReactGridLayout.Layout,
    event: MouseEvent,
    element: HTMLElement
  ) => {
    const { width, height } = element.parentElement
      ? element.parentElement.getBoundingClientRect()
      : { width: 0, height: 0 };
    setIsDragging(false);
    handleResize(oldItem.i, width, height);
  };

  useEffect(() => {
    if (gridVisible !== undefined || gridVisible !== null) {
      setIsDragging(!!gridVisible);
    }
  }, [gridVisible]);
  return (
    <ResponsiveReactGridLayout
      onBreakpointChange={onBreakpointChange}
      onLayoutChange={onLayoutChange}
      onResizeStop={onResize}
      useCSSTransforms
      compactType="vertical"
      isDraggable={canChange}
      isResizable={canChange}
      margin={[12, 12]}
      containerPadding={[0, 0]}
      onDragStart={() => setIsDragging(true)}
      onDragStop={() => setIsDragging(false)}
      onResizeStart={() => setIsDragging(true)}
      cols={{ lg: 24, md: 24 }}
      breakpoints={{ lg: 24, md: 24 }}
      rowHeight={40}
      className={isDragging ? classes.dragBackground : ''}>
      {list.map(({ id, positionMeta, config, ...otherProps }: any) => {
        if (!positionMeta) {
          // TODO:  Handle Invalid Position Metadata
          return (
            <Typography key={id} variant="h3">
              Invalid Position Details
            </Typography>
          );
        }
        if (config?.viewConfig?.type === 'separator') {
          return (
            <div
              key={id}
              data-grid={{
                x: positionMeta[0],
                y: positionMeta[1],
                w: positionMeta[2],
                h: positionMeta[3],
                minH: 1,
                maxH: 2
              }}
              style={{
                background: 'none'
              }}>
              <DashboardSeparator config={config} {...otherProps} />
            </div>
          );
        }
        return (
          <Card
            className={classes.root}
            key={id}
            data-grid={{
              x: positionMeta[0],
              y: positionMeta[1],
              w: positionMeta[2],
              h: positionMeta[3],
              minW: positionMeta[4] || 2,
              minH: positionMeta[5] || 2
            }}>
            <Component refId={id} config={config} {...otherProps} />
          </Card>
        );
      })}
    </ResponsiveReactGridLayout>
  );
};

export default SLFlexiContainer;
