/**
 * Dashboard Permissions,
 * @author ashwini.gawade@shorelineiot.com
 */
export const DASHBOARD_PERMISSIONS = {
  VIEW: 'view dashboard',
  CREATE: 'create dashboard',
  DELETE: 'delete dashboard',
  UPDATE: 'update dashboard'
};
