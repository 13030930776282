/**
 * Narrowband Reducer
 * @author mahesh.kedari@shorelineiot.com
 */
import { AnyAction } from 'redux';
import { parseRulesConfigToForm } from '../parser/narrowbandRuleParser';

import * as ACTIONS from './manual-config.actionTypes';
import {
  ManualNBConfigState,
  ManualNBConfigStatus,
  MANUAL_NB_FORM_STEPS
} from './manual-config.state';
/**
 *
 */
const initialState: ManualNBConfigState = {
  status: ManualNBConfigStatus.INIT,
  narrowbandConfig: {},
  ruleConditions: {},
  ruleActions: {},
  formStep: MANUAL_NB_FORM_STEPS.NARROWBAND_CONFIG,
  ruleObject: null
};
/**
 *
 * @param state
 */
function onFetchNarrowbandRule(state: ManualNBConfigState): ManualNBConfigState {
  return state;
}
/**
 * Parse Rule Object and Make is compatible with Form using Form parser
 * @param state
 * @param ruleObj
 */
function onFetchNarrowbandRuleSuccess(
  state: ManualNBConfigState,
  ruleObject: any
): ManualNBConfigState {
  // If Rule exists for current narrowband, parse its object to make it compatible to form
  if (ruleObject) {
    const { ruleConditions, ruleActions } = parseRulesConfigToForm(ruleObject);
    return {
      ...state,
      ruleObject,
      ruleConditions,
      ruleActions
    };
  }
  // If rule is not present on current narrowband, state will remain unchanged
  return state;
}
/**
 *
 * @param state
 * @param narrowbandConfig
 */
function onPersistNBConfig(state: ManualNBConfigState, narrowbandConfig: any): ManualNBConfigState {
  return {
    ...state,
    narrowbandConfig,
    formStep: MANUAL_NB_FORM_STEPS.RULES_CONDITIONS
  };
}
/**
 *
 * @param state
 * @param ruleConditions
 */
function onPersistNBRuleConditions(
  state: ManualNBConfigState,
  ruleConditions: any
): ManualNBConfigState {
  return {
    ...state,
    ruleConditions,
    formStep: MANUAL_NB_FORM_STEPS.RULES_ACTIONS
  };
}
/**
 *
 * @param state
 * @param ruleActions
 */
function onPersistNBRuleActions(state: ManualNBConfigState, ruleActions: any): ManualNBConfigState {
  return {
    ...state,
    ruleActions,
    formStep: MANUAL_NB_FORM_STEPS.RULES_CONDITIONS
  };
}
/**
 *
 * @param state
 * @param ruleActions
 */
function onPersistNBRuleActionsAndSubmit(
  state: ManualNBConfigState,
  ruleActions: any
): ManualNBConfigState {
  return {
    ...state,
    ruleActions
  };
}
/**
 *
 * @param state
 * @param formStep
 */
function onUpdateFormStep(
  state: ManualNBConfigState,
  formStep: MANUAL_NB_FORM_STEPS
): ManualNBConfigState {
  return {
    ...state,
    formStep
  };
}
/**
 *
 */
function onResetNarrowbandConfig(): ManualNBConfigState {
  return {
    ...initialState
  };
}

function onNarrowbandNoChane(state: ManualNBConfigState): ManualNBConfigState {
  return { ...state, status: ManualNBConfigStatus.NO_CHANGE };
}
function onNarrowbandInProgress(state: ManualNBConfigState): ManualNBConfigState {
  return { ...state, status: ManualNBConfigStatus.IN_PROGRESS };
}
function onNarrowbandUpdating(state: ManualNBConfigState): ManualNBConfigState {
  return { ...state, status: ManualNBConfigStatus.UPDATING };
}

function onNarrowbandSuccess(state: ManualNBConfigState): ManualNBConfigState {
  return { ...state, status: ManualNBConfigStatus.SUCCESS };
}

/**
 *
 * @param state
 * @param action
 */
export default function ManualConfigReducer(
  state: ManualNBConfigState = initialState,
  action: AnyAction
): ManualNBConfigState {
  switch (action.type) {
    case ACTIONS.FETCH_NB_RULE:
      return onFetchNarrowbandRule(state);
    case ACTIONS.FETCH_NB_RULE_SUCCESS:
      return onFetchNarrowbandRuleSuccess(state, action.payload);
    case ACTIONS.PERSIST_NB_CONFIG:
      return onPersistNBConfig(state, action.payload);
    case ACTIONS.PERSIST_NB_RULE_CONDITIONS:
      return onPersistNBRuleConditions(state, action.payload);
    case ACTIONS.PERSIST_NB_RULE_ACTIONS:
      return onPersistNBRuleActions(state, action.payload);
    case ACTIONS.PERSIST_NB_RULE_ACTIONS_AND_SUBMIT:
      return onPersistNBRuleActionsAndSubmit(state, action.payload);
    case ACTIONS.NB_NO_CHANGE:
      return onNarrowbandNoChane(state);
    case ACTIONS.NB_IN_PROGRESS:
      return onNarrowbandInProgress(state);
    case ACTIONS.NB_UPDATING:
      return onNarrowbandUpdating(state);
    case ACTIONS.NB_SUCCESS:
      return onNarrowbandSuccess(state);
    case ACTIONS.UPDATE_FORM_STEP:
      return onUpdateFormStep(state, action.payload);
    case ACTIONS.RESET_NB_CONFIG:
      return onResetNarrowbandConfig();

    default:
      return state;
  }
}
