export enum VA_NARROWBANDS_STATUS {
  INIT,
  FETCHING,
  ERROR,
  READY
}

export interface NormalizedNarrowbandsType {
  name: string;
  dpid: number;
  fmax: number;
  fmin: number;
  cfmax: number;
  cfmin: number;
  nb_type: string;
  raw_dpid: number;
  raw_dp_name: string;
  peak_amplitude: number;
  frequency_at_peak_amp: number;
  [name: string]: any;
}
export interface LatestRawNBType {
  name: string;
  description: string | null;
  dpid: number;
  fmax: number;
  fmin: number;
  raw_dpid: number;
  raw_dp_type: string;
  device_name: string;
  nb_type: string;
  raw_dp_name: string;
  [name: string]: any;
}

export interface SelectedNarrowband {
  key: string;
  name: string;
  dpid: number;
  fmax: number;
  fmin: number;
  nb_type: string;
  raw_dpid: number;
  raw_dp_name: string;
  peak_amplitude: number;
  frequency_at_peak_amp: number;
  deviceId: number;
  eventId: number;
  raw_dpname: string;
  odr: number;
  deviceName: string;
  type: string;
  rawName: string;
  fmin_in_HZ: number;
  fmax_in_HZ: number;
  frequency_at_peak_amp_in_HZ: number;
  peak_amplitude_in_hz: number;
}

export interface VANarrowbandsState {
  status: VA_NARROWBANDS_STATUS;
  narrowbands: Array<any>;
  checkedNarrowbands?: Array<any>;
  selectedNarrowband?: SelectedNarrowband;
  rawNarrowbands?: Array<NormalizedNarrowbandsType>;
  selectedODR: number;
  latestNarrowbandsForDevices: Array<LatestRawNBType>;
  latestNBStatus: VA_NARROWBANDS_STATUS;
}
export interface MultiDeviceNarrowbandsListType {
  deviceId: number;
  deviceName: string;
  dpids?: Array<number>;
  eventId?: number;
  narrowbands: Array<NormalizedNarrowbandsType>;
}
