/**
 * fetch device photos Saga
 * @author ashwini.gawade@shorelineiot.com
 */
import { put, takeEvery, call, all } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import * as actions from './devicePhotos.action';
import * as ACTION_TYPES from './devicePhotos.actionTypes';
import { httpGet, SafeSaga, APISERVICES } from '../../../../../../../framework';
import { SagaIterator } from 'redux-saga';
import { photo } from './devicePhotos.state';

function getPhotos(data: { slug: string; deviceId: number }) {
  return httpGet(
    `orgs/${data.slug}/devices/${data.deviceId}/device_photos_meta`,
    null,
    APISERVICES.DEVICE_API
  );
}

function* loadMultipleDevicePhotosSaga(action: AnyAction) {
  const { devices, slug } = action.payload;
  const multipleDevicePhotos: { device_photos_meta: Array<photo> }[] = yield all(
    devices.map((data: any) => {
      return call(getPhotos, { deviceId: data.device_id, slug });
    })
  );
  const flatDevicesPhotos: photo[] = [];
  multipleDevicePhotos.forEach((dp: { device_photos_meta: Array<photo> }, index: number) => {
    dp?.device_photos_meta?.forEach((meta: photo) => {
      flatDevicesPhotos.push({ ...meta, caption: `${devices[index]?.device_name} - ${meta.view}` });
    });
  });
  yield put(actions.loadMultipleDevicePhotoSuccess(flatDevicesPhotos));
}

function* errorHandler() {
  yield put(actions.loadDevicePhotoFailure());
}
/**
 *
 */
export function* watchLoadDevicePhotosSagas(): SagaIterator {
  yield takeEvery(
    ACTION_TYPES.LOAD_MULTIPLE_DEVICE_PHOTOS,
    SafeSaga(loadMultipleDevicePhotosSaga, ACTION_TYPES.LOAD_MULTIPLE_DEVICE_PHOTOS, errorHandler)
  );
}
