/**
 * Update Dashboard Item
 * @author harsha.gokhale@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTION_TYPES from './updateDashboardItem.actionsTypes';
/**
 *
 */
export function updateDashboardItem(data: any): AnyAction {
  return {
    type: ACTION_TYPES.UPDATE_DASHBOARD_ITEM_PROGRESS,
    payload: data
  };
}
/**
 *
 * @param data data to be returned on successful fetch API call
 */
export function handleUpdateDashboardItemSuccess(data: any): AnyAction {
  return {
    type: ACTION_TYPES.UPDATE_DASHBOARD_ITEM_SUCCESS,
    payload: data
  };
}
/**
 *
 */
export function handleUpdateDashboardItemFailure(error: any): AnyAction {
  return {
    type: ACTION_TYPES.UPDATE_DASHBOARD_ITEM_FAILURE,
    payload: error
  };
}

export const resetState = (): AnyAction => {
  return {
    type: ACTION_TYPES.UPDATE_DASHBOARD_ITEM_RESET_STATE,
    payload: false
  };
};
