import moment from 'moment';
import { DeviceReportsObj } from './deviceReports.state';

const dateFormatConverter = (LastConnected: any) => {
  const localDate = new Date(LastConnected);
  const dateTime = moment.unix(Number(localDate.getTime()) / 1000).format('MMM D, YYYY hh:mm:ss A');
  return dateTime;
};

/**
 * Transforms the data points in a device report by converting each sensor name to camel case and adding
 * additional properties to each data point.
 *
 * @param {DeviceReportsObj} deviceReports - The device report to transform.
 * @returns {DeviceReportsObj} - The transformed device report.
 */
export const enhanceDeviceReportWithDatapoints = (
  deviceReports: DeviceReportsObj
): DeviceReportsObj => {
  const transformedDeviceReports = {
    device_summary: deviceReports?.device_summary?.map((deviceSummary) => {
      const transformedDataPoints: DeviceReportsObj = deviceSummary.datapoint_count_list.reduce(
        (acc: any, dataPoint) => {
          const camelCasedName = dataPoint.sensor_name
            .split(' ')
            .map(function (word, index) {
              // Convert the first word to lowercase
              if (index === 0) {
                return word.toLowerCase();
              }
              // Convert the rest of the words to uppercase
              return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
            })
            .join('');
          acc[camelCasedName] = {
            sensor_name: dataPoint.sensor_name,
            actual_count: dataPoint.actual_count,
            expected_count: dataPoint.expected_count,
            min_value: dataPoint.min_value,
            max_value: dataPoint.max_value,
            data: `(${dataPoint.actual_count}/${dataPoint.expected_count}) MAX: ${dataPoint.max_value} MIN: ${dataPoint.min_value}`
          };
          return acc;
        },
        {}
      );

      const lastConnected = dateFormatConverter(deviceSummary.last_connected_ts);
      return {
        ...deviceSummary,
        ...transformedDataPoints,
        lastConnected: lastConnected
      };
    })
  };

  return transformedDeviceReports;
};
