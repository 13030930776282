/**
 * Create Anomaly template Reducer
 * @author aditya.bhadange@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTIONS from './createAnomalyTemplate.actionTypes';
import {
  createAnomalyTemplateState,
  createAnomalyTemplateStatus
} from './createAnomalyTemplate.state';
/**
 *
 */
const initialState: createAnomalyTemplateState = {
  status: createAnomalyTemplateStatus.INIT,
  payload: []
};
/**
 *
 * @param state
 * @returns
 */
const handleCreateAnomalyTemplate = (
  state: createAnomalyTemplateState
): createAnomalyTemplateState => {
  return {
    ...state,
    status: createAnomalyTemplateStatus.PROGRESS
  };
};
/**
 *
 * @param state
 * @returns
 */
const handleCreateAnomalyTemplateSuccess = (state: createAnomalyTemplateState, payload: any) => {
  return {
    ...state,
    status: createAnomalyTemplateStatus.SUCCESS,
    payload
  };
};
/**
 *
 * @param state
 * @returns
 */
const handleFetchFailure = (state: createAnomalyTemplateState, payload: any) => ({
  ...state,
  status: createAnomalyTemplateStatus.ERROR,
  payload
});
/**
 *
 * @param state
 * @param action
 * @returns
 */
export default function createAnomalyTemplateReducer(
  state: createAnomalyTemplateState = initialState,
  action: AnyAction
): createAnomalyTemplateState {
  switch (action.type) {
    case ACTIONS.CREATE_ANOMALY_TEMPLATE:
      return handleCreateAnomalyTemplate(state);
    case ACTIONS.CREATE_ANOMALY_TEMPLATE_SUCCESS:
      return handleCreateAnomalyTemplateSuccess(state, action.payload);
    case ACTIONS.CREATE_ANOMALY_TEMPLATE_FAILURE:
      return handleFetchFailure(state, action.payload);
    case ACTIONS.RESET_CREATE_ANOMALY_TEMPLATE:
      return {
        ...initialState
      };
    default:
      return state;
  }
}
