/**
 * Organisation Slug Context
 * @author mahesh.kedari@shorelineiot.com
 */
import { useContext, createContext, ReactNode } from 'react';

type ContextProps = {
  actions: ReactNode;
  setActions: (actions: ReactNode) => void;
};
/**
 *
 */
export const ActionContext = createContext<Partial<ContextProps>>({});
/**
 *
 */
export function useActionContext() {
  return useContext(ActionContext);
}
