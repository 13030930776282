/**
 * Component LIST Action Types
 * @author mahesh.kedari@shorelineiot.com
 */

export const FETCH_COMPONENT_ATTACH_LIST =
  'FEATURES/COMPONENT_COMPILER/COMPONENT_ATTACH_LIST/FETCH';
export const FETCH_COMPONENT_ATTACH_LIST_SUCCESS =
  'FEATURES/COMPONENT_COMPILER/COMPONENT_ATTACH_LIST/FETCH/SUCCESS';
export const FETCH_COMPONENT_ATTACH_LIST_FAILURE =
  'FEATURES/COMPONENT_COMPILER/COMPONENT_ATTACH_LIST/FETCH/FAILURE';
export const RESET_COMPONENT_ATTACH_LIST =
  'FEATURES/COMPONENT_COMPILER/COMPONENT_ATTACH_LIST/RESET';

export const UPDATE_COMPONENT_ATTACH_LIST =
  'FEATURES/COMPONENT_COMPILER/COMPONENT_ATTACH_LIST/UPDATE';
export const UPDATE_COMPONENT_ATTACH_LIST_SUCCESS =
  'FEATURES/COMPONENT_COMPILER/COMPONENT_ATTACH_LIST/UPDATE/SUCCESS';
export const UPDATE_COMPONENT_ATTACH_LIST_FAILURE =
  'FEATURES/COMPONENT_COMPILER/COMPONENT_ATTACH_LIST/UPDATE/FAILURE';
