/**
 * Additional Sensors Reducer
 * @author mahesh.kedari@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTIONS from './additionalSensors.actionTypes';
import {
  CreateAdditionalSensorsState,
  CreateAdditionalSensorsStatus
} from './additionalSensors.state';

const initialState: CreateAdditionalSensorsState = {
  status: CreateAdditionalSensorsStatus.INIT,
  response: []
};
/**
 *
 * @param state
 */
function createAdditionalSensors(
  state: CreateAdditionalSensorsState
): CreateAdditionalSensorsState {
  return { ...state, status: CreateAdditionalSensorsStatus.IN_PROGRESS };
}
/**
 *
 * @param state
 * @param payload
 */
function onCreateAdditionalSensorsSuccess(
  state: CreateAdditionalSensorsState,
  payload: Array<any>
): CreateAdditionalSensorsState {
  return {
    ...state,
    status: CreateAdditionalSensorsStatus.READY,
    response: payload
  };
}
/**
 *
 * @param state
 */
function onCreateAdditionalSensorsFailure(
  state: CreateAdditionalSensorsState
): CreateAdditionalSensorsState {
  return {
    ...state,
    status: CreateAdditionalSensorsStatus.ERROR
  };
}
/**
 *
 * @param state
 */
function resetCreateAdditionalSensors(): CreateAdditionalSensorsState {
  return {
    ...initialState
  };
}
/**
 *
 * @param state
 * @param action
 */
export default function CreateAdditionalSensorReducer(
  state: CreateAdditionalSensorsState = initialState,
  action: AnyAction
): CreateAdditionalSensorsState {
  switch (action.type) {
    case ACTIONS.CREATE_SENSOR:
      return createAdditionalSensors(state);
    case ACTIONS.CREATE_SENSOR_SUCCESS:
      return onCreateAdditionalSensorsSuccess(state, action.payload);
    case ACTIONS.CREATE_SENSOR_FAILURE:
      return onCreateAdditionalSensorsFailure(state);
    case ACTIONS.RESET_CREATE_SENSOR:
      return resetCreateAdditionalSensors();
    default:
      return state;
  }
}
