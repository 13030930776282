/**
 * Device Permissions
 * @author mahesh.kedari@shorelineiot.com
 */

export const DEVICE_PERMISSIONS = {
  ADD_DEVICE: 'add device',
  CREATE_POWERTRAIN: 'create powertrain',
  VIEW_LIST: 'view device',
  VIEW_DETAILS: 'view_details device',
  UPDATE: 'update device',
  VIEW_DATA: 'view_data device',
  ACTIONS: 'device actions',
  LIVE_DATA: 'device live_data',
  ATTACH_PROFILE: 'device attach_profile',
  DELETE: 'delete device',
  APPLY_FIRMWARE: 'device firmware_apply',
  REBOOT: 'reboot device',
  STANDBY: 'standby device',
  DEACTIVATE: 'deactivate device',
  ADD_RULE: 'device add_rule',
  VIEW_DEBUG_LOGS: 'view device_debug_log',
  UPDATE_RULE: 'device update_rule',
  REPROVISION: 'reprovision device',
  CPM: 'update device cpm',
  UPDATE_SIM_SETTING: 'update sim settings',
  SCHEDULE_UPDATE: 'device schedule update',
  SENSOR_SETTINGS: 'update sensor settings',
  ODR_UPDATE: 'device odr update',
  ODR_SAMPLES_UPDATE: 'device odr_samples update',
  CREATE_GROUP: 'create group',
  UPDATE_GROUP: 'update group',
  DELETE_GROUP: 'delete group',
  VIEW_GROUP: 'view groups',
  CREATE_NB: 'create auto narrowband',
  UPDATE_NB: 'update auto narrowband',
  READ_NB: 'view auto narrowband',
  DELETE_NB: 'delete auto narrowband',
  DELETE_POWERTRAIN: 'delete powertrain',
  UPDATE_POWERTRAIN: 'update powertrain'
};
