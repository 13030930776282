/**
 * Update Multiple Dashboard Items Reducer
 */
import { AnyAction } from 'redux';
import * as ACTION_TYPE from './updateMultipleDashboardItems.actionsTypes';
import {
  UpdateMultipleDashboardItemsState,
  UpdateMultipleDashboardItemsStatus
} from './updateMultipleDashboardItems.stateType';

const initialState: UpdateMultipleDashboardItemsState = {
  status: UpdateMultipleDashboardItemsStatus.INIT,
  updateMultipleDashboardItemsData: {}
};

function updateMultipleDashboardItemsProgress(
  state: UpdateMultipleDashboardItemsState
): UpdateMultipleDashboardItemsState {
  return {
    ...state,
    status: UpdateMultipleDashboardItemsStatus.FETCHING
  };
}

function updateMultipleDashboardItemsSuccess(
  state: UpdateMultipleDashboardItemsState,
  updateMultipleDashboardItemsData: any
): UpdateMultipleDashboardItemsState {
  return {
    ...state,
    status: UpdateMultipleDashboardItemsStatus.READY,
    updateMultipleDashboardItemsData
  };
}

function updateMultipleDashboardItemsFailure(
  state: UpdateMultipleDashboardItemsState
): UpdateMultipleDashboardItemsState {
  return {
    ...state,
    status: UpdateMultipleDashboardItemsStatus.ERROR
  };
}

function resetState(state: UpdateMultipleDashboardItemsState): UpdateMultipleDashboardItemsState {
  return {
    ...state,
    status: UpdateMultipleDashboardItemsStatus.INIT,
    updateMultipleDashboardItemsData: {}
  };
}
/*
    Reducer is a pure function that takes actions and state as input 
    and returns/passes the modified state to the store.
    It is implemented using switch case to update the state according 
    to the ation type required.
*/

export default function updateMultipleDashboardItemsReducer(
  state: UpdateMultipleDashboardItemsState = initialState,
  action: AnyAction
): UpdateMultipleDashboardItemsState {
  switch (action.type) {
    case ACTION_TYPE.UPDATE_MULTIPLE_DASHBOARD_ITEMS_PROGRESS:
      return updateMultipleDashboardItemsProgress(state);
    case ACTION_TYPE.UPDATE_MULTIPLE_DASHBOARD_ITEMS_SUCCESS:
      return updateMultipleDashboardItemsSuccess(state, action.payload);
    case ACTION_TYPE.UPDATE_MULTIPLE_DASHBOARD_ITEMS_FAILURE:
      return updateMultipleDashboardItemsFailure(state);
    case ACTION_TYPE.UPDATE_MULTIPLE_DASHBOARD_ITEMS_RESET_STATE:
      return resetState(state);
    default:
      return state;
  }
}
