/**
 * Sidebar header Styles
 * @author mahesh.kedari@shorelineiot.com
 */
import { Theme, alpha } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  sidebarHeader: {
    backgroundColor: alpha(theme.palette.common.white, 0.06)
  }
}));
