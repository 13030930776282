import { AnyAction } from 'redux';
import * as ACTION_TYPES from './deviceLiveData.actionTypes';

export const initialiseDeviceDataSaga = (topic: Array<string>): AnyAction => {
  return {
    type: ACTION_TYPES.INITIATE_SUBSCRIPTION_TO_TOPIC,
    payload: topic
  };
};

export const getDeviceDataSuccess = (data: any): AnyAction => {
  return {
    type: ACTION_TYPES.GET_DEVICE_DATA_SUCCESS,
    payload: data
  };
};

export const getDeviceDataFailure = (error: any): AnyAction => {
  return {
    type: ACTION_TYPES.GET_DEVICE_DATA_FAILURE,
    payload: error
  };
};

export const cancelSubscription = (): AnyAction => {
  return {
    type: ACTION_TYPES.CANCEL_SUBSCRIPTION_TO_TOPIC
  };
};
