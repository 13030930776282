/**
 * Organisation Theme Context
 * @author mahesh.kedari@shorelineiot.com
 */
import { useContext, createContext } from 'react';
import { Theme } from '@mui/material/styles';

type ContextProps = {
  theme: Theme;
  setTheme: Function;
};

export const ThemeContext = createContext<Partial<ContextProps>>({});

export function useThemeContext() {
  return useContext(ThemeContext);
}
