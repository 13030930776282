/**
 * Selected Organisation Reducer
 * @author mahesh.kedari@shorelineiot.com
 */
import { AnyAction } from 'redux';
import { Organisation } from '../types/Organisation';
import * as ACTIONS from './orgSelector.actionTypes';
import { OrgSelectorState, ORG_SELECTOR_STATUS } from '../types/orgSelector.state';

const initialState: OrgSelectorState = {
  status: ORG_SELECTOR_STATUS.UNKNOWN,
  selectedOrg: null
};
/**
 *
 * @param state old state
 * @param selectedOrg Selected Organisation
 */
function onSwitchOrg(state: OrgSelectorState, selectedOrg: Organisation): OrgSelectorState {
  return {
    ...state,
    status: ORG_SELECTOR_STATUS.UPDATING_SELECTION,
    selectedOrg
  };
}
/**
 *
 * @param state old state
 */
function onSwitchOrgSuccess(state: OrgSelectorState, selectedOrg: Organisation): OrgSelectorState {
  return {
    ...state,
    status: ORG_SELECTOR_STATUS.READY,
    selectedOrg
  };
}
/**
 *
 * @param state old state
 */
function onSwitchOrgFailure(state: OrgSelectorState): OrgSelectorState {
  return {
    ...state,
    status: ORG_SELECTOR_STATUS.ERROR
  };
}
/**
 *
 * @param state
 */
function onNoOrgFound(state: OrgSelectorState): OrgSelectorState {
  return {
    ...state,
    status: ORG_SELECTOR_STATUS.NO_ORG_FOUND
  };
}
/**
 *
 * @param state
 */
function onSwitchSelectOrg(state: OrgSelectorState): OrgSelectorState {
  return {
    ...state,
    status: ORG_SELECTOR_STATUS.SELECT
  };
}
/**
 *
 * @param state
 * @param action
 */
export default function selectedOrgReducer(
  state: OrgSelectorState = initialState,
  action: AnyAction
): OrgSelectorState {
  switch (action.type) {
    case ACTIONS.SWITCH_ORG_INIT:
      return onSwitchOrg(state, action.payload);
    case ACTIONS.SWITCH_ORG_SUCCESS:
      return onSwitchOrgSuccess(state, action.payload);
    case ACTIONS.SWITCH_ORG_FAILED:
      return onSwitchOrgFailure(state);
    case ACTIONS.NO_ORG_FOUND:
      return onNoOrgFound(state);
    case ACTIONS.SWITCH_ORG_SELECT: {
      return onSwitchSelectOrg(state);
    }
    default:
      return state;
  }
}
