import { Box, Grid, Typography } from '@mui/material';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useState, useEffect } from 'react';
import WidgetDataUnavailable from '../widget-data-unavailable/WidgetDataUnavailable';
import { useStyles } from './gaugeWidget.styles';
import { gaugeWidgetSettingsGenerator } from './gaugeWidgetSettingsGenerator';
import TranslateText from '../../../../i18n/TranslateText';

interface GaugeData {
  min: number;
  max: number;
  /**
   * Current value
   */
  avg: number;
  /**
   * Range contains the ```start``` & ```end``` value to
   * be plotted in the chart.
   */
  range: any;
  units: string;
  message?: string;
}

interface GaugeProps {
  data: GaugeData;
  widgetSettings: any;
}

const SLGaugeWidget = React.forwardRef(({ data, widgetSettings }: GaugeProps, ref: any) => {
  const classes = useStyles();
  const [gaugeSettings, setGaugeSettings] = useState(
    gaugeWidgetSettingsGenerator(
      data?.avg,
      data?.range,
      data?.units,
      widgetSettings,
      ref?.current?.container?.current?.parentNode?.offsetParent?.clientHeight
    )
  );

  useEffect(() => {
    setGaugeSettings(
      gaugeWidgetSettingsGenerator(
        data?.avg,
        data?.range,
        data?.units,
        widgetSettings,
        ref?.current?.container?.current?.parentNode?.offsetParent?.clientHeight
      )
    );
  }, [data?.avg, data?.range, data?.units, widgetSettings]);

  if (!data || (data?.message && data?.message === 'No data')) {
    return <WidgetDataUnavailable />;
  }

  return (
    <Box>
      <Box>
        <HighchartsReact highcharts={Highcharts} options={gaugeSettings} ref={ref} />
      </Box>
      <Box>
        <Grid container xs={12} justifyContent="space-between" alignItems="center">
          <Grid item className={classes.minMax}>
            <Typography variant="subtitle1">
              {TranslateText('min', 'Min')}: {data?.min}
            </Typography>
          </Grid>
          <Grid item className={classes.minMax}>
            <Typography variant="subtitle1">
              {TranslateText('max', 'Max')}: {data?.max}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
});

export default SLGaugeWidget;
