/**
 * Datapoints Aggregator Saga
 * @author mahesh.kedari@gmail.com
 */
import { all } from 'redux-saga/effects';
import { watchHistoricalDataSaga } from './historical.saga';
import { watchDatapointsSagas } from './fetchDatapoints.saga';
import { watchChartConfigSaga } from './chartConfig.saga';
import { watchDataSaga } from './fetchData.saga';
import { watchSetDataSaga } from './setData.saga';
import { watchAdditionalSensorSaga } from './additionalSensor.saga';
import { watchUpdateDatapointsSaga } from './updateDatapoints.saga';
import { watchTopicSaga } from './fetchTopic.saga';
import { watchOnlyDatapointsSagas } from './fetchOnlyDatapoints.saga';
import { watchCreateNarrowbandRuleSaga } from '../../../narrowband-rule-create/createNarrowbandRules.saga';
import { watchNarrowbandRuleListSaga } from '../../../narrowband-rules-list/narrowbandRules.saga';
import { watchUpdateNarrowbandRuleSaga } from '../../../narrowband-rules-update/updateNarrowbandRules.saga';

/**
 *
 */
export default function* watchDeviceDataSaga() {
  yield all([
    watchDatapointsSagas(),
    watchOnlyDatapointsSagas(),
    watchHistoricalDataSaga(),
    watchTopicSaga(),
    watchChartConfigSaga(),
    watchDataSaga(),
    watchSetDataSaga(),
    watchAdditionalSensorSaga(),
    watchUpdateDatapointsSaga(),
    watchCreateNarrowbandRuleSaga(),
    watchNarrowbandRuleListSaga(),
    watchUpdateNarrowbandRuleSaga()
  ]);
}
