/**
 * Additional Sensors Action Types
 * @author mahesh.kedari@shorelineiot.com
 */
export const SET_DRAWER_OPEN_STATUS =
  'FEATURES/DEVICE/DEVICE_DETAILS/ADDITIONAL_SENSORS/SET_DRAWER_OPEN_STATUS';

export const FETCH_SENSORS_SCHEMA =
  'FEATURES/DEVICE/DEVICE_DETAILS/ADDITIONAL_SENSORS/SCHEMA/FETCH';
export const SENSORS_SCHEMA_SUCCESS =
  'FEATURES/DEVICE/DEVICE_DETAILS/ADDITIONAL_SENSORS/SCHEMA/SUCCESS';
export const SENSORS_SCHEMA_FAILURE =
  'FEATURES/DEVICE/DEVICE_DETAILS/ADDITIONAL_SENSORS/SCHEMA/FAILURE';
export const SENSORS_SCHEMA_RESET =
  'FEATURES/DEVICE/DEVICE_DETAILS/ADDITIONAL_SENSORS/SCHEMA/RESET';

export const CREATE_SENSOR = 'FEATURES/DEVICE/DEVICE_DETAILS/ADDITIONAL_SENSOR/CREATE';
export const CREATE_SENSOR_SUCCESS =
  'FEATURES/DEVICE/DEVICE_DETAILS/ADDITIONAL_SENSOR/CREATE/SUCCESS';
export const CREATE_SENSOR_FAILURE =
  'FEATURES/DEVICE/DEVICE_DETAILS/ADDITIONAL_SENSOR/CREATE/FAILURE';
export const RESET_CREATE_SENSOR = 'FEATURES/DEVICE/DEVICE_DETAILS/ADDITIONAL_SENSOR/CREATE/RESET';
