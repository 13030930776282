import { APISERVICES } from '../../../../services';
import { featureApi } from '../../../../../features/feature.api';
import { DeviceAirborneDataType, FetchAirborneDataArgs } from '../leakDetectionTypes';
import { DEVICE_TAG } from '../../../../../features/device/store/device.types';

export const deviceAirborneDataApi = featureApi.injectEndpoints({
  endpoints: (build) => ({
    fetchAirborneData: build.query<DeviceAirborneDataType, FetchAirborneDataArgs>({
      query: ({ slug, id, startDate, endDate }) => ({
        apiEndpoint: `orgs/${slug}/devices/${id}/datapoints/historical_data_without_sampling?start=${startDate}&end=${endDate}&maxPoints=40000&dpids=3000016`,
        params: null,
        service: APISERVICES.DEVICE_API,
        method: 'GET',
        actionKey: 'FETCH_AIRBORNE_DATA',
        showProgressBar: true
      }),
      providesTags: [DEVICE_TAG.AIRBORNE_DATA]
    })
  })
});

export const { useFetchAirborneDataQuery, useLazyFetchAirborneDataQuery } = deviceAirborneDataApi;
