/**
 * Cellular Widget Styles
 * @author aditys.bhadange@shorelineiot.com
 */
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  resizableIcon: {
    height: 'auto',
    width: '60%',
    minWidth: '40px',
    maxWidth: '200px',
    minHeight: '40px',
    maxHeight: '180px'
  },
  containerize: {
    width: '95%',
    margin: 'auto'
  },
  autoMargin: {
    margin: 'auto'
  },
  alignLeft: {
    textAlign: 'left'
  },
  alignRight: {
    textAlign: 'right'
  }
}));
