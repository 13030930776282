/**
 * Org Manu Style
 * @author mahesh.kedari@shorelineiot.com
 */
import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  footer: {
    zIndex: 10000,
    position: 'fixed',
    bottom: '0px',
    opacity: 0.5,
    width: '100%',
    textAlign: 'center',
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.grey[200]
  }
}));
