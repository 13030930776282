/**
 * Popover Component
 * @author mahesh.kedari@shorelineiot.com
 */
import { Button, ButtonProps, Popover, PopoverProps } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import { toSnakeCase } from '../../../i18n/translation.helper';
import TranslateText from '../../../i18n/TranslateText';

interface Props {
  buttonProps?: ButtonProps;
  popoverProps?: Omit<PopoverProps, 'open' | 'anchorEl'>;
  label: string;
}

export const SLPopover = ({
  buttonProps = {},
  popoverProps = {},
  label,
  children
}: PropsWithChildren<Props>) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const open = Boolean(anchorEl);
  const id = open ? `popover-${Math.random()}` : undefined;
  const { size, color, variant, onClick, ...otherButtonProps } = buttonProps;
  const { onClose, anchorOrigin, transformOrigin, ...otherPopoverProps } = popoverProps;
  const handleClose = (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (onClose) {
      onClose(event, reason);
    }
    setAnchorEl(null);
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <>
      <Button
        aria-describedby={id}
        variant={variant || 'contained'}
        color={color || 'primary'}
        size={size || 'small'}
        onClick={handleClick}
        {...otherButtonProps}>
        {TranslateText(toSnakeCase(label), label)}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={
          anchorOrigin || {
            vertical: 'bottom',
            horizontal: 'center'
          }
        }
        transformOrigin={
          transformOrigin || {
            vertical: 'top',
            horizontal: 'center'
          }
        }
        {...otherPopoverProps}>
        {children}
      </Popover>
    </>
  );
};
