/**
 * Public Layout
 * @author priyanka.ambawane@shorelineiot.com
 */
import React from 'react';
import Box from '@mui/material/Box';
import { Hidden } from '@mui/material';
import UnauthenticatedHeader from '../common/header/unauthenticatedHeader/UnauthenticatedHeader';
import { useStyles } from '../auth/authLayout.styles';
import { Filler, SLFooter } from '../../framework';
import PublicRoutes from './publicRoutes';
/**
 *
 */
function PublicLayout() {
  const classes = useStyles();
  return (
    <Box className={classes.authContainer}>
      <UnauthenticatedHeader />
      <PublicRoutes />
      <Filler />
      <Hidden mdDown>
        <SLFooter />
      </Hidden>
    </Box>
  );
}
export default PublicLayout;
