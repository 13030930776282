/**
 * Additional Sensor Saga
 * @author mahesh.kedari@shorelineiot.com
 */
import { takeEvery, put, call, all } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import { datapointActions } from '../actions';
import * as ADDITIONAL_SENSOR_ACTIONS from '../../../additional-sensors/additionalSensors.actionTypes';
import * as additionalSensorActions from '../../../additional-sensors/additionalSensors.actions';
import {
  GenericObject,
  httpGet,
  httpPost,
  removeProgressFor,
  showProgressFor,
  showToast,
  APISERVICES
} from '../../../../../../../framework';
import { SagaIterator } from 'redux-saga';
/**
 *
 */
const FETCH_SENSOR_SCHEMA_PROGRESS = 'FETCH_SENSOR_SCHEMA_PROGRESS';
const CREATE_SENSOR_PROGRESS = 'CREATE_SENSOR_PROGRESS';
/**
 *
 * @param data
 */
function fetchSensorSchema(data: any) {
  return httpGet(
    `orgs/${data.slug}/device_sensor_templates/type/${data.templateId}?device_id=${data.deviceId}&sensor_module_name=${data.sensorName}`,
    null,
    APISERVICES.DEVICE_API
  );
}
/**
 *
 * @param data
 */
function createSensor(data: any) {
  return httpPost(`orgs/${data.slug}/device_sensor_modules`, data.module, APISERVICES.DEVICE_API);
}
/**
 *
 * @param action
 */
function* fetchSensorSchemaSaga(action: AnyAction) {
  yield put(showProgressFor(FETCH_SENSOR_SCHEMA_PROGRESS));
  try {
    const response: GenericObject = yield call(fetchSensorSchema, action.payload);
    yield put(additionalSensorActions.handleSensorSchemaSuccess(response));
  } catch (error: any) {
    yield put(additionalSensorActions.handleSensorSchemaFailure(error));
  } finally {
    yield put(removeProgressFor(FETCH_SENSOR_SCHEMA_PROGRESS));
  }
}
/**
 *
 * @param action
 */
function* createSensorSaga(action: any) {
  yield put(showProgressFor(CREATE_SENSOR_PROGRESS));
  try {
    const response: GenericObject = yield call(createSensor, action.payload);
    yield put(showToast('Sensor module created succesfully', 'success'));
    yield put(additionalSensorActions.createSensorSuccess(response));
    /* Refresh Datapoint List after creation */
    yield put(
      datapointActions.fetchDatapoints({
        slug: action.payload.slug,
        deviceId: action.payload.module.device_id
      })
    );
    /* 
    This request is already being sent by 
    yield put(
      additionalSensorActions.getAdditionalSensors({
        slug: action.payload.slug,
        deviceId: action.payload.module.device_id,
      })
    ); */
  } catch (error: any) {
    yield put(showToast('Sensor module could not be created', 'error', true));
    yield put(additionalSensorActions.createSensorFailure(error));
  } finally {
    yield put(removeProgressFor(CREATE_SENSOR_PROGRESS));
  }
}
/**
 *
 */
export function* watchAdditionalSensorSaga(): SagaIterator {
  yield all([
    takeEvery(ADDITIONAL_SENSOR_ACTIONS.FETCH_SENSORS_SCHEMA, fetchSensorSchemaSaga),
    takeEvery(ADDITIONAL_SENSOR_ACTIONS.CREATE_SENSOR, createSensorSaga)
  ]);
}
