export function findPeakIndex(data: Array<any>): number {
  if (data.length === 0) {
    return -1;
  }
  let max = data[0];
  let maxIndex = 0;
  for (let i = 1; i < data.length; i += 1) {
    if (data[i] > max) {
      maxIndex = i;
      max = data[i];
    }
  }
  return maxIndex;
}

export function findPeaks(data: Array<Array<number>>, count?: number): Array<Array<number>> {
  const dataArrayCopy = data.slice();

  const topNArray: Array<any> = dataArrayCopy
    .filter((predicate: number[], index: number, array: Array<number[]>) => {
      if (index === 0 || index === array.length - 1) {
        return false;
      }
      const previous = array[index - 1];
      const next = array[index + 1];
      return predicate[1] > previous[1] && predicate[1] > next[1];
    })
    .sort((begin: Array<number>, end: Array<number>) => {
      // Sort Array based on Y axis value
      return end[1] - begin[1];
    })
    .slice(0, count);
  return topNArray;
}
