/**
 * Data container Style
 * @author ashwini.gawade@shorelineiot.com
 */
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  root: {
    height: '32px',
    borderRadius: '27px',
    color: theme.palette.text.primary,
    '& .Mui-selected': {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.dark
    },
    '&:hover': {
      color: theme.palette.primary.dark
      //  backgroundColor: theme.palette.,
    }
  }
}));
