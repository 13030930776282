/**
 * Framework Style
 * @author mahesh.kedari@shorelineiot.com
 */
import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100vh',
    '& .MuiTableCell-root': {
      paddingTop: '5px !important',
      paddingBottom: '5px !important',
      lineHeight: 2
    }
  },
  progressBar: {
    width: '100%',
    position: 'absolute',
    top: '0px',
    height: '5px'
  },
  mainPanel: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    maxHeight: 'calc(100vh - 40px)',
    overflow: 'auto'
  },
  appBarSpacer: theme.mixins.toolbar,

  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  fixedHeight: {
    height: 240
  }
}));
