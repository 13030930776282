export const FETCH_ACTIONS_ORG = 'FEATURES/ORG_SETTINGS/USERS/ROLES_LIST/FETCH_ACTIONS_ORG';
export const FETCH_ACTIONS_SUCCESS = 'FEATURES/ORG_SETTINGS/USERS/ROLES_LIST/FETCH_ACTIONS_SUCCESS';
export const FETCH_ACTIONS_FAILURE = 'FEATURES/ORG_SETTINGS/USERS/ROLES_LIST/FETCH_ACTIONS_FAILURE';
export const FETCH_ACTIONS_RESET = 'FEATURES/ORG_SETTINGS/USERS/ROLES_LIST/FETCH_ACTIONS_RESET';

export const FETCH_ACTIONS_ROLE = 'FEATURES/ORG_SETTINGS/USERS/ROLES_LIST/FETCH_ACTIONS_ROLE';
export const FETCH_ACTIONS_ROLE_SUCCESS =
  'FEATURES/ORG_SETTINGS/USERS/ROLES_LIST/FETCH_ACTIONS_ROLE_SUCCESS';
export const FETCH_ACTIONS_ROLE_FAILURE =
  'FEATURES/ORG_SETTINGS/USERS/ROLES_LIST/FETCH_ACTIONS_ROLE_FAILURE';
export const FETCH_ACTIONS_ROLE_RESET =
  'FEATURES/ORG_SETTINGS/USERS/ROLES_LIST/FETCH_ACTIONS_ROLE_RESET';

export const FETCH_ROLES_DETAILS = 'FEATURES/ORG_SETTINGS/USERS/ROLES_LIST/FETCH_ROLES_DETAILS';
export const FETCH_ROLES_DETAILS_SUCCESS =
  'FEATURES/ORG_SETTINGS/USERS/ROLES_LIST/FETCH_ROLES_DETAILS_SUCCESS';
export const FETCH_ROLES_DETAILS_FAILURE =
  'FEATURES/ORG_SETTINGS/USERS/ROLES_LIST/FETCH_ROLES_DETAILS_FAILURE';
export const FETCH_ROLES_DETAILS_RESET =
  'FEATURES/ORG_SETTINGS/USERS/ROLES_LIST/FETCH_ROLES_DETAILS_RESET';

export const RESOURCE_TYPE = 'FEATURES/ORG_SETTINGS/USERS/ROLES_LIST/RESOURCE_TYPE';
export const EDIT_ROLES = 'FEATURES/ORG_SETTINGS/USERS/ROLES_LIST/EDIT_ROLE';
export const SELECT_ALL_ROLES = 'FEATURES/ORG_SETTINGS/USERS/ROLES_LIST/SELECT_ALL_ROLES';
export const CRUD_ALL_ROLES = 'FEATURES/ORG_SETTINGS/USERS/ROLES_LIST/CRUD_ALL_ROLES';

export const UPDATE_ROLE_INIT = 'FEATURES/ORG_SETTINGS/USERS/ROLES_LIST/UPDATE_ROLE_INIT';
export const UPDATE_ROLE_SUCCESS = 'FEATURES/ORG_SETTINGS/USERS/ROLES_LIST/UPDATE_ROLE_SUCCESS';
export const UPDATE_ROLE_FAILURE = 'FEATURES/ORG_SETTINGS/USERS/ROLES_LIST/UPDATE_ROLE_FAILURE';
export const UPDATE_ROLE_RESET = 'FEATURES/ORG_SETTINGS/USERS/ROLES_LIST/UPDATE_ROLE_RESET';
