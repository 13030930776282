/**
 * Device reports Saga
 * @author prerna.more@shorelineiot.com
 */
import { put, takeEvery, call } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import * as actions from './deviceReports.action';
import * as ACTION_TYPES from './deviceReports.actionTypes';
import { GenericObject, httpGet, SafeSaga, APISERVICES } from '../../../../framework';
import { SagaIterator } from 'redux-saga';

function getDetails(data: any) {
  if (data.start && data.end) {
    return httpGet(
      `orgs/${data.slug}/device_data_summary?start_ts=${data.start}&end_ts=${data.end}`,
      null,
      APISERVICES.DEVICE_API
    );
  }

  return httpGet(`orgs/${data.slug}/device_data_summary`, null, APISERVICES.DEVICE_API);
}

function* deviceReportsSaga(action: AnyAction) {
  const response: GenericObject = yield call(getDetails, action.payload);
  yield put(actions.fetchDeviceReportsSuccess(response));
}

function* errorHandler() {
  yield put(actions.fetchDeviceReportsFailure());
}

export function* watchdeviceReportsSagas(): SagaIterator {
  yield takeEvery(
    ACTION_TYPES.FETCH_DEVICE_REPORTS,
    SafeSaga(deviceReportsSaga, ACTION_TYPES.FETCH_DEVICE_REPORTS, errorHandler)
  );
}
