import { createApi } from '@reduxjs/toolkit/query/react';
import { APISERVICES } from '../../../../framework';
import { AnomalyEmailExportResult, AnomalyExportType } from './anomaly.types';
import { axiosBaseQuery } from '../../../../framework/lib/rtk/axiosBaseQuery';

export const anomalyApi = createApi({
  reducerPath: 'anomaly',
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    anomalyExportByEmail: build.query<AnomalyEmailExportResult, AnomalyExportType>({
      query: (data: AnomalyExportType) => ({
        apiEndpoint: `orgs/${data.slug}/derived_expressions/${data.derived_dp_id}/export?email=${data.email}&start_ts=${data.start}&end_ts=${data.end}&timezone=${data.timeZone}`,
        service: APISERVICES.DEVICE_API,
        method: 'GET',
        params: null,
        actionKey: 'ANOMALY_EMAIL_EXPORT'
      })
    })
  })
});

export const { useAnomalyExportByEmailQuery, useLazyAnomalyExportByEmailQuery } = anomalyApi;
