/**
 * Login Page Style
 * @author mahesh.kedari@shorelineiot.com
 */
import makeStyles from '@mui/styles/makeStyles';
/**
 *
 */
export const useStyles = makeStyles(() => ({
  root: {
    maxWidth: '500px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '10%',
    display: 'flex',
    padding: '20px',
    justifyContent: 'center',
    overflow: 'auto',
    border: 'solid thin black'
  },
  forgetButtonFont: {
    marginTop: '-5px',
    marginRight: '10px',
    '@media (max-width:600px)': {
      marginRight: '-15px'
    }
  }
}));
