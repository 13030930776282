import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { FREQUENCY_UNIT, WaterfallState, Y_AXIS_UNIT, ZoomValue } from './waterfall.types';
const initialState: WaterfallState = {
  selectedDatapoint: null,
  xUnit: FREQUENCY_UNIT.Hz,
  yUnit: Y_AXIS_UNIT.INCHES_PER_SEC,
  selectedDevice: undefined,
  isStructureborneDatapointSelected: false,
  xAxisZoomValue: { min: 0, max: null },
  isSkipNullCpm: true,
  speed: undefined,
  selectedEventCount: 20,
  skipEventCount: 0,
  yAxisExtremesValue: { min: 0, max: null }
};

export const waterfallSlice = createSlice({
  name: 'waterfall',
  initialState,
  reducers: {
    handleWaterfallDpidChange(state: WaterfallState, action: PayloadAction<number>) {
      state.selectedDatapoint = action.payload;
    },
    handleWaterfallXUnitChange(state: WaterfallState, action: PayloadAction<FREQUENCY_UNIT>) {
      state.xUnit = action.payload;
    },
    handleWaterfallYUnitChange(state: WaterfallState, action: PayloadAction<Y_AXIS_UNIT>) {
      state.yUnit = action.payload;
    },
    handleWaterfallDeviceChange(state: WaterfallState, action: PayloadAction<number | undefined>) {
      state.selectedDevice = action.payload;
    },
    handleWaterfallStrutureborneSelected(state: WaterfallState, action: PayloadAction<boolean>) {
      state.isStructureborneDatapointSelected = action.payload;
    },
    handleWaterfallXAxisZoomValueChange(state: WaterfallState, action: PayloadAction<ZoomValue>) {
      state.xAxisZoomValue = action.payload;
    },
    handleSkipNullCpmChange(state: WaterfallState, action: PayloadAction<boolean>) {
      state.isSkipNullCpm = action.payload;
    },
    handleWaterfallEventSpeed(state: WaterfallState, action: PayloadAction<number | undefined>) {
      state.speed = action.payload;
    },
    handleWaterfallSelectEventCount(state: WaterfallState, action: PayloadAction<number>) {
      state.selectedEventCount = action.payload;
    },
    handleWaterfallSkipEventCount(state: WaterfallState, action: PayloadAction<number>) {
      state.skipEventCount = action.payload;
    },
    handleWaterfallYAxisExtremesValueChange(
      state: WaterfallState,
      action: PayloadAction<ZoomValue>
    ) {
      state.yAxisExtremesValue = action.payload;
    },
    resetWaterfall() {
      return initialState;
    }
  }
});

export const {
  handleWaterfallDpidChange,
  handleWaterfallXUnitChange,
  handleWaterfallYUnitChange,
  handleWaterfallDeviceChange,
  handleWaterfallStrutureborneSelected,
  handleWaterfallXAxisZoomValueChange,
  handleSkipNullCpmChange,
  handleWaterfallEventSpeed,
  handleWaterfallSelectEventCount,
  handleWaterfallSkipEventCount,
  handleWaterfallYAxisExtremesValueChange,
  resetWaterfall
} = waterfallSlice.actions;

export default waterfallSlice.reducer;
