export enum fetchAnalogSensorSchemaStatusAI1 {
  INIT,
  PROGRESS,
  READY,
  ERROR
}

export enum fetchAnalogSensorSchemaStatusAI2 {
  INIT,
  PROGRESS,
  READY,
  ERROR
}
/**
 *
 */

export interface fetchAnalogSensorSchemaState {
  statusAI1: fetchAnalogSensorSchemaStatusAI1;
  statusAI2: fetchAnalogSensorSchemaStatusAI2;
  AI1: any;
  AI2: any;
  defaultAI1: any;
  defaultAI2: any;
}

export enum addAnalogSensorStatus {
  INIT,
  PROGRESS,
  SUCCESS,
  ERROR
}

export interface addAnalogSensorState {
  status: addAnalogSensorStatus;
}
