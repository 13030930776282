/**
 * Raw Datapoints Custom Hook
 * @author mahesh.kedari@shorelineiot.com
 */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSlugContext } from '../../../framework';
import { rawDatapointsActions, RAW_DEVICE_DATAPOINTS_STATUS } from '../../device';
import { useRawWidgetData } from '../../device/store';
import { useFetchDevices } from '../../device/store/device.hooks';

export function useRawDatapoints() {
  const dispatch = useDispatch();
  const { slug } = useSlugContext();

  const { devices } = useFetchDevices(
    {
      slug
    },
    { skip: slug && slug !== 'org' ? false : true }
  );

  const rawDatapointsState = useRawWidgetData();

  useEffect(() => {
    if (
      rawDatapointsState.status === RAW_DEVICE_DATAPOINTS_STATUS.INIT &&
      rawDatapointsState?.datapointsList?.length === 0 &&
      rawDatapointsState?.devicesList?.length === 0 &&
      devices.length > 0 &&
      slug
    ) {
      dispatch(rawDatapointsActions.fetchRawDatapoints(slug, devices));
    }
  }, [
    rawDatapointsState.status,
    devices,
    rawDatapointsState?.datapointsList?.length,
    rawDatapointsState?.devicesList?.length,
    slug,
    dispatch
  ]);
  return {
    status: rawDatapointsState.status,
    datapointsList: rawDatapointsState?.datapointsList
  };
}
