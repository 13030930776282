/**
 * Narrowband Actions
 * @author mahesh.kedari@shorelineiot.com
 */
import { AnyAction } from 'redux';
import { MANUAL_NB_FORM_STEPS } from './manual-config.state';
import * as ACTIONS from './manual-config.actionTypes';
/**
 *
 * @param payload
 */
export function fetchNarrowbandRule(payload: any): AnyAction {
  return {
    type: ACTIONS.FETCH_NB_RULE,
    payload
  };
}
export function fetchNarrowbandRuleSuccess(payload: any): AnyAction {
  return {
    type: ACTIONS.FETCH_NB_RULE_SUCCESS,
    payload
  };
}
/**
 *
 */
export function persistNarrowbandConfig(payload: any): AnyAction {
  return {
    type: ACTIONS.PERSIST_NB_CONFIG,
    payload
  };
}
/**
 *
 */
export function persistRulesConditions(payload: any): AnyAction {
  return {
    type: ACTIONS.PERSIST_NB_RULE_CONDITIONS,
    payload
  };
}
/**
 *
 */
export function persistRulesActions(payload: any): AnyAction {
  return {
    type: ACTIONS.PERSIST_NB_RULE_ACTIONS,
    payload
  };
}
/**
 *
 * @param payload
 */
export function persistRulesActionsAndSubmit(payload: any): AnyAction {
  return {
    type: ACTIONS.PERSIST_NB_RULE_ACTIONS_AND_SUBMIT,
    payload
  };
}

export function submitNarrowband(payload: any): AnyAction {
  return {
    type: ACTIONS.SUBMIT_NB,
    payload
  };
}

export function handleNarrowbandsUpdating(): AnyAction {
  return {
    type: ACTIONS.NB_UPDATING
  };
}

export function handleNarrowbandsInProgress(): AnyAction {
  return {
    type: ACTIONS.NB_IN_PROGRESS
  };
}

export function handleNarrowbandsNoChange(): AnyAction {
  return {
    type: ACTIONS.NB_NO_CHANGE
  };
}

export function submitNarrowbandSuccess(payload: any): AnyAction {
  return {
    type: ACTIONS.NB_SUCCESS,
    payload
  };
}

export function submitNarrowbandFailure(error: any): AnyAction {
  return {
    type: ACTIONS.NB_FAILURE,
    error
  };
}
/**
 *
 */
export function updateFormStep(payload: MANUAL_NB_FORM_STEPS): AnyAction {
  return {
    type: ACTIONS.UPDATE_FORM_STEP,
    payload
  };
}
/**
 *
 */
export function resetNarrowbandConfig(): AnyAction {
  return {
    type: ACTIONS.RESET_NB_CONFIG
  };
}
export function createManualNB(payload: any): AnyAction {
  return {
    type: ACTIONS.CREATE_MANUAL_NB,
    payload
  };
}
export function updateManualNB(payload: any): AnyAction {
  return {
    type: ACTIONS.UPDATE_MANUAL_NB,
    payload
  };
}
