import { AnyAction } from 'redux';
import * as ACTION_TYPE from './actions/deviceActivity.actionTypes';
import { DeviceActivityState } from './DeviceActivityState';

const initialState: DeviceActivityState = {
  status: 'INIT',
  deviceActivityData: Object
};

function fetchActivityDataProgress(state: DeviceActivityState): DeviceActivityState {
  return {
    ...state,
    status: 'FETCHING_ACTIVITY_LOGS'
  };
}

function fetchActivityDataSuccess(
  state: DeviceActivityState,
  deviceActivityData: any
): DeviceActivityState {
  return {
    ...state,
    status: 'READY',
    deviceActivityData
  };
}

function fetchActivityDataFailure(state: DeviceActivityState): DeviceActivityState {
  return {
    ...state,
    status: 'ERROR'
  };
}

function fetchActivitiesReset(state: DeviceActivityState): DeviceActivityState {
  return {
    ...state,
    status: 'INIT'
  };
}

export default function deviceActivityReducer(
  state: DeviceActivityState = initialState,
  action: AnyAction
): DeviceActivityState {
  switch (action.type) {
    case ACTION_TYPE.FETCH_ACTIVITIES:
      return fetchActivityDataProgress(state);
    case ACTION_TYPE.FETCH_ACTIVITIES_SUCCESS:
      return fetchActivityDataSuccess(state, action.payload);
    case ACTION_TYPE.FETCH_ACTIVITIES_FAILURE:
      return fetchActivityDataFailure(state);
    case ACTION_TYPE.FETCH_ACTIVITIES_RESET:
      return fetchActivitiesReset(state);
    default:
      return state;
  }
}
