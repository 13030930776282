/**
 * Get Dashboard List Reducer
 */
import { AnyAction } from 'redux';
import * as ACTION_TYPE from './defaultDashboard.actionsTypes';
import { DefaultDashboardState, DefaultDashboardStatus } from './defaultDashboard.stateType';

const initialState: DefaultDashboardState = {
  status: DefaultDashboardStatus.INIT,
  defaultDashboardData: []
};

function getDefaultDashboardProgress(state: DefaultDashboardState): DefaultDashboardState {
  return {
    ...state,
    status: DefaultDashboardStatus.FETCHING
  };
}

function getDefaultDashboardSuccess(
  state: DefaultDashboardState,
  defaultDashboardData: any
): DefaultDashboardState {
  return {
    ...state,
    status: DefaultDashboardStatus.READY,
    defaultDashboardData
  };
}

function getDefaultDashboardFailure(state: DefaultDashboardState): DefaultDashboardState {
  return {
    ...state,
    status: DefaultDashboardStatus.ERROR
  };
}

function resetState(state: DefaultDashboardState): DefaultDashboardState {
  return {
    ...state,
    status: DefaultDashboardStatus.INIT,
    defaultDashboardData: []
  };
}

function setDefaultDashboard(
  state: DefaultDashboardState,
  defaultDashboardData: any
): DefaultDashboardState {
  return {
    ...state,
    status: DefaultDashboardStatus.READY,
    defaultDashboardData
  };
}

/*
    Reducer is a pure function that takes actions and state as input 
    and returns/passes the modified state to the store.
    It is implemented using switch case to update the state according 
    to the ation type required.
*/

export default function defaultdashboardReducer(
  state: DefaultDashboardState = initialState,
  action: AnyAction
): DefaultDashboardState {
  switch (action.type) {
    case ACTION_TYPE.DEFAULT_DASHBOARD_PROGRESS:
      return getDefaultDashboardProgress(state);
    case ACTION_TYPE.DEFAULT_DASHBOARD_SUCCESS:
      return getDefaultDashboardSuccess(state, action.payload);
    case ACTION_TYPE.DEFAULT_DASHBOARD_FAILURE:
      return getDefaultDashboardFailure(state);
    case ACTION_TYPE.DEFAULT_DASHBOARD_RESET_STATE:
      return resetState(state);
    case ACTION_TYPE.SET_CURRENT_DASHBOARD:
      return setDefaultDashboard(state, action.payload);
    default:
      return state;
  }
}
