import { put, takeEvery, all } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import * as actions from './resetDatapoint.actions';
import * as actionTypes from './resetDatapoint.actionTypes';
import { datapointActions } from '../../../device-settings/device-data';
import {
  GenericObject,
  httpPatch,
  SafeSaga,
  showToast,
  APISERVICES
} from '../../../../../../../framework';
import { SagaIterator } from 'redux-saga';

function* resetDatapoint(action: AnyAction) {
  const data = action.payload;

  const body = { reset_value: data.resetValue };
  const response: GenericObject = yield httpPatch(
    `orgs/${data.slug}/devices/${data.deviceId}/derived_datapoints/${data.dpid}`,
    body,
    APISERVICES.DEVICE_API
  );

  yield put(actions.resetDatapointSuccess(response));
  yield put(showToast('Battery usage accumulator reset successfully', 'success'));
  yield put(
    datapointActions.fetchDatapoints({
      slug: data.slug,
      deviceId: data.deviceId
    })
  );
}

function* errorHandler(error: any) {
  yield put(actions.resetDatapointFailure(error));
}
/**
 *
 * @param action

/**
 *
 */
export function* watchResetDatapointSaga(): SagaIterator {
  yield all([
    takeEvery(
      actionTypes.RESET_DATAPOINT_PROGRESS,
      SafeSaga(resetDatapoint, actionTypes.RESET_DATAPOINT_PROGRESS, errorHandler)
    )
  ]);
}
