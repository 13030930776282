/**
 * Register Saga
 * @author swapnil.kshirsagar@shorelineiot.com
 */
import { put, takeEvery, all } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import * as actions from '../actions/register.actions';
import * as ACTIONS from '../actions/register.actionTypes';
import { removeProgressFor, showToast } from '../../../../../framework';
import { AuthService } from '../../../../../framework/services/auth.service';
import { confirmSignUp, SignUpOutput } from 'aws-amplify/auth';

const REGISTER_ACTION_PROGRESS_ID = 'REGISTER_ACTION_PROGRESS_ID';
const authService = new AuthService();

export function* registerUserSaga(action: AnyAction) {
  try {
    const response: SignUpOutput = yield authService.signUp(action.payload);
    yield put(actions.registerSuccess({ ...response }));
  } catch (error: any) {
    if (error.name === 'UsernameExistsException') {
      yield put(
        showToast(
          'Already registered. Sign in to continue, or select forgot password to recover account',
          'error',
          true
        )
      );
      yield put(
        actions.registerFailure(
          'Already registered. Sign in to continue, or select forgot password to recover account'
        )
      );
    } else {
      yield put(showToast(error.message, 'error'));
      yield put(actions.registerFailure(error.message));
    }
  } finally {
    yield put(removeProgressFor(REGISTER_ACTION_PROGRESS_ID));
  }
}

export function* confirmUserSaga(action: AnyAction) {
  const { email, code } = action.payload;
  try {
    yield confirmSignUp({ username: email, confirmationCode: code });
    yield put(showToast('User Verification Successful', 'success'));
    yield put(actions.onConfirmUserSuccess());
  } catch (error: any) {
    yield put(actions.onConfirmUserFailure(error));
  } finally {
    yield put(removeProgressFor('CONFIRM_USER'));
  }
}

export function* registerSagas() {
  yield all([
    takeEvery(ACTIONS.SIGNUP_PROGERSS, registerUserSaga),
    takeEvery(ACTIONS.CONFIRM_USER, confirmUserSaga)
  ]);
}
