/**
 * VA summary Section Styles
 * @author mahesh.kedari@shorelineiot.com
 */
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  icon1: {
    transform: 'rotate(90deg)'
  },
  accordionStyle: {
    marginTop: '20px',
    margin: '0px',
    border: '1px solid #bfcfe2',
    padding: '0px',
    borderWidth: '1px',
    borderStyle: 'solid',
    boxShadow: 'none',
    backgroundColor: theme.palette.background.default
  },
  accordionHeading: {
    marginTop: '-14px',
    marginLeft: ' -5px'
  },
  panelSummary: {
    flexDirection: 'row-reverse',
    width: '21vw',
    marginTop: '-25px',
    marginLeft: '-34px',
    '& .MuiAccordionSummary-content': {
      textAlign: 'center',
      alignItems: 'center',
      border: '1px solid #bfcfe2',
      borderWidth: 1,
      borderRadius: '27px',
      backgroundColor: theme.palette.background.default
    }
  },
  accordionDetails: {
    marginTop: '-25px',
    fontSize: '0.87rem'
  }
}));
