/**
 * Axes settings Configurator
 * @author mahesh.kedari@shorelineiot.com
 */
import { PivotConfig } from '@cubejs-client/core';
import { Grid } from '@mui/material';
import React, { ReactElement } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import DroppableAxisArea from './DroppableAxisArea';

interface Props {
  pivotConfig?: PivotConfig;
  onMove: (config: any) => void;
}

export default function SLAxesSettings({ pivotConfig, onMove }: Props): ReactElement {
  return (
    <DragDropContext
      onDragEnd={({ source, destination }) => {
        if (!destination) {
          return;
        }
        onMove({
          sourceIndex: source.index,
          destinationIndex: destination.index,
          sourceAxis: source.droppableId,
          destinationAxis: destination.droppableId
        });
      }}>
      <Grid container>
        <Grid item xs={6}>
          <DroppableAxisArea axis="x" pivotConfig={pivotConfig} />
        </Grid>
        <Grid item xs={6}>
          <DroppableAxisArea axis="y" pivotConfig={pivotConfig} />
        </Grid>
      </Grid>
    </DragDropContext>
  );
}
