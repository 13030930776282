/**
 * Redirection Options
 * @author mahesh.kedari@shorelineiot.com
 */
export const RedirectionOptions: Array<{ label: string; value: string }> = [
  {
    label: 'None',
    value: ''
  },
  {
    label: 'Asset',
    value: 'asset'
  },
  {
    label: 'Device Data',
    value: 'device-data'
  },
  {
    label: 'Datapoint',
    value: 'datapoint'
  },
  {
    label: 'Device List',
    value: 'device-list'
  },
  {
    label: 'Alarms',
    value: 'alarms'
  },
  {
    label: 'Another Dashboard',
    value: 'dashboard'
  },
  {
    label: 'Vibration Analysis',
    value: 'vibration-analysis'
  },
  {
    label: 'Activity Logs',
    value: 'activity-logs'
  }
];
