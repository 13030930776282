/**
 * Vibration Analysis Revamp Saga
 * @author mahesh.kedari@shorelineiot.com
 */
import { all } from 'redux-saga/effects';
import { watchVADatapointListSaga } from './device/vaDevice.saga';
import { watchMotionUnitsSaga } from './va-units/vaUnits.saga';
import { watchVAEventsSaga } from './events/vaEvents.saga';
import { watchVAFFTSpectrumSaga } from './fft-chart/fftChart.saga';
import { watchVANarrowbandsSaga } from './narrowbands/vaNarrowbands.saga';
import { watchGetDatapointsTrendchartSagas } from './events/getSensors.saga';
import { watchGetEventDiagnosisSagas } from './events/vaEventDiagnosis.saga';
import { watchUpdateCPMSaga } from './common/updateCPMSaga';

export function* vibrationAnalysisSaga() {
  yield all([
    watchVADatapointListSaga(),
    watchMotionUnitsSaga(),
    watchVAEventsSaga(),
    watchVAFFTSpectrumSaga(),
    watchVANarrowbandsSaga(),
    watchGetDatapointsTrendchartSagas(),
    watchUpdateCPMSaga(),
    watchGetEventDiagnosisSagas()
  ]);
}
