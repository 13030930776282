/**
 * Framework Reducer
 * @author mahesh.kedari@shorelineiot.com
 */
import { combineReducers } from 'redux';
import { ToastReducer as toasts } from '../components/toast';
import {
  SidebarReducer as sidebar,
  BannerReducer as banner,
  BreadcrumbReducer as breadcrumb,
  LogoPathReducer as logoPath
} from '../components';
import loader from './loader.reducer';

import theme from './theme.reducer';
import useAPI from './useAPI.reducer';
import { BackdropReducer as backdrop } from '../components/backdrop';
import { ProgressBarReducer as progressBar } from '../components/progress-bar';

/**
 *
 */

const frameworkReducer = combineReducers({
  loader,
  progressBar,
  toasts,
  banner,
  theme,
  breadcrumb,
  sidebar,
  backdrop,
  useAPI,
  logoPath
});

export default frameworkReducer;
