/**
 * Dynamic Dashboard Menu
 * @author mahesh.kedari@shorelineiot.com
 */
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router';

import merge from 'lodash/merge';
import { useDispatch } from 'react-redux';
import { USER_SELF_STATUS, useUserSelf } from '../user-settings';
import {
  ACCESS_PERMIT,
  showProgressFor,
  SidebarMenu,
  SLSubmenuLink,
  useGAEventTracker,
  usePermissions,
  useSlugContext
} from '../../framework';
import { DashboardIcon } from '../../framework/icons';
import { newDashbaordDefaultDetails } from './utils/DashboardUtils';
import {
  DashboardListState,
  DashboardListStatus,
  DASHBOARD_LIST_ACTIONS,
  DASHBOARD_ITEMS_ACTIONS,
  LOCAL_DASHBOARD_ITEMS_ACTIONS,
  DEFAULT_DASHBOARD_ACTIONS,
  useDefaultDashboardData,
  useDashboardList
} from './store';
import { NEW_DASHBOARD_ID } from './utils/dashboard';
import { DASHBOARD_PERMISSIONS } from './dashboard.permissions';
import { sidebarId } from '../../framework/components/sidebar/sidebar.utils';
import { formatString, canEnableMachineHealthAndLeakDetectionDashboard } from './DashboardUtils';
import { useIsFeatureEnable } from '../../framework/custom-hooks/useIsFeatureEnable';
import { FEATURE_NAME } from '../featureUniqueNames';

interface DashboardMenuItem {
  key: string;
  value: string;
  label: string;
}
export default function DashboardMenu() {
  const { slug } = useSlugContext();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const userSelf: any = useUserSelf();

  const isMachineHealthDashboardEnabled = useIsFeatureEnable(FEATURE_NAME.MACHINE_HEALTH_DASHBOARD);
  const isLeakDetectionDashboardEnabled = useIsFeatureEnable(FEATURE_NAME.LEAK_DETECTION_DASHBOARD);

  const GAEventsTracker = useGAEventTracker('Dashboard');

  const getDashboardIdFromURL = () => {
    const urlParams = location?.pathname.split('/');
    return urlParams[urlParams.length - 1];
  };
  const dashboardList: DashboardListState = useDashboardList();
  const hasCreatePermission: ACCESS_PERMIT = usePermissions(DASHBOARD_PERMISSIONS.CREATE);
  const [dashboardMenuList, setDashboardMenuList] = useState<Array<DashboardMenuItem>>([]);
  const defaultDashboardData: any = useDefaultDashboardData();

  // intial load of dashboard list
  useEffect(() => {
    if (
      dashboardList.status === DashboardListStatus.INIT &&
      userSelf.status === USER_SELF_STATUS.READY &&
      slug
    ) {
      dispatch(showProgressFor('GET_DASHBOARD_LIST'));
      dispatch(
        DASHBOARD_LIST_ACTIONS.getDashboardList({
          slug,
          role_uuid: userSelf.self.role.id
        })
      );
    }
  }, [dashboardList.status, userSelf.status]);

  // Sort the list after the dashboard list is updated
  useEffect(() => {
    if (dashboardList.status === DashboardListStatus.READY) {
      const dashboardListArray = dashboardList?.dashboardListData;
      const formattedDashboardList: Array<DashboardMenuItem> = [];
      dashboardListArray.forEach((item: any) => {
        formattedDashboardList.push({
          key: item.dashboard_uuid,
          value: item.dashboard_uuid,
          label: item.name
        });
      });
      formattedDashboardList.sort((a, b) => a?.label?.localeCompare(b?.label));
      setDashboardMenuList(formattedDashboardList);
    }
  }, [dashboardList.status, dashboardList?.dashboardListData, defaultDashboardData]);
  // set the default dashboard id in URLdashboardList to get it LSB highlted in intial load
  useEffect(() => {
    if (dashboardList.status === DashboardListStatus.READY) {
      const dashboardListArray = dashboardList?.dashboardListData;
      const dashboardId = getDashboardIdFromURL();
      if (dashboardId === 'default') {
        const defaultDashboardObject =
          dashboardListArray.find(
            (currentDashboard: any) => currentDashboard.is_default === true
          ) || (dashboardListArray?.length > 0 ? dashboardListArray[0] : null);
        navigate(`/${slug}/app/custom-dashboard/${defaultDashboardObject?.dashboard_uuid}`, {
          replace: true
        });
      }
    }
  }, [dashboardList.status, location.pathname]);

  const navigateToExistingDashboard = (selectedDashbaord: DashboardMenuItem) => {
    const dashboardId = getDashboardIdFromURL();
    if (dashboardId !== selectedDashbaord?.value) {
      dispatch(DASHBOARD_ITEMS_ACTIONS.resetState());
      dispatch(LOCAL_DASHBOARD_ITEMS_ACTIONS.resetState());
      const viewDashboardInfo = dashboardList?.dashboardListData.find(
        (dashboard: any) => dashboard.dashboard_uuid === selectedDashbaord?.value
      );
      // setting edit mode false on dashboard switch
      dispatch(
        DEFAULT_DASHBOARD_ACTIONS.setCurrentDashboardView(
          merge({ edit_mode: false }, viewDashboardInfo)
        )
      );
      GAEventsTracker('Navigation', selectedDashbaord?.label);
    }
  };

  const navigateToNewDashboard = () => {
    dispatch(DASHBOARD_ITEMS_ACTIONS.resetState());
    dispatch(LOCAL_DASHBOARD_ITEMS_ACTIONS.resetState());
    dispatch(
      DEFAULT_DASHBOARD_ACTIONS.setCurrentDashboardView({
        ...newDashbaordDefaultDetails(slug)
      })
    );
  };

  const dashboardMenuId: string = sidebarId.menuItems('dashboard');
  return (
    <SidebarMenu
      testID={dashboardMenuId}
      route={`/${slug}/app/custom-dashboard`}
      textKey="custom_dashboard"
      defaultText="Dashboard"
      icon={<DashboardIcon className="MuiSvgIcon-root" />}>
      {dashboardList.status === DashboardListStatus.READY &&
        dashboardList?.dashboardListData?.length &&
        dashboardMenuList?.map((item) => {
          const shouldDisplayMenuItem = canEnableMachineHealthAndLeakDetectionDashboard(
            isMachineHealthDashboardEnabled,
            isLeakDetectionDashboardEnabled,
            item.label
          );

          if (shouldDisplayMenuItem) {
            return (
              <SLSubmenuLink
                testID={`${dashboardMenuId}-${formatString(item?.label)}`}
                key={item?.value}
                route={`/${slug}/app/custom-dashboard/${item?.value}`}
                label={item?.label}
                textKey={item?.value}
                defaultText={item?.label}
                onClick={() => navigateToExistingDashboard(item)}
              />
            );
          }
        })}
      <SLSubmenuLink
        testID={`${dashboardMenuId}-view_all`}
        route={`/${slug}/app/custom-dashboard/list`}
        textKey="viewAll"
        defaultText="View All"
      />
      {hasCreatePermission === ACCESS_PERMIT.ALLOW && (
        <SLSubmenuLink
          testID={`${dashboardMenuId}-add_dashboard`}
          route={`/${slug}/app/custom-dashboard/${NEW_DASHBOARD_ID}`}
          textKey="addDashboard"
          defaultText="Add Dashboard"
          onClick={navigateToNewDashboard}
          icon={<AddCircleOutlineRoundedIcon fontSize="small" />}
        />
      )}
    </SidebarMenu>
  );
}
