import { useUserSelf } from '../../../user-settings';
import { useMemo } from 'react';

/**
 * This custom hook determines if the current logged-in
 * user is a super admin or not.
 * If the user is a super admin, it returns `true`; otherwise, `false`.
 * @returns boolean
 */
const useIsSuperAdmin = (): boolean => {
  const userSelf = useUserSelf();

  const isSuperAdmin = useMemo(() => {
    return Boolean(userSelf?.self?.role?.name === 'Super Admin');
  }, [userSelf]);

  return isSuperAdmin;
};

export default useIsSuperAdmin;
