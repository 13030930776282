/**
 * Authentication Sagas
 */
import { all } from 'redux-saga/effects';
import { registerSagas } from './register/store';
import { loginSagas, acceptInviteSaga } from './login';
import { configureSagas } from './configure';
import { changePasswordSaga } from '../user-settings';
import { forgotPasswordSaga } from './forgot-password/forgotPassword.saga';
import { authProviderSaga } from './store';

export function* authSagas() {
  yield all([
    loginSagas(),
    registerSagas(),
    acceptInviteSaga(),
    configureSagas(),
    forgotPasswordSaga(),
    changePasswordSaga(),
    authProviderSaga()
  ]);
}
