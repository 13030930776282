export enum deleteDashboardItemStatus {
  INIT,
  PROGRESS,
  SUCCESS,
  ERROR
}

export interface deleteDashboardItemState {
  status: deleteDashboardItemStatus;
}
