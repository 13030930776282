/**
 * Handle Chart configurations for Analytics Widget
 * @returns
 */
import merge from 'lodash/merge';
import { parseSeries } from './parseSeries';
import { CartesianChartTooltipFormatter } from './cartesianChartTooltipFormatter';
import { isByHourMinuteSecondSelected, timeAxisFormatter } from './timeAxisFormatter';
import { formattedDate } from '../../..';
import { NavigateFunction } from 'react-router-dom';

export const analyticsCartesianParser = (
  resultSet: any,
  chartType: string,
  chartSettings: any,
  dataSettings: any,
  grid: any,
  navigate: NavigateFunction,
  slug: string
) => {
  const { allSeries, xAxisCategories, yAxisArray, hasTimeAxis } = parseSeries(
    resultSet,
    chartType,
    chartSettings,
    dataSettings,
    grid,
    navigate,
    slug
  );

  const getTickInterval = (xAxisCategoriesData: Array<any>) => {
    if (xAxisCategoriesData?.length < 100) {
      return 1;
    }
    if (xAxisCategoriesData?.length > 100 && xAxisCategoriesData?.length < 1000) {
      return 10;
    }
    if (xAxisCategoriesData?.length < 5000) {
      return 100;
    }
    if (xAxisCategoriesData?.length < 7000) {
      return 500;
    }
    if (xAxisCategoriesData?.length < 10000) {
      return 1000;
    }
    return 3000;
  };

  const xAxis: Highcharts.XAxisOptions = hasTimeAxis
    ? {
        type: 'datetime',
        labels: {
          autoRotation: [0],
          formatter: (axisObject: any) => {
            if (isByHourMinuteSecondSelected(resultSet)) {
              // if time 'By' Hour, Minute or Second selected
              return timeAxisFormatter(axisObject);
            }
            const updatedValue = axisObject.value;
            const dateObj = new Date(updatedValue);
            return formattedDate(dateObj);
          }
        },
        // TODO: tickInterval values is being hardcode at the moment.
        // This needs to e changed in the future according to dynamic category array.
        tickInterval: getTickInterval(xAxisCategories)
      }
    : {};

  const tooltip = hasTimeAxis
    ? {
        useHTML: true,
        formatter: CartesianChartTooltipFormatter,
        style: {
          color: 'white'
        },
        backgroundColor: '#4d4d4d'
      }
    : {};
  return {
    tooltip,
    xAxis: merge(xAxis, { categories: xAxisCategories }),
    yAxis: yAxisArray,
    series: allSeries
  };
};
