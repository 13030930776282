import { put, takeEvery, call } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import * as getSurveyAnswersActions from './actions/getSurveyAnswers.actions';
import * as actionTypes from './actions/getSurveyAnswers.actionTypes';
import {
  GenericObject,
  httpGet,
  removeProgressFor,
  showProgressFor,
  showToast,
  APISERVICES
} from '../../../../../../../framework';
import { SagaIterator } from 'redux-saga';

const GET_SURVEY_ANSWERS = 'GET_SURVEY_ANSWERS';
function getSurveyAnswers(slug: string, deviceId: string) {
  return httpGet(`orgs/${slug}/devices/${deviceId}/survey_answers`, null, APISERVICES.DEVICE_API);
}

export function* getSurveyAnswersSaga(action: AnyAction) {
  yield put(showProgressFor(GET_SURVEY_ANSWERS));

  try {
    const response: GenericObject = yield call(
      getSurveyAnswers,
      action.payload.slug,
      action.payload.deviceId
    );

    yield put(getSurveyAnswersActions.getSurveyAnswersSuccess(response));
  } catch (error: any) {
    yield put(showToast('Error in getting Answers', 'error', true));
    yield put(getSurveyAnswersActions.getSurveyAnswersFailure(error));
  } finally {
    yield put(removeProgressFor(GET_SURVEY_ANSWERS));
  }
}

export function* watchGetSurveyAnswersSaga(): SagaIterator {
  yield takeEvery(actionTypes.GET_SURVEY_ANSWERS, getSurveyAnswersSaga);
}
