/**
 * @author mahesh.kedari@shorelineiot.com
 */

import { Suborg } from '../orgs';
import { Organisation } from './Organisation';

export enum ORG_LIST_STATUS {
  UNKNOWN,
  FETCHING_LIST,
  READY,
  ERROR
}

export interface OrgListState {
  status: ORG_LIST_STATUS;
  orgList: Array<Organisation>;
}

export interface SubOrgListState {
  status: ORG_LIST_STATUS;
  orgList: Suborg[];
}
