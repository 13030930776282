import React from 'react';
import { Grid, IconButton } from '@mui/material';
import SLSelect from '../../form/select/SLSelect';
import { ListItemType, BatteryStatusConditionObject } from './batteryStatusTypes';
import SLTextField from '../../form/SLTextField';
import {
  defaultFormatterType,
  defaultSensorNameConditionObject,
  formatterOptions
} from './BatteryStatusConstants';
import { SLButton } from '../../..';
import { useFieldArray, useFormContext } from 'react-hook-form';
import SLColorPicker from '../../color-picker/SLColorPicker';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

const BatteryStatusConditionalFormatting = ({
  conditionList,
  handleUpdate,
  prefix,
  settings
}: {
  conditionList: ListItemType[];
  handleUpdate: () => void;
  prefix: string;
  settings: BatteryStatusConditionObject;
}) => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `${prefix}.formatters`
  });

  const addFormatter = () => {
    append({
      type: defaultFormatterType,
      color: '#808080'
    });
    handleUpdate();
  };

  const showAddFormatterButton = () => {
    const typeSet = new Set<string>();
    settings.formatters?.map((formatter) => {
      typeSet.add(formatter.type);
    });
    return typeSet.size < 3;
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid xs={6}>
          <SLSelect
            name={`${prefix}.condition`}
            label="Condition"
            translationId="condition"
            options={conditionList}
            defaultValue={settings?.condition || defaultSensorNameConditionObject.condition}
            onSelectChange={handleUpdate}
          />
        </Grid>
        <Grid xs={6}>
          <SLTextField
            name={`${prefix}.value`}
            label="Value"
            labelTranslationId="value"
            style={{ marginTop: '3px' }}
            onBlur={handleUpdate}
            defaultValue={settings?.value || defaultSensorNameConditionObject.value}
          />
        </Grid>
        {fields &&
          fields.map((field, index) => (
            <Grid container xs={12} key={field.id}>
              <Grid xs={6}>
                <SLSelect
                  name={`${prefix}.formatters.${index}.type`}
                  label="Type"
                  labelTranslationId="type"
                  options={formatterOptions}
                  onSelectChange={handleUpdate}
                  defaultValue={settings?.formatters[index]?.type || defaultFormatterType}
                />
              </Grid>
              <Grid xs={3}>
                <SLColorPicker
                  name={`${prefix}.formatters.${index}.color`}
                  value={{ hex: settings?.formatters[index]?.color?.hex || '#808080' }}
                  handleColorChange={handleUpdate}
                  label=""
                />
              </Grid>
              <Grid xs={3}>
                <div style={{ marginTop: '4px' }}>
                  <IconButton
                    onClick={() => {
                      remove(index);
                      handleUpdate();
                    }}>
                    <DeleteOutlineOutlinedIcon />
                  </IconButton>
                </div>
              </Grid>
            </Grid>
          ))}
        {showAddFormatterButton() && (
          <div style={{ marginTop: '10px' }}>
            <SLButton
              onClick={addFormatter}
              translationId="add_formatter"
              defaultMessage="Add Formatter">
              Add Formatter
            </SLButton>
          </div>
        )}
      </Grid>
    </>
  );
};

export default BatteryStatusConditionalFormatting;
