/**
 * VA unit State
 * @author ashwini.gawade@shorelineiot.com
 */
export enum VAMotionUnitStatus {
  INIT,
  FETCHING,
  READY,
  ERROR,
  RESET
}
export enum FREQUENCY_UNIT {
  Hz = 'Hz',
  CPM = 'CPM',
  ORDERS = 'Orders'
}

export enum AMPLITUDE_SELECTION {
  PEAK = 'Peak',
  RMS = 'RMS',
  PEAK_TO_PEAK = 'Peak_to_Peak'
}

export type MotionUnitTypes = 'G' | 'inches/second' | 'mils' | 'dB(G)';

export interface VAMotionUnit {
  status: VAMotionUnitStatus;
  motionUnitsList: Array<any>;
  selectedMotionUnit: string;
  selectedMotionUnitType: MotionUnitTypes;
}

export type IndicatorsOptionTypes = 'Indicators' | 'Peak Search' | 'Harmonics' | 'SideBand';

export enum INDICATOR_OPTION {
  INDICATORS = 'Indicators',
  PEAK_SEARCH = 'Peak Search',
  HARMONICS = 'Harmonics',
  SIDEBAND = 'SideBand'
}

export interface VAUnitsState {
  frequencyUnit: FREQUENCY_UNIT;
  motionUnit: VAMotionUnit;
  amplitudeSelection: AMPLITUDE_SELECTION;
  displayUnit: string;
  enableHarmonics: boolean;
  lockHarmonics: boolean;
  harmonicsCount: number;
  selectedIndicator: IndicatorsOptionTypes;
  enableSideband: boolean;
  lockSideband: boolean;
  timeChartSelectedIndicator: TimeChartOptionsType;
  lockDeltaMarker: boolean;
  enableDeltaMarker: boolean;
  enableSlidingWindow: boolean;
  slidingWindowCount: number;
}

export interface UnitsPayload {
  frequencyUnit: FREQUENCY_UNIT;
  motionUnitType: MotionUnitTypes;
  motionUnit?: string;
  amplitudeSelection: AMPLITUDE_SELECTION;
  enableHarmonics?: boolean;
  speedValue?: any;
}
export interface UnitInfoType {
  units: [MotionUnitTypes];
  user_unit: MotionUnitTypes;
}

export type TimeChartOptionsType = 'None' | 'Delta Markers' | 'Sliding Window';

export enum TIME_CHART_OPTION {
  NONE = 'None',
  DELTA_MARKERS = 'Delta Markers',
  SLIDING_WINDOW = 'Sliding Window'
}

export interface TimeChartDataType {
  color: string;
  cropThreshold: number;
  data: Array<Array<number>>;
  id: string;
  name: string;
  rawDpName: string;
  showInLegend: boolean;
  type: string;
}

export interface HarmonicSeriesPointType {
  x: number;
  y: number;
  marker: {
    enabled: boolean;
    radius: number;
    symbol: string;
  };
  color: string;
  order: number;
  x_hz: number;
  x_cpm: number;
  x_order: number;
  y_peak: number;
  y_peakToPeak: number;
  y_rms: number;
  spectrumSeriesName: string;
  arrayIndex: number;
  seriesId: string;
}
export interface HarmonicsSeriesType {
  data: Array<HarmonicSeriesPointType>;
  cropThreshold: number;
  type: string;
  selected: boolean;
  id: string;
  name: string;
  actualSeriesName: string;
  showInLegend: boolean;
  zIndex: number;
}
