/**
 * Component List Reducer
 * @author mahesh.kedari@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTIONS from './componentList.actionTypes';
import { ComponentType } from '../component-details/componentDetails.type';
/**
 *
 */
export enum COMPONENT_LIST_STATUS {
  INIT,
  FETCHING,
  READY,
  ERROR
}
/**
 *
 */
export interface ComponentListState {
  status: COMPONENT_LIST_STATUS;
  list: Array<ComponentType>;
}
/**
 *
 */
const initialState: ComponentListState = {
  status: COMPONENT_LIST_STATUS.INIT,
  list: []
};
/**
 *
 * @param state
 * @returns
 */
const handleFetchComponentList = (state: ComponentListState): ComponentListState => ({
  ...state,
  status: COMPONENT_LIST_STATUS.FETCHING
});
/**
 *
 * @param state
 * @param list
 * @returns
 */
const handleFetchComponentsSuccess = (
  state: ComponentListState,
  list: Array<ComponentType>
): ComponentListState => ({
  ...state,
  status: COMPONENT_LIST_STATUS.READY,
  list
});

const handleFetchComponentsFailure = (state: ComponentListState): ComponentListState => ({
  ...state,
  status: COMPONENT_LIST_STATUS.ERROR,
  list: []
});

const handleDeleteComponent = (state: ComponentListState): ComponentListState => {
  // TODO: Handle Delete Component
  return {
    ...state
  };
};

const handleDeleteComponentSuccess = (state: ComponentListState): ComponentListState => {
  // TODO: Handle
  return {
    ...state
  };
};
/**
 *
 * @param state
 * @param action
 * @returns
 */
export default function componentListReducer(
  state: ComponentListState = initialState,
  action: AnyAction
): ComponentListState {
  switch (action.type) {
    case ACTIONS.FETCH_COMPONENT_LIST:
      return handleFetchComponentList(state);
    case ACTIONS.FETCH_COMPONENT_LIST_SUCCESS:
      return handleFetchComponentsSuccess(state, action.payload);
    case ACTIONS.FETCH_COMPONENT_LIST_FAILURE:
      return handleFetchComponentsFailure(state);
    case ACTIONS.RESET_COMPONENT_LIST:
      return { ...initialState };
    case ACTIONS.DELETE_COMPONENT:
      return handleDeleteComponent(state);
    case ACTIONS.DELETE_COMPONENT_SUCCESS:
      return handleDeleteComponentSuccess(state);
    default:
      return state;
  }
}
