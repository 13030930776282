/**
 * Narrowband Rule List Sagas
 * @author priyanka.ambawane@shorelineiot.com
 */
import { SagaIterator } from 'redux-saga';
import { put, takeEvery, call } from 'redux-saga/effects';
import { GenericObject, httpGet, showToast, APISERVICES } from '../../../../../framework';
import { apiToFormDataParser } from '../createRule.parsers';
import * as narrowbandRulesListActions from './narrowbandRules.actions';
import * as NARROWBAND_RULELIST_ACTIONS from './narrowbandRules.actionTypes';
/**
 *
 * @param data
 */
function narrowbandRuleList(obj: any) {
  return httpGet(
    `orgs/${obj.slug}/device_rule_template?dpid=${obj.data.datapointUUID}&device_id=${obj.data.deviceID}`,
    null,
    APISERVICES.DEVICE_API
  );
}

/**
 *
 * @param action
 */
export function* narrowbandRuleListSaga(action: any) {
  try {
    const response: GenericObject = yield call(narrowbandRuleList, action.payload);
    if (!response.results || response.results.length === 0) {
      throw 'NoRulesPresent';
    }
    let warningRule;
    let errorRule;
    if (response.results[0]?.attributes?.alert_level === 'warning') {
      warningRule = apiToFormDataParser(response.results[0]);
    } else if (response.results[0]?.attributes?.alert_level === 'error') {
      errorRule = apiToFormDataParser(response.results[0]);
    }
    if (response.results[1]?.attributes?.alert_level === 'warning') {
      warningRule = apiToFormDataParser(response.results[1]);
    } else if (response.results[1]?.attributes?.alert_level === 'error') {
      errorRule = apiToFormDataParser(response.results[1]);
    }
    yield put(
      narrowbandRulesListActions.narrowbandRuleListSuccess({
        warningRule,
        errorRule
      })
    );
  } catch (error: any) {
    if (error === 'NoRulesPresent') {
      yield put(
        showToast(
          'No rules configured for this narrowband. Please fill in the details if you wish to add rules for this narrowband.',
          'error',
          true
        )
      );
    } else {
      yield put(showToast('Rule details could not be fetched!', 'error', true));
    }

    yield put(narrowbandRulesListActions.narrowbandRuleListFailure(error));
  }
}
/**
 *
 */
export function* watchNarrowbandRuleListSaga(): SagaIterator {
  yield takeEvery(NARROWBAND_RULELIST_ACTIONS.NARROWBAND_RULES_LIST, narrowbandRuleListSaga);
}
