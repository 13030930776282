import { useMemo } from 'react';
import { useOrgSelector } from '../../features/organization/store';

/**
 * This hook will returns `true` value if the user is present
 * on the main org page.
 * @returns boolean
 */
const useIsMainOrg = () => {
  const orgSelector = useOrgSelector();
  const isMainOrg = useMemo(() => {
    return Boolean(
      orgSelector?.selectedOrg?.suborgs?.length || orgSelector?.selectedOrg?.org_type === 'org'
    );
  }, [orgSelector]);

  return isMainOrg;
};

export default useIsMainOrg;
