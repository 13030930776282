/**
 * Component Details Actions
 * @author mahesh.kedari@shorelineiot.com
 */
import { AnyAction } from 'redux';
import { ComponentType } from './componentDetails.type';
import * as ACTIONS from './componentDetails.actionTypes';
/**
 *
 * @returns
 */
export const fetchComponentDetails = (componentId: string | number): AnyAction => ({
  type: ACTIONS.FETCH_COMPONENT_DETAILS,
  payload: {
    component_id: componentId
  }
});
/**
 *
 * @returns
 */
export const exportComponentDetails = (componentId: string | number): AnyAction => ({
  type: ACTIONS.EXPORT_COMPONENT_DETAILS,
  payload: {
    component_id: componentId
  }
});
/**
 *
 * @param payload
 * @returns
 */
export const fetchComponentDetailsSuccess = (payload: ComponentType): AnyAction => ({
  type: ACTIONS.FETCH_COMPONENT_DETAILS_SUCCESS,
  payload
});
/**
 *
 * @param error
 * @returns
 */
export const fetchComponentDetailsFailure = (error: any) => ({
  type: ACTIONS.FETCH_COMPONENT_DETAILS_FAILURE,
  error
});
/**
 *
 * @returns
 */
export const resetComponentDetails = () => ({
  type: ACTIONS.RESET_COMPONENT_DETAILS
});

export const addMountPoints = (payload: any) => ({
  type: ACTIONS.ADD_COMPONENT_MOUNT_POINTS,
  payload
});
export const updateMountPoint = (payload: any) => ({
  type: ACTIONS.UPDATE_COMPONENT_MOUNT_POINT,
  payload
});

export const updateMountPointOnSingleNB = (payload: any) => ({
  type: ACTIONS.UPDATE_NB_MOUNT_POINT,
  payload
});

export const updateSingleNB = (payload: any) => ({
  type: ACTIONS.UPDATE_SINGLE_NB,
  payload
});
export const deleteSingleNB = (payload: any) => ({
  type: ACTIONS.DELETE_SINGLE_NB,
  payload
});

export const addNewNB = (payload: any) => ({
  type: ACTIONS.ADD_NEW_NB,
  payload
});

/**
 *
 * @param payload
 * @returns
 */
export const updateComponentDetails = (componentId: any, payload: ComponentType) => ({
  type: ACTIONS.UPDATE_COMPONENT_DETAILS,
  payload: {
    component_id: componentId,
    payload
  }
});
/**
 *
 * @param payload
 * @returns
 */
export const updateComponentSuccess = (payload: ComponentType) => ({
  type: ACTIONS.UPDATE_COMPONENT_DETAILS_SUCCESS,
  payload
});
/**
 *
 * @param payload
 * @returns
 */
export const updateComponentFailure = (error: any) => ({
  type: ACTIONS.UPDATE_COMPONENT_DETAILS_SUCCESS,
  error
});

export const getNarrowbands = (payload: any) => ({
  type: ACTIONS.GET_NARROWBANDS_FOR_COMPONENTS,
  payload
});

export const getNarrowbandsSuccess = () => ({
  type: ACTIONS.GET_NARROWBANDS_SUCCESS
});

export const applyToDevices = (payload: any): AnyAction => ({
  type: ACTIONS.APPLY_TO_DEVICES,
  payload
});

export const applyToDevicesSuccess = (payload: any): AnyAction => ({
  type: ACTIONS.APPLY_TO_DEVICES_SUCCESS,
  payload
});

export const applyToDevicesFailure = (error: any): AnyAction => ({
  type: ACTIONS.APPLY_TO_DEVICES_FAILURE,
  error
});

export const getAttachedDevices = (payload: any): AnyAction => ({
  type: ACTIONS.GET_ATTACHED_DEVICES,
  payload
});

export const getAttachedDevicesSuccess = (payload: any): AnyAction => ({
  type: ACTIONS.GET_ATTACHED_DEVICES_SUCCESS,
  payload
});

export const getAttachedDevicesFailure = (error: any): AnyAction => ({
  type: ACTIONS.GET_ATTACHED_DEVICES_FAILURE,
  error
});

//  Create Component Action
export const createComponentDetails = (payload: any) => ({
  type: ACTIONS.CREATE_COMPONENT_DETAILS,
  payload
});

export const createComponentSuccess = (payload: any) => ({
  type: ACTIONS.CREATE_COMPONENT_DETAILS_SUCCESS,
  payload
});

export const createComponentFailure = (error: any) => ({
  type: ACTIONS.CREATE_COMPONENT_DETAILS_FAILURE,
  error
});

export const createComponentReset = () => ({
  type: ACTIONS.CREATE_COMPONENT_DETAILS_RESET
});
