/**
 * Autoconfig Saga
 * @author mahesh.kedari@shorelineiot.com
 */
import { takeEvery, call, put, all } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import { autoconfigNBActions, AUTOCONFIG_NB_ACTIONS } from './actions';
import { createUpdateNarrowband } from '../common/narrowband-saga.helper';
import {
  GenericObject,
  removeProgressFor,
  showProgressFor,
  showToast
} from '../../../../../../../framework';
import { SagaIterator } from 'redux-saga';

const NARROWBAND_CREATE_PROGRESS = 'NARROWBAND_CREATE_PROGRESS';

function* autoconfigNarrowband(action: AnyAction) {
  const {
    deviceId,
    narrowbandConfig,
    slug,
    index,
    deletedNbDpid,
    isEdit,
    narrowbands,
    surveyAnswers,
    componentId,
    powertrain
  } = action.payload;
  yield put(showProgressFor(NARROWBAND_CREATE_PROGRESS));
  try {
    const allNarrowbands = Object.entries(narrowbandConfig).map(([, narrowband]) => narrowband);
    /**
     * All Sensor modules for narrowband needs to be extracted for making a
     * sensor module creation request.
     */
    // taking only auto type nbs
    const payload = allNarrowbands
      .filter((nb: any) => nb.mode === 'auto')
      .map((narrowband: any) => narrowband.sensorModule);

    // const { nb_delete } = rawNarrowbands;
    const nbDelete = deletedNbDpid;
    const sensorModules = payload;
    /* 
    const getAxis = (nbName: string) => {
      const axisSplit = nbName.split(',').map(function(value) {
        return value.trim();
      });
      switch (axisSplit[axisSplit.length - 1]) {
        case 'X':
          return 'SNS_ACC_X_AXIS';
        case 'Y':
          return 'SNS_ACC_Y_AXIS';
        case 'Z':
          return 'SNS_ACC_Z_AXIS';
        default:
          return '';
      }
    };

    const getPriority = (nbName: string) => {
      const rawDatapointType = getAxis(nbName);
      if (rawDatapointType !== '') {
        const nbGroups = narrowbands.filter((axisNBGroup: any) => {
          return axisNBGroup.raw_datapoint_type === rawDatapointType;
        });
        const priority = nbGroups[0].narrowband_config?.find(
          (nb: any) => nb.name === nbName
        )?.prio;
        if (priority) {
          return priority;
        }
        if (nbGroups.length === 2) {
          return nbGroups[1].narrowband_config?.find(
            (nb: any) => nb.name === nbName
          )?.prio;
        }
        return 0;
      }
      return 0;
    }; */

    /* sensorModules.map(sensorModule => {
      sensorModule.datapoints[0].prio = getPriority(
        sensorModule.datapoints[0].name
      );
    }); */

    const narrowbandPayload = isEdit
      ? {
          sensor_modules: sensorModules,
          narrowbands,
          nb_delete: nbDelete,
          survey_answers: surveyAnswers,
          component_id: componentId,
          powertrain
        }
      : {
          sensor_modules: sensorModules,
          narrowbands,
          survey_answers: surveyAnswers,
          component_id: componentId,
          powertrain
        };
    const result: GenericObject = yield call(
      createUpdateNarrowband,
      narrowbandPayload,
      slug,
      isEdit,
      deviceId
    );
    yield put(showToast(result.message, 'success'));
    yield put(autoconfigNBActions.submitNarrowbandUpdating());
    yield put(autoconfigNBActions.resetAutoconfigNB());
  } catch (error: any) {
    yield put(showToast('Failed to submit Narrowband', 'error', true));
    yield put(autoconfigNBActions.submitNarrowbandFailure(index));
  } finally {
    yield put(removeProgressFor(NARROWBAND_CREATE_PROGRESS));
  }
}

/**
 *
 */
export function* watchAutoconfigSaga(): SagaIterator {
  yield all([
    takeEvery(AUTOCONFIG_NB_ACTIONS.SUBMIT_NARROWBANDS, autoconfigNarrowband)
    // takeEvery(AUTOCONFIG_NB_ACTIONS.ATTACH_NB_DIAGNOSTICS, attachDiagnostics),
  ]);
}
