/**
 * featuresReducers is object which contains featuresReducers as key value pair and is further added to the store.
 */
import { featureApi } from './feature.api';
import { anomalyApi } from './workflow/anomaly-configurator/store/anomalyExport.api';

export const featuresReducers = {
  [featureApi.reducerPath]: featureApi.reducer,
  [anomalyApi.reducerPath]: anomalyApi.reducer
};
