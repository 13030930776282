/**
 * Roles List Saga
 * @author mahesh.kedari@shorelineiot.com
 */
import { put, takeEvery } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import * as actions from './actions/rolesList.actions';
import * as ACTIONS from './actions/rolesList.actionTypes';
import {
  GenericObject,
  httpGet,
  removeProgressFor,
  showProgressFor,
  APISERVICES
} from '../../../../../../framework';
import { SagaIterator } from 'redux-saga';

const ROLES_LIST_PROCESS_ID = 'ROLES_LIST';
/**
 *
 */
function* rolesListSaga(action: AnyAction) {
  yield put(showProgressFor(ROLES_LIST_PROCESS_ID));
  const slug = action.payload;
  try {
    const response: GenericObject = yield httpGet(`orgs/${slug}/roles`, null, APISERVICES.HOST_API);
    yield put(actions.fetchRolesSuccess(response));
  } catch (error: any) {
    yield put(actions.fetchRolesFailure());
  } finally {
    yield put(removeProgressFor(ROLES_LIST_PROCESS_ID));
  }
}
/**
 *
 */
export default function* watchRolesListSagas(): SagaIterator {
  yield takeEvery(ACTIONS.FETCH_ROLES, rolesListSaga);
}
