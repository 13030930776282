import { put, takeEvery, call, select } from 'redux-saga/effects';
import * as actions from '../actions/sensorAlertsDelete.actions';
import * as actionTypes from '../actions/alerts.actionTypes';
import * as alertsListActions from '../actions/alertsList.actions';
import { GenericObject, httpDelete, SafeSaga, showToast, APISERVICES } from '../../../../framework';
import { SagaIterator } from 'redux-saga';
import { RootState } from '../../../../configureStore';
import {
  DeleteSensorAlertActionPayload,
  DeleteSensorAlertApiPayload,
  Filter
} from '../types/AlertsListState';

function deleteAlerts(slug: string, payload: DeleteSensorAlertApiPayload) {
  return httpDelete(`orgs/${slug}/alerts`, payload, APISERVICES.DEVICE_URL);
}

export function* deleteSensorAlerts(action: { payload: DeleteSensorAlertActionPayload }) {
  const { slug, payload } = action.payload;
  const response: GenericObject = yield call(deleteAlerts, slug, payload);
  yield put(actions.deleteSensorAlertsSuccess(response));
  const nbAlertsFilter: Filter = yield select(
    (state: RootState) => state.features.alerts.alertsList.nbAlertsFilter
  );

  yield put(
    alertsListActions.getAlertsList({
      slug,
      nbAlertsFilter
    })
  );
  yield put(showToast('Rule deleted Successfully!', 'success'));
}

export function* watchSensorAlertDeleteSaga(): SagaIterator {
  yield takeEvery(
    actionTypes.DELETE_SENSOR_ALERT,
    SafeSaga(deleteSensorAlerts, actionTypes.DELETE_SENSOR_ALERT)
  );
}
