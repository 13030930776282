/**
 * Peak Datalabel Formatter
 * @author mahesh.kedari@shorelineiot.com
 */
import { PointLabelObject } from 'highcharts';

function translatePowerAndUnit(du: any) {
  if (du === 'mm/second^2' || du === 'inches/second^2') {
    return `${du.split('^')[0].replace('second', 'sec')}
                    <sup>${du.split('^')[1]}</sup>
         `;
  }
  return du !== undefined ? `${du.replace('second', 'sec')}` : '';
}

export function PeakDatalabelFormatter(
  this: PointLabelObject | { series: any; point: any & { chart: any } }
): string {
  const displayUnit = this?.series?.chart?.displayUnit;
  const hzOrCPMValue = this?.series?.chart?.hzOrCPMValue;
  const updatedDisplayUnit = translatePowerAndUnit(displayUnit);

  const { x, y } = this.point;
  const formattedString = `<table style="background-color: #00000000">
  <tr><td><b>${x.toFixed(1) || ''} ${hzOrCPMValue} , ${
    y?.toFixed(2) || ''
  } ${updatedDisplayUnit}</b></td></tr></table>`;
  return formattedString;
}
