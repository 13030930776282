/**
 * Device Profile Saga
 * @author mahesh.kedari@shorelineiot.com
 */
import { all } from 'redux-saga/effects';
import watchProfileDetails from '../profile-details/updateProfile.saga';
// import watchSensorModule from '../profile-details/sensor-module/sensorModule.saga';

export function* deviceProfileSaga() {
  yield all([
    watchProfileDetails()
    // watchSensorModule(),
  ]);
}
