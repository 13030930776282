import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  root: {
    '& .MuiDialogContent-root': {
      overflowY: 'hidden'
    }
  },
  dialogContent: {
    height: '10vh',
    overflow: 'hidden',
    '@media (min-width:1920px)': {
      height: '6vh'
    }
  }
}));
