/**
 * Confirm User Component
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Typography, Box } from '@mui/material';
import * as actions from '../store/actions/register.actions';
import {
  useQuery,
  showProgressFor,
  useSlugContext,
  removeProgressFor,
  closeAllToasts,
  showToast
} from '../../../../framework';

import VerificationFailedPage from '../verification/VerificationFailedPage';
import VerificationSuccessfulPage from '../verification/VerificationSuccessfulPage';
import AlreadyVerifiedPage from '../verification/AlreadyVerifiedPage';
import { AuthService } from '../../../../framework/services/auth.service';
import { useSignupState } from '../store/reducers/register.selectorHooks';

const ORG_CREATE_ACTION_PROCESS_ID = 'ORG_CREATE_ACTION_PROCESS_ID';

/**
 *
 */
export default function ConfirmUser(): ReactElement {
  const signup = useSignupState();
  const query = useQuery();
  const dispatch = useDispatch();

  const email = query.get('email');
  const code = query.get('code');
  const { slug } = useSlugContext();
  const [orgCreationStatus, setOrgCreationStatus] = useState<boolean>(false);
  /**
   * Susbscribe to org Creation Process
   * @returns
   */
  const subscribeToOrgCreate = async () => {
    dispatch(showProgressFor(ORG_CREATE_ACTION_PROCESS_ID));
    const authService = new AuthService();
    if (!sessionStorage.getItem('ConnectionKey')) {
      await authService.connectToMQTTBroker();
    }
    const task = authService
      .getDataFromTopic([`${import.meta.env.VITE_TOPIC_PREFIX}/frontend/${slug}/orgcreate`])
      .subscribe({
        next: () => {
          dispatch(closeAllToasts());
          dispatch(removeProgressFor(ORG_CREATE_ACTION_PROCESS_ID)); // Progress is removed from success and error function
          // because failure in close method may keep progress bar in loop forever
          setOrgCreationStatus(true);
          task?.unsubscribe();
        },
        error: () => {
          dispatch(showToast('Failed to create Organization', 'error', true));
          dispatch(removeProgressFor(ORG_CREATE_ACTION_PROCESS_ID));
          task?.unsubscribe();
        },
        complete: () => undefined
      });
    return task;
  };

  useEffect(() => {
    if (email && code) {
      dispatch(showProgressFor('CONFIRM_USER'));
      dispatch(
        actions.onConfirmUser({
          email,
          code
        })
      );
    }
  }, []);

  if (signup.status) {
    if (signup.status === 'CONFIRM_USER_SUCCESS') {
      subscribeToOrgCreate();
    }
    if (orgCreationStatus) {
      return <VerificationSuccessfulPage />;
    }
    if (signup.status === 'ERROR' && signup.errorList.code === 'NotAuthorizedException') {
      return <AlreadyVerifiedPage />;
    }
    if (signup.status === 'ERROR') {
      return <VerificationFailedPage emailId={email} />;
    }
  }
  return (
    <>
      <Box display="flex" flexDirection="column" alignItems="center">
        {signup.status !== 'CONFIRM_USER_SUCCESS' && (
          <Typography>Validating User {email}... </Typography>
        )}
        {signup.status === 'CONFIRM_USER_SUCCESS' && (
          <Typography>Creating Organization. Please wait... </Typography>
        )}
      </Box>
    </>
  );
}
