/**
 * Survey Action Types
 * @author mahesh.kedari@shorelineiot.com
 */
export const EDIT_SURVEY_ANSWERS =
  'FEATURES/DEVICE/DEVICE-SETTINGS/AUTOMATIC-CONFIG/ACTIONS/EDIT_SURVEY_ANSWERS';
export const EDIT_SURVEY_ANSWERS_SUCCESS =
  'FEATURES/DEVICE/DEVICE-SETTINGS/AUTOMATIC-CONFIG/ACTIONS/EDIT_SURVEY_ANSWERS_SUCCESS';
export const EDIT_SURVEY_ANSWERS_FAILURE =
  'FEATURES/DEVICE/DEVICE-SETTINGS/AUTOMATIC-CONFIG/ACTIONS/EDIT_SURVEY_ANSWERS_FAILURE';
export const EDIT_SURVEY_ANSWERS_RESET =
  'FEATURES/DEVICE/DEVICE-SETTINGS/AUTOMATIC-CONFIG/ACTIONS/EDIT_SURVEY_ANSWERS_RESET';

export const ADD_SURVEY_ANSWERS =
  'FEATURES/DEVICE/DEVICE-SETTINGS/AUTOMATIC-CONFIG/ACTIONS/ADD_SURVEY_ANSWERS';
export const ADD_SURVEY_ANSWERS_SUCCESS =
  'FEATURES/DEVICE/DEVICE-SETTINGS/AUTOMATIC-CONFIG/ACTIONS/ADD_SURVEY_ANSWERS_SUCCESS';
export const ADD_SURVEY_ANSWERS_FAILURE =
  'FEATURES/DEVICE/DEVICE-SETTINGS/AUTOMATIC-CONFIG/ACTIONS/ADD_SURVEY_ANSWERS_FAILURE';
export const ADD_SURVEY_ANSWERS_RESET =
  'FEATURES/DEVICE/DEVICE-SETTINGS/AUTOMATIC-CONFIG/ACTIONS/ADD_SURVEY_ANSWERS_RESET';
