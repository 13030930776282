/**
 * Data Criteria Context
 * @author mahesh.kedari@shorelineiot.com
 */
import { useContext, createContext } from 'react';
/**
 *
 */
type ContextProps = {
  permissions: Array<string>;
  setPermissions: (permissions: Array<string>) => void;
};

export enum ACCESS_PERMIT {
  NOT_READY,
  ALLOW,
  DENY
}
/**
 *
 */
export const AccessControl = createContext<Partial<ContextProps>>({});
/**
 *
 * @param action
 */
export function usePermissions(action: string): ACCESS_PERMIT {
  const { permissions } = useContext(AccessControl);
  if (!permissions || permissions.length === 0) {
    return ACCESS_PERMIT.NOT_READY;
  }
  if (permissions.indexOf(action) >= 0) {
    return ACCESS_PERMIT.ALLOW;
  }
  return ACCESS_PERMIT.DENY;
}
