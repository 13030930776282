import { AnyAction } from 'redux';
import * as ACTION_TYPES from './modbus.actionTypes';

export const addForm1Object = (data: any): AnyAction => {
  return {
    type: ACTION_TYPES.FORM1_OBJECT,
    payload: data
  };
};

export const addCoilsObject = (data: any): AnyAction => {
  return {
    type: ACTION_TYPES.ADD_COILS_OBJECT,
    payload: data
  };
};

export const deleteCoilsObject = (data: any): AnyAction => {
  return {
    type: ACTION_TYPES.DELETE_COILS_OBJECT,
    payload: data
  };
};

export const editCoilsObject = (keyEdit: any, data: any): AnyAction => {
  return {
    type: ACTION_TYPES.EDIT_COILS_OBJECT,
    payload: { keyEdit, data }
  };
};

export const addDiscreteInputsObject = (data: any): AnyAction => {
  return {
    type: ACTION_TYPES.ADD_DISCRETE_INPUTS_OBJECT,
    payload: data
  };
};

export const deleteDiscreteInputsObject = (data: any): AnyAction => {
  return {
    type: ACTION_TYPES.DELETE_DISCRETE_INPUTS_OBJECT,
    payload: data
  };
};

export const editDiscreteInputsObject = (keyEdit: any, data: any): AnyAction => {
  return {
    type: ACTION_TYPES.EDIT_DISCRETE_INPUTS_OBJECT,
    payload: { keyEdit, data }
  };
};

export const addInputRegisterObject = (data: any): AnyAction => {
  return {
    type: ACTION_TYPES.ADD_INPUT_REGISTER_OBJECT,
    payload: data
  };
};

export const deleteInputRegisterObject = (data: any): AnyAction => {
  return {
    type: ACTION_TYPES.DELETE_INPUT_REGISTER_OBJECT,
    payload: data
  };
};

export const editInputRegisterObject = (keyEdit: any, data: any): AnyAction => {
  return {
    type: ACTION_TYPES.EDIT_INPUT_REGISTER_OBJECT,
    payload: { keyEdit, data }
  };
};

export const addHoldingRegisterObject = (data: any): AnyAction => {
  return {
    type: ACTION_TYPES.ADD_HOLDING_REGISTER_OBJECT,
    payload: data
  };
};

export const deleteHoldingRegisterObject = (data: any): AnyAction => {
  return {
    type: ACTION_TYPES.DELETE_HOLDING_REGISTER_OBJECT,
    payload: data
  };
};

export const editHoldingRegisterObject = (keyEdit: any, data: any): AnyAction => {
  return {
    type: ACTION_TYPES.EDIT_HOLDING_REGISTER_OBJECT,
    payload: { keyEdit, data }
  };
};
export const addModbusProgress = (data: any): AnyAction => {
  return {
    type: ACTION_TYPES.ADD_MODBUS_PROGRESS,
    payload: data
  };
};
/**
 *
 * @param data
 */
export const addModbusSuccess = (data: any): AnyAction => {
  // returns the action type
  return {
    type: ACTION_TYPES.ADD_MODBUS_SUCCESS,
    data
  };
};
/**
 *
 * @param error
 */
export const addModbusFailure = (error: any): AnyAction => {
  // returns the action type
  return {
    type: ACTION_TYPES.ADD_MODBUS_FAILURE,
    error
  };
};
/**
 *
 * @param data
 */

export const addModbusReset = (): AnyAction => {
  return {
    type: ACTION_TYPES.ADD_MODBUS_RESET
  };
};
