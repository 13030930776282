/**
 * Paper Styling
 * @author prerna.more@shorelineiot.com
 */
import { PaperClasses, Theme } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { Colors } from '../../../constants';

export const PaperStyle:
  | Partial<OverridesStyleRules<keyof PaperClasses, 'MuiPaper', Omit<Theme, 'components'>>>
  | undefined = {
  root: {
    backgroundColor: Colors.background,
    overflow: 'hidden',
    '& .MuiTablePagination-menuItem': {
      fontSize: '0.89rem'
    }
  }
};
