/**
 * Device remark Saga
 * @author prerna.more@shorelineiot.com
 */
import { put, takeEvery, call } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import * as actions from './remark.action';
import * as ACTION_TYPES from './remarks.actionTypes';
import { deviceReportsActions } from '../..';
import { GenericObject, SafeSaga, httpPut, APISERVICES } from '../../../../../framework';
import { SagaIterator } from 'redux-saga';

function getDetails(data: any) {
  return httpPut(
    `orgs/${data.slug}/device_data_summary/observations/${data.id}`,
    {
      remarks: data.remarks
    },
    APISERVICES.DEVICE_API
  );
}

function* remarkSaga(action: AnyAction) {
  const response: GenericObject = yield call(getDetails, action.payload);
  yield put(actions.fetchRemarksSuccess(response));
  yield put(deviceReportsActions.fetchDeviceReports(action.payload));
}

function* errorHandler() {
  yield put(actions.fetchRemarksFailure());
}

export function* watchRemarkSagas(): SagaIterator {
  yield takeEvery(
    ACTION_TYPES.FETCH_REMARK,
    SafeSaga(remarkSaga, ACTION_TYPES.FETCH_REMARK, errorHandler)
  );
}
