/**
 * Social Login Component
 * @author mahesh.kedari@shorelineiot.com
 */
import React from 'react';
import { Box, Avatar, Button, useMediaQuery, useTheme, Typography } from '@mui/material';
import { GoogleIcon, LinkedInIcon } from '../../../../framework/icons';
import useStyles from './socialLogin.style';

interface Props {
  googleTitle: string;
  linkedInTitle: string;
  isFromRegister: string;
}

function SocialLogin(props: Props) {
  const IDENTITY_PROVIDER = 'auth0-cognito';
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'), {
    defaultMatches: true
  });
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'xl'), {
    defaultMatches: false
  });

  const size = isMobile ? 'small' : isTablet ? 'medium' : 'large';

  const { googleTitle, linkedInTitle } = props;

  function getSignInURL() {
    return `${import.meta.env.VITE_AUTH_USER_POOL_DOMAIN}/oauth2/authorize?identity_provider=${IDENTITY_PROVIDER}&redirect_uri=${import.meta.env.VITE_BASE_PATH}org/auth/federated_auth/&response_type=CODE&client_id=${import.meta.env.VITE_AUTH_USER_POOL_WEB_CLIENT}&scope=aws.cognito.signin.user.admin email openid profile`;
  }

  /**
   *
   */
  function handleGoogleLogin() {
    // dispatch(actions.loginGoogle());
  }
  /**
   *
   */
  function handleLinkedInLogin() {
    // dispatch(actions.loginLinkedIn());
  }

  return (
    <>
      <Box my={3}>
        <Button
          title="Google"
          variant="contained"
          size={size}
          onClick={handleGoogleLogin}
          className={`${classes.google} ${classes.socialButton}`}
          disabled>
          <Avatar className={classes.avatar}>
            <GoogleIcon className={classes.icon} />
          </Avatar>
          &nbsp;&nbsp;
          <Typography variant="body2">{googleTitle}</Typography>
        </Button>
      </Box>
      <Box my={3}>
        <Button
          title="LinkedIn"
          onClick={handleLinkedInLogin}
          className={`${classes.linkedIn} ${classes.socialButton}`}
          variant="contained"
          size={size}
          disabled>
          <Avatar className={classes.avatar}>
            <LinkedInIcon className={classes.icon} />
          </Avatar>
          &nbsp;&nbsp;
          <Typography variant="body2">{linkedInTitle}</Typography>
        </Button>
      </Box>
      <Box my={3}>
        <a href={getSignInURL()}>
          <Avatar className={classes.avatar}>
            <LinkedInIcon className={classes.icon} />
          </Avatar>
          &nbsp;&nbsp;
          <Typography variant="body2">Login with Microsoft</Typography>
        </a>
      </Box>
    </>
  );
}

export default SocialLogin;
