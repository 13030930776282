/**
 * Leak Detection API
 * @author mahesh.kedari@shorelineiot.com
 */
import { featureApi } from '../../../feature.api';
import { APISERVICES } from '../../../../framework';
import {
  FetchAlarmsCountArgs,
  FetchMethaneAlarmsArgs,
  LEAK_DETECTION_ALARMS_VALIDATION_TAGS,
  MethaneEmissionAlarm
} from '../../leak-detection/utils/LeakDetectionAlarmType';

// export const UPDATE_ALARMS_STATUS_SHARED_RESULT = 'shared-update-alarms-status';

export const alarmsApi = featureApi.injectEndpoints({
  endpoints: (build) => ({
    fetchLeakDetectionAlarms: build.query<MethaneEmissionAlarm[], FetchMethaneAlarmsArgs>({
      query: ({
        slug,
        alarmType,
        isHistoricalAlarms,
        allAlarms,
        severity,
        start,
        end
      }: FetchMethaneAlarmsArgs) => {
        let apiEndpoint = `orgs/${slug}/device_alarms?alarm_type=${alarmType}${
          severity ? `&severity=${severity.toString()}` : ''
        }${start ? `&start=${start}` : ''}${end ? `&end=${end}` : ''}`;
        if (allAlarms) {
          apiEndpoint = apiEndpoint.concat(`&states=FIXED,NEW,ACK,IN_PROGRESS`);
        } else if (isHistoricalAlarms) {
          apiEndpoint = apiEndpoint.concat('&states=FIXED');
        }
        return {
          apiEndpoint,
          params: null,
          service: APISERVICES.DEVICE_API,
          method: 'GET',
          actionKey: 'FETCH_LEAK_DETECTION_LIST'
        };
      },
      transformResponse: (result) => {
        return result.results.map((alarm: MethaneEmissionAlarm) => ({
          ...alarm,
          duration: alarm.state_modified_on - alarm.created
        }));
      }
    }),
    fetchLeakDetectionAlarmsCount: build.query<any, FetchAlarmsCountArgs>({
      query: ({ slug, alarmType, count }: FetchAlarmsCountArgs) => ({
        apiEndpoint: `orgs/${slug}/device_alarms?severity_count=${count}&alarm_type=${alarmType}`,
        params: null,
        service: APISERVICES.DEVICE_API,
        method: 'GET',
        actionKey: 'FETCH_LEAK_DETECTION_COUNT'
      }),
      providesTags: [LEAK_DETECTION_ALARMS_VALIDATION_TAGS.ALARM_COUNT],
      transformResponse: (result: any) => {
        return result;
      }
    })
  })
});

export const { useFetchLeakDetectionAlarmsQuery, useFetchLeakDetectionAlarmsCountQuery } =
  alarmsApi;
