/**
 * Login Actions
 * @author mahesh.kedari@shorelineiot.com
 */

import { AnyAction } from 'redux';
import * as ACTIONS from './login.actionTypes';
import { inviteUserDataType } from '../types';
/**
 *
 */
export const checkCurrentSession = (): AnyAction => {
  return {
    type: ACTIONS.CHECK_CURRENT_SESSION
  };
};
export const currentSessionAvailable = (sessionObject: any): AnyAction => {
  return {
    type: ACTIONS.CURRENT_SESSION_AVAILABLE,
    payload: sessionObject
  };
};

export const currentSessionNotAvailable = (): AnyAction => {
  return {
    type: ACTIONS.CURRENT_SESSION_NOT_AVAILABLE
  };
};
/**
 *
 */
export const loginGoogle = (): AnyAction => {
  return {
    type: ACTIONS.LOGIN_WITH_GOOGLE
  };
};
/**
 *
 */
export const loginLinkedIn = (): AnyAction => {
  return {
    type: ACTIONS.LOGIN_WITH_LINKED_IN
  };
};

export const setUserEmail = (data: inviteUserDataType): AnyAction => {
  return {
    type: ACTIONS.SET_USER_EMAIL,
    payload: data
  };
};
/**
 *
 * @param body
 */
// TODO: Update Body datatype
export const login = (body: any): AnyAction => {
  // returns the action type to login_reducer
  return {
    type: ACTIONS.LOGIN_PROGERSS,
    body
  };
};
/**
 *
 * @param data
 */
export const loginSuccess = (data: any): AnyAction => {
  // returns the action type to login_reducer
  return {
    type: ACTIONS.LOGIN_SUCCESS,
    data
  };
};
/**
 *
 * @param data
 */
export const forceNewPassword = (data: any): AnyAction => {
  return {
    type: ACTIONS.LOGIN_FORCE_NEW_PWD,
    data
  };
};
/**
 *
 * @param time Time for logout
 */
export const loginTimeout = (time: number): AnyAction => {
  return {
    type: ACTIONS.LOGIN_TIMEOUT,
    time
  };
};
/**
 *
 * @param error
 */
export const loginFailure = (error: any): AnyAction => {
  // returns the action type to login_reducer
  return {
    type: ACTIONS.LOGIN_FAILURE,
    error
  };
};
/**
 *
 */
export const logout = (flag: boolean): AnyAction => {
  return {
    type: ACTIONS.LOGOUT_PROGRESS,
    payload: flag
  };
};
/**
 *
 */
export const logoutSucceed = (): AnyAction => {
  return {
    type: ACTIONS.LOGOUT_SUCCEED
  };
};
/**
 *
 * @param email
 */
export const resendVerificationLink = (email: string) => {
  return {
    type: ACTIONS.RESEND_VERIFICATION,
    payload: email
  };
};
/**
 *
 * @param message
 */
export const resendVerificationSuccess = (message: string) => {
  return {
    type: ACTIONS.RESEND_VERIFICATION_SUCCESS,
    payload: message
  };
};
/**
 *
 * @param message
 */
export const resendVerificationFailure = (message: string) => {
  return {
    type: ACTIONS.RESEND_VERIFICATION_FAILED,
    payload: message
  };
};
/**
 *
 * @param email
 */
export const forgotPasswordCode = (email: string) => {
  return {
    type: ACTIONS.FORGOT_PASSWORD_CODE,
    payload: email
  };
};
/**
 *
 * @param message
 */
export const forgotPasswordCodeSuccess = (email: string) => {
  return {
    type: ACTIONS.FORGOT_PASSWORD_CODE_SUCCESS,
    payload: email
  };
};
/**
 *
 * @param message
 */
export const forgotPasswordCodeFailure = (message: string) => {
  return {
    type: ACTIONS.FORGOT_PASSWORD_CODE_FAILED,
    payload: message
  };
};

/**
 *
 */

export const resetToLoginPage = () => {
  return {
    type: ACTIONS.RESET_TO_LOGIN_PAGE
  };
};
