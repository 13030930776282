/**
 * Create organization reducer
 * @author ashwini.gawade@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTIONS from './actions/subOrg.actionType';
import { Organisation } from '../../types/Organisation';
import { CreateSubOrgState, CREATE_SUB_ORG_STATUS } from '../../types/createSubOrgState';
/**
 *
 */
const initialState: CreateSubOrgState = {
  status: CREATE_SUB_ORG_STATUS.INIT
};
/**
 *
 * @param state Old state
 */
function onCreateSuborg(state: CreateSubOrgState): CreateSubOrgState {
  return {
    ...state,
    status: CREATE_SUB_ORG_STATUS.CREATING
  };
}
/**
 *
 * @param state Old State
 * @param msg Success Message
 */
function onCreateSuborgSuccess(state: CreateSubOrgState): CreateSubOrgState {
  return {
    ...state,
    status: CREATE_SUB_ORG_STATUS.SUCCESS
  };
}
/**
 *
 * @param state Old State
 * @param errorList Error messages
 */
function onCreateSuborgFailure(state: CreateSubOrgState): CreateSubOrgState {
  return {
    ...state,
    status: CREATE_SUB_ORG_STATUS.ERROR
  };
}
/**
 *
 * @param state old State
 * @param subOrgName Sub-organisation name
 */
function onSuborgInit(state: CreateSubOrgState, org: Organisation): CreateSubOrgState {
  return {
    ...state,
    status: CREATE_SUB_ORG_STATUS.READY,
    org
  };
}
/**
 *
 * @param state
 * @param action
 */
export default function subOrgCreateReducer(
  state: CreateSubOrgState = initialState,
  action: AnyAction
): CreateSubOrgState {
  switch (action.type) {
    case ACTIONS.SUB_ORG_CREATE:
      return onCreateSuborg(state);
    case ACTIONS.SUB_ORG_SUCCESS:
      return onCreateSuborgSuccess(state);
    case ACTIONS.SUB_ORG_FAILURE:
      return onCreateSuborgFailure(state);
    case ACTIONS.SUB_ORG_INIT:
      return onSuborgInit(state, action.payload);
    case ACTIONS.SUB_ORG_RESET:
      return { ...initialState };
    default:
      return state;
  }
}
