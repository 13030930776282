/**
 * Date Picker
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { ReactElement } from 'react';
import makeStyles from '@mui/styles/makeStyles';
// import { KeyboardTimePicker } from '@material-ui/pickers';
// import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
// import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
// import { DownArrowTableIcon } from '../../icons';

const TIME_FORMAT = 'hh:mm a';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '10vw',
    '& .MuiFormLabel-root.Mui-error': {
      color: theme.palette.text.primary
    },
    [theme.breakpoints.down('lg')]: {
      width: '40% !important'
    }
  }
}));

export default function SLTimePicker(): ReactElement {
  const classes = useStyles();

  return (
    <>
      {/* <KeyboardTimePicker
        InputProps={
          {
            // disableUnderline: true,
          }
        }
        id={id}
        label={label}
        className={classes.root}
        margin="normal"
        ampm
        format={TIME_FORMAT}
        value={value}
        onChange={handleChange}
        helperText=""
        invalidDateMessage="Invalid Time"
        views={['hours', 'minutes']}
        variant="inline"
        keyboardIcon={<DownArrowTableIcon />}
      /> */}
    </>
  );
}
