/**
 * Forgot Password Page
 * @author mahesh.kedari@shorleineiot.com
 */
import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import { Navigate, useNavigate } from 'react-router';
import {
  SLForm,
  SLSubmit,
  useSlugContext,
  SLTextField,
  SLLinkButton,
  useQuery
} from '../../../framework';
import { useStyles } from './forgotPassword.styles';
import { registrationValidationRule as rules } from '../register/register-form/register.validation';
import AuthHeader from '../shared/AuthHeader';
import Passwords from '../register/register-form/components/Passwords';
import * as actions from './actions/forgotPassword.actions';
import { loginActions } from '../login/store/actions';
import { useForgotPasswordState } from './forgotPassword.selectorHooks';

type FormData = {
  code: any;
  password: string;
  retypePassword: string;
};

//
const ForgotPassword: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { slug } = useSlugContext();
  const navigate = useNavigate();
  const query = useQuery();
  const defaultValues: FormData = {
    code: query.get('code'),
    password: '',
    retypePassword: ''
  };

  const forgotPasswordState: any = useForgotPasswordState();

  useEffect(() => {
    return () => {
      dispatch(actions.forgotPasswordSubmitReset());
    };
  }, []);

  const onSubmit = (data: any) => {
    data = { ...data, email: query.get('email')?.trim() };
    dispatch(actions.forgotPasswordSubmit(data));
  };

  if (forgotPasswordState.isSetNewPasswordSuccess) {
    dispatch(loginActions.resetToLoginPage());
    return <Navigate to={`${slug}/auth/login`} replace />;
  }

  const cancelForgotPassword = (event: React.MouseEvent) => {
    event.preventDefault();
    dispatch(loginActions.resetToLoginPage());
    navigate(`/${slug}/auth/login`);
  };

  return (
    <Box className={classes.root}>
      <AuthHeader title="Reset Password" message="" translationId={'reset_password'} />
      <SLForm onSubmit={onSubmit} defaultValues={defaultValues}>
        <Box display="flex" flexDirection="column">
          <Box visibility="hidden">
            <SLTextField
              name="code"
              label="Please enter the verification code"
              rules={rules.code}
              disabled
              hidden
            />
          </Box>
          <Passwords />
        </Box>
        <Box display="flex" justifyContent="center" m={3}>
          <SLSubmit dataTestId="password-reset-button">Reset</SLSubmit>
          <SLLinkButton onClick={cancelForgotPassword}>Cancel</SLLinkButton>
        </Box>
      </SLForm>
    </Box>
  );
};

export default ForgotPassword;
