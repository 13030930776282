import { AnyAction } from 'redux';
import * as ACTION_TYPE from './deleteNarrowband.actionTypes';
import { deleteNarrowbandStatus, deleteNarrowbandState } from './deleteNarrowband.state';
/**
 *
 */

/**
 *
 */

const initialState: deleteNarrowbandState = {
  status: deleteNarrowbandStatus.INIT
};

function deleteNarrowbandProgress(state: deleteNarrowbandState): deleteNarrowbandState {
  return {
    ...state,
    status: deleteNarrowbandStatus.PROGRESS
  };
}
function deleteNarrowbandSuccess(state: deleteNarrowbandState): deleteNarrowbandState {
  return {
    ...state,
    status: deleteNarrowbandStatus.SUCCESS
  };
}
/**
 *
 * @param state
 */

/**
 *
 */

/**
 *
 */
function deleteNarrowbandFailure(state: deleteNarrowbandState): deleteNarrowbandState {
  return {
    ...state,
    status: deleteNarrowbandStatus.ERROR
  };
}

function deleteNarrowbandReset(state: deleteNarrowbandState): deleteNarrowbandState {
  return {
    ...state,
    status: deleteNarrowbandStatus.INIT
  };
}
/**
 *
 * @param state
 * @param action
 */
export default function deleteNarrowbandReducer(
  state: deleteNarrowbandState = initialState,
  action: AnyAction
): deleteNarrowbandState {
  switch (action.type) {
    case ACTION_TYPE.DELETE_NARROWBAND_PROGRESS:
      return deleteNarrowbandProgress(state);
    case ACTION_TYPE.DELETE_NARROWBAND_SUCCESS:
      return deleteNarrowbandSuccess(state);
    case ACTION_TYPE.DELETE_NARROWBAND_FAILURE:
      return deleteNarrowbandFailure(state);
    case ACTION_TYPE.DELETE_NARROWBAND_RESET_STATE:
      return deleteNarrowbandReset(state);
    default:
      return state;
  }
}
