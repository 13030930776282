/**
 * Login Form Styles
 * @author mahesh.kedari@shorelieiot.com
 */
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  submitButton: {
    width: '28%',
    '@media(min-width:600px)': {
      width: '28%'
    }
  },
  formStyle: {
    marginBottom: '30px'
  }
}));
