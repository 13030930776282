/**
 * Forgot Password Saga
 * @author mahesh.kedari@shorelineiot.com
 */
import { put } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import * as actions from '../actions/login.actions';
import { removeProgressFor, showToast } from '../../../../../framework';
import { AuthResponse, AuthService } from '../../../../../framework/services/auth.service';
/**
 *
 */
export function* forgotPassword(action: AnyAction) {
  try {
    const amplifyService = new AuthService();
    const email = action.payload;
    const response: AuthResponse = yield amplifyService.forgotPassword(email);
    yield put(removeProgressFor('SEND_FORGOT_PWD_LINK'));
    if (response.type === 'SUCCESS') {
      yield put(
        showToast('Reset password link will be sent to your email if it exists', 'success')
      );
      yield put(actions.forgotPasswordCodeSuccess(action.payload));
    } else {
      yield put(showToast('Error in sending reset link', 'error', true));
      yield put(actions.forgotPasswordCodeFailure(response.message));
    }
  } catch (error: any) {
    yield put(removeProgressFor('SEND_FORGOT_PWD_LINK'));
    yield put(showToast('Error in sending reset link', 'error', true));
    yield put(actions.forgotPasswordCodeFailure(error));
  }
}
