/**
 * Raw Data Query Handler custom hook
 * @author mahesh.kedari@shorelineiot.com
 * @param query
 */
import { useState, useEffect } from 'react';
import { httpGet, APISERVICES } from '../services';

export function useBatteryWidgetQuery(query: any, slug: string) {
  const [resultSet, setResultSet] = useState<any>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();
  async function fetchBatteryStatus() {
    try {
      httpGet(
        `orgs/${slug}/battery_status`,
        {
          days_left_min_threshold: query.days_left_min_threshold || 365,
          sort_asc_by: query.sort_asc_by
        },
        APISERVICES.DEVICE_API
      ).then((response: Array<any>) => {
        const sortedResponse = response.sort(
          (res1: any, res2: any) => res1.batt_remaining - res2.batt_remaining
        );
        setLoading(false);
        setResultSet(sortedResponse);
      });
    } catch (errorObject) {
      setError(errorObject);
      setLoading(false);
    }
  }
  useEffect(() => {
    if (isLoading) {
      return;
    }
    setError(undefined);
    setLoading(true);
    fetchBatteryStatus();
  }, [query]);

  return { resultSet, isLoading, error };
}
