/**
 * Raw Widget Data Selector hook
 * @author prerna.more@shorelineiot.com
 */
import { useSelector } from 'react-redux';
import { RootState } from '../../../../configureStore';

const useRawWidgetData = () =>
  useSelector((state: RootState) => state.features.devices.rawDatapoints);

const useRawWidgetDataStatus = () =>
  useSelector((state: RootState) => state.features.devices.rawDatapoints.status);

export { useRawWidgetData, useRawWidgetDataStatus };
