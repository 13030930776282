import { AnyAction } from 'redux';
import * as ACTION_TYPE from './updateRuleTemplate.actionTypes';
import { UpdateRuleTemplateState, UPDATE_RULE_TEMPLATE_STATUS } from './updateRuleTemplate.state';

const initialState: UpdateRuleTemplateState = {
  status: UPDATE_RULE_TEMPLATE_STATUS.INIT
};

function updateRuleTemplateProgress(state: UpdateRuleTemplateState): UpdateRuleTemplateState {
  return {
    ...state,
    status: UPDATE_RULE_TEMPLATE_STATUS.PROGRESS
  };
}

function updateRuleTemplateSuccess(
  state: UpdateRuleTemplateState,
  action: AnyAction
): UpdateRuleTemplateState {
  return {
    ...state,
    status: UPDATE_RULE_TEMPLATE_STATUS.SUCCESS,
    ruleDetails: action.data
  };
}

function updateRuleTemplateFailure(state: UpdateRuleTemplateState): UpdateRuleTemplateState {
  return {
    ...state,
    status: UPDATE_RULE_TEMPLATE_STATUS.ERROR
  };
}

function resetRuleTemplateUpdate() {
  return {
    ...initialState
  };
}
/**
 *
 * @param state
 * @param action
 */
export default function updateRuleTemplateReducer(
  state: UpdateRuleTemplateState = initialState,
  action: AnyAction
): UpdateRuleTemplateState {
  switch (action.type) {
    case ACTION_TYPE.UPDATE_RULE_TEMPLATE_PROGRESS:
      return updateRuleTemplateProgress(state);
    case ACTION_TYPE.UPDATE_RULE_TEMPLATE_SUCCESS:
      return updateRuleTemplateSuccess(state, action);
    case ACTION_TYPE.UPDATE_RULE_TEMPLATE_FAILURE:
      return updateRuleTemplateFailure(state);
    case ACTION_TYPE.UPDATE_RULE_TEMPLATE_RESET_STATE:
      return resetRuleTemplateUpdate();
    default:
      return state;
  }
}
