/**
 *
 */
import { combineReducers } from 'redux';
import { rolesList, rolesActions, rolesDetails, rolesUpdate } from '../store';

/**
 *
 */
const usersReducer = combineReducers({
  rolesList,
  rolesActions,
  rolesDetails,
  rolesUpdate
});
/**
 * Users reducer combined
 */
export default usersReducer;
