/**
 * Create Dashboard Item Reducer
 */
import { AnyAction } from 'redux';
import * as ACTION_TYPE from './createDashboardItem.actionsTypes';
import {
  CreateDashboardItemState,
  CreateDashboardItemStatus
} from './createDashboardItem.stateType';

const initialState: CreateDashboardItemState = {
  status: CreateDashboardItemStatus.INIT,
  createDashboardItemData: {}
};

function createDashboardItemProgress(state: CreateDashboardItemState): CreateDashboardItemState {
  return {
    ...state,
    status: CreateDashboardItemStatus.FETCHING
  };
}

function createDashboardItemSuccess(
  state: CreateDashboardItemState,
  createDashboardItemData: any
): CreateDashboardItemState {
  return {
    ...state,
    status: CreateDashboardItemStatus.READY,
    createDashboardItemData
  };
}

function createDashboardItemFailure(state: CreateDashboardItemState): CreateDashboardItemState {
  return {
    ...state,
    status: CreateDashboardItemStatus.ERROR
  };
}

function resetState(state: CreateDashboardItemState): CreateDashboardItemState {
  return {
    ...state,
    status: CreateDashboardItemStatus.INIT,
    createDashboardItemData: {}
  };
}
/*
    Reducer is a pure function that takes actions and state as input 
    and returns/passes the modified state to the store.
    It is implemented using switch case to update the state according 
    to the ation type required.
*/

export default function createDashboardItemReducer(
  state: CreateDashboardItemState = initialState,
  action: AnyAction
): CreateDashboardItemState {
  switch (action.type) {
    case ACTION_TYPE.CREATE_DASHBOARD_ITEM_PROGRESS:
      return createDashboardItemProgress(state);
    case ACTION_TYPE.CREATE_DASHBOARD_ITEM_SUCCESS:
      return createDashboardItemSuccess(state, action.payload);
    case ACTION_TYPE.CREATE_DASHBOARD_ITEM_FAILURE:
      return createDashboardItemFailure(state);
    case ACTION_TYPE.CREATE_DASHBOARD_ITEM_RESET_STATE:
      return resetState(state);
    default:
      return state;
  }
}
