import { put, takeEvery, all } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import * as actions from './actions/editModbus.actions';
import * as actionTypes from './actions/modbus.actionTypes';
import { datapointActions } from '../device-settings';
import {
  GenericObject,
  removeProgressFor,
  showProgressFor,
  httpPut,
  showToast,
  APISERVICES
} from '../../../../../framework';
import { SagaIterator } from 'redux-saga';

const UPDATE_MODBUS_ID = 'UPDATE_MODBUS';

function* updateModbus(action: AnyAction) {
  const data = action.payload;
  const body = {
    device_id: data.deviceId,
    sensor_module: data.sensorModule
  };

  try {
    yield put(showProgressFor(UPDATE_MODBUS_ID));
    const response: GenericObject = yield httpPut(
      `orgs/${data.slug}/device_sensor_modules/${data.sensorModuleUuid}`,
      body,
      APISERVICES.DEVICE_API
    );

    yield put(actions.updateModbusSuccess(response));
    yield put(showToast('Sensor updated successfully', 'success'));
    yield put(
      datapointActions.fetchDatapoints({
        slug: data.slug,
        deviceId: data.deviceId
      })
    );
  } catch (error: any) {
    yield put(showToast('Sensor could not be updated!', 'error', true));
    yield put(actions.updateModbusFailure(error));
  } finally {
    yield put(removeProgressFor(UPDATE_MODBUS_ID));
  }
}
/**
 *
 * @param action

/**
 *
 */
export function* watchUpdateModbusSaga(): SagaIterator {
  yield all([yield takeEvery(actionTypes.UPDATE_MODBUS_PROGRESS, updateModbus)]);
}
