/**
 * Asset Severity Age Viewer
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import MaterialTable from 'material-table';
import { Paper } from '@mui/material';
import { ArrowUpward, ChevronLeft, ChevronRight, FirstPage, LastPage } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router';
import { useStyles } from './tableWidget.styles';
import { getRedirectionPath } from '../../common/getRedirectionPath';
import { resolver } from '../../common/commonRedirection.helper';
import { ChartViewProps } from '../../common/ChartViewProps';
import {
  ORG_LIST_STATUS,
  useOrgSelector,
  useSubOrgList
} from '../../../../../features/organization/store';
import { useFetchDeviceGroups } from '../../../../../features/device';
import { useSlugContext } from '../../../../lib/slugContext';
import ConditionalFormattedColumn from '../../table/viewer/ConditionalFormattedColumn';

const columns = [
  {
    key: 'suborg_uuid',
    title: 'Site',
    width: 150,
    cellStyle: {}
  },
  {
    key: 'powertrain_name',
    title: 'Asset',
    width: 150,
    cellStyle: {}
  },
  {
    key: 'alarm_age',
    title: 'Age (Days)',
    width: 150,
    cellStyle: {}
  },
  { key: 'severity', title: 'Severity', width: 150, cellStyle: {} }
];
/**
 *
 * @param param0
 * @returns
 */
const SLTableView: React.FC<ChartViewProps> = React.forwardRef(
  ({ resultSet, chartSettings }: ChartViewProps, ref: any) => {
    const { slug } = useSlugContext();
    const classes = useStyles();
    const location = useLocation();
    const navigate = useNavigate();
    const stageCanvasRef: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
    const [tableHeight, setTableHeight] = useState(400);
    const orgSelector = useOrgSelector();
    const { deviceGroups } = useFetchDeviceGroups({ slug });
    const subOrgListState = useSubOrgList();

    const pathName = location?.pathname?.split('/');
    const isInEditWidgetMode = pathName[5] === 'edit' || false;

    useImperativeHandle(
      ref,
      () => ({
        resize() {
          const height: number = stageCanvasRef?.current?.offsetParent?.clientHeight ?? 500;
          setTableHeight(height);
        }
      }),
      []
    );

    useEffect(() => {
      if (stageCanvasRef?.current?.offsetParent?.clientHeight && !isInEditWidgetMode) {
        const height = stageCanvasRef?.current?.offsetParent?.clientHeight;
        if (height >= stageCanvasRef?.current?.scrollHeight) {
          setTableHeight(height);
        }
      } else {
        setTableHeight(500);
      }
    }, [stageCanvasRef?.current]);

    // **Code-Snippet-1 START**
    // Sub-Org Name Logic for specific specific suborg-uuid

    const getSubOrgInfo = (subOrgUUID: string) => {
      if (subOrgListState.status === ORG_LIST_STATUS.READY && subOrgListState.orgList.length > 0) {
        const subOrgInfo = subOrgListState?.orgList.find((subOrg) => subOrg.uuid === subOrgUUID);
        return subOrgInfo?.name;
      }
      return '';
    };

    const checkAndGetSubOrgName = (subOrgUUID: string) => {
      const subOrgName = getSubOrgInfo(subOrgUUID);
      if (subOrgName && subOrgName === '_phantom') {
        return orgSelector?.selectedOrg?.name;
      }
      return subOrgName || orgSelector?.selectedOrg?.name;
    };

    // **Code-Snippet-1 END**

    const getColumnTitle = (column: any) => {
      const configKey = column.key.replaceAll('.', '_');
      const columnSettings = chartSettings?.dataSettings?.[configKey];
      if (columnSettings && columnSettings.title) {
        return columnSettings.title;
      }
      return column.title;
    };

    /**
     * This function will determine if a specific column should be hidden or not.
     * @param0 chartData This will be a chartSettings object where we have dataSettings key.
     * @param1 columnData This is individual column data for the table.
     * @returns true/false
     */
    const isColumnHidden = (chartData: any, columnData: any) => {
      const formattedKey = columnData?.key?.replaceAll('.', '_');
      const hiddenColumn = chartData?.dataSettings?.[formattedKey]?.hideColumn === true;
      if (hiddenColumn) {
        return true;
      }
      return false;
    };

    return (
      <>
        <div className={classes.tableRoot} ref={stageCanvasRef}>
          <MaterialTable
            style={{
              width: '99%',
              marginLeft: 'auto',
              marginRight: 'auto',
              cursor: 'default',
              backgroundColor: '#ffffffff' // Transparancy added to material-table
            }}
            components={{
              Container: (props) => <Paper {...props} elevation={0} />
            }}
            icons={{
              SortArrow: forwardRef((props, arrowRef: React.Ref<SVGSVGElement>) => (
                <ArrowUpward {...props} ref={arrowRef} style={{ fill: '#000000' }} />
              )),
              NextPage: forwardRef((props, nextIconRef: React.Ref<SVGSVGElement>) => (
                <ChevronRight {...props} ref={nextIconRef} />
              )),
              PreviousPage: forwardRef((props, prevIconRef: React.Ref<SVGSVGElement>) => (
                <ChevronLeft {...props} ref={prevIconRef} />
              )),
              FirstPage: forwardRef((props, FirstIconRef: React.Ref<SVGSVGElement>) => (
                <FirstPage {...props} ref={FirstIconRef} />
              )),
              LastPage: forwardRef((props, LastIconRef: React.Ref<SVGSVGElement>) => (
                <LastPage {...props} ref={LastIconRef} />
              ))
            }}
            title=""
            columns={columns.map((column) => ({
              title: (
                <>
                  <div className={classes.colHeader}>{getColumnTitle(column)}</div>
                </>
              ),
              field: column.key,
              hidden: isColumnHidden(chartSettings, column),
              align: chartSettings?.columnAlignment || 'left',
              cellStyle: {
                paddingLeft: 1
              },
              headerStyle: {
                padding: 1
              },
              render: (data: any) => {
                const configKey = column.key;
                const columnSettings = chartSettings?.dataSettings?.[configKey];
                // **Code-Snippet-2 START**
                const columnData =
                  configKey == 'suborg_uuid'
                    ? checkAndGetSubOrgName(data[configKey])
                    : data[configKey];
                // **Code-Snippet-2 END**

                return (
                  <ConditionalFormattedColumn
                    data={columnData}
                    conditions={columnSettings?.conditions}
                    redirection={columnSettings?.redirection}
                    params={columnSettings?.params}
                    rowData={data}
                    chartSettings={chartSettings}
                  />
                );
              }
            }))}
            onRowClick={(event: any, rowData: any) => {
              if (!chartSettings?.fullRowRedirection) {
                return;
              }
              const redirectionFound: any = Object.entries(chartSettings?.dataSettings).find(
                ([key, value]: any) => {
                  return key && value?.redirection === 'alarms';
                }
              );

              if (redirectionFound) {
                const configKey = redirectionFound[0]?.replaceAll('.', '_');
                const columnSettings = chartSettings?.dataSettings?.[configKey];

                navigate(
                  getRedirectionPath(
                    slug || '',
                    columnSettings?.redirection || '',
                    resolver,
                    columnSettings?.params,
                    rowData,
                    chartSettings,
                    deviceGroups
                  )
                );
              }
            }}
            data={resultSet}
            options={{
              search: chartSettings?.search || false,
              toolbar: false,
              filtering: false,
              selection: false,
              sorting: chartSettings?.sorting || false,
              maxBodyHeight: tableHeight - 111,
              actionsColumnIndex: 1,
              paging: resultSet?.length > 50,
              pageSize: 10,
              pageSizeOptions: [10, 25, 50, 100],
              draggable: false
            }}
          />
        </div>
      </>
    );
  }
);

export default SLTableView;
