/**
 * deletePersonalizeSettings Saga
 * @author prerna.more@shorelineiot.com
 */
import { put, takeEvery } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import * as ACTIONS from './deletePersonalizationSettings.actionTypes';
import * as deletePersonalizationInfoActions from './deletePersonalizeSettings.actions';
import {
  GenericObject,
  httpDelete,
  removeProgressFor,
  showProgressFor,
  showToast,
  APISERVICES
} from '../../../../../framework';
import { SagaIterator } from 'redux-saga';

const DELETE_PERSONALIZATION_INFO = 'DELETE_PERSONALIZATION_INFO';

export function* deletePersonalizationInfoSaga(action: AnyAction) {
  yield put(showProgressFor(DELETE_PERSONALIZATION_INFO));
  try {
    const response: GenericObject = yield httpDelete(
      `orgs/${action.payload.slug}/org_personalization?image_type=${action.payload.image_type}`,
      null,
      APISERVICES.HOST_URL
    );
    yield put(deletePersonalizationInfoActions.deletePersonalizationInfoSuccess(response));
  } catch (error: any) {
    yield put(showToast('Error in Fetching Personalisation Info.', 'error', true));
    yield put(deletePersonalizationInfoActions.deletePersonalizationInfoFailure(error));
  } finally {
    yield put(deletePersonalizationInfoActions.deletePersonalizationInfoReset());
    yield put(removeProgressFor(DELETE_PERSONALIZATION_INFO));
  }
}

export default function* watchDeletePersonalizeSettingsSaga(): SagaIterator {
  yield takeEvery(ACTIONS.DELETE_THEMES, deletePersonalizationInfoSaga);
}
