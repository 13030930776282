/**
 * Toast Message Template
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { ReactNode } from 'react';

import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import { useStyles } from './toastTemplate.styles';
import { closeToast } from '../toast.actions';
import { ToastType } from '../../../types';
import { useToasts } from '../toast.selectorHooks';

interface Props {
  id: string | number;
  message?: ReactNode;
  variant: any;
  [key: string]: any;
}
/**
 *
 */
const ToastTemplate = React.forwardRef(({ id, message }: Props, ref: any) => {
  let className = '';
  const classes: any = useStyles();
  const toasts: Array<ToastType> = useToasts();
  const dispatch = useDispatch();
  /*
   * TODO: UPDATE THIS SECTION LATER. FOR TIME BEING THIS SOLUTION IS ADDED.
   * BUT THIS IS NOT THE OPTIMISED WAY OF HANDLING THIS PROBLEM
   */
  const toast: ToastType | undefined = toasts.find((t: ToastType) => {
    return t.key === id;
  });
  if (toast) {
    const { variant } = toast.options;

    className = classes[variant];
    const handleDismiss = () => {
      dispatch(closeToast(id));
    };
    return (
      <Card className={`${classes.card} ${className}`} ref={ref}>
        <CardActions classes={{ root: classes.actionRoot }}>
          <Box display="flex" flexDirection="column" flexGrow="1">
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Typography variant="body2" className={`${classes.title} ${className}`}>
                {variant}
              </Typography>
              <IconButton className={classes.close} onClick={handleDismiss} size="large">
                <CloseIcon className={classes.closeIcon} fontSize="small" />
              </IconButton>
            </Box>
            <Box display="flex" flexDirection="row">
              <Typography variant="body2" className={classes.message}>
                {message}
              </Typography>
              <div className={classes.icons} />
            </Box>
          </Box>
        </CardActions>
      </Card>
    );
  }
  return null;
});

export default ToastTemplate;
