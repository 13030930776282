import { MountPointType, NarrowbandType, updateNBCellColumns } from './componentDetails.type';
const ACCEL_800_ODR_FMAX_LIMIT = 88;
const ACCEL_3200_ODR_FMAX_LIMIT = 1400;
const STRUCT_BORNE_FMIN_LIMIT = 10000;

const toTitleCase = (phrase: string) => {
  return phrase
    .toLowerCase()
    .split(' ')
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const getNominalRPMFrequency = (nominalRPM?: number) => {
  if (nominalRPM) {
    return parseFloat((nominalRPM / 60).toFixed(2));
  }
  return 0;
};

export const getOrderFMaxFrequency = (cfMax: number, nominalRPM?: number) => {
  const nominalFreq = getNominalRPMFrequency(nominalRPM);
  const fMax = parseFloat((cfMax * nominalFreq).toFixed(2));
  return fMax;
};

export const checkUnitPresent = (freqString: string) => {
  const unitPresentFlag =
    toTitleCase(freqString).includes('X') || toTitleCase(freqString).includes('Hz');
  return unitPresentFlag;
};

export const getNarrowbandODRByFMax = (fMax: number) => {
  let ODR = 800;
  if (fMax > STRUCT_BORNE_FMIN_LIMIT) {
    ODR = 80000;
  } else if (fMax > ACCEL_3200_ODR_FMAX_LIMIT) {
    ODR = 25600;
  } else if (fMax > ACCEL_800_ODR_FMAX_LIMIT) {
    ODR = 3200;
  }
  return ODR;
};
export const getMinMaxFrequency = (data: string) => {
  const fMin = parseFloat(data.split('-')[0]);
  const fMax = parseFloat(data.split('-')[1]);
  return { fMin, fMax };
};
export const checkOrderUnitPresent = (freqString: string) => {
  const unitPresentFlag = toTitleCase(freqString).includes('X');
  return unitPresentFlag;
};
export const getRawNBName = (nbName: string) => {
  let rawName: string[] | string = nbName?.split(',');
  // checking if name has ',' then pop or else skip
  if (rawName?.length > 1) {
    rawName?.pop();
  }
  rawName = rawName?.join(',');
  return rawName;
};
export const preProcessTableData = (
  narrowbandList: NarrowbandType[],
  currentMountPoints?: MountPointType[],
  newMountPoint?: MountPointType
) => {
  const narrowbandData: NarrowbandType[] = [];
  const mountPoints: MountPointType[] = [];
  const originalNarrowbandData: NarrowbandType[] = [];
  narrowbandList?.forEach((nbData: NarrowbandType) => {
    const found = narrowbandData.some(
      (preProcessedDataObj: { name: string; path: string }) =>
        getRawNBName(preProcessedDataObj?.name) === getRawNBName(nbData?.name) &&
        preProcessedDataObj?.path === nbData?.path
    );
    const data: NarrowbandType = {
      ...nbData,
      freq_order: `${nbData?.cfmin} - ${nbData?.cfmax} X`,
      name: getRawNBName(nbData?.name),
      raw_name: getRawNBName(nbData?.name),
      original_name: nbData.name
    };
    let copyPoints: MountPointType[] = [];
    nbData?.mount_pts?.forEach((point: MountPointType) => {
      //checking existing and taking unique mount points
      if (
        !mountPoints?.some(
          (element: MountPointType) => element.mount_pt_name === point.mount_pt_name
        )
      ) {
        mountPoints.push({ ...point, value: point.mount_pt_id, label: point.mount_pt_name });
      }

      //mapping already copied mount points
      if (currentMountPoints && currentMountPoints.length > 0) {
        copyPoints = currentMountPoints.filter(
          (copyPoint: MountPointType) => copyPoint.mount_pt_copy_id === point?.mount_pt_id
        );
      }
      //when user selects new copy of mount point
      if (newMountPoint && newMountPoint.mount_pt_copy_id === point?.mount_pt_id) {
        copyPoints.push(newMountPoint);
      }
    });
    //updating respective narrowbands mount_pts with old and new mount points
    data.mount_pts = nbData?.mount_pts ? [...nbData.mount_pts, ...copyPoints] : [...copyPoints];

    if (nbData?.cfmax !== 0 && nbData?.cfmin !== 0 && nbData?.fmax === 0 && nbData?.fmin === 0) {
      data.freq_order = `${nbData?.cfmin} - ${nbData?.cfmax} X`;
      if (found === false) {
        narrowbandData.push(data);
      }
      originalNarrowbandData.push(nbData);
    } else {
      data.freq_order = `${nbData?.fmin} - ${nbData?.fmax} Hz`;
      if (found === false) {
        narrowbandData.push(data);
      }
      originalNarrowbandData.push(nbData);
    }
  });
  const finalMountPoints: MountPointType[] = currentMountPoints ? currentMountPoints : mountPoints;
  if (newMountPoint && newMountPoint.mount_pt_copy_id) {
    finalMountPoints.push({
      mount_pt_name: newMountPoint.mount_pt_name,
      mount_pt_copy_id: newMountPoint.mount_pt_copy_id,
      value: newMountPoint.mount_pt_copy_id,
      label: newMountPoint.mount_pt_name
    });
  }

  return { narrowbandData, mountPoints: finalMountPoints, originalNarrowbandData };
};

export const addMountPointToNBs = (
  narrowbandList: NarrowbandType[],
  currentMountPoints: MountPointType[],
  newMountPoint: MountPointType
) => {
  /**
   * Adding single copied mount point to respective NBs
   */
  const narrowbandData: NarrowbandType[] = [];
  narrowbandList?.forEach((nbData: NarrowbandType) => {
    const mount_pts: MountPointType[] = [];
    nbData.mount_pts.forEach((pt: MountPointType) => {
      if (newMountPoint.mount_pt_copy_id === pt.mount_pt_id) {
        mount_pts.push(newMountPoint);
      }
      mount_pts.push(pt);
    });
    nbData.mount_pts = mount_pts;
    narrowbandData.push(nbData);
  });
  const finalMountPoints: MountPointType[] = currentMountPoints;
  if (newMountPoint && newMountPoint.mount_pt_copy_id) {
    finalMountPoints.push({
      mount_pt_name: newMountPoint.mount_pt_name,
      mount_pt_copy_id: newMountPoint.mount_pt_copy_id,
      value: newMountPoint.mount_pt_copy_id,
      label: newMountPoint.mount_pt_name
    });
  }
  return { narrowbandData, mountPoints: finalMountPoints };
};

export const updateMountPointToNBs = (
  narrowbandList: NarrowbandType[],
  currentMountPoints: MountPointType[],
  updatedMountPoint: any
) => {
  /**
   * Updating mount point name on all nbs
   */
  const narrowbandData: NarrowbandType[] = [];
  narrowbandList?.forEach((nbData: NarrowbandType) => {
    const mount_pts: MountPointType[] = [];
    nbData.mount_pts.forEach((pt: MountPointType) => {
      if (
        pt.mount_pt_id &&
        updatedMountPoint.old.mount_pt_id === pt.mount_pt_id &&
        updatedMountPoint.old.mount_pt_name === pt.mount_pt_name
      ) {
        mount_pts.push({
          mount_pt_name: updatedMountPoint.mount_pt_name,
          mount_pt_id: updatedMountPoint.mount_pt_id,
          value: updatedMountPoint.mount_pt_id,
          label: updatedMountPoint.mount_pt_name
        });
      } else if (
        pt.mount_pt_copy_id &&
        updatedMountPoint.old.mount_pt_copy_id === pt.mount_pt_copy_id &&
        updatedMountPoint.old.mount_pt_name === pt.mount_pt_name
      ) {
        mount_pts.push({
          mount_pt_name: updatedMountPoint.mount_pt_name,
          mount_pt_copy_id: updatedMountPoint.mount_pt_copy_id,
          value: updatedMountPoint.mount_pt_copy_id,
          label: updatedMountPoint.mount_pt_name
        });
      } else {
        mount_pts.push(pt);
      }
    });
    nbData.mount_pts = mount_pts;
    narrowbandData.push(nbData);
  });

  const finalMountPoints: MountPointType[] = currentMountPoints.map((pt: MountPointType) => {
    if (
      pt.mount_pt_id &&
      updatedMountPoint.old.mount_pt_id === pt.mount_pt_id &&
      updatedMountPoint.old.mount_pt_name === pt.mount_pt_name
    ) {
      return {
        mount_pt_name: updatedMountPoint.mount_pt_name,
        mount_pt_id: updatedMountPoint.mount_pt_id,
        value: updatedMountPoint.mount_pt_id,
        label: updatedMountPoint.mount_pt_name
      };
    } else if (
      pt.mount_pt_copy_id &&
      updatedMountPoint.old.mount_pt_copy_id === pt.mount_pt_copy_id &&
      updatedMountPoint.old.mount_pt_name === pt.mount_pt_name
    ) {
      return {
        mount_pt_name: updatedMountPoint.mount_pt_name,
        mount_pt_copy_id: updatedMountPoint.mount_pt_copy_id,
        value: updatedMountPoint.mount_pt_copy_id,
        label: updatedMountPoint.mount_pt_name
      };
    }

    return pt;
  });
  return { narrowbandData, mountPoints: finalMountPoints };
};

export const updateMountPointToSingleNB = (
  narrowbandList: NarrowbandType[],
  changedMountPoints: MountPointType[],
  nbToUpdate: NarrowbandType
) => {
  /**
   * Updating mount point on single NB
   */
  const narrowbandData: NarrowbandType[] = narrowbandList?.map((nbData: NarrowbandType) => {
    /**
     * Here we are also covering condition for newly added narrowband which does not have raw name
     */
    if (
      (nbData.raw_name && nbData.raw_name === nbToUpdate.raw_name) ||
      (nbToUpdate.new === true && nbData.tempId === nbToUpdate.tempId)
    ) {
      nbData.mount_pts = changedMountPoints;
    }
    return nbData;
  });

  return { narrowbandData };
};

export const updateSingleNB = (
  narrowbandList: NarrowbandType[],
  changedCell: { name: updateNBCellColumns; value: any },
  nbToUpdate: NarrowbandType
) => {
  /**
   * Updating single nb info
   */

  const narrowbandData: NarrowbandType[] = [...narrowbandList].map((nbData: NarrowbandType) => {
    const currentNB = Object.assign({}, nbData);
    /**
     * nbToUpdate.new means newly added NB on table
     * as multiple new nb can be added so we have to check for their name also
     */
    if (
      (nbToUpdate.new === true && nbToUpdate.tempId && nbToUpdate.tempId === nbData.tempId) ||
      (nbData.raw_name && nbData.raw_name === nbToUpdate.raw_name)
    ) {
      if (changedCell.name === 'freq_order') {
        const unitPresentFlag = checkOrderUnitPresent(changedCell.value);
        const { fMin, fMax } = getMinMaxFrequency(changedCell.value);
        if (unitPresentFlag === true) {
          currentNB.cfmin = fMin;
          currentNB.cfmax = fMax;
        } else {
          currentNB.fmin = fMin;
          currentNB.fmax = fMax;
        }
      }
      currentNB[changedCell.name] = changedCell.value;
    }

    return currentNB;
  });

  return { narrowbandData };
};

export const deleteSingleNB = (narrowbandList: NarrowbandType[], nbToDelete: NarrowbandType) => {
  const narrowbandData: NarrowbandType[] = narrowbandList?.filter((nbData: NarrowbandType) => {
    if (
      (nbToDelete.new === true && nbToDelete.tempId !== nbData.tempId) ||
      nbData?.raw_name !== nbToDelete?.raw_name
    ) {
      return nbData;
    }
  });
  return { narrowbandData };
};
