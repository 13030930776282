/**
 * Register Reducer
 * @author mahesh.kedari@shorelineiot.com
 * Update by Swapnil
 */
import * as ACTIONS from '../actions/register.actionTypes';

export const initialState = {
  status: 'UNKNOWN',
  isLoading: false,
  isSigningUp: false,
  error: false,
  isTNCAccepted: true,
  userVerified: false,
  signup: '',
  errorList: {}
};
/**
 *
 * @param state old state
 * @param action Action to perform
 */
export default function registerReducer(state = initialState, action: any) {
  switch (action.type) {
    case ACTIONS.RESET_REGISTER_STATE:
      return {
        status: 'UNKNOWN',
        isLoading: false,
        isSigningUp: false,
        error: false,
        isTNCAccepted: true,
        userVerified: false,
        signup: '',
        errorList: {}
      };
    case ACTIONS.ON_VERIFICATION_PAGE:
      return {
        ...state,
        isSigningUp: false
      };
    case ACTIONS.SIGNUP_PROGERSS:
      return {
        ...state,
        isLoading: true,
        error: false,
        errorList: null
      };
    case ACTIONS.SIGNUP_SUCCESS:
      return {
        ...state,
        signup: action.payload.response.user.username,
        isLoading: false,
        isSigningUp: true,
        userVerified: action.payload.response.isSignUpComplete
      };
    case ACTIONS.SIGNUP_FAILURE:
      return {
        ...state,
        error: true,
        isLoading: false,
        errorList: action.payload,
        isSigningUp: false
      };
    case ACTIONS.CONFIRM_USER: {
      return {
        ...state,
        status: 'CONFIRM_USER_PROGRESS'
      };
    }
    case ACTIONS.CONFIRM_USER_SUCCESS: {
      return {
        ...state,
        status: 'CONFIRM_USER_SUCCESS'
      };
    }
    case ACTIONS.CONFIRM_USER_FAILURE: {
      return {
        ...state,
        status: 'ERROR',
        errorList: action.error
      };
    }
    default:
      return state;
  }
}
