import { put, takeEvery, all } from 'redux-saga/effects';
import * as actions from './deleteDashboardItem.actions';
import * as ACTION_TYPE from './deleteDashboardItem.actionTypes';
import {
  httpDelete,
  removeProgressFor,
  showProgressFor,
  showToast,
  APISERVICES
} from '../../../../framework';
import { SagaIterator } from 'redux-saga';

const DELETE_DASHBOARD_ITEM = 'DELETE_DASHBOARD_ITEM';

function* deleteDashboardItemSaga(action: any) {
  const data = action.payload;
  yield put(showProgressFor(DELETE_DASHBOARD_ITEM));
  try {
    yield httpDelete(
      `orgs/${data.slug}/dashboard_item/${data.dashboard_item_uuid}`,
      null,
      APISERVICES.HOST_URL
    );
    yield put(actions.deleteDashboardItemSuccess(data.dashboard_item_uuid));
    yield put(showToast('Dashboard Item deleted successfully!', 'success'));
  } catch (error: any) {
    yield put(showToast('Dashboard Item could not be deleted!', 'error', true));
    yield put(actions.deleteDashboardItemFailure(error));
  } finally {
    yield put(removeProgressFor(DELETE_DASHBOARD_ITEM));
  }
}

export function* watchDeleteDashboardItemSagas(): SagaIterator {
  yield all([takeEvery(ACTION_TYPE.DELETE_DASHBOARD_ITEM_PROGRESS, deleteDashboardItemSaga)]);
}
