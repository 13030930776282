export interface TreeDataType {
  title: string;
  rawTitle?: string;
  value: string;
  checkable?: boolean;
  children?: Array<TreeDataType>;
  disabled?: boolean;
  disableCheckbox?: boolean;
}

export enum CHECKED_STRATEGY_TYPE {
  SHOW_PARENT = 'SHOW_PARENT',
  SHOW_ALL = 'SHOW_ALL',
  SHOW_CHILD = 'SHOW_CHILD'
}

export enum TREEDATA_PARENT {
  TITLE = 'All',
  VALUE = 'all'
}
