/**
 * Organisation Slug Context
 * @author mahesh.kedari@shorelineiot.com
 */
import { useContext, createContext } from 'react';

type SlugContextProps = {
  slug: string;
  setSlug: React.Dispatch<React.SetStateAction<string>>;
};

const defaultContextValue: SlugContextProps = {
  slug: '',
  setSlug: () => {
    // do nothing by default
  }
};

export const SlugContext = createContext<SlugContextProps>(defaultContextValue);

export function useSlugContext() {
  return useContext(SlugContext);
}
