interface Conditions {
  device: string;
  dpid: string;
  operator: string;
  value: number;
  unit: string;
}
interface EmailActions {
  email: any;
  subject: string;
  message: string;
  body: string;
}
export interface CreateAlertForm {
  name: string;
  alert_level: string;
  conditions: Array<Conditions>;
  email_actions: Array<EmailActions>;
  validationCount: number;
}

export const createAlertDefaults: CreateAlertForm = {
  name: '',
  alert_level: '',
  validationCount: 3,

  conditions: [
    {
      device: '',
      dpid: '',
      operator: '',
      value: 0,
      unit: ''
    }
  ],

  email_actions: [
    {
      email: '',
      subject: '{{ device name }} - {{ alert level }} alert on {{ datapoint name }}',
      message: '',
      body: 'Hi, {{ alert level }} alert on rule {{ alert name }} for device {{ device name }} at {{ timestamp }}. Current value: {{ values }} {{ unit }} is {{ alert threshold }}.'
    }
  ]
};
