import { put, takeEvery, call } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import * as actions from '../actions/getSensorAlert.actions';
import * as createAlertActions from '../actions/createAlert.actions';
import * as actionTypes from '../actions/alerts.actionTypes';
import { datapointActions } from '../../../device/device-details';
import { httpGet, SafeSaga, APISERVICES } from '../../../../framework';
import { SagaIterator } from 'redux-saga';
import { RuleConfig } from '../types/CreateAlertState';

function getSensorAlert(slug: string, id: number) {
  return httpGet(`orgs/${slug}/alerts/${id}`, null, APISERVICES.DEVICE_API);
}

export function* getAlert(action: AnyAction) {
  const { slug, id } = action.payload;
  const response: RuleConfig = yield call(getSensorAlert, slug, id);

  let alertLevel = '';
  let deviceId = '';
  const conditions = response.conditions.map((condition: any) => {
    alertLevel = condition.alert_level;
    deviceId = condition.device_id;
    return {
      condition_id: condition.condition_id,
      device: condition.device_id,
      dpid: condition.dpid,
      operator: condition.operator,
      value: condition.operation_data.value,
      validation_count: condition.validation_count,
      unit: ''
    };
  });
  const emailActions = response.actions.map((action: any) => {
    return {
      subject: action.email.subject,
      body: action.email.body,
      type: 'email'
    };
  });
  const alert = {
    name: response.name,
    conditions,
    email_actions: emailActions,
    alert_level: alertLevel,
    skipReview: Boolean(response?.skip_review)
  };
  const dataPayload = {
    deviceId,
    slug
  };
  yield put(datapointActions.fetchDatapoints(dataPayload));
  yield put(createAlertActions.conditionsFormData(alert));

  yield put(actions.getSensorAlertSuccess(response));
}

function* errorHandler(error: any) {
  yield put(actions.getSensorAlertFailure(error));
}

export function* watchGetSensorAlertSaga(): SagaIterator {
  yield takeEvery(
    actionTypes.FETCH_SENSOR_ALERT_PROGRESS,
    SafeSaga(getAlert, actionTypes.FETCH_SENSOR_ALERT_PROGRESS, errorHandler)
  );
}
