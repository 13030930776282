/**
 * Hide Datapoint Helper functions
 * @author ninad.kulkarni@shorelineiot.com
 */
import { DatapointsListState } from '../types';
/**
 *
 * @param state
 */
export function onHideDatapoint(state: DatapointsListState): DatapointsListState {
  return {
    ...state
  };
}
/**
 *
 * @param state
 * @param datapointId
 */
export function onHideDatapointSuccess(
  state: DatapointsListState,
  dpid: number
): DatapointsListState {
  // console.log(datapointId);
  /* const { [dpid]: value1, ...datapointConfigs } = state.datapointConfigs;
  const { [dpid]: value2, ...lastDataValue } = state.lastDataValue;
  const { [dpid]: value3, ...liveDataConfigs } = state.liveDataConfigs;
  const { [dpid]: value4, ...viewConfigs } = state.viewConfigs;
  return {
    ...state,
    datapointConfigs,
    lastDataValue,
    liveDataConfigs,
    viewConfigs,
  }; */
  const hiddenDatapoint = state?.datapointConfigs[dpid];
  hiddenDatapoint.widget_settings.settings = {
    ...hiddenDatapoint?.widget_settings?.settings,
    show: false
  };
  return {
    ...state,
    datapointConfigs: { ...state.datapointConfigs, [dpid]: hiddenDatapoint }
  };
}
/**
 *
 * @param state
 * @param datapointId
 */
export function onHideDatapointFailure(state: DatapointsListState): DatapointsListState {
  return {
    ...state
  };
}
