import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { generatePath, useNavigate, useLocation } from 'react-router-dom';
import { LightTooltip } from '../../tooltip/Tooltip';
import { useStyles } from './imageMarkingWidget.styles';
import { getAlertName, getCorrectAlert, getRelativeTime } from './imageMarking.utils';
import { useSlugContext } from '../../../lib/slugContext';
import { NeverProvisionedIcon, ProvisioningInProcess, ProvisionFailedIcon } from '../../../icons';
import { DEVICE_PROVISION_STATUS, DeviceProvisionStatus } from '../../../constants/provision';
import {
  ALARM_TYPE_OPTIONS,
  ALERT_LEVEL,
  Alarm,
  AlertLevel
} from '../../../../features/alarms/store';
import {
  useFetchAssetWidgetData,
  useFetchDevices,
  useFetchGenericDashboard
} from '../../../../features/device/store/device.hooks';
import {
  DASHBOARD_TYPE,
  WIDGET_TYPE
} from '../../../../features/device/store/types/genericDashboard.types';
import { skipToken } from '@reduxjs/toolkit/query';
import { useFetchAlarms } from '../../../../features/alarms/store/alarms.hooks';

/**
 * CustomMarker component displays a marker with tooltip information on the asset view page.
 * It fetches necessary data and updates the tooltip based on the provisioning status and alert levels.
 */
const CustomMarker = ({
  sensor_id,
  itemNumber,
  name,
  description,
  last_connected_ts,
  provisioning_status
}: any) => {
  const classes = useStyles();
  const { slug } = useSlugContext();
  const location = useLocation();
  const navigate = useNavigate();

  const [tooltipNewData, setTooltipNewData] = useState<Alarm | null>(null);
  const [severityFromSensorList, setSeverityFromSensorList] = useState<AlertLevel | undefined>(
    undefined
  );

  const severity = getCorrectAlert(severityFromSensorList);
  const alertName = getAlertName(severityFromSensorList);
  const lastConnected = getRelativeTime(Number(last_connected_ts) / 1000);
  const dataFor = `component_${sensor_id}`;

  const params = new URLSearchParams(location.search);
  const isNewAssetDashboardPathnamePresent = location.pathname.includes('new-asset-dashboard');
  const path = params.get('fq_dg_path');

  // Fetch necessary data using hooks
  const { genericDashboard } = useFetchGenericDashboard({
    slug,
    dashboard_type: DASHBOARD_TYPE.ASSET_DASHBOARD
  });

  const assetAlarmsWidgetInfo = genericDashboard?.widgets?.find(
    (item) => item?.widget_type === WIDGET_TYPE.ASSET_ALARMS
  );
  const snsListWidgetInfo = genericDashboard?.widgets?.find(
    (item) => item?.widget_type === WIDGET_TYPE.SNS_LIST
  );

  const { alarms, isAlarmsFetchSuccess } = useFetchAlarms({
    slug,
    alarmType: ALARM_TYPE_OPTIONS.ASSET.value,
    isHistoricalAlarms: false
  });

  const { devices, isFetchingDevicesSuccess } = useFetchDevices({ slug });

  const {
    assetWidgetData: assetAlarmsWidgetData,
    isFetchingAssetWidgetDataSuccess: isFetchingAssetAlarmsWidgetDataSuccess
  } = useFetchAssetWidgetData(
    assetAlarmsWidgetInfo?.widget_uuid && path
      ? { slug, asset_path: path, widget_uuid: assetAlarmsWidgetInfo?.widget_uuid }
      : skipToken
  );

  const {
    assetWidgetData: snsListWidgetData,
    isFetchingAssetWidgetDataSuccess: isFetchingSnsListWidgetDataSuccess
  } = useFetchAssetWidgetData(
    snsListWidgetInfo?.widget_uuid && path
      ? { slug, asset_path: path, widget_uuid: snsListWidgetInfo?.widget_uuid }
      : skipToken
  );

  const isDeviceNotProvisioned = [
    DEVICE_PROVISION_STATUS.NEVER_PROVISIONED,
    DEVICE_PROVISION_STATUS.DE_PROVISIONED,
    DEVICE_PROVISION_STATUS.PROV_SERIAL_NUMBER_ATTACHED
  ].includes(provisioning_status);

  const isProvisioningFailed = provisioning_status === DEVICE_PROVISION_STATUS.PROV_FAILED;
  const isProvisioningInProgress = [
    DEVICE_PROVISION_STATUS.PROV_IN_PROGRESS,
    DEVICE_PROVISION_STATUS.PROV_CERTS_GENERATED
  ].includes(provisioning_status);

  /**
   * Generates the path for the alarms page based on the alert level and device IDs.
   */
  const getAlarmsPagePath = (
    alertLevel: AlertLevel,
    deviceIds: number,
    data: { assetDashboardURL: boolean }
  ) => {
    return generatePath(
      `/${slug}/app/alarms?isGroup=true&severity=${alertLevel}&path=${deviceIds}&alarm_type=${
        data?.assetDashboardURL ? ALARM_TYPE_OPTIONS.ASSET.value : ALARM_TYPE_OPTIONS.SENSOR.value
      }`,
      { alertLevel, deviceIds }
    );
  };

  /**
   * Redirects to the asset alarms page based on the device ID and alert level.
   */
  const redirectToAssetAlarms = (deviceId: number, alertLevel: AlertLevel) => {
    if (alertLevel !== ALERT_LEVEL.ALERT_NONE) {
      navigate(getAlarmsPagePath(alertLevel, deviceId, { assetDashboardURL: true }));
    }
  };

  /**
   * Determines the provisioning status to be displayed.
   */
  const getProvisioningStatus = (
    isProvisioningInProgress: boolean,
    provisioning_status: DeviceProvisionStatus
  ) => {
    return isProvisioningInProgress
      ? DEVICE_PROVISION_STATUS.PROV_IN_PROGRESS
      : provisioning_status;
  };

  /**
   * Tooltip data rendering based on the device status and alert levels.
   */
  const TooltipData = () => {
    const renderInfo = () => {
      if (isDeviceNotProvisioned || severityFromSensorList === ALERT_LEVEL.ALERT_NONE) {
        return (
          <>
            <span>
              <span className={classes.boldStyle}>Description -</span> {description}
            </span>
            <br />
            <span>
              <span className={classes.boldStyle}>Provisioning Status -</span>
              {getProvisioningStatus(isProvisioningInProgress, provisioning_status)}
            </span>
            <br />
            <span>
              <span className={classes.boldStyle}>Alert Level - </span>
              {alertName}
            </span>
            <br />
            <span>
              <span className={classes.boldStyle}>Last Connected -</span>
              {lastConnected}
            </span>
            <br />
            {isDeviceNotProvisioned && <p>Provision to capture data</p>}
          </>
        );
      }
      if (!isDeviceNotProvisioned) {
        return (
          <>
            <span className={classes.boldStyle}>Anomaly -</span> {tooltipNewData?.anomaly} <br />
            <span className={classes.boldStyle}>Diagnosis -</span>{' '}
            {tooltipNewData?.analyst_diagnosis || tooltipNewData?.diagnosis} <br />
            <span className={classes.boldStyle}>Recommendation -</span>{' '}
            {tooltipNewData?.analyst_recommendation || tooltipNewData?.recommendation} <br />
          </>
        );
      }
      return '';
    };

    return (
      <p className={classes.tooltipWidth}>
        <span className={classes.boldStyle}>Name -</span> {name} <br />
        {renderInfo()}
      </p>
    );
  };

  /**
   * Filter out `REVIEW` state alarms from all the users.
   * @param alarms `Alarms[]`
   * @returns `Alarms[]`
   */
  const filterOutReviewStateAlarms = (alarms: Alarm[]): Alarm[] => {
    return alarms?.filter((alarm) => alarm?.status !== 'REVIEW');
  };

  /**
   * Fetch and update tooltip data based on sensor and alarms data.
   */
  useEffect(() => {
    if (
      severityFromSensorList &&
      (isFetchingAssetAlarmsWidgetDataSuccess || isAlarmsFetchSuccess)
    ) {
      const sensorAssetAlarmsList =
        !isNewAssetDashboardPathnamePresent && assetAlarmsWidgetData?.widget_type === 'ASSET_ALARMS'
          ? assetAlarmsWidgetData?.data?.filter((item) => item?.device_ids?.includes(sensor_id))
          : alarms?.filter((alarm) => alarm.device_ids?.includes(sensor_id));

      const alarmsWithoutReviewState = filterOutReviewStateAlarms(sensorAssetAlarmsList);

      const sortedAssetAlarmsListByEpoch = alarmsWithoutReviewState?.sort(
        (a, b) => a?.created - b?.created
      );
      const criticalAlarms = sortedAssetAlarmsListByEpoch?.filter(
        (item) => item?.severity === ALERT_LEVEL.ALERT_FATAL
      );
      const highAlarms = sortedAssetAlarmsListByEpoch?.filter(
        (item) => item?.severity === ALERT_LEVEL.ALERT_ERROR
      );
      const warningAlarms = sortedAssetAlarmsListByEpoch?.filter(
        (item) => item?.severity === ALERT_LEVEL.ALERT_WARNING
      );
      const lowAlarms = sortedAssetAlarmsListByEpoch?.filter(
        (item) => item?.severity === ALERT_LEVEL.ALERT_LOW
      );

      if (sortedAssetAlarmsListByEpoch) {
        setTooltipNewData(
          criticalAlarms?.[criticalAlarms.length - 1] ||
            highAlarms?.[highAlarms.length - 1] ||
            warningAlarms?.[warningAlarms.length - 1] ||
            lowAlarms?.[lowAlarms.length - 1]
        );
      }
    }
  }, [
    assetAlarmsWidgetData,
    severityFromSensorList,
    sensor_id,
    isFetchingAssetAlarmsWidgetDataSuccess,
    isAlarmsFetchSuccess,
    alarms,
    isNewAssetDashboardPathnamePresent
  ]);

  /**
   * Update severity based on sensor list data.
   */
  useEffect(() => {
    if (
      !severityFromSensorList &&
      isFetchingSnsListWidgetDataSuccess &&
      snsListWidgetData?.widget_type === 'SNS_LIST' &&
      !isNewAssetDashboardPathnamePresent
    ) {
      const sensorDetailsObj = snsListWidgetData?.data?.find(
        (item) => String(item?.id) === String(sensor_id)
      );
      if (sensorDetailsObj) {
        setSeverityFromSensorList(sensorDetailsObj?.alert_level);
      }
    } else {
      const sensorDetails = devices.find((device) => String(device?.id) === String(sensor_id));
      if (sensorDetails) {
        setSeverityFromSensorList(sensorDetails?.alert_level);
      }
    }
  }, [
    devices,
    isFetchingDevicesSuccess,
    isFetchingSnsListWidgetDataSuccess,
    isNewAssetDashboardPathnamePresent,
    sensor_id,
    severityFromSensorList,
    snsListWidgetData
  ]);

  /**
   * Hide marker for deleted devices.
   */
  useEffect(() => {
    const sensorListObj =
      snsListWidgetData?.widget_type === 'SNS_LIST' ? snsListWidgetData : undefined;
    if (severityFromSensorList) {
      const isDevicePresent = Boolean(
        sensorListObj?.data?.find((item) => String(item?.id) === String(sensor_id))
      );
      const sensorDetails = devices.find((senor) => String(senor?.id) === String(sensor_id));
      if (
        (isNewAssetDashboardPathnamePresent && !sensorDetails) ||
        (!isNewAssetDashboardPathnamePresent && !isDevicePresent)
      ) {
        setSeverityFromSensorList(undefined);
      }
    }
  }, [sensor_id, devices, snsListWidgetData]);

  /**
   * Returns the appropriate icon based on the provisioning status.
   */
  const returnProvisioningRelatedIcons = (
    isDeviceNotProvisioned: boolean,
    isProvisioningFailed: boolean,
    isProvisioningInProgress: boolean
  ) => {
    if (isDeviceNotProvisioned) {
      return (
        <Box data-tip data-for={dataFor}>
          <NeverProvisionedIcon className={classes.inActiveSensorsStyle} />
        </Box>
      );
    } else if (isProvisioningFailed) {
      return (
        <Box data-tip data-for={dataFor}>
          <ProvisionFailedIcon className={classes.provFailedStyle} />
        </Box>
      );
    } else if (isProvisioningInProgress) {
      return (
        <Box data-tip data-for={dataFor}>
          <ProvisioningInProcess className={classes.provFailedStyle} />
        </Box>
      );
    }
    return <></>;
  };

  if (!severityFromSensorList) {
    // If sensor_id is not present in the Sensor List widget data,
    // then don't show the marker & its data for this sensor_id.
    return null;
  }

  return (
    <LightTooltip id={`component_${itemNumber}`} title={<TooltipData />} placement="top-start">
      {isDeviceNotProvisioned || isProvisioningFailed || isProvisioningInProgress ? (
        returnProvisioningRelatedIcons(
          isDeviceNotProvisioned,
          isProvisioningFailed,
          isProvisioningInProgress
        )
      ) : (
        <Box
          onClick={() => redirectToAssetAlarms(sensor_id, severityFromSensorList)}
          data-tip
          data-for={dataFor}
          className={`${severity !== 'none' && classes.cursor} ${classes.customMarker} ${
            severity === 'fatal' && classes.fatal
          } ${severity === 'none' && classes.none} ${severity === 'low' && classes.low} ${
            severity === 'warning' && classes.warning
          } ${severity === 'error' && classes.error}`}
        />
      )}
    </LightTooltip>
  );
};

export default CustomMarker;
