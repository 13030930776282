import { APISERVICES, showToast } from '../../../../../../framework';
import {
  BlockUserArgs,
  ChangeNotificationArgs,
  ChangeRoleArgs,
  FetchUsersArgs,
  InviteUserArgs,
  RemoveUserArgs,
  ResendInviteArgs,
  UnblockUserArgs,
  USER_TAG_TYPES,
  ChangeUserGroupArgs,
  FetchUserGroupsArgs
} from './user.types';
import { User, UserGroup } from '../../types';
import { featureApi } from '../../../../../feature.api';
import { invalidateTagsListId } from '../../../../../../framework/lib/rtk/RTK.constants';
import { RootState } from '../../../../../../configureStore';
import { WEBSOCKET_TYPE } from '../../../../../websocket';
import { WEBSOCKET_MODULE_OPTIONS } from '../../../../../common/websocket/websocket.constants';
import { usersMessageHandler } from '../../../../../common/websocket/org/UsersMessageHandler';
import { generateTags } from '../../../../../../framework/lib/rtk/RTK.utils';

export const userApi = featureApi.injectEndpoints({
  endpoints: (build) => ({
    fetchUsers: build.query<User[], FetchUsersArgs>({
      query: (usersArgs: FetchUsersArgs) => ({
        apiEndpoint: `orgs/${usersArgs.slug}/users?page=1&page_size=1000`,
        params: { all_users: true },
        service: APISERVICES.HOST_API,
        method: 'GET',
        actionKey: 'FETCH_USERS_LIST',
        showProgressBar: usersArgs.showProgressBar
      }),
      async onCacheEntryAdded(arg, { cacheDataLoaded, getState, dispatch }) {
        try {
          await cacheDataLoaded;
          const state = getState() as RootState;
          const ws = state.features.websocket.connections?.find(
            (connection) => WEBSOCKET_TYPE.ORG === connection.type
          );
          const listener = (event: MessageEvent) => {
            const data = JSON.parse(event.data);

            if (data.module === WEBSOCKET_MODULE_OPTIONS.USERS.USERS_LIST) {
              usersMessageHandler(dispatch, data, arg);
            }
          };

          if (ws?.connection && ws.connection.onmessage && (ws?.connection as WebSocket)) {
            ws?.connection.addEventListener('message', listener);
          }
        } catch {
          //
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }: { id: string }) => ({
                type: USER_TAG_TYPES.USER,
                id: id
              })),
              { type: USER_TAG_TYPES.USER, id: invalidateTagsListId }
            ]
          : [{ type: USER_TAG_TYPES.USER, id: invalidateTagsListId }],
      transformResponse: (result) => result.results
    }),
    removeUser: build.mutation<void, RemoveUserArgs>({
      query: (removeUserArgs) => ({
        apiEndpoint: `orgs/${removeUserArgs.slug}/users/${removeUserArgs.id}${
          removeUserArgs.removedSubSlugs
            ? `?slugs=${encodeURIComponent(removeUserArgs.removedSubSlugs)}`
            : ''
        }`,
        body: null,
        service: APISERVICES.HOST_URL,
        method: 'DELETE',
        actionKey: 'REMOVE_USER',
        onSuccess: (dispatch) => {
          dispatch(showToast('User Removed Successfully!', 'success'));
        }
      })
    }),
    inviteUser: build.mutation<void, InviteUserArgs>({
      query: (inviteUserArgs) => ({
        apiEndpoint: `orgs/${inviteUserArgs.slug}/users/invite`,
        body: {
          email: inviteUserArgs.email,
          slugs: [...inviteUserArgs.orgSlugs],
          role_id: inviteUserArgs.userRole,
          first_name: inviteUserArgs.firstName,
          last_name: inviteUserArgs.lastName
        },
        service: APISERVICES.HOST_API,
        method: 'POST',
        actionKey: 'INVITE_USER',
        onSuccess: (dispatch) => {
          dispatch(showToast('User Invited Successfully', 'success'));
        }
      })
    }),
    changeUserRole: build.mutation<void, ChangeRoleArgs>({
      query: (changeRoleArgs) => ({
        apiEndpoint: `orgs/${changeRoleArgs.slug}/users/${changeRoleArgs.userId}/change_role`,
        body: {
          role_id: changeRoleArgs.roleId
        },
        service: APISERVICES.HOST_API,
        method: 'POST',
        actionKey: 'ROLES_UPDATE',
        onSuccess: (dispatch) => {
          dispatch(showToast('User role changed successfully!', 'success'));
        }
      })
    }),
    resendUserInvite: build.mutation<void, ResendInviteArgs>({
      query: (resendInviteArgs) => ({
        apiEndpoint: `orgs/${resendInviteArgs.slug}/users/invite/resend`,
        body: resendInviteArgs.reqBody,
        service: APISERVICES.HOST_API,
        method: 'POST',
        actionKey: 'RESEND_INVITE_USER',
        onSuccess: (dispatch) => {
          dispatch(showToast('User Invite sent Successfully!', 'success'));
        }
      })
    }),
    changeUserNotification: build.mutation<void, ChangeNotificationArgs>({
      query: (changeNotificationArgs) => ({
        apiEndpoint: `orgs/${changeNotificationArgs.slug}/users/${changeNotificationArgs.userId}/notification`,
        body: {
          feature: changeNotificationArgs.feature,
          email: changeNotificationArgs.email,
          sms: changeNotificationArgs.sms,
          app_notification: changeNotificationArgs.appNotification
        },
        service: APISERVICES.HOST_API,
        method: 'POST',
        actionKey: 'NOTIFICATION_UPDATE',

        onSuccess: (dispatch) => {
          dispatch(
            showToast(
              `User ${changeNotificationArgs.feature} notifications updated successfully!`,
              'success'
            )
          );
        }
      })
    }),
    blockUser: build.mutation<void, BlockUserArgs>({
      query: (blockUserArgs) => ({
        apiEndpoint: `orgs/${blockUserArgs.slug}/users/${blockUserArgs.userId}/block`,
        body: null,
        service: APISERVICES.HOST_API,
        method: 'POST',
        actionKey: 'BLOCK_USER',
        onSuccess: (dispatch) => {
          dispatch(showToast('User blocked successfully!', 'success'));
        }
      })
    }),
    unblockUser: build.mutation<void, UnblockUserArgs>({
      query: (unblockUserArgs) => ({
        apiEndpoint: `orgs/${unblockUserArgs.slug}/users/${unblockUserArgs.userId}/unblock`,
        body: null,
        service: APISERVICES.HOST_API,
        method: 'POST',
        actionKey: 'UNBLOCK_USER',
        onSuccess: (dispatch) => {
          dispatch(showToast('User unblocked successfully!', 'success'));
        }
      })
    }),
    fetchUsersGroups: build.query<UserGroup[], FetchUserGroupsArgs>({
      query: ({ slug, payload }: FetchUserGroupsArgs) => ({
        apiEndpoint: `orgs/${slug}/user_group`,
        service: APISERVICES.DEVICE_API,
        method: 'GET',
        params: payload,
        actionKey: USER_TAG_TYPES.USER_GROUP
      }),
      providesTags: (result) => generateTags(result, USER_TAG_TYPES.USER_GROUP),
      transformResponse: (result: { results: UserGroup[] | Promise<UserGroup[]> }) => result.results
    }),
    changeUserGroup: build.mutation<void, ChangeUserGroupArgs>({
      query: (payload) => ({
        apiEndpoint: `orgs/${payload.slug}/user_group/${payload.userId}`,
        body: {
          associate: payload.associate,
          dissociate: payload.dissociate
        },
        service: APISERVICES.DEVICE_API,
        method: 'POST',
        actionKey: 'USER_GROUP_UPDATE',
        onSuccess: (dispatch) => {
          dispatch(showToast('User group changed successfully!', 'success'));
        }
      }),
      invalidatesTags: () => [USER_TAG_TYPES.USER_GROUP]
    })
  })
});

export const {
  useFetchUsersQuery,
  useLazyFetchUsersQuery,
  useRemoveUserMutation,
  useInviteUserMutation,
  useChangeUserRoleMutation,
  useResendUserInviteMutation,
  useChangeUserNotificationMutation,
  useBlockUserMutation,
  useUnblockUserMutation,
  useFetchUsersGroupsQuery,
  useLazyFetchUsersGroupsQuery,
  useChangeUserGroupMutation
} = userApi;
