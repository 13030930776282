/**
 * Websocket Reducer
 * @author mahesh.kedari@shorelineiot.com
 */

import { AnyAction } from 'redux';
import { WEBSOCKET_TYPE } from './websocket.const';
import * as ACTIONS from './websocket.actionTypes';

/**
 *
 */
export interface WebsocketObject {
  type: WEBSOCKET_TYPE;
  connection?: WebSocket;
  retryAttempt?: number;
  connectedAt?: Date;
}
/**
 *
 */
export interface WebsocketState {
  connections: Array<WebsocketObject>;
}
const initialState: WebsocketState = {
  connections: []
};
/**
 *
 * @param state
 * @param payload
 * @returns
 */
function setWebSocket(state: WebsocketState, payload: WebsocketObject): WebsocketState {
  // Remove Existing websocket of this type from current list
  const connections = state.connections.filter(
    (websocket: WebsocketObject) => websocket.type !== payload.type
  );
  // Add new entry
  connections.push({ ...payload, retryAttempt: 0, connectedAt: new Date() });
  return { ...state, connections };
}
/**
 *
 * @param state
 * @param payload
 * @returns
 */
function clearWebSocket(state: WebsocketState, payload: WebsocketObject): WebsocketState {
  // Force Close connection
  const connectionToClose: WebsocketObject | undefined = state.connections.find(
    (websocket: WebsocketObject) => websocket.type === payload.type
  );
  if (connectionToClose && connectionToClose.connection) {
    try {
      connectionToClose.connection.onclose = () => {
        // TODO: Below console.info statement is kept for debugging on production.
        // This should be removed later when websocket is stable enough
        // eslint-disable-next-line no-console
        console.info(
          `Websocket Connection for ${payload.type} Closed Successfully at ${new Date()}`
        );
      };
      connectionToClose.connection.close();
    } catch (exception) {
      // eslint-disable-next-line no-console
      console.error(`Failed to close ${connectionToClose.type} websocket connection.`);
      // eslint-disable-next-line no-console
      console.error(exception);
    }
  }
  const connections = state.connections.filter(
    (websocket: WebsocketObject) => websocket.type !== payload.type
  );
  return { ...state, connections };
}
/**
 *
 * @param state
 * @param payload
 * @returns
 */
function updateRetryAttempt(state: WebsocketState, payload: WebsocketObject): WebsocketState {
  const connections: Array<WebsocketObject> = state.connections.map(
    (websocket: WebsocketObject) => {
      if (websocket.type === payload.type) {
        const retryAttempt = (websocket.retryAttempt ? websocket.retryAttempt : 0) + 1;
        return {
          ...websocket,
          retryAttempt
        };
      }
      return websocket;
    }
  );
  return { ...state, connections };
}
/**
 *
 */
export function websocketReducer(
  state: WebsocketState = initialState,
  action: AnyAction
): WebsocketState {
  switch (action.type) {
    case ACTIONS.SET_WEBSOCKET:
      return setWebSocket(state, action.payload);
    case ACTIONS.UPDATE_RETRY_ATTEMPT:
      return updateRetryAttempt(state, action.payload);
    case ACTIONS.CLEAR_WEBSOCKET:
      return clearWebSocket(state, action.payload);
    default:
      return state;
  }
}
