import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  indicator: {
    borderRadius: '50%',
    height: '10px',
    width: '10px',
    display: 'inline-block',
    marginRight: '5px',
    marginLeft: '5px'
  }
}));
