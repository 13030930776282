/**
 * Get default dashbaord
 */
export enum DefaultDashboardStatus {
  INIT,
  FETCHING,
  READY,
  ERROR,
  RESET
}
export interface DefaultDashboardState {
  status: DefaultDashboardStatus;
  defaultDashboardData: any;
}
