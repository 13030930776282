import React from 'react';
import HighchartsReact from 'highcharts-react-official';
import highchartsMore from 'highcharts/highcharts-more.js';
import Highcharts from 'highcharts';
import { Grid, Typography } from '@mui/material';
import {
  DialWidgetPlotBandType,
  DialWidgetViewerProps,
  DISPLAY_RANGE_SELECTION_OPTIONS
} from './dialWidgetConstants';
import { useStyles } from './dialWidget.styles';
import { useLocation } from 'react-router-dom';

highchartsMore(Highcharts);

const DialWidgetViewer: React.FC<DialWidgetViewerProps> = ({ resultSet, chartSettings }) => {
  const { min: minDataValue, max: maxDataValue, recent: recentDataValue, sensor, unit } = resultSet;
  const {
    displayRangeSelection,
    min: chartSettingsMin,
    max: chartSettingsMax,
    colorRanges,
    showAlarm
  } = chartSettings;

  const classes = useStyles();

  const location = useLocation();

  const url = location.pathname;
  const editMode = url.includes('/edit/');

  //only if there are valid manual entries for min and max, they are considered
  //otherwise use the existing min and max values from the data
  const getDisplayRange = () => {
    const autoRange = {
      min: minDataValue !== undefined ? parseFloat(minDataValue.toFixed(4)) : 0,
      max: maxDataValue !== undefined ? parseFloat(maxDataValue.toFixed(4)) : 1000
    };
    if (displayRangeSelection === DISPLAY_RANGE_SELECTION_OPTIONS.MANUAL) {
      if (
        chartSettingsMin !== undefined &&
        chartSettingsMax !== undefined &&
        chartSettingsMin < chartSettingsMax
      ) {
        return {
          min: parseFloat(chartSettingsMin.toFixed(4)),
          max: parseFloat(chartSettingsMax.toFixed(4))
        };
      } else if (chartSettingsMin === undefined && chartSettingsMax !== undefined) {
        //manual min undefined but max is present. compare max with dataMin
        if (minDataValue !== undefined && minDataValue < chartSettingsMax) {
          return {
            min: parseFloat(minDataValue.toFixed(4)),
            max: parseFloat(chartSettingsMax.toFixed(4))
          };
        } else {
          return autoRange;
        }
      } else if (chartSettingsMax === undefined && chartSettingsMin !== undefined) {
        //manual max undefined but in is present. Compare min with dataMax
        if (maxDataValue !== undefined && maxDataValue > chartSettingsMin) {
          return {
            min: parseFloat(chartSettingsMin.toFixed(4)),
            max: parseFloat(maxDataValue.toFixed(4))
          };
        } else {
          return autoRange;
        }
      } else {
        return autoRange;
      }
    } else {
      return autoRange;
    }
  };

  const displayRange = getDisplayRange();

  const GREY = '#D3D3D3';

  //returns true if the color range limits are valid
  const checkLimitsValidity = (from: string, to: string) => {
    const { min, max } = displayRange;
    //explicitly writing undefined because the values might be 0 and hey need to be checked
    if (min !== undefined && max !== undefined) {
      if (Number(from) < min || Number(from) > max) {
        return false;
      }
      if (Number(to) < min || Number(to) > max) {
        return false;
      }
      if (Number(from) >= Number(to)) {
        return false;
      }
    }
    return true;
  };

  let isInvalidColorRange = false;

  const createPlotBands = () => {
    const plotBands: DialWidgetPlotBandType[] = [];
    const colorRangeIds = Object.keys(colorRanges);
    if (colorRangeIds.length === 0) {
      plotBands.push({
        from: displayRange.min ? displayRange.min : 0,
        to: displayRange.max ? displayRange.max : 1,
        color: GREY
      });
    } else {
      let maxEndVal = 0;
      colorRangeIds.forEach((rangeId) => {
        const colorRange = colorRanges[parseInt(rangeId)];
        if (Number(colorRange.from) === -1 || Number(colorRange.to) === -1) {
          plotBands.push({
            from: maxEndVal,
            to: displayRange.max ? displayRange.max : 1,
            color: GREY
          });
        } else {
          const isValid = checkLimitsValidity(colorRange.from, colorRange.to);
          if (isValid) {
            maxEndVal = Number(colorRange.to);
            plotBands.push({
              from: Number(colorRange.from),
              to: Number(colorRange.to),
              color: `${colorRange.color}`
            });
          } else {
            isInvalidColorRange = true;
          }
        }
      });
    }
    return plotBands;
  };

  //if the range is manully set and the recent value is outside of the range, then restrict the value to the bounds set by the user.
  const getRecentDataValue = () => {
    if (recentDataValue && displayRange.min && displayRange.max) {
      if (recentDataValue < displayRange.min) {
        return displayRange.min;
      } else if (recentDataValue > displayRange.max) {
        return displayRange.max;
      } else {
        return recentDataValue;
      }
    }
    return recentDataValue;
  };
  const dialDisplayValue =
    displayRangeSelection === DISPLAY_RANGE_SELECTION_OPTIONS.MANUAL
      ? getRecentDataValue()
      : recentDataValue;

  const showMinAlarm = () => {
    if (
      displayRangeSelection === DISPLAY_RANGE_SELECTION_OPTIONS.MANUAL &&
      showAlarm &&
      minDataValue !== undefined &&
      displayRange.min !== undefined &&
      parseFloat(minDataValue.toFixed(4)) < displayRange?.min
    ) {
      return true;
    }
    return false;
  };

  const showMaxAlarm = () => {
    if (
      displayRangeSelection === DISPLAY_RANGE_SELECTION_OPTIONS.MANUAL &&
      showAlarm &&
      maxDataValue !== undefined &&
      displayRange.max !== undefined &&
      parseFloat(maxDataValue.toFixed(4)) > displayRange?.max
    ) {
      return true;
    }
    return false;
  };

  const getColorRangeOutOfLimitsMessage = () => {
    const { min, max } = displayRange;
    if (min === max) {
      return 'Only one value. Cannot add color range.';
    } else {
      return `Colors only visible from ${min} - ${max}`;
    }
  };

  const gaugeOptions = {
    chart: {
      type: 'gauge',
      plotBackgroundColor: undefined,
      plotBackgroundImage: undefined,
      plotBorderWidth: 0,
      plotShadow: false,
      height: 300
    },
    title: {
      text: ''
    },
    credits: {
      enabled: false
    },
    pane: {
      startAngle: -150,
      endAngle: 150,
      size: '50%',
      background: [
        {
          backgroundColor: {
            linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
            stops: [
              [0, '#848484'],
              [1, '#E5E5E5']
            ]
          },
          borderWidth: 0,
          outerRadius: '170%',
          innerRadius: '165%'
        },
        {
          backgroundColor: {
            linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
            stops: [
              [0, '#FFF'],
              [1, '#FFF']
            ]
          },
          borderWidth: 0
        },
        {
          backgroundColor: {
            linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
            stops: [
              [0, '#E0E0DF'],
              [0.7, '#E0E0DF'],
              [0.8, '#E0E0DF'],
              [0.85, '#E0E0DF'],
              [0.89, '#E0E0DF'],
              [0.9, '#E0E0DF'],
              [0.91, '#E0E0DF'],
              [0.92, '#FFFF'],
              [0.93, '#FFF'],
              [1, '#FFF']
            ]
          },
          borderWidth: 0,
          innerRadius: '98%'
        }
      ]
    },

    yAxis: {
      min: displayRange.min,
      max: displayRange.max,

      minorTickInterval: 'auto',
      minorTickWidth: 1,
      minorTickLength: 10,
      minorTickPosition: 'inside',
      minorTickColor: '#666',
      minorTicks: false,

      tickPixelInterval: 20,
      tickWidth: 4,
      tickPosition: 'outside',
      tickLength: 10,
      tickColor: '#666',
      labels: {
        step: 1,
        distance: 27
      },
      title: {
        text: unit
      },
      plotBands: createPlotBands()
    },
    plotOptions: {
      gauge: {
        clip: true,
        dial: {
          radius: '102%',
          baseWidth: 12,
          borderWidth: 0,
          topWidth: 0,
          rearLength: '0%',
          baseLength: '5%',
          backgroundColor: '#EE5927'
        },
        pivot: {
          radius: 20,
          backgroundColor: {
            linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
            stops: [
              [0, '#675F5F'],
              [0.5, '#C6C5C5'],
              [1, '#675F5F']
            ]
          }
        }
      }
    },
    tooltip: {
      shared: true,
      useHTML: true,
      valueSuffix: ` ${unit}`,
      valueDecimals: 4,
      hideDelay: 0,
      style: {
        color: 'white'
      },
      backgroundColor: '#4d4d4d'
    },
    series: [
      {
        type: 'gauge',
        name: sensor.sensorLabel,
        data: [dialDisplayValue ? parseFloat(dialDisplayValue?.toFixed(4)) : 0],
        dataLabels: {
          formatter: function () {
            return parseFloat(Highcharts.numberFormat(recentDataValue ? recentDataValue : 0, 4));
          },
          color: 'black',
          y: 50,
          style: {
            fontSize: '0.8rem'
          }
        }
      }
    ]
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={gaugeOptions} allowChartUpdate="true" />
      <Grid container direction={'row'} width={editMode ? '70%' : '90%'} sx={{ margin: 'auto' }}>
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
          Min: {minDataValue ? parseFloat(minDataValue.toFixed(4)) : 0}{' '}
          {showMinAlarm() && <span className={classes.alarm} />}
        </Grid>

        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{ textAlign: 'right' }}>
          Max: {maxDataValue ? parseFloat(maxDataValue?.toFixed(4)) : 1000}{' '}
          {showMaxAlarm() && <span className={classes.alarm} />}
        </Grid>
      </Grid>
      {displayRangeSelection === DISPLAY_RANGE_SELECTION_OPTIONS.AUTO && isInvalidColorRange && (
        <Typography color={'error'} width={editMode ? '70%' : '90%'} sx={{ margin: 'auto' }}>
          {getColorRangeOutOfLimitsMessage()}
        </Typography>
      )}
    </div>
  );
};

export default DialWidgetViewer;
