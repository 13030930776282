/**
 * Datapoint Reducer
 * @author mahesh.kedari@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTIONS from './actions/datapoints.actionTypes';
import { DatapointsListState, DATAPOINTS_LIST_STATUS } from './types/datapointsList.state';
import {
  onFetchDatapoints,
  onfetchDatapointsSuccess,
  onFetchDatapointsFailure,
  onResetDatapoints,
  setFetchDatapointsAgain
} from './helper/fetchDatapoints';
import {
  onFetchViewConfig,
  onFetchViewConfigSuccess,
  onFetchViewConfigFailure,
  onResetViewConfig,
  onSetViewConfig,
  onSetViewConfigFailure,
  onSetViewConfigSuccess
} from './helper/fetchViewConfig';
import {
  onFetchTopic,
  onFetchTopicSuccess,
  onFetchTopicFailure,
  onTopicClose
} from './helper/fetchLiveDataConfig';
import { onSetLastDataValue, onSetDataValue } from './helper/setLastDataValue';
import {
  onRemoveDatapoint,
  onRemoveDatapointFailure,
  onRemoveDatapointSuccess
} from './helper/removeDatapoint';
import {
  onHideDatapoint,
  onHideDatapointFailure,
  onHideDatapointSuccess
} from './helper/hideDatapoint';
import {
  onUnhideDatapoint,
  onUnhideDatapointFailure,
  onUnhideDatapointSuccess
} from './helper/unhideDatapoint';

const initialState: DatapointsListState = {
  status: DATAPOINTS_LIST_STATUS.INIT,
  datapointConfigs: [],
  viewConfigs: {},
  liveDataConfigs: {},
  lastDataValue: {},
  fetchDatapointsAgain: false
};
/**
 *
 * @param state
 * @param action
 */
function datapointReducer(
  state: DatapointsListState = initialState,
  action: AnyAction
): DatapointsListState {
  switch (action.type) {
    case ACTIONS.FETCH_DATAPOINTS:
      return onFetchDatapoints(state);
    case ACTIONS.DATAPOINTS_SUCCESS:
      return onfetchDatapointsSuccess(state, action.payload);
    case ACTIONS.DATAPOINTS_FAILURE:
      return onFetchDatapointsFailure(state);
    case ACTIONS.RESET_DATAPOINTS:
      return onResetDatapoints(state);
    case ACTIONS.FETCH_DATAPOINTS_AGAIN:
      return setFetchDatapointsAgain(state, action.payload);

    case ACTIONS.FETCH_VIEW_CONFIG:
      return onFetchViewConfig(state, action.payload);
    case ACTIONS.VIEW_CONFIG_SUCCESS:
      return onFetchViewConfigSuccess(state, action.payload);
    case ACTIONS.VIEW_CONFIG_FAILURE:
      return onFetchViewConfigFailure(state, action.payload);
    case ACTIONS.RESET_VIEW_CONFIG:
      return onResetViewConfig(state, action.payload);
    case ACTIONS.SET_VIEW_CONFIG:
      return onSetViewConfig(state, action.payload);
    case ACTIONS.SET_VIEW_CONFIG_SUCCESS:
      return onSetViewConfigSuccess(state, action.payload);
    case ACTIONS.SET_VIEW_CONFIG_FAILURE:
      return onSetViewConfigFailure(state, action.payload);

    case ACTIONS.FETCH_TOPIC:
      return onFetchTopic(state, action.payload);
    case ACTIONS.TOPIC_SUCCESS:
      return onFetchTopicSuccess(state, action.payload);
    case ACTIONS.TOPIC_FAILURE:
      return onFetchTopicFailure(state, action.payload);
    case ACTIONS.TOPIC_CLOSE:
      return onTopicClose(state, action.payload);

    case ACTIONS.SET_LAST_DATA_VALUE:
      return onSetLastDataValue(state, action.payload);
    case ACTIONS.SET_DATA:
      return onSetDataValue(state, action.payload);

    case ACTIONS.REMOVE_DATAPOINT:
      return onRemoveDatapoint(state);
    case ACTIONS.REMOVE_DATAPOINT_SUCCESS:
      return onRemoveDatapointSuccess(state);
    case ACTIONS.REMOVE_DATAPOINT_FAILURE:
      return onRemoveDatapointFailure(state);

    case ACTIONS.HIDE_DATAPOINT:
      return onHideDatapoint(state);
    case ACTIONS.HIDE_DATAPOINT_SUCCESS:
      return onHideDatapointSuccess(state, action.payload);
    case ACTIONS.HIDE_DATAPOINT_FAILURE:
      return onHideDatapointFailure(state);

    case ACTIONS.UNHIDE_DATAPOINT:
      return onUnhideDatapoint(state);
    case ACTIONS.UNHIDE_DATAPOINT_SUCCESS:
      return onUnhideDatapointSuccess(state, action.payload);
    case ACTIONS.UNHIDE_DATAPOINT_FAILURE:
      return onUnhideDatapointFailure(state);

    default:
      return state;
  }
}
/**
 *
 */
export default datapointReducer;
