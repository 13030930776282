import { AnyAction } from 'redux';
import * as ACTION_TYPE from '../actions/alerts.actionTypes';
import { GetSensorAlertState } from '../types/GetSensorAlertState';
import { GET_SENSOR_ALERT_STATUS } from '../types/GetSensorAlertStatus';

const initialState: GetSensorAlertState = {
  status: GET_SENSOR_ALERT_STATUS.INIT,
  alert: {}
};

function getSensorAlertProgress(state: GetSensorAlertState): GetSensorAlertState {
  return {
    ...state,
    status: GET_SENSOR_ALERT_STATUS.FETCHING
  };
}

function getSensorAlertSuccess(state: GetSensorAlertState, payload: any): GetSensorAlertState {
  return {
    ...state,
    status: GET_SENSOR_ALERT_STATUS.SUCCESS,
    alert: payload
  };
}

function getSensorAlertFailure(state: GetSensorAlertState): GetSensorAlertState {
  return {
    ...state,
    status: GET_SENSOR_ALERT_STATUS.ERROR
  };
}
export default function getSensorAlertReducer(
  state: GetSensorAlertState = initialState,
  action: AnyAction
): GetSensorAlertState {
  switch (action.type) {
    case ACTION_TYPE.FETCH_SENSOR_ALERT_PROGRESS:
      return getSensorAlertProgress(state);
    case ACTION_TYPE.FETCH_SENSOR_ALERT_SUCCESSS:
      return getSensorAlertSuccess(state, action.payload);
    case ACTION_TYPE.FETCH_SENSOR_ALERT_FAILURE:
      return getSensorAlertFailure(state);
    default:
      return state;
  }
}
