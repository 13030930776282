/**
 * Configure Amplify actions
 * @author mahesh.kedari@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTIONS from './configure.actionTypes';

/**
 *
 * @param info
 */
export const requestConfigs = (slug: string): AnyAction => {
  // returns the action type to login_reducer
  return {
    type: ACTIONS.AMPLIFY_CONFIG_REQUEST,
    payload: slug
  };
};
/**
 *
 * @param configs
 */
export const requestConfigSuccess = (configs: any): AnyAction => {
  return {
    type: ACTIONS.AMPLIFY_REQUEST_SUCCESS,
    payload: configs
  };
};
/**
 *
 * @param error
 */
export const requestConfigFailure = (error: any): AnyAction => {
  return {
    type: ACTIONS.AMPLIFY_REQUEST_FAILURE,
    payload: error
  };
};

/**
 *
 * @param info
 */
export const configureAmplify = (configurations: any): AnyAction => {
  // returns the action type to login_reducer
  return {
    type: ACTIONS.AMPLIFY_CONFIG_INIT,
    payload: configurations
  };
};
/**
 *
 * @param configs
 */
export const configureAmplifySuccess = (): AnyAction => {
  return {
    type: ACTIONS.AMPLIFY_CONFIG_SUCCESS
  };
};
/**
 *
 * @param error
 */
export const configureAmplifyFailure = (error: any): AnyAction => {
  return {
    type: ACTIONS.AMPLIFY_CONFIG_FAILURE,
    payload: error
  };
};
