import { put, takeEvery } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import * as ACTIONS from './actions/forgotPassword.actions';
import * as ACTION_TYPE from './actions/forgotPassword.actionTypes';
import { showToast, showProgressFor, removeProgressFor, GenericObject } from '../../../framework';
import { AuthService } from '../../../framework/services/auth.service';

const FORGOT_PASSWORD = 'FORGOT_PASSWORD_PROGRESS';

export function* setNewPassword(action: AnyAction) {
  yield put(showProgressFor(FORGOT_PASSWORD));
  try {
    const authService = new AuthService();
    const response: GenericObject = yield authService.forgotPasswordSubmit(action.payload);
    yield put(showToast('New Password set succesfully', 'success'));
    yield put(ACTIONS.forgotPasswordSubmitSuccess(response));
  } catch (error: any) {
    yield put(showToast('New Password could not set', 'error', true));
    yield put(ACTIONS.forgotPasswordSubmitFailure(error.message));
  } finally {
    yield put(removeProgressFor(FORGOT_PASSWORD));
  }
}

export function* forgotPasswordSaga() {
  yield takeEvery(ACTION_TYPE.FORGOT_PASSWORD_SUBMIT_PROGRESS, setNewPassword);
}
