/**
 * Custom Header Component
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { ReactElement /* useEffect, ReactNode */ } from 'react';
// import { AuthService, useSlugContext } from '../../../framework';
import NotificationIndicator from '../../../framework/components/header/notifications/NotificationIndicator';
import { useLastNotification } from '../SelectorHooks/notification.selectorHooks';
// import * as ACTIONS from '../actions/live-notifications.actions';
// import DownloadEventsMessage from '../../device/device-details/components/device-data/exportDeviceData/DownloadEventsMessage';

export default function CustomNotification(): ReactElement {
  /* const authService = new AuthService();
  const { slug } = useSlugContext();
  const dispatch = useDispatch(); */

  const notificationsState: any = useLastNotification();
  /* let task: any;

  const handleNotification = (data: any) => {
    if (data.value.download_link) {
      const temp: Array<{ label: ReactNode; value: any }> = [];
      const obj = {
        label: (
          <DownloadEventsMessage
            downloadLink={data.value.download_link}
            timestamp={data.value.ts}
          />
        ),
        value: 'exports-notification',
      };
      temp.push(obj);
      dispatch(ACTIONS.setLastNotification(temp));
      // Unsubscribe to the topic after 1st success
      if (task) {
        task.unsubscribe();
      }
    } else {
      dispatch(ACTIONS.setLastNotification(data.value));
    }
  };

  const subscribeToNotificationTopic = async () => {
    if (!sessionStorage.getItem('ConnectionKey')) {
      await authService.connectToMQTTBroker();
    }
    task = authService
      .getDataFromTopic([
        `${
          import.meta.env.VITE_TOPIC_PREFIX
        }/frontend/${slug}/${authService.uuidv4()}/notifications`,
      ])
      .subscribe({
        next: handleNotification,
        // error: handleError,
        close: () => {
          if (task) {
            task.unsubscribe();
            sessionStorage.removeItem('ConnectionKey');
          }
        },
      });
    return task;
  };

  useEffect(() => {
    task = subscribeToNotificationTopic().then((subscriptionTask: any) => {
      task = subscriptionTask;
    });
    return () => {
      if (sessionStorage.getItem('ConnectionKey')) {
        sessionStorage.removeItem('ConnectionKey');
      }
      // if (task) {
      //   task.unsubscribe();
      // }
    };
  }, []); */

  return (
    <NotificationIndicator
      notificationList={notificationsState?.response}
      notificationCount={notificationsState?.response.length}
    />
  );
}
