/**
 * Auth Layout Style
 * @author mahesh.kedari@shorelineiot.com
 */
import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  authContainer: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'calc(100vh)',
    background: theme.palette.background.paper
  }
}));
