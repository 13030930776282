/**
 * Anomaly Templates List Actions
 * @author aditya.bhadange@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTIONS from './anomalyTemplatesList.actionTypes';

/**
 *
 * @returns All Anomaly Templates List.
 */
export const fetchAnomalyTemplatesList = (): AnyAction => ({
  type: ACTIONS.FETCH_ANOMALY_TEMPLATES_LIST
});
/**
 *
 * @param payload
 * @returns
 */
export const fetchAnomalyTemplatesListSuccess = (payload: any): AnyAction => ({
  type: ACTIONS.FETCH_ANOMALY_TEMPLATES_LIST_SUCCESS,
  payload
});

/**
 *
 * @param error
 * @returns
 */
export const fetchAnomalyTemplatesListFailure = (error: any) => ({
  type: ACTIONS.FETCH_ANOMALY_TEMPLATES_LIST_FAILURE,
  error
});

/**
 *
 * @returns Resets the Anomaly Templates List to an empty array.
 */
export const resetAnomalyTemplatesList = (): AnyAction => ({
  type: ACTIONS.RESET_ANOMALY_TEMPLATES_LIST
});
