import { invalidateTagsListId } from './RTK.constants';
import { EndpointConfig } from './axiosBaseQuery';

type ResultType<T> = T extends Record<string, any>[] ? T[number] : T;
type IdType<T> = T extends { id: infer U } ? U : never;
/**
 * Generates tags based on the input result, type, and idAttribute.
 *
 * @param {T | undefined} result - The input result
 * @param {string} type - The type of the tags
 * @param {K} idAttribute - The id attribute to use (default is 'id')
 * @return {{ type: string; id: IdType<ResultType<T>> }[]} The generated tags
 */
export const generateTags = <T, K extends keyof ResultType<T>>(
  result: T | undefined,
  type: string,
  idAttribute: K = 'id' as K
): { type: string; id: IdType<ResultType<T>> }[] => {
  // Initialize an empty array to store the tags
  const tags: { type: string; id: IdType<ResultType<T>> }[] = [];

  // If the result is an array, iterate through each item and push a tag with the specified type and the idAttribute value
  if (Array.isArray(result)) {
    result.forEach((item) => {
      tags.push({ type, id: item[idAttribute] });
    });
  }
  // If the result is not an array and is not undefined, iterate through each key in the result and push a tag with the specified type and the key as the id
  else if (result !== undefined && typeof result === 'object') {
    for (const key in result) {
      tags.push({ type, id: key as IdType<ResultType<T>> });
    }
  }
  // Push a special tag with the specified type and invalidateTagsListId as the id
  tags.push({ type, id: invalidateTagsListId as IdType<ResultType<T>> });
  return tags; // Return the generated tags
};

/**
 *
 * @param options - The options object
 * @returns The options object
 */
export function buildQuery(options: EndpointConfig) {
  return {
    ...options
  };
}
