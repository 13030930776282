/**
 * SL Card Component
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { ReactElement, PropsWithChildren, MouseEvent } from 'react';
import { Card, CardHeader, CardContent } from '@mui/material';
import { useStyles } from './card.styles';
/**
 *
 */
interface Props {
  title: string;
  action: ReactElement;
  onClick?: (event: MouseEvent<any>) => void;
}
/**
 *
 * @param param0
 */
export default function SLCard({
  title,
  action,
  children,
  ...otherProps
}: PropsWithChildren<Props>): ReactElement {
  const classes = useStyles();
  const header =
    title || action ? (
      <CardHeader
        title={title}
        action={action}
        disableRipple
        className={classes.header}
        {...otherProps}
      />
    ) : null;
  return (
    <Card className={classes.card}>
      {header}
      <CardContent>{children}</CardContent>
    </Card>
  );
}
