/**
 * Delete Button With Tooltip
 * @author mahesh.kedari@shorelineiot.com
 */

import React from 'react';
import { LightTooltip } from '../tooltip/Tooltip';
import { SLButton } from '.';
import { toSnakeCase } from '../../../i18n/translation.helper';
/**
 *
 */
interface AddButtonProps {
  tooltip: string;
  onAdd: React.MouseEventHandler<HTMLButtonElement>;
}
/**
 *
 */
const AddButtonWithTooltip = React.memo(
  ({ tooltip = 'Add', onAdd }: AddButtonProps) => {
    return (
      <LightTooltip title={tooltip} placement="bottom" translationId={toSnakeCase(tooltip)}>
        <SLButton
          color="primary"
          variant="outlined"
          onClick={onAdd}
          size="small"
          translationId="add">
          Add
        </SLButton>
      </LightTooltip>
    );
  },
  (prevProp: AddButtonProps, nextProps: AddButtonProps) => prevProp.tooltip === nextProps.tooltip
);
export default AddButtonWithTooltip;
