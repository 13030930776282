import { Box } from '@mui/material';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import WidgetDataUnavailable from '../widget-data-unavailable/WidgetDataUnavailable';
import SLTable from '../../table/SLTable';
import { Colors } from '../../../constants';
import { SLTableColumn } from '../../table/slTable.types';

interface TableProps {
  tableData: any;
  tableColumns: Array<SLTableColumn<any>>;
  customOptions: any;
  onRowClickFunc?: (rowData?: any) => void;
}

const SLSensorAlarmsListWidget = React.forwardRef(
  ({ tableData, tableColumns, customOptions, onRowClickFunc }: TableProps, ref: any) => {
    const stageCanvasRef: any = useRef(null);
    const [tableHeight, setTableHeight] = useState(
      stageCanvasRef?.current?.parentElement?.parentElement?.parentElement?.clientHeight
    );
    const DIFFERENCE_IN_PX = 40;

    useEffect(() => {
      setTableHeight(
        stageCanvasRef?.current?.parentElement?.parentElement?.parentElement?.clientHeight
      );
    }, [stageCanvasRef?.current?.parentElement?.parentElement?.parentElement?.clientHeight]);

    useImperativeHandle(
      ref,
      () => ({
        resize(width: number, height: number) {
          setTableHeight(height);
        }
      }),
      []
    );

    if (!tableData || (tableData && tableData[0]?.message && tableData[0]?.message === 'No data')) {
      return <WidgetDataUnavailable />;
    }

    return (
      <Box ref={stageCanvasRef} textAlign="center">
        <SLTable
          columns={tableColumns}
          data={tableData || []}
          enableSorting
          maxBodyHeight={tableHeight && tableHeight - DIFFERENCE_IN_PX}
          paperColor={Colors.transparent}
          onRowClick={onRowClickFunc}
          feature="sensor-alarms"
        />
      </Box>
    );
  }
);

export default SLSensorAlarmsListWidget;
