/**
 * update widget settings ACTIONs
 * @author ashwini.gawade@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTION_TYPES from './updateWidgetSetting.actionTypes';
/**
 *
 * @param data
 */
export const updateWidgetSettings = (data: any): AnyAction => {
  // returns the action type
  return {
    type: ACTION_TYPES.UPDATE_WIDGET_SETTINGS,
    payload: data
  };
};
/**
 *
 * @param data
 */
export const updateWidgetSettingSuccess = (data: any): AnyAction => {
  return {
    type: ACTION_TYPES.UPDATE_WIDGET_SETTINGS_SUCCESS,
    payload: data
  };
};
/**
 *
 */
export const updateWidgetSettingFailure = (error: any): AnyAction => {
  // returns the action type
  return {
    type: ACTION_TYPES.UPDATE_WIDGET_SETTINGS_FAILURE,
    error
  };
};

export const resetWidgetSettingState = (): AnyAction => {
  // returns the action type
  return {
    type: ACTION_TYPES.RESET_WIDGET_SETTINGS_STATE
  };
};
