import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AlarmsFiltersState,
  AlertLevel,
  AlarmStatusType,
  ModifiedBy,
  Occurred,
  Path,
  Site
} from './alarms.types';
import { ExportAlarmsFromWebsocketResponse } from './alarms-export';
import { FiltersFromURL } from './alarms-list';

export const emptyFiltersInitialState = {
  severity: [],
  state: [],
  modifiedBy: [],
  site: [],
  path: [],
  occurred: '',
  name: '',
  id: ''
};

const initialState: AlarmsFiltersState = {
  selections: emptyFiltersInitialState,
  setFilters: emptyFiltersInitialState,
  export: {
    shouldDownloadFile: false
  }
};

export const alarmsFiltersSlice = createSlice({
  name: 'alarmsFilters',
  initialState,
  reducers: {
    handleSeverityFilterChange(state: AlarmsFiltersState, action: PayloadAction<AlertLevel[]>) {
      state.selections.severity = action.payload;
    },
    handleStateFilterChange(state: AlarmsFiltersState, action: PayloadAction<AlarmStatusType[]>) {
      state.selections.state = action.payload;
    },
    handleModifiedByFilterChange(state: AlarmsFiltersState, action: PayloadAction<ModifiedBy[]>) {
      state.selections.modifiedBy = action.payload;
    },
    handleSiteFilterChange(state: AlarmsFiltersState, action: PayloadAction<Site[]>) {
      state.selections.site = action.payload;
    },
    handlePathFilterChange(state: AlarmsFiltersState, action: PayloadAction<Path[]>) {
      state.selections.path = action.payload;
    },
    handleOccurredFilterChange(state: AlarmsFiltersState, action: PayloadAction<Occurred>) {
      state.selections.occurred = action.payload;
    },
    setFilterChanges(state: AlarmsFiltersState, action: PayloadAction<FiltersFromURL>) {
      state.setFilters = action.payload;
    },
    setFilterSelections(state: AlarmsFiltersState, action: PayloadAction<FiltersFromURL>) {
      state.selections = action.payload;
    },
    applyFilters(state: AlarmsFiltersState) {
      state.setFilters = state.selections;
    },
    setShouldDownloadFile(state: AlarmsFiltersState, action: PayloadAction<boolean>) {
      state.export.shouldDownloadFile = action.payload;
    },
    onPerformAlarmsFileDownload(
      state: AlarmsFiltersState,
      webSocketData: PayloadAction<ExportAlarmsFromWebsocketResponse>
    ) {
      if (state.export.shouldDownloadFile && webSocketData.payload.action === 'create') {
        const link = document.createElement('a');
        link.href = webSocketData.payload.payload.download_link;
        link.click();
        link.remove();

        state.export.shouldDownloadFile = false;
      }
    }
  }
});

export const {
  handleSeverityFilterChange,
  handleStateFilterChange,
  handleModifiedByFilterChange,
  handleSiteFilterChange,
  handlePathFilterChange,
  handleOccurredFilterChange,
  setFilterChanges,
  setFilterSelections,
  applyFilters,
  setShouldDownloadFile,
  onPerformAlarmsFileDownload
} = alarmsFiltersSlice.actions;

export default alarmsFiltersSlice.reducer;
