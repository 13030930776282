import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  '@keyframes flash': {
    '0%': { opacity: 1 },
    '50%': { opacity: 0 },
    '100%': { opacity: 1 }
  },
  flashing: {
    animation: '$flash 1s infinite'
  },
  newIcon: {
    marginTop: '-10px',
    marginRight: '10px',
    cursor: 'pointer'
  }
}));
