/**
 * dashbaord items
 */
export enum UpdateDashboardStatus {
  INIT,
  FETCHING,
  READY,
  ERROR,
  RESET
}
export interface UpdateDashboardState {
  status: UpdateDashboardStatus;
  updateDashboardData: any;
}
