/**
 * Line Chart Editor
 * @author mahesh.kedari@shorelineiot.com
 */
import { Grid, Box, Button } from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import isNumber from 'lodash/isNumber';
import toNumber from 'lodash/toNumber';
import { SLTextField } from '../../form';
import { ChartEditorProps } from '../common/ChartEditorProps';
import { useStyles } from '../chart.styles';
import WidgetSettingAccordion from '../common/WidgetAccordion';
import TranslateText from '../../../../i18n/TranslateText';
// import SLColorPicker from '../../color-picker/SLColorPicker';
/**
 *
 * @returns
 */
const SLSolidGaugeChartEditor: React.FC<ChartEditorProps> = ({
  handleSubmitForm
}: ChartEditorProps) => {
  const classes = useStyles();
  /* const [averageColor, setAverageColor] = useState<any>({ hex: '#40739e' });
  const handleColorChange = (color: any) => {
    setAverageColor({ hex: color.hex });
  }; */

  const formDefaults = {
    min: '',
    max: '',
    decimalPointResolution: 0,
    unit: ''
  };

  const { setValue } = useFormContext();

  const handleUpdate = (data: any) => {
    const { chartType, min, max, decimalPointResolution, unit } = data;
    handleSubmitForm({
      chartType,
      chartSettings: {
        min: isNumber(min) ? min : toNumber(min),
        max: isNumber(max) ? max : toNumber(max),
        decimalPointResolution: toNumber(decimalPointResolution),
        unit
      }
    });
  };

  const resetToDefault = () => {
    setValue('min', formDefaults.min);
    setValue('max', formDefaults.max);
    setValue('decimalPointResolution', formDefaults.decimalPointResolution);
    setValue('unit', formDefaults.unit);
    handleSubmitForm({
      chartType: 'solidgauge',
      chartSettings: {
        min: undefined,
        max: undefined,
        decimalPointResolution: 0,
        unit: undefined
      }
    });
  };

  return (
    <>
      <WidgetSettingAccordion title="common">
        <Grid container justifyContent="flex-start" spacing={2} alignItems="flex-end">
          <Grid item xs={6}>
            <SLTextField
              type="number"
              name="min"
              label="Scale Min"
              labelTranslationId="scale_min"
              rules={{ required: true, type: 'number' }}
              onBlur={handleUpdate}
            />
          </Grid>
          <Grid item xs={6}>
            <SLTextField
              name="max"
              label="Scale Max"
              labelTranslationId="scale_max"
              onBlur={handleUpdate}
            />
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-start" spacing={2} alignItems="flex-end">
          <Grid item xs={6}>
            <SLTextField
              type="number"
              name="decimalPointResolution"
              label="Decimal Point Resolution"
              labelTranslationId="decimal_point_resolution"
              rules={{ type: 'number' }}
              onBlur={handleUpdate}
            />
          </Grid>
          <Grid item xs={6}>
            <SLTextField name="unit" label="Unit" labelTranslationId="unit" onBlur={handleUpdate} />
          </Grid>
        </Grid>
      </WidgetSettingAccordion>
      <Box my={5}>
        <Button
          variant="contained"
          size="small"
          classes={{
            root: classes.root
          }}
          onClick={resetToDefault}>
          {TranslateText('reset_default', 'Reset Default')}
        </Button>
      </Box>
    </>
  );
};

export default SLSolidGaugeChartEditor;
