import { DEVICE_TAG } from '../../../../../features/device/store/device.types';
import { featureApi } from '../../../../../features/feature.api';
import { APISERVICES } from '../../../../services';
import { DialWidgetDataArgs, DialWidgetResponse } from './dialWidgetApiTypes';

export const dialWidgetApi = featureApi.injectEndpoints({
  endpoints: (build) => ({
    fetchDialData: build.query<DialWidgetResponse, DialWidgetDataArgs>({
      query: ({ slug, deviceId, dpid, start, end, recordCount }) => {
        return {
          apiEndpoint: `orgs/${slug}/devices/${deviceId}/datapoints/historical_data?maxPoints=${
            recordCount ? recordCount : 5000
          }&dpids=${dpid}${start ? `&start=${start}` : ''}${end ? `&end=${end}` : ''}&type=dial`,
          params: null,
          service: APISERVICES.DEVICE_API,
          method: 'GET',
          actionKey: 'FETCH_DIAL_WIDGET_DATA',
          showProgressBar: true
        };
      },
      providesTags: [DEVICE_TAG.DIAL_WIDGET_DATA]
    })
  })
});

export const { useLazyFetchDialDataQuery } = dialWidgetApi;
