import { AnyAction } from 'redux';
import * as ACTIONS from './userSelf.actionTypes';

export const updateUser = (user: any): AnyAction => {
  return {
    type: ACTIONS.UPDATE_USER,
    payload: user
  };
};
/**
 *
 * @param data
 */
export const updateUserSuccess = (data: any): AnyAction => {
  // returns the action type
  return {
    type: ACTIONS.UPDATE_USER_SUCCESS,
    data
  };
};
/**
 *
 * @param error
 */
export const updateUserFailure = (error: any): AnyAction => {
  // returns the action type
  return {
    type: ACTIONS.UPDATE_USER_FAILURE,
    error
  };
};
/**
 *
 * @param data
 */

export const updateUsersReset = (): AnyAction => {
  return {
    type: ACTIONS.RESET_USER_DETAILS
  };
};
