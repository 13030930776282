export enum RESET_DATAPOINT_STATUS {
  INIT,
  PROGRESS,
  SUCCESS,
  ERROR
}

export interface resetDatapointState {
  status: RESET_DATAPOINT_STATUS;
  resetDetails?: any;
}
