/**
 * Crosshair Configurations
 * @author mahesh.kedari@shorelineiot.com
 */
import { Grid } from '@mui/material';
import React, { ReactElement } from 'react';
import { SLCheckbox } from '../../..';
import SLColorPicker from '../../../color-picker/SLColorPicker';

interface Props {
  prefix?: string;
  currentValue?: any;
  handleUpdate: () => void;
}

export default function CrosshairConfigs({
  prefix = 'crosshair',
  currentValue,
  handleUpdate
}: Props): ReactElement {
  return (
    <>
      <Grid item xs={6}>
        <SLCheckbox
          name={`${prefix}.enable`}
          label="Crosshair"
          labelTranslationId="crosshair"
          onChange={handleUpdate}
          value={currentValue?.[prefix]?.enable}
          checked={currentValue?.[prefix]?.enable || false}
        />
      </Grid>
      <Grid item xs={6}>
        <SLColorPicker
          name={`${prefix}.color`}
          value={currentValue?.[prefix]?.color}
          handleColorChange={handleUpdate}
        />
      </Grid>
    </>
  );
}
