/**
 * Org List Styles
 * @author mahesh.kedari@shorelineiot.com
 */
import { Theme, alpha } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
/**
 *
 */
export const useStyles = makeStyles((theme: Theme) => ({
  orgButton: {
    width: '100%',
    textAlign: 'left',
    justifyContent: 'space-between',
    borderColor: theme.palette.grey[100],
    borderWidth: 'thin',
    borderStyle: 'solid',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.dark, 0.4), // slightly darker custom value
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)'
    }
  },
  menu: {
    '& .MuiPaper-root': {
      width: '17%',
      '@media (max-width:600px)': {
        width: '200px'
      },
      marginTop: '30px',
      maxHeight: '50vh',
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main
    }
  },
  submenu: {
    '& .MuiPaper-root': {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      justifyContent: 'space-between',
      maxHeight: '50vh'
    },
    '&.MuiMenuItem-root': {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      justifyContent: 'space-between',
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.dark, 0.4), // slightly darker custom value
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)'
      }
    }
  },
  orgmenu: {
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.dark, 0.4), // slightly darker custom value
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)'
    }
  },
  suborgmenu: {
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.dark, 0.4), // slightly darker custom value
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)'
    }
  },
  menuItem: {},
  selectorText: {
    width: 120,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontWeight: theme.typography.fontWeightRegular
  }
}));
