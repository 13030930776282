import moment from 'moment';
import { ALARM_STATE_OPTIONS, ALARM_TYPE_OPTIONS } from '../../alarms.constants';
import { AlarmListHelperData } from '../../types/alarmsList.state';
import { Organisation } from '../../../../organization/store';
import { User } from '../../../../org-settings/store/user-management';
import { Device, Group } from '../../../../device/store/types';
import { Alarm, AlarmRawObj, AlarmStatusType } from '../..';
import { Suborg } from '../../../../organization/store/orgs';
import TranslateText from '../../../../../i18n/TranslateText';

export const HISTORICAL_ALARMS = 'historical';

export const ALERT_LEVEL = {
  ALERT_LOW: 'ALERT_LOW',
  ALERT_WARNING: 'ALERT_WARNING',
  ALERT_ERROR: 'ALERT_ERROR',
  ALERT_FATAL: 'ALERT_FATAL',
  ALERT_NONE: 'ALERT_NONE'
} as const;

export const getTimestamp = (timestamp?: number) => {
  const cellText = moment.unix(Number(timestamp) / 1000).format('MM/DD/YY HH:mm');
  return cellText;
};

export const getCreatedAndLatestColumnCellValue = (ts?: number) => {
  const cellText = getTimestamp(ts);
  const tooltipValue = moment(ts).format('MMM DD, YYYY | hh:mm:ss A');

  return { cellText, tooltipValue };
};

const getStateName = (name: AlarmStatusType) => {
  switch (name) {
    case ALARM_STATE_OPTIONS.ACKNOWLEDGED:
      return 'ACK';
    case ALARM_STATE_OPTIONS.NEW:
      return 'NEW';
    case ALARM_STATE_OPTIONS.IN_PROGRESS:
      return 'WIP';
    default:
      return name;
  }
};

const getTooltipValue = (name: AlarmStatusType) => {
  switch (name) {
    case ALARM_STATE_OPTIONS.ACKNOWLEDGED:
      return TranslateText('acknowledged', 'Acknowledged');
    case ALARM_STATE_OPTIONS.NEW:
      return TranslateText('new_alarm', 'New alarm');
    case ALARM_STATE_OPTIONS.IN_PROGRESS:
      return TranslateText('work_in_progress', 'Work in progress');
    case ALARM_STATE_OPTIONS.FIXED:
      return TranslateText('fixed', 'Fixed');
    case ALARM_STATE_OPTIONS.FALSE:
      return TranslateText('false', 'False');
    default:
      return name;
  }
};

export const getStateColumnCellValues = (data: Alarm, usersList: User[]) => {
  const cellText: string = getStateName(data.status);
  const tooltipValue = getTooltipValue(data.status);
  const stateModifiedTime = data?.state_modified_on;
  const stateModifiedByUserName = data?.state_modified_by_user_name;
  const daysAgo = moment.unix(Number(stateModifiedTime) / 1000).fromNow(true);

  return {
    cellText,
    tooltipValue,
    lastModifiedData: daysAgo,
    modifiedByUserName: stateModifiedByUserName
  };
};

export const checkAndGetSubOrgName = (
  subOrgUUID: string,
  subOrgListInfo: Suborg[],
  orgSelectorInfo: Organisation | null
) => {
  const getSubOrgInfo = (subOrgUUID: string, subOrgListStateInfo: Suborg[]) => {
    if (subOrgListStateInfo?.length > 0) {
      const subOrgInfo = subOrgListStateInfo.find((subOrg) => subOrg.uuid === subOrgUUID);
      return subOrgInfo?.name;
    }
    return '';
  };

  const checkAndGetSubOrgName = (
    subOrgUUID: string,
    subOrgListStateInfo: Suborg[],
    orgSelectorInfo: Organisation | null
  ) => {
    const subOrgName = getSubOrgInfo(subOrgUUID, subOrgListStateInfo);
    if (subOrgName && subOrgName === '_phantom') {
      return orgSelectorInfo?.name;
    }
    return subOrgName || orgSelectorInfo?.name;
  };

  const siteName = checkAndGetSubOrgName(subOrgUUID, subOrgListInfo, orgSelectorInfo);

  return { cellText: siteName || '' };
};

const getCanViewAnalysisData = (alarmObj: Alarm) =>
  Boolean(alarmObj.alarm_type === ALARM_TYPE_OPTIONS.ASSET.value && alarmObj.event_ids?.length);

/**
 * Traverses the `groupListData` array to find a group with a matching `fq_dg_path`.
 * If found, the name of the group is added to the `groupNameArray`.
 * If the group has a parent, the function is recursively called with the parent's `fq_dg_path`.
 * @param path - The `fq_dg_path` of the group to search for.
 * @param groupListData - List of groups from which to find matched `fq_dg_path`.
 * @param groupNameArray - Array for storing group name.
 * @returns string[]
 */

function traverseGroups(path: string, groupListData: Group[], groupNameArray: string[]) {
  const group = groupListData.find((group) => group.fq_dg_path === path);
  if (group) {
    groupNameArray.unshift(group.name);
    const parentPath = path.split('.').slice(0, -1).join('.');
    if (parentPath) {
      traverseGroups(parentPath, groupListData, groupNameArray);
    }
  }
  return groupNameArray;
}

/**
 * Generates a full path name for a device given its ID and the current group path.
 * @param groupListData - An array of groups.
 * @param deviceListData - An array of devices.
 * @param selectedDevice - An object containing the IDs of the selected devices and an optional custom path name.
 * @param currentPath - The current group path.
 * @param existingPathName - The existing full path name, if any.
 * @param deviceID - The ID of the selected device.
 * @returns The full path name of the device.
 */
function generateFullPath(
  groupListData: Group[],
  deviceListData: Device[],
  selectedDevice: { device_ids: number[]; pathName?: string },
  currentPath: string,
  existingPathName: string,
  deviceID: number
): string {
  const groupNameArray: string[] = [];
  // Traverse the group path to get the names of all parent groups.
  const listOfGroups = traverseGroups(currentPath, groupListData, groupNameArray);
  const groupPath = listOfGroups.join(' > ');

  // Find the device with the specified ID and get its name.
  const device = deviceListData.find((device) => device.id === deviceID);
  const deviceName = device ? device.name : '';

  // If no existing path name was passed, return a new path name based on the group path and device name.
  if (!existingPathName) {
    return `${groupPath} > ${deviceName}`;
  }

  // If an existing path name was passed, check if it already includes all selected devices.
  const existingNames = existingPathName.split(', ');
  const nameCount = selectedDevice.device_ids.length;
  if (existingNames.length < nameCount) {
    // If the existing path name does not include all selected devices, add the name of the current device or a custom path name.
    const nameToAdd = selectedDevice.pathName || groupPath;
    return `${existingPathName}, ${nameToAdd}`;
  }

  // If the existing path name already includes all selected devices, return the existing path name.
  return existingPathName;
}

/**
 * Updates the path name for the alarm object using the device IDs, device and group list data
 * @param alarmObj - the alarm object to update the path name for
 * @param deviceListData - the list of devices
 * @param groupListData - the list of groups
 * @returns the updated path name for the alarm object or undefined if no path name is generated
 */
export function updatePathName(
  alarm: AlarmRawObj,
  deviceListData: Device[],
  groupListData: Group[]
): string | undefined {
  const pathNames = alarm.device_ids?.map((deviceID) => {
    // an array of path names generated from the device IDs
    const existingPathName = alarm?.pathName || ''; // the existing path name or an empty string if it doesn't exist

    const devicePath = deviceListData.find((device) => device.id === deviceID); // the device path object that matches the device ID

    if (devicePath && devicePath?.fq_dg_path?.length > 0) {
      // if the device path has a full qualified device group path
      return generateFullPath(
        // call the generateFullPath function to generate the full path name for the device
        groupListData,
        deviceListData,
        alarm,
        devicePath?.fq_dg_path,
        existingPathName,
        deviceID
      );
    } else if (!existingPathName) {
      // if there is no existing path name and the device path doesn't have a full qualified device group path
      return devicePath?.name; // return the device name
    } else if (existingPathName?.split(',').length < alarm.device_ids.length) {
      // if the number of device IDs is greater than the number of path names in the existing path name
      return alarm.pathName ? `${alarm.pathName}, ${devicePath?.name}` : devicePath?.name; // concatenate the existing path name and the device name with a comma or return the device name if there is no existing path name
    } else {
      // if none of the conditions are met
      return undefined; // return undefined
    }
  });

  const newPathName = pathNames?.filter(Boolean).join(', '); // filter out any undefined values and join the path names into a single string with commas

  return newPathName; // return the new path name or undefined
}

export const formattedAlarmData = (
  allAlarms: Alarm[],
  alarmListHelperData?: AlarmListHelperData
) => {
  if (alarmListHelperData && Object.entries(alarmListHelperData)?.length) {
    return allAlarms?.map((alarmObj: Alarm) => {
      const createdColumnData = getCreatedAndLatestColumnCellValue(alarmObj?.created);
      const latestColumnData = getCreatedAndLatestColumnCellValue(alarmObj?.latest);
      const stateColumnData = getStateColumnCellValues(alarmObj, alarmListHelperData?.usersList);
      const siteColumnData = checkAndGetSubOrgName(
        alarmObj.suborg_uuid,
        alarmListHelperData.subOrgListState,
        alarmListHelperData.orgSelectorState
      );
      const canViewAnalysis = getCanViewAnalysisData(alarmObj);
      const pathName = updatePathName(
        alarmObj,
        alarmListHelperData.deviceListData,
        alarmListHelperData.groupListData
      );
      const listWithTableRelatedValues = {
        ...alarmObj,
        createdColumnData,
        latestColumnData,
        stateColumnData,
        siteColumnData,
        canViewAnalysis,
        pathName
      };

      return listWithTableRelatedValues;
    });
  }
  return allAlarms;
};
