/**
 * Chart Configuration Object Definition
 * @author mahesh.kedari@shorelineiot.com
 *
 */

export enum VIEW_TYPE {
  TABLE = 'table',
  LINE = 'line',
  CIRCULAR_GAUGE = 'gauge',
  ANOMALY_INDICATOR = 'indicator'
}
/**
 *
 */
export enum VIEW_CONFIG_STATUS {
  INIT,
  FETCHING,
  READY,
  ERROR
}
/**
 *
 */
export interface ViewConfigState {
  status: VIEW_CONFIG_STATUS;
  viewType: VIEW_TYPE;
  fullWidth: boolean;
  config: any;
  type: string;
}
