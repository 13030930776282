/**
 * Manual Configuration Saga
 * @author mahesh.kedari@shorelineiot.com
 */
import { takeEvery, put, call, all } from 'redux-saga/effects';
import { AnyAction } from 'redux';

import { submitNarrowband, createRule } from '../common/narrowband-saga.helper';
import * as ACTIONS from './manual-config.actionTypes';
import * as narrowbandActions from './manual-config.actions';

import * as datapointActions from '../../../device-settings/device-data/actions/datapoints.actions';
import {
  GenericObject,
  httpGet,
  httpPost,
  httpPut,
  removeProgressFor,
  showProgressFor,
  showToast,
  APISERVICES,
  handleErrorSaga
} from '../../../../../../../framework';
import { SagaIterator } from 'redux-saga';

const SUBMIT_NARROWBAND_PROGRESS_ID = 'SUBMIT_NARROWBAND_PROGRESS_ID';

function fetchRuleDetails(deviceId: string | number, dpid: string | number, slug: string) {
  return httpGet(
    `orgs/${slug}/device_rule_template?dpid=${dpid}&device_id=${deviceId}`,
    null,
    APISERVICES.DEVICE_API
  );
}

function submitManualNB(deviceId: string | number, payload: Object, slug: string) {
  return httpPost(
    `orgs/${slug}/devices/${deviceId}/nb_modules/manual`,
    payload,
    APISERVICES.DEVICE_API
  );
}
function updateManualNB(deviceId: string | number, payload: Object, slug: string) {
  return httpPut(
    `orgs/${slug}/devices/${deviceId}/nb_modules/manual`,
    payload,
    APISERVICES.DEVICE_API
  );
}

function updateMultipleRules(rules: any, slug: string) {
  return httpPut(
    `orgs/${slug}/device_rule_template/rule_list`,
    {
      rule_templates: rules
    },
    APISERVICES.DEVICE_API
  );
}

/**
 * Narrowband Configurator save
 * @param action
 */
function* saveNarrowbandConfig(action: AnyAction) {
  try {
    yield put(showProgressFor(SUBMIT_NARROWBAND_PROGRESS_ID));
    const { slug, module, uuid } = action.payload.narrowband;
    if (uuid) {
      // If DPID exists, this is an edit scenario
      // result will get a List of UUIDs of Narrowbands which have been updated
      const sensorModules = [];
      sensorModules.push({
        ...module.sensor_module,
        module_meta: {
          ...module.module_meta,
          uuid,
          version: '1.0',
          description: 'Narrowband',
          revision: 1
        }
      });
      const result: GenericObject = yield call(
        submitNarrowband,
        {
          device_id: module.device_id,
          sensor_modules: sensorModules
        },
        slug,
        true
      );
      const updatedModules = result.sensor_modules;
      const { rule } = action.payload;
      const updatedRules: any = [];
      updatedModules.forEach((updatedModule: any) => {
        const updatedConditions: any = [];
        rule.conditions.forEach((condition: any) => {
          updatedConditions.push({
            ...condition,
            operation_data: {
              ...condition.operation_data,
              dpid: updatedModule.dpid
            }
          });
        });
        updatedRules.push({
          ...rule,
          rule_template_uuid: updatedModule.rule_template_uuid,
          name: updatedModule.name,
          conditions: updatedConditions
        });
      });

      yield call(updateMultipleRules, updatedRules, slug);
      yield put(narrowbandActions.submitNarrowbandSuccess(result));
      yield put(showToast('Narrowband updated successfully', 'success'));
      // Refresh Datapoint List after creation
      yield put(
        datapointActions.fetchDatapoints({
          slug,
          deviceId: module.device_id
        })
      );
    } else {
      // If DPID doesn't exist, this is a create scenario
      const result: GenericObject = yield call(submitNarrowband, module, slug, false);
      if (action.payload.rule) {
        // With earlier flow, we needed to fetch dpid by calling datapoints list and extract desired dpid by comparing UUID.
        // const dpid = yield call(fetchDPID, deviceId, slug, result.uuid);
        // With Updated flow, we need to send single configuration in an array for multiple modules API. Backend will create one narrowband each for multiple axis
        // and return a list of datapoint to which rule needs to be applied
        const dpids = result?.sensor_modules;
        yield all(
          dpids.map((dpidObj: any) => {
            // #SLC-2128 Create new instance of conditions object, else due to closure,
            // last object will replace all the previous instances and
            // all the rules will have same conditions object
            const condition = { ...action.payload.rule.conditions[0] };
            condition.operation_data = {
              ...condition.operation_data,
              dpid: dpidObj.dpid
            };
            const ruleModule = {
              ...action.payload.rule,
              name: dpidObj.name,
              conditions: [condition]
            };
            // Fork is added for making non-blocking calls of create rules
            // This will allow parallel request flow for rules.
            // Error handling should be separately done for create rules
            return call(createRule, ruleModule, slug, module.device_id);
          })
        );
      }
      yield put(narrowbandActions.submitNarrowbandSuccess(result));
      yield put(showToast('Narrowband created successfully', 'success'));
      // Refresh Datapoint List after creation
      yield put(
        datapointActions.fetchDatapoints({
          slug,
          deviceId: module.device_id
        })
      );
    }
  } catch (error: any) {
    yield handleErrorSaga(error, 'Failed to create Narrowband');
    yield put(narrowbandActions.submitNarrowbandFailure(error));
  } finally {
    yield put(removeProgressFor(SUBMIT_NARROWBAND_PROGRESS_ID));
  }
}

function* fetchNarrowbandRule(action: AnyAction) {
  const { deviceId, dpid, slug } = action.payload;
  try {
    yield put(showProgressFor(SUBMIT_NARROWBAND_PROGRESS_ID));
    const response: GenericObject = yield call(fetchRuleDetails, deviceId, dpid, slug);
    const rule = response?.results[0];
    yield put(narrowbandActions.fetchNarrowbandRuleSuccess(rule));
  } catch (error: any) {
    yield put(showToast('Failed to fetch Narrowband Rule', 'error', true));
  } finally {
    yield put(removeProgressFor(SUBMIT_NARROWBAND_PROGRESS_ID));
  }
}

function* createManualNarrowband(action: AnyAction) {
  const { deviceId, payload, slug } = action.payload;
  try {
    yield put(showProgressFor(SUBMIT_NARROWBAND_PROGRESS_ID));
    const response: GenericObject = yield call(submitManualNB, deviceId, payload, slug);

    yield put(showToast(response.message, 'success'));
    yield put(narrowbandActions.resetNarrowbandConfig());
    yield put(narrowbandActions.handleNarrowbandsInProgress());
  } catch (error: any) {
    yield put(showToast('Failed to create Manual Narrowband ', 'error', true));
  } finally {
    yield put(removeProgressFor(SUBMIT_NARROWBAND_PROGRESS_ID));
  }
}

function* updateManualNarrowband(action: AnyAction) {
  const { deviceId, payload, slug } = action.payload;
  try {
    yield put(showProgressFor(SUBMIT_NARROWBAND_PROGRESS_ID));
    const response: GenericObject = yield call(updateManualNB, deviceId, payload, slug);

    yield put(showToast(response.message, 'success'));
    yield put(narrowbandActions.resetNarrowbandConfig());
    yield put(narrowbandActions.handleNarrowbandsUpdating());
  } catch (error: any) {
    yield put(showToast('Failed to fetch Narrowband Rule', 'error', true));
  } finally {
    yield put(removeProgressFor(SUBMIT_NARROWBAND_PROGRESS_ID));
  }
}

export function* watchManualConfigSaga(): SagaIterator {
  yield all([
    takeEvery(ACTIONS.PERSIST_NB_RULE_ACTIONS_AND_SUBMIT, saveNarrowbandConfig),
    takeEvery(ACTIONS.SUBMIT_NB, saveNarrowbandConfig),
    takeEvery(ACTIONS.FETCH_NB_RULE, fetchNarrowbandRule),
    takeEvery(ACTIONS.CREATE_MANUAL_NB, createManualNarrowband),
    takeEvery(ACTIONS.UPDATE_MANUAL_NB, updateManualNarrowband)
  ]);
}
