/**
 * dashbaord items
 */
export enum DashboardItemsStatus {
  INIT,
  FETCHING,
  READY,
  ERROR,
  RESET
}
export interface DashboardItemsState {
  status: DashboardItemsStatus;
  dashboardItemsData: any;
}
