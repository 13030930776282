/**
 * Alarms Export Actions
 * @author prerna.more@shorelineiot.com
 */
import { AnyAction } from 'redux';
import { GenericObject } from '../../../../framework';
import * as ACTIONS_TYPES from './alarmsExport.actionTypes';
import { ExportAlarmsFromWebsocketResponse } from '../types/AlarmExport';

export const fetchAlarmsExport = (data: GenericObject): AnyAction => {
  return {
    type: ACTIONS_TYPES.FETCH_ALARMS_EXPORT_LIST,
    payload: data
  };
};

export const fetchAlarmsExportSuccess = (data: GenericObject): AnyAction => {
  return {
    type: ACTIONS_TYPES.FETCH_ALARMS_EXPORT_LIST_SUCCESS,
    payload: data
  };
};

export const fetchAlarmsExportFailure = (): AnyAction => {
  return {
    type: ACTIONS_TYPES.FETCH_ALARMS_EXPORT_LIST_FAILURE
  };
};

export const handleEmailChange = (data: string[]): AnyAction => {
  return {
    type: ACTIONS_TYPES.FETCH_ALARMS_EXPORT_EMAIL_CHANGE,
    payload: data
  };
};

export const handleExportType = (data: string): AnyAction => {
  return {
    type: ACTIONS_TYPES.SET_ALARMS_EXPORT_TYPE,
    payload: data
  };
};

export const fetchAlarmsExportReset = (): AnyAction => {
  return {
    type: ACTIONS_TYPES.FETCH_ALARMS_EXPORT_LIST_RESET
  };
};

export const performAlarmsFileDownload = (
  websocketData: ExportAlarmsFromWebsocketResponse
): AnyAction => {
  return {
    type: ACTIONS_TYPES.PERFORM_ALARMS_FILE_DOWNLOAD,
    payload: websocketData
  };
};
