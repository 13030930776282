import React from 'react';
import { generatePath, useNavigate } from 'react-router';
import { useStyles } from './mapMarker.styles';
import { Info } from '../common/map-chart/mapChartUtils';
import { useSlugContext } from '../../../lib/slugContext';
import { ALARM_TYPE_OPTIONS, AlertLevel } from '../../../../features/alarms/store';
import { MapChartMarkerTypes, MAP_CHART_MARKER_TYPES } from './MapChartDefaultViewConfig';
import { TooltipInfo } from './GoogleMapComponent';

interface MapMarkerProps {
  color: string;
  info: Info;
  lat: number;
  lng: number;
  severity: AlertLevel;
  editMode: boolean;
  markerType: MapChartMarkerTypes;
  setShowTooltip: React.Dispatch<React.SetStateAction<boolean>>;
  setTooltipInfo: React.Dispatch<React.SetStateAction<TooltipInfo | null>>;
  onTooltip: boolean;
}
const MapMarker = (props: MapMarkerProps) => {
  const { color, info, severity, editMode, markerType } = props;
  const classes = useStyles();
  const navigate = useNavigate();
  const { slug } = useSlugContext();

  const getAlarmsPagePath = (
    alertLevel: AlertLevel,
    deviceId: number,
    data: { assetDashboardURL: boolean }
  ) => {
    return generatePath(
      `/${slug}/app/alarms?isGroup=true&severity=${alertLevel}&path=${deviceId}&alarm_type=${
        data?.assetDashboardURL ? ALARM_TYPE_OPTIONS.ASSET.value : ALARM_TYPE_OPTIONS.SENSOR.value
      }`,
      {
        alertLevel,
        deviceId
      }
    );
  };

  const redirectToAssetAlarms = (deviceId: number, alertLevel: AlertLevel) => {
    if (alertLevel !== 'ALERT_NONE') {
      navigate(
        getAlarmsPagePath(alertLevel, deviceId, {
          assetDashboardURL: true
        })
      );
    }
  };

  const handleMouseEnter = () => {
    props.setShowTooltip(true);
    props.setTooltipInfo({
      ...info,
      lat: props.lat,
      lng: props.lng
    });
  };

  const handleMouseExit = () => {
    if (!props.onTooltip) {
      props.setShowTooltip(false);
      props.setTooltipInfo(null);
    }
  };

  return (
    <div
      className={markerType === MAP_CHART_MARKER_TYPES.MARKER ? classes.marker : classes.bubble}
      style={
        markerType === MAP_CHART_MARKER_TYPES.BUBBLE
          ? { backgroundColor: color, height: `${info.radius}px`, width: `${info.radius}px` }
          : { backgroundColor: color }
      }
      onClick={() => {
        if (!editMode) {
          redirectToAssetAlarms(info.device_id, severity);
        }
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseExit}
    />
  );
};

export default MapMarker;
