/**
 * Invite Accept Failed
 * @author mahesh.kedari@shorelineiot.com
 */
import { Typography } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useSlugContext } from '../../../../framework';
import { FailureLarge } from '../../../../framework/icons';
/**
 *
 * @returns
 */
export default function InviteAcceptFailed() {
  const slug = useSlugContext();
  return (
    <>
      <FailureLarge />
      <Typography variant="h2">Failed to accept Invite</Typography>
      <Typography>
        Navigate to &nbsp;
        <RouterLink to={`/${slug}/auth/login`}>Login</RouterLink>
      </Typography>
    </>
  );
}
