import { Box, Grid } from '@mui/material';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import WidgetDataUnavailable from '../widget-data-unavailable/WidgetDataUnavailable';
import SLTable from '../../table/SLTable';
import { Colors } from '../../../constants';
import { useStyles } from './styles/sensorListWidget.styles';
import { SLTableProps } from '../../table/slTable.types';
import { ActionsDeleteIcon } from '../../action-icons';
import { Device } from '../../../../features/device/store/types';
import useIsSuperAdmin from '../../../../features/org-settings/user-management/custom-hooks/useIsSuperAdmin';
import { DEVICE_PROVISION_STATUS } from '../../../constants/provision';
import { CONFIRM_ACTION, SLConfirmationDialog } from '../..';
import { useSlugContext } from '../../../lib';
import { useDeleteDevice } from '../../../../features/device/store/device.hooks';

export type SLSensorListWidgetTableProps = SLTableProps<Record<string, any>>;

const SLSensorListWidget = React.forwardRef(
  (
    {
      data,
      columns,
      onRowClick,
      initialColumnVisibilityState,
      ...props
    }: SLSensorListWidgetTableProps,
    ref: any
  ) => {
    const classes = useStyles();
    const { slug } = useSlugContext();
    const stageCanvasRef: any = useRef(null);
    const [tableHeight, setTableHeight] = useState(
      stageCanvasRef?.current?.parentElement?.parentElement?.parentElement?.clientHeight
    );
    const [displayConfirmationDialog, setDisplayConfirmationDialog] = useState<boolean>(false);
    const [deviceDetailsToDelete, setDeviceDetailsToDelete] = useState<Device | undefined>(
      undefined
    );
    const DIFFERENCE_IN_PX = 70;

    const isSuperAdmin = useIsSuperAdmin();

    const { deleteDevice } = useDeleteDevice();

    useEffect(() => {
      setTableHeight(
        stageCanvasRef?.current?.parentElement?.parentElement?.parentElement?.clientHeight
      );
    }, [stageCanvasRef?.current?.parentElement?.parentElement?.parentElement?.clientHeight]);

    useImperativeHandle(
      ref,
      () => ({
        resize(width: number, height: number) {
          setTableHeight(height);
        }
      }),
      []
    );

    const resetRowSelection = () => {
      setDeviceDetailsToDelete(undefined);
      setDisplayConfirmationDialog(false);
    };

    const onConfirm = (action: CONFIRM_ACTION) => {
      if (action === CONFIRM_ACTION.CONFIRM && deviceDetailsToDelete && slug) {
        const data = {
          slug,
          deviceId: deviceDetailsToDelete.id
        };
        deleteDevice(data);
      }
      resetRowSelection();
    };

    const handleClose = () => {
      setDisplayConfirmationDialog(false);
    };

    if (!data || (data && data[0]?.message && data[0]?.message === 'No data')) {
      return <WidgetDataUnavailable />;
    }

    const RowActionsButtonsRender = ({ row }: { row: { original: Device } }) => {
      const rowData = row?.original;

      return (
        <>
          <Grid item>
            <ActionsDeleteIcon
              disabled={rowData?.provisioning_status !== DEVICE_PROVISION_STATUS.NEVER_PROVISIONED}
              onClick={() => {
                setDisplayConfirmationDialog(true);
                setDeviceDetailsToDelete(rowData);
              }}
            />
          </Grid>
        </>
      );
    };

    return (
      <Box ref={stageCanvasRef} textAlign="center" className={classes.autoMargin}>
        <SLConfirmationDialog
          message="All the data for this device will be deleted, do you want to continue?"
          translationId="device_deletion_msg"
          onConfirm={onConfirm}
          onClose={handleClose}
          confirmButtonName="Delete"
          displayConfirmationDialog={displayConfirmationDialog}
        />
        <SLTable
          {...props}
          columns={columns}
          data={data || []}
          enableSorting
          maxBodyHeight={tableHeight && tableHeight - DIFFERENCE_IN_PX}
          paperColor={Colors.background}
          onRowClick={onRowClick}
          initialColumnVisibilityState={initialColumnVisibilityState}
          enableRowActions={isSuperAdmin}
          RowActionsButtons={RowActionsButtonsRender}
          feature="sensors"
        />
      </Box>
    );
  }
);

export default SLSensorListWidget;
