import React, { useEffect } from 'react';
import {
  DEFAULT_CONFIG_3D,
  WATERFALL_LOCALSTORAGE_3D_CONFIG
} from '../../../../features/workflow/trend-analysis/waterfall/header/waterfall.helper';
import { useGAEventTracker } from '../../../custom-hooks';
import { SLButton } from '../../button';
import { SLSlider, SLForm } from '../../form';
import { Grid } from '@mui/material';

type ChartSliderProps = {
  configProperty: string;
  setLocalState: Function;
  label: string;
  value: number;
  min: number;
  max: number;
};

const ChartSlider = ({
  configProperty,
  setLocalState,
  label,
  value,
  min,
  max
}: ChartSliderProps) => {
  const handleChange = React.useCallback(
    (event: any) => {
      setLocalState(configProperty, event.target?.value);
    },
    [configProperty, setLocalState]
  );

  return <SLSlider label={label} value={value} min={min} max={max} onChange={handleChange} />;
};

type Props = {
  setConfig3d: React.Dispatch<any>;
  config3d: any;
};

export default function SLChartViewSlider({ setConfig3d, config3d }: Props) {
  const GAEventsTracker = useGAEventTracker('Waterfall config');

  const handleChange3d = React.useCallback((property: string, value: number) => {
    setConfig3d((prevValue: any) => ({ ...prevValue, [property]: value }));
  }, []);

  useEffect(() => {
    GAEventsTracker('WaterFall chart config', `config: ${config3d}`);
    localStorage.setItem(WATERFALL_LOCALSTORAGE_3D_CONFIG, JSON.stringify(config3d));
  }, [config3d]);

  const resetDefault = React.useCallback(() => {
    setConfig3d(DEFAULT_CONFIG_3D);
  }, []);

  return (
    <SLForm>
      <Grid container columnSpacing={2}>
        <Grid
          item
          xs={12}
          container
          columnSpacing={2}
          justifyContent="flex-start"
          alignItems="center">
          <Grid item xs={6}>
            <ChartSlider
              configProperty="alpha"
              label={'Vertical'}
              value={config3d.alpha}
              min={-45}
              max={45}
              setLocalState={handleChange3d}
            />
          </Grid>
          <Grid item xs={6}>
            <ChartSlider
              configProperty="beta"
              value={config3d.beta}
              label={'Horizontal'}
              min={-90}
              max={90}
              setLocalState={handleChange3d}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container columnSpacing={2}>
        <Grid
          item
          xs={12}
          container
          columnSpacing={2}
          justifyContent="flex-start"
          alignItems="center">
          <Grid item xs={6}>
            <ChartSlider
              configProperty="depth"
              value={config3d.depth}
              label={'Depth'}
              min={0}
              max={1000}
              setLocalState={handleChange3d}
            />
          </Grid>
          <Grid item xs={6}>
            <ChartSlider
              configProperty="viewDistance"
              value={config3d.viewDistance}
              label={'View Distance'}
              min={1}
              max={50}
              setLocalState={handleChange3d}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container columnSpacing={2}>
        <Grid
          item
          xs={12}
          container
          columnSpacing={2}
          justifyContent="flex-start"
          alignItems="center">
          <Grid item xs={6}>
            <ChartSlider
              configProperty="lineGap"
              value={config3d.lineGap}
              label={'Line Gap'}
              min={20}
              max={100}
              setLocalState={handleChange3d}
            />
          </Grid>
          <Grid item xs={6} marginTop={2}>
            <SLButton onClick={resetDefault} translationId="default">
              Default
            </SLButton>
          </Grid>
        </Grid>
      </Grid>
    </SLForm>
  );
}
