/**
 * Capitalize First letter of String
 * @author mahesh.kedari@shorelineiot.com
 */
export function capitalize(str: string) {
  if (str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  } else {
    return str;
  }
}
