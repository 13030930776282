/**
 * Features Saga
 * Aggregator
 * @author mahesh.kedari@shorelineiot.com
 */
import { all } from 'redux-saga/effects';
import { watchDeviceSaga } from './device';
import { dashboardSagas } from './dashboard/store';
import { anomalyTemplatesSaga } from './anomaly-templates';
import { watchUserSelfSaga, watchUserUpdateSaga } from './user-settings/store';
import { getOrgThemeWatcherSagas } from './theme/theme.saga';
import { watchOrganisationSaga } from './organization/org.saga';
import watchOrgSettings from './org-settings/orgSettings.saga';
import { authSagas } from './auth/auth.saga';
// import { rulesSagas } from './rules/sagas/rulesIndex.saga';
import { deviceProfileSaga } from './device-profile/sagas/deviceProfile.saga';
import { alertsSagas } from './alerts/alerts.saga';
import { vibrationAnalysisSaga } from './workflow/vibration-analysis/store/vibrationAnalysis.saga';
import { watchReportsSaga } from './reports/store/reports.saga';
import { watchAlarmsSaga } from './alarms/store';
import { watchAnomalySaga } from './workflow/anomaly-configurator/store/anomaly.saga';
import { componentCompilerSaga } from './templates/component-compiler/store';
import { verificationSagas } from './auth/register/store';
import { watcherSagas, getExportDeviceDataWatchingSagas } from './device/device-details';

/**
 *
 */
export function* featuresSaga() {
  yield all([
    getOrgThemeWatcherSagas(),
    authSagas(),
    watchOrgSettings(),
    watchDeviceSaga(),
    verificationSagas(),
    deviceProfileSaga(),
    watchOrganisationSaga(),
    watcherSagas(),
    getExportDeviceDataWatchingSagas(),
    watchUserSelfSaga(),
    // rulesSagas(),
    watchUserUpdateSaga(),
    dashboardSagas(),
    watchAlarmsSaga(),
    alertsSagas(),
    watchReportsSaga(),
    componentCompilerSaga(),
    anomalyTemplatesSaga(),
    vibrationAnalysisSaga(),
    watchAnomalySaga()
  ]);
}
