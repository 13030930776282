/**
 * Donut Chart Editor
 * @author Prerna.More@shorelineiot.com
 */
import React, { useEffect, useState } from 'react';
import { merge } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { ChartEditorProps } from '../common/ChartEditorProps';
import DataSpecificPieChartEditor from '../pie-chart/settings/DataSpecificPieChartEditor';
import ResetDefaultConfig from '../common/ResetDefaultConfig';
import DonutChartCommonEditor from './settings/DonutChartCommonEditor';
import { donutChartUnwantedKeysObject, donutFormDefaults } from '../chartEditor.utils';

const SLDonutChartEditor: React.FC<ChartEditorProps> = ({
  handleSubmitForm,
  defaultValues,
  dataConfig,
  widgetType
}: ChartEditorProps) => {
  const newDataSpecificSettings: any = {};

  if (defaultValues?.dataSettings?.donutData || !defaultValues?.dataSettings) {
    // Get only data-specific settings from the existingData object.
    Object.entries(defaultValues)?.forEach(([key, value]: [any, any]) => {
      const unwantedItem = Object.entries(donutChartUnwantedKeysObject)?.find(
        ([formDefaultKey]) => formDefaultKey === key
      );
      if (!unwantedItem) {
        newDataSpecificSettings[key] = value;
      }
    });
  }

  const [donutChartSettings, setDonutChartSettings] = useState<any>({
    chartType: 'donut',
    chartSettings: {
      ...(Object.keys(defaultValues).length > 0 ? defaultValues : donutFormDefaults),
      ...(Object.entries(newDataSpecificSettings)?.length && {
        dataSettings: newDataSpecificSettings
      })
    }
  });

  const { getValues, setValue } = useFormContext();
  const handleUpdate = () => {
    const values = getValues();
    const updatedSettings = merge({}, donutChartSettings);
    // updating local variable here
    const settings = {
      ...updatedSettings,
      chartSettings: {
        ...values
      }
    };
    setDonutChartSettings(settings);
    handleSubmitForm(settings);
  };

  useEffect(() => {
    if (Object.entries(newDataSpecificSettings)?.length) {
      setValue('dataSettings', newDataSpecificSettings);
      handleUpdate();
    }
  }, []);

  return (
    <>
      <DonutChartCommonEditor
        currentSettings={donutChartSettings.chartSettings}
        handleUpdate={handleUpdate}
      />
      <DataSpecificPieChartEditor
        dataConfig={dataConfig}
        chartSettings={donutChartSettings.chartSettings}
        widgetType={widgetType}
        handleUpdate={handleUpdate}
      />

      <ResetDefaultConfig chartType="donut" onReset={handleUpdate} />
    </>
  );
};
export default SLDonutChartEditor;
