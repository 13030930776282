/**
 * Vibration Analysis Reducer
 * @author mahesh.kedari@shorelineiot.com
 */
import { combineReducers } from 'redux';
import narrowbands from './narrowbands/VANarrowbands.reducer';
import devices from './device/vaDevice.reducer';
import units from './va-units/vaUnits.reducer';
import events from './events/vaEvents.reducer';
import fftChart from './fft-chart/fftChart.reducer';
import summary from './va-summary/vaSummary.reducer';

const VibrationAnalysisReducer = combineReducers({
  narrowbands,
  devices,
  units,
  events,
  fftChart,
  summary
});

export default VibrationAnalysisReducer;
