/**
 * Fetch Topic Helper for Datapoints Reducer
 * @author mahesh.kedari@shorelineiot.com
 */

import { DatapointsListState } from '../types/datapointsList.state';
import { LIVE_DATA_CONFIG_STATUS } from '../types/liveDataConfig';
/**
 *
 * @param state
 * @param dpid
 */
function getLiveDataConfig(state: DatapointsListState, dpid: string | number): any {
  const liveDataConfig = { ...state.liveDataConfigs[dpid] };
  if (liveDataConfig) {
    return liveDataConfig;
  }
  return null;
}
/**
 *
 * @param state
 * @param data
 */
export function onFetchTopic(state: DatapointsListState, { dpid }: any): DatapointsListState {
  const selectedDatapoint: any = getLiveDataConfig(state, dpid);
  if (selectedDatapoint) {
    selectedDatapoint.status = LIVE_DATA_CONFIG_STATUS.FETCHING;
    return {
      ...state,
      liveDataConfigs: { ...state.liveDataConfigs, [dpid]: selectedDatapoint }
    };
  }
  return state;
}
/**
 *
 * @param state
 * @param data
 */
export function onFetchTopicSuccess(state: DatapointsListState, data: any): DatapointsListState {
  const { topics } = data;
  let updatedState = { ...state };
  Object.entries(topics).forEach(([dpid, topic]) => {
    const selectedDatapoint: any = getLiveDataConfig(state, dpid);
    if (selectedDatapoint) {
      selectedDatapoint.status = LIVE_DATA_CONFIG_STATUS.READY;
      selectedDatapoint.topic = topic;
      updatedState = {
        ...updatedState,
        liveDataConfigs: {
          ...updatedState.liveDataConfigs,
          [dpid]: selectedDatapoint
        }
      };
    }
  });

  return updatedState;
}
/**
 *
 * @param state
 * @param param1
 */
export function onFetchTopicFailure(
  state: DatapointsListState,
  { dpid }: any
): DatapointsListState {
  const selectedDatapoint: any = getLiveDataConfig(state, dpid);
  if (selectedDatapoint) {
    selectedDatapoint.status = LIVE_DATA_CONFIG_STATUS.ERROR;
    return {
      ...state,
      liveDataConfigs: { ...state.liveDataConfigs, [dpid]: selectedDatapoint }
    };
  }
  return state;
}
/**
 *
 * @param state
 * @param param1
 */
export function onTopicClose(state: DatapointsListState, { dpid }: any): DatapointsListState {
  const selectedDatapoint: any = getLiveDataConfig(state, dpid);
  if (selectedDatapoint) {
    selectedDatapoint.status = LIVE_DATA_CONFIG_STATUS.INIT;
    return {
      ...state,
      liveDataConfigs: { ...state.liveDataConfigs, [dpid]: selectedDatapoint }
    };
  }
  return state;
}
