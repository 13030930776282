/**
 * Header component
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { ReactNode } from 'react';
import { AppBar, Toolbar } from '@mui/material';
import { useStyles } from './header.styles';
/**
 *
 */
interface Props {
  isDesktop: boolean;
  children: ReactNode | undefined;
}
/**
 *
 */
const SLHeader: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const { children } = props;
  return (
    <div className={classes.grow}>
      <AppBar position="static" className={classes.root}>
        <Toolbar>{children}</Toolbar>
      </AppBar>
    </div>
  );
};

export default SLHeader;
