/**
 *  SLBattery status editor
 * Created this component for future reference.
 *  @author mahesh.kedari@shorelineiot.com
 */
import React, { useState } from 'react';
import WidgetSettingAccordion from '../common/WidgetAccordion';
import BatteryStatusConditionContainer from './BatteryStatusConditionContainer';
import { ChartEditorProps } from '../common/ChartEditorProps';
import { DASHBOARD_ITEM_TYPE } from '../../dashboard-widgets/types/DashboardItemType';
import { columns, defaultBatteryStatusSettings } from './BatteryStatusConstants';
import { useFormContext } from 'react-hook-form';
import { merge } from 'lodash';

export default function SLBatteryStatusEditor({
  handleSubmitForm,
  defaultValues
}: ChartEditorProps) {
  const [settings, setSettings] = useState<any>({
    chart_type: DASHBOARD_ITEM_TYPE.BATTERY_STATUS,
    chartSettings:
      Object.keys(defaultValues).length > 0 ? defaultValues : { ...defaultBatteryStatusSettings },
    positionMeta: [0, 0, 16, 8]
  });

  const { getValues } = useFormContext();

  const handleUpdate = () => {
    const currentSettings = merge({}, settings);
    const values = getValues();
    const updatedSettings = {
      ...currentSettings,
      chartSettings: {
        ...values
      }
    };
    handleSubmitForm(updatedSettings);
    setSettings(updatedSettings);
  };

  return (
    <>
      {columns.map((column) => (
        <WidgetSettingAccordion
          key={column.name}
          title={column.title}
          defaultExpanded={
            settings.chartSettings[column.name].conditions.length > 0 ? true : false
          }>
          <BatteryStatusConditionContainer
            handleUpdate={handleUpdate}
            columnName={column.name}
            settings={settings.chartSettings[column.name]}
          />
        </WidgetSettingAccordion>
      ))}
    </>
  );
}
