import { AnyAction } from 'redux';
import groupBy from 'lodash/groupBy';
import * as ACTION_TYPE from '../actions/rolesUpdate.actionTypes';
import { RolesOrgActionsState } from '../types/RolesOrgActionsState';
import { ROLES_ORG_ACTIONS_STATUS } from '../types/RolesOrgActions.Status';
import { ROLES_ACTIONS_STATUS } from '../types/RolesActions.Status';

let arrayRoles: Array<{
  allow: boolean;
  name: string;
  uuid: string;
  display_name: string;
  action_type: string;
  resource_type: string;
}> = [];

const initialState: RolesOrgActionsState = {
  statusOrgActions: ROLES_ORG_ACTIONS_STATUS.INIT,
  statusRoleActions: ROLES_ACTIONS_STATUS.INIT,
  rolesPermissions: Object,
  create: Object,
  read: Object,
  update: Object,
  delete: Object
};

function fetchRolesOrgActions(state: RolesOrgActionsState): RolesOrgActionsState {
  return {
    ...state,
    statusOrgActions: ROLES_ORG_ACTIONS_STATUS.FETCHING_ROLES_ORG_ACTIONS_LIST
  };
}

function fetchRolesOrgActionsSuccess(
  state: RolesOrgActionsState,
  rolesOrgActionData: any
): RolesOrgActionsState {
  arrayRoles = [];
  rolesOrgActionData.forEach((index: any) => {
    arrayRoles.push({
      allow: false,
      name: index.name,
      uuid: index.uuid,
      display_name: index.display_name,
      action_type: index.action_type,
      resource_type: index.resource_type
    });
  });

  return {
    ...state,
    statusOrgActions: ROLES_ORG_ACTIONS_STATUS.READY,
    rolesPermissions: arrayRoles
  };
}

function fetchRolesOrgActionsFailure(state: RolesOrgActionsState): RolesOrgActionsState {
  return {
    ...state,
    statusOrgActions: ROLES_ORG_ACTIONS_STATUS.ERROR
  };
}

function resetRolesOrgActions(state: RolesOrgActionsState): RolesOrgActionsState {
  return {
    ...state,
    statusOrgActions: ROLES_ORG_ACTIONS_STATUS.INIT,
    rolesPermissions: Object
  };
}

function fetchRolesActions(state: RolesOrgActionsState): RolesOrgActionsState {
  return {
    ...state,
    statusRoleActions: ROLES_ACTIONS_STATUS.FETCHING_ROLES_ACTIONS_LIST
  };
}

function fetchRolesActionsSuccess(
  state: RolesOrgActionsState,
  rolesActionsData: any
): RolesOrgActionsState {
  const data = state.rolesPermissions;
  const keys = Object.keys(data);
  const values = Object.values(data);

  const actionsData = rolesActionsData;
  const actionsKeys = Object.keys(actionsData);
  const actionsValues = Object.values(actionsData);
  for (let i = 0; i <= keys.length; i += 1) {
    if (Object.prototype.hasOwnProperty.call(values, i)) {
      for (let j = 0; j <= actionsKeys.length; j += 1) {
        if (Object.prototype.hasOwnProperty.call(actionsValues, j)) {
          if (data[i].uuid === actionsData[j].action_uuid) {
            data[i].allow = true;
          }
        }
      }
    }
  }

  const groupsResourceType = groupBy(data, 'resource_type');

  let totalCreate = 0;
  let countCreate = 0;
  let totalRead = 0;
  let countRead = 0;
  let totalUpdate = 0;
  let countUpdate = 0;
  let totalDelete = 0;
  let countDelete = 0;
  const dataResource = groupsResourceType;
  Object.entries(dataResource).forEach(([key, value]) => {
    if (key) {
      const dataResourceType = Object(value);
      const keysResourceType = Object.keys(Object(value));
      const valuesResourceType = Object.values(Object(value));

      for (let i = 0; i <= keysResourceType.length; i += 1) {
        if (Object.prototype.hasOwnProperty.call(valuesResourceType, i)) {
          if (dataResourceType[i].action_type === 'create') {
            totalCreate += 1;
          }
          if (dataResourceType[i].action_type === 'read') {
            totalRead += 1;
          }
          if (dataResourceType[i].action_type === 'update') {
            totalUpdate += 1;
          }
          if (dataResourceType[i].action_type === 'delete') {
            totalDelete += 1;
          }
        }
      }
    }
  });

  const dataGroupResources = groupsResourceType;
  Object.entries(dataGroupResources).forEach(([key, value]) => {
    if (key) {
      const dataGroupValues = Object(value);
      const keysGroup = Object.keys(Object(value));
      const valuesGroup = Object.values(Object(value));

      for (let i = 0; i <= keysGroup.length; i += 1) {
        if (Object.prototype.hasOwnProperty.call(valuesGroup, i)) {
          if (dataGroupValues[i].action_type === 'create' && dataGroupValues[i].allow === true) {
            countCreate += 1;
          }
          if (dataGroupValues[i].action_type === 'read' && dataGroupValues[i].allow === true) {
            countRead += 1;
          }
          if (dataGroupValues[i].action_type === 'update' && dataGroupValues[i].allow === true) {
            countUpdate += 1;
          }
          if (dataGroupValues[i].action_type === 'delete' && dataGroupValues[i].allow === true) {
            countDelete += 1;
          }
        }
      }
    }
  });

  return {
    ...state,
    statusRoleActions: ROLES_ACTIONS_STATUS.READY,
    rolesPermissions: {
      edit: false,
      groupsResourceType
    },
    create: { total: totalCreate, selected: countCreate },
    read: { total: totalRead, selected: countRead },
    update: { total: totalUpdate, selected: countUpdate },
    delete: { total: totalDelete, selected: countDelete }
  };
}

function fetchRolesActionsFailure(state: RolesOrgActionsState): RolesOrgActionsState {
  return {
    ...state,
    statusRoleActions: ROLES_ACTIONS_STATUS.ERROR
  };
}

function resetRolesActions(state: RolesOrgActionsState): RolesOrgActionsState {
  return {
    ...state,
    statusRoleActions: ROLES_ACTIONS_STATUS.INIT,
    rolesPermissions: Object
  };
}

function editRolesActions(state: RolesOrgActionsState): RolesOrgActionsState {
  return {
    ...state,

    rolesPermissions: {
      edit: true,
      groupsResourceType: state.rolesPermissions.groupsResourceType
    }
  };
}

function resourceTypeAction(state: RolesOrgActionsState, dataPayLoad: any): RolesOrgActionsState {
  const data = state.rolesPermissions.groupsResourceType;

  Object.entries(data).forEach(([key, value]) => {
    if (key === dataPayLoad.resourceType) {
      const dataResource = Object(value);
      const keysResource = Object.keys(Object(value));
      const valuesResource = Object.values(Object(value));

      for (let i = 0; i <= keysResource.length; i += 1) {
        if (Object.prototype.hasOwnProperty.call(valuesResource, i)) {
          dataResource[i].allow = dataPayLoad.changeFlag;
        }
      }
    }
  });

  let countCreate = 0;
  let countRead = 0;
  let countUpdate = 0;
  let countDelete = 0;

  const dataGroupResources = data;
  Object.entries(dataGroupResources).forEach(([key, value]) => {
    if (key) {
      const dataResource = Object(value);
      const keysResource = Object.keys(Object(value));
      const valuesResource = Object.values(Object(value));

      for (let i = 0; i <= keysResource.length; i += 1) {
        if (Object.prototype.hasOwnProperty.call(valuesResource, i)) {
          if (dataResource[i].action_type === 'create' && dataResource[i].allow === true) {
            countCreate += 1;
          }
          if (dataResource[i].action_type === 'read' && dataResource[i].allow === true) {
            countRead += 1;
          }
          if (dataResource[i].action_type === 'update' && dataResource[i].allow === true) {
            countUpdate += 1;
          }
          if (dataResource[i].action_type === 'delete' && dataResource[i].allow === true) {
            countDelete += 1;
          }
        }
      }
    }
  });

  return {
    ...state,
    rolesPermissions: {
      edit: state.rolesPermissions.edit,
      groupsResourceType: data
    },

    create: { total: state.create.total, selected: countCreate },
    read: { total: state.read.total, selected: countRead },
    update: { total: state.update.total, selected: countUpdate },
    delete: { total: state.delete.total, selected: countDelete }
  };
}

function crudAllActions(state: RolesOrgActionsState, dataPayLoad: any): RolesOrgActionsState {
  const data = state.rolesPermissions.groupsResourceType;

  Object.entries(data).forEach(([key, value]) => {
    if (key) {
      const dataResource = Object(value);
      const keysResource = Object.keys(Object(value));
      const valuesResource = Object.values(Object(value));

      for (let i = 0; i <= keysResource.length; i += 1) {
        if (Object.prototype.hasOwnProperty.call(valuesResource, i)) {
          if (dataResource[i].action_type === dataPayLoad.actionType) {
            dataResource[i].allow = dataPayLoad.changeFlag;
          }
        }
      }
    }
  });
  let countCreate = state.create.selected;
  let countRead = state.read.selected;
  let countUpdate = state.update.selected;
  let countDelete = state.delete.selected;

  if (dataPayLoad.changeFlag === false && dataPayLoad.actionType === 'create') {
    countCreate = 0;
  }
  if (dataPayLoad.changeFlag === true && dataPayLoad.actionType === 'create') {
    countCreate = state.create.total;
  }

  if (dataPayLoad.changeFlag === false && dataPayLoad.actionType === 'read') {
    countRead = 0;
  }
  if (dataPayLoad.changeFlag === true && dataPayLoad.actionType === 'read') {
    countRead = state.read.total;
  }

  if (dataPayLoad.changeFlag === false && dataPayLoad.actionType === 'update') {
    countUpdate = 0;
  }
  if (dataPayLoad.changeFlag === true && dataPayLoad.actionType === 'update') {
    countUpdate = state.update.total;
  }

  if (dataPayLoad.changeFlag === false && dataPayLoad.actionType === 'delete') {
    countDelete = 0;
  }
  if (dataPayLoad.changeFlag === true && dataPayLoad.actionType === 'delete') {
    countDelete = state.delete.total;
  }

  return {
    ...state,
    rolesPermissions: {
      edit: state.rolesPermissions.edit,
      groupsResourceType: data
    },
    create: {
      total: state.create.total,
      selected: countCreate
    },
    read: {
      total: state.read.total,
      selected: countRead
    },
    update: {
      total: state.update.total,
      selected: countUpdate
    },
    delete: {
      total: state.delete.total,
      selected: countDelete
    }
  };
}

function selectAllActions(state: RolesOrgActionsState, dataPayLoad: any): RolesOrgActionsState {
  const data = state.rolesPermissions.groupsResourceType;
  let countCreate = state.create.selected;
  let countRead = state.read.selected;
  let countUpdate = state.update.selected;
  let countDelete = state.delete.selected;

  Object.entries(data).forEach(([key, value]) => {
    if (key) {
      const dataResource = Object(value);
      const keysResource = Object.keys(Object(value));
      const valuesResource = Object.values(Object(value));

      for (let i = 0; i <= keysResource.length; i += 1) {
        if (Object.prototype.hasOwnProperty.call(valuesResource, i)) {
          if (dataResource[i].uuid === dataPayLoad.id) {
            dataResource[i].allow = dataPayLoad.flag;

            if (dataResource[i].action_type === 'create' && dataPayLoad.flag === true) {
              countCreate += 1;
            }

            if (dataResource[i].action_type === 'create' && dataPayLoad.flag === false) {
              countCreate -= 1;
            }

            if (dataResource[i].action_type === 'read' && dataPayLoad.flag === true) {
              countRead += 1;
            }

            if (dataResource[i].action_type === 'read' && dataPayLoad.flag === false) {
              countRead -= 1;
            }

            if (dataResource[i].action_type === 'update' && dataPayLoad.flag === true) {
              countUpdate += 1;
            }

            if (dataResource[i].action_type === 'update' && dataPayLoad.flag === false) {
              countUpdate -= 1;
            }

            if (dataResource[i].action_type === 'delete' && dataPayLoad.flag === true) {
              countDelete += 1;
            }

            if (dataResource[i].action_type === 'delete' && dataPayLoad.flag === false) {
              countDelete -= 1;
            }
          }
        }
      }
    }
  });

  return {
    ...state,
    rolesPermissions: {
      edit: state.rolesPermissions.edit,
      groupsResourceType: data
    },

    create: {
      total: state.create.total,
      selected: countCreate
    },
    read: {
      total: state.read.total,
      selected: countRead
    },
    update: {
      total: state.update.total,
      selected: countUpdate
    },
    delete: {
      total: state.delete.total,
      selected: countDelete
    }
  };
}

export default function rolesActionsOrgReducer(
  state: RolesOrgActionsState = initialState,
  action: AnyAction
): RolesOrgActionsState {
  switch (action.type) {
    case ACTION_TYPE.FETCH_ACTIONS_ORG:
      return fetchRolesOrgActions(state);
    case ACTION_TYPE.FETCH_ACTIONS_SUCCESS:
      return fetchRolesOrgActionsSuccess(state, action.payload);
    case ACTION_TYPE.FETCH_ACTIONS_FAILURE:
      return fetchRolesOrgActionsFailure(state);
    case ACTION_TYPE.FETCH_ACTIONS_RESET:
      return resetRolesOrgActions(state);
    case ACTION_TYPE.FETCH_ACTIONS_ROLE:
      return fetchRolesActions(state);
    case ACTION_TYPE.FETCH_ACTIONS_ROLE_SUCCESS:
      return fetchRolesActionsSuccess(state, action.payload);
    case ACTION_TYPE.FETCH_ACTIONS_ROLE_FAILURE:
      return fetchRolesActionsFailure(state);
    case ACTION_TYPE.FETCH_ACTIONS_ROLE_RESET:
      return resetRolesActions(state);
    case ACTION_TYPE.RESOURCE_TYPE:
      return resourceTypeAction(state, action.payload);
    case ACTION_TYPE.EDIT_ROLES:
      return editRolesActions(state);
    case ACTION_TYPE.CRUD_ALL_ROLES:
      return crudAllActions(state, action.payload);
    case ACTION_TYPE.SELECT_ALL_ROLES:
      return selectAllActions(state, action.payload);
    default:
      return state;
  }
}
