/**
 * Register Page Style
 * @author mahesh.kedari@shorelineiot.com
 * Updated By Swapnil
 */
import makeStyles from '@mui/styles/makeStyles';
/**
 *
 */
export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'auto'
  },
  divider: {
    margin: '3% 5.8%'
  }
}));
