/**
 * Solid Gauge Settings
 * @author mahesh.kedari@shorelineiot.com
 */
import highchartsMore from 'highcharts/highcharts-more.js';
import Highcharts from 'highcharts';
/**
 *
 */
interface Stop {
  value: number;
  color: string;
}

highchartsMore(Highcharts);
/**
 *
 */
export interface GaugeSettings {
  min: number;
  max: number;
  unit: string;
  range: Array<Stop>;
}

export function parseGaugeSettings(settings: GaugeSettings): Highcharts.Options {
  const { min = 0, max = 100, unit, range } = settings;

  const stops: Array<[number, string]> = range?.map((stop: Stop) => [stop.value, stop.color]);
  return {
    chart: {
      type: 'gauge',
      plotBorderWidth: 0,
      plotShadow: false,
      height: '73%'
    },

    title: {
      text: ''
    },
    credits: {
      enabled: false
    },
    pane: {
      startAngle: -150,
      endAngle: 150,
      background: [
        {
          backgroundColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1
            },
            stops: [
              [0, '#FFF'],
              [1, '#333']
            ]
          },
          borderWidth: 0,
          outerRadius: '109%'
        },
        {
          backgroundColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1
            },
            stops: [
              [0, '#333'],
              [1, '#FFF']
            ]
          },
          borderWidth: 1,
          outerRadius: '107%'
        },
        {},
        {
          backgroundColor: '#DDD',
          borderWidth: 0,
          outerRadius: '105%',
          innerRadius: '103%'
        }
      ]
    },

    yAxis: {
      min,
      max,
      minorTickInterval: 'auto',
      minorTickWidth: 1,
      minorTickLength: 5,
      minorTickPosition: 'inside',
      minorTickColor: '#666',

      tickPixelInterval: 30,
      tickWidth: 2,
      tickPosition: 'inside',
      tickLength: 10,
      tickColor: '#666',
      labels: {
        step: 2,
        rotation: 0
      },
      title: {
        text: unit
      },
      plotBands: [
        {
          from: 0,
          to: 5,
          color: '#f1c40f' // yello
        },
        {
          from: 5,
          to: 85,
          color: '#27ae60' // green
        },
        {
          from: 85,
          to: 100,
          color: '#ed4f55' // red
        }
      ]
    },
    series: [
      {
        type: 'gauge',
        name: 'Availability',
        /* dataLabels: {
          format: `<div style="text-align:center">
              <span style="font-size:25px"><b>{y}</b></span><br/>
              <span style="font-size:12px;opacity:0.4">${unit || '%'}</span>
            </div>`,
        }, */
        tooltip: {
          valueSuffix: ' %'
        }
      }
    ]
  };
}
