export enum UpdateWidgetSettingStatus {
  INIT,
  IN_PROGRESS,
  READY,
  ERROR
}
/**
 *
 */
export interface UpdateWidgetSettingState {
  status: UpdateWidgetSettingStatus;
  widgetSettings?: any;
}
