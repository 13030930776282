import { skipToken } from '@reduxjs/toolkit/query';
import {
  useFetchFeaturesQuery,
  useFetchOrgFeaturesQuery,
  useUpdateOrgFeaturesMutation
} from './featureConfigurations.api';
import { FetchFeaturesListArgs } from './featureConfigurations.types';

export const useFetchFeaturesList = (args: FetchFeaturesListArgs) => {
  const {
    data: featuresList,
    isFetching: isFeaturesListFetching,
    isSuccess: isFeaturesListSuccess,
    isError: isFeaturesListError
  } = useFetchFeaturesQuery(args);
  return { featuresList, isFeaturesListFetching, isFeaturesListSuccess, isFeaturesListError };
};

export const useFetchOrgFeaturesList = (args: FetchFeaturesListArgs | typeof skipToken) => {
  const {
    data: orgFeaturesList,
    isFetching: isOrgFeaturesListFetching,
    isSuccess: isOrgFeaturesListSuccess,
    isError: isOrgFeaturesListError
  } = useFetchOrgFeaturesQuery(args);
  return {
    orgFeaturesList,
    isOrgFeaturesListFetching,
    isOrgFeaturesListSuccess,
    isOrgFeaturesListError
  };
};

export const useUpdateOrgFeatures = () => {
  const [
    updateOrgFeatures,
    { data: updateOrgData, isSuccess: updateOrgSuccess, isError: updateOrgError }
  ] = useUpdateOrgFeaturesMutation();
  return { updateOrgFeatures, updateOrgData, updateOrgSuccess, updateOrgError };
};
