import { APISERVICES } from '../../../framework';
import { FetchTourArgs } from './tour.types';
import { TOUR_TAG } from './tour.types';

import { buildQuery } from '../../../framework/lib/rtk/RTK.utils';
import { HTTP_METHOD } from '../../../framework/services/auth.service';

export function buildTourConfigApiEndpoint(slug: string) {
  return `orgs/${slug}/user_tour` as const;
}
/**
 *
 * @param args
 * @returns
 */
export function fetchTourConfigProperties(args: FetchTourArgs) {
  return buildQuery({
    apiEndpoint: buildTourConfigApiEndpoint(args.slug),
    method: HTTP_METHOD.GET,
    service: APISERVICES.HOST_API,
    actionKey: TOUR_TAG.TOUR
  });
}

/**
 *
 * @param args
 * @returns
 */
export function updateTourConfigQuery(args: FetchTourArgs) {
  return buildQuery({
    apiEndpoint: buildTourConfigApiEndpoint(args.slug),
    method: HTTP_METHOD.PUT,
    service: APISERVICES.HOST_API,
    actionKey: TOUR_TAG.TOUR,
    body: args.payload
  });
}
