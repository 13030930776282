export const { VITE_BASE_PATH } = import.meta.env;
export const { VITE_AUTH_REGION } = import.meta.env;
export const { VITE_AUTH_USER_POOL_ID } = import.meta.env;
export const { VITE_AUTH_USER_POOL_WEB_CLIENT } = import.meta.env;
export const { VITE_AUTH_USER_POOL_WEB_CLIENT_SECRET } = import.meta.env;
export const { VITE_IDENTITY_POOL_ID } = import.meta.env;
export const { VITE_AUTH_USER_POOL_DOMAIN } = import.meta.env;
export const { VITE_MQTT_ID } = import.meta.env;
export const { VITE_HOST_URL } = import.meta.env;
export const { VITE_DEVICE_URL } = import.meta.env;
export const { VITE_EVAL_EXPR_URL } = import.meta.env;
export const { VITE_ACTIVITY_LOGS_URL } = import.meta.env;
export const { VITE_CHAT_URL } = import.meta.env;
export const { VITE_EVAL_EXPR_API } = import.meta.env;
export const { VITE_HOST_API } = import.meta.env;
export const { VITE_DEVICE_API } = import.meta.env;
export const { VITE_ACTIVITY_LOGS_API } = import.meta.env;
export const { VITE_CHAT_API } = import.meta.env;
export const { VITE_GA_TRACKING_ID } = import.meta.env;
export const { VITE_VA_URL } = import.meta.env;
export const { VITE_VA_API } = import.meta.env;
export const { VITE_CUBEJS_API_TOKEN } = import.meta.env;
export const { VITE_CUBEJS_API_URL } = import.meta.env;
export const { VITE_HELPDESK_URL_GOOGLE_SSO } = import.meta.env;
export const { VITE_HELPDESK_URL_DCP_SSO } = import.meta.env;
export const { VITE_HELPDESK_URL_GE_SSO } = import.meta.env;
export const { VITE_HELPDESK_URL_NON_SSO } = import.meta.env;
export const { VITE_ASSET_META_BUCKET_URL } = import.meta.env;
export const { VITE_ENABLE_DEV_WEBSOCKET } = import.meta.env;
export const { VITE_BUGSNAG_KEY } = import.meta.env;
