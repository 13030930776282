/**
 * Anomaly Templates Reducer
 * @author aditya.bhadange@shorelineiot.com
 */
import { combineReducers } from 'redux';
import { createAnomalyTemplate } from './create-anomaly-template';
import { anomalyTemplatesList } from './anomaly-templates-list';
import { anomalyTemplateDetails } from './anomaly-templates-details';
import { deleteAnomalyTemplateReducer } from './delete-anomaly-template';
/**
 *
 */
const anomalyTemplates = combineReducers({
  anomalyTemplatesList,
  anomalyTemplateDetails,
  createAnomalyTemplate,
  deleteAnomalyTemplateReducer
});
/**
 *
 */
export default anomalyTemplates;
