/**
 * Login Helper functions
 * @author mahesh.kedari@shorelineiot.com
 */
import { AnyAction } from 'redux';
import { LoginStateType, inviteUserDataType } from '../types/LoginStateType';

export function setUserEmail(state: any, data: inviteUserDataType): LoginStateType {
  return {
    ...state,
    user: data
  };
}
/**
 *
 */
export function onLoginProgress(state: any): LoginStateType {
  return {
    ...state,
    isProcessing: true,
    login: [],
    isLoggedIn: false,
    messages: []
  };
}
/**
 *
 * @param state
 * @param action
 */
export function onLoginFailure(state: any, action: AnyAction): LoginStateType {
  return {
    ...state,
    status: 'ERROR',
    messages: [
      {
        type: 'error',
        errorCode: action.error.name,
        message: action.error.message
      }
    ],
    isLoggingIn: false,
    isProcessing: false
  };
}
/**
 *
 * @param state
 * @param action
 */
export function onLoginSuccess(state: any, action: AnyAction): LoginStateType {
  return {
    ...state,
    status: 'LOGGED_IN',
    isLoggingIn: false,
    session: action.data,
    isLoggedIn: true,
    isProcessing: false
  };
}
