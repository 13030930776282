import { TreeDataType } from '../../../../../../framework/types/MultiSelectDropdownTypes';

export interface FetchOrphanGroupArgs {
  slug: string;
}

export interface AssociateUserToGroupArgs {
  slug: string;
  groupId: string;
  payload: {
    associate: string[];
    dissociate: string[];
  };
}

export interface OrphanGroup {
  id: number;
  name: string;
}
export const GROUP_TAG = {
  ORPHAN_GROUP: 'ORPHAN_GROUP'
};

export interface UseQueryOptions {
  /**
   * Prevents a query from automatically running.
   * @default `false`
   */
  skip?: boolean;
}

export interface UserTreeNode extends TreeDataType {
  title: string;
  value: string;
  children?: UserTreeNode[];
}

export interface UserOptions {
  value: string;
  title: string;
  disabled?: boolean;
  children?: UserOptions[];
}
export interface GroupTreeNode {
  title: JSX.Element | string;
  rawName: string;
  children?: GroupTreeNode[];
  key: string;
  associatedUsers: string[];
  userOptions: {
    value: string;
    title: string;
    disabled?: boolean;
    children?: UserOptions[];
  }[];
  groupId: string;
}
