/**
 * Fill Missing Date Option in Pivot
 * @author mahesh.kedari@shorelineiot.com
 */
import { Checkbox } from '@mui/material';
import React, { ReactElement } from 'react';
import TranslateText from '../../../../i18n/TranslateText';

interface Props {
  fillMissingDates: boolean;
  handleUpdate: (newValue: boolean) => void;
}
/**
 *
 * @param param0
 * @returns
 */
export default function SLMissingDates({ fillMissingDates, handleUpdate }: Props): ReactElement {
  /**
   *
   */
  const handleFillMissingDatesUpdate = () => {
    handleUpdate(!fillMissingDates);
  };
  return (
    <>
      <Checkbox checked={fillMissingDates} onChange={handleFillMissingDatesUpdate} />
      {TranslateText('fill_missing_dates', 'Fill Missing Dates')}
    </>
  );
}
