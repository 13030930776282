import { AnyAction } from 'redux';
import * as ACTION_TYPES from './getAutoNarrowbandList.actionTypes';

export const getAutoNarrowbandListProgress = (
  slug: any,
  deviceId: string | number,
  componentId: number
): AnyAction => {
  return {
    type: ACTION_TYPES.GET_AUTO_NARROWBAND_LIST,
    payload: {
      slug,
      deviceId,
      componentId
    }
  };
};
/**
 *
 * @param data
 */
export const getAutoNarrowbandListSuccess = (data: any, rawNarrowbands: any): AnyAction => {
  // returns the action type
  return {
    type: ACTION_TYPES.GET_AUTO_NARROWBAND_LIST_SUCCESS,
    data,
    rawNarrowbands
  };
};
/**
 *
 * @param error
 */
export const getAutoNarrowbandListFailure = (error: any): AnyAction => {
  // returns the action type
  return {
    type: ACTION_TYPES.GET_AUTO_NARROWBAND_LIST_FAILURE,
    error
  };
};
/**
 *
 * @param data
 */

export const getAutoNarrowbandListReset = (): AnyAction => {
  return {
    type: ACTION_TYPES.GET_AUTO_NARROWBAND_LIST_RESET
  };
};

export const setNarrowbandStatus = (data: boolean): AnyAction => {
  return {
    type: ACTION_TYPES.SET_NARROWBAND_STATUS,
    data
  };
};

export const getAutoNarrowbandTableListProgress = (
  slug: any,
  deviceId: string | number,
  componentId: number
): AnyAction => {
  return {
    type: ACTION_TYPES.GET_AUTO_NARROWBAND_TABLE_LIST,
    payload: {
      slug,
      deviceId,
      componentId
    }
  };
};

export const getAutoNarrowbandTableListSuccess = (data: any, rawNarrowbands: any): AnyAction => {
  return {
    type: ACTION_TYPES.GET_AUTO_NARROWBAND_TABLE_LIST_SUCCESS,
    data,
    rawNarrowbands
  };
};

export const getAutoNarrowbandTableListFailure = (error: any): AnyAction => {
  return {
    type: ACTION_TYPES.GET_AUTO_NARROWBAND_TABLE_LIST_FAILURE,
    error
  };
};

export const getAutoNarrowbandTableListReset = (): AnyAction => {
  return {
    type: ACTION_TYPES.GET_AUTO_NARROWBAND_TABLE_LIST_RESET
  };
};
