/**
 * deletePersonalization Reducer
 * @author prerna.more@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTION_TYPE from './deletePersonalizationSettings.actionTypes';
import {
  deletePersonalizeSettingsState,
  DELETE_PERSONALIZE_SETTINGS_STATUS
} from './deletePersonalizeSettingsState';

const initialState: deletePersonalizeSettingsState = {
  status: DELETE_PERSONALIZE_SETTINGS_STATUS.INIT
};

function deletePersonalizationInfo(
  state: deletePersonalizeSettingsState
): deletePersonalizeSettingsState {
  return {
    ...state,
    status: DELETE_PERSONALIZE_SETTINGS_STATUS.FETCHING
  };
}

function deletePersonalizationInfoSuccess(
  state: deletePersonalizeSettingsState
): deletePersonalizeSettingsState {
  return {
    ...state,
    status: DELETE_PERSONALIZE_SETTINGS_STATUS.READY
  };
}

function deletePersonalizationInfoFailure(
  state: deletePersonalizeSettingsState
): deletePersonalizeSettingsState {
  return {
    ...state,
    status: DELETE_PERSONALIZE_SETTINGS_STATUS.ERROR
  };
}

function resetDeletePersonalisationInfo() {
  return {
    ...initialState
  };
}

export default function deletePersonalizeSettingsReducer(
  state: deletePersonalizeSettingsState = initialState,
  action: AnyAction
): deletePersonalizeSettingsState {
  switch (action.type) {
    case ACTION_TYPE.DELETE_THEMES:
      return deletePersonalizationInfo(state);
    case ACTION_TYPE.DELETE_THEMES_SUCCESS:
      return deletePersonalizationInfoSuccess(state);
    case ACTION_TYPE.DELETE_THEMES_FAILURE:
      return deletePersonalizationInfoFailure(state);
    case ACTION_TYPE.RESET_THEME:
      return resetDeletePersonalisationInfo();
    default:
      return state;
  }
}
