/**
 * Breadcrumb
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { useRef } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link, { LinkProps } from '@mui/material/Link';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import RightIcon from '@mui/icons-material/ChevronRight';
import { useSlugContext } from '../../lib/slugContext';
import { useStyles } from './breadcrumb.styles';
import { BreadcrumbType } from './types/breadcrumbTypes';
import {
  useBreadcrumbState,
  useShouldMoveBreadcrumbToRightState
} from './breadcrumb.selectorHooks';
import { Skeleton } from '@mui/material';

/**
 *
 */
interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}
/**
 *
 * @param props
 */
const LinkRouter = (props: LinkRouterProps) => {
  return <Link {...props} component={RouterLink as any} />;
};

const BreadcrumbSkeleton = () => {
  return <Skeleton variant="text" width={80} />;
};

/**
 *
 */
const SLBreadcrumb: React.FC = () => {
  const location = useLocation();
  const classes = useStyles();
  const { slug } = useSlugContext();
  const ref = useRef<any>(null);

  const SINGLE_LINE_HEIGHT = 25;
  const breadcrumbsList: Array<BreadcrumbType> = useBreadcrumbState();
  const shouldMoveToRight = useShouldMoveBreadcrumbToRightState();

  const getPath = (index: number) => {
    const subset = breadcrumbsList.slice(0, index + 1);
    const path = subset.reduce((accumulator: string, breadcrumb: BreadcrumbType) => {
      if (breadcrumb?.isAbsolute) {
        return `${breadcrumb.link}`;
      }
      if (breadcrumb?.concatenate) {
        return `${accumulator}${breadcrumb?.concatenate}${breadcrumb.link}`;
      }
      return `${accumulator}/${breadcrumb.link}`;
    }, '');
    return path;
  };

  return (
    <Breadcrumbs
      className={`${
        ref?.current?.parentElement?.clientHeight > SINGLE_LINE_HEIGHT &&
        breadcrumbsList?.length > 3
          ? classes.collapsedBreadcrumbs
          : classes.breadcrumbs
      } ${shouldMoveToRight ? classes.breadcrumbsMoveToRight : ''}`}
      ref={ref}
      separator={<RightIcon />}
      aria-label="breadcrumb">
      {breadcrumbsList?.map((breadcrumb, index) => {
        const last = index === breadcrumbsList?.length - 1;
        const to = `/${slug}/app${getPath(index)}`;
        const { isLoading, link } = breadcrumb;

        return last ? (
          <span key={to}>{isLoading ? <BreadcrumbSkeleton /> : breadcrumb.title}</span>
        ) : isLoading ? (
          <BreadcrumbSkeleton />
        ) : link ? (
          <LinkRouter underline="hover" to={to} key={to}>
            {breadcrumb.title}
          </LinkRouter>
        ) : (
          <span key={to}>{breadcrumb.title}</span>
        );
      })}
      {JSON.stringify(location)}
    </Breadcrumbs>
  );
};

export default SLBreadcrumb;
