import { AnyAction } from 'redux';
import { FETCH_TAGS_LIST_STATUS } from '../types/TagsListStatus';
import * as ACTION_TYPE from '../actions/alerts.actionTypes';
import { TagsListState } from '../types/TagsListState';

const initialState: TagsListState = {
  status: FETCH_TAGS_LIST_STATUS.INIT,
  tags: []
};

function fetchTagsListProgress(state: TagsListState): TagsListState {
  return {
    ...state,
    status: FETCH_TAGS_LIST_STATUS.PROGRESS
  };
}
function fetchTagsListSuccess(state: TagsListState, data: any): TagsListState {
  return {
    ...state,
    tags: data,
    status: FETCH_TAGS_LIST_STATUS.SUCCESS
  };
}
/**
 *
 * @param state
 */
function fetchTagsListFailure(state: TagsListState): TagsListState {
  return {
    ...state,
    status: FETCH_TAGS_LIST_STATUS.ERROR
  };
}

export default function tagsListReducer(
  state: TagsListState = initialState,
  action: AnyAction
): TagsListState {
  switch (action.type) {
    case ACTION_TYPE.FETCH_TAGS_LIST_PROGRESS:
      return fetchTagsListProgress(state);
    case ACTION_TYPE.FETCH_TAGS_LIST_SUCCESSS:
      return fetchTagsListSuccess(state, action.payload);
    case ACTION_TYPE.FETCH_TAGS_LIST_FAILURE:
      return fetchTagsListFailure(state);
    default:
      return state;
  }
}
