import {
  FormControl,
  FormControlLabel,
  Grid,
  RadioGroup,
  Typography,
  Radio,
  Divider,
  TextField,
  Checkbox
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useStyles } from './dialWidget.styles';
import {
  CompleteDialWidgetSettingsType,
  dialWidgetChartSettings,
  DialWidgetSettingsType,
  DISPLAY_RANGE_SELECTION_OPTIONS,
  DisplayRangeSelectionOptions
} from './dialWidgetConstants';
import DialWidgetColorConfigurator from './DialWidgetColorConfigurator';
import { DASHBOARD_ITEM_TYPE } from '../../dashboard-widgets/types/DashboardItemType';
import TranslateText from '../../../../i18n/TranslateText';

const DialWidgetEditor = ({
  handleSubmitForm,
  defaultValues
}: {
  handleSubmitForm: (value: any) => void;
  defaultValues: DialWidgetSettingsType;
}) => {
  const classes = useStyles();

  const [settings, setSettings] = useState<CompleteDialWidgetSettingsType>({
    chart_type: DASHBOARD_ITEM_TYPE.DIAL,
    chartSettings:
      Object.keys(defaultValues).length > 0 ? defaultValues : { ...dialWidgetChartSettings },
    positionMeta: [0, 0, 7, 8, 0, 0]
  });

  const [displayRangeSelection, setDisplayRangeSelection] = useState<DisplayRangeSelectionOptions>(
    settings?.chartSettings?.displayRangeSelection
      ? settings.chartSettings.displayRangeSelection
      : DISPLAY_RANGE_SELECTION_OPTIONS.AUTO
  );

  const [showAlarm, setShowAlarm] = useState(
    settings?.chartSettings?.showAlarm !== undefined ? settings.chartSettings.showAlarm : true
  );

  const [min, setMin] = useState<string | undefined>(
    settings?.chartSettings?.min !== undefined ? String(settings.chartSettings.min) : ''
  );
  const [max, setMax] = useState<string | undefined>(
    settings?.chartSettings?.max !== undefined ? String(settings.chartSettings.max) : ''
  );

  const handleDisplayRangeSelectionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selection = event.target.value as DisplayRangeSelectionOptions;
    setDisplayRangeSelection(selection);
    const newChartSettings = { ...settings.chartSettings, displayRangeSelection: selection };
    if (selection === DISPLAY_RANGE_SELECTION_OPTIONS.AUTO) {
      if ('showAlarm' in newChartSettings) {
        delete newChartSettings.showAlarm;
      }
      if ('min' in newChartSettings) {
        delete newChartSettings.min;
      }
      if ('max' in newChartSettings) {
        delete newChartSettings.max;
      }
    } else if (selection === DISPLAY_RANGE_SELECTION_OPTIONS.MANUAL) {
      newChartSettings.showAlarm = showAlarm;
      newChartSettings.min = min === '' ? undefined : Number(min);
      newChartSettings.max = max === '' ? undefined : Number(max);
    }
    const updatedSettings = { ...settings, chartSettings: newChartSettings };
    setSettings(updatedSettings);
    handleSubmitForm(updatedSettings);
  };

  const handleAlarmSettingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowAlarm(event.target.checked);
  };

  const handleMinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMin(event.target.value);
  };

  const handleMaxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMax(event.target.value);
  };

  const getMinError = () => {
    if (Number.isNaN(Number(min))) {
      return true;
    } else if (min && max && Number(max) && Number(min) && Number(min) >= Number(max)) {
      return true;
    } else {
      return false;
    }
  };

  const getMaxError = () => {
    if (Number.isNaN(Number(max))) {
      return true;
    } else if (min && max && Number(min) && Number(max) && Number(max) <= Number(min)) {
      return true;
    } else {
      return false;
    }
  };

  const getMinHelperText = () => {
    if (Number.isNaN(Number(min))) {
      return 'Invalid Input';
    } else if (min && max && Number(max) && Number(min) && Number(min) >= Number(max)) {
      return 'Please enter a value < Max';
    }
    return '';
  };

  const getMaxHelperText = () => {
    if (Number.isNaN(Number(max))) {
      return 'Invalid Input';
    } else if (min && max && Number(min) && Number(max) && Number(max) <= Number(min)) {
      return 'Please enter a value > Min';
    }
    return '';
  };

  useEffect(() => {
    const newChartSettings = {
      ...settings.chartSettings,
      showAlarm,
      min: min === '' ? undefined : Number(min),
      max: max === '' ? undefined : Number(max)
    };
    const updatedSettings = { ...settings, chartSettings: newChartSettings };
    setSettings(updatedSettings);
    handleSubmitForm(updatedSettings);
  }, [showAlarm, min, max]);

  return (
    <FormControl>
      <Grid container className={classes.content}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography> {TranslateText('display_range', 'Display Range')}:</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <RadioGroup
            row
            onChange={handleDisplayRangeSelectionChange}
            defaultValue={displayRangeSelection}
            name="min-max-selection-group">
            <FormControlLabel
              value={DISPLAY_RANGE_SELECTION_OPTIONS.AUTO}
              control={<Radio />}
              label={TranslateText('auto', 'Auto')}
            />
            <FormControlLabel
              value={DISPLAY_RANGE_SELECTION_OPTIONS.MANUAL}
              control={<Radio />}
              label={TranslateText('manual', 'Manual')}
            />
          </RadioGroup>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Divider className={classes.divider} />
          {displayRangeSelection === DISPLAY_RANGE_SELECTION_OPTIONS.MANUAL && (
            <>
              <Grid container direction="row" justifyContent="space-between" spacing={2}>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                  <TextField
                    label={TranslateText('min_value', 'Min Value')}
                    value={min}
                    onChange={handleMinChange}
                    error={getMinError()}
                    helperText={getMinHelperText()}
                  />
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                  <TextField
                    label={TranslateText('max_value', 'Max Value')}
                    value={max}
                    onChange={handleMaxChange}
                    error={getMaxError()}
                    helperText={getMaxHelperText()}
                  />
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                  <FormControlLabel
                    control={
                      <Checkbox defaultChecked={showAlarm} onChange={handleAlarmSettingChange} />
                    }
                    label={TranslateText('show_limit_exceeded', 'Show Limit Exceeded')}
                  />
                </Grid>
              </Grid>
              <Divider className={classes.divider} />
            </>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <DialWidgetColorConfigurator
            handleSubmitForm={handleSubmitForm}
            settings={settings}
            setSettings={setSettings}
            min={min}
            max={max}
            displayRangeSelection={displayRangeSelection}
          />
        </Grid>
      </Grid>
    </FormControl>
  );
};

export default DialWidgetEditor;
