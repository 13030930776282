/**
 * Organisation Permissions
 * @author mahesh.kedari@shorelineiot.com
 */
export const ORGANISATION_PERMISSIONS = {
  VIEW_LIST: 'view organization',
  UPDATE: 'update organization',
  VIEW_DETAILS: 'view_details organization',
  DELETE: 'delete organization',
  CREATE: 'create organization',
  CREATE_SUBORG: 'create sub_org'
};
