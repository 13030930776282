/**
 * VA Narrowbands Reducer
 * @author mahesh.kedari@shorelineiot.com
 */
import { AnyAction } from 'redux';
import {
  VANarrowbandsState,
  VA_NARROWBANDS_STATUS,
  MultiDeviceNarrowbandsListType,
  NormalizedNarrowbandsType,
  SelectedNarrowband,
  LatestRawNBType
} from './vaNarrowbands.stateType';
import * as ACTIONS from '../vibrationAnalysis.actionTypes';
import { AMPLITUDE_SELECTION, UnitsPayload } from '../va-units/vaUnit.stateType';
import { normalizeAllNarrowbands } from './narrowbandsNormaliser';
import {
  getDefaultCheckedNarrowbands,
  getDefaultSelectedNarrowband,
  parseNarrowbandData,
  getFrequencyUnitBasedValues,
  getAmplitudeSelectionBasedValues,
  getSelectedNarrowband,
  getCheckedNarrowbands,
  duplicateKeysForNB
} from './narrowbandsHelper';

/**
 *
 */
const initialState: VANarrowbandsState = {
  status: VA_NARROWBANDS_STATUS.INIT,
  narrowbands: [],
  selectedODR: 3200,
  latestNBStatus: VA_NARROWBANDS_STATUS.INIT,
  latestNarrowbandsForDevices: []
};
/**
 *
 * @param state
 * @returns
 */
function handleFetchNarrowbands(state: VANarrowbandsState): VANarrowbandsState {
  return {
    ...state,
    status: VA_NARROWBANDS_STATUS.FETCHING
  };
}
/**
 *
 * @param state
 * @param narrowbands
 * @returns
 */
function handleFetchNarrowbandsSuccess(
  state: VANarrowbandsState,
  payload: {
    rawNarrowbands: Array<MultiDeviceNarrowbandsListType>;
    units: UnitsPayload;
    selectedEvents: any;
    nbdpid?: string; // This is a narrowband ID coming from alarms page.
    // If user tries to navigate from Alarms to VA by clicking alarms object,
    // its narrowband ID gets passed through URL param to VA
    checkednb?: boolean;
  }
): VANarrowbandsState {
  const normalizedNarrowbands = normalizeAllNarrowbands(payload.rawNarrowbands);

  const newNormalizedNarrowbands = duplicateKeysForNB(
    normalizedNarrowbands,
    payload?.units.amplitudeSelection
  );
  const selectedNarrowband: SelectedNarrowband =
    state.selectedNarrowband ||
    getDefaultSelectedNarrowband(newNormalizedNarrowbands, payload.units, payload.nbdpid);

  const narrowbands = parseNarrowbandData(
    newNormalizedNarrowbands,
    payload.units,
    payload?.selectedEvents?.speed_hz,
    selectedNarrowband
  );

  // const newODR =
  //   selectedNarrowband && selectedNarrowband.nb_type !== "tve"
  //     ? selectedNarrowband.odr
  //     : state.selectedODR;
  return {
    ...state,
    status: VA_NARROWBANDS_STATUS.READY,
    rawNarrowbands: newNormalizedNarrowbands,
    selectedNarrowband,
    narrowbands
  };
}
function handleLatestFetchNarrowbandsSuccess(
  state: VANarrowbandsState,
  payload: {
    units: UnitsPayload;
    selectedEvents: { speed_hz: number };
    nbdpid?: string;
    // This is a narrowband ID coming from alarms page.
    // If user tries to navigate from Alarms to VA by clicking alarms object,
    // its narrowband ID gets passed through URL param to VA
    checkednb?: boolean;
    latestNarrowbandsForDevices: Array<MultiDeviceNarrowbandsListType>;
  }
): VANarrowbandsState {
  const latestDevicesNormalizedNBs = normalizeAllNarrowbands(payload.latestNarrowbandsForDevices);

  const newLatestNBsForDevices = duplicateKeysForNB(
    latestDevicesNormalizedNBs,
    payload?.units.amplitudeSelection
  );
  let checkedNarrowbands: NormalizedNarrowbandsType[] = [];
  if (state.checkedNarrowbands) {
    checkedNarrowbands = state.checkedNarrowbands;
  } else if (payload.checkednb !== false) {
    checkedNarrowbands = getDefaultCheckedNarrowbands(
      newLatestNBsForDevices,
      payload.units,
      payload.nbdpid
    );
  }

  const latestNarrowbandsForDevices = parseNarrowbandData(
    newLatestNBsForDevices,
    payload.units,
    payload?.selectedEvents?.speed_hz,
    null,
    checkedNarrowbands
  );

  return {
    ...state,
    latestNBStatus: VA_NARROWBANDS_STATUS.READY,
    checkedNarrowbands,
    latestNarrowbandsForDevices: latestNarrowbandsForDevices
  };
}
function handleSpeedUpdate(
  state: VANarrowbandsState,
  payload: {
    units: UnitsPayload;
    selectedEvents: any;
  }
): VANarrowbandsState {
  const rawNarrowbands: any = state?.rawNarrowbands;

  const latestNarrowbandsForDevices = state?.latestNarrowbandsForDevices;
  const selectedNarrowband: any = getSelectedNarrowband(
    rawNarrowbands,
    payload.units,
    state.selectedNarrowband
  );
  const checkedNarrowbands = getCheckedNarrowbands(
    latestNarrowbandsForDevices as unknown as NormalizedNarrowbandsType[],
    payload.units,
    state.checkedNarrowbands
  );
  const narrowbands = parseNarrowbandData(
    rawNarrowbands,
    payload.units,
    payload?.selectedEvents?.speed_hz,
    selectedNarrowband,
    checkedNarrowbands
  );
  return {
    ...state,
    status: VA_NARROWBANDS_STATUS.READY,
    rawNarrowbands,
    selectedNarrowband,
    checkedNarrowbands,
    narrowbands
  };
}

function handleNarrowbandsAfterEventUpdate(
  state: VANarrowbandsState,
  payload: {
    rawNarrowbands: Array<any>;
    units: UnitsPayload;
    selectedEvents: any;
  }
): VANarrowbandsState {
  const rawNarrowbands: any[] = normalizeAllNarrowbands(payload.rawNarrowbands);
  const newNormalizedNarrowbands = duplicateKeysForNB(
    rawNarrowbands,
    payload?.units.amplitudeSelection
  );
  const selectedNarrowband: any = getSelectedNarrowband(
    newNormalizedNarrowbands,
    payload.units,
    state.selectedNarrowband
  );
  const checkedNarrowbands = getCheckedNarrowbands(
    newNormalizedNarrowbands,
    payload.units,
    state.checkedNarrowbands
  );
  const narrowbands = parseNarrowbandData(
    newNormalizedNarrowbands,
    payload.units,
    payload?.selectedEvents?.speed_hz,
    selectedNarrowband,
    checkedNarrowbands
  );
  return {
    ...state,
    status: VA_NARROWBANDS_STATUS.READY,
    rawNarrowbands: newNormalizedNarrowbands,
    selectedNarrowband,
    narrowbands
  };
}

function handleFetchNarrowbandsFailure(state: VANarrowbandsState): VANarrowbandsState {
  return {
    ...state,
    status: VA_NARROWBANDS_STATUS.ERROR,
    rawNarrowbands: undefined,
    narrowbands: []
  };
}
function handleNarrowbandsReset(): VANarrowbandsState {
  return {
    ...initialState
  };
}
function handleUpdateCheckedNarrowbands(
  state: VANarrowbandsState,
  checkedNarrowband: any
): VANarrowbandsState {
  return {
    ...state,
    checkedNarrowbands: [...checkedNarrowband]
  };
}

function handleUpdateBulkCheckedNarrowbands(
  state: VANarrowbandsState,
  allCheckedNarrowbands: any[]
): VANarrowbandsState {
  return {
    ...state,
    checkedNarrowbands: allCheckedNarrowbands
  };
}
function changeOdrForNBs(state: VANarrowbandsState, odr: any): VANarrowbandsState {
  return {
    ...state,
    selectedODR: odr
  };
}
function handleUpdateSelectedNarrowband(state: VANarrowbandsState, payload: any) {
  const { selectedNarrowband, units, speedValue } = payload;
  const narrowbands = state.narrowbands.map((narrowband) => {
    return {
      ...narrowband,
      tableData: {
        ...narrowband.tableData,
        selected: narrowband.key === selectedNarrowband.key
      },
      ...getFrequencyUnitBasedValues(selectedNarrowband, units.frequencyUnit, speedValue)
    };
  });
  return {
    ...state,
    narrowbands,
    selectedNarrowband
  };
}

/**
 *
 * @param state
 * @param frequencyUnit
 */
function handleUpdateFrequencyUnit(
  state: VANarrowbandsState,
  { units }: { units: UnitsPayload }
): VANarrowbandsState {
  const rawNarrowbands: any[] = state.rawNarrowbands || []; //?.[units.motionUnitType]
  const narrowbands = state.narrowbands.map((narrowband) => {
    const selectedNarrowband: any = rawNarrowbands.find(
      (rawNarrowband: any) => rawNarrowband.key === narrowband.key
    );
    if (selectedNarrowband) {
      return {
        ...narrowband,
        ...getFrequencyUnitBasedValues(selectedNarrowband, units.frequencyUnit, units.speedValue)
      };
    }
    return narrowband;
  });

  const latestNarrowbandsForDevices = state.latestNarrowbandsForDevices?.map((narrowband) => {
    return {
      ...narrowband,
      ...getFrequencyUnitBasedValues(narrowband, units.frequencyUnit, units.speedValue)
    };
  });

  return {
    ...state,
    narrowbands,
    latestNarrowbandsForDevices
  };
}

/**
 *
 * @param state
 * @param frequencyUnit
 */
function handleUpdateAmplitudeSelection(
  state: VANarrowbandsState,
  { value }: { value: AMPLITUDE_SELECTION }
): VANarrowbandsState {
  const rawNarrowbands: Array<NormalizedNarrowbandsType> = state.rawNarrowbands || []; //?.[units.motionUnitType]
  const narrowbands = state.narrowbands.map((narrowband) => {
    const selectedNarrowband: NormalizedNarrowbandsType | undefined = rawNarrowbands.find(
      (rawNarrowband: NormalizedNarrowbandsType) => rawNarrowband.key === narrowband.key
    );
    return {
      ...narrowband,
      ...getAmplitudeSelectionBasedValues(selectedNarrowband, value)
    };
  });
  return {
    ...state,
    narrowbands
  };
}
/**
 *
 * @param state
 * @param action
 * @returns
 */
export default function VANarrowbandsReducer(
  state: VANarrowbandsState = initialState,
  action: AnyAction
): VANarrowbandsState {
  switch (action.type) {
    case ACTIONS.FETCH_NARROWBANDS:
      return handleFetchNarrowbands(state);
    case ACTIONS.FETCH_NARROWBANDS_SUCCESS:
      return handleFetchNarrowbandsSuccess(state, action.payload);
    case ACTIONS.FETCH_NARROWBANDS_FAILURE:
      return handleFetchNarrowbandsFailure(state);
    case ACTIONS.NARROWBANDS_AFTER_EVENT_UPDATE:
      return handleNarrowbandsAfterEventUpdate(state, action.payload);
    case ACTIONS.RESET_NARROWBANDS:
    case ACTIONS.UPDATE_DATAPOINT_SELECTION:
    case ACTIONS.RESET_VIBRATION_ANALYSIS:
      return handleNarrowbandsReset();
    case ACTIONS.UPDATE_CHECKED_NARROWBANDS:
      return handleUpdateCheckedNarrowbands(state, action.payload);
    case ACTIONS.UPDATE_BULK_CHECKED_NARROWBANDS:
      return handleUpdateBulkCheckedNarrowbands(state, action.payload);
    case ACTIONS.UPDATE_NARROWBANDS_SELECTION:
      return handleUpdateSelectedNarrowband(state, action.payload);
    case ACTIONS.UPDATE_FREQUENCY_UNIT:
      return handleUpdateFrequencyUnit(state, action.payload);
    case ACTIONS.RECALCULATE_NARROWBANDS:
      return handleSpeedUpdate(state, action.payload);
    case ACTIONS.UPDATE_AMPLITUDE_SELECTION:
      return handleUpdateAmplitudeSelection(state, action.payload);
    case ACTIONS.UPDATE_CPM_SUCCESS:
      return { ...state, status: VA_NARROWBANDS_STATUS.INIT };
    case ACTIONS.CHANGE_ODR_NARROWBANDS:
      return changeOdrForNBs(state, action.payload);

    case ACTIONS.FETCH_NARROWBAND_DATA_SUCCESS:
      return handleLatestFetchNarrowbandsSuccess(state, action.payload);
    default:
      return state;
  }
}
