/**
 * Create organization actions
 * @author ashwini.gawade@shorelineiot.com
 */

import * as ACTIONS from './orgCreate.actionType';
import { Organisation } from '../../types/Organisation';

export const createOrg = (payload: Organisation) => {
  return {
    type: ACTIONS.ORG_CREATE,
    payload
  };
};
/**
 * @param data response data
 * @returns data to be passed to reducer()
 */
export const createOrgSuccess = (data: any) => {
  return {
    type: ACTIONS.ORG_CREATE_SUCCESS,
    data
  };
};
/**
 *
 * @param err retrun from api call
 */
export const createOrgFailure = (err: any) => {
  return {
    type: ACTIONS.ORG_CREATE_FAILURE,
    error: err
  };
};

/**
 *
 */
export const resetCreateOrg = () => {
  return {
    type: ACTIONS.RESET_ORG_CREATE
  };
};
