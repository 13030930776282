export enum EXTERNAL_REPORTS_STATUS {
  INIT,
  FETCHING,
  READY,
  ERROR
}

export interface ExternalReportsState {
  status: EXTERNAL_REPORTS_STATUS;
  ExternalReportsData: any;
}
