/**
 * External reports Saga
 * @author prerna.more@shorelineiot.com
 */
import { put, takeEvery, call } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import * as actions from './externalReports.action';
import * as ACTION_TYPES from './externalReports.actionTypes';
import { GenericObject, httpGet, SafeSaga, APISERVICES } from '../../../../framework';
import { SagaIterator } from 'redux-saga';

function getDetails(data: any) {
  if (data.start && data.end) {
    return httpGet(
      `orgs/${data.slug}/ext_devices/statistics`,
      {
        start: data.start,
        end: data.end
      },
      APISERVICES.DEVICE_API
    );
  }

  return httpGet(`orgs/${data.slug}/ext_devices/statistics`, null, APISERVICES.DEVICE_API);
}

function* externalReportsSaga(action: AnyAction) {
  const response: GenericObject = yield call(getDetails, action.payload);
  yield put(actions.fetchExternalReportsSuccess(response));
}

function* errorHandler() {
  yield put(actions.fetchExternalReportsFailure());
}

export function* watchexternalReportsSagas(): SagaIterator {
  yield takeEvery(
    ACTION_TYPES.FETCH_EXTERNAL_REPORTS,
    SafeSaga(externalReportsSaga, ACTION_TYPES.FETCH_EXTERNAL_REPORTS, errorHandler)
  );
}
