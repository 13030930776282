/**
 * Framework Component
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { useState, useEffect, Suspense } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import ReactGA from 'react-ga4';
import {
  AccessControl,
  EmptyFunction,
  SLSidebar,
  openSidebar,
  useSlugContext
} from '../../framework';
import { useStyles } from '../../framework/framework.styles';
import { routes } from '../feature.routes';
import PageNotFound from '../page-not-found/PageNotFound';
import SidebarMenuList from '../../config/SidebarMenuList';
import SidebarHeader from './sidebar-options/SidebarHeader';
import CustomHeader from './header-options/CustomHeader';
import AsyncHandler from '../common/websocket/AsyncHandler';
import { useUserSelf, useUserSelfRole } from '../user-settings';
import { useDispatch } from 'react-redux';
import { useFetchTourConfig } from './tour/tour.hooks';
import { CircularProgress } from '@mui/material';

/**
 *
 */

function HomeLayout() {
  const theme = useTheme();
  const classes = useStyles();
  const { slug } = useSlugContext();

  /**
   * Fetching user tour config
   */
  useFetchTourConfig({ slug });

  const [permissions, setPermissions] = useState<Array<string>>([]);
  const location = useLocation();
  const isNoOrg: boolean = location.pathname === `/${slug}/app/org/no-org`;
  const allPermissions = useUserSelfRole();
  const dispatch = useDispatch();

  const userSelf: any = useUserSelf();

  useEffect(() => {
    if (allPermissions) {
      const permissionArray = allPermissions.map((permission: any) => permission.name);
      setPermissions(permissionArray);
    }
  }, [allPermissions]);
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });

  const handleSidebarOpen = () => {
    dispatch(openSidebar());
  };

  // Check for Organisation Slug. Fetch organisations if required
  useEffect(() => {
    if (slug && slug === 'org') {
      /* User has logged into application using default organisation 
      and local storage does not have org history */
      EmptyFunction();
    }
  }, []);

  useEffect(() => {
    // code to run after render goes here
    const { hostname } = document.location;
    if (hostname && hostname !== 'localhost') {
      ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
      ReactGA.set({ userId: userSelf?.self?.email });
    }
  });
  return (
    <>
      <AsyncHandler />
      <AccessControl.Provider value={{ permissions, setPermissions }}>
        <div className={classes.root}>
          <CssBaseline />
          {/* // If URL is 'no-org', then don't render CustomHeader & SLSidebar */}
          {!isNoOrg && <CustomHeader isDesktop={isDesktop} onSidebarOpen={handleSidebarOpen} />}
          <div className={classes.mainPanel}>
            {!isNoOrg && (
              <SLSidebar
                variant={isDesktop ? 'persistent' : 'temporary'}
                header={<SidebarHeader />}>
                <SidebarMenuList />
              </SLSidebar>
            )}
            <Suspense fallback={<CircularProgress />}>
              <Routes>
                {routes.map((route) => (
                  <Route key={route.id} path={route.path} element={<route.component />} />
                ))}
                <Route index element={<Navigate to="custom-dashboard" replace />} />
                <Route path="*" element={<PageNotFound />} />
              </Routes>
            </Suspense>
          </div>
        </div>
      </AccessControl.Provider>
    </>
  );
}

export default HomeLayout;
