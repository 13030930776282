/**
 * Order Group for Queries
 * @author mahesh.kedari@shorelineiot.com
 */
import { QueryOrder, TOrderMember } from '@cubejs-client/core';
import React from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { SLPopover } from '../../popover/SLPopover';
import DraggableOrderItem from './DraggableOrderItem';

interface Props {
  orderMembers: Array<TOrderMember>;
  onOrderChange: (memberId: string, order: QueryOrder | 'none') => void;
  onReorder: (sourceIndex: number, destinationIndex: number) => void;
}

export default function SLOrderGroup({ orderMembers, onOrderChange, onReorder }: Props) {
  return (
    <SLPopover label="Order">
      <DragDropContext
        onDragEnd={({ source, destination }: DropResult) => {
          onReorder(source && source.index, (destination && destination.index) || 0);
        }}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div data-testid="order-popover" ref={provided.innerRef} {...provided.droppableProps}>
              {orderMembers.map(({ id, title, order }, index) => (
                <DraggableOrderItem
                  key={id}
                  id={id}
                  index={index}
                  order={order}
                  onOrderChange={onOrderChange}>
                  {title}
                </DraggableOrderItem>
              ))}

              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </SLPopover>
  );
}
