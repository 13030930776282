/**
 * get widget settings ACTIONs
 * @author ashwini.gawade@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTION_TYPES from './getWidgetSetting.actionTypes';
/**
 *
 * @param data
 */
export const getWidgetSettings = (data: any): AnyAction => {
  // returns the action type
  return {
    type: ACTION_TYPES.GET_WIDGET_SETTINGS,
    payload: data
  };
};
/**
 *
 * @param data
 */
export const getWidgetSettingSuccess = (data: any): AnyAction => {
  return {
    type: ACTION_TYPES.GET_WIDGET_SETTINGS_SUCCESS,
    payload: data
  };
};
/**
 *
 */
export const getWidgetSettingFailure = (error: any): AnyAction => {
  // returns the action type
  return {
    type: ACTION_TYPES.GET_WIDGET_SETTINGS_FAILURE,
    error
  };
};

export const resetWidgetSettingState = (): AnyAction => {
  // returns the action type
  return {
    type: ACTION_TYPES.RESET_WIDGET_SETTINGS_STATE,
    payload: false
  };
};
