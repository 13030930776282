/**
 *
 */
import React, { PropsWithChildren, ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSlugContext } from '../../..';
import { getRedirectionPath } from './getRedirectionPath';
import { resolver } from './commonRedirection.helper';
import { useFetchDeviceGroups } from '../../../../features/device/store/device.hooks';

interface Props {
  redirection?: string;
  params?: string;
  rowData?: any;
  chartSettings: any;
}

export default function RedirectionView({
  redirection,
  params,
  rowData,
  chartSettings,
  children
}: PropsWithChildren<Props>): ReactElement {
  const { slug } = useSlugContext();
  const { deviceGroups } = useFetchDeviceGroups({
    slug
  });
  const navigate = useNavigate();
  const handleClick = () => {
    const path = getRedirectionPath(
      slug || '',
      redirection || '',
      resolver,
      params,
      rowData,
      chartSettings,
      deviceGroups
    );
    navigate(path);
  };

  return (
    <span onClick={handleClick} onKeyPress={handleClick} role="button" tabIndex={0}>
      {children}
    </span>
  );
}
