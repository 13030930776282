/**
 * Get topic Saga
 * @author mahesh.kedari@shorelineiot.com
 */
import { AnyAction } from 'redux';
import { takeEvery, call, put } from 'redux-saga/effects';
import { datapointActions, DATAPOINT_ACTIONS } from '../actions';
import {
  GenericObject,
  removeProgressFor,
  showProgressFor,
  httpPost,
  showToast,
  APISERVICES
} from '../../../../../../../framework';
import { SagaIterator } from 'redux-saga';

/**
 * get topics for each sensor
 */
function setData({ slug, deviceId, dpid, value }: any) {
  const payload = {
    request_type: 'c2d_set_sensor_value',
    payload: {
      dpid,
      value: value.value
    }
  };
  return httpPost(`orgs/${slug}/devices/${deviceId}/actions`, payload, APISERVICES.DEVICE_API);
}
/**
 *
 * @param action
 */
function* setDataSaga(action: AnyAction) {
  const { dpid } = action.payload;
  yield put(showProgressFor(`SET_DATA_${dpid}`));
  try {
    const resp: GenericObject = yield call(setData, action.payload);
    yield put(showToast('Value sent to device successfully', 'success'));
    yield put(datapointActions.setDataSuccess({ dpid, data: resp.data }));
  } catch (error: any) {
    yield put(showToast('Failed to send data to device', 'error', true));
    yield put(datapointActions.setDataFailure({}));
  } finally {
    yield put(removeProgressFor(`SET_DATA_${dpid}`));
  }
}
/**
 * Watcher saga for Fetch Topic Request
 */
export function* watchSetDataSaga(): SagaIterator {
  yield takeEvery(DATAPOINT_ACTIONS.SET_DATA, setDataSaga);
}
