/**
 * deletePersonalization State
 * @author prerna.more@shorelineiot.com
 */

export enum DELETE_PERSONALIZE_SETTINGS_STATUS {
  INIT,
  FETCHING,
  READY,
  ERROR,
  RESET
}
export interface deletePersonalizeSettingsState {
  status: DELETE_PERSONALIZE_SETTINGS_STATUS;
}
