import { AnyAction } from 'redux';
import * as ACTION_TYPES from './actions/forgotPassword.actionTypes';

export interface ForgotPasswordStateType {
  isSetNewPasswordProgress: boolean;
  isSetNewPasswordSuccess: boolean;
  isSetNewPasswordFailure: boolean;
}

const initialState: ForgotPasswordStateType = {
  isSetNewPasswordProgress: false,
  isSetNewPasswordSuccess: false,
  isSetNewPasswordFailure: false
};

export default function forgotPasswordSubmit(state = initialState, action: AnyAction) {
  switch (action.type) {
    case ACTION_TYPES.FORGOT_PASSWORD_SUBMIT_PROGRESS:
      return {
        ...state,
        isSetNewPasswordProgress: true
      };
    case ACTION_TYPES.FORGOT_PASSWORD_SUBMIT_SUCCESS:
      return {
        ...state,
        isSetNewPasswordProgress: false,
        isSetNewPasswordSuccess: true
      };
    case ACTION_TYPES.FORGOT_PASSWORD_SUBMIT_FAILURE:
      return {
        ...state,
        isSetNewPasswordProgress: false,
        isSetNewPasswordFailure: true
      };
    case ACTION_TYPES.FORGOT_PASSWORD_SUBMIT_RESET:
      return {
        isSetNewPasswordSuccess: false,
        isSetNewPasswordFailure: false
      };
    default:
      return state;
  }
}
