/**
 * Remove Datapoint Helper functions
 * @author mahesh.kedari@shorelineiot.com
 */
import { DatapointsListState } from '../types';
/**
 *
 * @param state
 */
export function onRemoveDatapoint(state: DatapointsListState): DatapointsListState {
  return {
    ...state
  };
}
/**
 *
 * @param state
 * @param datapointId
 */
export function onRemoveDatapointSuccess(state: DatapointsListState): DatapointsListState {
  // console.log(datapointId);
  /* const { [dpid]: value1, ...datapointConfigs } = state.datapointConfigs;
  const { [dpid]: value2, ...lastDataValue } = state.lastDataValue;
  const { [dpid]: value3, ...liveDataConfigs } = state.liveDataConfigs;
  const { [dpid]: value4, ...viewConfigs } = state.viewConfigs;
  return {
    ...state,
    datapointConfigs,
    lastDataValue,
    liveDataConfigs,
    viewConfigs,
  }; */
  return { ...state };
}
/**
 *
 * @param state
 * @param datapointId
 */
export function onRemoveDatapointFailure(state: DatapointsListState): DatapointsListState {
  return {
    ...state
  };
}
