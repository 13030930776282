/**
 * Alarms Export Reducers
 * @author prerna.more@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTION_TYPE from './alarmsExport.actionTypes';
import {
  ALARMS_EXPORT_STATUS,
  AlarmsExportState,
  ExportAlarmsFromWebsocketResponse
} from '../types/AlarmExport';

const initialState: AlarmsExportState = {
  status: ALARMS_EXPORT_STATUS.INIT,
  emailList: [],
  exportList: [],
  exportType: [],
  shouldDownloadFile: false
};

function handleFetchAlarmsExport(state: AlarmsExportState): AlarmsExportState {
  return {
    ...state,
    status: ALARMS_EXPORT_STATUS.INIT,
    shouldDownloadFile: true
  };
}

function handleFetchAlarmsExportSuccess(
  state: AlarmsExportState,
  exportList: any
): AlarmsExportState {
  return {
    ...state,
    status: ALARMS_EXPORT_STATUS.READY,
    exportList
  };
}

function handleFetchAlarmsExportFailure(state: AlarmsExportState): AlarmsExportState {
  return {
    ...state,
    status: ALARMS_EXPORT_STATUS.ERROR
  };
}

function onEmailChange(state: AlarmsExportState, data: any): AlarmsExportState {
  return {
    ...state,
    emailList: data
  };
}

function onExportTypeChange(state: AlarmsExportState, data: any): AlarmsExportState {
  return {
    ...state,
    exportType: data
  };
}

function onPerformAlarmsFileDownload(
  state: AlarmsExportState,
  webSocketData: ExportAlarmsFromWebsocketResponse
): AlarmsExportState {
  if (state.shouldDownloadFile && webSocketData.action === 'create') {
    const link = document.createElement('a');
    link.href = webSocketData.payload.download_link;
    link.click();
    link.remove();

    return {
      ...state,
      shouldDownloadFile: false
    };
  }

  return state;
}

function resetAlarmsExport(): AlarmsExportState {
  return {
    ...initialState
  };
}

export default function alarmsExportReducer(
  state: AlarmsExportState = initialState,
  action: AnyAction
): AlarmsExportState {
  switch (action.type) {
    case ACTION_TYPE.FETCH_ALARMS_EXPORT_LIST:
      return handleFetchAlarmsExport(state);
    case ACTION_TYPE.FETCH_ALARMS_EXPORT_LIST_SUCCESS:
      return handleFetchAlarmsExportSuccess(state, action.payload);
    case ACTION_TYPE.FETCH_ALARMS_EXPORT_LIST_FAILURE:
      return handleFetchAlarmsExportFailure(state);
    case ACTION_TYPE.FETCH_ALARMS_EXPORT_LIST_RESET:
      return resetAlarmsExport();
    case ACTION_TYPE.FETCH_ALARMS_EXPORT_EMAIL_CHANGE:
      return onEmailChange(state, action.payload);
    case ACTION_TYPE.SET_ALARMS_EXPORT_TYPE:
      return onExportTypeChange(state, action.payload);
    case ACTION_TYPE.PERFORM_ALARMS_FILE_DOWNLOAD:
      if (state.shouldDownloadFile) {
        return onPerformAlarmsFileDownload(state, action.payload);
      } else {
        return state;
      }

    default:
      return state;
  }
}
