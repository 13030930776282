/**
 * Additional Sensors State Definition
 * @author mahesh.kedari@shorelineiot.com
 */

/**
 *
 */
export enum AdditionalSensorsStatus {
  INIT,
  FETCHING,
  READY,
  ERROR
}
/**
 *
 */
export interface AdditionalSensorsState {
  selectedSensorStatus: AdditionalSensorsStatus;
  selectedSensorSchema: any;
  drawerOpen: boolean;
}

export enum CreateAdditionalSensorsStatus {
  INIT,
  IN_PROGRESS,
  READY,
  ERROR
}
/**
 *
 */
export interface CreateAdditionalSensorsState {
  status: CreateAdditionalSensorsStatus;
  response: any;
}
