/**
 * RBAC Permissions Saga
 * @author priyanka.ambawane@shorelineiot.com
 */
import { put } from 'redux-saga/effects';
import * as actions from '../actions/rbac.actions';
import { GenericObject, httpPost, APISERVICES } from '../../../../../framework';
import { AuthService } from '../../../../../framework/services/auth.service';
import { AuthSession } from 'aws-amplify/auth';
/**
 *
 */
function* getAccessToken() {
  const authService = new AuthService();
  try {
    const response: AuthSession = yield authService.getCurrentSession();
    return response.tokens?.accessToken.toString();
  } catch (error: any) {
    return error;
  }
}
/**
 *
 */
export function* getPermissions() {
  try {
    const accessToken: GenericObject = yield getAccessToken();
    const response: GenericObject = yield httpPost(
      'auth/rbac-permissions',
      accessToken,
      APISERVICES.HOST_API
    );
    yield put(actions.getRBACPermissionSuccess(response.results));
  } catch (error: any) {
    yield put(actions.getRBACPermissionFailure(error));
  }
}
