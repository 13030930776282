/**
 * Render Chart HOC accepts a component to be rendered
 * and then binds resultSet and other properties to selected chart component
 * @param Component
 * @returns
 */
import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { ChartViewProps } from './ChartViewProps';
import { RenderChartProps } from './RenderChartProps';
import { DASHBOARD_ITEM_TYPE } from './../../dashboard-widgets/types/DashboardItemType';
import { useBatteryWidgetQuery } from '../../../custom-hooks';
import { useSlugContext } from '../../../lib';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
/**
 *
 */

interface BatteryStatusViewerProps {
  query: any;
  chartSettings: any;
  chartRef: any;
  widgetType: string;
  Component: any;
}

const useStyles = makeStyles(() => ({
  noData: {
    padding: '10px'
  }
}));

export const BatteryStatusViewer: React.FC<BatteryStatusViewerProps> = ({
  query,
  chartSettings,
  chartRef,
  widgetType,
  Component,
  ...props
}) => {
  const { slug } = useSlugContext();
  const classes = useStyles();
  // Fetch Data from Raw data handler
  const { resultSet, isLoading, error } = useBatteryWidgetQuery(query, slug);

  if (isLoading) {
    // TODO: Show Progress details using Progress Object from props
    return <CircularProgress />;
  }
  if (error) {
    // TODO: Handle Error in eligant way
    return <>{error?.toString()}</>;
  }

  if (resultSet && resultSet?.length > 0) {
    return (
      <Component
        resultSet={resultSet}
        chartSettings={chartSettings}
        ref={chartRef}
        widgetType={widgetType}
        {...props}
      />
    );
  }
  // TODO: Set appropriate message to notify user that the query he has selected,
  // did not return anything
  return (
    <Typography variant="body1" className={classes.noData}>
      {`No devices needs battery replacement in next ${query.days_left_min_threshold} days`}
    </Typography>
  );
};

export const BatteryStatusViewerHOC =
  (Component: React.FC<ChartViewProps>) =>
  ({ query, chartSettings, chartRef, ...props }: RenderChartProps) => {
    return (
      <BatteryStatusViewer
        query={query}
        chartSettings={chartSettings}
        chartRef={chartRef}
        widgetType={DASHBOARD_ITEM_TYPE.RAW_DATA}
        Component={Component}
        {...props}
      />
    );
  };
