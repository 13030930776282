import { put, takeEvery, call } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import * as SET_DEFAULT_DASHBOARD_ACTION_TYPES from './setDefaultDashboard.actionTypes';
import * as SET_DEFAULT_DASHBOARD_ACTIONS from './setDefaultDashboard.actions';
import { DASHBOARD_LIST_ACTIONS } from '../dashboard-list';
import {
  GenericObject,
  httpPost,
  removeProgressFor,
  showToast,
  APISERVICES
} from '../../../../framework';
import { SagaIterator } from 'redux-saga';

function setDefaultDashboardIntoDatabase(data: any) {
  return httpPost(
    `orgs/${data.slug}/user_dashboard`,
    {
      dashboard_uuid: data.dashboardUuid
    },
    APISERVICES.HOST_API
  );
}

export function* setDefaultDashboard(action: AnyAction) {
  try {
    const response: GenericObject = yield call(setDefaultDashboardIntoDatabase, action.payload);
    yield put(SET_DEFAULT_DASHBOARD_ACTIONS.setDefaultDashboardSuccess(response));
    yield put(showToast('User dashboard set default successfully', 'success'));
    yield put(DASHBOARD_LIST_ACTIONS.resetState());
  } catch (error: any) {
    yield put(SET_DEFAULT_DASHBOARD_ACTIONS.setDefaultDashboardFailure(error));
    yield put(showToast('User dashboard could not be set', 'error', true));
  } finally {
    yield put(removeProgressFor('SET_DEFAULT_DASHBOARD'));
  }
}

export function* watchsetDefaultDashboardSaga(): SagaIterator {
  yield takeEvery(
    SET_DEFAULT_DASHBOARD_ACTION_TYPES.SET_DEFAULT_DASHBOARD_PROGRESS,
    setDefaultDashboard
  );
}
