/**
 * personalizeSettings Saga
 * @author ashwini.gawade@shorelineiot.com
 */
import { put, takeEvery, call } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import * as ACTIONS from './actions/getPersonalizeSettings.actionTypes';
import * as getPersonalizationInfoActions from './actions/getPersonalizeSettings.actions';
import {
  GenericObject,
  removeProgressFor,
  showProgressFor,
  showToast
} from '../../../../framework';
import { httpGet, APISERVICES } from '../../../../framework';
import { SagaIterator } from 'redux-saga';

const GET_PERSONALIZATION_INFO = 'GET_PERSONALIZATION_INFO';
function getPersonalizationInfo(slug: string) {
  return httpGet(`orgs/${slug}/org_personalization?get_images=1`, null, APISERVICES.HOST_API);
}

export function* getPersonalizationInfoSaga(action: AnyAction) {
  yield put(showProgressFor(GET_PERSONALIZATION_INFO));
  try {
    const response: GenericObject = yield call(getPersonalizationInfo, action.payload.slug);
    yield put(getPersonalizationInfoActions.getPersonalizationInfoSuccess(response));
  } catch (error: any) {
    yield put(showToast('Error in Fetching Personalisation Info.', 'error', true));
    yield put(getPersonalizationInfoActions.getPersonalizationInfoFailure(error));
  } finally {
    yield put(removeProgressFor(GET_PERSONALIZATION_INFO));
  }
}
/**
 *
 */
export default function* watchPersonalizeSettingsSaga(): SagaIterator {
  yield takeEvery(ACTIONS.FETCH_THEMES, getPersonalizationInfoSaga);
}
