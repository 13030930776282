import { skipToken } from '@reduxjs/toolkit/query';
import {
  useFetchAlarmNotesQuery,
  useLazyFetchAlarmNotesQuery,
  useFetchAlarmsCountQuery,
  useFetchAlarmsQuery,
  useLazyFetchAlarmEventsQuery,
  useUpdateAlarmsStatusMutation,
  useCreateAlarmNoteMutation,
  useFetchAlarmTimelinesQuery,
  useLazyFetchAlarmTimelinesQuery
} from './alarms.api';
import {
  FetchAlarmNotesArgs,
  FetchAlarmTimelineArgs,
  FetchAlarmsArgs,
  FetchAlarmsCountArgs
} from './alarms.types';

export const useFetchAlarms = (fetchAlarmsArgs: FetchAlarmsArgs | typeof skipToken) => {
  const {
    data,
    isFetching: isFetchingAlarms,
    isError: isAlarmsFetchError,
    error: alarmsFetchError,
    isSuccess: isAlarmsFetchSuccess,
    refetch: refetchAlarms
  } = useFetchAlarmsQuery(fetchAlarmsArgs);

  const alarms = data || [];

  return {
    alarms,
    isFetchingAlarms,
    isAlarmsFetchError,
    alarmsFetchError,
    isAlarmsFetchSuccess,
    refetchAlarms
  };
};

export const useFetchAlarmsCount = (fetchAlarmsCountArgs: FetchAlarmsCountArgs) => {
  const {
    data: alarmsCount,
    isFetching: isFetchingAlarmsCount,
    isError: isAlarmsCountFetchError,
    error: alarmsCountFetchError,
    isSuccess: isAlarmsCountFetchSuccess,
    refetch: refetchAlarmsCount
  } = useFetchAlarmsCountQuery(fetchAlarmsCountArgs);
  return {
    alarmsCount,
    isFetchingAlarmsCount,
    isAlarmsCountFetchError,
    alarmsCountFetchError,
    isAlarmsCountFetchSuccess,
    refetchAlarmsCount
  };
};

export const useUpdateAlarmStatus = (updateAlarmStatusArgs: any) => {
  const [
    updateAlarmStatus,
    {
      isLoading: isUpdatingAlarmStatus,
      isError: isUpdateAlarmStatusError,
      error: updateAlarmStatusError,
      isSuccess: isUpdateAlarmStatusSuccess
    }
  ] = useUpdateAlarmsStatusMutation(updateAlarmStatusArgs);

  return {
    updateAlarmStatus,
    isUpdatingAlarmStatus,
    isUpdateAlarmStatusError,
    updateAlarmStatusError,
    isUpdateAlarmStatusSuccess
  };
};

export const useLazyFetchAlarmEvents = () => {
  const [
    fetchAlarmEvents,
    {
      data: alarmEvents,
      isFetching: isFetchingAlarmEvents,
      isError: isAlarmEventsFetchError,
      error: alarmEventsFetchError,
      isSuccess: isAlarmEventsFetchSuccess
    }
  ] = useLazyFetchAlarmEventsQuery();

  return {
    fetchAlarmEvents,
    alarmEvents,
    isFetchingAlarmEvents,
    isAlarmEventsFetchError,
    alarmEventsFetchError,
    isAlarmEventsFetchSuccess
  };
};
export const useFetchAlarmNote = (fetchAlarmNotesArgs: FetchAlarmNotesArgs) => {
  const {
    data: alarmNotes,
    isFetching: isFetchingAlarmNotes,
    isError: isAlarmNotesFetchError,
    error: alarmNotesFetchError,
    isSuccess: isAlarmNotesFetchSuccess
  } = useFetchAlarmNotesQuery(fetchAlarmNotesArgs);
  return {
    alarmNotes,
    isFetchingAlarmNotes,
    isAlarmNotesFetchError,
    alarmNotesFetchError,
    isAlarmNotesFetchSuccess
  };
};
export const useLazyFetchAlarmNote = () => {
  const [
    fetchAlarmNote,
    {
      data: lazyAlarmNote,
      isFetching: isLazyFetchingAlarmNote,
      isError: isLazyAlarmNoteFetchError,
      error: lazyAlarmNoteFetchError,
      isSuccess: isLazyAlarmNoteFetchSuccess
    }
  ] = useLazyFetchAlarmNotesQuery();
  return {
    fetchAlarmNote,
    lazyAlarmNote,
    isLazyFetchingAlarmNote,
    isLazyAlarmNoteFetchError,
    lazyAlarmNoteFetchError,
    isLazyAlarmNoteFetchSuccess
  };
};

export const useCreateAlarmNote = () => {
  const [
    createAlarmNote,
    {
      data: alarmNote,
      isLoading: isCreatingAlarmNote,
      isError: isAlarmNoteCreateError,
      error: alarmNoteCreateError,
      isSuccess: isAlarmNoteCreateSuccess
    }
  ] = useCreateAlarmNoteMutation();
  return {
    createAlarmNote,
    alarmNote,
    isCreatingAlarmNote,
    isAlarmNoteCreateError,
    alarmNoteCreateError,
    isAlarmNoteCreateSuccess
  };
};

export const useFetchAlarmTimelines = (fetchAlarmTimelinesArgs: FetchAlarmTimelineArgs) => {
  const {
    data: alarmTimelines,
    isFetching: isFetchingAlarmTimelines,
    isError: isAlarmTimelinesFetchError,
    error: alarmTimelinesFetchError,
    isSuccess: isAlarmTimelinesFetchSuccess
  } = useFetchAlarmTimelinesQuery(fetchAlarmTimelinesArgs);
  return {
    alarmTimelines,
    isFetchingAlarmTimelines,
    isAlarmTimelinesFetchError,
    alarmTimelinesFetchError,
    isAlarmTimelinesFetchSuccess
  };
};

export const useLazyFetchAlarmTimelines = () => {
  const [
    lazyFetchAlarmTimelines,
    {
      data: lazyAlarmTimelines,
      isFetching: isLazyFetchingAlarmTimelines,
      isError: isLazyAlarmTimelinesFetchError,
      error: lazyAlarmTimelinesFetchError,
      isSuccess: isLazyAlarmTimelinesFetchSuccess
    }
  ] = useLazyFetchAlarmTimelinesQuery();
  return {
    lazyFetchAlarmTimelines,
    lazyAlarmTimelines,
    isLazyFetchingAlarmTimelines,
    isLazyAlarmTimelinesFetchError,
    lazyAlarmTimelinesFetchError,
    isLazyAlarmTimelinesFetchSuccess
  };
};
