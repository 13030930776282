/**
 * Alarms Details Action Types
 * @author mahesh.kedari@shorelineiot.com
 */
export const FETCH_ALARMS_DETAILS = 'FEATURES/ALARMS/ALARMS_DETAILS/FETCH_ALARMS_DETAILS';
export const FETCH_ALARMS_DETAILS_SUCCESS =
  'FEATURES/ALARMS/ALARMS_DETAILS/FETCH_ALARMS_DETAILS_SUCCESS';
export const FETCH_ALARMS_DETAILS_FAILURE =
  'FEATURES/ALARMS/ALARMS_DETAILS/FETCH_ALARMS_DETAILS_FAILURE';
export const RESET_ALARMS_DETAILS = 'FEATURES/ALARMS/RESET';
