/**
 * Header Style
 */
import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '45px',
    backgroundColor: theme.palette.primary.main,
    // backgroundColor: '#FF00FF',
    color: theme.palette.primary.contrastText,
    [theme.breakpoints.up('lg')]: {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary
    },
    [theme.breakpoints.up('md')]: {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary
    },
    transform: 'translateZ(0)'
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    }
  },

  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }
}));
