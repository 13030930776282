/**
 * User Profile Styles
 * @author mahesh.kedari@shorelineiot.com
 */
import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  profile: {
    borderWidth: 0,
    height: '32px',
    paddingTop: '0px',
    paddingLeft: '3px',
    paddingRight: '0px',
    color: theme.palette.text.secondary,
    marginTop: '-20px',
    '@media(min-width: 960px)': {
      borderWidth: 1,
      borderColor: theme.palette.grey[400],
      borderRadius: '30px',
      color: theme.palette.text.primary
    }
  },
  textRuleNameOverFlow: {
    width: 70,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  icon: {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.primary.main,
    marginLeft: '3px',
    height: '1.3em',
    width: '1.3em',
    '@media(min-width:960px)': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText
    }
  },
  username: {
    fontWeight: theme.typography.fontWeightRegular
  }
}));
