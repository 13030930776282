/**
 * Logo Path Selector hook
 * @author prerna.more@shorelineiot.com
 */
import { useSelector } from 'react-redux';
import { RootState } from '../../../configureStore';

const useLogopath = () => useSelector((state: RootState) => state.framework.logoPath);

export { useLogopath };
