/**
 * Add Multiple Dashboard Items
 * @author harsha.gokhale@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTION_TYPES from './addMultipleDashboardItems.actionsTypes';
/**
 *
 */
export function addMultipleDashboardItems(data: any): AnyAction {
  return {
    type: ACTION_TYPES.ADD_MULTIPLE_DASHBOARD_ITEMS_PROGRESS,
    payload: data
  };
}
/**
 *
 * @param data data to be returned on successful fetch API call
 */
export function handleAddMultipleDashboardItemsSuccess(data: any): AnyAction {
  return {
    type: ACTION_TYPES.ADD_MULTIPLE_DASHBOARD_ITEMS_SUCCESS,
    payload: data
  };
}
/**
 *
 */
export function handleAddMultipleDashboardItemsFailure(error: any): AnyAction {
  return {
    type: ACTION_TYPES.ADD_MULTIPLE_DASHBOARD_ITEMS_FAILURE,
    payload: error
  };
}

export const resetState = (): AnyAction => {
  return {
    type: ACTION_TYPES.ADD_MULTIPLE_DASHBOARD_ITEMS_RESET_STATE,
    payload: false
  };
};
