/**
 * Device Mailbox Actions
 * @author prerna.more@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTIONS_TYPES from './deviceMailbox.actionType';

export const fetchDeviceMailbox = (data: any): AnyAction => {
  return {
    type: ACTIONS_TYPES.FETCH_DEVICE_MAILBOX,
    payload: data
  };
};
export const fetchDeviceMailboxPathListSuccess = (data: any): AnyAction => {
  return {
    type: ACTIONS_TYPES.FETCH_DEVICE_MAILBOX_PATH_LIST_SUCCESS,
    payload: data
  };
};
export const fetchDeviceMailboxSuccess = (data: any): AnyAction => {
  return {
    type: ACTIONS_TYPES.FETCH_DEVICE_MAILBOX_SUCCESS,
    payload: data
  };
};

export const fetchDeviceMailboxFailure = (): AnyAction => {
  return {
    type: ACTIONS_TYPES.FETCH_DEVICE_MAILBOX_FAILURE
  };
};

export const fetchDeviceMailboxReset = (): AnyAction => {
  return {
    type: ACTIONS_TYPES.RESET_DEVICE_MAILBOX
  };
};
