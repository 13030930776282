/**
 * Link Button Component
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { ReactElement } from 'react';
import { Button, ButtonProps } from '@mui/material';
import { useStyles } from './linkButton.styles';

export default function SLLinkButton({
  variant = 'text',
  children,
  className = '',
  ...props
}: ButtonProps): ReactElement {
  const classes = useStyles();
  return (
    <Button className={`${classes.root} ${className}`} variant={variant} {...props}>
      {children}
    </Button>
  );
}
