/**
 * user self actions
 * @author swapnil.kshirsagar@shorelineiot.com
 */

import { AnyAction } from 'redux';
import * as ACTIONS from './userSelf.actionTypes';
import { UserSelf } from '../types/UserSelf';

export const fetchSelf = (slug: any): AnyAction => {
  // returns the action type
  return {
    type: ACTIONS.FETCH_USER_SELF_PROGRESS,
    slug
  };
};
/**
 *
 * @param self
 */
export const fetchSelfSuccess = (self: UserSelf): AnyAction => {
  return {
    type: ACTIONS.FETCH_USER_SELF_SUCCESS,
    payload: self
  };
};
/**
 *
 * @param error
 */
export const fetchSelfFailure = (error: any): AnyAction => {
  return {
    type: ACTIONS.FETCH_USER_SELF_FAILURE,
    payload: error
  };
};

export const fetchSelfReset = (): AnyAction => {
  return {
    type: ACTIONS.RESET_USER_DETAILS,
    payload: false
  };
};
