/**
 * Alerts list State Reducer
 * @author shubham.holkar@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTION_TYPE from '../actions/alerts.actionTypes';
import { SensorAlertsUpdateState } from '../types/SensorAlertsUpdateState';
import { SENSOR_ALERTS_UPDATE_STATUS } from '../types/SensorAlertsUpdateStatus';

const initialState: SensorAlertsUpdateState = {
  status: SENSOR_ALERTS_UPDATE_STATUS.INIT,
  response: []
};

function updateAlerts(state: SensorAlertsUpdateState): SensorAlertsUpdateState {
  return {
    ...state,
    status: SENSOR_ALERTS_UPDATE_STATUS.UPDATING
  };
}

function updateAlertsSuccess(
  state: SensorAlertsUpdateState,
  payload: any
): SensorAlertsUpdateState {
  return {
    ...state,
    status: SENSOR_ALERTS_UPDATE_STATUS.INIT,
    response: payload
  };
}

export default function AlertsUpdateReducer(
  state: SensorAlertsUpdateState = initialState,
  action: AnyAction
): SensorAlertsUpdateState {
  switch (action.type) {
    case ACTION_TYPE.UPDATE_ALERT:
      return updateAlerts(state);
    case ACTION_TYPE.UPDATE_ALERT_SUCCESS:
      return updateAlertsSuccess(state, action.payload);
    default:
      return state;
  }
}
