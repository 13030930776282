/**
 * Passwords Component
 * @author mahesh.kedari@shorelineiot.com
 */
import { Grid } from '@mui/material';
import React, { ReactElement } from 'react';
import { SLPasswordField } from '../../../../../framework';
import { registrationValidationRule as rules } from '../register.validation';

export default function Passwords(): ReactElement {
  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <SLPasswordField name="password" label="New Password" rules={rules.password} />
      </Grid>
      <Grid item>
        <SLPasswordField
          name="retypePassword"
          label="Confirm Password"
          rules={rules.retypePassword}
          canView={false}
        />
      </Grid>
    </Grid>
  );
}
