/**
 * digital sensor reducer
 * @author ashwini.gawade@shorelineiot.com
 */
import { AnyAction } from 'redux';
import {
  fetchDigitalSensorSchemaState,
  fetchDigitalSensorSchemaStatusDIO1,
  fetchDigitalSensorSchemaStatusDIO2,
  fetchDigitalSensorSchemaStatusDIO3,
  fetchDigitalSensorSchemaStatusDIO4
} from './digitalSensor.state';
import * as ACTION_TYPES from './digitalSensor.actionTypes';

const initialState: fetchDigitalSensorSchemaState = {
  statusDIO1: fetchDigitalSensorSchemaStatusDIO1.INIT,
  statusDIO2: fetchDigitalSensorSchemaStatusDIO2.INIT,
  statusDIO3: fetchDigitalSensorSchemaStatusDIO3.INIT,
  statusDIO4: fetchDigitalSensorSchemaStatusDIO4.INIT,
  DIO1: {},
  DIO2: {},
  DIO3: {},
  DIO4: {},
  defaultDIO1: {},
  defaultDIO2: {},
  defaultDIO3: {},
  defaultDIO4: {}
};
/**
 *
 * @param state
 */
function fetchDigitalSchemaProgressDIO1(
  state: fetchDigitalSensorSchemaState
): fetchDigitalSensorSchemaState {
  return {
    ...state,
    statusDIO1: fetchDigitalSensorSchemaStatusDIO1.PROGRESS
  };
}
function fetchDigitalSchemaSuccessDIO1(
  state: fetchDigitalSensorSchemaState,
  Data: any
): fetchDigitalSensorSchemaState {
  return {
    ...state,
    statusDIO1: fetchDigitalSensorSchemaStatusDIO1.READY,
    DIO1: { Data }
  };
}

function fetchDigitalSchemaFailureDIO1(
  state: fetchDigitalSensorSchemaState
): fetchDigitalSensorSchemaState {
  return {
    ...state,
    statusDIO1: fetchDigitalSensorSchemaStatusDIO1.ERROR
  };
}

function resetDigitalSchemaDIO1(
  state: fetchDigitalSensorSchemaState
): fetchDigitalSensorSchemaState {
  return {
    ...state,
    statusDIO1: fetchDigitalSensorSchemaStatusDIO1.INIT
  };
}

function fetchDigitalSchemaProgressDIO2(
  state: fetchDigitalSensorSchemaState
): fetchDigitalSensorSchemaState {
  return {
    ...state,
    statusDIO2: fetchDigitalSensorSchemaStatusDIO2.PROGRESS
  };
}
function fetchDigitalSchemaSuccessDIO2(
  state: fetchDigitalSensorSchemaState,
  Data: any
): fetchDigitalSensorSchemaState {
  return {
    ...state,
    statusDIO2: fetchDigitalSensorSchemaStatusDIO2.READY,
    DIO2: { Data }
  };
}

function fetchDigitalSchemaFailureDIO2(
  state: fetchDigitalSensorSchemaState
): fetchDigitalSensorSchemaState {
  return {
    ...state,
    statusDIO2: fetchDigitalSensorSchemaStatusDIO2.ERROR
  };
}

function resetDigitalSchemaDIO2(
  state: fetchDigitalSensorSchemaState
): fetchDigitalSensorSchemaState {
  return {
    ...state,
    statusDIO2: fetchDigitalSensorSchemaStatusDIO2.INIT
  };
}

function fetchDigitalSchemaProgressDIO3(
  state: fetchDigitalSensorSchemaState
): fetchDigitalSensorSchemaState {
  return {
    ...state,
    statusDIO3: fetchDigitalSensorSchemaStatusDIO3.PROGRESS
  };
}

function fetchDigitalSchemaSuccessDIO3(
  state: fetchDigitalSensorSchemaState,
  Data: any
): fetchDigitalSensorSchemaState {
  return {
    ...state,
    statusDIO3: fetchDigitalSensorSchemaStatusDIO3.READY,
    DIO3: { Data }
  };
}

function fetchDigitalSchemaFailureDIO3(
  state: fetchDigitalSensorSchemaState
): fetchDigitalSensorSchemaState {
  return {
    ...state,
    statusDIO3: fetchDigitalSensorSchemaStatusDIO3.ERROR
  };
}

function resetDigitalSchemaDIO3(
  state: fetchDigitalSensorSchemaState
): fetchDigitalSensorSchemaState {
  return {
    ...state,
    statusDIO3: fetchDigitalSensorSchemaStatusDIO3.INIT
  };
}

function fetchDigitalSchemaProgressDIO4(
  state: fetchDigitalSensorSchemaState
): fetchDigitalSensorSchemaState {
  return {
    ...state,
    statusDIO4: fetchDigitalSensorSchemaStatusDIO4.PROGRESS
  };
}

function fetchDigitalSchemaSuccessDIO4(
  state: fetchDigitalSensorSchemaState,
  Data: any
): fetchDigitalSensorSchemaState {
  return {
    ...state,
    statusDIO4: fetchDigitalSensorSchemaStatusDIO4.READY,
    DIO4: { Data }
  };
}

function fetchDigitalSchemaFailureDIO4(
  state: fetchDigitalSensorSchemaState
): fetchDigitalSensorSchemaState {
  return {
    ...state,
    statusDIO4: fetchDigitalSensorSchemaStatusDIO4.ERROR
  };
}

function resetDigitalSchemaDIO4(
  state: fetchDigitalSensorSchemaState
): fetchDigitalSensorSchemaState {
  return {
    ...state,
    statusDIO4: fetchDigitalSensorSchemaStatusDIO4.INIT
  };
}

function setDefaultDIO1(
  state: fetchDigitalSensorSchemaState,
  Data: any
): fetchDigitalSensorSchemaState {
  return {
    ...state,
    defaultDIO1: Data
  };
}

function setDefaultDIO2(
  state: fetchDigitalSensorSchemaState,
  Data: any
): fetchDigitalSensorSchemaState {
  return {
    ...state,
    defaultDIO2: Data
  };
}

function setDefaultDIO3(
  state: fetchDigitalSensorSchemaState,
  Data: any
): fetchDigitalSensorSchemaState {
  return {
    ...state,
    defaultDIO3: Data
  };
}
function setDefaultDIO4(
  state: fetchDigitalSensorSchemaState,
  Data: any
): fetchDigitalSensorSchemaState {
  return {
    ...state,
    defaultDIO4: Data
  };
}

export default function fetchDigitalSchemaReducer(
  state: fetchDigitalSensorSchemaState = initialState,
  action: AnyAction
): fetchDigitalSensorSchemaState {
  switch (action.type) {
    case ACTION_TYPES.FETCH_DIGITAL_SENSOR_SCHEMA_PROGRESS_DIO1:
      return fetchDigitalSchemaProgressDIO1(state);
    case ACTION_TYPES.FETCH_DIGITAL_SENSOR_SCHEMA_SUCCESS_DIO1:
      return fetchDigitalSchemaSuccessDIO1(state, action.payload);
    case ACTION_TYPES.FETCH_DIGITAL_SENSOR_SCHEMA_FAILURE_DIO1:
      return fetchDigitalSchemaFailureDIO1(state);
    case ACTION_TYPES.FETCH_DIGITAL_SENSOR_RESET_STATE_DIO1:
      return resetDigitalSchemaDIO1(state);

    case ACTION_TYPES.FETCH_DIGITAL_SENSOR_SCHEMA_PROGRESS_DIO2:
      return fetchDigitalSchemaProgressDIO2(state);
    case ACTION_TYPES.FETCH_DIGITAL_SENSOR_SCHEMA_SUCCESS_DIO2:
      return fetchDigitalSchemaSuccessDIO2(state, action.payload);
    case ACTION_TYPES.FETCH_DIGITAL_SENSOR_SCHEMA_FAILURE_DIO2:
      return fetchDigitalSchemaFailureDIO2(state);
    case ACTION_TYPES.FETCH_DIGITAL_SENSOR_RESET_STATE_DIO2:
      return resetDigitalSchemaDIO2(state);

    case ACTION_TYPES.FETCH_DIGITAL_SENSOR_SCHEMA_PROGRESS_DIO3:
      return fetchDigitalSchemaProgressDIO3(state);
    case ACTION_TYPES.FETCH_DIGITAL_SENSOR_SCHEMA_SUCCESS_DIO3:
      return fetchDigitalSchemaSuccessDIO3(state, action.payload);
    case ACTION_TYPES.FETCH_DIGITAL_SENSOR_SCHEMA_FAILURE_DIO3:
      return fetchDigitalSchemaFailureDIO3(state);
    case ACTION_TYPES.FETCH_DIGITAL_SENSOR_RESET_STATE_DIO3:
      return resetDigitalSchemaDIO3(state);

    case ACTION_TYPES.FETCH_DIGITAL_SENSOR_SCHEMA_PROGRESS_DIO4:
      return fetchDigitalSchemaProgressDIO4(state);
    case ACTION_TYPES.FETCH_DIGITAL_SENSOR_SCHEMA_SUCCESS_DIO4:
      return fetchDigitalSchemaSuccessDIO4(state, action.payload);
    case ACTION_TYPES.FETCH_DIGITAL_SENSOR_SCHEMA_FAILURE_DIO4:
      return fetchDigitalSchemaFailureDIO4(state);
    case ACTION_TYPES.FETCH_DIGITAL_SENSOR_RESET_STATE_DIO4:
      return resetDigitalSchemaDIO4(state);

    case ACTION_TYPES.SET_DEFAULT_DIO1:
      return setDefaultDIO1(state, action.payload);
    case ACTION_TYPES.SET_DEFAULT_DIO2:
      return setDefaultDIO2(state, action.payload);
    case ACTION_TYPES.SET_DEFAULT_DIO3:
      return setDefaultDIO3(state, action.payload);
    case ACTION_TYPES.SET_DEFAULT_DIO4:
      return setDefaultDIO4(state, action.payload);

    default:
      return state;
  }
}
