/**
 * Chart Configuration Saga
 * @author mahesh.kedari@shorelineiot.com
 */
import { AnyAction } from 'redux';
import { takeEvery, call, put, all } from 'redux-saga/effects';
import { datapointActions, DATAPOINT_ACTIONS } from '../actions';
import {
  GenericObject,
  httpGet,
  httpPost,
  removeProgressFor,
  showProgressFor,
  APISERVICES
} from '../../../../../../../framework';
import { SagaIterator } from 'redux-saga';
/**
 * get chart configuration for specified datapoint
 */
function getChartConfig(data: any) {
  return httpGet(
    `orgs/${data.slug}/devices/${data.device_id}/datapoints/${data.dpid}/graph_config`,
    null,
    APISERVICES.DEVICE_API
  );
}
/**
 * Set chart configuration for specified datapoint and user
 */
function setChartConfig(data: any) {
  return httpPost(
    `orgs/${data.slug}/devices/${data.device_id}/datapoints/${data.dpid}/graph_config`,
    {
      config: data.config
    },
    APISERVICES.DEVICE_API
  );
}
/**
 *
 * @param action
 */
function* getChartConfigSaga(action: AnyAction) {
  const data = action.payload;
  yield put(showProgressFor(`GET_VIEW_CONFIG_${data.dpid}`));
  try {
    const resp: GenericObject = yield call(getChartConfig, data);
    yield put(datapointActions.chartConfigSuccess(resp));
  } catch (error: any) {
    yield put(
      datapointActions.chartConfigFailure({
        dpid: data.dpid,
        config: {
          chartType: 'LINE_CHART'
        }
      })
    );
  } finally {
    yield put(removeProgressFor(`GET_VIEW_CONFIG_${data.dpid}`));
  }
}
/**
 *
 * @param action
 */
function* setChartConfigSaga(action: AnyAction) {
  const data = action.payload;
  yield put(showProgressFor(`SET_VIEW_CONFIG_${data.dpid}`));
  try {
    const resp: GenericObject = yield call(setChartConfig, data);
    yield put(datapointActions.setChartConfigSuccess(resp));
  } catch (error: any) {
    yield put(
      datapointActions.setChartConfigFailure({
        dpid: data.dpid
      })
    );
  } finally {
    yield put(removeProgressFor(`SET_VIEW_CONFIG_${data.dpid}`));
  }
}
/**
 * Watcher saga for Fetch Topic Request
 */
export function* watchChartConfigSaga(): SagaIterator {
  yield all([
    takeEvery(DATAPOINT_ACTIONS.FETCH_VIEW_CONFIG, getChartConfigSaga),
    takeEvery(DATAPOINT_ACTIONS.SET_VIEW_CONFIG, setChartConfigSaga)
  ]);
}
