/**
 * Column Chart View
 * @author aditya.bhadange@shorelineiot.com
 */
import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { ChartViewProps } from '../common/ChartViewProps';
import { columnChartConfig as defaultColumnChartSettings } from './columnChartSettings';
import useCartesionChart from '../utils/useCartesianChart';
/**
 *
 * @param param0
 * @returns
 */
const SLColumnChartView: React.FC<ChartViewProps> = React.forwardRef(
  ({ chartSettings, resultSet, widgetType }: ChartViewProps, ref: any) => {
    const columnChartSettings = useCartesionChart(
      chartSettings,
      defaultColumnChartSettings(),
      resultSet,
      widgetType,
      'column'
    );

    return <HighchartsReact highcharts={Highcharts} options={columnChartSettings} ref={ref} />;
  }
);

export default SLColumnChartView;
