/**
 * Sensor Alerts Create Saga
 * @author shubham.holkar@shorelineiot.com
 */

import { put, takeEvery, call } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import * as actions from '../actions/createAlert.actions';
import * as actionTypes from '../actions/alerts.actionTypes';
import {
  removeProgressFor,
  showProgressFor,
  SafeSaga,
  httpPost,
  showToast,
  APISERVICES
} from '../../../../framework';
import { SagaIterator } from 'redux-saga';
import { CreateRulePayload, CreateRuleResponse } from '../types/CreateAlertState';

function createAlerts(slug: string, payload: CreateRulePayload) {
  return httpPost(`orgs/${slug}/alerts`, payload, APISERVICES.DEVICE_API);
}

export function* createSensorAlerts(action: AnyAction) {
  yield put(showProgressFor('CREATE_SENSOR_ALERT'));
  const { slug, payload, navigate } = action.payload;
  const response: CreateRuleResponse = yield call(createAlerts, slug, payload);
  yield put(showToast('Rule created Successfully!', 'success'));

  yield put(actions.createAlertSuccess(response));
  yield put(removeProgressFor('CREATE_SENSOR_ALERT'));

  navigate(`/${slug}/app/alerts`);
}

function* errorHandler() {
  yield put(removeProgressFor('CREATE_SENSOR_ALERT'));
}

export function* watchSensorAlertsCreateSaga(): SagaIterator {
  yield takeEvery(
    actionTypes.CREATE_ALERT_PROGRESS,
    SafeSaga(createSensorAlerts, actionTypes.CREATE_ALERT_PROGRESS, errorHandler)
  );
}
