import { AnyAction } from 'redux';
import * as ACTION_TYPE from './externalReports.actionTypes';
import { ExternalReportsState, EXTERNAL_REPORTS_STATUS } from './externalReports.state';

const initialState: ExternalReportsState = {
  status: EXTERNAL_REPORTS_STATUS.INIT,
  ExternalReportsData: []
};

function handleFetchExternalReportsDetails(state: ExternalReportsState): ExternalReportsState {
  return {
    ...state,
    status: EXTERNAL_REPORTS_STATUS.FETCHING
  };
}

function handleFetchExternalReportsSuccess(
  state: ExternalReportsState,
  ExternalReportsData: any
): ExternalReportsState {
  return {
    ...state,
    status: EXTERNAL_REPORTS_STATUS.READY,
    ExternalReportsData
  };
}

function handleFetchExternalReportsFailure(state: ExternalReportsState): ExternalReportsState {
  return {
    ...state,
    status: EXTERNAL_REPORTS_STATUS.ERROR
  };
}

function resetExternalReports(state: ExternalReportsState): ExternalReportsState {
  return {
    ...state,
    status: EXTERNAL_REPORTS_STATUS.INIT,
    ExternalReportsData: []
  };
}

export default function externalreportsReducer(
  state: ExternalReportsState = initialState,
  action: AnyAction
): ExternalReportsState {
  switch (action.type) {
    case ACTION_TYPE.FETCH_EXTERNAL_REPORTS:
      return handleFetchExternalReportsDetails(state);
    case ACTION_TYPE.FETCH_EXTERNAL_REPORTS_SUCCESS:
      return handleFetchExternalReportsSuccess(state, action.payload);
    case ACTION_TYPE.FETCH_EXTERNAL_REPORTS_FAILURE:
      return handleFetchExternalReportsFailure(state);
    case ACTION_TYPE.RESET_EXTERNAL_REPORTS:
      return resetExternalReports(state);
    default:
      return state;
  }
}
