import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  overFlow: {
    '& .ant-select-multiple .ant-select-selection-overflow': {
      flexWrap: 'nowrap',
      maxWidth: '90%',
      overflow: 'auto',
      display: 'flex',
      flex: 'auto'
    },
    '& .ant-select-multiple .ant-select-selection-overflow-item': {
      display: 'contents'
    }
  },
  borderBottom: {
    borderBottom: '1px solid rgb(118, 118, 118)'
  },
  roundBorder: {
    border: '1px solid rgb(118, 118, 118)',
    borderRadius: '28px',
    paddingTop: '5px',
    paddingBottom: '5px'
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  tooltip: {
    overflowY: 'auto',
    maxHeight: '50vh'
  },
  textAlignToLeft: {
    textAlign: 'left'
  }
}));
