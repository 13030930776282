/**
 * Fetch Datapoints Helper for datapoint reducer
 * @author mahesh.kedari@shorelineiot.com
 */
import {
  DatapointsListState,
  DATAPOINTS_LIST_STATUS,
  LiveDataConfigType,
  ViewConfigType
} from '../types/datapointsList.state';

import { VIEW_CONFIG_STATUS } from '../types/viewConfig';
import { LIVE_DATA_CONFIG_STATUS } from '../types/liveDataConfig';
import { Datapoint } from '../types';

export function onFetchDatapoints(state: DatapointsListState): DatapointsListState {
  return {
    ...state,
    status: DATAPOINTS_LIST_STATUS.FETCHING
  };
}
/**
 * Reducer Function
 * @param accumulator Accumulated value
 * @param currentValue Current Value
 */
const reducerFn = (accumulator: any, currentValue: any) => {
  const { datapointConfigs } = accumulator;
  datapointConfigs[currentValue.dpid] = currentValue;

  const { viewConfigs } = accumulator;
  viewConfigs[currentValue.dpid] = {
    status: VIEW_CONFIG_STATUS.INIT,
    config: {
      configFor: currentValue.dpid
    }
  };

  const { liveDataConfigs } = accumulator;
  liveDataConfigs[currentValue.dpid] = {
    // [SLC-2622] if live data is not available for current dpid, set configuration to ready
    status: currentValue.is_live ? LIVE_DATA_CONFIG_STATUS.INIT : LIVE_DATA_CONFIG_STATUS.READY,
    topic: ''
  };

  return {
    datapointConfigs,
    viewConfigs,
    liveDataConfigs
  };
};
/**
 *
 * @param state
 */
export function onfetchDatapointsSuccess(
  state: DatapointsListState,
  newDatapoints: Array<any>
): DatapointsListState {
  const datapointsConfigs: {
    datapointConfigs: Datapoint[];
    viewConfigs: ViewConfigType;
    liveDataConfigs: LiveDataConfigType;
  } = newDatapoints.reduce(reducerFn, {
    datapointConfigs: {},
    viewConfigs: {},
    liveDataConfigs: {}
  });
  return {
    ...state,
    status: DATAPOINTS_LIST_STATUS.READY,
    ...datapointsConfigs
  };
}
/**
 *
 * @param state
 */
export function onFetchDatapointsFailure(state: DatapointsListState): DatapointsListState {
  return {
    ...state,
    status: DATAPOINTS_LIST_STATUS.ERROR
  };
}
/**
 *
 * @param state
 */
export function onResetDatapoints(state: DatapointsListState): DatapointsListState {
  return {
    ...state,
    status: DATAPOINTS_LIST_STATUS.INIT,
    datapointConfigs: [],
    viewConfigs: {},
    liveDataConfigs: {},
    lastDataValue: {},
    fetchDatapointsAgain: false
  };
}
export function setFetchDatapointsAgain(
  state: DatapointsListState,
  flag: boolean
): DatapointsListState {
  return {
    ...state,
    fetchDatapointsAgain: flag
  };
}
