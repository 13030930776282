/**
 * Login Form Component
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { useState } from 'react';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import { SLForm, SLCheckbox, SLSubmit, SLTextField } from '../../../../framework';
import { useStyles } from '../../shared/authForm.styles';
import { defaultValues } from './loginForm.defaultValue';
import * as toastAction from '../../../../framework';
import { loginActions } from '../store/actions';
import { AuthProviderActions } from '../../store';
import { rules } from './loginForm.validation';

/**
 *
 */
export default function LoginForm() {
  const savedEmail = localStorage.getItem('savedEmail');
  const dispatch = useDispatch();
  const [formDefault] = useState({
    ...defaultValues,
    email: savedEmail || ''
  });

  const classes = useStyles();

  const size = 'small';

  const onSubmit = (data: any) => {
    if (data.rememberme) {
      localStorage.setItem('savedEmail', data.email.trim());
    } else {
      localStorage.removeItem('savedEmail');
    }
    const domain = data.email.split('@')[1];
    dispatch(loginActions.setUserEmail({ email: data?.email }));
    dispatch(AuthProviderActions.getProviderForDomain(domain));
    dispatch(toastAction.closeAllToasts());
  };

  return (
    <SLForm defaultValues={formDefault} onSubmit={onSubmit}>
      <Box display="flex" flexDirection="column" width="auto" maxWidth="500px">
        <SLTextField
          name="email"
          label="Email"
          labelTranslationId="email"
          rules={rules.email}
          autoFocus={true}
        />
      </Box>
      <Box display="flex" justifyContent="space-between">
        <SLCheckbox name="rememberme" label="Remember Me" labelTranslationId="remember_me" />
      </Box>
      <Box display="flex" justifyContent="right" mt={3}>
        <SLSubmit
          size={size}
          className={classes.submitButton}
          dataTestId="login-email-form-next-button"
          translationId="next">
          Next
        </SLSubmit>
      </Box>
    </SLForm>
  );
}
