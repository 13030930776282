/**
 * Public Routes
 * @author priyanka.ambawane@shorelineiot.com
 */
import React, { ReactElement, lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import ReleaseNotes from '../../framework/components/header/ReleaseNote/ReleaseNotes';
import { CircularProgress } from '@mui/material';

const UnderConstructionPageLoadableComponent = lazy(() => import('./UnderContructionPage'));

function publicRoutes(): ReactElement {
  return (
    <Suspense fallback={<CircularProgress />}>
      <Routes>
        <Route
          path="pricing"
          element={<UnderConstructionPageLoadableComponent message="Pricing page coming soon!" />}
        />
        <Route
          path="faqs"
          element={<UnderConstructionPageLoadableComponent message="FAQs page coming soon!" />}
        />
        <Route
          path="demo"
          element={<UnderConstructionPageLoadableComponent message="Demo page coming soon!" />}
        />
        <Route
          path="walkthrough"
          element={
            <UnderConstructionPageLoadableComponent message="Walkthrough page coming soon!" />
          }
        />
        <Route
          path="terms"
          element={
            <UnderConstructionPageLoadableComponent
              message="Terms and conditions page coming soon!"
              back
            />
          }
        />

        <Route path="release-notes" element={<ReleaseNotes />} />
      </Routes>
    </Suspense>
  );
}
export default publicRoutes;
