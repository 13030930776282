/**
 * @author ashwini.gawade@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTIONS from './orgUpdate.actionTypes';
/**
 *
 * @param organisation
 */
export const updateOrg = (organisation: any): AnyAction => {
  return {
    type: ACTIONS.UPDATE_ORG,
    payload: organisation
  };
};
/**
 *
 * @param data
 */
export const updateOrgSuccess = (data: any): AnyAction => {
  // returns the action type
  return {
    type: ACTIONS.UPDATE_ORG_SUCCESS,
    data
  };
};
/**
 *
 * @param error
 */
export const updateOrgFailure = (error: any): AnyAction => {
  // returns the action type
  return {
    type: ACTIONS.UPDATE_ORG_FAILURE,
    error
  };
};
/**
 *
 * @param data
 */
export const fetchOrgDetails = (slug: string): AnyAction => {
  return {
    type: ACTIONS.FETCH_ORG_DETAILS,
    payload: { slug }
  };
};
/**
 *
 * @param data
 */
export const orgDetailsSuccess = (data: any): AnyAction => {
  // returns the action type
  return {
    type: ACTIONS.ORG_DETAILS_SUCCESS,
    data
  };
};
/**
 *
 */
export const orgDetailsFailure = (): AnyAction => {
  // returns the action type
  return {
    type: ACTIONS.ORG_DETAILS_FAILURE
  };
};
/**
 *
 */
export const orgDetailsReset = (): AnyAction => {
  return {
    type: ACTIONS.RESET_ORG_DETAILS
  };
};
