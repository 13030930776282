import { APISERVICES } from '../../../../framework';
import { featureApi } from '../../../feature.api';
import { ID_PROVIDER_TAG, IdProviderExistsArgs, IdProviderExistsResult } from './types/rtkTypes';

export const IdProviderExistsApi = featureApi.injectEndpoints({
  endpoints: (build) => ({
    fetchIdProviderExists: build.query<IdProviderExistsResult, IdProviderExistsArgs>({
      query: ({ idp }) => {
        return {
          apiEndpoint: `orgs/identity_provider/exists?idp=${idp}`,
          params: null,
          service: APISERVICES.ID_PROVIDER,
          method: 'GET',
          actionKey: 'FETCH_ID_PROIVIDER_EXISTS',
          showProgressBar: true
        };
      },
      providesTags: [ID_PROVIDER_TAG.ID_PROVIDER_EXISTS]
    })
  })
});

export const { useLazyFetchIdProviderExistsQuery } = IdProviderExistsApi;
