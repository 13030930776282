/**
 * Resend Verification Link Saga
 * @author mahesh.kedari@shorelineiot.com
 */
import { AnyAction } from 'redux';
import { put } from 'redux-saga/effects';
import * as actions from '../actions/login.actions';
import { showToast } from '../../../../../framework';
import { AuthResponse, AuthService } from '../../../../../framework/services/auth.service';
/**
 *
 */
export function* resendVerificationLink(action: AnyAction) {
  try {
    const amplifyService = new AuthService();
    const response: AuthResponse = yield amplifyService.sendEmailLink(action.payload);
    if (response.type === 'SUCCESS') {
      yield put(actions.resendVerificationSuccess(response.message));
      yield put(showToast('Verification email sent successfully', 'success'));
    } else {
      throw new Error(response.message);
    }
  } catch (error: any) {
    yield put(actions.resendVerificationFailure(error));
    yield put(showToast('Verification mail could not be sent', 'error', true));
  }
}
