export const GET_SURVEY_QUESTIONS =
  'FEATURES/DEVICE/DEVICE-SETTINGS/AUTOMATIC-CONFIG/ACTIONS/GET_SURVEY_QUESTIONS';
export const GET_SURVEY_QUESTIONS_SUCCESS =
  'FEATURES/DEVICE/DEVICE-SETTINGS/AUTOMATIC-CONFIG/ACTIONS/GET_SURVEY_QUESTIONS_SUCCESS';
export const GET_SURVEY_QUESTIONS_FAILURE =
  'FEATURES/DEVICE/DEVICE-SETTINGS/AUTOMATIC-CONFIG/ACTIONS/GET_SURVEY_QUESTIONS_FAILURE';
export const GET_SURVEY_QUESTIONS_RESET =
  'FEATURES/DEVICE/DEVICE-SETTINGS/AUTOMATIC-CONFIG/ACTIONS/GET_SURVEY_QUESTIONS_RESET';
export const GET_SINGLE_SURVEY_QUESTIONS =
  'FEATURES/DEVICE/DEVICE-SETTINGS/AUTOMATIC-CONFIG/ACTIONS/GET_SINGLE_SURVEY_QUESTIONS';
export const GET_SINGLE_SURVEY_QUESTIONS_SUCCESS =
  'FEATURES/DEVICE/DEVICE-SETTINGS/AUTOMATIC-CONFIG/ACTIONS/GET_SINGLE_SURVEY_QUESTIONS_SUCCESS';
export const GET_SINGLE_SURVEY_QUESTIONS_FAILURE =
  'FEATURES/DEVICE/DEVICE-SETTINGS/AUTOMATIC-CONFIG/ACTIONS/GET_SINGLE_SURVEY_QUESTIONS_FAILURE';
export const SET_NBAUTO_FIRST_PAGE_VALUES =
  'FEATURES/DEVICE/DEVICE-SETTINGS/AUTOMATIC-CONFIG/ACTIONS/SET_NBAUTO_FIRST_PAGE_VALUES';
