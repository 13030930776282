import SignalCellular0BarIcon from '@mui/icons-material/SignalCellular0Bar';
import SignalCellular1BarIcon from '@mui/icons-material/SignalCellular1Bar';
import SignalCellular2BarIcon from '@mui/icons-material/SignalCellular2Bar';
import SignalCellular3BarIcon from '@mui/icons-material/SignalCellular3Bar';
import SignalCellular4BarIcon from '@mui/icons-material/SignalCellular4Bar';
import React from 'react';

/**
 * This function return the correct Cellular SVG icon
 * as per the signal strength.
 * @param {number} signalStrength The strength of the signal.
 * @returns Cellular component
 */
export const renderCorrectCellularIcon = (signalStrength: number, classes: any) => {
  if (signalStrength <= -18 && signalStrength >= -20) {
    return <SignalCellular1BarIcon fontSize="large" className={classes.resizableIcon} />;
  }
  if (signalStrength <= -15 && signalStrength >= -17) {
    return <SignalCellular2BarIcon fontSize="large" className={classes.resizableIcon} />;
  }
  if (signalStrength <= -11 && signalStrength >= -14) {
    return <SignalCellular3BarIcon fontSize="large" className={classes.resizableIcon} />;
  }
  if (signalStrength <= 0 && signalStrength >= -10) {
    return <SignalCellular4BarIcon fontSize="large" className={classes.resizableIcon} />;
  }
  return <SignalCellular0BarIcon fontSize="large" className={classes.resizableIcon} />;
};

/**
 * This function will convert the raw CSQ signal values
 * to the simple understandable text.
 * @param data
 * @returns string
 */
export const renderCorrectSignalText = (data: number) => {
  if (data <= -18 && data >= -20) {
    return 'Very Low';
  }
  if (data <= -15 && data >= -17) {
    return 'Low';
  }
  if (data <= -11 && data >= -14) {
    return 'OK';
  }
  if (data <= 0 && data >= -10) {
    return 'Good';
  }
  return 'Very Low';
};
