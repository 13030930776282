/**
 * Avatar Style
 * @author mahesh.kedari@shorelineiot.com
 */
import { AvatarClasses, Theme } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

export const AvatarStyle:
  | Partial<OverridesStyleRules<keyof AvatarClasses, 'MuiAvatar', Omit<Theme, 'components'>>>
  | undefined = {
  root: {
    width: 18,
    height: 18,
    '@media (min-width:600px)': {
      width: 26,
      height: 26
    },
    '@media (min-width:960px)': {
      width: 34,
      height: 34
    },
    '@media (min-width:1280px)': {
      width: 42,
      height: 42
    },
    '@media (min-width:1920px)': {
      width: 50,
      height: 50
    }
  }
};
