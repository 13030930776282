/**
 * Narrowband rules list reducer
 * @author priyanka.ambawane@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTIONS from './nbRuleCreate.actionTypes';
import { CreateNarrowbandRuleState, CreateNarrowbandRuleStatus } from './nbRuleCreate.state';

const initialState: CreateNarrowbandRuleState = {
  status: CreateNarrowbandRuleStatus.INIT,
  response: []
};
/**
 *
 * @param state
 */
function createNarrowbandRule(state: CreateNarrowbandRuleState): CreateNarrowbandRuleState {
  return { ...state, status: CreateNarrowbandRuleStatus.IN_PROGRESS };
}
/**
 *
 * @param state
 * @param payload
 */
function createNarrowbandRuleSuccess(
  state: CreateNarrowbandRuleState,
  payload: Array<any>
): CreateNarrowbandRuleState {
  return {
    ...state,
    status: CreateNarrowbandRuleStatus.READY,
    response: payload
  };
}
/**
 *
 * @param state
 */
function createNarrowbandRuleFailure(
  state: CreateNarrowbandRuleState,
  payload: any
): CreateNarrowbandRuleState {
  return {
    ...state,
    status: CreateNarrowbandRuleStatus.ERROR,
    response: payload
  };
}
/**
 *
 * @param state
 */
function resetCreateNarrowbandRule(state: CreateNarrowbandRuleState): CreateNarrowbandRuleState {
  return {
    ...initialState
  };
}
/**
 *
 * @param state
 * @param action
 */
export default function createNarrowbandRuleReducer(
  state: CreateNarrowbandRuleState = initialState,
  action: AnyAction
): CreateNarrowbandRuleState {
  switch (action.type) {
    case ACTIONS.CREATE_NB_RULES:
      return createNarrowbandRule(state);
    case ACTIONS.CREATE_NB_RULES_SUCCESS:
      return createNarrowbandRuleSuccess(state, action.payload);
    case ACTIONS.CREATE_NB_RULES_FAILURE:
      return createNarrowbandRuleFailure(state, action.payload);
    case ACTIONS.RESET_CREATE_NB_RULES_STATE:
      return resetCreateNarrowbandRule(state);
    default:
      return state;
  }
}
