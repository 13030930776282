/**
 * users list State Reducer
 * @author swapnil.kshirsagar@shorelineiot.com
 */
import { AnyAction } from 'redux';
import {
  FETCH_HISTORICAL_DATA,
  FETCH_HISTORICAL_DATA_FAILURE,
  FETCH_HISTORICAL_DATA_RESET,
  FETCH_HISTORICAL_DATA_SUCCESS
} from './historicalData.actionTypes';
import { DatapointsListState, DATAPOINTS_LIST_STATUS } from './historicalDataList.state';

const initialState: DatapointsListState = {
  status: DATAPOINTS_LIST_STATUS.INIT,
  historicalConfigs: {}
};

function getHistoricalProgress(state: DatapointsListState): DatapointsListState {
  return {
    ...state,
    status: DATAPOINTS_LIST_STATUS.FETCHING
  };
}

function getHistoricalSuccess(
  state: DatapointsListState,
  historicalConfigs: Array<any>
): DatapointsListState {
  return {
    ...state,
    status: DATAPOINTS_LIST_STATUS.READY,
    historicalConfigs
  };
}

function getHistoricalError(state: DatapointsListState): DatapointsListState {
  return {
    ...state,
    status: DATAPOINTS_LIST_STATUS.ERROR
  };
}

function resetListState(state: DatapointsListState): DatapointsListState {
  return {
    ...state,
    status: DATAPOINTS_LIST_STATUS.INIT,
    historicalConfigs: {}
  };
}
/*
    Reducer is a pure function that takes actions and state as input 
    and returns/passes the modified state to the store.
    It is implemented using switch case to update the state according 
    to the ation type required.
*/

export default function historicalReducer(
  state: DatapointsListState = initialState,
  action: AnyAction
): DatapointsListState {
  switch (action.type) {
    case FETCH_HISTORICAL_DATA:
      return getHistoricalProgress(state);
    case FETCH_HISTORICAL_DATA_SUCCESS:
      return getHistoricalSuccess(state, action.payload);
    case FETCH_HISTORICAL_DATA_FAILURE:
      return getHistoricalError(state);
    case FETCH_HISTORICAL_DATA_RESET:
      return resetListState(state);
    default:
      return state;
  }
}
