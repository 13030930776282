import { Color } from 'react-color';
import { DASHBOARD_ITEM_TYPE } from '../../dashboard-widgets/types/DashboardItemType';

export const DISPLAY_RANGE_SELECTION_OPTIONS = {
  AUTO: 'auto',
  MANUAL: 'manual'
} as const;

export type DisplayRangeSelectionOptions = 'auto' | 'manual';

export type ColorRange = {
  from: string;
  to: string;
  color: Color;
};

export type ColorRanges = {
  [key: number]: ColorRange;
};

export const dialWidgetChartSettings: DialWidgetSettingsType = {
  displayRangeSelection: DISPLAY_RANGE_SELECTION_OPTIONS.AUTO,
  colorRanges: {}
};

export type DialWidgetSettingsType = {
  displayRangeSelection: DisplayRangeSelectionOptions;
  colorRanges: ColorRanges;
  showAlarm?: boolean;
  min?: number;
  max?: number;
};

export type CompleteDialWidgetSettingsType = {
  chart_type: DASHBOARD_ITEM_TYPE;
  chartSettings: DialWidgetSettingsType;
  positionMeta: number[];
};

export type DialWidgetPlotBandType = {
  from: number;
  to: number;
  color: string;
};

export type DialData = {
  min?: number;
  max?: number;
  recent?: number;
  unit?: string;
};

export type ResultType = {
  isLoading: boolean;
  isError: boolean;
  dialWidgetData: DialData;
};

export type SensorType = {
  deviceId: number;
  dpid: number;
  sensorLabel: string;
};

export interface ResultSetType extends DialData {
  sensor: SensorType;
}

export interface DialWidgetViewerProps {
  resultSet: ResultSetType;
  chartSettings: DialWidgetSettingsType;
}
