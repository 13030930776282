/**
 * Analytics Pie Chart Editor
 * @author mahesh.kedari@shorelineiot.com
 */
import { useCubeQuery } from '@cubejs-client/react';
import { Grid } from '@mui/material';
import React from 'react';
import { SLTextField } from '../../..';
import SLColorPicker from '../../../color-picker/SLColorPicker';
import RedirectionURLEditor from '../../common/RedirectionURLEditor';
import WidgetSettingAccordion from '../../common/WidgetAccordion';

interface SeriesConfiguratorProps {
  series: any;
  chartSettings: any;
  handleUpdate: (data?: any) => void;
}

const SeriesConfigurator = ({ series, chartSettings, handleUpdate }: SeriesConfiguratorProps) => {
  const prefix = series?.category?.replaceAll('.', '_');
  const path = `dataSettings.${prefix || series?.category}`;

  return (
    <Grid container spacing={3} alignItems="flex-start" justifyContent="flex-start">
      <Grid item xs={6}>
        <SLTextField
          name={`${path}.label`}
          label="Title"
          defaultValue={series?.category}
          onBlur={handleUpdate}
        />
      </Grid>
      <Grid item xs={6}>
        <SLColorPicker
          name={`${path}.color`}
          value={{ hex: chartSettings?.[prefix]?.color }}
          handleColorChange={handleUpdate}
        />
      </Grid>
      <RedirectionURLEditor
        path={path}
        handleUpdate={handleUpdate}
        currentValue={chartSettings?.dataSettings?.[prefix]}
      />
    </Grid>
  );
};
/**
 *
 */
interface AnalyticsProps {
  query: any;
  chartSettings: any;
  handleUpdate: (data?: void) => void;
}

/**
 * This function will remove the duplicate objects from an Array.
 * @param arr This is the array from which duplicate object needs to remove.
 * @param compare This is the string which will be used to compare objects keys.
 * @returns Array
 */
export const getUnique = (arr: any[], compare: string) => {
  // store the comparison  values in array
  const unique = arr
    .map((e: any) => e[compare])
    // store the indexes of the unique objects
    .map((e: any, i: any, final: any[]) => final.indexOf(e) === i && i)
    // eliminate the false indexes & return unique objects
    .filter((e: any) => arr[e])
    .map((e: any) => arr[e]);

  return unique;
};

/**
 * This function will combine the existing data settings which user has
 * configures & new query response. It will unionize the resultSet while
 * skipping the duplicate items.
 * @param existingData This is the existing data settings object,
 * which is stored in the database by user.
 * @param incomingData: This is the new incoming data array coming
 * from the result of query from cubejs.
 * @returns Array
 */
export const combineExistingAndNewIncomingPieWidgetsData = (
  existingData: any = {},
  incomingData: Array<any> = []
) => {
  const newArray: any[] = [];

  if (existingData?.dataSettings) {
    // Add all the data from the dataSettings object
    // into the newArray.
    Object.entries(existingData?.dataSettings)?.map(([key]: [any, any]) => {
      const newObject: any = {
        category: key,
        x: key
      };
      newArray?.push(newObject);
    });
  }

  if (incomingData && incomingData?.length) {
    // Add all the data from the incomingData object
    // into the newArray.
    incomingData?.forEach((item: any) => {
      const newObject: any = {
        category: item?.category,
        x: item?.category
      };
      newArray?.push(newObject);
    });
  }

  return getUnique(newArray, 'category');
};

/**
 *
 * @param param0
 * @returns
 */
const AnalyticsPieChartEditor = ({ query, chartSettings, handleUpdate }: AnalyticsProps) => {
  const { resultSet } = useCubeQuery<any>(query);
  const resultSeries = resultSet?.series()[0]?.series;

  const newResultSeries: any = combineExistingAndNewIncomingPieWidgetsData(
    chartSettings,
    resultSeries
  );

  return (
    <>
      {newResultSeries?.map((series: any) => {
        return (
          <WidgetSettingAccordion key={series.category} title={series.category}>
            <SeriesConfigurator
              series={series}
              chartSettings={chartSettings}
              handleUpdate={handleUpdate}
            />
          </WidgetSettingAccordion>
        );
      })}
    </>
  );
};

export default AnalyticsPieChartEditor;
