import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProgressBarState } from './progressBar.types';

const showProgressBarForHandler = (state: ProgressBarState, action: PayloadAction<string>) => {
  state.push(action.payload);
};

const removeProgressBarForHandler = (state: ProgressBarState, action: PayloadAction<string>) => {
  return state.filter((process) => process !== action.payload);
};

const resetProgressBarHandler = () => {
  return [];
};

const initialState: ProgressBarState = [];

const progressBarSlice = createSlice({
  name: 'progressBar',
  initialState: initialState,
  reducers: {
    showProgressBarFor: (state, action) => showProgressBarForHandler(state, action),
    removeProgressBarFor: (state, action) => removeProgressBarForHandler(state, action),
    resetProgressBar: () => resetProgressBarHandler()
  }
});

export const {
  showProgressBarFor: showProgressFor,
  removeProgressBarFor: removeProgressFor,
  resetProgressBar: resetProgress
} = progressBarSlice.actions;
export default progressBarSlice.reducer;
