import { Moment } from 'moment';

export enum VA_EVENT_STATUS {
  INIT,
  FETCHING,
  ERROR,
  READY
}

export enum CPM_STATUS {
  INIT,
  SUCCESS
}

export interface DiagnosisType {
  event_id: number;
  diagnosis: string;
  recommendation: string;
  anomaly: string;
  analyst_notes: string;
}

export interface VAEventsState {
  status: VA_EVENT_STATUS;
  deviceEventsStatus: VA_EVENT_STATUS;
  dataPointsStatus: VA_EVENT_STATUS;
  events: any;
  selectedEvent: any;
  title: string;
  chartData: Array<any>;
  deviceEvents: Array<any>;
  deviceEventsData: any;
  dataPoints: Array<any>;
  devicesWithDatapoints: Array<any>;
  additionalSensorsData: any;
  additionalSensorsDataStatus: VA_EVENT_STATUS;
  startDate: Moment | null;
  endDate: Moment | null;
  dateValue: string | null;
  cpmStatus: CPM_STATUS;
  allEventDiagnosis: Array<DiagnosisType>;
  isMergeAxisSelected: boolean;
}
export const DATE_FORMAT = 'MMM DD,YYYY | hh:mm a';
/**
 * Start end time we need date range
 */
export const DAY_RANGE = 90;
/**
 *
 */
