import { Box } from '@mui/material';
import React from 'react';
import TranslateText from '../../../../i18n/TranslateText';

const WidgetDataUnavailable = () => {
  return (
    <Box textAlign="center">
      {TranslateText('no_data_for_widget_msg', 'No data available for this widget')}
    </Box>
  );
};

export default WidgetDataUnavailable;
