/**
 * Redirection URL Editor
 * @author mahesh.kedari@shorelineiot.com
 */
import { Grid } from '@mui/material';
import React, { ReactElement, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { SLDivider, SLSelect } from '../..';
import {
  DashboardListState,
  DashboardListStatus,
  useDashboardList
} from '../../../../features/dashboard/store';
import { RedirectionOptions } from './RedirectionOptions';
import { RedirectionTypeParametersOptions } from './RedirectionParameterOptions';

interface Props {
  query?: any;
  path: string; // Prefix for Form
  currentValue?: any;
  handleUpdate: (data?: any) => void;
}

interface DashboardMenuItem {
  key: string;
  value: string;
  label: string;
}

export default function RedirectionURLEditor({ path, handleUpdate }: Props): ReactElement {
  const { getValues } = useFormContext();
  const values = getValues();
  const dashboardList: DashboardListState = useDashboardList();
  const [dashboardMenuList, setDashboardMenuList] = useState<Array<DashboardMenuItem>>([]);

  const isRedirectionToDashboard = () => {
    let columnName: string | number;
    let selectedRedirectionType: any;
    if (path?.split('.').length > 1) {
      // Check for Table/Cartesian Chart widget
      columnName = path?.split('.')[1].trim();
      selectedRedirectionType = values?.dataSettings?.[columnName]?.redirection;
      return selectedRedirectionType === 'dashboard';
    }
    // Check for Donut/Pie Chart widget
    columnName = path;
    selectedRedirectionType = values?.[columnName]?.redirection;
    return selectedRedirectionType === 'dashboard';
  };
  const getParametersOptions = () => {
    let columnName: string | number;
    let selectedRedirectionType: any;
    // Checking if the PATH is of Donut & Pie chart OR Table & Cartesian type widget
    if (path?.split('.').length > 1) {
      // Check for Table/Cartesian Chart widget
      columnName = path?.split('.')[1].trim();
      selectedRedirectionType = values?.dataSettings?.[columnName]?.redirection;
      const selectedParameters = RedirectionTypeParametersOptions.find(
        (redirect: any) => redirect.type === selectedRedirectionType
      );
      return selectedParameters?.parameters;
    }
    // Check for Donut/Pie Chart widget
    columnName = path;
    selectedRedirectionType = values?.[columnName]?.redirection;
    const selectedParameters = RedirectionTypeParametersOptions.find(
      (redirect: any) => redirect.type === selectedRedirectionType
    );
    return selectedParameters?.parameters;
  };

  // If parameters option is not present, then assigning the default one
  const parametersOptions =
    getParametersOptions() || RedirectionTypeParametersOptions[0]?.parameters;

  useEffect(() => {
    if (dashboardList.status === DashboardListStatus.READY) {
      const dashboardListArray = dashboardList?.dashboardListData;
      const formattedDashboardList: Array<DashboardMenuItem> = [];
      dashboardListArray.forEach((item: any) => {
        formattedDashboardList.push({
          key: item.dashboard_uuid,
          value: item.dashboard_uuid,
          label: item.name
        });
      });
      formattedDashboardList.sort((a, b) => a?.label?.localeCompare(b?.label));
      setDashboardMenuList(formattedDashboardList);
    }
  }, [dashboardList.status, dashboardList?.dashboardListData]);
  return (
    <>
      <SLDivider textAlign="center">Redirection</SLDivider>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <SLSelect
            label="Redirection Type"
            translationId="redirection_type"
            name={`${path}.redirection`}
            options={RedirectionOptions}
            onSelectChange={handleUpdate}
          />
        </Grid>
        <Grid item xs={6}>
          <SLSelect
            label="Parameters"
            translationId="parameters"
            name={`${path}.params`}
            options={parametersOptions}
            onSelectChange={handleUpdate}
          />
        </Grid>
        {isRedirectionToDashboard() ? (
          <Grid item xs={6}>
            <SLSelect
              label="Dashboard List"
              translationId="dashboard_list"
              name={`${path}.dashboard_id`}
              options={dashboardMenuList}
              onSelectChange={handleUpdate}
            />
          </Grid>
        ) : (
          ' '
        )}
      </Grid>
    </>
  );
}
