/**
 * useGetOrgUuidBySlug custom hook
 * @author prerna.more@shorelineiot.com
 */
import { skipToken } from '@reduxjs/toolkit/query';
import { useSelectedOrg } from '../../features/organization/store';
import { useFetchSuborgs } from '../../features/organization/store/orgs';

/**
 * This hook retrieves the organization UUID based on the provided slug.
 * It checks the selected organization or fetches sub-organizations to find a matching UUID.
 * Returns an empty string if no match is found..
 * @param slug - The slug of the organization or sub-organization.
 * @returns The organization UUID (org_uuid) or an empty string if no match is found.
 */
export const useGetOrgUuidBySlug = (slug: string) => {
  const selectedOrg = useSelectedOrg();
  const { suborgs, isFetchSuborgsSuccess } = useFetchSuborgs(
    slug !== 'org'
      ? {
          slug
        }
      : skipToken
  );
  if (isFetchSuborgsSuccess && suborgs?.length) {
    if (
      selectedOrg?.org_uuid &&
      (selectedOrg?.suborgs?.length || selectedOrg?.org_type === 'org')
    ) {
      const selectedSuborg = suborgs.find((suborg) => suborg.slug === '_phantom');
      return selectedSuborg?.uuid;
    } else {
      const selectedSuborg = suborgs.find((suborg) => suborg.slug === slug);
      if (selectedSuborg) {
        return selectedSuborg.uuid;
      } else {
        return '';
      }
    }
  } else {
    return '';
  }
};
export default useGetOrgUuidBySlug;
