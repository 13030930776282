import { AnyAction } from 'redux';
import * as ACTION_TYPE from './setDefaultDashboard.actionTypes';
import { setDefaultDashboardStatus, setDefaultDashboardState } from './setDefaultDashboard.state';

const initialState: setDefaultDashboardState = {
  status: setDefaultDashboardStatus.INIT,
  setDefaultDashboardData: {}
};

function setDefaultDashboardProgress(state: setDefaultDashboardState): setDefaultDashboardState {
  return {
    ...state,
    status: setDefaultDashboardStatus.PROGRESS
  };
}

function setDefaultDashboardSuccess(
  state: setDefaultDashboardState,
  setDefaultDashboardData: any
): setDefaultDashboardState {
  return {
    ...state,
    status: setDefaultDashboardStatus.SUCCESS,
    setDefaultDashboardData
  };
}

function setDefaultDashboardFailure(state: setDefaultDashboardState): setDefaultDashboardState {
  return {
    ...state,
    status: setDefaultDashboardStatus.ERROR
  };
}

function resetState(state: setDefaultDashboardState): setDefaultDashboardState {
  return {
    ...state,
    status: setDefaultDashboardStatus.INIT,
    setDefaultDashboardData: {}
  };
}
/*
    Reducer is a pure function that takes actions and state as input 
    and returns/passes the modified state to the store.
    It is implemented using switch case to update the state according 
    to the ation type required.
*/

export default function setDefaultDashboardReducer(
  state: setDefaultDashboardState = initialState,
  action: AnyAction
): setDefaultDashboardState {
  switch (action.type) {
    case ACTION_TYPE.SET_DEFAULT_DASHBOARD_PROGRESS:
      return setDefaultDashboardProgress(state);
    case ACTION_TYPE.SET_DEFAULT_DASHBOARD_SUCCESS:
      return setDefaultDashboardSuccess(state, action.payload);
    case ACTION_TYPE.SET_DEFAULT_DASHBOARD_FAILURE:
      return setDefaultDashboardFailure(state);
    case ACTION_TYPE.SET_DEFAULT_DASHBOARD_RESET_STATE:
      return resetState(state);
    default:
      return state;
  }
}
