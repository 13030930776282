/**
 * rule parser for NB config
 * @author ashwini.gawade@shorelineiot.com
 */
import defaultRulesModule from '../json-data/rule-details.json';

interface RuleThreshold {
  name: string;
  level: number;
  value: number;
}
const getThresholds = (conditions: any): Array<{ name: string; level: number; value: number }> => {
  const thresholds = [];
  if (typeof conditions.fatal !== 'undefined') {
    thresholds.push({
      name: 'fatal',
      level: 4,
      value: conditions.fatal
    });
  }
  if (typeof conditions.error !== 'undefined') {
    thresholds.push({
      name: 'error',
      level: 3,
      value: conditions.error
    });
  }
  if (typeof conditions.warning !== 'undefined') {
    thresholds.push({
      name: 'warning',
      level: 2,
      value: conditions.warning
    });
  }
  if (typeof conditions.lowLevel !== 'undefined') {
    thresholds.push({
      name: 'low level',
      level: 1,
      value: conditions.lowLevel
    });
  }
  return thresholds;
};
/* 
const getActions = (actions: any) => {
  const parsedActions: any = {};
  Object.entries(actions).forEach(([key, value]: Array<any>) => {
    const email: any = [];
    const sms: any = [];
    if (Array.isArray(value)) {
      value.forEach((obj: any) => {
        switch (obj.type) {
          case 'email':
            email.push(obj.email);
            break;
          case 'sms':
            sms.push(obj.sms);
            break;
          default:
            break;
        }
      });
    }
  parsedActions[key] = { app_notification_enabled: true, email / * , sms * / };
  });
  return parsedActions;
}; */
export const parseFormToRulesConfig = (
  formObject: any,
  defaultModule: any,
  dpid?: number | string
) => {
  // message: "{'conditions': {0: {'operation_data': {'dpid': ['Missing data for required field.']}}}, 'alert_level': ['Unknown field.'], 'rule_template_uuid': ['Unknown field.'], 'compatible_profiles': ['Unknown field.'], 'enabled_device_count': ['Unknown field.'], 'attached_device_count': ['Unknown field.']}"

  const rulesModule = defaultModule || defaultRulesModule.rules_module;
  // Remove Unwanted keys from Rules Module
  delete rulesModule.alert_level;
  delete rulesModule.compatible_profiles;
  delete rulesModule.enabled_device_count;
  delete rulesModule.attached_device_count;

  const thresholds = getThresholds(formObject.conditions);
  // getAction function needed to be verify
  // const actions = getActions(formObject.actions);
  const { actions } = formObject;
  const operationData = {
    ...rulesModule.conditions[0]?.operation_data,
    dpid,
    thresholds
  };
  const rulesModuleData = {
    ...rulesModule,
    name: formObject.name,
    description: '',
    attributes: {
      multi_device_rule: true,
      sub_type: 'nb_rule'
    },
    rule_type: 'cloud',
    enable_timestamp: new Date().getMilliseconds(),
    compatible_profile_uuids: [formObject.compatible_profile_uuid],
    compatible_device_types: [formObject.compatible_device_type],
    holdoff_time_ms: parseInt(formObject.conditions.holdoff_time_ms, 10),
    conditions: [
      {
        validation_count: 0,
        operator: 'NB_THRESHOLDS',
        operation_data: operationData
      }
    ],
    actions
  };
  return rulesModuleData;
};

/**
 * @param rulesModule
 */

export const parseRulesConfigToForm = (rulesModule: any) => {
  const { conditions, actions } = rulesModule;
  const holdoffTimeMs = rulesModule.holdoff_time_ms;
  const { thresholds } = conditions[0] ? conditions[0].operation_data : undefined;
  const ruleConditions: any = {
    holdoff_time_ms: holdoffTimeMs
  };
  thresholds.forEach((threshold: RuleThreshold) => {
    switch (threshold.level) {
      case 4:
        ruleConditions.fatal = { ...threshold }.value;
        break;
      case 3:
        ruleConditions.error = { ...threshold }.value;
        break;
      case 2:
        ruleConditions.warning = { ...threshold }.value;
        break;
      case 1:
        ruleConditions.lowLevel = { ...threshold }.value;
        break;
      default:
        break;
    }
  });
  const ruleActions: any = {};
  Object.entries(actions).forEach(([key, action]: [string, any]) => {
    const actionArray: { [x: string]: any; type: string }[] = [];
    Object.entries(action).forEach(([actionKey, actionValue]: [string, any]) => {
      if (actionKey !== 'app_notification_enabled') {
        actionValue.forEach((av: any) => {
          actionArray.push({
            type: actionKey,
            [actionKey]: av
          });
        });
      }
    });
    ruleActions[key] = actionArray;
  });
  return { ruleConditions, ruleActions };
};
