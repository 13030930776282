/**
 *
 */
import {
  InputClasses,
  CheckboxClasses,
  Theme,
  InputAdornmentClasses,
  InputLabelClasses
} from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { Colors } from '../../../constants';
/**
 *
 */
export const FormLabelStyle: Partial<
  OverridesStyleRules<keyof InputLabelClasses, 'MuiInputLabel'>
> = {
  root: {
    color: Colors.primaryText,
    fontWeight: 500,
    '&.Mui-disabled': {
      color: Colors.primaryText
    },
    // fontWeight: 300,
    '&$filled': {
      fontWeight: 600
    },
    '&$focused': {
      fontWeight: 600
    }
  },
  asterisk: {
    display: 'none'
  }
};

export const FormInputStyle: Partial<OverridesStyleRules<keyof InputClasses, 'MuiInput'>> = {
  root: {
    '&.Mui-disabled': {
      borderBottomWidth: '0 !important',
      '& .MuiSelect-icon': {
        display: 'none'
      },
      '&:before': {
        borderBottomWidth: '0 !important'
      }
    }
  },
  underline: {
    // borderBottomColor: Colors.secondary,
    '&&&:before': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.42);'
    },
    '&&:after': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.42);'
    }
  },
  focused: {}
};

export const FormCheckboxStyle:
  | Partial<OverridesStyleRules<keyof CheckboxClasses, 'MuiCheckbox', Omit<Theme, 'components'>>>
  | undefined = {
  root: {
    color: Colors.primaryText,
    '&.MuiIconButton-root': {
      '&:hover': {
        backgroundColor: 'transparent'
      }
    },
    '& .MuiFormControlLabel-label': {
      marginTop: '15px'
    }
  }
};

export const FormInputAdornmentStyle:
  | Partial<
      OverridesStyleRules<
        keyof InputAdornmentClasses,
        'MuiInputAdornment',
        Omit<Theme, 'components'>
      >
    >
  | undefined = {
  root: {
    '&.MuiIconButton-root': {
      padding: 0
    },
    marginBottom: '5px',
    color: Colors.primaryText
  }
};
