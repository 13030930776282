/**
 * Battery Widget Styles
 * @author aditys.bhadange@shorelineiot.com
 */
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  containerize: {
    width: '95%',
    margin: 'auto'
  },
  autoMargin: {
    margin: 'auto'
  }
}));
