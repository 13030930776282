/**
 * Users List Saga
 * @author mahesh.kedari@shorelineiot.com
 */
import { all, AllEffect } from 'redux-saga/effects';
import {
  watchActions,
  watchOrgActions,
  watchRolesDetails,
  watchRolesList,
  watchRolesUpdate
} from '../store';

/**
 *
 */
export default function* watchUsersSaga(): Generator<AllEffect<any>> {
  const rolesListSaga = watchRolesList();
  const orgActionsSaga = watchOrgActions();
  const actionsSaga = watchActions();
  const rolesDetailsSaga = watchRolesDetails();
  const rolesUpdateSaga = watchRolesUpdate();
  return yield all([rolesListSaga, orgActionsSaga, actionsSaga, rolesDetailsSaga, rolesUpdateSaga]);
}
