/**
 * SafeSaga handles common scenarios of Saga which needs following operations
 *  1. Show loader
 */
import Bugsnag from '@bugsnag/js';
import { call, put } from 'redux-saga/effects';
import { showProgressFor, removeProgressFor } from '../components/progress-bar';
import { showToast } from '../components/toast/toast.actions';

function* handleError(error: any, customErrorMessage?: string) {
  if (error?.response?.statusCode === 400) {
    yield put(showToast(error?.message || customErrorMessage, 'error', true));
  } else if (error?.response?.statusCode === 500) {
    yield put(showToast(customErrorMessage || 'Internal Server Error', 'error', true));
  } else if (error?.response?.statusCode === 401) {
    yield put(showToast('You are not authorized to perform this operation', 'error', true));
  } else {
    yield put(
      showToast(
        customErrorMessage || 'Unknown error has occurred. Please try after some time',
        'error',
        true
      )
    );
  }
}
/**
 *
 * @param errorHandler
 * @param saga
 * @param args
 */
export interface SafeSagaType {
  saga: any;
  actionKey: string;
  errorHandler?: any;
  cleanup?: any;
  customErrorMessage?: boolean;
  [args: string]: any;
}
export const SafeSaga = (
  saga: any,
  actionKey: string,
  errorHandler?: any,
  cleanup?: any,
  customErrorMessage?: string,
  ...args: any
): any =>
  function* (action: any) {
    yield put(showProgressFor(actionKey));
    try {
      yield call(saga, ...args, action);
    } catch (err: any) {
      // Check error message and display a toast
      if (err) Bugsnag.notify(err);
      yield handleError(err, customErrorMessage);
      if (errorHandler) {
        yield call(errorHandler, ...args, err);
      }
    } finally {
      yield put(removeProgressFor(actionKey));
      if (cleanup) {
        yield call(cleanup, ...args);
      }
    }
  };
