/**
 * Toggle Button Group
 * @author mahesh.kedari@shorelineiot.com
 */
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { withStyles } from '@mui/styles';
/**
 *
 * @param param0
 */
const SLToggleButtonGroup = withStyles((theme) => ({
  root: {
    // height: '2.2em',
    borderRadius: '3em',
    marginLeft: '10px',
    borderColor: theme.palette.primary.main,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.36)',
    border: 'solid 1px',
    '& .MuiToggleButton-root.Mui-selected': {
      borderRadius: '3em'
    },
    '& > button.Mui-selected': {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      borderRadius: '3em',
      height: '2em',
      '&:hover': {
        backgroundColor: theme.palette.primary.main
      }
      // width: '9em !important',
    },
    '& > button': {
      borderRadius: '3em',
      height: '2em',
      border: 'none',
      color: theme.palette.text.primary,
      minWidth: '5em !important'
    }
    // [theme.breakpoints.down('md')]: {
    //    height: '3.5em',
    // },
  }
}))(ToggleButtonGroup);

export default SLToggleButtonGroup;
