/**
 *
 */
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  root: {
    marginTop: '15px',
    display: 'flex',
    '& .Mui-error': {
      fontWeight: 'normal'
    }
  },
  color: {
    width: '85px',
    height: '27px',
    borderRadius: '5px',
    boxShadow: '0 0 0 1px rgba(0,0,0,.1)'
  },
  colorPicker: {
    // padding: '5px',
    background: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    height: '100%',
    zIndex: 2,
    cursor: 'pointer',
    width: 'fit-content'
  }
}));
