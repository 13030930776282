import { TableRow } from '../../feature-configurations/featureConfigurator.types';

export const FEATURE_CONFIGURATIONS_TAG_TYPES = {
  FEATURE_DETAILS: 'FEATURE_DETAILS',
  ORG_FEATURE_LIST: 'ORG_FEATURE_LIST',
  ORG_FEATURES_UPDATE: 'ORG_FEATURES_UPDATE'
};

export interface FeatureResponse {
  uuid: string;
  name: string;
  desc: string;
  config: {
    type: string;
    options: string[];
  };
}

export interface FetchFeaturesListArgs {
  slug: string;
}

interface Feature {
  feature_config: string;
  feature_uuid: string;
  unique_name: string;
}

export interface OrgFeatures {
  suborg_uuid: string;
  features: Feature[];
}
export interface UpdateOrgFeaturesResponse {
  org_uuid: string;
  suborg_uuid: string;
  feature_uuid: string;
  feature_config: string;
  is_active: boolean;
}
export interface UpdateOrgFeaturesArgs {
  slug: string;
  payload: {
    org_features: UpdateOrgFeaturesResponse[];
  };
}

export interface FeatureConfigurationState {
  featureConfigData: TableRow[];
  isSaveDisable: boolean;
  updatedFeatureConfigData: TableRow[];
}
