import { put, takeEvery, all } from 'redux-saga/effects';
import * as actions from './deleteNarrowband.actions';
import * as ACTION_TYPE from './deleteNarrowband.actionTypes';
import {
  GenericObject,
  httpDelete,
  removeProgressFor,
  showProgressFor,
  showToast,
  APISERVICES
} from '../../../../../../../framework';
import { SagaIterator } from 'redux-saga';

const DELETE_NARROWBAND = 'DELETE_NARROWBAND';

function* deleteNarrowbandSaga(action: any) {
  const data = action.payload;
  yield put(showProgressFor(DELETE_NARROWBAND));
  try {
    const response: GenericObject = yield httpDelete(
      `orgs/${data.slug}/devices/${data.deviceId}/nb_modules/manual/${data.sensorModuleUUID}`,
      null,
      APISERVICES.DEVICE_URL
    );
    yield put(actions.deleteNarrowbandSuccess(response));
    yield put(showToast('Narrowband will be deleted shortly!', 'success'));
    // Refresh Datapoint List after Deleting an Narrowband
    // yield put(datapointActions.resetDatapoints());
    // yield put(
    //   datapointActions.fetchDatapoints({
    //     slug: data.slug,
    //     deviceId: data.deviceId,
    //   })
    // );
  } catch (error: any) {
    yield put(showToast('Narrowband could not be deleted!', 'error', true));
    yield put(actions.deleteNarrowbandFailure(error));
  } finally {
    yield put(removeProgressFor(DELETE_NARROWBAND));
  }
}

export function* watchDeleteNarrowbandSagas(): SagaIterator {
  yield all([takeEvery(ACTION_TYPE.DELETE_NARROWBAND_PROGRESS, deleteNarrowbandSaga)]);
}
