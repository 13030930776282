import { AnyAction } from 'redux';
import * as ACTION_TYPE from './deleteDashboard.actionTypes';
/**
 *
 */

export const deleteDashboardProgress = (data: any): AnyAction => {
  return {
    type: ACTION_TYPE.DELETE_DASHBOARD_PROGRESS,
    payload: data
  };
};
export const deleteDashboardSuccess = (data: any): AnyAction => {
  return {
    type: ACTION_TYPE.DELETE_DASHBOARD_SUCCESS,
    payload: data
  };
};

export const deleteDashboardFailure = (error: any): AnyAction => {
  return {
    type: ACTION_TYPE.DELETE_DASHBOARD_FAILURE,
    payload: error
  };
};

export const deleteDashboardReset = (): AnyAction => {
  return {
    type: ACTION_TYPE.DELETE_DASHBOARD_RESET_STATE,
    payload: false
  };
};
