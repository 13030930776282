/**
 * Add Multiple Dashboard Items Saga
 */

import { put, takeEvery, call } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import * as ADD_MULTIPLE_DASHBOARD_ITEMS_ACTION_TYPES from './addMultipleDashboardItems.actionsTypes';
import * as ADD_MULTIPLE_DASHBOARD_ITEMS_ACTIONS from './addMultipleDashboardItems.action';
import {
  GenericObject,
  httpPost,
  removeProgressFor,
  showProgressFor,
  showToast,
  APISERVICES,
  httpPut
} from '../../../../framework';
import { SagaIterator } from 'redux-saga';

function addMultipleDashboardItemsIntoDatabase(data: any, dashboardInfo: any) {
  const rawInput: any = data.dashboard_items;
  const optimizedInput: any = rawInput?.map((item: any) => {
    return {
      config: item?.config
    };
  });
  return httpPut(
    `orgs/${data.slug}/dashboard/${dashboardInfo.dashboard_uuid}/dashboard_items`,
    {
      dashboard_items: optimizedInput
    },
    APISERVICES.HOST_API
  );
}

function addDashboardIntoDatabase(data: any) {
  const { dashboard_data } = data;
  return httpPost(
    `orgs/${data.slug}/dashboard`,
    {
      name: dashboard_data.name,
      role_uuids: dashboard_data.role_uuids,
      meta_data: dashboard_data?.meta_data
    },
    APISERVICES.HOST_API
  );
}

export function* addMultipleDashboardItems(action: AnyAction) {
  try {
    yield put(showProgressFor('ADD_MULTIPLE_DASHBOARD_ITEMS'));
    const dashboardResponse: GenericObject = yield call(addDashboardIntoDatabase, action.payload);

    yield call(addMultipleDashboardItemsIntoDatabase, action.payload, dashboardResponse);

    yield put(showToast('Dashboard created successfully', 'success'));
    yield put(
      // response
      ADD_MULTIPLE_DASHBOARD_ITEMS_ACTIONS.handleAddMultipleDashboardItemsSuccess(dashboardResponse)
    );
  } catch (error: any) {
    yield put(showToast('Add Dashboard failed', 'error'));
    yield put(ADD_MULTIPLE_DASHBOARD_ITEMS_ACTIONS.handleAddMultipleDashboardItemsFailure(error));
  } finally {
    yield put(removeProgressFor('ADD_MULTIPLE_DASHBOARD_ITEMS'));
  }
}

export function* watchAddMultipleDashboardItemsSaga(): SagaIterator {
  yield takeEvery(
    ADD_MULTIPLE_DASHBOARD_ITEMS_ACTION_TYPES.ADD_MULTIPLE_DASHBOARD_ITEMS_PROGRESS,
    addMultipleDashboardItems
  );
}
