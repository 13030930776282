/**
 * Alerts List Status
 * @author shubham.holkar@shorelineiot.com
 */
export enum GET_SENSOR_ALERT_STATUS {
  INIT,
  FETCHING,
  SUCCESS,
  ERROR
}
