/**
 * Asset Widget Data websocket Message Handler.
 * Here we are handling the message from websocket to update the Sensor list asset widget.
 * @author: aditya.bhadange@shorelineiot.com
 */

import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { WebSocketDeviceRelatedData } from './deviceMessageHandler.types';
import {
  WIDGET_TYPE,
  FetchAssetWidgetDataArgs
} from '../../../device/store/types/genericDashboard.types';
import { WEBSOCKET_MODULE_OPTIONS } from '../websocket.constants';
import { setNewDeviceProperties } from '../../../device/store/device.utils';
import { Device } from '../../../device';
import { deviceApi } from '../../../device/store/device.api';

const handleSensorListWidgetDataUpdate = (
  dispatch: ThunkDispatch<any, any, AnyAction>,
  data: WebSocketDeviceRelatedData,
  arg: FetchAssetWidgetDataArgs
) => {
  const { payload, action } = data;
  const sensorToUpdate: Device[] = setNewDeviceProperties(payload?.devices);

  if (action === 'update') {
    dispatch(
      deviceApi.util.updateQueryData('fetchAssetWidgetData', arg, (draftDevices) => {
        const sensorsCachedWidgetData =
          draftDevices.widget_type === WIDGET_TYPE.SNS_LIST ? draftDevices?.data : undefined;

        if (sensorsCachedWidgetData) {
          sensorToUpdate?.forEach((sensor) => {
            const sensorPresentIndex = sensorsCachedWidgetData?.findIndex(
              (sensorData) => sensor.id === sensorData.id
            );
            if (sensorPresentIndex !== -1) {
              sensorsCachedWidgetData[sensorPresentIndex] = sensor;
            }
          });
        }
      })
    );
  } else if (action === 'delete') {
    dispatch(
      deviceApi.util.updateQueryData('fetchAssetWidgetData', arg, (draftDevices) => {
        const sensorsCachedWidgetData =
          draftDevices.widget_type === WIDGET_TYPE.SNS_LIST ? draftDevices?.data : undefined;
        if (sensorsCachedWidgetData) {
          sensorToUpdate?.forEach((sensor) => {
            const sensorPresentIndex = sensorsCachedWidgetData?.findIndex(
              (sensorData) => sensor.id === sensorData.id
            );
            if (sensorPresentIndex !== -1) {
              sensorsCachedWidgetData.splice(sensorPresentIndex, 1);
            }
          });
        }
      })
    );
  }
};

export const assetWidgetDataMessageHandler = (
  dispatch: ThunkDispatch<any, any, AnyAction>,
  data: WebSocketDeviceRelatedData,
  arg: FetchAssetWidgetDataArgs
) => {
  switch (data?.module) {
    case WEBSOCKET_MODULE_OPTIONS.DEVICE.DEVICE_LIST:
      handleSensorListWidgetDataUpdate(dispatch, data, arg);
      break;
    default:
      break;
  }
};
