import { useLocation } from 'react-router';
import {
  ALARM_TYPE,
  ALARM_URL_PARAMS,
  AlarmStatusType,
  AlertLevel,
  ParamsString
} from '../../features/alarms/store';
import { useGetUserGroupDevicePaths } from '../../features/alarms/utils/alarms.utils';

/**
 * A custom hook to extract alarm filter parameters from the URL search string.
 *
 * @returns {object} An object containing the alarm filter parameters extracted
 * from the URL search string.
 *
 * @example
 * const { severity, state } = useGetAlarmFiltersFromURL(); // returns an object with severity and state properties
 *
 * @remarks
 * This hook is useful for extracting alarm filter parameters from the URL
 * search string in a standardized way.
 */
const useGetAlarmFiltersFromURL = (): ParamsString => {
  const location = useLocation();
  const alarmsParams = new URLSearchParams(location.search);

  const { userGroupDevicePaths } = useGetUserGroupDevicePaths();

  const alarmType = alarmsParams.get(ALARM_URL_PARAMS.ALARM_TYPE) as ALARM_TYPE;
  const severity = alarmsParams.get(ALARM_URL_PARAMS.SEVERITY) as AlertLevel;
  const state = alarmsParams.get(ALARM_URL_PARAMS.STATE) as AlarmStatusType;
  const modifiedBy = alarmsParams.get(ALARM_URL_PARAMS.MODIFIED_BY);
  const site = alarmsParams.get(ALARM_URL_PARAMS.SITE);
  const path = alarmsParams.get(ALARM_URL_PARAMS.PATH) || userGroupDevicePaths;
  const occurred = alarmsParams.get(ALARM_URL_PARAMS.OCCURRED);
  const start = alarmsParams.get(ALARM_URL_PARAMS.START);
  const end = alarmsParams.get(ALARM_URL_PARAMS.END);
  const name = alarmsParams.get(ALARM_URL_PARAMS.NAME);
  const id = alarmsParams.get(ALARM_URL_PARAMS.ID);

  const paramsString: ParamsString = {
    ...(severity && severity.length && { severity }),
    ...(state && state.length && { state }),
    ...(modifiedBy && modifiedBy.length && { modifiedBy }),
    ...(site && site.length && { site }),
    ...(occurred && occurred.length && { occurred }),
    ...(path && { path }),
    ...(start && { start }),
    ...(end && { end }),
    ...(alarmType && { alarm_type: alarmType }),
    ...(name && { name }),
    ...(id && { id })
  };

  return paramsString;
};

export default useGetAlarmFiltersFromURL;
