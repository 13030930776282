import { AnyAction } from 'redux';
import * as ACTIONS_TYPES from './rolesUpdate.actionTypes';

export const fetchRolesActions = (data: any): AnyAction => {
  return {
    type: ACTIONS_TYPES.FETCH_ACTIONS_ROLE,
    payload: data
  };
};
/**
 *
 * @param data
 */
export const fetchRolesActionsSuccess = (data: any): AnyAction => {
  return {
    type: ACTIONS_TYPES.FETCH_ACTIONS_ROLE_SUCCESS,
    payload: data
  };
};
/**
 *
 */
export const fetchRolesActionsFailure = (): AnyAction => {
  return {
    type: ACTIONS_TYPES.FETCH_ACTIONS_ROLE_FAILURE
  };
};

export const resetRolesActions = (): AnyAction => {
  return {
    type: ACTIONS_TYPES.FETCH_ACTIONS_ROLE_RESET,
    payload: false
  };
};

export const resourceTypeAction = (resourceType: any, changeFlag: boolean): AnyAction => {
  return {
    type: ACTIONS_TYPES.RESOURCE_TYPE,
    payload: { resourceType, changeFlag }
  };
};

export const editRoles = (): AnyAction => {
  return {
    type: ACTIONS_TYPES.EDIT_ROLES
  };
};

export const crudAllActions = (actionType: any, changeFlag: boolean): AnyAction => {
  return {
    type: ACTIONS_TYPES.CRUD_ALL_ROLES,
    payload: { actionType, changeFlag }
  };
};

export const selectAllActions = (id: any, flag: boolean): AnyAction => {
  return {
    type: ACTIONS_TYPES.SELECT_ALL_ROLES,
    payload: { id, flag }
  };
};
