/**
 * Additional Sensors Actions
 * @author mahesh.kedari@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTIONS from './additionalSensors.actionTypes';
/**
 *
 */
export function fetchSensorSchema(payload: any): AnyAction {
  return {
    type: ACTIONS.FETCH_SENSORS_SCHEMA,
    payload
  };
}
/**
 *
 */
export function handleSensorSchemaSuccess(payload: any): AnyAction {
  return {
    type: ACTIONS.SENSORS_SCHEMA_SUCCESS,
    payload
  };
}
/**
 *
 */
export function handleSensorSchemaFailure(error: any): AnyAction {
  return {
    type: ACTIONS.SENSORS_SCHEMA_FAILURE,
    error
  };
}
/**
 *
 */
export function resetSensorSchema(): AnyAction {
  return {
    type: ACTIONS.SENSORS_SCHEMA_RESET
  };
}
/**
 *
 * @param payload
 */
export function createSensor(payload: any): AnyAction {
  return {
    type: ACTIONS.CREATE_SENSOR,
    payload
  };
}
/**
 *
 * @param payload
 */
export function createSensorSuccess(payload: any): AnyAction {
  return {
    type: ACTIONS.CREATE_SENSOR_SUCCESS,
    payload
  };
}
/**
 *
 */
export function createSensorFailure(error: any): AnyAction {
  return {
    type: ACTIONS.CREATE_SENSOR_FAILURE,
    error
  };
}
/**
 *
 */
export function resetCreateSensorState(): AnyAction {
  return {
    type: ACTIONS.RESET_CREATE_SENSOR
  };
}

export function setDrawerOpenStatus(flag: boolean): AnyAction {
  return {
    type: ACTIONS.SET_DRAWER_OPEN_STATUS,
    payload: flag
  };
}
