/**
 * Parse Series
 * @author mahesh.kedari@shorelineiot.com
 */
import { Annotation, QueryAnnotations } from '@cubejs-client/core';
import isEmpty from 'lodash/isEmpty';
import { getRedirectionPath } from '../common/getRedirectionPath';
import { NavigateFunction } from 'react-router-dom';

/**
 * Check if time dimension is present in query
 */
function checkTimeAxis(resultSet: any): boolean {
  const queryAnnotations: QueryAnnotations = resultSet?.loadResponse?.results[0]?.annotation;
  if (!isEmpty(queryAnnotations?.timeDimensions)) {
    return true;
  }
  if (!isEmpty(queryAnnotations?.dimensions)) {
    const entries = Object.values(queryAnnotations?.dimensions);
    const timeAxisEntry = entries.find((entry: Annotation) => {
      return entry.type === 'time';
    });
    if (timeAxisEntry) {
      return true;
    }
  }
  return false;
}
/**
 * TODO: This function is supposed to get essensial configurations from resultSet and return those to parser.
 * But currently it is also creating axis related configrations which should actually be moved to parser.
 * @param resultSet
 * @returns
 * @author mahesh.kedari@shorelineiot.com
 */
export function parseSeries(
  resultSet: any,
  chartType = 'line',
  chartSettings: any,
  dataSettings: any = {},
  grid: any,
  navigate: NavigateFunction,
  slug: string
) {
  const resultSetSeries = resultSet?.series();
  const dataArray = resultSetSeries[0]?.series;
  const yAxisArray: Array<any> = [];
  const singleYAxisTitle: string =
    chartSettings?.singleYAxis && chartSettings?.singleYAxisTitle
      ? chartSettings?.singleYAxisTitle
      : '';
  const allSeries: Array<any> = resultSetSeries.map((series: any, index: number) => {
    const key = series?.key?.replace(/[.,]/g, '_');
    const currentSeriesSettings = dataSettings?.[key];
    const yAxisConfig = currentSeriesSettings?.yAxis;
    const yAxis: Highcharts.YAxisOptions = {
      title: {
        text:
          singleYAxisTitle || (yAxisConfig?.title?.length > 0 ? yAxisConfig?.title : series.title)
      },
      gridLineWidth: grid?.disable ? 0 : grid?.width || 1,
      tickInterval: yAxisConfig?.tickInterval
        ? Number.parseInt(yAxisConfig?.tickInterval, 10)
        : undefined,
      opposite: yAxisConfig?.opposit,
      visible: !yAxisConfig?.hide,
      min: !yAxisConfig?.min ? 0 : yAxisConfig?.min,
      // startOnTick: yAxisConfig?.min === undefined || yAxisConfig?.min === '', // Reference: https://www.highcharts.com/forum/viewtopic.php?t=34052#p119579
      max: yAxisConfig?.max || null
      // endOnTick: yAxisConfig?.max === undefined || yAxisConfig?.max === '',
    };
    const data = series.series.map((point: any) => point.value);

    const lineColor = dataSettings?.lineData?.find(
      (colorData: any) => colorData?.seriesName === series?.key
    );
    const currentSeries = {
      id: key,
      name: currentSeriesSettings?.name || series.title,
      type: currentSeriesSettings?.type || chartType,
      data,
      color: currentSeriesSettings?.color?.hex || currentSeriesSettings?.color || lineColor?.color,
      yAxis: chartSettings?.singleYAxis ? 0 : index,
      events: {
        click: function click() {
          if (currentSeriesSettings?.redirection) {
            navigate(getRedirectionPath(slug, currentSeriesSettings?.redirection, () => ({})));
          }
        }
      }
    };
    yAxisArray.push(yAxis);
    return currentSeries;
  });
  // Check if xAxis is a time Axis
  const hasTimeAxis = checkTimeAxis(resultSet);
  const xAxisCategories = dataArray
    ?.map((item: any) => {
      return hasTimeAxis ? Date.parse(item.x) : item.x;
    })
    ?.sort((valA: number, valB: number) => {
      return valA - valB;
    });
  return { allSeries, xAxisCategories, yAxisArray, hasTimeAxis };
}
