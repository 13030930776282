import { chunk } from 'lodash';
import { put, takeEvery, call, all } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import { VA_ACTIONS, VAactions } from '../index';

import { getDataPointsParsedList } from '../common/vaUtils';
import { httpGet, SafeSaga, APISERVICES, getUniqueElements } from '../../../../../framework';
import { SagaIterator } from 'redux-saga';

const CHUNK_SIZE = 60;
/**
 *
 * @param slug
 * @param deviceIds
 * @returns
 */

function getHistoricalData(data: any, slug: string, start: any, end: any) {
  return httpGet(
    `orgs/${slug}/devices/${data.deviceId}/datapoints/historical_data_without_sampling`,
    {
      dpids: data.dpids.join(','),
      start,
      end
    },
    APISERVICES.DEVICE_API
  );
}
/**
 *
 * @param action
 */
function* getHistoricalDataSaga(action: AnyAction) {
  const { slug, deviceData, start, end } = action.payload;
  const resp: [] = yield all(
    deviceData.map((data: any) => {
      return call(getHistoricalData, data, slug, start, end);
    })
  );
  const finalData: any = [];
  if (resp && deviceData.length > 0) {
    resp.forEach((dataObj: any, index: number) => {
      finalData.push({
        data: dataObj.data,
        unit: deviceData[index].unit,
        sensorDisplayTitle: deviceData[index].sensorDisplayTitle,
        deviceName: deviceData[index].deviceName,
        SensorData: deviceData[index].SensorData
      });
    });
  }
  yield put(VAactions.fetchHistoricalDataSuccess(finalData));
}
function getDatapoints(slug: string, devices: Array<number>) {
  return httpGet(
    `orgs/${slug}/devices/datapoints?device_ids=${devices.join()}&vibration_analysis=false`,
    null,
    APISERVICES.DEVICE_API
  );
}

function* getDatapointsListForAllDevices(slug: string, devices: Array<any>) {
  /**
   * #SLC-6656 When there are too many devices in org, lambda service dies to fetch the datapoints of all of them together.
   * Hence we need to chunk these devices in batches and make multiple APIs to get the result and then combine it to form
   * appropriate resultset.
   * TODO:  Eventually even this approach may create a lot of junk data on front-end. Hence we would need
   * to find out a better approach to handle this scenario.
   */
  const deviceChunks = chunk(devices, CHUNK_SIZE);
  const datapointsListResponse: Array<{
    datapoints: Array<any>;
  }> = yield all(
    deviceChunks.map((deviceChunk: Array<any>) => {
      return call(
        getDatapoints,
        slug,
        deviceChunk.map((device: any) => device.id)
      );
    })
  );
  // Combine result of all the API calls and create a consolidated list
  const devicesWithDatapoints = getUniqueElements(
    datapointsListResponse
      .map((rawResponse: { datapoints: Array<any> }) => rawResponse.datapoints)
      .flat(),
    'device_id'
  );
  const parsedData = getDataPointsParsedList(devicesWithDatapoints, devices);
  // Accelerometer sensor contains bulk data and showing it on trends chart freezes the entire page.
  // We can anyways show accelerometer raw data of specific event on time domain chart in other section.
  // Hence we need to filter accelerometer from datapoints list.
  const filteredDevicesWithDatapoints = parsedData.filter(
    (datapoint: any) => datapoint?.sensor_type !== 'sns_accelerometer'
  );
  return {
    parsedData: filteredDevicesWithDatapoints,
    devicesWithDatapoints
  };
}
function* getOnlyDataPointsSaga(action: AnyAction) {
  const { slug, devicesList } = action.payload;

  const datapointListMultiDevice: Array<any> = yield getDatapointsListForAllDevices(
    slug,
    devicesList
  );
  // const datapointsResponse: any = yield call(getDatapoints, action.payload);
  // const dataPoints = datapointsResponse.map((point: any) => {
  //   let currentPoint = point;
  //   currentPoint.label = point.dp_name;
  //   currentPoint.value = point.dpid;
  //   return currentPoint;
  // });
  yield put(VAactions.fetchDataPointsTrendChartSuccess(datapointListMultiDevice));
}
/**
 *
 */

function* handleError(error: any) {
  yield put(VAactions.fetchDataPointsTrendChartFailure(error));
}
export function* watchGetDatapointsTrendchartSagas(): SagaIterator {
  yield all([
    takeEvery(
      VA_ACTIONS.FETCH_DATAPOINTS_TREND_CHART,
      SafeSaga(getOnlyDataPointsSaga, VA_ACTIONS.FETCH_DATAPOINTS_TREND_CHART, handleError)
    ),
    takeEvery(
      VA_ACTIONS.FETCH_HISTORICAL_DATA,
      SafeSaga(getHistoricalDataSaga, VA_ACTIONS.FETCH_HISTORICAL_DATA, handleError)
    ),
    takeEvery(
      VA_ACTIONS.UPDATE_DATE_TIME_PICKER_VALUES,
      SafeSaga(getHistoricalDataSaga, VA_ACTIONS.UPDATE_DATE_TIME_PICKER_VALUES, handleError)
    )
  ]);
}
