import { LOCALES } from '../locales';

export default {
  [LOCALES.SPANISH]: {
    welcomeStatement: 'Idioma',
    email: 'Correo Electrónico',
    password: 'Contraseña',
    submit: 'Enviar',
    dashboard: 'Tablero',
    device: 'Dispositivos',
    powertrains: 'Trenes de Potencia',
    device_profile: 'Perfil del Dispositivo',
    profile: 'Perfil',
    sensor_module: 'Módulo de Sensor',
    rules: 'Reglas',
    organization: 'Configuración de la Organización',
    export_data: 'Exportar Datos',
    firmware: 'Firmware',
    workflow: 'Flujo de Trabajo',
    reports: 'Informes',
    settings: 'Personalización',
    user_settings: 'Configuración del Usuario',
    activity_logs: 'Registros de Actividad',
    genai_bot: 'GenAI Bot',
    custom_dashboard: 'Tablero Personalizado',
    alarm: 'Alarmas',
    alerts: 'Alertas',
    snapshot: 'Instantánea',
    assets: 'Activos',
    templates: 'Plantillas',
    addDashboard: 'Agregar Tablero',
    viewAll: 'Ver Todos',
    sensorAlarms: 'Alarmas de Sensores',
    assetAlarms: 'Alarmas de Activos',
    survey: 'Encuestas',
    leak_detection: 'Detección de Fugas',
    user_script: 'Scripts de Usuario',
    event_reports: 'Informes de Eventos',
    device_data: 'Datos del Dispositivo',
    external_data: 'Datos Externos',
    device_mailbox: 'Buzón del Dispositivo',
    component: 'Componente',
    powered_by_shoreline: 'Desarrollado por Shoreline',
    log_in: 'Iniciar Sesión',
    reset_password: 'Restablecer Contraseña',
    register: 'Registrarse',
    next: 'Siguiente',
    help: 'Ayuda',
    settings_button: 'Configuración',
    edit: 'Editar',
    duplicate: 'Duplicar',
    delete: 'Eliminar',
    add_new: 'Agregar Nuevo',
    critical: 'Crítico',
    moderate: 'Moderado',
    high: 'Alto',
    all: 'Todos',
    export: 'Exportar',
    view_active_alarms: 'Ver Alarmas Activas',
    view_historical_alarms: 'Ver Alarmas Históricas',
    reload_page: 'Recargar Página',
    name: 'Nombre',
    state: 'Estado',
    created: 'Creado',
    latest: 'Último',
    modified_by: 'Modificado Por',
    site: 'Sitio',
    path: 'Ruta',
    actions: 'Acciones',
    view_orbit_plot: 'Ver Gráfico de Órbita',
    view_asset_dashboard: 'Ver Tablero de Activos',
    view_sensor_dashboard: 'Ver Tablero de Sensores',
    view_sensor_data: 'Ver Datos del Sensor',
    view_vibration_analysis: 'Ver Análisis de Vibraciones',
    view_waterfall: 'Ver Cascada',
    view_custom: 'Ver Personalizado',
    device_images: 'Imágenes del Dispositivo',
    alarm_notes: 'Notas de Alarma',
    alarm_report: 'Informe de Alarma',
    occurrence: 'Ocurrencia',
    type: 'Tipo',
    last_connected: 'Última Conexión',
    serial_number: 'Número de Serie',
    move_to: 'Mover a',
    move_here: 'Mover Aquí',
    redirect_to_trends_chart: 'Redirigir al Gráfico de Tendencias',
    delete_powertrain: 'Eliminar Tren de Potencia',
    copy_powertrain: 'Copiar Tren de Potencia',
    copy: 'Copiar',
    edit_group: 'Editar Grupo',
    edit_powertrain: 'Editar Tren de Potencia',
    create: 'Crear',
    save: 'Guardar',
    confirmation: 'Confirmación',
    cancel: 'Cancelar',
    download: 'Descargar',
    data: 'Datos',
    activity: 'Actividad',
    vibration_analysis: 'Análisis de Vibraciones',
    anomaly_configurator: 'Configurador de Anomalías',
    trends_analysis: 'Análisis de Tendencias',
    vibration_analysis_message: 'Analizar datos de sensores del dispositivo',
    anomaly_configuration_mesage: 'Configurar Anomalía',
    trends_analysis_message: 'Analizar tendencias utilizando datos históricos',
    start: 'Iniciar',
    powertrain: 'Tren de Potencia',
    unit: 'Unidad',
    anomaly_redirection_message: 'Redirigir a Anomalía V2',
    create_new: 'Crear Nuevo',
    fetch: 'Obtener',
    orbit_plot_redirection_message: 'Redirigir al Gráfico de Órbita',
    waterfall_redirection_message: 'Redirigir a Cascada',
    show: 'Mostrar',
    invite_user: 'Invitar Usuario',
    invite: 'Invitar',
    group: 'Grupo',
    status: 'Estado',
    notifications: 'Notificaciones',
    role_name: 'Nombre del Rol',
    role_description: 'Descripción del Rol',
    user_attached: 'Usuario Adjunto',
    clone: 'Clonar',
    reset_default: 'Restablecer por Defecto',
    create_token: 'Crear Token',
    creator: 'Creador',
    created_on: 'Creado El',
    key: 'Clave',
    label: 'Etiqueta',
    last_used: 'Último Uso',
    category: 'Categoría',
    operation_details: 'Detalles de la Operación',
    user_name: 'Nombre de Usuario',
    timestamp: 'Marca de Tiempo',
    device_name: 'Nombre del Dispositivo',
    site_name: 'Nombre del Sitio',
    age: 'Edad',
    total_data: 'Datos Totales',
    observations: 'Observaciones',
    humidity: 'Humedad',
    link: 'Enlace',
    upload_timestamp: 'Fecha de Subida',
    source: 'Fuente',
    total_records: 'Total de Registros',
    success: 'Éxito',
    failure: 'Fracaso',
    request_type: 'Tipo de Solicitud',
    acknowledged_on: 'Reconocido El',
    request: 'Solicitud',
    response: 'Respuesta',
    upload_firmware: 'Subir Firmware',
    upload: 'Subir',
    version: 'Versión',
    description: 'Descripción',
    model: 'Modelo',
    release_notes: 'Notas de la Versión',
    attach_firmware_message: 'Adjuntar Firmware al dispositivo',
    view: 'Ver',
    run: 'Ejecutar',
    clear: 'Limpiar',
    import_component: 'Importar Componente',
    class: 'Clase',
    sub_class: 'Subclase',
    manufacturer: 'Fabricante',
    last_modified: 'Última Modificación',
    apply: 'Aplicar',
    apply_to_devices: 'Aplicar a Dispositivos',
    create_copy: 'Crear Copia',
    id_provider_name: 'Nombre del Proveedor de ID',
    domain: 'Dominio',
    provider_type: 'Tipo de Proveedor',
    enable_disable: 'Habilitar/Deshabilitar',
    user_notification: 'Notificación de Usuario',
    rule_name: 'Nombre de la Regla',
    level: 'Nivel',
    tools: 'Herramientas',
    edit_dashboard: 'Editar tablero',
    delete_dashboard: 'Eliminar tablero',
    delete_component: 'Eliminar componente',
    delete_condition: 'Eliminar condición',
    delete_trigger: 'Eliminar desencadenador',
    delete_action: 'Eliminar acción',
    edit_group_name: 'Editar nombre del grupo',
    unblock_user: 'Desbloquear usuario',
    block_user: 'Bloquear usuario',
    delete_user: 'Eliminar usuario',
    resend_invite: 'Reenviar invitación',
    cannot_delete_script_based_anomaly: 'No se puede eliminar la anomalía basada en script',
    export_anomaly_data: 'Exportar datos de anomalía',
    delete_variable: 'Eliminar variable',
    remember_me: 'Recuérdame',
    forgot_password: 'Olvidé mi contraseña',
    user_email: 'Correo electrónico del usuario',
    please_select: 'Por favor seleccione',
    select_all: 'Seleccionar todo',
    deselect_all: 'Deseleccionar todo',
    select_the_role: 'Seleccione el rol',
    select_org_suborg_to_invite: 'Seleccione Org/Sub org para invitar',
    power_train: 'Tren de Potencia',
    create_device: 'Crear dispositivo',
    add_powertrain: 'Agregar Tren de Potencia',
    create_power_train: 'Crear Tren de Potencia',
    create_group: 'Crear Grupo',
    device_type: 'Tipo de dispositivo',
    select_a_group: 'Seleccionar un Grupo',
    device_creation_note: 'Nota: Usando el perfil predeterminado para el dispositivo',
    name_of_power_train: 'Nombre del Tren de Potencia',
    enter_name: 'Ingrese Nombre',
    min: 'Mín',
    max: 'Máx',
    rpm_range: 'Rango de RPM',
    override_speed_detection: 'Anular Detección de Velocidad:',
    no: 'No',
    yes: 'Sí',
    manual: 'Manual',
    cpm_value: 'Valor CPM',
    device_datapoint: 'Punto de datos del dispositivo',
    select_device: 'Seleccionar dispositivo',
    select_datapoint: 'Seleccionar punto de datos',
    add_component: 'Agregar componente',
    driver: 'Conductor',
    intermediate: 'Intermedio',
    driven: 'Accionado',
    name_of_component: 'Nombre del componente',
    serial_number_full: 'Número de serie',
    select_manufacturer: 'Seleccionar fabricante',
    select_model: 'Seleccionar modelo',
    sensor_mount_points: 'Puntos de montaje del sensor',
    sensor_mount_point_message:
      'Recomendamos sensores en estos lugares para una mejor cobertura de la máquina. Consulte con soporte si tiene preguntas sobre la cobertura con menos sensores',
    import_manual_narrowband: 'Importar Narrowband manual',
    id: 'ID',
    additional_configuration: 'Configuración adicional',
    accelerometer_microphone: 'Micrófono del acelerómetro',
    logging_time_battery_hr: 'Tiempo de registro: Batería (hr)',
    sampling_interval_battery: 'Intervalo de muestreo: Batería',
    g_range: 'Rango G',
    cloud_update_interval_battery_hr: 'Intervalo de actualización en la nube: Batería (hr)',
    set_the_hour_and_minutes: 'Establecer la hora y los minutos',
    set_the_day_s_of_the_week: 'Establecer el/los día(s) de la semana',
    operating_hours: 'Horas de operación',
    select_the_hour_range: 'Seleccione el rango de horas',
    seconds_ago: 'hace segundos',
    minutes_ago: 'hace minutos',
    hours_ago: 'hace horas',
    days_ago: 'hace días',
    months_ago: 'hace meses',
    years_ago: 'hace años',
    online: 'En línea',
    a_few_seconds_ago: 'hace unos segundos',
    trigger_level: 'Disparador (> Nivel)',
    diagnosis_possible_cause: 'Diagnóstico/Causa posible',
    recommended_actions: 'Acciones recomendadas',
    analyst_notes: 'Notas del analista',
    anomaly: 'Anomalía',
    description_of_closure: 'Descripción del cierre',
    alarms_selected: 'Alarmas seleccionadas',
    acknowledged: 'Reconocido',
    new_alarm: 'Nueva alarma',
    work_in_progress: 'Trabajo en progreso',
    fixed: 'Arreglado',
    false: 'Falso',
    new: 'Nuevo',
    in_progress: 'En progreso',
    alarm_transition: 'Transición de alarma',
    write_here: 'Escribe aquí...',
    reason: 'Razón',
    add_your_notes_here: '¡Agrega tus notas aquí!',
    add_notes: 'Agregar notas',
    title: 'Título',
    report_title: 'Título del informe',
    asset: 'Activo',
    alarm_type: 'Tipo de alarma',
    reported_on: 'Reportado en',
    recommendation: 'Recomendación',
    diagnosis: 'Diagnóstico',
    customer_feedback: 'Comentarios del cliente',
    show_preview: 'Mostrar vista previa',
    click_to_view_pdf_attachment: 'Haga clic para ver el archivo adjunto PDF',
    ok: 'Ok',
    upload_image: 'Subir imagen',
    create_org: 'Crear Org',
    orgnization_name: 'Nombre de la organización',
    sub_orgnization_name: 'Nombre de la suborganización',
    short_name: 'Nombre corto',
    country: 'País',
    create_organization: 'Crear organización',
    create_sub_org: 'Crear Sub Org',
    please_select_sensor: 'Por favor seleccione sensor',
    select_sensor: 'Seleccionar sensor',
    no_data_to_display: 'No hay datos para mostrar',
    fetching_data: 'Recuperando datos',
    add_trace: 'Agregar traza',
    merge_similar_axes: 'Fusionar ejes similares',
    take_tour: 'Hacer tour',
    go_to_device_settings_page: 'Ir a la página de configuración del dispositivo',
    hold_down_shift_key_to_pan: 'Mantén presionada la tecla shift para desplazar',
    mark_narrowband: 'Marcar Narrowband',
    search_narrowbands: 'Buscar Narrowbands',
    go_to_waterfall_page: 'Ir a la página de Waterfall',
    time: 'Tiempo',
    frequency: 'Frecuencia',
    count: 'Cuenta',
    manual_scale_y_axis: 'Escala manual del eje Y',
    indicators: 'Indicadores',
    peak_search: 'Búsqueda de picos',
    harmonics: 'Armónicos',
    sideband: 'Banda lateral',
    none: 'Ninguno',
    center: 'Centro',
    upper: 'Superior',
    lower: 'Inferior',
    delta: 'Delta',
    sliding_window: 'Ventana deslizante',
    delta_markers: 'Marcadores Delta',
    show_all_hide_all: 'Mostrar todo / Ocultar todo',
    unlock_delta_markers: 'Desbloquear marcadores Delta',
    lock_delta_markers: 'Bloquear marcadores Delta',
    click_to_unlock_harmonics: 'Haga clic para desbloquear armónicos',
    set_harmonics_on_chart_to_lock: 'Establecer armónicos en el gráfico para bloquear',
    click_to_unlock_sideband: 'Haga clic para desbloquear banda lateral',
    set_sideband_on_chart_to_lock: 'Establecer banda lateral en el gráfico para bloquear',
    select_trend: 'Seleccionar tendencia',
    waterfall: 'Waterfall',
    orbit_plot: 'Gráfico de órbita',
    trend_chart: 'Gráfico de tendencia',
    select_devices: 'Seleccionar dispositivos',
    devices: 'Dispositivos',
    datapoint: 'Punto de datos',
    date_range: 'Rango de fechas',
    x_unit: 'Unidad X',
    y_unit: 'Unidad Y',
    no_events_found_for_selected_criteria:
      'No se encontraron eventos para los criterios seleccionados',
    events: 'Eventos',
    select_events_to_plot: 'Seleccionar eventos para graficar',
    speed_0: 'Velocidad 0',
    event_count: 'Conteo de eventos',
    skip_count: 'Conteo de omisiones',
    zoom: 'Zoom',
    min_x: 'Mín X',
    max_x: 'Máx X',
    reset_x_zoom: 'Restablecer zoom X',
    invalid_chart_range: 'Rango de gráfico no válido',
    min_y: 'Mín Y',
    max_y: 'Máx Y',
    reset_y_zoom: 'Restablecer zoom Y',
    waterfall_chart: 'Gráfico Waterfall',
    vertical: 'Vertical',
    horizontal: 'Horizontal',
    depth: 'Profundidad',
    view_distance: 'Ver distancia',
    line_gap: 'Espacio entre líneas',
    default: 'Predeterminado',
    odr: 'ODR',
    value: 'Valor',
    phase_estimate: 'Estimación de fase',
    radians: 'radianes',
    degrees: 'grados',
    datapoints: 'Puntos de datos',
    select_datapoints: 'Seleccionar puntos de datos',
    select_range: 'Seleccionar rango',
    select_notification: 'Seleccionar notificación',
    sms: 'SMS',
    app: 'App',
    alert: 'Alerta',
    billing: 'Facturación',
    pending: 'Pendiente',
    send_mail: 'Enviar correo',
    select_group: 'Seleccionar grupo',
    select_sub_org: 'Seleccionar Sub Org',
    filter_by_role: 'Filtrar por rol',
    search_user: 'Buscar usuario',
    search: 'Buscar',
    unblock: 'Desbloquear',
    block: 'Bloquear',
    select_user: 'Seleccionar usuario',
    user_block_msg: '¿Está seguro de que desea bloquear al usuario?',
    user_unblock_msg: '¿Está seguro de que desea desbloquear al usuario?',
    changes_confirmation_msg: '¿Está seguro de que desea realizar los siguientes cambios?',
    type_message_here: 'Escribe el mensaje aquí',
    super_admin: 'Super Admin',
    admin: 'Admin',
    user: 'Usuario',
    site_manager: 'Administrador de sitio',
    owner_account_administrator: 'Administrador de la cuenta del propietario',
    organization_user_can_view_everything: 'El usuario de la organización puede ver todo',
    search_group_powertrain: 'Buscar Grupo/Powertrain',
    org_url: 'URL de la Organización',
    billing_email_address: 'Correo Electrónico de Facturación',
    appearance: 'Apariencia',
    changes_save_msg: '¿Deseas guardar los cambios que has realizado?',
    logo: 'Logo',
    favicon: 'Favicon',
    colours: 'Colores',
    primary_color: 'Color Primario',
    secondary_color: 'Color Secundario',
    text_primary_color: 'Color de Texto Primario',
    text_secondary_color: 'Color de Texto Secundario',
    discard_msg: '¿Estás seguro de que deseas descartar los cambios?',
    discard_changes: 'Descartar Cambios',
    api_token_msg:
      'Usa esta clave API para conectarte con la nube. Guarda la clave API y el secreto en un lugar seguro. Esta es la única vez que verás el secreto.',
    copy_to_clipboard: 'Copiar al portapapeles',
    i_have_saved_my_api_key_and_am_ready_to_continue:
      'He guardado mi clave API y estoy listo para continuar',
    severity: 'Severidad',
    threshold: 'Umbral',
    trigger_value: 'Valor de Activación',
    next_connection: 'Próxima Conexión',
    last_month: 'El Mes Pasado',
    last_24_hours: 'Últimas 24 Horas',
    last_7_days: 'Últimos 7 Días',
    invalid_position_details: 'Detalles de Posición Inválidos',
    no_data_for_widget_msg: 'No hay datos disponibles para este widget',
    unknown_status: 'Estado Desconocido',
    replace_in: 'Reemplazar en',
    days: 'Días',
    cellular_signal: 'Señal Celular',
    device_deletion_msg: 'Todos los datos de este dispositivo serán eliminados, ¿deseas continuar?',
    export_device_data: 'Exportar Datos del Dispositivo',
    date: 'Fecha',
    event: 'Evento',
    no_record_available_for_current_date_and_time:
      'No hay registros disponibles para la fecha y hora actuales',
    please_select_atleast_one_event: 'Por favor, selecciona al menos un evento',
    remove_sensor: 'Eliminar Sensor',
    add_sensor: 'Agregar Sensor',
    apply_this_aggregation_to_all: 'Aplicar esta agregación a todos',
    loading_schema: 'Cargando Esquema',
    view_details: 'Ver Detalles',
    last_update: 'Última Actualización',
    live_data: 'Datos en Vivo',
    historical: 'Histórico',
    no_data_found: 'No se encontraron datos',
    error_while_fetching_data: 'Error al obtener datos',
    no_sensor_seleted_please_select_a_sensor:
      'No se ha seleccionado sensor, por favor selecciona un sensor',
    no_data_found_for_selected_criteria: 'No se encontraron datos para los criterios seleccionados',
    hide: 'Ocultar',
    remove: 'Eliminar',
    reset: 'Restablecer',
    sensor_delete_msg: '¿Estás seguro de que deseas eliminar el sensor?',
    failed_to_fetch_sensor_module: 'Error al obtener el módulo del sensor',
    sensor_type_is_not_supported: 'El tipo de sensor no es compatible',
    general_settings: 'Configuración General',
    delete_device: 'Eliminar Dispositivo',
    rebooting: 'Reiniciando',
    reboot: 'Reiniciar',
    standby_device: 'Dispositivo en Espera',
    firmware_no_more_supported: 'Firmware ya no es compatible',
    firmware_confirmation: '¿Estás seguro de que deseas aplicar el firmware?',
    interface: 'Interfaz',
    ble_mac: 'BLE MAC',
    network: 'Red',
    cellular: 'Celular',
    ethernet: 'Ethernet',
    wifi: 'Wi-Fi',
    network_name: 'Nombre de Red',
    ip_address: 'Dirección IP',
    subnet_mask: 'Máscara de Subred',
    gateway: 'Puerta de Enlace',
    dns_1: 'DNS 1',
    dns_2: 'DNS 2',
    imei_no: 'Número IMEI',
    sim_1: 'SIM 1',
    apn_name: 'Nombre APN',
    network_provider: 'Proveedor de Red',
    imsi_number: 'Número IMSI',
    sim_2: 'SIM 2',
    edit_sim_setting: 'Editar Configuración de SIM',
    sim_1_scan_sequence: 'SIM 1: Secuencia de Escaneo',
    sim_2_scan_sequence: 'SIM 2: Secuencia de Escaneo',
    auto: 'Automático',
    device_photos: 'Fotos del Dispositivo',
    fetching_uploaded_photos: 'Obteniendo fotos subidas',
    overall_machine: 'Máquina en General',
    device_install: 'Instalación del Dispositivo',
    machine_nameplate: 'Placa de Identificación de la Máquina',
    edit_location: 'Editar Ubicación',
    schedule: 'Calendario',
    accelerometer: 'Acelerómetro',
    structureborne_microphone: 'Micrófono Estructural',
    narrowband: 'Banda Estrecha',
    airborne_microphone: 'Micrófono Aéreo',
    cloud_update_interval_power_hr: 'Intervalo de Actualización en la Nube: Energía (h)',
    cloud_update_interval: 'Intervalo de Actualización en la Nube',
    set_default: 'Establecer como Predeterminado',
    same_as_battery: 'Igual que la Batería',
    at_every: 'Cada',
    hour_interval: 'intervalo de hora',
    from: 'de',
    select_the_day_s_of_week: 'Selecciona los días de la semana',
    clear_all: 'Limpiar Todo',
    on_battery: 'Con Batería',
    on_power: 'Con Energía',
    select_the_hour: 'Selecciona la hora',
    select_the_minute: 'Selecciona el minuto',
    set: 'Establecer',
    select_the_interval_hour: 'Selecciona el intervalo (hora)',
    Accelerometer_Structure_borne_Mic: 'Acelerómetro / Micrófono Estructural',
    logging_time_power_min: 'Tiempo de Registro: Energía (min)',
    sampling_interval_power: 'Intervalo de Muestreo: Energía',
    Temperature_Humidity_Airborne_Mic: 'Temperatura/Humedad/Micrófono Aéreo',
    sample_count: 'Conteo de Muestras',
    no_of_reading: 'Número de Lecturas',
    axial_orientation: 'Orientación Axial',
    horizontal_orientation: 'Orientación Horizontal',
    vertical_orientation: 'Orientación Vertical',
    reference_orientation: 'Orientación de Referencia',
    rotating_machine: 'Máquina Rotativa',
    raciprocating_machine: 'Máquina Reciprocante',
    on: 'Encendido',
    off: 'Apagado',
    add: 'Agregar',
    add_manual_narrowband: 'Agregar Banda Estrecha Manual',
    on_cloud: 'En la Nube',
    on_device: 'En el Dispositivo',
    alerts_threshold_are_in_inchhes_seconds: 'Los umbrales de alertas están en pulgadas/segundos',
    automated_narrowband: 'Banda Estrecha Automática',
    manual_narrowband: 'Banda Estrecha Manual',
    order_x_freq_hz: 'Orden X Frecuencia Hz',
    search_anomaly: 'Buscar Anomalía',
    anomaly_export: 'Exportar Anomalía',
    delete_anomaly_msg: '¿Estás seguro de que deseas eliminar la anomalía?',
    sensor: 'Sensor',
    '(none)': '(ninguno)',
    anomaly_name: 'Nombre de la Anomalía',
    skip_review: 'Omitir Revisión',
    enter_expression_msg: 'Ingresa tu Expresión Matemática',
    field_is_required: 'Este campo es obligatorio',
    configure_anomaly: 'Configurar Anomalía',
    add_variable: 'Agregar Variable',
    magnitude: 'Magnitud',
    operation: 'Operación',
    operation_interval: 'Intervalo de Operación',
    select_n: "Seleccionar 'n'",
    amplitude: 'Amplitud',
    amplitude_g: 'Amplitud (G)',
    sum: 'SUMA',
    slope: 'PENDIENTE',
    average: 'PROMEDIO',
    every_n_minutes: 'Cada n minutos',
    every_n_hours: 'Cada n horas',
    every_n_days: 'Cada n días',
    enter_your_mathematical_expression: 'Ingresa tu Expresión Matemática',
    evaluate: 'Evaluar',
    result: 'Resultado',
    validation_count: 'Conteo de Validación',
    alert_level: 'Nivel de Alerta',
    recommended_action: 'Acción Recomendada',
    anomaly_validation_msg: 'El valor debe ser Moderado < Alto < Crítico o estar vacío',
    enter_a_value: 'Ingresa un valor',
    enter_diagnosis_probable_cause: 'Ingresa Diagnóstico/Causa Probable',
    enter_a_recommended_action: 'Ingresa una acción recomendada',
    update: 'Actualizar',
    leave: 'Salir',
    url: 'URL',
    choose_an_option: 'Elige una opción',
    asset_dashboard: 'Panel de Activos',
    sensor_dashboard: 'Panel de Sensores',
    sensor_data: 'Datos del Sensor',
    custom: 'Personalizado',
    asset_list: 'Lista de Activos',
    custom_url: 'URL Personalizado',
    required: 'Requerido',
    invalid_url: 'URL Inválida',
    url_builder: 'Constructor de URL',
    anomaly_edit_message: 'Parece que has estado editando algo.',
    anomaly_leave_confirmation_message: 'Si sales antes de guardar, tus cambios se perderán.',
    create_sso_configuration: 'Crear Configuración SSO',
    edit_sso_configuration: 'Editar Configuración SSO',
    help_page: 'Página de Ayuda',
    custom_provider_type: 'Tipo de Proveedor Personalizado',
    enter_custom_provider_type: 'Ingresa el Tipo de Proveedor Personalizado',
    enter_provider_name: 'Ingresa el Nombre del Proveedor',
    provider_name_already_exists: 'El Nombre del Proveedor ya existe',
    domain_name: 'Nombre del Dominio',
    enter_domain_name: 'Ingresa el Nombre del Dominio',
    sso_note:
      'Nota: Los siguientes campos no son requeridos para el tipo de proveedor Google. Por eso están deshabilitados.',
    metadata_document_source: 'Fuente del Documento de Metadatos',
    enter_url: 'Ingresa URL',
    file_upload: 'Subir Archivo',
    enter_saml_attribute_for_email: 'Ingresa el atributo SAML para el Correo Electrónico',
    enter_saml_attribute_for_given_name: 'Ingresa el atributo SAML para el nombre',
    enter_saml_attribute_for_family_name: 'Ingresa el atributo SAML para el apellido',
    fw_ver: 'Versión de FW',
    batt_used: 'Batería Usada',
    axial: 'Axial',
    surf_temp: 'Temperatura Superficial',
    env_temp: 'Temperatura Ambiental',
    broadband: 'Banda Ancha',
    data_size: 'Tamaño de los Datos (MB)',
    firmware_file: 'Archivo de Firmware',
    choose_file: 'Seleccionar Archivo',
    firmware_name: 'Nombre del Firmware',
    firmware_name_already_exists: 'El Nombre del Firmware ya existe',
    manifest_version: 'Versión del Manifiesto',
    manifest_version_already_exists: 'La Versión del Manifiesto ya existe',
    current_firmware: 'Firmware Actual',
    no_devices_to_display: 'No hay dispositivos para mostrar',
    set_review: 'Establecer Revisión',
    selected: 'Seleccionado',
    subclass: 'Subclase',
    class_subclass_mismatch_msg:
      'La combinación de clase y subclase mencionada anteriormente no existe en nuestros registros. Intenta subir componentes con una clase y subclase compatibles',
    manufacturer_model_mismatch_msg:
      'La combinación mencionada anteriormente ya existe. Intenta nuevamente cambiando el nombre del Fabricante y del Modelo',
    invalid_json_file: 'Archivo JSON inválido',
    rev_id: 'ID de Rev',
    create_component: 'Crear Componente',
    add_mount_point_first: 'Añadir punto de montaje primero',
    add_new_row: 'Añadir nueva fila',
    narrowband_editor: 'Editor de Banda Estrecha',
    feaquency_order: 'Frecuencia/Orden',
    mount_points: 'Puntos de Montaje',
    delete_selected_narrowband_:
      '¿Estás seguro de que deseas eliminar la Banda Estrecha seleccionada?',
    nominal_rpm: 'RPM Nominal',
    attached_device_table: 'Tabla de Dispositivos Adjuntos',
    org: 'Org',
    sub_org: 'Sub Org',
    add_dashboard: 'Añadir Panel',
    default_dashboard: 'Panel Predeterminado',
    set_as_default_dashboard: 'Establecer como panel predeterminado',
    default_dashboard_confirmation_msg:
      '¿Estás seguro de que deseas establecer este panel como predeterminado?',
    delete_dashboard_confirmation_msg: '¿Estás seguro de que deseas eliminar este panel?',
    new_widget_add_msg:
      'Haz clic en el botón “Añadir Nuevo” para añadir nuevos widgets a tu panel.',
    add_widget: 'Añadir Widget',
    raw_data_widget: 'Widget de Datos Crudos',
    map_chart: 'Gráfico de Mapa',
    dial_widget: 'Widget de Dial',
    plant_hralth: 'Salud de la Planta',
    plant_health_trend: 'Tendencia de la Salud de la Planta',
    asset_severity_age: 'Edad de la Severidad del Activo',
    separator: 'Separador',
    assigned_to: 'Asignado a',
    loading: 'Cargando',
    measures: 'Medidas',
    dimensions: 'Dimensiones',
    segments: 'Segmentos',
    for: 'Para',
    by: 'Por',
    filters: 'Filtros',
    fill_missing_dates: 'Rellenar Fechas Faltantes',
    pivot: 'Pivote',
    order: 'Orden',
    limit: 'Límite',
    number: 'Número',
    field: 'Campo',
    record_count: 'Conteo de Registros',
    every: 'Cada',
    expand_dropdown_view: 'Expandir Vista Desplegable',
    max_days_left: 'Máximos Días Restantes',
    sort_by: 'Ordenar Por',
    record_count_error_msg: 'El Conteo de Registros debe estar entre 1 y 5000',
    invalid_chart_type: 'Tipo de Gráfico Inválido',
    widget_type: 'Tipo de Widget',
    no_result_for_selected_criteria: 'No hay resultados para los criterios seleccionados',
    unable_to_fetch_data: 'No se pueden obtener datos para los criterios seleccionados.',
    please_select_a_class_subclass_manufacturer_or_model:
      'Por favor selecciona una Clase, Subclase, Fabricante o Modelo',
    auto_selection_feature_is_yet_to_be_implemented:
      'La función de selección automática aún no está implementada',
    please_select_some_devices: 'Por favor selecciona algunos dispositivos',
    no_alarm_for_selected_criteria: 'No hay Alarmas para mostrar en los Dispositivos seleccionados',
    please_select_widget_type: 'Por favor selecciona el Tipo de Widget',
    only_alarms: 'Solo Alarmas',
    all_devices: 'Todos los Dispositivos',
    location_marker: 'Marcador de Ubicación',
    bubble_marker: 'Marcador de Burbuja',
    bubble_makrer_info_text:
      'Marcador de burbuja: el tamaño de la burbuja aumenta en función de la cantidad de días que las alarmas hayan estado activas. Esto está diseñado para llamar la atención sobre las alarmas que pueden haber quedado abiertas/sin atender durante mucho tiempo.',
    what_is_bubble_marker: '¿Qué es el Marcador de Burbuja?',
    x_axis_title: 'Título del Eje X',
    marker_on_off: 'Marcador Encendido/Apagado',
    grid_off: 'Cuadrícula Apagada',
    grid_width: 'Ancho de la Cuadrícula',
    single_y_axis: 'Eje Y Único',
    single_y_axis_title: 'Título del Eje Y Único',
    crosshair: 'Línea de Cruz',
    configs_3d: 'Configuraciones 3D',
    enable_3d: 'Habilitar 3D',
    configurations_for: 'Configuraciones para',
    hide_y_axis: 'Ocultar Eje Y',
    opposite_side: 'Lado Opuesto',
    tick_interval: 'Intervalo de Ticks',
    y_axis_title: 'Título del Eje Y',
    redirection_type: 'Tipo de Redirección',
    parameters: 'Parámetros',
    dashboard_list: 'Lista de Paneles',
    allow_sorting: 'Permitir Ordenación',
    column_alignment: 'Alineación de la Columna',
    left: 'Izquierda',
    full_row_redirection_: 'Redirección de Fila Completa',
    right: 'Derecha',
    hide_column: 'Ocultar Columna',
    conditional_formatting: 'Formato Condicional',
    add_condition: 'Añadir Condición',
    inner_size: 'Tamaño Interno',
    show_legends: 'Mostrar Leyendas',
    data_label_value_unit: 'Unidad del Valor de la Etiqueta de Datos',
    inner_radius: 'Radio Interno',
    hide_label: 'Ocultar Etiqueta',
    scale_min: 'Escala Mínima',
    scale_max: 'Escala Máxima',
    decimal_point_resolution: 'Resolución de Puntos Decimales',
    height: 'Altura',
    common: 'Común',
    stack_line_chart: 'Gráfico de Líneas Apiladas',
    coming_soon: 'Próximamente',
    zoom_x: 'Zoom X',
    battery_icon: 'Icono de Batería',
    percentage: 'Porcentaje',
    add_fromatter: 'Añadir Formateador',
    display_range: 'Rango de Visualización',
    min_value: 'Valor Mínimo',
    max_value: 'Valor Máximo',
    show_limit_exceeded: 'Mostrar Límite Excedido',
    color_configurator: 'Configurador de Color',
    to: 'Hasta',
    add_a_color_range: 'Añadir un Rango de Color',
    alarm_age: 'Edad de la Alarma',
    select_powertrain: 'Seleccionar Tren de Potencia',
    total_powertrain: 'Tren de Potencia Total',
    in_critical: 'En Crítico',
    in_high: 'En Alto',
    no_alarms: 'Sin Alarmas',
    create_powertrain: 'Crear Tren de Potencia',
    move_to_note: 'Nota: Los Dispositivos/Grupos solo pueden moverse dentro del mismo sitio.',
    sensor_status_count: 'Conteo de Estado de Sensores',
    provisioned: 'Provisionado',
    deprovisioned: 'Desprovisionado',
    never_provisioned: 'Nunca Provisionado',
    provisioning_failed: 'Fallo en el Provisionamiento',
    oldest_connected: 'Más Antiguo Conectado',
    newest_connected: 'Más Nuevo Conectado',
    search_powertrain: 'Buscar Tren de Potencia',
    no_powertrains_associated_msg: 'Nota: No hay Trenes de Potencia asociados a ti.',
    no_records_found_try_removing_filter: 'No se encontraron registros. Intenta eliminar el filtro',
    to_see_any_records_are_available: 'para ver si hay registros disponibles.',
    apply_filter_powertrain:
      'Mostrando los grupos y trenes de potencia asociados a ti. Haz clic en este icono para eliminar el filtro.',
    not_applied_filter_powertrain:
      'Mostrando todos los grupos y trenes de potencia. Haz clic en el icono para ver solo los asociados a ti.',
    more_actions: 'Más Acciones',
    trends_chart: 'Gráfico de Tendencias',
    dashboard_not_available:
      'El Panel de Sensores no está disponible para este tipo de dispositivo',
    sensor_list: 'Lista de Sensores',
    image_and_photos_2d: 'Imágenes y Fotos en 2D',
    previous: 'Anterior',
    no_photos: 'Sin Fotos',
    trends: 'Tendencias',
    no_data_available: 'No Hay Datos Disponibles',
    redirect_to_aset_alarms: 'Redirigir a Alarmas del Activo',
    alarms: 'Alarmas',
    anomaly_definitions: 'Definiciones de Anomalías',
    custom_note_1: 'Nota: El',
    custome_note_2: 'se creará en la',
    sub_organization: 'Sub-Organización seleccionada',
    group_name: 'Nombre del Grupo',
    search_rules: 'Buscar Reglas',
    rule_delete_confirmation_msg: '¿Estás seguro de que deseas eliminar las reglas seleccionadas?',
    level_filters: 'Filtros de Nivel',
    rule_level: 'Nivel de la Regla',
    no_device_found: 'No se Encontraron Dispositivos',
    condition: 'Condición',
    only_5_conditions_are_allowed: 'Solo se permiten 5 condiciones por regla.',
    save_rule: 'Guardar Regla',
    create_action: 'Crear Acción',
    send_email: 'Enviar Correo',
    send_sms: 'Enviar SMS',
    set_variable: 'Establecer Variable',
    set_webhook_trigger: 'Establecer Activador de Webhook',
    no_records_to_display: 'No hay registros para mostrar',
    total_battery_remaining: 'Batería Total Restante %',
    sensor_name: 'Nombre del Sensor',
    replace_battery_days: 'Reemplazar Batería (días)',
    log_battery_replacement: 'Registrar Reemplazo de Batería',
    battery_replaced: 'Batería Reemplazada',
    battery_replacement_msg: 'Por favor confirme si la batería ha sido reemplazada en el sensor.',
    invalid_measure_dimensions_segments_msg:
      'Selección inválida de Medidas / Dimensiones / Segmentos',
    log_out: 'Cerrar Sesión',
    local_time_zone: 'Zona Horaria Local',
    sso_password_msg: 'Cambio de contraseña no permitido ya que SSO está habilitado',
    unit_of_measurement: 'Unidad de Medida',
    languages: 'Idiomas',
    Password: 'Contraseña',
    change: 'Cambiar',
    change_password: 'Cambiar Contraseña',
    old_password: 'Contraseña Anterior',
    new_password: 'Nueva Contraseña',
    confirm_new_password: 'Confirmar Nueva Contraseña',
    from_group: 'De',
    to_group: 'A',
    moving: 'Moviendo',
    y_axis_minimum: 'Escala manual del eje Y - Mínimo',
    y_axis_maximum: 'Escala manual del eje Y - Máximo',
    cloud_update_interval_default_power_msg:
      'La configuración de energía predeterminada es cada intervalo de 1 hora todos los días.',
    power_setting: 'Configuración de energía',
    hour_interval_on: 'intervalo de horas en',
    everyday: 'Todos los dias',
    logging_time_power_msg: 'La configuración de energía predeterminada es cada 1 hora.',
    sampling_interval_power_msg: 'La configuración de energía predeterminada es cada 5 minutos.',
    same_as_power: 'Igual que el poder'
  }
};
