/**
 * Progress Bar Styles
 * @author mahesh.kedari@shorelineiot.com
 */
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
/**
 *
 */
export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'fixed',
    width: '100%',
    zIndex: theme.zIndex.tooltip
  }
}));
