/**
 * Alarms Saga
 * @author mahesh.kedari@shorelineiot.com
 */
import { all } from 'redux-saga/effects';
import { watchAlarmsDetailsSagas } from './alarms-details/alarmsDetails.saga';
import { watchAlarmsExportSagas } from './alarms-export/alarmsExport.saga';

export function* watchAlarmsSaga() {
  yield all([watchAlarmsDetailsSagas(), watchAlarmsExportSagas()]);
}
