/**
 * Invite Accept Failed
 * @author mahesh.kedari@shorelineiot.com
 */
import { Box, Typography } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useSlugContext } from '../../../framework';
import { FailureLarge } from '../../../framework/icons';
import { useTheme } from '@mui/styles';
interface Props {
  errorDescription: string;
}
/**
 *
 * @returns
 */
export default function FederatedAuthFailed({ errorDescription }: Props) {
  const slug = useSlugContext();
  const theme = useTheme();
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <FailureLarge />
      <Typography variant="h2">Authentication Failed</Typography>
      <br />
      <Typography variant="h4" style={{ color: theme.palette.error.main }}>
        {errorDescription}
      </Typography>
      <br />
      <Typography variant="h4">
        Failed to authenticate current user in our system.
        <br />
        Please contact support team for further assistance
      </Typography>
      <br />
      <Typography>
        Try with different email ID? &nbsp;
        <RouterLink to={`/${slug}/auth/login`}>Login</RouterLink>
      </Typography>
    </Box>
  );
}
