/**
 * Converts a given string to snake_case format.
 *
 * @param str - The input string to be converted. If not provided or empty, the function returns the input as is.
 * @returns The string converted to snake_case, with spaces replaced by underscores and camelCase converted to snake_case.
 */
export function toSnakeCase(str?: string) {
  if (!str) return str;
  return str
    .replace(/\s+/g, '_') // Replace spaces with underscores
    .replace(/([a-z])([A-Z])/g, '$1_$2') // Add underscore between camelCase letters
    .toLowerCase(); // Convert the entire string to lowercase
}

export const extractFirstWordToLowercase = (str: string): string => {
  return str.split(' ')[0].toLowerCase(); // Split by space, get the first word, and convert to lowercase
};
