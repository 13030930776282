/**
 * Content Pane Component
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { ReactNode, useState } from 'react';
import { Box } from '@mui/material';
import { useStyles } from './contentPane.styles';
// import Banner from '../../../features/home/Banner';
import { SLBreadcrumb } from '../breadcrumb';
import SLActionBar from '../action-bar/ActionBar';
import { ActionContext } from '../action-bar/actionContext';
/* 
  All individual features will be loaded inside the content-pane.
  Routes have been set here and Links related to each route have been used in sidebar
*/
interface Props {
  children: React.ReactNode;
}
const SLContentPane: React.FC<Props> = ({ children }: Props) => {
  const classes = useStyles();

  const [actions, setActions] = useState<ReactNode>(null);
  return (
    <ActionContext.Provider value={{ actions, setActions }}>
      <div className={classes.content}>
        {/* <Banner /> */}
        <Box display="flex" justifyContent="space-between" mb={1} alignItems="flex-start">
          <SLBreadcrumb />
          <SLActionBar />
        </Box>
        {children}
      </div>
    </ActionContext.Provider>
  );
};

export default SLContentPane;
