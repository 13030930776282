/**
 * Sub-Organisation List Saga
 * @author meghneel.adke@shorelineiot.com
 */
import { takeEvery, call, put } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import * as ACTIONS from './actions/subOrgList.actionTypes';
import * as subOrgActions from './actions/subOrgList.actions';
import * as switchOrgActions from '../org-selector/orgSelector.actions';
import { GenericObject, httpGet, SafeSaga, APISERVICES } from '../../../../framework';
import { SagaIterator } from 'redux-saga';

/**
 *
 */
function getSubOrganisations(data: any) {
  return httpGet(`orgs/${data.slug}/suborgs`, null, APISERVICES.HOST_API);
}

/**
 * get Organisation List
 */
function* getSubOrganisationListSaga(action: AnyAction) {
  const response: GenericObject = yield call(getSubOrganisations, action.payload);
  yield put(subOrgActions.fetchSubOrgListSuccess(response.results));
  // yield put(showToast("Failed to fetch Org List", "error", true));
}
function* errorHandler(err: any) {
  yield put(subOrgActions.fetchSubOrgListFailure(err));
  yield put(switchOrgActions.switchOrgFailure(err));
}
/**
 * Aggregating Organisation List sagas
 */
export function* watchSubOrganisationListSaga(): SagaIterator {
  yield takeEvery(
    ACTIONS.FETCH_SUB_ORG_LIST_PROGRESS,
    SafeSaga(getSubOrganisationListSaga, ACTIONS.FETCH_SUB_ORG_LIST_PROGRESS, errorHandler)
  );
}
