/**
 * SL Waterfall Chart Editor
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { useEffect, useState } from 'react';
import { ChartEditorProps } from '../common/ChartEditorProps';
import {
  DEFAULT_CONFIG_3D,
  WATERFALL_LOCALSTORAGE_3D_CONFIG
} from '../../../../features/workflow/trend-analysis/waterfall/header/waterfall.helper';
import SLChartViewSlider from './SLWaterfallSlider';
import { merge } from 'lodash';
import SLChartXAxisZoomHandler from './SLChartXAxisZoomHandler';
import WaterfallFrequencyUnit from './SLWaterfallUnitSelector';
import { FREQUENCY_UNIT, Y_AXIS_UNIT } from '../../../../features/workflow/trend-analysis/store';
import { Grid } from '@mui/material';

export interface ZoomConfig {
  min: number | null;
  max: number | null;
}

const SLWaterfallChartEditor: React.FC<ChartEditorProps> = ({
  defaultValues,
  handleSubmitForm
}: ChartEditorProps) => {
  const [settings, setSettings] = useState<any>({
    chart_type: 'waterfall',
    chartSettings: Object.keys(defaultValues).length !== 0 ? defaultValues : null
  });

  const getWaterfallConfig = () => {
    const storedObjectString = localStorage.getItem(WATERFALL_LOCALSTORAGE_3D_CONFIG);
    return (
      defaultValues?.config3d ||
      (storedObjectString ? JSON.parse(storedObjectString) : DEFAULT_CONFIG_3D)
    );
  };
  const [config3d, setConfig3d] = React.useState(() => getWaterfallConfig());
  const [xAxisZoomConfig, setXAxisZoomConfig] = useState<ZoomConfig>(
    defaultValues?.xAxisZoomConfig || { min: 0, max: null }
  );
  const [waterfallXUnit, setWaterfallXUnit] = useState<FREQUENCY_UNIT>(
    defaultValues?.waterfallXUnit || FREQUENCY_UNIT.Hz
  );

  const [waterfallYUnit, setWaterfallYUnit] = useState<Y_AXIS_UNIT>(
    defaultValues?.waterfallYUnit || Y_AXIS_UNIT.INCHES_PER_SEC
  );
  useEffect(() => {
    const updatedSettings = merge({}, settings);
    // updating local variable here
    const mergedSettings = {
      ...updatedSettings,
      chartSettings: {
        config3d,
        xAxisZoomConfig,
        waterfallXUnit,
        waterfallYUnit
      }
    };
    handleSubmitForm(mergedSettings);
    setSettings(mergedSettings);
  }, [config3d, xAxisZoomConfig, waterfallXUnit, waterfallYUnit]);

  return (
    <Grid container spacing={2} padding={2}>
      <Grid item xs={12}>
        <SLChartViewSlider setConfig3d={setConfig3d} config3d={config3d} />
      </Grid>
      <Grid item xs={12}>
        <WaterfallFrequencyUnit
          setWaterfallXUnit={setWaterfallXUnit}
          xUnit={waterfallXUnit}
          setWaterfallYUnit={setWaterfallYUnit}
          yUnit={waterfallYUnit}
        />
      </Grid>
      <Grid item xs={12}>
        <SLChartXAxisZoomHandler
          setXAxisZoomConfig={setXAxisZoomConfig}
          xAxisZoomConfig={xAxisZoomConfig}
        />
      </Grid>
    </Grid>
  );
};

export default SLWaterfallChartEditor;
