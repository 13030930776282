/**
 * get Harmonics Points
 * @author mahesh.kedari@shorelineiot.com
 */
import {
  HARMONICS_MUlTIPLIER,
  HARMONICS_MUlTIPLIER_FOR_NEG_VALUE,
  FREQUENCY_UNIT,
  AMPLITUDE_SELECTION,
  HarmonicSeriesPointType,
  HarmonicsSeriesType
} from '../../store';
import {
  getEquivalentValueinHz,
  getEquivalentValueForSelectedFrequencyUnit,
  getAmplitudeSelectionValues,
  getAmplitudeValueinPeak
} from '../../store/narrowbands/narrowbandsHelper';

export function constructSingleHarmonicPoint({
  currentX,
  currentY,
  hzOrCPMValue,
  speedValue,
  peakOrRMSValue,
  currentPointIndex,
  spectrumSeriesName,
  order,
  seriesId
}: {
  currentX: number;
  currentY: number;
  hzOrCPMValue: FREQUENCY_UNIT;
  speedValue: number | undefined;
  peakOrRMSValue: AMPLITUDE_SELECTION;
  currentPointIndex: number;
  spectrumSeriesName: string;
  order: number;
  seriesId: string;
}) {
  let x_hz = 0;
  let x_cpm = 0;
  let x_order = 0;

  let y_peak = 0;
  let y_rms = 0;
  let y_peakToPeak = 0;

  if (hzOrCPMValue) {
    x_hz = getEquivalentValueinHz(currentX, hzOrCPMValue, speedValue);
    // x = getEquivalentValueForSelectedFrequencyUnit(x_hz, hzOrCPMValue, speedValue);
    x_cpm = getEquivalentValueForSelectedFrequencyUnit(x_hz, FREQUENCY_UNIT.CPM, speedValue);
    x_order = getEquivalentValueForSelectedFrequencyUnit(x_hz, FREQUENCY_UNIT.ORDERS, speedValue);
  }
  if (peakOrRMSValue) {
    y_peak = getAmplitudeValueinPeak(currentY, peakOrRMSValue);
    // y = getAmplitudeSelectionValues(y_peak, peakOrRMSValue);
    y_rms = getAmplitudeSelectionValues(y_peak, AMPLITUDE_SELECTION.RMS);
    y_peakToPeak = getAmplitudeSelectionValues(y_peak, AMPLITUDE_SELECTION.PEAK_TO_PEAK);
  }

  const harmonicsPoint: HarmonicSeriesPointType = {
    x: currentX,
    y:
      currentY >= 0
        ? currentY + currentY * HARMONICS_MUlTIPLIER || 0
        : currentY + Math.abs(currentY) * HARMONICS_MUlTIPLIER_FOR_NEG_VALUE || 0,
    marker: {
      enabled: true,
      radius: 6,
      symbol: 'triangle-down'
    },
    color: '#CE0000',
    order: order,
    x_hz,
    x_cpm,
    x_order,
    y_peak,
    y_peakToPeak,
    y_rms,
    spectrumSeriesName: spectrumSeriesName,
    arrayIndex: currentPointIndex,
    seriesId: seriesId
  };
  return harmonicsPoint;
}

export function getSingleSeriesHarmonicsPoints(
  count: number,
  xData: Array<[number, number]>,
  harmonicsx: number,
  series: any,
  hzOrCPMValue: FREQUENCY_UNIT,
  speedValue: number | undefined,
  peakOrRMSValue: AMPLITUDE_SELECTION
): Array<any> {
  const harmonicsPointsArray: Array<HarmonicSeriesPointType> = [];
  let i = 1;

  /*
   * Finding the first closest value index in xData as on different ODR data
   * might defer and we might not find exact harmonicsx value.
   */
  const directMatchIndex = xData.findIndex((item: [number, number]) => item[0] === harmonicsx);
  const lastValue = xData[xData.length - 1];

  const closest = xData.reduce(function (prev, curr) {
    return Math.abs(curr[0] - harmonicsx) < Math.abs(prev[0] - harmonicsx)
      ? curr //currentIndex //curr[0]
      : prev; //currentIndex - 1;
  });

  if (harmonicsx > lastValue[0]) {
    /**
     * did not find the harmonics point in current range
     */
    return [];
  }
  const firstIndexFound = xData.findIndex((item: [number, number]) => item[0] === closest[0]);

  const startingIndex = directMatchIndex > 0 ? directMatchIndex : firstIndexFound;
  let currentPointIndex: any = directMatchIndex > 0 ? directMatchIndex : firstIndexFound;

  if (startingIndex >= 0) {
    while (currentPointIndex < xData.length && i <= count) {
      const harmonicsPoint: HarmonicSeriesPointType = constructSingleHarmonicPoint({
        currentX: xData[currentPointIndex][0],
        currentY: xData[currentPointIndex][1],
        hzOrCPMValue,
        speedValue,
        peakOrRMSValue,
        currentPointIndex,
        spectrumSeriesName: series.name,
        order: i,
        seriesId: series.id
      });
      // we are adding same no of index to show next harmonic point
      currentPointIndex += startingIndex;
      i++;
      harmonicsPointsArray.push(harmonicsPoint);
    }
  }

  return harmonicsPointsArray;
}

export function constructHarmonicSeries({
  seriesName,
  data,
  defaultSeries = false
}: {
  seriesName: string;
  data: Array<HarmonicSeriesPointType>;
  defaultSeries?: boolean;
}) {
  const harmonicSeriesName = defaultSeries === false ? `${seriesName} - harmonics` : 'Harmonics';
  const harmonicsSeries: HarmonicsSeriesType = {
    data: data,
    cropThreshold: 100000,
    type: 'scatter',
    selected: true,
    id: harmonicSeriesName,
    name: harmonicSeriesName,
    actualSeriesName: seriesName,
    showInLegend: false,
    zIndex: 100
  };
  return harmonicsSeries;
}

export function getConvertedHarmonicPoint({
  item,
  frequencyUnit,
  peakOrRMSValue
}: {
  item: HarmonicSeriesPointType;
  frequencyUnit: FREQUENCY_UNIT;
  peakOrRMSValue: AMPLITUDE_SELECTION;
}) {
  let x = 0;
  let y = 0;
  switch (frequencyUnit) {
    case FREQUENCY_UNIT.CPM: {
      x = item.x_cpm;
      break;
    }
    case FREQUENCY_UNIT.ORDERS: {
      x = item.x_order;
      break;
    }
    default: {
      x = item.x_hz;
      break;
    }
  }

  switch (peakOrRMSValue) {
    case AMPLITUDE_SELECTION.RMS: {
      y = item.y_rms;
      break;
    }
    case AMPLITUDE_SELECTION.PEAK_TO_PEAK: {
      y = item.y_peakToPeak;
      break;
    }
    default: {
      y = item.y_peak;
      break;
    }
  }
  return { x, y };
}
