export const formatterOptions = [
  {
    value: 'color',
    label: 'Text Color'
  },
  {
    value: 'bgcolor',
    label: 'Background'
  },
  {
    value: 'icon',
    label: 'Icon'
  },
  { value: 'text', label: 'Text' },
  {
    value: 'prefix',
    label: 'Prefix'
  },
  {
    value: 'suffix',
    label: 'Suffix'
  },
  {
    value: 'format',
    label: 'Format'
  }
];
