/**
 * Default Plant Health Chart Configuration
 * @author mahesh.kedari@shorelineiot.com
 */
import Highcharts from 'highcharts';
import TranslateText from '../../../../../i18n/TranslateText';
/**
 *
 */
export const plantHealthChartConfig = (): Highcharts.Options => {
  return {
    lang: {
      noData: TranslateText('no_data_found', 'No data found')
    },
    noData: {
      style: {
        fontWeight: 'bold',
        fontSize: '15px'
      }
    },
    legend: {
      enabled: true,
      itemStyle: {
        fontWeight: 'bold'
      },
      y: -10
    },
    chart: {
      type: 'pie',
      animation: false,
      zooming: { type: 'x' },
      plotBorderWidth: 0,
      spacingLeft: 0,
      spacingRight: 0,
      marginTop: 0,
      marginLeft: 0,
      marginRight: 0,
      resetZoomButton: {
        position: {
          x: 0,
          y: 0
        }
      }
    },
    tooltip: {
      formatter() {
        return `<b>${this.key} : ${this.y} </b>`;
      },
      style: {
        color: 'white'
      },
      backgroundColor: '#4d4d4d'
    },
    scrollbar: {
      liveRedraw: false
    },
    credits: {
      enabled: false
    },
    accessibility: {
      point: {
        valueSuffix: '%'
      }
    },
    plotOptions: {
      pie: {
        shadow: false,
        allowPointSelect: true,
        size: '80%',
        cursor: 'pointer',
        innerSize: '50%',
        dataLabels: {
          enabled: true,
          distance: 20, // Adjust this value to move labels outside the chart
          format: '{point.name}<br/>{point.y}',
          style: {
            fontSize: '13px'
          }
        }
      }
    },
    series: [
      {
        type: 'pie'
      }
    ]
  };
};
