import Highcharts from 'highcharts/highstock';
import { chartXAxisFormatter, chartXAxisLabelFormatter } from '../../../../helpers';
import { TooltipFormatter } from './TooltipFormatter';
import {
  OrbitPointOptionsObject,
  findLastValidEventId,
  updateQueryParameters
} from '../../../../../features/workflow/trend-analysis/orbit-plot';
import { Location } from 'history';
import { NavigateFunction } from 'react-router-dom';
import TranslateText from '../../../../../i18n/TranslateText';

export interface CustomConfigs {
  /**
   * If true, the event data param will be updated on series click.
   */
  updateEventDataParamOnSeriesClick: boolean;
  initialSelectionDone?: boolean;
  setInitialSelectionDone?: React.Dispatch<React.SetStateAction<boolean>>;
  /**
   * If `true`, the marker will always be shown even if data is too big.
   * Note - This prop is created to control the existing marker behavior
   * in this component. There's issue where marker gets disabled when user zooms in.
   */
  alwaysShowMarker?: boolean;
}

export const lineChartConfig_live_data = (
  unit: string,
  setExtremes: Highcharts.AxisSetExtremesEventCallbackFunction | undefined,
  data:
    | (number | [string | number, number | null] | Highcharts.PointOptionsObject | null)[]
    | undefined,
  getFormattedValueTimestamp: (value?: number, ts?: number) => void,
  navigate?: NavigateFunction,
  location?: Location,
  customConfigs?: CustomConfigs
): Highcharts.Options => {
  /**
   * This function will update the event_id query parameter of the URL.
   * @param event - The event object
   */
  const handleSeriesClick = (event: any) => {
    const eventId = event?.point?.event_id;
    if (eventId && location && navigate) {
      updateQueryParameters(location, navigate, {
        eventId: String(eventId)
      });
      getFormattedValueTimestamp(event?.point?.y, event?.point?.x);
    }
  };

  const MAX_POINTS = 50;
  return {
    lang: {
      noData: TranslateText('no_data_found', 'No data found'),
      resetZoom: 'Reset',
      resetZoomTitle: ''
    },
    noData: {
      style: {
        fontWeight: 'bold',
        fontSize: '15px'
      }
    },
    chart: {
      type: 'line',
      animation: false,
      zooming: {
        type: 'x',
        resetButton: {
          position: {
            align: 'right',
            x: 0,
            y: 0
          },
          theme: {
            fill: '#21436C',
            stroke: 'none',
            style: {
              fontWeight: '900',
              color: 'white',
              backgroundColor: '#21436C'
            },
            states: {
              hover: { fill: '#21436C', style: { color: 'white', backgroundColor: '#21436C' } }
            }
          }
        }
      },
      events: {
        render(event: any) {
          const { series } = this;
          const seriesData: OrbitPointOptionsObject[] = event?.target?.series[0]?.data;
          if (
            seriesData?.length &&
            !customConfigs?.initialSelectionDone &&
            customConfigs?.setInitialSelectionDone &&
            data?.length &&
            series?.length
          ) {
            const searchParams = new URLSearchParams(location?.search);
            const eventIdFromUrl = searchParams.get('eventId');

            // Find the last valid event_id.
            const lastValidEventId = findLastValidEventId(seriesData);

            if (eventIdFromUrl) {
              // Find the point with matching event_id in the tveSeries.
              const matchingEventPointFromUrl = series
                .flatMap((seriesItem) => seriesItem?.data)
                .find((point: any) => String(point?.event_id) === eventIdFromUrl);

              if (matchingEventPointFromUrl) {
                getFormattedValueTimestamp(
                  matchingEventPointFromUrl?.y,
                  matchingEventPointFromUrl?.x
                );
                matchingEventPointFromUrl.select(true);
                customConfigs?.setInitialSelectionDone(true);
              }
            } else if (lastValidEventId && location && navigate) {
              // Find the point with matching event_id in the tveSeries.
              const lastValidEventPointToSelect = series
                .flatMap((seriesItem) => seriesItem?.data)
                .find((point: any) => point?.event_id === lastValidEventId);

              if (lastValidEventPointToSelect) {
                getFormattedValueTimestamp(
                  lastValidEventPointToSelect?.y,
                  lastValidEventPointToSelect?.x
                );
                lastValidEventPointToSelect.select(true);
                customConfigs?.setInitialSelectionDone(true);
              }
            }
          }
        }
      }
    },
    // boost: {
    //   useGPUTranslations: true,
    //   seriesThreshold: 1,
    //   debug: {
    //     timeSetup: true,
    //     timeSeriesProcessing: true,
    //     timeKDTree: true,
    //     timeBufferCopy: true,
    //   },
    // },
    title: {
      text: ''
    },
    xAxis: {
      type: 'linear',
      tickPixelInterval: 150,
      // tickInterval: 24 * 3600,
      crosshair: true,
      labels: {
        autoRotation: [0],
        formatter: chartXAxisFormatter,
        ...chartXAxisLabelFormatter
      },
      events: {
        setExtremes,
        ...(!customConfigs?.alwaysShowMarker && {
          afterSetExtremes(zoomAxis: any) {
            const { min } = zoomAxis;
            const { max } = zoomAxis;
            const axis = zoomAxis.target;

            const { chart } = axis;
            let visiblePoints: any = 0;

            Highcharts.each(axis.series, (obj: any) => {
              Highcharts.each(obj.data, function (p: any) {
                if (p.x >= Math.round(min) && p.x <= Math.round(max)) {
                  visiblePoints += 1;
                }
              });
            });
            if (visiblePoints > MAX_POINTS) {
              chart.series[0]?.update({
                marker: {
                  enabled: false
                }
              });
            }
          }
        })
      },
      minRange: 1
    },
    tooltip: {
      // shared: true,
      shape: 'rect',
      useHTML: true,
      formatter: TooltipFormatter,
      style: {
        color: 'white'
      },
      backgroundColor: '#4d4d4d',
      positioner: function (boxWidth) {
        const chart = this.chart;
        const plotLeft = chart.plotLeft;
        const plotWidth = chart.plotWidth;

        // Calculate the position of the tooltip in the top right
        const x = plotLeft + plotWidth - boxWidth;

        return { x, y: 0 };
      }
    },
    time: {
      useUTC: false
    },
    yAxis: [
      {
        title: {
          text: unit
        },
        height: '75%',
        labels: {
          align: 'left',
          x: -3,
          formatter(this) {
            if (Number.isInteger(this?.value)) {
              return `${this?.value}`;
            }
            return typeof this?.value == 'number' ? this.value.toPrecision(4) : this.value;
          }
        }
      }
    ],
    scrollbar: {
      liveRedraw: false
    },
    exporting: {
      enabled: false
    },
    navigator: {
      enabled: false,
      adaptToUpdatedData: true,
      xAxis: {
        type: 'linear',
        labels: {
          formatter: chartXAxisFormatter,
          ...chartXAxisLabelFormatter
        }
      }
    },
    credits: {
      enabled: false
    },
    ...(customConfigs?.updateEventDataParamOnSeriesClick && {
      plotOptions: {
        series: {
          allowPointSelect: true,
          marker: {
            enabled: true,
            states: {
              select: {
                lineWidth: 2,
                radius: 7
              }
            }
          },
          point: {
            events: {
              unselect(): boolean {
                // Cancel unselect operation if same point in clicked.
                return !this?.selected;
              }
            }
          },
          events: {
            click: handleSeriesClick
          }
        }
      }
    }),
    series: [
      {
        // boostThreshold: 1,
        findNearestPointBy: 'xy',
        allowPointSelect: true,
        turboThreshold: 0,
        data,
        id: 'tve',
        // boostThreshold: 1,
        pointStart: 1329163200000,
        pointInterval: 1000 * 3600, // * 24,
        type: 'line',
        name: 'AI1',
        showInLegend: false,
        cropThreshold: 1000,
        tooltip: {
          valueDecimals: 2
        },
        dataGrouping: {
          enabled: false
        }
      }
    ]
  };
};
