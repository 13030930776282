/**
 * Narrowband rules list reducer
 * @author priyanka.ambawane@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTIONS from './nbRuleUpdate.actionTypes';
import { UpdateNarrowbandRuleState, UpdateNarrowbandRuleStatus } from './nbRuleUpdate.state';

const initialState: UpdateNarrowbandRuleState = {
  status: UpdateNarrowbandRuleStatus.INIT,
  response: []
};
/**
 *
 * @param state
 */
function updateNarrowbandRule(state: UpdateNarrowbandRuleState): UpdateNarrowbandRuleState {
  return { ...state, status: UpdateNarrowbandRuleStatus.IN_PROGRESS };
}
/**
 *
 * @param state
 * @param payload
 */
function updateNarrowbandRuleSuccess(
  state: UpdateNarrowbandRuleState,
  payload: Array<any>
): UpdateNarrowbandRuleState {
  return {
    ...state,
    status: UpdateNarrowbandRuleStatus.READY,
    response: payload
  };
}
/**
 *
 * @param state
 */
function updateNarrowbandRuleFailure(
  state: UpdateNarrowbandRuleState,
  payload: any
): UpdateNarrowbandRuleState {
  return {
    ...state,
    status: UpdateNarrowbandRuleStatus.ERROR,
    response: payload
  };
}
/**
 *
 * @param state
 */
function resetUpdateNarrowbandRule(state: UpdateNarrowbandRuleState): UpdateNarrowbandRuleState {
  return {
    ...initialState
  };
}
/**
 *
 * @param state
 * @param action
 */
export default function updateNarrowbandRuleReducer(
  state: UpdateNarrowbandRuleState = initialState,
  action: AnyAction
): UpdateNarrowbandRuleState {
  switch (action.type) {
    case ACTIONS.UPDATE_NB_RULES:
      return updateNarrowbandRule(state);
    case ACTIONS.UPDATE_NB_RULES_SUCCESS:
      return updateNarrowbandRuleSuccess(state, action.payload);
    case ACTIONS.UPDATE_NB_RULES_FAILURE:
      return updateNarrowbandRuleFailure(state, action.payload);
    case ACTIONS.RESET_UPDATE_NB_RULES_STATE:
      return resetUpdateNarrowbandRule(state);
    default:
      return state;
  }
}
