import { AnyAction } from 'redux';
import {
  fetchAnalogSensorSchemaStatusAI1,
  fetchAnalogSensorSchemaStatusAI2,
  fetchAnalogSensorSchemaState
} from './analogSensor.state';
import * as ACTION_TYPE from './analogSensor.actionTypes';

const initialState: fetchAnalogSensorSchemaState = {
  statusAI1: fetchAnalogSensorSchemaStatusAI1.INIT,
  statusAI2: fetchAnalogSensorSchemaStatusAI2.INIT,
  AI1: {},
  AI2: {},
  defaultAI1: {},
  defaultAI2: {}
};
/**
 *
 * @param state
 */
function fetchAnalogSchemaProgressAI1(
  state: fetchAnalogSensorSchemaState
): fetchAnalogSensorSchemaState {
  return {
    ...state,
    statusAI1: fetchAnalogSensorSchemaStatusAI1.PROGRESS
  };
}
function fetchAnalogSchemaSuccessAI1(
  state: fetchAnalogSensorSchemaState,
  Data: any
): fetchAnalogSensorSchemaState {
  return {
    ...state,
    statusAI1: fetchAnalogSensorSchemaStatusAI1.READY,
    AI1: { Data }
  };
}

function fetchAnalogSchemaFailureAI1(
  state: fetchAnalogSensorSchemaState
): fetchAnalogSensorSchemaState {
  return {
    ...state,
    statusAI1: fetchAnalogSensorSchemaStatusAI1.ERROR
  };
}

function resetAnalogSchemaAI1(state: fetchAnalogSensorSchemaState): fetchAnalogSensorSchemaState {
  return {
    ...state,
    statusAI1: fetchAnalogSensorSchemaStatusAI1.INIT
  };
}

function fetchAnalogSchemaProgressAI2(
  state: fetchAnalogSensorSchemaState
): fetchAnalogSensorSchemaState {
  return {
    ...state,
    statusAI2: fetchAnalogSensorSchemaStatusAI2.PROGRESS
  };
}
function fetchAnalogSchemaSuccessAI2(
  state: fetchAnalogSensorSchemaState,
  Data: any
): fetchAnalogSensorSchemaState {
  return {
    ...state,
    statusAI2: fetchAnalogSensorSchemaStatusAI2.READY,
    AI2: { Data }
  };
}

function fetchAnalogSchemaFailureAI2(
  state: fetchAnalogSensorSchemaState
): fetchAnalogSensorSchemaState {
  return {
    ...state,
    statusAI2: fetchAnalogSensorSchemaStatusAI2.ERROR
  };
}

function resetAnalogSchemaAI2(state: fetchAnalogSensorSchemaState): fetchAnalogSensorSchemaState {
  return {
    ...state,
    statusAI2: fetchAnalogSensorSchemaStatusAI2.INIT
  };
}

function setDefaultAI1(
  state: fetchAnalogSensorSchemaState,
  Data: any
): fetchAnalogSensorSchemaState {
  return {
    ...state,
    defaultAI1: Data
  };
}

function setDefaultAI2(
  state: fetchAnalogSensorSchemaState,
  Data: any
): fetchAnalogSensorSchemaState {
  return {
    ...state,
    defaultAI2: Data
  };
}

export default function fetchAnalogSchemaReducer(
  state: fetchAnalogSensorSchemaState = initialState,
  action: AnyAction
): fetchAnalogSensorSchemaState {
  switch (action.type) {
    case ACTION_TYPE.FETCH_ANALOG_SENSOR_SCHEMA_PROGRESS_AI1:
      return fetchAnalogSchemaProgressAI1(state);
    case ACTION_TYPE.FETCH_ANALOG_SENSOR_SCHEMA_SUCCESS_AI1:
      return fetchAnalogSchemaSuccessAI1(state, action.payload);
    case ACTION_TYPE.FETCH_ANALOG_SENSOR_SCHEMA_FAILURE_AI1:
      return fetchAnalogSchemaFailureAI1(state);
    case ACTION_TYPE.FETCH_ANALOG_SENSOR_RESET_STATE_AI1:
      return resetAnalogSchemaAI1(state);

    case ACTION_TYPE.FETCH_ANALOG_SENSOR_SCHEMA_PROGRESS_AI2:
      return fetchAnalogSchemaProgressAI2(state);
    case ACTION_TYPE.FETCH_ANALOG_SENSOR_SCHEMA_SUCCESS_AI2:
      return fetchAnalogSchemaSuccessAI2(state, action.payload);
    case ACTION_TYPE.FETCH_ANALOG_SENSOR_SCHEMA_FAILURE_AI2:
      return fetchAnalogSchemaFailureAI2(state);
    case ACTION_TYPE.FETCH_ANALOG_SENSOR_RESET_STATE_AI2:
      return resetAnalogSchemaAI2(state);

    case ACTION_TYPE.SET_DEFAULT_AI1:
      return setDefaultAI1(state, action.payload);
    case ACTION_TYPE.SET_DEFAULT_AI2:
      return setDefaultAI2(state, action.payload);

    default:
      return state;
  }
}
