/*  */
/**
 * VA Summary Reducer
 * @author mahesh.kedari@shorelineiot.com
 */
import { AnyAction } from 'redux';
import {
  FFTChartState,
  FFTZoomType,
  FFT_CHART_STATUS,
  FFT_DOMAIN,
  seriesColorMap
} from './fftChart.stateType';
import * as ACTIONS from '../vibrationAnalysis.actionTypes';
import { findPeaks } from './findPeak';
import { PeakDatalabelFormatter } from './PeakDatalabelFormatter';
import { PeakHighlighterConfig } from './peakHighlighter.config';
import { AMPLITUDE_SELECTION, UnitsPayload } from '../va-units/vaUnit.stateType';
import { getEquivalentValueForSelectedFrequencyUnit } from '../narrowbands/narrowbandsHelper';
import {
  constructHarmonicSeries,
  getConvertedHarmonicPoint
} from '../../fft-plot/helpers/getHarmonicsPoints';
import { HarmonicSeriesPointType, HarmonicsSeriesType } from '../index';
const initialState: FFTChartState = {
  status: FFT_CHART_STATUS.INIT,
  domain: FFT_DOMAIN.FREQUENCY,
  fftDataTime: [],
  fftDataFrequency: [],
  fftchartDataTime: [],
  fftchartDataFrequency: [],

  harmonicsList: [],
  allHarmonicsSeries: [],
  harmonicsOriginalList: [],
  spectrumSeriesId: '',
  manualScale: {
    enabled: false,
    min: 0,
    max: 0
  },
  peakSearch: {
    enabled: false,
    count: 5
  },
  zoomInHZ: { minHz: null, maxHz: null }
};

function handleFetchFFTSpectrum(state: FFTChartState): FFTChartState {
  return {
    ...state,
    status: FFT_CHART_STATUS.FETCHING,
    fftDataFrequency: [],
    fftchartDataFrequency: [],
    fftchartDataTime: []
  };
}

function updateFFTChartForZeroDeviceEvent(): FFTChartState {
  return {
    ...initialState,
    status: FFT_CHART_STATUS.FREQUENCY_READY
  };
}

function updateTimeDomainChartForZeroDeviceEvent(): FFTChartState {
  return {
    ...initialState,
    domain: FFT_DOMAIN.TIME,
    status: FFT_CHART_STATUS.TIME_READY
  };
}
function handleUpdateFFTDomain(state: FFTChartState, payload: any) {
  return {
    ...state,
    domain: payload.value
  };
}
function handleUpdateFFTPeakSearch(state: FFTChartState, payload: any) {
  const peakSearch = payload;
  return {
    ...state,
    peakSearch
  };
}
function handleUpdateFFTChartExtremes(state: FFTChartState, payload: FFTZoomType) {
  const zoomInHZ = payload;
  return {
    ...state,
    zoomInHZ
  };
}
function handleUpdateHarmonicsList(state: FFTChartState, payload: any) {
  const { harmonicsData, allHarmonicsSeries } = payload;

  if (allHarmonicsSeries) {
    const fftchartDataFrequency = [...state.fftchartDataFrequency, ...allHarmonicsSeries];

    return {
      ...state,
      harmonicsList: harmonicsData,
      fftchartDataFrequency,
      allHarmonicsSeries
    };
  }
  if (harmonicsData.length === 0) {
    const seriesWithoutHarmonics = state.fftchartDataFrequency.filter(
      (s) => !s.name.includes('harmonics')
    );

    return {
      ...state,
      harmonicsList: harmonicsData,
      fftchartDataFrequency: seriesWithoutHarmonics,
      allHarmonicsSeries: []
    };
  }
  return {
    ...state,
    harmonicsList: harmonicsData
  };
}
function handleUpdateHarmonicsOriginalList(state: FFTChartState, payload: any) {
  const { harmonicsOriginalList } = payload;

  return {
    ...state,
    harmonicsOriginalList
  };
}
function handleUpdateSpectrumSeriesId(state: FFTChartState, payload: any) {
  const spectrumSeriesId = payload;
  return {
    ...state,
    spectrumSeriesId
  };
}

function handleUpdateFFTFrequencyData(state: FFTChartState, payload: any) {
  return {
    ...state,
    ...payload,
    status: FFT_CHART_STATUS.FREQUENCY_READY
  };
}

function handleUpdateFFTTimeData(state: FFTChartState, payload: any) {
  const { data } = payload;
  return {
    ...state,
    status: FFT_CHART_STATUS.TIME_READY,
    fftDataTime: payload.data,
    fftchartDataTime: data
  };
}
function refreshFFTChartDataAsPerChangedValues(
  state: FFTChartState,
  { units }: { units: UnitsPayload }
) {
  const { peakSearch, fftDataFrequency, allHarmonicsSeries, fftDataTime } = state;

  const {
    amplitudeSelection,
    frequencyUnit,
    // enableHarmonics,
    speedValue
  } = units;

  const peakOrRMSValue = amplitudeSelection;
  const hzOrCPMValue = frequencyUnit;
  const fftchartDataFrequency: any = [];
  const seriesMapIdandColor: any = [];
  fftDataFrequency.forEach((spectrumRow: any) => {
    if (spectrumRow.data) {
      let colorMappingId = 1;
      const foundColorSeries = seriesMapIdandColor.find(
        (s: { deviceId: any }) => s.deviceId === spectrumRow.deviceId
      );

      if (!foundColorSeries) {
        // here means next device series
        colorMappingId = seriesMapIdandColor.length + 1;
        seriesMapIdandColor.push({
          id: seriesMapIdandColor.length + 1,
          deviceId: spectrumRow.deviceId
        });
      } else {
        // keeping current if found
        colorMappingId = seriesMapIdandColor.length;
      }

      let updatedData: any = [];
      updatedData = [...spectrumRow.data]; //[selectedUnit]
      const frequency_step = spectrumRow.frequency_step;

      const arrayState: any = [];
      let i: any;
      for (i = 0; i < updatedData.length; i++) {
        const value = updatedData[i];

        const xAxisValue = getEquivalentValueForSelectedFrequencyUnit(
          value[0],
          hzOrCPMValue,
          speedValue
        );
        let yAxisValue = value[1];

        switch (peakOrRMSValue) {
          case AMPLITUDE_SELECTION.RMS: {
            yAxisValue = value[1] * 0.7071;
            break;
          }
          case AMPLITUDE_SELECTION.PEAK_TO_PEAK: {
            yAxisValue = value[1] * 2;
            break;
          }
          default: {
            break;
          }
        }
        arrayState.push([xAxisValue, yAxisValue]);
      }

      if (arrayState && arrayState.length > 0) {
        const currentSeries = {
          data: arrayState,
          findNearestPointBy: 'xy',
          cropThreshold: 100000,
          type: 'line',
          enableMouseTracking: true,
          id: `${spectrumRow.deviceId}-${spectrumRow.dpid}`,
          name: `${spectrumRow.device_name} - ${spectrumRow.raw_dp_name.replace(/[0-9]/g, '')}`,

          color: seriesColorMap(spectrumRow.raw_dp_name.replace(/[0-9]/g, ''), colorMappingId),
          custom: {
            frequency_step: frequency_step || 1,
            showPeaks: !!peakSearch.enabled
          },
          showInLegend: true,
          marker: {
            enabled: false,
            states: {
              hover: {
                enabled: true
              }
            }
          },
          events: {
            hide() {
              // @ts-ignore
              this?.chart?.get(`${this?.userOptions?.id}-peaks`)?.hide();
            },
            show() {
              // @ts-ignore
              const { showPeaks } = this.chart;
              if (showPeaks) {
                // @ts-ignore
                this?.chart?.get(`${this?.userOptions?.id}-peaks`)?.show();
              }
            }
          }
        };
        fftchartDataFrequency.push(currentSeries);
        const currentSeriesPeak = {
          data: findPeaks(arrayState, peakSearch.count),
          type: 'scatter',
          id: `${spectrumRow.deviceId}-${spectrumRow.dpid}-peaks`,
          name: `${spectrumRow.device_name} - ${spectrumRow.raw_dp_name.replace(
            /[0-9]/g,
            ''
          )} - Peaks`,
          color: seriesColorMap(spectrumRow.raw_dp_name.replace(/[0-9]/g, ''), colorMappingId),
          custom: {
            frequency_step: frequency_step || 1
          },
          showInLegend: false,
          visible: !!peakSearch.enabled,
          marker: {
            enabled: true
          },
          dataLabels: [
            {
              enabled: true,
              formatter: PeakDatalabelFormatter
            }
          ]
        };
        fftchartDataFrequency.push(currentSeriesPeak);
      }
    }
  });
  const peakHighlighterSeries = PeakHighlighterConfig([]);
  fftchartDataFrequency.push(peakHighlighterSeries);

  let updatedHarmonicsList: HarmonicSeriesPointType[] = [];
  /**
   * allHarmonicsSeries has length means harmonics are set
   */
  if (allHarmonicsSeries.length > 0) {
    allHarmonicsSeries.forEach((harmonicsPoint: HarmonicsSeriesType, index: number) => {
      const data: Array<HarmonicSeriesPointType> = harmonicsPoint.data.map(
        (item: HarmonicSeriesPointType) => {
          const { x, y } = getConvertedHarmonicPoint({ item, frequencyUnit, peakOrRMSValue });
          const point = {
            ...item,
            x,
            y
          };
          return point;
        }
      );
      if (index === 0) {
        /**
         * getting first updated harmonics series as new
         * as we need latest data on saga level
         */
        updatedHarmonicsList = data;
      }
      const updatedSeries = { ...harmonicsPoint, data };

      fftchartDataFrequency.push(updatedSeries);
    });
  }

  // We need to add default harmonic series as it is need to plot harmonics
  const defaultHarmonicsSeries: HarmonicsSeriesType = constructHarmonicSeries({
    seriesName: 'Harmonics',
    data: [],
    defaultSeries: true
  });
  fftchartDataFrequency.push(defaultHarmonicsSeries);
  return {
    ...state,
    fftchartDataFrequency,
    fftchartDataTime: fftDataTime,
    harmonicsList: updatedHarmonicsList
  };
}

function handleResetFFTChart() {
  return {
    ...initialState
  };
}

export default function VASummaryReducer(
  state: FFTChartState = initialState,
  action: AnyAction
): FFTChartState {
  switch (action.type) {
    case ACTIONS.FETCH_FFT_SPECTRUM:
      return handleFetchFFTSpectrum(state);
    case ACTIONS.UPDATE_FFT_CHART_FOR_ZERO_DEVICE_EVENT:
      return updateFFTChartForZeroDeviceEvent();
    case ACTIONS.UPDATE_TIME_DOMAIN_CHART_FOR_ZERO_DEVICE_EVENT:
      return updateTimeDomainChartForZeroDeviceEvent();
    case ACTIONS.UPDATE_FFT_DOMAIN:
      return handleUpdateFFTDomain(state, action.payload);
    case ACTIONS.UPDATE_FFT_FREQUENCY_DATA:
      return handleUpdateFFTFrequencyData(state, action.payload);
    case ACTIONS.UPDATE_FFT_TIME_DATA:
      return handleUpdateFFTTimeData(state, action.payload);
    case ACTIONS.UPDATE_FFT_PEAK_SEARCH:
      return handleUpdateFFTPeakSearch(state, action.payload);
    case ACTIONS.UPDATE_FFT_ZOOM:
      return handleUpdateFFTChartExtremes(state, action.payload);
    // case ACTIONS.UPDATE_SELECTED_MOTION_UNIT:
    //   return refreshFFTChartDataAsPerChangedValues(state, action.payload);
    case ACTIONS.UPDATE_FREQUENCY_UNIT:
      return refreshFFTChartDataAsPerChangedValues(state, action.payload);
    case ACTIONS.UPDATE_AMPLITUDE_SELECTION:
      return refreshFFTChartDataAsPerChangedValues(state, action.payload);
    case ACTIONS.UPDATE_HARMONICS_LIST:
      return handleUpdateHarmonicsList(state, action.payload);
    case ACTIONS.UPDATE_HARMONICS_ORIGINAL_LIST:
      return handleUpdateHarmonicsOriginalList(state, action.payload);
    case ACTIONS.UPDATE_SPECTRUM_SERIES_ID:
      return handleUpdateSpectrumSeriesId(state, action.payload);
    case ACTIONS.RESET_VIBRATION_ANALYSIS:
    case ACTIONS.RESET_FFT_CHART:
      return handleResetFFTChart();
    case ACTIONS.UPDATE_CPM_SUCCESS:
      return { ...state, status: FFT_CHART_STATUS.INIT };

    default:
      return state;
  }
}
