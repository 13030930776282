/**
 * Anomaly Templates Saga
 * @author aditya.bhadange@shorelineiot.com
 */
import { all } from 'redux-saga/effects';
import {
  watchFetchAnomalyTemplateDetailsSaga,
  watchUpdateAnomalyTemplateDetailsSaga
} from './anomaly-templates-details';
import { watchFetchAnomalyTemplatesListSaga } from './anomaly-templates-list';
import { watchCreateAnomalyTemplateSaga } from './create-anomaly-template';
import { watchDeleteAnomalyTemplateSaga } from './delete-anomaly-template';
/**
 *
 */
export function* anomalyTemplatesSaga() {
  yield all([
    watchFetchAnomalyTemplateDetailsSaga(),
    watchUpdateAnomalyTemplateDetailsSaga(),
    watchFetchAnomalyTemplatesListSaga(),
    watchCreateAnomalyTemplateSaga(),
    watchDeleteAnomalyTemplateSaga()
  ]);
}
