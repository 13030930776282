import { AnyAction } from 'redux';
import * as ACTION_TYPES from './getSurveyAnswers.actionTypes';

export const getSurveyAnswersProgress = (slug: string, deviceId: string | number): AnyAction => {
  return {
    type: ACTION_TYPES.GET_SURVEY_ANSWERS,
    payload: {
      slug,
      deviceId
    }
  };
};
/**
 *
 * @param data
 */
export const getSurveyAnswersSuccess = (data: any): AnyAction => {
  // returns the action type
  return {
    type: ACTION_TYPES.GET_SURVEY_ANSWERS_SUCCESS,
    data
  };
};
/**
 *
 * @param error
 */
export const getSurveyAnswersFailure = (error: any): AnyAction => {
  // returns the action type
  return {
    type: ACTION_TYPES.GET_SURVEY_ANSWERS_FAILURE,
    error
  };
};
/**
 *
 * @param data
 */

export const getSurveyAnswersReset = (): AnyAction => {
  return {
    type: ACTION_TYPES.GET_SURVEY_ANSWERS_RESET
  };
};
