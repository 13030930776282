import { AnyAction } from 'redux';
import * as ACTION_TYPE from './actions/getSurveyAnswers.actionTypes';
import { getSurveyAnswersState } from './types/getSurveyAnswersState';
import { GET_SURVEY_ANSWERS_STATUS } from './types/getSurveyAnswersStatus';

const initialState: getSurveyAnswersState = {
  status: GET_SURVEY_ANSWERS_STATUS.INIT,
  surveyAnswers: {}
};

function getSurveyAnswersStateProgress(state: getSurveyAnswersState): getSurveyAnswersState {
  return {
    ...state,
    status: GET_SURVEY_ANSWERS_STATUS.PROGRESS
  };
}

function getSurveyAnswersStateSuccess(
  state: getSurveyAnswersState,
  action: AnyAction
): getSurveyAnswersState {
  const { data } = action;
  if (data.survey_questions) {
    const surveyQuestions = data.survey_questions;
    delete surveyQuestions.visibleIf;
    data.survey_questions = surveyQuestions;
  }
  return {
    ...state,
    status: GET_SURVEY_ANSWERS_STATUS.COMPLETED,
    surveyAnswers: data
  };
}

function getSurveyAnswersStateFailure(state: getSurveyAnswersState): getSurveyAnswersState {
  return {
    ...state,
    status: GET_SURVEY_ANSWERS_STATUS.ERROR
  };
}

function resetGetSurveyAnswersState() {
  return {
    ...initialState
  };
}
/**
 *
 * @param state
 * @param action
 */
export default function getSurveyAnswersReducer(
  state: getSurveyAnswersState = initialState,
  action: AnyAction
): getSurveyAnswersState {
  switch (action.type) {
    case ACTION_TYPE.GET_SURVEY_ANSWERS:
      return getSurveyAnswersStateProgress(state);
    case ACTION_TYPE.GET_SURVEY_ANSWERS_SUCCESS:
      return getSurveyAnswersStateSuccess(state, action);
    case ACTION_TYPE.GET_SURVEY_ANSWERS_FAILURE:
      return getSurveyAnswersStateFailure(state);
    case ACTION_TYPE.GET_SURVEY_ANSWERS_RESET:
      return resetGetSurveyAnswersState();
    default:
      return state;
  }
}
