/**
 * Create Sub organisation Action Types
 * @author mahesh.kedari@shorelineiot.com
 */
export const SUB_ORG_CREATE = 'FEATURES/ORGANISATION/SUB_ORG/CREATE';
export const SUB_ORG_SUCCESS = 'FEATURES/ORGANISATION/SUB_ORG/SUCCESS';
export const SUB_ORG_FAILURE = 'FEATURES/ORGANISATION/SUB_ORG/FAILURE';

export const SUB_ORG_INIT = 'FEATURES/ORGANISATION/SUB_ORG/INIT';
export const SUB_ORG_RESET = 'FEATURES/ORGANISATION/SUB_ORG/RESET';
