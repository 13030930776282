export enum setDefaultDashboardStatus {
  INIT,
  PROGRESS,
  SUCCESS,
  ERROR
}
export interface setDefaultDashboardState {
  status: setDefaultDashboardStatus;
  setDefaultDashboardData: any;
}
