/**
 * Custom Header Component
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { ReactElement, MouseEvent, useState, useEffect } from 'react';
import { Hidden, IconButton, Link } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import { useDispatch } from 'react-redux';
// import * as AUTH_ACTIONS from '@slcloud/shared/features/auth/login/actions/login.actions';
import { SLHeader, Filler, SLOptionMenu, useSlugContext, EmptyFunction } from '../../../framework';
import Logo from '../../../framework/components/header/logo/Logo';
// import GlobalSearch from '../../../framework/components/header/global-search/GlobalSearch';
import Help from '../../../framework/components/header/help/Help';
import CustomNotification from './CustomNotification';
import UserProfile from '../../../framework/components/header/user-profile/UserProfile';
import OptionMenuList from '../../../config/OptionMenuList';
import { loginActions } from '../../auth/login/store/actions';
import { AuthService } from '../../../framework/services/auth.service';
import { useUserSelf } from '../../user-settings/store';
import Banner from '../Banner';
import { useStyles } from './customHeader.styles';
import WhatsNewIconComponent from '../../../framework/components/header/ReleaseNote/WhatsNewIconComponent';
import { fetchUserAttributes, FetchUserAttributesOutput } from 'aws-amplify/auth';
import TranslateText from '../../../i18n/TranslateText';

interface Props {
  isDesktop: boolean;
  onSidebarOpen: (event: MouseEvent) => void;
}

export default function CustomHeader({ isDesktop, onSidebarOpen }: Props): ReactElement {
  const authService = new AuthService();
  const { slug } = useSlugContext();
  const dispatch = useDispatch();

  const [username, setUsername] = useState('');
  const userSelf = useUserSelf();
  const classes = useStyles();

  useEffect(() => {
    fetchUserAttributes().then((user: FetchUserAttributesOutput) => {
      if (user.given_name) {
        setUsername(user.given_name);
      } else if (user.family_name) {
        setUsername(user.family_name);
      }
    });
  }, []);

  const handleLogout = () => {
    // Auth.signOut();
    authService.signOutCurrentUser();
    dispatch(loginActions.logout(false));
    // Remove User Details
    localStorage.clear();
    // Clear fedarated session in cognito
    authService.navigateToFederatedLogOut();
  };

  return (
    <SLHeader isDesktop>
      <Hidden lgUp>
        <IconButton color="inherit" onClick={onSidebarOpen} size="large">
          <MenuIcon />
        </IconButton>
      </Hidden>
      <Hidden>
        <Link href={`/${slug}/app/`}>
          <Logo />
        </Link>
      </Hidden>
      <Banner alertClassName={classes.banner} />
      {/* TODO: we need this for adjusting the space between logo and right side buttons
      Enable this when, banner will be removed from this position
      */}
      <Filler />
      <Hidden lgDown>
        <WhatsNewIconComponent />
      </Hidden>
      <Hidden lgDown>
        {/* <GlobalSearch /> */}
        <Help />
      </Hidden>
      <Hidden lgUp>
        <IconButton color="inherit" onClick={EmptyFunction} size="large">
          <SearchIcon />
        </IconButton>
      </Hidden>
      <CustomNotification />
      <UserProfile userName={userSelf.self?.first_name}>
        <OptionMenuList />
        <SLOptionMenu action={handleLogout}>{TranslateText('log_out', 'Log Out')}</SLOptionMenu>
      </UserProfile>
    </SLHeader>
  );
}
