/**
 * Signup Validation
 * @author mahesh.kedari@shorelineiot.com
 */
export const rules = {
  email: {
    required: 'Email is required',
    minLength: {
      value: '6',
      message: 'Minimum 6 characters are required'
    },
    maxLength: {
      value: '64',
      message: 'Maximum 64 characters are allowed'
    },
    pattern: {
      value: /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/,
      message: 'Enter a valid email address'
    }
  },
  password: {
    required: 'Password is required'
  }
};
