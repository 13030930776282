import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginRight: '10px',
      '&.MuiButton-contained': {
        color: theme.palette.primary.main,
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.background.default
      }
    },
    fieldWidth: {
      width: '12.5vw'
    },
    widgetSelectWidth: {
      width: '27.5vw'
    },
    isHide: {
      marginTop: '17px'
    }
  })
);
