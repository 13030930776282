import Bugsnag from '@bugsnag/js';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import CryptoJS from 'crypto-js';
import {
  APISERVICES,
  fileUploadAxiosPut,
  handleError,
  httpPost,
  removeProgressFor,
  showProgressFor,
  showToast
} from '../../../../framework';
import {
  ALARM_TAG,
  CreateAlarmNoteArgs,
  CreateAlarmNoteResponse,
  FetchAlarmNotesArgs,
  FetchAlarmTimelineArgs,
  NoteType
} from '../alarms.types';
import { buildQuery } from '../../../../framework/lib/rtk/RTK.utils';
import { HTTP_METHOD } from '../../../../framework/services/auth.service';

export function buildAlarmNotesApiEndpoint(slug: string, alarm_id: number) {
  return `orgs/${slug}/alarm_notes/${alarm_id}` as const;
}

export function buildAlarmTimelinesApiEndpoint(slug: string, alarm_id: number) {
  return `orgs/${slug}/device_alarms/${alarm_id}/state_severity_timeline` as const;
}
export async function CreateAlarmNoteQuery(
  args: CreateAlarmNoteArgs,
  dispatch: ThunkDispatch<any, any, AnyAction>,
  actionKey: string
) {
  dispatch(showProgressFor(actionKey));
  try {
    const { slug, alarm_id, payload } = args;
    const actualPayload = {
      user_id: payload?.user_id,
      note_type: payload?.note_type,
      content: payload.content
    };

    const uploadPhotoResponse: CreateAlarmNoteResponse = await httpPost(
      `orgs/${slug}/alarm_notes/${alarm_id}`,
      actualPayload,
      APISERVICES.DEVICE_API
    );
    if (payload.note_type.startsWith(NoteType.Image) || payload.note_type === NoteType.PDF) {
      const md5Hash = CryptoJS.MD5(JSON.stringify(payload.file)).toString();
      await fileUploadAxiosPut(uploadPhotoResponse.put_object_url || '', payload.file, {
        'Content-Type': payload.note_type
      });
    }

    return { data: uploadPhotoResponse };
  } catch (error: any) {
    Bugsnag.notify(error);
    dispatch(showToast('Failed to upload file', 'error', true));
    handleError(error, dispatch);
    return { error: error?.response || 'error' };
  } finally {
    dispatch(removeProgressFor(actionKey));
  }
}

export function fetchAlarmNotes({ slug, alarm_id }: FetchAlarmNotesArgs) {
  return buildQuery({
    apiEndpoint: buildAlarmNotesApiEndpoint(slug, alarm_id),
    method: HTTP_METHOD.GET,
    service: APISERVICES.DEVICE_API,
    actionKey: ALARM_TAG.ALARM_NOTES
  });
}

export function fetchAlarmTimelines({ slug, alarm_id }: FetchAlarmTimelineArgs) {
  return buildQuery({
    apiEndpoint: buildAlarmTimelinesApiEndpoint(slug, alarm_id),
    method: HTTP_METHOD.GET,
    service: APISERVICES.DEVICE_API,
    actionKey: ALARM_TAG.ALARM_TIMELINE
  });
}
