/**
 * Get Dashboard List Actions
 * @author harsha.gokhale@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTION_TYPES from './dashboardList.actionsTypes';
/**
 *
 */
export function getDashboardList(data: any): AnyAction {
  return {
    type: ACTION_TYPES.DASHBOARD_LIST_PROGRESS,
    payload: data
  };
}
/**
 *
 * @param data data to be returned on successful fetch API call
 */
export function handleGetDashboardListSuccess(data: any): AnyAction {
  return {
    type: ACTION_TYPES.DASHBOARD_LIST_SUCCESS,
    payload: data
  };
}
/**
 *
 */
export function handleGetDashboardListFailure(error: any): AnyAction {
  return {
    type: ACTION_TYPES.DASHBOARD_LIST_FAILURE,
    payload: error
  };
}

export function handleDashboardListUpdate(data: any): AnyAction {
  return {
    type: ACTION_TYPES.DASHBOARD_LIST_UPDATE,
    payload: data
  };
}

export const resetState = (): AnyAction => {
  return {
    type: ACTION_TYPES.DASHBOARD_LIST_RESET_STATE,
    payload: false
  };
};
