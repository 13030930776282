/**
 * Column Alignment Editor
 * @author aditya.bhadange@shorelineiot.com
 */
import React, { ReactElement, useState } from 'react';
import { SLSelect } from '../../../../form';
import TranslateText from '../../../../../../i18n/TranslateText';

interface Props {
  value: boolean;
  handleUpdate: (data: any) => void;
}

export default function ColumnAlignment({ value, handleUpdate }: Props): ReactElement {
  const [newValue, setValue] = useState(value || 'left');
  const columnAlignmentTypes = [
    {
      label: TranslateText('left', 'Left'),
      value: 'left'
    },
    {
      label: TranslateText('center', 'Center'),
      value: 'center'
    },
    {
      label: TranslateText('right', 'Right'),
      value: 'right'
    }
  ];
  const onSelectChange = (data: any): void => {
    handleUpdate({
      columnAlignment: data
    });
    setValue(data);
  };

  return (
    <SLSelect
      name="columnAlignment"
      label="Column Alignment"
      translationId="column_alignment"
      options={columnAlignmentTypes}
      value={newValue}
      defaultValue={value}
      onSelectChange={onSelectChange}
    />
  );
}
