/**
 * Donut Chart Common Editor
 * @author mahesh.kedari@shorelineiot.com
 */
import { Grid } from '@mui/material';
import React, { ReactElement } from 'react';
import { SLCheckbox } from '../../..';
import SLSelect from '../../../form/select/SLSelect';
import SLTextField from '../../../form/SLTextField';
import WidgetSettingAccordion from '../../common/WidgetAccordion';

const rules = {
  donutInnersize: {
    required: 'Required',
    min: {
      value: 0,
      message: 'Minimum 0 is allowed'
    },
    max: {
      value: 100,
      message: 'Maximum 100 is allowed'
    }
  }
};

const ValueUnits = [
  {
    value: 'PERCENTAGE',
    label: 'Percentage'
  },
  {
    value: 'ABSOLUTE',
    label: 'Absolute'
  }
];
interface Props {
  currentSettings: any;
  handleUpdate: () => void;
}

export default function DonutChartCommonEditor({
  handleUpdate,
  currentSettings
}: Props): ReactElement {
  return (
    <WidgetSettingAccordion title="common">
      <Grid container justifyContent="flex-start" spacing={2} alignItems="flex-end">
        <Grid item xs={6}>
          <SLTextField
            name="title"
            label="Title"
            labelTranslationId="title"
            onBlur={handleUpdate}
          />
        </Grid>
        <Grid item xs={6}>
          <SLTextField
            name="innerSize"
            label="Inner radius"
            labelTranslationId="inner_radius"
            defaultValue="50"
            onBlur={handleUpdate}
            type="number"
            rules={rules.donutInnersize}
          />
        </Grid>
        <Grid item xs={6}>
          <SLCheckbox
            name="legands"
            label="Show legends"
            labelTranslationId="show_legends"
            onChange={handleUpdate}
            checked={currentSettings?.legands || false}
          />
        </Grid>
        <Grid item xs={6}>
          <SLCheckbox
            name="enable3d"
            label="Enable 3D"
            labelTranslationId="enable_3d"
            onChange={handleUpdate}
            checked={currentSettings?.enable3d || false}
          />
        </Grid>
        <Grid item xs={6}>
          <SLSelect
            name="dataLabelType"
            options={ValueUnits}
            label="Data Label Value Unit"
            labelTranslationId="data_label_value_unit"
            defaultValue="PERCENTAGE"
            onSelectChange={handleUpdate}
          />
        </Grid>
      </Grid>
    </WidgetSettingAccordion>
  );
}
