/**
 * Tooltip Component
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { ReactElement } from 'react';
import { Tooltip, Theme, TooltipProps, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import TranslateText from '../../../i18n/TranslateText';

interface Props extends TooltipProps {
  buttonColor?: 'inherit' | 'primary' | 'secondary' | 'default' | undefined;
  //Id for translation
  translationId?: string;
  /**
   * True if children is an icon
   */
  isIcon?: boolean;
  /**
   * Icon size, only applicable if isIcon is true
   */
  iconSize?: 'small' | 'medium' | 'large';
}
/**
 *
 */
const useStyles = makeStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: '0.9rem',
    maxWidth: '800px',
    margin: 0
  },
  popper: {
    zIndex: '9999 !important'
  },
  arrow: {
    '&::before': {
      backgroundColor: theme.palette.grey[800] // Ensures the arrow triangle is colored correctly
    }
  },
  iconStyle: {
    padding: 0,
    border: 'none'
  }
}));
/**
 *
 */
export function LightTooltip({
  children,
  title,
  translationId,
  isIcon,
  arrow = true,
  iconSize = 'small',
  ...otherProps
}: Props): ReactElement {
  const classes = useStyles();
  return (
    <Tooltip
      title={translationId ? TranslateText(translationId) : title}
      arrow={arrow}
      disableFocusListener
      disableTouchListener
      classes={{ tooltip: classes.tooltip, popper: classes.popper, arrow: classes.arrow }}
      {...otherProps}>
      {isIcon ? (
        <IconButton size={iconSize} className={classes.iconStyle}>
          {children}
        </IconButton>
      ) : (
        children
      )}
    </Tooltip>
  );
}
