/**
 *
 */
import { AnyAction } from 'redux';
import * as ACTIONS from './sensorSettings.actionTypes';
/**
 *
 */
export const fetchSensorSettings = (payload: any): AnyAction => {
  return {
    type: ACTIONS.SENSOR_SETTINGS_FETCH,
    payload
  };
};
/**
 *
 * @param payload
 */
export const sensorSettingsSuccess = (payload: any): AnyAction => {
  return {
    type: ACTIONS.SENSOR_SETTINGS_SUCCESS,
    payload
  };
};

export const sensorSettingsFailure = (error: any): AnyAction => {
  return {
    type: ACTIONS.SENSOR_SETTINGS_FAILURE,
    error
  };
};

export const sensorSettingsReset = (): AnyAction => {
  return {
    type: ACTIONS.SENSOR_SETTINGS_RESET
  };
};

/**
 *
 */
export const updateSensorConfig = (
  slug = '',
  deviceId: string,
  sensorModuleUUID: string,
  sensorModule: any,
  isInPowertrain: any
): AnyAction => {
  return {
    type: ACTIONS.UPDATE_SENSOR_CONFIG,
    payload: {
      slug,
      deviceId,
      sensorModuleUUID,
      sensorModule,
      isInPowertrain
    }
  };
};
/**
 *
 */
export const updateSensorConfigSuccess = (payload: any): AnyAction => {
  return {
    type: ACTIONS.UPDATE_SENSOR_CONFIG_SUCCESS,
    payload
  };
};
/**
 *
 */
export const updateSensorConfigFailure = (error: any): AnyAction => {
  return {
    type: ACTIONS.UPDATE_SENSOR_CONFIG_FAILURE,
    error
  };
};
