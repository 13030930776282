import { put, takeEvery, all } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import * as actions from './actions/modbus.actions';
import * as actionTypes from './actions/modbus.actionTypes';
import { datapointActions } from '../device-settings';
import {
  GenericObject,
  httpPost,
  removeProgressFor,
  showProgressFor,
  showToast,
  APISERVICES
} from '../../../../../framework';
import { SagaIterator } from 'redux-saga';

const ADD_MODBUS_ID = 'ADD_MODBUS';

function* addModbus(action: AnyAction) {
  const data = action.payload;
  const body = {
    device_id: data.deviceId,
    sensor_module: data.sensorModule
  };

  try {
    yield put(showProgressFor(ADD_MODBUS_ID));
    const response: GenericObject = yield httpPost(
      `orgs/${data.slug}/device_sensor_modules`,
      body,
      APISERVICES.DEVICE_API
    );
    yield put(showToast('Modbus added Successfully!', 'success'));
    yield put(actions.addModbusSuccess(response));
    yield put(
      datapointActions.fetchDatapoints({
        slug: data.slug,
        deviceId: data.deviceId
      })
    );
  } catch (error: any) {
    yield put(showToast('Modbus could not be added!', 'error', true));
    yield put(actions.addModbusFailure(error));
  } finally {
    yield put(removeProgressFor(ADD_MODBUS_ID));
  }
}
/**
 *
 * @param action

/**
 *
 */
export function* watchaddModbusSaga(): SagaIterator {
  yield all([yield takeEvery(actionTypes.ADD_MODBUS_PROGRESS, addModbus)]);
}
