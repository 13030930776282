import React, { useMemo } from 'react';
import SLLineChart from '../SLLineChart/SLLineChart';
import WidgetDataUnavailable from '../widget-data-unavailable/WidgetDataUnavailable';

interface TVEData {
  ts: number;
  value: number;
  /**
   * This property could be present at the zeroth index of the array to indicate no data.
   */
  message?: string;
}

interface TVEProps {
  data?: TVEData[];
}

/**
 * SLTVETrendsWidget component for the dashboard.
 */
const SLTVETrendsWidget = React.forwardRef(({ data }: TVEProps, ref: any) => {
  const copiedData = useMemo(() => (data ? [...data] : []), [data]);

  if (!data || (Array.isArray(data) && 'message' in data[0] && data?.[0]?.message === 'No data')) {
    return <WidgetDataUnavailable />;
  }

  const parsedWidgetData = copiedData
    ?.sort((a, b) => a?.ts - b?.ts)
    ?.map((dataInfo) => [dataInfo?.ts * 1000, parseFloat(dataInfo?.value?.toFixed(4))]);

  return <SLLineChart data={parsedWidgetData} unit="inches/second" ref={ref} />;
});

export default SLTVETrendsWidget;
