/**
 * Dashboard Item Editor Styles
 * @author mahesh.kedari@shorelineiot.com
 */
import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    list: {
      padding: 0
    }
  })
);
