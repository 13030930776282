/**
 * Sidebar Styles
 * @author mahesh.kedari@shorelineiot.com
 */
import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

const drawerWidth = 240;

export const useStyles = makeStyles((theme: Theme) => ({
  drawerPaper: {
    zIndex: 0,
    position: 'relative',
    whiteSpace: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    width: drawerWidth,
    overflowY: 'hidden',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.light,
    transition: `width ${theme.transitions.duration.standard * 2}ms ${
      theme.transitions.easing.easeOut
    } !important`,
    height: 'calc(100vh - 45px)',
    '@media (max-width:600px)': {
      marginTop: '56px',
      height: 'calc(100vh - 50px)'
    },
    '@media (min-width: 600px)': {
      '@media (max-width:1024px)': {
        marginTop: '56px',
        height: 'calc(100vh - 50px)'
      }
    }
  },
  drawerRootClose: {
    borderRight: `2px solid ${theme.palette.divider}`
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.short
    }),
    width: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(3)
    }
  },
  list: {
    flexGrow: 1,
    paddingTop: 0,
    overflowY: 'auto'
  },
  header: {
    flexGrow: 0
  },
  footer: {
    flexGrow: 0,
    color: theme.palette.background.default
  },
  collapseIcon: {
    position: 'absolute',
    top: 65,
    right: -15,
    zIndex: 1,
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.text.secondary,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.text.secondary,
      border: `1px solid ${theme.palette.text.secondary}`
    },
    height: '30px',
    width: '30px'
  }
}));
