import {
  APISERVICES,
  fileUploadAxiosPut,
  handleError,
  httpDelete,
  httpGet,
  httpPost,
  removeProgressFor,
  showProgressFor,
  showToast
} from '../../../../../../framework';

import { FetchOrphanGroupArgs, AssociateUserToGroupArgs, GROUP_TAG } from './group.types';
import { buildQuery } from '../../../../../../framework/lib/rtk/RTK.utils';
import { HTTP_METHOD } from '../../../../../../framework/services/auth.service';
import Bugsnag from '@bugsnag/js';

export function buildOrphanGroupApiEndpoint(slug: string) {
  return `orgs/${slug}/device_groups/check-orphan-associations` as const;
}

export function fetchOrphanGroupQuery({ slug }: FetchOrphanGroupArgs) {
  return buildQuery({
    apiEndpoint: buildOrphanGroupApiEndpoint(slug),
    method: HTTP_METHOD.GET,
    service: APISERVICES.DEVICE_API,
    actionKey: GROUP_TAG.ORPHAN_GROUP
  });
}

export function associateUserToGroupQuery({ slug, groupId, payload }: AssociateUserToGroupArgs) {
  return buildQuery({
    apiEndpoint: `orgs/${slug}/group_user/${groupId}`,
    method: HTTP_METHOD.POST,
    service: APISERVICES.DEVICE_API,
    actionKey: GROUP_TAG.ORPHAN_GROUP,
    body: payload,
    onSuccess(dispatch) {
      dispatch(showToast('Group user associated successfully', 'success'));
    }
  });
}
