import Highcharts from 'highcharts';
import TranslateText from '../../../../i18n/TranslateText';
/**
 *
 */
export const barChartConfig = (
  unit?: string,
  setExtremes?: Highcharts.AxisSetExtremesEventCallbackFunction | any,
  data?:
    | (number | [string | number, number | null] | Highcharts.PointOptionsObject | null)[]
    | undefined
): Highcharts.Options => {
  return {
    lang: {
      noData: TranslateText('no_data_found', 'No data found')
    },
    noData: {
      style: {
        fontWeight: 'bold',
        fontSize: '15px'
      }
    },
    chart: {
      animation: false,
      zooming: { type: 'x' },
      resetZoomButton: {
        position: {
          x: 0,
          y: 0
        }
      }
    },
    // boost: {
    //   useGPUTranslations: true,
    //   seriesThreshold: 1,
    //   debug: {
    //     timeSetup: true,
    //     timeSeriesProcessing: true,
    //     timeKDTree: true,
    //     timeBufferCopy: true,
    //   },
    // },
    title: {
      text: ''
    },
    xAxis: {
      type: 'linear',
      // tickInterval: 24 * 3600,
      crosshair: true,
      labels: {
        // enabled: false,
        autoRotation: [0]
      },
      minRange: 1
    },
    time: {
      useUTC: false
    },
    yAxis: {
      title: {
        text: unit
      }
      // labels: {
      //   align: 'left',
      //   x: -3,
      // },
    },
    legend: {
      enabled: true,
      itemStyle: {
        fontWeight: 'bold'
      }
    },
    rangeSelector: {
      enabled: true,
      buttonPosition: {
        align: 'right',
        x: -25
      },
      buttonTheme: {
        // styles for the buttons
        fill: 'none',
        stroke: 'none',
        'stroke-width': 0,
        style: {
          fontWeight: 'bold'
        },
        states: {
          hover: {
            fill: 'none'
          },
          select: {
            fill: 'none',
            style: {
              color: 'white'
            }
          }
          // disabled: { ... }
        }
      },
      inputBoxBorderColor: 'gray',
      inputBoxWidth: 120,
      inputBoxHeight: 18,
      inputStyle: {
        color: '#00b1c1',
        fontWeight: 'bold'
      },
      buttons: [
        {
          type: 'all',
          text: 'Reset'
        }
      ],
      inputEnabled: false
      /* labelStyle: {
        display: 'none',
      }, */
    },
    scrollbar: {
      liveRedraw: false
    },
    navigator: {
      enabled: true,
      adaptToUpdatedData: false,
      xAxis: {
        type: 'linear'
        // labels: {},
      }
    },
    credits: {
      enabled: false
    },
    tooltip: {
      valueDecimals: 2,
      style: {
        color: 'white'
      },
      backgroundColor: '#4d4d4d'
    },
    plotOptions: {
      //   // series: {
      //   //   marker: {
      //   //     enabled: false,
      //   //   },
      //   // },
    },
    series: [
      {
        data,
        // boostThreshold: 1,
        // pointStart: 1329163200000,
        // pointInterval: 1000 * 3600, // * 24,
        type: 'bar'
        // name: 'AI1',
        // showInLegend: false,
        // cropThreshold: 1000,
        // tooltip: {
        //   valueDecimals: 2,
        // },
        // dataGrouping: {
        //   enabled: false,
        // },
      }
    ]
  };
};
