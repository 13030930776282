/**
 * Plant Health Editor
 * @author mahesh.kedari@shorelineiot.com
 */
import { Grid, Typography } from '@mui/material';
import { merge } from 'lodash';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import SLColorPicker from '../../../color-picker/SLColorPicker';
import { SLCheckbox, SLSelect, SLTextField } from '../../../form';
import { pieFormDefaults } from '../../chartEditor.utils';
import { ChartEditorProps } from '../../common/ChartEditorProps';
import TranslateText from '../../../../../i18n/TranslateText';

const ValueUnits = [
  {
    value: 'PERCENTAGE',
    label: 'Percentage'
  },
  {
    value: 'ABSOLUTE',
    label: 'Absolute'
  }
];

const PlantHealthEditor: React.FC<ChartEditorProps> = ({
  handleSubmitForm,
  defaultValues
  //TODO: Uncomment this when query paremeters are available on backend.
  // dataConfig,
  // widgetType
}) => {
  const newDataSpecificSettings: any = {};
  const [pieChartSettings, setPieChartSettings] = useState<any>({
    chart_type: 'pie',
    chartSettings: {
      ...(Object.keys(defaultValues).length > 0 ? defaultValues : pieFormDefaults),
      ...(Object.entries(newDataSpecificSettings)?.length && {
        dataSettings: newDataSpecificSettings
      })
    }
  });

  const { getValues } = useFormContext();

  const handleUpdate = () => {
    const values = getValues();
    const updatedSettings = merge({}, pieChartSettings);
    // updating local variable here
    const settings = {
      ...updatedSettings,
      chartSettings: {
        ...values
      }
    };
    setPieChartSettings(settings);
    handleSubmitForm(settings);
  };

  return (
    <Grid container justifyContent="flex-start" spacing={2} alignItems="center" p={3}>
      <Grid item xs={6}>
        <SLTextField name="title" label="Title" labelTranslationId="title" onBlur={handleUpdate} />
      </Grid>
      <Grid item xs={6}>
        <SLSelect
          name="dataLabelType"
          options={ValueUnits}
          label="Data Label Value Unit"
          translationId="data_label_value_unit"
          onSelectChange={handleUpdate}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h3">{TranslateText('no_alarms', 'No Alarms')}</Typography>
        <SLTextField name="healthy.label" label="Label" onBlur={handleUpdate} />
        <SLColorPicker
          name={`healthy.color`}
          label=""
          value={pieChartSettings?.chartSettings?.healthy?.color}
          handleColorChange={handleUpdate}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h3">{TranslateText('critical', 'Critical')}</Typography>
        <SLTextField
          name="critical.label"
          label="Label"
          labelTranslationId="label"
          onBlur={handleUpdate}
        />
        <SLColorPicker
          name={`critical.color`}
          label=""
          value={pieChartSettings?.chartSettings?.critical?.color}
          handleColorChange={handleUpdate}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h3">{TranslateText('high', 'High')}</Typography>
        <SLTextField
          name="high.label"
          label="Label"
          labelTranslationId="label"
          onBlur={handleUpdate}
        />
        <SLColorPicker
          name={`high.color`}
          label=""
          value={pieChartSettings?.chartSettings?.high?.color}
          handleColorChange={handleUpdate}
        />
      </Grid>
      <Grid item xs={6}>
        <SLCheckbox name="enable3d" label="Enable 3D" onBlur={handleUpdate} />
      </Grid>
    </Grid>
  );
};

export default PlantHealthEditor;
