import { useLazyFetchAirborneDataQuery } from './deviceAirborneData.api';

export const useLazyFetchAirborneDeviceData = () => {
  const [
    fetchLazyFetchAirborneData,
    {
      data: lazyDeviceAirborneData,
      isFetching: isFetchingLazyDeviceAirborneData,
      isError: isLazyDeviceAirborneDataFetchError,
      error: lazyDeviceAirborneDataFetchError,
      isSuccess: isLazyDeviceAirborneDataFetchSuccess
    }
  ] = useLazyFetchAirborneDataQuery({});
  return {
    fetchLazyFetchAirborneData,
    lazyDeviceAirborneData,
    isFetchingLazyDeviceAirborneData,
    isLazyDeviceAirborneDataFetchError,
    lazyDeviceAirborneDataFetchError,
    isLazyDeviceAirborneDataFetchSuccess
  };
};
