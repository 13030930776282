/**
 * Battery Editor
 * @author mahesh.kedari@shorelineiot.com
 */
import React from 'react';
import { ChartEditorProps } from '../common/ChartEditorProps';
import TranslateText from '../../../../i18n/TranslateText';

const SLBatteryEditor: React.FC<ChartEditorProps> = () => {
  return <div>{TranslateText('coming_soon', 'Coming Soon')}</div>;
};

export default SLBatteryEditor;
