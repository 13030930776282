/**
 * Anomaly Templates List Actions
 * @author aditya.bhadange@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTIONS from './createAnomalyTemplate.actionTypes';

/**
 * @param payload
 * @returns
 */
export const createAnomalyTemplate = (payload: any): AnyAction => ({
  type: ACTIONS.CREATE_ANOMALY_TEMPLATE,
  payload
});
/**
 *
 * @param payload
 * @returns
 */
export const createAnomalyTemplateSuccess = (payload: any): AnyAction => ({
  type: ACTIONS.CREATE_ANOMALY_TEMPLATE_SUCCESS,
  payload
});

/**
 *
 * @param error
 * @returns
 */
export const createAnomalyTemplateFailure = (error: any) => ({
  type: ACTIONS.CREATE_ANOMALY_TEMPLATE_FAILURE,
  error
});

/**
 *
 * @returns Resets the Anomaly Template to an empty array.
 */
export const resetAnomalyTemplate = (): AnyAction => ({
  type: ACTIONS.RESET_CREATE_ANOMALY_TEMPLATE
});
