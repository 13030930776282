/**
 * Federated Authentication
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import NotInvitedUser from './NotInvitedUser';
import FederatedAuthFailed from './FederatedAuthFailed';
import { ACCEPT_INVITE_STATUS } from '../login';
import { acceptInviteActions, loginActions } from '../login/store/actions';
import { useAcceptInviteStatus } from '../login/store';
import { showToast } from '../../../framework/components/toast';
import { AuthService } from '../../../framework/services/auth.service';

interface Props {
  acceptInvite?: boolean;
}

export default function FederatedAuth({ acceptInvite }: Props): React.ReactElement<Props> {
  const location = useLocation();
  const navigate = useNavigate();
  const authService = new AuthService();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get('code');
  const errorDescription = queryParams.get('error_description');
  const dispatch = useDispatch();
  const acceptInviteStatus: ACCEPT_INVITE_STATUS = useAcceptInviteStatus();
  /**
   *
   * @param code
   */
  const getAuthenticationSession = () => {
    authService.handleFederatedSession(code || '', (data: any) =>
      dispatch(loginActions.loginSuccess(data))
    );
  };
  /**
   *
   */
  useEffect(() => {
    // If user has landed on this page through Invite flow,
    if (acceptInvite) {
      // Check if Accept Invite Status is init. If yes, initiate a request to accept invite
      if (acceptInviteStatus === ACCEPT_INVITE_STATUS.INIT) {
        authService.handleFederatedSession(
          code || '',
          (data: any) => {
            // After getting authentication tokens, go for accepting invites
            dispatch(acceptInviteActions.acceptInprogressInvites(data));
          },
          'federated_invite_accept'
        );
      } else if (
        acceptInviteStatus === ACCEPT_INVITE_STATUS.SUCCESS ||
        acceptInviteStatus === ACCEPT_INVITE_STATUS.ALREADY_ACCEPTED
      ) {
        navigate('/org/app', {
          state: {
            signedIn: true,
            authenticated: true
          }
        });
      } else if (acceptInviteStatus === ACCEPT_INVITE_STATUS.ERROR) {
        dispatch(showToast('Unable to accept invite', 'error', true));
      }
    }
  }, [acceptInviteStatus]);
  /**
   *
   */

  if (code && !acceptInvite) {
    getAuthenticationSession();
  }
  /**
   * User is not invited to current organization
   */
  if (errorDescription && errorDescription.includes('User not invited')) {
    return <NotInvitedUser />;
  }
  /**
   * Unknown error has occurred
   */
  if (errorDescription) {
    return <FederatedAuthFailed errorDescription={errorDescription} />;
  }
  /**
   *
   */
  return <></>;
}
