/**
 * Query Settings Editor
 * @author mahesh.kedari@shorelineiot.com
 */
import { OrderUpdater, PivotConfigUpdater } from '@cubejs-client/react';
import { Typography, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { ReactElement } from 'react';
import { PivotConfig, TOrderMember } from '@cubejs-client/core';
import SLQueryLimitSetting from './limit/SLQueryLimitSetting';
import SLOrderGroup from './order/SLOrderGroup';
import SLPivotSetting from './pivot/SLPivotSetting';
import TranslateText from '../../../i18n/TranslateText';

interface Props {
  limit: number;
  pivotConfig?: PivotConfig;
  updatePivotConfig: PivotConfigUpdater;
  orderMembers: Array<TOrderMember>;
  updateOrder: OrderUpdater;
}

const useStyles = makeStyles(() => ({
  settings: {
    marginTop: '5px',
    display: 'flex',
    columnGap: '20px'
  }
}));

export default function SLQuerySettings({
  limit,
  pivotConfig,
  updatePivotConfig,
  orderMembers,
  updateOrder
}: Props): ReactElement<Props> {
  const classes = useStyles();

  const updateQueryLimit = (value: number) => {
    updatePivotConfig.update({ limit: value });
  };
  return (
    <Box mr={4}>
      <Typography variant="h3">{TranslateText('settings_button', ' Settings')}: </Typography>
      <div className={classes.settings}>
        <SLPivotSetting
          limit={limit}
          pivotConfig={pivotConfig}
          updatePivotConfig={updatePivotConfig}
        />
        <SLOrderGroup
          orderMembers={orderMembers}
          onReorder={updateOrder.reorder}
          onOrderChange={updateOrder.set}
        />
        <SLQueryLimitSetting defaultLimit={limit} updateQueryLimit={updateQueryLimit} />
      </div>
    </Box>
  );
}
