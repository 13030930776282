import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';
import VerificationText from './verificationTextComponent';
import { useStyles } from './verificationPages.styles';
import { SLSubmit, useSlugContext } from '../../../../framework';
import { SuccessLarge } from '../../../../framework/icons';

export default function AlreadyVerifiedPage(): ReactElement {
  const { slug } = useSlugContext();
  const navigate = useNavigate();

  const classes = useStyles();
  return (
    <>
      <Box className={classes.imageStyle}>
        <SuccessLarge />
      </Box>

      <Box>
        <VerificationText message="Your account is already verified!" />
      </Box>
      <Box className={classes.buttonStyle}>
        <SLSubmit
          dataTestId="login-button"
          onClick={() => navigate(`/${slug}/auth/login`)}
          translationId="log_in">
          Log In
        </SLSubmit>
      </Box>
    </>
  );
}
