/**
 * SLSnackbar Component
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSnackbar, SnackbarKey } from 'notistack';
import { Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { removeToast, closeToast } from './toast.actions';
import { ToastType } from '../../types';
import { useToasts } from './toast.selectorHooks';
/**
 * List of displayed Snackbar keys
 */
let displayed: Array<any> = [];

interface Props {
  toastKey: any;
}
const DefaultCloseButton = (props: Props) => {
  const dispatch = useDispatch();
  const { toastKey } = props;
  return (
    <Button onClick={() => dispatch(closeToast(toastKey))}>
      <CloseIcon />
    </Button>
  );
};
/**
 *
 */
const SLToast = () => {
  const dispatch = useDispatch();

  const toasts: Array<ToastType> = useToasts();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  /**
   *
   * @param id
   */
  const storeDisplayed = (id?: SnackbarKey) => {
    if (id) {
      displayed = [...displayed, id];
    }
  };
  /**
   *
   * @param id
   */
  const removeDisplayed = (id: SnackbarKey) => {
    displayed = [...displayed.filter((key) => id !== key)];
  };
  /**
   *
   */
  useEffect(() => {
    toasts.forEach(({ key, message, options = {}, dismissed = false }: ToastType) => {
      const allOptions = { ...options };
      if (!allOptions.action) {
        allOptions.action = (toastKey: any) => <DefaultCloseButton toastKey={toastKey} />;
      }
      if (dismissed) {
        // dismiss snackbar using notistack
        closeSnackbar(key);
        return;
      }

      // do nothing if snackbar is already displayed
      if (displayed.includes(key)) return;

      // display snackbar using notistack
      enqueueSnackbar(message, {
        key,
        ...allOptions,
        onClose: (event, reason, myKey) => {
          if (allOptions.onClose) {
            allOptions.onClose(event, reason, myKey);
          }
        },
        onExited: (event, myKey) => {
          // removen this snackbar from redux store
          dispatch(removeToast(myKey));
          removeDisplayed(myKey);
        }
      });

      // keep track of snackbars that we've displayed
      storeDisplayed(key);
    });
  }, [toasts, closeSnackbar, enqueueSnackbar, dispatch]);

  return null;
};

export default SLToast;
