import { Device } from '../../device';

export type Slug = string | undefined;

type Version = {
  manifest_version: string;
};

export type Firmware = {
  ts: number;
  compatible_device_type: string;
  is_latest: number;
  name: string;
  description: string;
  versions: Version;
  uuid: string;
  release_note_url: string;
  is_hidden: number;
};

export type UnfilteredFirmware = {
  value: string;
  label: string;
  is_hidden: boolean;
};

export type FirmwareResponse = {
  previous: string;
  results: Firmware[];
};

export type FormDataObject = {
  name: string;
  firmware: File;
  description: string;
  manifest_version: string;
  release_note: File;
  compatible_device_type: string;
};

export type FirmwareUploadPayload = {
  slug: Slug;
  data: FormDataObject;
};

export type AttachFirmwarePayload = {
  slug: Slug;
  id: string | null;
  data: {
    device_ids: number[];
  };
};

export type GetDevicesByFirmwareIdPayload = {
  slug: Slug;
  firmwareId: string;
};

export type DeviceResponse = {
  page: number;
  page_size: number;
  total: number;
  previous: string;
  next: string;
  results: Device[];
};

export const FIRMWARE_TAG_TYPES = {
  FIRMWARE: 'Firmware'
};

export const FIRMWARE_HIDDEN_STATUS_TYPES = {
  HIDDEN: 1,
  NOT_HIDDEN: 0
};
