/*  */
/**
 * VA Events Saga
 * @author mahesh.kedari@shorelineiot.com
 */
import { AnyAction } from 'redux';
import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import {
  APISERVICES,
  GenericObject,
  SafeSaga,
  httpGet,
  httpPost,
  showToast
} from '../../../../../framework';
import { VA_ACTIONS, VAactions } from '../index';

// Function to create a map with device ID as key and set of dpids and other dpids as value
function createDeviceIdMap(selectedDevices: Array<any>) {
  const deviceIdMap = new Map();

  for (const item of selectedDevices) {
    const { device_id, dpids } = item;
    const dpidsSet = new Set();

    for (const dpidItem of dpids) {
      dpidsSet.add(dpidItem.dpid);
      for (const otherDpidItem of dpidItem.otherDpids) {
        dpidsSet.add(otherDpidItem.dpid);
      }
    }

    deviceIdMap.set(device_id, dpidsSet);
  }

  return deviceIdMap;
}

// Function to filter allEventsData based on the given condition
function filterEventsData(selectedDevices: Array<any>, allEventsData: Array<any>) {
  const filteredData = [];
  const deviceIdMap = createDeviceIdMap(selectedDevices);

  for (const dataGroup of allEventsData) {
    for (const item of dataGroup) {
      const { deviceId, raw_dpid } = item;

      // Check if device_id of selectedDevices matches with deviceId of allEventsData
      if (deviceIdMap.has(deviceId)) {
        const validDpidsSet = deviceIdMap.get(deviceId);

        // Check if raw_dpid is present in the validDpidsSet
        if (validDpidsSet.has(raw_dpid) || item.nb_type === 'tve') {
          filteredData.push(item);
        }
      }
    }
  }

  return filteredData;
}
/**
 *
 * @param data
 * @returns
 */
function* getDeviceEventsTrend(payload: any): any {
  const trendData: any = yield httpGet(
    `orgs/${payload.slug}/devices/${payload.deviceId}/datapoints/narrowband_data`,
    {
      unit: payload?.units?.motionUnitType,
      dpids: payload.dpids.join(','),
      ...(payload.start && { start: payload.start }),
      ...(payload.end && { end: payload.end })
    },
    APISERVICES.VA_API
  );

  const response: any = [];
  //Creating response structure from API response
  Object.keys(trendData.data).forEach((dpid: any) => {
    const selectedNarrowband = payload.relatedNBs.find(
      (nb: { dpid: number }) => nb.dpid === Number(dpid)
    );

    if (selectedNarrowband) {
      //adding extra fields inside each data which is needed in reducer and on component level
      const mappedData = trendData.data[dpid].map((nbdata: any) => {
        return {
          ...nbdata,
          deviceName: selectedNarrowband.deviceName,
          key: selectedNarrowband.key,
          name: selectedNarrowband.name,
          raw_dp_name: selectedNarrowband.raw_dp_name,
          raw_dpid: selectedNarrowband.raw_dpid,
          deviceId: selectedNarrowband.deviceId,
          dpid: Number(dpid),
          nb_type: selectedNarrowband.nb_type
        };
      });

      response.push({
        data: mappedData, //trendData.data[dpid],
        deviceName: selectedNarrowband.deviceName,
        key: selectedNarrowband.key,
        name: selectedNarrowband.name,
        raw_dp_name: selectedNarrowband.raw_dp_name,
        raw_dpid: selectedNarrowband.raw_dpid,
        deviceId: selectedNarrowband.deviceId,
        dpid: Number(dpid),
        nb_type: selectedNarrowband.nb_type
      });
    }
  });
  return response;
}
/**
 *
 * @param slug
 * @param devices
 * @param event
 * @param narrowband
 * @returns
 */
function* getDeviceEvents(
  slug: string,
  devices: any,
  narrowband?: any,
  event?: any,
  start?: any,
  end?: any
) {
  let eventId;
  if (event) {
    eventId = event.eventId;
  } else if (narrowband) {
    eventId = narrowband.eventId;
  }
  const vaEvents: GenericObject = yield httpPost(
    `orgs/${slug}/device_events`,
    {
      device_dpid_map: devices,
      event_id: event ? event.eventId : eventId,
      ...(narrowband && { nb_dpid: narrowband?.dpid }),
      ...(start && { start }),
      ...(end && { end })
    },
    APISERVICES.DEVICE_API
  );
  const eventsForSpectrum: any = [];
  const defaultEventIdMap: any = [];
  const eventsForNBList: any = [];
  let ts = 0;
  if (vaEvents && vaEvents?.events?.length > 0) {
    ts = vaEvents.events[0].ts;
  }
  vaEvents?.events.forEach((element: any) => {
    const defaultEventExists = defaultEventIdMap?.find(
      (item: any) => item.device_id === element.device_id
    );
    const { dpids } = element;
    if (!defaultEventExists) {
      defaultEventIdMap.push({
        device_id: element.device_id,
        event_id: element.event_id,
        ts: element.ts,
        slug
      });
    }
    if (dpids && dpids.length > 0) {
      dpids.forEach((currentDpId: any) => {
        const currentEvent = {
          event_id: element.event_id,
          ts: element.ts,
          dpids: currentDpId,
          device_id: element.device_id,
          slug
        };
        eventsForSpectrum.push(currentEvent);
        eventsForNBList.push(currentEvent);
      });
    }
  });

  devices.forEach(({ device_id, dpids }: any) => {
    dpids.forEach((origDPId: any) => {
      const dpIdExists = eventsForSpectrum?.find(
        (item: any) => item.device_id === device_id && item.dpids === origDPId
      );
      if (!dpIdExists) {
        const defaultEventObj = defaultEventIdMap?.find(
          (item: any) => item.device_id === device_id
        );
        if (defaultEventObj) {
          const currentEvent = {
            event_id: defaultEventObj.event_id,
            ts: defaultEventObj.ts,
            dpids: origDPId,
            device_id,
            slug
          };
          eventsForNBList.push(currentEvent);
        }
      }
    });
  });
  const eventsObj = {
    events: eventsForSpectrum,
    eventsForNBList,
    ts
  };
  return eventsObj;
}
/**
 *
 */
function* getEventsTrendData(action: AnyAction) {
  const { selectedNarrowband, slug, units, start, end, paramNBDpid } = action.payload;

  const narrowbandState: { narrowbands: any } = yield select(
    (state: any) => state.features.workflow.va.narrowbands
  );
  const eventsState: { deviceEvents: any } = yield select(
    (state: any) => state.features.workflow.va.events
  );
  const selectedDevices: any[] = yield select(
    (state: any) => state.features.workflow.va.devices.selectedDevices
  );

  //creating a payload obj below which is needed for API calling
  //All the information is similar so
  const payloadConstruct: any = {};
  // checking with different deviceID found from events as user can select multi device sensor
  narrowbandState.narrowbands.forEach(
    (nb: { dpid: number; name: string | any; deviceId: number; odr: number }) => {
      const eventFound = eventsState.deviceEvents.find((e: any) => e.device_id === nb.deviceId);
      if (eventFound) {
        if (
          nb.name?.split(',')[0] === selectedNarrowband.name?.split(',')[0] &&
          nb.deviceId === eventFound.device_id
        ) {
          //checking if we have already have device related event object or not
          if (payloadConstruct[eventFound.device_id]) {
            //found existing device id inside payload
            //and now we are appending dpid and updating nbs array inside it and keeping all other info as it is

            const currentEvent = payloadConstruct[eventFound.device_id];
            currentEvent.relatedNBs.push(nb);
            currentEvent.dpids.push(nb.dpid);
            payloadConstruct[eventFound.device_id] = currentEvent;
          } else {
            //found new device id which is not yet added into payload
            const payload: any = Object.assign({});
            payload.deviceId = nb.deviceId;
            payload.relatedNBs = [nb];
            payload.dpids = [nb.dpid];
            payloadConstruct[eventFound.device_id] = payload;
          }
        }
      }
    }
  );
  const allEventsData: any[] = yield all(
    Object.values(payloadConstruct).map((data: any) => {
      return call(getDeviceEventsTrend, {
        slug,
        units,
        start,
        end,
        ...data
      });
    })
  );

  const filteredEvents = filterEventsData(selectedDevices, allEventsData);
  yield put(VAactions.fetchEventsSuccess(filteredEvents.flat(), units.motionUnitType, paramNBDpid));
  const frequencyUnit: {} = yield select((state) => state.features.workflow.va.units.frequencyUnit);
  const amplitudeSelection: {} = yield select(
    (state) => state.features.workflow.va.units.amplitudeSelection
  );
  const motionUnitType: {} = yield select(
    (state) => state.features.workflow.va.units.motionUnit.selectedMotionUnitType
  );
  const selectedUnits: any = {
    amplitudeSelection,
    frequencyUnit,
    motionUnitType
  };

  const selectedEvents: {} = yield select(
    (state: any) => state.features.workflow.va.events.selectedEvent
  );

  yield put(VAactions.recalculateNBs(selectedEvents, selectedUnits));
}
/**
 *
 * @param action
 */
function* getDeviceEventsData(action: AnyAction) {
  const { slug, devices, event, selectedNarrowband, start, end } = action.payload;
  const mappedDatapoints = devices.map((device: any) => ({
    device_id: device.device_id,
    // device.dpids consists of all the selected DPID objects which are shown in device dropdown.
    // This object consists of other DPIDs with different ODRs as well. We need to flatten that array to get
    // all the datapoints which are represented by selected datapoints on UI.
    dpids: device.dpids.reduce(
      // This operation gives a list of all the datapoints of all the ODRs which user wants to select
      (accumulator: Array<any>, currentObject: any) => {
        currentObject.otherDpids.forEach((otherDpid: any) => {
          accumulator.push(otherDpid.dpid);
        });
        return accumulator;
      },
      []
    )
  }));
  const response: { events: Array<any> } = yield call(
    getDeviceEvents,
    slug,
    mappedDatapoints,
    selectedNarrowband,
    event,
    start,
    end
  );
  if (response.events.length === 0) {
    yield put(showToast('Events not found', 'warning', true));
  }
  yield put(VAactions.fetchDeviceEventsSuccess(response));
}
function* handleFetchEventsError(error: any) {
  yield put(VAactions.fetchEventsFailure(error));
}
function* handleFetchDeviceEventsError(error: any) {
  yield put(VAactions.fetchDeviceEventsFailure(error));
}
/**
 * Main Saga Function which listens for below actions
 * 1. Fetch Events for Trends Chart
 * 2. Fetch Device Events
 */
export function* watchVAEventsSaga(): SagaIterator {
  yield all([
    // When Narrowband selection gets changed, we need to update Trends chart. Hence need to fetch narrowband_data API
    takeEvery(
      VA_ACTIONS.UPDATE_NARROWBANDS_SELECTION,
      SafeSaga(
        getEventsTrendData,
        `${VA_ACTIONS.UPDATE_NARROWBANDS_SELECTION}_FETCH_EVENTS`,
        handleFetchEventsError
      )
    ),
    // Initially when narrowbands table gets populated, we need to listen for selected narrowband object.
    // When selection is done, we can fetch narrowband_data which gets plotted on Trends Chart
    takeEvery(
      VA_ACTIONS.FETCH_EVENTS,
      SafeSaga(getEventsTrendData, VA_ACTIONS.FETCH_EVENTS, handleFetchEventsError)
    ),
    takeEvery(
      VA_ACTIONS.UPDATE_DATE_TIME_PICKER_VALUES,
      SafeSaga(
        getEventsTrendData,
        VA_ACTIONS.UPDATE_DATE_TIME_PICKER_VALUES,
        handleFetchEventsError
      )
    ),

    // Fetch Device Events for selected Criteria
    takeEvery(
      VA_ACTIONS.FETCH_DEVICE_EVENTS,
      SafeSaga(
        getDeviceEventsData,
        VA_ACTIONS.FETCH_DEVICE_EVENTS,
        handleFetchDeviceEventsError,
        null,
        'Failed to fetch events'
      )
    ),
    takeEvery(
      VA_ACTIONS.UPDATE_EVENT_SELECTION,
      SafeSaga(
        getDeviceEventsData,
        `${VA_ACTIONS.UPDATE_EVENT_SELECTION}_DEVICE_EVENTS`,
        handleFetchDeviceEventsError
      )
    ),
    // When datapoint selection gets updated, fetch Device Events
    takeEvery(
      VA_ACTIONS.UPDATE_DATAPOINT_SELECTION,
      SafeSaga(
        getDeviceEventsData,
        `${VA_ACTIONS.UPDATE_DATAPOINT_SELECTION}_DEVICE_EVENTS`,
        handleFetchDeviceEventsError
      )
    ),
    takeEvery(
      VA_ACTIONS.UPDATE_NARROWBANDS_SELECTION,
      SafeSaga(
        getDeviceEventsData,
        `${VA_ACTIONS.UPDATE_NARROWBANDS_SELECTION}_FETCH_EVENTS`,
        handleFetchEventsError
      )
    )
  ]);
}
