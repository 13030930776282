/**
 * Add Multiple Dashboard Items Reducer
 */
import { AnyAction } from 'redux';
import * as ACTION_TYPE from './addMultipleDashboardItems.actionsTypes';
import {
  AddMultipleDashboardItemsState,
  AddMultipleDashboardItemsStatus
} from './addMultipleDashboardItems.stateType';

const initialState: AddMultipleDashboardItemsState = {
  status: AddMultipleDashboardItemsStatus.INIT,
  addMultipleDashboardItemsData: {}
};

function addMultipleDashboardItemsProgress(
  state: AddMultipleDashboardItemsState
): AddMultipleDashboardItemsState {
  return {
    ...state,
    status: AddMultipleDashboardItemsStatus.FETCHING
  };
}

function addMultipleDashboardItemsSuccess(
  state: AddMultipleDashboardItemsState,
  addMultipleDashboardItemsData: any
): AddMultipleDashboardItemsState {
  return {
    ...state,
    status: AddMultipleDashboardItemsStatus.READY,
    addMultipleDashboardItemsData
  };
}

function addMultipleDashboardItemsFailure(
  state: AddMultipleDashboardItemsState
): AddMultipleDashboardItemsState {
  return {
    ...state,
    status: AddMultipleDashboardItemsStatus.ERROR
  };
}

function resetState(state: AddMultipleDashboardItemsState): AddMultipleDashboardItemsState {
  return {
    ...state,
    status: AddMultipleDashboardItemsStatus.INIT,
    addMultipleDashboardItemsData: {}
  };
}
/*
    Reducer is a pure function that takes actions and state as input 
    and returns/passes the modified state to the store.
    It is implemented using switch case to add the state according 
    to the ation type required.
*/

export default function addMultipleDashboardItemsReducer(
  state: AddMultipleDashboardItemsState = initialState,
  action: AnyAction
): AddMultipleDashboardItemsState {
  switch (action.type) {
    case ACTION_TYPE.ADD_MULTIPLE_DASHBOARD_ITEMS_PROGRESS:
      return addMultipleDashboardItemsProgress(state);
    case ACTION_TYPE.ADD_MULTIPLE_DASHBOARD_ITEMS_SUCCESS:
      return addMultipleDashboardItemsSuccess(state, action.payload);
    case ACTION_TYPE.ADD_MULTIPLE_DASHBOARD_ITEMS_FAILURE:
      return addMultipleDashboardItemsFailure(state);
    case ACTION_TYPE.ADD_MULTIPLE_DASHBOARD_ITEMS_RESET_STATE:
      return resetState(state);
    default:
      return state;
  }
}
