import React from 'react';
import { useStyles } from './mapMarker.styles';
import { AlertLevel } from '../../../../features/alarms/store';
import { getMarkerColor, getSeverity } from '../common/map-chart/mapChartUtils';
import { Typography } from '@mui/material';

interface LegendMarkerProps {
  severity: AlertLevel;
  show: boolean;
}
const LegendMarker = (props: LegendMarkerProps) => {
  const { severity, show } = props;
  const classes = useStyles();

  const severityText = getSeverity(severity);
  const color = getMarkerColor(severityText);
  return (
    <>
      {show && (
        <>
          <span className={classes.legendItem} style={{ backgroundColor: color }} />
          <span className={classes.legendText}>
            <Typography fontWeight={500} variant="inherit">
              {severityText.charAt(0).toUpperCase() + severityText.slice(1)}
            </Typography>
          </span>
        </>
      )}
      {!show && (
        <>
          <span className={classes.legendItem} style={{ backgroundColor: 'gray' }} />
          <span className={classes.legendTextHidden}>
            <Typography fontWeight={500} variant="inherit">
              {severityText.charAt(0).toUpperCase() + severityText.slice(1)}
            </Typography>
          </span>
        </>
      )}
    </>
  );
};

export default LegendMarker;
