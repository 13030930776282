/**
 * New Device Message Handler for RTK Query.
 * @author: aditya.bhadange@shorelineiot.com
 */

import { AnyAction, Dispatch, ThunkDispatch } from '@reduxjs/toolkit';
import {
  DEVICE_TAG,
  FetchDeviceGroupsArgs,
  FetchStandaloneDevicesArgs,
  GetDevicesArgs
} from '../../../device/store/device.types';
import { deviceApi } from '../../../device/store/device.api';
import { WEBSOCKET_MODULE_OPTIONS } from '../websocket.constants';
import { Device, Group } from '../../../device';
import {
  HandleNbModulesUpdateResponse,
  WebSocketDeviceRelatedData
} from './deviceMessageHandler.types';
import { ToastMessageType, showToast } from '../../../../framework';
import { Datapoint, datapointActions, exportDataActions } from '../../../device/device-details';
import { setNewDeviceProperties, setNewGroupProperties } from '../../../device/store/device.utils';
import { getAutoNarrowbandListActions } from '../../../device/device-details/store/additional-sensors/narrowband/automatic-config';
import { VAactions } from '../../../workflow/vibration-analysis/store';

export function handleUpdatedDeviceListWithDatapoints(
  slug: string,
  deviceId: number,
  datapoints: Datapoint[],
  dispatch?: ThunkDispatch<any, any, AnyAction>
) {
  if (!dispatch) {
    return;
  }
  dispatch(
    deviceApi.util.updateQueryData('fetchDevices', { slug }, (draftDevices) => {
      draftDevices.forEach((device) => {
        if (String(device.id) === String(deviceId)) {
          device.datapoints = datapoints;
        }
      });
    })
  );
}

type UpdateDeviceArgs = GetDevicesArgs | FetchStandaloneDevicesArgs;
type DeviceApiType = 'fetchDevices' | 'fetchStandaloneDevices';

function handleDeviceListUpdate(
  dispatch: ThunkDispatch<any, any, AnyAction>,
  data: WebSocketDeviceRelatedData,
  arg: UpdateDeviceArgs,
  apiType: DeviceApiType
) {
  const { payload, action } = data;
  const devicesToUpdate: Device[] = setNewDeviceProperties(payload?.devices);
  const deletePayload = payload as { devices: Device[] };

  const updateDevices = (draftDevices: Device[], deviceToUpdate: Device) => {
    const devicePresentIndex = draftDevices?.findIndex((device) => deviceToUpdate.id === device.id);
    return devicePresentIndex;
  };

  /**
   * Invalidate the powertrain detail cache whenever a device is added, updated or deleted,
   * because it might be used in the powertrain detail form page.
   * This will ensure that the new powertrain detail will be fetched with the updated device information.
   */
  dispatch(deviceApi.util.invalidateTags([DEVICE_TAG.POWERTRAIN_DETAIL]));

  switch (action) {
    case 'add':
      dispatch(
        deviceApi.util.updateQueryData(apiType, arg, (draftDevices) => {
          devicesToUpdate.forEach((deviceToAdd) => {
            const devicePresentIndex = updateDevices(draftDevices, deviceToAdd);
            // Only add the device if it doesn't already exist
            if (devicePresentIndex === -1) {
              draftDevices.push(deviceToAdd);
            }
          });
        })
      );
      break;
    case 'update':
      dispatch(
        deviceApi.util.updateQueryData(apiType, arg, (draftDevices) => {
          devicesToUpdate.forEach((deviceToUpdate) => {
            const devicePresentIndex = updateDevices(draftDevices, deviceToUpdate);
            // Update the device if it exists
            if (devicePresentIndex !== -1) {
              draftDevices[devicePresentIndex] = deviceToUpdate;
            }
          });
        })
      );
      break;
    case 'delete':
      dispatch(
        deviceApi.util.updateQueryData(apiType, arg, (draftDevices) => {
          setNewDeviceProperties(deletePayload?.devices)?.forEach((deviceToDelete) => {
            const devicePresentIndex = updateDevices(draftDevices, deviceToDelete);
            // Remove the device if it exists
            if (devicePresentIndex !== -1) {
              draftDevices.splice(devicePresentIndex, 1);
              dispatch(deviceApi.util.invalidateTags([DEVICE_TAG.STANDALONE_DEVICES]));
            }
          });
        })
      );
      break;
  }
}

function handleDeviceGroupListUpdate(
  dispatch: ThunkDispatch<any, any, AnyAction>,
  data: WebSocketDeviceRelatedData,
  arg: GetDevicesArgs
) {
  const { payload, action } = data;
  const groupsToUpdate: Group[] = setNewGroupProperties(payload?.device_groups);

  const updateGroups = (groups: Group[]) => {
    groupsToUpdate?.forEach((groupToUpdate) => {
      const groupPresentIndex = groups?.findIndex((group) => groupToUpdate.id === group.id);
      if (groupPresentIndex !== -1) {
        groups[groupPresentIndex] = groupToUpdate;
      }
    });
  };

  const deletGroups = (groups: Group[]) => {
    groupsToUpdate?.forEach((groupToDelete) => {
      const groupPresentIndex = groups?.findIndex((group) => groupToDelete.id === group.id);
      if (groupPresentIndex !== -1) {
        groups.splice(groupPresentIndex, 1);
      }
    });
  };

  /**
   * Dispatching another update query data action to deviceApi util with hardcoded payload parameters.
   * This ensures that the cache is also updated with new groups containing `alert_level` and `last_connected_ts`.
   */
  const alertLevelAndLastConnectedArgs = {
    slug: arg.slug,
    payload: { alert_level: true, last_connected: true }
  } as const;

  switch (action) {
    case 'add':
      dispatch(
        deviceApi.util.updateQueryData('fetchDeviceGroups', arg, (draftGroups) => {
          draftGroups.push(...groupsToUpdate);
        })
      );
      dispatch(
        deviceApi.util.updateQueryData(
          'fetchDeviceGroups',
          alertLevelAndLastConnectedArgs,
          (draftGroups) => {
            draftGroups.push(...groupsToUpdate);
          }
        )
      );
      break;
    case 'update':
      dispatch(
        deviceApi.util.updateQueryData('fetchDeviceGroups', arg, (draftGroups) => {
          updateGroups(draftGroups);
        })
      );
      dispatch(
        deviceApi.util.updateQueryData(
          'fetchDeviceGroups',
          alertLevelAndLastConnectedArgs,
          (draftGroups) => {
            updateGroups(draftGroups);
          }
        )
      );
      break;
    case 'delete':
      dispatch(
        deviceApi.util.updateQueryData('fetchDeviceGroups', arg, (draftGroups) => {
          deletGroups(draftGroups);
        })
      );
      dispatch(
        deviceApi.util.updateQueryData(
          'fetchDeviceGroups',
          alertLevelAndLastConnectedArgs,
          (draftGroups) => {
            deletGroups(draftGroups);
          }
        )
      );
      break;
  }
}

const toastShownFlags: { [key: string]: boolean } = {};
const MESSAGE_TIMEOUT = 5000; // 5 seconds

/**
 * This function is used to prevent same message toaster via websocket.
 * This is done due to the multiple arguments used for the device-groups related API, which
 * results in multiple invocation
 * @param dispatch - `ThunkDispatch<any, any, AnyAction>`
 * @param key - `string`
 * @param message - `string`
 * @param type - `ToastMessageType`
 */
function showToastWithTimeout(
  dispatch: ThunkDispatch<any, any, AnyAction>,
  key: string,
  message: string,
  type: ToastMessageType
) {
  if (!toastShownFlags[key]) {
    dispatch(showToast(message, type));
    toastShownFlags[key] = true;
    setTimeout(() => {
      toastShownFlags[key] = false;
    }, MESSAGE_TIMEOUT);
  }
}

function handlePowertrainUpdate(
  dispatch: ThunkDispatch<any, any, AnyAction>,
  data: WebSocketDeviceRelatedData,
  arg: GetDevicesArgs
) {
  const { module, action } = data;
  const successKey = `${module}_${action}_success`;
  const errorKey = `${module}_${action}_error`;

  if (data?.payload?.success === true) {
    const message = `Powertrain ${action}d successfully`;
    showToastWithTimeout(dispatch, successKey, message, 'success');

    if (action === 'delete') {
      dispatch(
        deviceApi.util.updateQueryData('fetchDeviceGroups', arg, (draftGroups) => {
          const groupPresentIndex = draftGroups?.findIndex(
            (device) => device.powertrain_id === data.payload?.powertrain_id
          );
          if (groupPresentIndex !== -1) {
            draftGroups.splice(groupPresentIndex, 1);
          }
        })
      );
    } else if (action === 'update') {
      dispatch(
        deviceApi.util.invalidateTags([
          { type: DEVICE_TAG.POWERTRAIN_DETAIL, id: Number(data.payload?.powertrain_id) }
        ])
      );
      dispatch(deviceApi.util.invalidateTags([DEVICE_TAG.LATEST_POWERTRAIN_CONFIG]));
    }
  } else if (data.payload.success === false) {
    // If message is available, display a message, else show default message
    const message =
      data.payload.message ||
      `Powertrain ${action === 'create' ? 'creation' : 'modification'} failed`;
    showToastWithTimeout(dispatch, errorKey, message, 'error');
  }
}

function handleDatapointListUpdate(dispatch: Dispatch<any>, data: any, arg: GetDevicesArgs) {
  const newData = {
    ...data,
    slug: arg.slug
  };

  if (data?.payload?.vibration_analysis === false) {
    const { pathname } = window.location;
    if (pathname.includes(`${data.payload.device_id}/data`) && data.action === 'add') {
      dispatch(datapointActions.setFetchDatapointsAgain(true));
    }
    if (pathname.includes(`${data.payload.device_id}/settings`)) {
      dispatch(getAutoNarrowbandListActions.setNarrowbandStatus(true));
    }
  } else {
    dispatch(VAactions.updateVATrueDatapointsFromWS(newData));
  }
}

function handleNbModulesUpdate(dispatch: Dispatch<any>, data: HandleNbModulesUpdateResponse) {
  if (data?.payload?.success === true) {
    const message = `Narrowbands ${data?.action}d successfully`;
    dispatch(showToast(message, 'success'));

    /**
     * Invalidate cache of device profiles list which will refetch latest deviceSensorConfigurations,
     * that's needed while deleting the manual narrowbands in the device-settings page.
     */
    dispatch(
      deviceApi.util.invalidateTags([DEVICE_TAG.DEVICE_PROFILES_DETAILS_BY_DEVICE_PROFILE_ID])
    );
  } else if (data.payload.success === false) {
    const message =
      (typeof data.payload?.message === 'object' && data.payload?.message?.message) ||
      (typeof data.payload?.message === 'string' && data.payload?.message) ||
      `Powertrain ${data.action === 'create' ? 'creation' : 'modification'} failed`;
    dispatch(showToast(message, 'error'));
  }
}

export const deviceMessageHandler = (
  dispatch: ThunkDispatch<any, any, AnyAction>,
  data: WebSocketDeviceRelatedData,
  arg: GetDevicesArgs
) => {
  switch (data?.module) {
    case WEBSOCKET_MODULE_OPTIONS.DEVICE.DEVICE_LIST:
      handleDeviceListUpdate(dispatch, data, arg, 'fetchDevices');
      break;
    case WEBSOCKET_MODULE_OPTIONS.DEVICE.DATAPOINT_LIST:
      handleDatapointListUpdate(dispatch, data, arg);
      break;
    case WEBSOCKET_MODULE_OPTIONS.DEVICE.NB_MODULES:
      handleNbModulesUpdate(dispatch, data as HandleNbModulesUpdateResponse);
      break;
    case WEBSOCKET_MODULE_OPTIONS.DEVICE.EXPORT_DATA:
      dispatch(exportDataActions.performDeviceDataFileDownload(data));
      break;
    default:
      break;
  }
};

export const groupsMessageHandler = (
  dispatch: ThunkDispatch<any, any, AnyAction>,
  data: WebSocketDeviceRelatedData,
  arg: FetchDeviceGroupsArgs
) => {
  switch (data?.module) {
    case WEBSOCKET_MODULE_OPTIONS.DEVICE.DEVICE_GROUP_LIST:
      handleDeviceGroupListUpdate(dispatch, data, arg);
      break;
    case WEBSOCKET_MODULE_OPTIONS.DEVICE.POWERTRAIN:
      handlePowertrainUpdate(dispatch, data, arg);
      break;
    default:
      break;
  }
};

export const standaloneDevicesMessageHandler = (
  dispatch: ThunkDispatch<any, any, AnyAction>,
  data: WebSocketDeviceRelatedData,
  arg: FetchStandaloneDevicesArgs
) => {
  switch (data?.module) {
    case WEBSOCKET_MODULE_OPTIONS.DEVICE.DEVICE_LIST:
      handleDeviceListUpdate(dispatch, data, arg, 'fetchStandaloneDevices');
      break;
    default:
      break;
  }
};
