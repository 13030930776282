import { AnyAction } from 'redux';
import * as ACTION_TYPE from './actions/survey.actionTypes';
import { SurveyState, SURVEY_STATUS } from './types';

const initialState: SurveyState = {
  status: SURVEY_STATUS.INIT,
  surveyDetails: [],
  rawNarrowbands: null,
  powertrain: null
};

function editSurveyStateProgress(state: SurveyState): SurveyState {
  return {
    ...state,
    status: SURVEY_STATUS.PROGRESS
  };
}

function editSurveyStateSuccess(state: SurveyState, action: AnyAction): SurveyState {
  const { data, rawNarrowbands } = action;
  return {
    ...state,
    status: SURVEY_STATUS.COMPLETED,
    surveyDetails: data,
    rawNarrowbands
  };
}

function editSurveyStateFailure(state: SurveyState): SurveyState {
  return {
    ...state,
    status: SURVEY_STATUS.ERROR
  };
}

function resetSurveyState() {
  return {
    ...initialState
  };
}
/**
 *
 * @param state
 * @param action
 */
export default function editSurveyReducer(
  state: SurveyState = initialState,
  action: AnyAction
): SurveyState {
  switch (action.type) {
    case ACTION_TYPE.EDIT_SURVEY_ANSWERS:
      return editSurveyStateProgress(state);
    case ACTION_TYPE.EDIT_SURVEY_ANSWERS_SUCCESS:
      return editSurveyStateSuccess(state, action);
    case ACTION_TYPE.EDIT_SURVEY_ANSWERS_FAILURE:
      return editSurveyStateFailure(state);
    case ACTION_TYPE.EDIT_SURVEY_ANSWERS_RESET:
      return resetSurveyState();
    default:
      return state;
  }
}
