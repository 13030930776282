/*  */
/**
 * Get Dashboard Items Reducer
 */
import { AnyAction } from 'redux';
import { v4 as uuidv4 } from 'uuid';
import * as ACTION_TYPE from './localDashboardItems.actionsTypes';
import {
  LocalDashboardItemsState,
  LocalDashboardItemsStatus
} from './localDashboardItems.stateType';
import * as DELETE_ITEM_ACTION_TYPE from '../delete-dashboard-item/deleteDashboardItem.actionTypes';
import * as DASHBOARD_ITEMS_ACTION_TYPE from '../dashboard-items/dashboardItems.actionsTypes';

const initialState: LocalDashboardItemsState = {
  status: LocalDashboardItemsStatus.INIT,
  localDashboardItemsData: { dashboard_items: [] }
};

function getLocalDashboardItemsProgress(state: LocalDashboardItemsState): LocalDashboardItemsState {
  return {
    ...state,
    status: LocalDashboardItemsStatus.FETCHING
  };
}

function getLocalDashboardItemsSuccess(
  state: LocalDashboardItemsState,
  localDashboardItemsData: any
): LocalDashboardItemsState {
  return {
    ...state,
    status: LocalDashboardItemsStatus.READY,
    localDashboardItemsData
  };
}

function getLocalDashboardItemsFailure(state: LocalDashboardItemsState): LocalDashboardItemsState {
  return {
    ...state,
    status: LocalDashboardItemsStatus.ERROR
  };
}

function resetState(state: LocalDashboardItemsState): LocalDashboardItemsState {
  return {
    ...state,
    ...initialState
  };
}

function removeLocalDashboardItemFromList(
  state: LocalDashboardItemsState,
  dashboard_item_uuid: any
): any {
  const localDashboardItemsData = { ...state.localDashboardItemsData };
  const updatedItemsList = [...localDashboardItemsData.dashboard_items];
  const index: number = updatedItemsList.findIndex((item: any) => {
    return item?.dashboard_item_uuid === dashboard_item_uuid;
  });
  if (index > -1) {
    updatedItemsList.splice(index, 1);
  }
  localDashboardItemsData.dashboard_items = updatedItemsList;

  return {
    ...state,
    status: LocalDashboardItemsStatus.READY,
    localDashboardItemsData
  };
}

function setLocaltDashboardItems(
  state: LocalDashboardItemsState,
  dashboardItemsData: any
): LocalDashboardItemsState {
  const localDashboardItemsData = { ...state.localDashboardItemsData };
  localDashboardItemsData.dashboard_items = dashboardItemsData?.dashboard_items?.map(
    (dashboardItem: any) => {
      return {
        ...dashboardItem,
        local_item_uuid: dashboardItem.dashboard_item_uuid
      };
    }
  );

  return {
    ...state,
    status: LocalDashboardItemsStatus.READY,
    localDashboardItemsData
  };
}

function updateLocaltDashboardItems(
  state: LocalDashboardItemsState,
  localDashboardItemsData: any
): LocalDashboardItemsState {
  return {
    ...state,
    status: LocalDashboardItemsStatus.READY,
    localDashboardItemsData
  };
}

function addItemToLocaltDashboardItems(state: LocalDashboardItemsState, dashboardItem: any): any {
  const localDashboardItemsData = { ...state.localDashboardItemsData };
  localDashboardItemsData.dashboard_items = [
    ...localDashboardItemsData.dashboard_items,
    { ...dashboardItem, local_item_uuid: uuidv4() }
  ];
  return {
    ...state,
    status: LocalDashboardItemsStatus.READY,
    localDashboardItemsData
  };
}

function updateItemToLocaltDashboardItems(
  state: LocalDashboardItemsState,
  dashboardItem: any
): any {
  const localDashboardItemsData = { ...state.localDashboardItemsData };
  const orginalDashboardItems = [...localDashboardItemsData.dashboard_items];
  const filteredDashboardItems = orginalDashboardItems?.filter((item: any) => {
    return item.local_item_uuid !== dashboardItem.local_item_uuid;
  });
  if (dashboardItem?.local_item_uuid) {
    localDashboardItemsData.dashboard_items = [...filteredDashboardItems, dashboardItem];
  } else {
    localDashboardItemsData.dashboard_items = [...filteredDashboardItems];
  }
  return {
    ...state,
    status: LocalDashboardItemsStatus.READY,
    localDashboardItemsData
  };
}

function deleteItemFromLocaltDashboardItems(
  state: LocalDashboardItemsState,
  localItemUUID: any
): any {
  const localDashboardItemsData = { ...state.localDashboardItemsData };
  const orginalDashboardItems = [...localDashboardItemsData.dashboard_items];
  const filteredDashboardItems = orginalDashboardItems?.filter((item: any) => {
    return item.local_item_uuid !== localItemUUID;
  });
  if (!localItemUUID) {
    /**
     * This is the case where user has created 'Separator' widget locally,
     * but not updated into the database yet, and user decide to cancel this widget
     * selection.
     */
    const newFilteredItemsAfterCancel = orginalDashboardItems?.filter(
      (item: any) =>
        (item?.config?.viewConfig?.type === 'separator' &&
          item?.config?.label &&
          item?.dashboard_item_uuid) ||
        item?.config?.type
    );
    localDashboardItemsData.dashboard_items = [...newFilteredItemsAfterCancel];
  } else {
    localDashboardItemsData.dashboard_items = [...filteredDashboardItems];
  }
  return {
    ...state,
    status: LocalDashboardItemsStatus.READY,
    localDashboardItemsData
  };
}

/*
    Reducer is a pure function that takes actions and state as input 
    and returns/passes the modified state to the store.
    It is implemented using switch case to update the state according 
    to the ation type required.
*/

export default function localDashboardItemsReducer(
  state: LocalDashboardItemsState = initialState,
  action: AnyAction
): LocalDashboardItemsState {
  switch (action.type) {
    case ACTION_TYPE.LOCAL_DASHBOARD_ITEMS_PROGRESS:
      return getLocalDashboardItemsProgress(state);
    case ACTION_TYPE.LOCAL_DASHBOARD_ITEMS_SUCCESS:
      return getLocalDashboardItemsSuccess(state, action.payload);
    case ACTION_TYPE.LOCAL_DASHBOARD_ITEMS_FAILURE:
      return getLocalDashboardItemsFailure(state);
    case ACTION_TYPE.LOCAL_DASHBOARD_ITEMS_RESET_STATE:
      return resetState(state);
    case DELETE_ITEM_ACTION_TYPE.DELETE_DASHBOARD_ITEM_SUCCESS:
      return removeLocalDashboardItemFromList(state, action.payload);
    case ACTION_TYPE.SET_LOCAL_DASHBOARD_ITEMS:
      return setLocaltDashboardItems(state, action.payload);
    case DASHBOARD_ITEMS_ACTION_TYPE.DASHBOARD_ITEMS_SUCCESS:
      return setLocaltDashboardItems(state, action.payload);
    case ACTION_TYPE.ADD_ITEM_TO_LOCAL_DASHBOARD_ITEMS:
      return addItemToLocaltDashboardItems(state, action.payload);
    case ACTION_TYPE.UPDATE_ITEM_TO_LOCAL_DASHBOARD_ITEMS:
      return updateItemToLocaltDashboardItems(state, action.payload);
    case ACTION_TYPE.DELETE_ITEM_FROM_LOCAL_DASHBOARD_ITEMS:
      return deleteItemFromLocaltDashboardItems(state, action.payload);
    case ACTION_TYPE.UPDATE_LOCAL_DASHBOARD_ITEMS:
      return updateLocaltDashboardItems(state, action.payload);
    default:
      return state;
  }
}
