/**
 * Amplify Configuration Types
 * @author mahesh.kedari@shorelineiot.com
 */
/**
 * Request Amplify configurations from backend service
 */
export const AMPLIFY_CONFIG_REQUEST = 'FEATURES/AUTH/CONFIGURE/REQUEST';
export const AMPLIFY_REQUEST_SUCCESS = 'FEATURES/AUTH/CONFIGURE/REQUEST/SUCCESS';
export const AMPLIFY_REQUEST_FAILURE = 'FEATURES/AUTH/CONFIGURE/REQUEST/FAILURE';
/**
 * Configure Amplify service
 */
export const AMPLIFY_CONFIG_INIT = 'FEATURES/AUTH/CONFIGURE/INIT';
export const AMPLIFY_CONFIG_SUCCESS = 'FEATURES/AUTH/CONFIGURE/SUCCESS';
export const AMPLIFY_CONFIG_FAILURE = 'FEATURES/AUTH/CONFIGURE/ERROR';
/**
 * Get Current user's session from amplify
 */
export const AMPLIFY_CURRENT_SESSION = 'FEATURES/AUTH/CONFIGURE/INIT';
