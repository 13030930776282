export const ADD_MODBUS_PROGRESS = 'FEATURES/DEVICE/DEVICE-SETTINGS/MODBUS/ADD_MODBUS_PROGRESS';
export const ADD_MODBUS_SUCCESS = 'FEATURES/DEVICE/DEVICE-SETTINGS/MODBUS/ADD_MODBUS_SUCCESS';
export const ADD_MODBUS_FAILURE = 'FEATURES/DEVICE/DEVICE-SETTINGS/MODBUS/ADD_MODBUS_FAILURE';
export const ADD_MODBUS_RESET = 'FEATURES/DEVICE/DEVICE-SETTINGS/MODBUS/ADD_MODBUS_RESET';

export const FORM1_OBJECT = 'FEATURES/DEVICE/DEVICE-SETTINGS/MODBUS/FORM1_OBJECT';
export const ADD_COILS_OBJECT = 'FEATURES/DEVICE/DEVICE-SETTINGS/MODBUS/ADD_COILS_OBJECT';
export const DELETE_COILS_OBJECT = 'FEATURES/DEVICE/DEVICE-SETTINGS/MODBUS/DELETE_COILS_OBJECT';
export const EDIT_COILS_OBJECT = 'FEATURES/DEVICE/DEVICE-SETTINGS/MODBUS/EDIT_COILS_OBJECT';
export const ADD_DISCRETE_INPUTS_OBJECT =
  'FEATURES/DEVICE/DEVICE-SETTINGS/MODBUS/ADD_DISCRETE_INPUTS_OBJECT';
export const DELETE_DISCRETE_INPUTS_OBJECT =
  'FEATURES/DEVICE/DEVICE-SETTINGS/MODBUS/DELETE_DISCRETE_INPUTS_OBJECT';
export const EDIT_DISCRETE_INPUTS_OBJECT =
  'FEATURES/DEVICE/DEVICE-SETTINGS/MODBUS/EDIT_DISCRETE_INPUTS_OBJECT';
export const ADD_INPUT_REGISTER_OBJECT =
  'FEATURES/DEVICE/DEVICE-SETTINGS/MODBUS/ADD_INPUT_REGISTER_OBJECT';
export const DELETE_INPUT_REGISTER_OBJECT =
  'FEATURES/DEVICE/DEVICE-SETTINGS/MODBUS/DELETE_INPUT_REGISTER_OBJECT';
export const EDIT_INPUT_REGISTER_OBJECT =
  'FEATURES/DEVICE/DEVICE-SETTINGS/MODBUS/EDIT_INPUT_REGISTER_OBJECT';
export const ADD_HOLDING_REGISTER_OBJECT =
  'FEATURES/DEVICE/DEVICE-SETTINGS/MODBUS/ADD_HOLDING_REGISTER_OBJECT';
export const DELETE_HOLDING_REGISTER_OBJECT =
  'FEATURES/DEVICE/DEVICE-SETTINGS/MODBUS/DELETE_HOLDING_REGISTER_OBJECT';
export const EDIT_HOLDING_REGISTER_OBJECT =
  'FEATURES/DEVICE/DEVICE-SETTINGS/MODBUS/EDIT_HOLDING_REGISTER_OBJECT';

export const UPDATE_MODBUS_PROGRESS =
  'FEATURES/DEVICE/DEVICE-SETTINGS/MODBUS/UPDATE_MODBUS_PROGRESS';
export const UPDATE_MODBUS_SUCCESS = 'FEATURES/DEVICE/DEVICE-SETTINGS/MODBUS/UPDATE_MODBUS_SUCCESS';
export const UPDATE_MODBUS_FAILURE = 'FEATURES/DEVICE/DEVICE-SETTINGS/MODBUS/UPDATE_MODBUS_FAILURE';
export const UPDATE_MODBUS_RESET = 'FEATURES/DEVICE/DEVICE-SETTINGS/MODBUS/UPDATE_MODBUS_RESET';
