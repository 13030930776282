import { useSelector } from 'react-redux';
import { RootState } from '../../../configureStore';

const useAssetDashboardRightDrawerSelector = () =>
  useSelector(
    (state: RootState) => state.features.devices.assetDashboard.newAssetDashboard.rightDrawerAction
  );

const useAssetDashboardPowertrainDropdownOpen = () =>
  useSelector(
    (state: RootState) =>
      state.features.devices.assetDashboard.newAssetDashboard.isPowertrainDropdownOpen
  );

const useAssetDashboardDialogActionSelector = () =>
  useSelector(
    (state: RootState) => state.features.devices.assetDashboard.newAssetDashboard.dialogAction
  );

const usePowertrainUserGroupsFilterSelector = () =>
  useSelector(
    (state: RootState) => state.features.devices.assetDashboard.powertrainUserGroups.isFilterApplied
  );

export {
  useAssetDashboardRightDrawerSelector,
  useAssetDashboardDialogActionSelector,
  useAssetDashboardPowertrainDropdownOpen,
  usePowertrainUserGroupsFilterSelector
};
