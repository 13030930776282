import React from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import { useStyles } from './leakDetection.styles';
import LeakDetectionDateSelector from '../../../../features/reports/leak-detection/LeakDetectionDateSelector';
import {
  LeakDetectionDateRange,
  LeakDetectionDateRangeType
} from '../../../../features/reports/leak-detection/utils/LeakDetectionDateRange';
import LeakDetectionActiveAlarms from './LeakDetectionActiveAlarms';
import { useFetchLeakDetectionAlarms } from '../../../../features/reports/store/leak-detection/leakDetection.hooks';
import LeakDetectionHistoricalAlarms from './LeakDetectionHistoricalAlarms';
import { MethaneEmissionAlarm } from '../../../../features/reports/leak-detection/utils/LeakDetectionAlarmType';
import LeakDetectionChartSettings from './LeakDetectionChartSettings';
import { useFetchDevices } from '../../../../features/device/store/device.hooks';
import { useSlugContext } from '../../../../framework';
import { useFetchAllDevicesData } from './deviceDataUtils';
import { TimeData } from './leakDetectionTypes';

export type AirborneDataType = {
  [key: number]: TimeData[];
};
export type ChartAndHistoricalTableProps = {
  alarms: MethaneEmissionAlarm[];
  isFetchingAlarms: boolean;
  airborneData: AirborneDataType;
  isAirborneDataLoading: boolean;
};
export const LeakDetectionViewer = () => {
  const location = useLocation();
  const classes = useStyles();

  const url = location.pathname;
  const editMode = url.includes('/edit/');

  const [selectedRange, setSelectedRange] = React.useState<LeakDetectionDateRangeType>(
    LeakDetectionDateRange[2]
  );

  const { slug } = useSlugContext();
  const { alarms, isFetchingAlarms, isFetchAlarmsSuccess } = useFetchLeakDetectionAlarms(
    selectedRange.startDate,
    selectedRange.endDate,
    true
  );

  const { devices } = useFetchDevices({
    slug
  });

  const { isAirborneDataError, isAirborneDataLoading, airborneData } = useFetchAllDevicesData(
    devices,
    selectedRange,
    slug
  );

  return (
    <Box className={editMode ? classes.marginsEditMode : classes.marginsDashboardMode}>
      <LeakDetectionDateSelector
        selectedDateRange={selectedRange}
        onSelectionChange={setSelectedRange}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <LeakDetectionActiveAlarms />
        </Grid>
        {(isFetchAlarmsSuccess ||
          isFetchingAlarms ||
          isAirborneDataLoading ||
          !isAirborneDataError) && (
          <>
            <Grid item xs={12} md={6}>
              <LeakDetectionHistoricalAlarms
                alarms={alarms}
                isFetchingAlarms={isFetchingAlarms}
                isAirborneDataLoading={isAirborneDataLoading}
                airborneData={airborneData}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <LeakDetectionChartSettings
                alarms={alarms}
                isFetchingAlarms={isFetchingAlarms}
                airborneData={airborneData}
                isAirborneDataLoading={isAirborneDataLoading}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};
