/**
 * Get topic Saga
 * @author priyanka.ambawane@shorelineiot.com
 */
import { AnyAction } from 'redux';
import { takeEvery, call, put, all } from 'redux-saga/effects';
import { datapointActions, DATAPOINT_ACTIONS } from '../actions';
import {
  GenericObject,
  httpGet,
  removeProgressFor,
  showProgressFor,
  showToast,
  APISERVICES
} from '../../../../../../../framework';
import { SagaIterator } from 'redux-saga';

/**
 * get topics for each sensor
 */

function getTopic(data: any) {
  return httpGet(
    `orgs/${data.slug}/devices/${data.deviceId}/mqtt-stream`,
    {
      dpids: data.dpids.join()
    },
    APISERVICES.DEVICE_API
  );
}
/**
 *
 * @param action
 */
function* getAllTopicsSaga(action: AnyAction) {
  const data = action.payload;
  yield put(showProgressFor(`GET_ALL_TOPICS_${data.deviceId}`));
  try {
    const resp: GenericObject = yield call(getTopic, data);
    yield put(
      datapointActions.fetchTopicSuccess({
        topics: resp
      })
    );
  } catch (error: any) {
    yield put(datapointActions.fetchTopicFailure());
  } finally {
    yield put(removeProgressFor(`GET_ALL_TOPICS_${data.deviceId}`));
  }
}

/**
 * close topics request
 */

function closeTopic(data: any) {
  return httpGet(
    `orgs/${data.slug}/devices/${data.device_id}/datapoints/${data.dp_id}/close-topics`,
    null,
    APISERVICES.DEVICE_API
  );
}
/**
 *
 * @param action
 */
function* closeTopicSaga(action: AnyAction) {
  try {
    const response: GenericObject = yield call(closeTopic, action.payload);
    yield put(datapointActions.closeTopic(response));
  } catch (error: any) {
    yield put(showToast('Failed to close subscription', 'error', true));
  }
}
/**
 * Watcher saga for Fetch Topic Request
 */
export function* watchTopicSaga(): SagaIterator {
  yield all([
    takeEvery(DATAPOINT_ACTIONS.FETCH_ALL_TOPICS, getAllTopicsSaga),
    takeEvery(DATAPOINT_ACTIONS.TOPIC_CLOSE, closeTopicSaga)
  ]);
}
