/**
 * Update Dashboard  Reducer
 */
import { AnyAction } from 'redux';
import * as ACTION_TYPE from './updateDashboard.actionsTypes';
import { UpdateDashboardState, UpdateDashboardStatus } from './updateDashboard.stateType';

const initialState: UpdateDashboardState = {
  status: UpdateDashboardStatus.INIT,
  updateDashboardData: {}
};

function updateDashboardProgress(state: UpdateDashboardState): UpdateDashboardState {
  return {
    ...state,
    status: UpdateDashboardStatus.FETCHING
  };
}

function updateDashboardSuccess(
  state: UpdateDashboardState,
  updateDashboardData: any
): UpdateDashboardState {
  return {
    ...state,
    status: UpdateDashboardStatus.READY,
    updateDashboardData
  };
}

function updateDashboardFailure(state: UpdateDashboardState): UpdateDashboardState {
  return {
    ...state,
    status: UpdateDashboardStatus.ERROR
  };
}

function resetState(state: UpdateDashboardState): UpdateDashboardState {
  return {
    ...state,
    status: UpdateDashboardStatus.INIT,
    updateDashboardData: {}
  };
}
/*
    Reducer is a pure function that takes actions and state as input 
    and returns/passes the modified state to the store.
    It is implemented using switch case to update the state according 
    to the ation type required.
*/

export default function updateDashboardReducer(
  state: UpdateDashboardState = initialState,
  action: AnyAction
): UpdateDashboardState {
  switch (action.type) {
    case ACTION_TYPE.UPDATE_DASHBOARD_PROGRESS:
      return updateDashboardProgress(state);
    case ACTION_TYPE.UPDATE_DASHBOARD_SUCCESS:
      return updateDashboardSuccess(state, action.payload);
    case ACTION_TYPE.UPDATE_DASHBOARD_FAILURE:
      return updateDashboardFailure(state);
    case ACTION_TYPE.UPDATE_DASHBOARD_RESET_STATE:
      return resetState(state);
    default:
      return state;
  }
}
