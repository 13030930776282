/**
 * Stack Line Chart Common Settings
 * @author aditya.bhadange@shorelineiot.com
 */
import React, { PropsWithChildren, ReactElement } from 'react';
import Grid from '@mui/material/Grid';
import WidgetSettingAccordion from '../common/WidgetAccordion';
import { SLTextField } from '../..';

interface Props {
  currentSettings: any;
  handleUpdate: () => void;
}
export default function StackLineCommonSettings({
  handleUpdate
}: PropsWithChildren<Props>): ReactElement {
  return (
    <WidgetSettingAccordion title="Common">
      <Grid container justifyContent="flex-start" spacing={2} alignItems="flex-end">
        <Grid item xs={6}>
          <SLTextField
            name="chartHeight"
            label="Height"
            labelTranslationId="height"
            type="number"
            onBlur={handleUpdate}
          />
        </Grid>
      </Grid>
    </WidgetSettingAccordion>
  );
}
