/**
 * Logout helper function
 * @author mahesh.kedari@shorelineiot.com
 */
import { LoginStateType } from '../types/LoginStateType';

export function onLogout(state: any, flag: boolean): LoginStateType {
  // for WEB flag is false for MOBILE flag is true
  if (!flag) {
    sessionStorage?.clear();
    // localStorage?.clear();
  }
  return {
    ...state,
    status: 'LOGGED_OUT',
    isLoggingIn: false,
    login: {},
    isLoggedIn: false
  };
}
