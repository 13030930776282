export const rules = {
  value: {
    required: 'Field is required',
    pattern: {
      value: /^[-+]?[0-9]*\.?[0-9]\d*$/,
      message: 'Only numbers are allowed'
    }
  },
  requiredError: {
    required: 'Field is required'
  }
};
