/**
 * Dialog Component
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { ReactElement, PropsWithChildren, ReactNode } from 'react';
import { Dialog, DialogTitle, DialogContent, Paper, Typography, Box } from '@mui/material';
import { useStyles } from './dialog.styles';
import { Colors } from '../../constants';

/**
 *
 */
interface Props {
  title: ReactNode | string;
  open: boolean;
  onClose: ((event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void) | undefined;
  className?: any;
}
/**
 *
 * @param param0
 */
export default function SLDialog({
  title,
  open,
  className,
  onClose,
  children
}: PropsWithChildren<Props>): ReactElement {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      className={className || classes.dialogRoot}
      scroll="body">
      <DialogTitle id="form-dialog-title" className={classes.title}>
        <Paper elevation={2} className={classes.paper}>
          <Typography variant="h3">
            <Box fontWeight={500}>{title}</Box>
          </Typography>
        </Paper>
      </DialogTitle>
      <DialogContent>
        <Box color={Colors.primaryText}>{children}</Box>
      </DialogContent>
    </Dialog>
  );
}
