/**
 * Confirmation Dialog Component
 * @author ashwini.gawade@shorelineiot.com
 */
import React, { ReactElement, PropsWithChildren, ReactNode } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useStyles } from './confirmation-dialog.styles';
import { SLButton, SLDialog } from '..';
import TranslateText from '../../../i18n/TranslateText';
import { toSnakeCase } from '../../../i18n/translation.helper';

export enum CONFIRM_ACTION {
  CONFIRM,
  CANCEL
}

/**
 *
 */
interface Props {
  message: string;
  displayConfirmationDialog: boolean;
  onConfirm: (event: CONFIRM_ACTION) => void;
  onClose?: () => void;
  children?: ReactNode | ReactNode[];
  ConfirmationBoxTitle?: string;
  confirmButtonName?: string;
  cancelButtonName?: string;
  dataTestId?: string;
  translationId?: string;
}
/**
 *
 * @param param0
 */
export default function SLConfirmationDialog({
  message,
  displayConfirmationDialog,
  onConfirm,
  onClose,
  children,
  ConfirmationBoxTitle,
  confirmButtonName,
  cancelButtonName,
  dataTestId,
  translationId
}: PropsWithChildren<Props>): ReactElement {
  const classes = useStyles();
  return (
    <SLDialog
      open={displayConfirmationDialog}
      onClose={onClose}
      title={
        ConfirmationBoxTitle
          ? TranslateText(toSnakeCase(ConfirmationBoxTitle), ConfirmationBoxTitle)
          : TranslateText('confirmation', 'Confirmation')
      }
      className={classes.root}>
      <Typography mt={3} variant="body1" align="center">
        {translationId ? TranslateText(translationId) : message}
      </Typography>
      <div>{children || ''}</div>
      <Box display="flex" marginTop="3vh" />
      <Grid container spacing={2} className={classes.dialogContent}>
        <Grid item xs={6}>
          <Box display="flex" justifyContent="center">
            <SLButton
              dataTestId={`${dataTestId}-${confirmButtonName || 'Apply'}`}
              variant="contained"
              color="primary"
              size="small"
              onClick={() => onConfirm(CONFIRM_ACTION.CONFIRM)}
              className={classes.applyButton}>
              {confirmButtonName
                ? TranslateText(toSnakeCase(confirmButtonName), confirmButtonName)
                : TranslateText('apply', 'Apply')}
            </SLButton>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" justifyContent="center">
            <SLButton
              dataTestId={`${dataTestId}-${cancelButtonName || 'Cancel'}`}
              size="small"
              variant="outlined"
              color="primary"
              onClick={() => onConfirm(CONFIRM_ACTION.CANCEL)}
              className={classes.cancelButton}>
              {cancelButtonName
                ? TranslateText(toSnakeCase(cancelButtonName), cancelButtonName)
                : TranslateText('cancel', 'Cancel')}
            </SLButton>
          </Box>
        </Grid>
      </Grid>
    </SLDialog>
  );
}
