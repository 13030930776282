/**
 * Create org saga
 * @author ashwini.gawade@shorelineiot.com
 */
import { AnyAction } from 'redux';
import { put, call, takeEvery } from 'redux-saga/effects';
import { CreateOrganisation } from '../types/Organisation';
import * as actions from './actions/orgCreate.actions';
import * as ACTIONS from './actions/orgCreate.actionType';
import { httpGet, httpPost, SafeSaga, showToast, APISERVICES } from '../../../../framework';
import { SagaIterator } from 'redux-saga';

const ORG_CREATE_ACTION_PROCESS_ID = 'ORG_CREATE_ACTION_PROCESS_ID';
/**
 *
 * @param organisation Organisation object to be created
 */
function createOrganisation(organisation: CreateOrganisation) {
  return httpPost(
    'orgs',
    {
      name: organisation.org_name,
      slug: organisation.slug,
      country_code: organisation.country_code
    },
    APISERVICES.HOST_API
  );
}

function validateOrgShortName(organisation: CreateOrganisation) {
  return httpGet(`orgs/${organisation?.slug}/exists`, null, APISERVICES.HOST_API);
}

/**
 *
 * @param action Action Object
 */
export function* createOrgSaga(action: AnyAction) {
  const organisation = action.payload;
  const shortNameResponse: { status: boolean } = yield call(validateOrgShortName, organisation);
  if (shortNameResponse?.status === false) {
    // Request for org creation if shortname does not exists
    const result: {
      [key: string]: any;
    } = yield call(createOrganisation, organisation);
    yield put(
      showToast(
        'Request submitted successfully. You will be notified once Org is created',
        'success',
        true
      )
    );
    // SLC-3447 Organization creation takes longer than lambda timemout limit. Hence we need to listen to MQTT topic for checking if organization is created.
    // Hence commenting below message. This message will be shown when a success is received on MQTT Topic
    // yield put(showToast("Organization Created Successfully!", "success"));
    yield put(actions.createOrgSuccess(result));
    yield put(actions.resetCreateOrg());
  } else {
    yield put(actions.createOrgFailure('Short name is already used'));
    yield put(showToast('Short name is already used', 'error'));
  }
}

function* errorHandler(err: any) {
  yield put(actions.createOrgFailure(err));
  yield put(showToast('Failed to create org', 'error'));
}
/**
 *
 */
export function* watchCreateOrgRequest(): SagaIterator {
  yield takeEvery(
    ACTIONS.ORG_CREATE,
    SafeSaga(createOrgSaga, ORG_CREATE_ACTION_PROCESS_ID, errorHandler)
  );
}
