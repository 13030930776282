/**
 * VA Events Reducer
 * @author mahesh.kedari@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTIONS from '../vibrationAnalysis.actionTypes';
import { VAEventsState, VA_EVENT_STATUS, CPM_STATUS, DiagnosisType } from './vaEvents.stateType';
import { AMPLITUDE_SELECTION } from '../va-units/vaUnit.stateType';
import { EventSelectionPayload } from '../vibrationAnalysis.actions';
import { getDataPointsParsedList } from '../common/vaUtils';

const initialState: VAEventsState = {
  status: VA_EVENT_STATUS.INIT,
  title: '',
  events: [],
  deviceEventsStatus: VA_EVENT_STATUS.INIT,
  deviceEvents: [],
  deviceEventsData: {},
  chartData: [],
  selectedEvent: null,
  dataPoints: [],
  allEventDiagnosis: [],
  devicesWithDatapoints: [],
  dataPointsStatus: VA_EVENT_STATUS.INIT,
  additionalSensorsData: [],
  additionalSensorsDataStatus: VA_EVENT_STATUS.INIT,
  startDate: null,
  endDate: null,
  dateValue: null,
  cpmStatus: CPM_STATUS.INIT,
  isMergeAxisSelected: true
};

function updateDateTimePickerValues(state: VAEventsState, payload: any): VAEventsState {
  const { startDate, endDate, dateValue } = payload;

  return {
    ...state,
    startDate,
    endDate,
    dateValue
  };
}

/**
 *
 * @param state
 * @param payload
 * @returns
 */
function handleFetchEvents(state: VAEventsState): VAEventsState {
  return { ...state, status: VA_EVENT_STATUS.FETCHING };
}
/**
 *
 * @param state
 * @param payload
 * @returns
 */
function handleEventsSuccess(state: VAEventsState, payload: any): VAEventsState {
  const { events, selectedNBDpid } = payload;
  const { selectedEvent, deviceEvents } = state;
  let newSelectedEvent = selectedEvent;
  let foundSelected = false;
  let defaultEventIndex = -1;
  let tveExceptionEvent: any = null;
  let foundSelectedEvent = false;
  const seriesData = events.map((event: any, index: number) => {
    //Each event has data which consist of all event nbs
    const dataValue: any = event.data;
    // showing only selected series on trendchart
    event.visible = selectedNBDpid ? event.key.includes(selectedNBDpid?.dpid) : true;
    /* 
selectedNBDpid === undefined when user selects NB from dropdown
selectedEvent has eventId or event_id in two scenarios so covered both
on condition below

selectedEvent has value when user select any event trendchart
selectedNBDpid has value when user redirects from Alarms page

foundSelected variable is there as we need only one selected event 
and with same condition we can get max 3 has all axis has same event id
*/

    const seriesMapIdandColor: { id: number; deviceId: number }[] = [];
    let colorMappingId = 1;
    const foundColorSeries = seriesMapIdandColor.find(
      (s: { deviceId: number }) => s.deviceId === event.deviceId
    );
    if (!foundColorSeries) {
      colorMappingId = seriesMapIdandColor.length + 1;
      seriesMapIdandColor.push({
        id: seriesMapIdandColor.length + 1,
        deviceId: event.deviceId
      });
    }
    const chartData: Array<any> = dataValue?.map((item: any, chartDataIndex: number) => {
      //TODO: item contains event_id and eventId indicate same thing
      //need to remove duplicate keys
      const newItem = {
        x: item.ts,
        y: parseFloat(item.value?.toFixed(4)),
        pointEvent: item.event_id,
        eventId: item.event_id,
        event_id: item.event_id,
        // eventName: item.event_name,
        deviceId: item.deviceId,
        dpid: item.dpid,
        raw_dpid: item.raw_dpid,
        raw_dp_name: item.raw_dp_name,
        speed_hz: item.speed_hz,
        peak_frequency: item.peak_frequency,
        selected: false,
        ...(item.event_id === null
          ? {
              marker: {
                enabled: false,
                states: {
                  hover: {
                    enabled: false
                  }
                }
              }
            }
          : {})
      };
      if (foundSelected === false) {
        if (
          (selectedEvent &&
            selectedEvent.pointEvent === item.event_id &&
            selectedEvent.dpid === item.dpid) ||
          (selectedEvent &&
            (selectedEvent.event_id === item.event_id || selectedEvent.eventId === item.event_id) &&
            selectedNBDpid === undefined &&
            selectedEvent.raw_dp_name === item.raw_dp_name) ||
          (deviceEvents.length > 0 &&
            deviceEvents[0].event_id === item.event_id &&
            selectedNBDpid?.dpid === item.dpid)
        ) {
          // any deviceEvents obj has same event_id so taking zeroth obj value
          newItem.selected = true;
          foundSelected = true;
          newSelectedEvent = newItem;
        }
        if (
          foundSelected === false &&
          selectedEvent &&
          (selectedEvent.event_id === item.event_id || selectedEvent.eventId === item.event_id) &&
          selectedNBDpid === undefined &&
          tveExceptionEvent === null
        ) {
          //once found then not letting it be selected again
          tveExceptionEvent = { index: chartDataIndex, seriesIndex: index };
        }
        if (item.event_id) {
          defaultEventIndex = chartDataIndex;
        }
      }

      return newItem;
    });
    if (
      selectedEvent === null &&
      chartData?.length > 0 &&
      foundSelected === false &&
      index === events.length - 1
    ) {
      foundSelectedEvent = true;
      chartData[defaultEventIndex].selected = true;
      foundSelected = true;
      newSelectedEvent = chartData[defaultEventIndex];
    }
    return {
      ...event,
      series: chartData
    };
  });
  if (foundSelected === false && tveExceptionEvent && foundSelectedEvent === false) {
    //This block is needed when user shifts from other nb to tve type nb
    seriesData[tveExceptionEvent.seriesIndex].series[tveExceptionEvent.index].selected = true;
    foundSelected = true;

    newSelectedEvent = seriesData[tveExceptionEvent.seriesIndex].series[tveExceptionEvent.index];
  }

  return {
    ...state,
    status: VA_EVENT_STATUS.READY,
    chartData: seriesData,
    events,
    selectedEvent: newSelectedEvent
  };
}
/**
 *
 * @param state
 * @returns
 */
function handleEventsFailure(state: VAEventsState): VAEventsState {
  return {
    ...state,
    status: VA_EVENT_STATUS.ERROR,
    events: [],
    selectedEvent: null
  };
}
/**
 *
 * @returns
 */
function handleResetEvents(state: VAEventsState, actionName: string): VAEventsState {
  const { dataPoints, devicesWithDatapoints, startDate, endDate, dateValue } = state;
  let additionalSensorsData = [];
  let additionalSensorsDataStatus = VA_EVENT_STATUS.INIT;
  let previousStartDate = initialState.startDate;
  let previousEndDate = initialState.endDate;
  let previousDateValue = initialState.dateValue;
  // if user leaves VA page then reseting to empty
  if (ACTIONS.UPDATE_DATAPOINT_SELECTION === actionName && state.additionalSensorsData.length > 0) {
    // keeping selected trace sensor on change of device sensors
    additionalSensorsData = state.additionalSensorsData;
    additionalSensorsDataStatus = state.additionalSensorsDataStatus;
  }
  // if user change datapoint then persisting start and end date
  if (ACTIONS.UPDATE_DATAPOINT_SELECTION === actionName) {
    previousStartDate = startDate;
    previousEndDate = endDate;
    previousDateValue = dateValue;
  }
  return {
    ...initialState,
    dataPoints,
    devicesWithDatapoints,
    additionalSensorsData,
    additionalSensorsDataStatus,
    startDate: previousStartDate,
    endDate: previousEndDate,
    dateValue: previousDateValue
  };
}
function handleFullResetEvents(): VAEventsState {
  return {
    ...initialState,
    dataPoints: []
  };
}
function handleUpdateSelection(
  state: VAEventsState,
  payload: EventSelectionPayload
): VAEventsState {
  // const {chartData} = state;
  const { selectedEvent } = payload;
  const updatedChartData = state.chartData.map((singleCD) => {
    const series = singleCD.series.map((event: any) => {
      return {
        ...event,
        selected: event.pointEvent === selectedEvent.pointEvent && event.dpid === selectedEvent.dpid
      };
    });
    singleCD.series = series;
    return singleCD;
  });

  return {
    ...state,
    selectedEvent: payload.selectedEvent,
    title: payload.title,
    chartData: updatedChartData
  };
}
function handleUpdateMergeAxisSelection(
  state: VAEventsState,
  payload: { isMergeAxisSelected: boolean }
): VAEventsState {
  const { isMergeAxisSelected } = payload;
  return {
    ...state,
    isMergeAxisSelected
  };
}

function handleUpdateTitle(state: VAEventsState, title: any): VAEventsState {
  return {
    ...state,
    title
  };
}

function handleFetchDeviceEvents(state: VAEventsState): VAEventsState {
  return {
    ...state,
    deviceEventsStatus: VA_EVENT_STATUS.FETCHING,
    status: VA_EVENT_STATUS.INIT
  };
}

function handleDeviceEventsSuccess(state: VAEventsState, payload: any): VAEventsState {
  return {
    ...state,
    deviceEvents: payload.events,
    deviceEventsStatus: VA_EVENT_STATUS.READY,
    deviceEventsData: payload
  };
}

function handleDeviceEventsFailure(state: VAEventsState): VAEventsState {
  return { ...state, deviceEventsStatus: VA_EVENT_STATUS.ERROR };
}
function handleUpdateTrendChartData(state: VAEventsState, payload: any) {
  const { amplitudeSelection } = payload;
  const { selectedEvent } = state;
  let defaultEventIndex = -1;
  const seriesData = state.events.map((event: any, index: number) => {
    const chartData: any = [];
    if (event.data && event.data.length > 0) {
      event.data.forEach((item: any) => {
        let yAxisValue = item.value;
        switch (amplitudeSelection) {
          case AMPLITUDE_SELECTION.RMS: {
            yAxisValue = item.value * 0.7071;
            break;
          }
          case AMPLITUDE_SELECTION.PEAK_TO_PEAK: {
            yAxisValue = item.value * 2;
            break;
          }
          default: {
            break;
          }
        }

        chartData.push({
          x: item.ts,
          y: parseFloat(yAxisValue?.toFixed(4)),
          pointEvent: item.event_id,
          eventId: item.event_id,
          //eventName: item.event_name,
          deviceId: item.deviceId,
          dpid: item.dpid,
          raw_dpid: item.raw_dpid,
          raw_dp_name: item.raw_dp_name,
          speed_hz: item.speed_hz,
          peak_frequency: item.peak_frequency,
          selected:
            selectedEvent &&
            (selectedEvent.pointEvent === item.event_id ||
              selectedEvent.event_id === item.event_id ||
              selectedEvent.eventId === item.event_id)
        });
        if (item.event_id) {
          defaultEventIndex = index;
        }
      });
      if (selectedEvent === null && chartData?.length > 0 && index === 0) {
        chartData[defaultEventIndex].selected = true;
      }
    }

    return { ...event, series: chartData };
  });

  return {
    ...state,
    chartData: seriesData
  };
}

function handleFetchDataPoints(state: VAEventsState): VAEventsState {
  return { ...state, dataPointsStatus: VA_EVENT_STATUS.FETCHING };
}
function handleFetchDataPointsSuccess(state: VAEventsState, payload: any): VAEventsState {
  const { parsedData, devicesWithDatapoints } = payload;
  return {
    ...state,
    dataPointsStatus: VA_EVENT_STATUS.READY,
    dataPoints: parsedData,
    devicesWithDatapoints
  };
}
function handleFetchDataPointsFailure(state: VAEventsState): VAEventsState {
  return {
    ...state,
    dataPointsStatus: VA_EVENT_STATUS.ERROR
    // dataPoints: [],
  };
}

function handleHistoricalData(state: VAEventsState): VAEventsState {
  return { ...state, additionalSensorsDataStatus: VA_EVENT_STATUS.FETCHING };
}
function handleHistoricalDataSuccess(state: VAEventsState, payload: any): VAEventsState {
  return {
    ...state,
    additionalSensorsDataStatus: VA_EVENT_STATUS.READY,
    additionalSensorsData: payload.additionalSensorsData
  };
}
function handleHistoricalDataFailure(state: VAEventsState): VAEventsState {
  return {
    ...state,
    additionalSensorsDataStatus: VA_EVENT_STATUS.ERROR,
    additionalSensorsData: []
  };
}
function handleUpdateVAFalseDatapoints(state: VAEventsState, data: any): VAEventsState {
  const { payload, devices, action } = data;
  const { datapoints } = payload;

  let updatedDatapoints = state.dataPoints;
  let { devicesWithDatapoints } = state;
  const parsedData = datapoints;
  if (action === 'add') {
    devicesWithDatapoints = devicesWithDatapoints.map((dp) => {
      if (dp.device_id === payload.device_id) {
        dp.datapoints = [...dp.datapoints, ...datapoints];
      }
      return dp;
    });
  } else if (action === 'update') {
    devicesWithDatapoints = devicesWithDatapoints.map((dp: any) => {
      if (dp.device_id === payload.device_id) {
        dp.datapoints = dp.datapoints.map((dpoint: any) => {
          const found = parsedData.find((d: any) => d.dpid === dpoint.dpid);
          if (found) {
            return found;
          }
          return dpoint;
        });
      }
      return dp;
    });
  } else if (action === 'delete') {
    devicesWithDatapoints = devicesWithDatapoints.map((dp: any) => {
      if (dp.device_id === payload.device_id) {
        dp.datapoints = dp.datapoints.filter((dpoint: any) => {
          const found = parsedData.find((d: any) => d.dpid === dpoint.dpid);
          if (found === undefined) {
            return dpoint;
          }
        });
      }
      return dp;
    });
  }
  updatedDatapoints = getDataPointsParsedList(devicesWithDatapoints, devices);
  return {
    ...state,
    dataPoints: updatedDatapoints,
    devicesWithDatapoints
  };
}

function handleUpdateCPM(state: VAEventsState): VAEventsState {
  return state;
}

function handleUpdateCPMSuccess(state: VAEventsState): VAEventsState {
  return {
    ...state,
    status: VA_EVENT_STATUS.INIT
  };
}
/**
 *
 * @param state
 * @param action
 * @returns
 */
function handleAddEventDiagnosis(
  state: VAEventsState,
  diagnosisInfo: DiagnosisType[]
): VAEventsState {
  const allDiagnosis = [...state.allEventDiagnosis, ...diagnosisInfo];
  return {
    ...state,
    allEventDiagnosis: allDiagnosis
  };
}
/**
 *
 * @param state
 * @param action
 * @returns
 */
export default function VADeviceReducer(
  state: VAEventsState = initialState,
  action: AnyAction
): VAEventsState {
  switch (action.type) {
    case ACTIONS.FETCH_EVENTS:
      return handleFetchEvents(state);
    case ACTIONS.FETCH_EVENTS_SUCCESS:
      return handleEventsSuccess(state, action.payload);
    case ACTIONS.FETCH_EVENTS_FAILURE:
      return handleEventsFailure(state);
    case ACTIONS.RESET_EVENTS:
    case ACTIONS.UPDATE_DATAPOINT_SELECTION:
    case ACTIONS.RESET_VIBRATION_ANALYSIS:
      return handleResetEvents(state, action.type);
    case ACTIONS.UPDATE_EVENT_SELECTION:
      return handleUpdateSelection(state, action.payload);
    case ACTIONS.UPDATE_MERGE_AXIS_SELECTION:
      return handleUpdateMergeAxisSelection(state, action.payload);
    case ACTIONS.UPDATE_EVENT_TITLE:
      return handleUpdateTitle(state, action.payload);
    case ACTIONS.FETCH_DEVICE_EVENTS:
      return handleFetchDeviceEvents(state);
    case ACTIONS.FETCH_DEVICE_EVENTS_SUCCESS:
      return handleDeviceEventsSuccess(state, action.payload);
    case ACTIONS.FETCH_DEVICE_EVENTS_FAILURE:
      return handleDeviceEventsFailure(state);
    case ACTIONS.UPDATE_TREND_CHART_DATA:
      return handleUpdateTrendChartData(state, action.payload);
    case ACTIONS.FETCH_DATAPOINTS_TREND_CHART:
      return handleFetchDataPoints(state);
    case ACTIONS.FETCH_DATAPOINTS_TREND_CHART_SUCCESS:
      return handleFetchDataPointsSuccess(state, action.payload);
    case ACTIONS.FETCH_DATAPOINTS_TREND_CHART_FAILURE:
      return handleFetchDataPointsFailure(state);

    case ACTIONS.FETCH_HISTORICAL_DATA:
      return handleHistoricalData(state);
    case ACTIONS.FETCH_HISTORICAL_DATA_SUCCESS:
      return handleHistoricalDataSuccess(state, action.payload);
    case ACTIONS.FETCH_HISTORICAL_DATA_FAILURE:
      return handleHistoricalDataFailure(state);
    case ACTIONS.UPDATE_DATE_TIME_PICKER_VALUES:
      return updateDateTimePickerValues(state, action.payload);
    case ACTIONS.SET_ONLY_DATE_TIME_PICKER_VALUES:
      return updateDateTimePickerValues(state, action.payload);
    // smart-cache Listening to update va false datapoints
    case ACTIONS.UPDATE_VA_TRUE_DATAPOINTS:
      return handleUpdateVAFalseDatapoints(state, action.payload);
    case ACTIONS.UPDATE_CPM:
      return handleUpdateCPM(state);
    case ACTIONS.UPDATE_CPM_SUCCESS:
      return handleUpdateCPMSuccess(state);
    case ACTIONS.FULL_RESET_EVENTS:
      return handleFullResetEvents();
    case ACTIONS.FETCH_EVENT_DIAGNOSIS_SUCCESS:
      return handleAddEventDiagnosis(state, action.payload);
    default:
      return state;
  }
}
