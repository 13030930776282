import { AnyAction } from 'redux';
import * as ACTION_TYPE from '../actions/alerts.actionTypes';
import { createAlertState } from '../types/CreateAlertState';
import { CREATE_ALERT_STATUS } from '../types/CreateAlertStatus';
import { createAlertDefaults } from '../types/createAlert.constants';

const initialState: createAlertState = {
  status: CREATE_ALERT_STATUS.INIT,
  formDefaults: createAlertDefaults
};

function createAlertProgress(state: createAlertState): createAlertState {
  return {
    ...state,
    status: CREATE_ALERT_STATUS.PROGRESS
  };
}
function createAlertSuccess(state: createAlertState): createAlertState {
  return {
    ...state,
    status: CREATE_ALERT_STATUS.SUCCESS
  };
}
/**
 *
 * @param state
 */
function createAlertFailure(state: createAlertState): createAlertState {
  return {
    ...state,
    status: CREATE_ALERT_STATUS.ERROR
  };
}

/**
 *
 * @param state
 * @param action
 */

function conditionsFormData(state: createAlertState, data: any): createAlertState {
  return {
    ...state,
    formDefaults: data
  };
}
export default function createAlertReducer(
  state: createAlertState = initialState,
  action: AnyAction
): createAlertState {
  switch (action.type) {
    case ACTION_TYPE.CREATE_ALERT_PROGRESS:
      return createAlertProgress(state);
    case ACTION_TYPE.CREATE_ALERT_SUCCESS:
      return createAlertSuccess(state);
    case ACTION_TYPE.CREATE_ALERT_FAILURE:
      return createAlertFailure(state);
    case ACTION_TYPE.CONDITIONS_FORM_DATA:
      return conditionsFormData(state, action.payload);

    default:
      return state;
  }
}
