import { AnyAction } from 'redux';
import * as ACTION_TYPE from '../actions/userSelf.actionTypes';
import { UserUpdateState } from '../types/UserUpdateState';
import { USER_UPDATE_STATUS } from '../types/UserUpdateStatus';
/**
 *
 */
const initialState: UserUpdateState = {
  status: USER_UPDATE_STATUS.INIT
};

function onUpdateUser(state: UserUpdateState): UserUpdateState {
  return {
    ...state,
    status: USER_UPDATE_STATUS.UPDATING_USER
  };
}
/**
 *
 * @param state
 */
function onUpdateUserFailure(state: UserUpdateState): UserUpdateState {
  return {
    ...state,
    status: USER_UPDATE_STATUS.ERROR
  };
}
/**
 *
 * @param state
 * @param action
 */
function onUpdateUserSuccess(state: UserUpdateState, action: AnyAction): UserUpdateState {
  return {
    ...state,
    status: USER_UPDATE_STATUS.READY,
    userDetails: action.data
  };
}
/**
 *
 * @param state
 */

function resetUserDetails() {
  return {
    ...initialState
  };
}
/**
 *
 * @param state
 * @param action
 */
export default function userUpdateReducer(
  state: UserUpdateState = initialState,
  action: AnyAction
): UserUpdateState {
  switch (action.type) {
    case ACTION_TYPE.UPDATE_USER:
      return onUpdateUser(state);
    case ACTION_TYPE.UPDATE_USER_SUCCESS:
      return onUpdateUserSuccess(state, action);
    case ACTION_TYPE.UPDATE_USER_FAILURE:
      return onUpdateUserFailure(state);
    case ACTION_TYPE.RESET_USER_DETAILS:
      return resetUserDetails();
    default:
      return state;
  }
}
