/**
 * Reset Password Reducer
 * @author priyanka.ambawame@shorelineiot.com
 */
import { ACCEPT_INVITE_STATUS, LoginStateType } from '../types/LoginStateType';
/**
 *
 * @param state
 */
export function onResetPasswordSuccess(state: LoginStateType, session: any): LoginStateType {
  return {
    ...state,
    status: 'LOGGED_IN',
    session
  };
}
/**
 *
 * @param state
 * @returns
 */
export function onValidatePassword(state: LoginStateType): LoginStateType {
  return {
    ...state,
    acceptInviteStatus: ACCEPT_INVITE_STATUS.PASSWORD_VALIDATING
  };
}
/**
 *
 * @param state
 */
export function onValidatePasswordSuccess(
  state: LoginStateType,
  acceptInviteStatus: ACCEPT_INVITE_STATUS
): LoginStateType {
  return {
    ...state,
    acceptInviteStatus
  };
}
/**
 *
 * @param state
 */
export function onValidatePasswordFailure(
  state: LoginStateType,
  acceptInviteStatus: ACCEPT_INVITE_STATUS
): LoginStateType {
  return {
    ...state,
    acceptInviteStatus
  };
}
/**
 *
 * @param state
 */
export function onAcceptInviteFailure(
  state: LoginStateType,
  status: ACCEPT_INVITE_STATUS
): LoginStateType {
  return {
    ...state,
    acceptInviteStatus: status
  };
}
/**
 *
 * @param state
 */
export function resetAcceptInvite(state: LoginStateType): LoginStateType {
  return {
    ...state,
    acceptInviteStatus: ACCEPT_INVITE_STATUS.INIT
  };
}
