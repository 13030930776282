import { AnyAction } from 'redux';
import * as ACTION_TYPE from './actions/getSurveyQuestions.actionTypes';
import { getSurveyQuestionsState } from './types/getSurveyQuestionsState';
import { GET_SURVEY_QUESTIONS_STATUS, nbFirstPagedefaults } from './types/getSurveyQuestionsStatus';

const initialState: getSurveyQuestionsState = {
  status: GET_SURVEY_QUESTIONS_STATUS.INIT,
  surveyQuestions: [],
  getSingleSurveyQuestionsStatus: GET_SURVEY_QUESTIONS_STATUS.INIT,
  singleSurveyQuestions: {},
  singleSurveyAnswers: {},
  calculatedValues: [],
  firstPageAnswers: nbFirstPagedefaults,
  surveyQuestionsId: 0
};

function getSurveyQuestionsStateProgress(state: getSurveyQuestionsState): getSurveyQuestionsState {
  return {
    ...state,
    status: GET_SURVEY_QUESTIONS_STATUS.PROGRESS
  };
}

function getSurveyQuestionsStateSuccess(
  state: getSurveyQuestionsState,
  action: AnyAction
): getSurveyQuestionsState {
  return {
    ...state,
    status: GET_SURVEY_QUESTIONS_STATUS.COMPLETED,
    surveyQuestions: action.data
  };
}

function getSurveyQuestionsStateFailure(state: getSurveyQuestionsState): getSurveyQuestionsState {
  return {
    ...state,
    status: GET_SURVEY_QUESTIONS_STATUS.ERROR
  };
}

function getSingleSurveyQuestionsStateProgress(
  state: getSurveyQuestionsState
): getSurveyQuestionsState {
  return {
    ...state,
    getSingleSurveyQuestionsStatus: GET_SURVEY_QUESTIONS_STATUS.PROGRESS
  };
}

function getSingleSurveyQuestionsStateSuccess(
  state: getSurveyQuestionsState,
  action: AnyAction
): getSurveyQuestionsState {
  const questions = action.data.survey_questions.pages;
  delete questions.visibleIf;
  const answers = action.data.survey_answers;
  const { calculatedValues } = action.data.survey_questions;
  return {
    ...state,
    getSingleSurveyQuestionsStatus: GET_SURVEY_QUESTIONS_STATUS.COMPLETED,
    singleSurveyQuestions: questions,
    singleSurveyAnswers: answers,
    calculatedValues,
    surveyQuestionsId: action.data.survey_questions_id
  };
}

function getSingleSurveyQuestionsStateFailure(
  state: getSurveyQuestionsState
): getSurveyQuestionsState {
  return {
    ...state,
    getSingleSurveyQuestionsStatus: GET_SURVEY_QUESTIONS_STATUS.ERROR
  };
}
function setFirstPageAnswers(
  state: getSurveyQuestionsState,
  payload: any
): getSurveyQuestionsState {
  return {
    ...state,
    firstPageAnswers: payload
  };
}
function resetSurveyState() {
  return {
    ...initialState
  };
}
/**
 *
 * @param state
 * @param action
 */
export default function getSurveyQuestionsReducer(
  state: getSurveyQuestionsState = initialState,
  action: AnyAction
): getSurveyQuestionsState {
  switch (action.type) {
    case ACTION_TYPE.GET_SURVEY_QUESTIONS:
      return getSurveyQuestionsStateProgress(state);
    case ACTION_TYPE.GET_SURVEY_QUESTIONS_SUCCESS:
      return getSurveyQuestionsStateSuccess(state, action);
    case ACTION_TYPE.GET_SURVEY_QUESTIONS_FAILURE:
      return getSurveyQuestionsStateFailure(state);
    case ACTION_TYPE.GET_SURVEY_QUESTIONS_RESET:
      return resetSurveyState();

    case ACTION_TYPE.GET_SINGLE_SURVEY_QUESTIONS:
      return getSingleSurveyQuestionsStateProgress(state);
    case ACTION_TYPE.GET_SINGLE_SURVEY_QUESTIONS_SUCCESS:
      return getSingleSurveyQuestionsStateSuccess(state, action);
    case ACTION_TYPE.GET_SINGLE_SURVEY_QUESTIONS_FAILURE:
      return getSingleSurveyQuestionsStateFailure(state);
    case ACTION_TYPE.SET_NBAUTO_FIRST_PAGE_VALUES:
      return setFirstPageAnswers(state, action.payload);
    default:
      return state;
  }
}
