/**
 * Header Logo
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { useEffect } from 'react';
import { ShorelineNewLogo } from '../../../../assets';
import { useLogopath } from '../../logo-path';

interface Props {
  logoPath?: string;
  altText?: string;
}
export default function Logo({ logoPath = ShorelineNewLogo, altText = 'Shoreline' }: Props) {
  const [logoSource, setLogoSource] = React.useState('');
  const customLogoPath = useLogopath();

  useEffect(() => {
    setLogoSource(customLogoPath.length && customLogoPath[0] !== '' ? customLogoPath[0] : logoPath);
  }, [customLogoPath]);

  return (
    <>
      <img style={{ marginTop: '-16px' }} width={183} height={50} alt={altText} src={logoSource} />
    </>
  );
}
