/**
 * Sensor Settings State
 * @author mahesh.kedari@shorelineiot.com
 */
export enum SensorSettingsStatus {
  INIT,
  FETCHING,
  READY,
  UPDATED,
  ERROR
}
/**
 *
 */
export interface SensorSettingsState {
  status: SensorSettingsStatus;
  datapointUUID?: string;
  moduleSchema?: any;
  widgetSchema?: any;
}
