import { put, takeEvery, call } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import * as actions from './actions/deviceActivity.actions';
import * as ACTION_TYPES from './actions/deviceActivity.actionTypes';
import { GenericObject, httpGet, SafeSaga, APISERVICES } from '../../../../../framework';
import { SagaIterator } from 'redux-saga';

function getList(data: any) {
  return httpGet(
    `orgs/${data.slug}/activity_logs?resource_type=${data.resourceType}&resource_id=${data.resourceId}&page=${data.page}&page_size=${data.pageSize}&sort_descending=${data.sortDescendingValue}&sort_ascending=${data.sortAscendingValue}&start=${data.from}&end=${data.to}`,
    null,
    APISERVICES.ACTIVITY_LOGS_API
  );
}

function* activityLogsSaga(action: AnyAction) {
  const response: GenericObject = yield call(getList, action.payload);
  yield put(actions.fetchActivitiesSuccess(response));
}

function* errorHandler() {
  yield put(actions.fetchActivitiesFailure());
}
/**
 *
 */
export default function* watchDeviceActivityLogsSagas(): SagaIterator {
  yield takeEvery(
    ACTION_TYPES.FETCH_ACTIVITIES,
    SafeSaga(activityLogsSaga, ACTION_TYPES.FETCH_ACTIVITIES, errorHandler)
  );
}
