/**
 * Image Marking Widget Styles
 * @author aditya.bhadange@shorelineiot.com
 */
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  hidden: {
    display: 'none'
  },
  container: {
    marginTop: '-40px',
    textAlign: 'center'
  },
  frame: {
    '& .image-marker': {
      height: '100%',
      zIndex: 2
    },
    '& .image-marker__image': {
      height: '100%',
      objectFit: 'contain'
    },
    height: '100%',
    margin: '0 auto'
  },
  cursor: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  tooltipWidth: {
    maxWidth: '400px'
  },
  customMarker: {
    backgroundColor: 'white',
    width: '20px',
    height: '20px',
    padding: '5px',
    border: 'solid thin white',
    boxShadow: '-1px 1px 4px rgba(0, 0, 0, 0.5)',
    borderRadius: '50% 50% 50% 0',
    transform: 'perspective(40px) rotateX(20deg) rotateZ(-45deg)'
  },
  error: {
    backgroundColor: 'orange',
    webkitAnimation: '$blink 2s infinite',
    animation: 'blink 2s infinite'
  },
  fatal: {
    backgroundColor: 'red',
    animation: '$blink 1s infinite'
  },
  none: {
    backgroundColor: 'green',
    animation: 'blink 2s infinite'
  },
  low: {
    backgroundColor: 'rgb(129, 173, 232)',
    animation: 'blink 2s infinite'
  },
  warning: {
    backgroundColor: 'yellow',
    animation: 'blink 2s infinite'
  },
  inActiveSensorsStyle: {
    height: '30px',
    width: '30px',
    filter: 'drop-shadow(3px 5px 3px rgb(0 0 0 / 0.6))'
  },
  provFailedStyle: {
    height: '25px',
    width: '25px',
    filter: 'drop-shadow(3px 5px 3px rgb(0 0 0 / 0.6))'
  },
  boldStyle: {
    fontWeight: 600
  },
  beacon: {
    '&:before': {
      content: ' ',
      height: '16em',
      width: '16em',
      left: 0,
      top: 0,
      backgroundColor: 'transparent',
      borderWidth: '1px',
      borderColor: 'red',
      borderStyle: 'solid',
      borderRadius: '50%',
      boxShadow: '0px 0px 2px 2px #db2828',
      webkitAnimation: 'grow 2s infinite linear',
      animation: 'grow 2s infinite linear',
      transform: 'perspective(40px) rotateX(20deg) rotateZ(-45deg)'
    }
  },

  '@-webkit-keyframes blink': {
    from: {
      backgroundColor: '#841617'
    },
    to: {
      backgroundColor: '#ff2400'
    }
  },

  '@keyframes blink': {
    from: {
      backgroundColor: '#841617'
    },
    to: {
      backgroundColor: '#ff2400'
    }
  },
  '@-webkit-keyframes grow': {
    '0%': {
      borderRadius: '3px',
      // -webkit-border-radius: '3px',
      padding: 0
    },
    '100%': {
      borderRadius: '25px',
      // -webkit-border-radius: '25px',
      left: '9px',
      padding: '21px',
      top: '7px'
    }
  },

  '@keyframes grow': {
    '0%': {
      transform: 'scale(0.1)',
      padding: 0
    },
    '100%': {
      transform: 'scale(10)',
      padding: '10px'
    }
  },

  '@-webkit-keyframes active': {
    '0%': {
      opacity: 1
    },
    '70%': {
      opacity: 0
    },
    '100%': {
      opacity: 0
    }
  },

  '@keyframes active': {
    '0%': {
      transform: 'scale(0.1)',
      opacity: 1
    },
    '70%': {
      transform: 'scale(2.5)',
      opacity: 0
    },
    '100%': {
      opacity: 0
    }
  }
}));
