import { useLocation } from 'react-router';
import { HISTORICAL_ALARMS } from '../../features/alarms/store/alarms-list/utils/alarmsList.utils';

/**
 * This hook will returns `true` value if the user is present on the historical alarms page.
 * @returns boolean
 */
const useIsHistoricalAlarms = () => {
  const location = useLocation();
  const isHistoricalAlarms = Boolean(location.pathname?.includes(`alarms/${HISTORICAL_ALARMS}`));
  return isHistoricalAlarms;
};

export default useIsHistoricalAlarms;
