import React from 'react';
import { useStyles } from './verificationTextComponent.styles';

interface Props {
  message: string;
}

export default function VerificationText(props: Props) {
  const classes = useStyles();
  const { message } = props;
  return (
    <div className={classes.grow}>
      <div className={classes.growContent}>{message}</div>
    </div>
  );
}
