/**
 * add digital sensor saga
 * @author ashwini.gawade@shorelineiot.com
 */
import { put, takeEvery, call, all } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import * as actions from './digitalSensor.actions';
import * as ACTION_TYPES from './digitalSensor.actionTypes';
import {
  GenericObject,
  httpGet,
  httpPut,
  SafeSaga,
  showToast,
  APISERVICES
} from '../../../../../../framework';
import { SagaIterator } from 'redux-saga';
// import { datapointActions } from "features/device/device-details/store/device-settings/device-data";

function getDataDIO1(data: any) {
  return httpGet(
    `orgs/${data.slug}/device_sensor_templates/type/${data.templateId}?device_id=${data.deviceId}&sensor_module_name=${data.sensorName}&port=${data.port}`,
    null,
    APISERVICES.DEVICE_API
  );
}
function* digitalSensorSagaDIO1(action: AnyAction) {
  const response: GenericObject = yield call(getDataDIO1, action.payload);
  yield put(actions.getDigitalSensorSchemaSuccessDIO1(response));
}

function* errorHandlerDI01(error: any) {
  yield put(actions.getDigitalSensorSchemaFailureDIO1(error));
}

function getDataDIO2(data: any) {
  return httpGet(
    `orgs/${data.slug}/device_sensor_templates/type/${data.templateId}?device_id=${data.deviceId}&sensor_module_name=${data.sensorName}&port=${data.port}`,
    null,
    APISERVICES.DEVICE_API
  );
}
function* digitalSensorSagaDIO2(action: AnyAction) {
  const response: GenericObject = yield call(getDataDIO2, action.payload);
  yield put(actions.getDigitalSensorSchemaSuccessDIO2(response));
}

function* errorHandlerDI02(error: any) {
  yield put(actions.getDigitalSensorSchemaFailureDIO2(error));
}

function getDataDIO3(data: any) {
  return httpGet(
    `orgs/${data.slug}/device_sensor_templates/type/${data.templateId}?device_id=${data.deviceId}&sensor_module_name=${data.sensorName}&port=${data.port}`,
    null,
    APISERVICES.DEVICE_API
  );
}
function* digitalSensorSagaDIO3(action: AnyAction) {
  const response: GenericObject = yield call(getDataDIO3, action.payload);
  yield put(actions.getDigitalSensorSchemaSuccessDIO3(response));
}

function* errorHandlerDI03(error: any) {
  yield put(actions.getDigitalSensorSchemaFailureDIO3(error));
}

function getDataDIO4(data: any) {
  return httpGet(
    `orgs/${data.slug}/device_sensor_templates/type/${data.templateId}?device_id=${data.deviceId}&sensor_module_name=${data.sensorName}&port=${data.port}`,
    null,
    APISERVICES.DEVICE_API
  );
}
function* digitalSensorSagaDIO4(action: AnyAction) {
  const response: GenericObject = yield call(getDataDIO4, action.payload);
  yield put(actions.getDigitalSensorSchemaSuccessDIO4(response));
}

function* errorHandlerDI04(error: any) {
  yield put(actions.getDigitalSensorSchemaFailureDIO4(error));
}

function* addDigitalSensorSaga(action: any) {
  const data = action.payload;
  const body = {
    device_id: data.deviceId,
    sensor_modules: data.modules
  };
  const response: GenericObject = yield httpPut(
    `orgs/${data.slug}/device_sensor_modules/multiple_modules`,
    body,
    APISERVICES.DEVICE_API
  );

  yield put(actions.addDigitalSensorSuccess(response));
  yield put(showToast('Sensor/sensors added/updated successfully!', 'success'));
  /* yield put(
    datapointActions.fetchDatapoints({
      slug: data.slug,
      deviceId: data.deviceId,
    })
  ); */
}

function* errorHandler(error: any) {
  yield put(actions.addDigitalSensorFailure(error));
}

export function* watchDigitalSensorSagas(): SagaIterator {
  yield all([
    takeEvery(
      ACTION_TYPES.FETCH_DIGITAL_SENSOR_SCHEMA_PROGRESS_DIO1,
      SafeSaga(
        digitalSensorSagaDIO1,
        ACTION_TYPES.FETCH_DIGITAL_SENSOR_SCHEMA_PROGRESS_DIO1,
        errorHandlerDI01
      )
    ),
    takeEvery(
      ACTION_TYPES.FETCH_DIGITAL_SENSOR_SCHEMA_PROGRESS_DIO2,
      SafeSaga(
        digitalSensorSagaDIO2,
        ACTION_TYPES.FETCH_DIGITAL_SENSOR_SCHEMA_PROGRESS_DIO2,
        errorHandlerDI02
      )
    ),
    takeEvery(
      ACTION_TYPES.FETCH_DIGITAL_SENSOR_SCHEMA_PROGRESS_DIO3,
      SafeSaga(
        digitalSensorSagaDIO3,
        ACTION_TYPES.FETCH_DIGITAL_SENSOR_SCHEMA_PROGRESS_DIO3,
        errorHandlerDI03
      )
    ),
    takeEvery(
      ACTION_TYPES.FETCH_DIGITAL_SENSOR_SCHEMA_PROGRESS_DIO4,
      SafeSaga(
        digitalSensorSagaDIO4,
        ACTION_TYPES.FETCH_DIGITAL_SENSOR_SCHEMA_PROGRESS_DIO4,
        errorHandlerDI04
      )
    ),
    takeEvery(
      ACTION_TYPES.ADD_DIGITAL_SENSOR_PROGRESS,
      SafeSaga(addDigitalSensorSaga, ACTION_TYPES.ADD_DIGITAL_SENSOR_PROGRESS, errorHandler)
    )
  ]);
}
