/**
 * Get default Dashboard Actions
 * @author harsha.gokhale@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTION_TYPES from './defaultDashboard.actionsTypes';
/**
 *
 */
export function getDefaultDashboard(data: any): AnyAction {
  return {
    type: ACTION_TYPES.DEFAULT_DASHBOARD_PROGRESS,
    payload: data
  };
}

export function setCurrentDashboardView(data: any): AnyAction {
  return {
    type: ACTION_TYPES.SET_CURRENT_DASHBOARD,
    payload: data
  };
}

/**
 *
 * @param data data to be returned on successful fetch API call
 */
export function handleGetDefaultDashboardSuccess(data: any): AnyAction {
  return {
    type: ACTION_TYPES.DEFAULT_DASHBOARD_SUCCESS,
    payload: data
  };
}
/**
 *
 */
export function handleGetDefaultDashboardFailure(error: any): AnyAction {
  return {
    type: ACTION_TYPES.DEFAULT_DASHBOARD_FAILURE,
    payload: error
  };
}

export const resetState = (): AnyAction => {
  return {
    type: ACTION_TYPES.DEFAULT_DASHBOARD_RESET_STATE,
    payload: false
  };
};
