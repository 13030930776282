/**
 * Can Access Component
 * @author mahesh.kedari@shorelineiot.com
 */

import React, { ReactNode, PropsWithChildren, ReactElement } from 'react';
import { usePermissions, ACCESS_PERMIT } from './accessControl';
// import { Text } from "react-native";

const useCheckAccess = (action: string, data: any): ACCESS_PERMIT => {
  const accessPass = usePermissions(action);
  if (data) {
    // handle dynamic Permissions
    return ACCESS_PERMIT.DENY;
  }
  // const permissions = rules[role];
  return accessPass;
};
interface AccessDeniedProps {
  isMobile?: boolean;
}
export function AccessDenied({
  isMobile = false
}: PropsWithChildren<AccessDeniedProps>): ReactElement {
  return isMobile ? (
    <>You are not authorized to perform this operation</>
  ) : (
    <>You are not authorized to perform this operation</>
  );
}

interface CanAccessProps {
  action: string;
  notReady?: ReactNode;
  no?: ReactNode;
  data?: any;
}

export default function CanAccess({
  action,
  data,
  notReady = null,
  no = null,
  children
}: PropsWithChildren<CanAccessProps>): ReactElement {
  const accessPass = useCheckAccess(action, data);

  switch (accessPass) {
    case ACCESS_PERMIT.DENY: {
      return <>{no}</>;
    }
    case ACCESS_PERMIT.ALLOW: {
      return <>{children}</>;
    }
    default: {
      return <>{notReady}</>;
    }
  }
}
