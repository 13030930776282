export enum UPDATE_RULE_TEMPLATE_STATUS {
  INIT,
  PROGRESS,
  SUCCESS,
  ERROR
}

export interface UpdateRuleTemplateState {
  status: UPDATE_RULE_TEMPLATE_STATUS;
  ruleDetails?: any;
}
