/**
 * SL Emailfield component
 * @author priyanka.ambawane@shorelineiot.com
 */
import React, { ReactElement, useEffect } from 'react';
import { InputLabel, FormControl, FormHelperText, Input } from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';
import { useStyles } from './common/SLForms.styles';

interface Props {
  name: string;
  label: string;
  rules?: Object | Function;
  watchField?: string;
  value?: any;
  updateValue?: (value: string) => string;
  onBlur?: ((value: any, fn: Function) => void) | undefined;
  [key: string]: any;
}

const emailRules = {
  email: {
    required: 'Email is required',
    minLength: {
      value: 6,
      message: 'Minimum 6 characters are required'
    },
    maxLength: {
      value: 64,
      message: 'Maximum 64 characters are allowed'
    },
    pattern: {
      value:
        /^\s*([a-zA-Z0-9_]+)([.+_-]?[a-zA-Z0-9]+)*@([a-zA-Z0-9]+)([._-]?[a-zA-Z0-9]+)*([.]{1}[a-zA-Z0-9]{2,})+$/,
      message: 'Enter a valid  email address'
    }
  }
};

export default function SLEmailField({
  name,
  label,
  onBlur,
  value,
  ...otherProps
}: Props): ReactElement {
  const {
    formState: { errors },
    setValue,
    getValues
  } = useFormContext();
  const classes = useStyles();

  const handleOnBlur = () => {
    if (onBlur) {
      onBlur(getValues(), setValue);
    }
  };

  useEffect(() => {
    if (value) {
      setValue(name, value);
    }
  }, [value]);

  return (
    <FormControl fullWidth error={Boolean(errors[name])} className={classes.root}>
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <Controller
        name={name}
        rules={emailRules.email}
        render={({ field: { onChange, ref }, fieldState: { error } }) => (
          <>
            <Input
              id={name}
              name={name}
              inputRef={ref}
              onChange={onChange}
              onBlur={handleOnBlur}
              error={!!error}
              {...otherProps}
            />
            <FormHelperText>{error && error?.message}</FormHelperText>
          </>
        )}
      />
    </FormControl>
  );
}
