/**
 * Get User Self Utility Method
 */
import { selfActions } from './store';
// import { AuthService } from "./auth.service";
/**
 *
 * @param slug - Current Slug
 * @param dispatch - Action Dispatch Method
 */
export const getUserSelf = async (slug: string, dispatch: any) => {
  // TODO: [SLC-5365] Commenting the below code regarding localstorage,
  // because it's causing blank dashboard & two 'self' API calls on org-switch.

  // const userString = localStorage.getItem("self");
  // if (userString) {
  //   const userObj = JSON.parse(userString);
  //   const authServiceInstance = new AuthService();
  //   const currentUser = await authServiceInstance.getCurrentUserInfo();
  //   if (userObj && userObj[slug]?.email === currentUser?.attributes?.email) {
  //     dispatch(selfActions.fetchSelfSuccess(userObj[slug]));
  //     return;
  //   }
  // }
  dispatch(selfActions.fetchSelf(slug));
};
