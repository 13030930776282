import { put, takeEvery } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import * as actions from '../actions/userUpdate.actions';
import * as actionTypes from '../actions/userSelf.actionTypes';
import * as selfActions from '../actions/userSelf.actions';
import {
  GenericObject,
  httpPatch,
  removeProgressFor,
  showProgressFor,
  showToast,
  APISERVICES
} from '../../../../framework';

/**
 *
 */

const UPDATE_USER_PROCESS_ID = 'UPDATE_USER';
/**
 *
 * @param action
 */

function* updateUser(action: AnyAction) {
  const data = action.payload;
  const body = {
    first_name: data.firstName,
    last_name: data.lastName,
    // mobile: data.phoneNumber,
    system_units: data.systemUnit,
    language: data.language
  };

  try {
    yield put(showProgressFor(UPDATE_USER_PROCESS_ID));
    const response: GenericObject = yield httpPatch(
      `orgs/${data.slug}/users/self`,
      body,
      APISERVICES.HOST_API
    );
    yield put(showToast('User Updated Successfully!', 'success'));
    yield put(actions.updateUserSuccess(response));
    yield put(selfActions.fetchSelf(data.slug));
  } catch (error: any) {
    yield put(showToast('User could not be updated!', 'error', true));
    yield put(actions.updateUserFailure(error));
  } finally {
    yield put(removeProgressFor(UPDATE_USER_PROCESS_ID));
  }
}
/**
 *
 * @param action

/**
 *
 */
export function* watchUserUpdateSaga() {
  yield takeEvery(actionTypes.UPDATE_USER, updateUser);
}
