/**
 * This is a custom base query function which needs to be used inside createApi hook.
 * It handles api calls at a common place.
 * It handles common error handling and progressBar.
 *
 */

import Bugsnag from '@bugsnag/js';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { BaseQueryFn } from '@reduxjs/toolkit/query';
import { removeProgressFor, showProgressFor } from '../../components';
import {
  APISERVICES,
  fileUploadPost,
  httpDelete,
  httpGet,
  httpPatch,
  httpPost,
  httpPut
} from '../../services';
import { HTTP_METHOD, HttpMethod } from '../../services/auth.service';
import { handleError } from '../../helpers/errorHandler';

interface BaseEndpointConfig {
  apiEndpoint: string;
  service: APISERVICES;
  method: HttpMethod;
  actionKey: string;
  onSuccess?: (dispatch: ThunkDispatch<any, any, any>, result: any) => void;
  onError?: (dispatch: ThunkDispatch<any, any, any>, error: any) => void;
}

interface GetEndpointConfig extends BaseEndpointConfig {
  method: HTTP_METHOD.GET;
  /** * Optional parameters for the `GET` API call. */
  params?: Record<string, any> | null;
  showProgressBar?: boolean;
}

interface MutationEndpointConfig extends BaseEndpointConfig {
  method: Exclude<HttpMethod, HTTP_METHOD.GET>;
  /** Optional parameters for the `mutation` query - `POST`, `PUT`, `DELETE` and `PATCH` API call. */
  body?: Record<string, any> | null;
  isFileUpload?: boolean;
  data?: any;
  showProgressBar?: boolean;
}

export type EndpointConfig = GetEndpointConfig | MutationEndpointConfig;

/**
 * This function is a base query function that takes in an object of parameters
 * and returns a promise containing the API response.
 *
 * @param {Object} options - An object containing the following parameters:
 *   apiEndpoint {string} - The endpoint for the API call.
 *   params {Object} - Optional parameters for the API call.
 *   service {string} - The name of the API service to use.
 *   method {string} - The HTTP method to use for the API call.
 *   actionKey {string} - The key to use for the Redux action.
 *   onSuccess {function} - A function to call on a successful API call.
 *   onError {function} - A function to call on an error.
 * @param {Object} extraOptions - An object containing additional options, such as dispatch.
 * @returns {Promise} - A promise containing the API response.
 */
export const axiosBaseQuery =
  (): BaseQueryFn<EndpointConfig, any, unknown> =>
  async (config, { dispatch }) => {
    const {
      apiEndpoint,
      service,
      method,
      actionKey,
      onSuccess,
      onError,
      showProgressBar = true
    } = config;

    const isGetRequest = (config: EndpointConfig): config is GetEndpointConfig =>
      config.method === 'GET';

    if (showProgressBar) {
      dispatch(showProgressFor(actionKey));
    }
    try {
      let result;
      switch (method) {
        case 'GET':
          result = await httpGet(
            apiEndpoint,
            isGetRequest(config) ? config.params : undefined,
            service
          );
          break;
        case 'PATCH':
          result = await httpPatch(apiEndpoint, config.body, service);
          break;
        case 'PUT':
          result = await httpPut(apiEndpoint, config.body, service);
          break;
        case 'DELETE':
          result = await httpDelete(apiEndpoint, config.body, service);
          break;
        case 'POST':
          if (config?.isFileUpload) {
            result = await fileUploadPost(apiEndpoint, config.data, null, service);
          } else {
            result = await httpPost(apiEndpoint, config.body, service);
          }
          break;
      }
      if (onSuccess) {
        onSuccess(dispatch, result);
      }
      return { data: result };
    } catch (err: any) {
      if (err) Bugsnag.notify(err);
      handleError(err, dispatch);
      if (onError) {
        onError(dispatch, err);
      }
      return { error: err?.response || 'error' };
    } finally {
      if (showProgressBar) {
        dispatch(removeProgressFor(actionKey));
      }
    }
  };
