import { AnyAction } from 'redux';
import * as ACTION_TYPES from './getSurveyQuestions.actionTypes';

export const getSurveyQuestionsProgress = (
  slug: string,
  deviceId: string | number,
  componentId: string
): AnyAction => {
  return {
    type: ACTION_TYPES.GET_SURVEY_QUESTIONS,
    payload: {
      slug,
      deviceId,
      componentId
    }
  };
};
/**
 *
 * @param data
 */
export const getSurveyQuestionsSuccess = (data: any): AnyAction => {
  // returns the action type
  return {
    type: ACTION_TYPES.GET_SURVEY_QUESTIONS_SUCCESS,
    data
  };
};
/**
 *
 * @param error
 */
export const getSurveyQuestionsFailure = (error: any): AnyAction => {
  // returns the action type
  return {
    type: ACTION_TYPES.GET_SURVEY_QUESTIONS_FAILURE,
    error
  };
};
/**
 *
 * @param data
 */

export const getSurveyQuestionsReset = (): AnyAction => {
  return {
    type: ACTION_TYPES.GET_SURVEY_QUESTIONS_RESET
  };
};
export const getSingleSurveyQuestionsProgress = (
  slug: string,
  deviceId: string | number,
  componentId: string,
  setShowSurveyJS?: any
): AnyAction => {
  return {
    type: ACTION_TYPES.GET_SINGLE_SURVEY_QUESTIONS,
    payload: {
      slug,
      deviceId,
      componentId,
      setShowSurveyJS
    }
  };
};
export const getSingleSurveyQuestionsSuccess = (data: any): AnyAction => {
  // returns the action type
  return {
    type: ACTION_TYPES.GET_SINGLE_SURVEY_QUESTIONS_SUCCESS,
    data
  };
};
/**
 *
 * @param error
 */
export const getSingleSurveyQuestionsFailure = (error: any): AnyAction => {
  // returns the action type
  return {
    type: ACTION_TYPES.GET_SINGLE_SURVEY_QUESTIONS_FAILURE,
    error
  };
};

export const setNBAutoFirstPageAnswers = (data: any): AnyAction => {
  // returns the action type
  return {
    type: ACTION_TYPES.SET_NBAUTO_FIRST_PAGE_VALUES,
    payload: data
  };
};
