/**
 * Anomaly Aggregator Saga
 * @author mahesh.kedari@shorelineiot.com
 */
import { all } from 'redux-saga/effects';
import { watchAnomalyDetailsSaga } from './anomaly-details/anomaly-details.saga';
import { watchAnomalyListSaga } from './anomaly-list/anomaly-list.saga';
import { watchAnomalyExpressionEvalResultSaga } from './expr-eval/expr-eval.saga';
/**
 *
 */
export function* watchAnomalySaga() {
  yield all([
    watchAnomalyDetailsSaga(),
    watchAnomalyListSaga(),
    watchAnomalyExpressionEvalResultSaga()
  ]);
}
