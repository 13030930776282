import React, { useState } from 'react';
import { capitalize } from '../../..';
import {
  Box,
  CircularProgress,
  Input,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ChartAndHistoricalTableProps } from './LeakDetectionViewer';
import LeakDetectionChartComponent from './LeakDetectionChartComponent';

enum RangeTypes {
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY'
}

const useStyles = makeStyles(() => ({
  root: {
    zIndex: 1
  },
  selectContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginRight: '30px'
  },
  chartContainer: {
    marginTop: -38
  }
}));

const LeakDetectionChartSettings = ({
  alarms,
  isFetchingAlarms,
  airborneData,
  isAirborneDataLoading
}: ChartAndHistoricalTableProps) => {
  const [range, setRange] = useState<RangeTypes>(RangeTypes.MONTHLY);
  const classes = useStyles();

  const handleRangeChange = (event: SelectChangeEvent<RangeTypes>) => {
    setRange(event.target.value as RangeTypes);
  };
  if (isFetchingAlarms || isAirborneDataLoading) {
    return <CircularProgress />;
  }
  if (alarms.length === 0) {
    return <Typography>No Historical Alarms Found</Typography>;
  }
  return (
    <>
      <Box className={classes.selectContainer}>
        <Select<RangeTypes>
          value={range}
          onChange={handleRangeChange}
          input={<Input disableUnderline />}
          className={classes.root}>
          {Object.keys(RangeTypes).map((item) => (
            <MenuItem key={item} value={item}>
              {capitalize(item)}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box className={classes.chartContainer}>
        <LeakDetectionChartComponent alarms={alarms} range={range} airborneData={airborneData} />
      </Box>
    </>
  );
};

export default LeakDetectionChartSettings;
