/**
 * Verification Saga
 * @author swapnil.kshirsagar@shorelineiot.com
 */
import { call, put, takeEvery, all } from 'redux-saga/effects';
import * as actions from '../actions/verification.actions';
import * as ACTION_TYPE from '../actions/verification.actionTypes';
import { removeProgressFor, showToast } from '../../../../../../framework';
import { AuthResponse, AuthService } from '../../../../../../framework/services/auth.service';

const EMAIL_VARIFICATION_ACTION_PROGRESS_ID = 'EMAIL_VARIFICATION_ACTION_PROGRESS_ID';

export function sendEmailLink(payload: any) {
  const authService = new AuthService();
  return authService.sendEmailLink(payload);
}

export function* callSendLink(action: any) {
  try {
    const response: AuthResponse = yield call(sendEmailLink, action.payload);
    if (response.type === 'SUCCESS') {
      yield put(showToast('Verification Link sent to email', 'success'));
      yield put(actions.sentLinkSuccess(response.message));
    } else {
      yield put(showToast('Unable to mail verification link', 'error', true));
      yield put(actions.sentLinkFailure(response.message));
    }
  } catch (error: any) {
    yield put(actions.sentLinkFailure(error));
  } finally {
    yield put(removeProgressFor(EMAIL_VARIFICATION_ACTION_PROGRESS_ID));
  }
}

export function* verificationSagas() {
  yield all([takeEvery(ACTION_TYPE.VERIFICATION_PROGERSS, callSendLink)]);
}
