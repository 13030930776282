/**
 * Dashboard Separator
 * @author mahesh.kedari@shorelineiot.com
 */
import { Grid, IconButton, Typography } from '@mui/material';
import React, { ReactElement } from 'react';
import CancelIcon from '@mui/icons-material/CancelOutlined';
import { useDispatch } from 'react-redux';
// import { LOCAL_DASHBOARD_ITEMS_ACTIONS } from '../../../features/dashboard/store';
import { SLForm, SLSelect, SLTextField } from '../form';
import SLColorPicker from '../color-picker/SLColorPicker';
import * as LOCAL_DASHBOARD_ITEMS_ACTIONS from '../../../features/dashboard/store/local-dashboard-items/localDashboardItems.action';

interface Props {
  config: any;
  editEnabled: boolean;
  dashboardUUID: string;
  dashboardItemUUID: string;
  handleUpdate: () => void;
}

export default function DashboardSeparator({
  config,
  editEnabled,
  dashboardUUID,
  dashboardItemUUID
}: Props): ReactElement {
  const dispatch = useDispatch();
  const handleLabelChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (
    data: any
  ) => {
    const currentPosition = config?.viewConfig?.positionMeta;

    const configCopy = config;
    const updatedConfigCopy = Object.assign(configCopy, config);
    updatedConfigCopy.viewConfig.positionMeta = currentPosition;

    updatedConfigCopy.label = data?.label;

    const input = {
      config: updatedConfigCopy,
      dashboard_uuid: dashboardUUID,
      local_item_uuid: dashboardItemUUID,
      dashboard_item_uuid: dashboardItemUUID
    };
    dispatch(LOCAL_DASHBOARD_ITEMS_ACTIONS.updateItemToLocalDashboardItems(input));
  };

  const defaultValues = {
    label: config?.label || '',
    fontSize: config?.fontSize || 'h3',
    color: config?.color || { hex: '#000000' },
    backgroundColor: config?.backgroundColor || { hex: '#1fe0' }
  };

  const fontSizeOptions: Array<any> = [
    {
      label: 'h1',
      value: 'h1'
    },
    {
      label: 'h2',
      value: 'h2'
    },
    {
      label: 'h3',
      value: 'h3'
    },
    {
      label: 'h4',
      value: 'h4'
    },
    {
      label: 'h5',
      value: 'h5'
    }
  ];

  const onChange = (data: any) => {
    const currentPosition = config?.viewConfig?.positionMeta;
    const configCopy = config;
    const updatedConfigCopy = Object.assign(configCopy, config);
    updatedConfigCopy.viewConfig.positionMeta = currentPosition;

    updatedConfigCopy.fontSize = data;

    const input = {
      config: updatedConfigCopy,
      dashboard_uuid: dashboardUUID,
      local_item_uuid: dashboardItemUUID,
      dashboard_item_uuid: dashboardItemUUID
    };
    dispatch(LOCAL_DASHBOARD_ITEMS_ACTIONS.updateItemToLocalDashboardItems(input));
  };

  const handleColorChange = (data: any) => {
    const currentPosition = config?.viewConfig?.positionMeta;
    const configCopy = config;
    const updatedConfigCopy = Object.assign(configCopy, config);
    updatedConfigCopy.viewConfig.positionMeta = currentPosition;

    updatedConfigCopy.color = { hex: data?.hex };

    const input = {
      config: updatedConfigCopy,
      dashboard_uuid: dashboardUUID,
      local_item_uuid: dashboardItemUUID,
      dashboard_item_uuid: dashboardItemUUID
    };
    dispatch(LOCAL_DASHBOARD_ITEMS_ACTIONS.updateItemToLocalDashboardItems(input));
  };

  const handleBackgroundColorChange = (data: any) => {
    const currentPosition = config?.viewConfig?.positionMeta;
    const configCopy = config;
    const updatedConfigCopy = Object.assign(configCopy, config);
    updatedConfigCopy.viewConfig.positionMeta = currentPosition;

    updatedConfigCopy.backgroundColor = { hex: data?.hex };

    const input = {
      config: updatedConfigCopy,
      dashboard_uuid: dashboardUUID,
      local_item_uuid: dashboardItemUUID,
      dashboard_item_uuid: dashboardItemUUID
    };
    dispatch(LOCAL_DASHBOARD_ITEMS_ACTIONS.updateItemToLocalDashboardItems(input));
  };

  const removeItem = () => {
    /* const input = {
      config,
      dashboard_uuid: dashboardUUID,
      local_item_uuid: dashboardItemUUID,
      dashboard_item_uuid: dashboardItemUUID,
    }; */
    dispatch(LOCAL_DASHBOARD_ITEMS_ACTIONS.deleteItemFromLocalDashboardItems(dashboardItemUUID));
  };
  return (
    <div>
      <SLForm
        defaultValues={defaultValues}
        onSubmit={() => {
          /*empty function*/
        }}>
        <Grid container spacing={2} justifyContent="flex-start" alignItems="flex-end">
          {editEnabled ? (
            <Grid item>
              <IconButton onClick={removeItem} size="large">
                <CancelIcon />
              </IconButton>
            </Grid>
          ) : null}
          {editEnabled && (
            <>
              <Grid item>
                <SLTextField name="label" label="Label" onBlur={handleLabelChange} />
              </Grid>
              <Grid item>
                <SLSelect
                  name="fontSize"
                  label="Font Size"
                  options={fontSizeOptions}
                  style={{ width: '70px' }}
                  onSelectChange={onChange}
                />
              </Grid>
              <Grid item>
                <SLColorPicker
                  label="Text Color"
                  name="color"
                  value={config?.color}
                  handleColorChange={handleColorChange}
                />
              </Grid>
              <Grid item>
                <SLColorPicker
                  label="Background Color"
                  name="backgroundColor"
                  value={config?.backgroundColor}
                  handleColorChange={handleBackgroundColorChange}
                />
              </Grid>
            </>
          )}
          {!editEnabled && (
            <Grid item>
              <Typography
                style={{
                  color: config?.color?.hex || 'black',
                  backgroundColor: config?.backgroundColor?.hex || 'transparent'
                }}
                variant={config?.fontSize || 'h3'}>
                {config?.label}
              </Typography>
            </Grid>
          )}
        </Grid>
      </SLForm>
    </div>
  );
}
