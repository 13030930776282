import React from 'react';
import './App.css';
import 'survey-react/survey.css';
import ReactGA from 'react-ga4';
import AppLayout from './config/AppLayout';
import { VITE_GA_TRACKING_ID } from './framework/services/envConstants';

const { hostname } = document.location;
if (VITE_GA_TRACKING_ID && hostname && hostname !== 'localhost') {
  ReactGA.initialize(VITE_GA_TRACKING_ID);
}

function App(): JSX.Element {
  sessionStorage.clear();
  return <AppLayout />;
}

export default App;
