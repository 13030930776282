/**
 * Component Details Saga
 * @author mahesh.kedari@shorelineiot.com
 */
import { takeEvery, put, call } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import * as COMPONENT_DETAILS_ACTIONS from './componentDetails.actionTypes';
import * as componentDetailsActions from './componentDetails.actions';
import * as componentListActions from '../component-list/componentList.actions';
import {
  httpGet,
  httpPost,
  httpPut,
  SafeSaga,
  showToast,
  APISERVICES
} from '../../../../../framework';
import { ComponentType } from './componentDetails.type';
import { SagaIterator } from 'redux-saga';

/**
 *
 */
function getcomponentDetails(data: any) {
  return httpGet(`component_compiler/${data.component_id}`, null, APISERVICES.DEVICE_API);
}
/**
 *
 */
function updateComponentDetails(data: any) {
  return httpPut(`component_compiler/${data.component_id}`, data.payload, APISERVICES.DEVICE_API);
}

function createComponentAPI(payload: any) {
  return httpPost(`component_compiler/copy_component`, payload, APISERVICES.DEVICE_API);
}
/**
 *
 */
function* fetchComponentSaga(action: AnyAction) {
  const response: ComponentType = yield call(getcomponentDetails, action.payload);
  yield put(componentDetailsActions.fetchComponentDetailsSuccess(response));
}
function* exportComponentSaga(action: AnyAction) {
  const response: ComponentType = yield call(getcomponentDetails, action.payload);
  const fileName = `component-${action.payload.component_id}`;
  const json = JSON.stringify(response || {}, null, 2);
  const blob = new Blob([json], { type: 'application/json' });
  const href = URL.createObjectURL(blob);

  // create "a" HTLM element with href to file
  const link = document.createElement('a');
  link.href = href;
  link.download = fileName + '.json';
  document.body.appendChild(link);
  link.click();

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
}
/**
 *
 */
function* updateComponentSaga(action: AnyAction) {
  const response: ComponentType = yield call(updateComponentDetails, action.payload);

  yield put(componentDetailsActions.updateComponentSuccess(response));
  yield put(showToast('Component Details Updated Successfully!', 'success'));
}

function* createComponentSaga(action: AnyAction) {
  const { navigate, apiPayload, slug } = action.payload;
  yield call(createComponentAPI, apiPayload);

  // yield put(componentDetailsActions.createComponentSuccess(response));

  /**
   * After creating component
   * redirecting user to component list page with newly fetched data
   */
  yield put(componentListActions.fetchComponentList());
  navigate({
    pathname: `/${slug}/app/templates/component-compiler`
  });
  yield put(showToast('Component Created Successfully!', 'success'));
}
/**
 *
 * @param error
 */
function* fetchErrorHandler(error: any) {
  yield put(componentDetailsActions.fetchComponentDetailsFailure(error));
}
/**
 *
 * @param error
 */
function* updateErrorHandler(error: any) {
  yield put(componentDetailsActions.updateComponentFailure(error));
}

function* createErrorHandler(error: any) {
  yield put(componentDetailsActions.createComponentFailure(error));
}
/**
 *
 */
export function* watchFetchComponentDetailsSaga(): SagaIterator {
  yield takeEvery(
    COMPONENT_DETAILS_ACTIONS.FETCH_COMPONENT_DETAILS,
    SafeSaga(
      fetchComponentSaga,
      COMPONENT_DETAILS_ACTIONS.FETCH_COMPONENT_DETAILS,
      fetchErrorHandler
    )
  );
}
/**
 * EXPORT SINGLE COMPONENT DETAILS
 */
export function* watchExportComponentDetailsSaga(): SagaIterator {
  yield takeEvery(
    COMPONENT_DETAILS_ACTIONS.EXPORT_COMPONENT_DETAILS,
    SafeSaga(
      exportComponentSaga,
      COMPONENT_DETAILS_ACTIONS.EXPORT_COMPONENT_DETAILS,
      fetchErrorHandler
    )
  );
}

/**
 *
 */
export function* watchUpdateComponentDetailsSaga(): SagaIterator {
  yield takeEvery(
    COMPONENT_DETAILS_ACTIONS.UPDATE_COMPONENT_DETAILS,
    SafeSaga(
      updateComponentSaga,
      COMPONENT_DETAILS_ACTIONS.UPDATE_COMPONENT_DETAILS,
      updateErrorHandler
    )
  );
  yield takeEvery(
    COMPONENT_DETAILS_ACTIONS.CREATE_COMPONENT_DETAILS,
    SafeSaga(
      createComponentSaga,
      COMPONENT_DETAILS_ACTIONS.CREATE_COMPONENT_DETAILS,
      createErrorHandler
    )
  );
}
