/**
 * Line Chart Editor
 * @author mahesh.kedari@shorelineiot.com
 */
import { Grid, Box, Button } from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { SLTextField } from '../../form';
import { ChartEditorProps } from '../common/ChartEditorProps';
import { useStyles } from '../chart.styles';
import WidgetSettingAccordion from '../common/WidgetAccordion';
import TranslateText from '../../../../i18n/TranslateText';
// import SLColorPicker from '../../color-picker/SLColorPicker';
/**
 *
 * @returns
 */
const SLGaugeChartEditor: React.FC<ChartEditorProps> = ({ handleSubmitForm }: ChartEditorProps) => {
  const classes = useStyles();
  /* const [averageColor, setAverageColor] = useState<any>({ hex: '#40739e' });
  const handleColorChange = (color: any) => {
    setAverageColor({ hex: color.hex });
  }; */

  const { setValue } = useFormContext();
  const checkMinMaxValue = (value: string) => {
    const newValue = value !== '' ? Number(value) : undefined;
    return Number.isNaN(newValue) ? undefined : newValue;
  };

  const handleUpdate = (data: any) => {
    const { chartType, min, max, unit } = data;
    const newMin = checkMinMaxValue(min);
    const newMax = checkMinMaxValue(max);
    if (newMax !== undefined || newMin !== undefined || unit !== undefined) {
      handleSubmitForm({
        chartType,
        chartSettings: { min: newMin, max: newMax, unit }
      });
    }
  };

  const resetToDefault = () => {
    setValue('min', '');
    setValue('max', '');
    setValue('unit', '');
    handleSubmitForm({
      chartType: 'gauge',
      chartSettings: {
        min: undefined,
        max: undefined,
        unit: undefined
      }
    });
  };

  return (
    <>
      <WidgetSettingAccordion title="common">
        <Grid container justifyContent="flex-start" spacing={2} alignItems="flex-end">
          <Grid item xs={6}>
            <SLTextField
              type="number"
              name="min"
              label="Scale Min"
              labelTranslationId="scale_min"
              rules={{ required: true, type: 'number' }}
              onBlur={handleUpdate}
            />
          </Grid>
          <Grid item xs={6}>
            <SLTextField
              type="number"
              name="max"
              label="Scale Max"
              labelTranslationId="scale_max"
              onBlur={handleUpdate}
            />
          </Grid>
          <Grid>
            <SLTextField name="unit" label="Unit" labelTranslationId="unit" onBlur={handleUpdate} />
          </Grid>
        </Grid>
      </WidgetSettingAccordion>
      <Box my={5}>
        <Button
          variant="contained"
          size="small"
          classes={{
            root: classes.root
          }}
          onClick={resetToDefault}>
          {TranslateText('reset_default', ' Reset Default')}
        </Button>
      </Box>
    </>
  );
};

export default SLGaugeChartEditor;
