export type ApiToken = {
  cognito_uuid: string;
  label: string | null;
  suborg_slug: string;
  api_key_id: string;
  created_on: number;
  org_slug: string;
  name: string;
  last_used_on: number | null;
  value: string;
};

export type Slug = string | undefined;

export type ApiTokenUpdatePayload = {
  label: string | null;
  api_key_id: string;
  slug: string | undefined;
};

export type ApiTokenDeletePayload = { api_key_id: string; slug: string | undefined };

export const APITOKENS_TAG_TYPES = {
  APITOKEN: 'ApiToken'
};
