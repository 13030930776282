/**
 * Anomaly Reducer
 * @author mahesh.kedari@shroelineiot.com
 */
import { combineReducers } from 'redux';
import details from './anomaly-details/anomaly-details.reducer';
import list from './anomaly-list/anomaly-list.reducer';
import exprEval from './expr-eval/expr-eval.reducer';
/**
 *
 */
const anomalyReducer = combineReducers({
  details,
  list,
  exprEval
});
/**
 *
 */
export default anomalyReducer;
