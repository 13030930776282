/**
 * Microseconds Time Formatter
 * @author mahesh.kedari@shorelineiot.com
 */
import moment from 'moment';

export const microsecondsTimeFormatter = (axisObject: any) => {
  const updatedValue = axisObject.value;
  const date = Math.ceil(Number(updatedValue) / 1000000);
  const dateTime = moment.unix(date).format('MM/DD/YY  HH:mm:ss ');
  return dateTime;
};
