/**
 * Features Reducer
 * @author mahesh.kedari@shorelineiot.com
 */
import { combineReducers } from 'redux';
import { deviceReducer as devices } from './device';
import alarms from './alarms/store/alarms.reducer';
import { anomalyTemplates } from './anomaly-templates';
import { userUpdateReducer, userSelf } from './user-settings/store';
import { websocketReducer as websocket } from './websocket';

import orgTheme from './theme/theme.reducer';
import auth from './auth/auth.reducer';
import organisations from './organization/org.reducer';
// import rules from './rules/rules.reducer';
import orgSettings from './org-settings/orgSettings.reducer';
import notifications from './home/reducers/notifications.reducer';
import alerts from './alerts/alerts.reducer';
import workflow from './workflow/workflow.reducer';
import reportReducer from './reports/store/reports.reducer';
import { dashboard } from './dashboard/store';
import { componentCompiler } from './templates/component-compiler/store';
import orgsSlice from './organization/store/orgs/orgs.slice';
import alarmsFiltersSlice from './alarms/store/alarmsFilters.slice';
/**
 *
 */
const featuresReducer = combineReducers({
  orgTheme,
  devices,
  auth,
  orgSettings,
  organisations,
  orgs: orgsSlice,
  alarmsFilters: alarmsFiltersSlice,
  // rules,
  userSelf,
  websocket,
  notifications,
  userUpdateReducer,
  workflow,
  dashboard,
  alerts,
  alarms,
  reportReducer,
  componentCompiler,
  anomalyTemplates
});
/**
 * Features reducer combined
 */
export default featuresReducer;
