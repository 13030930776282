/**
 * Vibration Analysis Action Types
 * @author mahesh.kedari@shorelineiot.com
 */

/** Device */
export const FETCH_DATAPOINTS = 'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/DEVICE/FETCH';
export const FETCH_DATAPOINTS_SUCCESS = 'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/DEVICE/SUCCESS';
export const FETCH_DATAPOINTS_FAILURE = 'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/DEVICE/ERROR';
export const RESET_DEVICE_STATE = 'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/DEVICE/RESET';
export const UPDATE_DATAPOINT_SELECTION =
  'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/DEVICE/UPDATE_SELECTION';
export const UPDATE_VA_TRUE_DATAPOINTS =
  'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/DEVICE/UPDATE_VA_TRUE_DATAPOINTS';
export const UPDATE_VA_TRUE_DATAPOINTS_FROM_WS =
  'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/DEVICE/UPDATE_VA_TRUE_DATAPOINTS_FROM_WS';

export const FULL_RESET_DEVICE_STATE = 'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/DEVICE/FULL_RESET';

export const SET_ONLY_DATE_TIME_PICKER_VALUES =
  'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/DEVICE/SET_ONLY_DATE_TIME_PICKER_VALUES';

/** Events */
export const FETCH_EVENTS = 'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/EVENTS/FETCH';
export const FETCH_EVENTS_SUCCESS = 'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/EVENTS/SUCCESS';
export const FETCH_EVENTS_FAILURE = 'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/EVENTS/ERROR';
export const RESET_EVENTS = 'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/EVENTS/RESET';
export const UPDATE_EVENT_SELECTION =
  'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/EVENTS/UPDATE_SELECTION';
export const UPDATE_MERGE_AXIS_SELECTION =
  'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/EVENTS/UPDATE_MERGE_AXIS_SELECTION';
export const UPDATE_EVENT_TITLE = 'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/EVENTS/UPDATE_TITLE';
export const FETCH_DEVICE_EVENTS = 'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/DEVICE_EVENTS/FETCH';
export const FETCH_DEVICE_EVENTS_SUCCESS =
  'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/DEVICE_EVENTS/SUCCESS';
export const FETCH_DEVICE_EVENTS_FAILURE =
  'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/DEVICE_EVENTS/ERROR';
export const UPDATE_TREND_CHART_DATA =
  'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/EVENTS/UPDATE_TREND_CHART_DATA';
export const FETCH_DATAPOINTS_TREND_CHART =
  'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/EVENTS/FETCH_DATAPOINTS_TREND_CHART';
export const FETCH_DATAPOINTS_TREND_CHART_SUCCESS =
  'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/EVENTS/FETCH_DATAPOINTS_TREND_CHART_SUCCESS';
export const FETCH_DATAPOINTS_TREND_CHART_FAILURE =
  'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/EVENTS/FETCH_DATAPOINTS_TREND_CHART_FAILURE';

export const FETCH_HISTORICAL_DATA =
  'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/EVENTS/FETCH_HISTORICAL_DATA';
export const FETCH_HISTORICAL_DATA_SUCCESS =
  'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/EVENTS/FETCH_HISTORICAL_DATA_SUCCESS';
export const FETCH_HISTORICAL_DATA_FAILURE =
  'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/EVENTS/FETCH_HISTORICAL_DATA_FAILURE';
export const UPDATE_DATE_TIME_PICKER_VALUES =
  'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/EVENTS/UPDATE_DATE_TIME_PICKER_VALUES';
export const FULL_RESET_EVENTS = 'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/EVENTS/FULL_RESET';

/** Narrowbands */
export const FETCH_NARROWBANDS = 'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/NARROWBANDS/FETCH';
export const FETCH_NARROWBANDS_SUCCESS = 'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/NARROWBANDS/SUCCESS';
export const RECALCULATE_NARROWBANDS =
  'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/NARROWBANDS/RECALCULATE';
export const FETCH_NARROWBANDS_FAILURE = 'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/NARROWBANDS/ERROR';
export const RESET_NARROWBANDS = 'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/NARROWBANDS/RESET';
export const UPDATE_NARROWBANDS_SELECTION =
  'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/NARROWBANDS/UPDATE_SELECTION';
export const UPDATE_CHECKED_NARROWBANDS =
  'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/NARROWBANDS/UPDATE_CHECKED';
export const UPDATE_BULK_CHECKED_NARROWBANDS =
  'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/NARROWBANDS/BULK_UPDATE_CHECKED';
export const FETCH_NARROWBAND_DATA = 'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/NARROWBAND/DATA/FETCH';
export const FETCH_NARROWBAND_DATA_SUCCESS =
  'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/NARROWBAND/DATA/SUCCESS';
export const FETCH_NARROWBAND_DATA_FAILURE =
  'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/NARROWBAND/DATA/FAILURE';
export const NARROWBANDS_AFTER_EVENT_UPDATE =
  'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/NARROWBAND/EVENTS/SUCCESSS';
export const CHANGE_ODR_NARROWBANDS = 'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/NARROWBANDS/CHANGE_ODR';

/** Harmonics */
export const UPDATE_HARMONICS = 'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/HARMONICS/UPDATE';
export const UPDATE_LOCK_HARMONICS_STATUS =
  'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/HARMONICS/UPDATE_LOCK_STATUS';
export const UPDATE_HARMONICS_COUNT = 'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/HARMONICS/UPDATE_COUNT';

/** Motion Units */
export const FETCH_MOTION_UNITS = 'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/MOTION_UNITS/FETCH';
export const FETCH_MOTION_UNITS_SUCCESS =
  'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/MOTION_UNITS/SUCCESS';
export const FETCH_MOTION_UNITS_FAILURE = 'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/MOTION_UNITS/ERROR';
export const RESET_MOTION_UNITS = 'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/MOTION_UNITS/RESET';
export const UPDATE_SELECTED_MOTION_UNIT =
  'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/MOTION_UNITS/UPDATE_SELECTION';
export const UPDATE_SELECTED_MOTION_UNIT_SUCCESS =
  'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/MOTION_UNITS/UPDATE_SELECTION_SUCCESS';

/** Other Unit Change */
export const UPDATE_FREQUENCY_UNIT =
  'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/FREQUENCY_UNITS/UPDATE_SELECTION';
export const UPDATE_AMPLITUDE_SELECTION =
  'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/AMPLITUDE/UPDATE_SELECTION';

export const RESET_VIBRATION_ANALYSIS = 'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/RESET';

/** FFT Domain - Time Or Frequency */
export const UPDATE_FFT_DOMAIN = 'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/FFT_DOMAIN/UPDATE_SELECTION';

/** FFT Frequency and Time Change */
export const UPDATE_FFT_TIME_DATA = 'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/FFT_TIME_DATA';
export const UPDATE_FFT_FREQUENCY_DATA = 'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/FFT_FREQUENCY_DATA';
export const FETCH_FFT_SPECTRUM = 'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/FFT/FETCH_SPECTRUM';
export const UPDATE_FFT_CHART_FOR_ZERO_DEVICE_EVENT =
  'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/FFT/UPDATE_FFT_CHART_FOR_ZERO_DEVICE_EVENT';
export const UPDATE_TIME_DOMAIN_CHART_FOR_ZERO_DEVICE_EVENT =
  'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/FFT/UPDATE_TIME_DOMAIN_CHART_FOR_ZERO_DEVICE_EVENT';
export const FETCH_FFT_SPECTRUM_FAILURE =
  'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/FFT/FETCH_SPECTRUM_FAILURE';
/** FFT peakSearch */
export const UPDATE_FFT_PEAK_SEARCH = 'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/FFT_PEAK_SEARCH_UPDATE';
export const UPDATE_FFT_ZOOM = 'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/FFT_ZOOM_UPDATE';
export const FFT_PEAK_SEARCH_TOGGLE = 'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/FFT_PEAK_SEARCH_TOGGLE';

/** FFT peakSearch */
export const UPDATE_HARMONICS_LIST =
  'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/FFT_UPDATE_HARMONICS_LIST';
export const UPDATE_HARMONICS_ORIGINAL_LIST =
  'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/FFT_UPDATE_HARMONICS_ORIGINAL_LIST';
/** FFT SpectrumSeries ID */
export const UPDATE_SPECTRUM_SERIES_ID =
  'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/FFT_UPDATE_SPECTRUM_SERIES_ID';
export const RESET_FFT_CHART = 'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/FFT_CHART/RESET';

/** FFTchart Indicators */
export const SET_CURRENT_INDICATORS =
  'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/FFT_CHART/SET_CURRENT_INDICATORS';
export const SET_SIDEBAND_STATE =
  'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/FFT_CHART/SET_SIDEBAND_STATE';
export const SET_LOCK_SIDEBAND_STATE =
  'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/FFT_CHART/SET_LOCK_SIDEBAND_STATE';

/** CPM Update */
export const UPDATE_CPM = 'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/CPM/UPDATE';
export const UPDATE_CPM_SUCCESS = 'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/CPM/UPDATE/SUCCESS';

/** Diagnosis */
export const FETCH_EVENT_DIAGNOSIS = 'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/EVENT_DIAGNOSIS/FETCH';
export const FETCH_EVENT_DIAGNOSIS_SUCCESS =
  'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/EVENT_DIAGNOSIS/SUCCESS';

/** FFT Time Chart Delta Marker*/
export const SET_TIME_CHART_CURRENT_INDICATORS =
  'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/FFT_CHART/SET_TIME_CHART_CURRENT_INDICATORS';
export const SET_LOCK_DELTA_MARKER_STATE =
  'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/FFT_CHART/SET_LOCK_DELTA_MARKER_STATE';
export const SET_DELTA_MARKER_STATE =
  'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/FFT_CHART/SET_DELTA_MARKER_STATE';

/** FFT Time Chart Sliding Window */
export const SET_SLIDING_WINDOW_STATE =
  ' FEATURES/WORKFLOW/VIBRATION_ANALYSIS/FFT_CHART/SET_SLIDING_WINDOW_STATE';
export const UPDATE_SLIDING_WINDOW_COUNT =
  'FEATURES/WORKFLOW/VIBRATION_ANALYSIS/SLIDING_WINDOW/UPDATE_SLIDING_WINDOW_COUNT';
