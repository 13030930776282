import React, { useEffect, useMemo, useState } from 'react';
import { Tag, TreeSelect } from 'antd';
import './externalComponent.css';
import { Box } from '@mui/system';
import SLInputLabel from '../form/SLInputLabel';
import MaxTagPlaceholder from './MaxTagPlaceholder';
import { CircularProgress } from '@mui/material';
import { useStyles } from './MultiselectDropdown.styles';
import { SLButton } from '../button';
import {
  concatArray,
  getChildOfParent,
  getPropsForValues,
  getValuesForTitle
} from './multiSelectDropdown.helper';
import {
  CHECKED_STRATEGY_TYPE,
  TREEDATA_PARENT,
  TreeDataType
} from '../../types/MultiSelectDropdownTypes';
import { LightTooltip } from '../tooltip/Tooltip';
import { SelectCommonPlacement } from 'antd/es/_util/motion';
import TranslateText from '../../../i18n/TranslateText';
import { toSnakeCase } from '../../../i18n/translation.helper';

interface Props {
  /**
   * Data of the treeNodes
   */
  treeData: Array<TreeDataType>;
  /**
   * Value of dropdown
   */
  value?: string[] | undefined;
  /**
   * To set the initial selected treeNode
   */
  defaultValue?: string[] | undefined;
  /**
   * Function execute when dropdown selection change
   */
  onOptionChange?: any;
  /**
   * Whether to show checkbox on the treeNodes.
   * If `true`, the treeNodes can be selected by clicking on them, and multiple
   * nodes can be selected, else only one node can be selected if `false`.
   * @default true
   */
  treeCheckable?: boolean;
  /**
   * Option showing strategy which node to select.
   * - SHOW_PARENT: Return the parent node when all child selected
   * - SHOW_ALL: Return all nodes. parent as well as child nodes
   * - SHOW_CHILD: Return all child nodes only
   */
  showCheckedStrategy?:
    | CHECKED_STRATEGY_TYPE.SHOW_ALL
    | CHECKED_STRATEGY_TYPE.SHOW_PARENT
    | CHECKED_STRATEGY_TYPE.SHOW_CHILD;
  /**
   * Placeholder for dropdown
   */
  placeholder?: string;
  /**
   * style for treeSelect
   */
  style?: any;
  /**
   * Clear all options at once
   */
  allowClear?: boolean;
  /**
   * Varient for dropdown style
   */
  bordered?: 'LINE' | 'ROUND' | 'BOX';
  /**
   * Disabled or not
   */
  disabled?: boolean;
  /**
   * Determine whether the dropdown menu and the select input are the same width
   */
  dropdownMatchSelectWidth?: boolean;
  /**
   * Max tag count to show
   */
  maxTagCount?: number;
  /**
   * Specify content to show when no result matches
   */
  notFoundContent?: string;
  /**
   * Whether to show the suffixIcon，when single selection mode, default true
   */
  showArrow?: boolean;
  /**
   * Whether to expand all treeNodes by default
   */
  treeDefaultExpandAll?: boolean;
  /**
   * Whether to filter treeNodes by input value.
   * The value of treeNodeFilterProp is used for filtering by default
   */
  treeNodeFilterProp?: string;
  /** Use this prop to set the default expand action of a particular treeNode.
   * `Usage` - Just pass `String[]` which contains value(s) of the items.
   */
  treeDefaultExpandedKeys?: React.Key[] | undefined;
  /**
   * Function execute when click outside dropdown
   */
  onBlurDropdown?: any;
  /**
   * Function Execute when click on close icon
   */
  onCloseClick?: any;
  /**
   * Whether tag tag is custom or not
   */
  isCustomTag?: boolean;
  /**
   * If false, it will not show close icon[x] on tag
   * bydefault it is true
   */
  isCustomTagclosable?: boolean;
  /**
   * Number of checkbox selectable
   */
  checkboxLimit?: number;
  /**
   * To set the style of the dropdown menu
   */
  dropdownStyle?: React.CSSProperties | undefined;
  /**
   * Config popup height
   */
  listHeight?: number;
  /**
   * Function execute when clicks on
   * tag close icon
   */
  onTagCloseButton?: any;
  /**
   * Function execute when dropdown opens
   */
  onOpenDropdown?: any;
  /**
   *If true show title in tag,
   *else show value in tag
   */
  titleTag?: boolean;
  /**
   * Support search or not
   * only require when ```treeCheckable``` is false
   */
  showSearch?: boolean;
  /**
   * whether data in fetching state or not
   */
  fetchingData?: boolean;
  /**
   *  Label will be shown on the top of select component.
   */
  label?: string | undefined;
  /**
   * When onBlur not work correctly we can use this function
   * executed when dropdown closed
   */
  onDropdownClose?: ((value: string[], selectedNodes?: string[]) => void) | undefined;
  /**
   * Style for label
   */
  labelStyle?: any;
  /**
   * Whether to open dropdown menu by default
   */
  defaultOpenDropdownMenu?: boolean;
  /**
   * Diff parent node selection vs child nodes
   */
  treeCheckStrictly?: boolean;
  dataTestId?: string;
  placement?: SelectCommonPlacement;
  /**
   * it will return user selected node either parent/child
   */
  onNodeSelect?: ((selectedNodes: string[], selectedValues: string[]) => void) | undefined;
  /**
   * Remove 'All' as parent from treeData
   */
  removeAllAsParent?: boolean;
}
type CustomTagProps = {
  label: React.ReactNode;
  value: any;
  disabled: boolean;
  onClose: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  closable: boolean;
  isMaxTag: boolean;
};

const MultiSelectDropdown = ({
  treeData,
  value,
  defaultValue = undefined,
  onOptionChange,
  treeCheckable = true,
  showCheckedStrategy = CHECKED_STRATEGY_TYPE.SHOW_CHILD,
  placeholder = 'Please select',
  style,
  allowClear = true,
  bordered,
  disabled = false,
  dropdownMatchSelectWidth,
  maxTagCount = 2,
  listHeight = 400,
  notFoundContent,
  showArrow = true,
  treeDefaultExpandAll = true,
  treeDefaultExpandedKeys,
  treeNodeFilterProp = 'title',
  onBlurDropdown,
  onCloseClick,
  isCustomTag,
  checkboxLimit,
  dropdownStyle,
  onTagCloseButton,
  onOpenDropdown,
  titleTag,
  showSearch,
  fetchingData,
  label,
  onDropdownClose,
  labelStyle,
  isCustomTagclosable = true,
  defaultOpenDropdownMenu = false,
  treeCheckStrictly = false,
  dataTestId,
  placement,
  onNodeSelect,
  removeAllAsParent = false
}: Props) => {
  const classes = useStyles();

  const [newValue, setNewValue]: any = useState<string[] | undefined>(value);
  const [searchFilterData, setSearchFilterData] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');

  const [selectedNodes, setSelectedNodes] = useState<string[]>([]);

  const newStyle: React.CSSProperties | undefined = style || { width: '100%' };

  useEffect(() => {
    setNewValue(value);
    if (value && onNodeSelect) {
      setSelectedNodes(value);
    }
  }, [value]);

  const borderVarient = () => {
    switch (bordered) {
      case 'LINE':
        return classes.borderBottom;
      case 'ROUND':
        return classes.roundBorder;
      default:
        return null;
    }
  };
  const onChange = (updatedValue: string[], labelList: React.ReactNode[]) => {
    if (checkboxLimit && updatedValue.length <= checkboxLimit) {
      setNewValue(updatedValue);
    } else if (!checkboxLimit) {
      setNewValue(updatedValue);
    }

    /**
     * selectedNodes has all the user clicked nodes info but when user un-select any node then
     * we are filtering out those un-selected nodes from selectedNodes.
     * onSelect methods adds node to selectedNodes
     */
    const updateSelectedNodes: string[] = Array.from(
      new Set(selectedNodes.filter((node: string) => updatedValue.includes(node)))
    );

    if (onNodeSelect) {
      setSelectedNodes(updateSelectedNodes);
    }
    if (onOptionChange) onOptionChange(updatedValue, labelList, [...updateSelectedNodes]);
  };

  const onBlur = () => {
    if (onBlurDropdown) onBlurDropdown(newValue);
  };

  const clearSearchData = () => {
    setSearchFilterData([]);
    setSearchValue('');
  };
  const onClear = () => {
    setNewValue([]);
    clearSearchData();
    if (onCloseClick) onCloseClick();
  };

  const onTagClose = useMemo(
    () => (text: string) => {
      const activeTag = newValue?.filter((item: any) => item !== text);
      setNewValue(activeTag);
      onTagCloseButton(activeTag);
    },
    [newValue, onTagCloseButton]
  );

  /**
   * Function triggers on select
   * and give information of treeData
   * @param selectedKeys
   * @param info
   */
  const onSelect = (selectedKeys: any, info: TreeDataType) => {
    if (onNodeSelect) {
      const updatedSelectedNodes = Array.from(new Set([...selectedNodes, info.value]));

      setSelectedNodes(updatedSelectedNodes);
      onNodeSelect(updatedSelectedNodes, newValue);
    }
  };

  const onDropDownopen = () => {
    if (onOpenDropdown) onOpenDropdown();
  };

  const onDropDownListClose = (open: boolean) => {
    if (!open) {
      if (treeCheckable) {
        onBlur();
      }
      if (onDropdownClose) {
        onDropdownClose(newValue, selectedNodes);
      }
    }
    clearSearchData();
  };

  const onSearch = (value: string) => {
    if (value.length) {
      setSearchValue(value);
      const data = getValuesForTitle(treeData, value, showCheckedStrategy);
      setSearchFilterData(data);
    } else {
      /**
       * If handle condition where search value is empty if click on select all | deselect all button.
       * Not clearing search value if previus search value is greater than 1
       * with assumption that search value is suddenly cleared becuase of click on select all | deselect all.
       * If prevoius search value is less than 1 then clearing search value with assumption that user manually cleared search value by backspace
       */
      if (searchValue.length > 1 && value.length === 0) {
        setSearchValue(searchValue);
        const data = getValuesForTitle(treeData, searchValue, showCheckedStrategy);
        setSearchFilterData(data);
      } else {
        clearSearchData();
      }
    }
  };

  const onSelectAll = () => {
    //Combine search value and already selected value
    const selectedValues = concatArray(newValue, searchFilterData);
    const labelList = getPropsForValues(treeData, selectedValues);
    //Filtering enabled value that is not disabled
    const valueList = getPropsForValues(treeData, selectedValues, 'value');
    if (valueList.length) {
      const values =
        showCheckedStrategy === CHECKED_STRATEGY_TYPE.SHOW_CHILD
          ? getChildOfParent(treeData, valueList)
          : valueList;

      setNewValue(values);
      onChange(values, labelList);
    }
  };

  const onDeselectAll = () => {
    //Filtering value which are not result of search
    const notSearchedValue = newValue.filter((value: string) => !searchFilterData.includes(value));
    setNewValue(notSearchedValue);
  };

  const isSelectAllDisabled = () => {
    if (checkboxLimit) {
      if (searchFilterData.length <= checkboxLimit) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const newTreeData = useMemo(() => {
    //If checkbox limit is enabled or do not want All as parent to data
    // or dropdown is not checkable then return treeData
    if (checkboxLimit || removeAllAsParent || !treeCheckable) {
      return treeData;
    }
    if (treeCheckable) {
      const ParentAllTreeData: TreeDataType[] = [
        {
          title: TREEDATA_PARENT.TITLE,
          value: TREEDATA_PARENT.VALUE,
          children: treeData
        }
      ];
      return ParentAllTreeData;
    } else {
      return treeData;
    }
  }, [checkboxLimit, removeAllAsParent, treeCheckable, treeData]);

  const tagRender = useMemo(
    () => (props: CustomTagProps) => {
      const { label, value, isMaxTag } = props;
      const tagText = titleTag ? label : value;

      const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
        event.preventDefault();
        event.stopPropagation();
      };

      return (
        <Tag
          closable={isCustomTagclosable && !isMaxTag}
          onMouseDown={onPreventMouseDown}
          onClose={() => onTagClose(value)}>
          {isMaxTag ? label : tagText}
        </Tag>
      );
    },
    [isCustomTagclosable, titleTag, onTagClose]
  );

  return (
    <Box>
      {label && (
        <Box mb={1}>
          <SLInputLabel className={labelStyle ? labelStyle : ''}>
            {TranslateText(toSnakeCase(label), label)}
          </SLInputLabel>
        </Box>
      )}
      <Box className={`${classes.overFlow} ${classes.textAlignToLeft} ${borderVarient()}`}>
        <TreeSelect
          data-test-id={dataTestId}
          treeData={newTreeData}
          maxTagPlaceholder={(props) => {
            return (
              <>
                <MaxTagPlaceholder placeholder={props} isTitle={titleTag} />
              </>
            );
          }}
          value={newValue}
          treeCheckStrictly={treeCheckStrictly}
          defaultValue={defaultValue}
          onChange={onChange}
          onSearch={onSearch}
          searchValue={searchValue}
          treeCheckable={treeCheckable}
          showCheckedStrategy={showCheckedStrategy}
          placeholder={TranslateText(toSnakeCase(placeholder), placeholder)}
          style={{ ...newStyle }}
          allowClear={allowClear}
          bordered={bordered !== 'LINE' && bordered !== 'ROUND'}
          disabled={disabled}
          dropdownMatchSelectWidth={dropdownMatchSelectWidth}
          dropdownStyle={dropdownStyle}
          maxTagCount={maxTagCount}
          listHeight={listHeight}
          showArrow={showArrow}
          placement={placement}
          treeDefaultExpandAll={treeDefaultExpandAll}
          treeDefaultExpandedKeys={treeDefaultExpandedKeys || [TREEDATA_PARENT.VALUE]}
          treeNodeFilterProp={treeNodeFilterProp}
          showSearch={showSearch}
          autoClearSearchValue={false}
          notFoundContent={
            fetchingData ? (
              <div className={classes.loader}>
                <CircularProgress size="1.5rem" />
              </div>
            ) : (
              notFoundContent
            )
          }
          //Cutomize dropdown view to add Select All | Deselect All button
          dropdownRender={(menu) => (
            <div>
              {/* Hiding Select All | Deselect All button when searchFilterData is not present and
                due to some technical limitation in antd dropdown showing Select All | Deselect All
                button when search lenght is greater than 1 */}
              {searchFilterData.length > 0 && searchValue.length > 1 && treeCheckable && (
                <div style={{ textAlign: 'center' }}>
                  <LightTooltip
                    disableHoverListener={!isSelectAllDisabled()}
                    title={`Disabled since only ${checkboxLimit} options are allowed for selection`}
                    placement="top-start">
                    <div style={{ display: 'inline' }}>
                      <SLButton
                        onClick={() => onSelectAll()}
                        disabled={isSelectAllDisabled()}
                        variant="outlined"
                        translationId="select_all">
                        Select All
                      </SLButton>
                    </div>
                  </LightTooltip>
                  &nbsp;
                  <SLButton
                    onClick={() => onDeselectAll()}
                    variant="outlined"
                    translationId="deselect_all">
                    Deselect All
                  </SLButton>
                </div>
              )}
              <div>{menu}</div>
            </div>
          )}
          defaultOpen={defaultOpenDropdownMenu}
          treeExpandAction="click"
          /**As we have given select all | deselect all buttons in dropdown render,
           * On clicking those, onBlur function get called even though dropdown is not closed yet so to avoid it,
           * Calling onBlur function when treeCheckable is false when select all | deselect all buttons are hidden else
           * We are calling it from onDropDownListClose when dropdown is close.
           */
          onBlur={!treeCheckable ? onBlur : undefined}
          onClear={onClear}
          onSelect={onSelect}
          onFocus={onDropDownopen}
          onDropdownVisibleChange={onDropDownListClose}
          tagRender={isCustomTag ? tagRender : undefined}
        />
      </Box>
    </Box>
  );
};

export default MultiSelectDropdown;
