/**
 * Delete Anomaly Template Reducer
 * @author aditya.bhadange@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTIONS from './deleteAnomalyTemplate.actionTypes';

export enum DELETE_ANOMALY_TEMPLATE_STATUS {
  INIT,
  FETCHING,
  READY,
  ERROR
}

export interface DeleteAnomalyTemplateState {
  status: DELETE_ANOMALY_TEMPLATE_STATUS;
}

const initialState: DeleteAnomalyTemplateState = {
  status: DELETE_ANOMALY_TEMPLATE_STATUS.INIT
};

const handleDeleteAnomalyTemplateProgress = (
  state: DeleteAnomalyTemplateState
): DeleteAnomalyTemplateState => ({
  ...state,
  status: DELETE_ANOMALY_TEMPLATE_STATUS.FETCHING
});

const handleDeleteAnomalyTemplateSuccess = (
  state: DeleteAnomalyTemplateState
): DeleteAnomalyTemplateState => ({
  ...state,
  status: DELETE_ANOMALY_TEMPLATE_STATUS.READY
});
/**
 *
 * @param state
 * @returns
 */
const handleDeleteAnomalyTemplateFailure = (
  state: DeleteAnomalyTemplateState
): DeleteAnomalyTemplateState => ({
  ...state,
  status: DELETE_ANOMALY_TEMPLATE_STATUS.ERROR
});

/**
 * Reducer for the delete anomaly template.
 * @param state
 * @param action
 * @returns
 */
export default function deleteAnomalyTemplateReducer(
  state: DeleteAnomalyTemplateState = initialState,
  action: AnyAction
): DeleteAnomalyTemplateState {
  switch (action.type) {
    case ACTIONS.DELETE_ANOMALY_TEMPLATE:
      return handleDeleteAnomalyTemplateProgress(state);
    case ACTIONS.DELETE_ANOMALY_TEMPLATE_SUCCESS:
      return handleDeleteAnomalyTemplateSuccess(state);
    case ACTIONS.DELETE_ANOMALY_TEMPLATE_FAILURE:
      return handleDeleteAnomalyTemplateFailure(state);
    case ACTIONS.DELETE_ANOMALY_TEMPLATE_RESET:
      return initialState;
    default:
      return state;
  }
}
