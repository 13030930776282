/**
 * Get Dashboard Items Reducer
 */
import { AnyAction } from 'redux';
import * as ACTION_TYPE from './dashboardItems.actionsTypes';
import { DashboardItemsState, DashboardItemsStatus } from './dashboardItems.stateType';
import * as DELETE_ITEM_ACTION_TYPE from '../delete-dashboard-item/deleteDashboardItem.actionTypes';

const initialState: DashboardItemsState = {
  status: DashboardItemsStatus.INIT,
  dashboardItemsData: []
};

function getDashboardItemsProgress(state: DashboardItemsState): DashboardItemsState {
  return {
    ...state,
    status: DashboardItemsStatus.FETCHING
  };
}

function getDashboardItemsSuccess(
  state: DashboardItemsState,
  dashboardItemsData: any
): DashboardItemsState {
  return {
    ...state,
    status: DashboardItemsStatus.READY,
    dashboardItemsData
  };
}

function getDashboardItemsFailure(state: DashboardItemsState): DashboardItemsState {
  return {
    ...state,
    status: DashboardItemsStatus.ERROR
  };
}

function resetState(state: DashboardItemsState): DashboardItemsState {
  return {
    ...state,
    status: DashboardItemsStatus.INIT,
    dashboardItemsData: []
  };
}

function removeDashboardItemFromList(state: DashboardItemsState, dashboard_item_uuid: any): any {
  const dashboardItemsData = { ...state.dashboardItemsData };
  const updatedItemsList = [...dashboardItemsData.dashboard_items];
  const index: number = updatedItemsList.findIndex((item: any) => {
    return item?.dashboard_item_uuid === dashboard_item_uuid;
  });
  if (index > -1) {
    updatedItemsList.splice(index, 1);
  }
  dashboardItemsData.dashboard_items = updatedItemsList;

  return {
    ...state,
    status: DashboardItemsStatus.READY,
    dashboardItemsData
  };
}

/*
    Reducer is a pure function that takes actions and state as input 
    and returns/passes the modified state to the store.
    It is implemented using switch case to update the state according 
    to the ation type required.
*/

export default function dashboardItemsReducer(
  state: DashboardItemsState = initialState,
  action: AnyAction
): DashboardItemsState {
  switch (action.type) {
    case ACTION_TYPE.DASHBOARD_ITEMS_PROGRESS:
      return getDashboardItemsProgress(state);
    case ACTION_TYPE.DASHBOARD_ITEMS_SUCCESS:
      return getDashboardItemsSuccess(state, action.payload);
    case ACTION_TYPE.DASHBOARD_ITEMS_FAILURE:
      return getDashboardItemsFailure(state);
    case ACTION_TYPE.DASHBOARD_ITEMS_RESET_STATE:
      return resetState(state);
    case DELETE_ITEM_ACTION_TYPE.DELETE_DASHBOARD_ITEM_SUCCESS:
      return removeDashboardItemFromList(state, action.payload);
    default:
      return state;
  }
}
