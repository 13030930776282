/**
 * Delete Anomaly Template Action Types
 * @author aditya.bhadange@shorelineiot.com
 */

export const DELETE_ANOMALY_TEMPLATE = 'FEATURES/ANOMALY_TEMPLATES/ANOMALY_TEMPLATE/DELETE';
export const DELETE_ANOMALY_TEMPLATE_SUCCESS =
  'FEATURES/ANOMALY_TEMPLATES/ANOMALY_TEMPLATE/DELETE/SUCCESS';
export const DELETE_ANOMALY_TEMPLATE_FAILURE =
  'FEATURES/ANOMALY_TEMPLATES/ANOMALY_TEMPLATE/DELETE/FAILURE';
export const DELETE_ANOMALY_TEMPLATE_RESET =
  'FEATURES/ANOMALY_TEMPLATES/ANOMALY_TEMPLATE/DELETE/RESET';
