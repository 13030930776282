import { Theme, MenuItemClasses } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

/**
 *
 */
export const MuiMenuItemStyle:
  | Partial<OverridesStyleRules<keyof MenuItemClasses, 'MuiMenuItem', Omit<Theme, 'components'>>>
  | undefined = {
  root: {
    '& .MuiBox-root': {
      /**
       * This will increase the font-size of the Column Actions text
       * of the material-react-table.
       */
      fontSize: '0.89rem'
    },
    '& .MuiBox-root .MuiTypography-root.MuiTypography-body1': {
      fontSize: '0.89rem'
    },
    '& .MuiBox-root .MuiSwitch-root': {
      height: '25px',
      width: '45px',
      padding: '8px 10px'
    },
    '& .MuiBox-root .MuiSwitch-root .MuiSwitch-thumb': {
      height: '12px',
      width: '12px'
    },
    '& .MuiBox-root .MuiSwitch-root .MuiButtonBase-root': {
      padding: '6px'
    }
  }
};
