/**
 * widget details
 */
export enum widgetDetailsStatus {
  INIT,
  FETCHING,
  READY,
  ERROR,
  RESET
}
export interface widgetDetailsState {
  status: widgetDetailsStatus;
  widgetDetailsData: any;
}
