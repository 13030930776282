import { LOCALES } from '../locales';

export default {
  [LOCALES.FRENCH]: {
    welcomeStatement: 'Langue',
    email: 'E-mail',
    password: 'Mot de passe',
    submit: 'Soumettre',
    dashboard: 'Tableau de bord',
    device: 'Appareils',
    powertrains: 'Chaînes cinématiques',
    device_profile: "Profil de l'appareil",
    profile: 'Profil',
    sensor_module: 'Module de capteur',
    rules: 'Règles',
    organization: "Paramètres de l'organisation",
    export_data: 'Exporter les données',
    firmware: 'Micrologiciel',
    workflow: 'Flux de travail',
    reports: 'Rapports',
    settings: 'Personnalisation',
    user_settings: 'Paramètres utilisateur',
    activity_logs: "Journaux d'activité",
    genai_bot: 'GenAI Bot',
    custom_dashboard: 'Tableau de bord',
    alarm: 'Alarmes',
    alerts: 'Alertes',
    snapshot: 'Instantané',
    assets: 'Actifs',
    templates: 'Modèles',
    addDashboard: 'Ajouter un tableau de bord',
    viewAll: 'Voir tout',
    sensorAlarms: 'Alarmes de capteur',
    assetAlarms: "Alarmes d'actifs",
    survey: 'Enquêtes',
    leak_detection: 'Détection de fuites',
    user_script: 'Scripts utilisateur'
  }
};
