/**
 * Anomaly Template State
 * @author aditya.bhadange@shorelineiot.com
 */

export enum ANOMALY_TEMPLATE_DETAILS_STATUS {
  INIT,
  FETCHING,
  READY,
  ERROR,
  IN_PROGRESS,
  UPDATED
}

/**
 *
 */
export interface anomalyTemplateDetailsState {
  status: ANOMALY_TEMPLATE_DETAILS_STATUS;
  data?: any;
}
