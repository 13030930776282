/**
 * Subscriptions Reducer
 * @author mahesh.kedari@shorelineiot.com
 */
import { AnyAction } from 'redux';
import { SubscriptionsState } from './types/SubscriptionsState';
import { SUBSCRIPTION_STATUS } from './types/SubscriptionsStatus';
import * as ACTIONS from './subscriptions.actionTypes';
/**
 *
 */
const initialState: SubscriptionsState = {
  status: SUBSCRIPTION_STATUS.INIT
};
/**
 * Subscriptions Reducer
 * @param state
 */
export default function subscriptionsReducer(
  state: SubscriptionsState = initialState,
  action: AnyAction
) {
  switch (action.type) {
    case ACTIONS.SUBSCRIPTION_RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
}
