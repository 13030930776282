/**
 * Pie Chart Editor
 * @author mahesh.kedari@shorelineiot.com
 */

import { merge } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { pieChartUnwantedKeysObject, pieFormDefaults } from '../chartEditor.utils';
import { ChartEditorProps } from '../common/ChartEditorProps';
import ResetDefaultConfig from '../common/ResetDefaultConfig';
import DataSpecificPieChartEditor from './settings/DataSpecificPieChartEditor';
import { PieChartCommonEditor } from './settings/PieChartCommonSettings';

const SLPieChartEditor: React.FC<ChartEditorProps> = ({
  handleSubmitForm,
  defaultValues,
  dataConfig,
  widgetType
}: ChartEditorProps) => {
  const newDataSpecificSettings: any = {};

  if (defaultValues?.dataSetting?.pieData || !defaultValues?.dataSettings) {
    // Get only data-specific settings from the existingData object.
    Object.entries(defaultValues)?.forEach(([key, value]: [any, any]) => {
      const unwantedItem = Object.entries(pieChartUnwantedKeysObject)?.find(
        ([formDefaultKey]) => formDefaultKey === key
      );
      if (!unwantedItem) {
        newDataSpecificSettings[key] = value;
      }
    });
  }

  const [pieChartSettings, setPieChartSettings] = useState<any>({
    chart_type: 'pie',
    chartSettings: {
      ...(Object.keys(defaultValues).length > 0 ? defaultValues : pieFormDefaults),
      ...(Object.entries(newDataSpecificSettings)?.length && {
        dataSettings: newDataSpecificSettings
      })
    }
  });

  const { getValues, setValue } = useFormContext();

  const handleUpdate = () => {
    const values = getValues();
    const updatedSettings = merge({}, pieChartSettings);
    // updating local variable here
    const settings = {
      ...updatedSettings,
      chartSettings: {
        ...values
      }
    };
    setPieChartSettings(settings);
    handleSubmitForm(settings);
  };

  useEffect(() => {
    if (Object.entries(newDataSpecificSettings)?.length) {
      setValue('dataSettings', newDataSpecificSettings);
      handleUpdate();
    }
  }, []);

  return (
    <>
      <PieChartCommonEditor
        currentSettings={pieChartSettings.chartSettings}
        handleUpdate={handleUpdate}
      />
      <DataSpecificPieChartEditor
        dataConfig={dataConfig}
        chartSettings={pieChartSettings.chartSettings}
        widgetType={widgetType}
        handleUpdate={handleUpdate}
      />
      <ResetDefaultConfig chartType="pie" onReset={handleUpdate} />
    </>
  );
};

export default SLPieChartEditor;
