import { put, takeEvery, call, ForkEffect } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import * as actions from '../actions/rolesActions';
import * as ACTION_TYPES from '../actions/rolesUpdate.actionTypes';
import {
  GenericObject,
  httpGet,
  removeProgressFor,
  showProgressFor,
  showToast,
  APISERVICES
} from '../../../../../../../framework';

const ROLE_ACTIONS_LIST = 'ROLE_ACTIONS_LIST';

function getList(data: any) {
  return httpGet(`orgs/${data.slug}/role-actions/${data.roleId}`, null, APISERVICES.HOST_API);
}
function* RolesActionsSaga(action: AnyAction) {
  yield put(showProgressFor(ROLE_ACTIONS_LIST));
  try {
    const response: GenericObject = yield call(getList, action.payload);
    yield put(actions.fetchRolesActionsSuccess(response));
    yield put(showToast('Actions for the role fetched successfully!', 'success'));
  } catch (error: any) {
    yield put(actions.fetchRolesActionsFailure());
    yield put(showToast('Actions for the role could not be fetched!', 'error', true));
  } finally {
    yield put(removeProgressFor(ROLE_ACTIONS_LIST));
  }
}
/**
 *
 */
export default function* watchRolesActions(): Generator<ForkEffect<GenericObject>> {
  yield takeEvery(ACTION_TYPES.FETCH_ACTIONS_ROLE, RolesActionsSaga);
}
