export enum GET_AUTO_NARROWBAND_LIST_STATUS {
  INIT,
  PROGRESS,
  COMPLETED,
  ERROR,
  TABLE_INIT,
  TABLE_PROGRESS,
  TABLE_COMPLETED,
  TABLE_ERROR
}
