import { put, takeEvery, call } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import * as actions from './getRuleTemplate.actions';
import * as ACTION_TYPES from './getRuleTemplate.actionTypes';
import { GenericObject, httpGet, SafeSaga, APISERVICES } from '../../../../../../../framework';
import { SagaIterator } from 'redux-saga';

function getList(data: any) {
  return httpGet(
    `orgs/${data.slug}/device_rule_template?dpid=${data.dpid}&device_id=${data.deviceId}`,
    null,
    APISERVICES.DEVICE_API
  );
}
function* getRuleTemplateSaga(action: AnyAction) {
  const response: GenericObject = yield call(getList, action.payload);
  yield put(actions.getRuleTemplateSuccess(response.results));
}

function* errorHandler(error: any) {
  yield put(actions.getRuleTemplateFailure(error));
}
/**
 *
 */
export function* watchGetRuleTemplateSagas(): SagaIterator {
  yield takeEvery(
    ACTION_TYPES.FETCH_RULE_TEMPLATE_PROGRESS,
    SafeSaga(getRuleTemplateSaga, ACTION_TYPES.FETCH_RULE_TEMPLATE_PROGRESS, errorHandler)
  );
}
