import { put, takeEvery, call } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import * as getAutoNarrowbandListActions from './actions/getAutoNarrowbandList.actions';
import * as actionTypes from './actions/getAutoNarrowbandList.actionTypes';
import * as autoConfigActions from './actions/autoConfig.actions';
import {
  GenericObject,
  httpGet,
  removeProgressFor,
  showProgressFor,
  showToast,
  APISERVICES
} from '../../../../../../../framework';
import { SagaIterator } from 'redux-saga';

const GET_AUTO_NARROWBAND_LIST = 'GET_AUTO_NARROWBAND_LIST';
function getAutoNarrowbandList(slug: string, deviceId: string) {
  return httpGet(
    `orgs/${slug}/devices/${deviceId}/device_narrowbands`,
    null,
    APISERVICES.DEVICE_API
  );
}

function getNarrowbandAxis(datapointType: string) {
  if (datapointType.includes('SNS_ACC_X_AXIS')) {
    return 'x_axis';
  }
  if (datapointType.includes('SNS_ACC_Y_AXIS')) {
    return 'y_axis';
  }
  if (datapointType.includes('SNS_ACC_Z_AXIS')) {
    return 'z_axis';
  }
  if (datapointType.includes('SNS_MIC_STRUCT_BORNE')) {
    return 'mic';
  }
  return '';
}

function formatNarrowbands(response: any) {
  const narrowbands: any = [];
  response?.narrowbands?.forEach((narrowbandAxis: any) => {
    const datapointType = narrowbandAxis.raw_datapoint_type;
    const axisNarrowbands = narrowbandAxis.narrowband_config;
    const rawDpid = narrowbandAxis.raw_dpid;
    axisNarrowbands?.forEach((narrowband: any) => {
      // narrowband.description = narrowband.description.replace(/,/g, "");
      if (getNarrowbandAxis(datapointType) === 'x_axis') {
        narrowbands.push({
          ...narrowband,
          dpid: narrowband.dpid,
          raw_dpid: rawDpid,
          axis: 'x'
        });
      } else if (getNarrowbandAxis(datapointType) === 'y_axis') {
        narrowbands.push({
          ...narrowband,
          dpid: narrowband.dpid,
          raw_dpid: rawDpid,
          axis: 'y'
        });
      } else if (getNarrowbandAxis(datapointType) === 'z_axis') {
        narrowbands.push({
          ...narrowband,
          dpid: narrowband.dpid,
          raw_dpid: rawDpid,
          axis: 'z'
        });
      } else if (getNarrowbandAxis(datapointType) === 'mic') {
        narrowbands.push({
          ...narrowband,
          dpid: narrowband.dpid,
          raw_dpid: rawDpid,
          axis: 'mic'
        });
      } else {
        narrowbands.push({
          ...narrowband,
          dpid: narrowband.dpid,
          raw_dpid: rawDpid,
          axis: ''
        });
      }
    });
  });

  return narrowbands;
}

export function* getAutoNarrowbandListSaga(action: AnyAction) {
  yield put(showProgressFor(GET_AUTO_NARROWBAND_LIST));

  try {
    yield put(
      autoConfigActions.setComponentId({
        componentId: action.payload.componentId
      })
    );
    const response: GenericObject = yield call(
      getAutoNarrowbandList,
      action.payload.slug,
      action.payload.deviceId
    );

    // yield put(console.log("NEW DATA: ", formatNarrowbands(response)));

    yield put(
      getAutoNarrowbandListActions.getAutoNarrowbandListSuccess(formatNarrowbands(response), {
        nb_create_update: response?.narrowbands,
        nb_delete: [],
        powertrain: response?.powertrain
      })
    );
  } catch (error: any) {
    yield put(showToast('Error in getting Answers', 'error', true));
    yield put(getAutoNarrowbandListActions.getAutoNarrowbandListFailure(error));
  } finally {
    yield put(removeProgressFor(GET_AUTO_NARROWBAND_LIST));
  }
}

export function* getAutoNarrowbandTableListSaga(action: AnyAction) {
  yield put(showProgressFor(GET_AUTO_NARROWBAND_LIST));

  try {
    yield put(
      autoConfigActions.setComponentId({
        componentId: action.payload.componentId
      })
    );
    const response: GenericObject = yield call(
      getAutoNarrowbandList,
      action.payload.slug,
      action.payload.deviceId
    );

    yield put(
      getAutoNarrowbandListActions.getAutoNarrowbandTableListSuccess(formatNarrowbands(response), {
        nb_create_update: response?.narrowbands,
        nb_delete: [],
        powertrain: response?.powertrain
      })
    );
  } catch (error: any) {
    yield put(showToast('Error in getting Answers', 'error', true));
    yield put(getAutoNarrowbandListActions.getAutoNarrowbandTableListFailure(error));
  } finally {
    yield put(removeProgressFor(GET_AUTO_NARROWBAND_LIST));
  }
}

export function* watchGetAutoNarrowbandListSaga(): SagaIterator {
  yield takeEvery(actionTypes.GET_AUTO_NARROWBAND_LIST, getAutoNarrowbandListSaga);
  yield takeEvery(actionTypes.GET_AUTO_NARROWBAND_TABLE_LIST, getAutoNarrowbandTableListSaga);
}
