/**
 *
 */
export enum AutoconfigNarrowbandStatus {
  INIT,
  IN_PROGRESS,
  UPDATING,
  READY,
  ALL_NB_DONE,
  SUCCESS,
  ERROR
}

export enum NarrowbandStatus {
  INIT,
  IN_PROGRESS,
  UPDATING,
  DONE,
  ERROR
}
