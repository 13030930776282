import { skipToken } from '@reduxjs/toolkit/query';
import React, { useCallback } from 'react';
import { useSlugContext } from '../../../../framework';
import { useFetchUsersGroup, UserGroup } from '../../../org-settings/store';
import { useUserSelf, USER_SELF_STATUS } from '../../../user-settings';
import { Group, useFetchDeviceGroups } from '../../store';
import { generatePayloadForGroupsApiForNewAssetDashboard } from '../utils/newAssetDashboard.utils';
import { usePowertrainUserGroupsFilterSelector } from '../../store/device.slice.hooks';

interface UsePowertrainsUserGroupsReturn {
  powertrainUserGroups: Group[];
  isPowertrainUserGroupsFilterApplied: boolean;
  isFetchingDeviceGroups: boolean;
  deviceGroupsMap: Record<number, Group> | undefined;
  isFetchingDeviceGroupsSuccess: boolean;
  isUsersGroupFetchSuccess: boolean;
  isFetchingPowertrainUserGroups: boolean;
  usersGroup: UserGroup[] | undefined;
  refetchUsersGroup: () => void;
}

/**
 * Custom hook to fetch and filter user groups related to powertrains.
 * This hook returns user groups assigned to the currently logged-in user and applies specific filters if required.
 *
 * @returns Object containing filtered powertrain user groups and various loading states.
 */
const usePowertrainsUserGroups = (): UsePowertrainsUserGroupsReturn => {
  const { slug } = useSlugContext();

  const userSelf = useUserSelf();
  const isPowertrainUserGroupsFilterApplied = usePowertrainUserGroupsFilterSelector();

  const { deviceGroups, isFetchingDeviceGroups, deviceGroupsMap, isFetchingDeviceGroupsSuccess } =
    useFetchDeviceGroups({
      slug,
      payload: generatePayloadForGroupsApiForNewAssetDashboard()
    });

  const { isFetchingUsersGroup, usersGroup, isUsersGroupFetchSuccess, refetchUsersGroup } =
    useFetchUsersGroup(
      userSelf.status == USER_SELF_STATUS.READY && userSelf?.self?.user_uuid
        ? {
            slug,
            payload: {
              user_uuid: userSelf?.self?.user_uuid
            }
          }
        : skipToken
    );

  /**
   * Recursively fetches parent groups for a given group ID using the `fq_dg_path` property.
   * Each parent group is identified by segmenting the `fq_dg_path` string and adding
   * each ancestor group to the result array until no further parent exists.
   *
   * @param groupId - ID of the group to find parents for
   * @param deviceGroupsMap - Map of device groups by their IDs
   * @returns Array of parent groups
   */
  const getParentGroups = useCallback(
    (groupId: number, deviceGroupsMap: Record<number, Group> | undefined): Group[] => {
      if (!deviceGroupsMap) return [];

      const group = deviceGroupsMap[groupId];
      if (!group || !group.fq_dg_path) return [];

      const parentGroupIds = group.fq_dg_path.split('.').slice(0, -1);
      const parents = parentGroupIds
        .map((id) => deviceGroupsMap[Number(id)])
        .filter(Boolean) as Group[]; // Remove undefined entries if any

      return parents.reduce<Group[]>((acc, parentGroup) => {
        return [...acc, parentGroup, ...getParentGroups(parentGroup.id, deviceGroupsMap)];
      }, []);
    },
    []
  );

  const powertrainUserGroups = React.useMemo(() => {
    if (isFetchingDeviceGroupsSuccess) {
      if (!isPowertrainUserGroupsFilterApplied || !usersGroup?.length) return deviceGroups;

      // Extract user group IDs for easy comparison
      const formattedUserGroupsIds = usersGroup.map((group) => group.asset_id);
      const allGroups = new Map<number, Group>(); // Using a Map to ensure unique entries by ID

      // Recursively gather all parent and child groups for each formatted user group ID
      formattedUserGroupsIds.forEach((groupId) => {
        const group = deviceGroupsMap ? deviceGroupsMap[groupId] : null;
        if (!group) return;

        // Add the initial group itself if it's valid
        allGroups.set(group.id, group);

        // Add the current group and its parents to `allGroups`
        getParentGroups(groupId, deviceGroupsMap).forEach((parentGroup) =>
          allGroups.set(parentGroup.id, parentGroup)
        );

        // Corrected filtering logic for child groups
        const filteredChildGroups = deviceGroups.filter((childGroup) =>
          childGroup.fq_dg_path.startsWith(`${group.fq_dg_path}.`)
        );

        // Adding the filtered child groups to `allGroups`
        filteredChildGroups.forEach((childGroup) => allGroups.set(childGroup.id, childGroup));
      });

      return Array.from(allGroups.values());
    }

    return [];
  }, [
    isFetchingDeviceGroupsSuccess,
    isPowertrainUserGroupsFilterApplied,
    usersGroup,
    deviceGroups,
    deviceGroupsMap,
    getParentGroups
  ]);

  // Return the filtered user groups along with related loading and success states.
  return {
    powertrainUserGroups,
    isPowertrainUserGroupsFilterApplied,
    isFetchingDeviceGroups,
    deviceGroupsMap,
    isFetchingDeviceGroupsSuccess,
    isUsersGroupFetchSuccess,
    usersGroup,
    isFetchingPowertrainUserGroups: isFetchingUsersGroup || isFetchingDeviceGroups,
    refetchUsersGroup
  };
};

export default usePowertrainsUserGroups;
