/**
 * Widget details Saga
 */

import { put, takeEvery, call } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import * as WIDGET_DETAILS_ACTION_TYPES from './widgetDetails.actionsTypes';
import * as WIDGET_DETAILS_ACTIONS from './widgetDetails.action';
import { GenericObject, httpGet, removeProgressFor, APISERVICES } from '../../../../framework';
import { SagaIterator } from 'redux-saga';

function fetchWidgetDetailsFromDatabase(data: any) {
  return httpGet(
    `orgs/${data.slug}/dashboard_widgets/${data.widget_uuid}`,
    null,
    APISERVICES.HOST_API
  );
}

export function* getWidgetDetailsData(action: AnyAction) {
  try {
    const response: GenericObject = yield call(fetchWidgetDetailsFromDatabase, action.payload);
    yield put(WIDGET_DETAILS_ACTIONS.handleGetWidgetDetailsSuccess(response));
  } catch (error: any) {
    yield put(WIDGET_DETAILS_ACTIONS.handleGetWidgetDetailsFailure(error));
  } finally {
    yield put(removeProgressFor('GET_WIDGET_DETAILS'));
  }
}

export function* watchWidgetDetailsSaga(): SagaIterator {
  yield takeEvery(WIDGET_DETAILS_ACTION_TYPES.FETCH_WIDGET_DETAILS_PROGRESS, getWidgetDetailsData);
}
