/**
 * Create organization reducer
 * @author ashwini.gawade@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTIONS from './actions/orgCreate.actionType';
import { CREATE_ORG_STATUS, CreateOrgState } from '../types/createOrgState';
/**
 *
 */
export const initialState: CreateOrgState = {
  status: CREATE_ORG_STATUS.INIT
};
/**
 *
 * @param state
 * @param action
 */
export default function orgCreateReducer(
  state: CreateOrgState = initialState,
  action: AnyAction
): CreateOrgState {
  switch (action.type) {
    case ACTIONS.ORG_CREATE:
      return {
        ...state,
        status: CREATE_ORG_STATUS.CREATING
      };
    case ACTIONS.ORG_CREATE_SUCCESS:
      return {
        ...state,
        status: CREATE_ORG_STATUS.SUCCESS
      };
    case ACTIONS.ORG_CREATE_FAILURE:
      return {
        ...state,
        status: CREATE_ORG_STATUS.ERROR
      };
    case ACTIONS.RESET_ORG_CREATE:
      return {
        ...initialState
      };
    default:
      return state;
  }
}
