/**
 * Pie Chart View
 * @author mahesh.kedari@shorelineiot.com
 */
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highcharts3d from 'highcharts/highcharts-3d';
import merge from 'lodash/merge';
import React, { useEffect, useState } from 'react';
import { generatePath, useNavigate } from 'react-router';
import { useSlugContext } from '../../../../lib/slugContext';
import { ChartViewProps } from '../../common/ChartViewProps';
import { plantHealthChartConfig as defaultPlantHealthSettings } from './defaultPlantHealthSettings';

highcharts3d(Highcharts);

/**
 *
 * @param param0
 * @returns
 */
const PlantHealthViewer: React.FC<ChartViewProps> = React.forwardRef(
  ({ chartSettings, resultSet }: ChartViewProps, ref: any) => {
    const navigate = useNavigate();
    const { slug } = useSlugContext();
    const [pieChartSettings, setPieChartSettings] = useState(
      merge(defaultPlantHealthSettings(), chartSettings)
    );
    useEffect(() => {
      const data = [];
      if (resultSet?.ALERT_FATAL) {
        data.push({
          name: chartSettings?.critical?.label || 'Critical',
          color: chartSettings?.critical?.color?.hex || '#dc0000',
          y: resultSet?.ALERT_FATAL ?? 0,
          custom: {
            url: generatePath(`/${slug}/app/alarms?severity=ALERT_FATAL`)
          }
        });
      }
      if (resultSet?.ALERT_ERROR) {
        data.push({
          name: chartSettings?.high?.label || 'High',
          color: chartSettings?.high?.color?.hex || '#fd8c00',
          y: resultSet?.ALERT_ERROR ?? 0,
          custom: {
            url: generatePath(`/${slug}/app/alarms?severity=ALERT_ERROR`)
          }
        });
      }

      if (resultSet?.ALERT_NONE) {
        data.push({
          name: chartSettings?.healthy?.label || 'No Alarms',
          color: chartSettings?.healthy?.color?.hex || '#109505',
          y: resultSet?.ALERT_NONE ?? 0,
          custom: {
            url: generatePath(`/${slug}/app/alarms?severity=ALERT_NONE`)
          }
        });
      }
      const series: Array<Highcharts.SeriesPieOptions> = [
        {
          type: 'pie',
          data
        }
      ];

      const revisedSettings = merge(pieChartSettings, {
        chart: {
          options3d: {
            enabled: chartSettings.enable3d,
            alpha: 45
          }
        },
        title: {
          text: chartSettings?.title,
          verticalAlign: 'middle',
          align: 'center',
          y: 0,
          style: {
            fontSize: '15px',
            fontWeight: 'bold'
          }
        },
        plotOptions: {
          pie: {
            innerSize: chartSettings.innerSize
              ? `${chartSettings.innerSize}%`
              : pieChartSettings?.plotOptions?.pie?.innerSize,
            depth: 35,
            showInLegend: chartSettings.legands,
            dataLabels: {
              format: `{point.name}<br />{point.${
                chartSettings.dataLabelType === 'ABSOLUTE' ? 'y}</br>' : 'percentage:.1f} %</br>'
              }`
            }
          },
          series: {
            cursor: 'pointer',
            point: {
              events: {
                click: (e: any) => {
                  if (e.point.url !== '' && e.point.url !== undefined) {
                    navigate(e.point.url);
                  }
                }
              }
            }
          }
        },
        series
      });
      setPieChartSettings({ ...revisedSettings });
    }, [resultSet, chartSettings]);
    return <HighchartsReact highcharts={Highcharts} options={pieChartSettings} ref={ref} />;
  }
);

export default PlantHealthViewer;
