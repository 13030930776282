import { ButtonClasses } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { Colors } from '../../../constants';

/**
 *
 */
export const ButtonStyle: Partial<OverridesStyleRules<keyof ButtonClasses, 'MuiButton'>> = {
  outlined: {
    borderRadius: '3.25rem'
  },
  containedSecondary: {
    color: Colors.primaryText,
    backgroundColor: Colors.secondaryText,
    '&:hover': {
      backgroundColor: Colors.secondaryText
    }
  },
  containedPrimary: {
    color: Colors.secondaryText,
    backgroundColor: Colors.primaryVariant,
    '&:hover': {
      backgroundColor: Colors.primaryVariant
    }
  },
  containedSuccess: {
    color: Colors.primaryText,
    backgroundColor: Colors.secondaryText,
    '&:hover': {
      backgroundColor: Colors.secondary
    }
  },
  containedInfo: {
    color: Colors.secondaryText,
    backgroundColor: Colors.primaryVariant,
    borderColor: Colors.borderGrey,
    border: '1px solid',
    '&:hover': {
      backgroundColor: Colors.primaryVariant
    }
  },
  containedSizeLarge: {
    fontSize: '0.857rem',
    padding: '0px',
    '@media (min-width : 600px)': {
      fontSize: '1.5rem'
    },
    '@media (min-width : 960px)': {
      height: '2.875rem'
    },
    '@media (min-width : 1280px)': {
      height: '3.25rem'
    },
    '@media (min-width : 1920px)': {
      height: '4.5rem'
    }
  },
  containedSizeSmall: {
    height: '34px',
    fontSize: '1rem'
  },
  contained: {
    borderRadius: '2.25rem'
  }
};
