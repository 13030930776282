import { AnyAction } from 'redux';
import * as ACTION_TYPE from './resetDatapoint.actionTypes';
import { resetDatapointState, RESET_DATAPOINT_STATUS } from './resetDatapoint.state';

const initialState: resetDatapointState = {
  status: RESET_DATAPOINT_STATUS.INIT
};

function resetDatapointStateProgress(state: resetDatapointState): resetDatapointState {
  return {
    ...state,
    status: RESET_DATAPOINT_STATUS.PROGRESS
  };
}

function resetDatapointStateSuccess(
  state: resetDatapointState,
  action: AnyAction
): resetDatapointState {
  return {
    ...state,
    status: RESET_DATAPOINT_STATUS.SUCCESS,
    resetDetails: action.data
  };
}

function resetDatapointStateFailure(state: resetDatapointState): resetDatapointState {
  return {
    ...state,
    status: RESET_DATAPOINT_STATUS.ERROR
  };
}

function resetRuleTemplateUpdate() {
  return {
    ...initialState
  };
}
/**
 *
 * @param state
 * @param action
 */
export default function resetDatapointStateReducer(
  state: resetDatapointState = initialState,
  action: AnyAction
): resetDatapointState {
  switch (action.type) {
    case ACTION_TYPE.RESET_DATAPOINT_PROGRESS:
      return resetDatapointStateProgress(state);
    case ACTION_TYPE.RESET_DATAPOINT_SUCCESS:
      return resetDatapointStateSuccess(state, action);
    case ACTION_TYPE.RESET_DATAPOINT_FAILURE:
      return resetDatapointStateFailure(state);
    case ACTION_TYPE.RESET_DATAPOINT_RESET_STATE:
      return resetRuleTemplateUpdate();
    default:
      return state;
  }
}
