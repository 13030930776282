/**
 * @author ashwini.gawade@shorelineiot.com
 */

import { combineReducers } from 'redux';
import { orgList, subOrgList, orgSelector, orgCreate, subOrgCreate } from './store';

const organisation = combineReducers({
  orgCreate,
  orgList,
  subOrgList,
  subOrgCreate,
  orgSelector
});

export default organisation;
