/**
 * SLSlider Component
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { ReactElement, SyntheticEvent } from 'react';
import { FormControl, FormHelperText, Slider, SliderProps } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useFormContext, Controller } from 'react-hook-form';
import SLInputLabel, { SLInputLabelProps } from '../SLInputLabel';
import { toSnakeCase } from '../../../../i18n/translation.helper';
import TranslateText from '../../../../i18n/TranslateText';

export interface SLSliderProps extends SliderProps {
  label: string;
  rules?: Object | Function;
  onSliderChange?: (value?: any, setter?: Function, getter?: Function) => void;
  labelProps?: SLInputLabelProps;
}

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '15px',
    display: 'flex'
  },
  slider: {
    marginTop: '20px'
  }
}));
export default function SLSlider({
  name = 'slider',
  label,
  rules,
  onSliderChange,
  labelProps,
  defaultValue = 0,
  valueLabelDisplay = 'auto',
  ...otherProps
}: SLSliderProps): ReactElement {
  const classes = useStyles();
  const {
    formState: { errors },
    watch,
    setValue,
    getValues,
    control
  } = useFormContext();

  const handleChange = (
    event: Event | SyntheticEvent<Element, Event>,
    value: number | number[]
  ) => {
    if (!Number.isNaN(value)) {
      setValue(name, value);
      if (onSliderChange) {
        onSliderChange(value, setValue, getValues);
      }
    }
  };

  return (
    <FormControl fullWidth error={Boolean(errors[name])} className={classes.root}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={typeof rules === 'function' ? rules(watch) : rules}
        render={({ field: { onChange }, fieldState: { error } }) => (
          <>
            <SLInputLabel bold shrink {...labelProps}>
              {label ? TranslateText(toSnakeCase(label), label) : label}
            </SLInputLabel>
            <Slider
              name={name}
              value={defaultValue}
              className={classes.slider}
              valueLabelDisplay={valueLabelDisplay}
              onChange={onChange}
              onChangeCommitted={handleChange}
              {...otherProps}
            />
            <FormHelperText error>{error && error?.message}</FormHelperText>
          </>
        )}
      />
    </FormControl>
  );
}
