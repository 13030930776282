import { AnyAction } from 'redux';
import * as ACTION_TYPES from './forgotPassword.actionTypes';

/**
 *
 */
export const forgotPasswordSubmit = (data: any): AnyAction => {
  return {
    type: ACTION_TYPES.FORGOT_PASSWORD_SUBMIT_PROGRESS,
    payload: data
  };
};
/**
 *
 */
export const forgotPasswordSubmitSuccess = (data: any): AnyAction => {
  return {
    type: ACTION_TYPES.FORGOT_PASSWORD_SUBMIT_SUCCESS,
    payload: data
  };
};
/**
 *
 */
export const forgotPasswordSubmitFailure = (error: any): AnyAction => {
  return {
    type: ACTION_TYPES.FORGOT_PASSWORD_SUBMIT_FAILURE,
    payload: error
  };
};
/**
 *
 */
export const forgotPasswordSubmitReset = (): AnyAction => {
  return {
    type: ACTION_TYPES.FORGOT_PASSWORD_SUBMIT_RESET
  };
};
