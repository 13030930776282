/**
 * Sensor Module Details Actions
 * @author mahesh.kedari@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTION_TYPES from './updateProfile.actionTypes';
/**
 *
 */
export function setType(typeId: string): AnyAction {
  return {
    type: ACTION_TYPES.SET_TYPE,
    payload: typeId
  };
}
/**
 *
 * @param profileId
 */
export function setProfileId(profileId: string): AnyAction {
  return {
    type: ACTION_TYPES.SET_PROFILE_ID,
    payload: profileId
  };
}
/**
 *
 * @param data
 */
export function getSchema(data: any): AnyAction {
  return {
    type: ACTION_TYPES.FETCH_SCHEMA,
    payload: data
  };
}
/**
 *
 * @param data data to be returned on successful fetch API call
 */
export function handleSchemaSuccess(data: any): AnyAction {
  return {
    type: ACTION_TYPES.FETCH_SCHEMA_SUCCESS,
    payload: data
  };
}
/**
 *
 */
export function handleSchemaFailure(error: any): AnyAction {
  return {
    type: ACTION_TYPES.FETCH_SCHEMA_FAILURE,
    payload: error
  };
}
/**
 *
 * @param data
 */
export function getDetails(data: any): AnyAction {
  return {
    type: ACTION_TYPES.FETCH,
    payload: data
  };
}
/**
 *
 * @param data data to be returned on successful fetch API call
 */
export function handleDetailsSuccess(data: any): AnyAction {
  return {
    type: ACTION_TYPES.FETCH_SUCCESS,
    payload: data
  };
}
/**
 *
 */
export function handleDetailsFailure(error: any): AnyAction {
  return {
    type: ACTION_TYPES.FETCH_FAILURE,
    payload: error
  };
}
/**
 *
 */
export function addNewSensorModule(data: any): AnyAction {
  return {
    type: ACTION_TYPES.ADD_NEW_SENSOR_MODULE,
    payload: data
  };
}
/**
 *
 * @param data
 */
export function fetchSensorTemplate(data: any): AnyAction {
  return {
    type: ACTION_TYPES.FETCH_SENSOR_TEMPLATE,
    payload: data
  };
}
/**
 *
 * @param data
 * @param index
 */
export function fetchSensorTemplateSuccess(schema: any, index: number): AnyAction {
  return {
    type: ACTION_TYPES.FETCH_SENSOR_TEMPLATE_SUCCESS,
    payload: {
      schema,
      index
    }
  };
}
/**
 *
 * @param error
 */
export function fetchSensorTemplateFailure(error: any): AnyAction {
  return {
    type: ACTION_TYPES.FETCH_SENSOR_MODULES_FAILURE,
    payload: error
  };
}
/**
 *
 * @param data
 */
export function addExistingSensorModule(data: { sensorType: string; slug: string }): AnyAction {
  return {
    type: ACTION_TYPES.ADD_EXISTING_SENSOR_MODULE,
    payload: data
  };
}
/**
 *
 * @param data
 */
export function addSensorModuleSuccess(data: any): AnyAction {
  return {
    type: ACTION_TYPES.ADD_SENSOR_MODULE_SUCCESS,
    payload: data
  };
}
/**
 *
 * @param error
 */
export function addSensorModuleFailure(error: any): AnyAction {
  return {
    type: ACTION_TYPES.ADD_SENSOR_MODULE_FAILURE,
    payload: error
  };
}
/**
 *
 * @param index
 */
export function removeSensorModule(index: number): AnyAction {
  return {
    type: ACTION_TYPES.REMOVE_SENSOR_MODULE,
    payload: index
  };
}
/**
 *
 */
export function resetSensorModules(): AnyAction {
  return {
    type: ACTION_TYPES.SENSOR_MODULE_RESET
  };
}
/**
 *
 * @param data
 */
export function updateProfile(data: any): AnyAction {
  return {
    type: ACTION_TYPES.UPDATE,
    payload: data
  };
}
/**
 *
 * @param data data to be returned on successful fetch API call
 */
export function handleUpdateSuccess(data: any): AnyAction {
  return {
    type: ACTION_TYPES.UPDATE_SUCCESS,
    payload: data
  };
}
/**
 *
 */
export function handleUpdateFailure(error: any): AnyAction {
  return {
    type: ACTION_TYPES.UPDATE_FAILURE,
    payload: error
  };
}
/**
 *
 * @param data
 */
export function fetchSensorModules(data: any): AnyAction {
  return {
    type: ACTION_TYPES.FETCH_SENSOR_MODULES,
    payload: data
  };
}
/**
 *
 * @param data
 */
export function handleSensorModulesSuccess(data: any): AnyAction {
  return {
    type: ACTION_TYPES.FETCH_SENSOR_MODULES_SUCCESS,
    payload: data
  };
}
/**
 *
 * @param error
 */
export function handleSensorModulesFailure(error: any): AnyAction {
  return {
    type: ACTION_TYPES.FETCH_SENSOR_MODULES_FAILURE,
    payload: error
  };
}

export function submitSensorConfig(data: any): AnyAction {
  return {
    type: ACTION_TYPES.SUBMIT_SENSOR_CONFIG,
    payload: data
  };
}

export function submitSensorMetadata(data: any): AnyAction {
  return {
    type: ACTION_TYPES.SUBMIT_SENSOR_METADATA,
    payload: data
  };
}
/**
 *
 */
export const resetState = (): AnyAction => {
  return {
    type: ACTION_TYPES.RESET
  };
};
