import React from 'react';

import { ACCESS_PERMIT, SidebarMenu, usePermissions, useSlugContext } from '../../framework';
import { ActivityLogsIcon } from '../../framework/icons';
import { ACTIVITY_LOGS_PERMISSIONS } from './activityLogs.permissions';
import { sidebarId } from '../../framework/components/sidebar/sidebar.utils';

export default function ActivityLogsMenu() {
  const { slug } = useSlugContext();
  const hasViewPermission: ACCESS_PERMIT = usePermissions(ACTIVITY_LOGS_PERMISSIONS?.VIEW);
  if (hasViewPermission === ACCESS_PERMIT.ALLOW) {
    return (
      <SidebarMenu
        testID={sidebarId.menuItems('activity_logs')}
        route={`/${slug}/app/activity-logs`}
        textKey="activity_logs"
        defaultText="Activity Logs"
        icon={<ActivityLogsIcon className="MuiSvgIcon-root" />}
      />
    );
  }
  return null;
}
