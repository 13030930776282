/**
 * @author mahesh.kedari@shorelineiot.com
 */

import { combineReducers } from 'redux';
import users from './user-management/users.reducer';
import subscriptions from './subscriptions/subscriptions.reducer';
import payments from './payments/payments.reducer';
import { org, personalizeSettings, updatePersonalizeSettings } from './store';
import { deletePersonalizeSettings } from './store/personalize-settings/deletePersonalizeSettings';
import featureConfigurations from './store/feature-configurations/featureConfigurations.slice';
/**
 *
 */
const organisation = combineReducers({
  org,
  users,
  subscriptions,
  personalizeSettings,
  updatePersonalizeSettings,
  payments,
  deletePersonalizeSettings,
  featureConfigurations
});
/**
 *
 */
export default organisation;
