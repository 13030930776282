/**
 * Sensor Settings Reducer
 * @author mahesh.kedari@shorelineiot.com
 */
import { AnyAction } from 'redux';
import { SensorSettingsState, SensorSettingsStatus } from './types/sensorSettings.state';
import * as ACTIONS from './actions/sensorSettings.actionTypes';
/**
 *
 */
const initialState: SensorSettingsState = {
  status: SensorSettingsStatus.INIT,
  datapointUUID: undefined,
  moduleSchema: null,
  widgetSchema: null
};
/**
 *
 * @param state
 */
function onSensorSettingsFetch(state: SensorSettingsState): SensorSettingsState {
  return {
    ...state,
    status: SensorSettingsStatus.FETCHING
  };
}
/**
 *
 * @param state
 * @param moduleSchema
 */
function onSensorSettingsSuccess(
  state: SensorSettingsState,
  moduleSchema: any
): SensorSettingsState {
  return {
    ...state,
    status: SensorSettingsStatus.READY,
    moduleSchema
  };
}
/**
 *
 * @param state
 * @param action
 */
function onSensorSettingsFailure(state: SensorSettingsState) {
  return {
    ...state,
    status: SensorSettingsStatus.ERROR
  };
}
/**
 *
 */
function onSensorSettingsReset(): SensorSettingsState {
  return {
    ...initialState
  };
}
/**
 *
 * @param state
 */
function onUpdateSensorConfig(state: SensorSettingsState): SensorSettingsState {
  return {
    ...state
  };
}
/**
 *
 * @param state
 * @param data
 */
function onUpdateSensorConfigSuccess(state: SensorSettingsState): SensorSettingsState {
  return {
    ...state,
    status: SensorSettingsStatus.UPDATED
  };
}

/**
 *
 * @param state
 * @param action
 */

function onUpdateSensorConfigFailure(state: SensorSettingsState): SensorSettingsState {
  return {
    ...state
  };
}
/**
 *
 * @param state
 * @param action
 */
export default function SensorSettingsReducer(
  state: SensorSettingsState = initialState,
  action: AnyAction
): SensorSettingsState {
  switch (action.type) {
    case ACTIONS.SENSOR_SETTINGS_FETCH:
      return onSensorSettingsFetch(state);
    case ACTIONS.SENSOR_SETTINGS_SUCCESS:
      return onSensorSettingsSuccess(state, action.payload);
    case ACTIONS.SENSOR_SETTINGS_FAILURE:
      return onSensorSettingsFailure(state);
    case ACTIONS.UPDATE_SENSOR_CONFIG:
      return onUpdateSensorConfig(state);
    case ACTIONS.UPDATE_SENSOR_CONFIG_SUCCESS:
      return onUpdateSensorConfigSuccess(state);
    case ACTIONS.UPDATE_SENSOR_CONFIG_FAILURE:
      return onUpdateSensorConfigFailure(state);
    case ACTIONS.SENSOR_SETTINGS_RESET:
      return onSensorSettingsReset();
    default:
      return state;
  }
}
