/**
 * Update Dashboard  Actions
 * @author meghneel.adke@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTION_TYPES from './updateDashboard.actionsTypes';
/**
 *
 */
export function updateDashboard(data: any): AnyAction {
  return {
    type: ACTION_TYPES.UPDATE_DASHBOARD_PROGRESS,
    payload: data
  };
}
/**
 *
 * @param data data to be returned on successful fetch API call
 */
export function handleUpdateDashboardSuccess(data: any): AnyAction {
  return {
    type: ACTION_TYPES.UPDATE_DASHBOARD_SUCCESS,
    payload: data
  };
}
/**
 *
 */
export function handleUpdateDashboardFailure(error: any): AnyAction {
  return {
    type: ACTION_TYPES.UPDATE_DASHBOARD_FAILURE,
    payload: error
  };
}

export const resetState = (): AnyAction => {
  return {
    type: ACTION_TYPES.UPDATE_DASHBOARD_RESET_STATE,
    payload: false
  };
};
