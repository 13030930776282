export enum DEVICE_PROVISION_STATUS {
  NEVER_PROVISIONED = 'NEVER-PROVISIONED',
  DE_PROVISIONED = 'DE-PROVISIONED',
  PROVISIONED = 'PROVISIONED',
  PROV_FAILED = 'PROV-FAILED',
  PROV_SERIAL_NUMBER_ATTACHED = 'PROV-SERIAL-NUMBER-ATTACHED',
  PROV_IN_PROGRESS = 'PROV-IN-PROGRESS',
  PROV_INITIATED = 'PROV-INITIATED',
  PROV_CERTS_GENERATED = 'PROV-CERTS-GENERATED'
}

export type DeviceProvisionStatus = `${DEVICE_PROVISION_STATUS}`;
