import { AnyAction } from 'redux';
import { takeEvery, call, put } from 'redux-saga/effects';
import { datapointActions, DATAPOINT_ACTIONS } from '../actions';
import { listActions } from '../../../../../device-list';
import {
  httpGet,
  removeProgressFor,
  showProgressFor,
  showToast,
  APISERVICES
} from '../../../../../../../framework';
import { SagaIterator } from 'redux-saga';
import { Datapoint } from '../types';
import { handleUpdatedDeviceListWithDatapoints } from '../../../../../../common/websocket/device/deviceMessageHandler';
import { GetOnlyDataPointsSagaActionPayload } from '../actions/datapoints.actions';

function getDatapoints(data: any) {
  if (data.vibration_analysis) {
    return httpGet(
      `orgs/${data.slug}/devices/${data.deviceId}/datapoints?vibration_analysis=true`,
      null,
      APISERVICES.DEVICE_API
    );
  }
  return httpGet(
    `orgs/${data.slug}/devices/${data.deviceId}/datapoints?vibration_analysis=false`,
    null,
    APISERVICES.DEVICE_API
  );
}

function* getOnlyDataPointsSaga(action: AnyAction) {
  yield put(showProgressFor('GET_DATAPOINTS'));
  try {
    const { deviceId, slug, dispatch } = action.payload as GetOnlyDataPointsSagaActionPayload;
    const datapointsResponse: Datapoint[] = yield call(getDatapoints, action.payload);
    handleUpdatedDeviceListWithDatapoints(slug, deviceId, datapointsResponse, dispatch);

    yield put(
      listActions.addDeviceListWithDatapoints({
        datapoints: datapointsResponse,
        deviceId
      })
    );
    yield put(datapointActions.datapointsSuccess(datapointsResponse));
  } catch (error: any) {
    yield put(showToast('Failed to fetch datapoints', 'error', true));
    yield put(datapointActions.datapointsFailure());
  } finally {
    yield put(removeProgressFor('GET_DATAPOINTS'));
  }
}
/**
 *
 */
export function* watchOnlyDatapointsSagas(): SagaIterator {
  yield takeEvery(DATAPOINT_ACTIONS.FETCH_ONLY_DATAPOINTS, getOnlyDataPointsSaga);
}
