import { skipToken } from '@reduxjs/toolkit/query';
import {
  useBlockUserMutation,
  useChangeUserNotificationMutation,
  useChangeUserRoleMutation,
  useFetchUsersQuery,
  useInviteUserMutation,
  useRemoveUserMutation,
  useResendUserInviteMutation,
  useUnblockUserMutation,
  useLazyFetchUsersQuery,
  useFetchUsersGroupsQuery,
  useChangeUserGroupMutation,
  useLazyFetchUsersGroupsQuery
} from './user.api';
import { FetchUserGroupsArgs, FetchUsersArgs } from './user.types';

export const useFetchUsers = (args: FetchUsersArgs | typeof skipToken) => {
  const {
    data,
    isFetching: isFetchingUsers,
    isError: isUsersFetchError,
    error: usersFetchError,
    isSuccess: isUsersFetchSuccess,
    refetch: refetchUsers
  } = useFetchUsersQuery(args);

  const users = data || [];

  return {
    users,
    isFetchingUsers,
    isUsersFetchError,
    usersFetchError,
    isUsersFetchSuccess,
    refetchUsers
  };
};

export const useLazyFetchUsers = () => {
  const [
    fetchLazyUsers,
    {
      isFetching: isLazyFetchingUsers,
      isError: isLazyUsersFetchError,
      error: lazyUsersFetchError,
      isSuccess: isLazyUsersFetchSuccess,
      data: lazyData
    }
  ] = useLazyFetchUsersQuery();

  const users = lazyData || [];

  return {
    fetchLazyUsers,
    users,
    isLazyFetchingUsers,
    isLazyUsersFetchError,
    lazyUsersFetchError,
    isLazyUsersFetchSuccess
  };
};
export const useRemoveUser = () => {
  const [
    removeUser,
    { isLoading: isRemovingUser, isError: isRemoveUserError, error: removeUserError }
  ] = useRemoveUserMutation();

  return { removeUser, isRemovingUser, isRemoveUserError, removeUserError };
};

export const useInviteUser = () => {
  const [
    inviteUser,
    {
      isLoading: isInvitingUser,
      isError: isInviteUserError,
      error: inviteUserError,
      isSuccess: isInviteUserSuccess
    }
  ] = useInviteUserMutation();

  return { inviteUser, isInvitingUser, isInviteUserError, inviteUserError, isInviteUserSuccess };
};

export const useChangeUserRole = () => {
  const [
    changeUserRole,
    { isLoading: isChangingUserRole, isError: isChangeUserRoleError, error: changeUserRoleError }
  ] = useChangeUserRoleMutation();

  return { changeUserRole, isChangingUserRole, isChangeUserRoleError, changeUserRoleError };
};

export const useChangeUserNotification = () => {
  const [
    changeUserNotification,
    {
      isLoading: isChangingNotification,
      isError: isChangeNotificationError,
      error: changeNotificationError
    }
  ] = useChangeUserNotificationMutation();

  return {
    changeUserNotification,
    isChangingNotification,
    isChangeNotificationError,
    changeNotificationError
  };
};

export const useBlockUser = () => {
  const [
    blockUser,
    { isLoading: isblockingUser, isError: isblockUserError, error: blockUserError }
  ] = useBlockUserMutation();

  return {
    blockUser,
    isblockingUser,
    isblockUserError,
    blockUserError
  };
};

export const useUnblockUser = () => {
  const [
    unblockUser,
    { isLoading: isUnblockingUser, isError: isUnblockUserError, error: unblockUserError }
  ] = useUnblockUserMutation();

  return {
    unblockUser,
    isUnblockingUser,
    isUnblockUserError,
    unblockUserError
  };
};

export const useResendUserInvite = () => {
  const [
    resendUserInvite,
    {
      isLoading: isResendingUserInvite,
      isError: isResendUserInviteError,
      error: resendUserInviteError
    }
  ] = useResendUserInviteMutation();

  return {
    resendUserInvite,
    isResendingUserInvite,
    isResendUserInviteError,
    resendUserInviteError
  };
};
export const useFetchUsersGroup = (args: FetchUserGroupsArgs | typeof skipToken) => {
  const {
    data,
    isFetching: isFetchingUsersGroup,
    isError: isUsersGroupFetchError,
    error: usersGroupFetchError,
    isSuccess: isUsersGroupFetchSuccess,
    refetch: refetchUsersGroup
  } = useFetchUsersGroupsQuery(args);

  const usersGroup = data || [];

  return {
    usersGroup,
    isFetchingUsersGroup,
    isUsersGroupFetchError,
    usersGroupFetchError,
    isUsersGroupFetchSuccess,
    refetchUsersGroup
  };
};

export const useChangeUserGroup = () => {
  const [
    changeUserGroup,
    {
      isLoading: isChangingUserGroup,
      isError: isChangingUserGroupError,
      error: ChangingUserGroupError,
      isSuccess: isChangingUserGroupSuccess
    }
  ] = useChangeUserGroupMutation();

  return {
    changeUserGroup,
    isChangingUserGroup,
    isChangingUserGroupError,
    ChangingUserGroupError,
    isChangingUserGroupSuccess
  };
};
export const useLazyFetchUsersGroup = () => {
  const [
    fetchUsersGroup,
    {
      data,
      isFetching: isLazyFetchingUsersGroup,
      isError: isLazyUsersGroupFetchError,
      error: lazyUsersGroupFetchError,
      isSuccess: isLazyUsersGroupFetchSuccess
    }
  ] = useLazyFetchUsersGroupsQuery();

  const usersGroup = data || [];

  return {
    usersGroup,
    fetchUsersGroup,
    isLazyFetchingUsersGroup,
    isLazyUsersGroupFetchError,
    lazyUsersGroupFetchError,
    isLazyUsersGroupFetchSuccess
  };
};
