/**
 * Payments Saga
 * @author mahesh.kedari@shorelineiot.com
 */
import { put, takeEvery } from 'redux-saga/effects';
import * as ACTIONS from './payments.actionTypes';
import { showToast } from '../../../framework';
import { SagaIterator } from 'redux-saga';
/**
 *
 */
function* paymentsSaga() {
  yield put(showToast('Error while resetting payments state', 'error', true));
}
/**
 *
 */
export default function* watchPaymentsSaga(): SagaIterator {
  yield takeEvery(ACTIONS.PAYMENTS_RESET, paymentsSaga);
}
