/**
 * Customization Action
 * @author ashwini.gawade@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTIONS from './updatePersonalizeSettings.actionTypes';
/**
 *
 */
export const updatePersonalizationInfoProgress = (data: any): AnyAction => {
  return {
    type: ACTIONS.UPDATE_THEMES,
    payload: data
  };
};
/**
 *
 * @param data
 */
export const updatePersonalizationInfoSuccess = (data: any): AnyAction => {
  // returns the action type
  return {
    type: ACTIONS.UPDATE_THEMES_SUCCESS,
    data
  };
};
/**
 *
 * @param error
 */
export const updatePersonalizationInfoFailure = (error: any): AnyAction => {
  // returns the action type
  return {
    type: ACTIONS.UPDATE_THEMES_FAILURE,
    error
  };
};
/**
 *
 * @param data
 */

export const updatePersonalizationInfoReset = (): AnyAction => {
  return {
    type: ACTIONS.RESET_THEME
  };
};
