/**
 * Component Complier Saga
 * @author mahesh.kedari@shorelineiot.com
 */

import { all } from 'redux-saga/effects';
import {
  watchFetchComponentDetailsSaga,
  watchUpdateComponentDetailsSaga,
  watchExportComponentDetailsSaga
} from './component-details';
import { watchFetchComponentListSaga } from './component-list';
import {
  watchFetchComponentAttachListSaga,
  watchUpdateComponentAttachListSaga
} from './component-apply-to-devices';
/**
 *
 */
export function* componentCompilerSaga() {
  yield all([
    watchUpdateComponentDetailsSaga(),
    watchFetchComponentDetailsSaga(),
    watchFetchComponentListSaga(),
    watchFetchComponentAttachListSaga(),
    watchUpdateComponentAttachListSaga(),
    watchExportComponentDetailsSaga()
  ]);
}
