/**
 * What's New Icon component
 * @author prerna.more@shorelineiot.com
 */
import React, { useEffect, useState } from 'react';
import { VITE_BASE_PATH } from '../../../services/envConstants';
import { useStyles } from './release.styles';
import { WhatsNewIcon } from '../../../icons';

function WhatsNewIconComponent() {
  const classes = useStyles();
  const RELEASE_VERSION = 'release_version';
  const releasePageLink = `${VITE_BASE_PATH}orgservice/api/v1/public/release-notes/latest`;
  // For front-end release page
  //const releasePageLink =`${VITE_BASE_PATH}${slug}/public/release-notes`;
  const [isFlashing, setIsFlashing] = useState(false);

  const onClick = () => {
    if (import.meta.env.VITE_WEBSITE_VERSION) {
      localStorage.setItem(RELEASE_VERSION, import.meta.env.VITE_WEBSITE_VERSION);
      setIsFlashing(false);
    }
    window.open(releasePageLink, '_blank');
  };

  useEffect(() => {
    const storedReleaseVersion = localStorage.getItem(RELEASE_VERSION);
    if (storedReleaseVersion !== import.meta.env.VITE_WEBSITE_VERSION) {
      setIsFlashing(true);
    }
  }, []);
  return (
    <div className={isFlashing ? `${classes.newIcon} ${classes.flashing}` : classes.newIcon}>
      <WhatsNewIcon onClick={onClick} />
    </div>
  );
}

export default WhatsNewIconComponent;
