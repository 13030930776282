export enum CreateNarrowbandRuleStatus {
  INIT,
  IN_PROGRESS,
  READY,
  ERROR
}
/**
 *
 */
export interface CreateNarrowbandRuleState {
  status: CreateNarrowbandRuleStatus;
  response: any;
}
