/**
 * SL Select Component
 * @author swapnil.kshirsagar@shorelineiot.com
 */
import React, { ReactElement, ReactNode, useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useFormContext, Controller } from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import { Checkbox, FormHelperText } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useStyles } from '../common/SLForms.styles';
import SLInputLabel, { SLInputLabelProps } from '../SLInputLabel';
import { validationMode } from '../common/ValidationMode.constants';
import { LightTooltip } from '../../tooltip/Tooltip';
import TranslateText from '../../../../i18n/TranslateText';
import { toSnakeCase } from '../../../../i18n/translation.helper';

const useStylesForMenu = makeStyles({
  root: {
    '& .MuiPaper-root': {
      maxHeight: '25vh'
    }
  },
  selectRoot: {
    display: 'flex',
    flexGrow: 1
  },
  dropdownStyle: {
    width: '10vw',
    marginRight: '30px',
    marginTop: '3px'
  },
  selectStyle: {
    borderWidth: 1,
    height: '33px',
    width: '15vw',
    borderColor: 'black',
    borderRadius: '28px',
    borderStyle: 'solid',
    '& .MuiSelect-select.MuiSelect-select': {
      paddingLeft: '10px'
    },
    '@media(min-width: 1280px)': {
      borderWidth: 1,
      borderColor: 'black',
      borderRadius: '28px',
      borderStyle: 'solid',
      paddingTop: '1px'
    },
    '@media(min-width: 1920px)': {
      borderWidth: 1,
      borderColor: 'black',
      borderRadius: '28px',
      borderStyle: 'solid',
      paddingTop: '2px'
    },
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '&:focus': {
      backgroundColor: 'transparent'
    }
  }
});

type OptionsType = {
  value: string | number;
  label: string | ReactNode;
  disabled?: boolean;
};

interface Props {
  name: string;
  label: ReactNode;
  labelProps?: SLInputLabelProps;
  rules?: Object | Function;
  defaultValue?: any;
  [key: string]: any;
  options: OptionsType[];
  multiple?: boolean;
  showCheckbox?: boolean;
  val?: any;
  rounded?: any;
  prefix?: ReactNode;
  suffix?: ReactNode;
  onSelectChange?: Function;
  onBlur?: ((value: any, setFn: Function, getFn: Function) => void) | undefined;
  triggerValidationMode?: 'onBlur' | 'onChange' | 'onFocus' | 'all' | string | undefined;
  /**
   * Enable tooltip on selected option
   */
  showTooltip?: boolean;
  value?: any;
  customRootStyles?: string;
  testID?: string;
  translationId?: string;
}

function SLSelect({
  name,
  label,
  labelProps,
  rules,
  defaultValue,
  options,
  onSelectChange,
  val,
  multiple = false,
  showCheckbox = false,
  onBlur,
  rounded,
  prefix,
  suffix,
  triggerValidationMode = undefined,
  showTooltip = false,
  value,
  customRootStyles,
  testID,
  translationId,
  ...otherProps
}: Props): ReactElement {
  const {
    watch,
    setValue,
    control,
    formState: { errors },
    trigger,
    getValues
  } = useFormContext();

  const classes = useStyles();
  const menuClasses = useStylesForMenu();

  /**
   * Trigger validation based on ```triggerValidationMode``` prop value.
   * @param eventType onBlur | onChange | onFocus
   */
  const triggerValidationsOnEvent = async (eventType: string) => {
    if (eventType === triggerValidationMode) {
      await trigger(name, { shouldFocus: false });
    }
  };

  const handleOnChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    triggerValidationsOnEvent(validationMode.onChange);
    setValue(name, event?.target?.value);
    if (onSelectChange) {
      onSelectChange(event.target.value as string);
    }
  };
  const handleOnBlur = () => {
    triggerValidationsOnEvent(validationMode.onBlur);
    if (onBlur) {
      onBlur(getValues(), setValue, getValues);
    }
  };

  useEffect(() => {
    if (val) {
      setValue(name, val);
    }
  }, [val]);

  /**
   * Return label of selected value
   * @param value selected value
   * @returns selected value label
   */
  const getTooltipTitle = () => {
    const selectedLabel = options.find((option) => option.value === value)?.label;
    return selectedLabel;
  };

  return (
    <>
      {prefix}
      <FormControl
        error={Boolean(errors[name])}
        variant="standard"
        fullWidth
        className={
          rounded ? menuClasses.dropdownStyle : customRootStyles ? customRootStyles : classes.root
        }
        classes={{
          root: rounded ? menuClasses.selectRoot : ''
        }}>
        <Controller
          name={name}
          control={control}
          rules={typeof rules === 'function' ? rules(watch) : rules}
          defaultValue={defaultValue || ''}
          render={({ field, fieldState: { error } }) => (
            <>
              <SLInputLabel id={name} {...labelProps}>
                {translationId ? TranslateText(translationId) : label}
              </SLInputLabel>
              <LightTooltip
                disableHoverListener={false}
                title={showTooltip ? getTooltipTitle() : ''}
                placement="top-start">
                <Select
                  id={name}
                  name={name}
                  multiple={multiple || showCheckbox}
                  disableUnderline={rounded}
                  className={rounded ? menuClasses.selectStyle : ''}
                  labelId={name}
                  label={translationId ? TranslateText(translationId) : label}
                  inputProps={{ id: testID }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left'
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left'
                    },
                    className: menuClasses.root
                  }}
                  onChange={(e: any) => {
                    field?.onChange(e);
                    handleOnChange(e);
                  }}
                  onBlur={() => {
                    field?.onBlur();
                    handleOnBlur();
                  }}
                  defaultValue={defaultValue || ''}
                  value={value !== undefined ? value : field?.value}
                  inputRef={field?.ref}
                  {...otherProps}>
                  {options.map((option) => (
                    <MenuItem
                      id={`${testID}[${option.label}]`}
                      key={option.value}
                      value={option.value}
                      disabled={option.disabled}>
                      {showCheckbox ? <Checkbox /> : null}
                      {option.label && typeof option.label === 'string'
                        ? TranslateText(toSnakeCase(option.label), option.label)
                        : option.label}
                    </MenuItem>
                  ))}
                </Select>
              </LightTooltip>
              {error && error?.message && (
                <FormHelperText error>{error && error?.message}</FormHelperText>
              )}
            </>
          )}
        />
      </FormControl>
      {suffix}
    </>
  );
}
export default SLSelect;
