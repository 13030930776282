/**
 * Avatar Style
 * @author mahesh.kedari@shorelineiot.com
 */
import { BreadcrumbsClasses, Theme } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { Colors } from '../../../constants';

export const BreadcrumbStyle:
  | Partial<
      OverridesStyleRules<keyof BreadcrumbsClasses, 'MuiBreadcrumbs', Omit<Theme, 'components'>>
    >
  | undefined = {
  root: {
    color: Colors.primaryText,
    fontWeight: 600
  },
  li: {
    fontWeight: 'bold',
    color: Colors.primaryText
  },
  separator: {
    width: '18px',
    height: '18px',
    marginLeft: 0,
    marginTop: '-8px'
  }
};
