import { RawDatapointsObject } from '../device.types';

/**
 *
 */
export enum RAW_DEVICE_DATAPOINTS_STATUS {
  INIT,
  FETCHING,
  READY,
  ERROR
}
/**
 *
 */
export interface RawDeviceDatapointsState {
  status: RAW_DEVICE_DATAPOINTS_STATUS;
  datapointsList: RawDatapointsObject[];
  devicesList: Array<any>;
  selectedDatapoints: Array<any>;
  selectedDevices: Array<any>;
}
