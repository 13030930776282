/**
 * Sign-up Form Component
 * @author mahesh.kedari@shorelineiot.com
 * Update by Swapnil
 */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { Box, Typography, Link } from '@mui/material';
import { useStyles } from '../../shared/authForm.styles';
import {
  SLForm,
  SLTextField,
  SLSubmit,
  SLCheckbox,
  SLSelect,
  usePlatformDetails,
  nameSplitter,
  useSlugContext,
  showProgressFor
} from '../../../../framework';
import {
  registrationValidationRule as rules,
  registerDefaultValues as defaultValues
} from './register.validation';
import countryList from '../../../common/country.json';
import Organisation from './components/Organisation';
import Passwords from './components/Passwords';
import SLEmailField from '../../../../framework/components/form/SLEmailField';
import { registerActions, useSignupState } from '../store';

export const REGISTER_TASK = 'USER_REGISTER';
const REGISTER_ACTION_PROGRESS_ID = 'REGISTER_ACTION_PROGRESS_ID';
/**
 *
 */
function RegisterForm() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isMobile, isTablet, isPortrait } = usePlatformDetails();
  const { slug } = useSlugContext();

  const size = isMobile ? 'small' : isTablet ? 'medium' : 'large';
  const navigate = useNavigate();
  const signupState = useSignupState();
  /**
   *
   */
  const handleSubmit = (data: Record<string, any>) => {
    const [givenName, familyName] = nameSplitter(data.name);
    const updatedData = {
      ...data,
      email: data?.email.trim(),
      givenName,
      familyName: familyName || '',
      slug: data.slug.toLowerCase()
    };
    dispatch(showProgressFor(REGISTER_ACTION_PROGRESS_ID));
    dispatch(registerActions.register(updatedData));
  };

  useEffect(() => {
    if (signupState.error === true) {
      dispatch(registerActions.resetToRegister());
      navigate(`/${slug}/auth/login`);
    }
  }, [signupState.error]);
  /**
   *
   */
  useEffect(() => {
    if (signupState.isSigningUp) {
      navigate(`/${slug}/auth/verification-link`);
    }
  });

  // TODO:SLC-608 Register: When you hover over the T&C box there is agreyish semi circle that appears. Need to remove it
  return (
    <SLForm onSubmit={handleSubmit} defaultValues={defaultValues} className={classes.formStyle}>
      <Box
        display="flex"
        flexDirection="column"
        width={isPortrait ? '80vw' : 'auto'}
        maxWidth="500px">
        <SLTextField name="name" label="Name" rules={rules.name} />
        <SLEmailField name="email" label="Email" rule={rules.email} />
        <Passwords />
        <SLSelect
          name="country_code"
          label="Country"
          rules={rules.country_code}
          options={countryList}
          defaultValue=""
        />
        <Organisation rules={rules} />
      </Box>
      <Box display="flex" justifyContent="space-between" marginTop="20px">
        <SLCheckbox
          name="terms"
          label={
            <>
              <Typography variant="body1">
                Accept the&nbsp;
                <Link href={`/${slug}/public/terms`} target="_blank" color="secondary">
                  Terms and Conditions
                </Link>
              </Typography>
            </>
          }
          rules={rules.acceptTnC}
        />
      </Box>
      <Box display="flex" justifyContent={isMobile || isPortrait ? 'center' : 'left'} mt={3}>
        <SLSubmit
          size={size}
          className={classes.submitButton}
          dataTestId="register-form-register-button">
          Register
        </SLSubmit>
      </Box>
    </SLForm>
  );
}

export default RegisterForm;
