/**
 * @author ashwini.gawade@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTION_TYPE from './actions/orgUpdate.actionTypes';
import { OrgUpdateState } from './types/OrgUpdateState';
import { ORG_UPDATE_STATUS } from './types/OrgUpdateStatus';
/**
 *
 */
const initialState: OrgUpdateState = {
  status: ORG_UPDATE_STATUS.INIT
};
/**
 *
 * @param state
 */
function onUpdateOrg(state: OrgUpdateState): OrgUpdateState {
  return {
    ...state,
    status: ORG_UPDATE_STATUS.UPDATING_ORG
  };
}
/**
 *
 * @param state
 */
function onUpdateOrgFailure(state: OrgUpdateState): OrgUpdateState {
  return {
    ...state,
    status: ORG_UPDATE_STATUS.ERROR
  };
}
/**
 *
 * @param state
 * @param action
 */
function onUpdateOrgSuccess(state: OrgUpdateState, action: AnyAction): OrgUpdateState {
  return {
    ...state,
    status: ORG_UPDATE_STATUS.READY,
    orgDetails: action.data
  };
}
/**
 *
 * @param state
 */
function getOrgDetails(state: OrgUpdateState): OrgUpdateState {
  return {
    ...state,
    status: ORG_UPDATE_STATUS.FETCHING_ORG
  };
}
/**
 *
 * @param state
 */
function getOrgDetailsFailure(state: OrgUpdateState): OrgUpdateState {
  return {
    ...state,
    status: ORG_UPDATE_STATUS.ERROR
  };
}
/**
 *
 * @param state
 * @param action
 */
function getOrgDetailsSuccess(state: OrgUpdateState, action: AnyAction): OrgUpdateState {
  return {
    ...state,
    status: ORG_UPDATE_STATUS.READY,
    orgDetails: action.data
  };
}
/**
 *
 */
function resetOrgDetails() {
  return {
    ...initialState
  };
}
/**
 *
 * @param state
 * @param action
 */
export default function orgUpdateReducer(
  state: OrgUpdateState = initialState,
  action: AnyAction
): OrgUpdateState {
  switch (action.type) {
    case ACTION_TYPE.UPDATE_ORG:
      return onUpdateOrg(state);
    case ACTION_TYPE.UPDATE_ORG_SUCCESS:
      return onUpdateOrgSuccess(state, action);
    case ACTION_TYPE.UPDATE_ORG_FAILURE:
      return onUpdateOrgFailure(state);
    case ACTION_TYPE.FETCH_ORG_DETAILS:
      return getOrgDetails(state);
    case ACTION_TYPE.ORG_DETAILS_SUCCESS:
      return getOrgDetailsSuccess(state, action);
    case ACTION_TYPE.ORG_DETAILS_FAILURE:
      return getOrgDetailsFailure(state);
    case ACTION_TYPE.RESET_ORG_DETAILS:
      return resetOrgDetails();
    default:
      return state;
  }
}
