import { TablePaginationClasses, Theme } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

/**
 *
 */
export const MuiMaterialReactTablePaginationStyle:
  | Partial<
      OverridesStyleRules<
        keyof TablePaginationClasses,
        'MuiTablePagination',
        Omit<Theme, 'components'>
      >
    >
  | undefined = {
  select: {
    fontSize: '0.89rem',
    marginTop: '2px'
  },
  displayedRows: {
    fontSize: '0.89rem !important'
  }
};
