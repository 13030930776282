/**
 * Datapoint State
 * @author mahesh.kedari@shorelineiot.com
 */

import { Datapoint } from './datapoint';
import { ViewConfigState } from './viewConfig';
import { LiveDataConfigState } from './liveDataConfig';

/**
 *
 */
export enum DATAPOINTS_LIST_STATUS {
  INIT,
  FETCHING,
  READY,
  ERROR
}

export interface ViewConfigType {
  [key: string]: ViewConfigState;
  [key: number]: ViewConfigState;
}
export interface LiveDataConfigType {
  [key: string]: LiveDataConfigState;
  [key: number]: LiveDataConfigState;
}

export interface LastDataValue {
  [key: string]: any;
  [key: number]: any;
}
/**
 *
 */
export interface DatapointsListState {
  status: DATAPOINTS_LIST_STATUS;
  datapointConfigs: Datapoint[];
  viewConfigs: ViewConfigType;
  liveDataConfigs: LiveDataConfigType;
  lastDataValue: LastDataValue;
  fetchDatapointsAgain: boolean;
}
