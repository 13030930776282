import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';
import { columnChartConfig } from '../../../../features/reports/leak-detection/month-count-chart/defaultChartConfig';
import { MethaneEmissionAlarm } from '../../../../features/reports/leak-detection/utils/LeakDetectionAlarmType';
import { AirborneDataType } from './LeakDetectionViewer';
import { addZeroEntries, getMinMax, sortAccordingToKeys } from './leakDetectionChartUtils';

enum RangeTypes {
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY'
}

type Props = {
  alarms: MethaneEmissionAlarm[];
  range: RangeTypes;
  airborneData: AirborneDataType;
};
const LeakDetectionChartComponent = ({ alarms, range, airborneData }: Props) => {
  const formatData = (alarms: MethaneEmissionAlarm[], range: RangeTypes) => {
    const columnData: Array<any> = [];
    const lineData: Array<any> = [];
    if (range === RangeTypes.MONTHLY) {
      let month_count: { [key: string]: number } = {};
      let month_duration: { [key: string]: number } = {};

      // first traverse the alarms and airborne data to find out the min and max month created
      const { minVal: min_month, maxVal: max_month } = getMinMax(alarms, airborneData, 'month');

      // Calculate the count for each month
      alarms.forEach((alarm) => {
        const month_created = moment(alarm.created);
        const formattedDate = month_created.format('YYYY-MMM');
        if (formattedDate in month_count) {
          month_count[formattedDate] += 1;
        } else {
          month_count[formattedDate] = 1;
        }
      });

      month_count = { ...addZeroEntries(min_month, max_month, month_count, 'month') };

      // Sort according to the months
      let sortedMonths = sortAccordingToKeys(month_count, 'month');
      sortedMonths.forEach((month) => {
        columnData.push([month, month_count[month]]);
      });

      // Calculate the duration for each month
      const deviceIds = Object.keys(airborneData);
      deviceIds.forEach((deviceId) => {
        const dataPoints = airborneData[Number(deviceId)];
        dataPoints.forEach((item) => {
          const month_created = moment(item.time / 1000); //nanosec to milisec
          const duration = item.val;
          const formattedDate = month_created.format('YYYY-MMM');
          if (formattedDate in month_duration) {
            month_duration[formattedDate] += duration;
          } else {
            month_duration[formattedDate] = duration;
          }
        });
      });
      month_duration = {
        ...addZeroEntries(min_month, max_month, month_duration, 'month')
      };
      sortedMonths = sortAccordingToKeys(month_duration, 'month');
      sortedMonths.forEach((month) => {
        lineData.push([month, month_duration[month] * 0.25]); // Each count is 0.25 hours
      });
    } else if (range === RangeTypes.WEEKLY) {
      let week_count: { [key: string]: number } = {};
      let week_duration: { [key: string]: number } = {};

      // first traverse the alarms and airborne data to find out the min and max week created
      const { minVal: min_week, maxVal: max_week } = getMinMax(alarms, airborneData, 'week');

      // Calculate the count for each week
      alarms.forEach((alarm) => {
        const week_created = moment(alarm.created).startOf('week');
        const formattedDate = week_created.format('DD-MMM-YY');
        if (formattedDate in week_count) {
          week_count[formattedDate] += 1;
        } else {
          week_count[formattedDate] = 1;
        }
      });
      week_count = { ...addZeroEntries(min_week, max_week, week_count, 'week') };
      let sortedWeeks = sortAccordingToKeys(week_count, 'week');
      sortedWeeks.forEach((week) => {
        columnData.push([week, week_count[week]]);
      });

      // Calculate the duration for each week
      const deviceIds = Object.keys(airborneData);
      deviceIds.forEach((deviceId) => {
        const dataPoints = airborneData[Number(deviceId)];
        dataPoints.forEach((item) => {
          const week_created = moment(item.time / 1000).startOf('week'); //nanosec to milisec
          const duration = item.val;
          const formattedDate = week_created.format('DD-MMM-YY');
          if (formattedDate in week_duration) {
            week_duration[formattedDate] += duration;
          } else {
            week_duration[formattedDate] = duration;
          }
        });
      });
      week_duration = { ...addZeroEntries(min_week, max_week, week_duration, 'week') };
      sortedWeeks = sortAccordingToKeys(week_duration, 'week');
      sortedWeeks.forEach((week) => {
        lineData.push([week, week_duration[week] * 0.25]); // Each count is 0.25 hours
      });
    }
    return columnChartConfig(columnData, lineData);
  };

  const chartData = formatData(alarms, range);

  return <HighchartsReact highcharts={Highcharts} options={chartData} />;
};

export default LeakDetectionChartComponent;
