/**
 * SL Panel Header
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { PropsWithChildren } from 'react';
import { Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { grey } from '@mui/material/colors';
import { toSnakeCase } from '../../../i18n/translation.helper';
import TranslateText from '../../../i18n/TranslateText';

type Props = {
  prefix?: React.ReactElement<any, any>;
  title: string;
  enabled?: boolean;
};

const HEADER_HEIGHT = '47px';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    height: HEADER_HEIGHT,
    width: '100%',
    borderRadius: '5px 5px 0px 0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px 10px'
  },
  enabled: {
    backgroundColor: theme.palette.primary.main
  },
  disabled: {
    backgroundColor: grey[600]
  },
  titleBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  title: {
    color: theme.palette.text.secondary
  }
}));

export default function SLPanelHeader({
  prefix,
  title,
  enabled = true,
  children
}: PropsWithChildren<Props>) {
  const classes = useStyles();
  return (
    <div className={`${classes.header} ${enabled ? classes.enabled : classes.disabled}`}>
      <div className={classes.titleBox}>
        {prefix}
        <Typography variant="h3" className={classes.title}>
          {TranslateText(toSnakeCase(title), title)}
        </Typography>
      </div>
      {children}
    </div>
  );
}
