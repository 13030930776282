/**
 * Default values for Common Settings for all chart types.
 * @author aditya.bhadange@shorelineiot.com
 */
export const widgetFormCommonDefaults = [
  {
    chartType: 'table',
    commonDefaultSettings: {
      sorting: false,
      columnAlignment: 'left',
      fullRowRedirection: false,
      fullRowRedirectionType: 'alarms',
      dataSettings: {}
    }
  },
  {
    chartType: 'line',
    commonDefaultSettings: {
      xAxisTitle: '',
      marker: true,
      'grid.disable': false,
      'grid.width': '',
      'crosshair.enable': false,
      'crosshair.color': { hex: '#000000' },
      'options3d.enabled': false,
      'options3d.alpha': 15,
      'options3d.beta': 15,
      options3ddepth: 50,
      manualYAxisScale: false,
      yAxisMin: '',
      yAxisMax: ''
    }
  },
  {
    chartType: 'area',
    commonDefaultSettings: {
      xAxisTitle: '',
      marker: true,
      'grid.disable': false,
      'grid.width': '',
      'crosshair.enable': false,
      'crosshair.color': { hex: '#000000' },
      'options3d.enabled': false,
      'options3d.alpha': 15,
      'options3d.beta': 15,
      options3ddepth: 50,
      manualYAxisScale: false,
      yAxisMin: '',
      yAxisMax: ''
    }
  },
  {
    chartType: 'bar',
    commonDefaultSettings: {
      xAxisTitle: '',
      marker: true,
      'grid.disable': false,
      'grid.width': '',
      'crosshair.enable': false,
      'crosshair.color': { hex: '#000000' },
      'options3d.enabled': false,
      'options3d.alpha': 15,
      'options3d.beta': 15,
      options3ddepth: 50,
      manualYAxisScale: false,
      yAxisMin: '',
      yAxisMax: ''
    }
  },
  {
    chartType: 'column',
    commonDefaultSettings: {
      xAxisTitle: '',
      marker: true,
      'grid.disable': false,
      'grid.width': '',
      'crosshair.enable': false,
      'crosshair.color': { hex: '#000000' },
      'options3d.enabled': false,
      'options3d.alpha': 15,
      'options3d.beta': 15,
      options3ddepth: 50,
      manualYAxisScale: false,
      yAxisMin: '',
      yAxisMax: ''
    }
  },
  {
    chartType: 'pie',
    commonDefaultSettings: {
      title: '',
      innerSize: '',
      legands: true,
      enable3d: false,
      dataLabelType: 'PERCENTAGE'
    }
  },
  {
    chartType: 'donut',
    commonDefaultSettings: {
      title: '',
      innerSize: '50',
      legands: true,
      enable3d: false,
      dataLabelType: 'PERCENTAGE'
    }
  },
  {
    chartType: 'scatter',
    commonDefaultSettings: {
      xAxisTitle: '',
      marker: true,
      'grid.disable': false,
      'grid.width': '',
      'crosshair.enable': false,
      'crosshair.color': { hex: '#000000' },
      'options3d.enabled': false,
      'options3d.alpha': 15,
      'options3d.beta': 15,
      options3ddepth: 50,
      manualYAxisScale: false,
      yAxisMin: '',
      yAxisMax: ''
    }
  },
  {
    chartType: 'cartesian',
    commonDefaultSettings: {
      xAxisTitle: '',
      marker: true,
      'grid.disable': false,
      'grid.width': '',
      'crosshair.enable': false,
      'crosshair.color': { hex: '#000000' },
      'options3d.enabled': false,
      'options3d.alpha': 15,
      'options3d.beta': 15,
      options3ddepth: 50,
      manualYAxisScale: false,
      yAxisMin: '',
      yAxisMax: ''
    }
  },
  {
    chartType: 'stack-line',
    commonDefaultSettings: {
      chartHeight: ''
    }
  }
];
