/**
 * Asset Severity Age Editor
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { useState } from 'react';
import TableWidgetCommonEditor from '../../table/editor/TableWidgetCommonEditor';
import { ChartEditorProps } from '../../common/ChartEditorProps';
import { useFormContext } from 'react-hook-form';
import { merge } from 'lodash';
import ResetDefaultConfig from '../../common/ResetDefaultConfig';
import WidgetSettingAccordion from '../../common/WidgetAccordion';
import { Grid } from '@mui/material';
import ColumnTitle from '../../table/editor/header/ColumnTitle';
import { SLCheckbox } from '../../../form';
import TableColumnConditions from '../../table/editor/condition/TableColumnConditions';
import RedirectionURLEditor from '../../common/RedirectionURLEditor';
import { TableComponentProps } from '../../table/table.types';
import { CHART_TYPE } from '../../SLWidgetList';

interface ColumnConfigurationProps {
  columnKey: string;
  handleUpdate: (data: any) => void;
}
/**
 * This is a configuration for individual columns to be shown in Table view
 * @param param0
 * @returns
 */
const ColumnConfiguration = ({ columnKey, handleUpdate }: ColumnConfigurationProps) => {
  const { getValues } = useFormContext();

  const newValues = getValues();

  const handleFieldUpdate = () => {
    const values = getValues();
    handleUpdate(values);
  };

  return (
    <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
      <Grid item xs={6}>
        <ColumnTitle name={`dataSettings.${columnKey}.title`} handleUpdate={handleFieldUpdate} />
      </Grid>
      <Grid item xs={6}>
        <SLCheckbox
          name={`dataSettings.${columnKey}.hideColumn`}
          label="Hide Column"
          labelTranslationId="hide_column"
          checked={!!newValues?.dataSettings?.[columnKey]?.hideColumn}
          onChange={handleFieldUpdate}
        />
      </Grid>

      <TableColumnConditions
        name={`dataSettings.${columnKey}.conditions`}
        handleUpdate={handleFieldUpdate}
      />
      <RedirectionURLEditor handleUpdate={handleUpdate} path={`dataSettings.${columnKey}`} />
    </Grid>
  );
};

const AssetSeverityAgeEditor: React.FC<ChartEditorProps> = ({
  handleSubmitForm,
  defaultValues
}: ChartEditorProps) => {
  const formDefaults = {
    sorting: false,
    columnAlignment: 'left'
  };
  const [tableWidgetSettings, setTableWidgetSettings] = useState<TableComponentProps>({
    chartType: CHART_TYPE.ASSET_SEVERITY_AGE,
    chartSettings: {
      sorting: defaultValues?.sorting ? defaultValues?.sorting : formDefaults.sorting,
      columnAlignment: defaultValues?.columnAlignment || formDefaults.columnAlignment,
      dataSettings: defaultValues?.dataSettings
    }
  });

  const { getValues } = useFormContext();

  const handleUpdate = () => {
    const currentSettings = merge({}, tableWidgetSettings);
    const values = getValues();
    const updatedSettings = {
      ...currentSettings,
      chartSettings: {
        ...values
      }
    };
    handleSubmitForm(updatedSettings);
    setTableWidgetSettings(updatedSettings);
  };

  return (
    <>
      <TableWidgetCommonEditor
        currentSettings={tableWidgetSettings.chartSettings}
        handleUpdate={handleUpdate}
      />
      <WidgetSettingAccordion key={'suborg_uuid'} title={'Site'}>
        <ColumnConfiguration columnKey={'suborg_uuid'} handleUpdate={handleUpdate} />
      </WidgetSettingAccordion>
      <WidgetSettingAccordion key={'powertrain_name'} title={'Asset'}>
        <ColumnConfiguration columnKey={'powertrain_name'} handleUpdate={handleUpdate} />
      </WidgetSettingAccordion>
      <WidgetSettingAccordion key={'alarm_age'} title={'Alarm Age'}>
        <ColumnConfiguration columnKey={'alarm_age'} handleUpdate={handleUpdate} />
      </WidgetSettingAccordion>
      <WidgetSettingAccordion key={'severity'} title={'Severity'}>
        <ColumnConfiguration columnKey={'severity'} handleUpdate={handleUpdate} />
      </WidgetSettingAccordion>
      <ResetDefaultConfig chartType="table" onReset={handleUpdate} />
    </>
  );
};

export default AssetSeverityAgeEditor;
