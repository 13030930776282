import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  marginsEditMode: {
    marginTop: '7%',
    marginLeft: '2%',
    marginRight: '2%'
  },
  marginsDashboardMode: {
    marginTop: '2%',
    marginLeft: '2%',
    marginRight: '2%'
  }
}));
