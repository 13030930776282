/**
 * dashbaord list
 */
export enum DashboardListStatus {
  INIT,
  FETCHING,
  READY,
  ERROR,
  RESET
}

export interface DashboardListData {
  dashboard_uuid: string;
  name: string;
  slug: string;
  meta_data: Record<string, any>;
  is_default: boolean;
  edit_mode?: boolean;
  role_uuids: string[];
}

export interface DashboardListState {
  status: DashboardListStatus;
  dashboardListData: DashboardListData[];
}

export interface DashboardTypeData {
  slug: string;
  role_uuid: string;
}
