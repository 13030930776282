/**
 * Reset Password Actions
 * @author mahesh.kedari@shorelineiot.com
 */
import { AnyAction } from 'redux';
import { ACCEPT_INVITE_STATUS } from '../types';
import * as ACTIONS from './acceptInvite.actionTypes';

/**
 *
 */
export const resetPasswordAndAccept = (data: any): AnyAction => {
  return {
    type: ACTIONS.RESET_PASSWORD_PROGRESS,
    payload: data
  };
};
/**
 *
 */
export const resetPasswordSuccess = (sessionObject: any): AnyAction => {
  return {
    type: ACTIONS.RESET_PASSWORD_SUCCESS,
    payload: sessionObject
  };
};
/**
 *
 */
export const resetPasswordFailure = (error: any): AnyAction => {
  return {
    type: ACTIONS.RESET_PASSWORD_FAILURE,
    payload: error
  };
};
/**
 *
 *
 */
export const validatePassword = (data: any): AnyAction => {
  return {
    type: ACTIONS.VALIDATE_PASSWORD,
    payload: data
  };
};
/**
 *
 * @param data
 * @returns
 */
export const validatePasswordSuccess = (data: any): AnyAction => {
  return {
    type: ACTIONS.VALIDATE_PASSWORD_SUCCESS,
    payload: data
  };
};
/**
 *
 * @param data
 * @returns
 */
export const validatePasswordFailure = (data: any): AnyAction => {
  return {
    type: ACTIONS.VALIDATE_PASSWORD_FAILURE,
    payload: data
  };
};
/**
 *
 */
export const acceptInvite = (data: any): AnyAction => {
  return {
    type: ACTIONS.ACCEPT_INVITE,
    payload: data
  };
};

export const acceptInprogressInvites = (payload: any): AnyAction => {
  return {
    type: ACTIONS.ACCEPT_IN_PROGRESS_INVITES,
    payload
  };
};
/**
 *
 */
export const acceptInviteSuccess = (data?: any): AnyAction => {
  return {
    type: ACTIONS.ACCEPT_INVITE_SUCCESS,
    payload: data
  };
};
/**
 *
 */
export const acceptInviteFailure = (error: ACCEPT_INVITE_STATUS): AnyAction => {
  return {
    type: ACTIONS.ACCEPT_INVITE_FAILURE,
    payload: error
  };
};
/**
 *
 */
export const resetAcceptInvite = (): AnyAction => {
  return {
    type: ACTIONS.RESET_ACCEPT_INVITE
  };
};

export const markAcceptInviteInProgress = (
  email?: string | null,
  slug?: string, // Host organization from which the request for invite accept will be sent
  subOrgSlugs?: Array<any> | null, // Array of sub-orgs
  role?: string | null,
  provider?: string | null
): AnyAction => {
  return {
    type: ACTIONS.MARK_ACCEPT_INVITE_IN_PROGRESS,
    payload: {
      email,
      slug,
      subOrgSlugs,
      role,
      provider
    }
  };
};
