/**
 * Progress Bar Component
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { ReactElement, useEffect, useState } from 'react';
import { LinearProgress } from '@mui/material';
import { useStyles } from './progressBar.styles';
import { useProgressBar } from './progressBar.selectorHooks';
/**
 *
 */

export default function SLProgressBar(): ReactElement {
  const classes = useStyles();
  const processes: Array<string> = useProgressBar();
  const [showProgressBar, setProgressBar] = useState(false);

  useEffect(() => {
    setProgressBar(processes.length > 0);
  }, [processes]);

  if (showProgressBar) {
    return <LinearProgress className={classes.root} color="primary" />;
  }
  return <></>;
}
