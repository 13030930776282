import { combineReducers, AnyAction } from 'redux';
import createSagaMiddleware from 'redux-saga';
import features from './features/features.reducer';
import framework from './framework/reducers/framework.reducer';
import { featuresSaga } from './features/feature.saga';
import { configureStore } from '@reduxjs/toolkit';
import { featuresMiddlewares } from './features/features.rtk.middlewares';
import { featuresReducers } from './features/features.rtk.reducers';
import { setupListeners } from '@reduxjs/toolkit/query';

const sagaMiddleware = createSagaMiddleware();

const allReducers = combineReducers({
  features,
  framework,
  ...featuresReducers
});

const rootReducer = (state: any, action: AnyAction) => {
  if (action.type === 'RESET_APP' || action.type === 'FEATURES/AUTH/LOGOUT_PROGRESS') {
    state = undefined;
  }

  return allReducers(state, action);
};
export type RootState = ReturnType<typeof rootReducer>;

export default function SLConfigureStore() {
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ immutableCheck: false, serializableCheck: false }).prepend(
        sagaMiddleware,
        ...featuresMiddlewares
      ),
    /**
     * Configures the devTools option based on the current environment for debugging.
     *
     * @param {boolean} import.meta.env.DEV - development environment
     * @return {object|boolean} the devTools configuration object or false
     */
    devTools: import.meta.env.DEV ? { trace: true, traceLimit: 25 } : false
  });
  setupListeners(store.dispatch);
  sagaMiddleware.run(featuresSaga);

  return store;
}
