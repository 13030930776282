/**
 * TranslateText Component
 * @author prerna.more@shorelineiot.com
 */
import { getGlobalIntl } from './intlGlobal';

/**
 * Translates a message ID to a localized string using the `intl` object from `react-intl`.
 *
 * @param id - The message ID to be translated. This is a required parameter.
 * @param defaultMessage - The default message to be used if the `id` is not found. This is an optional parameter.
 * @returns The localized string corresponding to the `id` and `defaultMessage`.
 */
const TranslateText = (id?: string, defaultMessage?: string): string => {
  const intl = getGlobalIntl();

  return intl.formatMessage({ id, defaultMessage });
};

export default TranslateText;
