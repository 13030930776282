/**
 * Register Page
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import AuthHeader from '../shared/AuthHeader';
import SocialLogin from '../login/social-login/SocialLogin';
import { useStyles } from './registerPage.style';
import RegisterForm from './register-form/RegisterForm';
import { useSlugContext, usePlatformDetails } from '../../../framework';
/**
 *
 */
interface Props {
  login?: {
    isLoggedIn: boolean;
  };
}

/**
 *
 * @param login Check for Login object
 */
const redirectIfAlreadyLoggedIn: React.FC<Props> = (props: Props) => {
  const { login } = props;
  if (login && login.isLoggedIn) {
    return <Navigate to="/app" replace />;
  }
  return null;
};
/**
 *
 * @param param0
 */
export default function RegisterPage(props: Props): ReactElement {
  redirectIfAlreadyLoggedIn(props);
  const classes = useStyles();
  const { slug } = useSlugContext();
  const { isMobile, isPortrait } = usePlatformDetails();
  return (
    <>
      <AuthHeader
        title="Register"
        message="Already have an account?"
        buttonText="Log In"
        translationId={'register'}
        path={`/${slug}/auth/login`}
      />
      <Container className={classes.root}>
        <Box
          flexDirection={isMobile || isPortrait ? 'column' : 'row'}
          className={classes.root}
          flexGrow="1">
          <Box order={isMobile || isPortrait ? 2 : 0} flexGrow="1" flexBasis="0">
            <RegisterForm />
          </Box>
          {isMobile || isPortrait ? (
            <Box component="span" order={1} margin="10px 0px">
              Or
            </Box>
          ) : (
            <Divider orientation="vertical" variant="middle" flexItem className={classes.divider} />
          )}
          <Box order={isMobile || isPortrait ? 0 : 2} flexGrow="1" flexBasis="0">
            <SocialLogin
              googleTitle="Register with Google"
              linkedInTitle="Register with LinkedIn"
              isFromRegister="true"
            />
          </Box>
        </Box>
      </Container>
    </>
  );
}
