import { put, takeEvery, call } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import * as actions from './actions/ComponentClassList.actions';
import * as ACTION_TYPES from './actions/ComponentClassList.actionTypes';
import { GenericObject, httpGet, SafeSaga, APISERVICES } from '../../../../../framework';
import { SagaIterator } from 'redux-saga';

function getList(data: any) {
  // return httpGet(
  //   `orgs/${data.slug}/devices/${data.deviceId}/powertrain_component?manufacturer=${data.manufacturerId}&model=${data.modelId}&component_class=${data.classId}&subclass=${data.subclassId}`
  // );
  return httpGet(
    `orgs/${data.slug}/devices/${data.deviceId}/powertrain_component?component_class=${data.classId}&subclass=${data.subclassId}`,
    null,
    APISERVICES.DEVICE_API
  );
}
function* componentClassesListSaga(action: AnyAction) {
  const response: GenericObject = yield call(getList, action.payload);
  yield put(actions.fetchComponentClassListSuccess(response));
}

function* errorHandler() {
  yield put(actions.fetchComponentClassListFailure());
}
/**
 *
 */
export function* watchComponentClassesListSagas(): SagaIterator {
  yield takeEvery(
    ACTION_TYPES.FETCH_COMPONENT_CLASS_LIST_PROGRESS,
    SafeSaga(
      componentClassesListSaga,
      ACTION_TYPES.FETCH_COMPONENT_CLASS_LIST_PROGRESS,
      errorHandler
    )
  );
}
