/**
 * digital sensor actions
 * @author ashwini.gawade@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTION_TYPES from './digitalSensor.actionTypes';
/**
 *
 */
export function getDigitalSensorSchemaProgressDIO1(data: any): AnyAction {
  return {
    type: ACTION_TYPES.FETCH_DIGITAL_SENSOR_SCHEMA_PROGRESS_DIO1,
    payload: data
  };
}

export function getDigitalSensorSchemaSuccessDIO1(data: any): AnyAction {
  return {
    type: ACTION_TYPES.FETCH_DIGITAL_SENSOR_SCHEMA_SUCCESS_DIO1,
    payload: data
  };
}
/**
 *
 */
export function getDigitalSensorSchemaFailureDIO1(error: any): AnyAction {
  return {
    type: ACTION_TYPES.FETCH_DIGITAL_SENSOR_SCHEMA_FAILURE_DIO1,
    payload: error
  };
}

export const resetSchemaStateDIO1 = (): AnyAction => {
  return {
    type: ACTION_TYPES.FETCH_DIGITAL_SENSOR_RESET_STATE_DIO1,
    payload: false
  };
};

export function getDigitalSensorSchemaProgressDIO2(data: any): AnyAction {
  return {
    type: ACTION_TYPES.FETCH_DIGITAL_SENSOR_SCHEMA_PROGRESS_DIO2,
    payload: data
  };
}

export function getDigitalSensorSchemaSuccessDIO2(data: any): AnyAction {
  return {
    type: ACTION_TYPES.FETCH_DIGITAL_SENSOR_SCHEMA_SUCCESS_DIO2,
    payload: data
  };
}
/**
 *
 */
export function getDigitalSensorSchemaFailureDIO2(error: any): AnyAction {
  return {
    type: ACTION_TYPES.FETCH_DIGITAL_SENSOR_SCHEMA_FAILURE_DIO2,
    payload: error
  };
}

export const resetSchemaStateDIO2 = (): AnyAction => {
  return {
    type: ACTION_TYPES.FETCH_DIGITAL_SENSOR_RESET_STATE_DIO2,
    payload: false
  };
};

export function getDigitalSensorSchemaProgressDIO3(data: any): AnyAction {
  return {
    type: ACTION_TYPES.FETCH_DIGITAL_SENSOR_SCHEMA_PROGRESS_DIO3,
    payload: data
  };
}

export function getDigitalSensorSchemaSuccessDIO3(data: any): AnyAction {
  return {
    type: ACTION_TYPES.FETCH_DIGITAL_SENSOR_SCHEMA_SUCCESS_DIO3,
    payload: data
  };
}
/**
 *
 */
export function getDigitalSensorSchemaFailureDIO3(error: any): AnyAction {
  return {
    type: ACTION_TYPES.FETCH_DIGITAL_SENSOR_SCHEMA_FAILURE_DIO3,
    payload: error
  };
}

export const resetSchemaStateDIO3 = (): AnyAction => {
  return {
    type: ACTION_TYPES.FETCH_DIGITAL_SENSOR_RESET_STATE_DIO3,
    payload: false
  };
};

export function getDigitalSensorSchemaProgressDIO4(data: any): AnyAction {
  return {
    type: ACTION_TYPES.FETCH_DIGITAL_SENSOR_SCHEMA_PROGRESS_DIO4,
    payload: data
  };
}

export function getDigitalSensorSchemaSuccessDIO4(data: any): AnyAction {
  return {
    type: ACTION_TYPES.FETCH_DIGITAL_SENSOR_SCHEMA_SUCCESS_DIO4,
    payload: data
  };
}
/**
 *
 */
export function getDigitalSensorSchemaFailureDIO4(error: any): AnyAction {
  return {
    type: ACTION_TYPES.FETCH_DIGITAL_SENSOR_SCHEMA_FAILURE_DIO4,
    payload: error
  };
}

export const resetSchemaStateDIO4 = (): AnyAction => {
  return {
    type: ACTION_TYPES.FETCH_DIGITAL_SENSOR_RESET_STATE_DIO4,
    payload: false
  };
};
export const addDigitalSensorProgress = (data: any): AnyAction => {
  return {
    type: ACTION_TYPES.ADD_DIGITAL_SENSOR_PROGRESS,
    payload: data
  };
};
export const addDigitalSensorSuccess = (data: any): AnyAction => {
  return {
    type: ACTION_TYPES.ADD_DIGITAL_SENSOR_SUCCESS,
    payload: data
  };
};

export const addDigitalSensorFailure = (error: any): AnyAction => {
  return {
    type: ACTION_TYPES.ADD_DIGITAL_SENSOR_FAILURE,
    payload: error
  };
};

export const addDigitalSensorReset = (): AnyAction => {
  return {
    type: ACTION_TYPES.ADD_DIGITAL_SENSOR_RESET_STATE,
    payload: false
  };
};

export const setDefaultDIO1 = (data: any) => {
  return {
    type: ACTION_TYPES.SET_DEFAULT_DIO1,
    payload: data
  };
};

export const setDefaultDIO2 = (data: any) => {
  return {
    type: ACTION_TYPES.SET_DEFAULT_DIO2,
    payload: data
  };
};
export const setDefaultDIO3 = (data: any) => {
  return {
    type: ACTION_TYPES.SET_DEFAULT_DIO3,
    payload: data
  };
};
export const setDefaultDIO4 = (data: any) => {
  return {
    type: ACTION_TYPES.SET_DEFAULT_DIO4,
    payload: data
  };
};
