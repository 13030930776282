/**
 * Anomaly Templates List Saga
 * @author aditya.bhadange@shorelineiot.com
 */
import { takeEvery, put, call } from 'redux-saga/effects';
import * as ACTIONS from './anomalyTemplatesList.actionTypes';
import * as actions from './anomalyTemplatesList.actions';
import { GenericObject, httpGet, SafeSaga, APISERVICES } from '../../../../framework';
import { SagaIterator } from 'redux-saga';
/**
 *
 */
function getAllAnomalyTemplatesList() {
  return httpGet(`anomalies`, null, APISERVICES.DEVICE_API);
}

function* fetchAnomalyTemplatesListSaga() {
  const response: GenericObject = yield call(getAllAnomalyTemplatesList);

  yield put(actions.fetchAnomalyTemplatesListSuccess(response?.anomalies));
}
/**
 *
 * @param error
 */
function* errorHandler(error: any) {
  yield put(actions.fetchAnomalyTemplatesListFailure(error));
}
/**
 *
 */
export function* watchFetchAnomalyTemplatesListSaga(): SagaIterator {
  yield takeEvery(
    ACTIONS.FETCH_ANOMALY_TEMPLATES_LIST,
    SafeSaga(fetchAnomalyTemplatesListSaga, ACTIONS.FETCH_ANOMALY_TEMPLATES_LIST, errorHandler)
  );
}
