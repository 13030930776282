/**
 * Provider Reducer
 * @author mahesh.kedari@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTIONS from './provider.actionTypes';
/**
 *
 */
export enum PROVIDER_STATUS {
  INIT,
  FETCHING,
  READY,
  ERROR
}
/**
 *
 */
export interface ProviderState {
  status: PROVIDER_STATUS;
  providerType: 'normal' | 'federated';
  providerName?: string;
}
/**
 *
 */
const initialState: ProviderState = {
  status: PROVIDER_STATUS.INIT,
  providerType: 'normal',
  providerName: ''
};

function handleGetAuthProvider(state: ProviderState): ProviderState {
  return { ...state, status: PROVIDER_STATUS.FETCHING };
}
function handleProviderSuccess(state: ProviderState, data: any): ProviderState {
  return {
    ...state,
    status: PROVIDER_STATUS.READY,
    providerName: data,
    providerType: data ? 'federated' : 'normal'
  };
}

function handleProviderError(state: ProviderState): ProviderState {
  return { ...state, status: PROVIDER_STATUS.ERROR };
}
/**
 *
 * @param state
 * @param action
 */
export default function providerReducer(
  state: ProviderState = {
    status: PROVIDER_STATUS.INIT,
    providerType: 'normal'
  },
  action: AnyAction
): ProviderState {
  switch (action.type) {
    case ACTIONS.GET_AUTH_PROVIDER:
      return handleGetAuthProvider(state);
    case ACTIONS.AUTH_PROVIDER_SUCCESS:
      return handleProviderSuccess(state, action.payload);
    case ACTIONS.AUTH_PROVIDER_FAILURE:
      return handleProviderError(state);
    case ACTIONS.RESET_AUTH_PROVIDER:
      return { ...initialState };
    default:
      return state;
  }
}
