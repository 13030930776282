/**
 * Data points get Saga
 * @author priyanka.ambawane@shorelineiot.com
 */
import { AnyAction } from 'redux';
import { takeEvery, call, put, all } from 'redux-saga/effects';
import { datapointActions, DATAPOINT_ACTIONS } from '../actions';
import { orderDatapoints, orderNevadaNanoDatapoints } from '../utils/orderDatapoints';
import {
  GenericObject,
  httpGet,
  httpPut,
  removeProgressFor,
  showProgressFor,
  showToast,
  APISERVICES
} from '../../../../../../../framework';
import { SagaIterator } from 'redux-saga';
/**
 *
 * @param data
 */
function getDatapoints(data: any) {
  if (data.vibration_analysis) {
    return httpGet(
      `orgs/${data.slug}/devices/${data.deviceId}/datapoints?vibration_analysis=true`,
      null,
      APISERVICES.DEVICE_API
    );
  }
  return httpGet(
    `orgs/${data.slug}/devices/${data.deviceId}/datapoints?vibration_analysis=false`,
    null,
    APISERVICES.DEVICE_API
  );
}

function getWidgetSettings(data: any) {
  return httpGet(
    `orgs/${data.slug}/devices/${data.deviceId}/widgets`,
    null,
    APISERVICES.DEVICE_API
  );
}

function updateWidgetSettings(payload: any, widgets: any) {
  return httpPut(
    `orgs/${payload.slug}/devices/${payload.deviceId}/widgets`,
    {
      widgets
    },
    APISERVICES.DEVICE_API
  );
}

// function addWidgetsToDatapoints(datapoints: any, widgets: any) {
//   const updatedDatapoints = datapoints.map((datapoint: any) => {
//     // Iterate through widgets to find widget for the current datapoint
//     const updatedDatapoint = { ...datapoint };
//     widgets.forEach((widget: any) => {
//       if (widget?.settings?.datapoint_ids.indexOf(datapoint.dpid) > -1) {
//         updatedDatapoint.widget_settings = widget;
//       }
//     });
//     return updatedDatapoint;
//   });

//   return updatedDatapoints;
// }
const hideSensor = (datapointType: string): boolean => {
  /* Datapoint Criteria */
  const hideDatapointTypes: string[] = [
    'sns_csq',
    'sns_rsrq',
    'sns_rsrp',
    'sns_post_req_succ',
    'sns_post_req_done',
    'sns_diagnostics_register',
    'sns_sinr',
    'sns_band',
    'sns_sim_slot',
    'sns_bat_accum',
    'sns_bat_time',
    'sns_pow_time',
    'sns_battery'
  ];

  if (hideDatapointTypes.includes(datapointType)) {
    return true;
  }
  return false;
};

function getRequiredWidgets(datapoints: any, widgets: any) {
  /**
   * Check if widgets exist for each datapoint
   * If yes, add widgets settings inside datapoint object
   * If no, create widgets for datapoints without widgets
   */
  let widgetCreationRequired = false;
  const requiredWidgets: any = {
    widgets: []
  };

  const updatedDatapoints = datapoints.map((datapoint: any) => {
    const updatedDatapoint = { ...datapoint };
    let widgetFound = false;
    // Iterate through widgets to find widget for the current datapoint
    widgets.forEach((widget: any) => {
      if (widget?.settings?.datapoint_ids.indexOf(datapoint.dpid) > -1) {
        widgetFound = true;
        updatedDatapoint.widget_settings = widget;
      }
    });

    if (!widgetFound) {
      widgetCreationRequired = true;
      const defaultHideSensor = hideSensor(datapoint.sensor_type);
      if (defaultHideSensor) {
        requiredWidgets.widgets.push({
          settings: {
            widget_type: 'line',
            show: false,
            datapoint_ids: [datapoint.dpid]
          }
        });
      } else {
        requiredWidgets.widgets.push({
          settings: {
            widget_type: 'line',
            show: true,
            datapoint_ids: [datapoint.dpid]
          }
        });
      }
    }
    return updatedDatapoint;
  });

  return {
    widgetCreationRequired,
    requiredWidgets: requiredWidgets.widgets,
    datapoints: updatedDatapoints
  };
}

/**
 *
 * @param action
 */
function* getDataPointsSaga(action: AnyAction): GenericObject {
  yield put(showProgressFor('GET_DATAPOINTS'));
  try {
    const [datapointsResponse, widgetsResponse] = yield all([
      call(getDatapoints, action.payload),
      call(getWidgetSettings, action.payload)
    ]);
    const deviceType = action.payload?.deviceType;
    let orderedDatapoints;
    if (deviceType === 'iCast1_NN_MPS') {
      orderedDatapoints = orderNevadaNanoDatapoints(datapointsResponse);
    } else {
      orderedDatapoints = orderDatapoints(datapointsResponse);
    }
    const datapointWidgets = getRequiredWidgets(datapointsResponse, widgetsResponse.widget);
    let datapointsWithWidgets;

    if (datapointWidgets.widgetCreationRequired) {
      // Create missing widgets
      const widgetsUpdateResponse: GenericObject = yield call(
        updateWidgetSettings,
        action.payload,
        datapointWidgets.requiredWidgets
      );
      const allWidget = [...widgetsResponse.widget, ...widgetsUpdateResponse.widgets];
      const datapointsWithWidgetList = getRequiredWidgets(orderedDatapoints, allWidget);
      datapointsWithWidgets = orderDatapoints(datapointsWithWidgetList.datapoints);
    } else {
      datapointsWithWidgets = orderDatapoints(datapointWidgets.datapoints);
    }
    yield put(datapointActions.datapointsSuccess(datapointsWithWidgets));
    yield put(datapointActions.setFetchDatapointsAgain(false));
  } catch (error: any) {
    yield put(showToast('Failed to fetch datapoints', 'error', true));
    yield put(datapointActions.datapointsFailure());
  } finally {
    yield put(removeProgressFor('GET_DATAPOINTS'));
  }
}
/**
 *
 */
export function* watchDatapointsSagas(): SagaIterator {
  yield takeEvery(DATAPOINT_ACTIONS.FETCH_DATAPOINTS, getDataPointsSaga);
}
