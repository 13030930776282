/**
 * Anomaly Eval Expression Reducer
 * @author meghneel.adke@shorelineiot.com
 */
import { AnyAction } from 'redux';
import { AnomalyExprEvalState, EXPR_EVAL_STATUS } from './expr-eval.state';
import * as ACTIONS from './expr-eval.actionTypes';

const initialState: AnomalyExprEvalState = {
  status: EXPR_EVAL_STATUS.INIT,
  result: {}
};

function onAnomolyExprEvalProgress(state: AnomalyExprEvalState): AnomalyExprEvalState {
  return {
    ...state,
    status: EXPR_EVAL_STATUS.IN_PROGRESS
  };
}
/**
 *
 * @param state
 */
function onAnomolyExprEvalSuccess(
  state: AnomalyExprEvalState,
  action: AnyAction
): AnomalyExprEvalState {
  return {
    ...state,
    status: EXPR_EVAL_STATUS.READY,
    result: action.payload
  };
}

function onAnomolyExprEvalFailure(state: AnomalyExprEvalState): AnomalyExprEvalState {
  return {
    ...state,
    status: EXPR_EVAL_STATUS.ERROR
  };
}

function onAnomolyExprEvalReset(): AnomalyExprEvalState {
  return {
    ...initialState
  };
}

export default function anomalyListReducer(
  state: AnomalyExprEvalState = initialState,
  action: AnyAction
): AnomalyExprEvalState {
  switch (action.type) {
    case ACTIONS.ANOMALY_EXPR_EVAL_IN_PROGRESS:
      return onAnomolyExprEvalProgress(state);
    case ACTIONS.ANOMALY_EXPR_EVAL_SUCCESS:
      return onAnomolyExprEvalSuccess(state, action);
    case ACTIONS.ANOMALY_EXPR_EVAL_FAILURE:
      return onAnomolyExprEvalFailure(state);
    case ACTIONS.ANOMALY_EXPR_EVAL_RESET:
      return onAnomolyExprEvalReset();
    default:
      return state;
  }
}
