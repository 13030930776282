import { AnyAction } from 'redux';
import * as ACTION_TYPES from './resetDatapoint.actionTypes';

export const resetDatapointProgress = (data: any): AnyAction => {
  return {
    type: ACTION_TYPES.RESET_DATAPOINT_PROGRESS,
    payload: data
  };
};
/**
 *
 * @param data
 */
export const resetDatapointSuccess = (data: any): AnyAction => {
  // returns the action type
  return {
    type: ACTION_TYPES.RESET_DATAPOINT_SUCCESS,
    data
  };
};
/**
 *
 * @param error
 */
export const resetDatapointFailure = (error: any): AnyAction => {
  // returns the action type
  return {
    type: ACTION_TYPES.RESET_DATAPOINT_FAILURE,
    error
  };
};
/**
 *
 * @param data
 */

export const resetDatapointReset = (): AnyAction => {
  return {
    type: ACTION_TYPES.RESET_DATAPOINT_RESET_STATE
  };
};
