import { AnyAction } from 'redux';
import * as ACTION_TYPE from './actions/ComponentClassList.actionTypes';
import { COMPONENT_CLASS_LIST_STATUS } from './types/ComponentClassList.status';
import { ComponentClassListState } from './types/ComponentClassListState';

const initialState: ComponentClassListState = {
  status: COMPONENT_CLASS_LIST_STATUS.INIT,
  ComponentClassListData: []
};

function fetchComponentClassList(state: ComponentClassListState): ComponentClassListState {
  return {
    ...state,
    status: COMPONENT_CLASS_LIST_STATUS.FETCHING_COMPONENT_CLASS_LIST
  };
}

function fetchComponentClassListSuccess(
  state: ComponentClassListState,
  ComponentClassListData: any
): ComponentClassListState {
  return {
    ...state,
    status: COMPONENT_CLASS_LIST_STATUS.READY,
    ComponentClassListData
  };
}

function fetchComponentClassListFailure(state: ComponentClassListState): ComponentClassListState {
  return {
    ...state,
    status: COMPONENT_CLASS_LIST_STATUS.ERROR
  };
}

function resetComponentClassList(state: ComponentClassListState): ComponentClassListState {
  return {
    ...state,
    status: COMPONENT_CLASS_LIST_STATUS.INIT
  };
}
export default function componentClassListReducer(
  state: ComponentClassListState = initialState,
  action: AnyAction
): ComponentClassListState {
  switch (action.type) {
    case ACTION_TYPE.FETCH_COMPONENT_CLASS_LIST_PROGRESS:
      return fetchComponentClassList(state);
    case ACTION_TYPE.FETCH_COMPONENT_CLASS_LIST_SUCCESS:
      return fetchComponentClassListSuccess(state, action.payload);
    case ACTION_TYPE.FETCH_COMPONENT_CLASS_LIST_FAILURE:
      return fetchComponentClassListFailure(state);
    case ACTION_TYPE.RESET_COMPONENT_CLASS_LIST:
      return resetComponentClassList(state);
    default:
      return state;
  }
}
