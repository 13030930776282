/**
 * Dashboard List Saga
 */

import { put, takeEvery, call } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import * as DASHBOARD_LIST_ACTION_TYPES from './dashboardList.actionsTypes';
import * as DASHBOARD_LIST_ACTIONS from './dashboardList.action';
import { GenericObject, httpGet, removeProgressFor, APISERVICES } from '../../../../framework';
import { SagaIterator } from 'redux-saga';
import { DashboardTypeData } from './dashboardList.stateType';

function fetchDashboardListFromDatabase(data: DashboardTypeData) {
  return httpGet(
    `orgs/${data.slug}/dashboard/dashboard_type/${data.role_uuid}`,
    null,
    APISERVICES.HOST_API
  );
}

export function* getDashboardListData(action: AnyAction) {
  try {
    const response: GenericObject = yield call(fetchDashboardListFromDatabase, action.payload);
    yield put(DASHBOARD_LIST_ACTIONS.handleGetDashboardListSuccess(response));
  } catch (error: any) {
    yield put(DASHBOARD_LIST_ACTIONS.handleGetDashboardListFailure(error));
  } finally {
    yield put(removeProgressFor('GET_DASHBOARD_LIST'));
  }
}

export function* watchDashboardListSaga(): SagaIterator {
  yield takeEvery(DASHBOARD_LIST_ACTION_TYPES.DASHBOARD_LIST_PROGRESS, getDashboardListData);
}
