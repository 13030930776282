/**
 * Selected Device state access
 * @param state
 * @returns
 */
import { RootState } from '../../../../../configureStore';

export const getSelectedDevices = (state: RootState) =>
  state.features.workflow.va.devices.selectedDevices;

export const getSelectedODR = (state: RootState) =>
  state.features.workflow.va.narrowbands.selectedODR;

export const getHarmonicsCount = (state: RootState) =>
  state.features.workflow.va?.units.harmonicsCount;
