import { put, takeEvery } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import * as ACTIONS from '../actions/changePassword.actions';
import * as ACTION_TYPE from '../actions/changePassword.actionTypes';
import { GenericObject, showToast } from '../../../../framework';
import { AuthService } from '../../../../framework/services/auth.service';

// const CHANGE_PASSWORD = 'CHANGE_PASSWORD_PROGRESS';

export function* changePassword(action: AnyAction) {
  try {
    const amplifyService = new AuthService();
    const response: GenericObject = yield amplifyService.changePassword(action.payload);
    yield put(showToast('Password changed successfully', 'success'));
    yield put(ACTIONS.changePasswordSuccess(response));
  } catch (error: any) {
    if (error.name === 'NotAuthorizedException') {
      yield put(showToast('Entered old password is incorrect!', 'error', true));
    } else {
      yield put(showToast('Password could not be changed!', 'error', true));
    }
    yield put(ACTIONS.changePasswordFailure(error));
  }
}

export function* changePasswordSaga() {
  yield takeEvery(ACTION_TYPE.CHANGE_PASSWORD_PROGRESS, changePassword);
}
