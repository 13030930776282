/**
 * Get Theme Actions
 * @author vinayak.rathod@shorelineiot.com
 */

import { AnyAction } from 'redux';
import * as ACTION_TYPE from './theme.actionTypes';

export const getOrgTheme = (body: any): AnyAction => {
  return {
    type: ACTION_TYPE.GET_ORG_THEME_PROGERSS,
    payload: body
  };
};

export const getOrgThemeSuccess = (data: any): AnyAction => {
  return {
    type: ACTION_TYPE.GET_ORG_THEME_SUCCESS,
    payload: data
  };
};

export const getOrgThemeFailure = (error: any): AnyAction => {
  return {
    type: ACTION_TYPE.GET_ORG_THEME_FAILURE,
    payload: error
  };
};
