/**
 * SL Multiselect Styles
 * @author mahesh.kedari@shorelineiot.com
 */
import makeStyles from '@mui/styles/makeStyles';
/**
 *
 */
export const useStyles = makeStyles(() => ({
  root: {
    '& .MuiPaper-root': {
      maxHeight: '25vh',
      maxWidth: '10vh',
      overflow: 'auto'
    },
    '& .MuiCheckbox - indeterminate': {
      color: 'primary'
    },
    '& .MuiTypography-displayBlock ': {
      overflow: 'hidden',
      width: '190px',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      cursor: 'pointer'
    }
  },
  checkBoxStyle: {
    color: 'primary'
  }
}));
