/**
 * Websocket Types
 * @author mahesh.kedari@shorelineiot.com
 */
export enum WEBSOCKET_TYPE {
  ORG = 'ORG',
  DEVICE = 'DEVICE',
  CREATE_ORG = 'CREATE_ORG'
}

export const MAX_RETRY_ATTEMPT = 5;
export const MAX_WAIT_TIME = 5 * 60 * 1000; // 5 Minutes;
export const PING_PONG_INTERVAL = 9 * 60 * 1000; // 9 Minutes;
