/**
 * Websocket Actions
 * @author mahesh.kedari@shorelineiot.com
 */
import { AnyAction } from 'redux';
import * as ACTIONS from './websocket.actionTypes';
import { WEBSOCKET_TYPE } from './websocket.const';
/**
 *
 * @param webSocket
 * @returns
 */
export const setWebSocket = (connection: any, type: WEBSOCKET_TYPE): AnyAction => {
  // returns the action type
  return {
    type: ACTIONS.SET_WEBSOCKET,
    payload: { type, connection }
  };
};
/**
 *
 * @param type
 * @returns
 */
export const clearWebSocket = (type: WEBSOCKET_TYPE): AnyAction => {
  return {
    type: ACTIONS.CLEAR_WEBSOCKET,
    payload: { type }
  };
};

export const updateRetryAttempt = (type: WEBSOCKET_TYPE): AnyAction => {
  return {
    type: ACTIONS.UPDATE_RETRY_ATTEMPT,
    payload: { type }
  };
};
