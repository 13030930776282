import { Location } from 'history';
import { OrbitPointOptionsObject } from '../types';
import { Device } from '../../../../device/store/types';
import { DEVICE_PROVISION_STATUS } from '../../../../../framework/constants/provision';
import { DeviceWithValueAndLabel } from '../../../anomaly-configurator/components/AnomalyConfigConditionRow';
import { NavigateFunction } from 'react-router-dom';

/**
 * Represents a collection of query parameters as key-value pairs.
 */
type QueryParameters = { [key: string]: string };

/**
 * Updates query parameters of the current URL and navigates to the updated URL
 * while preserving the existing ones.
 *
 * @param location - The current location object containing the URL information.
 * @param navigate - The navigate object used for navigation and URL updates.
 * @param newParams - The new query parameters to be added or updated.
 * @param paramsToDelete - The query parameter keys to be deleted.
 */
export const updateQueryParameters = (
  location: Location,
  navigate: NavigateFunction,
  newParams: QueryParameters = {},
  paramsToDelete: string[] = []
): void => {
  const updatedSearchParams = new URLSearchParams(location.search);

  // Add or update new query parameters
  Object.entries(newParams).forEach(([key, value]) => {
    updatedSearchParams.set(key, value || '');
  });

  // Delete query parameters that are meant to be deleted
  paramsToDelete.length &&
    paramsToDelete.forEach((key) => {
      updatedSearchParams.delete(key);
    });

  // Construct the new URL by combining the pathname with the updated search parameters
  const newUrl = `${location.pathname}?${updatedSearchParams.toString()}`;

  // Update the URL with the new query parameters and preserve the current state
  navigate(newUrl, { replace: true, state: location.state });
};

/**
 * Find the last valid event_id in an array of objects.
 *
 * @param {OrbitPointOptionsObject[]} items - The array of objects to search.
 * @returns {string | null} The last valid event_id found, or null if none is found.
 */
export function findLastValidEventId(items: OrbitPointOptionsObject[]): number | null {
  let lastValidEventId = null;

  for (let i = items.length - 1; i >= 0; i--) {
    const event_id = items[i]?.event_id;
    if (event_id) {
      lastValidEventId = event_id;
      break; // Stop looping once a valid event_id is found
    }
  }

  return lastValidEventId;
}

export type DeviceTypeValue = {
  label: string;
  value: number;
  path: string;
};

export const deviceListMap = (device: Device) => ({
  label: device?.name || `${device?.id}`,
  value: device?.id,
  path: device?.fq_dg_path
});

export const deviceListSort = (
  device1: DeviceTypeValue | DeviceWithValueAndLabel,
  device2: DeviceTypeValue | DeviceWithValueAndLabel
) => {
  if (device1.label < device2.label) {
    return -1;
  } else if (device1.label > device2.label) {
    return 1;
  } else {
    return 0;
  }
};

/**
 * Retrieves a list of provisioned devices based on the given array of devices.
 *
 * @param {Device[]} devices - An array of devices to filter and map.
 * @return {DeviceTypeValue[]} - A sorted array of provisioned devices.
 */
export const getProvisionedDeviceList = (devices: Device[]): DeviceTypeValue[] => {
  const list = devices
    .filter(
      (device) =>
        device.provisioning_status === DEVICE_PROVISION_STATUS.PROVISIONED &&
        device.ext_datasource.length === 0 &&
        device?.is_in_powertrain
    )
    .map(deviceListMap)
    .sort(deviceListSort);
  return list;
};
