/**
 * local dashbaord items
 */
export enum LocalDashboardItemsStatus {
  INIT,
  FETCHING,
  READY,
  ERROR,
  RESET
}

interface LocalDashboardItemsData {
  dashboard_items: any[];
}
export interface LocalDashboardItemsState {
  status: LocalDashboardItemsStatus;
  localDashboardItemsData: LocalDashboardItemsData;
}
