import { showToast } from '../../../../framework';
import { buildQuery } from '../../../../framework/lib/rtk/RTK.utils';
import { APISERVICES, HTTP_METHOD } from '../../../../framework/services/auth.service';
import {
  FEATURE_CONFIGURATIONS_TAG_TYPES,
  FetchFeaturesListArgs,
  UpdateOrgFeaturesArgs
} from './featureConfigurations.types';

export function buildFeatureDetailsApiEndpoint(slug: string) {
  return `orgs/${slug}/features`;
}

export function buildOrgFeaturesDetailsApiEndpoint(slug: string) {
  return `orgs/${slug}/org_features`;
}
export function fetchFeatureListQuery(args: FetchFeaturesListArgs) {
  return buildQuery({
    apiEndpoint: buildFeatureDetailsApiEndpoint(args.slug),
    method: HTTP_METHOD.GET,
    service: APISERVICES.HOST_API,
    actionKey: FEATURE_CONFIGURATIONS_TAG_TYPES.FEATURE_DETAILS
  });
}

export function fetchOrgFeatureListQuery(args: FetchFeaturesListArgs) {
  return buildQuery({
    apiEndpoint: buildOrgFeaturesDetailsApiEndpoint(args.slug),
    method: HTTP_METHOD.GET,
    service: APISERVICES.HOST_API,
    actionKey: FEATURE_CONFIGURATIONS_TAG_TYPES.ORG_FEATURE_LIST
  });
}

export function updateOrgFeaturesQuery(args: UpdateOrgFeaturesArgs) {
  return buildQuery({
    apiEndpoint: buildOrgFeaturesDetailsApiEndpoint(args.slug),
    method: HTTP_METHOD.PUT,
    service: APISERVICES.HOST_API,
    actionKey: FEATURE_CONFIGURATIONS_TAG_TYPES.ORG_FEATURES_UPDATE,
    body: args.payload,
    onSuccess(dispatch) {
      dispatch(showToast('Feature configurations updated successfully!', 'success'));
    }
  });
}
